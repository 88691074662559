import React from "react";

function IconRightFilled(props) {
   return (
    <svg
      width="7"
      height="11"
      viewBox="0 0 7 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.95952 4.79289L1.87373 0.707107C1.24377 0.0771419 0.166626 0.523308 0.166626 1.41421V9.58579C0.166626 10.4767 1.24377 10.9229 1.87373 10.2929L5.95952 6.20711C6.35004 5.81658 6.35004 5.18342 5.95952 4.79289Z"
        // fill="#2F5D6E"
        fill="#ffffff"
      />
    </svg>
  );
}

export default IconRightFilled;
