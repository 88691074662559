    import axios from 'axios';

    import { handleNavigation } from './navigationHandler';

    const instance = axios.create({
        baseURL: API_URL,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            // Authorization: `Bearer ${localStorage.getItem('token')}`  // Add this line 
        }
    });

    instance.interceptors.response.use(
        (response) => {
            return response;
            },
            (error) => {
          
                if (error.response.status === 403) {
                    handleNavigation(error.response.status);
                }

                return Promise.reject(error);
    
        }
    );

    export default instance;


