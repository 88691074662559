import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import MetaTags from 'react-meta-tags';
import doc from '../../assets/images/doc.jpg';
import Menus from "./onCallMenus";
import SignaturePad from 'react-signature-canvas'
import { connect } from "react-redux"
import {
    Modal
} from "reactstrap"
import { getWardsList, getTheatresList, getClinicList, getPatientList, getUprofile, getDoctorList, updatePatient, getNews, updateDailyRounds, getDailyRounds, teamListUpdate } from "../../store/actions"

const OnCall = props => {
    document.body.style.overflow = '';
    document.body.style.padding = '';
    let clinicPatient = JSON.parse(localStorage.getItem("p"))
    const [discard_popup, setdiscard_popup] = useState(false)
    const [allied_value, setallied_value] = useState(null)
    const [clinicValues, setClinic] = useState({})
    const [patientValues, setPatient] = useState({})
    const [success_msg, setsuccess_msg] = useState(false)

    const {
        wards, user, theatres, clinic, patients, profile, doctors
    } = props;

    
const assessedinternal = [
    {
        name: "assessedinternalCheck",
        value: "Exam"
    },
    {
        name: "assessedinternalCheck",
        value: "Ultrasound"
    },
    {
        name: "assessedinternalCheck",
        value: "CT"
    },
    {
        name: "assessedinternalCheck",
        value: "Diagnostic peritoneal large"
    }
]
    const airwayintervention = [
        {
            name: "airwayinterventionCheck",
            value: "Yes, Done"
        },
        {
            name: "airwayinterventionCheck",
            value: "No"
        }
    ]
    
    const Haemothorax = [
        {
            name: "HaemothoraxCheck",
            value: "Yes, chest drain placed"
        },
        {
            name: "HaemothoraxCheck",
            value: "No"
        }
    ]
    
    const pulseoximeter = [
        {
            name: "pulseoximeterCheck",
            value: "Yes"
        },
        {
            name: "pulseoximeterCheck",
            value: "No"
        }
    ]
    
    const IVplaced = [
        {
            name: "IVplacedCheck",
            value: "Yes"
        },
        {
            name: "IVplacedCheck",
            value: "Not indicated"
        }
    ]
    
    const externalbleeding = [
        {
            name: "externalbleedingCheck",
            value: "Scalp"
        },
        {
            name: "externalbleedingCheck",
            value: "Perinium"
        },
        {
            name: "externalbleedingCheck",
            value: "Back"
        }
    ]
    
    
    const pelvicfracture = [
        {
            name: "pelvicfractureCheck",
            value: "Exam"
        },
        {
            name: "pelvicfractureCheck",
            value: "X-Ray"
        },
        {
            name: "pelvicfractureCheck",
            value: "CT"
        }
    ]
    
    const internalbleeding = [
        {
            name: "internalbleedingCheck",
            value: "Exam"
        },
        {
            name: "internalbleedingCheck",
            value: "Ultrasound"
        },
        {
            name: "internalbleedingCheck",
            value: "CT"
        },
        {
            name: "internalbleedingCheck",
            value: "Diagnostic peritoneal large"
        }
    ]
    
    const immobilisation = [
        {
            name: "immobilisationCheck",
            value: "Yes, done"
        },
        {
            name: "immobilisationCheck",
            value: "Not indicated"
        }
    ]
    
    const neurovascular = [
        {
            name: "neurovascularCheck",
            value: "Yes"
        },
        {
            name: "neurovascularCheck",
            value: "No"
        }
    ]
    
    const hypothermic = [
        {
            name: "hypothermicCheck",
            value: "Yes, Warming"
        },
        {
            name: "hypothermicCheck",
            value: "No"
        }
    ]
    
    
    const patientneed = [
        {
            name: "patientneedCheck",
            value: "Urinary catheter"
        },
        {
            name: "patientneedCheck",
            value: "NG tube"
        },
        {
            name: "patientneedCheck",
            value: "Chest drain"
        },
        {
            name: "patientneedCheck",
            value: "None indicated"
        }
    ]
    
    const leavepatienttetanus = [
        {
            name: "leavepatienttetanusCheck",
            value: "Tetanus vaccine"
        },
        {
            name: "leavepatienttetanusCheck",
            value: "Analgesics"
        },
        {
            name: "leavepatienttetanusCheck",
            value: "Antibiotics"
        },
        {
            name: "leavepatienttetanusCheck",
            value: "None indicated"
        }
    ]
    
    const tensionpneumo = [
        {
            name: "tensionpneumoCheck",
            value: "Yes, chest drain placed"
        },
        {
            name: "tensionpneumoCheck",
            value: "No"
        }
    ]
    
    const pulseoximeterplaced = [
        {
            name: "pulseoximeterplacedCheck",
            value: "Yes"
        },
        {
            name: "pulseoximeterplacedCheck",
            value: "No"
        }
    ]
    
    const fluidsstarted = [
        {
            name: "fluidsstartedCheck",
            value: "Yes"
        },
        {
            name: "fluidsstartedCheck",
            value: "Not indicated"
        }
    ]
    
    const surveybleeding = [
        {
            name: "surveybleedingCheck",
            value: "Scalp"
        },
        {
            name: "surveybleedingCheck",
            value: "Perinium"
        },
        {
            name: "surveybleedingCheck",
            value: "Back"
        }
    ]
    
    const assessedpelvic = [
        {
            name: "assessedpelvicCheck",
            value: "Exam"
        },
        {
            name: "assessedpelvicCheck",
            value: "X-Ray"
        },
        {
            name: "assessedpelvicCheck",
            value: "CT"
        }
    ]
    
    const spinalimmobilisation = [
        {
            name: "spinalimmobilisationCheck",
            value: "Yes, Done"
        },
        {
            name: "spinalimmobilisationCheck",
            value: "Not indicated"
        }
    ]
    
    const neurovascularstatus = [
        {
            name: "neurovascularstatusCheck",
            value: "Yes"
        },
        {
            name: "neurovascularstatusCheck",
            value: "No"
        }
    ]
    
    const patienthypothermic = [
        {
            name: "patienthypothermicCheck",
            value: "Yes, Warming"
        },
        {
            name: "patienthypothermicCheck",
            value: "No"
        }
    ]
    
    const contraindications = [
        {
            name: "contraindicationsCheck",
            value: "Urinary catheter"
        },
        {
            name: "contraindicationsCheck",
            value: "NG tube"
        },
        {
            name: "contraindicationsCheck",
            value: "Chest drain"
        },
        {
            name: "contraindicationsCheck",
            value: "None"
        },
        {
            name: "contraindicationsCheck",
            value: "Indicated"
        }
    ]
    
    const imagingbeenreviewed = [
        {
            name: "imagingbeenreviewedCheck",
            value: "Yes"
        },
        {
            name: "imagingbeenreviewedCheck",
            value: "No follow-up plan in place"
        }
    ]
    
    const serialexamination = [
        {
            name: "serialexaminationCheck",
            value: "Neurological"
        },
        {
            name: "serialexaminationCheck",
            value: "Abdominal"
        },
        {
            name: "serialexaminationCheck",
            value: "Vascular"
        },
        {
            name: "serialexaminationCheck",
            value: "None"
        }
    ]
    
    const carediscussed = [
        {
            name: "carediscussedCheck",
            value: "Patient/family"
        },
        {
            name: "carediscussedCheck",
            value: "Receiving unit"
        },
        {
            name: "carediscussedCheck",
            value: "Primary team"
        },
        {
            name: "carediscussedCheck",
            value: "Other specialists"
        }
    ]
    
    const relevantTrauma = [
        {
            name: "relevantTraumaCheck",
            value: "Yes"
        },
        {
            name: "relevantTraumaCheck",
            value: "Not available"
        }
    ]
    
    
    
    
    const treatment_airway = [
        {
            name: "treatment_airwayCheck",
            value: "Oral"
        },
        {
            name: "treatment_airwayCheck",
            value: "Nasal Catheter"
        },
        {
            name: "treatment_airwayCheck",
            value: "NRB"
        },
        {
            name: "treatment_airwayCheck",
            value: "Ambu"
        },
        {
            name: "treatment_airwayCheck",
            value: "Other"
        }
    ]
    

    const backboardcheck = [
        {
            name: "backboardcheckCheck",
            value: "Long"
        },
        {
            name: "backboardcheckCheck",
            value: "Short"
        },
        {
            name: "backboardcheckCheck",
            value: "Ked"
        },
        {
            name: "backboardcheckCheck",
            value: "Scoop"
        }
    ]

    const treatmentcollar = [
        {
            name: "treatmentcollarCheck",
            value: "C-Collar on"
        },
        {
            name: "treatmentcollarCheck",
            value: "No cervical collar"
        }
    ]
    const penetrating = [
        {
            name: "penetratingCheck",
            value: "GSW"
        },
        {
            name: "penetratingCheck",
            value: "Stabbing"
        },
        {
            name: "penetratingCheck",
            value: "Other"
        }
    ]
    const blunt = [
        {
            name: "bluntCheck",
            value: "Assault"
        },
        {
            name: "bluntCheck",
            value: "Crush"
        },
        {
            name: "bluntCheck",
            value: "Other"
        }
    ]

    const thermal = [
        {
            name: "thermalCheck",
            value: "Burn"
        },
        {
            name: "thermalCheck",
            value: "Heat exposure"
        },
        {
            name: "thermalCheck",
            value: "Cold exposure"
        }
    ]

    const mechanism_other = [
        {
            name: "mechanism_otherCheck",
            value: "Hanging"
        },
        {
            name: "mechanism_otherCheck",
            value: "Near drowning"
        },
        {
            name: "mechanism_otherCheck",
            value: "Animal related"
        }
    ]

    const lower_check = [
        {
            name: "lower_check_CMS",
            value: "CMS intact x4"
        },
        {
            name: "lower_check_Moves",
            value: "Moves all extremities"
        },
        {
            name: "lower_check_Extremities",
            value: "Extremities warm and pink"
        },
        {
            name: "lower_check_Deformity",
            value: "Deformity"
        },
        {
            name: "lower_check_Fracture",
            value: "Fracture"
        },
        {
            name: "lower_check_Dislocation",
            value: "Dislocation"
        }
    ]

    const upper_check = [
        {
            name: "upper_check_CMS",
            value: "CMS intact x4"
        },
        {
            name: "upper_check_Moves",
            value: "Moves all extremities"
        },
        {
            name: "upper_check_Extremities",
            value: "Extremities warm and pink"
        },
        {
            name: "upper_check_Deformity",
            value: "Deformity"
        },
        {
            name: "upper_check_Fracture",
            value: "Fracture"
        },
        {
            name: "upper_check_Dislocation",
            value: "Dislocation"
        }
    ]

    const pelvis_check = [
        {
            name: "pelvis_check_flail",
            value: "Blood at meatus"
        },
        {
            name: "pelvis_check_Dyspnoea",
            value: "Rectal Exam Blood at rectum"
        }
    ]

    const pelvispain = [
        {
            name: "pelvispainCheck",
            value: "Pain"
        },
        {
            name: "pelvispainCheck",
            value: "Tenderness"
        }
    ]

    const pelvisstable = [
        {
            name: "pelvisstableCheck",
            value: "Stable"
        },
        {
            name: "pelvisstableCheck",
            value: "Unstable"
        }
    ]

    const pelvisspresent = [
        {
            name: "pelvisspresentCheck",
            value: "Present"
        },
        {
            name: "pelvisspresentCheck",
            value: "Absent"
        }
    ]

    const lowerextremities = [
        {
            name: "lowerextremitiesCheck",
            value: "Pain"
        },
        {
            name: "lowerextremitiesCheck",
            value: "Tenderness"
        }
    ]


    const upperextremities = [
        {
            name: "upperextremitiesCheck",
            value: "Pain"
        },
        {
            name: "upperextremitiesCheck",
            value: "Tenderness"
        }
    ]
    const logrolling = [
        {
            name: "logrollingCheck",
            value: "Pain"
        },
        {
            name: "logrollingCheck",
            value: "Tenderness"
        }
    ]

    const abodmensoft = [
        {
            name: "abodmensoftCheck",
            value: "Soft"
        },
        {
            name: "abodmensoftCheck",
            value: "Rigid"
        },
        {
            name: "abodmensoftCheck",
            value: "Guarded"
        },
        {
            name: "abodmensoftCheck",
            value: "Distended Non tender"
        },
        {
            name: "abodmensoftCheck",
            value: "Tender"
        }
    ]

    const abodmenbowel = [
        {
            name: "abodmenbowelCheck",
            value: "Present"
        },
        {
            name: "abodmenbowelCheck",
            value: "Absent Hyperactive"
        }
    ]

    const chestlungsSounds = [
        {
            name: "chestlungsSoundsCheck",
            value: "Heart Sounds"
        },
        {
            name: "chestlungsSoundsCheck",
            value: "Present"
        },
        {
            name: "chestlungsSoundsCheck",
            value: "Distant"
        },
        {
            name: "chestlungsSoundsCheck",
            value: "Absent"
        }
    ]

    const chestlungspain = [
        {
            name: "chestlungspainCheck",
            value: "Chest Pain"
        },
        {
            name: "chestlungspainCheck",
            value: "Location"
        },
        {
            name: "chestlungspainCheck",
            value: "Time of onset Activity @ onset"
        }
    ]


    const chestlungs_checklist = [
        {
            name: "chestlungs_checklist_flail",
            value: "Flail chest"
        },
        {
            name: "chestlungs_checklist_Dyspnoea",
            value: "Dyspnoea"
        },
        {
            name: "chestlungs_checklist_Other",
            value: "Other"
        }
    ]


    const chestlungs = [
        {
            name: "chestlungsCheck",
            value: "Pain"
        },
        {
            name: "chestlungsCheck",
            value: "Tenderness"
        },
        {
            name: "chestlungsCheck",
            value: "Symmetrical"
        },
        {
            name: "chestlungsCheck",
            value: "Asymmetrical Paradoxical expansion movement"
        }
    ]


    const neckspine_checklist = [
        {
            name: "neckspine_checklist_Subcutaneous",
            value: "Subcutaneous emphysema"
        },
        {
            name: "neckspine_checklist_swallowing",
            value: "Difficulty swallowing"
        },
        {
            name: "neckspine_checklist_Jugular",
            value: "Jugular Vein Distension"
        }
    ]

    const neckspine = [
        {
            name: "neckspineCheck",
            value: "Pain"
        },
        {
            name: "neckspineCheck",
            value: "Tenderness"
        },
        {
            name: "neckspineCheck",
            value: "Swelling"
        },
        {
            name: "neckspineCheck",
            value: "Cut"
        },
        {
            name: "neckspineCheck",
            value: "Bruise"
        }
    ]

    const missingteeth = [
        {
            name: "missingteethCheck",
            value: "Right"
        },
        {
            name: "missingteethCheck",
            value: "Left"
        }
    ]

    const rightear = [
        {
            name: "rightearCheck",
            value: "Clear"
        },
        {
            name: "rightearCheck",
            value: "Blood"
        }
    ]

    const leftear = [
        {
            name: "leftearCheck",
            value: "Clear"
        },
        {
            name: "leftearCheck",
            value: "Blood"
        }
    ]
    const noselist = [
        {
            name: "noselistCheck",
            value: "Clear"
        },
        {
            name: "noselistCheck",
            value: "Blood"
        }
    ]
    const visualacultylac = [
        {
            name: "visualacultylacCheck",
            value: "Right Eye"
        },
        {
            name: "visualacultylacCheck",
            value: "Left eye"
        }
    ]
    const headslap = [
        {
            name: "headslapCheck",
            value: "Pain"
        },
        {
            name: "headslapCheck",
            value: "Tenderness"
        }
    ]

    const headslaplac = [
        {
            name: "headslaplacCheck",
            value: "Laceration"
        },
        {
            name: "headslaplacCheck",
            value: "Bruising"
        },
        {
            name: "headslaplacCheck",
            value: "Abrasions"
        },
        {
            name: "headslaplacCheck",
            value: "Burns"
        },
    ]
    const bloodTable = ["O negative", "Blood unit #", "Time discontinued", "Amount infused ml"]
    const infusionTable = ["Adrenaline", "Noradrenaline", "Dopamine", "Fentanyl"]
    const inputTable = ["IV Fluids", "Blood", "Fresh Frozen Plasma"]

    const outputTable = ["Urine", "Emesis", "Chest Tube", "Other"]

    const bloodProductsTable = ["Packed cells", "Fresh frozen plasma", "Platelets", "PTM cryoprecipitate", "Fibrinogen", "Prothrombin complex"]

    const fluidsTable = ["Crystalloids", "Colloids"]

    const medicationTable = ["Analgesics", "Tranexamic Acid (15mg/kg)", "Antibiotics", "Tetanus prophylaxis", "Calcium Chloride(Pack red blood cells contain citrate that chelates the ionised calcium and depletes it from the body; thus calcium needs replacing)"]

    const vitalTable = [1, 2, 3, 4]
    const infus = [
        {
            name: "infus_allergy",
            value: "ALLERGIES"
        },
        {
            name: "infus_medi",
            value: "MEDICATIONS"
        },
        {
            name: "infus_history",
            value: "MEDICAL HISTORY"
        }
    ]

    const ecomo = [
        {
            name: "ecomo_fac",
            value: "Comorbid Factors"
        },
        {
            name: "ecomo_belong",
            value: "Personal Belongings"
        },
        {
            name: "ecomo_clothes",
            value: "Clothes"
        },
        {
            name: "ecomo_purse",
            value: "Purse"
        },
        {
            name: "ecomo_wallet",
            value: "Wallet"
        },
        {
            name: "ecomo_jewel",
            value: "Jewellery"
        }
    ]
    const transferTeam = [
        {
            name: "transferTeam_eqp",
            value: "Equipment"
        },
        {
            name: "transferTeam_medi",
            value: "Medications"
        },
        {
            name: "transferTeam_identify",
            value: "Identify who will travel with patent"
        },
        {
            name: "transferTeam_notify",
            value: "Notify destination: Theatre ICU etc"
        },
        {
            name: "transferTeam_consider",
            value: "Consider splinting and apply dressings pre-transfer"
        },
        {
            name: "transferTeam_secure",
            value: "Secure for transfer (use a vacuum mattress)"
        }
    ]

    const transferList = [
        {
            name: "transferCheck",
            value: "Transfer"
        },
        {
            name: "transferCheck",
            value: "Not applicable Intubation"
        },
        {
            name: "transferCheck",
            value: "Operation Theatre"
        },
        {
            name: "transferCheck",
            value: "Critical care"
        },
        {
            name: "transferCheck",
            value: "For monitoring"
        },
        {
            name: "transferCheck",
            value: "Interventional Radiology"
        },
        {
            name: "transferCheck",
            value: "Trauma Ward"
        },
        {
            name: "transferCheck",
            value: "Tertiary Centre"
        }
    ]

    const wholeTeam = [
        {
            name: "wholeTeam_direct",
            value: "Direct team to complete any unchecked items"
        },
        {
            name: "wholeTeam_findings",
            value: "Summarise findings and brief team on plan of care and next steps"
        },
        {
            name: "wholeTeam_anyone",
            value: "Does anyone have any other concerns"
        }
    ]

    const secondary = [
        {
            name: "secondary_direct",
            value: "Immediate computed tomography scan or transfer to operating theatre?"
        },
        {
            name: "secondary_patient",
            value: "Set goals on physiology and blood products"
        },
        {
            name: "secondary_everyone",
            value: "Ongoing transfusion requirements?"
        }
    ]

    const vitalspause = [
        {
            name: "vitalspause_direct",
            value: "Direct team to complete any unchecked items"
        },
        {
            name: "vitalspause_patient",
            value: "Patient is haemodynamically unstable may require emergency surgical intervention / Not applicable"
        },
        {
            name: "vitalspause_everyone",
            value: "Is everyone comfortable moving onto the secondary survey?"
        }
    ]

    const vitals = [
        {
            name: "vitals_state",
            value: "State and evaluate whether logical and WNL for age"
        },
        {
            name: "vitals_heart",
            value: "Heart rate with good waveform"
        },
        {
            name: "vitals_resp",
            value: "Respiratory rate"
        },
        {
            name: "vitals_oxy",
            value: "Oxygen saturation"
        },
        {
            name: "vitals_bp",
            value: "Blood pressure"
        },
        {
            name: "vitals_chest",
            value: "Consider chest and pelvic radiographs"
        },
        {
            name: "vitals_focused",
            value: "Consider focused assessment with sonography for trauma (FAST scan)"
        },
        {
            name: "vitals_access",
            value: "Further intravenous access"
        },
        {
            name: "vitals_analgesia",
            value: "Analgesia (rapid sequence induction for humanitarian needs, ie, if the patient is in such severe pain and an operation is planned very soon)"
        },
        {
            name: "vitals_rapid",
            value: "If rapid sequence induction is performed, start intravenous infusion of sedation drugs"
        },
        {
            name: "vitals_active",
            value: "Active warming measures"
        },
        {
            name: "vitals_reversal",
            value: "Reversal of warfarin if actively bleeding (prothrombin complex concentrate)"
        },
        {
            name: "vitals_tranexamic",
            value: " Tranexamic acid (ideally initial loading dose given as early as possible, followed by an infusion)"
        }
    ]

    const outcome_admitted = [
        {
            name: "outcome_admitted_service",
            value: "Admitting service"
        },
        {
            name: "outcome_admitted_physician",
            value: "Admitting physician"
        },
        {
            name: "outcome_admitted_ward",
            value: "Ward Location"
        }
    ]

    const transferred_to = [
        {
            name: "transferred_to_time",
            value: "Time out of ED"
        }
    ]

    const expired = [
        {
            name: "expired_time",
            value: "Time"
        },
        {
            name: "expired_coroner",
            value: "Coroner information"
        },
        {
            name: "expired_organ",
            value: "Organ donor services called"
        },
        {
            name: "expired_valuable",
            value: "Valuables"
        },
        {
            name: "expired_ref",
            value: "Referral hospital notified"
        }
    ]

    const assess = [
        {
            name: "assess_airway",
            value: "Patent airway"
        },
        {
            name: "assess_central",
            value: "Central pulse"
        },
        {
            name: "assess_catas",
            value: "No Visible catastrophic haemorrhage"
        }
    ]

    const catastrophic = [
        {
            name: "catastrophic_bandage",
            value: "Apply Compression bandage"
        },
        {
            name: "catastrophic_bandage",
            value: "Tourniquet"
        }
    ]

    const jawList = [
        {
            name: "jaw",
            value: "Jaw thrust"
        },
        {
            name: "jaw",
            value: "Suctioning Bag Mask O2: L"
        }
    ]

    const oralList = [
        {
            name: "oral",
            value: "Oral airway"
        },
        {
            name: "oral",
            value: "Nasal airway"
        }
    ]

    const objremovalList = [
        {
            name: "objremoval",
            value: "Foreign object removal"
        },
        {
            name: "objremoval",
            value: "laryngoscopy"
        }
    ]

    const breathList = [
        {
            name: "breath",
            value: "Breathing Spontaneous Laboured"
        },
        {
            name: "breath",
            value: "Agonal"
        },
        {
            name: "breath",
            value: "Shallow"
        },
        {
            name: "breath",
            value: "Stridor"
        },
        {
            name: "breath",
            value: "Retracting"
        },
        {
            name: "breath",
            value: "Intercostal"
        },
        {
            name: "breath",
            value: "Paradoxical"
        },
        {
            name: "breath",
            value: "Absent"
        }
    ]

    const arrivedList = [
        {
            name: "arrivednasal",
            value: "Ambulance"
        },
        {
            name: "arrivednasal",
            value: "Helicopter"
        },
        {
            name: "arrivednasal",
            value: "Police"
        },
        {
            name: "arrivednasal",
            value: "Self"
        }
    ]

    const pulsesList = [
        {
            name: "pulsesPresent",
            value: "Carotid"
        },
        {
            name: "pulsesPresent",
            value: "Femoral"
        },
        {
            name: "pulsesPresent",
            value: "Radial"
        },
        {
            name: "pulsesPresent",
            value: "Pedal No pulse"
        }
    ]

    const capillaryList = [
        {
            name: "capillary",
            value: "None Delayed (&gt; 2 sec)"
        },
        {
            name: "capillary",
            value: "Normal (&lt; 2 sec)"
        }
    ]

    const ioList = [
        {
            name: "ioCheck",
            value: "Confirm IV"
        },
        {
            name: "ioCheck",
            value: "IO access has been established"
        }
    ]

    const fluidList = [
        {
            name: "fluidCheck",
            value: "Give fluid bolus ( Normal saline/Ringers lactate)"
        },
        {
            name: "fluidCheck",
            value: "N/A"
        }
    ]

    const bleedingList = [
        {
            name: "bleedingCheck",
            value: "Controlled"
        },
        {
            name: "bleedingCheck",
            value: "Uncontrolled"
        },
        {
            name: "bleedingCheck",
            value: "N/A"
        }
    ]

    const bleedingControlList = [
        {
            name: "bleedingControlCheck",
            value: "Direct pressure"
        },
        {
            name: "bleedingControlCheck",
            value: "Site"
        }
    ]
    const skinList = [
        {
            name: "skinCheck",
            value: "Pink"
        },
        {
            name: "skinCheck",
            value: "Pallor"
        },
        {
            name: "skinCheck",
            value: "Flushed"
        },
        {
            name: "skinCheck",
            value: "Dusky"
        },
        {
            name: "skinCheck",
            value: "Cyanotic"
        },
        {
            name: "skinCheck",
            value: "Mottled"
        }
    ]

    const pelvicList = [
        {
            name: "pelvicCheck",
            value: "Exam"
        },
        {
            name: "pelvicCheck",
            value: "X-ray"
        },
        {
            name: "pelvicCheck",
            value: "CT"
        }
    ]

    const fractureList = [
        {
            name: "fractureCheck",
            value: "Pelvic fracture suspected"
        },
        {
            name: "fractureCheck",
            value: "Apply pelvic binder"
        }
    ]

    const longList = [
        {
            name: "longCheck",
            value: "Long bone fractures"
        },
        {
            name: "longCheck",
            value: "Splint & assess peripheral pulses"
        }
    ]

    const assessedList = [
        {
            name: "assessedCheck",
            value: "Exam"
        },
        {
            name: "assessedCheck",
            value: "Ultrasound"
        },
        {
            name: "assessedCheck",
            value: "CT"
        },
        {
            name: "assessedCheck",
            value: "Diagnostic peritoneal large"
        }
    ]

    const bleedList = [
        {
            name: "bleedCheck",
            value: "Internal bleed suspected"
        },
        {
            name: "bleedCheck",
            value: "Management"
        }
    ]

    const rapidList = [
        {
            name: "rapidCheck",
            value: "Head injury "
        },
        {
            name: "rapidCheck",
            value: "Rapid Sequence Intubation"
        }
    ]

    const interventions = [
        {
            name: "interventions_oxygen",
            value: "Oxygen (15 L via non re-breather mask)"
        },
        {
            name: "interventions_cervical",
            value: "Cervical spine immobilisation if neck injury is suspected"
        },
        {
            name: "interventions_secure",
            value: "Securing a large bore venous access to allow rapid administration of blood and blood products, or intraosseous access. Begin transfusion via rapid infuser"
        },
        {
            name: "interventions_perform",
            value: "Perform an early log roll if dealing with penetrating trauma"
        }
    ]

    const bloodtaken = [
        {
            name: "bloodtaken_count",
            value: "Full blood count"
        },
        {
            name: "bloodtaken_rotem",
            value: "Thromboelastometry (eg, RoTEM)"
        },
        {
            name: "bloodtaken_gas",
            value: "Venous blood gas"
        },
        {
            name: "bloodtaken_group",
            value: "Blood group and save"
        },
        {
            name: "bloodtaken_point",
            value: "Point of care INR testing if the patient is on warfarin"
        }
    ]

    let fractureSigPad = useRef(null);

    useEffect(() => {
        const { getWards, getTheatres, getClinic, getDoctor, getPatient, getUserprofile } = props

        getPatient()
        getDoctor()
        let userId = localStorage.getItem("authUser")
        getUserprofile(userId)
    }, [])

    useEffect(() => {
        let clinicPatient = JSON.parse(localStorage.getItem("p"))
        if (patients && patients.length > 0 && clinicPatient && clinicPatient._id) {
            const cPatient = patients.filter(
                usr => usr._id === clinicPatient._id
            )
            if (cPatient && cPatient[0]) {
                let cpatientData = cPatient[0]
                delete cpatientData["_id"]
                setClinic(clinicValues => ({
                    ...clinicValues,
                    ...cpatientData
                }));
            }

        }
    }, [patients])

    const clear = (e) => {
        e.preventDefault()
        fractureSigPad.clear()
    }
    const trim = (e) => {
        e.preventDefault()
        console.log("doc url", fractureSigPad.getTrimmedCanvas()
                .toDataURL('image/png'))

    }

    const updateInputValue = (e) => {
        let val = e.target.value;
        let name = e.target.name;
        let updatedValue = {};
        updatedValue = { [name]: val };
        setPatient(patientValues => ({
            ...patientValues,
            ...updatedValue
        }));

    }

    const switchChange = (event) => {
        updateClinicValues(event, event.target.name, event.target.checked)
    }


    const handleSelectGroup = (selectedGroup, name) => {
        // console.log("selectedGroup ", selectedGroup)
        let updatedValue = { [name]: selectedGroup };
        setPatient(patientValues => ({
            ...patientValues,
            ...updatedValue
        }));
    }

    const updateClinicValues = (e, name, val) => {
        e.preventDefault()
        let updatedValue = {};
        updatedValue = { [name]: val };
        setPatient(patientValues => ({
            ...patientValues,
            ...updatedValue
        }));
    }


    const addInput = (labelname) => {
        let updatedValue = {};

        if (arr[labelname]) {
            updatedValue[labelname] = arr[labelname]

            let keylength = Object.keys(arr[labelname]).length
            updatedValue[labelname][labelname + "_input_" + (keylength + 1)] = true
        } else {
            updatedValue[labelname] = { [labelname + "_input"]: true }
        }
        setArr(arr => ({
            ...arr,
            ...updatedValue
        }));
    };



    const selectCheckbox = (e, labelname) => {
        // console.log(e.target.name, e.target.checked)
        let name = e.target.name;
        if (e.target.checked) {
            let val = e.target.value;
            let updatedValue = {};
            if (patientValues[labelname]) {
                updatedValue[labelname] = patientValues[labelname]
                updatedValue[labelname][name] = val;
            } else {
                updatedValue[labelname] = { [name]: val };
            }

            setPatient(patientValues => ({
                ...patientValues,
                ...updatedValue
            }));
        } else {
            delete patientValues[labelname][name]
            setPatient(patientValues)
        }
    }

    const updateCheckbox = (e) => {
        // console.log(e.target.name, e.target.checked)
        if (e.target.checked) {
            let val = e.target.value;
            let name = e.target.name;
            let updatedValue = {};
            updatedValue = { [name]: val };
            setPatient(patientValues => ({
                ...patientValues,
                ...updatedValue
            }));
        } else {
            let name = e.target.name;
            delete patientValues[name]
            setPatient(patientValues)
        }
    }
    const submitUpdate = (e) => {
        const { teamupdatelist } = props
        let clinicPatient = JSON.parse(localStorage.getItem("p"))
        let id = clinicPatient.patientId
        let bodyObj = {
            obj: patientValues,
            query: { "patientId": id },
            method: "Update"
        }
        teamupdatelist(bodyObj, "trauma")
        // setsuccess_msg(!success_msg)

    }


    return (
        <React.Fragment>

            <MetaTags>
                <title>TRAUMA CALL</title>
            </MetaTags>
            <div className='ward '>
                <div className="main n_office oncall">
                    <div className="wrapper">
                        <Menus menuName={"TRAUMA CALL"} />



                        <div className="tc_btns ">
                            <div className="tc_btns_wrp">
                                <a href="#prearrival" className="icon_btn">PRE ARRIVAL </a>
                                <a href="#primarysurvey" className="icon_btn">PRIMARY SURVEY </a>
                                <a href="#secondarysurvey" className="icon_btn">SECONDARY SURVEY </a>
                                <a href="#transfer" className="icon_btn">TRANSFER </a>
                                <a href="#vitals" className="icon_btn">VITALS </a>
                                <a href="#medicationgiven" className="icon_btn">MEDICATIONS </a>
                                <a href="#infusions" className="icon_btn">EVENT LOG </a>
                                <a href="#notes" className="icon_btn">NOTES </a>
                            </div>
                        </div>
                        <div className="section ctr pua" id="prearrival">
                            <h2 className="popup_title">PRE ARRIVAL </h2>
                            <div className="row">
                                <div className="col-md-5">
                                    <div className="form_group">
                                        <label className="label">Time of Trauma Team Activation</label>
                                        <input type="time" onChange={(e) => updateInputValue(e)} name="trauma_time" className="form-control" placeholder="Enter" />

                                    </div>
                                </div>
                                <div className="col-md-5">
                                    <div className="form_group">
                                        <label className="label">Not activated</label>
                                        <input type="time" onChange={(e) => updateInputValue(e)} name="trauma_notactivated_time" className="form-control" placeholder="Enter" />

                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form_group">
                                        <label className="label">NAME</label>
                                        <input type="text" onChange={(e) => updateInputValue(e)} name="trauma_name" className="form-control" placeholder="Enter" />

                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form_group">
                                        <label className="label">AGE </label>
                                        <input type="text" onChange={(e) => updateInputValue(e)} name="trauma_age" className="form-control" placeholder="Enter" />

                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form_group">
                                        <label className="label"> Height</label>
                                        <input type="text" onChange={(e) => updateInputValue(e)} name="trauma_height" className="form-control" placeholder="Enter" />

                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form_group">
                                        <label className="label"> weight </label>
                                        <input type="text" onChange={(e) => updateInputValue(e)} name="trauma_weight" className="form-control" placeholder="Enter" />

                                    </div>
                                </div>
                            </div>
                            <div className="sub_section mt-20">
                                <p className="sub_title mb-10">PRE ARRIVAL (15 MIN BEFORE) </p>
                                <button type="button" className="update_btn mt-10 mb-20" data-toggle="modal"
                                    data-target="#assembled">Assembled all team members </button>
                                <p className="field_title">Time Checklist </p>

                                <div className="check_list w_white mb-0">
                                    <span className="check_btn2" onClick={(e) => updateClinicValues(e, "time_checklist_flag", true)}><span className="material-icons"> done </span></span>
                                </div>

                                <div className="poc poc1 pt-0">

                                    {patientValues.time_checklist_flag && patientValues.time_checklist && Object.keys(patientValues.time_checklist).length > 0 ? <div className="check_list w_white mb-0">

                                        {Object.keys(patientValues.time_checklist).map((item, i) => (
                                            <div className="check_item active" key={i} onClick={(e) => updateClinicValues(e, "time_checklist_flag", false)} ><label className="check">{patientValues.time_checklist[item]}</label></div>
                                        ))}

                                    </div> : <>

                                        <div className="check-box">
                                            <div className="round">
                                                <input type="checkbox" onClick={(e) => selectCheckbox(e, "time_checklist")} name="time_checklist_introduce" value="Introductions, Role allocations, Team brief carried out" id="time_checklist_introduce" defaultChecked={patientValues && patientValues.time_checklist && patientValues.time_checklist["time_checklist_introduce"] ? true : false} />
                                                <label for="time_checklist_introduce"></label>
                                            </div>
                                            Introductions, Role allocations, Team brief carried out
                                        </div>
                                        <div className="check-box">
                                            <div className="round">
                                                <input type="checkbox" onClick={(e) => selectCheckbox(e, "time_checklist")} name="time_checklist_eq" value="Equipment and Drugs checked" id="time_checklist_eq" defaultChecked={patientValues && patientValues.time_checklist && patientValues.time_checklist["time_checklist_eq"] ? true : false} />
                                                <label for="time_checklist_eq"></label>
                                            </div>
                                            Equipment and Drugs checked
                                        </div>
                                        <div className="check-box">
                                            <div className="round">
                                                <input type="checkbox" onClick={(e) => selectCheckbox(e, "time_checklist")} name="time_checklist_blood" value="Blood bank informed if a “shock pack” is required" id="time_checklist_blood" defaultChecked={patientValues && patientValues.time_checklist && patientValues.time_checklist["time_checklist_blood"] ? true : false} />
                                                <label for="time_checklist_blood"></label>
                                            </div>
                                            Blood bank informed if a “shock pack” is required
                                        </div>
                                        <div className="check-box">
                                            <div className="round">
                                                <input type="checkbox" onClick={(e) => selectCheckbox(e, "time_checklist")} name="time_checklist_operate" value="Operating theatre informed that patient with major trauma to arrive soon in the A&E" id="time_checklist_operate" defaultChecked={patientValues && patientValues.time_checklist && patientValues.time_checklist["time_checklist_operate"] ? true : false} />
                                                <label for="time_checklist_operate"></label>
                                            </div>
                                            Operating theatre informed that patient with major trauma to arrive soon in the A&E
                                        </div>

                                        <div className="check-box">
                                            <div className="round">
                                                <input type="checkbox" onClick={(e) => selectCheckbox(e, "time_checklist")} name="time_checklist_add" value="Additional consultants called to trauma bay, if required" id="time_checklist_add" defaultChecked={patientValues && patientValues.time_checklist && patientValues.time_checklist["time_checklist_add"] ? true : false} />
                                                <label for="time_checklist_add"></label>
                                            </div>
                                            Additional consultants called to trauma bay, if required
                                        </div>
                                    </>}


                                </div>
                                <p className="field_title mt-20">Checklist </p>
                                <div className="check_list w_white mb-0">
                                    <span className="check_btn2" onClick={(e) => updateClinicValues(e, "checklist_flag", true)}><span className="material-icons"> done </span></span>
                                </div>
                                <div className="poc poc1 pt-0">

                                    {patientValues.checklist_flag && patientValues.checklist && Object.keys(patientValues.checklist).length > 0 ? <div className="check_list w_white mb-0">

                                        {Object.keys(patientValues.checklist).map((item, i) => (
                                            <div className="check_item active" key={i} onClick={(e) => updateClinicValues(e, "checklist_flag", false)} ><label className="check">{patientValues.checklist[item]}</label></div>
                                        ))}

                                    </div> : <>

                                        <div className="check-box">
                                            <div className="round">
                                                <input type="checkbox" onClick={(e) => selectCheckbox(e, "checklist")} name="checklist_int" value="Introduction & Confirm Team Roles" id="checklist_int" defaultChecked={patientValues && patientValues.checklist && patientValues.checklist["checklist_int"] ? true : false} />
                                                <label for="checklist_int"></label>
                                            </div>
                                            Introduction & Confirm Team Roles
                                        </div>
                                        <div className="check-box">
                                            <div className="round">
                                                <input type="checkbox" onClick={(e) => selectCheckbox(e, "checklist")} name="checklist_team" value=" Brief Team on incoming patient" id="checklist_team" defaultChecked={patientValues && patientValues.checklist && patientValues.checklist["checklist_team"] ? true : false} />
                                                <label for="checklist_team"></label>
                                            </div>
                                            Brief Team on incoming patient
                                        </div>
                                        <div className="check-box">
                                            <div className="round">
                                                <input type="checkbox" onClick={(e) => selectCheckbox(e, "checklist")} name="checklist_weight" value="Estimate weight" id="checklist_weight" defaultChecked={patientValues && patientValues.checklist && patientValues.checklist["checklist_weight"] ? true : false} />
                                                <label for="checklist_weight"></label>
                                            </div>
                                            Estimate weight
                                        </div>
                                        <div className="check-box">
                                            <div className="round">
                                                <input type="checkbox" onClick={(e) => selectCheckbox(e, "checklist")} name="checklist_oxygen" value="Oxygen connect to NRS" id="checklist_oxygen" defaultChecked={patientValues && patientValues.checklist && patientValues.checklist["checklist_oxygen"] ? true : false} />
                                                <label for="checklist_oxygen"></label>
                                            </div>
                                            Oxygen connect to NRS
                                        </div>

                                        <div className="check-box">
                                            <div className="round">
                                                <input type="checkbox" onClick={(e) => selectCheckbox(e, "checklist")} name="checklist_suction" value="Suction hooked up" id="checklist_suction" defaultChecked={patientValues && patientValues.checklist && patientValues.checklist["checklist_suction"] ? true : false} />
                                                <label for="checklist_suction"></label>
                                            </div>
                                            Suction hooked up
                                        </div>
                                        <div className="check-box">
                                            <div className="round">
                                                <input type="checkbox" onClick={(e) => selectCheckbox(e, "checklist")} name="checklist_trauma" value="Trauma shears available" id="checklist_trauma" defaultChecked={patientValues && patientValues.checklist && patientValues.checklist["checklist_trauma"] ? true : false} />
                                                <label for="checklist_trauma"></label>
                                            </div>
                                            Trauma shears available
                                        </div>
                                        <div className="check-box">
                                            <div className="round">
                                                <input type="checkbox" onClick={(e) => selectCheckbox(e, "checklist")} name="checklist_bair" value="Bair hugger on bed" id="checklist_bair" defaultChecked={patientValues && patientValues.checklist && patientValues.checklist["checklist_bair"] ? true : false} />
                                                <label for="checklist_bair"></label>
                                            </div>
                                            Bair hugger on bed
                                        </div>
                                        <div className="check-box">
                                            <div className="round">
                                                <input type="checkbox" onClick={(e) => selectCheckbox(e, "checklist")} name="checklist_rapid" value="Rapid sequence intubation kit ready" id="checklist_rapid" defaultChecked={patientValues && patientValues.checklist && patientValues.checklist["checklist_rapid"] ? true : false} />
                                                <label for="checklist_rapid"></label>
                                            </div>
                                            Rapid sequence intubation kit ready
                                        </div>
                                        <div className="check-box">
                                            <div className="round">
                                                <input type="checkbox" onClick={(e) => selectCheckbox(e, "checklist")} name="checklist_intubation" value="Prepare intubation equipment" id="checklist_intubation" defaultChecked={patientValues && patientValues.checklist && patientValues.checklist["checklist_intubation"] ? true : false} />
                                                <label for="checklist_intubation"></label>
                                            </div>
                                            Prepare intubation equipment
                                        </div>

                                        <div className="check-box">
                                            <div className="round">
                                                <input type="checkbox" onClick={(e) => selectCheckbox(e, "checklist")} name="checklist_order" value="Order Uncrossmatched RBCs start this is" id="checklist_order" defaultChecked={patientValues && patientValues.checklist && patientValues.checklist["checklist_order"] ? true : false} />
                                                <label for="checklist_order"></label>
                                            </div>
                                            Order Uncrossmatched RBCs start this is
                                        </div>
                                        <div className="check-box">
                                            <div className="round">
                                                <input type="checkbox" onClick={(e) => selectCheckbox(e, "checklist")} name="checklist_cpr" value="CPR board in room or on bed" id="checklist_cpr" defaultChecked={patientValues && patientValues.checklist && patientValues.checklist["checklist_cpr"] ? true : false} />
                                                <label for="checklist_cpr"></label>
                                            </div>
                                            CPR board in room or on bed
                                        </div>
                                    </>}

                                </div>
                            </div>





                        </div>

                        <div className="section ctr pua" id="primarysurvey">
                            <h2 className="bg_title text_center mb-20 mt-30">PRIMARY SURVEY</h2>
                            <div className="row  align-items-center">
                                <div className="col-md-9">
                                    <h2 className="popup_title  mt-0 mb-0">PATIENT ARRIVAL </h2>
                                </div>
                                <div className="col-md-3">
                                    <div className="form_group mb-0">
                                        <label className="label">Time</label>
                                        <input type="time" onChange={(e) => updateInputValue(e)} name="trauma_patient_arrival" className="form-control" />

                                    </div>
                                </div>
                            </div>
                            <div className="sub_section">
                                <div className="row">
                                    <div className="col-md-4 mt-20">
                                        <div className="form_group mb-0">
                                            <label className="label">PATIENT ARRIVAL TIME</label>
                                            <input type="time" onChange={(e) => updateInputValue(e)} name="trauma_patient_arrival_time" className="form-control" />

                                        </div>
                                    </div>
                                    <div className="col-md-1"></div>
                                    <div className="col-md-7 mt-20">

                                        <div className="check_item mt-0"><label className="check">ARRIVED VIA
                                        </label>
                                            {patientValues.arrived_via ? <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input type="radio"
                                                    value={patientValues.arrived_via} checked /><span>{patientValues.arrived_via} </span></label>
                                                </div> {patientValues.arrived_via && <a onClick={(e) => updateClinicValues(e, "arrived_via", false)}><span className="material-symbols-outlined"> reply
                                                </span></a>}

                                            </div>
                                                :

                                                <div className="select_btns popup_select">
                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="arrived_via" type="radio"
                                                        value="Ambulance" /><span>Ambulance
                                                        </span></label></div>
                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="arrived_via" type="radio"
                                                        value="Helicopter" /><span>Helicopter
                                                        </span></label>
                                                    </div>
                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="arrived_via" type="radio"
                                                        value="Police" /><span>Police
                                                        </span></label>
                                                    </div>
                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="arrived_via" type="radio"
                                                        value="Self" /><span>Self
                                                        </span></label>
                                                    </div>
                                                </div>}


                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="sub_section">
                                <p className="sub_title">ASSESS FOR IMMEDIATE CONCERNS</p>
                                <p className="field_title mb_15">Confirm  </p>
                                <div className="check_list w_white mb-0">
                                    <span className="check_btn2" onClick={(e) => updateClinicValues(e, "assess_flag", true)}><span className="material-icons"> done </span></span>
                                </div>

                                <div className="poc poc1 pt-0">


                                    {patientValues.assess_flag && patientValues.assess && Object.keys(patientValues.assess).length > 0 ? <div className="check_list w_white mb-0">

                                        {Object.keys(patientValues.assess).map((item, i) => (
                                            <div className="check_item active" key={i} onClick={(e) => updateClinicValues(e, "assess_flag", false)} ><label className="check">{patientValues.assess[item]}</label></div>
                                        ))}

                                    </div> : <>

                                        {assess && assess.length > 0 && assess.map((item, i) => (

                                            <div className="check-box sqeare" key={i}>
                                                <div className="round">
                                                    <input type="checkbox" onClick={(e) => selectCheckbox(e, "assess")} name={item.name} value={item.value} id={item.name} defaultChecked={patientValues && patientValues.assess && patientValues.assess[item.name] ? true : false}
                                                    />
                                                    <label for={item.name}></label>
                                                </div>
                                                {item.value}
                                            </div>


                                        ))} </>}




                                </div>
                            </div>
                            <div className="sub_section">
                                <p className="sub_title mb-20">IF SAFE FOR HANDOVER FROM PRE-HOSPITAL TEAM IN SILENCE</p>
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="form_group">
                                            <label className="label">Age</label>
                                            <input type="text" onChange={(e) => updateInputValue(e)} name="trauma_safe_age"
                                                className="form-control" placeholder="Enter" />

                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form_group">
                                            <label className="label">Time of injury</label>
                                            <input type="time" onChange={(e) => updateInputValue(e)} name="trauma_time_if_injury"
                                                className="form-control" placeholder="Enter" />

                                        </div>
                                    </div>
                                    <div className="col-md-2"></div>
                                    <div className="col-md-12">
                                        <div className="sub_section sub_bg data">
                                            <p className="sub_title link mb-20" data-toggle="modal" data-target="#mechanism">
                                                Mechanism of injury <span className="material-symbols-outlined icon"> open_in_new
                                                </span> </p>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form_group">
                                                        <div className="field_text">ROAD TRAFFIC ACCIDENT </div>
                                                        <div className="result">Yes</div>
                                                    </div>
                                                    <div className="form_group">
                                                        <div className="field_text">Penetrating
                                                        </div>
                                                        <div className="result">GSW
                                                        </div>
                                                    </div>
                                                    <div className="form_group">
                                                        <div className="field_text">Thermal

                                                        </div>
                                                        <div className="result">Heat exposure

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form_group">
                                                        <div className="field_text">Fall</div>
                                                        <div className="result">Lorem Ipsum</div>
                                                    </div>
                                                    <div className="form_group">
                                                        <div className="field_text">Blunt
                                                        </div>
                                                        <div className="result">Crush
                                                        </div>
                                                    </div>
                                                    <div className="form_group">
                                                        <div className="field_text">Other

                                                        </div>
                                                        <div className="result">Near drowning

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                    <div className="col-md-6">
                                        <div className="form_group">
                                            <label className="label">Injuries sustained</label>
                                            <input type="text" onChange={(e) => updateInputValue(e)} name="trauma_sustained"
                                                className="form-control" placeholder="Enter" />

                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form_group">
                                            <label className="label">Signs and symptoms</label>
                                            <input type="text" onChange={(e) => updateInputValue(e)} name="trauma_signs"
                                                className="form-control" placeholder="Enter" />

                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <p className="sub_title link mb-20 mt-10" data-toggle="modal" data-target="#treatment">
                                            Treatment given
                                            so far <span className="material-symbols-outlined icon"> open_in_new </span> </p>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="section ctr pua">
                            <div className="row  align-items-center">
                                <div className="col-md-9">
                                    <h2 className="popup_title mt-0 mb-0">PRIMARY SURVEY AND PRELIMINARY INTERVENTIONS </h2>
                                </div>
                                <div className="col-md-3">
                                    <div className="form_group mb-0">
                                        <label className="label">Time</label>
                                        <input type="time" onChange={(e) => updateInputValue(e)} name="trauma_primary_time" className="form-control" />

                                    </div>
                                </div>
                            </div>
                            <div className="sub_section">
                                <p className="sub_title mt-20 mb-20">C CONTROL OF CATASTROPHIC BLEEDING </p>
                                <div className="check_item">
                                    <div className="select_btns popup_select mt-0">


                                        {patientValues.catastrophic_bandage ? <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input type="radio"
                                                value={patientValues.catastrophic_bandage} checked /><span>{patientValues.catastrophic_bandage} </span></label>
                                            </div> {patientValues.catastrophic_bandage && <a onClick={(e) => updateClinicValues(e, "catastrophic_bandage", false)}><span className="material-symbols-outlined"> reply
                                            </span></a>}

                                        </div>
                                            :

                                            <div className="select_btns mt-0">
                                                {catastrophic && catastrophic.length > 0 && catastrophic.map((item, i) => (
                                                    <div className="select_btn" key={i}><label><input onClick={(e) => updateInputValue(e)} name={item.name} type="radio"
                                                        value={item.value} /><span>{item.value} </span></label></div>
                                                ))}
                                            </div>
                                        }



                                    </div>
                                </div>
                                <div className="yn_switch mb-0 mt-10">
                                    <div className="label-container">
                                        <p>Haemostatic agents </p>
                                    </div>
                                    <label for="haemostatic" className="switch-container">
                                        <input hidden="" type="checkbox" name="haemostatic" onChange={(e) => switchChange(e)} className="switch_check" id="haemostatic" />
                                        <div className="switch-bg"></div>
                                        <div className="round-box"></div>
                                        <div className="switch-left">
                                            <span>YES</span>
                                        </div>
                                        <div className="switch-right">
                                            <span>NO</span>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div className="sub_section">
                                <p className="sub_title">A AIRWAY </p>
                                <div className="yn_switch mb-10 mt-10">
                                    <div className="label-container">
                                        <p>Confirm airway is protected </p>
                                    </div>
                                    <label for="confirmairway" className="switch-container">
                                        <input hidden="" type="checkbox" name="confirmairway" onChange={(e) => switchChange(e)} className="switch_check" id="confirmairway" />
                                        <div className="switch-bg"></div>
                                        <div className="round-box"></div>
                                        <div className="switch-left">
                                            <span>YES</span>
                                        </div>
                                        <div className="switch-right">
                                            <span>NO</span>
                                        </div>
                                    </label>
                                </div>
                                <div className="yn_switch mb-20 mt-10">
                                    <div className="label-container">
                                        <p>Confirm C-spine is immobilised properly (manually or with collar)</p>
                                    </div>
                                    <label for="confirm_cspine" className="switch-container">
                                        <input hidden="" type="checkbox" name="confirm_cspine" onChange={(e) => switchChange(e)} className="switch_check" id="confirm_cspine" />
                                        <div className="switch-bg"></div>
                                        <div className="round-box"></div>
                                        <div className="switch-left">
                                            <span>YES</span>
                                        </div>
                                        <div className="switch-right">
                                            <span>NO</span>
                                        </div>
                                    </label>
                                </div>
                                <div className="check-box sqeare ">
                                    <div className="round">
                                        <input type="checkbox" onClick={(e) => selectCheckbox(e, "airway_patient")} name="airway_patient_talk" value="Airway Patent - Patient talking" id="airway_patient_talk" defaultChecked={patientValues && patientValues.airway_patient && patientValues.airway_patient["airway_patient_talk"] ? true : false} />
                                        <label for="airway_patient_talk"></label>
                                    </div>
                                    Airway Patent - Patient talking
                                </div>
                                <div className="check-box sqeare">
                                    <div className="round">
                                        <input type="checkbox" onClick={(e) => selectCheckbox(e, "airway_patient")} name="airway_patient_obv" value="Partially obstructed / Completely obstructed with blood / vomit / stridor" id="airway_patient_obv" defaultChecked={patientValues && patientValues.airway_patient && patientValues.airway_patient["airway_patient_obv"] ? true : false} />
                                        <label for="airway_patient_obv"></label>
                                    </div>
                                    Partially obstructed / Completely obstructed with blood / vomit / stridor
                                </div>

                                <div className="check_item">
                                    {patientValues.jaw ? <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input type="radio"
                                            value={patientValues.jaw} checked /><span>{patientValues.jaw} </span></label>
                                        </div> {patientValues.jaw && <a onClick={(e) => updateClinicValues(e, "jaw", false)}><span className="material-symbols-outlined"> reply
                                        </span></a>}

                                    </div>
                                        :

                                        <div className="select_btns popup_select mt-0">
                                            {jawList && jawList.length > 0 && jawList.map((item, i) => (
                                                <div className="select_btn" key={i}><label><input onClick={(e) => updateInputValue(e)} name={item.name} type="radio"
                                                    value={item.value} /><span>{item.value} </span></label></div>
                                            ))}
                                        </div>
                                    }

                                </div>
                                <div className="check_item">

                                    {patientValues.oral ? <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input type="radio"
                                            value={patientValues.oral} checked /><span>{patientValues.oral} </span></label>
                                        </div> {patientValues.oral && <a onClick={(e) => updateClinicValues(e, "oral", false)}><span className="material-symbols-outlined"> reply
                                        </span></a>}

                                    </div>
                                        :

                                        <div className="select_btns popup_select mt-0">
                                            {oralList && oralList.length > 0 && oralList.map((item, i) => (
                                                <div className="select_btn" key={i}><label><input onClick={(e) => updateInputValue(e)} name={item.name} type="radio"
                                                    value={item.value} /><span>{item.value} </span></label></div>
                                            ))}
                                        </div>
                                    }




                                </div>
                                <div className="check_item">

                                    {patientValues.objremoval ? <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input type="radio"
                                            value={patientValues.objremoval} checked /><span>{patientValues.objremoval} </span></label>
                                        </div> {patientValues.objremoval && <a onClick={(e) => updateClinicValues(e, "objremoval", false)}><span className="material-symbols-outlined"> reply
                                        </span></a>}

                                    </div>
                                        :

                                        <div className="select_btns popup_select mt-0">
                                            {objremovalList && objremovalList.length > 0 && objremovalList.map((item, i) => (
                                                <div className="select_btn" key={i}><label><input onClick={(e) => updateInputValue(e)} name={item.name} type="radio"
                                                    value={item.value} /><span>{item.value} </span></label></div>
                                            ))}
                                        </div>
                                    }



                                </div>


                                <div className="check-box sqeare mt-30">
                                    <div className="round">
                                        <input type="checkbox" onClick={(e) => selectCheckbox(e, "airway_patient")} name="airway_patient_act" value="Actual or impending airway compromise: Rapid Sequence Intubation with cervical spinal control" id="airway_patient_act" defaultChecked={patientValues && patientValues.airway_patient && patientValues.airway_patient["airway_patient_act"] ? true : false} />
                                        <label for="airway_patient_act"></label>
                                    </div>
                                    Actual or impending airway compromise: Rapid Sequence Intubation with cervical spinal
                                    control
                                </div>
                                <p className="field_title mt-10 mb-0">If intubating </p>
                                <p className="field_title mb-0 mt-5">GCS assessed before Rapid Sequence Intubation Medication</p>
                                <p className="field_title mb-0 mt-5"> Report ET tube size and depth</p>
                                <p className="field_title mb-0 mt-5"> Confirm ETCO2 reading on monitor</p>
                                <p className="field_title mb-0 mt-5">Order chest x-ray for placement confirmation</p>


                                <div className="check-box sqeare mt-30">
                                    <div className="round">
                                        <input type="checkbox" onClick={(e) => selectCheckbox(e, "airway_patient")} name="airway_patient_tra" value="Tracheostomy" id="airway_patient_tra" defaultChecked={patientValues && patientValues.airway_patient && patientValues.airway_patient["airway_patient_tra"] ? true : false} />
                                        <label for="airway_patient_tra"></label>
                                    </div>
                                    Tracheostomy
                                </div>
                                <div className="check-box sqeare">
                                    <div className="round">
                                        <input type="checkbox" onClick={(e) => selectCheckbox(e, "airway_patient")} name="airway_patient_cri" value="Cricothyroidotomy" id="airway_patient_cri" defaultChecked={patientValues && patientValues.airway_patient && patientValues.airway_patient["airway_patient_cri"] ? true : false} />
                                        <label for="airway_patient_cri"></label>
                                    </div>
                                    Cricothyroidotomy
                                </div>

                            </div>


                            <div className="sub_section">
                                <p className="sub_title">B BREATHING </p>
                                <div className="row mt-10">
                                    <div className="col-md-4">
                                        <div className="form_group input_style">
                                            <label className="label">Respiratory rate:</label>
                                            <div className="measure tqt">
                                                <input type="text" onChange={(e) => updateInputValue(e)} name="trauma_breathing" className="field" placeholder="Enter " />
                                                <span>min</span>
                                            </div>
                                        </div>
                                    </div>

                                </div>


                                <div className="yn_switch mb-20 mt-10">
                                    <div className="label-container">
                                        <p>O2Sats (On % O2/On Air)</p>
                                    </div>
                                    <label for="trauma_sats" className="switch-container">
                                        <input hidden="" type="checkbox" name="trauma_sats" onChange={(e) => switchChange(e)} className="switch_check" id="trauma_sats" />
                                        <div className="switch-bg"></div>
                                        <div className="round-box"></div>
                                        <div className="switch-left">
                                            <span>YES</span>
                                        </div>
                                        <div className="switch-right">
                                            <span>NO</span>
                                        </div>
                                    </label>
                                </div>
                                <div className="check_item mt-10">
                                    {patientValues.breath ? <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input type="radio"
                                            value={patientValues.breath} checked /><span>{patientValues.breath} </span></label>
                                        </div> {patientValues.breath && <a onClick={(e) => updateClinicValues(e, "breath", false)}><span className="material-symbols-outlined"> reply
                                        </span></a>}

                                    </div>
                                        :

                                        <div className="select_btns popup_select mt-0">
                                            {breathList && breathList.length > 0 && breathList.map((item, i) => (
                                                <div className="select_btn" key={i}><label><input onClick={(e) => updateInputValue(e)} name={item.name} type="radio"
                                                    value={item.value} /><span>{item.value} </span></label></div>
                                            ))}
                                        </div>
                                    }
                                </div>

                                <p className="field_title mb-0 mt-5"> Trachea: Midline Deviated R L</p>
                                <p className="field_title mb-0 mt-5"> Chest wall: Symmetrical Asymmetrical</p>
                                <p className="field_title mb-0 mt-5"> Lung sounds: R/L Clear Rales Rhonchi/Wheezes Decreased Absent
                                </p>

                                <p className="field_title mb-0 mt-20">If there is Ventilatory failure: </p>
                                <p className="field_title mb-0 mt-5">Rapid Sequence Intubation and
                                    Consider need for Chest decompression: Needle versus Thoracostomy versus Chest drain
                                    insertion
                                </p>

                                <div className="check-box sqeare mt-10">
                                    <div className="round">
                                        <input type="checkbox" onClick={(e) => selectCheckbox(e, "supplemental")} name="supplemental02" value="Supplemental O2" id="supplemental02" defaultChecked={patientValues && patientValues.supplemental && patientValues.supplemental["supplemental02"] ? true : false} />
                                        <label for="supplemental02"></label>
                                    </div>
                                    Supplemental O2
                                </div>
                                <div className="check-box sqeare">
                                    <div className="round">
                                        <input type="checkbox" onClick={(e) => selectCheckbox(e, "mask")} name="mask_check" value="Mask" id="mask_check" defaultChecked={patientValues && patientValues.mask && patientValues.mask["mask_check"] ? true : false} />
                                        <label for="mask_check"></label>
                                    </div>
                                    Mask
                                </div>
                                <div className="row mt-10">
                                    <div className="col-md-3">
                                        <div className="form_group input_style">
                                            <label className="label">Nasal Cannula</label>
                                            <div className="measure tqt">
                                                <input type="text" onChange={(e) => updateInputValue(e)} name="nasal_cannula" className="field" placeholder="Enter " />
                                                <span>l/m</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-7">
                                        <div className="check_item mt-0"><label className="check">ARRIVED VIA
                                        </label>
                                            {patientValues.arrivednasal ? <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input type="radio"
                                                    value={patientValues.arrivednasal} checked /><span>{patientValues.arrivednasal} </span></label>
                                                </div> {patientValues.arrivednasal && <a onClick={(e) => updateClinicValues(e, "arrivednasal", false)}><span className="material-symbols-outlined"> reply
                                                </span></a>}

                                            </div>
                                                :

                                                <div className="select_btns popup_select mt-0">
                                                    {arrivedList && arrivedList.length > 0 && arrivedList.map((item, i) => (
                                                        <div className="select_btn" key={i}><label><input onClick={(e) => updateInputValue(e)} name={item.name} type="radio"
                                                            value={item.value} /><span>{item.value} </span></label></div>
                                                    ))}
                                                </div>
                                            }

                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="form_group mb-0">
                                            <label className="label">Vent. Rate</label>
                                            <input type="text" onChange={(e) => updateInputValue(e)} name="vent" className="form-control" />

                                        </div>
                                    </div>
                                </div>



                            </div>
                            <div className="sub_section">
                                <div className="yn_switch mb-20 mt-10">
                                    <div className="label-container">
                                        <p>Check distal pulses and then central if needed</p>
                                    </div>
                                    <label for="distal" className="switch-container">
                                        <input hidden="" type="checkbox" name="distal" onChange={(e) => switchChange(e)} className="switch_check" id="distal" />
                                        <div className="switch-bg"></div>
                                        <div className="round-box"></div>
                                        <div className="switch-left">
                                            <span>YES</span>
                                        </div>
                                        <div className="switch-right">
                                            <span>NO</span>
                                        </div>
                                    </label>
                                </div>
                                <div className="check_item mt-0"><label className="check">Pulses Present
                                </label>


                                    {patientValues.pulsesPresent ? <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input type="radio"
                                            value={patientValues.pulsesPresent} checked /><span>{patientValues.pulsesPresent} </span></label>
                                        </div> {patientValues.pulsesPresent && <a onClick={(e) => updateClinicValues(e, "pulsesPresent", false)}><span className="material-symbols-outlined"> reply
                                        </span></a>}

                                    </div>
                                        :

                                        <div className="select_btns popup_select mt-0">
                                            {pulsesList && pulsesList.length > 0 && pulsesList.map((item, i) => (
                                                <div className="select_btn" key={i}><label><input onClick={(e) => updateInputValue(e)} name={item.name} type="radio"
                                                    value={item.value} /><span>{item.value} </span></label></div>
                                            ))}
                                        </div>
                                    }


                                </div>
                                <div className="yn_switch mb-20 mt-10">
                                    <div className="label-container">
                                        <p>Jugular Vein Distension</p>
                                    </div>
                                    <label for="jugular" className="switch-container">
                                        <input hidden="" type="checkbox" name="jugular" onChange={(e) => switchChange(e)} className="switch_check" id="jugular" />
                                        <div className="switch-bg"></div>
                                        <div className="round-box"></div>
                                        <div className="switch-left">
                                            <span>YES</span>
                                        </div>
                                        <div className="switch-right">
                                            <span>NO</span>
                                        </div>
                                    </label>
                                </div>
                                <div className="check_item mt-0"><label className="check">Capillary Refill
                                </label>
                                    {patientValues.capillary ? <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input type="radio"
                                            value={patientValues.capillary} checked /><span>{patientValues.capillary} </span></label>
                                        </div> {patientValues.capillary && <a onClick={(e) => updateClinicValues(e, "capillary", false)}><span className="material-symbols-outlined"> reply
                                        </span></a>}

                                    </div>
                                        :

                                        <div className="select_btns popup_select mt-0">
                                            {capillaryList && capillaryList.length > 0 && capillaryList.map((item, i) => (
                                                <div className="select_btn" key={i}><label><input onClick={(e) => updateInputValue(e)} name={item.name} type="radio"
                                                    value={item.value} /><span>{item.value} </span></label></div>
                                            ))}
                                        </div>
                                    }
                                </div>
                                <div className="check_item mt-10">
                                    {patientValues.ioCheck ? <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input type="radio"
                                            value={patientValues.ioCheck} checked /><span>{patientValues.ioCheck} </span></label>
                                        </div> {patientValues.ioCheck && <a onClick={(e) => updateClinicValues(e, "ioCheck", false)}><span className="material-symbols-outlined"> reply
                                        </span></a>}

                                    </div>
                                        :

                                        <div className="select_btns popup_select mt-0">
                                            {ioList && ioList.length > 0 && ioList.map((item, i) => (
                                                <div className="select_btn" key={i}><label><input onClick={(e) => updateInputValue(e)} name={item.name} type="radio"
                                                    value={item.value} /><span>{item.value} </span></label></div>
                                            ))}
                                        </div>
                                    }
                                </div>
                                <div className="check_item mt-0">
                                    {patientValues.fluidCheck ? <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input type="radio"
                                            value={patientValues.fluidCheck} checked /><span>{patientValues.fluidCheck} </span></label>
                                        </div> {patientValues.fluidCheck && <a onClick={(e) => updateClinicValues(e, "fluidCheck", false)}><span className="material-symbols-outlined"> reply
                                        </span></a>}

                                    </div>
                                        :

                                        <div className="select_btns popup_select mt-0">
                                            {fluidList && fluidList.length > 0 && fluidList.map((item, i) => (
                                                <div className="select_btn" key={i}><label><input onClick={(e) => updateInputValue(e)} name={item.name} type="radio"
                                                    value={item.value} /><span>{item.value} </span></label></div>
                                            ))}
                                        </div>
                                    }
                                </div>
                                <div className="yn_switch mb-20 mt-10">
                                    <div className="label-container">
                                        <p>Assessed for shock</p>
                                    </div>
                                    <label for="assessed" className="switch-container">
                                        <input hidden="" type="checkbox" name="assessed" onChange={(e) => switchChange(e)} className="switch_check" id="assessed" />
                                        <div className="switch-bg"></div>
                                        <div className="round-box"></div>
                                        <div className="switch-left">
                                            <span>YES</span>
                                        </div>
                                        <div className="switch-right">
                                            <span>NO</span>
                                        </div>
                                    </label>
                                </div>
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="form_group mb-0">
                                            <label className="label">BP</label>
                                            <input type="text" onChange={(e) => updateInputValue(e)} name="trauma_bp" className="form-control" />

                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form_group mb-0">
                                            <label className="label">Temperature</label>
                                            <input type="text" onChange={(e) => updateInputValue(e)} name="trauma_temperature" className="form-control" />

                                        </div>
                                    </div>
                                </div>
                                <div className="check_item mt-20"><label className="check">Bleeding
                                </label>
                                    {patientValues.bleedingCheck ? <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input type="radio"
                                            value={patientValues.bleedingCheck} checked /><span>{patientValues.bleedingCheck} </span></label>
                                        </div> {patientValues.bleedingCheck && <a onClick={(e) => updateClinicValues(e, "bleedingCheck", false)}><span className="material-symbols-outlined"> reply
                                        </span></a>}

                                    </div>
                                        :

                                        <div className="select_btns popup_select mt-0">
                                            {bleedingList && bleedingList.length > 0 && bleedingList.map((item, i) => (
                                                <div className="select_btn" key={i}><label><input onClick={(e) => updateInputValue(e)} name={item.name} type="radio"
                                                    value={item.value} /><span>{item.value} </span></label></div>
                                            ))}
                                        </div>
                                    }
                                </div>
                                <div className="check_item mt-0"><label className="check">Bleeding control
                                </label>
                                    {patientValues.bleedingControlCheck ? <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input type="radio"
                                            value={patientValues.bleedingControlCheck} checked /><span>{patientValues.bleedingControlCheck} </span></label>
                                        </div> {patientValues.bleedingControlCheck && <a onClick={(e) => updateClinicValues(e, "bleedingControlCheck", false)}><span className="material-symbols-outlined"> reply
                                        </span></a>}

                                    </div>
                                        :

                                        <div className="select_btns popup_select mt-0">
                                            {bleedingControlList && bleedingControlList.length > 0 && bleedingControlList.map((item, i) => (
                                                <div className="select_btn" key={i}><label><input onClick={(e) => updateInputValue(e)} name={item.name} type="radio"
                                                    value={item.value} /><span>{item.value} </span></label></div>
                                            ))}
                                        </div>
                                    }
                                </div>
                                <div className="check_item mt-0"><label className="check">Skin Colour
                                </label>
                                    {patientValues.skinCheck ? <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input type="radio"
                                            value={patientValues.skinCheck} checked /><span>{patientValues.skinCheck} </span></label>
                                        </div> {patientValues.skinCheck && <a onClick={(e) => updateClinicValues(e, "skinCheck", false)}><span className="material-symbols-outlined"> reply
                                        </span></a>}

                                    </div>
                                        :

                                        <div className="select_btns popup_select mt-0">
                                            {skinList && skinList.length > 0 && skinList.map((item, i) => (
                                                <div className="select_btn" key={i}><label><input onClick={(e) => updateInputValue(e)} name={item.name} type="radio"
                                                    value={item.value} /><span>{item.value} </span></label></div>
                                            ))}
                                        </div>
                                    }
                                </div>
                                <div className="row mt-10 dsp-flex align-items-center">
                                    <div className="col-md-4">
                                        <div className="yn_switch mb-0 mt-20">
                                            <div className="label-container">
                                                <p>Warm IV fluids</p>
                                            </div>
                                            <label for="warm_tv" className="switch-container">
                                                <input hidden="" type="checkbox" name="warm_tv" onChange={(e) => switchChange(e)} className="switch_check" id="warm_tv" />
                                                <div className="switch-bg"></div>
                                                <div className="round-box"></div>
                                                <div className="switch-left">
                                                    <span>YES</span>
                                                </div>
                                                <div className="switch-right">
                                                    <span>NO</span>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form_group mb-0">
                                            <label className="label">Time</label>
                                            <input type="text" onChange={(e) => updateInputValue(e)} name="warm_time" className="form-control" />

                                        </div>
                                    </div>
                                </div>
                                <div className="yn_switch mb-0 mt-20">
                                    <div className="label-container">
                                        <p>Warm blankets</p>
                                    </div>
                                    <label for="blanket" className="switch-container">
                                        <input hidden="" type="checkbox" name="blanket" onChange={(e) => switchChange(e)} className="switch_check" id="blanket" />
                                        <div className="switch-bg"></div>
                                        <div className="round-box"></div>
                                        <div className="switch-left">
                                            <span>YES</span>
                                        </div>
                                        <div className="switch-right">
                                            <span>NO</span>
                                        </div>
                                    </label>
                                </div>
                                <div className="check_item mt-30"><label className="check">Assessed for pelvic fracture by
                                </label>
                                    {patientValues.pelvicCheck ? <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input type="radio"
                                            value={patientValues.pelvicCheck} checked /><span>{patientValues.pelvicCheck} </span></label>
                                        </div> {patientValues.pelvicCheck && <a onClick={(e) => updateClinicValues(e, "pelvicCheck", false)}><span className="material-symbols-outlined"> reply
                                        </span></a>}

                                    </div>
                                        :

                                        <div className="select_btns popup_select mt-0">
                                            {pelvicList && pelvicList.length > 0 && pelvicList.map((item, i) => (
                                                <div className="select_btn" key={i}><label><input onClick={(e) => updateInputValue(e)} name={item.name} type="radio"
                                                    value={item.value} /><span>{item.value} </span></label></div>
                                            ))}
                                        </div>
                                    }
                                </div>

                                <div className="check_item">
                                    {patientValues.fractureCheck ? <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input type="radio"
                                            value={patientValues.fractureCheck} checked /><span>{patientValues.fractureCheck} </span></label>
                                        </div> {patientValues.fractureCheck && <a onClick={(e) => updateClinicValues(e, "fractureCheck", false)}><span className="material-symbols-outlined"> reply
                                        </span></a>}

                                    </div>
                                        :

                                        <div className="select_btns popup_select mt-0">
                                            {fractureList && fractureList.length > 0 && fractureList.map((item, i) => (
                                                <div className="select_btn" key={i}><label><input onClick={(e) => updateInputValue(e)} name={item.name} type="radio"
                                                    value={item.value} /><span>{item.value} </span></label></div>
                                            ))}
                                        </div>
                                    }
                                </div>
                                <div className="check_item mt-10">
                                    {patientValues.longCheck ? <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input type="radio"
                                            value={patientValues.longCheck} checked /><span>{patientValues.longCheck} </span></label>
                                        </div> {patientValues.longCheck && <a onClick={(e) => updateClinicValues(e, "longCheck", false)}><span className="material-symbols-outlined"> reply
                                        </span></a>}

                                    </div>
                                        :

                                        <div className="select_btns popup_select mt-0">
                                            {longList && longList.length > 0 && longList.map((item, i) => (
                                                <div className="select_btn" key={i}><label><input onClick={(e) => updateInputValue(e)} name={item.name} type="radio"
                                                    value={item.value} /><span>{item.value} </span></label></div>
                                            ))}
                                        </div>
                                    }

                                </div>
                                <div className="check_item mt-10"><label className="check">Assessed for pelvic fracture by
                                </label>
                                    {patientValues.assessedCheck ? <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input type="radio"
                                            value={patientValues.assessedCheck} checked /><span>{patientValues.assessedCheck} </span></label>
                                        </div> {patientValues.assessedCheck && <a onClick={(e) => updateClinicValues(e, "assessedCheck", false)}><span className="material-symbols-outlined"> reply
                                        </span></a>}

                                    </div>
                                        :

                                        <div className="select_btns popup_select mt-0">
                                            {assessedList && assessedList.length > 0 && assessedList.map((item, i) => (
                                                <div className="select_btn" key={i}><label><input onClick={(e) => updateInputValue(e)} name={item.name} type="radio"
                                                    value={item.value} /><span>{item.value} </span></label></div>
                                            ))}
                                        </div>
                                    }
                                </div>
                                <div className="check_item mt-0">
                                    {patientValues.bleedCheck ? <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input type="radio"
                                            value={patientValues.bleedCheck} checked /><span>{patientValues.bleedCheck} </span></label>
                                        </div> {patientValues.bleedCheck && <a onClick={(e) => updateClinicValues(e, "bleedCheck", false)}><span className="material-symbols-outlined"> reply
                                        </span></a>}

                                    </div>
                                        :

                                        <div className="select_btns popup_select mt-0">
                                            {bleedList && bleedList.length > 0 && bleedList.map((item, i) => (
                                                <div className="select_btn" key={i}><label><input onClick={(e) => updateInputValue(e)} name={item.name} type="radio"
                                                    value={item.value} /><span>{item.value} </span></label></div>
                                            ))}
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="sub_section">
                                <p className="sub_title">D DISABILITY </p>
                                <div className="row mt-20">
                                    <div className="col-md-12">
                                        <button type="button" className="update_btn mb-10" data-toggle="modal"
                                            data-target="#adultgcs">ADULT GCS</button>
                                    </div>
                                    <div className="col-md-12">
                                        <button type="button" className="update_btn mt-10 mb-10" data-toggle="modal"
                                            data-target="#PAEDIATRIC">PAEDIATRIC GCS</button>
                                    </div>
                                </div>
                                <div className="yn_switch mb-20 mt-20">
                                    <div className="label-container">
                                        <p>State GCS</p>
                                    </div>
                                    <label for="state_gcs" className="switch-container">
                                        <input hidden="" type="checkbox" name="state_gcs" onChange={(e) => switchChange(e)} className="switch_check" id="state_gcs" />
                                        <div className="switch-bg"></div>
                                        <div className="round-box"></div>
                                        <div className="switch-left">
                                            <span>YES</span>
                                        </div>
                                        <div className="switch-right">
                                            <span>NO</span>
                                        </div>
                                    </label>
                                </div>
                                <div className="yn_switch mb-20 mt-0">
                                    <div className="label-container">
                                        <p>State pupil size and response</p>
                                    </div>
                                    <label for="state_pupil" className="switch-container">
                                        <input hidden="" type="checkbox" name="state_pupil" onChange={(e) => switchChange(e)} className="switch_check" id="state_pupil" />
                                        <div className="switch-bg"></div>
                                        <div className="round-box"></div>
                                        <div className="switch-left">
                                            <span>YES</span>
                                        </div>
                                        <div className="switch-right">
                                            <span>NO</span>
                                        </div>
                                    </label>
                                </div>

                                <div className="check_item mt-10">
                                    <label className="check">Unconsciousness (GCS8 or less), unmanageable, combative or severely
                                        agitated patient with a </label>
                                    {patientValues.rapidCheck ? <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input type="radio"
                                            value={patientValues.rapidCheck} checked /><span>{patientValues.rapidCheck} </span></label>
                                        </div> {patientValues.rapidCheck && <a onClick={(e) => updateClinicValues(e, "rapidCheck", false)}><span className="material-symbols-outlined"> reply
                                        </span></a>}

                                    </div>
                                        :

                                        <div className="select_btns popup_select mt-0">
                                            {rapidList && rapidList.length > 0 && rapidList.map((item, i) => (
                                                <div className="select_btn" key={i}><label><input onClick={(e) => updateInputValue(e)} name={item.name} type="radio"
                                                    value={item.value} /><span>{item.value} </span></label></div>
                                            ))}
                                        </div>
                                    }
                                </div>

                            </div>
                            <div className="sub_section">
                                <p className="sub_title">E EXPOSURE </p>
                                <div className="yn_switch mb-20 mt-20">
                                    <div className="label-container">
                                        <p>Completely remove patient’s clothing</p>
                                    </div>
                                    <label for="exposure" className="switch-container">
                                        <input hidden="" type="checkbox" name="exposure" onChange={(e) => switchChange(e)} className="switch_check" id="exposure" />
                                        <div className="switch-bg"></div>
                                        <div className="round-box"></div>
                                        <div className="switch-left">
                                            <span>YES</span>
                                        </div>
                                        <div className="switch-right">
                                            <span>NO</span>
                                        </div>
                                    </label>
                                </div>
                                <div className="yn_switch mb-20 mt-0">
                                    <div className="label-container">
                                        <p>Cover patient with warm blanket</p>
                                    </div>
                                    <label for="coverPatient" className="switch-container">
                                        <input hidden="" type="checkbox" name="coverPatient" onChange={(e) => switchChange(e)} className="switch_check" id="coverPatient" />
                                        <div className="switch-bg"></div>
                                        <div className="round-box"></div>
                                        <div className="switch-left">
                                            <span>YES</span>
                                        </div>
                                        <div className="switch-right">
                                            <span>NO</span>
                                        </div>
                                    </label>
                                </div>
                                <div className="yn_switch mb-0 mt-0">
                                    <div className="label-container">
                                        <p>Take temperature</p>
                                    </div>
                                    <label for="tasktemperature" className="switch-container">
                                        <input hidden="" type="checkbox" name="tasktemperature" onChange={(e) => switchChange(e)} className="switch_check" id="tasktemperature" />
                                        <div className="switch-bg"></div>
                                        <div className="round-box"></div>
                                        <div className="switch-left">
                                            <span>YES</span>
                                        </div>
                                        <div className="switch-right">
                                            <span>NO</span>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div className="sub_section">
                                <p className="sub_title mb-20">Interventions</p>

                                <div className="check_list w_white mb-0"><span className="check_btn2" onClick={(e) => updateClinicValues(e, "interventions_flag", true)}><span className="material-icons"> done </span></span></div>

                                {patientValues.interventions_flag && patientValues.interventions && Object.keys(patientValues.interventions).length > 0 ? <div className="check_list w_white mb-0">

                                    {Object.keys(patientValues.interventions).map((item, i) => (
                                        <div className="check_item active" key={i} onClick={(e) => updateClinicValues(e, "interventions_flag", false)} ><label className="check">{patientValues.interventions[item]}</label></div>
                                    ))}

                                </div> : <>

                                    {interventions && interventions.length > 0 && interventions.map((item, i) => (

                                        <div className="check-box sqeare" key={i}>
                                            <div className="round">
                                                <input type="checkbox" onClick={(e) => selectCheckbox(e, "interventions")} name={item.name} value={item.value} id={item.name} defaultChecked={patientValues && patientValues.interventions && patientValues.interventions[item.name] ? true : false}
                                                />
                                                <label for={item.name}></label>
                                            </div>
                                            {item.value}
                                        </div>


                                    ))} </>}


                            </div>

                            <div className="sub_section pb-0 mb-0">
                                <p className="sub_title mb-20">Blood taken for </p>
                                <div className="check_list w_white mb-0"> <span className="check_btn2" onClick={(e) => updateClinicValues(e, "bloodtaken_flag", true)}><span className="material-icons"> done </span></span></div>

                                {patientValues.bloodtaken_flag && patientValues.bloodtaken && Object.keys(patientValues.bloodtaken).length > 0 ? <div className="check_list w_white mb-0">

                                    {Object.keys(patientValues.bloodtaken).map((item, i) => (
                                        <div className="check_item active" key={i} onClick={(e) => updateClinicValues(e, "bloodtaken_flag", false)} ><label className="check">{patientValues.bloodtaken[item]}</label></div>
                                    ))}

                                </div> : <>

                                    {bloodtaken && bloodtaken.length > 0 && bloodtaken.map((item, i) => (

                                        <div className="check-box sqeare" key={i}>
                                            <div className="round">
                                                <input type="checkbox" onClick={(e) => selectCheckbox(e, "bloodtaken")} name={item.name} value={item.value} id={item.name} defaultChecked={patientValues && patientValues.bloodtaken && patientValues.bloodtaken[item.name] ? true : false}
                                                />
                                                <label for={item.name}></label>
                                            </div>
                                            {item.value}
                                        </div>


                                    ))} </>}

                            </div>
                        </div>
                        <div className="section ctr pua">
                            <div className="row  align-items-center">
                                <div className="col-md-9">
                                    <h2 className="popup_title mt-0 mb-0">PAUSE & REVIEW (AFTER PRIMARY SURVEY) </h2>
                                </div>
                                <div className="col-md-3">
                                    <div className="form_group mb-0">
                                        <label className="label">Time</label>
                                        <input type="time" onChange={(e) => updateInputValue(e)} name="trauma_review_time" className="form-control" />

                                    </div>
                                </div>
                            </div>
                            <p className="field_title">MASSIVE HAEMORRHAGE</p>
                            <div className="yn_switch mb-20 mt-0">
                                <div className="label-container">
                                    <p>Massive haemorrhage controlled?</p>
                                </div>
                                <label for="massive" className="switch-container">
                                    <input hidden="" type="checkbox" name="massive" onChange={(e) => switchChange(e)} className="switch_check" id="massive" />
                                    <div className="switch-bg"></div>
                                    <div className="round-box"></div>
                                    <div className="switch-left">
                                        <span>YES</span>
                                    </div>
                                    <div className="switch-right">
                                        <span>NO</span>
                                    </div>
                                </label>
                            </div>

                            <p className="field_title">AIRWAY</p>
                            <div className="yn_switch mb-20 mt-0">
                                <div className="label-container">
                                    <p>Rapid sequence induction needed?</p>
                                </div>
                                <label for="rapid_induction" className="switch-container">
                                    <input hidden="" type="checkbox" name="rapid_induction" onChange={(e) => switchChange(e)} className="switch_check" id="rapid_induction" />
                                    <div className="switch-bg"></div>
                                    <div className="round-box"></div>
                                    <div className="switch-left">
                                        <span>YES</span>
                                    </div>
                                    <div className="switch-right">
                                        <span>NO</span>
                                    </div>
                                </label>
                            </div>

                            <p className="field_title">BREATHING</p>
                            <div className="yn_switch mb-10 mt-0">
                                <div className="label-container">
                                    <p>Chest decompression (thoracostomy or chest drain)?</p>
                                </div>
                                <label for="decompression" className="switch-container">
                                    <input hidden="" type="checkbox" name="decompression" onChange={(e) => switchChange(e)} className="switch_check" id="decompression" />
                                    <div className="switch-bg"></div>
                                    <div className="round-box"></div>
                                    <div className="switch-left">
                                        <span>YES</span>
                                    </div>
                                    <div className="switch-right">
                                        <span>NO</span>
                                    </div>
                                </label>
                            </div>
                            <div className="yn_switch mb-20 mt-0">
                                <div className="label-container">
                                    <p>Is it effective?</p>
                                </div>
                                <label for="effective" className="switch-container">
                                    <input hidden="" type="checkbox" name="effective" onChange={(e) => switchChange(e)} className="switch_check" id="effective" />
                                    <div className="switch-bg"></div>
                                    <div className="round-box"></div>
                                    <div className="switch-left">
                                        <span>YES</span>
                                    </div>
                                    <div className="switch-right">
                                        <span>NO</span>
                                    </div>
                                </label>
                            </div>

                            <p className="field_title">CIRCULATION</p>
                            <div className="yn_switch mb-10 mt-0">
                                <div className="label-container">
                                    <p>Massive transfusion? Immediate transfer to operating theatre?</p>
                                </div>
                                <label for="transfusion" className="switch-container">
                                    <input hidden="" type="checkbox" name="transfusion" onChange={(e) => switchChange(e)} className="switch_check" id="transfusion" />
                                    <div className="switch-bg"></div>
                                    <div className="round-box"></div>
                                    <div className="switch-left">
                                        <span>YES</span>
                                    </div>
                                    <div className="switch-right">
                                        <span>NO</span>
                                    </div>
                                </label>
                            </div>

                            <p className="field_title">REASSESS GCS</p>
                            <div className="yn_switch mb-10 mt-0">
                                <div className="label-container">
                                    <p>Current GCS</p>
                                </div>
                                <label for="currentgcs" className="switch-container">
                                    <input hidden="" type="checkbox" name="currentgcs" onChange={(e) => switchChange(e)} className="switch_check" id="currentgcs" />
                                    <div className="switch-bg"></div>
                                    <div className="round-box"></div>
                                    <div className="switch-left">
                                        <span>YES</span>
                                    </div>
                                    <div className="switch-right">
                                        <span>NO</span>
                                    </div>
                                </label>
                            </div>
                            <p className="field_title mb-0 mt-10">VITALS  </p>
                            <div className="check_list w_white mb-0"><span className="check_btn2" onClick={(e) => updateClinicValues(e, "vitals_flag", true)}><span className="material-icons"> done </span></span></div>

                            {patientValues.vitals_flag && patientValues.vitals && Object.keys(patientValues.vitals).length > 0 ? <div className="check_list w_white mb-0">

                                {Object.keys(patientValues.vitals).map((item, i) => (
                                    <div className="check_item active" key={i} onClick={(e) => updateClinicValues(e, "vitals_flag", false)} ><label className="check">{patientValues.vitals[item]}</label></div>
                                ))}

                            </div> : <>

                                {vitals && vitals.length > 0 && vitals.map((item, i) => (

                                    <div className="check-box sqeare" key={i}>
                                        <div className="round">
                                            <input type="checkbox" onClick={(e) => selectCheckbox(e, "vitals")} name={item.name} value={item.value} id={item.name} defaultChecked={patientValues && patientValues.vitals && patientValues.vitals[item.name] ? true : false}
                                            />
                                            <label for={item.name}></label>
                                        </div>
                                        {item.value}
                                    </div>


                                ))} </>}



                            <div className="sub_section mt-30">
                                <p className="sub_title mb-10">PAUSE  </p>

                                <div className="check_list w_white mb-10"><span className="check_btn2" onClick={(e) => updateClinicValues(e, "vitalspause_flag", true)}><span className="material-icons"> done </span></span></div>
                                {patientValues.vitalspause_flag && patientValues.vitalspause && Object.keys(patientValues.vitalspause).length > 0 ? <div className="check_list w_white mb-0">

                                    {Object.keys(patientValues.vitalspause).map((item, i) => (
                                        <div className="check_item active" key={i} onClick={(e) => updateClinicValues(e, "vitalspause_flag", false)} ><label className="check">{patientValues.vitalspause[item]}</label></div>
                                    ))}

                                </div> : <>

                                    {vitalspause && vitalspause.length > 0 && vitalspause.map((item, i) => (

                                        <div className="check-box sqeare" key={i}>
                                            <div className="round">
                                                <input type="checkbox" onClick={(e) => selectCheckbox(e, "vitalspause")} name={item.name} value={item.value} id={item.name} defaultChecked={patientValues && patientValues.vitalspause && patientValues.vitalspause[item.name] ? true : false}
                                                />
                                                <label for={item.name}></label>
                                            </div>
                                            {item.value}
                                        </div>


                                    ))} </>}
                            </div>
                        </div>

                        <div className="section ctr " id="secondarysurvey">
                            <h2 className="bg_title text_center mb-20 mt-30">SECONDARY SURVEY</h2>
                            <div className="row  align-items-center">
                                <div className="col-md-9">
                                    <h2 className="popup_title mt-0 mb-0">SITUATIONAL UPDATE BY TEAM LEAD <span className="sub"> (10 -
                                        15 MIN. AFTER ARRIVAL) </span> </h2>
                                </div>
                                <div className="col-md-3">
                                    <div className="form_group mb-0">
                                        <label className="label">Time</label>
                                        <input type="time" onChange={(e) => updateInputValue(e)} name="secondary_time" className="form-control" />

                                    </div>
                                </div>
                            </div>
                            <div className="check_list w_white mb-0"><span className="check_btn2" onClick={(e) => updateClinicValues(e, "secondary_flag", true)}><span className="material-icons"> done </span></span></div>

                            {patientValues.secondary_flag && patientValues.secondary && Object.keys(patientValues.secondary).length > 0 ? <div className="check_list w_white mb-0">

                                {Object.keys(patientValues.secondary).map((item, i) => (
                                    <div className="check_item active" key={i} onClick={(e) => updateClinicValues(e, "secondary_flag", false)} ><label className="check">{patientValues.secondary[item]}</label></div>
                                ))}

                            </div> : <>

                                {secondary && secondary.length > 0 && secondary.map((item, i) => (

                                    <div className="check-box sqeare" key={i}>
                                        <div className="round">
                                            <input type="checkbox" onClick={(e) => selectCheckbox(e, "secondary")} name={item.name} value={item.value} id={item.name} defaultChecked={patientValues && patientValues.secondary && patientValues.secondary[item.name] ? true : false}
                                            />
                                            <label for={item.name}></label>
                                        </div>
                                        {item.value}
                                    </div>


                                ))} </>}
                            <button type="button" className="update_btn mb-10" data-toggle="modal"
                                data-target="#secondary-survey">Secondary survey performed if patient not requiring
                                time-critical interventions</button>
                            <p className="field_title mb-0 mt-5">Consider splinting and apply dressings pre-transfer.</p>
                            <p className="field_title mb-10 mt-5">Secure for transfer (use a vacuum mattress).</p>
                            <ul>
                                <li> Computed tomography scan</li>
                                <li> Operating theatre – for surgery</li>
                                <li> Critical care – for monitoring</li>
                            </ul>

                        </div>
                        <div className="section ctr ">
                            <div className="row  align-items-center">
                                <div className="col-md-9">
                                    <h2 className="popup_title mt-0 mb-0">WHOLE TEAM HUDDLE <span className="sub"> ( 20-30 MIN. AFTER
                                        ARRIVAL) </span> </h2>
                                </div>
                                <div className="col-md-3">
                                    <div className="form_group mb-0">
                                        <label className="label">Time</label>
                                        <input type="time" onChange={(e) => updateInputValue(e)} name="whole_team_time" className="form-control" />

                                    </div>
                                </div>
                            </div>
                            <div className="sub_section">
                                <p className="sub_title mb-10">PAUSE  </p>

                                <div className="check_list w_white mb-0"><span className="check_btn2" onClick={(e) => updateClinicValues(e, "wholeTeam_flag", true)}><span className="material-icons"> done </span></span></div>

                                {patientValues.wholeTeam_flag && patientValues.wholeTeam && Object.keys(patientValues.wholeTeam).length > 0 ? <div className="check_list w_white mb-0">

                                    {Object.keys(patientValues.wholeTeam).map((item, i) => (
                                        <div className="check_item active" key={i} onClick={(e) => updateClinicValues(e, "wholeTeam_flag", false)} ><label className="check">{patientValues.wholeTeam[item]}</label></div>
                                    ))}

                                </div> : <>

                                    {wholeTeam && wholeTeam.length > 0 && wholeTeam.map((item, i) => (

                                        <div className="check-box sqeare" key={i}>
                                            <div className="round">
                                                <input type="checkbox" onClick={(e) => selectCheckbox(e, "wholeTeam")} name={item.name} value={item.value} id={item.name} defaultChecked={patientValues && patientValues.wholeTeam && patientValues.wholeTeam[item.name] ? true : false}
                                                />
                                                <label for={item.name}></label>
                                            </div>
                                            {item.value}
                                        </div>


                                    ))} </>}



                                <button type="button" className="update_btn mb-10">REVIEW DRUGS GIVEN </button>
                            </div>
                            <div className="sub_section mt-10" id="transfer">
                                <p className="sub_title">INFORM FAMILY OF THE SITUATION</p>
                                <div className="check_item mt-10">
                                    {patientValues.transferCheck ? <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input type="radio"
                                            value={patientValues.transferCheck} checked /><span>{patientValues.transferCheck} </span></label>
                                        </div> {patientValues.transferCheck && <a onClick={(e) => updateClinicValues(e, "transferCheck", false)}><span className="material-symbols-outlined"> reply
                                        </span></a>}

                                    </div>
                                        :

                                        <div className="select_btns popup_select mt-0">
                                            {transferList && transferList.length > 0 && transferList.map((item, i) => (
                                                <div className="select_btn" key={i}><label><input onClick={(e) => updateInputValue(e)} name={item.name} type="radio"
                                                    value={item.value} /><span>{item.value} </span></label></div>
                                            ))}
                                        </div>
                                    }
                                </div>

                                <div className="check_list w_white mb-0"><span className="check_btn2" onClick={(e) => updateClinicValues(e, "transferTeam_flag", true)}><span className="material-icons"> done </span></span></div>

                                {patientValues.transferTeam_flag && patientValues.transferTeam && Object.keys(patientValues.transferTeam).length > 0 ? <div className="check_list w_white mb-0">

                                    {Object.keys(patientValues.transferTeam).map((item, i) => (
                                        <div className="check_item active" key={i} onClick={(e) => updateClinicValues(e, "transferTeam_flag", false)} ><label className="check">{patientValues.transferTeam[item]}</label></div>
                                    ))}

                                </div> : <>

                                    {transferTeam && transferTeam.length > 0 && transferTeam.map((item, i) => (

                                        <div className="check-box sqeare" key={i}>
                                            <div className="round">
                                                <input type="checkbox" onClick={(e) => selectCheckbox(e, "transferTeam")} name={item.name} value={item.value} id={item.name} defaultChecked={patientValues && patientValues.transferTeam && patientValues.transferTeam[item.name] ? true : false}
                                                />
                                                <label for={item.name}></label>
                                            </div>
                                            {item.value}
                                        </div>


                                    ))} </>}


                                <button type="button" className="update_btn mb-10" data-toggle="modal" data-target="#PRE-DEPARTURE">
                                    PRE DEPARTURE CHECK LIST </button>

                            </div>
                            <div className=" sub_section  vitals" id="vitals">
                                <p className="sub_title mb-10">VITALS</p>
                                <table className="table table-bordered table_blue">
                                    <thead>
                                        <tr>
                                            <th>Time</th>
                                            <th>Temp oC</th>
                                            <th>PR/min</th>
                                            <th>RR/min</th>
                                            <th>BP/mmHG</th>
                                            <th>SaO2 %</th>
                                            <th>O2L/min</th>
                                            <th colspan="2" className="text_center">Pupil Reaction <br />
                                                Right /Left
                                            </th>
                                            <th>Pain Scale
                                                0-10/min
                                            </th>
                                            <th width="12%">Blood Glucose mg/dl </th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {vitalTable && vitalTable.length > 0 && vitalTable.map((item, i) => (
                                            <tr>
                                                <td>
                                                    <div className="form_group">
                                                        <input type="time" onChange={(e) => updateInputValue(e)} name={"vital_time_" + i} className="form-control" placeholder="Enter" />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="form_group">
                                                        <input type="text" onChange={(e) => updateInputValue(e)} name={"vital_TempoC_" + i} className="form-control" placeholder="Enter" />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="form_group">
                                                        <input type="text" onChange={(e) => updateInputValue(e)} name={"vital_PR_" + i} className="form-control" placeholder="Enter" />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="form_group">
                                                        <input type="text" onChange={(e) => updateInputValue(e)} name={"vital_RR_" + i} className="form-control" placeholder="Enter" />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="form_group">
                                                        <input type="text" onChange={(e) => updateInputValue(e)} name={"vital_BP_" + i} className="form-control" placeholder="Enter" />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="form_group">
                                                        <input type="text" onChange={(e) => updateInputValue(e)} name={"vital_SaO2_" + i} className="form-control" placeholder="Enter" />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="form_group">
                                                        <input type="text" onChange={(e) => updateInputValue(e)} name={"vital_O2L_" + i} className="form-control" placeholder="Enter" />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="form_group">
                                                        <input type="text" onChange={(e) => updateInputValue(e)} name={"vital_Pupil_Right_" + i} className="form-control" placeholder="Enter" />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="form_group">
                                                        <input type="text" onChange={(e) => updateInputValue(e)} name={"vital_Pupil_Left_" + i} className="form-control" placeholder="Enter" />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="form_group">
                                                        <input type="text" onChange={(e) => updateInputValue(e)} name={"vital_Pain_" + i} className="form-control" placeholder="Enter" />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="form_group">
                                                        <input type="text" onChange={(e) => updateInputValue(e)} name={"vital_Blood_" + i} className="form-control" placeholder="Enter" />
                                                    </div>
                                                </td>
                                            </tr>))}

                                    </tbody>
                                </table>
                                <p>PupilsS-slow U-unequal B-brisk D-dilated F-fixed = - Equal C-closed by swelling</p>
                            </div>
                            <div className=" sub_section" id="medicationgiven">
                                <p className="sub_title mt-20 mb-10">MEDICATIONS GIVEN</p>
                                <table className="table table-bordered table_blue">
                                    <thead>
                                        <tr>
                                            <th width="40%">Medication</th>
                                            <th width="15%">Dose</th>
                                            <th width="15%">Route </th>
                                            <th width="15%"> Time Given </th>
                                            <th width="15%">Initials</th>

                                        </tr>
                                    </thead>
                                    <tbody>

                                        {medicationTable && medicationTable.length > 0 && medicationTable.map((item, i) => (
                                            <tr>
                                                <td>
                                                    {item}
                                                </td>
                                                <td>
                                                    <div className="form_group">
                                                        <input type="text" onChange={(e) => updateInputValue(e)} name={"medication_dose_" + i} className="form-control" placeholder="Enter" />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="form_group">
                                                        <input type="text" onChange={(e) => updateInputValue(e)} name={"medication_route_" + i} className="form-control" placeholder="Enter" />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="form_group">
                                                        <input type="time" onChange={(e) => updateInputValue(e)} name={"medication_timegiven_" + i} className="form-control" placeholder="Enter" />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="form_group">
                                                        <input type="text" onChange={(e) => updateInputValue(e)} name={"medication_initial_" + i} className="form-control" placeholder="Enter" />
                                                    </div>
                                                </td>

                                            </tr>))}


                                    </tbody>
                                </table>

                            </div>
                            <div className=" sub_section" id="infusions">
                                <p className="sub_title mt-20 mb-10">INFUSIONS</p>
                                <table className="table table-bordered table_blue">
                                    <thead>
                                        <tr>
                                            <th width="25%"></th>
                                            <th width="15%">Amount Infused</th>
                                            <th width="15%">Dose </th>
                                            <th width="15%"> Route </th>
                                            <th width="15%">Time Given </th>
                                            <th width="15%">Initials </th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td colspan="6" className="text_center">
                                                <p className="field_title"> Fluids </p>

                                            </td>
                                        </tr>

                                        {fluidsTable && fluidsTable.length > 0 && fluidsTable.map((item, i) => (
                                            <tr>
                                                <td>
                                                    {item}
                                                </td>
                                                <td>
                                                    <div className="form_group">
                                                        <input type="text" onChange={(e) => updateInputValue(e)} name={"fluid_infused_" + i} className="form-control" placeholder="Enter" />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="form_group">
                                                        <input type="text" onChange={(e) => updateInputValue(e)} name={"fluid_dose_" + i} className="form-control" placeholder="Enter" />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="form_group">
                                                        <input type="text" onChange={(e) => updateInputValue(e)} name={"fluid_route_" + i} className="form-control" placeholder="Enter" />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="form_group">
                                                        <input type="time" onChange={(e) => updateInputValue(e)} name={"fluid_timegiven_" + i} className="form-control" placeholder="Enter" />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="form_group">
                                                        <input type="text" onChange={(e) => updateInputValue(e)} name={"fluid_initial_" + i} className="form-control" placeholder="Enter" />
                                                    </div>
                                                </td>

                                            </tr>

                                        ))}



                                        <tr>
                                            <td colspan="6" className="text_center">
                                                <p className="field_title"> Blood products </p>

                                            </td>
                                        </tr>

                                        {bloodProductsTable && bloodProductsTable.length > 0 && bloodProductsTable.map((item, i) => (
                                            <tr>
                                                <td>
                                                    {item}
                                                </td>
                                                <td>
                                                    <div className="form_group">
                                                        <input type="text" onChange={(e) => updateInputValue(e)} name={"bloodProducts_infused_" + i} className="form-control" placeholder="Enter" />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="form_group">
                                                        <input type="text" onChange={(e) => updateInputValue(e)} name={"bloodProducts_dose_" + i} className="form-control" placeholder="Enter" />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="form_group">
                                                        <input type="text" onChange={(e) => updateInputValue(e)} name={"bloodProducts_route_" + i} className="form-control" placeholder="Enter" />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="form_group">
                                                        <input type="time" onChange={(e) => updateInputValue(e)} name={"bloodProducts_timegiven_" + i} className="form-control" placeholder="Enter" />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="form_group">
                                                        <input type="text" onChange={(e) => updateInputValue(e)} name={"bloodProducts_initial_" + i} className="form-control" placeholder="Enter" />
                                                    </div>
                                                </td>

                                            </tr>

                                        ))}

                                        <tr>
                                            <td colspan="6" className="text_center">
                                                <p className="field_title"> Blood </p>

                                            </td>
                                        </tr>

                                        {bloodTable && bloodTable.length > 0 && bloodTable.map((item, i) => (
                                            <tr>
                                                <td>
                                                    {item}
                                                </td>
                                                <td>
                                                    <div className="form_group">
                                                        <input type="text" onChange={(e) => updateInputValue(e)} name={"blood_infused_" + i} className="form-control" placeholder="Enter" />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="form_group">
                                                        <input type="text" onChange={(e) => updateInputValue(e)} name={"blood_dose_" + i} className="form-control" placeholder="Enter" />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="form_group">
                                                        <input type="text" onChange={(e) => updateInputValue(e)} name={"blood_route_" + i} className="form-control" placeholder="Enter" />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="form_group">
                                                        <input type="time" onChange={(e) => updateInputValue(e)} name={"blood_timegiven_" + i} className="form-control" placeholder="Enter" />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="form_group">
                                                        <input type="text" onChange={(e) => updateInputValue(e)} name={"blood_initial_" + i} className="form-control" placeholder="Enter" />
                                                    </div>
                                                </td>

                                            </tr>

                                        ))}


                                        <tr>
                                            <td colspan="6" className="text_center">
                                                <p className="field_title"> Continuous infusion drugs</p>

                                            </td>
                                        </tr>

                                        {infusionTable && infusionTable.length > 0 && infusionTable.map((item, i) => (
                                            <tr>
                                                <td>
                                                    {item}
                                                </td>
                                                <td>
                                                    <div className="form_group">
                                                        <input type="text" onChange={(e) => updateInputValue(e)} name={"infusion_infused_" + i} className="form-control" placeholder="Enter" />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="form_group">
                                                        <input type="text" onChange={(e) => updateInputValue(e)} name={"infusion_dose_" + i} className="form-control" placeholder="Enter" />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="form_group">
                                                        <input type="text" onChange={(e) => updateInputValue(e)} name={"infusion_route_" + i} className="form-control" placeholder="Enter" />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="form_group">
                                                        <input type="time" onChange={(e) => updateInputValue(e)} name={"infusion_timegiven_" + i} className="form-control" placeholder="Enter" />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="form_group">
                                                        <input type="text" onChange={(e) => updateInputValue(e)} name={"infusion_initial_" + i} className="form-control" placeholder="Enter" />
                                                    </div>
                                                </td>

                                            </tr>

                                        ))}

                                    </tbody>
                                </table>
                            </div>
                            <div className=" sub_section ">
                                <p className="sub_title mt-20 mb-10">INPUT/OUTPUT</p>
                                <table className="table table-bordered table_blue">
                                    <thead>
                                        <tr>
                                            <th width="40%"></th>
                                            <th width="20%">Prior to Arrival</th>
                                            <th width="20%">ED </th>
                                            <th width="20%"> Total </th>


                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td colspan="4" className="text_center">
                                                <p className="field_title"> Input </p>

                                            </td>
                                        </tr>

                                        {inputTable && inputTable.length > 0 && inputTable.map((item, i) => (
                                            <tr>
                                                <td>
                                                    {item}
                                                </td>
                                                <td>
                                                    <div className="form_group">
                                                        <input type="text" onChange={(e) => updateInputValue(e)} name={"input_arrival_" + i} className="form-control" placeholder="Enter" />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="form_group">
                                                        <input type="text" onChange={(e) => updateInputValue(e)} name={"input_ed_" + i} className="form-control" placeholder="Enter" />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="form_group">
                                                        <input type="text" onChange={(e) => updateInputValue(e)} name={"input_total_" + i} className="form-control" placeholder="Enter" />
                                                    </div>
                                                </td>


                                            </tr>
                                        ))}

                                        <tr>
                                            <td colspan="4" className="text_center">
                                                <p className="field_title"> Output </p>

                                            </td>
                                        </tr>

                                        {outputTable && outputTable.length > 0 && outputTable.map((item, i) => (
                                            <tr>
                                                <td>
                                                    {item}
                                                </td>
                                                <td>
                                                    <div className="form_group">
                                                        <input type="text" onChange={(e) => updateInputValue(e)} name={"output_arrival_" + i} className="form-control" placeholder="Enter" />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="form_group">
                                                        <input type="text" onChange={(e) => updateInputValue(e)} name={"output_ed_" + i} className="form-control" placeholder="Enter" />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="form_group">
                                                        <input type="text" onChange={(e) => updateInputValue(e)} name={"output_total_" + i} className="form-control" placeholder="Enter" />
                                                    </div>
                                                </td>


                                            </tr>
                                        ))}


                                    </tbody>
                                </table>
                            </div>
                            <div className=" sub_section ">
                                <p className="sub_title mt-20 mb-10">PROCEDURES</p>
                                <table className="table table-bordered table_blue mb-0">
                                    <thead>
                                        <tr>
                                            <th width="60%">Procedure </th>
                                            <th width="20%">Time</th>
                                            <th width="20%">By </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div className="check-box sqeare mb-0 mt-5">
                                                    <div className="round">
                                                        <input type="checkbox" onClick={(e) => selectCheckbox(e, "procedures")} name="procedures_neck" value="Neck immobilization: C-Collar Applied" id="procedures_neck" defaultChecked={patientValues && patientValues.procedures && patientValues.procedures["procedures_neck"] ? true : false} />
                                                        <label for="procedures_neck"></label>
                                                    </div>
                                                    Neck immobilization: C-Collar Applied
                                                </div>
                                            </td>
                                            <td>
                                                <div className="form_group">
                                                    <input type="time" onChange={(e) => updateInputValue(e)} name="procedure_neck_time" className="form-control" placeholder="Enter" />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="form_group">
                                                    <input type="text" className="form-control" onChange={(e) => updateInputValue(e)} name="procedure_neck_by" placeholder="Enter" />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="check-box sqeare mb-0 mt-5">
                                                    <div className="round">
                                                        <input type="checkbox" onClick={(e) => selectCheckbox(e, "procedures")} name="procedures_iv" value="IV access" id="procedures_iv" defaultChecked={patientValues && patientValues.procedures && patientValues.procedures["procedures_iv"] ? true : false} />
                                                        <label for="procedures_iv"></label>
                                                    </div>
                                                    IV access
                                                </div>
                                            </td>
                                            <td>
                                                <div className="form_group">
                                                    <input type="time" className="form-control" onChange={(e) => updateInputValue(e)} name="procedure_iv_time" placeholder="Enter" />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="form_group">
                                                    <input type="text" className="form-control" onChange={(e) => updateInputValue(e)} name="procedure_iv_by" placeholder="Enter" />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="check-box sqeare mt-5">
                                                    <div className="round">
                                                        <input type="checkbox" onClick={(e) => selectCheckbox(e, "procedures")} name="procedures_rapid" value="Rapid Sequence Intubation" id="procedures_rapid" defaultChecked={patientValues && patientValues.procedures && patientValues.procedures["procedures_rapid"] ? true : false} />
                                                        <label for="procedures_rapid"></label>
                                                    </div>
                                                    Rapid Sequence Intubation
                                                </div>
                                                <div className="check-box sqeare mb-0">
                                                    <div className="round">
                                                        <input type="checkbox" onClick={(e) => selectCheckbox(e, "procedures")} name="procedures_et" value="ET Tube" id="procedures_et" defaultChecked={patientValues && patientValues.procedures && patientValues.procedures["procedures_et"] ? true : false} />
                                                        <label for="procedures_et"></label>
                                                    </div>
                                                    ET Tube
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form_group input_style mt-20">
                                                            <label className="label">Size </label>
                                                            <div className="measure tqt">
                                                                <input type="text" onChange={(e) => updateInputValue(e)} name="procedure_size" className="field" placeholder="Enter " />
                                                                <span>cm</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form_group input_style mt-20">
                                                            <label className="label">Secured @ </label>
                                                            <div className="measure tqt">
                                                                <input type="text" onChange={(e) => updateInputValue(e)} name="procedure_secured" className="field" placeholder="Enter " />
                                                                <span>cm</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form_group input_style">
                                                            <label className="label">FiO2</label>
                                                            <div className="measure tqt">
                                                                <input type="text" onChange={(e) => updateInputValue(e)} name="procedure_fio2" className="field" placeholder="Enter " />
                                                                <span>%</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="form_group">
                                                    <input type="time" onChange={(e) => updateInputValue(e)} name="procedure_et_time" className="form-control" placeholder="Enter" />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="form_group">
                                                    <input type="text" onChange={(e) => updateInputValue(e)} name="procedure_et_by" className="form-control" placeholder="Enter" />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="check-box sqeare mb-0 mt-5">
                                                    <div className="round">
                                                        <input type="checkbox" onClick={(e) => selectCheckbox(e, "procedures")} name="procedures_surgical" value="Surgical Airway" id="procedures_surgical" defaultChecked={patientValues && patientValues.procedures && patientValues.procedures["procedures_surgical"] ? true : false} />
                                                        <label for="procedures_surgical"></label>
                                                    </div>
                                                    Surgical Airway
                                                </div>
                                            </td>
                                            <td>
                                                <div className="form_group">
                                                    <input type="time" onChange={(e) => updateInputValue(e)} name="procedure_surgical_time" className="form-control" placeholder="Enter" />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="form_group">
                                                    <input type="text" onChange={(e) => updateInputValue(e)} name="procedure_surgical_by" className="form-control" placeholder="Enter" />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="check-box sqeare">
                                                    <div className="round">
                                                        <input type="checkbox" onClick={(e) => selectCheckbox(e, "procedures")} name="procedures_needle" value="Needle decompression/thoracotomy" id="procedures_needle" defaultChecked={patientValues && patientValues.procedures && patientValues.procedures["procedures_needle"] ? true : false} />
                                                        <label for="procedures_needle"></label>
                                                    </div>
                                                    Needle decompression/thoracotomy
                                                </div>
                                                <div className="check-box sqeare ">
                                                    <div className="round">
                                                        <input type="checkbox" onClick={(e) => selectCheckbox(e, "procedures")} name="procedures_thoracotomy" value="Thoracotomy" id="procedures_thoracotomy" defaultChecked={patientValues && patientValues.procedures && patientValues.procedures["procedures_thoracotomy"] ? true : false} />
                                                        <label for="procedures_thoracotomy"></label>
                                                    </div>
                                                    Thoracotomy
                                                </div>
                                                <div className="check-box sqeare mb-0">
                                                    <div className="round">
                                                        <input type="checkbox" onClick={(e) => selectCheckbox(e, "procedures")} name="procedures_cross" value="Cross clamp time" id="procedures_cross" defaultChecked={patientValues && patientValues.procedures && patientValues.procedures["procedures_cross"] ? true : false} />
                                                        <label for="procedures_cross"></label>
                                                    </div>
                                                    Cross clamp time
                                                </div>
                                            </td>
                                            <td>
                                                <div className="form_group">
                                                    <input type="time" onChange={(e) => updateInputValue(e)} name="procedures_cross_time" className="form-control" placeholder="Enter" />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="form_group">
                                                    <input type="text" onChange={(e) => updateInputValue(e)} name="procedures_cross_by" className="form-control" placeholder="Enter" />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="check-box sqeare">
                                                    <div className="round">
                                                        <input type="checkbox" onClick={(e) => selectCheckbox(e, "procedures")} name="procedures_chest_r" value="Chest tube R" id="procedures_chest_r" defaultChecked={patientValues && patientValues.procedures && patientValues.procedures["procedures_chest_r"] ? true : false} />
                                                        <label for="procedures_chest_r"></label>
                                                    </div>
                                                    Chest tube R
                                                </div>
                                                <div className="check-box sqeare mb-0">
                                                    <div className="round">
                                                        <input type="checkbox" onClick={(e) => selectCheckbox(e, "procedures")} name="procedures_chest_l" value="Chest tube L" id="procedures_chest_l" defaultChecked={patientValues && patientValues.procedures && patientValues.procedures["procedures_chest_l"] ? true : false} />
                                                        <label for="procedures_chest_l"></label>
                                                    </div>
                                                    Chest tube L
                                                </div>

                                            </td>
                                            <td>
                                                <div className="form_group">
                                                    <input type="time" onChange={(e) => updateInputValue(e)} name="procedures_chest_time" className="form-control" placeholder="Enter" />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="form_group">
                                                    <input type="text" onChange={(e) => updateInputValue(e)} name="procedures_chest_by" className="form-control" placeholder="Enter" />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="check-box sqeare mb-0">
                                                    <div className="round">
                                                        <input type="checkbox" onClick={(e) => selectCheckbox(e, "procedures")} name="procedures_pericardiocentesis" value="Pericardiocentesis" id="procedures_pericardiocentesis" defaultChecked={patientValues && patientValues.procedures && patientValues.procedures["procedures_pericardiocentesis"] ? true : false} />
                                                        <label for="procedures_pericardiocentesis"></label>
                                                    </div>
                                                    Pericardiocentesis
                                                </div>

                                            </td>
                                            <td>
                                                <div className="form_group">
                                                    <input type="time" onChange={(e) => updateInputValue(e)} name="procedures_pericardiocentesis_time" className="form-control" placeholder="Enter" />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="form_group">
                                                    <input type="text" onChange={(e) => updateInputValue(e)} name="procedures_pericardiocentesis_by" className="form-control" placeholder="Enter" />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="check-box sqeare">
                                                    <div className="round">
                                                        <input type="checkbox" onClick={(e) => selectCheckbox(e, "procedures")} name="procedures_CPR" value="CPR" id="procedures_CPR" defaultChecked={patientValues && patientValues.procedures && patientValues.procedures["procedures_CPR"] ? true : false} />
                                                        <label for="procedures_CPR"></label>
                                                    </div>
                                                    CPR
                                                </div>
                                                <div className="check-box sqeare mb-0">
                                                    <div className="round">
                                                        <input type="checkbox" onClick={(e) => selectCheckbox(e, "procedures")} name="procedures_Defib" value="Defib / Cardiovert" id="procedures_Defib" defaultChecked={patientValues && patientValues.procedures && patientValues.procedures["procedures_Defib"] ? true : false} />
                                                        <label for="procedures_Defib"></label>
                                                    </div>
                                                    Defib / Cardiovert
                                                </div>
                                            </td>
                                            <td>
                                                <div className="form_group">
                                                    <input type="time" onChange={(e) => updateInputValue(e)} name="procedures_CPR_time" className="form-control" placeholder="Enter" />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="form_group">
                                                    <input type="text" onChange={(e) => updateInputValue(e)} name="procedures_CPR_by" className="form-control" placeholder="Enter" />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="check-box sqeare">
                                                    <div className="round">
                                                        <input type="checkbox" onClick={(e) => selectCheckbox(e, "procedures")} name="procedures_central" value="CENTRAL LINE" id="procedures_central" defaultChecked={patientValues && patientValues.procedures && patientValues.procedures["procedures_central"] ? true : false} />
                                                        <label for="procedures_central"></label>
                                                    </div>
                                                    CENTRAL LINE
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form_group input_style mt-0">
                                                            <label className="label">Size </label>
                                                            <div className="measure tqt">
                                                                <input type="text" onChange={(e) => updateInputValue(e)} name="procedures_central_size" className="field" placeholder="Enter " />
                                                                <span>Fr</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6"></div>
                                                    <div className="col-md-6">
                                                        <div className="form_group mb-0">
                                                            <label className="label">Site </label>
                                                            <input type="text" onChange={(e) => updateInputValue(e)} name="procedures_central_site" className="form-control" />

                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form_group mb-0">
                                                            <label className="label">Solution</label>
                                                            <input type="text" onChange={(e) => updateInputValue(e)} name="procedures_central_sol" className="form-control" />

                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="form_group">
                                                    <input type="time" onChange={(e) => updateInputValue(e)} name="procedures_central_time" className="form-control" placeholder="Enter" />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="form_group">
                                                    <input type="text" onChange={(e) => updateInputValue(e)} name="procedures_central_by" className="form-control" placeholder="Enter" />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="check-box sqeare">
                                                    <div className="round">
                                                        <input type="checkbox" onClick={(e) => selectCheckbox(e, "procedures")} name="procedures_Arterial" value="Arterial line" id="procedures_Arterial" defaultChecked={patientValues && patientValues.procedures && patientValues.procedures["procedures_Arterial"] ? true : false} />
                                                        <label for="procedures_Arterial"></label>
                                                    </div>
                                                    Arterial line
                                                </div>
                                                <div className="check-box sqeare mb-0">
                                                    <div className="round">
                                                        <input type="checkbox" onClick={(e) => selectCheckbox(e, "procedures")} name="procedures_Intraosseous" value="Intraosseous" id="procedures_Intraosseous" defaultChecked={patientValues && patientValues.procedures && patientValues.procedures["procedures_Intraosseous"] ? true : false} />
                                                        <label for="procedures_Intraosseous"></label>
                                                    </div>
                                                    Intraosseous
                                                </div>
                                            </td>
                                            <td>
                                                <div className="form_group">
                                                    <input type="time" onChange={(e) => updateInputValue(e)} name="procedures_Intraosseous_time" className="form-control" placeholder="Enter" />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="form_group">
                                                    <input type="text" onChange={(e) => updateInputValue(e)} name="procedures_Intraosseous_by" className="form-control" placeholder="Enter" />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="check-box sqeare">
                                                    <div className="round">
                                                        <input type="checkbox" onClick={(e) => selectCheckbox(e, "procedures")} name="procedures_warm" value="Warming Measures" id="procedures_warm" defaultChecked={patientValues && patientValues.procedures && patientValues.procedures["procedures_warm"] ? true : false} />
                                                        <label for="procedures_warm"></label>
                                                    </div>
                                                    Warming Measures
                                                </div>
                                                <div className="check-box sqeare">
                                                    <div className="round">
                                                        <input type="checkbox" onClick={(e) => selectCheckbox(e, "procedures")} name="procedures_fluids" value="Fluids" id="procedures_fluids" defaultChecked={patientValues && patientValues.procedures && patientValues.procedures["procedures_fluids"] ? true : false} />
                                                        <label for="procedures_fluids"></label>
                                                    </div>
                                                    Fluids
                                                </div>
                                                <div className="check-box sqeare ">
                                                    <div className="round">
                                                        <input type="checkbox" onClick={(e) => selectCheckbox(e, "procedures")} name="procedures_Mechanical" value="Mechanical" id="procedures_Mechanical" defaultChecked={patientValues && patientValues.procedures && patientValues.procedures["procedures_Mechanical"] ? true : false} />
                                                        <label for="procedures_Mechanical"></label>
                                                    </div>
                                                    Mechanical
                                                </div>
                                                <div className="check-box sqeare">
                                                    <div className="round">
                                                        <input type="checkbox" onClick={(e) => selectCheckbox(e, "procedures")} name="procedures_bair" value="Bair Hugger" id="procedures_bair" defaultChecked={patientValues && patientValues.procedures && patientValues.procedures["procedures_bair"] ? true : false} />
                                                        <label for="procedures_bair"></label>
                                                    </div>
                                                    Bair Hugger
                                                </div>
                                                <div className="check-box sqeare mb-0">
                                                    <div className="round">
                                                        <input type="checkbox" onClick={(e) => selectCheckbox(e, "procedures")} name="procedures_Blankets" value="Blankets" id="procedures_Blankets" defaultChecked={patientValues && patientValues.procedures && patientValues.procedures["procedures_Blankets"] ? true : false} />
                                                        <label for="procedures_Blankets"></label>
                                                    </div>
                                                    Blankets
                                                </div>
                                            </td>
                                            <td>
                                                <div className="form_group">
                                                    <input type="time" onChange={(e) => updateInputValue(e)} name="procedures_warm_time" className="form-control" placeholder="Enter" />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="form_group">
                                                    <input type="text" onChange={(e) => updateInputValue(e)} name="procedures_warm_by" className="form-control" placeholder="Enter" />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="check-box sqeare">
                                                    <div className="round">
                                                        <input type="checkbox" onClick={(e) => selectCheckbox(e, "procedures")} name="procedures_og" value="OG/NG tube" id="procedures_og" defaultChecked={patientValues && patientValues.procedures && patientValues.procedures["procedures_og"] ? true : false} />
                                                        <label for="procedures_og"></label>
                                                    </div>
                                                    OG/NG tube
                                                </div>
                                                <div className="check-box sqeare">
                                                    <div className="round">
                                                        <input type="checkbox" onClick={(e) => selectCheckbox(e, "procedures")} name="procedures_og_Size" value="Size" id="procedures_og_Size" defaultChecked={patientValues && patientValues.procedures && patientValues.procedures["procedures_og_Size"] ? true : false} />
                                                        <label for="procedures_og_Size"></label>
                                                    </div>
                                                    Size
                                                </div>
                                                <div className="check-box sqeare mb-0">
                                                    <div className="round">
                                                        <input type="checkbox" onClick={(e) => selectCheckbox(e, "procedures")} name="procedures_Colour" value="Colour" id="procedures_Colour" defaultChecked={patientValues && patientValues.procedures && patientValues.procedures["procedures_Colour"] ? true : false} />
                                                        <label for="procedures_Colour"></label>
                                                    </div>
                                                    Colour
                                                </div>

                                            </td>
                                            <td>
                                                <div className="form_group">
                                                    <input type="time" onChange={(e) => updateInputValue(e)} name="procedures_og_time" className="form-control" placeholder="Enter" />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="form_group">
                                                    <input type="text" onChange={(e) => updateInputValue(e)} name="procedures_og_by" className="form-control" placeholder="Enter" />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="check-box sqeare">
                                                    <div className="round">
                                                        <input type="checkbox" onClick={(e) => selectCheckbox(e, "procedures")} name="procedures_peritoneal" value="Peritoneal Lavage" id="procedures_peritoneal" defaultChecked={patientValues && patientValues.procedures && patientValues.procedures["procedures_peritoneal"] ? true : false} />
                                                        <label for="procedures_peritoneal"></label>
                                                    </div>
                                                    Peritoneal Lavage
                                                </div>
                                                <div className="check-box sqeare mb-0">
                                                    <div className="round">
                                                        <input type="checkbox" onClick={(e) => selectCheckbox(e, "procedures")} name="procedures_Fast" value="Fast" id="procedures_Fast" defaultChecked={patientValues && patientValues.procedures && patientValues.procedures["procedures_Fast"] ? true : false} />
                                                        <label for="procedures_Fast"></label>
                                                    </div>
                                                    Fast
                                                </div>

                                            </td>
                                            <td>
                                                <div className="form_group">
                                                    <input type="time" onChange={(e) => updateInputValue(e)} name="procedures_fast_time" className="form-control" placeholder="Enter" />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="form_group">
                                                    <input type="text" onChange={(e) => updateInputValue(e)} name="procedures_fast_by" className="form-control" placeholder="Enter" />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="check-box sqeare">
                                                    <div className="round">
                                                        <input type="checkbox" onClick={(e) => selectCheckbox(e, "procedures")} name="procedures_Foley" value="Foley / Quick Cath" id="procedures_Foley" defaultChecked={patientValues && patientValues.procedures && patientValues.procedures["procedures_Foley"] ? true : false} />
                                                        <label for="procedures_Foley"></label>
                                                    </div>
                                                    Foley / Quick Cath
                                                </div>
                                                <div className="check-box sqeare">
                                                    <div className="round">
                                                        <input type="checkbox" onClick={(e) => selectCheckbox(e, "procedures")} name="procedures_Foley_Size" value="Size" id="procedures_Foley_Size" defaultChecked={patientValues && patientValues.procedures && patientValues.procedures["procedures_Foley_Size"] ? true : false} />
                                                        <label for="procedures_Foley_Size"></label>
                                                    </div>
                                                    Size
                                                </div>
                                                <div className="check-box sqeare mb-0">
                                                    <div className="round">
                                                        <input type="checkbox" onClick={(e) => selectCheckbox(e, "procedures")} name="procedures_Foley_color" value="Colour" id="procedures_Foley_color" defaultChecked={patientValues && patientValues.procedures && patientValues.procedures["procedures_Foley_color"] ? true : false} />
                                                        <label for="procedures_Foley_color"></label>
                                                    </div>
                                                    Colour
                                                </div>
                                            </td>
                                            <td>
                                                <div className="form_group">
                                                    <input type="time" onChange={(e) => updateInputValue(e)} name="procedures_foley_time" className="form-control" placeholder="Enter" />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="form_group">
                                                    <input type="text" onChange={(e) => updateInputValue(e)} name="procedures_foley_by" className="form-control" placeholder="Enter" />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="check-box sqeare">
                                                    <div className="round">
                                                        <input type="checkbox" onClick={(e) => selectCheckbox(e, "procedures")} name="procedures_cast" value="Cast/splint" id="procedures_cast" defaultChecked={patientValues && patientValues.procedures && patientValues.procedures["procedures_cast"] ? true : false} />
                                                        <label for="procedures_cast"></label>
                                                    </div>
                                                    Cast/splint
                                                </div>
                                                <div className="check-box sqeare">
                                                    <div className="round">
                                                        <input type="checkbox" onClick={(e) => selectCheckbox(e, "procedures")} name="procedures_Location" value="Location" id="procedures_Location" defaultChecked={patientValues && patientValues.procedures && patientValues.procedures["procedures_Location"] ? true : false} />
                                                        <label for="procedures_Location"></label>
                                                    </div>
                                                    Location
                                                </div>
                                                <div className="check-box sqeare">
                                                    <div className="round">
                                                        <input type="checkbox" onClick={(e) => selectCheckbox(e, "procedures")} name="procedures_Suture" value="Suture" id="procedures_Suture" defaultChecked={patientValues && patientValues.procedures && patientValues.procedures["procedures_Suture"] ? true : false} />
                                                        <label for="procedures_Suture"></label>
                                                    </div>
                                                    Suture
                                                </div>
                                                <div className="check-box sqeare">
                                                    <div className="round">
                                                        <input type="checkbox" onClick={(e) => selectCheckbox(e, "procedures")} name="procedures_Tourniquet" value="Tourniquet" id="procedures_Tourniquet" defaultChecked={patientValues && patientValues.procedures && patientValues.procedures["procedures_Tourniquet"] ? true : false} />
                                                        <label for="procedures_Tourniquet"></label>
                                                    </div>
                                                    Tourniquet
                                                </div>
                                                <div className="check-box sqeare mb-0">
                                                    <div className="round">
                                                        <input type="checkbox" onClick={(e) => selectCheckbox(e, "procedures")} name="procedures_Traction" value="Traction" id="procedures_Traction" defaultChecked={patientValues && patientValues.procedures && patientValues.procedures["procedures_Traction"] ? true : false} />
                                                        <label for="procedures_Traction"></label>
                                                    </div>
                                                    Traction
                                                </div>
                                            </td>
                                            <td>
                                                <div className="form_group">
                                                    <input type="time" onChange={(e) => updateInputValue(e)} name="procedures_traction_time" className="form-control" placeholder="Enter" />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="form_group">
                                                    <input type="text" onChange={(e) => updateInputValue(e)} name="procedures_traction_by" className="form-control" placeholder="Enter" />
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="sub_section">
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="form_group">
                                            <label className="label">AGE </label>
                                            <input type="text" onChange={(e) => updateInputValue(e)} name="infusion_age" className="form-control" />

                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form_group">
                                            <label className="label">WEIGHT </label>
                                            <input type="text" onChange={(e) => updateInputValue(e)} name="infusion_weight" className="form-control" />

                                        </div>
                                    </div>
                                </div>

                                <div className="check_list w_white mb-0"><span className="check_btn2" onClick={(e) => updateClinicValues(e, "infus_flag", true)}><span className="material-icons"> done </span></span></div>

                                {patientValues.infus_flag && patientValues.infus && Object.keys(patientValues.infus).length > 0 ? <div className="check_list w_white mb-0">

                                    {Object.keys(patientValues.infus).map((item, i) => (
                                        <div className="check_item active" key={i} onClick={(e) => updateClinicValues(e, "infus_flag", false)} ><label className="check">{patientValues.infus[item]}</label></div>
                                    ))}

                                </div> : <>

                                    {infus && infus.length > 0 && infus.map((item, i) => (

                                        <div className="check-box sqeare" key={i}>
                                            <div className="round">
                                                <input type="checkbox" onClick={(e) => selectCheckbox(e, "infus")} name={item.name} value={item.value} id={item.name} defaultChecked={patientValues && patientValues.infus && patientValues.infus[item.name] ? true : false}
                                                />
                                                <label for={item.name}></label>
                                            </div>
                                            {item.value}
                                        </div>


                                    ))} </>}


                                <div className="sub_title mb-10 mt-20">P</div>
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="form_group">
                                            <label className="label">LMP </label>
                                            <input type="text" onChange={(e) => updateInputValue(e)} name="infusion_lmp" className="form-control" />

                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form_group">
                                            <label className="label">LAST TETANUS </label>
                                            <input type="text" onChange={(e) => updateInputValue(e)} name="infusion_tetanus" className="form-control" />

                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form_group">
                                            <label className="label">LAST MEAL </label>
                                            <input type="text" onChange={(e) => updateInputValue(e)} name="infusion_meal" className="form-control" />

                                        </div>
                                    </div>
                                </div>

                                <p className="ps1">Older adult Age &gt; 55 Children Burns Anticoagulants & Bleeding Disorders
                                    Pregnancy &gt; 20weeks
                                </p>
                                <p className="sub_title mb-10 mt-20">E </p>

                                <div className="check_list w_white mb-0"><span className="check_btn2" onClick={(e) => updateClinicValues(e, "ecomo_flag", true)}><span className="material-icons"> done </span></span></div>

                                {patientValues.ecomo_flag && patientValues.ecomo && Object.keys(patientValues.ecomo).length > 0 ? <div className="check_list w_white mb-0">

                                    {Object.keys(patientValues.ecomo).map((item, i) => (
                                        <div className="check_item active" key={i} onClick={(e) => updateClinicValues(e, "ecomo_flag", false)} ><label className="check">{patientValues.ecomo[item]}</label></div>
                                    ))}

                                </div> : <>

                                    {ecomo && ecomo.length > 0 && ecomo.map((item, i) => (

                                        <div className="check-box sqeare" key={i}>
                                            <div className="round">
                                                <input type="checkbox" onClick={(e) => selectCheckbox(e, "ecomo")} name={item.name} value={item.value} id={item.name} defaultChecked={patientValues && patientValues.ecomo && patientValues.ecomo[item.name] ? true : false}
                                                />
                                                <label for={item.name}></label>
                                            </div>
                                            {item.value}
                                        </div>


                                    ))} </>}

                                <p className="sub_title mb-10">Given to</p>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form_group">
                                            <label className="label">Name </label>
                                            <input type="text" className="form-control" />

                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form_group">
                                            <label className="label">Relationship </label>
                                            <input type="text" className="form-control" />

                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form_group">
                                            <label className="label">Nursing Staff </label>
                                            <input type="text" className="form-control" />

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="section investigation ctr" id="notes">
                            <h2 className="popup_title">NOTES </h2>

                            <div className="files">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="file_upload" data-toggle="modal" data-target="#exampleModalLong">
                                            <img src={doc} className="img" />
                                            <div className="file_ttile">
                                                <p className="fl_title">A&E Consultant - Trauma team leader (TTL)</p>
                                                <p className="fl_link">
                                                    <a href="#" className="link">LINK </a>
                                                    <a href="#" className="link">SCAN </a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="file_upload" data-toggle="modal" data-target="#exampleModalLong">
                                            <img src={doc} className="img" />
                                            <div className="file_ttile">
                                                <p className="fl_title">A&E specialty trainee</p>
                                                <p className="fl_link">
                                                    <a href="#" className="link">LINK </a>
                                                    <a href="#" className="link">SCAN </a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="file_upload" data-toggle="modal" data-target="#exampleModalLong">
                                            <img src={doc} className="img" />
                                            <div className="file_ttile">
                                                <p className="fl_title">Anaesthetic Specialty trainee 5–7, Post final FRCA</p>
                                                <p className="fl_link">
                                                    <a href="#" className="link">LINK </a>
                                                    <a href="#" className="link">SCAN </a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="file_upload" data-toggle="modal" data-target="#exampleModalLong">
                                            <img src={doc} className="img" />
                                            <div className="file_ttile">
                                                <p className="fl_title">Operator department practitioner</p>
                                                <p className="fl_link">
                                                    <a href="#" className="link">LINK </a>
                                                    <a href="#" className="link">SCAN </a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="file_upload" data-toggle="modal" data-target="#exampleModalLong">
                                            <img src={doc} className="img" />
                                            <div className="file_ttile">
                                                <p className="fl_title">Trauma Nurse Coordinator - Band 7 (Scribe)</p>
                                                <p className="fl_link">
                                                    <a href="#" className="link">LINK </a>
                                                    <a href="#" className="link">SCAN </a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="file_upload" data-toggle="modal" data-target="#exampleModalLong">
                                            <img src={doc} className="img" />
                                            <div className="file_ttile">
                                                <p className="fl_title">Orthopaedic Specialty trainee 4–7</p>
                                                <p className="fl_link">
                                                    <a href="#" className="link">LINK </a>
                                                    <a href="#" className="link">SCAN </a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="file_upload" data-toggle="modal" data-target="#exampleModalLong">
                                            <img src={doc} className="img" />
                                            <div className="file_ttile">
                                                <p className="fl_title">General surgical Specialty Trainee 4–7</p>
                                                <p className="fl_link">
                                                    <a href="#" className="link">LINK </a>
                                                    <a href="#" className="link">SCAN </a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="file_upload" data-toggle="modal" data-target="#exampleModalLong">
                                            <img src={doc} className="img" />
                                            <div className="file_ttile">
                                                <p className="fl_title">Neurosurgery</p>
                                                <p className="fl_link">
                                                    <a href="#" className="link">LINK </a>
                                                    <a href="#" className="link">SCAN </a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="file_upload" data-toggle="modal" data-target="#exampleModalLong">
                                            <img src={doc} className="img" />
                                            <div className="file_ttile">
                                                <p className="fl_title">Oral Maxillofacial Surgery</p>
                                                <p className="fl_link">
                                                    <a href="#" className="link">LINK </a>
                                                    <a href="#" className="link">SCAN </a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="file_upload" data-toggle="modal" data-target="#exampleModalLong">
                                            <img src={doc} className="img" />
                                            <div className="file_ttile">
                                                <p className="fl_title">Other</p>
                                                <p className="fl_link">
                                                    <a href="#" className="link">LINK </a>
                                                    <a href="#" className="link">SCAN </a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="section investigation ctr">
                            <h2 className="popup_title">OUTCOME </h2>
                            <p className="field_title mb-10">Admitted </p>

                            <div className="check_list w_white mb-0"><span className="check_btn2" onClick={(e) => updateClinicValues(e, "outcome_admitted_flag", true)}><span className="material-icons"> done </span></span></div>

                            {patientValues.outcome_admitted_flag && patientValues.outcome_admitted && Object.keys(patientValues.outcome_admitted).length > 0 ? <div className="check_list w_white mb-0">

                                {Object.keys(patientValues.outcome_admitted).map((item, i) => (
                                    <div className="check_item active" key={i} onClick={(e) => updateClinicValues(e, "outcome_admitted_flag", false)} ><label className="check">{patientValues.outcome_admitted[item]}</label></div>
                                ))}

                            </div> : <>

                                {outcome_admitted && outcome_admitted.length > 0 && outcome_admitted.map((item, i) => (

                                    <div className="check-box sqeare" key={i}>
                                        <div className="round">
                                            <input type="checkbox" onClick={(e) => selectCheckbox(e, "outcome_admitted")} name={item.name} value={item.value} id={item.name} defaultChecked={patientValues && patientValues.outcome_admitted && patientValues.outcome_admitted[item.name] ? true : false}
                                            />
                                            <label for={item.name}></label>
                                        </div>
                                        {item.value}
                                    </div>


                                ))} </>}


                            <p className="field_title mb-10">Transferred to  </p>

                            <div className="check_list w_white mb-0"><span className="check_btn2" onClick={(e) => updateClinicValues(e, "transferred_to_flag", true)}><span className="material-icons"> done </span></span></div>

                            {patientValues.transferred_to_flag && patientValues.transferred_to && Object.keys(patientValues.transferred_to).length > 0 ? <div className="check_list w_white mb-0">

                                {Object.keys(patientValues.transferred_to).map((item, i) => (
                                    <div className="check_item active" key={i} onClick={(e) => updateClinicValues(e, "transferred_to_flag", false)} ><label className="check">{patientValues.transferred_to[item]}</label></div>
                                ))}

                            </div> : <>

                                {transferred_to && transferred_to.length > 0 && transferred_to.map((item, i) => (

                                    <div className="check-box sqeare" key={i}>
                                        <div className="round">
                                            <input type="checkbox" onClick={(e) => selectCheckbox(e, "transferred_to")} name={item.name} value={item.value} id={item.name} defaultChecked={patientValues && patientValues.transferred_to && patientValues.transferred_to[item.name] ? true : false}
                                            />
                                            <label for={item.name}></label>
                                        </div>
                                        {item.value}
                                    </div>


                                ))} </>}






                            <p className="field_title mb-10">Expired in ED  </p>

                            <div className="check_list w_white mb-0"><span className="check_btn2" onClick={(e) => updateClinicValues(e, "expired_flag", true)}><span className="material-icons"> done </span></span></div>

                            {patientValues.expired_flag && patientValues.expired && Object.keys(patientValues.expired).length > 0 ? <div className="check_list w_white mb-0">

                                {Object.keys(patientValues.expired).map((item, i) => (
                                    <div className="check_item active" key={i} onClick={(e) => updateClinicValues(e, "expired_flag", false)} ><label className="check">{patientValues.expired[item]}</label></div>
                                ))}

                            </div> : <>

                                {expired && expired.length > 0 && expired.map((item, i) => (

                                    <div className="check-box sqeare" key={i}>
                                        <div className="round">
                                            <input type="checkbox" onClick={(e) => selectCheckbox(e, "expired")} name={item.name} value={item.value} id={item.name} defaultChecked={patientValues && patientValues.expired && patientValues.expired[item.name] ? true : false}
                                            />
                                            <label for={item.name}></label>
                                        </div>
                                        {item.value}
                                    </div>


                                ))} </>}


                        </div>
                        <button type="button" onClick={(e) => { submitUpdate(e) }} className="update_btn mt-0 mb-20">Submit</button>
                        <br /><br /><br /><br />



                        <div className="modal fade addjob tc" id="assembled" tabIndex="-1" role="dialog"
                            aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">Team member Popup</h5>
                                        <span className="material-icons" data-dismiss="modal" aria-label="Close">
                                            close
                                        </span>
                                    </div>
                                    <div className="modal-body">


                                        <table className="table table-bordered table_blue">
                                            <thead>
                                                <tr>
                                                    <th width="60%">TEAM MEMBER</th>
                                                    <th width="20%">CTIME CALLED</th>
                                                    <th width="20%">TIME ARRIVED</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <p className="bold">A&E Consultant - Trauma team leader (TTL)</p>
                                                        <p>Brief the team</p>
                                                        <p>Coordinate the primary survey and maintain situational awareness</p>
                                                    </td>
                                                    <td>
                                                        <div className="form_group">
                                                            <input type="time" onChange={(e) => updateInputValue(e)} name="trauma_aeconsult_ctime" className="form-control" placeholder="Enter" />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form_group">
                                                            <input type="time" onChange={(e) => updateInputValue(e)} name="trauma_aeconsult_arrived_time" className="form-control" placeholder="Enter" />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p className="bold">A&E speciality trainee</p>
                                                        <p>Perform the primary survey</p>

                                                    </td>
                                                    <td>
                                                        <div className="form_group">
                                                            <input type="time" onChange={(e) => updateInputValue(e)} name="trauma_aespeciality_ctime" className="form-control" placeholder="Enter" />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form_group">
                                                            <input type="time" onChange={(e) => updateInputValue(e)} name="trauma_aespeciality_arrived_time" className="form-control" placeholder="Enter" />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p className="bold">Anaesthetic Specialty trainee 5–7, Post final FRCA</p>
                                                        <p>Responsible for airway management and advanced vascular access</p>

                                                    </td>
                                                    <td>
                                                        <div className="form_group">
                                                            <input type="time" onChange={(e) => updateInputValue(e)} name="trauma_Anaesthetic_ctime" className="form-control" placeholder="Enter" />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form_group">
                                                            <input type="time" onChange={(e) => updateInputValue(e)} name="trauma_Anaesthetic_arrived_time" className="form-control" placeholder="Enter" />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p className="bold">Operator department practitioner</p>
                                                        <p>Assist the anaesthetist</p>

                                                    </td>
                                                    <td>
                                                        <div className="form_group">
                                                            <input type="time" onChange={(e) => updateInputValue(e)} name="trauma_department_ctime" className="form-control" placeholder="Enter" />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form_group">
                                                            <input type="time" onChange={(e) => updateInputValue(e)} name="trauma_department_arrived_time" className="form-control" placeholder="Enter" />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p className="bold">Trauma Nurse Coordinator - Band 7 (Scribe)</p>
                                                        <p>Maintain a record of the events in the trauma bay</p>

                                                    </td>
                                                    <td>
                                                        <div className="form_group">
                                                            <input type="time" onChange={(e) => updateInputValue(e)} name="trauma_Coordinator_ctime" className="form-control" placeholder="Enter" />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form_group">
                                                            <input type="time" onChange={(e) => updateInputValue(e)} name="trauma_Coordinator_arrived_time" className="form-control" placeholder="Enter" />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p className="bold">A&E Nurse 1 (Band 5)</p>
                                                        <p>Support the trauma team with tasks such as positioning the patient,
                                                            preparing an IV drip, administering medication, etc.</p>

                                                    </td>
                                                    <td>
                                                        <div className="form_group">
                                                            <input type="time" onChange={(e) => updateInputValue(e)} name="trauma_aenurse_ctime" className="form-control" placeholder="Enter" />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form_group">
                                                            <input type="time" onChange={(e) => updateInputValue(e)} name="trauma_aenurse_arrived_time" className="form-control" placeholder="Enter" />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p className="bold">A&E Nurse 2 and 3 (Band 5) </p>
                                                        <p>Check blood and blood products and deliver via the rapid infuser
                                                            following the instructions of the trauma team leader.</p>

                                                    </td>
                                                    <td>
                                                        <div className="form_group">
                                                            <input type="time" onChange={(e) => updateInputValue(e)} name="trauma_aenurse2_ctime" className="form-control" placeholder="Enter" />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form_group">
                                                            <input type="time" onChange={(e) => updateInputValue(e)} name="trauma_aenurse2_arrived_time" className="form-control" placeholder="Enter" />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p className="bold">Health care assistant- Band 2 (Runner) </p>
                                                        <p>Collect blood and blood products from the transfusion laboratory and
                                                            other equipment as necessary</p>

                                                    </td>
                                                    <td>
                                                        <div className="form_group">
                                                            <input type="time" onChange={(e) => updateInputValue(e)} name="trauma_healthcare_ctime" className="form-control" placeholder="Enter" />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form_group">
                                                            <input type="time" onChange={(e) => updateInputValue(e)} name="trauma_healthcare_arrived_time" className="form-control" placeholder="Enter" />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p className="bold">Orthopaedic Specialty trainee 4–7 </p>
                                                        <p>Provide orthopaedic advice to the trauma team leader</p>

                                                    </td>
                                                    <td>
                                                        <div className="form_group">
                                                            <input type="time" onChange={(e) => updateInputValue(e)} name="trauma_Orthopaedic_ctime" className="form-control" placeholder="Enter" />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form_group">
                                                            <input type="time" onChange={(e) => updateInputValue(e)} name="trauma_Orthopaedic_arrived_time" className="form-control" placeholder="Enter" />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p className="bold">General surgical Specialty Trainee 4–7 </p>
                                                        <p>Provide general surgical advice to the trauma team leader</p>

                                                    </td>
                                                    <td>
                                                        <div className="form_group">
                                                            <input type="time" onChange={(e) => updateInputValue(e)} name="trauma_surgical_ctime" className="form-control" placeholder="Enter" />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form_group">
                                                            <input type="time" onChange={(e) => updateInputValue(e)} name="trauma_surgical_arrived_time" className="form-control" placeholder="Enter" />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p className="bold">Radiographer </p>
                                                        <p>Undertake chest and pelvic radiographs as required</p>

                                                    </td>
                                                    <td>
                                                        <div className="form_group">
                                                            <input type="time" onChange={(e) => updateInputValue(e)} name="trauma_Radiographer_ctime" className="form-control" placeholder="Enter" />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form_group">
                                                            <input type="time" onChange={(e) => updateInputValue(e)} name="trauma_Radiographer_arrived_time" className="form-control" placeholder="Enter" />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p className="bold">Other Services Consulted </p>


                                                    </td>
                                                    <td>

                                                    </td>
                                                    <td>

                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>

                                                        <p>Neurosurgery: Telephone - In-person</p>

                                                    </td>
                                                    <td>
                                                        <div className="form_group">
                                                            <input type="time" onChange={(e) => updateInputValue(e)} name="trauma_Neurosurgery_ctime" className="form-control" placeholder="Enter" />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form_group">
                                                            <input type="time" onChange={(e) => updateInputValue(e)} name="trauma_Neurosurgery_arrived_time" className="form-control" placeholder="Enter" />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>

                                                        <p>Oral Maxillofacial Surgery: Telephone - In-person</p>

                                                    </td>
                                                    <td>
                                                        <div className="form_group">
                                                            <input type="time" onChange={(e) => updateInputValue(e)} name="trauma_Maxillofacial_ctime" className="form-control" placeholder="Enter" />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form_group">
                                                            <input type="time" onChange={(e) => updateInputValue(e)} name="trauma_Maxillofacial_arrived_time" className="form-control" placeholder="Enter" />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>Other: Telephone - In-person</p>
                                                    </td>
                                                    <td>
                                                        <div className="form_group">
                                                            <input type="time" onChange={(e) => updateInputValue(e)} name="trauma_Telephone_ctime" className="form-control" placeholder="Enter" />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form_group">
                                                            <input type="time" onChange={(e) => updateInputValue(e)} name="trauma_Telephone_arrived_time" className="form-control" placeholder="Enter" />
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" data-dismiss="modal" onClick={(e) => { submitUpdate(e) }} className="btn">Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="modal fade addjob mechanism" id="mechanism" tabIndex="-1" role="dialog"
                            aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">MECHANISM OF INJURY</h5>
                                        <span className="material-icons" data-dismiss="modal" aria-label="Close">
                                            close
                                        </span>
                                    </div>
                                    <div className="modal-body">
                                        <div className="yn_switch mb_15 mt-10">
                                            <div className="label-container">
                                                <p>ROAD TRAFFIC ACCIDENT </p>
                                            </div>
                                            <label for="road_traffic_accident" className="switch-container">
                                                <input hidden="" type="checkbox" onChange={(e) => switchChange(e)} name="road_traffic_accident" className="switch_check" id="road_traffic_accident" />
                                                <div className="switch-bg"></div>
                                                <div className="round-box"></div>
                                                <div className="switch-left">
                                                    <span>YES</span>
                                                </div>
                                                <div className="switch-right">
                                                    <span>NO</span>
                                                </div>
                                            </label>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form_group mb-0">
                                                    <label className="label">Fall</label>
                                                    <input type="text" onChange={(e) => updateInputValue(e)} name="mechanism_fall" className="form-control" placeholder="Enter" />

                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form_group mb-0 input_style">
                                                    <label className="label">Fell from: Height</label>
                                                    <div className="measure tqt">
                                                        <input type="text" onChange={(e) => updateInputValue(e)} name="mechanism_fell_height" className="field" />
                                                        <span>ft</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="check_item mt-0"><label className="check">Penetrating
                                        </label>
                                            {patientValues.penetratingCheck ? <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input type="radio"
                                                    value={patientValues.penetratingCheck} checked /><span>{patientValues.penetratingCheck} </span></label>
                                                </div> {patientValues.penetratingCheck && <a onClick={(e) => updateClinicValues(e, "penetratingCheck", false)}><span className="material-symbols-outlined"> reply
                                                </span></a>}

                                            </div>
                                                :

                                                <div className="select_btns popup_select mt-0">
                                                    {penetrating && penetrating.length > 0 && penetrating.map((item, i) => (
                                                        <div className="select_btn" key={i}><label><input onClick={(e) => updateInputValue(e)} name={item.name} type="radio"
                                                            value={item.value} /><span>{item.value} </span></label></div>
                                                    ))}
                                                </div>
                                            }







                                        </div>
                                        <div className="check_item mt-0"><label className="check">Blunt
                                        </label>
                                            {patientValues.bluntCheck ? <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input type="radio"
                                                    value={patientValues.bluntCheck} checked /><span>{patientValues.bluntCheck} </span></label>
                                                </div> {patientValues.bluntCheck && <a onClick={(e) => updateClinicValues(e, "bluntCheck", false)}><span className="material-symbols-outlined"> reply
                                                </span></a>}

                                            </div>
                                                :

                                                <div className="select_btns popup_select mt-0">
                                                    {blunt && blunt.length > 0 && blunt.map((item, i) => (
                                                        <div className="select_btn" key={i}><label><input onClick={(e) => updateInputValue(e)} name={item.name} type="radio"
                                                            value={item.value} /><span>{item.value} </span></label></div>
                                                    ))}
                                                </div>
                                            }
                                        </div>
                                        <div className="check_item mt-0"><label className="check">Thermal
                                        </label>
                                            {patientValues.thermalCheck ? <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input type="radio"
                                                    value={patientValues.thermalCheck} checked /><span>{patientValues.thermalCheck} </span></label>
                                                </div> {patientValues.thermalCheck && <a onClick={(e) => updateClinicValues(e, "thermalCheck", false)}><span className="material-symbols-outlined"> reply
                                                </span></a>}

                                            </div>
                                                :

                                                <div className="select_btns popup_select mt-0">
                                                    {thermal && thermal.length > 0 && thermal.map((item, i) => (
                                                        <div className="select_btn" key={i}><label><input onClick={(e) => updateInputValue(e)} name={item.name} type="radio"
                                                            value={item.value} /><span>{item.value} </span></label></div>
                                                    ))}
                                                </div>
                                            }
                                        </div>
                                        <div className="check_item mt-0"><label className="check">Other
                                        </label>
                                            {patientValues.mechanism_otherCheck ? <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input type="radio"
                                                    value={patientValues.mechanism_otherCheck} checked /><span>{patientValues.mechanism_otherCheck} </span></label>
                                                </div> {patientValues.mechanism_otherCheck && <a onClick={(e) => updateClinicValues(e, "mechanism_otherCheck", false)}><span className="material-symbols-outlined"> reply
                                                </span></a>}

                                            </div>
                                                :

                                                <div className="select_btns popup_select mt-0">
                                                    {mechanism_other && mechanism_other.length > 0 && mechanism_other.map((item, i) => (
                                                        <div className="select_btn" key={i}><label><input onClick={(e) => updateInputValue(e)} name={item.name} type="radio"
                                                            value={item.value} /><span>{item.value} </span></label></div>
                                                    ))}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" data-dismiss="modal" onClick={(e) => { submitUpdate(e) }} className="btn">Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="modal fade addjob mechanism" id="treatment" tabIndex="-1" role="dialog"
                            aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">TREATMENT GIVEN SO FAR <span className="sub">(pre-hospital
                                            interventions)</span> </h5>
                                        <span className="material-icons" data-dismiss="modal" aria-label="Close">
                                            close
                                        </span>
                                    </div>
                                    <div className="modal-body">

                                        <div className="check_item mt-0">
                                        {patientValues.treatmentcollarCheck ? <div className="select_btns popup_select">
    <div className="select_btn"><label><input type="radio"
        value={patientValues.treatmentcollarCheck} checked /><span>{patientValues.treatmentcollarCheck} </span></label>
    </div> {patientValues.treatmentcollarCheck && <a onClick={(e) => updateClinicValues(e, "treatmentcollarCheck", false)}><span className="material-symbols-outlined"> reply
    </span></a>}

</div>
    :
    
    <div className="select_btns popup_select mt-0">
    {treatmentcollar && treatmentcollar.length > 0 && treatmentcollar.map((item, i) => (
            <div className="select_btn" key={i}><label><input onClick={(e) => updateInputValue(e)} name={item.name} type="radio"
            value={item.value} /><span>{item.value} </span></label></div>
        ))} 
    </div>
}
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form_group mb-0">
                                                    <label className="label">Bilateral Head Supports in place</label>
                                                    <input type="text" onChange={(e) => updateInputValue(e)} name="bilateral_head" className="form-control" placeholder="Enter" />

                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="check_item mt-0">
                                                    <label className="check">Backboard </label>
                                                    {patientValues.backboardcheckCheck ? <div className="select_btns popup_select">
    <div className="select_btn"><label><input type="radio"
        value={patientValues.backboardcheckCheck} checked /><span>{patientValues.backboardcheckCheck} </span></label>
    </div> {patientValues.backboardcheckCheck && <a onClick={(e) => updateClinicValues(e, "backboardcheckCheck", false)}><span className="material-symbols-outlined"> reply
    </span></a>}

</div>
    :
    
    <div className="select_btns popup_select mt-0">
    {backboardcheck && backboardcheck.length > 0 && backboardcheck.map((item, i) => (
            <div className="select_btn" key={i}><label><input onClick={(e) => updateInputValue(e)} name={item.name} type="radio"
            value={item.value} /><span>{item.value} </span></label></div>
        ))} 
    </div>
}
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="check_item mt-0 mb-10">
                                                    <label className="check">Airway </label>
                                                    {patientValues.treatment_airwayCheck ? <div className="select_btns popup_select">
    <div className="select_btn"><label><input type="radio"
        value={patientValues.treatment_airwayCheck} checked /><span>{patientValues.treatment_airwayCheck} </span></label>
    </div> {patientValues.treatment_airwayCheck && <a onClick={(e) => updateClinicValues(e, "treatment_airwayCheck", false)}><span className="material-symbols-outlined"> reply
    </span></a>}

</div>
    :
    
    <div className="select_btns popup_select mt-0">
    {treatment_airway && treatment_airway.length > 0 && treatment_airway.map((item, i) => (
            <div className="select_btn" key={i}><label><input onClick={(e) => updateInputValue(e)} name={item.name} type="radio"
            value={item.value} /><span>{item.value} </span></label></div>
        ))} 
    </div>
}
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form_group mb-0 input_style">
                                                    <label className="label">Intubated: ET tube # </label>
                                                    <div className="measure tqt">
                                                        <input type="text" onChange={(e) => updateInputValue(e)} name="intubated" className="field" />
                                                        <span>@</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form_group mb-0 input_style">
                                                    <label className="label">&nbsp; </label>
                                                    <div className="measure tqt">
                                                        <input type="text" onChange={(e) => updateInputValue(e)} name="intubated_measure" className="field" />
                                                        <span>cm</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form_group mb-0 input_style">
                                                    <label className="label">O2 @ </label>
                                                    <div className="measure tqt">
                                                        <input type="text" onChange={(e) => updateInputValue(e)} name="intubated_min"  className="field" />
                                                        <span>L/min</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form_group">
                                                    <label className="label">IV size </label>
                                                    <input type="text" onChange={(e) => updateInputValue(e)} name="intubated_iv" className="form-control" placeholder="Enter" />

                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form_group">
                                                    <label className="label">Site</label>
                                                    <input type="text" onChange={(e) => updateInputValue(e)} name="intubated_site" className="form-control" placeholder="Enter" />

                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form_group">
                                                    <label className="label">IV #2 size </label>
                                                    <input type="text"  onChange={(e) => updateInputValue(e)} name="intubated_iv2" className="form-control" placeholder="Enter" />

                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form_group">
                                                    <label className="label">Site</label>
                                                    <input type="text" onChange={(e) => updateInputValue(e)} name="intubated_iv2_site" className="form-control" placeholder="Enter" />

                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form_group">
                                                    <label className="label">Fluid infused-Type</label>
                                                    <input type="text" onChange={(e) => updateInputValue(e)} name="intubated_fluid" className="form-control" placeholder="Enter" />

                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form_group">
                                                    <label className="label">Amount</label>
                                                    <input type="text" onChange={(e) => updateInputValue(e)} name="intubated_amount" className="form-control" placeholder="Enter" />

                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form_group">
                                                    <label className="label">Medications</label>
                                                    <input type="text" onChange={(e) => updateInputValue(e)} name="intubated_medications" className="form-control" placeholder="Enter" />

                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <p className="field_title mt-0">Field vital signs</p>
                                                <div className="signature_block height152">
                                                <SignaturePad canvasProps={{ className: "sigPad" }} ref={(ref) => { fractureSigPad = ref }} />
                                                    <p>Signature</p>

                                                    
                                                </div>
                                                <button onClick={(e) => clear(e)}> Clear </button>
                                                <button onClick={(e) => trim(e)}> Trim </button>
                                            </div>
                                            <div className="col-md-6"></div>
                                            <div className="col-md-4">
                                                <div className="form_group mt-20 mb-0 input_style">
                                                    <label className="label">Blood sugar </label>
                                                    <div className="measure tqt">
                                                        <input type="text" onChange={(e) => updateInputValue(e)} name="intubated_blood_sugar" className="field" placeholder="Enter " />
                                                        <span>mg/dl</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="check-box sqeare mt-10">
                                            <div className="round">
                                                <input type="checkbox" onClick={(e) => selectCheckbox(e, "EtOH")} name="EtOH_check" value="DNACPR" id="EtOH_check" defaultChecked={patientValues && patientValues.EtOH && patientValues.EtOH["EtOH_check"] ? true : false} />
                                                <label for="EtOH_check"></label>
                                            </div>
                                            EtOH evident
                                        </div>
                                        <div className="check-box sqeare">
                                            <div className="round">
                                                <input type="checkbox" onClick={(e) => selectCheckbox(e, "hospitalarrest")} name="hospitalarrest_check" value="DNACPR" id="hospitalarrest_check" defaultChecked={patientValues && patientValues.hospitalarrest && patientValues.hospitalarrest["hospitalarrest_check"] ? true : false} />
                                                <label for="hospitalarrest_check"></label>
                                            </div>
                                            Pre Hospital arrest / LOC
                                        </div>
                                        <div className="row mt-10">
                                            <div className="col-md-4">
                                                <div className="form_group">
                                                    <label className="label">CPR started @ (time):</label>
                                                    <input type="time" onChange={(e) => updateInputValue(e)} name="intubated_cpr_time" className="form-control" placeholder="Enter" />

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" data-dismiss="modal" onClick={(e) => { submitUpdate(e) }}  className="btn">Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="modal fade addjob tc" id="adultgcs" tabIndex="-1" role="dialog"
                            aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">ADULT GCS <span className="sub">( reference)</span> </h5>
                                        <span className="material-icons" data-dismiss="modal" aria-label="Close">
                                            close
                                        </span>
                                    </div>
                                    <div className="modal-body">
                                        <table className="table table-bordered table_blue">
                                            <thead>
                                                <tr>
                                                    <th width="33%">Eye Opening</th>
                                                    <th width="33%">Voice</th>
                                                    <th width="33%">Motor</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        Spontaneous 4
                                                    </td>
                                                    <td>
                                                        Orientated 5
                                                    </td>
                                                    <td>
                                                        To Command 6
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        To command 3
                                                    </td>
                                                    <td>
                                                        Confused 4
                                                    </td>
                                                    <td>
                                                        Localises pain 5
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        To pain 2
                                                    </td>
                                                    <td>
                                                        Inappropriate 3
                                                    </td>
                                                    <td>
                                                        Withdraws 4
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        None 1
                                                    </td>
                                                    <td>
                                                        Incomprehensible 2
                                                    </td>
                                                    <td>
                                                        Flexes to pain 3
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>

                                                    </td>
                                                    <td>
                                                        None 1
                                                    </td>
                                                    <td>
                                                        Extends to pain 2
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>

                                                    </td>
                                                    <td>

                                                    </td>
                                                    <td>
                                                        None 1
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="modal fade addjob tc" id="PAEDIATRIC" tabIndex="-1" role="dialog"
                            aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">PAEDIATRIC GCS OVER 5 YEARS </h5>
                                        <span className="material-icons" data-dismiss="modal" aria-label="Close">
                                            close
                                        </span>
                                    </div>
                                    <div className="modal-body">
                                        <table className="table table-bordered table_blue">
                                            <thead>
                                                <tr>
                                                    <th width="20%">Eye Opening</th>
                                                    <th width="30%">Voice</th>
                                                    <th width="50%">Motor</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        Spontaneous 4
                                                    </td>
                                                    <td>
                                                        Orientated(In person or place)5
                                                    </td>
                                                    <td>
                                                        To Command 6
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        To voice3
                                                    </td>
                                                    <td>
                                                        Confused 4
                                                    </td>
                                                    <td>
                                                        Localises to supraorbital pain (&gt; 9mths) or withdraws to
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        To pain 2
                                                    </td>
                                                    <td>
                                                        Inappropriate words3
                                                    </td>
                                                    <td>
                                                        touch 5
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        None 1
                                                    </td>
                                                    <td>
                                                        Incomprehensible sounds 2
                                                    </td>
                                                    <td>
                                                        Withdraws from nail bed pain 4
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Eyes closed by swelling or
                                                    </td>
                                                    <td>
                                                        No verbal response to pain1
                                                    </td>
                                                    <td>
                                                        Flexes to supraorbital pain (Decerebrate) 3
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        bandage C
                                                    </td>
                                                    <td>
                                                        Intubated T
                                                    </td>
                                                    <td>
                                                        Extends to supraorbital pain (Decerebrate)2
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>

                                                    </td>
                                                    <td>

                                                    </td>
                                                    <td>
                                                        No response to supraorbital pain (Flaccid) 1
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <h3 className="popup_title mt-30 mb-10">PAEDIATRIC GCS UNDER 5 YEARS</h3>
                                        <table className="table table-bordered table_blue">
                                            <thead>
                                                <tr>
                                                    <th width="20%">Eye Opening</th>
                                                    <th width="30%">Voice</th>
                                                    <th width="50%">Motor</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        Spontaneous 4
                                                    </td>
                                                    <td>
                                                        Alert, babbles, coos, words or
                                                    </td>
                                                    <td>
                                                        Normal spontaneous movements 6
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        To voice3
                                                    </td>
                                                    <td>
                                                        sentences to usual ability5
                                                    </td>
                                                    <td>
                                                        Localises to supraorbital pain (&gt; 9mths) or withdraws to
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        To pain 2
                                                    </td>
                                                    <td>
                                                        Less than usual ability, Irritable cry 4
                                                    </td>
                                                    <td>
                                                        touch 5
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        None 1
                                                    </td>
                                                    <td>
                                                        Cries to pain3
                                                    </td>
                                                    <td>
                                                        Withdraws from nail bed pain 4
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Eyes closed by swelling or
                                                    </td>
                                                    <td>
                                                        Moans to pain 2
                                                    </td>
                                                    <td>
                                                        Flexes to supraorbital pain (Decerebrate) 3
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        bandage C
                                                    </td>
                                                    <td>
                                                        No Response to pain1
                                                    </td>
                                                    <td>
                                                        Extends to supraorbital pain (Decerebrate) 2
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>

                                                    </td>
                                                    <td>
                                                        Intubated T
                                                    </td>
                                                    <td>
                                                        No response to supraorbital pain (Flaccid) 1
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="modal fade addjob ss" id="secondary-survey" tabIndex="-1" role="dialog"
                            aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">Secondary survey </h5>
                                        <span className="material-icons" data-dismiss="modal" aria-label="Close">
                                            close
                                        </span>
                                    </div>
                                    <div className="modal-body">
                                        <div className="row mb-20">
                                            <div className="col-md-9">
                                                <p className="sub_title"> Evaluate and state findings</p>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form_group mb-0">
                                                    <label className="label">Time</label>
                                                    <input type="time" onChange={(e) => updateInputValue(e)} name="trauma_Evaluate_time" className="form-control" />

                                                </div>
                                            </div>
                                        </div>
                                        <div className="tbl_block">
                                            <div className="row m-0">
                                                <div className="col-sm-4 col1">
                                                    <p className="field_title mb-0 mt-0">AREA</p>
                                                </div>
                                                <div className="col-sm-3 col2">
                                                    <p className="mb-0">WNL</p>
                                                </div>
                                                <div className="col-sm-5 col3">
                                                    <div className="check-box sqeare mt-0 mb-0">
                                                        <div className="round">
                                                            <input type="checkbox" onClick={(e) => selectCheckbox(e, "areawnl")} name="areawnl_Check" value="Details of Abnormalities" id="areawnl_Check" defaultChecked={patientValues && patientValues.areawnl && patientValues.areawnl["areawnl_Check"] ? true : false} />
                                                            <label for="areawnl_Check"></label>
                                                        </div>
                                                        Details of Abnormalities
                                                    </div>
                                                </div>
                                                <div className="col-sm-4 col1">
                                                    <p className="field_title mb-0 mt-0">SKIN</p>
                                                </div>
                                                <div className="col-sm-3 col2">
                                                    <p className="mb-0">No Injuries</p>
                                                </div>
                                                <div className="col-sm-5 col3">
                                                    <div className="check-box sqeare mt-0 mb-0">
                                                        <div className="round">
                                                            <input type="checkbox" onClick={(e) => selectCheckbox(e, "skin")} name="skin_Check" value="Wounds" id="skin_Check" defaultChecked={patientValues && patientValues.skin && patientValues.skin["skin_Check"] ? true : false} />
                                                            <label for="skin_Check"></label>
                                                        </div>
                                                        Wounds
                                                    </div>
                                                </div>
                                                <div className="col-sm-4 col1">
                                                    <p className="field_title mb-0 mt-0"> HEAD + SCALP</p>
                                                </div>
                                                <div className="col-sm-3 col2">
                                                    <p className="mb-0">No Injuries</p>
                                                </div>
                                                <div className="col-sm-5 col3">
                                                    <div className="check_item mt-0">
                                                        {patientValues.headslapCheck ? <div className="select_btns popup_select">
                                                            <div className="select_btn"><label><input type="radio"
                                                                value={patientValues.headslapCheck} checked /><span>{patientValues.headslapCheck} </span></label>
                                                            </div> {patientValues.headslapCheck && <a onClick={(e) => updateClinicValues(e, "headslapCheck", false)}><span className="material-symbols-outlined"> reply
                                                            </span></a>}

                                                        </div>
                                                            :

                                                            <div className="select_btns popup_select mt-0">
                                                                {headslap && headslap.length > 0 && headslap.map((item, i) => (
                                                                    <div className="select_btn" key={i}><label><input onClick={(e) => updateInputValue(e)} name={item.name} type="radio"
                                                                        value={item.value} /><span>{item.value} </span></label></div>
                                                                ))}
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="check-box sqeare mt-0 ">
                                                        <div className="round">
                                                            <input type="checkbox" onClick={(e) => selectCheckbox(e, "battle_sign")} name="battle_sign_Check" value="Battle Signs" id="battle_sign_Check" defaultChecked={patientValues && patientValues.battle_sign && patientValues.battle_sign["battle_sign_Check"] ? true : false} />
                                                            <label for="battle_sign_Check"></label>
                                                        </div>
                                                        Battle Signs
                                                    </div>
                                                    <div className="check_item mt-0">
                                                        {patientValues.headslaplacCheck ? <div className="select_btns popup_select">
                                                            <div className="select_btn"><label><input type="radio"
                                                                value={patientValues.headslaplacCheck} checked /><span>{patientValues.headslaplacCheck} </span></label>
                                                            </div> {patientValues.headslaplacCheck && <a onClick={(e) => updateClinicValues(e, "headslaplacCheck", false)}><span className="material-symbols-outlined"> reply
                                                            </span></a>}

                                                        </div>
                                                            :

                                                            <div className="select_btns popup_select mt-0">
                                                                {headslaplac && headslaplac.length > 0 && headslaplac.map((item, i) => (
                                                                    <div className="select_btn" key={i}><label><input onClick={(e) => updateInputValue(e)} name={item.name} type="radio"
                                                                        value={item.value} /><span>{item.value} </span></label></div>
                                                                ))}
                                                            </div>
                                                        }

                                                    </div>
                                                </div>
                                                <div className="col-sm-4 col1">
                                                    <p className="field_title mb-0 mt-0"> OCULAR/PERIORBITAL INTEGRITY</p>
                                                </div>
                                                <div className="col-sm-3 col2">
                                                    <p className="mb-0">No Injuries </p>
                                                </div>
                                                <div className="col-sm-5 col3">
                                                    <div className="check-box sqeare mt-10">
                                                        <div className="round">
                                                            <input type="checkbox" onClick={(e) => selectCheckbox(e, "occular")} name="occular_Check" value="Pearl" id="occular_Check" defaultChecked={patientValues && patientValues.occular && patientValues.occular["occular_Check"] ? true : false} />
                                                            <label for="occular_Check"></label>
                                                        </div>
                                                        Pearl
                                                    </div>
                                                    <div className="check-box sqeare mt-10">
                                                        <div className="round">
                                                            <input type="checkbox" onClick={(e) => selectCheckbox(e, "raccoon")} name="raccoon_Check" value="Raccoon eyes" id="raccoon_Check" defaultChecked={patientValues && patientValues.raccoon && patientValues.raccoon["raccoon_Check"] ? true : false} />
                                                            <label for="raccoon_Check"></label>
                                                        </div>
                                                        Raccoon eyes
                                                    </div>


                                                    <div className="form_group">
                                                        <label className="label">Extra Ocular Muscles: follows </label>
                                                        <input type="text" onChange={(e) => updateInputValue(e)} name="extra_ocular" className="form-control" placeholder="Enter" />
                                                    </div>
                                                    <div className="check_item mt-0">
                                                        <label className="check">Visual Acuity
                                                        </label>
                                                        {patientValues.visualacultylacCheck ? <div className="select_btns popup_select">
                                                            <div className="select_btn"><label><input type="radio"
                                                                value={patientValues.visualacultylacCheck} checked /><span>{patientValues.visualacultylacCheck} </span></label>
                                                            </div> {patientValues.visualacultylacCheck && <a onClick={(e) => updateClinicValues(e, "visualacultylacCheck", false)}><span className="material-symbols-outlined"> reply
                                                            </span></a>}

                                                        </div>
                                                            :

                                                            <div className="select_btns popup_select mt-0">
                                                                {visualacultylac && visualacultylac.length > 0 && visualacultylac.map((item, i) => (
                                                                    <div className="select_btn" key={i}><label><input onClick={(e) => updateInputValue(e)} name={item.name} type="radio"
                                                                        value={item.value} /><span>{item.value} </span></label></div>
                                                                ))}
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="col-sm-4 col1">
                                                    <p className="field_title mb-0 mt-0"> EARS/NOSE</p>
                                                </div>
                                                <div className="col-sm-3 col2">
                                                    <p className="mb-0">No Injuries </p>
                                                </div>
                                                <div className="col-sm-5 col3">
                                                    <div className="check-box sqeare mt-10">
                                                        <div className="round">
                                                            <input type="checkbox" onClick={(e) => selectCheckbox(e, "drainage")} name="drainage_Check" value="No drainage" id="drainage_Check" defaultChecked={patientValues && patientValues.drainage && patientValues.drainage["drainage_Check"] ? true : false} />
                                                            <label for="drainage_Check"></label>
                                                        </div>
                                                        No drainage
                                                    </div>
                                                    <div className="check_item mt-0">
                                                        <label className="check">Right Ear
                                                        </label>




                                                        {patientValues.rightearCheck ? <div className="select_btns popup_select">
                                                            <div className="select_btn"><label><input type="radio"
                                                                value={patientValues.rightearCheck} checked /><span>{patientValues.rightearCheck} </span></label>
                                                            </div> {patientValues.rightearCheck && <a onClick={(e) => updateClinicValues(e, "rightearCheck", false)}><span className="material-symbols-outlined"> reply
                                                            </span></a>}

                                                        </div>
                                                            :

                                                            <div className="select_btns popup_select mt-0">
                                                                {rightear && rightear.length > 0 && rightear.map((item, i) => (
                                                                    <div className="select_btn" key={i}><label><input onClick={(e) => updateInputValue(e)} name={item.name} type="radio"
                                                                        value={item.value} /><span>{item.value} </span></label></div>
                                                                ))}
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="check_item mt-0">
                                                        <label className="check">Left Ear
                                                        </label>
                                                        {patientValues.leftearCheck ? <div className="select_btns popup_select">
                                                            <div className="select_btn"><label><input type="radio"
                                                                value={patientValues.leftearCheck} checked /><span>{patientValues.leftearCheck} </span></label>
                                                            </div> {patientValues.leftearCheck && <a onClick={(e) => updateClinicValues(e, "leftearCheck", false)}><span className="material-symbols-outlined"> reply
                                                            </span></a>}

                                                        </div>
                                                            :

                                                            <div className="select_btns popup_select mt-0">
                                                                {leftear && leftear.length > 0 && leftear.map((item, i) => (
                                                                    <div className="select_btn" key={i}><label><input onClick={(e) => updateInputValue(e)} name={item.name} type="radio"
                                                                        value={item.value} /><span>{item.value} </span></label></div>
                                                                ))}
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="check_item mt-0">
                                                        <label className="check">Nose
                                                        </label>
                                                        {patientValues.noselistCheck ? <div className="select_btns popup_select">
                                                            <div className="select_btn"><label><input type="radio"
                                                                value={patientValues.noselistCheck} checked /><span>{patientValues.noselistCheck} </span></label>
                                                            </div> {patientValues.noselistCheck && <a onClick={(e) => updateClinicValues(e, "noselistCheck", false)}><span className="material-symbols-outlined"> reply
                                                            </span></a>}

                                                        </div>
                                                            :

                                                            <div className="select_btns popup_select mt-0">
                                                                {noselist && noselist.length > 0 && noselist.map((item, i) => (
                                                                    <div className="select_btn" key={i}><label><input onClick={(e) => updateInputValue(e)} name={item.name} type="radio"
                                                                        value={item.value} /><span>{item.value} </span></label></div>
                                                                ))}
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="form_group">
                                                        <label className="label">Other injury</label>
                                                        <input type="text" onChange={(e) => updateInputValue(e)} name="earnose_otherinjury" className="form-control" placeholder="Enter" />
                                                    </div>

                                                </div>
                                                <div className="col-sm-4 col1">
                                                    <p className="field_title mb-0 mt-0">MAXILLO FACIAL</p>
                                                </div>
                                                <div className="col-sm-3 col2">
                                                    <p className="mb-0">No Injuries </p>
                                                </div>
                                                <div className="col-sm-5 col3">

                                                    <div className="form_group">
                                                        <label className="label">Other</label>
                                                        <input type="text" onChange={(e) => updateInputValue(e)} name="maxillo" className="form-control" placeholder="Enter" />
                                                    </div>

                                                </div>
                                                <div className="col-sm-4 col1">
                                                    <p className="field_title mb-0 mt-0">MOUTH</p>
                                                </div>
                                                <div className="col-sm-3 col2">
                                                    <p className="mb-0">No Injuries </p>
                                                </div>
                                                <div className="col-sm-5 col3">
                                                    <div className="check-box sqeare mt-10">
                                                        <div className="round">
                                                            <input type="checkbox" onClick={(e) => selectCheckbox(e, "teethintact")} name="teethintact_Check" value="Teeth Intact" id="teethintact_Check" defaultChecked={patientValues && patientValues.teethintact && patientValues.teethintact["teethintact_Check"] ? true : false} />
                                                            <label for="teethintact_Check"></label>
                                                        </div>
                                                        Teeth Intact
                                                    </div>

                                                    <div className="check_item mt-0">
                                                        <label className="check">Missing teeth </label>
                                                        {patientValues.missingteethCheck ? <div className="select_btns popup_select">
                                                            <div className="select_btn"><label><input type="radio"
                                                                value={patientValues.missingteethCheck} checked /><span>{patientValues.missingteethCheck} </span></label>
                                                            </div> {patientValues.missingteethCheck && <a onClick={(e) => updateClinicValues(e, "missingteethCheck", false)}><span className="material-symbols-outlined"> reply
                                                            </span></a>}

                                                        </div>
                                                            :

                                                            <div className="select_btns popup_select mt-0">
                                                                {missingteeth && missingteeth.length > 0 && missingteeth.map((item, i) => (
                                                                    <div className="select_btn" key={i}><label><input onClick={(e) => updateInputValue(e)} name={item.name} type="radio"
                                                                        value={item.value} /><span>{item.value} </span></label></div>
                                                                ))}
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="check-box sqeare mt-10">
                                                        <div className="round">
                                                            <input type="checkbox" onClick={(e) => selectCheckbox(e, "denturesintact")} name="denturesintact_Check" value="Dentures intact" id="denturesintact_Check" defaultChecked={patientValues && patientValues.denturesintact && patientValues.denturesintact["denturesintact_Check"] ? true : false} />
                                                            <label for="denturesintact_Check"></label>
                                                        </div>
                                                        Dentures intact
                                                    </div>
                                                </div>
                                                <div className="col-sm-4 col1">
                                                    <p className="field_title mb-0 mt-0">NECK/C SPINE</p>
                                                </div>
                                                <div className="col-sm-3 col2">
                                                    <p className="mb-0">No Injuries </p>
                                                </div>
                                                <div className="col-sm-5 col3">

                                                    <div className="check_item mt-0">

                                                        {patientValues.neckspineCheck ? <div className="select_btns popup_select">
                                                            <div className="select_btn"><label><input type="radio"
                                                                value={patientValues.neckspineCheck} checked /><span>{patientValues.neckspineCheck} </span></label>
                                                            </div> {patientValues.neckspineCheck && <a onClick={(e) => updateClinicValues(e, "neckspineCheck", false)}><span className="material-symbols-outlined"> reply
                                                            </span></a>}

                                                        </div>
                                                            :

                                                            <div className="select_btns popup_select mt-0">
                                                                {neckspine && neckspine.length > 0 && neckspine.map((item, i) => (
                                                                    <div className="select_btn" key={i}><label><input onClick={(e) => updateInputValue(e)} name={item.name} type="radio"
                                                                        value={item.value} /><span>{item.value} </span></label></div>
                                                                ))}
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="check_list w_white mb-0">
                                                        <span className="check_btn2" onClick={(e) => updateClinicValues(e, "neckspine_checklist_flag", true)}><span className="material-icons"> done </span></span></div>


                                                    {patientValues.neckspine_checklist_flag && patientValues.neckspine_checklist && Object.keys(patientValues.neckspine_checklist).length > 0 ? <div className="check_list w_white mb-0">

                                                        {Object.keys(patientValues.neckspine_checklist).map((item, i) => (
                                                            <div className="check_item active" key={i} onClick={(e) => updateClinicValues(e, "neckspine_checklist_flag", false)} ><label className="check">{patientValues.neckspine_checklist[item]}</label></div>
                                                        ))}

                                                    </div> : <>

                                                        {neckspine_checklist && neckspine_checklist.length > 0 && neckspine_checklist.map((item, i) => (

                                                            <div className="check-box sqeare" key={i}>
                                                                <div className="round">
                                                                    <input type="checkbox" onClick={(e) => selectCheckbox(e, "neckspine_checklist")} name={item.name} value={item.value} id={item.name} defaultChecked={patientValues && patientValues.neckspine_checklist && patientValues.neckspine_checklist[item.name] ? true : false}
                                                                    />
                                                                    <label for={item.name}></label>
                                                                </div>
                                                                {item.value}
                                                            </div>


                                                        ))} </>}

                                                </div>
                                                <div className="col-sm-4 col1">
                                                    <p className="field_title mb-0 mt-0">CHEST/LUNGS</p>
                                                </div>
                                                <div className="col-sm-3 col2">
                                                    <p className="mb-0">No Injuries </p>
                                                </div>
                                                <div className="col-sm-5 col3">
                                                    <div className="check_item  mt-0">
                                                        {patientValues.chestlungsCheck ? <div className="select_btns popup_select">
                                                            <div className="select_btn"><label><input type="radio"
                                                                value={patientValues.chestlungsCheck} checked /><span>{patientValues.chestlungsCheck} </span></label>
                                                            </div> {patientValues.chestlungsCheck && <a onClick={(e) => updateClinicValues(e, "chestlungsCheck", false)}><span className="material-symbols-outlined"> reply
                                                            </span></a>}

                                                        </div>
                                                            :

                                                            <div className="select_btns popup_select mt-0">
                                                                {chestlungs && chestlungs.length > 0 && chestlungs.map((item, i) => (
                                                                    <div className="select_btn" key={i}><label><input onClick={(e) => updateInputValue(e)} name={item.name} type="radio"
                                                                        value={item.value} /><span>{item.value} </span></label></div>
                                                                ))}
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form_group">
                                                                <label className="label">Location</label>
                                                                <input type="text" onChange={(e) => updateInputValue(e)} name="chestlung_loc" className="form-control" placeholder="Enter" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form_group">
                                                                <label className="label">Crepitus</label>
                                                                <input type="text" onChange={(e) => updateInputValue(e)} name="chestlung_Crepitus" className="form-control" placeholder="Enter" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form_group">
                                                                <label className="label">Location</label>
                                                                <input type="text" onChange={(e) => updateInputValue(e)} name="chestlung_Crepitus_loc" className="form-control" placeholder="Enter" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div className="check_list w_white mb-0">
                                                        <span className="check_btn2" onClick={(e) => updateClinicValues(e, "chestlungs_checklist_flag", true)}><span className="material-icons"> done </span></span></div>


                                                    {patientValues.chestlungs_checklist_flag && patientValues.chestlungs_checklist && Object.keys(patientValues.chestlungs_checklist).length > 0 ? <div className="check_list w_white mb-0">

                                                        {Object.keys(patientValues.chestlungs_checklist).map((item, i) => (
                                                            <div className="check_item active" key={i} onClick={(e) => updateClinicValues(e, "chestlungs_checklist_flag", false)} ><label className="check">{patientValues.chestlungs_checklist[item]}</label></div>
                                                        ))}

                                                    </div> : <>

                                                        {chestlungs_checklist && chestlungs_checklist.length > 0 && chestlungs_checklist.map((item, i) => (

                                                            <div className="check-box sqeare" key={i}>
                                                                <div className="round">
                                                                    <input type="checkbox" onClick={(e) => selectCheckbox(e, "chestlungs_checklist")} name={item.name} value={item.value} id={item.name} defaultChecked={patientValues && patientValues.chestlungs_checklist && patientValues.chestlungs_checklist[item.name] ? true : false}
                                                                    />
                                                                    <label for={item.name}></label>
                                                                </div>
                                                                {item.value}
                                                            </div>


                                                        ))} </>}
                                                    <div className="check_item mt-0">



                                                        {patientValues.chestlungsSoundsCheck ? <div className="select_btns popup_select">
                                                            <div className="select_btn"><label><input type="radio"
                                                                value={patientValues.chestlungsSoundsCheck} checked /><span>{patientValues.chestlungsSoundsCheck} </span></label>
                                                            </div> {patientValues.chestlungsSoundsCheck && <a onClick={(e) => updateClinicValues(e, "chestlungsSoundsCheck", false)}><span className="material-symbols-outlined"> reply
                                                            </span></a>}

                                                        </div>
                                                            :

                                                            <div className="select_btns popup_select mt-0">
                                                                {chestlungsSounds && chestlungsSounds.length > 0 && chestlungsSounds.map((item, i) => (
                                                                    <div className="select_btn" key={i}><label><input onClick={(e) => updateInputValue(e)} name={item.name} type="radio"
                                                                        value={item.value} /><span>{item.value} </span></label></div>
                                                                ))}
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="check_item mt-0">

                                                        {patientValues.chestlungspainCheck ? <div className="select_btns popup_select">
                                                            <div className="select_btn"><label><input type="radio"
                                                                value={patientValues.chestlungspainCheck} checked /><span>{patientValues.chestlungspainCheck} </span></label>
                                                            </div> {patientValues.chestlungspainCheck && <a onClick={(e) => updateClinicValues(e, "chestlungspainCheck", false)}><span className="material-symbols-outlined"> reply
                                                            </span></a>}

                                                        </div>
                                                            :

                                                            <div className="select_btns popup_select mt-0">
                                                                {chestlungspain && chestlungspain.length > 0 && chestlungspain.map((item, i) => (
                                                                    <div className="select_btn" key={i}><label><input onClick={(e) => updateInputValue(e)} name={item.name} type="radio"
                                                                        value={item.value} /><span>{item.value} </span></label></div>
                                                                ))}
                                                            </div>
                                                        }
                                                    </div>
                                                </div>

                                                <div className="col-sm-4 col1">
                                                    <p className="field_title mb-0 mt-0">ABDOMEN</p>
                                                </div>
                                                <div className="col-sm-3 col2">
                                                    <p className="mb-0">No Injuries </p>
                                                </div>
                                                <div className="col-sm-5 col3">
                                                    <div className="check-box sqeare mt-10">
                                                        <div className="round">
                                                            <input type="checkbox" onClick={(e) => selectCheckbox(e, "abdomen")} name="abdomen_Check" value="Pain" id="abdomen_Check" defaultChecked={patientValues && patientValues.abdomen && patientValues.abdomen["abdomen_Check"] ? true : false} />
                                                            <label for="abdomen_Check"></label>
                                                        </div>
                                                        Pain
                                                    </div>
                                                    <div className="check_item mt-0">

                                                        {patientValues.abodmensoftCheck ? <div className="select_btns popup_select">
                                                            <div className="select_btn"><label><input type="radio"
                                                                value={patientValues.abodmensoftCheck} checked /><span>{patientValues.abodmensoftCheck} </span></label>
                                                            </div> {patientValues.abodmensoftCheck && <a onClick={(e) => updateClinicValues(e, "abodmensoftCheck", false)}><span className="material-symbols-outlined"> reply
                                                            </span></a>}

                                                        </div>
                                                            :

                                                            <div className="select_btns popup_select mt-0">
                                                                {abodmensoft && abodmensoft.length > 0 && abodmensoft.map((item, i) => (
                                                                    <div className="select_btn" key={i}><label><input onClick={(e) => updateInputValue(e)} name={item.name} type="radio"
                                                                        value={item.value} /><span>{item.value} </span></label></div>
                                                                ))}
                                                            </div>
                                                        }



                                                    </div>
                                                    <div className="check_item mt-0">
                                                        <label className="check">Bowel Sounds</label>
                                                        {patientValues.abodmenbowelCheck ? <div className="select_btns popup_select">
                                                            <div className="select_btn"><label><input type="radio"
                                                                value={patientValues.abodmenbowelCheck} checked /><span>{patientValues.abodmenbowelCheck} </span></label>
                                                            </div> {patientValues.abodmenbowelCheck && <a onClick={(e) => updateClinicValues(e, "abodmenbowelCheck", false)}><span className="material-symbols-outlined"> reply
                                                            </span></a>}

                                                        </div>
                                                            :

                                                            <div className="select_btns popup_select mt-0">
                                                                {abodmenbowel && abodmenbowel.length > 0 && abodmenbowel.map((item, i) => (
                                                                    <div className="select_btn" key={i}><label><input onClick={(e) => updateInputValue(e)} name={item.name} type="radio"
                                                                        value={item.value} /><span>{item.value} </span></label></div>
                                                                ))}
                                                            </div>
                                                        }
                                                    </div>
                                                    <button type="button" className="update_btn mb-10">Gastro occult</button>
                                                </div>
                                                <div className="col-sm-4 col1">
                                                    <p className="field_title mb-0 mt-0">PELVIS /GENITAL</p>
                                                </div>
                                                <div className="col-sm-3 col2">
                                                    <p className="mb-0">No Injuries </p>
                                                </div>
                                                <div className="col-sm-5 col3">
                                                    <div className="check_item mt-0">
                                                        {patientValues.pelvispainCheck ? <div className="select_btns popup_select">
                                                            <div className="select_btn"><label><input type="radio"
                                                                value={patientValues.pelvispainCheck} checked /><span>{patientValues.pelvispainCheck} </span></label>
                                                            </div> {patientValues.pelvispainCheck && <a onClick={(e) => updateClinicValues(e, "pelvispainCheck", false)}><span className="material-symbols-outlined"> reply
                                                            </span></a>}

                                                        </div>
                                                            :

                                                            <div className="select_btns popup_select mt-0">
                                                                {pelvispain && pelvispain.length > 0 && pelvispain.map((item, i) => (
                                                                    <div className="select_btn" key={i}><label><input onClick={(e) => updateInputValue(e)} name={item.name} type="radio"
                                                                        value={item.value} /><span>{item.value} </span></label></div>
                                                                ))}
                                                            </div>
                                                        }





                                                    </div>
                                                    <div className="check_item mt-0">
                                                        <label className="check">Pelvis </label>
                                                        {patientValues.pelvisstableCheck ? <div className="select_btns popup_select">
                                                            <div className="select_btn"><label><input type="radio"
                                                                value={patientValues.pelvisstableCheck} checked /><span>{patientValues.pelvisstableCheck} </span></label>
                                                            </div> {patientValues.pelvisstableCheck && <a onClick={(e) => updateClinicValues(e, "pelvisstableCheck", false)}><span className="material-symbols-outlined"> reply
                                                            </span></a>}

                                                        </div>
                                                            :

                                                            <div className="select_btns popup_select mt-0">
                                                                {pelvisstable && pelvisstable.length > 0 && pelvisstable.map((item, i) => (
                                                                    <div className="select_btn" key={i}><label><input onClick={(e) => updateInputValue(e)} name={item.name} type="radio"
                                                                        value={item.value} /><span>{item.value} </span></label></div>
                                                                ))}
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="check_list w_white mb-0">
                                                        <span className="check_btn2" onClick={(e) => updateClinicValues(e, "pelvis_check_flag", true)}><span className="material-icons"> done </span></span></div>


                                                    {patientValues.pelvis_check_flag && patientValues.pelvis_check && Object.keys(patientValues.pelvis_check).length > 0 ? <div className="check_list w_white mb-0">

                                                        {Object.keys(patientValues.pelvis_check).map((item, i) => (
                                                            <div className="check_item active" key={i} onClick={(e) => updateClinicValues(e, "pelvis_check_flag", false)} ><label className="check">{patientValues.pelvis_check[item]}</label></div>
                                                        ))}

                                                    </div> : <>

                                                        {pelvis_check && pelvis_check.length > 0 && pelvis_check.map((item, i) => (

                                                            <div className="check-box sqeare" key={i}>
                                                                <div className="round">
                                                                    <input type="checkbox" onClick={(e) => selectCheckbox(e, "pelvis_check")} name={item.name} value={item.value} id={item.name} defaultChecked={patientValues && patientValues.pelvis_check && patientValues.pelvis_check[item.name] ? true : false}
                                                                    />
                                                                    <label for={item.name}></label>
                                                                </div>
                                                                {item.value}
                                                            </div>


                                                        ))} </>}


                                                    <div className="check_item mt-0">
                                                        <label className="check">Rectal tone</label>
                                                        {patientValues.pelvisspresentCheck ? <div className="select_btns popup_select">
                                                            <div className="select_btn"><label><input type="radio"
                                                                value={patientValues.pelvisspresentCheck} checked /><span>{patientValues.pelvisspresentCheck} </span></label>
                                                            </div> {patientValues.pelvisspresentCheck && <a onClick={(e) => updateClinicValues(e, "pelvisspresentCheck", false)}><span className="material-symbols-outlined"> reply
                                                            </span></a>}

                                                        </div>
                                                            :

                                                            <div className="select_btns popup_select mt-0">
                                                                {pelvisspresent && pelvisspresent.length > 0 && pelvisspresent.map((item, i) => (
                                                                    <div className="select_btn" key={i}><label><input onClick={(e) => updateInputValue(e)} name={item.name} type="radio"
                                                                        value={item.value} /><span>{item.value} </span></label></div>
                                                                ))}
                                                            </div>
                                                        }
                                                    </div>
                                                    <button type="button" className="update_btn mb-10">Hemocult</button>
                                                </div>
                                                <div className="col-sm-4 col1">
                                                    <p className="field_title mb-0 mt-0">LOWER EXTREMITIES</p>
                                                </div>
                                                <div className="col-sm-3 col2">
                                                    <p className="mb-0">No Injuries </p>
                                                </div>
                                                <div className="col-sm-5 col3">
                                                    <div className="check_item mt-0">
                                                        {patientValues.lowerextremitiesCheck ? <div className="select_btns popup_select">
                                                            <div className="select_btn"><label><input type="radio"
                                                                value={patientValues.lowerextremitiesCheck} checked /><span>{patientValues.lowerextremitiesCheck} </span></label>
                                                            </div> {patientValues.lowerextremitiesCheck && <a onClick={(e) => updateClinicValues(e, "lowerextremitiesCheck", false)}><span className="material-symbols-outlined"> reply
                                                            </span></a>}

                                                        </div>
                                                            :

                                                            <div className="select_btns popup_select mt-0">
                                                                {lowerextremities && lowerextremities.length > 0 && lowerextremities.map((item, i) => (
                                                                    <div className="select_btn" key={i}><label><input onClick={(e) => updateInputValue(e)} name={item.name} type="radio"
                                                                        value={item.value} /><span>{item.value} </span></label></div>
                                                                ))}
                                                            </div>
                                                        }

                                                    </div>


                                                    <div className="check_list w_white mb-0">
                                                        <span className="check_btn2" onClick={(e) => updateClinicValues(e, "lower_check_flag", true)}><span className="material-icons"> done </span></span></div>


                                                    {patientValues.lower_check_flag && patientValues.lower_check && Object.keys(patientValues.lower_check).length > 0 ? <div className="check_list w_white mb-0">

                                                        {Object.keys(patientValues.lower_check).map((item, i) => (
                                                            <div className="check_item active" key={i} onClick={(e) => updateClinicValues(e, "lower_check_flag", false)} ><label className="check">{patientValues.lower_check[item]}</label></div>
                                                        ))}

                                                    </div> : <>

                                                        {lower_check && lower_check.length > 0 && lower_check.map((item, i) => (

                                                            <div className="check-box sqeare" key={i}>
                                                                <div className="round">
                                                                    <input type="checkbox" onClick={(e) => selectCheckbox(e, "lower_check")} name={item.name} value={item.value} id={item.name} defaultChecked={patientValues && patientValues.lower_check && patientValues.lower_check[item.name] ? true : false}
                                                                    />
                                                                    <label for={item.name}></label>
                                                                </div>
                                                                {item.value}
                                                            </div>


                                                        ))} </>}



                                                </div>
                                                <div className="col-sm-4 col1">
                                                    <p className="field_title mb-0 mt-0">UPPER EXTREMITIES</p>
                                                </div>
                                                <div className="col-sm-3 col2">
                                                    <p className="mb-0">No Injuries </p>
                                                </div>
                                                <div className="col-sm-5 col3">
                                                    <div className="check_item mt-0">
                                                        {patientValues.upperextremitiesCheck ? <div className="select_btns popup_select">
                                                            <div className="select_btn"><label><input type="radio"
                                                                value={patientValues.upperextremitiesCheck} checked /><span>{patientValues.upperextremitiesCheck} </span></label>
                                                            </div> {patientValues.upperextremitiesCheck && <a onClick={(e) => updateClinicValues(e, "upperextremitiesCheck", false)}><span className="material-symbols-outlined"> reply
                                                            </span></a>}

                                                        </div>
                                                            :

                                                            <div className="select_btns popup_select mt-0">
                                                                {upperextremities && upperextremities.length > 0 && upperextremities.map((item, i) => (
                                                                    <div className="select_btn" key={i}><label><input onClick={(e) => updateInputValue(e)} name={item.name} type="radio"
                                                                        value={item.value} /><span>{item.value} </span></label></div>
                                                                ))}
                                                            </div>
                                                        }
                                                    </div>

                                                    <div className="check_list w_white mb-0">
                                                        <span className="check_btn2" onClick={(e) => updateClinicValues(e, "upper_check_flag", true)}><span className="material-icons"> done </span></span></div>


                                                    {patientValues.upper_check_flag && patientValues.upper_check && Object.keys(patientValues.upper_check).length > 0 ? <div className="check_list w_white mb-0">

                                                        {Object.keys(patientValues.upper_check).map((item, i) => (
                                                            <div className="check_item active" key={i} onClick={(e) => updateClinicValues(e, "upper_check_flag", false)} ><label className="check">{patientValues.upper_check[item]}</label></div>
                                                        ))}

                                                    </div> : <>

                                                        {upper_check && upper_check.length > 0 && upper_check.map((item, i) => (

                                                            <div className="check-box sqeare" key={i}>
                                                                <div className="round">
                                                                    <input type="checkbox" onClick={(e) => selectCheckbox(e, "upper_check")} name={item.name} value={item.value} id={item.name} defaultChecked={patientValues && patientValues.upper_check && patientValues.upper_check[item.name] ? true : false}
                                                                    />
                                                                    <label for={item.name}></label>
                                                                </div>
                                                                {item.value}
                                                            </div>


                                                        ))} </>}



                                                </div>
                                                <div className="col-sm-4 col1">
                                                    <p className="field_title mb-0 mt-0">LOG ROLLING - CHECK BACK C-SPINE EXAM
                                                        BACK
                                                    </p>
                                                </div>
                                                <div className="col-sm-3 col2">
                                                    <p className="mb-0">No Injuries </p>
                                                </div>
                                                <div className="col-sm-5 col3">
                                                    <div className="check_item mt-0">
                                                        {patientValues.logrollingCheck ? <div className="select_btns popup_select">
                                                            <div className="select_btn"><label><input type="radio"
                                                                value={patientValues.logrollingCheck} checked /><span>{patientValues.logrollingCheck} </span></label>
                                                            </div> {patientValues.logrollingCheck && <a onClick={(e) => updateClinicValues(e, "logrollingCheck", false)}><span className="material-symbols-outlined"> reply
                                                            </span></a>}

                                                        </div>
                                                            :

                                                            <div className="select_btns popup_select mt-0">
                                                                {logrolling && logrolling.length > 0 && logrolling.map((item, i) => (
                                                                    <div className="select_btn" key={i}><label><input onClick={(e) => updateInputValue(e)} name={item.name} type="radio"
                                                                        value={item.value} /><span>{item.value} </span></label></div>
                                                                ))}
                                                            </div>
                                                        }
                                                    </div>

                                                    <div className="check-box sqeare mt-10">
                                                        <div className="round">
                                                            <input type="checkbox" onClick={(e) => selectCheckbox(e, "deformity")} name="deformity_Check" value="Deformity" id="deformity_Check" defaultChecked={patientValues && patientValues.deformity && patientValues.deformity["deformity_Check"] ? true : false} />
                                                            <label for="deformity_Check"></label>
                                                        </div>
                                                        Deformity
                                                    </div>

                                                </div>
                                            </div>
                                        </div>



                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" data-dismiss="modal" onClick={(e) => { submitUpdate(e) }} className="btn">Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="modal fade addjob ss pd" id="PRE-DEPARTURE" tabIndex="-1" role="dialog"
                            aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">PRE DEPARTURE CHECK LIST </h5>
                                        <span className="material-icons" data-dismiss="modal" aria-label="Close">
                                            close
                                        </span>
                                    </div>
                                    <div className="modal-body">
                                        <div className="row mb-20">
                                            <div className="col-md-9">
                                                <p className="sub_title">IMMEDIATELY AFTER PRIMARY & SECONDARY SURVEY</p>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form_group mb-0">
                                                    <label className="label">Time</label>
                                                    <input type="time" onChange={(e) => updateInputValue(e)} name="pre_depature_time" className="form-control" />

                                                </div>
                                            </div>
                                        </div>
                                        <div className="tbl_block">
                                            <div className="row m-0">
                                                <div className="col-sm-4 col1">
                                                    <p className="field_title mb-5 mt-0">It’s further airway intervention needed?
                                                    </p>
                                                    <p>May be needed if </p>
                                                    <ul>
                                                        <li>GCS 8 or below </li>
                                                        <li>Hypoxaemia are Hypercapnia </li>
                                                        <li>Face, Neck, Chest or any severe trauma</li>
                                                    </ul>
                                                </div>
                                                <div className="col-sm-3 col2">
                                                    <div className="check_item mt-0">

                                                    {patientValues.airwayinterventionCheck ? <div className="select_btns popup_select">
    <div className="select_btn"><label><input type="radio"
        value={patientValues.airwayinterventionCheck} checked /><span>{patientValues.airwayinterventionCheck} </span></label>
    </div> {patientValues.airwayinterventionCheck && <a onClick={(e) => updateClinicValues(e, "airwayinterventionCheck", false)}><span className="material-symbols-outlined"> reply
    </span></a>}

</div>
    :
    
    <div className="select_btns popup_select mt-0">
    {airwayintervention && airwayintervention.length > 0 && airwayintervention.map((item, i) => (
            <div className="select_btn" key={i}><label><input onClick={(e) => updateInputValue(e)} name={item.name} type="radio"
            value={item.value} /><span>{item.value} </span></label></div>
        ))} 
    </div>
}
                                                    </div>
                                                </div>
                                                <div className="col-sm-4 col1">
                                                    <p className="field_title mb-0 mt-0">Is there a tension pneumo – Haemothorax</p>

                                                </div>
                                                <div className="col-sm-3 col2">
                                                    <div className="check_item mb-0 mt-0">

                                                    {patientValues.HaemothoraxCheck ? <div className="select_btns popup_select">
    <div className="select_btn"><label><input type="radio"
        value={patientValues.HaemothoraxCheck} checked /><span>{patientValues.HaemothoraxCheck} </span></label>
    </div> {patientValues.HaemothoraxCheck && <a onClick={(e) => updateClinicValues(e, "HaemothoraxCheck", false)}><span className="material-symbols-outlined"> reply
    </span></a>}

</div>
    :
    
    <div className="select_btns popup_select mt-0">
    {Haemothorax && Haemothorax.length > 0 && Haemothorax.map((item, i) => (
            <div className="select_btn" key={i}><label><input onClick={(e) => updateInputValue(e)} name={item.name} type="radio"
            value={item.value} /><span>{item.value} </span></label></div>
        ))} 
    </div>
}
                                                    </div>
                                                </div>
                                                <div className="col-sm-4 col1">
                                                    <p className="field_title mb-0 mt-0">Is there pulse oximeter placed and
                                                        functioning</p>

                                                </div>
                                                <div className="col-sm-3 col2">
                                                    <div className="check_item mb-0 mt-0">
                                                    {patientValues.pulseoximeterCheck ? <div className="select_btns popup_select">
    <div className="select_btn"><label><input type="radio"
        value={patientValues.pulseoximeterCheck} checked /><span>{patientValues.pulseoximeterCheck} </span></label>
    </div> {patientValues.pulseoximeterCheck && <a onClick={(e) => updateClinicValues(e, "pulseoximeterCheck", false)}><span className="material-symbols-outlined"> reply
    </span></a>}

</div>
    :
    
    <div className="select_btns popup_select mt-0">
    {pulseoximeter && pulseoximeter.length > 0 && pulseoximeter.map((item, i) => (
            <div className="select_btn" key={i}><label><input onClick={(e) => updateInputValue(e)} name={item.name} type="radio"
            value={item.value} /><span>{item.value} </span></label></div>
        ))} 
    </div>
}
                                                    </div>
                                                </div>
                                                <div className="col-sm-4 col1">
                                                    <p className="field_title mb-0 mt-0">Large bore IV placed and fluids started</p>

                                                </div>
                                                <div className="col-sm-3 col2">
                                                    <div className="check_item mb-0 mt-0">

                                                    {patientValues.IVplacedCheck ? <div className="select_btns popup_select">
    <div className="select_btn"><label><input type="radio"
        value={patientValues.IVplacedCheck} checked /><span>{patientValues.IVplacedCheck} </span></label>
    </div> {patientValues.IVplacedCheck && <a onClick={(e) => updateClinicValues(e, "IVplacedCheck", false)}><span className="material-symbols-outlined"> reply
    </span></a>}

</div>
    :
    
    <div className="select_btns popup_select mt-0">
    {IVplaced && IVplaced.length > 0 && IVplaced.map((item, i) => (
            <div className="select_btn" key={i}><label><input onClick={(e) => updateInputValue(e)} name={item.name} type="radio"
            value={item.value} /><span>{item.value} </span></label></div>
        ))} 
    </div>
}
                                                    </div>
                                                </div>
                                                <div className="col-sm-4 col1">
                                                    <p className="field_title mb-0 mt-0">Full survey for (and control of) external
                                                        bleeding including</p>

                                                </div>
                                                <div className="col-sm-3 col2">
                                                    <div className="check_item mb-0 mt-0">

                                                    {patientValues.externalbleedingCheck ? <div className="select_btns popup_select">
    <div className="select_btn"><label><input type="radio"
        value={patientValues.externalbleedingCheck} checked /><span>{patientValues.externalbleedingCheck} </span></label>
    </div> {patientValues.externalbleedingCheck && <a onClick={(e) => updateClinicValues(e, "externalbleedingCheck", false)}><span className="material-symbols-outlined"> reply
    </span></a>}

</div>
    :
    
    <div className="select_btns popup_select mt-0">
    {externalbleeding && externalbleeding.length > 0 && externalbleeding.map((item, i) => (
            <div className="select_btn" key={i}><label><input onClick={(e) => updateInputValue(e)} name={item.name} type="radio"
            value={item.value} /><span>{item.value} </span></label></div>
        ))} 
    </div>
}
                                                    </div>
                                                </div>
                                                <div className="col-sm-4 col1">
                                                    <p className="field_title mb-0 mt-0">Assessed for pelvic fracture by</p>

                                                </div>
                                                <div className="col-sm-3 col2">
                                                    <div className="check_item mb-0 mt-0">

                                                    {patientValues.pelvicfractureCheck ? <div className="select_btns popup_select">
    <div className="select_btn"><label><input type="radio"
        value={patientValues.pelvicfractureCheck} checked /><span>{patientValues.pelvicfractureCheck} </span></label>
    </div> {patientValues.pelvicfractureCheck && <a onClick={(e) => updateClinicValues(e, "pelvicfractureCheck", false)}><span className="material-symbols-outlined"> reply
    </span></a>}

</div>
    :
    
    <div className="select_btns popup_select mt-0">
    {pelvicfracture && pelvicfracture.length > 0 && pelvicfracture.map((item, i) => (
            <div className="select_btn" key={i}><label><input onClick={(e) => updateInputValue(e)} name={item.name} type="radio"
            value={item.value} /><span>{item.value} </span></label></div>
        ))} 
    </div>
}
                                                    </div>
                                                </div>
                                                <div className="col-sm-4 col1">
                                                    <p className="field_title mb-0 mt-0">Assessed for internal bleeding by</p>

                                                </div>
                                                <div className="col-sm-3 col2">
                                                    <div className="check_item mb-0 mt-0">

                                                    {patientValues.internalbleedingCheck ? <div className="select_btns popup_select">
    <div className="select_btn"><label><input type="radio"
        value={patientValues.internalbleedingCheck} checked /><span>{patientValues.internalbleedingCheck} </span></label>
    </div> {patientValues.internalbleedingCheck && <a onClick={(e) => updateClinicValues(e, "internalbleedingCheck", false)}><span className="material-symbols-outlined"> reply
    </span></a>}

</div>
    :
    
    <div className="select_btns popup_select mt-0">
    {internalbleeding && internalbleeding.length > 0 && internalbleeding.map((item, i) => (
            <div className="select_btn" key={i}><label><input onClick={(e) => updateInputValue(e)} name={item.name} type="radio"
            value={item.value} /><span>{item.value} </span></label></div>
        ))} 
    </div>
}
                                                    </div>
                                                </div>
                                                <div className="col-sm-4 col1">
                                                    <p className="field_title mb-0 mt-0">Is spinal immobilisation needed</p>

                                                </div>
                                                <div className="col-sm-3 col2">
                                                    <div className="check_item mb-0 mt-0">

                                                    {patientValues.immobilisationCheck ? <div className="select_btns popup_select">
    <div className="select_btn"><label><input type="radio"
        value={patientValues.immobilisationCheck} checked /><span>{patientValues.immobilisationCheck} </span></label>
    </div> {patientValues.immobilisationCheck && <a onClick={(e) => updateClinicValues(e, "immobilisationCheck", false)}><span className="material-symbols-outlined"> reply
    </span></a>}

</div>
    :
    
    <div className="select_btns popup_select mt-0">
    {immobilisation && immobilisation.length > 0 && immobilisation.map((item, i) => (
            <div className="select_btn" key={i}><label><input onClick={(e) => updateInputValue(e)} name={item.name} type="radio"
            value={item.value} /><span>{item.value} </span></label></div>
        ))} 
    </div>
}

                                                    </div>
                                                </div>
                                                <div className="col-sm-4 col1">
                                                    <p className="field_title mb-0 mt-0">Neurovascular status of all four limbs
                                                        checked</p>

                                                </div>
                                                <div className="col-sm-3 col2">
                                                    <div className="check_item mb-0 mt-0">

                                                    {patientValues.neurovascularCheck ? <div className="select_btns popup_select">
    <div className="select_btn"><label><input type="radio"
        value={patientValues.neurovascularCheck} checked /><span>{patientValues.neurovascularCheck} </span></label>
    </div> {patientValues.neurovascularCheck && <a onClick={(e) => updateClinicValues(e, "neurovascularCheck", false)}><span className="material-symbols-outlined"> reply
    </span></a>}

</div>
    :
    
    <div className="select_btns popup_select mt-0">
    {neurovascular && neurovascular.length > 0 && neurovascular.map((item, i) => (
            <div className="select_btn" key={i}><label><input onClick={(e) => updateInputValue(e)} name={item.name} type="radio"
            value={item.value} /><span>{item.value} </span></label></div>
        ))} 
    </div>
}
                                                    </div>
                                                </div>
                                                <div className="col-sm-4 col1">
                                                    <p className="field_title mb-0 mt-0">Is the patient hypothermic</p>

                                                </div>
                                                <div className="col-sm-3 col2">
                                                    <div className="check_item mb-0 mt-0">

                                                    {patientValues.hypothermicCheck ? <div className="select_btns popup_select">
    <div className="select_btn"><label><input type="radio"
        value={patientValues.hypothermicCheck} checked /><span>{patientValues.hypothermicCheck} </span></label>
    </div> {patientValues.hypothermicCheck && <a onClick={(e) => updateClinicValues(e, "hypothermicCheck", false)}><span className="material-symbols-outlined"> reply
    </span></a>}

</div>
    :
    
    <div className="select_btns popup_select mt-0">
    {hypothermic && hypothermic.length > 0 && hypothermic.map((item, i) => (
            <div className="select_btn" key={i}><label><input onClick={(e) => updateInputValue(e)} name={item.name} type="radio"
            value={item.value} /><span>{item.value} </span></label></div>
        ))} 
    </div>
}
                                                    </div>
                                                </div>

                                                <div className="col-sm-4 col1">
                                                    <p className="field_title mb-0 mt-0">Does the patient need (if no
                                                        contraindications)</p>

                                                </div>
                                                <div className="col-sm-3 col2">
                                                    <div className="check_item mb-0 mt-0">

                                                    {patientValues.patientneedCheck ? <div className="select_btns popup_select">
    <div className="select_btn"><label><input type="radio"
        value={patientValues.patientneedCheck} checked /><span>{patientValues.patientneedCheck} </span></label>
    </div> {patientValues.patientneedCheck && <a onClick={(e) => updateClinicValues(e, "patientneedCheck", false)}><span className="material-symbols-outlined"> reply
    </span></a>}

</div>
    :
    
    <div className="select_btns popup_select mt-0">
    {patientneed && patientneed.length > 0 && patientneed.map((item, i) => (
            <div className="select_btn" key={i}><label><input onClick={(e) => updateInputValue(e)} name={item.name} type="radio"
            value={item.value} /><span>{item.value} </span></label></div>
        ))} 
    </div>
}

                                                    </div>
                                                </div>
                                            </div>
                                            <p className="field_title mt-20 mb-10">BEFORE TEAM LEAVES PATIENT</p>
                                            <div className="row m-0">
                                                <div className="col-sm-4 col1">
                                                    <p className="field_title mb-5 mt-0">Has the patient been given
                                                    </p>

                                                </div>
                                                <div className="col-sm-3 col2">
                                                    <div className="check_item mt-0">

                                                    {patientValues.leavepatienttetanusCheck ? <div className="select_btns popup_select">
    <div className="select_btn"><label><input type="radio"
        value={patientValues.leavepatienttetanusCheck} checked /><span>{patientValues.leavepatienttetanusCheck} </span></label>
    </div> {patientValues.leavepatienttetanusCheck && <a onClick={(e) => updateClinicValues(e, "leavepatienttetanusCheck", false)}><span className="material-symbols-outlined"> reply
    </span></a>}

</div>
    :
    
    <div className="select_btns popup_select mt-0">
    {leavepatienttetanus && leavepatienttetanus.length > 0 && leavepatienttetanus.map((item, i) => (
            <div className="select_btn" key={i}><label><input onClick={(e) => updateInputValue(e)} name={item.name} type="radio"
            value={item.value} /><span>{item.value} </span></label></div>
        ))} 
    </div>
}
                                                    </div>
                                                </div>
                                                <div className="col-sm-4 col1">
                                                    <p className="field_title mb-0 mt-0">Is there a tension pneumo – Haemothorax</p>

                                                </div>
                                                <div className="col-sm-3 col2">
                                                    <div className="check_item mb-0 mt-0">

                                                    {patientValues.tensionpneumoCheck ? <div className="select_btns popup_select">
    <div className="select_btn"><label><input type="radio"
        value={patientValues.tensionpneumoCheck} checked /><span>{patientValues.tensionpneumoCheck} </span></label>
    </div> {patientValues.tensionpneumoCheck && <a onClick={(e) => updateClinicValues(e, "tensionpneumoCheck", false)}><span className="material-symbols-outlined"> reply
    </span></a>}

</div>
    :
    
    <div className="select_btns popup_select mt-0">
    {tensionpneumo && tensionpneumo.length > 0 && tensionpneumo.map((item, i) => (
            <div className="select_btn" key={i}><label><input onClick={(e) => updateInputValue(e)} name={item.name} type="radio"
            value={item.value} /><span>{item.value} </span></label></div>
        ))} 
    </div>
}
                                                    </div>
                                                </div>
                                                <div className="col-sm-4 col1">
                                                    <p className="field_title mb-0 mt-0">Is there pulse oximeter placed and
                                                        functioning</p>

                                                </div>
                                                <div className="col-sm-3 col2">
                                                    <div className="check_item mb-0 mt-0">

                                                    {patientValues.pulseoximeterplacedCheck ? <div className="select_btns popup_select">
    <div className="select_btn"><label><input type="radio"
        value={patientValues.pulseoximeterplacedCheck} checked /><span>{patientValues.pulseoximeterplacedCheck} </span></label>
    </div> {patientValues.pulseoximeterplacedCheck && <a onClick={(e) => updateClinicValues(e, "pulseoximeterplacedCheck", false)}><span className="material-symbols-outlined"> reply
    </span></a>}

</div>
    :
    
    <div className="select_btns popup_select mt-0">
    {pulseoximeterplaced && pulseoximeterplaced.length > 0 && pulseoximeterplaced.map((item, i) => (
            <div className="select_btn" key={i}><label><input onClick={(e) => updateInputValue(e)} name={item.name} type="radio"
            value={item.value} /><span>{item.value} </span></label></div>
        ))} 
    </div>
}
                                                    </div>
                                                </div>
                                                <div className="col-sm-4 col1">
                                                    <p className="field_title mb-0 mt-0">Large bore IV placed and fluids started</p>

                                                </div>
                                                <div className="col-sm-3 col2">
                                                    <div className="check_item mb-0 mt-0">

                                                    {patientValues.fluidsstartedCheck ? <div className="select_btns popup_select">
    <div className="select_btn"><label><input type="radio"
        value={patientValues.fluidsstartedCheck} checked /><span>{patientValues.fluidsstartedCheck} </span></label>
    </div> {patientValues.fluidsstartedCheck && <a onClick={(e) => updateClinicValues(e, "fluidsstartedCheck", false)}><span className="material-symbols-outlined"> reply
    </span></a>}

</div>
    :
    
    <div className="select_btns popup_select mt-0">
    {fluidsstarted && fluidsstarted.length > 0 && fluidsstarted.map((item, i) => (
            <div className="select_btn" key={i}><label><input onClick={(e) => updateInputValue(e)} name={item.name} type="radio"
            value={item.value} /><span>{item.value} </span></label></div>
        ))} 
    </div>
}
                                                    </div>
                                                </div>
                                                <div className="col-sm-4 col1">
                                                    <p className="field_title mb-0 mt-0">Full survey for (and control of) external
                                                        bleeding including</p>

                                                </div>
                                                <div className="col-sm-3 col2">
                                                    <div className="check_item mb-0 mt-0">

                                                    {patientValues.surveybleedingCheck ? <div className="select_btns popup_select">
    <div className="select_btn"><label><input type="radio"
        value={patientValues.surveybleedingCheck} checked /><span>{patientValues.surveybleedingCheck} </span></label>
    </div> {patientValues.surveybleedingCheck && <a onClick={(e) => updateClinicValues(e, "surveybleedingCheck", false)}><span className="material-symbols-outlined"> reply
    </span></a>}

</div>
    :
    
    <div className="select_btns popup_select mt-0">
    {surveybleeding && surveybleeding.length > 0 && surveybleeding.map((item, i) => (
            <div className="select_btn" key={i}><label><input onClick={(e) => updateInputValue(e)} name={item.name} type="radio"
            value={item.value} /><span>{item.value} </span></label></div>
        ))} 
    </div>
}
                                                    </div>
                                                </div>
                                                <div className="col-sm-4 col1">
                                                    <p className="field_title mb-0 mt-0">Assessed for pelvic fracture by</p>

                                                </div>
                                                <div className="col-sm-3 col2">
                                                    <div className="check_item mb-0 mt-0">

                                                    {patientValues.assessedpelvicCheck ? <div className="select_btns popup_select">
    <div className="select_btn"><label><input type="radio"
        value={patientValues.assessedpelvicCheck} checked /><span>{patientValues.assessedpelvicCheck} </span></label>
    </div> {patientValues.assessedpelvicCheck && <a onClick={(e) => updateClinicValues(e, "assessedpelvicCheck", false)}><span className="material-symbols-outlined"> reply
    </span></a>}

</div>
    :
    
    <div className="select_btns popup_select mt-0">
    {assessedpelvic && assessedpelvic.length > 0 && assessedpelvic.map((item, i) => (
            <div className="select_btn" key={i}><label><input onClick={(e) => updateInputValue(e)} name={item.name} type="radio"
            value={item.value} /><span>{item.value} </span></label></div>
        ))} 
    </div>
}
                                                    </div>
                                                </div>
                                                <div className="col-sm-4 col1">
                                                    <p className="field_title mb-0 mt-0">Assessed for internal bleeding by</p>

                                                </div>
                                                <div className="col-sm-3 col2">
                                                    <div className="check_item mb-0 mt-0">

                                                    {patientValues.assessedinternalCheck ? <div className="select_btns popup_select">
    <div className="select_btn"><label><input type="radio"
        value={patientValues.assessedinternalCheck} checked /><span>{patientValues.assessedinternalCheck} </span></label>
    </div> {patientValues.assessedinternalCheck && <a onClick={(e) => updateClinicValues(e, "assessedinternalCheck", false)}><span className="material-symbols-outlined"> reply
    </span></a>}

</div>
    :
    
    <div className="select_btns popup_select mt-0">
    {assessedinternal && assessedinternal.length > 0 && assessedinternal.map((item, i) => (
            <div className="select_btn" key={i}><label><input onClick={(e) => updateInputValue(e)} name={item.name} type="radio"
            value={item.value} /><span>{item.value} </span></label></div>
        ))} 
    </div>
}
                                                    </div>
                                                </div>
                                                <div className="col-sm-4 col1">
                                                    <p className="field_title mb-0 mt-0">Is spinal immobilisation needed</p>

                                                </div>
                                                <div className="col-sm-3 col2">
                                                    <div className="check_item mb-0 mt-0">

                                                    {patientValues.spinalimmobilisationCheck ? <div className="select_btns popup_select">
    <div className="select_btn"><label><input type="radio"
        value={patientValues.spinalimmobilisationCheck} checked /><span>{patientValues.spinalimmobilisationCheck} </span></label>
    </div> {patientValues.spinalimmobilisationCheck && <a onClick={(e) => updateClinicValues(e, "spinalimmobilisationCheck", false)}><span className="material-symbols-outlined"> reply
    </span></a>}

</div>
    :
    
    <div className="select_btns popup_select mt-0">
    {spinalimmobilisation && spinalimmobilisation.length > 0 && spinalimmobilisation.map((item, i) => (
            <div className="select_btn" key={i}><label><input onClick={(e) => updateInputValue(e)} name={item.name} type="radio"
            value={item.value} /><span>{item.value} </span></label></div>
        ))} 
    </div>
}
                                                    </div>
                                                </div>
                                                <div className="col-sm-4 col1">
                                                    <p className="field_title mb-0 mt-0">Neurovascular status of all four limbs
                                                        checked</p>

                                                </div>
                                                <div className="col-sm-3 col2">
                                                    <div className="check_item mb-0 mt-0">

                                                    {patientValues.neurovascularstatusCheck ? <div className="select_btns popup_select">
    <div className="select_btn"><label><input type="radio"
        value={patientValues.neurovascularstatusCheck} checked /><span>{patientValues.neurovascularstatusCheck} </span></label>
    </div> {patientValues.neurovascularstatusCheck && <a onClick={(e) => updateClinicValues(e, "neurovascularstatusCheck", false)}><span className="material-symbols-outlined"> reply
    </span></a>}

</div>
    :
    
    <div className="select_btns popup_select mt-0">
    {neurovascularstatus && neurovascularstatus.length > 0 && neurovascularstatus.map((item, i) => (
            <div className="select_btn" key={i}><label><input onClick={(e) => updateInputValue(e)} name={item.name} type="radio"
            value={item.value} /><span>{item.value} </span></label></div>
        ))} 
    </div>
}

                                                    </div>
                                                </div>
                                                <div className="col-sm-4 col1">
                                                    <p className="field_title mb-0 mt-0">Is the patient hypothermic</p>

                                                </div>
                                                <div className="col-sm-3 col2">
                                                    <div className="check_item mb-0 mt-0">

                                                    {patientValues.patienthypothermicCheck ? <div className="select_btns popup_select">
    <div className="select_btn"><label><input type="radio"
        value={patientValues.patienthypothermicCheck} checked /><span>{patientValues.patienthypothermicCheck} </span></label>
    </div> {patientValues.patienthypothermicCheck && <a onClick={(e) => updateClinicValues(e, "patienthypothermicCheck", false)}><span className="material-symbols-outlined"> reply
    </span></a>}

</div>
    :
    
    <div className="select_btns popup_select mt-0">
    {patienthypothermic && patienthypothermic.length > 0 && patienthypothermic.map((item, i) => (
            <div className="select_btn" key={i}><label><input onClick={(e) => updateInputValue(e)} name={item.name} type="radio"
            value={item.value} /><span>{item.value} </span></label></div>
        ))} 
    </div>
}
                                                    </div>
                                                </div>

                                                <div className="col-sm-4 col1">
                                                    <p className="field_title mb-0 mt-0">Does the patient need (if no
                                                        contraindications)</p>

                                                </div>
                                                <div className="col-sm-3 col2">
                                                    <div className="check_item mb-0 mt-0">

                                                    {patientValues.contraindicationsCheck ? <div className="select_btns popup_select">
    <div className="select_btn"><label><input type="radio"
        value={patientValues.contraindicationsCheck} checked /><span>{patientValues.contraindicationsCheck} </span></label>
    </div> {patientValues.contraindicationsCheck && <a onClick={(e) => updateClinicValues(e, "contraindicationsCheck", false)}><span className="material-symbols-outlined"> reply
    </span></a>}

</div>
    :
    
    <div className="select_btns popup_select mt-0">
    {contraindications && contraindications.length > 0 && contraindications.map((item, i) => (
            <div className="select_btn" key={i}><label><input onClick={(e) => updateInputValue(e)} name={item.name} type="radio"
            value={item.value} /><span>{item.value} </span></label></div>
        ))} 
    </div>
}
                                                    </div>
                                                </div>
                                                <div className="col-sm-4 col1">
                                                    <p className="field_title mb-0 mt-0">Have all tests and imaging been reviewed
                                                    </p>

                                                </div>
                                                <div className="col-sm-3 col2">
                                                    <div className="check_item mb-0 mt-0">

                                                    {patientValues.imagingbeenreviewedCheck ? <div className="select_btns popup_select">
    <div className="select_btn"><label><input type="radio"
        value={patientValues.imagingbeenreviewedCheck} checked /><span>{patientValues.imagingbeenreviewedCheck} </span></label>
    </div> {patientValues.imagingbeenreviewedCheck && <a onClick={(e) => updateClinicValues(e, "imagingbeenreviewedCheck", false)}><span className="material-symbols-outlined"> reply
    </span></a>}

</div>
    :
    
    <div className="select_btns popup_select mt-0">
    {imagingbeenreviewed && imagingbeenreviewed.length > 0 && imagingbeenreviewed.map((item, i) => (
            <div className="select_btn" key={i}><label><input onClick={(e) => updateInputValue(e)} name={item.name} type="radio"
            value={item.value} /><span>{item.value} </span></label></div>
        ))} 
    </div>
}
                                                    </div>
                                                </div>
                                                <div className="col-sm-4 col1">
                                                    <p className="field_title mb-0 mt-0">Which serial examination are needed</p>

                                                </div>
                                                <div className="col-sm-3 col2">
                                                    <div className="check_item mb-0 mt-0">

                                                    {patientValues.serialexaminationCheck ? <div className="select_btns popup_select">
    <div className="select_btn"><label><input type="radio"
        value={patientValues.serialexaminationCheck} checked /><span>{patientValues.serialexaminationCheck} </span></label>
    </div> {patientValues.serialexaminationCheck && <a onClick={(e) => updateClinicValues(e, "serialexaminationCheck", false)}><span className="material-symbols-outlined"> reply
    </span></a>}

</div>
    :
    
    <div className="select_btns popup_select mt-0">
    {serialexamination && serialexamination.length > 0 && serialexamination.map((item, i) => (
            <div className="select_btn" key={i}><label><input onClick={(e) => updateInputValue(e)} name={item.name} type="radio"
            value={item.value} /><span>{item.value} </span></label></div>
        ))} 
    </div>
}
                                                    </div>
                                                </div>
                                                <div className="col-sm-4 col1">
                                                    <p className="field_title mb-0 mt-0">Plan of care discussed with</p>

                                                </div>
                                                <div className="col-sm-3 col2">
                                                    <div className="check_item mb-0 mt-0">

                                                    {patientValues.carediscussedCheck ? <div className="select_btns popup_select">
    <div className="select_btn"><label><input type="radio"
        value={patientValues.carediscussedCheck} checked /><span>{patientValues.carediscussedCheck} </span></label>
    </div> {patientValues.carediscussedCheck && <a onClick={(e) => updateClinicValues(e, "carediscussedCheck", false)}><span className="material-symbols-outlined"> reply
    </span></a>}

</div>
    :
    
    <div className="select_btns popup_select mt-0">
    {carediscussed && carediscussed.length > 0 && carediscussed.map((item, i) => (
            <div className="select_btn" key={i}><label><input onClick={(e) => updateInputValue(e)} name={item.name} type="radio"
            value={item.value} /><span>{item.value} </span></label></div>
        ))} 
    </div>
}
                                                    </div>
                                                </div>
                                                <div className="col-sm-4 col1">
                                                    <p className="field_title mb-0 mt-0">Relevant Trauma chart or form completed</p>

                                                </div>
                                                <div className="col-sm-3 col2">
                                                    <div className="check_item mb-0 mt-0">

                                                    {patientValues.relevantTraumaCheck ? <div className="select_btns popup_select">
    <div className="select_btn"><label><input type="radio"
        value={patientValues.relevantTraumaCheck} checked /><span>{patientValues.relevantTraumaCheck} </span></label>
    </div> {patientValues.relevantTraumaCheck && <a onClick={(e) => updateClinicValues(e, "relevantTraumaCheck", false)}><span className="material-symbols-outlined"> reply
    </span></a>}

</div>
    :
    
    <div className="select_btns popup_select mt-0">
    {relevantTrauma && relevantTrauma.length > 0 && relevantTrauma.map((item, i) => (
            <div className="select_btn" key={i}><label><input onClick={(e) => updateInputValue(e)} name={item.name} type="radio"
            value={item.value} /><span>{item.value} </span></label></div>
        ))} 
    </div>
}
                                                    </div>
                                                </div>

                                            </div>
                                        </div>



                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" data-dismiss="modal" onClick={(e) => { submitUpdate(e) }}  className="btn">Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Modal
                            isOpen={success_msg}
                            toggle={() => {
                                setsuccess_msg(false)
                            }}
                            centered={true}
                        >
                            <div className="modal-header">
                                <h5 className="modal-title mt-0"></h5>
                                <button
                                    type="button"
                                    onClick={() => {
                                        setsuccess_msg(false)
                                    }}
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <h1>Updated Successfully</h1>
                            </div>
                        </Modal>

                    </div>
                </div>
            </div>




        </React.Fragment>
    )
}

OnCall.propTypes = {
    getWards: PropTypes.func,
    getTheatres: PropTypes.func,
    wards: PropTypes.array,
    user: PropTypes.any,
    theatres: PropTypes.array,
    clinic: PropTypes.array,
    patients: PropTypes.array,
    doctors: PropTypes.array,
    profile: PropTypes.object,
    news: PropTypes.array,
}

const mapStateToProps = ({ Layout, Login }) => ({
    wards: Layout.wards,
    user: Login.user,
    theatres: Layout.theatres,
    clinic: Layout.clinic,
    patients: Layout.patients,
    doctors: Layout.doctors,
    profile: Layout.profile,
    news: Layout.news,
    dailyrounds: Layout.dailyrounds
})

const mapDispatchToProps = dispatch => ({
    getWards: () => dispatch(getWardsList()),
    getTheatres: () => dispatch(getTheatresList()),
    getClinic: () => dispatch(getClinicList()),
    getPatient: () => dispatch(getPatientList()),
    getUserprofile: (id) => dispatch(getUprofile(id)),
    getDoctor: () => dispatch(getDoctorList()),
    patientupdate: (id, data) => dispatch(updatePatient(id, data)),
    newsList: (data) => dispatch(getNews(data)),
    dailyRoundsList: (data) => dispatch(getDailyRounds(data)),
    dailyUpdate: (data) => dispatch(updateDailyRounds(data)),
    teamupdatelist: (data, tablename) => dispatch(teamListUpdate(data, tablename))
})

export default connect(mapStateToProps, mapDispatchToProps)(OnCall)
