import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import MetaTags from 'react-meta-tags';
import Select from "react-select"
import { connect } from "react-redux"
import { Label } from "reactstrap"
import { getWardsList, getTheatresList, getClinicList, getPatientList, getUprofile, getDoctorList, updateTheatrePatient, updateWardPatient, updatePatient } from "../../store/actions"

const surgicalRecord = props => {

    const {
        updateInputValue, clinicValues, handleChange, handleSelectGroup, handleFile, file
    } = props;

    const updateClinicValues = (e, name, val) => {
        e.preventDefault()
        let updatedValue = {};
        updatedValue = { [name]: val };
        setClinic(clinicValues => ({
            ...clinicValues,
            ...updatedValue
        }));
    }

    let operationNote = [
        { value: "None", label: "NONE" },
        { value: "CERVICAL PROCEDURES AND POST-OP", label: "CERVICAL PROCEDURES AND POST-OP" },
        { value: "LUMBOSACRAL PROCEDURES AND POST OP", label: "LUMBOSACRAL PROCEDURES AND POST OP" },
        { value: "SHOULDER PROCEDURE AND POST-OP", label: "SHOULDER PROCEDURE AND POST-OP" },
        { value: "ELBOW PROCEDURE AND POST-OP", label: "ELBOW PROCEDURE AND POST-OP" },
        { value: "WRIST-FOREARM PROCEDURE AND POST-OP", label: "WRIST-FOREARM PROCEDURE AND POST-OP" },
        { value: "HAND PROCEDURES AND PROCEDURE AND POST-OP", label: "HAND PROCEDURES AND PROCEDURE AND POST-OP" },
        { value: "HIP PROCEDURES AND POST OP", label: "HIP PROCEDURES AND POST OP" },
        { value: "KNEE PROCEDURES AND POST OP", label: "KNEE PROCEDURES AND POST OP" },
        { value: "ANKLE AND FOOT PROCEDURES AND POST-OP", label: "ANKLE AND FOOT PROCEDURES AND POST-OP" }
    ]

    return (
        <React.Fragment>

            <MetaTags>
                <title>SURGICAL RECORD</title>
            </MetaTags>
            <div>
                <div className="section simple_wound ctr">
                    <h2 className="popup_title"> PREOP SURGICAL TEAM VISIT ON WARD </h2>
                    <div className="pd_border"></div>

                    <div className="yn_switch mt-20 ">
                        <div className="label-container">
                            <p>Patient's identity verified </p>
                        </div>
                        <label className="switch-container">
                            <input type="checkbox" className="switch_check" name="sur_patient_id_verified" onChange={(e) => handleChange(e)} checked={clinicValues.sur_patient_id_verified} />
                            <div className="switch-bg"></div>
                            <div className="round-box"></div>
                            <div className="switch-left">
                                <span>YES</span>
                            </div>
                            <div className="switch-right">
                                <span>NO</span>
                            </div>
                        </label>
                    </div>
                    <div className="yn_switch mt-20 ">
                        <div className="label-container">
                            <p>Informed consent verified </p>
                        </div>
                        <label className="switch-container">
                            <input type="checkbox" className="switch_check" name="sur_consent_verified" onChange={(e) => handleChange(e)} checked={clinicValues.sur_consent_verified} />
                            <div className="switch-bg"></div>
                            <div className="round-box"></div>
                            <div className="switch-left">
                                <span>YES</span>
                            </div>
                            <div className="switch-right">
                                <span>NO</span>
                            </div>
                        </label>
                    </div>
                    <div className="yn_switch mt-20 ">
                        <div className="label-container">
                            <p>Operative site Marked after checking reliable documentation / images </p>
                        </div>
                        <label className="switch-container">
                            <input type="checkbox" className="switch_check" name="sur_operative_site" onChange={(e) => handleChange(e)} checked={clinicValues.sur_operative_site} />
                            <div className="switch-bg"></div>
                            <div className="round-box"></div>
                            <div className="switch-left">
                                <span>YES</span>
                            </div>
                            <div className="switch-right">
                                <span>NO</span>
                            </div>
                        </label>
                    </div>
                    <div className="yn_switch mt-20 ">
                        <div className="label-container">
                            <p>Relevant diagnostic reports </p>
                        </div>
                        <label className="switch-container">
                            <input type="checkbox" className="switch_check" name="sur_relevant_diagnotic" onChange={(e) => handleChange(e)} checked={clinicValues.sur_relevant_diagnotic} />
                            <div className="switch-bg"></div>
                            <div className="round-box"></div>
                            <div className="switch-left">
                                <span>YES</span>
                            </div>
                            <div className="switch-right">
                                <span>NO</span>
                            </div>
                        </label>
                    </div>
                    <div className="check_item mt-20"><label className="check">Blood grouped and cross matched?
                    </label>
                        {clinicValues.sur_bloodgroup_matched ?
                            <div className="select_btns popup_select">
                                <div className="select_btn"><label><input type="radio"
                                    value={clinicValues.sur_bloodgroup_matched} checked /><span>{clinicValues.sur_bloodgroup_matched} </span></label>
                                </div> {clinicValues.sur_bloodgroup_matched && <a onClick={(e) => updateClinicValues(e, "sur_bloodgroup_matched", false)}><span className="material-symbols-outlined"> reply
                                </span></a>}

                            </div>
                            :
                            <div className="select_btns popup_select">
                                <div className="select_btn"><label><input name="sur_bloodgroup_matched" type="radio" onClick={(e) => updateInputValue(e)}
                                    value="Yes" /><span>Yes</span></label></div>
                                <div className="select_btn"><label><input name="sur_bloodgroup_matched" type="radio" onClick={(e) => updateInputValue(e)}
                                    value="Not applicable" /><span>Not applicable</span></label></div>
                            </div>}


                    </div>
                    <p className="field_title mt-0">Surgeon or Deputy (Doctor) </p>
                    <div className="form_group">
                        <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="sur_doctor_name" value={clinicValues.sur_doctor_name}
                            className="form-control" autocomplete="off" /><label
                                className="floating-label">Doctor Name</label>
                        </div>
                    </div>
                </div>
                <div className="section simple_wound ctr">
                    <h2 className="popup_title"> OPERATION NOTES</h2>
                    <div className="pd_border"></div>

                    <p className="field_title mt-0">Patient details </p>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form_group">
                                <div className="floating-label-group"><input type="text" value={clinicValues.firstname}
                                    className="form-control" autocomplete="off" /><label
                                        className="floating-label">First name</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form_group">
                                <div className="floating-label-group"><input type="text" value={clinicValues.lastname}
                                    className="form-control" autocomplete="off" /><label
                                        className="floating-label">Last name</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="search_dropdown_blk">
                        <div className="form_group">
                            <div className="floating-label-group">
                                <Label>Operation note template</Label>
                                <Select
                                    name="sur_operation"
                                    onChange={(e) => {
                                        handleSelectGroup(e, "sur_operation")
                                    }}
                                    options={operationNote}
                                    classNamePrefix="select2-selection"
                                />
                            </div>
                        </div>
                    </div>

                    {clinicValues.sur_operation && clinicValues.sur_operation.value != "None" &&
                        <div className='pr pr_report'>
                            <div className="schdule_menu">
                                <div className="wrp">
                                    <a href="#" className="btn active">TAKE PICTURE</a>
                                    <a href="#" className="btn active">IMPORT PICTURE </a>
                                    <a href="#" className="btn active">ANNOTATE PICTURE</a>
                                    <a href="#" className="btn active">DRAW PICTURE</a>
                                </div>
                            </div>

                            <div className="section simple_wound">

                                <div className="dsp_flex">
                                    <div className="pr_left">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form_group">
                                                    <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="sur_endosoft" value={clinicValues.sur_endosoft} className="form-control"
                                                        autocomplete="off" /><label className="floating-label">EndoSoft Surgery
                                                            Center</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-7">
                                                <div className="form_group">
                                                    <div className="floating-label-group"><input type="text" value={clinicValues.firstname + " " + clinicValues.lastname} className="form-control"
                                                        autocomplete="off" /><label className="floating-label">Patient Name</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-5">
                                                <div className="form_group">
                                                    <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="sur_record" value={clinicValues.sur_record} className="form-control"
                                                        autocomplete="off" /><label className="floating-label">Record Number</label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-7">
                                                <div className="form_group">
                                                    <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="sur_datetime_procedure" value={clinicValues.sur_datetime_procedure} className="form-control"
                                                        autocomplete="off" /><label className="floating-label">Date / Time of
                                                            Procedure</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-5">
                                                <div className="form_group">
                                                    <div className="floating-label-group"><input type="date" value={clinicValues.dob} className="form-control"
                                                        autocomplete="off" /><label className="floating-label">Date of Birth</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form_group">
                                                    <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="sur_datetime_procedure" value={clinicValues.sur_Referring_Physician} className="form-control"
                                                        autocomplete="off" /><label className="floating-label">Referring Physician</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form_group">
                                                    <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="sur_Surgeon" value={clinicValues.sur_Surgeon} className="form-control"
                                                        autocomplete="off" /><label className="floating-label">Surgeon</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form_group">
                                                    <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="sur_Anesthesiologist" value={clinicValues.sur_Anesthesiologist} className="form-control"
                                                        autocomplete="off" /><label className="floating-label">Anesthesiologist</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form_group">
                                                    <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="sur_Preoperative_Diagnosis" value={clinicValues.sur_Preoperative_Diagnosis} className="form-control"
                                                        autocomplete="off" /><label className="floating-label">Preoperative Diagnosis</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form_group">
                                                    <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="sur_Postoperative_Diagnosis" value={clinicValues.sur_Postoperative_Diagnosis} className="form-control"
                                                        autocomplete="off" /><label className="floating-label">Postoperative Diagnosis</label>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                    <div className="pr_right">
                                        <div className="row">
                                            <div className="col-md-12">
                                                {
                                                    clinicValues.slao_tear_result || (file && file.slao_tear_result) ?
                                                        <img src={(clinicValues.slao_tear_result) || (file && file.slao_tear_result)} className="img mb-20" />
                                                        :
                                                        <div className="file_upload">
                                                            <div className="upload_file">
                                                                <span className="material-symbols-outlined">
                                                                    cloud_upload
                                                                </span>
                                                            </div>

                                                            <input className="custom-file-input" type="file" onChange={(e) => handleFile(e, "slao_tear")} />
                                                            <div className="file_ttile">SLAO Tear</div>
                                                        </div>}
                                            </div>
                                            <div className="col-md-12">
                                                {
                                                    clinicValues.slap_repair_result || (file && file.slap_repair_result) ?
                                                        <img src={(clinicValues.slap_repair_result) || (file && file.slap_repair_result)} className="img mb-20" />
                                                        :
                                                        <div className="file_upload">
                                                            <div className="upload_file">
                                                                <span className="material-symbols-outlined">
                                                                    cloud_upload
                                                                </span>
                                                            </div>

                                                            <input className="custom-file-input" type="file" onChange={(e) => handleFile(e, "slap_repair")} />

                                                            <div className="file_ttile">SLAP Repair</div>
                                                        </div>}
                                            </div>
                                            <div className="col-md-12">
                                                {
                                                    clinicValues.acromioplasty_result || (file && file.acromioplasty_result) ?
                                                        <img src={(clinicValues.acromioplasty_result) || (file && file.acromioplasty_result)} className="img mb-20" />
                                                        :
                                                        <div className="file_upload">
                                                            <div className="upload_file">
                                                                <span className="material-symbols-outlined">
                                                                    cloud_upload
                                                                </span>
                                                            </div>
                                                            <input className="custom-file-input" type="file" onChange={(e) => handleFile(e, "acromioplasty")} />
                                                            <div className="file_ttile">Acromioplasty</div>
                                                        </div>}
                                            </div>
                                            <div className="col-md-12">
                                                {
                                                    clinicValues.slap_repair2_result || (file && file.slap_repair2_result) ?
                                                        <img src={(clinicValues.slap_repair2_result) || (file && file.slap_repair2_result)} className="img mb-20" />
                                                        :
                                                        <div className="file_upload">
                                                            <div className="upload_file">
                                                                <span className="material-symbols-outlined">
                                                                    cloud_upload
                                                                </span>
                                                            </div>
                                                            <input className="custom-file-input" type="file" onChange={(e) => handleFile(e, "slap_repair2")} />
                                                            <div className="file_ttile">SLAP repair</div>
                                                        </div>}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="form_group">
                                    <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="sur_Procedure_Performed" value={clinicValues.sur_Procedure_Performed} className="form-control" autocomplete="off"
                                    /><label className="floating-label">Procedure Performed</label>
                                    </div>
                                </div>

                                <div className="form_group">
                                    <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="sur_Allergies" value={clinicValues.sur_Allergies} className="form-control" autocomplete="off"
                                    /><label className="floating-label">Allergies</label>
                                    </div>
                                </div>

                                <div className="form_group">
                                    <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="sur_Indications" value={clinicValues.sur_Indications} className="form-control" autocomplete="off"
                                    /><label className="floating-label">Indications for Examination</label>
                                    </div>
                                </div>

                                <div className="form_group">
                                    <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="sur_Patient_Position" value={clinicValues.sur_Patient_Position} className="form-control" autocomplete="off"
                                    /><label className="floating-label">Patient Position</label>
                                    </div>
                                </div>

                                <div className="form_group">
                                    <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="sur_Examination" value={clinicValues.sur_Examination} className="form-control" autocomplete="off"
                                    /><label className="floating-label">Examination under Anesthesia</label>
                                    </div>
                                </div>

                                <div className="form_group">
                                    <textarea className="textarea" onChange={(e) => updateInputValue(e)} name="sur_Procedure_Techinique" value={clinicValues.sur_Procedure_Techinique} placeholder="Procedure Techinique " rows="2" cols="30"></textarea>
                                </div>
                                <div className="form_group">
                                    <textarea className="textarea" onChange={(e) => updateInputValue(e)} name="sur_Findings" value={clinicValues.sur_Findings} placeholder="Findings " rows="2" cols="30"></textarea>
                                </div>
                                <p className="mt-30 mb-30">Signature:</p>
                                <div className="form_group">
                                    <textarea className="textarea" onChange={(e) => updateInputValue(e)} name="ICD_10_Codes" value={clinicValues.ICD_10_Codes} placeholder="ICD 10 Codes " rows="2" cols="30"></textarea>
                                </div>
                                <div className="form_group">
                                    <textarea className="textarea" onChange={(e) => updateInputValue(e)} name="CPT_Codes" value={clinicValues.CPT_Codes} placeholder="CPT Code " rows="2" cols="30"></textarea>
                                </div>
                            </div>
                        </div>}


                    <h2 className="popup_title">CODE Generator</h2>
                    <table className="code_table table-bordered">
                        <thead>
                            <tr>
                                <th width="60%">Procedure</th>
                                <th width="20%">Code Type</th>
                                <th width="20%">Code</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td width="60%"><input type="text" className="field" onChange={(e) => updateInputValue(e)} name="sur_procedure1" value={clinicValues.sur_procedure1} /></td>
                                <td width="20%"><input type="text" className="field" onChange={(e) => updateInputValue(e)} name="sur_codetype" value={clinicValues.sur_codetype} /></td>
                                <td width="20%"> <b> 2943 </b></td>
                            </tr>
                            <tr>
                                <td width="60%"><input type="text" className="field" onChange={(e) => updateInputValue(e)} name="sur_procedure2" value={clinicValues.sur_procedure2} /></td>
                                <td width="20%"><input type="text" className="field" onChange={(e) => updateInputValue(e)} name="sur_codetype2" value={clinicValues.sur_codetype2} /></td>
                                <td width="20%"> <b> 2943 </b></td>
                            </tr>
                            <tr>
                                <td width="60%"><input type="text" className="field" onChange={(e) => updateInputValue(e)} name="sur_procedure3" value={clinicValues.sur_procedure3} /></td>
                                <td width="20%"><input type="text" className="field" onChange={(e) => updateInputValue(e)} name="sur_codetype3" value={clinicValues.sur_codetype3} /></td>
                                <td width="20%"> <a className="btn" >Get
                                    Code</a> </td>
                            </tr>
                            <tr>
                                <td width="60%"><input type="text" className="field" onChange={(e) => updateInputValue(e)} name="sur_procedure4" value={clinicValues.sur_procedure4} /></td>
                                <td width="20%"><input type="text" className="field" onChange={(e) => updateInputValue(e)} name="sur_codetype4" value={clinicValues.sur_codetype4} /></td>
                                <td width="20%"> <b> 2943 </b></td>
                            </tr>
                            <tr>
                                <td width="60%"><input type="text" className="field" onChange={(e) => updateInputValue(e)} name="sur_procedure5" value={clinicValues.sur_procedure5} /></td>
                                <td width="20%"><input type="text" className="field" onChange={(e) => updateInputValue(e)} name="sur_codetype5" value={clinicValues.sur_codetype5} /></td>
                                <td width="20%"> <b> 2943 </b></td>
                            </tr>

                        </tbody>
                    </table>

                </div>
            </div>

        </React.Fragment>
    )
}

surgicalRecord.propTypes = {
    t: PropTypes.any,
    getWards: PropTypes.func,
    getTheatres: PropTypes.func,
    wards: PropTypes.array,
    user: PropTypes.any,
    theatres: PropTypes.array,
    clinic: PropTypes.array,
    patients: PropTypes.array,
    doctors: PropTypes.array,
    profile: PropTypes.object
}

const mapStateToProps = ({ Layout, Login }) => ({
    wards: Layout.wards,
    user: Login.user,
    theatres: Layout.theatres,
    clinic: Layout.clinic,
    patients: Layout.patients,
    doctors: Layout.doctors,
    profile: Layout.profile
})

const mapDispatchToProps = dispatch => ({
    getWards: () => dispatch(getWardsList()),
    getTheatres: () => dispatch(getTheatresList()),
    getClinic: () => dispatch(getClinicList()),
    getPatient: () => dispatch(getPatientList()),
    getUserprofile: (id) => dispatch(getUprofile(id)),
    getDoctor: () => dispatch(getDoctorList()),
    theatreupdate: (id, data) => dispatch(updateTheatrePatient(id, data)),
    wardupdate: (id, data) => dispatch(updateWardPatient(id, data)),
    patientupdate: (id, data) => dispatch(updatePatient(id, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(surgicalRecord)
