import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import MetaTags from 'react-meta-tags';
import PatientBlock from "../Clinic/patientBlock";
import { Link, useHistory } from "react-router-dom"

import centralmonitor from '../../assets/images/central-monitor.svg';
import qualityReport from '../../assets/images/qualityReport.svg';
import performanceReport from '../../assets/images/performanceReport.svg';
import FinancialReport from '../../assets/images/FinancialReport.svg';
import AdminReport from '../../assets/images/AdminReport.svg';
import FormsProtocol from '../../assets/images/FormsProtocol.svg';
import todos from '../../assets/images/todos.svg';



import { connect } from "react-redux"
import { getWardsList, getTheatresList, getClinicList, getPatientList, getUprofile, getDoctorList, updatePatient, getNews, updateDailyRounds, getDailyRounds } from "../../store/actions"

const NursingOffice = props => {
    document.body.style.overflow = '';
    let clinicPatient = JSON.parse(localStorage.getItem("p"))
    const history = useHistory();
    const btnClick = (e) => {
        e.preventDefault()
        history.push("/wlist")
        localStorage.setItem('rrd', 'noffice')
      }

    return (
        <React.Fragment>

            <MetaTags>
                <title>Nursing Office</title>
            </MetaTags>
            <div className='ward '>
                <div className="main">
                    <div className="main">
                        
                        <div className="wrapper">
                            <div className="patient_record prd">
                                <div className="prd_top">
                                    
                                    {clinicPatient && <PatientBlock patient={clinicPatient} accordion={true} selectPatientList={true} />}

                                    {!clinicPatient && <div className="find">
                                        <button type="button" onClick={(e) => btnClick(e)} className="btn"> <span className="material-symbols-outlined icon">
                                            person_search </span> Find Patient</button>
                                    </div>}
                                    {!clinicPatient &&<div className="guided_tour">
                                        <div className="guided_tour_arrow"></div>
                                        <p>Find and Add a patient to access the Ward</p>
                                        <span className="material-icons icon"> close </span>
                                    </div>}
                                    <div className="prd_btns">
                                        <div className="btns">
                                            <Link to='pinfo' className="btn">PATIENT INFO</Link>
                                            <button type="button" className="btn">HAND OVER</button>
                                        </div>
                                        <div className="btn-group">
                                            <button type="button" className="btn btn-primary">JACS</button>
                                            <button type="button" className="btn btn-primary">ICE</button>
                                            <button type="button" className="btn btn-primary">PACS</button>
                                        </div>
                                    </div>
                                </div>
                                <h2>DASHBOARDS</h2>
                                <ul className={!clinicPatient ? "oncall_dboard disabled col_3" : "oncall_dboard col_3"}>

                                    <li>
                                    <Link to='uc' className="item_card">
                                            <span className="icon"> <img src={centralmonitor} /></span>
                                             CENTRAL MONITOR BOARD 

                                        </Link>
                                    </li>
                                    <li>
                                        <Link to='uc' className="item_card">
                                            <span className="icon"> <img src={qualityReport} /></span>
                                            QUALITY REPORT
                                        </Link>
                                    </li>
                                    <li>
                                    <Link to='uc' className="item_card">
                                            <span className="icon"> <img src={performanceReport} /></span>
                                            PERFORMANCE REPORT
                                        </Link>
                                    </li>
                                    <li>
                                    <Link to="/fluid" className="item_card">
                                            <span className="icon"> <img src={FinancialReport} /></span>
                                            FINANCIAL REPORT
                                        </Link>
                                    </li>
                                    <li>
                                    <Link to='uc' className="item_card">
                                            <span className="icon"> <img src={AdminReport} /></span>
                                            ADMIN REPORT
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/uc" className="item_card">
                                            <span className="icon"> <img src={FormsProtocol} /></span>
                                            FORMS / PROTOCOL
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/uc" className="item_card">
                                            <span className="icon"> <img src={todos} /></span>
                                            TO DOs
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

          

        </React.Fragment>
    )
}

NursingOffice.propTypes = {
    getWards: PropTypes.func,
    getTheatres: PropTypes.func,
    wards: PropTypes.array,
    user: PropTypes.any,
    theatres: PropTypes.array,
    clinic: PropTypes.array,
    patients: PropTypes.array,
    doctors: PropTypes.array,
    profile: PropTypes.object,
    news: PropTypes.array,
}

const mapStateToProps = ({ Layout, Login }) => ({
    wards: Layout.wards,
    user: Login.user,
    theatres: Layout.theatres,
    clinic: Layout.clinic,
    patients: Layout.patients,
    doctors: Layout.doctors,
    profile: Layout.profile,
    news: Layout.news,
    dailyrounds: Layout.dailyrounds
})

const mapDispatchToProps = dispatch => ({
    getWards: () => dispatch(getWardsList()),
    getTheatres: () => dispatch(getTheatresList()),
    getClinic: () => dispatch(getClinicList()),
    getPatient: () => dispatch(getPatientList()),
    getUserprofile: (id) => dispatch(getUprofile(id)),
    getDoctor: () => dispatch(getDoctorList()),
    patientupdate: (id, data) => dispatch(updatePatient(id, data)),
    newsList: (data) => dispatch(getNews(data)),
    dailyRoundsList: (data) => dispatch(getDailyRounds(data)),
    dailyUpdate: (data) => dispatch(updateDailyRounds(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(NursingOffice)
