import React from "react"
import { useHistory, Link } from 'react-router-dom';
import FooterLogo from '../../assets/images/footer_logo.png';
import gpt from '../../assets/images/chat-gpt.svg';
import { connect } from "react-redux"
import PropTypes from 'prop-types'
// actions
import { logoutUser } from "../../store/actions"

const Footer = props => {

  const history = useHistory();

  const handleLogout = (e) => {
    props.logoutUser(history)
  }

  const onClickCME = (e) => {
    $("#exampleModalCenter .close_btn").click()
    history.push("/category")
    // window.location.reload()
  }

  return (
    <React.Fragment>
      <div className="footer">
            <div className="wrapper wrp">
              <div className="footer_logo">
                <img src={FooterLogo} />
              </div>
              <div>
              <a className="footer_submenu" data-toggle="modal" data-target="#exampleModalCenter">
                <span className="material-icons">apps</span>  Additional Functions
                </a>
       
              </div>
              <div className="footer_menu">
                <a href="#" className="footer_link"> <span className="material-icons">bedtime</span>  Sleep</a>
                 <a onClick={(e) => handleLogout()} className="footer_link"><span className="material-icons">power_settings_new</span> logout</a> 
              </div>
            </div>
          </div>
          <div className="modal fade additional_functions" id="exampleModalCenter">
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">ADDITIONAL FUNCTIONS</h5>
                    <button type="button" className="close_btn" data-dismiss="modal" aria-label="Close">
                    <span className="material-icons icon"> close </span>
                    </button>
                  </div>
                  <div className="modal-body">
                  <ul>
                    <li> <a href="#"><span className="material-symbols-outlined icon"> feature_search </span>Code Finder</a> </li>
                    <li> <Link onClick={(e)=> onClickCME(e)}><span className="material-symbols-outlined icon"> batch_prediction </span>CME Knowledge Center</Link> </li>
                    <li> <a href="#"><span className="material-symbols-outlined icon"> library_books </span>Add to My Log Book</a> </li>
                    <li> <a href="#"><span className="material-symbols-outlined icon"> calculate </span>Medical calculator</a> </li>
                    <li> <a href="#"><span className="material-symbols-outlined icon"> personal_injury </span>Patient Information</a> </li>
                    <li> <a href="#"><span className="material-symbols-outlined icon"> contacts </span>Contacts</a> </li>
                    <li> <a href="#"> <img src={gpt} className="icon" /> Ask Chat GPT</a> </li>
                    <li> <a href="#"><span className="material-symbols-outlined icon"> quiz </span>Help Center</a> </li>
                  </ul>
                  </div>
                
                </div>
              </div>
            </div>
    </React.Fragment>
  )
}

Footer.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object
}

const mapStateToProps = state => {
  const { error } = state.Login
  return { error }
}

export default connect(mapStateToProps, { logoutUser })(Footer)

