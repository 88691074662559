import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import MetaTags from 'react-meta-tags';


import PatientBlock from "../Clinic/patientBlock";
import { connect } from "react-redux"
import { getWardsList, getTheatresList, getClinicList, getPatientList, getUprofile, getDoctorList, updatePatient } from "../../store/actions"

const MovingHandling = props => {


    const [visiblePatients, setPatient] = useState(true)
    const [visiblelanding, setClanding] = useState(false)
    const [successmessage, setSuccess] = useState("")


    const [assesmentValues, setAssesment] = useState({})

    const {
        wards, user, theatres, clinic, patients, profile, doctors
    } = props;

    useEffect(() => {
        const { getWards, getTheatres, getClinic, getDoctor, getPatient, getUserprofile } = props
        // getWards()
        // getTheatres()
        // getClinic()
        getPatient()
        getDoctor()
        let userId = localStorage.getItem("authUser")
        getUserprofile(userId)
    }, [])

    const updateRadioValue = (e) => {
        let val = e.target.value;
        let name = e.target.name;
        let updatedValue = { [name]: val };
        setAssesment(assesmentValues => ({
            ...assesmentValues,
            ...updatedValue
        }));
    }


    const closeClick = (e) => {
        e.preventDefault()
        localStorage.removeItem("a")
        props.history.push("/riskassesment")
    }

    const handleclick = (e) => {
        e.preventDefault()
        props.history.push("/safe")
        
       
    }

    let assesmentPage = JSON.parse(localStorage.getItem("a"))
    let clinicPatient = JSON.parse(localStorage.getItem("p"))

    return (
        <React.Fragment>

            <MetaTags>
                <title>{assesmentPage}</title>
            </MetaTags>
            <div className="dashboard ward">
                <div className="main">

                    <div className="popup_wrp">
                        <div className="popup_full prescribe">
                            <div className="popup_hed">
                                <h2> Moving and Handling </h2>
                                <div className="dsp-flex"> <a className="popup_close" onClick={(e) => closeClick(e)}><span className="material-icons">close</span></a></div>

                            </div>
                            <div className="popup_content">
                                <div className="popup_scroll w-btns main nutrition">



                                    <PatientBlock patient={clinicPatient} />



                                    <div className="section ctr pua mah">
                                        <div className="yn_switch mb-15 mt-10">
                                            <div className="label-container">
                                                <p>Risk of Falls </p>
                                            </div>
                                            <label for="check814" className="switch-container">
                                                <input hidden="" type="checkbox" className="switch_check" id="check814" />
                                                <div className="switch-bg"></div>
                                                <div className="round-box"></div>
                                                <div className="switch-left">
                                                    <span>NO</span>
                                                </div>
                                                <div className="switch-right">
                                                    <span>YES</span>
                                                </div>
                                            </label>
                                        </div>
                                        <p className="ps1">If High, ensure this is taken into account when prescribing techniques or
                                            equipment for the various manoeuvres with this patient</p>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form_group input_style">
                                                    <label className="label">Height:</label>
                                                    <div className="measure tqt">
                                                        <input type="text" className="field" placeholder="Enter " />
                                                        <span>cms</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form_group input_style">
                                                    <label className="label">Weight:</label>
                                                    <div className="measure tqt">
                                                        <input type="text" className="field" placeholder="Enter " />
                                                        <span>Kg</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="poc poc1 pt-0">
                                            <p className="field_title mb-20">MANUAL HANDLING CONSTRAINTS / RISK FACTORS (tick all that
                                                apply)</p>

                                            <div className="check-box">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-114" />
                                                    <label for="checkbox-114"></label>
                                                </div>
                                                Lack of comprehension / understanding
                                            </div>
                                            <div className="check-box">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-116" />
                                                    <label for="checkbox-116"></label>
                                                </div>
                                                Has confusion / agitation /Unpredictable Behaviour
                                            </div>
                                            <div className="check-box">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-117" />
                                                    <label for="checkbox-117"></label>
                                                </div>
                                                Lack of co-operation / compliance
                                            </div>
                                            <div className="check-box">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-118" />
                                                    <label for="checkbox-118"></label>
                                                </div>
                                                Skin lesions / wounds
                                            </div>
                                            <div className="check-box">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-119" />
                                                    <label for="checkbox-119"></label>
                                                </div>
                                                Infusion / catheter / drain /traction, limb oedema etc
                                            </div>
                                            <div className="check-box">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-120" />
                                                    <label for="checkbox-120"></label>
                                                </div>
                                                Tractions/Splints etc
                                            </div>
                                            <div className="check-box">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-121" />
                                                    <label for="checkbox-121"></label>
                                                </div>
                                                Disability/Amputee (Upper/Lower limb)
                                            </div>
                                            <div className="check-box">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-122" />
                                                    <label for="checkbox-122"></label>
                                                </div>
                                                Paralysis / weakness /Injury
                                            </div>
                                            <div className="check-box">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-123" />
                                                    <label for="checkbox-123"></label>
                                                </div>
                                                Muscular spasm or rigidity
                                            </div>
                                            <div className="check-box">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-124" />
                                                    <label for="checkbox-124"></label>
                                                </div>
                                                Pain
                                            </div>
                                            <div className="check-box">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-125" />
                                                    <label for="checkbox-125"></label>
                                                </div>
                                                Cultural considerations
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form_group">
                                                        <label className="label">Comment :</label>
                                                        <textarea className="form-control" rows="2"></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="check-box mt-15">
                                                        <div className="round">
                                                            <input type="checkbox" id="checkbox-206" />
                                                            <label for="checkbox-206"></label>
                                                        </div>
                                                        Patient has Impaired hearing
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="yn_switch mb-15 mt-15">
                                                        <div className="label-container">
                                                            <p>Hearing aid : </p>
                                                        </div>
                                                        <label for="check120" className="switch-container">
                                                            <input hidden="" type="checkbox" className="switch_check" id="check120" />
                                                            <div className="switch-bg"></div>
                                                            <div className="round-box"></div>
                                                            <div className="switch-left">
                                                                <span>NO</span>
                                                            </div>
                                                            <div className="switch-right">
                                                                <span>YES</span>
                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form_group">
                                                        <label className="label">Comment :</label>
                                                        <textarea className="form-control" rows="2"></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="check-box mt-15">
                                                        <div className="round">
                                                            <input type="checkbox" id="checkbox-206" />
                                                            <label for="checkbox-206"></label>
                                                        </div>
                                                        Patient has Impaired sight
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="yn_switch mb-15 mt-15">
                                                        <div className="label-container">
                                                            <p>Spectacles : </p>
                                                        </div>
                                                        <label for="check121" className="switch-container">
                                                            <input hidden="" type="checkbox" className="switch_check" id="check121" />
                                                            <div className="switch-bg"></div>
                                                            <div className="round-box"></div>
                                                            <div className="switch-left">
                                                                <span>NO</span>
                                                            </div>
                                                            <div className="switch-right">
                                                                <span>YES</span>
                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form_group">
                                                        <label className="label">Comment :</label>
                                                        <textarea className="form-control" rows="2"></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-8">
                                                    <div className="check-box mt-15">
                                                        <div className="round">
                                                            <input type="checkbox" id="checkbox-207" />
                                                            <label for="checkbox-207"></label>
                                                        </div>
                                                        Other relevant handling factor Comment:
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form_group">

                                                        <textarea className="form-control" rows="2"></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="section ctr pua shr">
                                        <p className="field_title text-center mb-10 mt-10"> SAFER HANDLING RISK LEVEL</p>
                                        <div className="poc green">
                                            <div className="check-box">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-33" />
                                                    <label for="checkbox-33"></label>
                                                </div>
                                                <div>
                                                    <p>
                                                        <span>PRESSURE ULCER RISK</span> <br />
                                                        Predominantly independent in tasks, Minimal Assistance
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="poc yello">
                                            <div className="check-box">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-33" />
                                                    <label for="checkbox-33"></label>
                                                </div>
                                                <div>
                                                    <p>
                                                        <span>Moderate Risk:</span> <br />
                                                        Essentially weight bearing or partial weight bearing, some limited mobility,
                                                        Assistance required
                                                    </p>
                                                </div>
                                            </div>
                                            <a onClick={(e)=> handleclick(e)} className="icon_btn">SAFE HANDLING <span className="material-symbols-outlined icon">
                                                arrow_outward
                                            </span></a>
                                        </div>
                                        <div className="poc orange">
                                            <div className="check-box">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-33" />
                                                    <label for="checkbox-33"></label>
                                                </div>
                                                <div>
                                                    <p>
                                                        <span> High Risk:</span> <br />
                                                        Non weight bearing or prone to falls, Assistance from multiple team members
                                                    </p>
                                                </div>
                                            </div>
                                            <a onClick={(e)=> handleclick(e)} className="icon_btn">SAFE HANDLING <span className="material-symbols-outlined icon">
                                                arrow_outward
                                            </span></a>
                                        </div>
                                        <div className="poc red">
                                            <div className="check-box">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-33" />
                                                    <label for="checkbox-33"></label>
                                                </div>
                                                <div>
                                                    <p>
                                                        <span>Significant Risk:</span> <br />
                                                        Non weight bearing, Bariatric, Assistance from multiple team members
                                                    </p>
                                                </div>
                                            </div>
                                            <a onClick={(e)=> handleclick(e)} className="icon_btn">SAFE HANDLING <span className="material-symbols-outlined icon">
                                                arrow_outward
                                            </span></a>
                                        </div>
                                    </div>


                                </div>

                            </div>
                            <div className="popup_footer">
                                <div className="prescribe_footer">
                                    <div className="dsp-flex">
                                        <a className="icon_btn"> <span className="material-icons">save</span> SAVE</a>
                                        <button className="border_btn"><span className="material-icons">save_as</span>SAVE &amp;
                                            CLOSE
                                        </button>
                                    </div>
                                    <button className="btn dsp_flex"><span className="material-icons"><span
                                        className="material-icons">close</span></span>DISCARD </button>
                                </div>
                            </div>
                        </div>
                    </div>





                </div>
            </div>




        </React.Fragment>
    )
}

MovingHandling.propTypes = {
    t: PropTypes.any,
    getWards: PropTypes.func,
    getTheatres: PropTypes.func,
    wards: PropTypes.array,
    user: PropTypes.any,
    theatres: PropTypes.array,
    clinic: PropTypes.array,
    patients: PropTypes.array,
    doctors: PropTypes.array,
    profile: PropTypes.object
}

const mapStateToProps = ({ Layout, Login }) => ({
    wards: Layout.wards,
    user: Login.user,
    theatres: Layout.theatres,
    clinic: Layout.clinic,
    patients: Layout.patients,
    doctors: Layout.doctors,
    profile: Layout.profile
})

const mapDispatchToProps = dispatch => ({
    getWards: () => dispatch(getWardsList()),
    getTheatres: () => dispatch(getTheatresList()),
    getClinic: () => dispatch(getClinicList()),
    getPatient: () => dispatch(getPatientList()),
    getUserprofile: (id) => dispatch(getUprofile(id)),
    getDoctor: () => dispatch(getDoctorList()),
    patientupdate: (id, data) => dispatch(updatePatient(id, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(MovingHandling)
