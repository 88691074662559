var config = {
 category: "user/category",
  sub_category:"user/sub-category",
  quiz:"user/quiz",
  result:"user/results",
  createResult: "user/createResult",
  auth:"auth"

};

export default config;