/* LAYOUT */
export const CHANGE_LAYOUT = "CHANGE_LAYOUT"
export const CHANGE_LAYOUT_WIDTH = "CHANGE_LAYOUT_WIDTH"
export const CHANGE_SIDEBAR_THEME = "CHANGE_SIDEBAR_THEME"
export const CHANGE_SIDEBAR_TYPE = "CHANGE_SIDEBAR_TYPE"

// topbar
export const CHANGE_TOPBAR_THEME = "CHANGE_TOPBAR_THEME"

// show sidebar
export const SHOW_SIDEBAR = "SHOW_SIDEBAR"
export const TOGGLE_LEFTMENU = "TOGGLE_LEFTMENU"

/* RIGHT SIDEBAR */
export const SHOW_RIGHT_SIDEBAR = "SHOW_RIGHT_SIDEBAR"

/* ADMINISTRATION THEME */
export const SELECT_ADMINISTRATION = "SELECT_ADMINISTRATION"

/* GET_WARDS */
export const GET_WARDS = "GET_WARDS"
export const GET_WARDS_SUCCESS = "GET_WARDS_SUCCESS"

/* GET_THEATRES */
export const GET_THEATRES = "GET_THEATRES"
export const GET_THEATRES_SUCCESS = "GET_THEATRES_SUCCESS"

/* GET_CLINIC */
export const GET_CLINIC = "GET_CLINIC"
export const GET_CLINIC_SUCCESS = "GET_CLINIC_SUCCESS"

/* GET_PATIENTS */
export const GET_PATIENTS = "GET_PATIENTS"
export const GET_PATIENTS_SUCCESS = "GET_PATIENTS_SUCCESS"

/* USER_PROFILE */
export const USER_PROFILE = "USER_PROFILE"
export const USER_PROFILE_SUCCESS = "USER_PROFILE_SUCCESS"

/* GET_DOCTORS */
export const GET_DOCTORS = "GET_DOCTORS"
export const GET_DOCTOR_SUCCESS = "GET_DOCTOR_SUCCESS"

/* UPDATE_PATIENT */
export const UPDATE_PATIENT = "UPDATE_PATIENT"

/* UPDATE_THEATRE_PATIENT */
export const UPDATE_THEATRE_PATIENT = "UPDATE_THEATRE_PATIENT" 

/* UPDATE_TEAM_BRIEF */
export const UPDATE_TEAM_BRIEF = "UPDATE_TEAM_BRIEF" 

/* UPDATE_WARD_PATIENT */
export const UPDATE_THEATRE_REPORTS = "UPDATE_THEATRE_REPORTS" 

/* UPDATE_WARD_PATIENT */
export const UPDATE_WARD_PATIENT = "UPDATE_WARD_PATIENT" 

/* GET_SESSION_TEAM */
export const GET_SESSION_TEAM = "GET_SESSION_TEAM"
export const GET_SESSION_TEAM_SUCCESS = "GET_SESSION_TEAM_SUCCESS"

/* UPDATE_NEWS */
export const UPDATE_NEWS = "UPDATE_NEWS"
/* GET_NEWS */
export const GET_NEWS = "GET_NEWS"
export const GET_NEWS_SUCCESS = "GET_NEWS_SUCCESS"

/* UPDATE_DAILY_ROUNDS */
export const UPDATE_DAILY_ROUNDS = "UPDATE_DAILY_ROUNDS"
/* GET_DAILY_ROUNDS */
export const GET_DAILY_ROUNDS = "GET_DAILY_ROUNDS"
export const GET_DAILY_ROUNDS_SUCCESS = "GET_DAILY_ROUNDS_SUCCESS"

/* UPDATE_TEAM */
export const UPDATE_TEAM = "UPDATE_TEAM"
export const TEAM_LIST_SUCCESS = "TEAM_LIST_SUCCESS"

/* GET_TABLE_DATA */
export const GET_TABLE_DATA = "GET_TABLE_DATA"
export const GET_TABLE_DATA_SUCCESS = "GET_TABLE_DATA_SUCCESS"