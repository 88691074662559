import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import MetaTags from 'react-meta-tags';


import PatientBlock from "../Clinic/patientBlock";
import { connect } from "react-redux"
import { getWardsList, getTheatresList, getClinicList, getPatientList, getUprofile, getDoctorList, updatePatient } from "../../store/actions"

const SafeHandling = props => {


    const handlingObject = {
        "moving_in_bed": [
            {
                title: "Moving up /down in bed",
                radios: ["Independent", "Super Vision / verbal Prompt", "N/A", "Assisted / Unable"],
                method: [{
                    "title": "Sitting slide: Towel slide / Sheet slide",
                    "handlers": ["Handler1", "Handler2", "Handler3", "Handler4"],
                    "equipment": ["Sliding sheets", "Grab handle /Monkey pole", "Other"],
                    "equipmentType": "checkbox"
                },
                {
                    "title": "Supine sheet slide",
                    "handlers": ["Handler1", "Handler2", "Handler3", "Handler4"],
                    "equipment": ["Sliding sheets", "Grab handle /Monkey pole", "Other"],
                    "equipmentType": "checkbox"
                },
                {
                    "title": "By Hoist",
                    "handlers": ["Handler1", "Handler2", "Handler3", "Handler4"],
                    "equipment": ["Handler1", "Handler2", "Handler3"],
                    "equipmentType": "dropdown",
                    "input": ["Host Sling Size"]
                }]
            },
            {
                title: "Rolling / Turning",
                radios: ["Independent", "Super Vision / verbal Prompt", "N/A", "Assisted / Unable"]
            },
            {
                "checkboxes": ["Same Day & Night", "Other"],
                "otherInput": ["Other"]
            }
        ],
    }


    const [handlingValues, setHandling] = useState({})

    const {
        wards, user, theatres, clinic, patients, profile, doctors
    } = props;

    useEffect(() => {
        const { getWards, getTheatres, getClinic, getDoctor, getPatient, getUserprofile } = props
        // getWards()
        // getTheatres()
        // getClinic()
        getPatient()
        getDoctor()
        let userId = localStorage.getItem("authUser")
        getUserprofile(userId)
    }, [])

    const updateRadioValue = (e) => {
        let val = e.target.value;
        let name = e.target.name;
        let updatedValue = { [name]: val };
        setHandling(handlingValues => ({
            ...handlingValues,
            ...updatedValue
        }));
    }

    const updateReplyValue = (e, radioname, value) => {
        e.preventDefault()
        console.log("radioname ", radioname, value )
        let updatedValue = { [radioname]: value };
        setHandling(handlingValues => ({
            ...handlingValues,
            ...updatedValue
        }));
    }

    const handleChange = (event) => {
        updateReplyValue(event, event.target.name, event.target.checked)
    }

    const closeClick = (e) => {
        e.preventDefault()
        localStorage.removeItem("a")
        props.history.push("/riskassesment")
    }

    let assesmentPage = JSON.parse(localStorage.getItem("a"))
    let clinicPatient = JSON.parse(localStorage.getItem("p"))

    console.log("handlingValues ", handlingValues)

    return (
        <React.Fragment>

            <MetaTags>
                <title>{assesmentPage}</title>
            </MetaTags>
            <div className="dashboard ward">
                <div className="main">

                    <div className="popup_wrp">
                        <div className="popup_full prescribe">
                            <div className="popup_hed">
                                <h2>Safe Handling Plan <span className="tag">Moderate Risk</span> </h2>
                                <div className="dsp-flex"> <a className="popup_close" onClick={(e) => closeClick(e)}><span className="material-icons">close</span></a></div>

                            </div>
                            <div className="popup_content">
                                <div className="popup_scroll w-btns main nutrition">




                                    <PatientBlock patient={clinicPatient} />

                                    {handlingObject && Object.keys(handlingObject).length > 0 && Object.keys(handlingObject).map((item, i) => {
                                        let titleHead = item.toUpperCase().replace(/\_/g, ' ')
                                        return (
                                            <div key={i}>
                                                <p className="bg_title mt-15 mb-10">{i + 1}. {titleHead} </p>
                                                <div className="section ctr pua ">
                                                    {handlingObject && handlingObject[item].length > 0 && handlingObject[item].map((inneritem, i) => {

                                                        let radioname = inneritem.title && inneritem.title.toLowerCase().replace(/\W/g, '') 
                                                        return (
                                                            inneritem.radios ?
                                                                <div key={i}>
                                                                    <div className="check_item ">
                                                                        <label className="check">{inneritem.title}</label>
                                                                        
                                                                        {!handlingValues[radioname] &&
                                                                        <div className="select_btns popup_select">
                                                                            {inneritem.radios && inneritem.radios.length > 0 && inneritem.radios.map((radio, i) => {
                                                                                
                                                                                return (
                                                                                    <div className="select_btn" key={i}><label>
                                                                                        <input onChange={(e)=>updateRadioValue(e)} name={radioname} type="radio"
                                                                                        value={radio} /><span>{radio}
                                                                                        </span></label></div>
                                                                                )
                                                                            })}


                                                                        </div>}
                                                                    </div>
                                                                            
                                                                    
                                                                    {handlingValues[radioname] && <div className="leavel_1">

                                                                        <h2 className="selected">{handlingValues[radioname]}<a onClick={(e)=>updateReplyValue(e, radioname, false)}><span
                                                                            className="material-symbols-outlined"> reply
                                                                        </span></a></h2>

                                                                        {handlingValues[radioname] == "Assisted / Unable" &&   
                                                                        <div className="poc poc1 pt-0 mt-20">
                                                                            <p className="field_title mb_15">Method</p>

                                                                            {inneritem.method && inneritem.method.length > 0 && inneritem.method.map((method, i) => {
                                                                                let methodname = method.title && method.title.toLowerCase().replace(/\W/g, '') 
                                                                                return (

                                                                                    <div className="row align-item-center" key={i}>
                                                                                        <div className="col-md-6">
                                                                                            <div className="check-box mb-0">
                                                                                                <div className="round">
                                                                                                    <input type="checkbox"  onChange={(e)=>handleChange(e)} name={radioname+"_method_"+methodname} value={method.title} id={method.title} checked={handlingValues[radioname+"_method_"+methodname]} />
                                                                                                    <label htmlFor={method.title}></label>
                                                                                                </div>
                                                                                                {method.title}
                                                                                            </div>
                                                                                        </div>

                                                                                        {handlingValues[radioname+"_method_"+methodname] &&
                                                                                        <>
                                                                                        <div className="col-md-6">
                                                                                            <div className="form_group mb-0">
                                                                                                <select className="form-control with-auto ">
                                                                                                    <option value="1">No of Handlers : 1</option>
                                                                                                    <option value="1">No of Handlers : 2</option>
                                                                                                    <option value="1">No of Handlers : 3</option>
                                                                                                    <option value="1">No of Handlers : 4</option>
                                                                                                </select>
                                                                                            </div>
                                                                                        </div>



                                                                                        <p className="field_title mb_15">EQUIPMENT</p>

                                                                                        {method.equipmentType == "checkbox" &&
                                                                                            <>
                                                                                                {method.equipment && method.equipment.length > 0 && method.equipment.map((eqp, i) => {
                                                                                                    return (<div className="check-box" key={i}>
                                                                                                        <div className="round">
                                                                                                            <input type="checkbox" id={eqp} name={eqp} />
                                                                                                            <label htmlFor={eqp}></label>
                                                                                                        </div>
                                                                                                        {eqp}
                                                                                                    </div>)
                                                                                                })}


                                                                                            </>}



                                                                                        {method.equipmentType == "dropdown" &&
                                                                                            <div className="row">
                                                                                                <div className="col-md-3">
                                                                                                    <div className="form_group mb-0">
                                                                                                        <select className="form-control ">
                                                                                                            <option value="1" selected>Hoist sling type</option>
                                                                                                            <option value="1">No of Handlers : 2</option>
                                                                                                            <option value="1">No of Handlers : 3</option>
                                                                                                            <option value="1">No of Handlers : 4</option>
                                                                                                        </select>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-md-3">
                                                                                                    <div className="form_group">
                                                                                                        <input type="text" className="form-control"
                                                                                                            placeholder="Hoist sling size" />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>}

                                                                                        {method.equipmentType == "radio" &&
                                                                                            <div className="check_item ">
                                                                                                <div className="select_btns popup_select">
                                                                                                    <div className="select_btn"><label><input name="site" type="radio"
                                                                                                        value="Site 1" /><span>Electric bed
                                                                                                        </span></label></div>
                                                                                                    <div className="select_btn"><label><input name="site" type="radio"
                                                                                                        value="Site 2" /><span> Hoist</span></label></div>
                                                                                                    <div className="select_btn"><label><input name="site" type="radio"
                                                                                                        value="Site 2" /><span>Rope ladder</span></label></div>

                                                                                                </div>
                                                                                            </div>}
                                                                                            </>
                                                                                         }

                                                                                    </div>

                                                                                )
                                                                            })}






                                                                        </div>}


                                                                    </div>}

                                                                </div>


                                                                :
                                                                <div className="poc poc1 pt-0 mt-15">

                                                                    {inneritem.checkboxes && inneritem.checkboxes.length > 0 && inneritem.checkboxes.map((checkbox, i) => {
                                                                        return (<div className="check-box" key={i}>
                                                                            <div className="round">
                                                                                <input type="checkbox" name={checkbox} id={checkbox} />
                                                                                <label htmlFor={checkbox}></label>
                                                                            </div>
                                                                            {checkbox}
                                                                        </div>)
                                                                    })}



                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <div className="form_group">
                                                                                <input type="text" className="form-control" placeholder="Enter" />
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>


                                                        )

                                                    })}



                                                </div>
                                            </div>
                                        )

                                    })}

                                    {/* <p className="bg_title mt-15 mb-10">1. MOVING IN BED</p>
                                    <div className="section ctr pua ">
                                        <div className="check_item ">
                                            <label className="check">Moving up /down in bed</label>
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 1" /><span>Independent
                                                    </span></label></div>
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 2" /><span> Super Vision / verbal Prompt</span></label>
                                                </div>
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 2" /><span>N/A </span></label></div>
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 2" /><span>Assisted / Unable
                                                    </span></label></div>
                                            </div>
                                        </div>
                                        <div className="check_item ">
                                            <label className="check">Rolling/Turning</label>
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 1" /><span>Independent
                                                    </span></label></div>
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 2" /><span> Super Vision / verbal Prompt</span></label>
                                                </div>
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 2" /><span>N/A </span></label></div>
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 2" /><span>Assisted / Unable
                                                    </span></label></div>
                                            </div>
                                        </div>
                                        <div className="check_item ">
                                            <label className="check">Sitting Up in Bed</label>
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 1" /><span>Independent
                                                    </span></label></div>
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 2" /><span> Super Vision / verbal Prompt</span></label>
                                                </div>
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 2" /><span>N/A </span></label></div>
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 2" /><span>Assisted / Unable
                                                    </span></label></div>
                                            </div>
                                        </div>

                                        <div className="poc poc1 pt-0 mt-15">

                                            <div className="check-box">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-114" />
                                                    <label for="checkbox-114"></label>
                                                </div>
                                                Same Day & Night
                                            </div>
                                            <div className="check-box mt-20">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-116" />
                                                    <label for="checkbox-116"></label>
                                                </div>
                                                Other
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form_group">
                                                        <input type="text" className="form-control" placeholder="Enter" />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>



                                    <p className="bg_title mt-15 mb-10">2. GETTING IN/OUT OF BED</p>
                                    <div className="section ctr pua ">
                                        <div className="check_item ">
                                            <label className="check">Sitting on edge of bed to supine</label>
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 1" /><span>Independent
                                                    </span></label></div>
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 2" /><span> Super Vision / verbal Prompt</span></label>
                                                </div>
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 2" /><span>N/A </span></label></div>
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 2" /><span>Assisted / Unable
                                                    </span></label></div>
                                            </div>
                                        </div>
                                        <div className="check_item ">
                                            <label className="check">Supine to sitting on edge of bed</label>
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 1" /><span>Independent
                                                    </span></label></div>
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 2" /><span> Super Vision / verbal Prompt</span></label>
                                                </div>
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 2" /><span>N/A </span></label></div>
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 2" /><span>Assisted / Unable
                                                    </span></label></div>
                                            </div>
                                        </div>
                                        <div className="poc poc1 pt-0 mt-15">

                                            <div className="check-box">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-119" />
                                                    <label for="checkbox-119"></label>
                                                </div>
                                                Same Day & Night
                                            </div>
                                            <div className="check-box mt-20">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-120" />
                                                    <label for="checkbox-120"></label>
                                                </div>
                                                Other
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form_group">
                                                        <input type="text" className="form-control" placeholder="Enter" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <p className="bg_title mt-15 mb-10">3. TRANSFERRING</p>
                                    <div className="section ctr pua ">
                                        <div className="check_item ">
                                            <label className="check">TRANSFERRING</label>
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 1" /><span>Independent
                                                    </span></label></div>
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 2" /><span> Super Vision / verbal Prompt</span></label>
                                                </div>
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 2" /><span>N/A </span></label></div>
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 2" /><span>Assisted / Unable
                                                    </span></label></div>
                                            </div>
                                        </div>
                                        <div className="check_item ">
                                            <label className="check">Bed to chair / chair to bed</label>
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 1" /><span>Independent
                                                    </span></label></div>
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 2" /><span> Super Vision / verbal Prompt</span></label>
                                                </div>
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 2" /><span>N/A </span></label></div>
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 2" /><span>Assisted / Unable
                                                    </span></label></div>
                                            </div>
                                        </div>
                                        <div className="check_item ">
                                            <label className="check">Chair to chair</label>
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 1" /><span>Independent
                                                    </span></label></div>
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 2" /><span> Super Vision / verbal Prompt</span></label>
                                                </div>
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 2" /><span>N/A </span></label></div>
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 2" /><span>Assisted / Unable
                                                    </span></label></div>
                                            </div>
                                        </div>
                                        <div className="check_item ">
                                            <label className="check">Sitting to standing</label>
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 1" /><span>Independent
                                                    </span></label></div>
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 2" /><span> Super Vision / verbal Prompt</span></label>
                                                </div>
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 2" /><span>N/A </span></label></div>
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 2" /><span>Assisted / Unable
                                                    </span></label></div>
                                            </div>
                                        </div>
                                        <div className="poc poc1 pt-0 mt-15">

                                            <div className="check-box">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-119" />
                                                    <label for="checkbox-119"></label>
                                                </div>
                                                Same Day & Night
                                            </div>
                                            <div className="check-box mt-20">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-120" />
                                                    <label for="checkbox-120"></label>
                                                </div>
                                                Other
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form_group">
                                                        <input type="text" className="form-control" placeholder="Enter" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <p className="bg_title mt-15 mb-10">4, TOILETING</p>
                                    <div className="section ctr pua ">
                                        <div className="check_item ">
                                            <label className="check">Toileting On/off toilet or commode</label>
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 1" /><span>Independent
                                                    </span></label></div>
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 2" /><span> Super Vision / verbal Prompt</span></label>
                                                </div>
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 2" /><span>N/A </span></label></div>
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 2" /><span>Assisted / Unable
                                                    </span></label></div>
                                            </div>
                                        </div>
                                        <div className="check_item ">
                                            <label className="check">Toileting in bed</label>
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 1" /><span>Independent
                                                    </span></label></div>
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 2" /><span> Super Vision / verbal Prompt</span></label>
                                                </div>
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 2" /><span>N/A </span></label></div>
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 2" /><span>Assisted / Unable
                                                    </span></label></div>
                                            </div>
                                        </div>
                                        <div className="poc poc1 pt-0 mt-15">

                                            <div className="check-box">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-119" />
                                                    <label for="checkbox-119"></label>
                                                </div>
                                                Same Day & Night
                                            </div>
                                            <div className="check-box mt-20">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-120" />
                                                    <label for="checkbox-120"></label>
                                                </div>
                                                Other
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form_group">
                                                        <input type="text" className="form-control" placeholder="Enter" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <p className="bg_title mt-15 mb-10">5. SHOWERING, BATHING
                                        WASHING
                                    </p>
                                    <div className="section ctr pua ">
                                        <div className="check_item ">
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 1" /><span>Independent
                                                    </span></label></div>
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 2" /><span> Super Vision / verbal Prompt</span></label>
                                                </div>
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 2" /><span>N/A </span></label></div>
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 2" /><span>Assisted / Unable
                                                    </span></label></div>
                                            </div>
                                        </div>
                                        <div className="poc poc1 pt-0 mt-15">
                                            <div className="check-box">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-119" />
                                                    <label for="checkbox-119"></label>
                                                </div>
                                                Same Day & Night
                                            </div>
                                            <div className="check-box mt-20">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-120" />
                                                    <label for="checkbox-120"></label>
                                                </div>
                                                Other
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form_group">
                                                        <input type="text" className="form-control" placeholder="Enter" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <p className="bg_title mt-15 mb-10">6. WALKING
                                    </p>
                                    <div className="section ctr pua ">
                                        <div className="check_item ">
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 1" /><span>Independent
                                                    </span></label></div>
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 2" /><span> Super Vision / verbal Prompt</span></label>
                                                </div>
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 2" /><span>N/A </span></label></div>
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 2" /><span>Assisted / Unable
                                                    </span></label></div>
                                            </div>
                                        </div>
                                        <div className="poc poc1 pt-0 mt-15">
                                            <div className="check-box">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-131" />
                                                    <label for="checkbox-131"></label>
                                                </div>
                                                Mobile in wheelchair
                                            </div>
                                            <div className="check-box">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-132" />
                                                    <label for="checkbox-132"></label>
                                                </div>
                                                Climbing stairs: Insert details
                                            </div>
                                            <div className="check-box">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-135" />
                                                    <label for="checkbox-135"></label>
                                                </div>
                                                Same Day & Night
                                            </div>
                                            <div className="check-box mt-20">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-133" />
                                                    <label for="checkbox-133"></label>
                                                </div>
                                                Other
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form_group">
                                                        <input type="text" className="form-control" placeholder="Enter" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="bg_title mt-15 mb-10">7. STAIRS/EMERGENCY EVACUATION
                                    </p>
                                    <div className="section ctr pua ">
                                        <div className="check_item ">
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 1" /><span>Independent
                                                    </span></label></div>
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 2" /><span> Super Vision / verbal Prompt</span></label>
                                                </div>
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 2" /><span>N/A </span></label></div>
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 2" /><span>Assisted / Unable
                                                    </span></label></div>
                                            </div>
                                        </div>
                                        <div className="poc poc1 pt-0 mt-15">


                                            <div className="check-box">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-139" />
                                                    <label for="checkbox-139"></label>
                                                </div>
                                                Same Day & Night
                                            </div>
                                            <div className="check-box mt-20">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-140" />
                                                    <label for="checkbox-140"></label>
                                                </div>
                                                Other
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form_group">
                                                        <input type="text" className="form-control" placeholder="Enter" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>

                            </div>
                            <div className="popup_footer">
                                <div className="prescribe_footer">
                                    <div className="dsp-flex">
                                        <a className="icon_btn"> <span className="material-icons">save</span> SAVE</a>
                                        <button className="border_btn"><span className="material-icons">save_as</span>SAVE &amp;
                                            CLOSE
                                        </button>
                                    </div>
                                    <button className="btn dsp_flex"><span className="material-icons"><span
                                        className="material-icons">close</span></span>DISCARD </button>
                                </div>
                            </div>
                        </div>
                    </div>





                </div>
            </div>




        </React.Fragment>
    )
}

SafeHandling.propTypes = {
    t: PropTypes.any,
    getWards: PropTypes.func,
    getTheatres: PropTypes.func,
    wards: PropTypes.array,
    user: PropTypes.any,
    theatres: PropTypes.array,
    clinic: PropTypes.array,
    patients: PropTypes.array,
    doctors: PropTypes.array,
    profile: PropTypes.object
}

const mapStateToProps = ({ Layout, Login }) => ({
    wards: Layout.wards,
    user: Login.user,
    theatres: Layout.theatres,
    clinic: Layout.clinic,
    patients: Layout.patients,
    doctors: Layout.doctors,
    profile: Layout.profile
})

const mapDispatchToProps = dispatch => ({
    getWards: () => dispatch(getWardsList()),
    getTheatres: () => dispatch(getTheatresList()),
    getClinic: () => dispatch(getClinicList()),
    getPatient: () => dispatch(getPatientList()),
    getUserprofile: (id) => dispatch(getUprofile(id)),
    getDoctor: () => dispatch(getDoctorList()),
    patientupdate: (id, data) => dispatch(updatePatient(id, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(SafeHandling)
