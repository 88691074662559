// @flow
import { all, call, fork, takeEvery, put } from "redux-saga/effects"

import {
  CHANGE_LAYOUT,
  CHANGE_LAYOUT_WIDTH,
  CHANGE_SIDEBAR_THEME,
  CHANGE_SIDEBAR_TYPE,
  CHANGE_TOPBAR_THEME,
  SHOW_RIGHT_SIDEBAR,
  GET_WARDS,
  GET_THEATRES,
  GET_CLINIC,
  GET_PATIENTS,
  GET_SESSION_TEAM,
  USER_PROFILE,
  GET_DOCTORS,
  UPDATE_PATIENT,
  UPDATE_THEATRE_PATIENT,
  UPDATE_WARD_PATIENT,
  UPDATE_THEATRE_REPORTS,
  UPDATE_TEAM_BRIEF,
  UPDATE_NEWS,
  GET_NEWS,
  UPDATE_DAILY_ROUNDS,
  GET_DAILY_ROUNDS,
  UPDATE_TEAM,
  GET_TABLE_DATA
} from "./actionTypes"

import {
  changeSidebarType as changeSidebarTypeAction,
  changeTopbarTheme as changeTopbarThemeAction,
} from "./actions"

import {
  getWards, getTheatres, getClinics, getPatients, theatreTeamBrief, 
  getUserProfile, getDoctors, getTable, updatePatient, patienttheatre, patientWard, theatreReport, getSessionTeam, updateNews, getNews, getDRounds, updateDRounds, updateTable
} from "../../helpers/fakebackend_helper"

import { wardSuccess, theatreSuccess, clinicSuccess, patientSuccess, userprofileSuccess, doctorSuccess, getSessionListSuccess, getNewsSuccess, getDailyRoundsSuccess, teamListSuccess, gettableSuccess } from "./actions"

/**
 * Changes the body attribute
 */
function changeBodyAttribute(attribute, value) {
  if (document.body) document.body.setAttribute(attribute, value)
  return true
}

/**
 * Toggle the class on body
 * @param {*} cssClass
 */
function manageBodyClass(cssClass, action = "toggle") {
  switch (action) {
    case "add":
      if (document.body) document.body.classList.add(cssClass)
      break
    case "remove":
      if (document.body) document.body.classList.remove(cssClass)
      break
    default:
      if (document.body) document.body.classList.toggle(cssClass)
      break
  }

  return true
}

/**
 * Changes the layout type
 * @param {*} param0
 */
function* changeLayout({ payload: layout }) {
  try {
    if (layout === "horizontal") {
      yield put(changeTopbarThemeAction("dark"))
      document.body.removeAttribute("data-sidebar")
      document.body.removeAttribute("data-sidebar-size")
    } else {
      yield put(changeTopbarThemeAction("light"))
    }
    yield call(changeBodyAttribute, "data-layout", layout)
  } catch (error) { }
}

/**
 * Changes the layout width
 * @param {*} param0
 */
function* changeLayoutWidth({ payload: width }) {
  try {
    if (width === "boxed") {
      yield put(changeSidebarTypeAction("icon"))
      yield call(changeBodyAttribute, "data-layout-size", width)
    } else {
      yield put(changeSidebarTypeAction("default"))
      yield call(changeBodyAttribute, "data-layout-size", width)
    }
  } catch (error) { }
}

/**
 * Changes the left sidebar theme
 * @param {*} param0
 */
function* changeLeftSidebarTheme({ payload: theme }) {
  try {
    yield call(changeBodyAttribute, "data-sidebar", theme)
  } catch (error) { }
}

/**
 * Changes the topbar theme
 * @param {*} param0
 */
function* changeTopbarTheme({ payload: theme }) {
  try {
    yield call(changeBodyAttribute, "data-topbar", theme)
  } catch (error) { }
}

/**
 * Changes the left sidebar type
 * @param {*} param0
 */
function* changeLeftSidebarType({ payload: { sidebarType, isMobile } }) {
  try {
    switch (sidebarType) {
      case "compact":
        yield call(changeBodyAttribute, "data-sidebar-size", "small")
        yield call(manageBodyClass, "sidebar-enable", "remove")
        yield call(manageBodyClass, "vertical-collpsed", "remove")
        break
      case "icon":
        yield call(changeBodyAttribute, "data-keep-enlarged", "true")
        yield call(manageBodyClass, "vertical-collpsed", "add")
        break
      case "condensed":
        // yield call(manageBodyClass, "sidebar-enable", "add")
        // if (!isMobile) yield call(manageBodyClass, "vertical-collpsed", "add")
        yield call(manageBodyClass, "sidebar-enable", "add")
        if (window.screen.width >= 992) {
          yield call(manageBodyClass, "vertical-collpsed", "remove")
          yield call(manageBodyClass, "sidebar-enable", "remove")
          yield call(manageBodyClass, "vertical-collpsed", "add")
          yield call(manageBodyClass, "sidebar-enable", "add")
        } else {
          yield call(manageBodyClass, "sidebar-enable", "add")
          yield call(manageBodyClass, "vertical-collpsed", "add")
        }
        break
      default:
        yield call(changeBodyAttribute, "data-sidebar-size", "")
        yield call(manageBodyClass, "sidebar-enable", "remove")
        if (!isMobile)
          yield call(manageBodyClass, "vertical-collpsed", "remove")
        break
    }
  } catch (error) { }
}

/**
 * Show the rightsidebar
 */
function* showRightSidebar() {
  try {
    yield call(manageBodyClass, "right-bar-enabled", "add")
  } catch (error) { }
}

/**
 * getWardsList
 */
 function* getWardsList() {
  try {
    const response = yield call(getWards)
    yield put(wardSuccess(response))
  } catch (error) { }
}

/**
 * getTheatresList
 */
 function* getTheatresList() {
  try {
    const response = yield call(getTheatres)
    yield put(theatreSuccess(response))
  } catch (error) { }
}


/**
 * getClinicList
 */
 function* getClinicList() {
  try {
    const response = yield call(getClinics)
    yield put(clinicSuccess(response))
  } catch (error) { }
}

/**
 * getPatientList
 */
 function* getPatientList() {
  try {
    const response = yield call(getPatients)
    yield put(patientSuccess(response))
  } catch (error) { }
}

/**
 * getSessionList
 */
function* getSessionList() {
  try {
    const response = yield call(getSessionTeam)
    yield put(getSessionListSuccess(response))
  } catch (error) { }
}


/**
 * getDoctorList
 */
 function* getDoctorList() {
  try {
    const response = yield call(getDoctors)
    yield put(doctorSuccess(response))
  } catch (error) { }
}

/**
 * getTableListData
 */
function* getTableListData({ payload: { data, tablename } }) {
  try {
    const response = yield call(getTable,data, tablename)
    console.log('response ', response)
    yield put(gettableSuccess(response))
  } catch (error) { }
}


/**
 * getUserProfile
 */
 function* getProfile(id) {
  try {
    const response = yield call(getUserProfile, id)
   // localStorage.setItem("authUser", JSON.stringify(response))
    yield put(userprofileSuccess(response[0]))
  } catch (error) { }
}

/**
 * UpdateTeam
 */
function* updateTeamProfile({ payload: { data, tablename } }) {
  try {
    const response = yield call(updateTable, data, tablename)
    yield put(teamListSuccess(response[0]))
  } catch (error) { }
}

/**
 * UpdatePatient
 */
 function* updatePatientProfile({ payload: { id, data } }) {
  try {
    const response = yield call(updatePatient, id, data)
    yield put(userprofileSuccess(response[0]))
  } catch (error) { }
}

/**
 * Update News
 */
function* updateNewsInfo(data) {
  try {
    const response = yield call(updateNews, data)
    // yield put(userprofileSuccess(response[0]))
  } catch (error) { }
}

/**
 * Get News
 */
function* getNewsInfo(data) {
  try {
    const response = yield call(getNews, data)
    yield put(getNewsSuccess(response))
  } catch (error) { }
}

/**
 * Update News
 */
function* updateDailyRoundsInfo(data) {
  try {
    const response = yield call(updateDRounds, data)
    // yield put(userprofileSuccess(response[0]))
  } catch (error) { }
}

/**
 * Get News
 */
function* getDailyRoundsInfo(data) {
  try {
    const response = yield call(getDRounds, data)
    yield put(getDailyRoundsSuccess(response))
  } catch (error) { }
}


/**
 * UpdatePatient Theatre
 */
function* updatePatientTheatre({ payload: { id, data } }) {
  try {
    const response = yield call(patienttheatre, id, data)
    yield put(userprofileSuccess(response[0]))
  } catch (error) { }
}

/**
 * UpdatePatient Theatre
 */
function* updateTeamBriefTheatre({ payload: { id, data } }) {
  try {
    const response = yield call(theatreTeamBrief, id, data)
    // yield put(userprofileSuccess(response[0]))
  } catch (error) { }
}

/**
 * updateTheatreReport 
 */
function* updateTheatreReport({ payload: { id, data } }) {
  try {
    const response = yield call(theatreReport, id, data)
    yield put(userprofileSuccess(response[0]))
  } catch (error) { }
}

/**
 * UpdatePatient Ward
 */
function* updatePatientWard({ payload: { id, data } }) {
  try {
    const response = yield call(patientWard, id, data)
    yield put(userprofileSuccess(response[0]))
  } catch (error) { }
}



/**
 * Watchers
 */
export function* watchChangeLayoutType() {
  yield takeEvery(CHANGE_LAYOUT, changeLayout)
}

export function* watchChangeLayoutWidth() {
  yield takeEvery(CHANGE_LAYOUT_WIDTH, changeLayoutWidth)
}

export function* watchChangeLeftSidebarTheme() {
  yield takeEvery(CHANGE_SIDEBAR_THEME, changeLeftSidebarTheme)
}

export function* watchChangeLeftSidebarType() {
  yield takeEvery(CHANGE_SIDEBAR_TYPE, changeLeftSidebarType)
}

export function* watchChangeTopbarTheme() {
  yield takeEvery(CHANGE_TOPBAR_THEME, changeTopbarTheme)
}

export function* watchShowRightSidebar() {
  yield takeEvery(SHOW_RIGHT_SIDEBAR, showRightSidebar)
}

export function* getWardsData() {
  yield takeEvery(GET_WARDS, getWardsList)
}

export function* getTheatresData() {
  yield takeEvery(GET_THEATRES, getTheatresList)
}

export function* getClinicData() {
  yield takeEvery(GET_CLINIC, getClinicList)
}

export function* getPatientData() {
  yield takeEvery(GET_PATIENTS, getPatientList)
}

export function* getSessionTeamData() {
  yield takeEvery(GET_SESSION_TEAM, getSessionList)
}

export function* getProfileData() {
  yield takeEvery(USER_PROFILE, getProfile)
}

export function* getDoctorsData() {
  yield takeEvery(GET_DOCTORS, getDoctorList)
}

export function* getTablesData() {
  yield takeEvery(GET_TABLE_DATA, getTableListData)
}

export function* updateTeamData() {
  yield takeEvery(UPDATE_TEAM, updateTeamProfile)
}

export function* updatePatientData() {
  yield takeEvery(UPDATE_PATIENT, updatePatientProfile)
}

export function* updatePatientTheatreData() {
  yield takeEvery(UPDATE_THEATRE_PATIENT, updatePatientTheatre)
} 

export function* updateTeamBriefData() {
  yield takeEvery(UPDATE_TEAM_BRIEF, updateTeamBriefTheatre)
} 

export function* updateTheatreReportsData() {
  yield takeEvery(UPDATE_THEATRE_REPORTS, updateTheatreReport)
}

export function* updatePatientWardData() {
  yield takeEvery(UPDATE_WARD_PATIENT, updatePatientWard)
} 

export function* updateNewsData() {
  yield takeEvery(UPDATE_NEWS, updateNewsInfo)
}

export function* getNewsData() {
  yield takeEvery(GET_NEWS, getNewsInfo)
}


export function* updateDailyRoundsData() {
  yield takeEvery(UPDATE_DAILY_ROUNDS, updateDailyRoundsInfo)
}

export function* getDailyRoundsData() {
  yield takeEvery(GET_DAILY_ROUNDS, getDailyRoundsInfo)
}


function* LayoutSaga() {
  yield all([
    fork(watchChangeLayoutType),
    fork(watchChangeLayoutWidth),
    fork(watchChangeLeftSidebarTheme),
    fork(watchChangeLeftSidebarType),
    fork(watchShowRightSidebar),
    fork(watchChangeTopbarTheme),
    fork(getWardsData),
    fork(getTheatresData),
    fork(getClinicData),
    fork(getPatientData),
    fork(getSessionTeamData),
    fork(getProfileData),
    fork(getDoctorsData),
    fork(updatePatientData),
    fork(updatePatientTheatreData),
    fork(updateTeamBriefData),
    fork(updatePatientWardData),
    fork(updateTheatreReportsData),
    fork(updateNewsData),
    fork(getNewsData),
    fork(updateDailyRoundsData),
    fork(getDailyRoundsData),
    fork(updateTeamData),
    fork(getTablesData)
  ])
}

export default LayoutSaga
