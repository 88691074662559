import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import MetaTags from 'react-meta-tags';
import SignaturePad from 'react-signature-canvas'
import PatientBlock from "../Clinic/patientBlock";
import { removeSpecialCharacters } from '../../utils/utils';
import { Buffer } from 'buffer';
import { connect } from "react-redux"
import { getWardsList, getTheatresList, getClinicList, getPatientList, getUprofile, getDoctorList, updatePatient } from "../../store/actions"

const DischargePlanning = props => {

    let provider_sign = useRef(null);
    let responsibility_sign = useRef(null);
    let photograph_sign = useRef(null);

    const [producettos, setproducettos] = useState(false)
    const [dischargeLetter, setDischargeLetter] = useState(false)

    const [file, setFile] = useState({});
    const [legalInfo, setLegalInfo] = useState(false)
    const [clinicValues, setClinic] = useState({})

    const [selectedGroup, setselectedGroup] = useState(null)
    const [popupname, setpopup_name] = useState({})
    const [anydelay, setDelay] = useState(false)

    const {
        wards, user, theatres, clinic, patients, profile, doctors
    } = props;

    useEffect(() => {
        const { getWards, getTheatres, getClinic, getDoctor, getPatient, getUserprofile } = props
        // getWards()
        // getTheatres()
        // getClinic()
        getPatient()
        getDoctor()
        let userId = localStorage.getItem("authUser")
        getUserprofile(userId)
    }, [])


    useEffect(() => {
        let clinicPatient = JSON.parse(localStorage.getItem("p"))
        if (patients && patients.length > 0 && clinicPatient && clinicPatient._id) {
            const cPatient = patients.filter(
                usr => usr._id === clinicPatient._id
            )
            if (cPatient && cPatient[0]) {
                let cpatientData = cPatient[0]
                delete cpatientData["_id"]
                setClinic(clinicValues => ({
                    ...clinicValues,
                    ...cpatientData
                }));
            }

        }
    }, [patients])


    const updateInputValue = (e) => {
        let val = e.target.value;
        let name = e.target.name;
        let updatedValue = {};
        updatedValue = { [name]: val };
        setLegalInfo(legalInfo => ({
            ...legalInfo,
            ...updatedValue
        }));

    }

    const updateInputValues = (name, popupname, val) => {
        //  e.preventDefault()
        // let updatedValue = {
        //    [popupname]:{[name] : val}
        // };
        let updatedValue = clinicValues;
        //updatedValue = { [name]: val };
        // updatedValue[popupname][name] = val;
        if (updatedValue[popupname]) {
            updatedValue[popupname][name] = val;
        } else {
            updatedValue = {
                [popupname]: { [name]: val }

            };
        }
        console.log("updatedValue 2", updatedValue)
        setClinic(clinicValues => ({
            ...clinicValues,
            ...updatedValue
        }));
    }

    const updateClinic = (e) => {
        const { patientupdate } = props
        let clinicPatient = JSON.parse(localStorage.getItem("p"))
        let id = clinicPatient._id
        patientupdate(id, clinicValues)
        let patient = {
            ...clinicPatient,
            ...clinicValues
        }
        localStorage.setItem("p", JSON.stringify(patient))



    }

    const updateSwitchValue = (name, value) => {
        let updatedValue = {};
        updatedValue = { [name]: value };
        setLegalInfo(legalInfo => ({
            ...legalInfo,
            ...updatedValue
        }));

    }

    const handleChange = (event) => {
        updateSwitchValue(event.target.name, event.target.checked)
    }


    const clear = (e, refvalue) => {
        e.preventDefault()
        refvalue.clear()
    }
    const trim = (e, type, refvalue) => {
        e.preventDefault()
        sigUpload(refvalue.getCanvas().toDataURL('image/png'), type)
    }

    const sigUpload = async (filedata, name) => {
        const file = filedata
        //console.log("file ", file, name)
        let filename = name + ".png";
        // const base64 = await convertBase64(file)
        //console.log("filename ", filename)
        let pdetails = JSON.parse(localStorage.getItem("p"))

        const dataf = file.replace(/^data:image\/\w+;base64,/, "");

        const buf = Buffer.from(dataf, "base64");
        let uploadFilePath = "ipr/" + pdetails._id + "/" + name + "_result" + "/" + removeSpecialCharacters(filename);
        let data = { source_bucket: "ipr-resources", sourcepath: uploadFilePath }
        // console.log("data ", data)

        const response = await fetch(API_URL+'upload?appname=ipr', {
            method: "POST",
            mode: "cors",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
        const result = await response.json();
        // console.log("result ", result, buf.length)
        let url = result.result
        // console.log("url ", url)
        const base64Data = new Buffer.from(filedata.replace(/^data:image\/\w+;base64,/, ""), 'base64');
        // Getting the file type, ie: jpeg, png or gif
        const type = filedata.split(';')[0].split('/')[1];
        const uploadImage = await fetch(url, {
            method: "PUT",
            mode: "cors",
            headers: {
                'Content-Type': `image/${type}`,
                'Content-Encoding': 'base64',
                ACL: 'public-read',
            },
            body: base64Data
        })

        //console.log("resultImage ", uploadImage)

        let imagePath = "https://ipr-resources.s3.amazonaws.com/"
        let imageUploadPath = imagePath + uploadFilePath;


        let updateObj = {
            [name + "_result"]: imageUploadPath,
            [name + "_update"]: new Date()
        }

        setFile(file => ({
            ...file,
            ...updateObj
        }));
        setLegalInfo(legalInfo => ({
            ...legalInfo,
            ...updateObj
        }));

        // let patient = {
        //     ...pdetails,
        //     ...updateObj
        // }
        // localStorage.setItem("p", JSON.stringify(patient))

    };

    const handleFile = async (e, name) => {

        const file = e.target.files[0]
        // console.log("file ", file, name)
        let filename = e.target.files[0].name;
        // const base64 = await convertBase64(file)
        //console.log("filename ", filename)
        let pdetails = JSON.parse(localStorage.getItem("p"))
        // var reader = new FileReader();
        //   reader.readAsDataURL(file);
        // reader.onload = async function (e) {
        // console.log("e ", e)
        let uploadFilePath = "ipr/" + pdetails._id + "/" + name + "_result" + "/" + removeSpecialCharacters(filename);
        let data = { source_bucket: "ipr-resources", sourcepath: uploadFilePath }
        // console.log("data ", data)

        const response = await fetch(API_URL+'upload?appname=ipr', {
            method: "POST",
            mode: "cors",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
        const result = await response.json();

        // console.log("result ", result)
        let url = result.result
        // console.log("url ", url)
        const uploadImage = await fetch(url, {
            method: "PUT",
            mode: "cors",
            headers: {
                "Content-Type": "multipart/form-data",
                "Accept": "/",
                "Cache-Control": "no-cache",
                "Accept-Encoding": "gzip, deflate",
                "Connection": "keep-alive",
                "cache-control": "no-cache"
            },
            body: file
        })

        // console.log("resultImage ", uploadImage)

        let imagePath = "https://ipr-resources.s3.amazonaws.com/"
        let imageUploadPath = imagePath + uploadFilePath;

        let updateObj = {
            [name + "_result"]: imageUploadPath,
            [name + "_update"]: new Date()
        }
        // patientupdate(pdetails._id, updateObj)
        setFile(file => ({
            ...file,
            ...updateObj
        }));
        setLegalInfo(legalInfo => ({
            ...legalInfo,
            ...updateObj
        }));



    };

    const wardBack = (e) => {
        e.preventDefault()
        props.history.push("/wl")
        document.body.style.overflow = '';
    }

    let clinicPatient = JSON.parse(localStorage.getItem("p"))

    // console.log(" legalInfo ", legalInfo)

    return (
        <React.Fragment>

            <MetaTags>
                <title>DISCHARGE PLANNING</title>
            </MetaTags>
            <div className='ward '>
                <div className="popup_wrp">
                    <div className="popup_full prescribe">
                        <div className="popup_hed">
                            <h2>DISCHARGE PLANNING </h2>
                            <div className="dsp-flex"> <a className="popup_close" onClick={(e) => { wardBack(e) }}><span className="material-icons">close</span></a></div>

                        </div>
                        <div className="popup_content">
                            <div className="popup_scroll w-btns main ">
                                <div className="section ctr">
                                    <div className="yn_switch mb-15 mt-10">
                                        <div className="label-container">
                                            <p>Is patient Medically Fit For Discharge? </p>
                                        </div>
                                        <label for="check810" className="switch-container">
                                            <input hidden="" type="checkbox" className="switch_check" id="check810" />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>NO</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>YES</span>
                                            </div>
                                        </label>
                                    </div>
                                    <p className="sub_title mb-10 mt-10">Nurse lead discharge based on following criteria
                                        <span>Criteria-led discharge pro forma</span>
                                    </p>
                                    <div className="yn_switch mb-10 mt-10">
                                        <div className="label-container">
                                            <p>Have social issues been considered? </p>
                                        </div>
                                        <label for="check811" className="switch-container">
                                            <input hidden="" type="checkbox" className="switch_check" id="check811" />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>NO</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>YES</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="yn_switch mb-15 mt-10">
                                        <div className="label-container">
                                            <p>Do you have problems caring for yourself at home? </p>
                                        </div>
                                        <label for="check812" className="switch-container">
                                            <input hidden="" type="checkbox" className="switch_check" id="check812" />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>NO</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>YES</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="yn_switch mb-15 mt-0">
                                        <div className="label-container">
                                            <p>Do you live alone? </p>
                                        </div>
                                        <label for="check813" className="switch-container">
                                            <input hidden="" type="checkbox" className="switch_check" id="check813" />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>NO</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>YES</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="yn_switch mb-15 mt-0">
                                        <div className="label-container">
                                            <p>Do you care for someone else? </p>
                                        </div>
                                        <label for="check814" className="switch-container">
                                            <input hidden="" type="checkbox" className="switch_check" id="check814" />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>NO</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>YES</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="yn_switch mb-15 mt-0">
                                        <div className="label-container">
                                            <p>Do you receive community services? </p>
                                        </div>
                                        <label for="check815" className="switch-container">
                                            <input hidden="" type="checkbox" className="switch_check" id="check815" />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>NO</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>YES</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="yn_switch mb-10 mt-0">
                                        <div className="label-container">
                                            <p>Do you receive community services? </p>
                                        </div>
                                        <label for="check815" className="switch-container">
                                            <input hidden="" type="checkbox" className="switch_check" id="check815" />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>NO</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>YES</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="check_item mt-0 mb-10">
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input name="site" type="radio"
                                                value="Site 1" /><span>Nurses </span></label></div>
                                            <div className="select_btn"><label><input name="site" type="radio"
                                                value="Site 2" /><span> Home Care </span></label>
                                            </div>
                                            <div className="select_btn"><label><input name="site" type="radio"
                                                value="Site 2" /><span>Meals on Wheels
                                                </span></label></div>
                                            <div className="select_btn"><label><input name="site" type="radio"
                                                value="Site 2" /><span>Other
                                                </span></label></div>
                                        </div>
                                    </div>
                                    <div className="form_group">
                                        <label className="label">Other </label>
                                        <input type="text" className="form-control" placeholder="Enter" />
                                    </div>
                                    <div className="check_item mt-0 mb-10"><label className="check">Problems delaying Discharge +
                                    </label>
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input name="site" type="radio"
                                                value="Site 1" /><span>Out of area </span></label>
                                            </div>
                                            <div className="select_btn"><label><input name="site" type="radio"
                                                value="Site 2" /><span>Ambulance </span></label></div>

                                        </div>
                                    </div>
                                    <div className="check_item mt-0 mb-10"><label className="check">Anticipated discharge needs +
                                    </label>
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input name="site" type="radio"
                                                value="Site 1" /><span>Social</span></label>
                                            </div>
                                            <div className="select_btn"><label><input name="site" type="radio"
                                                value="Site 2" /><span>OT </span></label></div>
                                            <div className="select_btn"><label><input name="site" type="radio"
                                                value="Site 2" /><span>Physio input
                                                </span></label></div>
                                        </div>
                                    </div>
                                    <div className="check_item mt-0 mb-10"><label className="check">Place of discharge + </label>
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input name="site" type="radio"
                                                value="Site 1" /><span>Home</span></label>
                                            </div>
                                            <div className="select_btn"><label><input name="site" type="radio"
                                                value="Site 2" /><span>rehabilitation </span></label></div>

                                        </div>
                                    </div>
                                    <div className="check_item mt-0 mb-10"><label className="check">Discharge date and time +</label>
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input name="site" type="radio"
                                                value="Site 1" /><span>Home</span></label>
                                            </div>
                                            <div className="select_btn"><label><input name="site" type="radio"
                                                value="Site 2" /><span>rehabilitation </span></label></div>

                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <label className="label">Discharge Date </label>
                                                <input type="text" className="form-control" placeholder="Enter" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <label className="label">Discharge Time </label>
                                                <input type="text" className="form-control" placeholder="Enter" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <label className="label">Estimated date of discharge</label>
                                                <input type="text" className="form-control" placeholder="Enter" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="yn_switch mb-10 mt-0">
                                        <div className="label-container">
                                            <p>Provide patient with information relating to plan of care and checks patient
                                                understanding +</p>
                                        </div>
                                        <label className="switch-container">
                                            <input type="checkbox" className="switch_check" />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>NO</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>YES</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="yn_switch mb-10 mt-0">
                                        <div className="label-container">
                                            <p>PRODUCE TTOs</p>
                                        </div>
                                        <label for="check817" className="switch-container">
                                            <input hidden="" type="checkbox" className="switch_check" id="check817" />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>NO</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>YES</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="yn_switch mb-10 mt-0">
                                        <div className="label-container">
                                            <p>PRODUCE DISCHARGE LETTER</p>
                                        </div>
                                        <label for="check818" className="switch-container">
                                            <input hidden="" type="checkbox" className="switch_check" id="check818" />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>NO</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>YES</span>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                <div className="section ctr">
                                    <h2 className="popup_title">FOR DAY PATIENTS ONLY</h2>
                                    <div className="yn_switch mb-10 mt-0">
                                        <div className="label-container">
                                            <p>Who will be taking you home and be with you for 24 hours?</p>
                                        </div>
                                        <label for="check819" className="switch-container">
                                            <input hidden="" type="checkbox" className="switch_check" id="check819" />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>NO</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>YES</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <label className="label">Name </label>
                                                <input type="text" className="form-control" placeholder="Enter" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <label className="label">Relationship</label>
                                                <input type="text" className="form-control" placeholder="Enter" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <label className="label"> Best contact phone no</label>
                                                <input type="text" className="form-control" placeholder="Enter" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <label className="label"> Mobile no</label>
                                                <input type="text" className="form-control" placeholder="Enter" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="yn_switch mb-10 mt-0">
                                        <div className="label-container">
                                            <p>Criteria-led discharge pro forma</p>
                                        </div>
                                        <label for="check820" className="switch-container">
                                            <input hidden="" type="checkbox" className="switch_check" id="check820" />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>NO</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>YES</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="yn_switch mb-10 mt-10">
                                        <div className="label-container">
                                            <p>Follow-up arrangements </p>
                                        </div>
                                        <label for="check821" className="switch-container">
                                            <input hidden="" type="checkbox" className="switch_check" id="check821" />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>NO</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>YES</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="yn_switch mb-20 mt-10">
                                        <div className="label-container">
                                            <p>Provide patient information </p>
                                        </div>
                                        <label for="check821" className="switch-container">
                                            <input hidden="" type="checkbox" className="switch_check" id="check821" />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>NO</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>YES</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <button className="border_btn" onClick={() => { setproducettos(true) }} ><span
                                                className="material-icons">open_in_full</span>Produce TTOs
                                            </button>
                                        </div>
                                        <div className="col-md-6">
                                            <button className="border_btn" onClick={() => { setDischargeLetter(true) }}><span
                                                className="material-icons">open_in_full</span>Produce
                                                discharge letter
                                            </button>
                                        </div>
                                    </div>

                                </div>



                            </div>
                            <div className="popup_footer">
                                <div className="prescribe_footer">
                                    <div className="dsp-flex">
                                        <a className="icon_btn"> <span className="material-icons">save</span> SAVE</a>
                                        <button className="border_btn" onClick={(e) => { wardBack(e) }}><span className="material-icons">save_as</span>SAVE &amp;
                                            CLOSE
                                        </button>
                                    </div>

                                    <button className="btn dsp_flex" onClick={(e) => { wardBack(e) }}><span className="material-icons"><span className="material-icons">close</span></span>DISCARD </button>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>


                {producettos &&
                    <div className="popup_wrp">
                        <div className="popup_full guidance">
                            <div className="popup_hed">
                                <h2>TTOs </h2>
                                <div className="dsp-flex"> <a className="popup_close" onClick={() => { setproducettos(false) }}><span className="material-icons">close</span></a></div>

                            </div>
                            <div className="popup_content modal-body">
                                <div className="popup_scroll w-btns main ">
                                    <h2 className="popup_title">Discharge Prescription</h2>
                                    <table className="table table-striped table-bordered ">
                                        <thead>
                                            <tr>
                                                <th width="10%"></th>
                                                <th width="30%">Drug</th>
                                                <th width="10%">Dose</th>
                                                <th width="10%">Route</th>
                                                <th width="20%">Frequency</th>
                                                <th width="10%">Days Supplied </th>
                                                <th width="10%">GP to Continue </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td> <strong>Long standing</strong> </td>
                                                <td>ALENDRONIC ACID 70 mg Tablets</td>
                                                <td>70 mg</td>
                                                <td>ORAL</td>
                                                <td>in the morning, on the same day each week</td>
                                                <td></td>
                                                <td>Yes</td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td>ASPIRIN 75 mg Dispersible Tablets</td>
                                                <td>150 mg</td>
                                                <td>ORAL</td>
                                                <td>in the MORNING</td>
                                                <td>42</td>
                                                <td>No</td>
                                            </tr>
                                            <tr>
                                                <td><strong>Changes made</strong></td>
                                                <td>ATORVASTATIN 40 mg Tablets</td>
                                                <td>40 mg</td>
                                                <td>ORAL</td>
                                                <td>at NIGHT</td>
                                                <td>0</td>
                                                <td>Yes</td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td>CALCI -D (1000mg/1000unit) Chewable Tablet</td>
                                                <td>1 Tablet</td>
                                                <td>ORAL</td>
                                                <td>in the MORNING</td>
                                                <td></td>
                                                <td>Yes</td>
                                            </tr>
                                            <tr>
                                                <td><strong>Hospital given</strong></td>
                                                <td>CODEINE PHOSPHATE 30 mg Tablets</td>
                                                <td>60 mg</td>
                                                <td>ORAL</td>
                                                <td>FOUR times a day</td>
                                                <td>14</td>
                                                <td>No</td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td>MACROGOL Compound (MOVICOL/LAXIDO) Oral Powder</td>
                                                <td>1 Sachet</td>
                                                <td>ORAL</td>
                                                <td>TWICE a day</td>
                                                <td>14</td>
                                                <td>No</td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td>PARACETAMOL 500 mg Tablets</td>
                                                <td>1000 mg</td>
                                                <td>ORAL</td>
                                                <td>FOUR times a day</td>
                                                <td></td>
                                                <td>No</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>}


                    {dischargeLetter &&
                <div className="popup_wrp">
                    <div className="popup_full guidance">
                        <div className="popup_hed">
                            <h2>DISCHARGE LETTER </h2>
                            <div className="dsp-flex"> <a className="popup_close" onClick={() => { setDischargeLetter(false) }}><span
                                className="material-icons">close</span></a></div>

                        </div>


                        <div className="popup_content modal-body">
                            <div className="popup_scroll w-btns main ">

                                <div className="form_group">
                                    <label className="label">GP Practice </label>
                                    <input type="text" className="form-control" placeholder="Enter" />
                                </div>
                                <p className="sub_title mb-10 mt-0">Please find below the details of your patient’s
                                    recent treatment at our hospital:
                                </p>
                                <div className="section ctr">
                                    <h2 className="popup_title">Patient Details</h2>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <label className="label">Patient</label>
                                                <input type="text" className="form-control" placeholder="Enter" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <label className="label">DOB</label>
                                                <input type="text" className="form-control" placeholder="Enter" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <label className="label">Address</label>
                                                <input type="text" className="form-control" placeholder="Enter" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <label className="label">Hosp no</label>
                                                <input type="text" className="form-control" placeholder="Enter" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <label className="label">NHS No</label>
                                                <input type="text" className="form-control" placeholder="Enter" />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="section ctr">
                                    <h2 className="popup_title">Admission Details</h2>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <label className="label">Admitted on </label>
                                                <input type="text" className="form-control" placeholder="Enter" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <label className="label">Ward at </label>
                                                <input type="text" className="form-control" placeholder="Enter" />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form_group">
                                                <label className="label">Under care of </label>
                                                <input type="text" className="form-control" placeholder="Enter" />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="check_item mt-0 mb-0"><label className="check">Admitted </label>
                                                <div className="select_btns popup_select">
                                                    <div className="select_btn"><label><input name="site" type="radio"
                                                        value="Site 1" /><span>Electively </span></label>
                                                    </div>
                                                    <div className="select_btn"><label><input name="site" type="radio"
                                                        value="Site 2" /><span>as an emergency with
                                                            (problem)</span></label></div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <label className="label">Admitted on</label>
                                                <input type="text" className="form-control" placeholder="Enter" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <label className="label">Discharged on</label>
                                                <input type="text" className="form-control" placeholder="Enter" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <label className="label">Stay of days</label>
                                                <input type="text" className="form-control" placeholder="Enter" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="check_item mt-0 mb-0"><label className="check">Discharged to
                                            </label>
                                                <div className="select_btns popup_select">
                                                    <div className="select_btn"><label><input name="site" type="radio"
                                                        value="Site 1" /><span>Home </span></label></div>
                                                    <div className="select_btn"><label><input name="site" type="radio"
                                                        value="Site 2" /><span>Other</span></label></div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <label className="label">Follow up appointment date</label>
                                                <input type="text" className="form-control" placeholder="Enter" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <label className="label">With</label>
                                                <input type="text" className="form-control" placeholder="Enter" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <label className="label">At</label>
                                                <input type="text" className="form-control" placeholder="Enter" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="section ctr">
                                    <h2 className="popup_title">Requests for GP action</h2>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="check_item mt-0 mb-0">
                                                <div className="select_btns popup_select">
                                                    <div className="select_btn"><label><input name="site" type="radio"
                                                        value="Site 1" /><span>DEXA as Outpatient
                                                        </span></label></div>
                                                    <div className="select_btn"><label><input name="site" type="radio"
                                                        value="Site 2" /><span>Please chase</span></label>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="yn_switch mb-10 mt-10">
                                                <div className="label-container">
                                                    <p>Details of any results pending </p>
                                                </div>
                                                <label for="check1122" className="switch-container">
                                                    <input hidden="" type="checkbox" className="switch_check" id="check1122" value="" />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>NO</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>YES</span>
                                                    </div>
                                                </label>
                                            </div>

                                        </div>
                                        <div className="col-md-12">
                                            <div className="yn_switch mb-10 mt-10">
                                                <div className="label-container">
                                                    <p>Medication adjustments </p>
                                                </div>
                                                <label for="check1123" className="switch-container">
                                                    <input hidden="" type="checkbox" className="switch_check" id="check1123" value="" />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>NO</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>YES</span>
                                                    </div>
                                                </label>
                                            </div>

                                        </div>
                                        <div className="col-md-12">
                                            <div className="yn_switch mb-10 mt-10">
                                                <div className="label-container">
                                                    <p>Please advise us if this patient develops an infection or any
                                                        other complication which you believe is related to this
                                                        procedure </p>
                                                </div>
                                                <label for="check1124" className="switch-container">
                                                    <input hidden="" type="checkbox" className="switch_check" id="check1124" value="" />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>NO</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>YES</span>
                                                    </div>
                                                </label>
                                            </div>

                                        </div>
                                        <div className="col-md-12">
                                            <div className="yn_switch mb-10 mt-10">
                                                <div className="label-container">
                                                    <p>'Fit' certificate given to patient? </p>
                                                </div>
                                                <label for="check1125" className="switch-container">
                                                    <input hidden="" type="checkbox" className="switch_check" id="check1125" value="" />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>NO</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>YES</span>
                                                    </div>
                                                </label>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="section ctr">
                                    <h2 className="popup_title">Requests for Nursing action</h2>
                                    <div className="yn_switch mb-10 mt-10">
                                        <div className="label-container">
                                            <p>Dressings required</p>
                                        </div>
                                        <label for="check1126" className="switch-container">
                                            <input hidden="" type="checkbox" className="switch_check" id="check1126" value="" />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>NO</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>YES</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <label className="label">Details</label>
                                                <input type="text" className="form-control" placeholder="Enter" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <label className="label">Frequency</label>
                                                <input type="text" className="form-control" placeholder="Enter" />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="yn_switch mb-10 mt-10">
                                                <div className="label-container">
                                                    <p>Removal of staples / sutures required? </p>
                                                </div>
                                                <label for="check1127" className="switch-container">
                                                    <input hidden="" type="checkbox" className="switch_check" id="check1127" value="" />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>NO</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>YES</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <label className="label">Date</label>
                                                <input type="text" className="form-control" placeholder="Enter" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <label className="label">Details of District Nurse arranged</label>
                                                <input type="text" className="form-control" placeholder="Enter" />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="yn_switch mb-10 mt-10">
                                                <div className="label-container">
                                                    <p>Activity restrictions (weight bearing status, ROM, etc.)</p>
                                                </div>
                                                <label for="check1128" className="switch-container">
                                                    <input hidden="" type="checkbox" className="switch_check" id="check1128" value="" />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>NO</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>YES</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="yn_switch mb-10 mt-10">
                                                <div className="label-container">
                                                    <p>Written post discharge advice following procedure given to
                                                        patient</p>
                                                </div>
                                                <label for="check1129" className="switch-container">
                                                    <input hidden="" type="checkbox" className="switch_check" id="check1129" value="" />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>NO</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>YES</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="yn_switch mb-10 mt-10">
                                                <div className="label-container">
                                                    <p>Equipment sent home with patient</p>
                                                </div>
                                                <label for="check1130" className="switch-container">
                                                    <input hidden="" type="checkbox" className="switch_check" id="check1130" value="" />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>NO</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>YES</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <label className="label">Details of Social Care arranged</label>
                                                <input type="text" className="form-control" placeholder="Enter" />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="yn_switch mb-10 mt-10">
                                                <div className="label-container">
                                                    <p>Physiotherapy follow-up</p>
                                                </div>
                                                <label for="check1131" className="switch-container">
                                                    <input hidden="" type="checkbox" className="switch_check" id="check1131" value="" />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>NO</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>YES</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <label className="label">Details of other agency arranged</label>
                                                <input type="text" className="form-control" placeholder="Enter" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="section ctr">
                                    <h2 className="popup_title">Hospital management/procedures</h2>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="yn_switch mb-10 mt-10">
                                                <div className="label-container">
                                                    <p>Admitted with: Left fractured neck of femur following
                                                        mechanical fall</p>
                                                </div>
                                                <label for="check1132" className="switch-container">
                                                    <input hidden="" type="checkbox" className="switch_check" id="check1132" value="" />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>NO</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>YES</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="yn_switch mb-10 mt-10">
                                                <div className="label-container">
                                                    <p>Background: Benign prostatic hyperplasia,
                                                        hypercholesterolaemia, history of fall.</p>
                                                </div>
                                                <label for="check1133" className="switch-container">
                                                    <input hidden="" type="checkbox" className="switch_check" id="check1133" value="" />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>NO</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>YES</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="yn_switch mb-10 mt-10">
                                                <div className="label-container">
                                                    <p>Left Hemiarthroplasty 29.4.2020</p>
                                                </div>
                                                <label for="check1134" className="switch-container">
                                                    <input hidden="" type="checkbox" className="switch_check" id="check1134" value="" />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>NO</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>YES</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="yn_switch mb-10 mt-10">
                                                <div className="label-container">
                                                    <p>Uncomplicated recovery</p>
                                                </div>
                                                <label for="check1133" className="switch-container">
                                                    <input hidden="" type="checkbox" className="switch_check" id="check1133" value="" />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>NO</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>YES</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="yn_switch mb-10 mt-10">
                                                <div className="label-container">
                                                    <p>Complications including infections</p>
                                                </div>
                                                <label for="check1134" className="switch-container">
                                                    <input hidden="" type="checkbox" className="switch_check" id="check1134" value="" />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>NO</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>YES</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <label className="label">Significant results</label>
                                                <input type="text" className="form-control" placeholder="Enter" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <label className="label">Pathology</label>
                                                <input type="text" className="form-control" placeholder="Enter" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <label className="label">Radiology</label>
                                                <input type="text" className="form-control" placeholder="Enter" />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="yn_switch mb-10 mt-10">
                                                <div className="label-container">
                                                    <p>Seen by Ortho geriatrician and physiotherapy team as
                                                        inpatient</p>
                                                </div>
                                                <label for="check1135" className="switch-container">
                                                    <input hidden="" type="checkbox" className="switch_check" id="check1135" value="" />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>NO</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>YES</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="yn_switch mb-10 mt-10">
                                                <div className="label-container">
                                                    <p>Needs DEXA as Outpatient</p>
                                                </div>
                                                <label for="check1136" className="switch-container">
                                                    <input hidden="" type="checkbox" className="switch_check" id="check1136" value="" />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>NO</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>YES</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h2 className="popup_title">Discharge Prescription</h2>
                                <table className="table table-striped table-bordered ">
                                    <thead>
                                        <tr>
                                            <th width="10%"></th>
                                            <th width="30%">Drug</th>
                                            <th width="10%">Dose</th>
                                            <th width="10%">Route</th>
                                            <th width="20%">Frequency</th>
                                            <th width="10%">Days Supplied </th>
                                            <th width="10%">GP to Continue </th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td> <strong>Long standing</strong> </td>
                                            <td>ALENDRONIC ACID 70 mg Tablets</td>
                                            <td>70 mg</td>
                                            <td>ORAL</td>
                                            <td>in the morning, on the same day each week</td>
                                            <td></td>
                                            <td>Yes</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>ASPIRIN 75 mg Dispersible Tablets</td>
                                            <td>150 mg</td>
                                            <td>ORAL</td>
                                            <td>in the MORNING</td>
                                            <td>42</td>
                                            <td>No</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Changes made</strong></td>
                                            <td>ATORVASTATIN 40 mg Tablets</td>
                                            <td>40 mg</td>
                                            <td>ORAL</td>
                                            <td>at NIGHT</td>
                                            <td>0</td>
                                            <td>Yes</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>CALCI -D (1000mg/1000unit) Chewable Tablet</td>
                                            <td>1 Tablet</td>
                                            <td>ORAL</td>
                                            <td>in the MORNING</td>
                                            <td></td>
                                            <td>Yes</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Hospital given</strong></td>
                                            <td>CODEINE PHOSPHATE 30 mg Tablets</td>
                                            <td>60 mg</td>
                                            <td>ORAL</td>
                                            <td>FOUR times a day</td>
                                            <td>14</td>
                                            <td>No</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>MACROGOL Compound (MOVICOL/LAXIDO) Oral Powder</td>
                                            <td>1 Sachet</td>
                                            <td>ORAL</td>
                                            <td>TWICE a day</td>
                                            <td>14</td>
                                            <td>No</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>PARACETAMOL 500 mg Tablets</td>
                                            <td>1000 mg</td>
                                            <td>ORAL</td>
                                            <td>FOUR times a day</td>
                                            <td></td>
                                            <td>No</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="section ctr">
                                    <h2 className="popup_title">Pharmacy notes</h2>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="yn_switch mb-10 mt-10">
                                                <div className="label-container">
                                                    <p>Unchanged from prior to treatment? </p>
                                                </div>
                                                <label for="check1138" className="switch-container">
                                                    <input hidden="" type="checkbox" className="switch_check" id="check1138" value="" />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>NO</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>YES</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="yn_switch mb-10 mt-10">
                                                <div className="label-container">
                                                    <p>Give details below including those stopped or changed </p>
                                                </div>
                                                <label for="check1139" className="switch-container">
                                                    <input hidden="" type="checkbox" className="switch_check" id="check1139" value="" />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>NO</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>YES</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form_group">
                                                <label className="label">Reasons for changes in medications
                                                    prescribed</label>
                                                <input type="text" className="form-control" placeholder="Enter" />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="yn_switch mb-10 mt-10">
                                                <div className="label-container">
                                                    <p>Commenced alendronic acid and Calci-D for bone protection
                                                    </p>
                                                </div>
                                                <label for="check1140" className="switch-container">
                                                    <input hidden="" type="checkbox" className="switch_check" id="check1140" value="" />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>NO</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>YES</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="yn_switch mb-10 mt-10">
                                                <div className="label-container">
                                                    <p>Course of aspirin VTE prophylaxis to continue for 42 days on
                                                        discharge </p>
                                                </div>
                                                <label for="check1141" className="switch-container">
                                                    <input hidden="" type="checkbox" className="switch_check" id="check1141" value="" />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>NO</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>YES</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="yn_switch mb-10 mt-10">
                                                <div className="label-container">
                                                    <p>Analgesia and laxatives provided</p>
                                                </div>
                                                <label for="check1141" className="switch-container">
                                                    <input hidden="" type="checkbox" className="switch_check" id="check1141" value="" />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>NO</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>YES</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="section ctr">

                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <label className="label">Discharged by </label>
                                                <input type="text" className="form-control" placeholder="Enter" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <label className="label">Bleep </label>
                                                <input type="text" className="form-control" placeholder="Enter" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <label className="label">Designation </label>
                                                <input type="text" className="form-control" placeholder="Enter" />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form_group">
                                                <label className="label">Date </label>
                                                <input type="text" className="form-control" placeholder="Enter" />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form_group">
                                                <label className="label">Time </label>
                                                <input type="text" className="form-control" placeholder="Enter" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="section ctr">

                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="yn_switch mb-10 mt-10">
                                                <div className="label-container">
                                                    <p>Advance directive in place? </p>
                                                </div>
                                                <label for="check1145" className="switch-container">
                                                    <input hidden="" type="checkbox" className="switch_check" id="check1145" value="" />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>NO</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>YES</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="yn_switch mb-10 mt-10">
                                                <div className="label-container">
                                                    <p>DNACPR decision in place? </p>
                                                </div>
                                                <label for="check1146" className="switch-container">
                                                    <input hidden="" type="checkbox" className="switch_check" id="check1146" value="" />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>NO</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>YES</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form_group">
                                                <label className="label">Mental capacity concerns </label>
                                                <input type="text" className="form-control" placeholder="Enter" />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="check_item mt-0 mb-10"><label className="check">Special
                                                requirements </label>
                                                <div className="select_btns popup_select">
                                                    <div className="select_btn"><label><input name="site" type="radio"
                                                        value="Site 1" /><span>language </span></label>
                                                    </div>
                                                    <div className="select_btn"><label><input name="site" type="radio"
                                                        value="Site 2" /><span>interpreter </span></label>
                                                    </div>
                                                    <div className="select_btn"><label><input name="site" type="radio"
                                                        value="Site 2" /><span>advocate
                                                        </span></label></div>
                                                    <div className="select_btn"><label><input name="site" type="radio"
                                                        value="Site 2" /><span>other
                                                        </span></label></div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="col-md-12">
                                            <div className="form_group">
                                                <label className="label">Other </label>
                                                <input type="text" className="form-control" placeholder="Enter" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <p className="popup_title">Copy to </p>
                                    </div>
                                    <div className="col-md-12 copy_btns">

                                        <button className="border_btn"><span className="material-icons">send</span>GP </button>
                                        <button className="border_btn"><span className="material-icons">send</span>Patient </button>
                                        <button className="border_btn"><span className="material-icons">send</span>Patient record
                                        </button>


                                    </div>

                                </div>


                            </div>
                        </div>
                    </div>
                </div>
                    }






            </div>


        </React.Fragment>
    )
}

DischargePlanning.propTypes = {
    t: PropTypes.any,
    getWards: PropTypes.func,
    getTheatres: PropTypes.func,
    wards: PropTypes.array,
    user: PropTypes.any,
    theatres: PropTypes.array,
    clinic: PropTypes.array,
    patients: PropTypes.array,
    doctors: PropTypes.array,
    profile: PropTypes.object
}

const mapStateToProps = ({ Layout, Login }) => ({
    wards: Layout.wards,
    user: Login.user,
    theatres: Layout.theatres,
    clinic: Layout.clinic,
    patients: Layout.patients,
    doctors: Layout.doctors,
    profile: Layout.profile
})

const mapDispatchToProps = dispatch => ({
    getWards: () => dispatch(getWardsList()),
    getTheatres: () => dispatch(getTheatresList()),
    getClinic: () => dispatch(getClinicList()),
    getPatient: () => dispatch(getPatientList()),
    getUserprofile: (id) => dispatch(getUprofile(id)),
    getDoctor: () => dispatch(getDoctorList()),
    patientupdate: (id, data) => dispatch(updatePatient(id, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(DischargePlanning)
