import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import MetaTags from 'react-meta-tags';
import bodyOutline from '../../assets/images/nurse-icons/bodyOutline.svg';

import PatientBlock from "../Clinic/patientBlock";
import { connect } from "react-redux"
import { getWardsList, getTheatresList, getClinicList, getPatientList, getUprofile, getDoctorList, updatePatient } from "../../store/actions"

const SkinAssesment = props => {


    const [visiblePatients, setPatient] = useState(true)
    const [visiblelanding, setClanding] = useState(false)
    const [successmessage, setSuccess] = useState("")


    const [assesmentValues, setAssesment] = useState({})

    const {
        wards, user, theatres, clinic, patients, profile, doctors
    } = props;

    useEffect(() => {
        const { getWards, getTheatres, getClinic, getDoctor, getPatient, getUserprofile } = props
        // getWards()
        // getTheatres()
        // getClinic()
        getPatient()
        getDoctor()
        let userId = localStorage.getItem("authUser")
        getUserprofile(userId)
    }, [])

    const updateRadioValue = (e) => {
        let val = e.target.value;
        let name = e.target.name;
        let updatedValue = { [name]: val };
        setAssesment(assesmentValues => ({
            ...assesmentValues,
            ...updatedValue
        }));
    }


    const closeClick = (e) => {
        e.preventDefault()
        localStorage.removeItem("a")
        props.history.push("/riskassesment")
    }

    let assesmentPage = JSON.parse(localStorage.getItem("a"))
    let clinicPatient = JSON.parse(localStorage.getItem("p"))

    return (
        <React.Fragment>

            <MetaTags>
                <title>{assesmentPage}</title>
            </MetaTags>
            <div className="dashboard ward">
                <div className="main">

                    <div className="popup_wrp">
                        <div className="popup_full prescribe">
                            <div className="popup_hed">
                                <h2>SKIN ASSESMENT </h2>
                                <div className="dsp-flex"> <a className="popup_close" onClick={(e) => closeClick(e)}><span className="material-icons">close</span></a></div>

                            </div>
                            <div className="popup_content">
                                <div className="popup_scroll w-btns main nutrition">


                                    <div className="modal add_form guidance_popup fade" id="guidance" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                        <div className="modal-dialog" role="document">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title">GUIDANCE</h5>
                                                    <span className="material-icons" data-dismiss="modal" aria-label="Close">
                                                        close
                                                    </span>
                                                </div>
                                                <div className="modal-body">
                                                    <p>Observe cannula 8 hourly or more frequently if clinically indicated
                                                    </p>
                                                    <p>Secure cannula with an appropriate dressing – change if soiled or contaminated
                                                    </p>
                                                    <p>Aseptic technique must be followed for insertion</p>
                                                    <p>Consider re-siting the cannula every 48-72 hours or as indicated by VIP score
                                                        Plan and document care
                                                    </p>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <PatientBlock patient={clinicPatient} />
                                    <div className="bar">
                                        <div className="left">
                                            <div className="tag" data-toggle="modal"
                                                data-target="#guidance"> Guidance </div>
                                            <h3 className="sub_title">SKIN ASSESMENT </h3>
                                        </div>
                                        <div className="right">
                                            <a href="#" className="btn"> <span className="material-symbols-outlined"> assignment </span> Not
                                                Yet Done</a>
                                            <div className="tag"> Due </div>
                                        </div>
                                    </div>
                                   
                                    <div className="section ctr pua">
                            <div className="section1">
                                <h2 className="title">Pressure wound
                                </h2>
                                <h3>Skin & Pressure Areas Inspection</h3>
                                <p>Must be completed on all patients - 3 times per day <a href="#"
                                        className="small_btn">Around 4 am, Before 12:00, Before 20:00</a></p>
                                <p>Look & feel for a category 1 pressure ulcer.</p>
                                <p>If any pressure ulcer damage identified: <a href="#" className="big_btn">Take a
                                        Picture</a></p>
                            </div>
                            <div className="section2">
                                <h3>Select Location</h3>
                                <div className="picture_blk">
                                    <div className="left">
                                        <ul>
                                            <li> <span className="num">01</span> <span className="text">Back of head</span>
                                            </li>
                                            <li> <span className="num">02</span> <span className="text">Ear - Left/ Right</span>
                                            </li>
                                            <li> <span className="num">03</span> <span className="text">Scapula - Left/
                                                    Right</span> </li>
                                            <li> <span className="num">04</span> <span className="text">Spine</span> </li>
                                            <li> <span className="num">05</span> <span className="text">Shoulder - Left/
                                                    Right</span> </li>
                                            <li> <span className="num">06</span> <span className="text">Elbow - Left/
                                                    Right</span> </li>
                                            <li> <span className="num">07</span> <span className="text">Ribs</span> </li>
                                            <li> <span className="num">08</span> <span className="text">Sacrum / Natal
                                                    cleft</span> </li>
                                            <li> <span className="num">09</span> <span className="text">Ischial - Left/
                                                    Right</span> </li>
                                            <li> <span className="num">10</span> <span className="text">Trochanter - Left/
                                                    Right</span> </li>
                                            <li> <span className="num">11</span> <span className="text">Knee - Left/
                                                    Right</span> </li>
                                            <li> <span className="num">12</span> <span className="text">Ankle - Left/
                                                    Right</span> </li>
                                            <li> <span className="num">13</span> <span className="text">Heel - Left/
                                                    Right</span> </li>
                                            <li> <span className="num">14</span> <span className="text">Toes</span> </li>
                                        </ul>
                                        <div className="form_group">
                                            <label className="label">Others</label>
                                            <input type="text" className="form-control" placeholder="Eg : Nose" />
                                        </div>
                                    </div>

                                    <div className="right">
                                        <div className="figure_section">
                                            <div className="buttons_sec">
                                                <div className="anterior_sec">
                                                    <a className="pain_area left_ear">2</a>
                                                    <a className="pain_area right_ear">2</a>
                                                    <a className="pain_area left_shoulder">5</a>
                                                    <a className="pain_area right_shoulder">5</a>
                                                    <a className="pain_area left_rib">7</a>
                                                    <a className="pain_area right_rib">7</a>
                                                    <a className="pain_area left_trochanter">10</a>
                                                    <a className="pain_area right_trochanter">10</a>
                                                    <a className="pain_area left_knee">11</a>
                                                    <a className="pain_area right_knee">11</a>
                                                    <a className="pain_area left_ankle">12</a>
                                                    <a className="pain_area right_ankle">12</a>
                                                    <a className="pain_area left_toe">14</a>
                                                    <a className="pain_area right_toe">14</a>
                                                </div>
                                                <div className="posterior_sec">
                                                    <a className="pain_area backOfHead">1</a>
                                                    <a className="pain_area left_scapula">3</a>
                                                    <a className="pain_area right_scapula">3</a>
                                                    <a className="pain_area spine">4</a>
                                                    <a className="pain_area sacrum">8</a>
                                                    <a className="pain_area left_elbow">6</a>
                                                    <a className="pain_area right_elbow">6</a>
                                                    <a className="pain_area left_ischial">9</a>
                                                    <a className="pain_area right_ischial">9</a>
                                                    <a className="pain_area left_heel">13</a>
                                                    <a className="pain_area right_heel">13</a>
                                                </div>
                                            </div>
                                            <img className="img" src={bodyOutline} alt="" />
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className="poc poc1">
                                    <div className="check-box">
                                        <div className="round">
                                            <input type="checkbox" id="checkbox-1" />
                                            <label for="checkbox-1"></label>
                                        </div>
                                        All areas intact, Healthy & Blanching
                                    </div>
                                    <div className="check-box">
                                        <div className="round">
                                            <input type="checkbox" id="checkbox-1" />
                                            <label for="checkbox-1"></label>
                                        </div>
                                        Pressure Ulcer damage identified
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="section ctr pua">
                            <div className="poc pt-0">
                                <h3>Pressure wound</h3>

                                <p className="p-s1"> <strong>Pressure ulcer Category 1234 UN SDTI</strong> (suspected deep
                                    tissue injury) Pressure ulcers must be graded according to their depth using the
                                    European Ulcer Advisory Panel Classification (EPUAP 1998)</p>
                            </div>
                            <div className="poc">
                                <h3 className="mb-20">Non pressure wound </h3>
                                <div className="check-box">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-3" />
                                        <label for="checkbox-3"></label>
                                    </div>
                                    Leg ulcer
                                </div>
                                <div className="check-box">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-4" />
                                        <label for="checkbox-4"></label>
                                    </div>
                                    Venous stasis ulcer
                                </div>
                                <div className="check-box">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-5" />
                                        <label for="checkbox-5"></label>
                                    </div>
                                    Venous Mixed
                                </div>
                                <div className="check-box">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-6" />
                                        <label for="checkbox-6"></label>
                                    </div>
                                    Arterial ulcer
                                </div>
                                <div className="check-box">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-7" />
                                        <label for="checkbox-7"></label>
                                    </div>
                                    Ischaemic Foot Ulcer
                                </div>
                                <div className="check-box mb-0">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-8" />
                                        <label for="checkbox-8"></label>
                                    </div>
                                    Diabetic foot ulcer Unknown
                                </div>
                            </div>
                            <div className="poc">
                                <div className="check-box">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-9" />
                                        <label for="checkbox-9"></label>
                                    </div>
                                    MASD Moisture Associated Skin Damage
                                </div>
                                <div className="check-box">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-10" />
                                        <label for="checkbox-10"></label>
                                    </div>
                                    IAD Incontinence Associated Dermatitis
                                </div>
                                <div className="check-box">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-11" />
                                        <label for="checkbox-11"></label>
                                    </div>
                                    Weeping Cellulitis (W)
                                </div>
                                <div className="check-box mb-0">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-12" />
                                        <label for="checkbox-12"></label>
                                    </div>
                                    Oedematous Legs (OL)
                                </div>

                            </div>
                            <div className="poc">
                                <div className="check-box">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-13" />
                                        <label for="checkbox-13"></label>
                                    </div>
                                    Problematic Primary Intention
                                </div>
                                <div className="check-box">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-14" />
                                        <label for="checkbox-14"></label>
                                    </div>
                                    Leaking surgical wound
                                </div>
                                <div className="check-box">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-15" />
                                        <label for="checkbox-15"></label>
                                    </div>
                                    Surgical suture line
                                </div>
                                <div className="check-box">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-16" />
                                        <label for="checkbox-16"></label>
                                    </div>
                                    Dehisced Surgical Wound
                                </div>
                                <div className="check-box">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-17" />
                                        <label for="checkbox-17"></label>
                                    </div>
                                    Skin graft
                                </div>
                                <div className="check-box mb-0">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-18" />
                                        <label for="checkbox-18"></label>
                                    </div>
                                    Trauma / Haematoma
                                </div>
                            </div>
                            <div className="poc">
                                <div className="check-box">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-19" />
                                        <label for="checkbox-19"></label>
                                    </div>
                                    Skin tear Type1 Type2 Type 3
                                </div>
                                <div className="check-box">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-20" />
                                        <label for="checkbox-20"></label>
                                    </div>
                                    Pre-Tibial Laceration
                                </div>
                                <div className="check-box">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-21" />
                                        <label for="checkbox-21"></label>
                                    </div>
                                    Laceration
                                </div>
                                <div className="check-box">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-22" />
                                        <label for="checkbox-22"></label>
                                    </div>
                                    Bruise
                                </div>
                                <div className="check-box">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-23" />
                                        <label for="checkbox-23"></label>
                                    </div>
                                    Abrasion
                                </div>
                                <div className="check-box">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-24" />
                                        <label for="checkbox-24"></label>
                                    </div>
                                    Puncture
                                </div>
                                <div className="check-box">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-25" />
                                        <label for="checkbox-25"></label>
                                    </div>
                                    Scar
                                </div>
                                <div className="check-box">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-26" />
                                        <label for="checkbox-26"></label>
                                    </div>
                                    Rash
                                </div>
                                <div className="check-box">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-27" />
                                        <label for="checkbox-27"></label>
                                    </div>
                                    Burn / Scald
                                </div>
                                <div className="check-box">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-28" />
                                        <label for="checkbox-28"></label>
                                    </div>
                                    Abscess
                                </div>
                                <div className="check-box mb-0">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-29" />
                                        <label for="checkbox-29"></label>
                                    </div>
                                    Excoriation
                                </div>
                            </div>
                            <div className="poc">
                                <div className="check-box">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-30" />
                                        <label for="checkbox-30"></label>
                                    </div>
                                    Malignant ulcer/ Fungating Wound
                                </div>
                                <div className="check-box mb-0">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-31" />
                                        <label for="checkbox-31"></label>
                                    </div>
                                    Other Lession
                                </div>
                            </div>
                            <div className="poc">
                                <div className="check-box font_16">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-32" />
                                        <label for="checkbox-32"></label>
                                    </div>
                                    Complex wound assessment needed
                                </div>
                            </div>
                        </div>
                        <div className="section ctr pua">
                            <div className="poc green">
                                <h3>PRESSURE ULCER RISK</h3>
                                <div className="check-box">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-33" />
                                        <label for="checkbox-33"></label>
                                    </div>
                                    The patient has normal/intact skin and can move independently or with aids
                                </div>
                            </div>
                            <div className="poc green mb-15">
                                <h3 className="mt-10">MANAGEMENT PLAN</h3>
                                <ol>
                                    <li>Nurse on static foam mattress</li>
                                    <li>Complete RAG risk assessment weekly or at status change</li>
                                </ol>
                            </div>
                            <div className="poc red">
                                <h3>PRESSURE ULCER RISK</h3>
                                <div className="check-box">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-34" />
                                        <label for="checkbox-34"></label>
                                    </div>
                                    The patient has a current pressure ulcer category 1 or above
                                </div>
                                <div className="check-box">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-35" />
                                        <label for="checkbox-35"></label>
                                    </div>
                                    The patient is a dependent patient spending the majority of time in bed or chair
                                    with moisture lesions in the sacral area
                                </div>
                            </div>
                            <div className="poc red">
                                <h3 className="mt-10">MANAGEMENT PLAN</h3>
                                <ol>
                                    <li>Complete Wound assessment +</li>
                                    <li>Commence a 2 hourly turn and repositioning regime </li>
                                    <li>Suggested Pressure Redistributing Device(s) :</li>
                                    <li>Complete DATIX WEB (N/A for Moisture Lesions and Category 1)</li>
                                    <li>Consider referral to the dietician</li>
                                    <li>Consider Safeguarding referral</li>
                                    <li>SEND to Skin integrity Dashboard+</li>
                                </ol>
                                <div className="form-group">
                                    <label>Comments </label>
                                    <textarea className="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                                </div>
                            </div>
                        </div>
                        <div className="section ctr pua">
                            <div className="poc orange">
                                <h3>PRESSURE ULCER RISK</h3>
                                <p className="ps1">No pressure ulcer but at risk (due to following risk factors):</p>
                                <div className="check-box">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-48" />
                                        <label for="checkbox-48"></label>
                                    </div>
                                    Had a previous pressure ulcer or scarring over previous ulcer site
                                </div>
                                <div className="check-box">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-36" />
                                        <label for="checkbox-36"></label>
                                    </div>
                                    Dependant patient spending majority of time in bed/chair
                                </div>
                                <div className="check-box color">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-37" />
                                        <label for="checkbox-37"></label>
                                    </div>
                                    Needs another person to reposition/mobilise
                                </div>
                                <div className="check-box color">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-38" />
                                        <label for="checkbox-38"></label>
                                    </div>
                                    Patient unable to feel/and or respond to discomfort from pressure
                                </div>
                                <div className="check-box color">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-39" />
                                        <label for="checkbox-39"></label>
                                    </div>
                                    Vulnerable skin e.g. blanchable redness that persists
                                </div>
                                <div className="check-box">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-40" />
                                        <label for="checkbox-40"></label>
                                    </div>
                                    Moisture due to perspiration, urine, faeces or exudate more than 2 episodes per day
                                    and the Patient is bed bound
                                </div>
                                <div className="check-box">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-41" />
                                        <label for="checkbox-41"></label>
                                    </div>
                                    Conditions affecting peripheral circulation e.g. peripheral vascular/ arterial
                                    disease
                                </div>
                                <div className="check-box">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-42" />
                                        <label for="checkbox-42"></label>
                                    </div>
                                    Conditions affecting central circulation e.g. shock / heart failure / hypotension/
                                    oedematous skin
                                </div>
                                <div className="check-box">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-43" />
                                        <label for="checkbox-43"></label>
                                    </div>
                                    Diabetic patient with redness / pallor / potential tissue damage on the lower limb
                                </div>
                                <div className="check-box">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-44" />
                                        <label for="checkbox-44"></label>
                                    </div>
                                    Clinical impression - thin, obvious wasting and obesity
                                </div>
                                <div className="check-box">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-45" />
                                        <label for="checkbox-45"></label>
                                    </div>
                                    History of decreased food intake, reduced appetite or dysphagia over 3 - 6 months
                                </div>
                                <div className="check-box">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-46" />
                                        <label for="checkbox-46"></label>
                                    </div>
                                    Underlying disease / psychosocial / physical disabilities likely to cause weight
                                    loss
                                </div>
                                <div className="check-box">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-47" />
                                        <label for="checkbox-47"></label>
                                    </div>
                                    No nutritional intake or likelihood of no intake for more than 5 days 3 times per
                                    day
                                </div>
                            </div>
                            <div className="poc orange">
                                <h3>MANAGEMENT PLAN</h3>
                                <ol>
                                    <li>Complete Wound assessment +</li>
                                    <li>Commence a 4 hourly turn and repositioning regime. ln the absence of pressure
                                        relieving equipment commence a 2 hourly turn and reposition regime until
                                        equipment becomes available</li>
                                    <li>Suggested Pressure Redistributing Device(s) :</li>
                                    <li>Refer diabetic patient with redness/pallor/potential tissue damage on the lower
                                        limb to the inpatient </li>
                                    <li>Diabetic Specialist Nurse Team</li>
                                    <li>Consider referral to the dietitian</li>
                                    <li>Consider Safeguarding referral</li>
                                    <li>SEND to Skin integrity Dashboard+ </li>
                                </ol>
                                <div className="form-group">
                                    <label>Comments </label>
                                    <textarea className="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                                </div>
                                <div className="form-group date">
                                    <label>Example label</label>
                                    <input type="date" className="form-control" placeholder="Example input" />
                                </div>
                            </div>
                        </div>
                        <div className="section ctr pua">
                            <h2 className="popup_title"> WOUND MANAGEMENT</h2>
                            <div className="yn_switch mb-15 mt-0 ">
                                <div className="label-container">
                                    <p>Is patient on a pressure reduction or relief surface:</p>
                                </div>
                                <label for="check826" className="switch-container">
                                    <input hidden="" type="checkbox" className="switch_check" id="check826" />
                                    <div className="switch-bg"></div>
                                    <div className="round-box"></div>
                                    <div className="switch-left">
                                        <span>NO</span>
                                    </div>
                                    <div className="switch-right">
                                        <span>YES</span>
                                    </div>
                                </label>
                            </div>
                            <div className="form_group">
                                <label className="label">Yes, type</label>
                                <input type="text" className="form-control" placeholder="Enter" />
                            </div>

                            <div className="yn_switch mb-15 mt-0 ">
                                <div className="label-container">
                                    <p>Known allergies/ Sensitive to wound care products</p>
                                </div>
                                <label for="check827" className="switch-container">
                                    <input hidden="" type="checkbox" className="switch_check" id="check827" />
                                    <div className="switch-bg"></div>
                                    <div className="round-box"></div>
                                    <div className="switch-left">
                                        <span>NO</span>
                                    </div>
                                    <div className="switch-right">
                                        <span>YES</span>
                                    </div>
                                </label>
                            </div>
                            <div className="form_group">
                                <input type="text" className="form-control" placeholder="Enter" />
                            </div>
                            <p className="ps2">Wound care Performed using aseptic technique</p>
                            <div className="check_item mt-10">
                                <label className="check">Cleansed With</label>
                                <div className="select_btns popup_select">
                                    <div className="select_btn"><label><input name="site" type="radio"
                                                value="Site 1" /><span>Normal Saline
                                            </span></label></div>
                                    <div className="select_btn"><label><input name="site" type="radio"
                                                value="Site 2" /><span> Other </span></label></div>
                                </div>
                            </div>
                            <div className="form_group">
                                <label className="label">Other</label>
                                <input type="text" className="form-control" placeholder="Enter" />
                            </div>
                            <div className="form_group">
                                <label className="label">Product Applied</label>
                                <input type="text" className="form-control" placeholder="Enter" />
                            </div>
                            <div className="form_group">
                                <label className="label">Packed with </label>
                                <input type="text" className="form-control" placeholder="Enter" />
                            </div>
                            <p className="sub_title mb-10">Peri-wound care</p>
                            <div className="form_group">
                                <label className="label">Covered with </label>
                                <input type="text" className="form-control" placeholder="Gauze and a bandage" />
                            </div>
                            <div className="yn_switch mb-15 mt-10 ">
                                <div className="label-container">
                                    <p>Secured </p>
                                </div>
                                <label for="check830" className="switch-container">
                                    <input hidden="" type="checkbox" className="switch_check" id="check830" />
                                    <div className="switch-bg"></div>
                                    <div className="round-box"></div>
                                    <div className="switch-left">
                                        <span>NO</span>
                                    </div>
                                    <div className="switch-right">
                                        <span>YES</span>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className="section ctr pua">

                            <div className="check_item mt-0 mb-10">
                                <label className="check">Wound VAC applied</label>
                                <div className="select_btns popup_select">
                                    <div className="select_btn"><label><input name="site" type="radio"
                                                value="Site 1" /><span>Black
                                            </span></label></div>
                                    <div className="select_btn"><label><input name="site" type="radio"
                                                value="Site 2" /><span> White </span></label></div>
                                    <div className="select_btn"><label><input name="site" type="radio"
                                                value="Site 2" /><span> Sliver </span></label></div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="check_item mt-0">
                                        <label className="check">Canister changed</label>
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input name="site" type="radio"
                                                        value="Site 1" /><span>Constant suction
                                                    </span></label></div>
                                            <div className="select_btn"><label><input name="site" type="radio"
                                                        value="Site 2" /><span> Intermittent suction </span></label>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form_group mb-0">
                                        <label className="label">Pressure</label>
                                        <div className="measure tqt">
                                            <input type="text" className="field" placeholder="Pressure " />
                                            <span>mmHg</span>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className="section ctr pua">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form_group">
                                        <label className="label">Approximate drainage in Canister: </label>
                                        <input type="text" className="form-control" placeholder="Enter" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form_group">
                                        <label className="label">Colour</label>
                                        <input type="text" className="form-control" placeholder="Enter" />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form_group">
                                        <label className="label">Frequency of dressing Change:</label>
                                        <input type="text" className="form-control" placeholder="Enter" />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form_group">
                                        <label className="label">Other</label>
                                        <input type="text" className="form-control" placeholder="Enter" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="section ctr pua mb-0">
                            <p className="sub_title mb-10">Frequency of dressing Change:</p>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="search_dropdown_blk mb-0">
                                        <div className="form_group">
                                            <label className="label">Days</label>
                                            <div className="floating-label-group mb-0">
                                                <div className="search_dropdown">
                                                    <select className="form-control">
                                                        <option value="1">1</option>
                                                        <option value="1">2</option>
                                                        <option value="1"> 3</option>
                                                        <option value="1">4</option>
                                                        <option value="1">5</option>
                                                        <option value="1"> 6</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="search_dropdown_blk mb-0">
                                        <div className="form_group">
                                            <label className="label">Times</label>
                                            <div className="floating-label-group mb-0">
                                                <div className="search_dropdown">
                                                    <select className="form-control">
                                                        <option value="1">Once</option>

                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form_group">
                                        <label className="label">Comment</label>
                                        <input type="text" className="form-control" placeholder="Enter" />
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="tbl_block mt-5 mb_5">
                            <h2 className="tbl_hed">
                                <div className="left">ACTIONS TO CONTROL RISK FACTORS </div>
                                <div className="right"><a href="#" className="tbl_btn">YES</a><a href="#" className="tbl_btn">NO</a>
                                </div>
                            </h2>
                        </div>
                        <div className="section mt-0 ctr pua">
                            <div className="poc poc1 pt-0">
                                <div className="check-box">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-101" />
                                        <label for="checkbox-101"></label>
                                    </div>
                                    Patient / Carer has been given the information on Treatment Plan & Impact on
                                    lifestyle
                                </div>
                                <div className="check-box">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-102" />
                                        <label for="checkbox-102"></label>
                                    </div>
                                    Information booklet given to patient.
                                </div>
                            </div>
                            <div className="check_item mt-0 mb-10">
                                <label className="check">Patient Comprehension:</label>
                                <div className="select_btns popup_select">
                                    <div className="select_btn"><label><input name="site" type="radio"
                                                value="Site 1" /><span>Good
                                            </span></label></div>
                                    <div className="select_btn"><label><input name="site" type="radio"
                                                value="Site 2" /><span> Needs reinforcement </span></label></div>
                                    <div className="select_btn"><label><input name="site" type="radio"
                                                value="Site 2" /><span> Unable to comprehend </span></label></div>
                                </div>
                            </div>
                            <div className="check_item mt-0 mb-10">
                                <label className="check">Carer Comprehension:</label>
                                <div className="select_btns popup_select">
                                    <div className="select_btn"><label><input name="site" type="radio"
                                                value="Site 1" /><span>Good
                                            </span></label></div>
                                    <div className="select_btn"><label><input name="site" type="radio"
                                                value="Site 2" /><span> Needs reinforcement</span></label></div>
                                    <div className="select_btn"><label><input name="site" type="radio"
                                                value="Site 2" /><span> Unable to comprehend</span></label></div>
                                </div>
                            </div>
                        </div>
                        <div className="section ctr pua">
                            <h3>NOTES</h3>
                            <div className="poc poc1 pt-0">
                                <div className="check-box">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-110" />
                                        <label for="checkbox-110"></label>
                                    </div>
                                    For suspected grade 3 or 4 pressure ulcers please refer the patient the Tissue Viability Link Nurse/Tissue Viability Team on bleep ….
                                </div>
                                <div className="check-box">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-111" />
                                        <label for="checkbox-111"></label>
                                    </div>
                                    Refer to a dietician having completed the MUST tool
                                </div>
                                <div className="check-box">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-112" />
                                        <label for="checkbox-112"></label>
                                    </div>
                                    Consider if Hb and albumin need to be checked
                                </div>
                                <div className="check-box">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-113" />
                                        <label for="checkbox-113"></label>
                                    </div>
                                    If the ulcer is on the heel an ABPI (DOPPLER assessment) needs to be undertaken prior to any debridement
                                </div>
                            </div>
                       
                        </div>
                        <div className="section ctr pua">
                            <p className="sub_title">Complete an INCIDENT FORM and document the incident number in the
                                patient’s records</p>
                            <div className="row mt-20">
                                <div className="col-md-6">
                                    <div className="yn_switch mb-15 mt-0 ">
                                        <div className="label-container">
                                            <p>DATIX report has been made :</p>
                                        </div>
                                        <label for="check784" className="switch-container">
                                            <input hidden="" type="checkbox" className="switch_check" id="check784" />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>NO</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>YES</span>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <p className="ps3">DATIX No: <span>112255689</span> </p>
                                </div>
                            </div>
                            <p className="sub_title">Hospital acquired pressure ulcers will require a ROOT CAUSE ANALYSIS
                            </p>
                        </div>
                        <div className="section ctr pua">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form_group">
                                        <label className="label">Designation</label>
                                        <input type="text" className="form-control" placeholder="Enter" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form_group">
                                        <label className="label">Print name</label>
                                        <input type="text" className="form-control" placeholder="Enter" />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form_group date">
                                        <label className="label">Date:</label>
                                        <input type="date" className="form-control" placeholder="Example input" />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form_group date">
                                        <label className="label">Time :</label>
                                        <input type="time" className="form-control" placeholder="Example input" />
                                    </div>
                                </div>
                                <div className="col-md-6 align_right">
                                    <div className="form_group">
                                        <label className="label">&nbsp;</label>
                                        <button type="button" className="update_btn">UPDATE</button>
                                    </div>

                                </div>
                            </div>

                        </div>
                        <div className="section ctr pua">
                            <h2 className="popup_title">DIABETIC PATIENTS</h2>
                            <p className="ps4"> Assessment  <span> (remove footwear and dressings and complete) :</span> </p>
                            <div className="poc pt-0 pb-0">
                                <div className="check-box">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-450" />
                                        <label for="checkbox-450"></label>
                                    </div>
                                    There is an ulcer
                                </div>
                                <div className="check-box">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-451" />
                                        <label for="checkbox-451"></label>
                                    </div>
                                    There is inflammation or swelling or sign of infection
                                </div>
                                <div className="check-box">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-452" />
                                        <label for="checkbox-452"></label>
                                    </div>
                                    There is unexplained pain in the foot
                                </div>
                                <div className="check-box">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-453" />
                                        <label for="checkbox-453"></label>
                                    </div>
                                    There is unexplained fracture dislocation?
                                </div>
                                <div className="check-box">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-454" />
                                        <label for="checkbox-454"></label>
                                    </div>
                                    There is cyanotic discoloration or gangrene?
                                </div>
                            </div>
                            <div className="yn_switch mb-15 mt-0 ">
                                <div className="label-container">
                                    <p>If yes to any of the above notify the diabetes SpR (bleep ) or nurse advisor
                                        (bleep )</p>
                                </div>
                                <label for="check810" className="switch-container">
                                    <input hidden="" type="checkbox" className="switch_check" id="check810" />
                                    <div className="switch-bg"></div>
                                    <div className="round-box"></div>
                                    <div className="switch-left">
                                        <span>NO</span>
                                    </div>
                                    <div className="switch-right">
                                        <span>YES</span>
                                    </div>
                                </label>
                            </div>
                            <div className="form-group mb-20">
                                <textarea className="form-control" rows="3"></textarea>
                            </div>
                            <p className="sub_title">Refer for URGENT SURGICAL REVIEW and notify diabetes team if:</p>
                            <ul>
                                <li>Gas in the soft tissues</li>
                                <li>Abscess or rapidly progressive soft tissue infection</li>
                                <li>Wet gangrene</li>
                                <li>Systemically unwell from foot sepsis (fever, shock</li>
                                <li>Critical ischemia (rest pain, tachycardia, rigors, prostration)</li>
                            </ul>
                        </div>


                                </div>

                            </div>
                            <div className="popup_footer">
                                <div className="prescribe_footer">
                                    <div className="dsp-flex">
                                        <a className="icon_btn"> <span className="material-icons">save</span> SAVE</a>
                                        <button className="border_btn"><span className="material-icons">save_as</span>SAVE &amp;
                                            CLOSE
                                        </button>
                                    </div>
                                    <button className="btn dsp_flex"><span className="material-icons"><span
                                        className="material-icons">close</span></span>DISCARD </button>
                                </div>
                            </div>
                        </div>
                    </div>





                </div>
            </div>




        </React.Fragment>
    )
}

SkinAssesment.propTypes = {
    t: PropTypes.any,
    getWards: PropTypes.func,
    getTheatres: PropTypes.func,
    wards: PropTypes.array,
    user: PropTypes.any,
    theatres: PropTypes.array,
    clinic: PropTypes.array,
    patients: PropTypes.array,
    doctors: PropTypes.array,
    profile: PropTypes.object
}

const mapStateToProps = ({ Layout, Login }) => ({
    wards: Layout.wards,
    user: Login.user,
    theatres: Layout.theatres,
    clinic: Layout.clinic,
    patients: Layout.patients,
    doctors: Layout.doctors,
    profile: Layout.profile
})

const mapDispatchToProps = dispatch => ({
    getWards: () => dispatch(getWardsList()),
    getTheatres: () => dispatch(getTheatresList()),
    getClinic: () => dispatch(getClinicList()),
    getPatient: () => dispatch(getPatientList()),
    getUserprofile: (id) => dispatch(getUprofile(id)),
    getDoctor: () => dispatch(getDoctorList()),
    patientupdate: (id, data) => dispatch(updatePatient(id, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(SkinAssesment)
