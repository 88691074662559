import React, { useState, useRef } from "react"
import { Link, useHistory  } from "react-router-dom"
import pi from '../../assets/images/personal_injury.svg'; //personal_injury
import chronic from '../../assets/images/chronic.svg'; //chronic
import amp from '../../assets/images/amp_stories.svg'; // amp_stories

const WardMenus = (props) => {
    const ref = useRef()
    const history = useHistory();
    const [showSidemenu, setshowSidemenu] = useState(false)
    const menuClick = (e) => {
        setshowSidemenu(!showSidemenu)
    }

    const redirectLink = (e) => {
        e.preventDefault()
        // console.log("rot", route)
        // props.history.push("/" + route)
        history.goBack();
    }
    return (
        <div className="menu_block">
            <div className="left">
                <div className="side_menu">
                    <a className="menu" data-bs-toggle="offcanvas" href="#offcanvasExample" role="button"
                        aria-controls="offcanvasExample">
                        <span className="material-icons"> menu </span>
                    </a>
                    <div className="offcanvas offcanvas-start" id="offcanvasExample"
                        aria-labelledby="offcanvasExampleLabel">
                        <div className="offcanvas-body">
                            <ul className="nav nav-pills flex-column">
                                <li className="nav-item first_level"><a className="nav-link" href="#"> <span
                                    className="icon"> <img src={pi} alt="" />
                                </span> PATIENT RECORD </a>

                                </li>
                                <Link to="/lifetimerecord">
                                <li className="nav-item first_level"><a className="nav-link"><span
                                    className="icon"> <img src={chronic} alt="" />
                                </span>LIFETIME RECORD</a></li>
                                </Link>
                                
                                <li className="nav-item first_level">
                                    <a className="nav-link collapsed" href="#submenu1" data-toggle="collapse"
                                        data-target="#submenu1"><span className="icon"> <img
                                        src={amp} alt="" /> </span>CURRENT EPISODE
                                        <span className="material-icons menu_arrow"> navigate_next </span></a>
                                    <div className="collapse" id="submenu1" aria-expanded="false">
                                        <ul className="flex-column pl-2 nav">
                                        <Link to="/wlist"><li className="nav-item secend_level"><a className="nav-link" >
                                                <span className="dot"></span> List of Patients </a>
                                            </li></Link>
                                            <li className="nav-item secend_level">
                                                <a className="nav-link collapsed" href="#subsubmenu1"
                                                    data-toggle="collapse" data-target="#subsubmenu1"> <span
                                                        className="dot"></span> Ward
                                                    Notes <span className="material-icons menu_arrow">
                                                        arrow_right </span></a>
                                                <div className="collapse" id="subsubmenu1"
                                                    aria-expanded="false">
                                                    <ul className="flex-column pl-2 nav">
                                                        <li className="nav-item third_level"> <a
                                                            className="nav-link collapsed"
                                                            href="#subsubsubmenu1"
                                                            data-toggle="collapse"
                                                            data-target="#subsubsubmenu1"> <span
                                                                className="dot"></span> Front Sheet <span
                                                                    className="material-icons menu_arrow">
                                                                arrow_right </span></a>
                                                            <div className="collapse" id="subsubsubmenu1"
                                                                aria-expanded="false">
                                                                <ul className="flex-column pl-2 nav">
                                                                <Link to="/front">
                                                                    <li className="nav-item fourth_level"><a
                                                                        className="nav-link" >
                                                                        patient ID & details</a>
                                                                    </li></Link>
                                                                    <Link to="/demo">
                                                                    <li className="nav-item fourth_level"><a
                                                                        className="nav-link" >
                                                                        Demographics</a>
                                                                    </li></Link>
                                                                    <Link to="/legal">
                                                                    <li className="nav-item fourth_level"><a
                                                                        className="nav-link" >
                                                                        Legal</a>
                                                                    </li></Link>

                                                                </ul>
                                                            </div>
                                                        </li>
                                                        <li className="nav-item third_level"> <a
                                                            className="nav-link collapsed"
                                                            href="#subsubsubmenu2"
                                                            data-toggle="collapse"
                                                            data-target="#subsubsubmenu2"> <span
                                                                className="dot"></span>Admission <span
                                                                    className="material-icons menu_arrow">
                                                                arrow_right </span></a>
                                                            <div className="collapse" id="subsubsubmenu2"
                                                                aria-expanded="false">
                                                                <ul className="flex-column pl-2 nav">
                                                                    <li className="nav-item fourth_level"><a
                                                                        className="nav-link" href="#">
                                                                        Medical Admission</a>
                                                                    </li>

                                                                    <Link to="/nursingadmission">
                                                                    <li className="nav-item fourth_level"><a
                                                                        className="nav-link" >
                                                                        Nursing Admission</a>
                                                                    </li></Link>
                                                                    <Link to="/riskassesment">
                                                                    <li className="nav-item fourth_level"><a
                                                                        className="nav-link" >
                                                                        Risk Assesment</a>
                                                                    </li></Link>
                                                                    <li className="nav-item fourth_level"><a
                                                                        className="nav-link" href="#">
                                                                        Allied health Initial
                                                                        Assessment</a>
                                                                    </li>

                                                                </ul>
                                                            </div>
                                                        </li>
                                                        <li className="nav-item third_level"> <a
                                                            className="nav-link collapsed"
                                                            href="#subsubsubmenu3"
                                                            data-toggle="collapse"
                                                            data-target="#subsubsubmenu3"> <span
                                                                className="dot"></span>Hospital
                                                            Stay <span
                                                                className="material-icons menu_arrow">
                                                                arrow_right </span></a>
                                                            <div className="collapse" id="subsubsubmenu3"
                                                                aria-expanded="false">
                                                                <ul className="flex-column pl-2 nav">
                                                                <Link to="/weekendhandover">
                                                                    <li className="nav-item fourth_level"><a
                                                                        className="nav-link">
                                                                        Daily Summary & Handover</a>
                                                                    </li></Link>
                                                                    <li className="nav-item fourth_level"><a
                                                                        className="nav-link" href="#">
                                                                        Medical progress Notes</a>
                                                                    </li>
                                                                    <li className="nav-item fourth_level"><a
                                                                        className="nav-link" href="#">
                                                                        Nursing progress Notes</a>
                                                                    </li>
                                                                    <Link to="/allied">
                                                                    <li className="nav-item fourth_level"><a
                                                                        className="nav-link" href="#">
                                                                        Allied Health + Visiting
                                                                        Notes</a>
                                                                    </li></Link>
                                                                    <li className="nav-item fourth_level"><a
                                                                        className="nav-link" href="#">
                                                                        End of Life Care & Death</a>
                                                                    </li>

                                                                </ul>
                                                            </div>
                                                        </li>
                                                        <li className="nav-item third_level"> <a
                                                            className="nav-link collapsed"
                                                            href="#subsubsubmenu4"
                                                            data-toggle="collapse"
                                                            data-target="#subsubsubmenu4"> <span
                                                                className="dot"></span>Discharge <span
                                                                    className="material-icons menu_arrow">
                                                                arrow_right </span></a>
                                                            <div className="collapse" id="subsubsubmenu4"
                                                                aria-expanded="false">
                                                                <ul className="flex-column pl-2 nav">
                                                                <Link to="/discharge">
                                                                    <li className="nav-item fourth_level"><a
                                                                        className="nav-link" >
                                                                        Discharge Summary</a>
                                                                    </li></Link>
                                                                    <Link to="/fluid">
                                                                    <li className="nav-item fourth_level"><a
                                                                        className="nav-link" >
                                                                        Patient’s Episode/Discahrge
                                                                        report</a>
                                                                    </li></Link>

                                                                </ul>
                                                            </div>
                                                        </li>
                                                        <li className="nav-item third_level"> <a
                                                            className="nav-link collapsed"
                                                            href="#subsubsubmenu5"
                                                            data-toggle="collapse"
                                                            data-target="#subsubsubmenu5"> <span
                                                                className="dot"></span>Reports <span
                                                                    className="material-icons menu_arrow">
                                                                arrow_right </span></a>
                                                            <div className="collapse" id="subsubsubmenu5"
                                                                aria-expanded="false">
                                                                <ul className="flex-column pl-2 nav">
                                                                    <li className="nav-item fourth_level"><a
                                                                        className="nav-link" href="#">
                                                                        Patient’s Admin Report</a>
                                                                    </li>
                                                                    <li className="nav-item fourth_level"><a
                                                                        className="nav-link" href="#">
                                                                        Patient’s Quality Report</a>
                                                                    </li>
                                                                    <li className="nav-item fourth_level"><a
                                                                        className="nav-link" href="#">
                                                                        Patient’s Coding & Finance
                                                                        Report</a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </li>

                                                    </ul>
                                                </div>
                                            </li>
                                            <li className="nav-item secend_level"><a className="nav-link" href="#">
                                                <span className="dot"></span> THEATRE Notes </a>
                                            </li>
                                            <li className="nav-item secend_level"><a className="nav-link" href="#">
                                                <span className="dot"></span> CLINIC Notes </a>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li className="nav-item first_level">
                                    <a className="nav-link collapsed" href="#submenu2" data-toggle="collapse"
                                        data-target="#submenu2"><span className="icon"> <img
                                        src={amp} alt="" /> </span>MEDICAL RECORDS
                                        ROOM
                                        <span className="material-icons menu_arrow"> navigate_next </span></a>
                                    <div className="collapse" id="submenu2" aria-expanded="false">
                                        <ul className="flex-column pl-2 nav">
                                            <li className="nav-item secend_level"><a className="nav-link" href="#">
                                                <span className="dot"></span> MAR </a>
                                            </li>
                                            <li className="nav-item secend_level"><a className="nav-link" href="#">
                                                <span className="dot"></span>Nursing Assessments </a>
                                            </li>
                                            <li className="nav-item secend_level"><a className="nav-link" href="#">
                                                <span className="dot"></span> Nursing AUDITS </a>
                                            </li>
                                            <li className="nav-item secend_level"><a className="nav-link" href="#">
                                                <span className="dot"></span>Nursing PROCEDURES</a>
                                            </li>
                                            <li className="nav-item secend_level"><a className="nav-link" href="#">
                                                <span className="dot"></span> Nursing Assistant’s entries
                                            </a>
                                            </li>
                                            <li className="nav-item secend_level"><a className="nav-link" href="#">
                                                <span className="dot"></span> pharmacist template </a>
                                            </li>
                                            <li className="nav-item secend_level"><a className="nav-link" href="#">
                                                <span className="dot"></span> Phlebotomist template </a>
                                            </li>
                                            <li className="nav-item secend_level"><a className="nav-link" href="#">
                                                <span className="dot"></span>physiotherapy template </a>
                                            </li>
                                            <li className="nav-item secend_level"><a className="nav-link" href="#">
                                                <span className="dot"></span> OT template </a>
                                            </li>
                                            <li className="nav-item secend_level"><a className="nav-link" href="#">
                                                <span className="dot"></span> SOCIAL WORKER template </a>
                                            </li>
                                            <li className="nav-item secend_level"><a className="nav-link" href="#">
                                                <span className="dot"></span> discharge coordinator temp
                                            </a>
                                            </li>
                                            <li className="nav-item secend_level"><a className="nav-link" href="#">
                                                <span className="dot"></span>skin integrity template</a>
                                            </li>
                                        </ul>
                                    </div>
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>
                <a onClick={(e) =>redirectLink(e)} className="btn">BACK</a>
                <Link to='pinfo' className="btn">PATIENT INFO</Link>
                <a href="#" className="btn">HAND OVER</a>
            </div>
            <div className="right">
                <div className="btn-group">
                    <button type="button" className="btn btn-primary">JACS</button>
                    <button type="button" className="btn btn-primary">ICE</button>
                    <button type="button" className="btn btn-primary">PACS</button>
                </div>
            </div>
        </div>
    )
}

export default WardMenus