import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import MetaTags from 'react-meta-tags';
import SignaturePad from 'react-signature-canvas'
import PatientBlock from "../Clinic/patientBlock";
import { removeSpecialCharacters } from '../../utils/utils';
import { Buffer } from 'buffer';
import { connect } from "react-redux"
import { getWardsList, getTheatresList, getClinicList, getPatientList, getUprofile, getDoctorList, updatePatient } from "../../store/actions"

const DemoGraphicsData = props => {


    const [clinicValues, setClinic] = useState({})


    const {
        patients,
    } = props;

    useEffect(() => {
        const { getWards, getTheatres, getClinic, getDoctor, getPatient, getUserprofile } = props
        // getWards()
        // getTheatres()
        // getClinic()
        getPatient()
        getDoctor()
        let userId = localStorage.getItem("authUser")
        getUserprofile(userId)
    }, [])


    useEffect(() => {
        let clinicPatient = JSON.parse(localStorage.getItem("p"))
        if (patients && patients.length > 0 && clinicPatient && clinicPatient._id) {
            const cPatient = patients.filter(
                usr => usr._id === clinicPatient._id
            )
            if (cPatient && cPatient[0]) {
                let cpatientData = cPatient[0]
                delete cpatientData["_id"]
                setClinic(clinicValues => ({
                    ...clinicValues,
                    ...cpatientData
                }));
            }

        }
    }, [patients])
    
    const wardBack = (e) => {
        e.preventDefault()
        props.history.push("/wl")
        document.body.style.overflow = '';
    }


    return (
        <React.Fragment>

            <MetaTags>
                <title>DemoGraphics Data</title>
            </MetaTags>
            <div className='ward '>
                <div className="popup_wrp">
                    <div className="popup_full prescribe">
                        <div className="popup_hed">
                            <h2>DemoGraphics Data </h2>
                            <div className="dsp-flex"> <a className="popup_close" onClick={(e) => { wardBack(e) }}><span className="material-icons">close</span></a></div>

                        </div>
                        <div className="popup_content">
                        <div className='popup_scroll main w-btns'>
                            <div className="section simple_wound">
                                <div className="pr_data">
                                    <div className="form_group">
                                        <div className="field_text">Have you attended this Hospital as an in-patient or
                                            outpatient before?</div>
                                        <div className="result">{clinicValues.demo_inpatient_outpatient}</div>
                                    </div>
                                    <div className="form_group">
                                        <div className="field_text">Under what name?</div>
                                        <div className="result">{clinicValues.demo_whatname}</div>
                                    </div>
                                    <div className="form_group">
                                        <div className="field_text">Have you filled this questionnaire before within the
                                            last 12 months or longer?</div>
                                        <div className="result">{clinicValues.demo_questionnaire}</div>
                                    </div>
                                    <div className="form_group">
                                        <div className="field_text">Any changes to your health since your last treatment
                                            E.g. Changes to medications, admissions, new diagnoses?</div>
                                        <div className="result">{clinicValues.demo_change_medication}</div>
                                    </div>

                                </div>
                            </div>
                            <div className="section ctr">
                                <h2 className="popup_title"> Last updated</h2>
                                <div className="pr_data">
                                    <div className="form_group">
                                        <div className="field_text">Information obtained from</div>
                                        <div className="result">{clinicValues.demo_information_obtained} </div>
                                    </div>
                                    <div className="form_group">
                                        <div className="field_text">Unable to answer screening questions within 48 hours
                                        </div>
                                        <div className="result">{clinicValues.demo_screening_questions}</div>
                                    </div>
                                    <div className="form_group">
                                        <div className="field_text">Reason</div>
                                        <div className="result">{clinicValues.demo_reason}</div>
                                    </div>
                                    <div className="form_group">
                                        <div className="field_text">UNABLE TO OBTAIN HISTORY ON ADMISSION </div>
                                        <div className="result">{clinicValues.demo_obtain_history}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="section ctr">
                                <h2 className="popup_title">COMMUNICATION</h2>
                                <div className="pr_data">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="field_text">Primary language</div>
                                                <div className="result">{clinicValues.demo_communication}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="field_text">Language Interpreter required
                                                </div>
                                                <div className="result">{clinicValues.demo_language_intrepreter_required}</div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="field_text">Language interpreter arranged </div>
                                                <div className="result">{clinicValues.demo_language_intrepreter_arranged}</div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="field_text">Hearing/speech
                                                    difficulty</div>
                                                <div className="result">{clinicValues.demo_hearing_difficulty}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="field_text">Hearing Aids</div>
                                                <div className="result">{clinicValues.demo_hearing_aids} </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="field_text">Deaf interpreter required? </div>
                                                <div className="result">{clinicValues.demo_deaf_intrepreter_required} </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="field_text">BSL interpreter arranged? </div>
                                                <div className="result">{clinicValues.demo_bsl_intrepreter_arranged} </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="field_text">Vision Difficulty</div>
                                                <div className="result">{clinicValues.demo_vision_difficulty} </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="field_text">Dentures </div>
                                                <div className="result">{clinicValues.demo_dentures} </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="field_text">Upper</div>
                                                <div className="result">{clinicValues.demo_upper} </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="field_text">Lower</div>
                                                <div className="result">{clinicValues.demo_lower} </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="field_text">Reading & Writing</div>
                                                <div className="result">{clinicValues.demo_reading_writing} </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                            <div className="section ctr">
                                <h2 className="popup_title">LIFESTYLE &amp; SOCIAL CIRCUMSTANCES</h2>
                                <p className="sub_title">Occupation <span> {clinicValues.demo_occupation}</span> </p>
                                <div className="pr_data">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="field_text">Accommodation </div>
                                                <div className="result">{clinicValues.demo_accomdation} </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="field_text">Home care
                                                </div>
                                                <div className="result">{clinicValues.demo_homecare}</div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="field_text">How often? </div>
                                                <div className="result">{clinicValues.demo_howoften} </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="field_text">Name of provider? </div>
                                                <div className="result">{clinicValues.demo_provider_name}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="field_text">Formal Care Local Authority Warden
                                                    Control </div>
                                                <div className="result">{clinicValues.demo_inpatient_outpatient}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="field_text">Heating </div>
                                                <div className="result">{clinicValues.demo_heating} </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="field_text">Domestic ADL </div>
                                                <div className="result">{clinicValues.demo_domestic_adl} </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="field_text">Drink </div>
                                                <div className="result">{clinicValues.demo_drink} </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="field_text">Snack </div>
                                                <div className="result">{clinicValues.demo_snack} </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="field_text">Shopping </div>
                                                <div className="result">{clinicValues.demo_shopping} </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="field_text">Cooking </div>
                                                <div className="result">{clinicValues.demo_cooking} </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="field_text">Laundry </div>
                                                <div className="result">{clinicValues.demo_laundry} </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="field_text">Cleaning </div>
                                                <div className="result">{clinicValues.demo_cleaning} </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <p className="sub_title">Lives with</p>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="field_text">Lives alone </div>
                                                <div className="result">{clinicValues.demo_livealone}</div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="field_text">Marital Status </div>
                                                <div className="result">{clinicValues.demo_marital_status}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <p className="sub_title">Environment</p>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="field_text">Pendant alarm </div>
                                                <div className="result">{clinicValues.demo_pendant_alarm} </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="field_text">Pets </div>
                                                <div className="result">{clinicValues.demo_pets} </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="field_text">Location of key </div>
                                                <div className="result">{clinicValues.demo_location_of_key} </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="field_text">Sleeps </div>
                                                <div className="result">{clinicValues.demo_sleeps} </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="field_text">Toilet </div>
                                                <div className="result">{clinicValues.demo_toilet} </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="field_text">Adaptations </div>
                                                <div className="result">{clinicValues.demo_adaptationns} </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <p className="sub_title">Transport</p>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="field_text">To hospital </div>
                                                <div className="result">{clinicValues.demo_transport_to_hospital} </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="field_text">On discharge </div>
                                                <div className="result">{clinicValues.demo_on_discharge} </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="field_text">Assessment of transport needs </div>
                                                <div className="result">{clinicValues.demo_assesment} </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="field_text">Previous mobility </div>
                                                <div className="result">{clinicValues.demo_mobility}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <p className="sub_title">Exercise tolerance. distance, stairs, activity, etc</p>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="field_text">Do you drive </div>
                                                <div className="result">{clinicValues.demo_mobility_type}</div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="field_text">Would you like a visit from </div>
                                                <div className="result">{clinicValues.demo_visit_form}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="field_text">Referral made to Spiritual Care (where
                                                    available) </div>
                                                <div className="result">{clinicValues.demo_spiritual} </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="field_text">Hospital chaplain (where available) </div>
                                                <div className="result">{clinicValues.demo_hos_chaplain} </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="field_text">What cultural practices, religious customs
                                                </div>
                                                <div className="result">{clinicValues.demo_cultural}</div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="field_text">rites, or health beliefs are important to
                                                    consider in your care? </div>
                                                <div className="result">{clinicValues.demo_rites} </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="field_text">Support offered </div>
                                                <div className="result">{clinicValues.demo_support_offered} </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                            <div className="section ctr">
                                <h2 className="popup_title">SERVICES /SUPPORT</h2>
                                <div className="row pr_data">
                                    <div className="col-md-6">
                                        <div className="form_group">
                                            <div className="field_text">Service </div>
                                            <div className="result">{clinicValues.demo_service} </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form_group">
                                            <div className="field_text">Community Matron </div>
                                            <div className="result">{clinicValues.demo_community_matron}</div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form_group">
                                            <div className="field_text">Home Care </div>
                                            <div className="result">{clinicValues.demo_home_care} </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form_group">
                                            <div className="field_text">Social Worker </div>
                                            <div className="result">{clinicValues.demo_social_worker}</div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form_group">
                                            <div className="field_text">District Nurse </div>
                                            <div className="result">{clinicValues.demo_district_nurse}</div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form_group">
                                            <div className="field_text">Wound Care </div>
                                            <div className="result">{clinicValues.demo_wound_care}</div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form_group">
                                            <div className="field_text">Health Visitor </div>
                                            <div className="result">{clinicValues.demo_health_visitor} </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form_group">
                                            <div className="field_text">Dietitian </div>
                                            <div className="result">{clinicValues.demo_dietitian}</div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form_group">
                                            <div className="field_text">Physiotherapy </div>
                                            <div className="result">{clinicValues.demo_physiotherapy} </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form_group">
                                            <div className="field_text">OT </div>
                                            <div className="result">{clinicValues.demo_ot}</div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form_group">
                                            <div className="field_text">Speech Therapy </div>
                                            <div className="result">{clinicValues.demo_speech_therapy} </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form_group">
                                            <div className="field_text">Day Care </div>
                                            <div className="result">{clinicValues.demo_day_care} </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form_group">
                                            <div className="field_text">CPN </div>
                                            <div className="result">{clinicValues.demo_cpn} </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form_group">
                                            <div className="field_text">Garage </div>
                                            <div className="result">{clinicValues.demo_garage} </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form_group">
                                            <div className="field_text">DANS </div>
                                            <div className="result">{clinicValues.demo_dans} </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form_group">
                                            <div className="field_text">Older People Mental Health Team </div>
                                            <div className="result">{clinicValues.demo_older} </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form_group">
                                            <div className="field_text">Others </div>
                                            <div className="result">{clinicValues.demo_service_others} </div>
                                        </div>
                                    </div>

                                </div>
                                <p className="note mb-0">Any appointment for treatment, investigation or social assessment
                                    is
                                    rearranged</p>
                            </div>
                            <div className="section ctr">
                                <h2 className="popup_title">STRICTLY CONFIDENTIAL</h2>
                                <div className="row pr_data">
                                    <div className="col-md-12">
                                        <p className="ps1">The National Health Service needs to know the ethnic group of
                                            patients for
                                            the purpose of planning. This is to make sure that all sectors of the
                                            community have
                                            equal access to the services provided.</p>
                                        <p className="ps1">Ethnic group describes how you see yourself and is a mixture of
                                            culture,
                                            religion, skin colour, language and the origins of yourself or your family.
                                            It is not
                                            the same as nationality.</p>
                                        <p className="ps1">The information will be treated in the strictest confidence.</p>
                                        <p className="ps1">The information is used only by National Health Service staff and
                                            will not be
                                            passed on to other agencies or used for any other purposes.</p>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form_group">
                                            <div className="field_text">Please indicate the ethnic group to which you feel
                                                you belong:</div>
                                            <div className="result">{clinicValues.demo_ethic_group} </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="section ctr">
                                <h2 className="popup_title">PERSONAL</h2>
                                <div className="row pr_data">
                                    <div className="col-md-6">
                                        <div className="form_group">
                                            <div className="field_text">Smoking </div>
                                            <div className="result">{clinicValues.demo_smoking}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form_group">
                                            <div className="field_text"> per day  </div>
                                            <div className="result">{clinicValues.demo_smoke_per_day} </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form_group">
                                            <div className="field_text"> for Years </div>
                                            <div className="result">{clinicValues.demo_smoke_years} </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form_group">
                                            <div className="field_text">Offered smoking cessation referral  </div>
                                            <div className="result">{clinicValues.demo_offered_smoking}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form_group">
                                            <div className="field_text">NRT offered  </div>
                                            <div className="result">{clinicValues.demo_offered_nrt}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form_group">
                                            <div className="field_text">Alcohol </div>
                                            <div className="result">{clinicValues.demo_alcohol}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form_group">
                                            <div className="field_text">Likely to develop withdrawl? </div>
                                            <div className="result">{clinicValues.demo_develop_withdrwal_alcohol} </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form_group">
                                            <div className="field_text">Wernicke’s Healthy Lifestyle
                                                advice given </div>
                                            <div className="result">{clinicValues.demo_wernicke}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form_group">
                                            <div className="field_text">Recreational drug
                                                use </div>
                                            <div className="result">{clinicValues.demo_recreational}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form_group">
                                            <div className="field_text">Likely to develop with drawl?  </div>
                                            <div className="result">{clinicValues.demo_develop_withdrwal_drug}</div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form_group">
                                            <div className="field_text">ALLERGIES & REACTIONS </div>
                                            <div className="result">{clinicValues.demo_allergies}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form_group">
                                            <div className="field_text">Others  </div>
                                            <div className="result">{clinicValues.demo_allery_others}  </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form_group">
                                            <div className="field_text">Infections </div>
                                            <div className="result"> {clinicValues.demo_infections} </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form_group">
                                            <div className="field_text">Any other infective disease  </div>
                                            <div className="result">{clinicValues.demo_infective_disease} </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form_group">
                                            <div className="field_text">HEIGHT </div>
                                            <div className="result">{clinicValues.demo_height} </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form_group">
                                            <div className="field_text">WEIGHT </div>
                                            <div className="result">{clinicValues.demo_weight} </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form_group">
                                            <div className="field_text">BMI </div>
                                            <div className="result">{clinicValues.demo_bmi}</div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form_group">
                                            <div className="field_text">ASA </div>
                                            <div className="result">{clinicValues.demo_asa} </div>
                                        </div>
                                    </div>
                                  

                                </div>
                          
                            </div>
                        </div>
                            <div className="popup_footer">
                                <div className="prescribe_footer">
                                    <div className="dsp-flex">
                                       
                                        <button className="border_btn" onClick={(e) => { wardBack(e) }}><span className="material-icons">save_as</span>EDIT &amp;
                                            CLOSE
                                        </button>
                                    </div>

                                    <button onClick={(e) => { wardBack(e) }} className="btn dsp_flex"><span className="material-icons"><span className="material-icons">close</span></span>DISCARD </button>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>


        </React.Fragment>
    )
}

DemoGraphicsData.propTypes = {
    t: PropTypes.any,
    getWards: PropTypes.func,
    getTheatres: PropTypes.func,
    wards: PropTypes.array,
    user: PropTypes.any,
    theatres: PropTypes.array,
    clinic: PropTypes.array,
    patients: PropTypes.array,
    doctors: PropTypes.array,
    profile: PropTypes.object
}

const mapStateToProps = ({ Layout, Login }) => ({
    wards: Layout.wards,
    user: Login.user,
    theatres: Layout.theatres,
    clinic: Layout.clinic,
    patients: Layout.patients,
    doctors: Layout.doctors,
    profile: Layout.profile
})

const mapDispatchToProps = dispatch => ({
    getWards: () => dispatch(getWardsList()),
    getTheatres: () => dispatch(getTheatresList()),
    getClinic: () => dispatch(getClinicList()),
    getPatient: () => dispatch(getPatientList()),
    getUserprofile: (id) => dispatch(getUprofile(id)),
    getDoctor: () => dispatch(getDoctorList()),
    patientupdate: (id, data) => dispatch(updatePatient(id, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(DemoGraphicsData)
