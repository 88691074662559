import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import MetaTags from 'react-meta-tags';
import { Link } from "react-router-dom"
import {
    Modal
} from "reactstrap"
import WardMenus from "./wardMenus";
import { connect } from "react-redux"
import { getWardsList, getTheatresList, getClinicList, getPatientList, getUprofile, getDoctorList, updatePatient, getNews, updateDailyRounds, getDailyRounds } from "../../store/actions"

const MyContacts = props => {
    const [discard_popup, setdiscard_popup] = useState(false)
    const [clinicValues, setClinic] = useState({})
    const {
        wards, user, theatres, clinic, patients, profile, doctors
    } = props;

    // useEffect(() => {
    //     const { getWards, getTheatres, getClinic, getDoctor, getPatient, getUserprofile } = props

    //     getPatient()
    //     getDoctor()
    //     let userId = localStorage.getItem("authUser")
    //     getUserprofile(userId)
    // }, [])

    // useEffect(() => {
    //     let clinicPatient = JSON.parse(localStorage.getItem("p"))
    //     if (patients && patients.length > 0 && clinicPatient && clinicPatient._id) {
    //         const cPatient = patients.filter(
    //             usr => usr._id === clinicPatient._id
    //         )
    //         if (cPatient && cPatient[0]) {
    //             let cpatientData = cPatient[0]
    //             delete cpatientData["_id"]
    //             setClinic(clinicValues => ({
    //                 ...clinicValues,
    //                 ...cpatientData
    //             }));
    //         }

    //     }
    // }, [patients])


    return (
        <React.Fragment>

            <MetaTags>
                <title>My Contacts</title>
            </MetaTags>
            <div className='ward '>
                <div className="main mo_list">

                    <div className="no-wrapper">
                        {/* <div className=" out_patient_section out_patient_details mb-0">
                            <WardMenus />
                        </div> */}

                        <div className="tbl_block">
                            <h2 className="tbl_hed">
                                <div className="left">MY CONTACTS</div>
                                <div className="right"><a className="tbl_btn" onClick={() => setdiscard_popup(true)}>Add New</a></div>
                            </h2>

                            <div className="patients_table">
                                <table>
                                    <thead>
                                        <tr>
                                            <th width="30%">Name</th>
                                            <th width="30%">Email</th>
                                            <th width="30%">Phone</th>
                                            <th width="10%">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="long">Jone Cooper </td>
                                            <td className="long">info@gmail.com</td>
                                            <td className="long">+123 8989 444 </td>
                                            <td className="long"><span className="action"> <a href="#" data-toggle="modal"
                                                data-target="#exampleModalLong" className="material-icons">edit</a>
                                                <a href="#" className="material-icons">delete_outline</a> </span></td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>

                        

                        <Modal
                            isOpen={discard_popup}
                            toggle={() => {
                                setdiscard_popup(false)
                            }}
                            centered={true}
                            className='add_form'
                        >
                            <div className="modal-header">
                                <h5 className="modal-title mt-0">Add</h5>
                                <button
                                    type="button"
                                    onClick={() => {
                                        setdiscard_popup(false)
                                    }}
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>


                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form_group">
                                            <label className="label">Name</label>
                                            <input type="text" className="form-control" placeholder="Enter" />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form_group">
                                            <label className="label">E mail</label>
                                            <input type="text" className="form-control" placeholder="Enter" />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form_group">
                                            <label className="label">Phone</label>
                                            <input type="text" className="form-control" placeholder="Enter" />
                                        </div>
                                    </div>
                                    <div className="col-md-12"><button type="button" className="update_btn">Submit</button></div>
                                </div>
                            </div>

                        </Modal>

                        <br /> <br /> <br />
                    </div>


                </div>
            </div>
        </React.Fragment>
    )
}

MyContacts.propTypes = {
    getWards: PropTypes.func,
    getTheatres: PropTypes.func,
    wards: PropTypes.array,
    user: PropTypes.any,
    theatres: PropTypes.array,
    clinic: PropTypes.array,
    patients: PropTypes.array,
    doctors: PropTypes.array,
    profile: PropTypes.object,
    news: PropTypes.array,
}

const mapStateToProps = ({ Layout, Login }) => ({
    wards: Layout.wards,
    user: Login.user,
    theatres: Layout.theatres,
    clinic: Layout.clinic,
    patients: Layout.patients,
    doctors: Layout.doctors,
    profile: Layout.profile,
    news: Layout.news,
    dailyrounds: Layout.dailyrounds
})

const mapDispatchToProps = dispatch => ({
    getWards: () => dispatch(getWardsList()),
    getTheatres: () => dispatch(getTheatresList()),
    getClinic: () => dispatch(getClinicList()),
    getPatient: () => dispatch(getPatientList()),
    getUserprofile: (id) => dispatch(getUprofile(id)),
    getDoctor: () => dispatch(getDoctorList()),
    patientupdate: (id, data) => dispatch(updatePatient(id, data)),
    newsList: (data) => dispatch(getNews(data)),
    dailyRoundsList: (data) => dispatch(getDailyRounds(data)),
    dailyUpdate: (data) => dispatch(updateDailyRounds(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(MyContacts)
