import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import MetaTags from 'react-meta-tags';
import { v4 as uuidv4 } from 'uuid';
import {
    Modal
} from "reactstrap"
import Menus from "./onCallMenus";
import { connect } from "react-redux"
import { getWardsList, getTheatresList, getClinicList, getPatientList, getUprofile, getDoctorList, updatePatient, getNews, updateDailyRounds, getDailyRounds, teamListUpdate, gettableList } from "../../store/actions"

const TeamList = props => {
    document.body.style.overflow = '';
    document.body.style.padding = '';
    let clinicPatient = JSON.parse(localStorage.getItem("p"))
    const [discard_popup, setdiscard_popup] = useState(false)
    const [allied_value, setallied_value] = useState(null)
    const [clinicValues, setClinic] = useState({})
    const [success_msg, setsuccess_msg] = useState(false)
    const [jobValues, setJob] = useState({})
    const [wardValues, setWard] = useState({})
    const [trelloboard, setTrelloboard] = useState([])
    const [members, setMembers] = useState([])

    const {
        wards, user, theatres, clinic, patients, profile, doctors, tablesData
    } = props;

    useEffect(() => {
        const { getWards, getTheatres, getClinic, getDoctor, getPatient, getUserprofile, getTableData  } = props

        getPatient()
        getDoctor()
        let userId = localStorage.getItem("authUser")
        getUserprofile(userId)

        getTableData({
            method: "Get"
        }, "jobs")


    }, [])

    

    useEffect(() => {
        let clinicPatient = JSON.parse(localStorage.getItem("p"))
        if (patients && patients.length > 0 && clinicPatient && clinicPatient._id) {
            const cPatient = patients.filter(
                usr => usr._id === clinicPatient._id
            )
            if (cPatient && cPatient[0]) {
                let cpatientData = cPatient[0]
                delete cpatientData["_id"]
                setClinic(clinicValues => ({
                    ...clinicValues,
                    ...cpatientData
                }));
            }

        }
    }, [patients])

    useEffect(() => {
        
        const grouped = groupBy(tablesData, item => item.type);

        
        let membersData = grouped.get("Member")
        let jobsData =grouped.get("Job")
        if(jobsData && jobsData.length > 0){
            setTrelloboard(jobsData);
        }
        if(membersData && membersData.length > 0){
            setMembers(membersData)
        }

    }, [tablesData])

    const groupBy = (list, keyGetter) => {
        const map = new Map();
        list.forEach((item) => {
             const key = keyGetter(item);
             const collection = map.get(key);
             if (!collection) {
                 map.set(key, [item]);
             } else {
                 collection.push(item);
             }
        });
        return map;
    }


    const updateInputValue = (e) => {
        let val = e.target.value;
        let name = e.target.name;
        let updatedValue = {};
        updatedValue = { [name]: val };
        setJob(jobValues => ({
            ...jobValues,
            ...updatedValue
        }));

    }

    const memberUpdate = (e) => {

        // setsuccess_msg(!success_msg)

        let updatedValue = jobValues;
        updatedValue["type"] = "Member"
        setMembers( // Replace the state
            [ // with a new array
                ...members, // that contains all the old items
                updatedValue // and one new item at the end
            ]
        );
        const { teamupdatelist } = props
        let bodyObj = {
            obj: updatedValue,
            query: {},
            method: "Insert"
        }
        teamupdatelist(bodyObj, "jobs")

        setJob(jobValues, {});
        document.getElementById("memberForm").reset();
    }


    const jobUpdate = (e) => {

        // setsuccess_msg(!success_msg)
        jobValues["status"] = "New Order"
        jobValues["id"] = uuidv4
        jobValues["type"] = "Job"
        let updatedValue = jobValues;
        setTrelloboard( // Replace the state
            [ // with a new array
                ...trelloboard, // that contains all the old items
                updatedValue // and one new item at the end
            ]
        );
        const { teamupdatelist } = props
        let bodyObj = {
            obj: updatedValue,
            query: {},
            method: "Insert"
        }
        teamupdatelist(bodyObj, "jobs")

        setJob(jobValues, {});
        document.getElementById("jobForm").reset();

    }

    const getInitial = (name) => {
        if(name){
            let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');

            let initials = [...name.matchAll(rgx)] || [];
    
            return initials = (
                (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
            ).toUpperCase();
        }
        

    }

    const onDragStart = (evt) => {
        let element = evt.currentTarget;
        element.classList.add("dragged");
        evt.dataTransfer.setData("text/plain", evt.currentTarget.id);
        evt.dataTransfer.effectAllowed = "move";
    }

    const onDragEnd = (evt) => {
        evt.currentTarget.classList.remove("dragged");
    }

    const onDragEnter = (evt) => {
        evt.preventDefault();
        let element = evt.currentTarget;
        element.classList.add("dragged-over");
        evt.dataTransfer.dropEffect = "move";
    }

    const onDragLeave = (evt) => {
        let currentTarget = evt.currentTarget;
        let newTarget = evt.relatedTarget;
        if (newTarget.parentNode === currentTarget || newTarget === currentTarget)
            return;
        evt.preventDefault();
        let element = evt.currentTarget;
        element.classList.remove("dragged-over");
    }

    const onDragOver = (evt) => {
        evt.preventDefault();
        evt.dataTransfer.dropEffect = "move";
    }

    const onDrop = (evt, value, status) => {
        evt.preventDefault();
        evt.currentTarget.classList.remove("dragged-over");
        let data = evt.dataTransfer.getData("text/plain");
        let tasks = trelloboard;
        
        let updated = tasks.map((task) => {
            if (task.id.toString() === data.toString()) {
                task.status = status;
            }
            return task;
        });
        console.log("data", data, status, updated);
        const { teamupdatelist } = props
        let update_id;
        if(updated && updated[0]._id){
            update_id = updated[0]._id
            delete updated[0]._id
        }
        let bodyObj = {
            obj: updated[0],
            query: { id: updated[0].id },
            method: "Update"
        }
        teamupdatelist(bodyObj, "jobs")
        setClinic(trelloboard => ({
            ...trelloboard,
            ...updated
        }));

    }


    let pending = trelloboard.filter((data) => data.status === "In Progress");
    let done = trelloboard.filter((data) => data.status === "Completed");
    let newOrder = trelloboard.filter((data) => data.status === "New Order");
    let waiting = trelloboard.filter((data) => data.status === "Delivered");
    let archive = trelloboard.filter((data) => data.status === "Archive");

    return (
        <React.Fragment>

            <MetaTags>
                <title>Team Members</title>
            </MetaTags>
            <div className='ward '>
                <div className="main n_office oncall team">
                    <div className="wrapper">
                        <Menus menuName={"TEA-M"} />
                        <div className="am_block">
                            <h2>Available Members</h2>
                            <div className="am_wrp">
                                <div className="profiles">

                                {members && members.length > 0 &&
                                                    
                                                    members.map((item, i) => {
                                                        //console.log("i ", i)

                                                        // let colorcode = [1,2]
                                                        // colorcode.map((code, i) => {
                                                        //     console.log("colorcode ", i, code)
                                                        // })
                                                        
                                                        
                                                        return (
                                                            <div key={i} className={"tm_profile color"+ (i+1)  }>{getInitial(item.member)}</div>
                                                       
                                                    )})}


                                 
                                </div>
                                <div className="btns">
                                    <a href="#" className="icon_btn">Edit Members <span className="material-symbols-outlined icon"> edit
                                    </span></a>
                                    <a href="#" className="icon_btn" data-toggle="modal" data-target="#addmembers">Add Members <span
                                        className="material-symbols-outlined icon"> add
                                    </span></a>
                                    <a href="#" className="icon_btn" data-toggle="modal" data-target="#addjob">Add Job <span
                                        className="material-symbols-outlined icon"> work
                                    </span></a>
                                </div>
                            </div>
                        </div>



                        <div className="trello">
                            <div
                                className="order small-box"
                                onDragLeave={(e) => onDragLeave(e)}
                                onDragEnter={(e) => onDragEnter(e)}
                                onDragEnd={(e) => onDragEnd(e)}
                                onDragOver={(e) => onDragOver(e)}
                                onDrop={(e) => onDrop(e, false, "New Order")}
                            >
                                <section className="drag_container">

                                    <div className="drag_column">
                                        <div className="drag_row">
                                            <h6 className='title'>JOB TODO</h6>
                                            {newOrder.map((task, i) => (
                                                <div
                                                    key={i}
                                                    className="card"
                                                    id={task.id}
                                                    draggable
                                                    onDragStart={(e) => onDragStart(e)}
                                                    onDragEnd={(e) => onDragEnd(e)}
                                                >
                                                    <div className='job_card'>
                                                        <div className='profile color1'>{getInitial(task.job_title)}</div>
                                                        <div className='jb_dec'>
                                                            <p>{task.job_title} </p>
                                                            <p>{task.description}</p>
                                                        </div>
                                                    </div>

                                                    {/* <div className="card_right">
                                                                <div className="status">{task.status}</div>
                                                                <div className="days">{task.time}</div>
                                                                <div className="time">{task.days}</div>
                                                            </div> */}
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                </section>
                            </div>
                            <div
                                className="pending small-box"
                                onDragLeave={(e) => onDragLeave(e)}
                                onDragEnter={(e) => onDragEnter(e)}
                                onDragEnd={(e) => onDragEnd(e)}
                                onDragOver={(e) => onDragOver(e)}
                                onDrop={(e) => onDrop(e, false, "In Progress")}
                            >
                                <section className="drag_container">
                                    <div className="drag_column">
                                        <div className="drag_row">
                                            <h6 className='title'>ACKNOWLEDGE</h6>
                                            {pending.map((task, i) => (
                                                <div
                                                    className="card"
                                                    key={i}
                                                    id={task.id}
                                                    draggable
                                                    onDragStart={(e) => onDragStart(e)}
                                                    onDragEnd={(e) => onDragEnd(e)}
                                                >
                                                    <div className='job_card'>
                                                        <div className='profile color1'>{getInitial(task.job_title)}</div>
                                                        <div className='jb_dec'>
                                                            <p>{task.job_title} </p>
                                                            <p>{task.description}</p>
                                                        </div>
                                                    </div>
                                                    {/* <div className="card_right">
                                                                <div className="status">{task.status}</div>
                                                                <div className="days">{task.time}</div>
                                                                <div className="time">{task.days}</div>
                                                            </div> */}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </section>
                            </div>
                            <div
                                className="waiting small-box"
                                onDragLeave={(e) => onDragLeave(e)}
                                onDragEnter={(e) => onDragEnter(e)}
                                onDragEnd={(e) => onDragEnd(e)}
                                onDragOver={(e) => onDragOver(e)}
                                onDrop={(e) => onDrop(e, true, "Delivered")}
                            >
                                <section className="drag_container">

                                    <div className="drag_column">
                                        <div className="drag_row">
                                            <h6 className='title'>DOING</h6>
                                            {waiting.map((task, i) => (
                                                <div
                                                    className="card"
                                                    key={i}
                                                    id={task.id}
                                                    draggable
                                                    onDragStart={(e) => onDragStart(e)}
                                                    onDragEnd={(e) => onDragEnd(e)}
                                                >
                                                   <div className='job_card'>
                                                        <div className='profile color1'>{getInitial(task.job_title)}</div>
                                                        <div className='jb_dec'>
                                                            <p>{task.job_title} </p>
                                                            <p>{task.description}</p>
                                                        </div>
                                                    </div>
                                                    {/* <div className="card_right">
                                                                <div className="status">{task.status}</div>
                                                                <div className="days">{task.time}</div>
                                                                <div className="time">{task.days}</div>
                                                            </div> */}
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                </section>
                            </div>
                            <div
                                className="done small-box"
                                onDragLeave={(e) => onDragLeave(e)}
                                onDragEnter={(e) => onDragEnter(e)}
                                onDragEnd={(e) => onDragEnd(e)}
                                onDragOver={(e) => onDragOver(e)}
                                onDrop={(e) => onDrop(e, true, "Completed")}
                            >
                                <section className="drag_container">

                                    <div className="drag_column">
                                        <div className="drag_row">
                                            <h6 className='title'>DONE</h6>
                                            {done.map((task, i) => (
                                                <div
                                                    className="card"
                                                    key={i}
                                                    id={task.id}
                                                    draggable
                                                    onDragStart={(e) => onDragStart(e)}
                                                    onDragEnd={(e) => onDragEnd(e)}
                                                >
                                                    <div className='job_card'>
                                                        <div className='profile color1'>{getInitial(task.job_title)}</div>
                                                        <div className='jb_dec'>
                                                            <p>{task.job_title} </p>
                                                            <p>{task.description}</p>
                                                        </div>
                                                    </div>
                                                    {/* <div className="card_right">
                                                                <div className="status">{task.status}</div>
                                                                <div className="days">{task.time}</div>
                                                                <div className="time">{task.days}</div>
                                                            </div> */}
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                </section>
                            </div>
                            <div
                                className="done small-box"
                                onDragLeave={(e) => onDragLeave(e)}
                                onDragEnter={(e) => onDragEnter(e)}
                                onDragEnd={(e) => onDragEnd(e)}
                                onDragOver={(e) => onDragOver(e)}
                                onDrop={(e) => onDrop(e, true, "Archive")}
                            >
                                <section className="drag_container">

                                    <div className="drag_column">
                                        <div className="drag_row">
                                            <h6 className='title'>ARCHIVE</h6>
                                            {archive.map((task, i) => (
                                                <div
                                                    className="card"
                                                    key={i}
                                                    id={task.id}
                                                    draggable
                                                    onDragStart={(e) => onDragStart(e)}
                                                    onDragEnd={(e) => onDragEnd(e)}
                                                >
                                                    <div className='job_card'>
                                                        <div className='profile color1'>{getInitial(task.job_title)}</div>
                                                        <div className='jb_dec'>
                                                            <p>{task.job_title} </p>
                                                            <p>{task.description}</p>
                                                        </div>
                                                    </div>
                                                    {/* <div className="card_right">
                                                                <div className="status">{task.status}</div>
                                                                <div className="days">{task.time}</div>
                                                                <div className="time">{task.days}</div>
                                                            </div> */}
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                </section>
                            </div>
                        </div>


                        <div className="modal fade addjob" id="addmembers" tabIndex="-1" role="dialog"
                            aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">Add Members</h5>
                                        <span className="material-icons" data-dismiss="modal" aria-label="Close">
                                            close
                                        </span>
                                    </div>
                                    <div className="modal-body">

                                    <form id="memberForm">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form_group">
                                                    <label className="label">Search Members</label>
                                                    <input type="text" onChange={(e) => updateInputValue(e)} name="member" className="form-control" placeholder="Add Members" />
                                                </div>
                                            </div>
                                            <div className="col-md-2">
                                                <div className="form_group">
                                                    <label className="label">From</label>
                                                    <input type="time" onChange={(e) => updateInputValue(e)} name="from_time" className="form-control" placeholder="enter" />
                                                </div>
                                            </div>
                                            <div className="col-md-2">
                                                <div className="form_group">
                                                    <label className="label">to</label>
                                                    <input type="time" onChange={(e) => updateInputValue(e)} name="to_time" className="form-control" placeholder="enter" />
                                                </div>
                                            </div>
                                            <div className="col-md-2">
                                                <div className="form_group">
                                                    <label className="label">&nbsp;</label>
                                                    <button className="add"> Add <span className="material-icons icon"> add
                                                    </span></button>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form_group">

                                                    <button type="button" onClick={(e) => { memberUpdate(e) }} data-dismiss="modal" className="btn btn-primary">Submit</button>
                                                </div>
                                            </div>



                                            <div className="members">
                                                {members && members.length > 0 &&
                                                    
                                                    members.map((item, i) => {
                                                        //console.log("i ", i)

                                                        // let colorcode = [1,2]
                                                        // colorcode.map((code, i) => {
                                                        //     console.log("colorcode ", i, code)
                                                        // })
                                                        
                                                        
                                                        return (
                                                        <div className="item" key={i}>
                                                            <a href="#" className="name"> <span className={"tm_profile color"+ (i+1)  }>{getInitial(item.member)}</span>{item.member}</a>
                                                            <div className="time">{item.from_time} to {item.to_time} <span className="material-icons icon"> remove
                                                            </span></div>
                                                        </div>
                                                    )})}

                                            </div>


                                        </div>
                                        </form>

                                    </div>
                                    <div className="modal-footer">

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal fade addjob" id="addjob" tabIndex="-1" role="dialog"
                            aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">Add Job</h5>
                                        <span className="material-icons" data-dismiss="modal" aria-label="Close">
                                            close
                                        </span>
                                    </div>
                                    <div className="modal-body">

                                        <form id="jobForm">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form_group">
                                                    <label className="label">Job Title</label>
                                                    <input type="text" onChange={(e) => updateInputValue(e)} name="job_title" className="form-control" placeholder="enter" autoComplete='off' />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form_group">
                                                    <label className="label">Due Date</label>
                                                    <input type="date" onChange={(e) => updateInputValue(e)} name="due_date" className="form-control" placeholder="enter" autoComplete='off' />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form_group">
                                                    <label className="label">Description</label>
                                                    <textarea rows="4" onChange={(e) => updateInputValue(e)} name="description" cols="50" className="form-control" autoComplete='off'> </textarea>

                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form_group">
                                                    <div className="upload-btn-wrapper">
                                                        <button className="upload_btn"> <span className="material-icons icon"> attachment
                                                        </span> Attach File </button>
                                                        <input type="file" name="myfile" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form_group">
                                                    <label className="label">Originator</label>
                                                    <input type="text" onChange={(e) => updateInputValue(e)} name="originator" className="form-control" placeholder="enter" autoComplete='off' />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form_group">
                                                    <label className="label">By Who </label>
                                                    <input type="text" onChange={(e) => updateInputValue(e)} name="by_who" className="form-control" placeholder="enter" autoComplete='off' />
                                                </div>
                                            </div>

                                        </div>
                                        </form>

                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" onClick={(e) => { jobUpdate(e) }} data-dismiss="modal" className="btn">Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <Modal
                            isOpen={success_msg}
                            toggle={() => {
                                setsuccess_msg(false)
                            }}
                            centered={true}
                        >
                            <div className="modal-header">
                                <h5 className="modal-title mt-0"></h5>
                                <button
                                    type="button"
                                    onClick={() => {
                                        setsuccess_msg(false)
                                    }}
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <h1>Updated Successfully</h1>
                            </div>
                        </Modal>


                    </div>
                </div>
            </div>




        </React.Fragment>
    )
}

TeamList.propTypes = {
    getWards: PropTypes.func,
    getTheatres: PropTypes.func,
    wards: PropTypes.array,
    user: PropTypes.any,
    theatres: PropTypes.array,
    clinic: PropTypes.array,
    patients: PropTypes.array,
    doctors: PropTypes.array,
    profile: PropTypes.object,
    news: PropTypes.array,
}

const mapStateToProps = ({ Layout, Login }) => ({
    wards: Layout.wards,
    user: Login.user,
    theatres: Layout.theatres,
    clinic: Layout.clinic,
    patients: Layout.patients,
    doctors: Layout.doctors,
    profile: Layout.profile,
    news: Layout.news,
    dailyrounds: Layout.dailyrounds,
    tablesData: Layout.tableData,
})

const mapDispatchToProps = dispatch => ({
    getWards: () => dispatch(getWardsList()),
    getTheatres: () => dispatch(getTheatresList()),
    getClinic: () => dispatch(getClinicList()),
    getPatient: () => dispatch(getPatientList()),
    getUserprofile: (id) => dispatch(getUprofile(id)),
    getDoctor: () => dispatch(getDoctorList()),
    patientupdate: (id, data) => dispatch(updatePatient(id, data)),
    newsList: (data) => dispatch(getNews(data)),
    dailyRoundsList: (data) => dispatch(getDailyRounds(data)),
    dailyUpdate: (data) => dispatch(updateDailyRounds(data)),
    teamupdatelist: (data, tablename) => dispatch(teamListUpdate(data, tablename)),
    getTableData: (data, tablename) => dispatch(gettableList(data, tablename))
})

export default connect(mapStateToProps, mapDispatchToProps)(TeamList)
