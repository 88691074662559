import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import MetaTags from 'react-meta-tags';


import PatientBlock from "../Clinic/patientBlock";
import { connect } from "react-redux"
import { getWardsList, getTheatresList, getClinicList, getPatientList, getUprofile, getDoctorList, updatePatient } from "../../store/actions"

const FallsRisk = props => {


    const [visiblePatients, setPatient] = useState(true)
    const [visiblelanding, setClanding] = useState(false)
    const [successmessage, setSuccess] = useState("")


    const [assesmentValues, setAssesment] = useState({})

    const {
        wards, user, theatres, clinic, patients, profile, doctors
    } = props;

    useEffect(() => {
        const { getWards, getTheatres, getClinic, getDoctor, getPatient, getUserprofile } = props
        // getWards()
        // getTheatres()
        // getClinic()
        getPatient()
        getDoctor()
        let userId = localStorage.getItem("authUser")
        getUserprofile(userId)
    }, [])

    const updateRadioValue = (e) => {
        let val = e.target.value;
        let name = e.target.name;
        let updatedValue = { [name]: val };
        setAssesment(assesmentValues => ({
            ...assesmentValues,
            ...updatedValue
        }));
    }


    const closeClick = (e) => {
        e.preventDefault()
        localStorage.removeItem("a")
        props.history.push("/riskassesment")
    }

    let assesmentPage = JSON.parse(localStorage.getItem("a"))
    let clinicPatient = JSON.parse(localStorage.getItem("p"))

    return (
        <React.Fragment>

            <MetaTags>
                <title>{assesmentPage}</title>
            </MetaTags>
            <div className="dashboard ward">
                <div className="main">

                    <div className="popup_wrp">
                        <div className="popup_full prescribe">
                            <div className="popup_hed">
                                <h2>FALLS RISK </h2>
                                <div className="dsp-flex"> <a className="popup_close" onClick={(e) => closeClick(e)}><span className="material-icons">close</span></a></div>

                            </div>
                            <div className="popup_content">
                                <div className="popup_scroll w-btns main nutrition">


                                    <div className="modal add_form guidance_popup fade" id="guidance" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                        <div className="modal-dialog" role="document">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title">GUIDANCE</h5>
                                                    <span className="material-icons" data-dismiss="modal" aria-label="Close">
                                                        close
                                                    </span>
                                                </div>
                                                <div className="modal-body">
                                                    <p>Observe cannula 8 hourly or more frequently if clinically indicated
                                                    </p>
                                                    <p>Secure cannula with an appropriate dressing – change if soiled or contaminated
                                                    </p>
                                                    <p>Aseptic technique must be followed for insertion</p>
                                                    <p>Consider re-siting the cannula every 48-72 hours or as indicated by VIP score
                                                        Plan and document care
                                                    </p>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <PatientBlock patient={clinicPatient} />
                                    <div className="bar">
                                        <div className="left">
                                            <div className="tag" data-toggle="modal"
                                                data-target="#guidance"> Guidance </div>
                                            <h3 className="sub_title">FALLS RISK </h3>
                                        </div>
                                        <div className="right">
                                            <a href="#" className="btn"> <span className="material-symbols-outlined"> assignment </span> Not
                                                Yet Done</a>
                                            <div className="tag"> Due </div>
                                        </div>
                                    </div>


                                    <h3 className="bg_title">FALLS RISK ASSESSMENT <span className="field_title ">(Select all that
                                        apply)</span> </h3>
                                    <div className="fb_table is_tab mb-20">
                                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link active" id="home-tab" data-bs-toggle="tab"
                                                    data-bs-target="#home2" type="button" role="tab" aria-controls="home2"
                                                    aria-selected="true">HIGH RISK</button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link" id="profile-tab" data-bs-toggle="tab"
                                                    data-bs-target="#profile2" type="button" role="tab" aria-controls="profile2"
                                                    aria-selected="false">MEDIUM RISK</button>
                                            </li>

                                        </ul>
                                        <div className="tab-content" id="myTabContent">
                                            <div className="tab-pane fade show active" id="home2" role="tabpanel"
                                                aria-labelledby="home-tab">
                                                
                                                <div className="section ctr fb_table pua is">
                                                    <div className="poc poc1 pt-0">
                                                        <div className="check-box ">
                                                            <div className="round">
                                                                <input type="checkbox" id="checkbox-200" />
                                                                <label for="checkbox-200"></label>
                                                            </div>
                                                            Two or more falls in the last 12 months? / Admitted with a fall
                                                        </div>
                                                        <div className="check-box ">
                                                            <div className="round">
                                                                <input type="checkbox" id="checkbox-201" />
                                                                <label for="checkbox-201"></label>
                                                            </div>
                                                            Difficulty with walking or Unsteady gait
                                                        </div>
                                                        <div className="check-box">
                                                            <div className="round">
                                                                <input type="checkbox" id="checkbox-202" />
                                                                <label for="checkbox-202"></label>
                                                            </div>
                                                            Confusion/ Agitation/Disorientation
                                                        </div>
                                                    </div>


                                                    <button type="button" className="update_btn mt-20">UPDATE</button>
                                                </div>

                                            </div>
                                            <div className="tab-pane fade" id="profile2" role="tabpanel" aria-labelledby="profile-tab">
                                               
                                                <div className="section ctr fb_table pua is">
                                                    <div className="poc poc1 pt-0">
                                                        <div className="check-box ">
                                                            <div className="round">
                                                                <input type="checkbox" id="checkbox-200" />
                                                                <label for="checkbox-200"></label>
                                                            </div>
                                                            Two or more falls in the last 12 months? / Admitted with a fall
                                                        </div>
                                                        <div className="check-box ">
                                                            <div className="round">
                                                                <input type="checkbox" id="checkbox-201" />
                                                                <label for="checkbox-201"></label>
                                                            </div>
                                                            Difficulty with walking or Unsteady gait
                                                        </div>
                                                        <div className="check-box">
                                                            <div className="round">
                                                                <input type="checkbox" id="checkbox-202" />
                                                                <label for="checkbox-202"></label>
                                                            </div>
                                                            Confusion/ Agitation/Disorientation
                                                        </div>
                                                    </div>


                                                    <button type="button" className="update_btn mt-20">UPDATE</button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <h2 className="bg_title">FALL PREVENTION PLAN <span className="field_title">(Check all that
                                        applies)</span> </h2>
                                    <div className="section ctr fb_table pua is">
                                        <div className="row align_center">
                                            <div className="col-md-10">
                                                <p className="field_title mt-0 mb-0">GENERAL</p>
                                            </div>
                                            <div className="col-md-2">
                                                <div className="yn_switch mb-0 mt-0 justify-content-right">
                                                    <label for="check113" className="switch-container ml_0">
                                                        <input hidden="" type="checkbox" className="switch_check" id="check113"/>
                                                            <div className="switch-bg"></div>
                                                            <div className="round-box"></div>
                                                            <div className="switch-left">
                                                                <span>NO</span>
                                                            </div>
                                                            <div className="switch-right">
                                                                <span>YES</span>
                                                            </div>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form_section mt-15 poc poc1 pt-0">
                                            <div className="check-box  ">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-210" />
                                                    <label for="checkbox-210"></label>
                                                </div>
                                                Orientated the patient to ward
                                            </div>
                                            <div className="check-box  ">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-211" />
                                                    <label for="checkbox-211"></label>
                                                </div>
                                                Advice given on safe transfer/mobility
                                            </div>
                                            <div className="check-box  ">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-212" />
                                                    <label for="checkbox-212"></label>
                                                </div>
                                                Falls leaflet / booklet ‘reducing harm from falls’ given to relatives/carers.
                                            </div>

                                            <div className="check-box  ">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-212" />
                                                    <label for="checkbox-212"></label>
                                                </div>
                                                Relatives/carers engaged in care plan.
                                            </div>

                                            <div className="check-box  ">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-212" />
                                                    <label for="checkbox-212"></label>
                                                </div>
                                                Relatives/ Carers would like to be contacted in the event of falls.
                                            </div>

                                            <div className="check-box  ">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-212" />
                                                    <label for="checkbox-212"></label>
                                                </div>
                                                Referred to ward Physiotherapist for strength and balance training.
                                            </div>

                                            <div className="check-box  ">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-212" />
                                                    <label for="checkbox-212"></label>
                                                </div>
                                                Medical & Nursing staff, physiotherapists aware of the patient risk, frequency,
                                                nature, and seriousness of falls.
                                            </div>

                                            <div className="check-box  ">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-212" />
                                                    <label for="checkbox-212"></label>
                                                </div>
                                                Falls Risk noted +
                                            </div>
                                            <button type="button" className="update_btn mt-20">UPDATE</button>
                                        </div>
                                    </div>
                                    <div className="section ctr fb_table pua is">
                                        <div className="row align_center">
                                            <div className="col-md-10">
                                                <p className="field_title mt-0 mb-0">ENVIRONMENT</p>
                                            </div>
                                            <div className="col-md-2 ">
                                                <div className="yn_switch mb-0 mt-0 justify-content-right ">
                                                    <label for="check114" className="switch-container ml_0">
                                                        <input hidden="" type="checkbox" className="switch_check" id="check114" />
                                                            <div className="switch-bg"></div>
                                                            <div className="round-box"></div>
                                                            <div className="switch-left">
                                                                <span>NO</span>
                                                            </div>
                                                            <div className="switch-right">
                                                                <span>YES</span>
                                                            </div>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form_section mt-10 poc poc1 pt-0">
                                            <p className="sub_title mb-10"> Hearing aid / Glasses / Walking aid </p>
                                            <div className="sub-block">
                                                <div className="check-box  ">
                                                    <div className="round">
                                                        <input type="checkbox" id="checkbox-210" />
                                                        <label for="checkbox-210"></label>
                                                    </div>
                                                    Hearing aid / Glasses / Walking aid is used and in reach. The patient can
                                                    identify a pen from a bed length away (with glasses on if worn)
                                                </div>
                                            </div>
                                            <p className="sub_title mb-10"> Trip hazards </p>
                                            <div className="sub-block">
                                                <div className="check-box  ">
                                                    <div className="round">
                                                        <input type="checkbox" id="checkbox-211" />
                                                        <label for="checkbox-211"></label>
                                                    </div>
                                                    Checked room for trailing cables, loose carpets etc.
                                                </div>
                                                <div className="check-box  ">
                                                    <div className="round">
                                                        <input type="checkbox" id="checkbox-212" />
                                                        <label for="checkbox-212"></label>
                                                    </div>
                                                    Advised on risks from drips/tubing/aids.
                                                </div>
                                            </div>
                                            <p className="sub_title mb-10"> Lighting </p>
                                            <div className="sub-block">
                                                <div className="check-box  ">
                                                    <div className="round">
                                                        <input type="checkbox" id="checkbox-213" />
                                                        <label for="checkbox-213"></label>
                                                    </div>
                                                    Best lighting for the patient. Example bedside lamp left on overnight/night
                                                    light in toilet
                                                </div>
                                            </div>

                                            <p className="sub_title mb-10"> Call bell system / bed side Light </p>
                                            <div className="sub-block">
                                                <div className="check-box  ">
                                                    <div className="round">
                                                        <input type="checkbox" id="checkbox-214" />
                                                        <label for="checkbox-214"></label>
                                                    </div>
                                                    Call bell system / bed side Light explained and in reach – Patient able to use
                                                </div>
                                                <div className="check-box  ">
                                                    <div className="round">
                                                        <input type="checkbox" id="checkbox-215" />
                                                        <label for="checkbox-215"></label>
                                                    </div>
                                                    Alternatives provided for patients unable to use call bell Eg brass bell or
                                                    moving of bed nearer nurse’s station.
                                                </div>
                                            </div>
                                            <p className="sub_title mb-10"> Patient Location</p>
                                            <div className="sub-block">
                                                <div className="check-box  ">
                                                    <div className="round">
                                                        <input type="checkbox" id="checkbox-216" />
                                                        <label for="checkbox-216"></label>
                                                    </div>
                                                    The patient is in the most appropriate place on ward for their needs considering
                                                    other patient’s needs as well?

                                                </div>

                                                <div className="check_item ">
                                                    <div className="select_btns popup_select mb-3">
                                                        <div className="select_btn">
                                                            <label>
                                                                <input name="site" type="radio" value="Site 1" /><span>Close to
                                                                    nurse’s station</span>
                                                            </label>
                                                        </div>
                                                        <div className="select_btn">
                                                            <label><input name="site" type="radio" value="Site 2" />
                                                                <span>Close to toilet</span>
                                                            </label>
                                                        </div>
                                                        <div className="select_btn">
                                                            <label><input name="site" type="radio" value="Site 2" />
                                                                <span>Quietest area</span>
                                                            </label>
                                                        </div>
                                                        <div className="select_btn">
                                                            <label><input name="site" type="radio" value="Site 2" />
                                                                <span>Other</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="check-box">
                                                    <div className="round">
                                                        <input type="checkbox" id="checkbox-217" />
                                                        <label for="checkbox-217"></label>
                                                    </div>
                                                    Not in the most appropriate place due to
                                                </div>
                                            </div>
                                            <p className="sub_title mb-10"> Patient has fear of falling</p>
                                            <div className="check-box  ">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-218" />
                                                    <label for="checkbox-218"></label>
                                                </div>
                                                Bed kept at lowest height
                                            </div>
                                            <button type="button" className="update_btn mt-20">UPDATE</button>
                                        </div>
                                    </div>
                                    <div className="section ctr fb_table pua is">
                                        <div className="row align_center">
                                            <div className="col-md-10">
                                                <p className="field_title mt-0 mb-0">FALLS HISTORY</p>
                                            </div>
                                            <div className="col-md-2 ">
                                                <div className="yn_switch mb-0 mt-0 justify-content-right ">
                                                    <label for="check115" className="switch-container ml_0">
                                                        <input hidden="" type="checkbox" className="switch_check" id="check115" />
                                                            <div className="switch-bg"></div>
                                                            <div className="round-box"></div>
                                                            <div className="switch-left">
                                                                <span>NO</span>
                                                            </div>
                                                            <div className="switch-right">
                                                                <span>YES</span>
                                                            </div>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form_section mt-15 poc poc1 pt-0">
                                            <div className="check-box  ">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-218" />
                                                    <label for="checkbox-218"></label>
                                                </div>
                                                Number of falls in the last 12 months
                                            </div>
                                            <div className="check-box  ">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-218" />
                                                    <label for="checkbox-218"></label>
                                                </div>
                                                The patient had an inpatient fall since the last assessment?
                                            </div>
                                            <div className="check-box  ">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-218" />
                                                    <label for="checkbox-218"></label>
                                                </div>
                                                Added to safety brief
                                            </div>
                                            <div className="check-box  ">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-218" />
                                                    <label for="checkbox-218"></label>
                                                </div>
                                                Referral to Community Falls Service – To discuss +
                                            </div>
                                            <div className="check-box  ">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-218" />
                                                    <label for="checkbox-218"></label>
                                                </div>
                                                Contact patient’s GP – To discuss +
                                            </div>
                                        </div>
                                        <button type="button" className="update_btn mt-20">UPDATE</button>
                                    </div>


                                    <div className="section ctr fb_table pua is">
                                        <div className="row align_center">
                                            <div className="col-md-10">
                                                <p className="field_title mt-0 mb-0">POSSIBLE OSTEOPOROSIS</p>
                                            </div>
                                            <div className="col-md-2 ">
                                                <div className="yn_switch mb-0 mt-0 justify-content-right ">
                                                    <label for="check115" className="switch-container ml_0">
                                                        <input hidden="" type="checkbox" className="switch_check" id="check115" />
                                                            <div className="switch-bg"></div>
                                                            <div className="round-box"></div>
                                                            <div className="switch-left">
                                                                <span>NO</span>
                                                            </div>
                                                            <div className="switch-right">
                                                                <span>YES</span>
                                                            </div>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form_section mt-15 poc poc1 pt-0">
                                            <div className="check-box  ">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-218" />
                                                    <label for="checkbox-218"></label>
                                                </div>
                                                Patient had fractures in last 5 years or has known osteoporosis
                                            </div>
                                            <div className="check-box  ">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-218" />
                                                    <label for="checkbox-218"></label>
                                                </div>
                                                Doctor informed +
                                            </div>

                                        </div>
                                        <div className="check_item ">
                                            <div className="select_btns popup_select">
                                                <div className="select_btn">
                                                    <label><input name="site" type="radio" value="Site 2" />
                                                        <span>Need for Screening for osteoporosis</span>
                                                    </label>
                                                </div>
                                                <div className="select_btn">
                                                    <label><input name="site" type="radio" value="Site 2" />
                                                        <span>Need for Anti osteoporotic medications</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <button type="button" className="update_btn mt-20">UPDATE</button>
                                    </div>

                                    <div className="section ctr fb_table pua is">
                                        <div className="row align_center">
                                            <div className="col-md-10">
                                                <p className="field_title mt-0 mb-0">SYNCOPE, BLACK OUTS, DROP ATTACKS</p>
                                            </div>
                                            <div className="col-md-2 ">
                                                <div className="yn_switch mb-0 mt-0 justify-content-right ">
                                                    <label for="check115" className="switch-container ml_0">
                                                        <input hidden="" type="checkbox" className="switch_check" id="check115" />
                                                            <div className="switch-bg"></div>
                                                            <div className="round-box"></div>
                                                            <div className="switch-left">
                                                                <span>NO</span>
                                                            </div>
                                                            <div className="switch-right">
                                                                <span>YES</span>
                                                            </div>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form_section mt-15 poc poc1 pt-0">
                                            <div className="check-box  ">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-218" />
                                                    <label for="checkbox-218"></label>
                                                </div>
                                                Patient has history of syncope, Black outs, drop attacks, loss of consciousness
                                                before falling? Dizziness on standing, turning or before falling
                                            </div>
                                            <div className="check-box  ">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-218" />
                                                    <label for="checkbox-218"></label>
                                                </div>
                                                Lying & standing blood pressures undertaken on 3 separate occasions and inform
                                                medical team.
                                            </div>
                                            <div className="check-box  ">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-218" />
                                                    <label for="checkbox-218"></label>
                                                </div>
                                                Deficit noted on Lying and standing BP - Patient advised on slow movement from
                                                sitting/lying to standing.
                                            </div>
                                            <div className="check-box  ">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-218" />
                                                    <label for="checkbox-218"></label>
                                                </div>
                                                Doctor informed
                                            </div>
                                            <button type="button" className="update_btn mt-20">UPDATE</button>
                                        </div>


                                    </div>

                                    <div className="section ctr fb_table pua is">
                                        <div className="row align_center">
                                            <div className="col-md-10">
                                                <p className="field_title mt-0 mb-0">COGNITIVE IMPAIRMENT</p>
                                            </div>
                                            <div className="col-md-2 ">
                                                <div className="yn_switch mb-0 mt-0 justify-content-right ">
                                                    <label for="check115" className="switch-container ml_0">
                                                        <input hidden="" type="checkbox" className="switch_check" id="check115" />
                                                            <div className="switch-bg"></div>
                                                            <div className="round-box"></div>
                                                            <div className="switch-left">
                                                                <span>NO</span>
                                                            </div>
                                                            <div className="switch-right">
                                                                <span>YES</span>
                                                            </div>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form_section mt-15 poc poc1 pt-0">
                                            <div className="sub_section">
                                                <div className="check-box  ">
                                                    <div className="round">
                                                        <input type="checkbox" id="checkbox-218" />
                                                        <label for="checkbox-218"></label>
                                                    </div>
                                                    Patient has cognitive impairment such as Delirium, Confusion, Dementia,
                                                    Agitated, Restless, Impulsive, Disoriented.
                                                </div>
                                                <div className="check-box  ">
                                                    <div className="round">
                                                        <input type="checkbox" id="checkbox-218" />
                                                        <label for="checkbox-218"></label>
                                                    </div>
                                                    Delirium screen
                                                </div>
                                                <div className="check-box  ">
                                                    <div className="round">
                                                        <input type="checkbox" id="checkbox-218" />
                                                        <label for="checkbox-218"></label>
                                                    </div>
                                                    Assess the needs for bed rails +
                                                </div>
                                                <div className="check-box  ">
                                                    <div className="round">
                                                        <input type="checkbox" id="checkbox-218" />
                                                        <label for="checkbox-218"></label>
                                                    </div>
                                                    Supervision assessment
                                                </div>
                                                <div className="check-box mb-0 ">
                                                    <div className="round">
                                                        <input type="checkbox" id="checkbox-218" />
                                                        <label for="checkbox-218"></label>
                                                    </div>
                                                    Requires increased level of supervision - 1:1 supervision - Escalated to senior
                                                    nurse/matron
                                                </div>

                                            </div>
                                            <div className="check-box ">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-218" />
                                                    <label for="checkbox-218"></label>
                                                </div>
                                                Bed lowest Position/wheels locked. Side rails up except during care/therapy.
                                            </div>
                                            <div className="check-box">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-218" />
                                                    <label for="checkbox-218"></label>
                                                </div>
                                                The chair /commode at the right height for transfer
                                            </div>
                                            <div className="check-box">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-218" />
                                                    <label for="checkbox-218"></label>
                                                </div>
                                                Observable bay
                                            </div>
                                            <div className="check-box">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-218" />
                                                    <label for="checkbox-218"></label>
                                                </div>
                                                Intentional Rounding
                                            </div>
                                            <div className="check-box">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-218" />
                                                    <label for="checkbox-218"></label>
                                                </div>
                                                Cohorted with multiple patients with cognitive impairment with staff member always
                                                present.
                                            </div>
                                            <div className="check-box">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-218" />
                                                    <label for="checkbox-218"></label>
                                                </div>
                                                1:1 special
                                            </div>
                                            <div className="check-box">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-218" />
                                                    <label for="checkbox-218"></label>
                                                </div>
                                                Chair/sensor mat
                                            </div>
                                            <div className="check-box">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-218" />
                                                    <label for="checkbox-218"></label>
                                                </div>
                                                Falls mat
                                            </div>

                                            <div className="check-box">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-218" />
                                                    <label for="checkbox-218"></label>
                                                </div>
                                                Double grip slipper socks applied (even in bed)
                                            </div>

                                            <button type="button" className="update_btn mt-20">UPDATE</button>
                                        </div>
                                    </div>

                                    <div className="section ctr fb_table pua is">
                                        <div className="row align_center">
                                            <div className="col-md-10">
                                                <p className="field_title mt-0 mb-0">CONTINENCE PROBLEMS</p>
                                            </div>
                                            <div className="col-md-2 ">
                                                <div className="yn_switch mb-0 mt-0 justify-content-right ">
                                                    <label for="check115" className="switch-container ml_0">
                                                        <input hidden="" type="checkbox" className="switch_check" id="check115" />
                                                            <div className="switch-bg"></div>
                                                            <div className="round-box"></div>
                                                            <div className="switch-left">
                                                                <span>NO</span>
                                                            </div>
                                                            <div className="switch-right">
                                                                <span>YES</span>
                                                            </div>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form_section mt-15 poc poc1 pt-0">
                                            <div className="check-box ">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-218" />
                                                    <label for="checkbox-218"></label>
                                                </div>
                                                The risk of falls appear to be associated with the patient’s need to use the toilet.
                                                The following are considered/used
                                            </div>
                                            <div className="check-box ">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-218" />
                                                    <label for="checkbox-218"></label>
                                                </div>
                                                A clear route to the bathroom ensured.
                                            </div>
                                            <div className="check-box ">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-218" />
                                                    <label for="checkbox-218"></label>
                                                </div>
                                                Intentional rounding: A routine of frequent toilet visits
                                            </div>
                                            <div className="check-box ">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-218" />
                                                    <label for="checkbox-218"></label>
                                                </div>
                                                Commode or toilet seat raiser
                                            </div>
                                            <div className="check-box ">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-218" />
                                                    <label for="checkbox-218"></label>
                                                </div>
                                                Continence Assessment
                                            </div>
                                            <div className="check-box ">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-218" />
                                                    <label for="checkbox-218"></label>
                                                </div>
                                                MSU for analysis
                                            </div>
                                        </div>
                                        <button type="button" className="update_btn mt-20">UPDATE</button>
                                    </div>

                                    <div className="section ctr fb_table pua is">
                                        <div className="row align_center">
                                            <div className="col-md-10">
                                                <p className="field_title mt-0 mb-0">FOOT PROBLEMS</p>
                                            </div>
                                            <div className="col-md-2 ">
                                                <div className="yn_switch mb-0 mt-0 justify-content-right ">
                                                    <label for="check115" className="switch-container ml_0">
                                                        <input hidden="" type="checkbox" className="switch_check" id="check115" />
                                                            <div className="switch-bg"></div>
                                                            <div className="round-box"></div>
                                                            <div className="switch-left">
                                                                <span>NO</span>
                                                            </div>
                                                            <div className="switch-right">
                                                                <span>YES</span>
                                                            </div>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form_section mt-15 poc poc1 pt-0">
                                            <div className="check-box ">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-218" />
                                                    <label for="checkbox-218"></label>
                                                </div>
                                                The patient has issues with foot health, (eg: overgrown toenails, dressings,
                                                pressure damage, oedema) or inappropriate footwear that could increase the risk of
                                                falls
                                            </div>
                                            <div className="check-box ">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-218" />
                                                    <label for="checkbox-218"></label>
                                                </div>
                                                Patient has secure fit, non-slip footware with no trailing laces.
                                            </div>
                                            <div className="check-box ">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-218" />
                                                    <label for="checkbox-218"></label>
                                                </div>
                                                Double grip slipper socks applied (even in bed) for patients at risk of falling at
                                                night.
                                            </div>
                                            <div className="check-box ">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-218" />
                                                    <label for="checkbox-218"></label>
                                                </div>
                                                Advised on appropriate footwear
                                            </div>
                                            <div className="check-box ">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-218" />
                                                    <label for="checkbox-218"></label>
                                                </div>
                                                Social nail cutting arranged
                                            </div>
                                            <div className="check-box ">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-218" />
                                                    <label for="checkbox-218"></label>
                                                </div>
                                                Referral to podiatry
                                            </div>
                                        </div>
                                        <button type="button" className="update_btn mt-20">UPDATE</button>
                                    </div>

                                    <div className="section ctr fb_table pua is">
                                        <div className="row align_center">
                                            <div className="col-md-10">
                                                <p className="field_title mt-0 mb-0">MOBILITY, POSTURAL INSTABILITY OR BALANCE PROBLEMS
                                                </p>
                                            </div>
                                            <div className="col-md-2 ">
                                                <div className="yn_switch mb-0 mt-0 justify-content-right ">
                                                    <label for="check115" className="switch-container ml_0">
                                                        <input hidden="" type="checkbox" className="switch_check" id="check115" />
                                                            <div className="switch-bg"></div>
                                                            <div className="round-box"></div>
                                                            <div className="switch-left">
                                                                <span>NO</span>
                                                            </div>
                                                            <div className="switch-right">
                                                                <span>YES</span>
                                                            </div>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <p className="ps1">Patient needs help to walk, transfer <br />
                                            Patient tries to walk unaided but are unsafe <br />
                                            Patient uses a walking aide <br />
                                            Patient has gait or balance problems <br />
                                            Patient has issues with seating, e.g <br />
                                            slipping out of the chair
                                        </p>
                                        <div className="form_section mt-15 poc poc1 pt-0">
                                            <div className="check-box ">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-218" />
                                                    <label for="checkbox-218"></label>
                                                </div>
                                                Patient has mobility, postural instability or balance problems
                                            </div>
                                            <div className="check-box ">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-218" />
                                                    <label for="checkbox-218"></label>
                                                </div>
                                                Ensured patient has own walking aids within reach
                                            </div>
                                            <div className="check-box ">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-218" />
                                                    <label for="checkbox-218"></label>
                                                </div>
                                                For Physiotherapy/occupational therapy assessment +
                                            </div>
                                            <div className="check-box ">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-218" />
                                                    <label for="checkbox-218"></label>
                                                </div>
                                                Individual plan for safe patient handling
                                            </div>

                                        </div>
                                        <button type="button" className="update_btn mt-20">UPDATE</button>
                                    </div>

                                    <div className="section ctr fb_table pua is">
                                        <div className="sub_section">
                                            <div className="row align_center">
                                                <div className="col-md-10">
                                                    <p className="field_title mt-0 mb-0">SENSORY PROBLEMS </p>
                                                </div>
                                                <div className="col-md-2 ">
                                                    <div className="yn_switch mb-0 mt-0 justify-content-right ">
                                                        <label for="check115" className="switch-container ml_0">
                                                            <input hidden="" type="checkbox" className="switch_check" id="check115" />
                                                                <div className="switch-bg"></div>
                                                                <div className="round-box"></div>
                                                                <div className="switch-left">
                                                                    <span>NO</span>
                                                                </div>
                                                                <div className="switch-right">
                                                                    <span>YES</span>
                                                                </div>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="sub_section">
                                            <div className="row align_center">
                                                <div className="col-md-10">
                                                    <p className="field_title mt-0 mb-0">Sensory Impairment</p>
                                                </div>
                                                <div className="col-md-2 ">
                                                    <div className="yn_switch mb-0 mt-0 justify-content-right ">
                                                        <label for="check116" className="switch-container ml_0">
                                                            <input hidden="" type="checkbox" className="switch_check" id="check116" />
                                                                <div className="switch-bg"></div>
                                                                <div className="round-box"></div>
                                                                <div className="switch-left">
                                                                    <span>NO</span>
                                                                </div>
                                                                <div className="switch-right">
                                                                    <span>YES</span>
                                                                </div>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form_section mt-15 poc poc1 pt-0">
                                                <div className="check-box ">
                                                    <div className="round">
                                                        <input type="checkbox" id="checkbox-218" />
                                                        <label for="checkbox-218"></label>
                                                    </div>
                                                    The patient has numbness, weakness or spatial perception problems
                                                </div>
                                                <div className="check-box ">
                                                    <div className="round">
                                                        <input type="checkbox" id="checkbox-218" />
                                                        <label for="checkbox-218"></label>
                                                    </div>
                                                    Appropriate referral
                                                </div>


                                            </div>
                                        </div>
                                        <div className="sub_section">
                                            <div className="row align_center">
                                                <div className="col-md-10">
                                                    <p className="field_title mt-0 mb-0">Visual Impairment</p>
                                                </div>
                                                <div className="col-md-2 ">
                                                    <div className="yn_switch mb-0 mt-0 justify-content-right ">
                                                        <label for="check115" className="switch-container ml_0">
                                                            <input hidden="" type="checkbox" className="switch_check" id="check115" />
                                                                <div className="switch-bg"></div>
                                                                <div className="round-box"></div>
                                                                <div className="switch-left">
                                                                    <span>NO</span>
                                                                </div>
                                                                <div className="switch-right">
                                                                    <span>YES</span>
                                                                </div>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form_section mt-15 poc poc1 pt-0">
                                                <div className="check-box ">
                                                    <div className="round">
                                                        <input type="checkbox" id="checkbox-218" />
                                                        <label for="checkbox-218"></label>
                                                    </div>
                                                    The patient has visual impairment: Patient wears glasses, has a history of eye
                                                    conditions or cannot read visual check image 1 or 2.
                                                </div>
                                                <div className="check-box ">
                                                    <div className="round">
                                                        <input type="checkbox" id="checkbox-218" />
                                                        <label for="checkbox-218"></label>
                                                    </div>
                                                    Patient does not have their usual glasses
                                                </div>
                                                <div className="check-box ">
                                                    <div className="round">
                                                        <input type="checkbox" id="checkbox-218" />
                                                        <label for="checkbox-218"></label>
                                                    </div>
                                                    Relatives asked to bring in glasses
                                                </div>
                                                <div className="check-box ">
                                                    <div className="round">
                                                        <input type="checkbox" id="checkbox-218" />
                                                        <label for="checkbox-218"></label>
                                                    </div>
                                                    Appropriate referral
                                                </div>
                                                <div className="check-box ">
                                                    <div className="round">
                                                        <input type="checkbox" id="checkbox-218" />
                                                        <label for="checkbox-218"></label>
                                                    </div>
                                                    Undertake actions for individual care needs
                                                </div>
                                                <div className="check-box ">
                                                    <div className="round">
                                                        <input type="checkbox" id="checkbox-218" />
                                                        <label for="checkbox-218"></label>
                                                    </div>
                                                    Ensure glasses with patient.
                                                </div>
                                                <div className="check-box ">
                                                    <div className="round">
                                                        <input type="checkbox" id="checkbox-218" />
                                                        <label for="checkbox-218"></label>
                                                    </div>
                                                    Adequate lighting.
                                                </div>
                                                <div className="check-box ">
                                                    <div className="round">
                                                        <input type="checkbox" id="checkbox-218" />
                                                        <label for="checkbox-218"></label>
                                                    </div>
                                                    Environment free from clutter.
                                                </div>
                                                <div className="check-box ">
                                                    <div className="round">
                                                        <input type="checkbox" id="checkbox-218" />
                                                        <label for="checkbox-218"></label>
                                                    </div>
                                                    Bed/chair orientation suitable
                                                </div>


                                            </div>
                                        </div>
                                        <div className="sub_section">
                                            <div className="row align_center">
                                                <div className="col-md-10">
                                                    <p className="field_title mt-0 mb-0">Hearing Impairment</p>
                                                </div>
                                                <div className="col-md-2 ">
                                                    <div className="yn_switch mb-0 mt-0 justify-content-right ">
                                                        <label for="check115" className="switch-container ml_0">
                                                            <input hidden="" type="checkbox" className="switch_check" id="check115" />
                                                                <div className="switch-bg"></div>
                                                                <div className="round-box"></div>
                                                                <div className="switch-left">
                                                                    <span>NO</span>
                                                                </div>
                                                                <div className="switch-right">
                                                                    <span>YES</span>
                                                                </div>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form_section mt-15 poc poc1 pt-0">
                                                <div className="check-box ">
                                                    <div className="round">
                                                        <input type="checkbox" id="checkbox-218" />
                                                        <label for="checkbox-218"></label>
                                                    </div>
                                                    The patient has hearing impairment
                                                </div>
                                                <div className="check-box ">
                                                    <div className="round">
                                                        <input type="checkbox" id="checkbox-218" />
                                                        <label for="checkbox-218"></label>
                                                    </div>
                                                    Patient does not have their usual hearing aid
                                                </div>
                                                <div className="check-box ">
                                                    <div className="round">
                                                        <input type="checkbox" id="checkbox-218" />
                                                        <label for="checkbox-218"></label>
                                                    </div>
                                                    Relatives asked to bring in hearing aid
                                                </div>
                                                <div className="check-box ">
                                                    <div className="round">
                                                        <input type="checkbox" id="checkbox-218" />
                                                        <label for="checkbox-218"></label>
                                                    </div>
                                                    Hearing aid battery replaced
                                                </div>
                                                <div className="check-box ">
                                                    <div className="round">
                                                        <input type="checkbox" id="checkbox-218" />
                                                        <label for="checkbox-218"></label>
                                                    </div>
                                                    Appropriate referral
                                                </div>


                                            </div>
                                        </div>
                                        <div className="sub_section">
                                            <div className="row align_center">
                                                <div className="col-md-10">
                                                    <p className="field_title mt-0 mb-0">FAMILY IDENTIFIED FACTORS </p>
                                                </div>
                                                <div className="col-md-2 ">
                                                    <div className="yn_switch mb-0 mt-0 justify-content-right ">
                                                        <label for="check115" className="switch-container ml_0">
                                                            <input hidden="" type="checkbox" className="switch_check" id="check115" />
                                                                <div className="switch-bg"></div>
                                                                <div className="round-box"></div>
                                                                <div className="switch-left">
                                                                    <span>NO</span>
                                                                </div>
                                                                <div className="switch-right">
                                                                    <span>YES</span>
                                                                </div>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form_section mt-15 poc poc1 pt-0">
                                                <div className="check-box ">
                                                    <div className="round">
                                                        <input type="checkbox" id="checkbox-218" />
                                                        <label for="checkbox-218"></label>
                                                    </div>
                                                    Patient’s existing falls interventions discussed with patient/family
                                                </div>
                                                <div className="check-box ">
                                                    <div className="round">
                                                        <input type="checkbox" id="checkbox-218" />
                                                        <label for="checkbox-218"></label>
                                                    </div>
                                                    Locker, aids, belongings moved to the same side of bed they get out of at home
                                                </div>
                                                <div className="check-box ">
                                                    <div className="round">
                                                        <input type="checkbox" id="checkbox-218" />
                                                        <label for="checkbox-218"></label>
                                                    </div>
                                                    Individual plan for safe patient handling
                                                </div>


                                            </div>
                                        </div>
                                        <div className="sub_section mb-0">
                                            <div className="row align_center">
                                                <div className="col-md-10">
                                                    <p className="field_title mt-0 mb-0">OTHERS </p>
                                                </div>
                                                <div className="col-md-2 ">
                                                    <div className="yn_switch mb-0 mt-0 justify-content-right ">
                                                        <label for="check115" className="switch-container ml_0">
                                                            <input hidden="" type="checkbox" className="switch_check" id="check115" />
                                                                <div className="switch-bg"></div>
                                                                <div className="round-box"></div>
                                                                <div className="switch-left">
                                                                    <span>NO</span>
                                                                </div>
                                                                <div className="switch-right">
                                                                    <span>YES</span>
                                                                </div>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form_section mt-15 poc poc1 pt-0">
                                                <div className="check-box ">
                                                    <div className="round">
                                                        <input type="checkbox" id="checkbox-218" />
                                                        <label for="checkbox-218"></label>
                                                    </div>
                                                    Based on the assessment, are there any targeted interventions required eg
                                                </div>
                                                <div className="check-box ">
                                                    <div className="round">
                                                        <input type="checkbox" id="checkbox-218" />
                                                        <label for="checkbox-218"></label>
                                                    </div>
                                                    Low bed
                                                </div>
                                                <div className="check-box ">
                                                    <div className="round">
                                                        <input type="checkbox" id="checkbox-218" />
                                                        <label for="checkbox-218"></label>
                                                    </div>
                                                    Bed in observable position
                                                </div>
                                                <div className="check-box ">
                                                    <div className="round">
                                                        <input type="checkbox" id="checkbox-218" />
                                                        <label for="checkbox-218"></label>
                                                    </div>
                                                    Close observations
                                                </div>
                                                <div className="check-box ">
                                                    <div className="round">
                                                        <input type="checkbox" id="checkbox-218" />
                                                        <label for="checkbox-218"></label>
                                                    </div>
                                                    Intentional rounding
                                                </div>
                                                <div className="check-box ">
                                                    <div className="round">
                                                        <input type="checkbox" id="checkbox-218" />
                                                        <label for="checkbox-218"></label>
                                                    </div>
                                                    Safety mat, Sensors etc
                                                </div>

                                            </div>
                                        </div>
                                        <button type="button" className="update_btn mt-0 mb-20">UPDATE</button>
                                        <p className="ps1">Reassessment - after a fall, whenever the patient’s condition changes or weekly</p>
                                        <div className="row mt_25">
                                            <div className="col-md-3">
                                                <div className="form_group">
                                                    <label className="label">Date </label>
                                                    <input type="date" className="form-control" placeholder="Enter" />

                                                </div>

                                            </div>
                                            <div className="col-md-3"></div>
                                            <div className="col-md-6">
                                                <div className="signature_block">
                                                    <p>Signature</p>
                                                </div>

                                            </div>

                                        </div>
                                    </div>

                                </div>

                            </div>
                            <div className="popup_footer">
                                <div className="prescribe_footer">
                                    <div className="dsp-flex">
                                        <a className="icon_btn"> <span className="material-icons">save</span> SAVE</a>
                                        <button className="border_btn"><span className="material-icons">save_as</span>SAVE &amp;
                                            CLOSE
                                        </button>
                                    </div>
                                    <button className="btn dsp_flex"><span className="material-icons"><span
                                        className="material-icons">close</span></span>DISCARD </button>
                                </div>
                            </div>
                        </div>
                    </div>





                </div>
            </div>




        </React.Fragment>
    )
}

FallsRisk.propTypes = {
    t: PropTypes.any,
    getWards: PropTypes.func,
    getTheatres: PropTypes.func,
    wards: PropTypes.array,
    user: PropTypes.any,
    theatres: PropTypes.array,
    clinic: PropTypes.array,
    patients: PropTypes.array,
    doctors: PropTypes.array,
    profile: PropTypes.object
}

const mapStateToProps = ({ Layout, Login }) => ({
    wards: Layout.wards,
    user: Login.user,
    theatres: Layout.theatres,
    clinic: Layout.clinic,
    patients: Layout.patients,
    doctors: Layout.doctors,
    profile: Layout.profile
})

const mapDispatchToProps = dispatch => ({
    getWards: () => dispatch(getWardsList()),
    getTheatres: () => dispatch(getTheatresList()),
    getClinic: () => dispatch(getClinicList()),
    getPatient: () => dispatch(getPatientList()),
    getUserprofile: (id) => dispatch(getUprofile(id)),
    getDoctor: () => dispatch(getDoctorList()),
    patientupdate: (id, data) => dispatch(updatePatient(id, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(FallsRisk)
