import {
  CHANGE_LAYOUT,
  CHANGE_LAYOUT_WIDTH,
  CHANGE_SIDEBAR_THEME,
  CHANGE_SIDEBAR_TYPE,
  CHANGE_TOPBAR_THEME,
  SHOW_RIGHT_SIDEBAR,
  SHOW_SIDEBAR,
  TOGGLE_LEFTMENU,
  SELECT_ADMINISTRATION,
  GET_WARDS,
  GET_WARDS_SUCCESS,
  GET_THEATRES,
  GET_THEATRES_SUCCESS,
  GET_CLINIC,
  GET_CLINIC_SUCCESS,
  GET_PATIENTS,
  GET_PATIENTS_SUCCESS,
  USER_PROFILE,
  USER_PROFILE_SUCCESS,
  GET_DOCTORS,
  GET_DOCTOR_SUCCESS,
  UPDATE_PATIENT,
  UPDATE_THEATRE_PATIENT,
  UPDATE_WARD_PATIENT,
  UPDATE_THEATRE_REPORTS,
  GET_SESSION_TEAM,
  GET_SESSION_TEAM_SUCCESS,
  UPDATE_TEAM_BRIEF,
  UPDATE_NEWS,
  GET_NEWS,
  GET_NEWS_SUCCESS,
  UPDATE_DAILY_ROUNDS,
  GET_DAILY_ROUNDS,
  GET_DAILY_ROUNDS_SUCCESS,
  UPDATE_TEAM,
  TEAM_LIST_SUCCESS,
  GET_TABLE_DATA,
  GET_TABLE_DATA_SUCCESS
} from "./actionTypes"

export const getWardsList = () => ({
  type: GET_WARDS,
  payload: null,
})

export const wardSuccess = data => {
  return {
    type: GET_WARDS_SUCCESS,
    payload: data,
  }
}

export const getTheatresList = () => ({
  type: GET_THEATRES,
  payload: null,
})

export const theatreSuccess = data => {
  return {
    type: GET_THEATRES_SUCCESS,
    payload: data,
  }
}

export const getClinicList = () => ({
  type: GET_CLINIC,
  payload: null,
})

export const clinicSuccess = data => {
  return {
    type: GET_CLINIC_SUCCESS,
    payload: data,
  }
}

export const getPatientList = () => ({
  type: GET_PATIENTS,
  payload: null,
})

export const patientSuccess = data => {
  return {
    type: GET_PATIENTS_SUCCESS,
    payload: data,
  }
}

export const getDoctorList = () => ({
  type: GET_DOCTORS,
  payload: null,
})

export const getSessionList = () => ({
  type: GET_SESSION_TEAM,
  payload: null,
})

export const getSessionListSuccess = data => {
  return {
    type: GET_SESSION_TEAM_SUCCESS,
    payload: data,
  }
}

export const doctorSuccess = data => {
  return {
    type: GET_DOCTOR_SUCCESS,
    payload: data,
  }
}

export const teamListUpdate = (data, tablename) => ({
  type: UPDATE_TEAM,
  payload: { data, tablename },
})

export const gettableList = (data, tablename) => ({
  type: GET_TABLE_DATA,
  payload: { data, tablename },
})

export const gettableSuccess = data => {
  return {
    type: GET_TABLE_DATA_SUCCESS,
    payload: data,
  }
}

export const updatePatient = (id, data) => ({
  type: UPDATE_PATIENT,
  payload: { id, data },
})

export const updateTheatrePatient = (id, data) => ({
  type: UPDATE_THEATRE_PATIENT,
  payload: { id, data },
})

export const updateTheatreBrief = (id, data) => ({
  type: UPDATE_TEAM_BRIEF,
  payload: { id, data },
})

export const updateWardPatient = (id, data) => ({
  type: UPDATE_WARD_PATIENT,
  payload: { id, data },
})

export const updatetheatreReports = (id, data) => ({
  type: UPDATE_THEATRE_REPORTS,
  payload: { id, data },
})

export const updateNews = (data) => ({
  type: UPDATE_NEWS,
  payload: data ,
})

export const getNews = (data) => ({
  type: GET_NEWS,
  payload: data,
})

export const getNewsSuccess = data => {
  return {
    type: GET_NEWS_SUCCESS,
    payload: data,
  }
}

export const updateDailyRounds = (data) => ({
  type: UPDATE_DAILY_ROUNDS,
  payload: data ,
})

export const getDailyRounds = (data) => ({
  type: GET_DAILY_ROUNDS,
  payload: data,
})

export const getDailyRoundsSuccess = data => {
  return {
    type: GET_DAILY_ROUNDS_SUCCESS,
    payload: data,
  }
}

export const getUprofile = (id) => ({
  type: USER_PROFILE,
  payload: id,
})

export const userprofileSuccess = data => {
  return {
    type: USER_PROFILE_SUCCESS,
    payload: data,
  }
}

export const teamListSuccess = data => {
  return {
    type: TEAM_LIST_SUCCESS,
    payload: data,
  }
}

export const selectAdministration = selection => ({
  type: SELECT_ADMINISTRATION,
  payload: selection,
})

export const changeLayout = layout => ({
  type: CHANGE_LAYOUT,
  payload: layout,
})

export const changeLayoutWidth = width => ({
  type: CHANGE_LAYOUT_WIDTH,
  payload: width,
})

export const changeSidebarTheme = theme => ({
  type: CHANGE_SIDEBAR_THEME,
  payload: theme,
})

export const changeSidebarType = (sidebarType, isMobile) => {
  return {
    type: CHANGE_SIDEBAR_TYPE,
    payload: { sidebarType, isMobile },
  }
}

export const changeTopbarTheme = topbarTheme => ({
  type: CHANGE_TOPBAR_THEME,
  payload: topbarTheme,
})

export const showRightSidebarAction = isopen => ({
  type: SHOW_RIGHT_SIDEBAR,
  payload: isopen,
})

export const showSidebar = isopen => ({
  type: SHOW_SIDEBAR,
  payload: isopen,
})

export const toggleLeftmenu = isopen => ({
  type: TOGGLE_LEFTMENU,
  payload: isopen,
})
