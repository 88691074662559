import React from "react"
import MetaTags from 'react-meta-tags';
import HospitalBed from '../../assets/images/hospital-bed.png';

const TheatrePatients = () => {
    return (
        <React.Fragment>

            <MetaTags>
                <title>Theatre Patients</title>
            </MetaTags>
            <div className="dashboard">

                <div className="main">
                    <div className="wrapper">
                        <div className="patients_section">
                            <div className="sections_block">
                                <div className="section flex">
                                    <div className="icon_title">  <span className="icon"> <img src={HospitalBed} /></span>Theatre - Orthopaedic</div>
                                    <div className="icon_link"> <span className="material-icons">keyboard_arrow_left</span> Locations</div>
                                </div>
                                <div className="section flex">
                                    <h3 className="sub_title">Theatre List</h3>
                                    <div className="select">
                                        <p className="select_title">Select Theatre</p>
                                        <select className="form-control">
                                            <option>Theatre 1</option>
                                            <option>Theatre 3</option>
                                            <option>Theatre 2</option>
                                            <option>Theatre 3</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="select_doctor">
                                    <div className="select_group">
                                        <div className="select">
                                            <p className="select_title">Select Surgeon</p>
                                            <select className="form-control">
                                                <option>Dr. T Kumar</option>
                                                <option>Dr. T Kumar</option>
                                                <option>Dr. T Kumar</option>
                                                <option>Dr. T Kumar</option>
                                            </select>
                                        </div>
                                        <div className="select">
                                            <p className="select_title">Anaesthetist</p>
                                            <select className="form-control">
                                                <option>Dr. Jansen Hoe</option>
                                                <option>Dr. Jansen Hoe</option>
                                                <option>Dr. Jansen Hoe</option>
                                                <option>Dr. Jansen Hoe</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="select_group right">
                                        <div className="select">
                                            <p className="select_title">Service</p>
                                            <select className="form-control">
                                                <option>Trauma</option>
                                                <option>Trauma</option>

                                            </select>
                                        </div>
                                        <div className="select">
                                            <p className="select_title">Time</p>
                                            <select className="form-control">
                                                <option>All Day</option>

                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="patients_table">

                                    <table>
                                        <thead>
                                            <tr>
                                                <td className="headcol">Patient</td>
                                                <th>Procedure</th>
                                                <th>Anaesthetic</th>
                                                <th>Notes</th>
                                                <th>Alerts</th>
                                                <th>Ward</th>
                                                <th>Tracker</th>
                                            </tr>
                                        </thead>
                                        <tr>
                                            <td className="headcol"><div className="ptient_name">Finley Goldsmith</div>
                                                <div className="ptient_age"> <span>Male</span>  <span>60Yrs</span></div>
                                                <div className="ptient_number">NHS : 11256578</div>
                                            </td>
                                            <td className="long">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam tristique felis ex, sed malesuada odio malesuada in. </td>
                                            <td className="long">Dr. Jansen Hoe</td>
                                            <td className="long">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam tristique felis</td>
                                            <td className="long">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</td>
                                            <td className="long">Ward 1</td>
                                            <td className="long">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</td>
                                        </tr>
                                        <tr>
                                            <td className="headcol"><div className="ptient_name">Finley Goldsmith</div>
                                                <div className="ptient_age"> <span>Male</span>  <span>60Yrs</span></div>
                                                <div className="ptient_number">NHS : 11256578</div>
                                            </td>
                                            <td className="long">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam tristique felis ex, sed malesuada odio malesuada in. </td>
                                            <td className="long">Dr. Jansen Hoe</td>
                                            <td className="long">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam tristique felis</td>
                                            <td className="long">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</td>
                                            <td className="long">Ward 1</td>
                                            <td className="long">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</td>
                                        </tr>
                                        <tr>
                                            <td className="headcol"><div className="ptient_name">Finley Goldsmith</div>
                                                <div className="ptient_age"> <span>Male</span>  <span>60Yrs</span></div>
                                                <div className="ptient_number">NHS : 11256578</div>
                                            </td>
                                            <td className="long">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam tristique felis ex, sed malesuada odio malesuada in. </td>
                                            <td className="long">Dr. Jansen Hoe</td>
                                            <td className="long">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam tristique felis</td>
                                            <td className="long">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</td>
                                            <td className="long">Ward 1</td>
                                            <td className="long">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</td>
                                        </tr>
                                        <tr>
                                            <td className="headcol"><div className="ptient_name">Finley Goldsmith</div>
                                                <div className="ptient_age"> <span>Male</span>  <span>60Yrs</span></div>
                                                <div className="ptient_number">NHS : 11256578</div>
                                            </td>
                                            <td className="long">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam tristique felis ex, sed malesuada odio malesuada in. </td>
                                            <td className="long">Dr. Jansen Hoe</td>
                                            <td className="long">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam tristique felis</td>
                                            <td className="long">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</td>
                                            <td className="long">Ward 1</td>
                                            <td className="long">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</td>
                                        </tr>
                                        <tr>
                                            <td className="headcol"><div className="ptient_name">Finley Goldsmith</div>
                                                <div className="ptient_age"> <span>Male</span>  <span>60Yrs</span></div>
                                                <div className="ptient_number">NHS : 11256578</div>
                                            </td>
                                            <td className="long">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam tristique felis ex, sed malesuada odio malesuada in. </td>
                                            <td className="long">Dr. Jansen Hoe</td>
                                            <td className="long">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam tristique felis</td>
                                            <td className="long">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</td>
                                            <td className="long">Ward 1</td>
                                            <td className="long">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</td>
                                        </tr>
                                        <tr>
                                            <td className="headcol"><div className="ptient_name">Finley Goldsmith</div>
                                                <div className="ptient_age"> <span>Male</span>  <span>60Yrs</span></div>
                                                <div className="ptient_number">NHS : 11256578</div>
                                            </td>
                                            <td className="long">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam tristique felis ex, sed malesuada odio malesuada in. </td>
                                            <td className="long">Dr. Jansen Hoe</td>
                                            <td className="long">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam tristique felis</td>
                                            <td className="long">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</td>
                                            <td className="long">Ward 1</td>
                                            <td className="long">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</td>
                                        </tr>
                                        <tr>
                                            <td className="headcol"><div className="ptient_name">Finley Goldsmith</div>
                                                <div className="ptient_age"> <span>Male</span>  <span>60Yrs</span></div>
                                                <div className="ptient_number">NHS : 11256578</div>
                                            </td>
                                            <td className="long">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam tristique felis ex, sed malesuada odio malesuada in. </td>
                                            <td className="long">Dr. Jansen Hoe</td>
                                            <td className="long">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam tristique felis</td>
                                            <td className="long">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</td>
                                            <td className="long">Ward 1</td>
                                            <td className="long">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

export default TheatrePatients
