import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import MetaTags from 'react-meta-tags';


import PatientBlock from "../Clinic/patientBlock";
import { connect } from "react-redux"
import { getWardsList, getTheatresList, getClinicList, getPatientList, getUprofile, getDoctorList, updatePatient } from "../../store/actions"

const VTERisk = props => {


    const [visiblePatients, setPatient] = useState(true)
    const [visiblelanding, setClanding] = useState(false)
    const [successmessage, setSuccess] = useState("")


    const [assesmentValues, setAssesment] = useState({})

    const {
        wards, user, theatres, clinic, patients, profile, doctors
    } = props;

    useEffect(() => {
        const { getWards, getTheatres, getClinic, getDoctor, getPatient, getUserprofile } = props
        // getWards()
        // getTheatres()
        // getClinic()
        getPatient()
        getDoctor()
        let userId = localStorage.getItem("authUser")
        getUserprofile(userId)
    }, [])

    const updateRadioValue = (e) => {
        let val = e.target.value;
        let name = e.target.name;
        let updatedValue = { [name]: val };
        setAssesment(assesmentValues => ({
            ...assesmentValues,
            ...updatedValue
        }));
    }


    const closeClick = (e) => {
        e.preventDefault()
        localStorage.removeItem("a")
        props.history.push("/riskassesment")
    }

    let assesmentPage = JSON.parse(localStorage.getItem("a"))
    let clinicPatient = JSON.parse(localStorage.getItem("p"))

    return (
        <React.Fragment>

            <MetaTags>
                <title>{assesmentPage}</title>
            </MetaTags>
            <div className="dashboard ward">
                <div className="main">

                    <div className="popup_wrp">
                        <div className="popup_full prescribe">
                            <div className="popup_hed">
                                <h2>VTE RISK ASSESSMENT </h2>
                                <div className="dsp-flex"> <a className="popup_close" onClick={(e) => closeClick(e)}><span className="material-icons">close</span></a></div>

                            </div>
                            <div className="popup_content">
                                <div className="popup_scroll w-btns main nutrition">


                                    <div className="modal add_form guidance_popup fade" id="guidance" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                        <div className="modal-dialog" role="document">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title">GUIDANCE</h5>
                                                    <span className="material-icons" data-dismiss="modal" aria-label="Close">
                                                        close
                                                    </span>
                                                </div>
                                                <div className="modal-body">
                                                    <p>Observe cannula 8 hourly or more frequently if clinically indicated
                                                    </p>
                                                    <p>Secure cannula with an appropriate dressing – change if soiled or contaminated
                                                    </p>
                                                    <p>Aseptic technique must be followed for insertion</p>
                                                    <p>Consider re-siting the cannula every 48-72 hours or as indicated by VIP score
                                                        Plan and document care
                                                    </p>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <PatientBlock patient={clinicPatient} />
                                    <div className="bar">
                                        <div className="left">
                                            <div className="tag" data-toggle="modal"
                                                data-target="#guidance"> Guidance </div>
                                            <h3 className="sub_title">VTE RISK ASSESSMENT </h3>
                                        </div>
                                        <div className="right">
                                            <a href="#" className="btn"> <span className="material-symbols-outlined"> assignment </span> Not
                                                Yet Done</a>
                                            <div className="tag"> Due </div>
                                        </div>
                                    </div>


                                    <p className="bg_title mt-20">Check all that apply</p>
                                    <div className="section ctr pua">
                                        <h3 className="sub_title">THROMBOSIS RISK</h3>
                                        <div className="poc poc1 pt-0">
                                            <div className="check_item mt-10 mb-20">
                                                <div className="select_btns popup_select">
                                                    <div className="select_btn">
                                                        <label><input name="site" type="radio" value="Site 1" /><span>ORTHOPAEDIC
                                                            PATIENTS</span></label>
                                                    </div>

                                                </div>
                                            </div>

                                            <div className="check-box">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-114" />
                                                    <label for="checkbox-114"></label>
                                                </div>
                                                Elective major lower extremity arthroplasty (Hip or knee replacement)
                                            </div>
                                            <div className="check-box">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-116" />
                                                    <label for="checkbox-116"></label>
                                                </div>
                                                Surgery involving pelvis or lower limb with a total anaesthetic + surgical
                                                time &gt; 60 minutes
                                            </div>
                                            <div className="check-box">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-117" />
                                                    <label for="checkbox-117"></label>
                                                </div>
                                                Hip, pelvis Fracture, or leg fracture, Multiple trauma
                                            </div>
                                            <div className="check-box">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-118" />
                                                    <label for="checkbox-118"></label>
                                                </div>
                                                Major surgery lasting 2-3 hrs or more
                                            </div>
                                            <div className="check-box">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-119" />
                                                    <label for="checkbox-119"></label>
                                                </div>
                                                Total anaesthetic + surgical time &lt; 90 minutes
                                            </div>
                                            <div className="check-box">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-120" />
                                                    <label for="checkbox-120"></label>
                                                </div>
                                                Leg cast/brace - current
                                            </div>
                                            <div className="check-box">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-121" />
                                                    <label for="checkbox-121"></label>
                                                </div>
                                                Acute spinal cord injury
                                            </div>
                                            <div className="check-box">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-122" />
                                                    <label for="checkbox-122"></label>
                                                </div>
                                                Age &gt; 60
                                            </div>
                                            <div className="check-box">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-123" />
                                                    <label for="checkbox-123"></label>
                                                </div>
                                                Obesity (BMI &lt; 30 kg/m2)
                                            </div>
                                            <div className="check-box">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-124" />
                                                    <label for="checkbox-124"></label>
                                                </div>
                                                Significantly reduced mobility for 3 days or more
                                            </div>
                                            <div className="check-box">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-125" />
                                                    <label for="checkbox-125"></label>
                                                </div>
                                                Long distance travel by car train plane lasting more than 4 hours in the
                                                last 4 weeks
                                            </div>
                                            <div className="check-box">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-126" />
                                                    <label for="checkbox-126"></label>
                                                </div>
                                                History of DVT/PE in self or in the family (first-degree relative)
                                            </div>
                                            <div className="check-box">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-127" />
                                                    <label for="checkbox-127"></label>
                                                </div>
                                                Thrombophilias like Factor V Leiden deficiency, antithrombin deficiency,
                                                protein C, protein S deficiency prothrombin thrombophilia, congenital
                                                dysfibrinogenemia, Antiphospholipid syndrome.
                                            </div>
                                            <div className="check-box">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-128" />
                                                    <label for="checkbox-128"></label>
                                                </div>
                                                Heparin induced thrombocytopenia HIT
                                            </div>
                                            <div className="check-box">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-129" />
                                                    <label for="checkbox-129"></label>
                                                </div>
                                                Varicose veins with phlebitis
                                            </div>
                                            <div className="check-box">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-130" />
                                                    <label for="checkbox-130"></label>
                                                </div>
                                                Dehydration
                                            </div>
                                            <div className="check-box">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-131" />
                                                    <label for="checkbox-131"></label>
                                                </div>
                                                Present cancer or chemotherapy
                                            </div>
                                            <div className="check-box">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-132" />
                                                    <label for="checkbox-132"></label>
                                                </div>
                                                One or more significant medical comorbidities (eg heart disease; metabolic,
                                                endocrine or respiratory pathologies; acute infection diseases; inflammatory
                                                conditions)
                                            </div>
                                            <div className="check-box">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-133" />
                                                    <label for="checkbox-133"></label>
                                                </div>
                                                Surgery with significant reduction in mobility
                                            </div>
                                            <div className="check-box">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-134" />
                                                    <label for="checkbox-134"></label>
                                                </div>
                                                Acute surgical admission with inflammatory or intra-abdominal condition
                                            </div>
                                            <div className="check-box">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-135" />
                                                    <label for="checkbox-135"></label>
                                                </div>
                                                Critical care admission
                                            </div>
                                            <a data-toggle="modal" data-target="#popup2" className="link_btn">CONTRAINDICATIONS
                                                TO VTE PROPHYLAXIS + BLEEDING RISK <span className="material-icons icon">
                                                    arrow_right
                                                </span></a>
                                            <p> <button type="button" className="update_btn mt-20">Select</button></p>

                                        </div>
                                    </div>
                                    <div className="section ctr pua">
                                        <h3 className="sub_title">Plaster casts</h3>
                                        <div className="poc poc1 pt-0">
                                            <div className="check-box">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-156" />
                                                    <label for="checkbox-156"></label>
                                                </div>
                                                Non weightbearing cast higher risk
                                            </div>
                                            <div className="check-box">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-157" />
                                                    <label for="checkbox-157"></label>
                                                </div>
                                                Bed bound, chair bound
                                            </div>
                                            <div className="check-box">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-158" />
                                                    <label for="checkbox-158"></label>
                                                </div>
                                                Long distance travel by car train plane lasting more than 4
                                                hours in the last 7 days
                                            </div>
                                            <div className="check-box">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-159" />
                                                    <label for="checkbox-159"></label>
                                                </div>
                                                Age &gt; 60
                                            </div>
                                            <div className="check-box">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-160" />
                                                    <label for="checkbox-160"></label>
                                                </div>
                                                Obesity (BMI &gt; 30 kg/m2)
                                            </div>
                                            <div className="check-box">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-161" />
                                                    <label for="checkbox-161"></label>
                                                </div>
                                                Dehydration
                                            </div>
                                            <div className="check-box">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-162" />
                                                    <label for="checkbox-162"></label>
                                                </div>
                                                Present cancer or chemotherapy
                                            </div>
                                            <div className="check-box">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-163" />
                                                    <label for="checkbox-163"></label>
                                                </div>
                                                One or more significant medical comorbidities (eg heart disease;
                                                metabolic, endocrine or respiratory pathologies; acute infection
                                                diseases; inflammatory conditions)
                                            </div>
                                            <div className="check-box">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-167" />
                                                    <label for="checkbox-167"></label>
                                                </div>
                                                Varicose veins with phlebitis
                                            </div>
                                            <div className="check-box">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-168" />
                                                    <label for="checkbox-168"></label>
                                                </div>
                                                Significantly reduced mobility for 3 days or more
                                            </div>
                                            <div className="check-box">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-169" />
                                                    <label for="checkbox-169"></label>
                                                </div>
                                                Surgery with significant reduction in mobility
                                            </div>
                                            <div className="check-box">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-170" />
                                                    <label for="checkbox-170"></label>
                                                </div>
                                                History of DVT/PE in self or in the family (first-degree
                                                relative)
                                            </div>
                                            <div className="check-box">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-171" />
                                                    <label for="checkbox-171"></label>
                                                </div>
                                                Thrombophilias like Factor V Leiden deficiency, antithrombin
                                                deficiency, protein C, protein S deficiency
                                            </div>
                                            <div className="check-box">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-172" />
                                                    <label for="checkbox-172"></label>
                                                </div>
                                                Oral contraceptive (Oestrogen-containing) /HRT
                                            </div>
                                            <div className="check-box">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-174" />
                                                    <label for="checkbox-174"></label>
                                                </div>
                                                Pregnant/postpartum &gt; 6 weeks
                                                <div className="check_item mt-20 mb-20">
                                                    <div className="select_btns popup_select">
                                                        <div className="select_btn"><label><input name="site" type="radio" value="Site 1" /><span>FEMALE
                                                            PATIENTS </span></label></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="check-box">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-175" />
                                                    <label for="checkbox-175"></label>
                                                </div>
                                                Oral contraceptive (Oestrogen-containing) /HRT
                                            </div>
                                            <div className="check-box">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-176" />
                                                    <label for="checkbox-176"></label>
                                                </div>
                                                Pregnant/postpartum &gt; 6 weeks
                                            </div>
                                            <div className="check-box">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-177" />
                                                    <label for="checkbox-177"></label>
                                                </div>
                                                Age over 35 years
                                            </div>
                                            <div className="check-box">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-178" />
                                                    <label for="checkbox-178"></label>
                                                </div>
                                                Pregnancy-related risk factor (such as ovarian hyper
                                                stimulation, hyperemesis gravidarum, multiple
                                                pregnancy or pre-eclampsia)
                                            </div>
                                            <div className="check_item mt-10 mb-10">
                                                <div className="select_btns popup_select">
                                                    <div className="select_btn mb-10 mt-10"><label><input name="site" type="radio"
                                                        value="Site 1" /><span>GENERAL
                                                            SURGERY
                                                            PATIENTS </span></label></div>
                                                    <div className="select_btn mt-10"><label><input name="site" type="radio" value="Site 1" /><span>MEDICAL
                                                        PATIENTS
                                                    </span></label></div>
                                                    <div className="select_btn mt-10"><label><input name="site" type="radio"
                                                        value="Site 1" /><span>GYNAECOLOGY
                                                            PATIENTS </span></label></div>
                                                    <div className="select_btn mt-10"><label><input name="site" type="radio"
                                                        value="Site 1" /><span>NEUROSURGERY
                                                            PATIENTS </span></label></div>
                                                </div>
                                            </div>
                                            <p className="field_title">If yes to any of the above: </p>
                                            <p className="sub_title">High Risk: Thromboprophylaxis needed</p>

                                            <p className="ps1 mt-20">Tick each box that applies (more than one box
                                                can be ticked). Any tick should prompt clinical staff to
                                                consider if bleeding risk is sufficient to preclude
                                                pharmacological intervention.
                                            </p>
                                            <div className="yn_switch mb-15 mt-10">
                                                <div className="label-container">
                                                    <p>If YES to any of the above </p>
                                                </div>
                                                <label for="check814" className="switch-container">
                                                    <input hidden="" type="checkbox" className="switch_check" id="check814" />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>NO</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>YES</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="check-box">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-180" />
                                                    <label for="checkbox-180"></label>
                                                </div>
                                                MECHANICAL PROPHYLAXIS until contraindications no longer
                                                present.
                                            </div>
                                            <div className="check-box">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-181" />
                                                    <label for="checkbox-181"></label>
                                                </div>
                                                FOR REVIEW AFTER 24 HRS.
                                            </div>
                                            <div className="check-box">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-182" />
                                                    <label for="checkbox-182"></label>
                                                </div>
                                                NO ABSOLUTE CONTRAINDICATIONS TO PHARMACOLOGICAL
                                                PROPHYLAXIS
                                            </div>
                                            <p> <button type="button" className="update_btn mt-20">Select</button></p>
                                        </div>

                                    </div>

                                    <div className="section ctr">
                                        <div className="row mb-10">
                                            <div className="col-md-3">
                                                <div className="form_group">
                                                    <label className="label">AGE</label>
                                                    <input type="text" className="form-control" placeholder="Enter" />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form_group">
                                                    <label className="label">WEIGHT</label>
                                                    <input type="text" className="form-control" placeholder="Enter" />
                                                </div>
                                            </div>
                                            <div className="col-md-5">
                                                <div className="form_group">
                                                    <label className="label">RENAL FUNCTION</label>
                                                    <input type="text" className="form-control" placeholder="Enter" />
                                                </div>
                                            </div>
                                            <div className="col-md-7">
                                                <div className="form_group">
                                                    <label className="label">CLOTTING PROFILE</label>
                                                    <input type="text" className="form-control" placeholder="Enter" />
                                                </div>
                                            </div>
                                            <div className="col-md-2">
                                                <div className="form_group">
                                                    <label className="label">Hb</label>
                                                    <input type="text" className="form-control" placeholder="Enter" />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="check_item mt_25 mb-0">
                                                    <div className="select_btns popup_select">
                                                        <div className="select_btn mb-0"><label><input name="site" type="radio" value="Site 1" /><span> lead
                                                            to
                                                            labs </span></label></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="section ctr poc is">
                                        <h2 className="popup_title">PROPHYLAXIS DECISION</h2>
                                        <div className="check-box">
                                            <div className="round">
                                                <input type="checkbox" id="checkbox-185" />
                                                <label for="checkbox-185"></label>
                                            </div>
                                            Prophylaxis not required (Patient ambulatory, low risk for DVT/PE)
                                        </div>
                                        <div className="check-box">
                                            <div className="round">
                                                <input type="checkbox" id="checkbox-186" />
                                                <label for="checkbox-186"></label>
                                            </div>
                                            Prophylaxis Required. (Moderate-High Risk)
                                        </div>
                                        <div className="check-box">
                                            <div className="round">
                                                <input type="checkbox" id="checkbox-187" />
                                                <label for="checkbox-187"></label>
                                            </div>
                                            <span> Prophylaxis contraindicated <span className="sub">(Bleeding risk
                                                outweighs VTE risk, On other therapeutic anticoagulant,
                                                Contraindication to heparin, Patient declined pharmacological
                                                prophylaxis)</span> </span>
                                        </div>
                                        <p> <button type="button" className="update_btn mt-20">Select</button></p>
                                    </div>
                                    <div className="section ctr poc is">
                                        <h2 className="popup_title">MECHANICAL PROPHYLAXIS </h2>
                                        <a href="#" className="link_btn">Prescribe <span className="material-icons icon">
                                            arrow_right
                                        </span></a>
                                        <ul className="mt-10">
                                            <li>Information leaflet and discuss decision</li>
                                            <li>Adequate hydration + Early mobilization</li>
                                            <li>Calf exercises</li>
                                            <li>Foot Impulse or Intermittent Pneumatic compression device
                                                (FLOWTRONS)</li>
                                            <li>Bilateral Sequential Compression Devices /Anti-embolic stockings
                                                (TEDS)</li>
                                        </ul>
                                        <p className="field_title">Contraindications to TEDS</p>
                                        <p className="ps1">Severe leg oedema or pulmonary oedema from congestive heart
                                            failure <br />
                                            Severe peripheral arterial disease / peripheral arterial bypass grafting
                                            <br />
                                            Peripheral neuropathy or other causes of sensory impairment
                                            <br />
                                            Major limb deformity preventing correct fit.
                                            <br />
                                            Fragile ‘tissue paper’ skin, Dermatitis
                                            <br />
                                            Use caution and clinical judgement over venous ulcers or wounds

                                        </p>
                                        <div className="check-box mt-20">
                                            <div className="round">
                                                <input type="checkbox" id="checkbox-188" />
                                                <label for="checkbox-188"></label>
                                            </div>
                                            Not suitable for stockings
                                        </div>
                                        <div className="check-box">
                                            <div className="round">
                                                <input type="checkbox" id="checkbox-189" />
                                                <label for="checkbox-189"></label>
                                            </div>
                                            Patient declined mechanical prophylaxis
                                        </div>
                                    </div>
                                    <div className="section ctr poc height">
                                        <h2 className="popup_title">PHARMACOLOGICAL PROPHYLAXIS </h2>
                                        <ul className="mt-10">
                                            <li>Information leaflet and discuss decision</li>
                                            <li>Advice on early mobilization, Hydration, Calf exercises </li>

                                        </ul>
                                        <div className="check-box mt-10">
                                            <div className="round">
                                                <input type="checkbox" id="checkbox-200" />
                                                <label for="checkbox-200"></label>
                                            </div>
                                            Aspirin 150 mg/day for days.
                                        </div>
                                        <div className="check-box">
                                            <div className="round">
                                                <input type="checkbox" id="checkbox-201" />
                                                <label for="checkbox-201"></label>
                                            </div>
                                            Adult enteric coated Aspirin 325mg PO – first dose in PACU, then daily
                                        </div>
                                        <div className="check-box">
                                            <div className="round">
                                                <input type="checkbox" id="checkbox-202" />
                                                <label for="checkbox-202"></label>
                                            </div>
                                            Rivaroxaban 10 mg/day Orally for
                                        </div>
                                        <div className="check-box">
                                            <div className="round">
                                                <input type="checkbox" id="checkbox-203" />
                                                <label for="checkbox-203"></label>
                                            </div>
                                            Rivaroxaban (Xarelto) 10mg PO daily at 9AM start POD
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form_group">
                                                    <label className="label">1. Serum Creatinine</label>
                                                    <input type="text" className="form-control" placeholder="Enter" />
                                                    <p className="field_note">contraindicated: CrCl &gt; 30mL /min or mod to severe hepatic disease (Child Pugh Score &lt; 7)</p>
                                                </div>

                                            </div>
                                            <div className="col-md-6">
                                                <div className="form_group">
                                                    <label className="label">Warfarin</label>
                                                    <input type="text" className="form-control" placeholder="Enter" />
                                                    <p className="field_note">mg PO at 17:00 day of surgery</p>
                                                </div>

                                            </div>
                                            <div className="col-md-6">
                                                <div className="form_group">
                                                    <label className="label">then Warfarin </label>
                                                    <input type="text" className="form-control" placeholder="Enter" />
                                                    <p className="field_note">mg Q day – Hold if INR &lt; 2.5</p>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="check-box">
                                            <div className="round">
                                                <input type="checkbox" id="checkbox-204" />
                                                <label for="checkbox-204"></label>
                                            </div>
                                            Low Molecular Weight Heparin (LMWH)
                                        </div>
                                        <div className="check-box">
                                            <div className="round">
                                                <input type="checkbox" id="checkbox-204" />
                                                <label for="checkbox-204"></label>
                                            </div>
                                            Dalteparin 5000 Units / day SC for days / while inpatient. STARTING AT
                                            ON
                                        </div>
                                        <div className="check-box">
                                            <div className="round">
                                                <input type="checkbox" id="checkbox-204" />
                                                <label for="checkbox-204"></label>
                                            </div>
                                            Enoxaparin Enoxaparin (Lovenox) 30mg SQ Q 12hrs, start POD 1 at 8am
                                            (caution with CrCl &gt; 30ml/min; heparin is preferred agent) </div>
                                        <div className="check-box">
                                            <div className="round">
                                                <input type="checkbox" id="checkbox-204" />
                                                <label for="checkbox-204"></label>
                                            </div>
                                            Enoxaparin (Lovenox) 40mg SQ Q 24hrs, start POD 1 at 8am
                                            (caution with CrCl &gt; 30ml/min; heparin is preferred agent) </div>
                                        <div className="check-box">
                                            <div className="round">
                                                <input type="checkbox" id="checkbox-204" />
                                                <label for="checkbox-204"></label>
                                            </div>
                                            Heparin
                                        </div>
                                        <a href="#" className="link_btn">Prescribe <span className="material-icons icon">
                                            arrow_right
                                        </span></a>
                                        <p className="sub_title mt_25 mb-10 align-center">Risk
                                            Assessment completed and documented on drug chart by</p>
                                        <div className="row mt_25">
                                            <div className="col-md-6">
                                                <div className="signature_block">
                                                    <p>Signature</p>
                                                </div>
                                                <div className="form_group mt-20">
                                                    <label className="label">Designation </label>
                                                    <input type="text" className="form-control" placeholder="Enter" />

                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form_group">
                                                    <label className="label">Name </label>
                                                    <input type="text" className="form-control" placeholder="Enter" />

                                                </div>

                                                <div className="form_group mt-20">
                                                    <label className="label">Date </label>
                                                    <input type="text" className="form-control" placeholder="Enter" />

                                                </div>
                                            </div>
                                        </div>

                                    </div>


                                </div>

                            </div>
                            <div className="popup_footer">
                                <div className="prescribe_footer">
                                    <div className="dsp-flex">
                                        <a className="icon_btn"> <span className="material-icons">save</span> SAVE</a>
                                        <button className="border_btn"><span className="material-icons">save_as</span>SAVE &amp;
                                            CLOSE
                                        </button>
                                    </div>
                                    <button className="btn dsp_flex"><span className="material-icons"><span
                                        className="material-icons">close</span></span>DISCARD </button>
                                </div>
                            </div>
                        </div>
                    </div>





                </div>
            </div >




        </React.Fragment >
    )
}

VTERisk.propTypes = {
    t: PropTypes.any,
    getWards: PropTypes.func,
    getTheatres: PropTypes.func,
    wards: PropTypes.array,
    user: PropTypes.any,
    theatres: PropTypes.array,
    clinic: PropTypes.array,
    patients: PropTypes.array,
    doctors: PropTypes.array,
    profile: PropTypes.object
}

const mapStateToProps = ({ Layout, Login }) => ({
    wards: Layout.wards,
    user: Login.user,
    theatres: Layout.theatres,
    clinic: Layout.clinic,
    patients: Layout.patients,
    doctors: Layout.doctors,
    profile: Layout.profile
})

const mapDispatchToProps = dispatch => ({
    getWards: () => dispatch(getWardsList()),
    getTheatres: () => dispatch(getTheatresList()),
    getClinic: () => dispatch(getClinicList()),
    getPatient: () => dispatch(getPatientList()),
    getUserprofile: (id) => dispatch(getUprofile(id)),
    getDoctor: () => dispatch(getDoctorList()),
    patientupdate: (id, data) => dispatch(updatePatient(id, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(VTERisk)
