import React from "react";

function IconAngleUp(props) {
   return (
    <svg
      width="14"
      height="8"
      viewBox="0 0 14 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.99965 2.8286L11.9495 7.77832L13.3637 6.36411L6.99965 0.000100136L0.635742 6.36411L2.04995 7.77832L6.99965 2.8286Z"
        fill="black"
      />
    </svg>
  );
}

export default IconAngleUp;
