import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import MetaTags from 'react-meta-tags';
import SignaturePad from 'react-signature-canvas'
import Select from "react-select"
import { removeSpecialCharacters } from '../../utils/utils';
import { Buffer } from 'buffer';
import { connect } from "react-redux"
import { getWardsList, getTheatresList, getClinicList, getPatientList, getUprofile, getDoctorList, updatePatient } from "../../store/actions"

const Interventions = props => {

    let provider_sign = useRef(null);
    let responsibility_sign = useRef(null);
    let photograph_sign = useRef(null);

    const [producettos, setproducettos] = useState(false)
    const [template, setTemplate] = useState(false)

    const [file, setFile] = useState({});
    const [legalInfo, setLegalInfo] = useState(false)
    const [clinicValues, setClinic] = useState({})

    const [selectedGroup, setselectedGroup] = useState(null)
    const [popupname, setpopup_name] = useState({})
    const [anydelay, setDelay] = useState(false)

    const {
        wards, user, theatres, clinic, patients, profile, doctors
    } = props;

    const opcsoptionGroup =  [
        { label: "CODE 1", value: "CODE 1" },
        { label: "CODE 2", value: "CODE 2" },
        { label: "CODE 3", value: "CODE 3" },
        { label: "CODE 4", value: "CODE 4" },
        { label: "CODE 5", value: "CODE 5" },
      ]
    const template_optionGroup =  [
    { label: "Template 1", value: "Template 1" },
    { label: "Template 2", value: "Template 2" },
    { label: "Template 3", value: "Template 3" },
    { label: "Template 4", value: "Template 4" },
    { label: "Template 5", value: "Template 5" },
    ]
      

    useEffect(() => {
        const { getWards, getTheatres, getClinic, getDoctor, getPatient, getUserprofile } = props
        // getWards()
        // getTheatres()
        // getClinic()
        getPatient()
        getDoctor()
        let userId = localStorage.getItem("authUser")
        getUserprofile(userId)
    }, [])


    useEffect(() => {
        let clinicPatient = JSON.parse(localStorage.getItem("p"))
        if (patients && patients.length > 0 && clinicPatient && clinicPatient._id) {
            const cPatient = patients.filter(
                usr => usr._id === clinicPatient._id
            )
            if (cPatient && cPatient[0]) {
                let cpatientData = cPatient[0]
                delete cpatientData["_id"]
                setClinic(clinicValues => ({
                    ...clinicValues,
                    ...cpatientData
                }));
            }

        }
    }, [patients])

    const updateClinicValues = (e, name, val) => {
        //  e.preventDefault()
        let updatedValue = {};
        updatedValue = { [name]: val };
        setClinic(clinicValues => ({
            ...clinicValues,
            ...updatedValue
        }));
    }

    const updateInputValue = (e) => {
        let val = e.target.value;
        let name = e.target.name;
        let updatedValue = {};
        updatedValue = { [name]: val };
        setClinic(clinicValues => ({
            ...clinicValues,
            ...updatedValue
        }));

    }

    const updateMultipleValues = (e, name, val, type) => {
        e.preventDefault()
        let tvalues = clinicValues
        e.target.parentElement.classList.add('active');
        if (tvalues[name]) {
            const exists = tvalues[name].includes(val);
            if (!exists) {
                tvalues[name].push(val)
            }

        } else {
            tvalues = {
                [name]: [val]
            }
        }
        // let updatedValue = {};
        // tvalues[type] = true
        setClinic(clinicValues => ({
            ...clinicValues,
            ...tvalues
        }));

    }

    const updateInputValues = (name, popupname, val) => {
        //  e.preventDefault()
        // let updatedValue = {
        //    [popupname]:{[name] : val}
        // };
        let updatedValue = clinicValues;
        //updatedValue = { [name]: val };
        // updatedValue[popupname][name] = val;
        if (updatedValue[popupname]) {
            updatedValue[popupname][name] = val;
        } else {
            updatedValue = {
                [popupname]: { [name]: val }

            };
        }
        console.log("updatedValue 2", updatedValue)
        setClinic(clinicValues => ({
            ...clinicValues,
            ...updatedValue
        }));
    }

    const updateClinic = (e) => {
        const { patientupdate } = props
        let clinicPatient = JSON.parse(localStorage.getItem("p"))
        let id = clinicPatient._id
        patientupdate(id, clinicValues)
        let patient = {
            ...clinicPatient,
            ...clinicValues
        }
        localStorage.setItem("p", JSON.stringify(patient))



    }

    const handleSelectGroup = (selectedGroup, name) => {
        // console.log("selectedGroup ", selectedGroup)
        let updatedValue = { [name]: selectedGroup };
        // setWard(wardValues => ({
        //     ...wardValues,
        //     ...updatedValue
        // }));
    }

    const updateSwitchValue = (name, value) => {
        let updatedValue = {};
        updatedValue = { [name]: value };
        setLegalInfo(legalInfo => ({
            ...legalInfo,
            ...updatedValue
        }));

    }

    const handleChange = (event) => {
        updateSwitchValue(event.target.name, event.target.checked)
    }


    const clear = (e, refvalue) => {
        e.preventDefault()
        refvalue.clear()
    }
    const trim = (e, type, refvalue) => {
        e.preventDefault()
        sigUpload(refvalue.getCanvas().toDataURL('image/png'), type)
    }

    const sigUpload = async (filedata, name) => {
        const file = filedata
        //console.log("file ", file, name)
        let filename = name + ".png";
        // const base64 = await convertBase64(file)
        //console.log("filename ", filename)
        let pdetails = JSON.parse(localStorage.getItem("p"))

        const dataf = file.replace(/^data:image\/\w+;base64,/, "");

        const buf = Buffer.from(dataf, "base64");
        let uploadFilePath = "ipr/" + pdetails._id + "/" + name + "_result" + "/" + removeSpecialCharacters(filename);
        let data = { source_bucket: "ipr-resources", sourcepath: uploadFilePath }
        // console.log("data ", data)

        const response = await fetch(API_URL+'upload?appname=ipr', {
            method: "POST",
            mode: "cors",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
        const result = await response.json();
        // console.log("result ", result, buf.length)
        let url = result.result
        // console.log("url ", url)
        const base64Data = new Buffer.from(filedata.replace(/^data:image\/\w+;base64,/, ""), 'base64');
        // Getting the file type, ie: jpeg, png or gif
        const type = filedata.split(';')[0].split('/')[1];
        const uploadImage = await fetch(url, {
            method: "PUT",
            mode: "cors",
            headers: {
                'Content-Type': `image/${type}`,
                'Content-Encoding': 'base64',
                ACL: 'public-read',
            },
            body: base64Data
        })

        //console.log("resultImage ", uploadImage)

        let imagePath = "https://ipr-resources.s3.amazonaws.com/"
        let imageUploadPath = imagePath + uploadFilePath;


        let updateObj = {
            [name + "_result"]: imageUploadPath,
            [name + "_update"]: new Date()
        }

        setFile(file => ({
            ...file,
            ...updateObj
        }));
        setLegalInfo(legalInfo => ({
            ...legalInfo,
            ...updateObj
        }));

        // let patient = {
        //     ...pdetails,
        //     ...updateObj
        // }
        // localStorage.setItem("p", JSON.stringify(patient))

    };

    const handleFile = async (e, name) => {

        const file = e.target.files[0]
        // console.log("file ", file, name)
        let filename = e.target.files[0].name;
        // const base64 = await convertBase64(file)
        //console.log("filename ", filename)
        let pdetails = JSON.parse(localStorage.getItem("p"))
        // var reader = new FileReader();
        //   reader.readAsDataURL(file);
        // reader.onload = async function (e) {
        // console.log("e ", e)
        let uploadFilePath = "ipr/" + pdetails._id + "/" + name + "_result" + "/" + removeSpecialCharacters(filename);
        let data = { source_bucket: "ipr-resources", sourcepath: uploadFilePath }
        // console.log("data ", data)

        const response = await fetch(API_URL+'upload?appname=ipr', {
            method: "POST",
            mode: "cors",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
        const result = await response.json();

        // console.log("result ", result)
        let url = result.result
        // console.log("url ", url)
        const uploadImage = await fetch(url, {
            method: "PUT",
            mode: "cors",
            headers: {
                "Content-Type": "multipart/form-data",
                "Accept": "/",
                "Cache-Control": "no-cache",
                "Accept-Encoding": "gzip, deflate",
                "Connection": "keep-alive",
                "cache-control": "no-cache"
            },
            body: file
        })

        // console.log("resultImage ", uploadImage)

        let imagePath = "https://ipr-resources.s3.amazonaws.com/"
        let imageUploadPath = imagePath + uploadFilePath;

        let updateObj = {
            [name + "_result"]: imageUploadPath,
            [name + "_update"]: new Date()
        }
        // patientupdate(pdetails._id, updateObj)
        setFile(file => ({
            ...file,
            ...updateObj
        }));
        setLegalInfo(legalInfo => ({
            ...legalInfo,
            ...updateObj
        }));



    };

    const wardBack = (e) => {
        e.preventDefault()
        props.history.push("/wl")
        document.body.style.overflow = '';
    }

    let clinicPatient = JSON.parse(localStorage.getItem("p"))

    // console.log(" legalInfo ", legalInfo)

    return (
        <React.Fragment>

            <MetaTags>
                <title>INTERVENTIONS</title>
            </MetaTags>

         

            <div className='ward '>
            <div className="main ald">

            <div className="wrapper">
                <div className="titleandinp">
                    <h3>INTERVENTIONS ADMINISTERED DURING THE SHIFT</h3>
                </div>
                <p className="field_title bold">RESP INTERVENTIONS</p>
                <div className="section mt-10">
                    <h2 className="popup_title"> COMFORT MEASURES</h2>

                    <div className="check_item mt-10"><label className="check">Using airway protection devices</label>

                    {clinicValues.airway_protection ?
                        <div className="select_btns popup_select">
                            <div className="select_btn"><label><input type="radio"
                                value={clinicValues.airway_protection} checked /><span>{clinicValues.airway_protection} </span></label>
                            </div> {clinicValues.airway_protection && <a onClick={(e) => updateClinicValues(e, "airway_protection", false)}><span className="material-symbols-outlined"> reply
                            </span></a>}

                        </div>
                        :

                        <div className="select_btns popup_select">
                            <div className="select_btn" ><label><input onClick={(e) => updateInputValue(e)}  name="airway_protection" type="radio" value="Nasal cannula" /><span>Nasal cannula </span></label></div>
                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)}  name="airway_protection" type="radio" value="Simple oxygen mask" /><span>Simple oxygen mask
                                    </span></label></div>
                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)}  name="airway_protection" type="radio" value="Non rebreather mask" /><span>Non rebreather mask
                                    </span></label></div>
                            
                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)}  name="airway_protection" type="radio" value="Warm and humidified oxygen"
                                         /><span>Warm and humidified oxygen
                                    </span></label></div>
                        </div>
                        }


                    </div>

                    <div className="row">
                        <div className="col-md-6">
                            <div className="form_group mb-0">
                                <label className="label">Ventilate</label>
                                <div className="measure tqt">
                                    <input type="text" className="field" placeholder="Ventilation rate " />
                                    <span>lit/min </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form_group mb-0">
                                <label className="label">O2administration</label>
                                <div className="measure tqt">
                                    <input type="text" className="field" placeholder="Oxygen rate" />
                                    <span>lit/min </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="yn_switch mb-15 mt-10 ">
                                <div className="label-container">
                                    <p>Starting and administering nebuliser therapy</p>
                                </div>
                                <label for="check826" className="switch-container">
                                    <input hidden="" type="checkbox" className="switch_check" id="check826" />
                                    <div className="switch-bg"></div>
                                    <div className="round-box"></div>
                                    <div className="switch-left">
                                        <span>NO</span>
                                    </div>
                                    <div className="switch-right">
                                        <span>YES</span>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="yn_switch mb-15 mt-0 ">
                                <div className="label-container">
                                    <p>Performing Spirometry</p>
                                </div>
                                <label for="check827" className="switch-container">
                                    <input hidden="" type="checkbox" className="switch_check" id="check827" />
                                    <div className="switch-bg"></div>
                                    <div className="round-box"></div>
                                    <div className="switch-left">
                                        <span>NO</span>
                                    </div>
                                    <div className="switch-right">
                                        <span>YES</span>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className="form_group">
                        <button className="border_btn" onClick={() => { setTemplate(true) }}><span className="material-icons">send</span>Select Template</button>
                            </div>
                    </div>
                  
                </div>

                <div className="section mt-10">
                    <h2 className="popup_title"> RESP INTERVENTIONS</h2>
                    <p className="note2 select_note">Select all that apply for Multiselect forms</p>
                    {clinicValues.resp_interventions && clinicValues.resp_interventions_list && clinicValues.resp_interventions_list.length > 0 ? <div className="check_list w_white mb-0">

                    {clinicValues.resp_interventions_list.map((item, i) => (
                        <div className="check_item active" key={i} onClick={(e) => updateClinicValues(e, "resp_interventions", false)} ><label className="check">{item}</label></div>
                    ))}

                    </div> :
                    <div className="check_list w_white mb-0">
                        <span className="check_btn2" onClick={(e) => updateClinicValues(e, "resp_interventions", true)}><span className="material-icons"> done </span></span>
                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "resp_interventions_list", "Performing CPR", "resp_interventions")}><label className="check">Performing CPR</label> </div>
                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "resp_interventions_list", "Central line insertion", "resp_interventions")}><label className="check">Central line insertion</label> </div>
                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "resp_interventions_list", "PICC line insertion", "resp_interventions")}><label className="check">PICC line insertion</label> </div>
                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "resp_interventions_list", "Elevate legs", "resp_interventions")}><label className="check">Elevate legs </label> </div>
                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "resp_interventions_list", "Compression stockings", "resp_interventions")}><label className="check">Compression stockings </label> </div>
                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "resp_interventions_list", "Taking all basic observations", "resp_interventions")}><label className="check">Taking all basic observations </label> </div>
                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "resp_interventions_list", "ECG recording", "resp_interventions")}><label className="check">ECG recording </label> </div>
                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "resp_interventions_list", "Cardiac monitoring initiated", "resp_interventions")}><label className="check">Cardiac monitoring initiated </label> </div>
                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "resp_interventions_list", "Central line Care", "resp_interventions")}><label className="check">Central line Care</label> </div>
                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "resp_interventions_list", "PICC line Care", "resp_interventions")}><label className="check">PICC line Care</label> </div>
                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "resp_interventions_list", "Pulse oximeter applied", "resp_interventions")}><label className="check">Pulse oximeter applied</label> </div>
                    </div>
                    }


                    <div className="form_group">
                        <button className="border_btn" onClick={() => { setTemplate(true) }}><span className="material-icons">send</span>Select Template</button>
                            </div>
                  
                </div>

                <div className="section mt-10">
                    <p className="field_title bold mt-10">GIT INTERVENTIONS</p>
                    <p className="note2 select_note">Select all that apply for Multiselect forms</p>
                    <div className="check_list w_white mb-0">
                        <span className="check_btn2"><span className="material-icons"> done </span></span>
                        <div className="check_item"><label className="check">Orogastric tube Insertion</label> </div>
                        <div className="check_item"><label className="check">NG tube Insertion</label> </div>
                        <div className="check_item"><label className="check">Aspiration</label> </div>
                        <div className="check_item"><label className="check">Gastric lavage </label> </div>
                        <div className="check_item"><label className="check">NG tube Care</label> </div>
                        <div className="check_item"><label className="check">Orogastric tube Care </label> </div>
                        <div className="check_item"><label className="check">Enema </label> </div>
                    </div>

                    <div className="form_group">
                        <button className="border_btn" onClick={() => { setTemplate(true) }}><span className="material-icons">send</span>Select Template</button>
                            </div>
                 
                </div>

                <div className="section mt-10">
                    <p className="field_title bold mt-10">GU INTERVENTIONS</p>
                    <p className="note2 select_note">Select all that apply for Multiselect forms</p>
                    <div className="check_list w_white mb-0">
                        <span className="check_btn2"><span className="material-icons"> done </span></span>
                        <div className="check_item"><label className="check">Bladder scanning </label> </div>
                        <div className="check_item"><label className="check">Urinary catheterization</label> </div>
                    </div>

                    <div className="form_group">
                        <button className="border_btn" onClick={() => { setTemplate(true) }}><span className="material-icons">send</span>Select Template</button>
                            </div>
                   
                </div>

                <div className="section mt-10">
                    <p className="field_title bold mt-10">IVLINES</p>
                    <p className="note2 select_note">Select all that apply for Multiselect forms</p>
                    <div className="check_list w_white mb-0">
                        <span className="check_btn2"><span className="material-icons"> done </span></span>
                        <div className="check_item"><label className="check">Venepuncture </label> </div>
                        <div className="check_item"><label className="check">Blood cultures </label> </div>
                        <div className="check_item"><label className="check">IV Cannulation</label> </div>
                        <div className="check_item"><label className="check">IV site 1,2,3,4 </label> </div>
                        <div className="check_item"><label className="check">IV injections </label> </div>
                        <div className="check_item"><label className="check">IV solution</label> </div>
                        <div className="check_item"><label className="check">Setting up an IV infusion </label> </div>
                        <div className="check_item"><label className="check">Blood transfusions etc </label> </div>
                        <div className="check_item"><label className="check">IV via pressure bag infusion </label> </div>
                        <div className="check_item"><label className="check">IV via rapid infuser </label> </div>
                        <div className="check_item"><label className="check">IM injections </label> </div>
                        <div className="check_item"><label className="check">SC injections </label> </div>
                        <div className="check_item"><label className="check">ARTERIAL blood gases </label> </div>
                    </div>

                    <div className="form_group">
                        <button className="border_btn" onClick={() => { setTemplate(true) }}><span className="material-icons">send</span>Select Template</button>
                            </div>
                   
                </div>

                <div className="section mt-10">
                    <p className="field_title bold mt-10">SKIN INTERVENTIONS</p>
                    <p className="note2 select_note">Select all that apply for Multiselect forms</p>
                    <div className="check_list w_white mb-0">
                        <span className="check_btn2"><span className="material-icons"> done </span></span>
                        <div className="check_item"><label className="check">Wound swab </label> </div>
                        <div className="check_item"><label className="check">PICO / VAC dressing </label> </div>
                        <div className="check_item"><label className="check">Dressing change</label> </div>
                        <div className="check_item"><label className="check">I&D assist </label> </div>
                        <div className="check_item"><label className="check">Wound cleaned </label> </div>
                        <div className="check_item"><label className="check">Wound dressed </label> </div>
                        <div className="check_item"><label className="check">Dressing changed </label> </div>
                        <div className="check_item"><label className="check">I&D </label> </div>
                        <div className="check_item"><label className="check">Wound debridement </label> </div>
                    </div>

                    <div className="form_group">
                        <button className="border_btn" onClick={() => { setTemplate(true) }}><span className="material-icons">send</span>Select Template</button>
                            </div>
                  
                </div>

                <div className="section mt-10">
                    <p className="field_title bold mt-10">POST OP INTERVENTIONS</p>
                    <p className="note2 select_note">Select all that apply for Multiselect forms</p>
                    <div className="check_list w_white mb-0">
                        <span className="check_btn2"><span className="material-icons"> done </span></span>
                        <div className="check_item"><label className="check">Removal of epidural catheter</label> </div>
                        <div className="check_item"><label className="check">Removal of chest drains </label> </div>
                        <div className="check_item"><label className="check">Suture removal/Removal of clips</label> </div>
                        <div className="check_item"><label className="check">Enhanced recovery </label> </div>
                    </div>

                    <div className="form_group">
                        <button className="border_btn" onClick={() => { setTemplate(true) }}><span className="material-icons">send</span>Select Template</button>
                            </div>
                  
                </div>

                <div className="section mt-10">
                    <p className="field_title bold mt-10">ORTHOPAEDIC INTERVENTIONS</p>
                    <p className="note2 select_note">Select all that apply for Multiselect forms</p>
                    <div className="check_list w_white mb-0">
                        <span className="check_btn2"><span className="material-icons"> done </span></span>
                        <div className="check_item"><label className="check">Aspiration</label> </div>
                        <div className="check_item"><label className="check">Block etc </label> </div>
                        <div className="check_item"><label className="check">Conscious sedation</label> </div>
                        <div className="check_item"><label className="check">Manipulation</label> </div>
                        <div className="check_item"><label className="check">Plaster cast application </label> </div>
                        <div className="check_item"><label className="check">Tractions, splints, collars etc</label> </div>
                        <div className="check_item"><label className="check">Knee Immobilizer </label> </div>
                        <div className="check_item"><label className="check">Shoulder immobilizer </label> </div>
                        <div className="check_item"><label className="check">Wrist splint/cast, elevation icepacks </label>
                        </div>
                    </div>

                    <div className="form_group">
                        <button className="border_btn" onClick={() => { setTemplate(true) }}><span className="material-icons">send</span>Select Template</button>
                            </div>
                  
                </div>

                <div className="section mt-10">
                    <p className="field_title bold mt-10">BED SIDE TESTS </p>
                    <p className="note2 select_note">Select all that apply for Multiselect forms</p>
                    <div className="check_list w_white mb-0">
                        <span className="check_btn2"><span className="material-icons"> done </span></span>
                        <div className="check_item"><label className="check">BM testing </label> </div>
                        <div className="check_item"><label className="check">Urine sample </label> </div>
                        <div className="check_item"><label className="check">Stool sample </label> </div>
                        <div className="check_item"><label className="check">Lateral flow test</label> </div>
                    </div>

                    <div className="form_group">
                        <button className="border_btn" onClick={() => { setTemplate(true) }}><span className="material-icons">send</span>Select Template</button>
                            </div>
                  
                </div>
                <div className="section ctr mt-20">
                    <h3 className="sub_title mb-10">Coding Support</h3>
                    <p className="ps1">&bull; ICD-10 / ICD -11 Support: The software supports ICD-10 / 11 codes for medical
                        classifications. Automatic coding suggestions: Make coding easier with an EHR that suggests
                        ICD-10/11, CPT and modifier codes for you. </p>
                    <p className="ps1">&bull; CPT support: The software supports Current Procedural Terminology (CPT) codes
                        for reporting medical procedures. </p>
                </div>
            </div>

            </div>


                    {template &&
                <div className="popup_wrp">
                    <div className="popup_full guidance">
                        <div className="popup_hed">
                            <h2>TEMPLATE </h2>
                            <div className="dsp-flex"> <a className="popup_close" onClick={() => { setTemplate(false) }}><span
                                className="material-icons">close</span></a></div>

                        </div>


                        <div className="popup_content modal-body">
                            <div className="popup_scroll w-btns main ">

                               
                            <div className="intrv_frm">
                        <div className="row fyb">
                            <div className="col-md-12">
                                <div className="template_frm">
                                    <h2 className="popup_title"> Selected Value </h2>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="search_dropdown_blk mb-0">
                                                <div className="form_group">
                                                    <label className="label">OPCS CODES</label>
                                                    <div className="floating-label-group mb-0">
                                                        <div className="search_dropdown">
                                                        <Select
                                                    
                                                            onChange={(e) => {
                                                                handleSelectGroup(e, "opcs")
                                                            }}
                                                            options={opcsoptionGroup}
                                                            classNamePrefix="select2-selection"
                                                        />
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="search_dropdown_blk mb-0">
                                                <div className="form_group">
                                                    <label className="label">TEMPLATES</label>
                                                    <div className="floating-label-group mb-0">
                                                        <div className="search_dropdown">
                                                        <Select
                                                    
                                                            onChange={(e) => {
                                                                handleSelectGroup(e, "template")
                                                            }}
                                                            options={template_optionGroup}
                                                            classNamePrefix="select2-selection"
                                                        />
                                                        
                                                           
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <h2 className="popup_title mb-0">TEMPLATE 1</h2>

                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="check_item mt-10">
                                                <label className="check">Universal precautions followed</label>
                                                <div className="select_btns popup_select">
                                                    <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 1" /><span>Hand washing
                                                            </span></label></div>
                                                    <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span> Gloves worn
                                                                Sharps disposal </span></label></div>
                                                    <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span>Alcohol
                                                                gel/Hand Cleanser </span></label></div>
                                                    <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span>Other
                                                            </span></label></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                   
                                    <h2 className="popup_title mb-10">ASEPTIC TECHNIQUE</h2>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <label className="label">PATIENT POSITION</label>
                                                <input type="text" className="form-control" placeholder="Enter" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <label className="label">SITE</label>
                                                <input type="text" className="form-control" placeholder="Enter" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <label className="label">MONITORING</label>
                                                <input type="text" className="form-control" placeholder="Enter" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <label className="label">DEVICE SIZE</label>
                                                <input type="text" className="form-control" placeholder="Enter" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <label className="label">LOCAL ANAESTHETIC</label>
                                                <input type="text" className="form-control" placeholder="Enter" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <label className="label">MEDICATION</label>
                                                <input type="text" className="form-control" placeholder="Enter" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <label className="label">VERIFICATION</label>
                                                <input type="text" className="form-control" placeholder="Enter" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <label className="label">COMPLICATIONS</label>
                                                <input type="text" className="form-control" placeholder="Enter" />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form_group">
                                                <label className="label">NOTES</label>
                                                <input type="text" className="form-control" placeholder="Enter" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <label className="label">OPERATOR NAME </label>
                                                <input type="text" className="form-control" placeholder="Enter" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="signature_block">
                                                <p>Signature</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                            </div>
                        </div>
                    </div>
                </div>
                    }






            </div>


        </React.Fragment>
    )
}

Interventions.propTypes = {
    t: PropTypes.any,
    getWards: PropTypes.func,
    getTheatres: PropTypes.func,
    wards: PropTypes.array,
    user: PropTypes.any,
    theatres: PropTypes.array,
    clinic: PropTypes.array,
    patients: PropTypes.array,
    doctors: PropTypes.array,
    profile: PropTypes.object
}

const mapStateToProps = ({ Layout, Login }) => ({
    wards: Layout.wards,
    user: Login.user,
    theatres: Layout.theatres,
    clinic: Layout.clinic,
    patients: Layout.patients,
    doctors: Layout.doctors,
    profile: Layout.profile
})

const mapDispatchToProps = dispatch => ({
    getWards: () => dispatch(getWardsList()),
    getTheatres: () => dispatch(getTheatresList()),
    getClinic: () => dispatch(getClinicList()),
    getPatient: () => dispatch(getPatientList()),
    getUserprofile: (id) => dispatch(getUprofile(id)),
    getDoctor: () => dispatch(getDoctorList()),
    patientupdate: (id, data) => dispatch(updatePatient(id, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Interventions)
