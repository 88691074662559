import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import MetaTags from 'react-meta-tags';
import SignaturePad from 'react-signature-canvas'
import PatientBlock from "../Clinic/patientBlock";
import { removeSpecialCharacters } from '../../utils/utils';
import { Buffer } from 'buffer';
import { connect } from "react-redux"
import { getWardsList, getTheatresList, getClinicList, getPatientList, getUprofile, getDoctorList, updatePatient } from "../../store/actions"

const AdvanceStatement = props => {

    let provider_sign = useRef(null);
    let responsibility_sign = useRef(null);
    let photograph_sign = useRef(null);

    const [file, setFile] = useState({});
    const [legalInfo, setLegalInfo] = useState(false)
    const [clinicValues, setClinic] = useState({})

    const [selectedGroup, setselectedGroup] = useState(null)
    const [popupname, setpopup_name] = useState({})
    const [anydelay, setDelay] = useState(false)

    const {
        wards, user, theatres, clinic, patients, profile, doctors
    } = props;

    useEffect(() => {
        const { getWards, getTheatres, getClinic, getDoctor, getPatient, getUserprofile } = props
        // getWards()
        // getTheatres()
        // getClinic()
        getPatient()
        getDoctor()
        let userId = localStorage.getItem("authUser")
        getUserprofile(userId)
    }, [])


    useEffect(() => {
        let clinicPatient = JSON.parse(localStorage.getItem("p"))
        if (patients && patients.length > 0 && clinicPatient && clinicPatient._id) {
            const cPatient = patients.filter(
                usr => usr._id === clinicPatient._id
            )
            if (cPatient && cPatient[0]) {
                let cpatientData = cPatient[0]
                delete cpatientData["_id"]
                setClinic(clinicValues => ({
                    ...clinicValues,
                    ...cpatientData
                }));
            }

        }
    }, [patients])


    const updateInputValue = (e) => {
        let val = e.target.value;
        let name = e.target.name;
        let updatedValue = {};
        updatedValue = { [name]: val };
        setLegalInfo(legalInfo => ({
            ...legalInfo,
            ...updatedValue
        }));

    }

    const updateInputValues = (name, popupname, val) => {
        //  e.preventDefault()
        // let updatedValue = {
        //    [popupname]:{[name] : val}
        // };
        let updatedValue = clinicValues;
        //updatedValue = { [name]: val };
        // updatedValue[popupname][name] = val;
        if (updatedValue[popupname]) {
            updatedValue[popupname][name] = val;
        } else {
            updatedValue = {
                [popupname]: { [name]: val }

            };
        }
        console.log("updatedValue 2", updatedValue)
        setClinic(clinicValues => ({
            ...clinicValues,
            ...updatedValue
        }));
    }

    const updateClinic = (e) => {
        const { patientupdate } = props
        let clinicPatient = JSON.parse(localStorage.getItem("p"))
        let id = clinicPatient._id
        patientupdate(id, clinicValues)
        let patient = {
            ...clinicPatient,
            ...clinicValues
        }
        localStorage.setItem("p", JSON.stringify(patient))



    }

    const updateSwitchValue = (name, value) => {
        let updatedValue = {};
        updatedValue = { [name]: value };
        setLegalInfo(legalInfo => ({
            ...legalInfo,
            ...updatedValue
        }));

    }

    const handleChange = (event) => {
        updateSwitchValue(event.target.name, event.target.checked)
    }


    const clear = (e, refvalue) => {
        e.preventDefault()
        refvalue.clear()
    }
    const trim = (e, type, refvalue) => {
        e.preventDefault()
        sigUpload(refvalue.getCanvas().toDataURL('image/png'), type)
    }

    const sigUpload = async (filedata, name) => {
        const file = filedata
        //console.log("file ", file, name)
        let filename = name + ".png";
        // const base64 = await convertBase64(file)
        //console.log("filename ", filename)
        let pdetails = JSON.parse(localStorage.getItem("p"))

        const dataf = file.replace(/^data:image\/\w+;base64,/, "");

        const buf = Buffer.from(dataf, "base64");
        let uploadFilePath = "ipr/" + pdetails._id + "/" + name + "_result" + "/" + removeSpecialCharacters(filename);
        let data = { source_bucket: "ipr-resources", sourcepath: uploadFilePath }
        // console.log("data ", data)

        const response = await fetch(API_URL+'upload?appname=ipr', {
            method: "POST",
            mode: "cors",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
        const result = await response.json();
        // console.log("result ", result, buf.length)
        let url = result.result
        // console.log("url ", url)
        const base64Data = new Buffer.from(filedata.replace(/^data:image\/\w+;base64,/, ""), 'base64');
        // Getting the file type, ie: jpeg, png or gif
        const type = filedata.split(';')[0].split('/')[1];
        const uploadImage = await fetch(url, {
            method: "PUT",
            mode: "cors",
            headers: {
                'Content-Type': `image/${type}`,
                'Content-Encoding': 'base64',
                ACL: 'public-read',
            },
            body: base64Data
        })

        //console.log("resultImage ", uploadImage)

        let imagePath = "https://ipr-resources.s3.amazonaws.com/"
        let imageUploadPath = imagePath + uploadFilePath;


        let updateObj = {
            [name + "_result"]: imageUploadPath,
            [name + "_update"]: new Date()
        }

        setFile(file => ({
            ...file,
            ...updateObj
        }));
        setLegalInfo(legalInfo => ({
            ...legalInfo,
            ...updateObj
        }));

        // let patient = {
        //     ...pdetails,
        //     ...updateObj
        // }
        // localStorage.setItem("p", JSON.stringify(patient))

    };

    const handleFile = async (e, name) => {

        const file = e.target.files[0]
        // console.log("file ", file, name)
        let filename = e.target.files[0].name;
        // const base64 = await convertBase64(file)
        //console.log("filename ", filename)
        let pdetails = JSON.parse(localStorage.getItem("p"))
        // var reader = new FileReader();
        //   reader.readAsDataURL(file);
        // reader.onload = async function (e) {
        // console.log("e ", e)
        let uploadFilePath = "ipr/" + pdetails._id + "/" + name + "_result" + "/" + removeSpecialCharacters(filename);
        let data = { source_bucket: "ipr-resources", sourcepath: uploadFilePath }
        // console.log("data ", data)

        const response = await fetch(API_URL+'upload?appname=ipr', {
            method: "POST",
            mode: "cors",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
        const result = await response.json();

        // console.log("result ", result)
        let url = result.result
        // console.log("url ", url)
        const uploadImage = await fetch(url, {
            method: "PUT",
            mode: "cors",
            headers: {
                "Content-Type": "multipart/form-data",
                "Accept": "/",
                "Cache-Control": "no-cache",
                "Accept-Encoding": "gzip, deflate",
                "Connection": "keep-alive",
                "cache-control": "no-cache"
            },
            body: file
        })

        // console.log("resultImage ", uploadImage)

        let imagePath = "https://ipr-resources.s3.amazonaws.com/"
        let imageUploadPath = imagePath + uploadFilePath;

        let updateObj = {
            [name + "_result"]: imageUploadPath,
            [name + "_update"]: new Date()
        }
        // patientupdate(pdetails._id, updateObj)
        setFile(file => ({
            ...file,
            ...updateObj
        }));
        setLegalInfo(legalInfo => ({
            ...legalInfo,
            ...updateObj
        }));



    };

    const wardBack = (e) => {
        e.preventDefault()
        props.history.push("/wl")
        document.body.style.overflow = '';
    }

    let clinicPatient = JSON.parse(localStorage.getItem("p"))

    // console.log(" legalInfo ", legalInfo)

    return (
        <React.Fragment>

            <MetaTags>
                <title>ADVANCE STATEMENT GUIDANCE</title>
            </MetaTags>
            <div className='ward '>
                <div className="popup_wrp">
                    <div className="popup_full prescribe">
                        <div className="popup_hed">
                            <h2>ADVANCE STATEMENT GUIDANCE</h2>
                            <div className="dsp-flex"> <a className="popup_close" onClick={(e) => { wardBack(e) }}><span className="material-icons">close</span></a></div>

                        </div>
                        <div className="popup_content">
                            <div className="popup_scroll w-btns main ">

                                <div className="section ctr">
                                    <p className="qt"> What is an Advance Statement?</p>
                                    <p className="at"> An advance statement is a document that you write when you are feeling
                                        mentally well. In the document you describe what you want to happen if you become
                                        unwell.</p>
                                    <p className="qt"> What can an Advance Statement do for me?</p>
                                    <p className="at mb-0"> An Advance Statement can:</p>
                                    <ul>
                                        <li>let staff know what care/medications work for you and which ones don’t work</li>
                                        <li>let staff know about any physical health needs or disabilities</li>
                                        <li>help things run smoothly while youíre ill e.g. benefits, bills, work, children, pets
                                        </li>
                                    </ul>
                                    <p className="qt"> What can’t an Advance Statement do for me?</p>
                                    <p className="at mb-0"> An advance statement cannot:</p>
                                    <ul>
                                        <li>legally ensure you get your preferred treatment</li>
                                        <li>stop you being sectioned under the Mental Health Act</li>
                                        <li>stop you being treated against your will if you are sectioned under the Mental
                                            Health Act.</li>
                                    </ul>
                                    <p className="qt"> About completing your advance statement</p>
                                    <p className="at mb-0"> It is best to get a doctor or Care Coordinator to help you with your
                                        advance statement so that:</p>
                                    <ul>
                                        <li>they can give you advice and information about available treatments</li>
                                        <li>you can agree care and treatment with them in advance</li>
                                        <li>they can ensure your advance statement is recorded on Leeds and York Partnership
                                            Foundation Trust’s systems and accessed by staff when needed</li>
                                        <li>you can agree with you how and when to review your advance statement</li>
                                        <li>your advance statement should be easy to access in a crisis. It should be clearly
                                            labelled and you should give a copy to any close friends and family members who
                                            might support you in a crisis.</li>
                                    </ul>
                                    <h2 className="popup_title">You should review your advance statement at least every year.</h2>
                                    <p className="qt"> Advance Decisions to Refuse Treatment</p>
                                    <p className="at"> You can also create a legally binding document when you are mentally capable
                                        to refuse specific medical treatment, for a time in the future when you may lack mental
                                        capacity to consent or refuse that treatment. This is called an Advance Decision. An
                                        Advance Decision can refuse treatment for a physical or mental issue. A refusal of
                                        treatment for mental disorder can be overruled by the Mental Health Act. Before creating
                                        an Advance Decision you should discuss it with your psychiatrist or GP. You may also
                                        wish to seek legal advice.</p>
                                    <h2 className="popup_title">This form is meant to be a help, so you should only fill in those
                                        parts that you wish to.</h2>
                                </div>
                                <div className="section ctr">
                                    <h2 className="popup_title"> Advance Statement Guidance Example of an Advance Statement</h2>
                                    <p className="qt"> Example of an Advance Statement</p>

                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form_group">
                                                <label className="label"> Name</label>
                                                <input type="text" className="form-control" placeholder="Enter" />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form_group">
                                                <label className="label"> Address </label>
                                                <input type="text" className="form-control" placeholder="Enter" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <label className="label"> Email </label>
                                                <input type="text" className="form-control" placeholder="Enter" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <label className="label"> Phone number </label>
                                                <input type="text" className="form-control" placeholder="Enter" />
                                            </div>

                                        </div>

                                    </div>
                                    <h2 className="popup_title">I am willing / NOT willing for this document to be entered onto the
                                        records of the Leeds and York Partnership NHS Foundation Trust.</h2>
                                    <div className="form_group">
                                        <label className="label"> List below who will hold a copy of this document or where else it
                                            can be found:</label>
                                        <textarea className="form-control"></textarea>
                                    </div>
                                </div>
                                <div className="section ctr">
                                    <h2 className="popup_title"> Care and Treatment</h2>
                                    <p className="sub_title mb-10"> Prevention</p>
                                    <p className="qt"> a) The signs of me becoming ill are</p>
                                    <p className="at"> Not sleeping/sleeping too much; loss/increase in appetite. Wanting to
                                        withdraw from people, crying</p>
                                    <p className="qt"> b) How people can help me during this time</p>
                                    <p className="at"> Being more supportive, emphasising my strengths. don’t patronise me.</p>
                                    <p className="sub_title mb-10"> Care and Treatment</p>
                                    <p className="qt"> a) My preferences about medical treatment and concerns are:</p>
                                    <p className="at"> No medication or, and an increase in medication. I want to be spoken to not
                                        isolated.</p>
                                    <p className="qt"> b) In the past, the following has worked well for me:</p>
                                    <p className="at"> Increasing my anti depressants, having Psychology input. Having friends and
                                        family to support me</p>
                                    <p className="qt"> c) In the past, the following has NOT worked well for me:</p>
                                    <p className="at"> Mood stabilisers as I gain weight. Not having someone to talk to</p>
                                    <p className="sub_title mb-10"> Personal and Social Statement</p>
                                    <p className="qt"> 1) If I become unwell or am admitted to hospital I WOULD like the following
                                        people to be informed:</p>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <label className="label"> Name</label>
                                                <input type="text" className="form-control" placeholder="Enter" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <label className="label"> Phone number(s) </label>
                                                <input type="text" className="form-control" placeholder="Enter" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <label className="label"> Email address </label>
                                                <input type="text" className="form-control" placeholder="Enter" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <label className="label"> Address </label>
                                                <input type="text" className="form-control" placeholder="Enter" />
                                            </div>
                                        </div>
                                    </div>
                                    <p className="qt">2) If I become unwell or am admitted to hospital I WOULD NOT like the
                                        following people to be informed:</p>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <label className="label"> Name(s) </label>
                                                <input type="text" className="form-control" placeholder="Enter" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <label className="label"> Relationship to me</label>
                                                <input type="text" className="form-control" placeholder="Enter" />
                                            </div>
                                        </div>
                                    </div>
                                    <p className="qt">3) I would like to name the following person to act as an advocate for staff
                                        to consult with if I am unwell:</p>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form_group">
                                                <label className="label"> Enter </label>
                                                <input type="text" className="form-control" placeholder="Enter" />
                                            </div>
                                        </div>
                                    </div>
                                    <p className="qt">4) My wishes about the people I care for are:</p>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form_group">
                                                <label className="label"> Enter </label>
                                                <input type="text" className="form-control" placeholder="Enter" />
                                            </div>
                                        </div>
                                    </div>
                                    <p className="qt">5) My wishes about my pets are:</p>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form_group">
                                                <label className="label"> Enter </label>
                                                <input type="text" className="form-control" placeholder="Enter" />
                                            </div>
                                        </div>
                                    </div>
                                    <p className="qt">6) My wishes about my housing / home care are:</p>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form_group">
                                                <label className="label"> I need help keeping the house clean as well as having all
                                                    my repairs dealt with. </label>
                                                <input type="text" className="form-control" placeholder="Enter" />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form_group">
                                                <label className="label"> I would like help with cooking too </label>
                                                <input type="text" className="form-control" placeholder="Enter" />
                                            </div>
                                        </div>
                                    </div>
                                    <p className="qt">7) My wishes about financial matters are:</p>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form_group">
                                                <label className="label"> Enter </label>
                                                <input type="text" className="form-control" placeholder="Enter" />
                                            </div>
                                        </div>
                                    </div>
                                    <p className="qt">8) My other wishes, not covered above, are:</p>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form_group">
                                                <label className="label"> Enter </label>
                                                <input type="text" className="form-control" placeholder="Enter" />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="section ctr">
                                    <h2 className="popup_title"> Declaration</h2>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form_group">
                                                <label className="label"> I, declare that this document has been completed by me or
                                                    by someone in accordance with my wishes. </label>
                                                <input type="text" className="form-control" placeholder="Enter Name" />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form_group">
                                                <label className="label"> Date</label>
                                                <input type="text" className="form-control" placeholder="Enter" />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form_group">
                                                <label className="label"> In completing this advanced statement I have discussed
                                                    this with</label>
                                                <input type="text" className="form-control" placeholder="Enter" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <p className="popup_title mb-0 mt-15">Copies have been sent to</p>
                                        </div>
                                        <div className="col-md-6 copy_btns">

                                            <button className="border_btn"><span
                                                className="material-icons">send</span>Psychiatrist/GP/partner/friend </button>

                                        </div>

                                    </div>
                                    <p className="at mt-20"> If I become incapable of expressing my choices due to mental health
                                        problems, I want this document to be referred to as an expression of my choices</p>
                                    <p className="at">It is my understanding that this document will be followed wherever possible
                                        and that if my choices are not followed that I will be provided with a valid explanation
                                        of why this happened.</p>
                                </div>
                            </div>
                            <div className="popup_footer">
                                <div className="prescribe_footer">
                                    <div className="dsp-flex">
                                        <a className="icon_btn" onClick={(e) => { wardBack(e) }}> <span className="material-icons">save</span> SAVE</a>
                                        <button className="border_btn" onClick={(e) => { wardBack(e) }}><span className="material-icons">save_as</span>SAVE &amp;
                                            CLOSE
                                        </button>
                                    </div>

                                    <button className="btn dsp_flex" onClick={(e) => { wardBack(e) }}><span className="material-icons"><span className="material-icons">close</span></span>DISCARD </button>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>


        </React.Fragment>
    )
}

AdvanceStatement.propTypes = {
    t: PropTypes.any,
    getWards: PropTypes.func,
    getTheatres: PropTypes.func,
    wards: PropTypes.array,
    user: PropTypes.any,
    theatres: PropTypes.array,
    clinic: PropTypes.array,
    patients: PropTypes.array,
    doctors: PropTypes.array,
    profile: PropTypes.object
}

const mapStateToProps = ({ Layout, Login }) => ({
    wards: Layout.wards,
    user: Login.user,
    theatres: Layout.theatres,
    clinic: Layout.clinic,
    patients: Layout.patients,
    doctors: Layout.doctors,
    profile: Layout.profile
})

const mapDispatchToProps = dispatch => ({
    getWards: () => dispatch(getWardsList()),
    getTheatres: () => dispatch(getTheatresList()),
    getClinic: () => dispatch(getClinicList()),
    getPatient: () => dispatch(getPatientList()),
    getUserprofile: (id) => dispatch(getUprofile(id)),
    getDoctor: () => dispatch(getDoctorList()),
    patientupdate: (id, data) => dispatch(updatePatient(id, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(AdvanceStatement)
