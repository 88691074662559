import  { useState, useEffect } from "react";
import styles from "./index.module.scss";
import { useLocation, useHistory } from "react-router-dom";
import { IconLeftAngleCovered, IconViewArrow } from "../../../icons/index.jsx";
import pdf from "../../../../assets/images/pdf.png";
import axios from "../../../../configuration/axios.js";
import config from "../../../../configuration/config.js";

const CategoryDocument = () => {
  const location = useLocation();
  const history = useHistory();

  const { id: categoryId, name: categoryName } = location.state || {};

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredDocuments, setFilteredDocuments] = useState([]);

  const getSubCategory = async () => {
    try {
      const response = await axios.get(config.sub_category+ "?pageNumber=1&limit=20&appname=ipr", {
        params: {
          category_id: categoryId,
        },
      });
      console.log("response ", response)
      return response?.data?.result?.data;
    } catch (error) {
      console.error("Error fetching sub category:", error);
      throw error; // Rethrow the error to handle it elsewhere
    }
  };

  useEffect(() => {
    const fetchSubCategory = async () => {
      try {
        const documents = await getSubCategory();
        console.log("documents ", documents)
        const filtered = documents.filter((document) =>
          document.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredDocuments(filtered);
      } catch (error) {
        console.error("Error fetching sub category:", error);
        // Handle error gracefully, maybe show a message to the user
      }
    };

    fetchSubCategory();
  }, [searchTerm]);

  const viewDocument = (item) => {
    history.push({
      pathname: "/categorydocumentdetails",
      state: {
        docId: item.id,
        docName: item.name,
        pdf: item.file_url,
        categoryId: categoryId,
        categoryName: categoryName,
        subCategoryName: item.name,
      },
    });
  };
  

  const backToCategories = () => {
     history.push("/category");
  };

  return (
    <div className={styles["page-container"]}>
      <div className={styles["page-main-header"]}>
        <div className={styles["header-left"]}>
          <div className={styles["category-content"]}>
            <span className={styles["category-back"]} onClick={backToCategories}>
              <IconLeftAngleCovered />
            </span>
            <span className={styles["category-name"]}>
              {categoryName && categoryName
                .toLowerCase()
                .split(" ")
                .map((word) => word && word.charAt(0) ? word.charAt(0).toUpperCase() + word.slice(1): "")
                .join(" ")}
            </span>
          </div>
        </div>
        <div className={styles["header-right"]}>
          <input
            type="text"
            placeholder="Search topics here"
            className={styles["search-box"]}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
        
          />
        </div>
      </div>

      <div className={styles["page-content"]}>
        <div className={styles["page-content-documents"]}>
          {filteredDocuments.length === 0 ? (
            <div className={styles["no-files"]}>No files found</div>
          ) : (
            filteredDocuments.map((item) => (
              <div className={styles["item-wrapper"]} key={item._id}>
                <div className={styles["item-name"]}>
                  <img src={pdf} alt="/" /> {item.name}
                </div>
                <span className={styles["view-arrow"]}>
                  <IconViewArrow />
                </span>
                <button
                  className={styles["view-button"]}
                  onClick={() => viewDocument(item)}
                >
                  View
                  <IconViewArrow iconColor="#ffffff" iconSize="14" />
                </button>
              </div>
            ))
          )}
        </div>
      </div>
      
    </div>
  );
};

export default CategoryDocument;
