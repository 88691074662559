import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
// import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { getTheatresList } from "../../store/actions"
// import Select from "react-select"
import { Link } from "react-router-dom"

const TheatreSubHeader = (props) => {

    // const [selectedGroup, setselectedGroup] = useState(null)
    const [selectedTheatre, setSelectedTheatre] = useState({})
    const {
        theatres
    } = props;
   

    useEffect(() => {
        const { getTheatres } = props
        getTheatres()
        let theatreSelected = JSON.parse(localStorage.getItem("t"))
        setSelectedTheatre(theatreSelected)
    }, [])


    const theatreChange = (e) => {
        e.preventDefault()
        // console.log("e", e.target.value)
        let selectedTheatre = theatres.filter(
            theatre => theatre._id == e.target.value
        )
        //console.log("selectedTheatre", selectedTheatre)
        localStorage.setItem("t", JSON.stringify(selectedTheatre[0]))
        setSelectedTheatre(selectedTheatre[0])
    }

    // const optionGroup = [
    //     { label: "Mustard", value: "Mustard" },
    //     { label: "Ketchup", value: "Ketchup" },
    //     { label: "Relish", value: "Relish" }
    // ]

    // const handleSelectGroup = (selectedGroup) => {
    //     setselectedGroup(selectedGroup)
    // }

  // console.log("selectedTheatre ", selectedTheatre)

    return (
        <React.Fragment>

            <div className="dashboard theatre">
                <div className="sub_header">
                    <div className="wrapper wrp">
                        <div className="dsp_flex">
                            <div className="orth_select">
                                {/* <label>Orthopaedic</label> */}
                                {/* <Select
                                    value={selectedGroup}
                                    onChange={() => {
                                        handleMulti()
                                    }}
                                    options={optionGroup}
                                    classNamePrefix="select2-selection"
                                /> */}

                                <select name="theatre" className="form-control" onChange={(e) => theatreChange(e)} >

                                    {theatres && theatres.length > 0 &&
                                        theatres.map((item, i) => (
                                            <option value={item._id} key={i}>{item.name}</option>

                                        ))}

                                </select>
                            </div>
                            <Link to={"/"+props.backPage} className="icon_link"> <span className="material-icons">
                                arrow_back_ios
                            </span> Back</Link>
                            
                        </div>
                    </div>
                </div>
              

            </div>

        </React.Fragment>
    )
}

TheatreSubHeader.propTypes = {
    theatres: PropTypes.array
}

const mapStateToProps = ({ Layout, Login }) => ({
    theatres: Layout.theatres
})

const mapDispatchToProps = dispatch => ({
    getTheatres: () => dispatch(getTheatresList())
})

export default connect(mapStateToProps, mapDispatchToProps)(TheatreSubHeader)
