import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import MetaTags from 'react-meta-tags';
import { Link, useHistory } from "react-router-dom"

import { connect } from "react-redux"

import Select from "react-select"


import { getWardsList, getTheatresList, getClinicList, getPatientList, getUprofile, getDoctorList, updatePatient, getNews, updateDailyRounds, getDailyRounds, teamListUpdate, gettableList  } from "../../store/actions"

const MyPreferences = props => {
    const history = useHistory();
    const [roles, setRoles] = useState([])
    const [locations, setLocations] = useState([])
    const [specialities, setSpeciality] = useState([])
    const [wards, setWards] = useState([])
    const [clinics, setClinic] = useState([])
    const [theatres, setTheatre] = useState([])
    const [nursing, setNursing] = useState([])
    const [oncallList, setoncallList] = useState([])
    const [flows, setFlowManagement] = useState([])
    const [consultants, setConsultants] = useState([])
    const [hospitals, setHospital] = useState([])
  
    
    const {
         user, clinic, patients, profile, doctors, tablesData
    } = props;

    useEffect(() => {
        const { getLookups } = props
    
        getLookups({
          method: "Get"
        }, "lookups")
    
      }, [])

    // useEffect(() => {
    //     const { getWards, getTheatres, getClinic, getDoctor, getPatient, getUserprofile } = props

    //     getPatient()
    //     getDoctor()
    //     let userId = localStorage.getItem("authUser")
    //     getUserprofile(userId)
    // }, [])

    // useEffect(() => {
    //     let clinicPatient = JSON.parse(localStorage.getItem("p"))
    //     if (patients && patients.length > 0 && clinicPatient && clinicPatient._id) {
    //         const cPatient = patients.filter(
    //             usr => usr._id === clinicPatient._id
    //         )
    //         if (cPatient && cPatient[0]) {
    //             let cpatientData = cPatient[0]
    //             delete cpatientData["_id"]
    //             setClinic(clinicValues => ({
    //                 ...clinicValues,
    //                 ...cpatientData
    //             }));
    //         }

    //     }
    // }, [patients])

    useEffect(() => {
        $(".modal-backdrop").hide()
    }, [])
    
  useEffect(() => {

    if (tablesData && tablesData.length > 0) {
      const specialityGroup = typeGrouping(tablesData, 'type', 'speciality');
      setSpeciality(specialityGroup)
      const locationGroup = typeGrouping(tablesData, 'type', 'location');
      setLocations(locationGroup)
      const roleGroup = typeGrouping(tablesData, 'type', 'role');
      setRoles(roleGroup)
      const hospitalGroup = typeGrouping(tablesData, 'type', 'hospital');
      setHospital(hospitalGroup)

      const wardGroup = typeGrouping(tablesData, 'type', 'ward');
      setWards(wardGroup)
      const clinicGroup = typeGrouping(tablesData, 'type', 'clinic');
      setClinic(clinicGroup)
      const theatreGroup = typeGrouping(tablesData, 'type', 'theatre');
      setTheatre(theatreGroup)
      const oncallGroup = typeGrouping(tablesData, 'type', 'oncall');
      setoncallList(oncallGroup)
      const nursingGroup = typeGrouping(tablesData, 'type', 'nursing_office');
      setNursing(nursingGroup)
      const flowGroup = typeGrouping(tablesData, 'type', 'flow_management');
      setFlowManagement(flowGroup)
      const consultantGroup = typeGrouping(tablesData, 'type', 'consultant');
      setConsultants(consultantGroup)
    }


  }, [tablesData])

  const typeGrouping = (assets, field, value) => {
    let filterArray = []
    assets.filter((item, i) => {
      if (item[field] == value) {

        filterArray.push({ "label": item.value, "value": item.name })
      }
    }, []);
    return filterArray
  }

  const handleSelectGroup = (selectedGroup, name) => {
    // console.log("selectedGroup ", selectedGroup)
    let updatedValue = { [name]: selectedGroup };
    setUserValue(userValues => ({
        ...userValues,
        ...updatedValue
    }));
}

    const menuClick = (e, menu) => {
        history.push("/" + menu)
    }



    return (
        <React.Fragment>

            <MetaTags>
                <title>My Preferences</title>
            </MetaTags>
            <div className='ward '>
                <div className="main mo_list">

                    <div className="wrapper">
                        <div className="patient_record prd my_preferences">

                            <h2>MY PREFERANCES</h2>

                            <div class="signup_form">
                            <div class="signup_wrp">
                                <div className='pref_block'>
                                    <h4 className='form_subtitle'>Workflow PREFERENCES</h4>
                                    <p className='form_subtag'>Set a quick way to login</p>
                                    {/* <h3>Set preferences</h3> */}

                                    <div className="form-group">
                                        <label>My Speciality</label>
                                        <Select
                                            isMulti={false}
                                            onChange={(e) => {
                                                handleSelectGroup(e, "speciality")
                                            }}
                                            options={specialities}
                                            classNamePrefix="select2-selection"
                                        />


                                    </div>
                                    <div className="form-group mb-0">
                                        <label>User Role</label>
                                        <Select
                                            isMulti={false}
                                            onChange={(e) => {
                                                handleSelectGroup(e, "role")
                                            }}
                                            options={roles}
                                            classNamePrefix="select2-selection"
                                        />


                                    </div>
                                </div>
                                <div className='pref_block'>
                                    <h4 className='form_subtitle'>Preferred Site</h4>
                                    <div className="form-group mb-0">
                                        <label>Select Site</label>
                                        <Select
                                            isMulti={false}
                                            onChange={(e) => {
                                                handleSelectGroup(e, "default_hospital")
                                            }}
                                            options={hospitals}
                                            classNamePrefix="select2-selection"
                                        />
                                    </div>


                                </div>
                                <div className='pref_block'>
                                    <h4 className='form_subtitle mb-10'>Preferred LOCATION</h4>
                                    <div className="form-group">
                                        <label>WARD</label>
                                        <Select
                                            isMulti={false}
                                            onChange={(e) => {
                                                handleSelectGroup(e, "ward")
                                            }}
                                            options={wards}
                                            classNamePrefix="select2-selection"
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label>CLINIC</label>
                                        <Select
                                            isMulti={false}
                                            onChange={(e) => {
                                                handleSelectGroup(e, "clinic")
                                            }}
                                            options={clinics}
                                            classNamePrefix="select2-selection"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>THEATRE</label>
                                        <Select
                                            isMulti={false}
                                            onChange={(e) => {
                                                handleSelectGroup(e, "theatre")
                                            }}
                                            options={theatres}
                                            classNamePrefix="select2-selection"
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label>ON CALL / TEAM ROOM</label>
                                        <Select
                                            isMulti={false}
                                            onChange={(e) => {
                                                handleSelectGroup(e, "oncall")
                                            }}
                                            options={oncallList}
                                            classNamePrefix="select2-selection"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>CONSULTANT / REGISTRAR OFFICE</label>
                                        <Select
                                            isMulti={false}
                                            onChange={(e) => {
                                                handleSelectGroup(e, "consultant")
                                            }}
                                            options={consultants}
                                            classNamePrefix="select2-selection"
                                        />
                                    </div>
                                    {/* <div className="form-group">
                    <label>REGISTRAR OFFICE</label>
                    <Select
                      isMulti={true}
                      onChange={(e) => {
                        handleSelectGroup(e, "location")
                    }}
                      options={locations}
                      classNamePrefix="select2-selection"
                    />
                  </div> */}

                                    <div className="form-group">
                                        <label>NURSING OFFICE</label>
                                        <Select
                                            isMulti={false}
                                            onChange={(e) => {
                                                handleSelectGroup(e, "nursing")
                                            }}
                                            options={nursing}
                                            classNamePrefix="select2-selection"
                                        />
                                    </div>
                                    <div className="form-group  mb-0">
                                        <label>FLOW MANAGEMENT</label>
                                        <Select
                                            isMulti={false}
                                            onChange={(e) => {
                                                handleSelectGroup(e, "flowmanagement")
                                            }}
                                            options={flows}
                                            classNamePrefix="select2-selection"
                                        />
                                    </div>
                                </div>


                                <button className="btn-primary loginbtn mt-20" onClick={(e) => { submitUpdate(e) }} type="button"> Submit </button>
                                <br />





                                </div>
                            </div>

                        </div>
                    </div>



                </div>
            </div>




        </React.Fragment>
    )
}

MyPreferences.propTypes = {
    getWards: PropTypes.func,
    getTheatres: PropTypes.func,
    wards: PropTypes.array,
    user: PropTypes.any,
    theatres: PropTypes.array,
    clinic: PropTypes.array,
    patients: PropTypes.array,
    doctors: PropTypes.array,
    profile: PropTypes.object,
    news: PropTypes.array,
    getLookups:  PropTypes.func,
}

const mapStateToProps = ({ Layout, Login }) => ({
    wards: Layout.wards,
    user: Login.user,
    theatres: Layout.theatres,
    clinic: Layout.clinic,
    patients: Layout.patients,
    doctors: Layout.doctors,
    profile: Layout.profile,
    news: Layout.news,
    dailyrounds: Layout.dailyrounds,
    tablesData: Layout.tableData,
})

const mapDispatchToProps = dispatch => ({
    getWards: () => dispatch(getWardsList()),
    getTheatres: () => dispatch(getTheatresList()),
    getClinic: () => dispatch(getClinicList()),
    getPatient: () => dispatch(getPatientList()),
    getUserprofile: (id) => dispatch(getUprofile(id)),
    getDoctor: () => dispatch(getDoctorList()),
    patientupdate: (id, data) => dispatch(updatePatient(id, data)),
    newsList: (data) => dispatch(getNews(data)),
    dailyRoundsList: (data) => dispatch(getDailyRounds(data)),
    dailyUpdate: (data) => dispatch(updateDailyRounds(data)),
    getLookups: (data, tablename) => dispatch(gettableList(data, tablename))
})

export default connect(mapStateToProps, mapDispatchToProps)(MyPreferences)
