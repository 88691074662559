import React from "react"
import MetaTags from 'react-meta-tags';
import Patient2 from '../../assets/images/patient.png';

const Theatre = () => {
    return (
        <React.Fragment>

            <MetaTags>
                <title>Theatre</title>
            </MetaTags>
            <div className="dashboard">

                <div className="main">
                    <div className="wrapper">
                        <div className="theatre_landing">
                            <div className="sub_menus">
                                <h3>Session Details</h3>
                                <div className="items">
                                    <a href="#" className="item">Session Team</a>
                                    <a href="#" className="item">Team Brief / Debrief</a>
                                    <a href="#" className="item">Theatre Flow Tracker</a>
                                </div>
                            </div>
                            <div className="sub_menus">
                                <h3>Reports</h3>
                                <div className="items">
                                    <a href="#" className="item">Theatre Productivity Report</a>
                                    <a href="#" className="item">Theatre Finance Report</a>
                                    <a href="#" className="item">Patient Quality Report</a>
                                </div>
                            </div>
                            <div className="patient_card">
                                <div className="arrows">
                                    <a href="#" className="active"><span className="material-icons">arrow_left</span></a>
                                    <a href="#"><span className="material-icons">arrow_right</span></a>
                                </div>
                                <div className="patient_pic">
                                    <img src={Patient2} />
                                </div>
                                <div className="details_l">
                                    <h3 className="title">Finley Goldsmith <span className="badge">MALE</span></h3>
                                    <p className="text"> <strong> 82 Yrs</strong> Aug 20th 1940</p>
                                    <p className="text">  NHS: <strong>112255689</strong></p>
                                </div>
                                <div className="details_r">
                                    <p className="text"> <span className="material-icons-outlined">apartment</span><strong> Queen Victoria Hospital</strong></p>
                                    <p className="text"> <span className="material-icons-outlined">local_hotel</span><strong>  Ward 1</strong>, Bay Bed 2</p>
                                    <p className="text"><span className="material-icons-outlined">person_outline</span><strong>K.Vivek Panikker</strong><span className="badge">Tr. & Ortho Surgeon</span></p>
                                </div>
                            </div>
                            <h2 className="heading">Patient Summary & Preop Assessment</h2>
                            <div className="tab_menu">
                                <div className="item red"> <div className="point done"><span className="material-icons">check_circle</span></div> Perioperative Rec</div>
                                <div className="item violet"> <div className="point pending"><span className="material-icons">rotate_right</span></div> Nursing Rec</div>
                                <div className="item blue"> <div className="point "></div> Recovery Rec</div>
                                <div className="item green"> <div className="point "></div> Anaesthetic Rec</div>
                                <div className="item orange"> <div className="point "></div> Surgical Rec</div>
                            </div>
                            <div className="sections_block records">
                                <div className="records_section">
                                    <div className="records_type red">
                                        <div className="records_head">
                                            Perioperative Record
                                            <a href="#" className="record_status">IN PROGRESS</a>
                                        </div>
                                        <div className="records_body">
                                            <a href="#" className="item"> <span className="material-icons arrow">arrow_right</span>
                                                <span className="text"> Machine / Equipment Checks</span>  <div className="item_status">
                                                    <span className="material-icons">done</span></div>
                                            </a>
                                            <a href="#" className="item"> <span className="material-icons arrow">arrow_right</span>
                                                <span className="text"> Time in</span>  <div className="item_status">
                                                    <span className="material-icons">rotate_right</span></div>
                                            </a>
                                            <a href="#" className="item"> <span className="material-icons arrow">arrow_right</span>
                                                <span className="text"> Patient details </span>
                                                <div className="item_status"> </div>
                                            </a>
                                            <a href="#" className="item"> <span className="material-icons arrow">arrow_right</span>
                                                <span className="text"> Preoperative checklist</span>
                                                <div className="item_status"> </div>
                                            </a>
                                            <a href="#" className="item"> <span className="material-icons arrow">arrow_right</span>
                                                <span className="text"> ODP check in </span>
                                                <div className="item_status"> </div>
                                            </a>
                                            <a href="#" className="item"> <span className="material-icons arrow">arrow_right</span>
                                                <span className="text"> Start of anaesthesia</span>
                                                <div className="item_status"> </div>
                                            </a>
                                            <a href="#" className="item"> <span className="material-icons arrow">arrow_right</span>
                                                <span className="text"> Time into theatre</span>
                                                <div className="item_status"> </div>
                                            </a>
                                            <a href="#" className="item"> <span className="material-icons arrow">arrow_right</span>
                                                <span className="text"> Nurses check in </span>
                                                <div className="item_status"> </div>
                                            </a>
                                            <a href="#" className="item"> <span className="material-icons arrow">arrow_right</span>
                                                <span className="text"> Time out document/ WHO checklist</span>
                                                <div className="item_status"> </div>
                                            </a>
                                            <a href="#" className="item"> <span className="material-icons arrow">arrow_right</span>
                                                <span className="text"> Commence procedure</span>
                                                <div className="item_status"> </div>
                                            </a>
                                            <a href="#" className="item"> <span className="material-icons arrow">arrow_right</span>
                                                <span className="text"> End of procedure</span>
                                                <div className="item_status"> </div>
                                            </a>
                                            <a href="#" className="item"> <span className="material-icons arrow">arrow_right</span>
                                                <span className="text"> Tourniquet time</span>
                                                <div className="item_status"> </div>
                                            </a>
                                            <a href="#" className="item"> <span className="material-icons arrow">arrow_right</span>
                                                <span className="text"> Swab count</span>
                                                <div className="item_status"> </div>
                                            </a>
                                            <a href="#" className="item"> <span className="material-icons arrow">arrow_right</span>
                                                <span className="text"> Nurses check out </span>
                                                <div className="item_status"> </div>
                                            </a>
                                            <a href="#" className="item"> <span className="material-icons arrow">arrow_right</span>
                                                <span className="text"> ODP check out</span>
                                                <div className="item_status"> </div>
                                            </a>
                                            <a href="#" className="item"> <span className="material-icons arrow">arrow_right</span>
                                                <span className="text"> Time left theatre</span>
                                                <div className="item_status"> </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="records_type violet">
                                        <div className="records_head">
                                            Perioperative Record
                                            <a href="#" className="record_status">IN PROGRESS</a>
                                        </div>
                                        <div className="records_body">
                                            <a href="#" className="item"> <span className="material-icons arrow">arrow_right</span>
                                                <span className="text"> Advance prep. / Preferences </span>  <div className="item_status">
                                                    <span className="material-icons">done</span></div>
                                            </a>
                                            <a href="#" className="item"> <span className="material-icons arrow">arrow_right</span>
                                                <span className="text"> Nursing record</span>  <div className="item_status">
                                                    <span className="material-icons">rotate_right</span></div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="records_type blue">
                                        <div className="records_head">
                                            Perioperative Record
                                            <a href="#" className="record_status">IN PROGRESS</a>
                                        </div>
                                        <div className="records_body">
                                            <a href="#" className="item"> <span className="material-icons arrow">arrow_right</span>
                                                <span className="text"> Recovery handover</span>  <div className="item_status">
                                                    <span className="material-icons">done</span></div>
                                            </a>
                                            <a href="#" className="item"> <span className="material-icons arrow">arrow_right</span>
                                                <span className="text"> Recovery to ward handover</span>  <div className="item_status">
                                                    <span className="material-icons">rotate_right</span></div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="records_type green">
                                        <div className="records_head">
                                            Perioperative Record
                                            <a href="#" className="record_status">IN PROGRESS</a>
                                        </div>
                                        <div className="records_body">
                                            <a href="#" className="item"> <span className="material-icons arrow">arrow_right</span>
                                                <span className="text"> Pre Anaesthetic Assessment</span>  <div className="item_status">
                                                    <span className="material-icons">done</span></div>
                                            </a>
                                            <a href="#" className="item"> <span className="material-icons arrow">arrow_right</span>
                                                <span className="text"> Anaesthetic Record</span>  <div className="item_status">
                                                    <span className="material-icons">rotate_right</span></div>
                                            </a>
                                            <a href="#" className="item"> <span className="material-icons arrow">arrow_right</span>
                                                <span className="text"> Close case</span>  <div className="item_status">
                                                    <span className="material-icons">rotate_right</span></div>
                                            </a>
                                            <a href="#" className="item"> <span className="material-icons arrow">arrow_right</span>
                                                <span className="text"> Log book</span>  <div className="item_status">
                                                    <span className="material-icons">rotate_right</span></div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="records_type orange">
                                        <div className="records_head">
                                            Perioperative Record
                                            <a href="#" className="record_status">IN PROGRESS</a>
                                        </div>
                                        <div className="records_body">
                                            <a href="#" className="item"> <span className="material-icons arrow">arrow_right</span>
                                                <span className="text"> Informed consent form </span>  <div className="item_status">
                                                    <span className="material-icons">done</span></div>
                                            </a>
                                            <a href="#" className="item"> <span className="material-icons arrow">arrow_right</span>
                                                <span className="text"> VTE risk assessment</span>  <div className="item_status">
                                                    <span className="material-icons">rotate_right</span></div>
                                            </a>
                                            <a href="#" className="item"> <span className="material-icons arrow">arrow_right</span>
                                                <span className="text"> Op notes templates </span>  <div className="item_status"></div>
                                            </a>
                                            <a href="#" className="item"> <span className="material-icons arrow">arrow_right</span>
                                                <span className="text">NJR</span>  <div className="item_status"></div>
                                            </a>
                                            <a href="#" className="item"> <span className="material-icons arrow">arrow_right</span>
                                                <span className="text"> Coding tool</span>  <div className="item_status"></div>
                                            </a>
                                            <a href="#" className="item"> <span className="material-icons arrow">arrow_right</span>
                                                <span className="text">Log book</span>  <div className="item_status"></div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <a href="#" className="record_button"><span className="material-icons">article</span>Patient Record</a>
                            </div>
                        </div>

                    </div>
                </div>
               
            </div>

        </React.Fragment>
    )
}

export default Theatre
