import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import MetaTags from 'react-meta-tags';


import PatientBlock from "../Clinic/patientBlock";
import { connect } from "react-redux"
import { getWardsList, getTheatresList, getClinicList, getPatientList, getUprofile, getDoctorList, updatePatient } from "../../store/actions"

const Continence = props => {


    const [visiblePatients, setPatient] = useState(true)
    const [visiblelanding, setClanding] = useState(false)
    const [successmessage, setSuccess] = useState("")


    const [assesmentValues, setAssesment] = useState({})

    const {
        wards, user, theatres, clinic, patients, profile, doctors
    } = props;

    useEffect(() => {
        const { getWards, getTheatres, getClinic, getDoctor, getPatient, getUserprofile } = props
        // getWards()
        // getTheatres()
        // getClinic()
        getPatient()
        getDoctor()
        let userId = localStorage.getItem("authUser")
        getUserprofile(userId)
    }, [])

    const updateRadioValue = (e) => {
        let val = e.target.value;
        let name = e.target.name;
        let updatedValue = { [name]: val };
        setAssesment(assesmentValues => ({
            ...assesmentValues,
            ...updatedValue
        }));
    }


    const closeClick = (e) => {
        e.preventDefault()
        localStorage.removeItem("a")
        props.history.push("/riskassesment")
    }

    let assesmentPage = JSON.parse(localStorage.getItem("a"))
    let clinicPatient = JSON.parse(localStorage.getItem("p"))

    return (
        <React.Fragment>

            <MetaTags>
                <title>{assesmentPage}</title>
            </MetaTags>
            <div className="dashboard ward">
                <div className="main">

                    <div className="popup_wrp">
                        <div className="popup_full prescribe">
                            <div className="popup_hed">
                                <h2>CONTINENCE </h2>
                                <div className="dsp-flex"> <a className="popup_close" onClick={(e) => closeClick(e)}><span className="material-icons">close</span></a></div>

                            </div>
                            <div className="popup_content">
                                <div className="popup_scroll w-btns main nutrition">


                                    <div className="modal add_form guidance_popup fade" id="guidance" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                        <div className="modal-dialog" role="document">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title">GUIDANCE</h5>
                                                    <span className="material-icons" data-dismiss="modal" aria-label="Close">
                                                        close
                                                    </span>
                                                </div>
                                                <div className="modal-body">
                                                    <p>Observe cannula 8 hourly or more frequently if clinically indicated
                                                    </p>
                                                    <p>Secure cannula with an appropriate dressing – change if soiled or contaminated
                                                    </p>
                                                    <p>Aseptic technique must be followed for insertion</p>
                                                    <p>Consider re-siting the cannula every 48-72 hours or as indicated by VIP score
                                                        Plan and document care
                                                    </p>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <PatientBlock patient={clinicPatient} />
                                    <div className="bar">
                                        <div className="left">
                                            <div className="tag" data-toggle="modal"
                                                data-target="#guidance"> Guidance </div>
                                            <h3 className="sub_title">CONTINENCE </h3>
                                        </div>
                                        <div className="right">
                                            <a href="#" className="btn"> <span className="material-symbols-outlined"> assignment </span> Not
                                                Yet Done</a>
                                            <div className="tag"> Due </div>
                                        </div>
                                    </div>


                                    <div className="section ctr">
                                        <h2 className="popup_title mt-0">PATIENT’S PHYSICAL CONDITION SCREENING QUESTIONS </h2>
                                        <p className="field_title">AT THIS CURRENT TIME DOES THE PATIENT</p>
                                        <div className="sub_section">
                                            <div className="check-box sqeare mb-0 mt-10">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-200" checked />
                                                    <label for="checkbox-200"></label>
                                                </div>
                                                NEED HELP TO GET TO THE TOILET
                                            </div>
                                            <p className="field_title">MANAGEMENT PLAN ACTIONS</p>
                                            <p className="ps1 mb-0">Requires assistance with toileting</p>
                                        </div>
                                        <div className="sub_section">
                                            <div className="check-box sqeare mb-0 mt-10">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-201" checked />
                                                    <label for="checkbox-201"></label>
                                                </div>
                                                Have any COGNITIVE PROBLEMS
                                            </div>
                                            <p className="field_title">MANAGEMENT PLAN ACTIONS</p>
                                            <p className="ps1 mb-0">Requires assistance with toileting</p>
                                        </div>
                                        <div className="sub_section last">
                                            <div className="check-box sqeare mb-0 mt-10">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-202" checked />
                                                    <label for="checkbox-202"></label>
                                                </div>
                                                Have MOBILITY PROBLEMS
                                            </div>
                                            <p className="field_title">MANAGEMENT PLAN ACTIONS</p>
                                            <p className="ps1 mb-0">Requires assistance with toileting</p>
                                        </div>
                                    </div>

                                    <div className="section ctr">
                                        <h2 className="popup_title mt-0">BLADDER SCREENING QUESTIONS</h2>
                                        <div className="check-box sqeare  mt-10">
                                            <div className="round">
                                                <input type="checkbox" id="checkbox-203" />
                                                <label for="checkbox-203"></label>
                                            </div>
                                            Need to rush to the toilet
                                        </div>
                                        <div className="yn_switch mb-10 mt-0">
                                            <div className="label-container">
                                                <p>Leak urine </p>
                                            </div>
                                            <label for="check812" className="switch-container">
                                                <input hidden="" type="checkbox" className="switch_check" id="check812" />
                                                <div className="switch-bg"></div>
                                                <div className="round-box"></div>
                                                <div className="switch-left">
                                                    <span>NO</span>
                                                </div>
                                                <div className="switch-right">
                                                    <span>YES</span>
                                                </div>
                                            </label>
                                        </div>
                                        <div className="check_item mt-0 mb-10">
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input name="site" type="radio" value="Site 1" /><span>Occasionally
                                                </span></label>
                                                </div>
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 2" /><span>Regularly</span></label></div>
                                            </div>
                                        </div>
                                        <div className="check-box sqeare mt-0">
                                            <div className="round">
                                                <input type="checkbox" id="checkbox-205" />
                                                <label for="checkbox-205"></label>
                                            </div>
                                            Have difficulty passing urine
                                        </div>
                                        <div className="check-box sqeare mt-0">
                                            <div className="round">
                                                <input type="checkbox" id="checkbox-206" />
                                                <label for="checkbox-206"></label>
                                            </div>
                                            Normally wear a pad or use other devices
                                        </div>

                                        <div className="check_item mt-0 mb-0"><label className="check">Normally use a catheter
                                        </label>
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input name="site" type="radio" value="Site 1" /><span>Indwelling
                                                </span></label>
                                                </div>
                                                <div className="select_btn"><label><input name="site" type="radio" value="Site 2" /><span>Intermittent Self
                                                    Catheterisation </span></label>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="section ctr">
                                        <h2 className="popup_title mt-0">INDIVIDUAL MANAGEMENT PLAN</h2>
                                        <div className="sub_section">
                                            <p className="field_title">URINARY INCONTINENCE</p>
                                            <div className="yn_switch mb-15 mt-0">
                                                <div className="label-container">
                                                    <p>Is this a new problem? </p>
                                                </div>
                                                <label for="check812" className="switch-container">
                                                    <input hidden="" type="checkbox" className="switch_check" id="check812" />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>NO</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>YES</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form_group">
                                                        <label className="label">What is the volume of post void scan </label>
                                                        <input type="text" className="form-control" placeholder="Enter" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6"><a href="#" className="link_btn">Inform Medic <span
                                                    className="material-icons icon">
                                                    arrow_right
                                                </span></a></div>
                                            </div>
                                            <div className="yn_switch mb-15 mt-0">
                                                <div className="label-container">
                                                    <p>Catheter required</p>
                                                </div>
                                                <label for="check100" className="switch-container">
                                                    <input hidden="" type="checkbox" className="switch_check" id="check100" />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>NO</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>YES</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="check_item mt-0 mb-0">
                                                <div className="select_btns mt-0">
                                                    <div className="select_btn mb-0"><label><input name="site" type="radio" value="Site 1" /><span>Send MSU
                                                    </span></label>
                                                    </div>
                                                    <div className="select_btn mb-0"><label><input name="site" type="radio" value="Site 2" /><span>CSU + (To
                                                        rule out UTI -
                                                        Avoid
                                                        catheter if possible in UTI)</span></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="sub_section">
                                            <p className="field_title">URINARY RETENTION </p>
                                            <a href="#" className="link_btn">Not passing urine: Inform Medic <span className="material-icons icon">
                                                arrow_right
                                            </span></a>
                                            <div className="yn_switch mb-0">
                                                <div className="label-container">
                                                    <p>Retention or AKI </p>
                                                </div>
                                                <label for="check102" className="switch-container">
                                                    <input hidden="" type="checkbox" className="switch_check" id="check102" />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>NO</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>YES</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="yn_switch mb-0 ">
                                                <div className="label-container">
                                                    <p>Catheter required </p>
                                                </div>
                                                <label for="check103" className="switch-container">
                                                    <input hidden="" type="checkbox" className="switch_check" id="check103" />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>NO</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>YES</span>
                                                    </div>
                                                </label>
                                            </div>

                                        </div>
                                        <div className="sub_section">
                                            <p className="field_title">CATHETER in place </p>
                                            <div className="yn_switch mt-10 mb-10">
                                                <div className="label-container">
                                                    <p>Has a urinary CATHETER in place </p>
                                                </div>
                                                <label for="check103" className="switch-container">
                                                    <input hidden="" type="checkbox" className="switch_check" id="check103" />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>NO</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>YES</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <a href="#" className="link_btn">Complete catheter IPOC <span className="material-icons icon">
                                                arrow_right
                                            </span></a>
                                            <div className="check_item mt-10 mb-0">
                                                <label className="check">Date inserted </label>
                                                <div className="select_btns">
                                                    <div className="select_btn mb-0"><label><input name="site" type="radio" value="Site 1" /><span>Still
                                                        needed</span></label>
                                                    </div>
                                                    <div className="select_btn mb-0"><label><input name="site" type="radio" value="Site 2" /><span>Can be
                                                        removed</span></label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="check_item mt-10 mb-0">
                                                <label className="check">Catheter site checked </label>
                                                <div className="select_btns">
                                                    <div className="select_btn mb-0"><label><input name="site" type="radio" value="Site 1" /><span>No signs
                                                        of Catheter infection</span></label>
                                                    </div>
                                                    <div className="select_btn mb-0"><label><input name="site" type="radio" value="Site 2" /><span>Signs of
                                                        CAUTI</span></label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="yn_switch mt-10 mb-10">
                                                <div className="label-container">
                                                    <p>Urinary catheter care given </p>
                                                </div>
                                                <label for="check104" className="switch-container">
                                                    <input hidden="" type="checkbox" className="switch_check" id="check104" />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>NO</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>YES</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="check_item mt-10 mb-0">
                                                <label className="check">Patients with catheter may need extra support with mobility to
                                                </label>
                                                <div className="select_btns">
                                                    <div className="select_btn mb-0"><label><input name="site" type="radio" value="Site 1" /><span>ensure
                                                        safety and reduce the risk of falls
                                                    </span></label>
                                                    </div>
                                                    <div className="select_btn mb-0"><label><input name="site" type="radio" value="Site 2" /><span>Please
                                                        offer assistance</span></label>
                                                    </div>
                                                </div>
                                            </div>
                                            <p className="mt-10">If patient still catheterized on discharge, <a href="#" className="link_btn">Complete
                                                catheter IPOC <span className="material-icons icon">
                                                    arrow_right </span></a></p>
                                            <p className="mt-10">If patient still incontinent on discharge, supply 3 day diary and refer
                                                to <a href="#" className="link_btn">community service <span className="material-icons icon">
                                                    arrow_right </span></a></p>
                                            <div className="yn_switch mt-0 mb-10">
                                                <div className="label-container">
                                                    <p>Does the patient use intermittent self- catheterisation (ISC)? </p>
                                                </div>
                                                <label for="check105" className="switch-container">
                                                    <input hidden="" type="checkbox" className="switch_check" id="check105" />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>NO</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>YES</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <p className="mt-10 mb-0">Have any problems been identified with the patient continuing to
                                                self-catheterise? <a href="#" className="link_btn">Continence Team Contact <span
                                                    className="material-icons icon"> arrow_right </span></a></p>
                                        </div>
                                        <div className="sub_section">
                                            <div className="yn_switch mt-0 mb-10">
                                                <div className="label-container">
                                                    <p>None of the above </p>
                                                </div>
                                                <label for="check106" className="switch-container">
                                                    <input hidden="" type="checkbox" className="switch_check" id="check106" />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>NO</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>YES</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <p className="ps1">Reassess weekly where required and if a patient condition changes or on
                                                ward transfer</p>
                                        </div>
                                    </div>
                                    <div className="section ctr">
                                        <h2 className="popup_title mt-0">BOWELS SCREENING QUESTIONS</h2>
                                        <p className="ps1">If any of the following is ticked: The patient is at High Risk of becoming
                                            incontinent or may already be experiencing incontinence. </p>
                                        <div className="check-box sqeare mt-0">
                                            <div className="round">
                                                <input type="checkbox" id="checkbox-207" />
                                                <label for="checkbox-207"></label>
                                            </div>
                                            Need to rush to the toilet
                                        </div>
                                        <div className="check-box sqeare mt-0">
                                            <div className="round">
                                                <input type="checkbox" id="checkbox-208" />
                                                <label for="checkbox-208"></label>
                                            </div>
                                            Need to use the toilet frequently
                                        </div>
                                        <div className="check_item mt-10 mb-0">
                                            <label className="check">Leak faeces </label>
                                            <div className="select_btns">
                                                <div className="select_btn mb-0"><label><input name="site" type="radio" value="Site 1" /><span>Occasionally
                                                </span></label>
                                                </div>
                                                <div className="select_btn mb-0"><label><input name="site" type="radio" value="Site 2" /><span>Regularly
                                                </span></label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="check-box sqeare mt-0">
                                            <div className="round">
                                                <input type="checkbox" id="checkbox-209" />
                                                <label for="checkbox-209"></label>
                                            </div>
                                            Have constipation
                                        </div>
                                        <div className="check-box sqeare mt-0">
                                            <div className="round">
                                                <input type="checkbox" id="checkbox-210" />
                                                <label for="checkbox-210"></label>
                                            </div>
                                            Have diarrhoea
                                        </div>
                                        <div className="check_item mt-10 mb-0">
                                            <label className="check">Bristol stool Chart type</label>
                                            <div className="select_btns">
                                                <div className="select_btn mb-0"><label><input name="site" type="radio" value="Site 1" /><span>Type
                                                    1</span></label>
                                                </div>
                                                <div className="select_btn mb-0"><label><input name="site" type="radio" value="Site 2" /><span>Type 2
                                                </span></label>
                                                </div>
                                                <div className="select_btn mb-0"><label><input name="site" type="radio" value="Site 2" /><span>Type 3
                                                </span></label>
                                                </div>
                                                <div className="select_btn mb-0"><label><input name="site" type="radio" value="Site 2" /><span>Type 4
                                                </span></label>
                                                </div>
                                                <div className="select_btn mb-0"><label><input name="site" type="radio" value="Site 2" /><span>Type 5
                                                </span></label>
                                                </div>
                                                <div className="select_btn mb-0"><label><input name="site" type="radio" value="Site 2" /><span>Type 6
                                                </span></label>
                                                </div>
                                                <div className="select_btn mb-0"><label><input name="site" type="radio" value="Site 2" /><span>Type 7
                                                </span></label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="check-box sqeare mt-15">
                                            <div className="round">
                                                <input type="checkbox" id="checkbox-211" />
                                                <label for="checkbox-211"></label>
                                            </div>
                                            Have difficulty passing faeces
                                        </div>
                                        <div className="check-box sqeare mt-0">
                                            <div className="round">
                                                <input type="checkbox" id="checkbox-212" />
                                                <label for="checkbox-212"></label>
                                            </div>
                                            Normally use any equipment to help with toileting
                                        </div>
                                    </div>
                                    <div className="section ctr">
                                        <h2 className="popup_title mt-0">INDIVIDUAL MANAGEMENT PLAN</h2>
                                        <div className="sub_section">
                                            <p className="field_title">FAECAL INCONTINENCE </p>
                                            <div className="yn_switch mb-15 mt-0">
                                                <div className="label-container">
                                                    <p>Is this a new problem? </p>
                                                </div>
                                                <label for="check900" className="switch-container">
                                                    <input hidden="" type="checkbox" className="switch_check" id="check900" />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>NO</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>YES</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <p className="mb_5"> <a href="#" className="link_btn">Inform Medic. Rectal examination may be
                                                advised <span className="material-icons icon"> arrow_right </span></a></p>
                                            <p className="mb_5"> <a href="#" className="link_btn">Send stool sample for culture &
                                                sensitivity if loose and offensive smelling <span className="material-icons icon">
                                                    arrow_right </span></a></p>
                                            <p className="mb_5"> <a href="#" className="link_btn">Commence Bristol stool chart <span
                                                className="material-icons icon"> arrow_right </span></a></p>
                                            <p className="mb_5"> <a href="#" className="link_btn">If patient still incontinent on discharge
                                                refer to community service <span className="material-icons icon"> arrow_right
                                                </span></a></p>

                                        </div>
                                        <div className="sub_section">
                                            <p className="field_title">CONSTIPATION</p>
                                            <a href="#" className="link_btn">Inform Medic <span className="material-icons icon"> arrow_right
                                            </span></a>
                                            <p className="ps1">To rule out Intestinal obstruction </p>
                                            <p className="ps1">To prescribe Laxatives </p>
                                        </div>
                                        <div className="sub_section">
                                            <p className="field_title">DIARRHOEA </p>
                                            <p className="mb_5"> <a href="#" className="link_btn">Commence Bristol stool chart <span
                                                className="material-icons icon"> arrow_right </span></a></p>
                                            <p className="mb_5"> <a href="#" className="link_btn">Inform Medic to prescribe Stool
                                                solidifiers <span className="material-icons icon"> arrow_right </span></a></p>
                                        </div>
                                        <div className="sub_section">

                                            <div className="yn_switch mb-10 mt-0">
                                                <div className="label-container">
                                                    <p>None of the above </p>
                                                </div>
                                                <label for="check905" className="switch-container">
                                                    <input hidden="" type="checkbox" className="switch_check" id="check905" />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>NO</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>YES</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <p className="ps1">Reassess weekly where required and if a patient condition changes or on
                                                ward transfer</p>

                                        </div>
                                    </div>
                                    <div className="section ctr">
                                        <h2 className="popup_title text-center mt-0">BRISTOL STOOL CHART</h2>
                                        <p className="text-center mt-10"> <img src="images/bristol-stool-chart.png" alt="" /></p>

                                    </div>

                                </div>

                            </div>
                            <div className="popup_footer">
                                <div className="prescribe_footer">
                                    <div className="dsp-flex">
                                        <a className="icon_btn"> <span className="material-icons">save</span> SAVE</a>
                                        <button className="border_btn"><span className="material-icons">save_as</span>SAVE &amp;
                                            CLOSE
                                        </button>
                                    </div>
                                    <button className="btn dsp_flex"><span className="material-icons"><span
                                        className="material-icons">close</span></span>DISCARD </button>
                                </div>
                            </div>
                        </div>
                    </div>





                </div>
            </div>




        </React.Fragment>
    )
}

Continence.propTypes = {
    t: PropTypes.any,
    getWards: PropTypes.func,
    getTheatres: PropTypes.func,
    wards: PropTypes.array,
    user: PropTypes.any,
    theatres: PropTypes.array,
    clinic: PropTypes.array,
    patients: PropTypes.array,
    doctors: PropTypes.array,
    profile: PropTypes.object
}

const mapStateToProps = ({ Layout, Login }) => ({
    wards: Layout.wards,
    user: Login.user,
    theatres: Layout.theatres,
    clinic: Layout.clinic,
    patients: Layout.patients,
    doctors: Layout.doctors,
    profile: Layout.profile
})

const mapDispatchToProps = dispatch => ({
    getWards: () => dispatch(getWardsList()),
    getTheatres: () => dispatch(getTheatresList()),
    getClinic: () => dispatch(getClinicList()),
    getPatient: () => dispatch(getPatientList()),
    getUserprofile: (id) => dispatch(getUprofile(id)),
    getDoctor: () => dispatch(getDoctorList()),
    patientupdate: (id, data) => dispatch(updatePatient(id, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Continence)
