import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import MetaTags from 'react-meta-tags';


import PatientBlock from "../Clinic/patientBlock";
import { connect } from "react-redux"
import { getWardsList, getTheatresList, getClinicList, getPatientList, getUprofile, getDoctorList, updatePatient } from "../../store/actions"

const Infection = props => {


    const [visiblePatients, setPatient] = useState(true)
    const [visiblelanding, setClanding] = useState(false)
    const [successmessage, setSuccess] = useState("")


    const [assesmentValues, setAssesment] = useState({})

    const {
        wards, user, theatres, clinic, patients, profile, doctors
    } = props;

    useEffect(() => {
        const { getWards, getTheatres, getClinic, getDoctor, getPatient, getUserprofile } = props
        // getWards()
        // getTheatres()
        // getClinic()
        getPatient()
        getDoctor()
        let userId = localStorage.getItem("authUser")
        getUserprofile(userId)
    }, [])

    const updateRadioValue = (e) => {
        let val = e.target.value;
        let name = e.target.name;
        let updatedValue = { [name]: val };
        setAssesment(assesmentValues => ({
            ...assesmentValues,
            ...updatedValue
        }));
    }


    const closeClick = (e) => {
        e.preventDefault()
        localStorage.removeItem("a")
        props.history.push("/riskassesment")
    }

    let assesmentPage = JSON.parse(localStorage.getItem("a"))
    let clinicPatient = JSON.parse(localStorage.getItem("p"))

    return (
        <React.Fragment>

            <MetaTags>
                <title>{assesmentPage}</title>
            </MetaTags>
            <div className="dashboard ward">
                <div className="main">

                <div className="popup_wrp">
            <div className="popup_full prescribe">
                <div className="popup_hed">
                    <h2>INFECTION SCREENING </h2>
                    <div className="dsp-flex"> <a className="popup_close" onClick={(e) => closeClick(e)}><span className="material-icons">close</span></a></div>

                </div>
                <div className="popup_content">
                    <div className="popup_scroll w-btns main nutrition">

                    <div className="modal add_form guidance_popup fade" id="guidance" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">GUIDANCE</h5>
                                    <span className="material-icons" data-dismiss="modal" aria-label="Close">
                                        close
                                    </span>
                                </div>
                                <div className="modal-body">
                                    <p>Observe cannula 8 hourly or more frequently if clinically indicated
                                    </p>
                                    <p>Secure cannula with an appropriate dressing – change if soiled or contaminated
                                    </p>
                                    <p>Aseptic technique must be followed for insertion</p>
                                    <p>Consider re-siting the cannula every 48-72 hours or as indicated by VIP score
                                        Plan and document care
                                    </p>

                                </div>
                            </div>
                        </div>
                    </div>

                    
                        <PatientBlock patient={clinicPatient} />
                        <div className="bar">
                            <div className="left">
                                <div className="tag" data-toggle="modal"
                                                    data-target="#guidance"> Guidance </div>
                                <h3 className="sub_title">INFECTION SCREENING </h3>
                            </div>
                            <div className="right">
                                <a href="#" className="btn"> <span className="material-symbols-outlined"> assignment </span> Not
                                    Yet Done</a>
                                <div className="tag"> Due </div>
                            </div>
                        </div>
                        <div className="section ctr fb_table poc is">
                            <h2 className="popup_title"> INFECTION SCREENING AT ADMISSION (Tick all that apply)</h2>

                            <div className="tbl_block">
                                <div className="row m-0">
                                    <div className="col-sm-6 col1">
                                        <div className="check-box">
                                            <div className="round">
                                                <input type="checkbox" id="checkbox-20" />
                                                <label for="checkbox-20"></label>
                                            </div>
                                            Patient transferred in from Care Home, Hospital Ward
                                        </div>

                                    </div>
                                    <div className="col-sm-6 col2">
                                        <div className="yn_switch mb-0 mt-0 disable">
                                            <div className="label-container">
                                                <p>Requires isolation?</p>
                                            </div>
                                            <label for="check826" className="switch-container">
                                                <input hidden="" type="checkbox" className="switch_check" id="check826" />
                                                <div className="switch-bg"></div>
                                                <div className="round-box"></div>
                                                <div className="switch-left">
                                                    <span>NO</span>
                                                </div>
                                                <div className="switch-right">
                                                    <span>YES</span>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col1">
                                        <div className="check-box">
                                            <div className="round">
                                                <input type="checkbox" id="checkbox-20" />
                                                <label for="checkbox-20"></label>
                                            </div>
                                            Patient has a suspected viral respiratory tract infection (Flu, RSV etc)
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col2">
                                        <div className="check_item mt-0 mb-0 disable">
                                            <label className="check">Respiratory isolation in a side room necessary?
                                            </label>
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                            value="Site 1" /><span> Inform Infection control team
                                                        </span></label>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col1">
                                        <div className="check-box ">
                                            <div className="round">
                                                <input type="checkbox" id="checkbox-21" />
                                                <label for="checkbox-21"></label>
                                            </div>
                                            Patient has diarrhoea thought to be of an infectious nature
                                        </div>

                                    </div>
                                    <div className="col-sm-6 col2">
                                        <div className="check_item mt-0 mb-0 disable">
                                            <label className="check">Enteric precautions & Isolation in a side room
                                                required? </label>
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                            value="Site 1" /><span> Inform Infection control
                                                            team
                                                        </span></label>
                                                </div>
                                                <div className="select_btn mt-5"><label><input name="site" type="radio"
                                                            value="Site 1" /><span> ISend C. difficile
                                                            sample
                                                        </span></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col1">
                                        <div className="check-box ">
                                            <div className="round">
                                                <input type="checkbox" id="checkbox-23" />
                                                <label for="checkbox-23"></label>
                                            </div>
                                            Difficile sample sent Negative, Positive, Culture Pending
                                        </div>

                                       
                                    </div>
                                    <div className="col-sm-6 col2">
                                        <div className="form_group disable">
                                            <label className="label">Actions</label>
                                            <input type="text" className="form-control" placeholder="Enter" />
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col1">
                                        <div className="check-box ">
                                            <div className="round">
                                                <input type="checkbox" id="checkbox-23" />
                                                <label for="checkbox-23"></label>
                                            </div>
                                            Patient has diarrhoea & had a positive C diff. test in the past 6 months
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col2">
                                        <div className="check_item mt-0 mb-0 disable">
                                            <label className="check">Enteric precautions & Isolation in a side room
                                                required?
                                            </label>
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                            value="Site 1" /><span> Inform Infection control team
                                                        </span></label>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col1">
                                        <div className="check-box ">
                                            <div className="round">
                                                <input type="checkbox" id="checkbox-23" />
                                                <label for="checkbox-23"></label>
                                            </div>
                                            E coli & other multi-resistant organisms swab taken
                                        </div>
                                        <div className="check_item mt-0 mb-0">
                                           
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                            value="Site 1" /><span> Negative
                                                        </span></label>
                                                </div>
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                            value="Site 1" /><span> Positive
                                                        </span></label>
                                                </div>
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                            value="Site 1" /><span> Culture Pending
                                                        </span></label>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col2">
                                        <div className="form_group disable">
                                            <label className="label">Actions</label>
                                            <input type="text" className="form-control" placeholder="Enter" />
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col1">
                                        <div className="check-box ">
                                            <div className="round">
                                                <input type="checkbox" id="checkbox-27" />
                                                <label for="checkbox-27"></label>
                                            </div>
                                            Risk of HIV, Hep B, Hep C, TB etc
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col2">
                                        <div className="check_item mt-0 mb-0 disable">

                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                            value="Site 1" /><span> Inform Medical team
                                                        </span></label>
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button type="button" className="update_btn mt-10">UPDATE</button>
                        </div>
                        <div className="section ctr fb_table poc is">
                            <div className="tbl_block">
                                <div className="row m-0">
                                    <div className="col-sm-6 col1">
                                        <div className="check-box ">
                                            <div className="round">
                                                <input type="checkbox" id="checkbox-23" />
                                                <label for="checkbox-23"></label>
                                            </div>
                                            MRSA (Nose/groin) swab taken:(For every patient)
                                        </div>

                                        <div className="check_item mt-0">
                                            <div className="select_btns popup_select mt-0">
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                            value="Site 1" /><span>Negative
                                                        </span></label></div>
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                            value="Site 2" /><span>Positive
                                                        </span></label></div>
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                            value="Site 2" /><span>Culture Pending
                                                        </span></label></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col2">
                                        
                                        <div className="check_item mt-0 disable"><label className="check">Repeat every 28 days
                                            </label>
                                            <div className="select_btns popup_select mt-0">
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                            value="Site 1" /><span>Implement appropriate action,
                                                            Antibiotics
                                                            Complete Infection Source Isolation Care Sheet
                                                        </span></label></div>

                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-sm-6 col1">
                                        <div className="check-box ">
                                            <div className="round">
                                                <input type="checkbox" id="checkbox-23" />
                                                <label for="checkbox-23"></label>
                                            </div>
                                            MRSA (Catheter, Devices, Wounds) sample sent
                                        </div>
                                        <div className="check_item mt-0">
                                            <div className="select_btns popup_select mt-0">
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                            value="Site 1" /><span>Negative
                                                        </span></label></div>
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                            value="Site 2" /><span>Positive
                                                        </span></label></div>
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                            value="Site 2" /><span>Culture Pending
                                                        </span></label></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col2">
                                        
                                        <div className="check_item mt-0 disable"><label className="check">Repeat weekly
                                            </label>
                                            <div className="select_btns popup_select mt-0">
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                            value="Site 1" /><span>Implement appropriate action,
                                                            Antibiotics
                                                            Complete Infection Source Isolation Care Sheet

                                                        </span></label></div>

                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-sm-6 col1">
                                        <div className="check-box ">
                                            <div className="round">
                                                <input type="checkbox" id="checkbox-23" />
                                                <label for="checkbox-23"></label>
                                            </div>
                                            COVID screen: Day 0, Day 3, Day 5, Day 7 and Day 28
                                        </div>
                                        <div className="check_item mt-0">
                                            <div className="select_btns popup_select mt-0">
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                            value="Site 1" /><span>Negative
                                                        </span></label></div>
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                            value="Site 2" /><span>Positive
                                                        </span></label></div>
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                            value="Site 2" /><span>Culture Pending
                                                        </span></label></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col2">
                                        <div className="check_item mt-0 disable"><label className="check">Isolation
                                            </label>
                                            <div className="select_btns popup_select mt-0">
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                            value="Site 1" /><span>Inform infection control team

                                                        </span></label></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button type="button" className="update_btn mt-10">UPDATE</button>
                        </div>
                    </div>
               
                </div>
                <div className="popup_footer">
                    <div className="prescribe_footer">
                        <div className="dsp-flex">
                            <a className="icon_btn"> <span className="material-icons">save</span> SAVE</a>
                            <button className="border_btn"><span className="material-icons">save_as</span>SAVE &amp;
                                CLOSE
                            </button>
                        </div>
                        <button className="btn dsp_flex"><span className="material-icons"><span
                                    className="material-icons">close</span></span>DISCARD </button>
                    </div>
                </div>
            </div>
        </div>



                </div>
            </div>




        </React.Fragment>
    )
}

Infection.propTypes = {
    t: PropTypes.any,
    getWards: PropTypes.func,
    getTheatres: PropTypes.func,
    wards: PropTypes.array,
    user: PropTypes.any,
    theatres: PropTypes.array,
    clinic: PropTypes.array,
    patients: PropTypes.array,
    doctors: PropTypes.array,
    profile: PropTypes.object
}

const mapStateToProps = ({ Layout, Login }) => ({
    wards: Layout.wards,
    user: Login.user,
    theatres: Layout.theatres,
    clinic: Layout.clinic,
    patients: Layout.patients,
    doctors: Layout.doctors,
    profile: Layout.profile
})

const mapDispatchToProps = dispatch => ({
    getWards: () => dispatch(getWardsList()),
    getTheatres: () => dispatch(getTheatresList()),
    getClinic: () => dispatch(getClinicList()),
    getPatient: () => dispatch(getPatientList()),
    getUserprofile: (id) => dispatch(getUprofile(id)),
    getDoctor: () => dispatch(getDoctorList()),
    patientupdate: (id, data) => dispatch(updatePatient(id, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Infection)
