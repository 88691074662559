import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import MetaTags from 'react-meta-tags';
import PatientBlock from "../Clinic/patientBlock";
import {
    Modal
} from "reactstrap"
import WardMenus from "./wardMenus";
import { connect } from "react-redux"
import { getWardsList, getTheatresList, getClinicList, getPatientList, getUprofile, getDoctorList, updatePatient, getNews, updateDailyRounds, getDailyRounds } from "../../store/actions"

const Allied = props => {
    document.body.style.overflow = '';
    let clinicPatient = JSON.parse(localStorage.getItem("p"))
    const [discard_popup, setdiscard_popup] = useState(false)
    const [allied_value, setallied_value] = useState(null)
    const [clinicValues, setClinic] = useState({})
    const [wardValues, setWard] = useState({})

    const {
        wards, user, theatres, clinic, patients, profile, doctors
    } = props;

    useEffect(() => {
        const { getWards, getTheatres, getClinic, getDoctor, getPatient, getUserprofile } = props
        
        getPatient()
        getDoctor()
        let userId = localStorage.getItem("authUser")
        getUserprofile(userId)
    }, [])

    useEffect(() => {
        let clinicPatient = JSON.parse(localStorage.getItem("p"))
        if (patients && patients.length > 0 && clinicPatient && clinicPatient._id) {
            const cPatient = patients.filter(
                usr => usr._id === clinicPatient._id
            )
            if (cPatient && cPatient[0]) {
                let cpatientData = cPatient[0]
                delete cpatientData["_id"]
                setClinic(clinicValues => ({
                    ...clinicValues,
                    ...cpatientData
                }));
            }

        }
    }, [patients])

    const updateWardValue = (e) => {
        let val = e.target.value;
        let name = e.target.name;
        let updatedValue = { [name]: val };
        setWard(wardValues => ({
            ...wardValues,
            ...updatedValue
        }));
        setClinic(clinicValues => ({
            ...clinicValues,
            ...updatedValue
        }));
    }

    const updateWard = (e) => {
        const { patientupdate } = props
        let clinicPatient = JSON.parse(localStorage.getItem("p"))
        let id = clinicPatient._id
        patientupdate(id, wardValues)
        setdiscard_popup(false)
        
    }

    const allied = [{
            "label": "PHYSIO",
            "value": "physio"
        },
        {
            "label": "OT",
            "value": "ot"
        },
        {
            "label": "PHARMACY",
            "value": "pharmacy"
        },
        {
            "label": "DIETICS",
            "value": "dietics"
        },
        {
            "label": "OTHER AHP",
            "value": "otherahp"
        },
    ]

    return (
        <React.Fragment>

            <MetaTags>
                <title>Allied Health + Visiting Notes</title>
            </MetaTags>
            <div className='ward '>
                <div className="main ald">
                    <div className="wrapper">

                        <div className=" out_patient_section out_patient_details mb-0">

                            <PatientBlock patient={clinicPatient} accordion={true} />
                            <WardMenus />


                        </div>


                        <div className="titleandinp">
                            <h3>Allied</h3>
                        </div>

                        {allied && allied.length > 0 && allied.map((item, i) => {
                            return(
                                <div className="ald_section" key={i}>
                                    <div className="left">
                                        <div className="hed">{item.label}</div>
                                        <div className="col">Initial Assessment</div>
                                        <div className="col">Update</div>
                                        <div className="col">Discharge</div>
                                    </div>
                                    <div className="right">
                                        <div className="hed"></div>
                                        <div className="col"  onClick={() => { setdiscard_popup(true), setallied_value(item.value+"_intial_assesment") }} >{clinicValues[item.value+"_intial_assesment"]}</div>
                                        <div className="col"  onClick={() => { setdiscard_popup(true), setallied_value(item.value+"_update") }}>{clinicValues[item.value+"_update"]}</div>
                                        <div className="col"  onClick={() => { setdiscard_popup(true), setallied_value(item.value+"_discharge") }}>{clinicValues[item.value+"_discharge"]}</div>
                                    </div>
                                </div>
                            )
                        })}
                       
                        <br/> <br/> <br/>


                        <Modal
                            isOpen={discard_popup}
                            toggle={() => {
                                setdiscard_popup(false)
                            }}
                            centered={true}
                        >
                            <div className="modal-header">
                                <h5 className="modal-title mt-0">Actions - {allied_value && allied_value.toUpperCase()}</h5>
                                <button
                                    type="button"
                                    onClick={() => {
                                        setdiscard_popup(false)
                                    }}
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>

                           
                            <div className="modal-body">
                                <textarea className="form-control" rows="4" cols="50" name={allied_value} onChange={(e) => updateWardValue(e)} > </textarea>
                                <div className="action_btns">
                                  
                                    <button type="button" className="btn btn-success" onClick={(e) => updateWard(e)}>Add</button>
                                </div>
                            </div>

                        </Modal>

                    </div>
                </div>
            </div>




        </React.Fragment>
    )
}

Allied.propTypes = {
    getWards: PropTypes.func,
    getTheatres: PropTypes.func,
    wards: PropTypes.array,
    user: PropTypes.any,
    theatres: PropTypes.array,
    clinic: PropTypes.array,
    patients: PropTypes.array,
    doctors: PropTypes.array,
    profile: PropTypes.object,
    news: PropTypes.array,
}

const mapStateToProps = ({ Layout, Login }) => ({
    wards: Layout.wards,
    user: Login.user,
    theatres: Layout.theatres,
    clinic: Layout.clinic,
    patients: Layout.patients,
    doctors: Layout.doctors,
    profile: Layout.profile,
    news: Layout.news,
    dailyrounds: Layout.dailyrounds
})

const mapDispatchToProps = dispatch => ({
    getWards: () => dispatch(getWardsList()),
    getTheatres: () => dispatch(getTheatresList()),
    getClinic: () => dispatch(getClinicList()),
    getPatient: () => dispatch(getPatientList()),
    getUserprofile: (id) => dispatch(getUprofile(id)),
    getDoctor: () => dispatch(getDoctorList()),
    patientupdate: (id, data) => dispatch(updatePatient(id, data)),
    newsList: (data) => dispatch(getNews(data)),
    dailyRoundsList: (data) => dispatch(getDailyRounds(data)),
    dailyUpdate: (data) => dispatch(updateDailyRounds(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Allied)
