import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import MetaTags from 'react-meta-tags';


import PatientBlock from "../Clinic/patientBlock";
import { connect } from "react-redux"
import { getWardsList, getTheatresList, getClinicList, getPatientList, getUprofile, getDoctorList, updatePatient } from "../../store/actions"

const Dementia = props => {


    const [visiblePatients, setPatient] = useState(true)
    const [visiblelanding, setClanding] = useState(false)
    const [successmessage, setSuccess] = useState("")


    const [assesmentValues, setAssesment] = useState({})

    const {
        wards, user, theatres, clinic, patients, profile, doctors
    } = props;

    useEffect(() => {
        const { getWards, getTheatres, getClinic, getDoctor, getPatient, getUserprofile } = props
        // getWards()
        // getTheatres()
        // getClinic()
        getPatient()
        getDoctor()
        let userId = localStorage.getItem("authUser")
        getUserprofile(userId)
    }, [])

    const updateRadioValue = (e) => {
        let val = e.target.value;
        let name = e.target.name;
        let updatedValue = { [name]: val };
        setAssesment(assesmentValues => ({
            ...assesmentValues,
            ...updatedValue
        }));
    }


    const closeClick = (e) => {
        e.preventDefault()
        localStorage.removeItem("a")
        props.history.push("/riskassesment")
    }

    let assesmentPage = JSON.parse(localStorage.getItem("a"))
    let clinicPatient = JSON.parse(localStorage.getItem("p"))

    return (
        <React.Fragment>

            <MetaTags>
                <title>{assesmentPage}</title>
            </MetaTags>
            <div className="dashboard ward">
                <div className="main">

                    <div className="popup_wrp">
                        <div className="popup_full prescribe">
                            <div className="popup_hed">
                                <h2>DEMENTIA </h2>
                                <div className="dsp-flex"> <a className="popup_close" onClick={(e) => closeClick(e)}><span className="material-icons">close</span></a></div>

                            </div>
                            <div className="popup_content">
                                <div className="popup_scroll w-btns main nutrition">


                                    <div className="modal add_form guidance_popup fade" id="guidance" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                        <div className="modal-dialog" role="document">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title">GUIDANCE</h5>
                                                    <span className="material-icons" data-dismiss="modal" aria-label="Close">
                                                        close
                                                    </span>
                                                </div>
                                                <div className="modal-body">
                                                    <p>Observe cannula 8 hourly or more frequently if clinically indicated
                                                    </p>
                                                    <p>Secure cannula with an appropriate dressing – change if soiled or contaminated
                                                    </p>
                                                    <p>Aseptic technique must be followed for insertion</p>
                                                    <p>Consider re-siting the cannula every 48-72 hours or as indicated by VIP score
                                                        Plan and document care
                                                    </p>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <PatientBlock patient={clinicPatient} />
                                    <div className="bar">
                                        <div className="left">
                                            <div className="tag" data-toggle="modal"
                                                data-target="#guidance"> Guidance </div>
                                            <h3 className="sub_title">DEMENTIA </h3>
                                        </div>
                                        <div className="right">
                                            <a href="#" className="btn"> <span className="material-symbols-outlined"> assignment </span> Not
                                                Yet Done</a>
                                            <div className="tag"> Due </div>
                                        </div>
                                    </div>
                                    <div className="section ctr fb_table poc is ms">

                                        <div className="tbl_block">
                                            <div className="row m-0">
                                                <div className="col-sm-6 col1">
                                                    <div className="check-box">
                                                        <div className="round">
                                                            <input type="checkbox" id="checkbox-20" />
                                                            <label for="checkbox-20"></label>
                                                        </div>
                                                        Patient KNOWN DEMENTIA
                                                    </div>
                                                    <div className="check-box">
                                                        <div className="round">
                                                            <input type="checkbox" id="checkbox-21" />
                                                            <label for="checkbox-21"></label>
                                                        </div>
                                                        'This is me' document completed
                                                    </div>
                                                    <div className="check-box">
                                                        <div className="round">
                                                            <input type="checkbox" id="checkbox-22" />
                                                            <label for="checkbox-22"></label>
                                                        </div>
                                                        John's Campaign
                                                    </div>
                                                    <div className="check-box mb-0">
                                                        <div className="round">
                                                            <input type="checkbox" id="checkbox-23" />
                                                            <label for="checkbox-23"></label>
                                                        </div>
                                                        "l am a Carer" Card provided
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col2 disable">
                                                    <div className="check-box">
                                                        <div className="round">
                                                            <input type="checkbox" id="checkbox-24" />
                                                            <label for="checkbox-24"></label>
                                                        </div>
                                                        Patient has KNOWN DEMENTIA
                                                    </div>
                                                    <div className="check-box">
                                                        <div className="round">
                                                            <input type="checkbox" id="checkbox-25" />
                                                            <label for="checkbox-25"></label>
                                                        </div>
                                                        Complete 'This is me' document
                                                    </div>
                                                    <div className="check-box">
                                                        <div className="round">
                                                            <input type="checkbox" id="checkbox-26" />
                                                            <label for="checkbox-26"></label>
                                                        </div>
                                                        John's Campaign
                                                    </div>
                                                    <div className="check-box mb-0">
                                                        <div className="round">
                                                            <input type="checkbox" id="checkbox-27" />
                                                            <label for="checkbox-27"></label>
                                                        </div>
                                                        Provide "l am a Carer" Card.
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col1">
                                                    <div className="check-box mb-0">
                                                        <div className="round">
                                                            <input type="checkbox" id="checkbox-28" />
                                                            <label for="checkbox-28"></label>
                                                        </div>
                                                        Patient NOT KNOWN to have a diagnosis of Dementia
                                                    </div>

                                                </div>
                                                <div className="col-sm-6 col2">
                                                    <div className="check-box disable mb-0">
                                                        <div className="round">
                                                            <input type="checkbox" id="checkbox-29" />
                                                            <label for="checkbox-29"></label>
                                                        </div>
                                                        Next step
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col1">
                                                    <div className="check-box mb-0">
                                                        <div className="round">
                                                            <input type="checkbox" id="checkbox-30" />
                                                            <label for="checkbox-30"></label>
                                                        </div>
                                                        <span> Patient SUSPECTED to have DEMENTIA
                                                            From collateral History <br />
                                                            <span className="small">(Sleep/Continence/Level of supervision at home)
                                                            </span> /More forgetful in the past 12 months </span>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col2">
                                                    <div className="check-box disable mb-0">
                                                        <div className="round">
                                                            <input type="checkbox" id="checkbox-31" />
                                                            <label for="checkbox-31"></label>
                                                        </div>
                                                        Perform AMT4 (Year, Name of place, Age, DOB)
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col1">
                                                    <div className="check-box mb-0">
                                                        <div className="round">
                                                            <input type="checkbox" id="checkbox-32" />
                                                            <label for="checkbox-32"></label>
                                                        </div>
                                                        AMT4 Score 4/4: Impairment unlikely
                                                    </div>

                                                </div>
                                                <div className="col-sm-6 col2">

                                                </div>
                                                <div className="col-sm-6 col1">
                                                    <div className="check-box mb-0">
                                                        <div className="round">
                                                            <input type="checkbox" id="checkbox-33" />
                                                            <label for="checkbox-33"></label>
                                                        </div>
                                                        Score &gt; 4: Possible impairment </div>
                                                </div>
                                                <div className="col-sm-6 col2 ">
                                                    <div className="check-box disable mb-0">
                                                        <div className="round">
                                                            <input type="checkbox" id="checkbox-34" />
                                                            <label for="checkbox-34"></label>
                                                        </div>
                                                        Ask family / Carers
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col1">
                                                    <div className="check-box mb-0">
                                                        <div className="round">
                                                            <input type="checkbox" id="checkbox-35" />
                                                            <label for="checkbox-35"></label>
                                                        </div>
                                                        No family or carers available to ask: Do 4 AT Assessment Test for
                                                        Delirium & Cognitive Impairment
                                                    </div>

                                                </div>
                                                <div className="col-sm-6 col2">

                                                </div>
                                                <div className="col-sm-6 col1">
                                                    <div className="check-box mb-0">
                                                        <div className="round">
                                                            <input type="checkbox" id="checkbox-36" />
                                                            <label for="checkbox-36"></label>
                                                        </div>
                                                        Score 0: delirium or severe cognitive impairment unlikely
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col2">

                                                </div>
                                                <div className="col-sm-6 col1">
                                                    <div className="check-box">
                                                        <div className="round">
                                                            <input type="checkbox" id="checkbox-37" />
                                                            <label for="checkbox-37"></label>
                                                        </div>
                                                        Score 1-3: possible cognitive impairment
                                                    </div>
                                                    <div className="check-box mb-0">
                                                        <div className="round">
                                                            <input type="checkbox" id="checkbox-38" />
                                                            <label for="checkbox-38"></label>
                                                        </div>
                                                        Score 4 or above: possible delirium +/- cognitive impairment
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col2">
                                                    <div className="check-box disable mb-0">
                                                        <div className="round">
                                                            <input type="checkbox" id="checkbox-39" />
                                                            <label for="checkbox-39"></label>
                                                        </div>
                                                        The patient’s GP to be informed via discharge letter about possible
                                                        Cognitive impairment in order to enable follow up care.
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col1">
                                                    <div className="check-box  mb-0">
                                                        <div className="round">
                                                            <input type="checkbox" id="checkbox-40" />
                                                            <label for="checkbox-40"></label>
                                                        </div>
                                                        Patient, family or carers state that the patient been more forgetful in
                                                        the past 12 months to the extent that it has significantly affected
                                                        their daily life
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col2">
                                                    <div className="check-box disable mb-0">
                                                        <div className="round">
                                                            <input type="checkbox" id="checkbox-41" />
                                                            <label for="checkbox-41"></label>
                                                        </div>
                                                        Complete 6 CIT
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="section ctr">
                                        <h2 className="popup_title"> Six Item Cognitive Impairment Test (6CIT)<br />
                                            <span className="sub">(Kingshill Version 2000, Dementia screening tool)</span>
                                        </h2>
                                        <div className="check_item mt-0 mb-10"><label className="check">1, What year is it?
                                        </label>
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 1" /><span>Correct </span></label>
                                                </div>
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 2" /><span>Incorrect </span></label></div>
                                            </div>
                                        </div>
                                        <div className="check_item mt-0 mb-10"><label className="check">2, What month is it?
                                        </label>
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 1" /><span>Correct </span></label>
                                                </div>
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 2" /><span>Incorrect </span></label></div>
                                            </div>
                                        </div>
                                        <div className="poc gtp">
                                            <div className="check-box">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-55" />
                                                    <label for="checkbox-55"></label>
                                                </div>
                                                Give the patient an address phrase to remember with 5 components, Eg John,
                                                Smith, 42, High St, Bedford
                                            </div>
                                        </div>
                                        <div className="check_item mt-0 mb-10"><label className="check">3, About what time is it (within
                                            1 hour)
                                        </label>
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 1" /><span>Correct </span></label>
                                                </div>
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 2" /><span>Incorrect </span></label></div>
                                            </div>
                                        </div>
                                        <div className="check_item mt-0 mb-10"><label className="check">4, Count backwards from 20-1
                                        </label>
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 1" /><span>Correct </span></label>
                                                </div>
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 2" /><span>1 error </span></label></div>
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 2" /><span>More than I error </span></label></div>
                                            </div>
                                        </div>
                                        <div className="check_item mt-0 mb-10"><label className="check">5, Say the months of the year in
                                            reverse
                                        </label>
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 1" /><span>Correct </span></label>
                                                </div>
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 2" /><span>1 error </span></label></div>
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 2" /><span>More than I error </span></label></div>
                                            </div>
                                        </div>
                                        <div className="check_item mt-0 mb-10"><label className="check">6, Repeat address phrase: John,
                                            Smith, Bedford42, High St,
                                        </label>
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 1" /><span>Correct </span></label>
                                                </div>
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 2" /><span>1 error </span></label></div>
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 2" /><span>2 errors </span></label></div>
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 2" /><span>3 errors </span></label></div>
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 2" /><span>4 errors </span></label></div>
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 2" /><span>All wrong </span></label></div>
                                            </div>
                                        </div>
                                        <div className="total_points">
                                            <span>TOTAL SCORE </span>
                                            <span>28</span>
                                        </div>
                                        <div className="info">
                                            <h4>Outcome from Score</h4>
                                            <p>0-7 = Normal	Referral not necessary at present</p>
                                            <p>8- 9 = Mild cognitive impairment. Probably refer</p>
                                            <p>10-28 = Significant cognitive impairment. Refer </p>

                                            <p className="mt-20">
                                                Scores of 0-7 are considered normal and 8 or more significant.
                                            </p>
                                            <p>If the patient scores 8 or more, a clinical decision must be made as to the mental capacity of the patient and subsequent risk assessment. </p>
                                            <p>The patient’s GP must also be informed in order to enable follow up care.</p>
                                        </div>
                                    </div>
                               
                            </div>

                        </div>
                        <div className="popup_footer">
                            <div className="prescribe_footer">
                                <div className="dsp-flex">
                                    <a className="icon_btn"> <span className="material-icons">save</span> SAVE</a>
                                    <button className="border_btn"><span className="material-icons">save_as</span>SAVE &amp;
                                        CLOSE
                                    </button>
                                </div>
                                <button className="btn dsp_flex"><span className="material-icons"><span
                                    className="material-icons">close</span></span>DISCARD </button>
                            </div>
                        </div>
                    </div>
                </div>





            </div>
        </div>




        </React.Fragment >
    )
}

Dementia.propTypes = {
    t: PropTypes.any,
    getWards: PropTypes.func,
    getTheatres: PropTypes.func,
    wards: PropTypes.array,
    user: PropTypes.any,
    theatres: PropTypes.array,
    clinic: PropTypes.array,
    patients: PropTypes.array,
    doctors: PropTypes.array,
    profile: PropTypes.object
}

const mapStateToProps = ({ Layout, Login }) => ({
    wards: Layout.wards,
    user: Login.user,
    theatres: Layout.theatres,
    clinic: Layout.clinic,
    patients: Layout.patients,
    doctors: Layout.doctors,
    profile: Layout.profile
})

const mapDispatchToProps = dispatch => ({
    getWards: () => dispatch(getWardsList()),
    getTheatres: () => dispatch(getTheatresList()),
    getClinic: () => dispatch(getClinicList()),
    getPatient: () => dispatch(getPatientList()),
    getUserprofile: (id) => dispatch(getUprofile(id)),
    getDoctor: () => dispatch(getDoctorList()),
    patientupdate: (id, data) => dispatch(updatePatient(id, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Dementia)
