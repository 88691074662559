import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import MetaTags from 'react-meta-tags';
// import Menus from "./onCallMenus";
import { Link, useHistory } from "react-router-dom"
import { connect } from "react-redux"
import { getWardsList, getTheatresList, getClinicList, getPatientList, getUprofile, getDoctorList, updatePatient, getNews, updateDailyRounds, getDailyRounds } from "../../store/actions"
import PatientBlock from "../Clinic/patientBlock";
import Wardnotes from '../../assets/images/Wardnotes.svg';
import theatreNotes from '../../assets/images/theatreNotes.svg';
import clinicNotes from '../../assets/images/clinicNotes.svg';
import reports from '../../assets/images/reports.svg';
import MedRecRoom from '../../assets/images/MedRecRoom.svg';
import LifetimePatientRecord from '../../assets/images/LifetimePatientRecord.svg';
import diagnosis from '../../assets/images/diagnosis.svg';


const OnCall = props => {
    document.body.style.overflow = '';
    document.body.style.padding = '';
    let clinicPatient = JSON.parse(localStorage.getItem("p"))
    const history = useHistory();
    const [discard_popup, setdiscard_popup] = useState(false)
    const [allied_value, setallied_value] = useState(null)
    const [clinicValues, setClinic] = useState({})
    const [wardValues, setWard] = useState({})

    const {
        wards, user, theatres, clinic, patients, profile, doctors
    } = props;

    useEffect(() => {
        const { getWards, getTheatres, getClinic, getDoctor, getPatient, getUserprofile } = props

        getPatient()
        getDoctor()
        let userId = localStorage.getItem("authUser")
        getUserprofile(userId)
    }, [])

    useEffect(() => {
        let clinicPatient = JSON.parse(localStorage.getItem("p"))
        if (patients && patients.length > 0 && clinicPatient && clinicPatient._id) {
            const cPatient = patients.filter(
                usr => usr._id === clinicPatient._id
            )
            if (cPatient && cPatient[0]) {
                let cpatientData = cPatient[0]
                delete cpatientData["_id"]
                setClinic(clinicValues => ({
                    ...clinicValues,
                    ...cpatientData
                }));
            }

        }
    }, [patients])


    const btnClick = (e) => {
        e.preventDefault()
        history.push("/wlist")
        localStorage.setItem('rrd', 'ol')
      }


    return (
        <React.Fragment>

            <MetaTags>
                <title>On Call</title>
            </MetaTags>
            <div className='ward '>
                <div className="main">
                    <div className="wrapper">
                        <div className="patient_record prd">
                            <div className="prd_top">
                                {clinicPatient && <PatientBlock patient={clinicPatient} accordion={true} selectPatientList={true} />}

                                {!clinicPatient && <div className="find">
                                    <button type="button" onClick={(e) => btnClick(e)} className="btn"> <span className="material-symbols-outlined icon">
                                        person_search </span> Find Patient</button>
                                </div>}
                                {!clinicPatient && <div className="guided_tour">
                                    <div className="guided_tour_arrow"></div>
                                    <p>Find and Add a patient to access the Ward</p>
                                    <span className="material-icons icon"> close </span>
                                </div>}

                                <div className="prd_btns">
                                    <div className="btns">
                                        <button type="button" className="btn">VOICE NOTE    </button>
                                        <button type="button" className="btn">TEXT NOTE </button>
                                    </div>
                                    <div className="btn-group">
                                        <button type="button" className="btn btn-primary">VIEW X RAYS </button>
                                        <button type="button" className="btn btn-primary">VIEW LABS  </button>
                                        <button type="button" className="btn btn-primary">ORDER TESTS  </button>
                                        <button type="button" className="btn btn-primary">PRESCRIBE  </button>
                                    </div>
                                </div>
                            </div>
                            <h2>ON CALL</h2>
                            <ul className={!clinicPatient ? "oncall_dboard col_3 disabled" : "oncall_dboard col_3 "}>
                                <li className="first">
                                    <a data-bs-toggle="modal" data-bs-target="#wordnotes" className="item_card">
                                        <span className="icon"> <img src={Wardnotes} /></span>
                                        <span className="wn">ON CALL / POST ON CALL MEETING
                                            <span className="material-symbols-outlined icon2"> keyboard_arrow_down </span>
                                        </span>

                                    </a>
                                </li>
                                <li>
                                <Link to="/trauma" className="item_card">
                                        <span className="icon"> <img src={Wardnotes} /></span>
                                        <span className="wn"> TRAUMA CALL
                                        </span>

                                    </Link>
                                </li>
                                <li>
                                    <Link to='uc' className="item_card">
                                        <span className="icon"> <img src={theatreNotes} /> </span>
                                        POST ON CALL FOLLOW UP
                                    </Link>
                                </li>
                                <li>
                                    <Link to='uc' className="item_card">
                                        <span className="icon"> <img src={clinicNotes} /></span>
                                        PATIENTS WAITING
                                    </Link>
                                </li>
                                <li>
                                    <Link to='uc' className="item_card">
                                        <span className="icon"> <img src={reports} /></span>
                                        THEATRE LIST (TRAUMA)
                                    </Link>
                                </li>
                                <li>
                                    <Link to='uc' className="item_card">
                                        <span className="icon"> <img src={MedRecRoom} /></span>
                                        LOG BOOK
                                    </Link>
                                </li>
                                <li>
                                <Link to="/fracture" className="item_card">
                                        <span className="icon"> <img src={LifetimePatientRecord} /></span>
                                        FRACTURE NECK OF FEMUR
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/serical" className="item_card">
                                        <span className="icon"> <img src={LifetimePatientRecord} /></span>
                                        SERICAL SPINAL
                                    </Link>
                                </li>
                                <li>
                                    <Link to='uc' className="item_card">
                                        <span className="icon"> <img src={LifetimePatientRecord} /></span>
                                        POST ON CALL
                                    </Link>
                                </li>
                                <li>
                                    <Link to='uc' className="item_card">
                                        <span className="icon"> <img src={LifetimePatientRecord} /></span>
                                        MEDICAL RECORDS ROOM
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/teamlist" className="item_card">
                                        <span className="icon"> <img src={LifetimePatientRecord} /></span>
                                        TEA(M) ROOM
                                    </Link>
                                </li>
                                <li>
                                    <Link to='uc' className="item_card">
                                        <span className="icon"> <img src={LifetimePatientRecord} /></span>
                                        PATIENT RECORD
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>


            <div className="modal wordnotes oncall fade" id="wordnotes" tabIndex="-1" aria-hidden="true">
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">
                        <span className="icon"><img src={diagnosis} /></span>
                        ON CALL / POST ON CALL MEETING 
                                     
                    </h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <div className="list_block">
            

                        <div className="list">
                        <Link to="/calllist" className="link">MEET</Link>
                        </div>                                        
                        <div className="list">
                        <Link to="/addpatient" className="link">ADD NEW PATIENT</Link>
                        </div>
                     
                        <div className="list">
                            <Link to='uc' className="link">REFERRALS </Link>
                        </div>
                        <div className="list">
                            <Link to='uc' className="link">WITHIN HOSPITALS </Link>
                        </div>
                        <div className="list">
                            <Link to='uc' className="link">TO REGIONAL CENTRES</Link>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>


        </React.Fragment>
    )
}

OnCall.propTypes = {
    getWards: PropTypes.func,
    getTheatres: PropTypes.func,
    wards: PropTypes.array,
    user: PropTypes.any,
    theatres: PropTypes.array,
    clinic: PropTypes.array,
    patients: PropTypes.array,
    doctors: PropTypes.array,
    profile: PropTypes.object,
    news: PropTypes.array,
}

const mapStateToProps = ({ Layout, Login }) => ({
    wards: Layout.wards,
    user: Login.user,
    theatres: Layout.theatres,
    clinic: Layout.clinic,
    patients: Layout.patients,
    doctors: Layout.doctors,
    profile: Layout.profile,
    news: Layout.news,
    dailyrounds: Layout.dailyrounds
})

const mapDispatchToProps = dispatch => ({
    getWards: () => dispatch(getWardsList()),
    getTheatres: () => dispatch(getTheatresList()),
    getClinic: () => dispatch(getClinicList()),
    getPatient: () => dispatch(getPatientList()),
    getUserprofile: (id) => dispatch(getUprofile(id)),
    getDoctor: () => dispatch(getDoctorList()),
    patientupdate: (id, data) => dispatch(updatePatient(id, data)),
    newsList: (data) => dispatch(getNews(data)),
    dailyRoundsList: (data) => dispatch(getDailyRounds(data)),
    dailyUpdate: (data) => dispatch(updateDailyRounds(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(OnCall)
