import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import MetaTags from 'react-meta-tags';
import Select from "react-select"
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { useHistory } from "react-router-dom";
import { connect } from "react-redux"
import { getWardsList, getTheatresList, getClinicList, getPatientList, getUprofile, getDoctorList, updatetheatreReports } from "../../store/actions"

const TheatreR = props => {
    const history = useHistory();
    const [theatreReports, setTheatreReport] = useState({})
    const tableRef = useRef(null);
 //   const [patientAccordion, setPatientAccordion] = useState(null)
    const [patientGroup, setpatientGroup] = useState([])
    const [patientDefault, setpatientDefault] = useState({})
    const [patientTimingPoints, setpatientTimingPoints] = useState([])
    const {
        wards, user, theatres, clinic, patients, profile, doctors
    } = props;

    useEffect(() => {
        $(".modal-backdrop").hide()
    }, [])

    useEffect(() => {
        const { getWards, getTheatres, getClinic, getDoctor, getPatient, getUserprofile } = props
        // getWards()
        // getTheatres()
        // getClinic()
        getPatient()
        getDoctor()
        let userId = localStorage.getItem("authUser")
        getUserprofile(userId)
    }, [])

    useEffect(() => {
        if (patients && patients.length > 0) {
            const patientList = []
            patients.forEach(function (item) {
                let obj = { value: item._id, label: item.firstname + " " + item.lastname }
                patientList.push(obj)
            });
            setpatientDefault({value: patients[0]._id, label:patients[0].firstname + " " + patients[0].lastname })
            setpatientGroup(patientList)
            setpatientTimingPoints([patients[0]])
        }


    }, [patients])

    const updateInputValue = (e) => {
        let val = e.target.value;
        let name = e.target.name;
        let updatedValue = {};
        updatedValue = { [name]: val };
        setTheatreReport(theatreReports => ({
            ...theatreReports,
            ...updatedValue
        }));
    }

    const updatedropdownValues = (e, name, val) => {
        //  e.preventDefault()
        let updatedValue = {};
        updatedValue = { [name]: val };
        setTheatreReport(theatreReports => ({
            ...theatreReports,
            ...updatedValue
        }));
    }

    const updateReport = (e) => {
        const { theatreReportUpdate } = props
        theatreReportUpdate(null, theatreReports)
    }

    const theatreBack = (e) => {
        e.preventDefault()
        // props.history.push("/theatre")
        history.goBack()
    }

    const handleWardSelectGroup = (selectedGroup) => {
        const index = patients.findIndex(x => x._id === selectedGroup.value);
        setpatientTimingPoints([patients[index]])
        setpatientDefault(selectedGroup)
    }

    // const accordion_patient = (id) => {
    //     if (id == patientAccordion) {
    //         id = null
    //     }
    //     setPatientAccordion(id)
    // }

    //console.log("patientDefault ", patientDefault)

    return (
        <React.Fragment>

            <MetaTags>
                <title>Theatre Reports</title>
            </MetaTags>
            <div className='popup_wrp'>
                <div className="popup_full prescribe">
                    <div className='popup_hed'>
                        <h2> THEATRE REPORTS</h2>
                        <a className='popup_close' onClick={(e) => theatreBack(e)}><span className="material-icons">close</span></a>
                    </div>
                    <div className='popup_content'>

                        <div className='popup_scroll main pr height190 '>

                            <Select
                                
                                value={patientDefault}
                                options={patientGroup}
                                classNamePrefix="select2-selection"
                                onChange={(e) => {
                                    handleWardSelectGroup(e)
                                }}
                            />

                            <DownloadTableExcel
                                filename="Timing Points"
                                sheet="timingpoints"
                                currentTableRef={tableRef.current}
                            ><button> Export excel </button>
                            </DownloadTableExcel>
                            {patientTimingPoints && patientTimingPoints.length > 0 &&
                                patientTimingPoints.map((item, i) => (
                                    <div className="section ctr check_item" key={i}>
                                        <h2 className="popup_title"> TIMING POINTS</h2>
                                        <div className="pd_border"></div>
                                        {item.patientsent || item.intoanaestheticroom || item.anaesthesiastarted || item.anaesthetizedreadyforsurgery || item.patientintotheatre || item.timeoutcompleted || item.timeoutcompleted || item.closing || item.procedurecomplete || item.anaesthesiastop || item.sighout ||
                                        item.patientoutoftheatre || item.startofrecovery || item.readytoreturntoward || item.readytoreturntoward
                                        || item.leftcomplex ? "" :"Not available" }
                                        <table className="timing_table" ref={tableRef}>
                                            <tbody>
                                                {item.patientsent &&
                                                    <tr>
                                                        <td width="40%"> <span className="point">Patient Sent for</span> </td>
                                                        <td width="60%">
                                                            <div className="tim_dsp">
                                                                <div className="sc_time">{item.patientsent.start_time}</div>
                                                                <div className="reason">{item.patientsent.reason}</div>
                                                            </div>
                                                        </td>
                                                    </tr>}
                                                {item.intoanaestheticroom &&
                                                    <tr>
                                                        <td width="40%"> <span className="point">Into Anaesthetic room for</span> </td>
                                                        <td width="60%">
                                                            <div className="tim_dsp">
                                                                <div className="sc_time">{item.intoanaestheticroom.start_time}</div>
                                                                <div className="reason">{item.intoanaestheticroom.reason}</div>
                                                            </div>
                                                        </td>
                                                    </tr>}
                                                {item.anaesthesiastarted &&
                                                    <tr>
                                                        <td width="40%"> <span className="point">Anaesthesia started for</span> </td>
                                                        <td width="60%">
                                                            <div className="tim_dsp">
                                                                <div className="sc_time">{item.anaesthesiastarted.start_time}</div>
                                                                <div className="reason">{item.anaesthesiastarted.reason}</div>
                                                            </div>
                                                        </td>
                                                    </tr>}
                                                {item.anaesthetizedreadyforsurgery &&
                                                    <tr>
                                                        <td width="40%"> <span className="point">Anaesthetized ready for surgery for</span> </td>
                                                        <td width="60%">
                                                            <div className="tim_dsp">
                                                                <div className="sc_time">{item.anaesthetizedreadyforsurgery.start_time}</div>
                                                                <div className="reason">{item.anaesthetizedreadyforsurgery.reason}</div>
                                                            </div>
                                                        </td>
                                                    </tr>}
                                                {item.patientintotheatre &&
                                                    <tr>
                                                        <td width="40%"> <span className="point">Patient Into Theatre for</span> </td>
                                                        <td width="60%">
                                                            <div className="tim_dsp">
                                                                <div className="sc_time">{item.patientintotheatre.start_time}</div>
                                                                <div className="reason">{item.patientintotheatre.reason}</div>
                                                            </div>
                                                        </td>
                                                    </tr>}
                                                {item.timeoutcompleted &&
                                                    <tr>
                                                        <td width="40%"> <span className="point">Time out completed for</span> </td>
                                                        <td width="60%">
                                                            <div className="tim_dsp">
                                                                <div className="sc_time">{item.timeoutcompleted.start_time}</div>
                                                                <div className="reason">{item.timeoutcompleted.reason}</div>
                                                            </div>
                                                        </td>
                                                    </tr>}
                                                {item.closing &&
                                                    <tr>
                                                        <td width="40%"> <span className="point">Closing for</span> </td>
                                                        <td width="60%">
                                                            <div className="tim_dsp">
                                                                <div className="sc_time">{item.closing.start_time}</div>
                                                                <div className="reason">{item.closing.reason}</div>
                                                            </div>
                                                        </td>
                                                    </tr>}
                                                {item.procedurecomplete &&
                                                    <tr>
                                                        <td width="40%"> <span className="point">Procedure complete for</span> </td>
                                                        <td width="60%">
                                                            <div className="tim_dsp">
                                                                <div className="sc_time">{item.procedurecomplete.start_time}</div>
                                                                <div className="reason">{item.procedurecomplete.reason}</div>
                                                            </div>
                                                        </td>
                                                    </tr>}
                                                {item.anaesthesiastop &&
                                                    <tr>
                                                        <td width="40%"> <span className="point">Anaesthesia stop for</span> </td>
                                                        <td width="60%">
                                                            <div className="tim_dsp">
                                                                <div className="sc_time">{item.anaesthesiastop.start_time}</div>
                                                                <div className="reason">{item.anaesthesiastop.reason}</div>
                                                            </div>
                                                        </td>
                                                    </tr>}
                                                {item.sighout &&
                                                    <tr>
                                                        <td width="40%"> <span className="point">Sigh out for</span> </td>
                                                        <td width="60%">
                                                            <div className="tim_dsp">
                                                                <div className="sc_time">{item.sighout.start_time}</div>
                                                                <div className="reason">{item.sighout.reason}</div>
                                                            </div>
                                                        </td>
                                                    </tr>}
                                                {item.patientoutoftheatre &&
                                                    <tr>
                                                        <td width="40%"> <span className="point">Patient out of theatre for</span> </td>
                                                        <td width="60%">
                                                            <div className="tim_dsp">
                                                                <div className="sc_time">{item.patientoutoftheatre.start_time}</div>
                                                                <div className="reason">{item.patientoutoftheatre.reason}</div>
                                                            </div>
                                                        </td>
                                                    </tr>}
                                                {item.startofrecovery &&
                                                    <tr>
                                                        <td width="40%"> <span className="point">Start of Recovery for</span> </td>
                                                        <td width="60%">
                                                            <div className="tim_dsp">
                                                                <div className="sc_time">{item.startofrecovery.start_time}</div>
                                                                <div className="reason">{item.startofrecovery.reason}</div>
                                                            </div>
                                                        </td>
                                                    </tr>}
                                                {item.readytoreturntoward &&
                                                    <tr>
                                                        <td width="40%"> <span className="point">Ready to return to ward for</span> </td>
                                                        <td width="60%">
                                                            <div className="tim_dsp">
                                                                <div className="sc_time">{item.readytoreturntoward.start_time}</div>
                                                                <div className="reason">{item.readytoreturntoward.reason}</div>
                                                            </div>
                                                        </td>
                                                    </tr>}

                                                {item.leftcomplex &&
                                                    <tr>
                                                        <td width="40%"> <span className="point">Left complex for</span> </td>
                                                        <td width="60%">
                                                            <div className="tim_dsp">
                                                                <div className="sc_time">{item.leftcomplex.start_time}</div>
                                                                <div className="reason">{item.leftcomplex.reason}</div>
                                                            </div>
                                                        </td>
                                                    </tr>}

                                            </tbody>
                                        </table>
                                    </div>
                                ))}

                          
                            <div className="section ctr check_item" style={{ "display": "none" }}>
                                <h2 className="popup_title">Productivity Report</h2>
                                <div className="pd_border"></div>
                                <div className="row simple_wound p0">
                                    <div className="col-md-12">
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text"
                                                className="form-control" name="delayReason" onChange={(e) => updateInputValue(e)} /><label
                                                    className="floating-label">Delay reason</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="yn_switch mb-0 ">
                                    <div className="label-container">
                                        <p>No delay</p>

                                        {theatreReports.nodelay ? <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input name="nodelay" type="radio"
                                                value={theatreReports.nodelay} checked /><span>{theatreReports.nodelay} </span></label>
                                            </div> {theatreReports.nodelay && <a onClick={(e) => updatedropdownValues(e, "nodelay", false)}><span className="material-symbols-outlined"> reply
                                            </span></a>}

                                        </div>
                                            :
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input name="nodelay" type="radio"
                                                    value="Yes" onClick={(e) => updateInputValue(e)} /><span>Yes </span></label>
                                                </div>
                                                <div className="select_btn"><label><input name="nodelay" type="radio"
                                                    value="No" onClick={(e) => updateInputValue(e)} /><span>No </span></label></div>
                                            </div>}
                                    </div>


                                </div>





                                {theatreReports.caseType ? <div className="select_btns popup_select">
                                    <div className="select_btn"><label><input name="caseType" type="radio"
                                        value={theatreReports.caseType} checked /><span>{theatreReports.caseType} </span></label>
                                    </div> {theatreReports.caseType && <a onClick={(e) => updatedropdownValues(e, "caseType", false)}><span className="material-symbols-outlined"> reply
                                    </span></a>}

                                </div>
                                    :
                                    <div className="select_btns popup_select">

                                        <div className="select_btn"><label><input name="caseType" type="radio"
                                            value="Case Bumped" onClick={(e) => updateInputValue(e)} /><span>Case Bumped </span></label>
                                        </div>
                                        <div className="select_btn"><label><input name="caseType" type="radio"
                                            value="Emergency" onClick={(e) => updateInputValue(e)} /><span>Emergency </span></label></div>
                                    </div>}



                                {theatreReports.patientcondition ? <div className="select_btns popup_select">
                                    <div className="select_btn"><label><input name="patientcondition" type="radio"
                                        value={theatreReports.patientcondition} checked /><span>{theatreReports.patientcondition} </span></label>
                                    </div> {theatreReports.patientcondition && <a onClick={(e) => updatedropdownValues(e, "patientcondition", false)}><span className="material-symbols-outlined"> reply
                                    </span></a>}

                                </div>
                                    :
                                    <div className="select_btns popup_select margins">
                                        <div className="select_btn"><label><input name="patientcondition" type="radio"
                                            value="Patient" onClick={(e) => updateInputValue(e)} /><span>Patient </span></label>
                                        </div>
                                        <div className="select_btn"><label><input name="patientcondition" type="radio"
                                            value="Condition" onClick={(e) => updateInputValue(e)} /><span>Condition </span></label></div>
                                        <div className="select_btn"><label><input name="patientcondition" type="radio"
                                            value="Inadequate work up" onClick={(e) => updateInputValue(e)} /><span>Inadequate work up </span></label></div>
                                        <div className="select_btn"><label><input name="patientcondition" type="radio"
                                            value="Late" onClick={(e) => updateInputValue(e)} /><span>Late </span></label></div>
                                        <div className="select_btn"><label><input name="patientcondition" type="radio"
                                            value="NPO" onClick={(e) => updateInputValue(e)} /><span>NPO </span></label></div>
                                        <div className="select_btn"><label><input name="patientcondition" type="radio"
                                            value="Not ready nursing unit" onClick={(e) => updateInputValue(e)} /><span>Not ready nursing unit </span></label></div>
                                        <div className="select_btn"><label><input name="patientcondition" type="radio"
                                            value="Prep not complete Previous case delay" onClick={(e) => updateInputValue(e)} /><span>Prep not complete Previous case delay
                                            </span></label></div>
                                        <div className="select_btn"><label><input name="patientcondition" type="radio"
                                            value="Previous case length" onClick={(e) => updateInputValue(e)} /><span>Previous case length </span></label></div>
                                    </div>}


                                {theatreReports.patientissue ? <div className="select_btns popup_select">
                                    <div className="select_btn"><label><input name="patientissue" type="radio"
                                        value={theatreReports.patientissue} checked /><span>{theatreReports.patientissue} </span></label>
                                    </div> {theatreReports.patientissue && <a onClick={(e) => updatedropdownValues(e, "patientissue", false)}><span className="material-symbols-outlined"> reply
                                    </span></a>}

                                </div>
                                    :
                                    <div>
                                        <div className="select_btns popup_select margins">
                                            <div className="select_btn"><label><input name="patientissue" type="radio"
                                                value="Anaesthesia" onClick={(e) => updateInputValue(e)} /><span>Anaesthesia </span></label>
                                            </div>
                                            <div className="select_btn"><label><input name="patientissue" type="radio"
                                                value="Epidural" onClick={(e) => updateInputValue(e)} /><span>Epidural </span></label>
                                            </div>
                                            <div className="select_btn"><label><input name="patientissue" type="radio"
                                                value="Training" onClick={(e) => updateInputValue(e)} /><span>Training </span></label></div>
                                            <div className="select_btn"><label><input name="patientissue" type="radio"
                                                value="Late" onClick={(e) => updateInputValue(e)} /><span>Late </span></label></div>
                                            <div className="select_btn"><label><input name="patientissue" type="radio"
                                                value="Lunch" onClick={(e) => updateInputValue(e)} /><span>Lunch </span></label></div>
                                            <div className="select_btn"><label><input name="patientissue" type="radio"
                                                value="Out of Department" onClick={(e) => updateInputValue(e)} /><span>Out of Department </span></label></div>
                                            <div className="select_btn"><label><input name="patientissue" type="radio"
                                                value="With patient
                                        Chart" onClick={(e) => updateInputValue(e)} /><span>With patient
                                                    Chart </span></label></div>
                                            <div className="select_btn"><label><input name="patientissue" type="radio"
                                                value="Consent incomplete" onClick={(e) => updateInputValue(e)} /><span>Consent incomplete
                                                </span></label></div>
                                            <div className="select_btn"><label><input name="patientissue" type="radio"
                                                value="History and physical incomplete" onClick={(e) => updateInputValue(e)} /><span>History and physical incomplete </span></label>
                                            </div>
                                            <div className="select_btn"><label><input name="patientissue" type="radio"
                                                value="Missing test results" onClick={(e) => updateInputValue(e)} /><span>Missing test results </span></label></div>
                                            <div className="select_btn"><label><input name="patientissue" type="radio"
                                                value="Lost information" onClick={(e) => updateInputValue(e)} /><span>Lost information </span></label></div>
                                            <div className="select_btn"><label><input name="patientissue" type="radio"
                                                value="Prep incomplete" onClick={(e) => updateInputValue(e)} /><span>Prep incomplete </span></label></div>
                                        </div>
                                        <div className="select_btns popup_select margins">
                                            <p className="field_title mt-0">Or</p>

                                            <div className="select_btn"><label><input name="patientissue" type="radio"
                                                value="Equipment Unavailable" onClick={(e) => updateInputValue(e)} /><span>Equipment Unavailable  </span></label>
                                            </div>
                                            <div className="select_btn"><label><input name="patientissue" type="radio"
                                                value="Room not Ready" onClick={(e) => updateInputValue(e)} /><span>Room not Ready </span></label></div>
                                            <div className="select_btn"><label><input name="patientissue" type="radio"
                                                value="Staff unavailable" onClick={(e) => updateInputValue(e)} /><span>Staff unavailable  </span></label></div>
                                            <div className="select_btn"><label><input name="patientissue" type="radio"
                                                value="Equipment unavailable vendor" onClick={(e) => updateInputValue(e)} /><span>Equipment unavailable vendor  </span></label></div>
                                            <div className="select_btn"><label><input name="patientissue" type="radio"
                                                value="Turnover help unavailable" onClick={(e) => updateInputValue(e)} /><span>Turnover help unavailable</span></label></div>

                                        </div></div>}




                                <div className="yn_switch  mt-10">
                                    <div className="label-container">
                                        <p>Radiology technician unavailable</p>
                                        {theatreReports.radiology ? <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input name="radiology" type="radio"
                                                value={theatreReports.radiology} checked /><span>{theatreReports.radiology} </span></label>
                                            </div> {theatreReports.radiology && <a onClick={(e) => updatedropdownValues(e, "radiology", false)}><span className="material-symbols-outlined"> reply
                                            </span></a>}

                                        </div>
                                            :
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input name="radiology" type="radio"
                                                    value="Yes" onClick={(e) => updateInputValue(e)} /><span>Yes </span></label>
                                                </div>
                                                <div className="select_btn"><label><input name="radiology" type="radio"
                                                    value="No" onClick={(e) => updateInputValue(e)} /><span>No </span></label></div>
                                            </div>}
                                    </div>


                                </div>
                                <div className="yn_switch mb-0 ">
                                    <div className="label-container">
                                        <p>Surgeon late Surgeon lunch Training Surgeon out of Department</p>

                                        {theatreReports.surgeonlate ? <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input name="surgeonlate" type="radio"
                                                value={theatreReports.surgeonlate} checked /><span>{theatreReports.surgeonlate} </span></label>
                                            </div> {theatreReports.surgeonlate && <a onClick={(e) => updatedropdownValues(e, "surgeonlate", false)}><span className="material-symbols-outlined"> reply
                                            </span></a>}

                                        </div>
                                            :
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input name="surgeonlate" type="radio"
                                                    value="Yes" onClick={(e) => updateInputValue(e)} /><span>Yes </span></label>
                                                </div>
                                                <div className="select_btn"><label><input name="surgeonlate" type="radio"
                                                    value="No" onClick={(e) => updateInputValue(e)} /><span>No </span></label></div>
                                            </div>}
                                    </div>
                                </div>



                                <div className="yn_switch mb-0 ">
                                    <div className="label-container">
                                        <p>Other</p>
                                        {theatreReports.other ? <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input name="other" type="radio"
                                                value={theatreReports.other} checked /><span>{theatreReports.other} </span></label>
                                            </div> {theatreReports.other && <a onClick={(e) => updatedropdownValues(e, "other", false)}><span className="material-symbols-outlined"> reply
                                            </span></a>}

                                        </div>
                                            :
                                            <div className="select_btns popup_select margins">

                                                <div className="select_btn"><label><input name="other" type="radio"
                                                    value="Diclofenac" onClick={(e) => updateInputValue(e)} /><span>Diclofenac</span></label>
                                                </div>
                                                <div className="select_btn"><label><input name="other" type="radio"
                                                    value="Omnigel" onClick={(e) => updateInputValue(e)} /><span>Omnigel</span></label></div>
                                                <div className="select_btn"><label><input name="other" type="radio"
                                                    value="Bevon Suspension" onClick={(e) => updateInputValue(e)} /><span>Bevon Suspension  </span></label></div>
                                            </div>}

                                    </div>
                                </div>


                            </div>


                            <div className="section">
                                <h2 className="popup_title">PRODUCTIVITY METRICS</h2>
                                <div className="pd_border"></div>
                                <p className="field_title mt-0">Other {theatreReports.productivity_metrics_other && <a onClick={(e) => updatedropdownValues(e, "productivity_metrics_other", false)}><span className="material-symbols-outlined"> reply
                                </span></a>}</p>

                                {theatreReports.productivity_metrics_other ? <div className="check_list">
                                    <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_other", false)}><label className="check">{theatreReports.productivity_metrics_other}</label></div>
                                </div>
                                    :
                                    <div className="check_list">
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_other", "First case starts")}><label className="check">First case starts</label></div>
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_other", "Turnover times")}><label className="check">Turnover times </label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_other", "Percent of locations used and what times are they used")}><label className="check"> Percent of locations used and what times are they used </label></div>
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_other", "Complications")}><label className="check">Complications </label></div>
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_other", "Value-based purchasing")}><label className="check">Value-based purchasing </label></div>
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_other", "Consistency of service")}><label className="check">Consistency of service </label></div>
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_other", "Outcomes")}><label className="check">Outcomes
                                        </label></div>
                                    </div>}


                                <p className="field_title mt-0">OR efficiency metrics 5 categories
                                    {theatreReports.productivity_metrics_efficiency && <a onClick={(e) => updatedropdownValues(e, "productivity_metrics_efficiency", false)}><span className="material-symbols-outlined"> reply
                                    </span></a>}
                                </p>
                                {theatreReports.productivity_metrics_efficiency ? <div className="check_list">
                                    <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_efficiency", false)}><label className="check">{theatreReports.productivity_metrics_efficiency}</label></div>
                                </div>
                                    :
                                    <div className="check_list">
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_efficiency", "Suite Utilization & Scheduling Accuracy")}><label className="check">Suite Utilization & Scheduling Accuracy</label></div>
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_efficiency", "Pre-Admission Testing & Case Cancellations")}><label className="check">Pre-Admission Testing & Case Cancellations</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_efficiency", "Case Start Timeliness")}><label className="check"> Case Start Timeliness</label></div>
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_efficiency", "Patient Out to Patient In & Close to Cut")}><label className="check">Patient Out to Patient In & Close to Cut </label></div>
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_efficiency", "Staffing")}><label className="check">Staffing </label></div>

                                    </div>
                                }
                                <p className="field_title mt-0">Suite Utilization & Scheduling Accuracy <br />
                                    KPIs (key performance indicators) or metrics to watch in this category are  {theatreReports.productivity_metrics_accuracy && <a onClick={(e) => updatedropdownValues(e, "productivity_metrics_accuracy", false)}><span className="material-symbols-outlined"> reply
                                    </span></a>}
                                </p>
                                {theatreReports.productivity_metrics_accuracy ? <div className="check_list">
                                    <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_accuracy", false)}><label className="check">{theatreReports.productivity_metrics_accuracy}</label></div>
                                </div>
                                    :
                                    <div className="check_list">
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_accuracy", "Suite Utilization")}><label className="check">Suite Utilization</label></div>
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_accuracy", "Scheduling Accuracy")}><label className="check">Scheduling Accuracy</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_accuracy", "Average Inaccurate Minutes per Case")}><label className="check"> Average Inaccurate Minutes per Case</label></div>
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_accuracy", "Unused Minutes")}><label className="check">Unused Minutes</label></div>
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_accuracy", "Delay Minutes")}><label className="check">Delay Minutes</label></div>
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_accuracy", "Add-on vs Scheduled cases")}><label className="check">Add-on vs Scheduled cases</label></div>
                                    </div>
                                }

                                <p className="field_title mt-0">In order to improve on these metrics, you should consider {theatreReports.productivity_metrics_huddles && <a onClick={(e) => updatedropdownValues(e, "productivity_metrics_huddles", false)}><span className="material-symbols-outlined"> reply
                                </span></a>}
                                </p>
                                {theatreReports.productivity_metrics_huddles ? <div className="check_list">
                                    <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_huddles", false)}><label className="check">{theatreReports.productivity_metrics_huddles}</label></div>
                                </div>
                                    :
                                    <div className="check_list">
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_huddles", "Daily Schedule Management Huddles")}><label className="check">Daily Schedule Management Huddles</label></div>
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_huddles", "Guidelines for case scheduling accuracy")}><label className="check">Guidelines for case scheduling accuracy</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_huddles", "Modified block time with some first come, first served time")}><label className="check"> Modified block time with some first come, first served time</label></div>
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_huddles", "Minimum utilization threshold for block allocation")}><label className="check">Minimum utilization threshold for block allocation</label></div>
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_huddles", "Release times based on specialty")}><label className="check">Release times based on specialty</label></div>
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_huddles", "Management of voluntary block release")}><label className="check">Management of voluntary block release</label></div>
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_huddles", "Balance of cases throughout the week")}><label className="check">Balance of cases throughout the week</label></div>
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_huddles", "Case times based upon historical data")}><label className="check">Case times based upon historical data</label></div>
                                    </div>
                                }


                                <p className="field_title mt-0">Pre-Admission Testing & Case Cancellations - KPIs to watch in this category include {theatreReports.productivity_metrics_preadmission && <a onClick={(e) => updatedropdownValues(e, "productivity_metrics_preadmission", false)}><span className="material-symbols-outlined"> reply
                                </span></a>}
                                </p>
                                {theatreReports.productivity_metrics_preadmission ? <div className="check_list">
                                    <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_preadmission", false)}><label className="check">{theatreReports.productivity_metrics_preadmission}</label></div>
                                </div>
                                    :
                                    <div className="check_list">
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_preadmission", "Percent of cancelled cases")}><label className="check">Percent of cancelled cases</label></div>
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_preadmission", "Cancel reasons")}><label className="check">Cancel reasons</label>
                                        </div>

                                    </div>}

                                <p className="field_title mt-0">Implementing proper protocols for pre-admission testing can have significant impact on these efficiency metrics: {theatreReports.productivity_metrics_protocols && <a onClick={(e) => updatedropdownValues(e, "productivity_metrics_protocols", false)}><span className="material-symbols-outlined"> reply
                                </span></a>}
                                </p>
                                {theatreReports.productivity_metrics_protocols ? <div className="check_list">
                                    <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_protocols", false)}><label className="check">{theatreReports.productivity_metrics_protocols}</label></div>
                                </div>
                                    :
                                    <div className="check_list">
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_protocols", "Pre-registration process to expedite day of surgery flow")}><label className="check">Pre-registration process to expedite day of surgery flow</label></div>
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_protocols", "Pre-certification process to ensure reimbursement")}><label className="check">Pre-certification process to ensure reimbursement</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_protocols", "Mandatory pre-admission processes")}><label className="check"> Mandatory pre-admission processes</label></div>
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_protocols", "Telephone screening for low risk patients & surgeries")}><label className="check">Telephone screening for low risk patients & surgeries</label></div>
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_protocols", "Streamlined diagnostic requirements based on protocols")}><label className="check">Streamlined diagnostic requirements based on protocols</label></div>
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_protocols", "Criteria for consults")}><label className="check">Criteria for consults</label></div>
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_protocols", "Deadline for elective chart completion")}><label className="check">Deadline for elective chart completion</label></div>

                                    </div>}


                                <p className="field_title mt-0">Case Start Timeliness - Some metrics to capture your effectiveness on this front include {theatreReports.productivity_metrics_casestart && <a onClick={(e) => updatedropdownValues(e, "productivity_metrics_casestart", false)}><span className="material-symbols-outlined"> reply
                                </span></a>}
                                </p>
                                {theatreReports.productivity_metrics_casestart ? <div className="check_list">
                                    <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_casestart", false)}><label className="check">{theatreReports.productivity_metrics_casestart}</label></div>
                                </div>
                                    :
                                    <div className="check_list">
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_casestart", "First Case On-Time Starts")}><label className="check">First Case On-Time Starts</label></div>
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_casestart", "Subsequent Case On-Time Starts")}><label className="check">Subsequent Case On-Time Starts</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_casestart", "After Hours Cases and Minutes")}><label className="check"> After Hours Cases and Minutes</label></div>
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_casestart", "Delay Minutes")}><label className="check">Delay Minutes</label></div>
                                    </div>}


                                <p className="field_title mt-0">Here are some elements to consider in order to improve case start timeliness {theatreReports.productivity_metrics_timeliness && <a onClick={(e) => updatedropdownValues(e, "productivity_metrics_timeliness", false)}><span className="material-symbols-outlined"> reply
                                </span></a>}
                                </p>
                                {theatreReports.productivity_metrics_timeliness ? <div className="check_list">
                                    <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_timeliness", false)}><label className="check">{theatreReports.productivity_metrics_timeliness}</label></div>
                                </div>
                                    :
                                    <div className="check_list">
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_timeliness", "Start Time Matrix with clearly defined start time")}><label className="check">Start Time Matrix with clearly defined start time</label></div>
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_timeliness", "Patient in room 5 minutes prior to posted start time")}><label className="check">Patient in room 5 minutes prior to posted start time</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_timeliness", "Room set-up teams")}><label className="check"> Room set-up teams</label></div>
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_timeliness", "Dedicated support personnel")}><label className="check">Dedicated support personnel</label></div>
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_timeliness", "Root cause analysis to determine issues")}><label className="check">Root cause analysis to determine issues</label></div>
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_timeliness", "Active governance structure to deal with issues")}><label className="check">Active governance structure to deal with issues</label></div>
                                    </div>}


                                <p className="field_title mt-0">Patient Out to Patient In & Close to Cut - Metrics that capture these times include: {theatreReports.productivity_metrics_outpatient && <a onClick={(e) => updatedropdownValues(e, "productivity_metrics_outpatient", false)}><span className="material-symbols-outlined"> reply
                                </span></a>}
                                </p>
                                {theatreReports.productivity_metrics_outpatient ? <div className="check_list">
                                    <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_outpatient", false)}><label className="check">{theatreReports.productivity_metrics_outpatient}</label></div>
                                </div>
                                    :
                                    <div className="check_list">
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_outpatient", "Turnover Time – Room (Patient-Out to Patient-In)")}><label className="check">Turnover Time – Room (Patient-Out to Patient-In)</label></div>
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_outpatient", "Turnover Time – Surgeon (Close to Cut)")}><label className="check">Turnover Time – Surgeon (Close to Cut)</label>
                                        </div>
                                    </div>}


                                <p className="field_title mt-0">Understanding these metrics for your operating room can help you decide which one of the following improvement areas will have the biggest impact on your operations: {theatreReports.productivity_metrics_improvement && <a onClick={(e) => updatedropdownValues(e, "productivity_metrics_improvement", false)}><span className="material-symbols-outlined"> reply
                                </span></a>}
                                </p>
                                {theatreReports.productivity_metrics_improvement ? <div className="check_list">
                                    <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_improvement", false)}><label className="check">{theatreReports.productivity_metrics_improvement}</label></div>
                                </div>
                                    :
                                    <div className="check_list">
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_improvement", "Proactive schedule management for case sequencing")}><label className="check">Proactive schedule management for case sequencing</label></div>
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_improvement", "Defined Patient Out to Patient In targets, by specialty")}><label className="check">Defined Patient Out to Patient In targets, by specialty</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_improvement", "Defined criteria and effective use of flip rooms")}><label className="check"> Defined criteria and effective use of "flip rooms"</label></div>
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_improvement", "Turnover teams for room clean-up and set-up")}><label className="check">Turnover teams for room clean-up and set-up</label></div>
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_improvement", "Communication between team members")}><label className="check">Communication between team members</label></div>
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_improvement", "Complete and accurate preference card and case carts")}><label className="check">Complete and accurate preference card and case carts</label></div>
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_improvement", "Appropriate instrument resources")}><label className="check">Appropriate instrument resources</label></div>
                                    </div>}


                                <p className="field_title mt-0">Staffing - You can consider the following checklist for improving how staffing is done for your OR: {theatreReports.productivity_metrics_staffing && <a onClick={(e) => updatedropdownValues(e, "productivity_metrics_staffing", false)}><span className="material-symbols-outlined"> reply
                                </span></a>}
                                </p>
                                {theatreReports.productivity_metrics_staffing ? <div className="check_list">
                                    <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_staffing", false)}><label className="check">{theatreReports.productivity_metrics_staffing}</label></div>
                                </div>
                                    :
                                    <div className="check_list">
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_staffing", "Defined hours of operation")}><label className="check">Defined hours of operation</label></div>
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_staffing", "Staffing pattern aligned with case demand pattern")}><label className="check">Staffing pattern aligned with case demand pattern</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_staffing", "Appropriate skill mix")}><label className="check"> Appropriate skill mix</label></div>
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_staffing", "Adequate room coverage")}><label className="check">Adequate room coverage</label></div>
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_staffing", "Compressed daily schedule to eliminate gaps")}><label className="check">Compressed daily schedule to eliminate gaps</label></div>
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_staffing", "Surgeon, or service-specific teams")}><label className="check">Surgeon, or service-specific teams</label></div>
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_staffing", "Prudent use of call teams")}><label className="check">Prudent use of call teams</label></div>
                                    </div>}


                                <p className="field_title mt-0">Surgical Case Duration Accuracy - Think about all the possible factors that impact a procedure: <br /> <b>Patient</b>  {theatreReports.productivity_metrics_surgical && <a onClick={(e) => updatedropdownValues(e, "productivity_metrics_surgical", false)}><span className="material-symbols-outlined"> reply </span></a>}
                                </p>
                                {theatreReports.productivity_metrics_surgical ? <div className="check_list">
                                    <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_surgical", false)}><label className="check">{theatreReports.productivity_metrics_surgical}</label></div>
                                </div>
                                    :
                                    <div className="check_list">
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_surgical", "Age & Sex")}><label className="check">Age & Sex</label></div>
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_surgical", "Height & Weight")}><label className="check">Height & Weight</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_surgical", "Allergies")}><label className="check">Allergies</label></div>
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_surgical", "History and Physical Examination (H&P)")}><label className="check">History and Physical Examination (H&P)</label></div>
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_surgical", "ASA Status (American Society of Anesthesiologists physical status)")}><label className="check">ASA Status (American Society of Anesthesiologists physical status)</label></div>
                                    </div>}


                                <p className="field_title mt-0">Providers  {theatreReports.productivity_metrics_providers && <a onClick={(e) => updatedropdownValues(e, "productivity_metrics_providers", false)}><span className="material-symbols-outlined"> reply </span></a>}
                                </p>
                                {theatreReports.productivity_metrics_providers ? <div className="check_list">
                                    <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_providers", false)}><label className="check">{theatreReports.productivity_metrics_providers}</label></div>
                                </div>
                                    :
                                    <div className="check_list">
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_providers", "Surgeon(s)")}><label className="check">Surgeon(s)</label></div>
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_providers", "Anesthesiologist(s)")}><label className="check">Anesthesiologist(s)</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_providers", "Scrub nurse(s) and tech(s)")}><label className="check">Scrub nurse(s) and tech(s)</label></div>
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_providers", "Circulator nurse(s) and tech(s)")}><label className="check">Circulator nurse(s) and tech(s)</label></div>

                                    </div>}


                                <div className="leavel_1">
                                    {/* <h2 className="popup_title">Providers </h2> */}
                                    <h2 className="selected">Surgeon(s)</h2>
                                </div>
                                <p className="field_title mt-0">Facility / Room   {theatreReports.productivity_metrics_facility && <a onClick={(e) => updatedropdownValues(e, "productivity_metrics_facility", false)}><span className="material-symbols-outlined"> reply </span></a>}
                                </p>
                                {theatreReports.productivity_metrics_facility ? <div className="check_list">
                                    <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_facility", false)}><label className="check">{theatreReports.productivity_metrics_facility}</label></div>
                                </div>
                                    :
                                    <div className="check_list">
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_facility", "Bed census")}><label className="check">Bed census</label></div>
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_facility", "Equipment")}><label className="check">Equipment</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_facility", "Staff")}><label className="check">Staff</label></div>
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_facility", "Day of week")}><label className="check">Day of week</label></div>
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_facility", "Time of day")}><label className="check">Time of day</label></div>

                                    </div>}


                                <p className="field_title mt-0">Procedure {theatreReports.productivity_metrics_procedure && <a onClick={(e) => updatedropdownValues(e, "productivity_metrics_procedure", false)}><span className="material-symbols-outlined"> reply </span></a>}
                                </p>
                                {theatreReports.productivity_metrics_procedure ? <div className="check_list">
                                    <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_procedure", false)}><label className="check">{theatreReports.productivity_metrics_procedure}</label></div>
                                </div>
                                    :
                                    <div className="check_list">
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_procedure", "Procedure type")}><label className="check">Procedure type</label></div>
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_procedure", "Surgeon comments")}><label className="check">Surgeon comments</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_procedure", "Procedure modifiers")}><label className="check">Procedure modifiers</label></div>
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_procedure", "Anaesthesia type")}><label className="check">Anaesthesia type</label></div>
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_procedure", "Implants/Tissues")}><label className="check">Implants/Tissues</label></div>
                                    </div>}


                                <p className="field_title mt-0">Prior Events {theatreReports.productivity_metrics_priorevents && <a onClick={(e) => updatedropdownValues(e, "productivity_metrics_priorevents", false)}><span className="material-symbols-outlined"> reply </span></a>}
                                </p>
                                {theatreReports.productivity_metrics_priorevents ? <div className="check_list">
                                    <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_priorevents", false)}><label className="check">{theatreReports.productivity_metrics_priorevents}</label></div>
                                </div>
                                    :
                                    <div className="check_list">
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_priorevents", "Timing of prior perioperative milestones")}><label className="check">Timing of prior perioperative milestones</label></div>
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_priorevents", "Case delays")}><label className="check">Case delays</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_priorevents", "Cancellations")}><label className="check">Cancellations</label></div>
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_priorevents", "Turnover")}><label className="check">Turnover</label></div>

                                    </div>}


                                <p className="field_title mt-0">Any single of one of these factors can have drastic impact {theatreReports.productivity_metrics_drasticimpact && <a onClick={(e) => updatedropdownValues(e, "productivity_metrics_drasticimpact", false)}><span className="material-symbols-outlined"> reply </span></a>}
                                </p>
                                {theatreReports.productivity_metrics_drasticimpact ? <div className="check_list">
                                    <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_drasticimpact", false)}><label className="check">{theatreReports.productivity_metrics_drasticimpact}</label></div>
                                </div>
                                    :
                                    <div className="check_list">
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_drasticimpact", "Streamlined Cross-Team Communication")}><label className="check">Streamlined Cross-Team Communication</label></div>
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_drasticimpact", "Accurate PACU census predictions")}><label className="check">Accurate PACU census predictions</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_drasticimpact", "Staff Scheduling")}><label className="check">Staff Scheduling</label></div>
                                        <div className="check_item" onClick={(e) => updatedropdownValues(e, "productivity_metrics_drasticimpact", "Vendor Management")}><label className="check">Vendor Management</label></div>

                                    </div>}
                            </div>

                        </div>
                    </div>
                    <div className='popup_footer'>
                        <div className="prescribe_footer">
                            <div className="dsp-flex">
                                <a className="icon_btn" onClick={(e) => { updateReport(e) }} >  <span className="material-icons">save</span> SAVE</a>
                                <button className="border_btn" onClick={(e) => { updateReport(e), theatreBack(e) }}><span className="material-icons">save_as</span>SAVE & CLOSE
                                </button>
                            </div>

                            <button className="btn dsp_flex" onClick={(e) => theatreBack(e)}><span className="material-icons"><span
                                className="material-icons">close</span></span>DISCARD </button>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

TheatreR.propTypes = {
    t: PropTypes.any,
    getWards: PropTypes.func,
    getTheatres: PropTypes.func,
    wards: PropTypes.array,
    user: PropTypes.any,
    theatres: PropTypes.array,
    clinic: PropTypes.array,
    patients: PropTypes.array,
    doctors: PropTypes.array,
    profile: PropTypes.object
}

const mapStateToProps = ({ Layout, Login }) => ({
    wards: Layout.wards,
    user: Login.user,
    theatres: Layout.theatres,
    clinic: Layout.clinic,
    patients: Layout.patients,
    doctors: Layout.doctors,
    profile: Layout.profile
})

const mapDispatchToProps = dispatch => ({
    getWards: () => dispatch(getWardsList()),
    getTheatres: () => dispatch(getTheatresList()),
    getClinic: () => dispatch(getClinicList()),
    getPatient: () => dispatch(getPatientList()),
    getUserprofile: (id) => dispatch(getUprofile(id)),
    getDoctor: () => dispatch(getDoctorList()),
    theatreReportUpdate: (id, data) => dispatch(updatetheatreReports(id, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(TheatreR)
