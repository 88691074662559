import React, { useState, useContext, useEffect, useRef } from "react";
import { Input, Button, Tooltip, Modal, message } from "antd";
//import Phone from "../../assets/images/vc/phone.gif";
import Teams from "../../assets/images/vc/teams.mp3";
import "./Options.css";
import { CopyToClipboard } from "react-copy-to-clipboard";
import VideoContext from "../../pages/context/VideoContext";

import { updatePatient } from "../../store/actions"
import { connect } from "react-redux"
// import Hang from "../../assets/images/vc/hang.svg";
// import {
//   TwitterIcon,
//   TwitterShareButton,
//   WhatsappShareButton,
//   WhatsappIcon,
//   FacebookIcon,
//   FacebookShareButton,
// } from "react-share";
import {
  UserOutlined,
  CopyOutlined,
  InfoCircleOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
//import { socket } from "../../context/VideoState";

const Options = (props) => {

  const {
    profile
  } = props;


  const [idToCall, setIdToCall] = useState("");

  const [isModalVisible, setIsModalVisible] = useState(false);
  const Audio = useRef();
  const {
    call,
    callAccepted,
    myVideo,
    userVideo,
    stream,
    name,
    setName,
    callEnded,
    me,
    callUser,
    leaveCall,
    answerCall,
    otherUser,
    setOtherUser,
    leaveCall1,
  } = useContext(VideoContext);

  useEffect(() => {
    if (isModalVisible) {
      Audio?.current?.play();
    } else Audio?.current?.pause();
  }, [isModalVisible]);

  const showModal = (showVal) => {
    setIsModalVisible(showVal);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    leaveCall1();
    window.location.reload();
  };
  useEffect(() => {
    if (call.isReceivingCall && !callAccepted) {
      setIsModalVisible(true);
      setOtherUser(call.from);
    } else setIsModalVisible(false);
  }, [call.isReceivingCall]);

  useEffect(() => {
    console.log("meeeee ", me)
    if(me){
      joinCallPatient(me)
    }

  }, [me]);

  useEffect(() => {
    console.log("profileeeee ", profile)
    if(profile && profile.call){
      //joinCallPatient(me)
      console.log("profile.call ", profile.call)
      setIdToCall(profile.call)
      // setTimeout(() => {
      //   callUser(profile.call)
      // }, 1000)

      
    }
    if(profile && profile.firstname && profile.lastname){
        localStorage.setItem("name", profile.firstname +' '+ profile.lastname);
        setName(profile.firstname +' '+ profile.lastname);
    }

  }, [profile]);

  const joinCallPatient = (code) => {
    let pdetails = JSON.parse(localStorage.getItem("p"))
    if(pdetails && pdetails._id && code){
      const { patientupdate } = props
      let updateObj = {
        "call": code
      }
      patientupdate(pdetails._id, updateObj)
    }
    
  }




  console.log("profile ",profile && profile.call)


  return (
    <div className="none">
     
       

         {/* <button onClick={() => {callUser(profile.call); setIdToCall(profile.call)}}>Joined {profile && profile.call}</button> */}

        {/* 
        <Input
          size="large"
          placeholder="Your name"
          prefix={<UserOutlined />}
          maxLength={15}
          suffix={<small>{name.length}/15</small>}
          value={name}
          onChange={(e) => {
            setName(e.target.value);
            localStorage.setItem("name", e.target.value);
          }}
          
        /> */}

       
        {/* <h3>  {me}</h3>  */}

          {/* <CopyToClipboard text={me}>
            <Button
              type="primary"
              icon={<CopyOutlined />}
              tabIndex="0"
              onClick={() => message.success("Code copied successfully!")}
            >
              Copy code
            </Button>
          </CopyToClipboard> */}

          {/* <div className={classes.share_social}>
            <WhatsappShareButton
              url={`https://video-chat-mihir.vercel.app/`}
              title={`Join this meeting with the given code "${me}"\n`}
              separator="Link: "
              className={classes.share_icon}
            >
              <WhatsappIcon size={26} round />
            </WhatsappShareButton>
            <FacebookShareButton
              url={`https://video-chat-mihir.vercel.app/`}
              title={`Join this meeting with the given code "${me}"\n`}
              className={classes.share_icon}
            >
              <FacebookIcon size={26} round />
            </FacebookShareButton>
            <TwitterShareButton
              url={`https://video-chat-mihir.vercel.app/`}
              title={`Join this meeting with the given code  "${me}"\n`}
              className={classes.share_icon}
            >
              <TwitterIcon size={26} round className={classes.share_border} />
            </TwitterShareButton>
          </div> */}
     
        {/* <h2>Enter Code</h2> */}

        {/* <Input
          placeholder="Enter code to call"
          size="large"
          value={idToCall}
          onChange={(e) => setIdToCall(e.target.value)}
          style={{ marginRight: "0.5rem", marginBottom: "0.5rem" }}
          prefix={<UserOutlined className="site-form-item-icon" />}
          suffix={
            <Tooltip title="Enter code of the other user">
              <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
            </Tooltip>
          }
        /> */}

    

         {callAccepted && !callEnded && (
          <Button
            variant="contained"
            onClick={leaveCall}
            tabIndex="0"
          >
            Hang up
          </Button>
        ) } 


        {profile && profile.call && !callAccepted && callEnded &&  (
          <Button
            type="primary"
            icon={<PhoneOutlined />}
            onClick={() => {callUser(idToCall)}}
            tabIndex="0"
          >
            Join Now
          </Button>
        )}

  

      {call.isReceivingCall && !callAccepted && (
        <>
          <audio src={Teams} loop ref={Audio} />
        
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <h1>
                {call.name} is calling you:{" "}
                Phone
              </h1>
            </div>
            <div >
              <Button
                variant="contained"
                color="#29bb89"
                icon={<PhoneOutlined />}
                onClick={() => {
                  answerCall();
                  Audio.current.pause();
                }}
                tabIndex="0"
              >
                Answer
              </Button>
              <Button
                variant="contained"
                icon={<PhoneOutlined />}
                onClick={() => {
                  setIsModalVisible(false);
                  Audio.current.pause();
                }}
                tabIndex="0"
              >
                Decline
              </Button>
            </div>
         
        </>
      )}

    </div>
  );
};

const mapStateToProps = ({ Layout }) => ({
  profile: Layout.profile
})

const mapDispatchToProps = dispatch => ({
  patientupdate: (id, data) => dispatch(updatePatient(id, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Options)