import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import MetaTags from 'react-meta-tags';
// import Select from "react-select"
import PatientBlock from "../Clinic/patientBlock";
import xray from '../../assets/images/x-ray.jpg';
import doc from '../../assets/images/doc.jpg';
import back from '../../assets/images/icons/BACK.jpg';
import Breathing from '../../assets/images/icons/Breathing.png';
import cvs from '../../assets/images/icons/CVS.jpg';
import extremities from '../../assets/images/icons/EXTREMITIES.jpg';
import headneck from '../../assets/images/icons/HEAD-NECK.jpg';
import neuro from '../../assets/images/icons/NEURO.jpg';
import pelvis from '../../assets/images/icons/PELVIS.jpg';
import Menus from "./onCallMenus";
import { connect } from "react-redux";
import {
    Modal
} from "reactstrap"
import { getWardsList, getTheatresList, getClinicList, getPatientList, getUprofile, getDoctorList, updatePatient, getNews, updateDailyRounds, getDailyRounds, teamListUpdate } from "../../store/actions"



const AddNewPatient = props => {
    document.body.style.overflow = '';
    document.body.style.padding = '';
    let clinicPatient = JSON.parse(localStorage.getItem("p"))
    const [discard_popup, setdiscard_popup] = useState(false)
    const [allied_value, setallied_value] = useState(null)
    const [clinicValues, setClinic] = useState({})
    const [patientValues, setPatient] = useState({})
    const [success_msg, setsuccess_msg] = useState(false)
    const inputArr = [];

    // const inputArr = [
    //     {
    //       type: "text",
    //       id: 1,
    //       value: ""
    //     }
    //   ];
    
    const [arr, setArr] = useState({});

    const {
        wards, user, theatres, clinic, patients, profile, doctors
    } = props;

    const optionGroup = [
        { label: "Male", value: "Male" },
        { label: "Female", value: "Female" },
        { label: "Other", value: "Other" }
    ]

    let breathing_ref = useRef();
    let headneck_ref = useRef();
    let pelvis_ref = useRef();
    let extremities_ref = useRef();
    let neuro_ref = useRef();
    let back_ref = useRef();
    let cvs_ref = useRef();
    let circleRadius = 10

    useEffect(() => {
        const { getWards, getTheatres, getClinic, getDoctor, getPatient, getUserprofile } = props

        getPatient()
        getDoctor()
        let userId = localStorage.getItem("authUser")
        getUserprofile(userId)

        $('#breathing').on('click', function (ev) {
            circlePrepage(ev, $(this))
        });

        $('#headneck').on('click', function (ev) {
            circlePrepage(ev, $(this))
        });

        $('#pelvis').on('click', function (ev) {
            circlePrepage(ev, $(this))
        });

        $('#extremities').on('click', function (ev) {
            circlePrepage(ev, $(this))
        });

        $('#neuro').on('click', function (ev) {
            circlePrepage(ev, $(this))
        });

        $('#back').on('click', function (ev) {
            circlePrepage(ev, $(this))
        });

        $('#cvs').on('click', function (ev) {
            circlePrepage(ev, $(this))
        });

    }, [])

    useEffect(() => {
        let clinicPatient = JSON.parse(localStorage.getItem("p"))
        let patient = {
            ...clinicPatient,
            ...patientValues
        }
        setPatient(patient)

        if (patients && patients.length > 0 && clinicPatient && clinicPatient._id) {
            const cPatient = patients.filter(
                usr => usr._id === clinicPatient._id
            )
            if (cPatient && cPatient[0]) {
                let cpatientData = cPatient[0]
                delete cpatientData["_id"]
                setClinic(clinicValues => ({
                    ...clinicValues,
                    ...cpatientData
                }));
            }

        }
    }, [patients])


    const updateInputValue = (e) => {
        let val = e.target.value;
        let name = e.target.name;
        let updatedValue = {};
        updatedValue = { [name]: val };
        setPatient(patientValues => ({
            ...patientValues,
            ...updatedValue
        }));

    }


    const handleSelectGroup = (selectedGroup, name) => {
        // console.log("selectedGroup ", selectedGroup)
        let updatedValue = { [name]: selectedGroup };
        setPatient(patientValues => ({
            ...patientValues,
            ...updatedValue
        }));
    }

    const updateClinicValues = (e, name, val) => {
        e.preventDefault()
        let updatedValue = {};
        updatedValue = { [name]: val };
        setPatient(patientValues => ({
            ...patientValues,
            ...updatedValue
        }));
    }

    
      const addInput = (labelname) => {
        let updatedValue = {};
        if (arr[labelname]) {
            updatedValue[labelname] = arr[labelname]

            let keylength = Object.keys(arr[labelname]).length
            updatedValue[labelname][labelname+"_input_"+(keylength+1)] =  true
        } else {
            updatedValue[labelname] = { [labelname+"_input"]: true }
        }
        setArr(arr => ({
            ...arr,
            ...updatedValue
        }));
      };
    


    const selectCheckbox = (e, labelname) => {
        // console.log(e.target.name, e.target.checked)
        let name = e.target.name;
        if (e.target.checked) {
            let val = e.target.value;
            let updatedValue = {};
            if (patientValues[labelname]) {
                updatedValue[labelname] = patientValues[labelname]
                updatedValue[labelname][name] = val;
            } else {
                updatedValue[labelname] = { [name]: val };
            }

            setPatient(patientValues => ({
                ...patientValues,
                ...updatedValue
            }));
        } else {
            delete patientValues[labelname][name]
            setPatient(patientValues)
        }
    }

    const updateCheckbox = (e) => {
        // console.log(e.target.name, e.target.checked)
        if (e.target.checked) {
            let val = e.target.value;
            let name = e.target.name;
            let updatedValue = {};
            updatedValue = { [name]: val };
            setPatient(patientValues => ({
                ...patientValues,
                ...updatedValue
            }));
        } else {
            let name = e.target.name;
            delete patientValues[name]
            setPatient(patientValues)
        }
    }
    const submitUpdate = (e) => {
        const { teamupdatelist } = props
        let clinicPatient = JSON.parse(localStorage.getItem("p"))
        let id = clinicPatient.patientId
        
        let bodyObj = {
            obj: patientValues,
            query: { "patientId": id },
            method: "Update"
        }
        teamupdatelist(bodyObj, "addpatient")
        setsuccess_msg(!success_msg)

    }

    const circlePrepage = (ev, $this) => {
        let r = parseInt(circleRadius, 10);
        let o = $this.offset();
        let y = ev.pageY - o.top;
        let x = ev.pageX - o.left;
        console.log("circlePrepage ", $this)

        $("<div />", {
            "class": "breifcircle",
            css: {
                top: y,
                left: x,
                width: r * 2,
                height: r * 2,
            },
            appendTo: $this // append to #image_preview!
        });

    }

    const imageCircle = (ev, refname, divId) => {

        // $(divId).on('click', function(ev) {

        let $this = $(this);
        //  r = parseInt($radius.val().trim(), 10), // Parse as Integer radix 10
        let r = parseInt(15, 10); // Parse as Integer radix 10
        let o = $this.offset();

        let y = ev.pageY - o.top;
        let x = ev.pageX - o.left;

        console.log("0", o.top, o.left)
        console.log("x", y, x)

        $("<div />", {
            "class": "breifcircle",
            css: {
                top: y,
                left: x,
                width: r * 2,
                height: r * 2,
            },
            appendTo: $this // append to #image_preview!
        });

        // // Append data to App.points
        // points.push({x,y,r});
        // // Test
        // console.log( points )

        //   });


        //     let  y = ev.pageY;
        //     let  x = ev.pageX ;

        //     if (refname.current) {
        //         const { scrollTop, scrollLeft, offsetTop, offsetLeft } = refname.current;
        //         console.log("scrollLeftscrollLeft ",scrollTop, scrollLeft, offsetTop, offsetLeft)
        //         y = ev.pageY - offsetTop;
        //         x = ev.pageX - offsetLeft;
        //       }


        // // $('#image_preview').on('click', function(ev) {
        //     //console.log("evv ",ev, refname)
        //     console.log("evv ",ev.pageY, ev.pageX)
        //     const { offsetTop, offsetLeft } = refname

        //     // let offsetElement = refname.current;
        //     var stickyContainer = document.getElementById(divId);
        //     let soffsetTop = stickyContainer && stickyContainer.offsetTop ? stickyContainer.offsetTop : 0
        //     let soffsetLeft = stickyContainer && stickyContainer.offsetLeft ? stickyContainer.offsetLeft : 0
        //     console.log('sssccc ', soffsetTop, soffsetLeft);

        //     console.log("offsetElement ",offsetTop, offsetLeft)
        //      //  r = parseInt($radius.val().trim(), 10), // Parse as Integer radix 10
        //     let r = parseInt(15, 10); // Parse as Integer radix 10
        //     // let  o = $this.offset();
        //     //  y = ev.pageY - offsetTop;
        //     //  x = ev.pageX - offsetLeft;

        //     console.log("offsetElement ",x, y)

        //     $("<div />", {
        //       "class": "breifcircle",
        //       css: {
        //         top: y,
        //         left: x,
        //         width: r * 2,
        //         height: r * 2,
        //       },
        //       appendTo: refname // append to #image_preview!
        //     });

    }

    const undoCircle = (ev, refname) => {
        console.log("refname ", refname)
        $("#" + refname).children("div[class=breifcircle]:last").remove();
    }
    
  
 
    return (
        <React.Fragment>

            <MetaTags>
                <title>ADD NEW PATIENT</title>
            </MetaTags>
            <div className='ward '>
                <div className="main n_office oncall">
                    <div className="wrapper">
                        <Menus menuName={"ADD NEW PATIENT"} />

                        <PatientBlock patient={clinicPatient} />
                        {/* <div className="section ctr">
                            <div className="row mb-10">
                                <div className="col-md-6">
                                    <div className="form_group">
                                        <label className="label">First Name</label>
                                        <input type="text" onChange={(e) => updateInputValue(e)} name="firstname" className="form-control" value={patientValues.firstname} />
                                        <span className="material-symbols-outlined input_icon"> person </span>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form_group">
                                        <label className="label">Last name</label>
                                        <input type="text" onChange={(e) => updateInputValue(e)} name="lastname" className="form-control" value={patientValues.lastname} />
                                        <span className="material-symbols-outlined input_icon"> person </span>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="search_dropdown_blk mb-0">
                                        <div className="form_group">
                                            <label className="label">Sex</label>
                                            <div className="floating-label-group mb-0">
                                                <div className="search_dropdown">
                                                    <Select
                                                        onChange={(e) => {
                                                            handleSelectGroup(e, "sex")
                                                        }}
                                                        options={optionGroup}
                                                        classNamePrefix="select2-selection"
                                                        value={{ label: patientValues.sex, value: patientValues.sex }}
                                                    />
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form_group">
                                        <label className="label">Age</label>
                                        <input type="text" onChange={(e) => updateInputValue(e)} name="age" className="form-control" value={patientValues.age} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form_group">
                                        <label className="label">Dob</label>
                                        <input type="date" onChange={(e) => updateInputValue(e)} name="dob" className="form-control" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form_group">
                                        <label className="label">NHS Number</label>
                                        <input type="text" onChange={(e) => updateInputValue(e)} name="hospitalNo" className="form-control" value={patientValues.hospitalNo} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form_group">
                                        <label className="label">Phone</label>
                                        <input type="text" onChange={(e) => updateInputValue(e)} name="mobile" className="form-control" value={patientValues.mobile} />
                                        <span className="material-symbols-outlined input_icon"> call </span>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form_group">
                                        <label className="label">Email</label>
                                        <input type="text" onChange={(e) => updateInputValue(e)} name="email" className="form-control" value={patientValues.email} />
                                        <span className="material-symbols-outlined input_icon"> mail </span>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form_group">
                                        <label className="label">Consultant</label>
                                        <input type="text" onChange={(e) => updateInputValue(e)} name="consultant" className="form-control" value={patientValues.doctor} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form_group">
                                        <label className="label">Location</label>
                                        <input type="text" onChange={(e) => updateInputValue(e)} name="location" className="form-control" value={patientValues.otherloc} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form_group">
                                        <label className="label">Date of Admission</label>
                                        <input type="date" onChange={(e) => updateInputValue(e)} name="dateofadmission" className="form-control" />
                                    </div>
                                </div>

                            </div>
                            <button type="button" onClick={(e) => { submitUpdate(e) }} className="update_btn mt-0 mb-20">SAVE</button>
                        </div> */}
                        <div className="section ctr pua">
                            <h2 className="popup_title">OUTCOME/ STATUS </h2>
                            <div className="poc poc1 pt-0">
                                <div className="check_item mt-20 mb-20">
                                    <div className="select_btns popup_select">
                                        <div className="select_btn os">
                                            <label><input onClick={(e) => updateInputValue(e)} name="outcome" type="radio"
                                                value="Admitted" /><span>Admitted</span></label>
                                        </div>
                                        <div className="select_btn os">
                                            <label><input onClick={(e) => updateInputValue(e)} name="outcome" type="radio" value="NOT_Admitted" /><span>NOT
                                                Admitted</span></label>
                                        </div>
                                        <div className="select_btn os">
                                            <label><input onClick={(e) => updateInputValue(e)} name="outcome" type="radio" value="Discharged" /><span>
                                                Discharged</span></label>
                                        </div>
                                    </div>
                                </div>

                                {(patientValues.outcome == "Admitted") &&


                                    <>
                                        <div className="check-box">
                                            <div className="round">
                                                <input type="checkbox" onChange={(e) => updateCheckbox(e)} name={patientValues.outcome + "_wfs"} value="Waiting for Surgery" id={patientValues.outcome + "_wfs"} />
                                                <label for={patientValues.outcome + "_wfs"}></label>
                                            </div>
                                            Waiting for Surgery
                                        </div>
                                        <div className="check-box">
                                            <div className="round">
                                                <input type="checkbox" onChange={(e) => updateCheckbox(e)} name={patientValues.outcome + "_fnom"} value="For Non operative management" id={patientValues.outcome + "_fnom"} />
                                                <label for={patientValues.outcome + "_fnom"}></label>
                                            </div>
                                            For Non operative management
                                        </div>
                                        <div className="check-box">
                                            <div className="round">
                                                <input type="checkbox" onChange={(e) => updateCheckbox(e)} name={patientValues.outcome + "_operated"} value="Operated" id={patientValues.outcome + "_operated"} />
                                                <label for={patientValues.outcome + "_operated"}></label>
                                            </div>
                                            Operated
                                        </div>
                                        <div className="check-box">
                                            <div className="round">
                                                <input type="checkbox" onChange={(e) => updateCheckbox(e)} name={patientValues.outcome + "_oaw"} value="Outlier on another ward" id={patientValues.outcome + "_oaw"} />
                                                <label for={patientValues.outcome + "_oaw"}></label>
                                            </div>
                                            Outlier on another ward
                                        </div></>
                                }

                                {patientValues.outcome == "NOT_Admitted" &&


                                    <>
                                        <div className="check-box">
                                            <div className="round">
                                                <input type="checkbox" onChange={(e) => updateCheckbox(e)} name={patientValues.outcome + "_wfs"} value="Waiting for Surgery" id={patientValues.outcome + "_wfs"} />
                                                <label for={patientValues.outcome + "_wfs"}></label>
                                            </div>
                                            Waiting for Surgery
                                        </div>
                                        <div className="check-box">
                                            <div className="round">
                                                <input type="checkbox" onChange={(e) => updateCheckbox(e)} name={patientValues.outcome + "_fnom"} value="For Non operative management" id={patientValues.outcome + "_fnom"} />
                                                <label for={patientValues.outcome + "_fnom"}></label>
                                            </div>
                                            For Non operative management
                                        </div>
                                        <div className="check-box">
                                            <div className="round">
                                                <input type="checkbox" onChange={(e) => updateCheckbox(e)} name={patientValues.outcome + "_operated"} value="Operated" id={patientValues.outcome + "_operated"} />
                                                <label for={patientValues.outcome + "_operated"}></label>
                                            </div>
                                            Operated
                                        </div>
                                        <div className="check-box">
                                            <div className="round">
                                                <input type="checkbox" onChange={(e) => updateCheckbox(e)} name={patientValues.outcome + "_oaw"} value="Outlier on another ward" id={patientValues.outcome + "_oaw"} />
                                                <label for={patientValues.outcome + "_oaw"}></label>
                                            </div>
                                            Outlier on another ward
                                        </div></>
                                }


                            </div>
                        </div>
                        <div className="section ctr">
                            <h2 className="popup_title">DIAGNOSIS</h2>
                            <div className="diagnosis_form">
                                <div className="item">
                                    <div className="form_group">
                                        <input type="text" className="form-control" onChange={(e) => updateInputValue(e)} name="diagnosis_1" />
                                    </div>
                                    <div className="select_btns popup_select">
                                        <div className="select_btn os">
                                            <label><input type="radio" onClick={(e) => updateInputValue(e)} name="diagnosis_1_right_left" value="R" /><span>R</span></label>
                                        </div>
                                        <div className="select_btn os">
                                            <label><input onClick={(e) => updateInputValue(e)} name="diagnosis_1_right_left" type="radio" value="L" /><span>L</span></label>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="form_group">
                                        <input type="text" className="form-control" onChange={(e) => updateInputValue(e)} name="diagnosis_2" />
                                    </div>
                                    <div className="select_btns popup_select">
                                        <div className="select_btn os">
                                            <label><input onClick={(e) => updateInputValue(e)} name="diagnosis_2_right_left" type="radio" value="R" /><span>R</span></label>
                                        </div>
                                        <div className="select_btn os">
                                            <label><input onClick={(e) => updateInputValue(e)} name="diagnosis_2_right_left" type="radio" value="L" /><span>L</span></label>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="form_group">
                                        <input type="text" className="form-control" onChange={(e) => updateInputValue(e)} name="diagnosis_3" />
                                    </div>
                                    <div className="select_btns popup_select">
                                        <div className="select_btn os">
                                            <label><input onClick={(e) => updateInputValue(e)} name="diagnosis_3_right_left" type="radio" value="R" /><span>R</span></label>
                                        </div>
                                        <div className="select_btn os">
                                            <label><input onClick={(e) => updateInputValue(e)} name="diagnosis_3_right_left" type="radio" value="L" /><span>L</span></label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="section ctr trauma">
                            <div className="trauma_flex">
                                <h2 className="popup_title">TRAUMA <span className="sub">( Select one or Many )</span> </h2>
                                <div className="yn_switch mb-15 mt-10">
                                    <label for="check814" className="switch-container">
                                        <input hidden="" type="checkbox" className="switch_check" id="check814" />
                                        <div className="switch-bg"></div>
                                        <div className="round-box"></div>
                                        <div className="switch-left">
                                            <span>NO</span>
                                        </div>
                                        <div className="switch-right">
                                            <span>YES</span>
                                        </div>
                                    </label>
                                    {/* <div className="label-container">
                                        <p>Open </p>
                                    </div> */}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="check_item ">

                                        {patientValues.trauma_loc ?
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input type="radio"
                                                    value={patientValues.trauma_loc} checked /><span>{patientValues.trauma_loc} </span></label>
                                                </div> {patientValues.trauma_loc && <a onClick={(e) => updateClinicValues(e, "trauma_loc", false)}><span className="material-symbols-outlined"> reply
                                                </span></a>}

                                            </div>
                                            :
                                            <div className="select_btns mt-0">
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="trauma_loc" type="radio"
                                                    value="Fracture" /><span>Fracture
                                                    </span></label></div>
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="trauma_loc" type="radio"
                                                    value="Dislocation" /><span> Dislocation</span></label>
                                                </div>
                                            </div>}
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="check_item ">
                                        {patientValues.trauma_rl ?
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input type="radio"
                                                    value={patientValues.trauma_rl} checked /><span>{patientValues.trauma_rl} </span></label>
                                                </div> {patientValues.trauma_rl && <a onClick={(e) => updateClinicValues(e, "trauma_rl", false)}><span className="material-symbols-outlined"> reply
                                                </span></a>}

                                            </div>
                                            :
                                            <div className="select_btns mt-0">
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="trauma_rl" type="radio"
                                                    value="Left" /><span>Left
                                                    </span></label></div>
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="trauma_rl" type="radio"
                                                    value="Right" /><span> Right</span></label>
                                                </div>
                                            </div>}
                                    </div>
                                </div>
                                <div className="col-md-5">
                                    <div className="form_group dsp_flex">
                                        <label className="label">NOF breech time</label>
                                        <input type="time" className="form-control" onChange={(e) => updateInputValue(e)} name="nof_breech_time" placeholder="hh:mm:ss" />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="check_item mt-10">
                                        {patientValues.trauma_poly ?
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input type="radio"
                                                    value={patientValues.trauma_poly} checked /><span>{patientValues.trauma_poly} </span></label>
                                                </div> {patientValues.trauma_poly && <a onClick={(e) => updateClinicValues(e, "trauma_poly", false)}><span className="material-symbols-outlined"> reply
                                                </span></a>}

                                            </div>
                                            :
                                            <div className="select_btns mt-0">
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="trauma_poly" type="radio"
                                                    value="Poly Trauma patient" /><span>Poly Trauma patient
                                                    </span></label></div>
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="trauma_poly" type="radio"
                                                    value="Neurovascular deficit" /><span> Neurovascular deficit</span></label>
                                                </div>
                                            </div>}


                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form_group">
                                        <label className="label">Mechanism of Injury</label>
                                        <input type="text" onChange={(e) => updateInputValue(e)} name="mechanism_injury" className="form-control" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form_group">
                                        <label className="label">Date of Injury</label>
                                        <input type="date" onChange={(e) => updateInputValue(e)} name="date_of_injury" className="form-control" />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form_group">
                                        <label className="label">Notes</label>
                                        <textarea className="form-control" onChange={(e) => updateInputValue(e)} name="injury_notes" rows="4" placeholder="Add Notes"></textarea>

                                    </div>
                                </div>
                            </div>
                            <button type="button" onClick={(e) => { submitUpdate(e) }} className="update_btn mt-0">UPDATE</button>


                        </div>
                        <div className="section ctr">
                            <div className="form_group mb-0">
                                <label className="label">Planned Management / procedure</label>
                                <input type="text" onChange={(e) => updateInputValue(e)} name="planned_management" className="form-control" />
                            </div>
                        </div>
                        <div className="section investigation ctr">
                            <div className="hed">
                                <h2 className="popup_title">INVESTIGATIONS </h2>
                                <div className="right">
                                    <div className="icon_text">
                                        <span className="material-symbols-outlined icon"> glucose </span>Order tests
                                    </div>
                                    <div className="icon_text">
                                        <span className="material-symbols-outlined icon"> list_alt </span>Fetch Results
                                    </div>
                                    <div className="icon_text nml" data-toggle="modal" data-target="#aide">
                                        <span className="material-icons icon"> help </span>AIDE MEMOIRE
                                    </div>
                                </div>
                            </div>
                            <p className="sub_title mb_5">Imaging</p>
                            <div className="files">
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="file_upload" data-toggle="modal" data-target="#exampleModalLong">
                                            <img src={xray} className="img" />
                                            <div className="file_ttile">
                                                <p className="fl_title">Elbow - AP & Later..</p>
                                                <p className="fl_dec">22-7-2022</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="file_upload" data-toggle="modal" data-target="#exampleModalLong">
                                            <img src={xray} className="img" />
                                            <div className="file_ttile">
                                                <p className="fl_title">Elbow - AP & Later..</p>
                                                <p className="fl_dec">22-7-2022</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="file_upload" data-toggle="modal" data-target="#exampleModalLong">
                                            <img src={xray} className="img" />
                                            <div className="file_ttile">
                                                <p className="fl_title">Elbow - AP & Later..</p>
                                                <p className="fl_dec">22-7-2022</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <p className="sub_title mt-20 mb_5">Labs</p>
                            <div className="files">
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="file_upload" data-toggle="modal" data-target="#exampleModalLong">
                                            <img src={doc} className="img" />
                                            <div className="file_ttile">
                                                <p className="fl_title">Elbow - AP & Later..</p>
                                                <p className="fl_dec">22-7-2022</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="file_upload" data-toggle="modal" data-target="#exampleModalLong">
                                            <img src={doc} className="img" />
                                            <div className="file_ttile">
                                                <p className="fl_title">Elbow - AP & Later..</p>
                                                <p className="fl_dec">22-7-2022</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="file_upload" data-toggle="modal" data-target="#exampleModalLong">
                                            <img src={doc} className="img" />
                                            <div className="file_ttile">
                                                <p className="fl_title">Elbow - AP & Later..</p>
                                                <p className="fl_dec">22-7-2022</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <p className="sub_title mt-20 mb_5">Photos</p>
                            <div className="files">
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="file_upload" data-toggle="modal" data-target="#exampleModalLong">
                                            <img src={xray} className="img" />
                                            <div className="file_ttile">
                                                <p className="fl_title">Elbow - AP & Later..</p>
                                                <p className="fl_dec">22-7-2022</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="file_upload" data-toggle="modal" data-target="#exampleModalLong">
                                            <img src={xray} className="img" />
                                            <div className="file_ttile">
                                                <p className="fl_title">Elbow - AP & Later..</p>
                                                <p className="fl_dec">22-7-2022</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="file_upload" data-toggle="modal" data-target="#exampleModalLong">
                                            <img src={xray} className="img" />
                                            <div className="file_ttile">
                                                <p className="fl_title">Elbow - AP & Later..</p>
                                                <p className="fl_dec">22-7-2022</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="section ctr adw">
                            <div className="check_item mb-10">
                                <div className="select_btns mt-0">

                                    <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="workshop" type="radio" value="Non Admission Workup" /><span>Non Admission Workup
                                    </span></label></div>
                                    <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="workshop" type="radio" value="Admission Workup" /><span>
                                        Admission Workup</span></label>
                                    </div>


                                </div>
                            </div>


                            <div className="poc poc1 pt-0">

                                {(patientValues.workshop == "Non Admission Workup") &&
                                    <>
                                        <div className="sub_section">
                                            <div className="form_group item">
                                                <label className="label">HPC</label>
                                                <input type="text" onChange={(e) => updateInputValue(e)} name="hpc" className="form-control" />
                                            </div>
                                        </div>
                                        <div className="sub_section">
                                            <div className="form_group item">
                                                <label className="label">Examination</label>
                                                <input type="text" onChange={(e) => updateInputValue(e)} name="examination" className="form-control" />
                                            </div>
                                        </div>
                                        <div className="sub_section">
                                            <div className="form_group item">
                                                <label className="label">Investigations</label>
                                                <input type="text" onChange={(e) => updateInputValue(e)} name="investigation" className="form-control" />
                                            </div>
                                        </div>

                                        <div className="sub_section">
                                            <p className="field_title mt-20 mb-20">PROCEDURES</p>
                                            <div className="check-box sqeare ">
                                                <div className="round">
                                                    <input type="checkbox" onChange={(e) => updateCheckbox(e)} name="procedures" value="Manipulations – Castings" id="procedures" />
                                                    <label for="procedures"></label>
                                                </div>
                                                Manipulations – Castings
                                            </div>
                                            <div className="check-box sqeare ">
                                                <div className="round">
                                                    <input type="checkbox" onChange={(e) => updateCheckbox(e)} name="wounds" value="Wounds & Management, Sutures etc" id="wounds" />
                                                    <label for="wounds"></label>
                                                </div>
                                                Wounds & Management, Sutures etc
                                            </div>
                                            <div className="check-box sqeare ">
                                                <div className="round">
                                                    <input type="checkbox" onChange={(e) => updateCheckbox(e)} name="mobility" value="Mobility and splinting and weight bearing status" id="mobility" />
                                                    <label for="mobility"></label>
                                                </div>
                                                Mobility and splinting and weight bearing status
                                            </div>
                                        </div>
                                        <div className="sub_section">
                                            <p className="field_title mt-20 mb-20">DISCHARGE</p>
                                            <div className="check-box sqeare ">
                                                <div className="round">
                                                    <input type="checkbox" onChange={(e) => updateCheckbox(e)} name="discharge" value="For Discussion" id="discharge" />
                                                    <label for="discharge"></label>
                                                </div>
                                                For Discussion
                                            </div>
                                            <div className="check-box sqeare ">
                                                <div className="round">
                                                    <input type="checkbox" onChange={(e) => updateCheckbox(e)} name="outpatient" value="Out-patient clinic Follow-up" id="outpatient" />
                                                    <label for="outpatient"></label>
                                                </div>
                                                Out-patient clinic Follow-up
                                            </div>
                                            <div className="check-box sqeare ">
                                                <div className="round">
                                                    <input type="checkbox" onChange={(e) => updateCheckbox(e)} name="specialinstruction" value="Special instructions" id="specialinstruction" />
                                                    <label for="specialinstruction"></label>
                                                </div>
                                                Special instructions
                                            </div>
                                        </div>
                                        <div className="sub_section">
                                            <div className="form_group item mt-20 mb-20">
                                                <label className="label">Referrals to other specialists</label>
                                                <input type="text" onChange={(e) => updateInputValue(e)} name="referrals_to_other_specialist" className="form-control" />
                                            </div>
                                        </div>
                                    </>}



                                {(patientValues.workshop == "Admission Workup") &&
                                    <>

                                        <div className="aw_block">
                                            <a href="#" className="icon_btn" data-toggle="modal" data-target="#awp">REVERSIBLE MEDICAL
                                                CONDITIONS/COMORBIDITIES <span className="material-symbols-outlined icon">
                                                    heart_broken
                                                </span></a>
                                            <a href="#" className="icon_btn" data-toggle="modal" data-target="#awp2">ALLERGIES & ALERTS
                                                <span className="material-symbols-outlined icon">
                                                    allergies
                                                </span></a>
                                            <a href="#" className="icon_btn" data-toggle="modal" data-target="#awp3">RELEVANT DRUG HISTORY
                                                <span className="material-symbols-outlined icon">
                                                    prescriptions
                                                </span></a>
                                            <a href="#" className="icon_btn" data-toggle="modal" data-target="#awp4">SOCIAL HISTORY<span
                                                className="material-symbols-outlined icon">
                                                history
                                            </span></a>
                                            <a href="#" className="icon_btn" data-toggle="modal" data-target="#awp5">PREGNANCY <span
                                                className="material-symbols-outlined icon">
                                                pregnant_woman
                                            </span></a>
                                            <a href="#" className="icon_btn" data-toggle="modal" data-target="#awp6">USUAL BASELINE
                                                MOBILITY<span className="material-symbols-outlined icon">
                                                    transfer_within_a_station
                                                </span></a>
                                            <a href="#" className="icon_btn" data-toggle="modal" data-target="#awp7">SPECIALIST TEMPLATES<span className="material-symbols-outlined icon">
                                                    auto_awesome_motion
                                                </span></a>
                                            <a href="#" className="icon_btn" data-toggle="modal" data-target="#awp8">ON CALL PROCEDURES<span
                                                className="material-symbols-outlined icon">
                                                clinical_notes
                                            </span></a>
                                            <a href="#" className="icon_btn" data-toggle="modal" data-target="#awp9">CONSENT <span
                                                className="material-symbols-outlined icon">
                                                task
                                            </span></a>
                                        </div>

                                        {patientValues.comorbidities_flag && patientValues.comorbidities && Object.keys(patientValues.comorbidities).length > 0 &&

                                            <div className="list">
                                                <div className="hed">
                                                    <span className="material-symbols-outlined icon"> heart_broken </span>
                                                    REVERSIBLE MEDICAL CONDITIONS / COMORBIDITIES
                                                </div>
                                                <ul>
                                                    {Object.keys(patientValues.comorbidities).map((item, i) => (
                                                        <li key={i}>{patientValues.comorbidities[item]}</li>

                                                    ))}

                                            {patientValues.comorbidities_input_input && <li>{patientValues.comorbidities_input_input}</li>}
                                            {patientValues.comorbidities_input_input1 && <li>{patientValues.comorbidities_input_input1}</li>}
                                            {patientValues.comorbidities_input_input2 && <li>{patientValues.comorbidities_input_input2}</li>}
                                            {patientValues.comorbidities_input_input3 && <li>{patientValues.comorbidities_input_input3}</li>}
                                            {patientValues.comorbidities_input_input4 && <li>{patientValues.comorbidities_input_input4}</li>}
                                            {patientValues.comorbidities_input_input5 && <li>{patientValues.comorbidities_input_input5}</li>}
                                            {patientValues.comorbidities_input_input6 && <li>{patientValues.comorbidities_input_input6}</li>}
                                            {patientValues.comorbidities_input_input7 && <li>{patientValues.comorbidities_input_input7}</li>}
                                            {patientValues.comorbidities_input_input8 && <li>{patientValues.comorbidities_input_input8}</li>}

                                                </ul>
                                            </div>

                                        }

                                        {patientValues.allergies_flag && patientValues.allergies && Object.keys(patientValues.allergies).length > 0 &&

                                        <div className="list" >
                                            <div className="hed">
                                                <span className="material-symbols-outlined icon"> allergies </span>
                                                ALLERGIES & ALERTS
                                            </div>
                                            <ul>
                                                {Object.keys(patientValues.allergies).map((item, i) => (
                                                    <li key={i}>{patientValues.allergies[item]}</li>

                                                ))}
                                                

                                            </ul>
                                        </div>

                                        }


                                    {patientValues.relevant_flag && patientValues.relevant && Object.keys(patientValues.relevant).length > 0 &&

                                    <div className="list" >
                                        <div className="hed">
                                            <span className="material-symbols-outlined icon"> prescriptions </span>
                                            RELEVANT DRUG HISTORY
                                        </div>
                                        <ul>
                                            {Object.keys(patientValues.relevant).map((item, i) => (
                                                <li key={i}>{patientValues.relevant[item]}</li>

                                            ))}

                                            {patientValues.relevant_input_input && <li>{patientValues.relevant_input_input}</li>}
                                            {patientValues.relevant_input_input1 && <li>{patientValues.relevant_input_input1}</li>}
                                            {patientValues.relevant_input_input2 && <li>{patientValues.relevant_input_input2}</li>}
                                            {patientValues.relevant_input_input3 && <li>{patientValues.relevant_input_input3}</li>}
                                            {patientValues.relevant_input_input4 && <li>{patientValues.relevant_input_input4}</li>}
                                            {patientValues.relevant_input_input5 && <li>{patientValues.relevant_input_input5}</li>}
                                            {patientValues.relevant_input_input6 && <li>{patientValues.relevant_input_input6}</li>}
                                            {patientValues.relevant_input_input7 && <li>{patientValues.relevant_input_input7}</li>}
                                            {patientValues.relevant_input_input8 && <li>{patientValues.relevant_input_input8}</li>}
                                            {patientValues.relevant_last_dose && <li>Last Dose Date: {patientValues.relevant_last_dose}</li>}
                                            
                                        </ul>
                                    </div>

                                    }


                                    {patientValues.social_history_flag && patientValues.social_history && Object.keys(patientValues.social_history).length > 0 &&

                                    <div className="list" >
                                        <div className="hed">
                                            <span className="material-symbols-outlined icon"> history </span>
                                            SOCIAL HISTORY
                                        </div>
                                        <ul>
                                            {Object.keys(patientValues.social_history).map((item, i) => (
                                                <li key={i}>{patientValues.social_history[item]}</li>

                                            ))}

                                            {patientValues.social_history_residence && <li>FROM: {patientValues.social_history_residence}</li>}
                                            {patientValues.social_history_other_residence && <li>Other (Please specify): {patientValues.social_history_other_residence}</li>}
                                            {patientValues.social_history_lives_with && <li>Lives with: {patientValues.social_history_lives_with}</li>}
                                            {patientValues.social_history_living_home && <li>If living at home, list care services in place: {patientValues.social_history_living_home}</li>}
                                            {patientValues.social_history_formal && <li>Formal: {patientValues.social_history_formal}</li>}
                                            {patientValues.social_history_informal && <li>Informal: {patientValues.social_history_informal}</li>}
                                            
                                        </ul>
                                    </div>

                                    }

                                        {patientValues.pregnancy_flag && patientValues.pregnancy && Object.keys(patientValues.pregnancy).length > 0 &&

                                        <div className="list" >
                                            <div className="hed">
                                                <span className="material-symbols-outlined icon"> pregnant_woman </span>
                                                PREGNANCY
                                            </div>
                                            <ul>
                                            {patientValues.pregnancy_major && <li>Major Emergency Admission: {patientValues.pregnancy_major}</li>}
                                            {patientValues.pregnancy_intermediate && <li>Intermediate Emergency Admission: {patientValues.pregnancy_intermediate}</li>}
                                            {patientValues.pregnancy_minor && <li>Minor / Day case Emergency Admission: {patientValues.pregnancy_minor}</li>}
                                                {Object.keys(patientValues.pregnancy).map((item, i) => (
                                                    <li key={i}>{patientValues.pregnancy[item]}</li>

                                                ))}
                                                

                                            </ul>
                                        </div>

                                        }

                                    {patientValues.mobility_flag && patientValues.mobility && Object.keys(patientValues.mobility).length > 0 &&

                                    <div className="list" >
                                        <div className="hed">
                                            <span className="material-symbols-outlined icon"> transfer_within_a_station </span>
                                            USUAL BASELINE MOBILITY
                                        </div>
                                        <ul>
                                        {patientValues.mobility_independent && <li>{patientValues.mobility_independent}</li>}
                                       
                                            {Object.keys(patientValues.mobility).map((item, i) => (
                                                <li key={i}>{patientValues.mobility[item]}</li>

                                            ))}
                                            

                                        </ul>
                                    </div>

                                    }
                                       
                                       {patientValues.templates_flag && patientValues.templates && Object.keys(patientValues.templates).length > 0 &&

                                        <div className="list" >
                                            <div className="hed">
                                                <span className="material-symbols-outlined icon"> auto_awesome_motion </span>
                                                SPECIALIST TEMPLATES
                                            </div>
                                            <ul>
                                                {Object.keys(patientValues.templates).map((item, i) => (
                                                    <li key={i}>{patientValues.templates[item]}</li>

                                                ))}
                                                

                                            </ul>
                                        </div>

                                        }


                                        {patientValues.oncall_flag && patientValues.oncall && Object.keys(patientValues.oncall).length > 0 &&

                                        <div className="list" >
                                            <div className="hed">
                                                <span className="material-symbols-outlined icon"> clinical_notes </span>
                                                ON CALL PROCEDURES
                                            </div>
                                            <ul>
                                                {Object.keys(patientValues.oncall).map((item, i) => (
                                                    <li key={i}>{patientValues.oncall[item]}</li>

                                                ))}
                                                

                                            </ul>
                                        </div>

                                        }

                                        {patientValues.consent_flag && patientValues.consent && Object.keys(patientValues.consent).length > 0 &&

                                        <div className="list" >
                                            <div className="hed">
                                                <span className="material-symbols-outlined icon"> task </span>
                                                CONSENT
                                            </div>
                                            <ul>
                                                {Object.keys(patientValues.consent).map((item, i) => (
                                                    <li key={i}>{patientValues.consent[item]}</li>

                                                ))}
                                                

                                            </ul>
                                        </div>

                                        }


                                    </>}
                                <button type="button" onClick={(e) => { submitUpdate(e) }} className="update_btn mt-20">UPDATE</button>


                            </div>
                        </div>


                        <div className="section ctr">
                            <h2 className="popup_title mt-0">EXAMINATION FINDINGS </h2>
                            <div className="ef">
                                <div className="item">
                                    <div className="text"><span className="material-symbols-outlined icon"> thermostat </span>
                                        TEMPERATURE</div>
                                    <div className="form_group">
                                        <input type="text" onChange={(e) => updateInputValue(e)} name="temperature" className="form-control" />
                                        <span className="formate">0C</span>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="text"><span className="material-symbols-outlined icon"> favorite </span> PULSE</div>
                                    <div className="form_group">
                                        <input type="text" onChange={(e) => updateInputValue(e)} name="pulse" className="form-control" />
                                        <span className="formate">BPM</span>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="text"><span className="material-symbols-outlined icon"> bloodtype </span> BLOOD
                                        PRESSURE</div>
                                    <div className="form_group">
                                        <input type="text" onChange={(e) => updateInputValue(e)} name="pressure" className="form-control" />
                                        <span className="formate">mgdl</span>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="text"><span className="material-symbols-outlined icon"> pulmonology </span>
                                        RESPIRATORY RATE</div>
                                    <div className="form_group">
                                        <input type="text" onChange={(e) => updateInputValue(e)} name="respiratory" className="form-control" />
                                        <span className="formate">BPM</span>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="text"><span className="material-symbols-outlined icon"> ecg_heart </span> SPO2</div>
                                    <div className="form_group">
                                        <input type="text" onChange={(e) => updateInputValue(e)} name="spo2" className="form-control" />
                                        <span className="formate">BPM</span>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="text"><span className="material-symbols-outlined icon"> scale </span> BMI</div>
                                    <div className="form_group">
                                        <input type="text" onChange={(e) => updateInputValue(e)} name="bmi" className="form-control" />

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="section ctr doa">
                            <h2 className="popup_title text-center ">DETAILS OF ABNORMALITIES </h2>
                            <div className="details_block">
                                <div className="hed">
                                    <h4>Airway/ Breathing / Respiratory </h4>
                                    <div className="yn_switch mb-0 mt-0">
                                        <label for="airway" className="switch-container">
                                            <input hidden="" type="checkbox" className="switch_check" id="airway" />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>NO</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>YES</span>
                                            </div>
                                        </label>
                                        <div className="label-container">
                                            <p>Abnormal </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="con">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="img_block crosshair" ref={(ref) => { breathing_ref = ref }} id="breathing"
                                            // onClick={(e) => imageCircle(e, breathing_ref, "breathing")}
                                            >
                                                <img src={Breathing} alt="Breathing" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_block">
                                                <div className="form_group">
                                                    <label className="label">Respiratory Rate:</label>
                                                    <input type="text" onChange={(e) => updateInputValue(e)} name="br_respiratory" className="form-control" />
                                                </div>
                                                <div className="form_group">
                                                    <label className="label">Tracheal Position</label>
                                                    <input type="text" onChange={(e) => updateInputValue(e)} name="br_tracheal" className="form-control" />
                                                </div>
                                                <div className="form_group">
                                                    <label className="label">Chest Expansion</label>
                                                    <input type="text" onChange={(e) => updateInputValue(e)} name="br_chest" className="form-control" />
                                                </div>
                                                <div className="form_group">
                                                    <label className="label">Percussion Note</label>
                                                    <input type="text" onChange={(e) => updateInputValue(e)} name="br_percussion" className="form-control" />
                                                </div>
                                                <div className="form_group">
                                                    <label className="label">Comments</label>
                                                    <textarea rows="2" onChange={(e) => updateInputValue(e)} name="br_comments" className="form-control"> </textarea>
                                                </div>
                                            </div>
                                            <button type="button" onClick={(e) => undoCircle(e, "breathing")} className="update_btn mt-10">UNDO</button>
                                            <button type="button" onClick={(e) => { submitUpdate(e) }} className="update_btn mt-10">SAVE</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="details_block">
                                <div className="hed">
                                    <h4>CVS</h4>
                                    <div className="yn_switch mb-0 mt-0">
                                        <label for="check1" className="switch-container">
                                            <input hidden="" type="checkbox" className="switch_check" id="check1" />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>NO</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>YES</span>
                                            </div>
                                        </label>
                                        <div className="label-container">
                                            <p>Abnormal </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="con">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="img_block crosshair" ref={(ref) => { cvs_ref = ref }} id="cvs">
                                                <img src={cvs} alt="CVS" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_block2">
                                                <div className="form_group">
                                                    <label className="label">HS I</label>
                                                    <input type="text" onChange={(e) => updateInputValue(e)} name="cvs_hs" className="form-control" />
                                                </div>
                                                <div className="form_group">
                                                    <label className="label">HS II</label>
                                                    <input type="text" onChange={(e) => updateInputValue(e)} name="cvs_hs2" className="form-control" />
                                                </div>

                                                {arr && arr.cvs && Object.keys(arr.cvs).map((item, i) => {
                                                    return (
                                                        <div className="form_group" key={i}>
                                                        <input
                                                            onChange={(e) => updateInputValue(e)}
                                                            type="text"
                                                            className="form-control"
                                                            name={item}
                                                        /></div>
                                                    );
                                                })}
                                            
                                                <div className="form_group">
                                                    <a onClick={(e) => { addInput("cvs") }} className="add_btn"> <span className="icon"> + </span> ADD</a>
                                                </div>
                                            </div>
                                            <button type="button" onClick={(e) => undoCircle(e, "cvs")} className="update_btn mt-10">UNDO</button>
                                            <button type="button" onClick={(e) => { submitUpdate(e) }} className="update_btn mt-10">SAVE</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="details_block">
                                <div className="hed">
                                    <h4>HEAD & NECK</h4>
                                    <div className="yn_switch mb-0 mt-0">
                                        <label for="headneckcheck" className="switch-container">
                                            <input hidden="" type="checkbox" className="switch_check" id="headneckcheck" />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>NO</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>YES</span>
                                            </div>
                                        </label>
                                        <div className="label-container">
                                            <p>Abnormal </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="con">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="img_block crosshair" ref={(ref) => { headneck_ref = ref }} id="headneck" >
                                                <img src={headneck} alt="head Neck" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_block2">
                                                <h3>Mini Mental test</h3>
                                                <div className="form_group">
                                                    <label className="label">Total Score</label>
                                                    <input type="text" onChange={(e) => updateInputValue(e)} name="mini_mental_test" className="form-control" />
                                                </div>

                                            </div>
                                            <button type="button" onClick={(e) => undoCircle(e, "headneck")} className="update_btn mt-10">UNDO</button>
                                            <button type="button" onClick={(e) => { submitUpdate(e) }} className="update_btn mt-10">SAVE</button>
                                        </div>
                                    </div>
                                    <div className="form_block2 mt-20">
                                        <h3>Pupils</h3>
                                        <div className="form_group">
                                            <label className="label">Cerebellar Signs</label>
                                            <input type="text" onChange={(e) => updateInputValue(e)} name="cerebellar" className="form-control" />
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form_group">
                                                    <label className="label">Cranial Nerves</label>
                                                    <input type="text" onChange={(e) => updateInputValue(e)} name="cranial_nerves" className="form-control" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form_group">
                                                    <label className="label">GCS</label>
                                                    <input type="text" className="form-control" onChange={(e) => updateInputValue(e)} name="gcs" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="yn_switch mt-0">
                                            <div className="label-container">
                                                <p>Head Injury </p>
                                            </div>
                                            <label for="headinjury" className="switch-container ml_10 ">
                                                <input hidden="" type="checkbox" className="switch_check" id="headinjury" />
                                                <div className="switch-bg"></div>
                                                <div className="round-box"></div>
                                                <div className="switch-left">
                                                    <span>NO</span>
                                                </div>
                                                <div className="switch-right">
                                                    <span>YES</span>
                                                </div>
                                            </label>

                                        </div>
                                        {arr && arr.headneck && Object.keys(arr.headneck).map((item, i) => {
                                                return (
                                                    <div className="form_group" key={i}>
                                                    <input
                                                        onChange={(e) => updateInputValue(e)}
                                                        type="text"
                                                        className="form-control"
                                                        name={item}
                                                    /></div>
                                                );
                                            })}
                                            
                                        <div className="form_group">
                                            <a onClick={(e) => { addInput("headneck") }} className="add_btn"> <span className="icon"> + </span> ADD</a>
                                        </div>

                                      
                                        <button type="button" onClick={(e) => { submitUpdate(e) }} className="update_btn mt-10">SAVE</button>
                                    </div>
                                </div>
                            </div>
                            <div className="details_block">
                                <div className="hed">
                                    <h4>ABDOMEN & PELVIS </h4>
                                    <div className="yn_switch mb-0 mt-0">
                                        <label for="abdomen" className="switch-container">
                                            <input hidden="" type="checkbox" className="switch_check" id="abdomen" />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>NO</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>YES</span>
                                            </div>
                                        </label>
                                        <div className="label-container">
                                            <p>Abnormal </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="con">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="img_block crosshair" ref={(ref) => { pelvis_ref = ref }} id="pelvis">
                                                <img src={pelvis} alt="Pelvis" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_block2">
                                                <h3>Abdomen is</h3>
                                                <div className="check_item ">

                                                    {patientValues.abdomen_is ? <div className="select_btns popup_select">
                                                        <div className="select_btn"><label><input type="radio"
                                                            value={patientValues.abdomen_is} checked /><span>{patientValues.abdomen_is} </span></label>
                                                        </div> {patientValues.abdomen_is && <a onClick={(e) => updateClinicValues(e, "abdomen_is", false)}><span className="material-symbols-outlined"> reply
                                                        </span></a>}

                                                    </div>
                                                        :
                                                        <div className="select_btns mt-0">
                                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="abdomen_is" type="radio"
                                                                value="Soft/Rigid" /><span>Soft/Rigid </span></label></div>
                                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="abdomen_is" type="radio"
                                                                value="Tender" /><span> Tender</span></label> </div>
                                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="abdomen_is" type="radio"
                                                                value="Distended" /><span> Distended</span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="abdomen_is" type="radio"
                                                                value="Any guarding" /><span> Any guarding</span></label> </div>
                                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="abdomen_is" type="radio"
                                                                value="Masses" /><span> Masses</span></label> </div>
                                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="abdomen_is" type="radio"
                                                                value="Organomegaly" /><span> Organomegaly</span></label> </div>
                                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="abdomen_is" type="radio"
                                                                value="Scars or Hernia" /><span> Scars or Hernia</span></label> </div>

                                                        </div>


                                                    }

                                                </div>
                                                <div className="form_group mt-20">
                                                    <label className="label">RECTAL EXAM if carried out</label>
                                                    <input type="text" onChange={(e) => updateInputValue(e)} name="rectal_exam" className="form-control" />
                                                </div>
                                                <div className="yn_switch mt-20">
                                                    <div className="label-container">
                                                        <p>Catheter in situ on admission?  </p>
                                                    </div>
                                                    <label for="catheter" className="switch-container ml_10 ">
                                                        <input hidden="" type="checkbox" className="switch_check" id="catheter" />
                                                        <div className="switch-bg"></div>
                                                        <div className="round-box"></div>
                                                        <div className="switch-left">
                                                            <span>NO</span>
                                                        </div>
                                                        <div className="switch-right">
                                                            <span>YES</span>
                                                        </div>
                                                    </label>

                                                </div>
                                                {arr && arr.abdomen_is && Object.keys(arr.abdomen_is).map((item, i) => {
                                                    return (
                                                        <div className="form_group" key={i}>
                                                        <input
                                                            onChange={(e) => updateInputValue(e)}
                                                            type="text"
                                                            className="form-control"
                                                            name={item}
                                                        /></div>
                                                    );
                                                })}
                                                
                                            <div className="form_group">
                                                <a onClick={(e) => { addInput("abdomen_is") }} className="add_btn"> <span className="icon"> + </span> ADD</a>
                                            </div>
                                            
                                               
                                            </div>
                                            <button type="button" onClick={(e) => undoCircle(e, "pelvis")} className="update_btn mt-10">UNDO</button>
                                            <button type="button" onClick={(e) => { submitUpdate(e) }} className="update_btn mt-10">SAVE</button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="details_block">
                                <div className="hed">
                                    <h4>BACK </h4>
                                    <div className="yn_switch mb-0 mt-0">
                                        <label for="check1" className="switch-container">
                                            <input hidden="" type="checkbox" className="switch_check" id="check1" />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>NO</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>YES</span>
                                            </div>
                                        </label>
                                        <div className="label-container">
                                            <p>Abnormal </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="con">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="img_block crosshair" ref={(ref) => { back_ref = ref }} id="back">
                                                <img src={back} alt="Back" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_block2">

                                            {arr && arr.back && Object.keys(arr.back).map((item, i) => {
                                                    return (
                                                        <div className="form_group" key={i}>
                                                        <input
                                                            onChange={(e) => updateInputValue(e)}
                                                            type="text"
                                                            className="form-control"
                                                            name={item}
                                                        /></div>
                                                    );
                                                })}
                                                
                                            <div className="form_group">
                                                <a onClick={(e) => { addInput("back") }} className="add_btn"> <span className="icon"> + </span> ADD</a>
                                            </div>
                                                
                                            </div>
                                            <button type="button" onClick={(e) => undoCircle(e, "back")} className="update_btn mt-10">UNDO</button>
                                            <button type="button" onClick={(e) => { submitUpdate(e) }} className="update_btn mt-10">SAVE</button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="details_block">
                                <div className="hed">
                                    <h4>EXTREMITIES </h4>
                                    <div className="yn_switch mb-0 mt-0">
                                        <label for="check1" className="switch-container">
                                            <input hidden="" type="checkbox" className="switch_check" id="check1" />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>NO</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>YES</span>
                                            </div>
                                        </label>
                                        <div className="label-container">
                                            <p>Abnormal </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="con">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="img_block crosshair" ref={(ref) => { extremities_ref = ref }} id="extremities">
                                                <img src={extremities} alt="Extremities" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_block2">
                                            {arr && arr.extremities && Object.keys(arr.extremities).map((item, i) => {
                                                    return (
                                                        <div className="form_group" key={i}>
                                                        <input
                                                            onChange={(e) => updateInputValue(e)}
                                                            type="text"
                                                            className="form-control"
                                                            name={item}
                                                        /></div>
                                                    );
                                                })}
                                                
                                            <div className="form_group">
                                                <a onClick={(e) => { addInput("extremities") }} className="add_btn"> <span className="icon"> + </span> ADD</a>
                                            </div>   
                                               
                                            </div>
                                            <button type="button" onClick={(e) => undoCircle(e, "extremities")} className="update_btn mt-10">UNDO</button>
                                            <button type="button" onClick={(e) => { submitUpdate(e) }} className="update_btn mt-10">SAVE</button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="details_block">
                                <div className="hed">
                                    <h4>NEURO VASCULAR </h4>
                                    <div className="yn_switch mb-0 mt-0">
                                        <label for="check1" className="switch-container">
                                            <input hidden="" type="checkbox" className="switch_check" id="check1" />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>NO</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>YES</span>
                                            </div>
                                        </label>
                                        <div className="label-container">
                                            <p>Abnormal </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="con">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="img_block crosshair" ref={(ref) => { neuro_ref = ref }} id="neuro">
                                                <img src={neuro} alt="Neuro" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_block2">
                                            {arr && arr.neuro && Object.keys(arr.neuro).map((item, i) => {
                                                    return (
                                                        <div className="form_group" key={i}>
                                                        <input
                                                            onChange={(e) => updateInputValue(e)}
                                                            type="text"
                                                            className="form-control"
                                                            name={item}
                                                        /></div>
                                                    );
                                                })}
                                                
                                            <div className="form_group">
                                                <a onClick={(e) => { addInput("neuro") }} className="add_btn"> <span className="icon"> + </span> ADD</a>
                                            </div>     
                                               
                                            </div>
                                            <button type="button" onClick={(e) => undoCircle(e, "neuro")} className="update_btn mt-10">UNDO</button>
                                            <button type="button" onClick={(e) => { submitUpdate(e) }} className="update_btn mt-10">SAVE</button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="details_block">
                                <div className="hed">
                                    <h4>SKIN </h4>
                                    <div className="yn_switch mb-0 mt-0">
                                        <label for="check1" className="switch-container">
                                            <input hidden="" type="checkbox" className="switch_check" id="check1" />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>NO</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>YES</span>
                                            </div>
                                        </label>
                                        <div className="label-container">
                                            <p>Abnormal </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="con">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form_block2">
                                            {arr && arr.skin && Object.keys(arr.skin).map((item, i) => {
                                                    return (
                                                        <div className="form_group" key={i}>
                                                        <input
                                                            onChange={(e) => updateInputValue(e)}
                                                            type="text"
                                                            className="form-control"
                                                            name={item}
                                                        /></div>
                                                    );
                                                })}
                                                
                                            <div className="form_group">
                                                <a onClick={(e) => { addInput("skin") }} className="add_btn"> <span className="icon"> + </span> ADD</a>
                                            </div> 
                                               
                                            </div>
                                            <button type="button" onClick={(e) => { submitUpdate(e) }} className="update_btn mt-10">SAVE</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="details_block">
                                <div className="hed">
                                    <h4>GENERAL EXAMINATION </h4>
                                    <div className="yn_switch mb-0 mt-0">
                                        <label for="general_examination" className="switch-container">
                                            <input hidden="" type="checkbox" className="switch_check" id="general_examination" />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>NO</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>YES</span>
                                            </div>
                                        </label>
                                        <div className="label-container">
                                            <p>Abnormal </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="con">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form_block2">
                                                <div className="check_item ">
                                                    {patientValues.ge_jaundiced ? <div className="select_btns popup_select">
                                                        <div className="select_btn"><label><input type="radio"
                                                            value={patientValues.ge_jaundiced} checked /><span>{patientValues.ge_jaundiced} </span></label>
                                                        </div> {patientValues.ge_jaundiced && <a onClick={(e) => updateClinicValues(e, "ge_jaundiced", false)}><span className="material-symbols-outlined"> reply
                                                        </span></a>}

                                                    </div>
                                                        :
                                                        <div className="select_btns mt-0">
                                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ge_jaundiced" type="radio"
                                                                value="Jaundiced" /><span>Jaundiced </span></label></div>
                                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ge_jaundiced" type="radio"
                                                                value="Cyanosed" /><span> Cyanosed</span></label> </div>
                                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ge_jaundiced" type="radio"
                                                                value="Peripheral Oedema" /><span> Peripheral Oedema</span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ge_jaundiced" type="radio"
                                                                value="Lymphoedema" /><span> Lymphoedema</span></label> </div>

                                                        </div>}

                                                </div>
                                                {arr && arr.examination && Object.keys(arr.examination).map((item, i) => {
                                                    return (
                                                        <div className="form_group" key={i}>
                                                        <input
                                                            onChange={(e) => updateInputValue(e)}
                                                            type="text"
                                                            className="form-control"
                                                            name={item}
                                                        /></div>
                                                    );
                                                })}
                                                
                                            <div className="form_group">
                                                <a onClick={(e) => { addInput("examination") }} className="add_btn"> <span className="icon"> + </span> ADD</a>
                                            </div> 

                                               
                                            </div>
                                            <button type="button" onClick={(e) => { submitUpdate(e) }} className="update_btn mt-10">SAVE</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h2 className="popup_title text-center">Management Instructions</h2>
                            <div className="sub_section">
                                <p className="field_title mt-20 mb-20">Resuscitation status and escalation <span className="check_btn2" onClick={(e) => updateClinicValues(e, "resuscitation_flag", true)}><span className="material-icons"> done </span></span></p>

                                {patientValues.resuscitation_flag && patientValues.resuscitation && Object.keys(patientValues.resuscitation).length > 0 ? <div className="check_list w_white mb-0">

                                    {Object.keys(patientValues.resuscitation).map((item, i) => (
                                        <div className="check_item active" key={i} onClick={(e) => updateClinicValues(e, "resuscitation_flag", false)} ><label className="check">{patientValues.resuscitation[item]}</label></div>
                                    ))}

                                </div> : <>

                                    <div className="check-box sqeare ">
                                        <div className="round">
                                            <input type="checkbox" onClick={(e) => selectCheckbox(e, "resuscitation")} name="resuscitation_treatment" value="Resuscitation and ceiling of treatment clarified and discussed with patient" id="resuscitation_treatment" defaultChecked={patientValues && patientValues.resuscitation && patientValues.resuscitation["resuscitation_treatment"] ? true : false} />
                                            <label for="resuscitation_treatment"></label>
                                        </div>
                                        Resuscitation and ceiling of treatment clarified and discussed with patient
                                    </div>
                                    <div className="check-box sqeare ">
                                        <div className="round">
                                            <input type="checkbox" onClick={(e) => selectCheckbox(e, "resuscitation")} name="resuscitation_community" value="Community DNACPR" id="resuscitation_community" defaultChecked={patientValues && patientValues.resuscitation && patientValues.resuscitation["resuscitation_community"] ? true : false} />
                                            <label for="resuscitation_community"></label>
                                        </div>
                                        Community DNACPR
                                    </div>
                                    <div className="check-box sqeare ">
                                        <div className="round">
                                            <input type="checkbox" onClick={(e) => selectCheckbox(e, "resuscitation")} name="resuscitation_dnacpr" value="DNACPR" id="resuscitation_dnacpr" defaultChecked={patientValues && patientValues.resuscitation && patientValues.resuscitation["resuscitation_dnacpr"] ? true : false} />
                                            <label for="resuscitation_dnacpr"></label>
                                        </div>
                                        DNACPR
                                    </div></>}

                                <p className="field_title mt-20 mb-0">Level 3 – Intubation /Ventilation</p>
                                <p className="field_title mb-0 mt-5">Level2 - NIV</p>
                                <p className="field_title mb-0 mt-5">Level 1 - Active ward based care</p>
                                <p className="field_title mb-0 mt-5">Inadequate information to decide <span className="check_btn2" onClick={(e) => updateClinicValues(e, "inadequate_flag", true)}><span className="material-icons"> done </span></span></p>
                            </div>
                            <div className="sub_section">

                                {patientValues.inadequate_flag && patientValues.inadequate && Object.keys(patientValues.inadequate).length > 0 ? <div className="check_list w_white mb-0">

                                    {Object.keys(patientValues.inadequate).map((item, i) => (
                                        <div className="check_item active" key={i} onClick={(e) => updateClinicValues(e, "inadequate_flag", false)} ><label className="check">{patientValues.inadequate[item]}</label></div>
                                    ))}

                                </div> : <>
                                    <div className="check_col">
                                        <div className="check-box sqeare ">
                                            <div className="round">
                                                <input type="checkbox" onClick={(e) => selectCheckbox(e, "inadequate")} name="inadequate_admit" value="ADMIT PATIENT   to Ward or Bed" id="inadequate_admit" defaultChecked={patientValues && patientValues.inadequate && patientValues.inadequate["inadequate_admit"] ? true : false} />
                                                <label for="inadequate_admit"></label>
                                            </div>
                                            ADMIT PATIENT   to
                                        </div>
                                        <div className="check_item ">
                                            <div className="select_btns mt-0">
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="inadequate_ward" type="radio"
                                                    value="Ward" /><span>Ward </span></label></div>
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="inadequate_ward" type="radio"
                                                    value="Bed" /><span> Bed</span></label> </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="check-box sqeare ">
                                        <div className="round">
                                            <input type="checkbox" onClick={(e) => selectCheckbox(e, "inadequate")} name="inadequate_nbm" value="NBM from" id="inadequate_nbm" defaultChecked={patientValues && patientValues.inadequate && patientValues.inadequate["inadequate_nbm"] ? true : false} />
                                            <label for="inadequate_nbm"></label>
                                        </div>
                                        NBM from
                                    </div>
                                    <div className="check-box sqeare ">
                                        <div className="round">
                                            <input type="checkbox" onClick={(e) => selectCheckbox(e, "inadequate")} name="inadequate_obv" value="Observations and their frequency: NEWS2,  Other" id="inadequate_obv" defaultChecked={patientValues && patientValues.inadequate && patientValues.inadequate["inadequate_obv"] ? true : false} />
                                            <label for="inadequate_obv"></label>
                                        </div>
                                        Observations and their frequency: NEWS2,  Other
                                    </div>
                                    <div className="check-box sqeare ">
                                        <div className="round">
                                            <input type="checkbox" onClick={(e) => selectCheckbox(e, "inadequate")} name="inadequate_mob" value="Mobility and splinting and weight bearing status" id="inadequate_mob" defaultChecked={patientValues && patientValues.inadequate && patientValues.inadequate["inadequate_mob"] ? true : false} />
                                            <label for="inadequate_mob"></label>
                                        </div>
                                        Mobility and splinting and weight bearing status
                                    </div>

                                    <div className="check-box sqeare ">
                                        <div className="round">
                                            <input type="checkbox" onClick={(e) => selectCheckbox(e, "inadequate")} name="inadequate_physio" value="Involvement of Physio, OT, SALT" id="inadequate_physio" defaultChecked={patientValues && patientValues.inadequate && patientValues.inadequate["inadequate_physio"] ? true : false} />
                                            <label for="inadequate_physio"></label>
                                        </div>
                                        Involvement of Physio, OT, SALT
                                    </div>
                                    <div className="check-box sqeare ">
                                        <div className="round">
                                            <input type="checkbox" onClick={(e) => selectCheckbox(e, "inadequate")} name="inadequate_spl" value="SPECIAL INSTRUCTIONS" id="inadequate_spl" defaultChecked={patientValues && patientValues.inadequate && patientValues.inadequate["inadequate_spl"] ? true : false} />
                                            <label for="inadequate_spl"></label>
                                        </div>
                                        SPECIAL INSTRUCTIONS
                                    </div>
                                    <div className="check-box sqeare ">
                                        <div className="round">
                                            <input type="checkbox" onClick={(e) => selectCheckbox(e, "inadequate")} name="inadequate_aki" value="AKI risk assessment - referring to nephrology" id="inadequate_aki" defaultChecked={patientValues && patientValues.inadequate && patientValues.inadequate["inadequate_aki"] ? true : false} />
                                            <label for="inadequate_aki"></label>
                                        </div>
                                        AKI risk assessment - referring to nephrology
                                    </div>
                                    <div className="check-box sqeare ">
                                        <div className="round">
                                            <input type="checkbox" onClick={(e) => selectCheckbox(e, "inadequate")} name="inadequate_dementia" value="Dementia screening tool: N/A" id="inadequate_dementia" defaultChecked={patientValues && patientValues.inadequate && patientValues.inadequate["inadequate_dementia"] ? true : false} />
                                            <label for="inadequate_dementia"></label>
                                        </div>
                                        Dementia screening tool: N/A
                                    </div>
                                    <div className="check-box sqeare ">
                                        <div className="round">
                                            <input type="checkbox" onClick={(e) => selectCheckbox(e, "inadequate")} name="inadequate_alcohol" value="Alcohol withdrawal plan: N/A" id="inadequate_alcohol" defaultChecked={patientValues && patientValues.inadequate && patientValues.inadequate["inadequate_alcohol"] ? true : false} />
                                            <label for="inadequate_alcohol"></label>
                                        </div>
                                        Alcohol withdrawal plan: N/A
                                    </div>
                                    <div className="check-box sqeare ">
                                        <div className="round">
                                            <input type="checkbox" onClick={(e) => selectCheckbox(e, "inadequate")} name="inadequate_mrsa" value="MRSA Screen" id="inadequate_mrsa" defaultChecked={patientValues && patientValues.inadequate && patientValues.inadequate["inadequate_mrsa"] ? true : false} />
                                            <label for="inadequate_mrsa"></label>
                                        </div>
                                        MRSA Screen
                                    </div>
                                    <div className="check-box sqeare ">
                                        <div className="round">
                                            <input type="checkbox" onClick={(e) => selectCheckbox(e, "inadequate")} name="inadequate_deco" value="MRSA Decolonization" id="inadequate_deco" defaultChecked={patientValues && patientValues.inadequate && patientValues.inadequate["inadequate_deco"] ? true : false} />
                                            <label for="inadequate_deco"></label>
                                        </div>
                                        MRSA Decolonization
                                    </div>
                                </>}

                            </div>
                            <div className="sub_section">
                                <p className="field_title mt-20 mb-20">VTE PROPHYLAXIS <span className="check_btn2" onClick={(e) => updateClinicValues(e, "vte_flag", true)}><span className="material-icons"> done </span></span></p>
                                {patientValues.vte_flag && patientValues.vte && Object.keys(patientValues.vte).length > 0 ? <div className="check_list w_white mb-0">

                                    {Object.keys(patientValues.vte).map((item, i) => (
                                        <div className="check_item active" key={i} onClick={(e) => updateClinicValues(e, "vte_flag", false)} ><label className="check">{patientValues.vte[item]}</label></div>
                                    ))}

                                </div> : <>


                                    <div className="check-box sqeare ">
                                        <div className="round">
                                            <input type="checkbox" onClick={(e) => selectCheckbox(e, "vte")} name="vte_riskasses" value="VTE Risk Assessment completed" id="vte_riskasses" defaultChecked={patientValues && patientValues.vte && patientValues.vte["vte_riskasses"] ? true : false} />
                                            <label for="vte_riskasses"></label>
                                        </div>
                                        VTE Risk Assessment completed
                                    </div>
                                    <div className="check-box sqeare ">
                                        <div className="round">
                                            <input type="checkbox" onClick={(e) => selectCheckbox(e, "vte")} name="vte_dalt" value="Dalteparin prescribed" id="vte_dalt" defaultChecked={patientValues && patientValues.vte && patientValues.vte["vte_dalt"] ? true : false} />
                                            <label for="vte_dalt"></label>
                                        </div>
                                        Dalteparin prescribed
                                    </div>
                                    <div className="check-box sqeare ">
                                        <div className="round">
                                            <input type="checkbox" onClick={(e) => selectCheckbox(e, "vte")} name="vte_lastdose" value="On any anticoagulants like Apixaban, warfarin Clopidogrel" id="vte_lastdose" defaultChecked={patientValues && patientValues.vte && patientValues.vte["vte_lastdose"] ? true : false} />
                                            <label for="vte_lastdose"></label>
                                        </div>
                                        On any anticoagulants like Apixaban, warfarin Clopidogrel
                                    </div>
                                    <div className="check-box sqeare ">
                                        <div className="round">
                                            <input type="checkbox" onClick={(e) => selectCheckbox(e, "vte")} name="vte_reversal" value="Reversal of anticoagulation:  Bereplex, Tranexamic acid" id="vte_reversal" defaultChecked={patientValues && patientValues.vte && patientValues.vte["vte_reversal"] ? true : false} />
                                            <label for="vte_reversal"></label>
                                        </div>
                                        Reversal of anticoagulation:  Bereplex, Tranexamic acid
                                    </div>
                                    <div className="check-box sqeare ">
                                        <div className="round">
                                            <input type="checkbox" onClick={(e) => selectCheckbox(e, "vte")} name="vte_warfarin" value="Warfarin reversed to INR &lt; 1.5" id="vte_warfarin" defaultChecked={patientValues && patientValues.vte && patientValues.vte["vte_warfarin"] ? true : false} />
                                            <label for="vte_warfarin"></label>
                                        </div>
                                        Warfarin reversed to INR &lt; 1.5 </div>
                                    <div className="check-box sqeare ">
                                        <div className="round">
                                            <input type="checkbox" onClick={(e) => selectCheckbox(e, "vte")} name="vte_bridging" value="Bridging anticoagulation" id="vte_bridging" defaultChecked={patientValues && patientValues.vte && patientValues.vte["vte_bridging"] ? true : false} />
                                            <label for="vte_bridging"></label>
                                        </div>
                                        Bridging anticoagulation
                                    </div>
                                </>}


                            </div>

                            <div className="sub_section">
                                <p className="field_title mt-20 mb-20">OTHER MEDICATIONS <span className="check_btn2" onClick={(e) => updateClinicValues(e, "other_medi_flag", true)}><span className="material-icons"> done </span></span></p>

                                {patientValues.other_medi_flag && patientValues.other_medi && Object.keys(patientValues.other_medi).length > 0 ? <div className="check_list w_white mb-0">

                                    {Object.keys(patientValues.other_medi).map((item, i) => (
                                        <div className="check_item active" key={i} onClick={(e) => updateClinicValues(e, "other_medi_flag", false)} ><label className="check">{patientValues.other_medi[item]}</label></div>
                                    ))}

                                </div> : <>

                                    <div className="check-box sqeare ">
                                        <div className="round">
                                            <input type="checkbox" onClick={(e) => selectCheckbox(e, "other_medi")} name="other_medi_anti" value="Antibiotics: Prescribed / Not indicated" id="other_medi_anti" defaultChecked={patientValues && patientValues.other_medi && patientValues.other_medi["other_medi_anti"] ? true : false} />
                                            <label for="other_medi_anti"></label>
                                        </div>
                                        Antibiotics: Prescribed / Not indicated
                                    </div>
                                    <div className="check-box sqeare ">
                                        <div className="round">
                                            <input type="checkbox" onClick={(e) => selectCheckbox(e, "other_medi")} name="other_medi_analgesia" value="Analgesia, Anti emetics and aperients: Prescribed / Notindicated" id="other_medi_analgesia" defaultChecked={patientValues && patientValues.other_medi && patientValues.other_medi["other_medi_analgesia"] ? true : false} />
                                            <label for="other_medi_analgesia"></label>
                                        </div>
                                        Analgesia, Anti emetics and aperients: Prescribed / Notindicated
                                    </div>
                                    <div className="check-box sqeare ">
                                        <div className="round">
                                            <input type="checkbox" onClick={(e) => selectCheckbox(e, "other_medi")} name="other_medi_ivfluid" value="IV fluids: Prescribed / Not indicated" id="other_medi_ivfluid" defaultChecked={patientValues && patientValues.other_medi && patientValues.other_medi["other_medi_ivfluid"] ? true : false} />
                                            <label for="other_medi_ivfluid"></label>
                                        </div>
                                        IV fluids: Prescribed / Not indicated
                                    </div>
                                    <div className="check-box sqeare ">
                                        <div className="round">
                                            <input type="checkbox" onClick={(e) => selectCheckbox(e, "other_medi")} name="other_medi_reg" value="Regular Medications" id="other_medi_reg" defaultChecked={patientValues && patientValues.other_medi && patientValues.other_medi["other_medi_reg"] ? true : false} />
                                            <label for="other_medi_reg"></label>
                                        </div>
                                        Regular Medications
                                    </div>
                                    <div className="check-box sqeare ">
                                        <div className="round">
                                            <input type="checkbox" onClick={(e) => selectCheckbox(e, "other_medi")} name="other_medi_pre" value="Pre meds" id="other_medi_pre" defaultChecked={patientValues && patientValues.other_medi && patientValues.other_medi["other_medi_pre"] ? true : false} />
                                            <label for="other_medi_pre"></label>
                                        </div>
                                        Pre meds
                                    </div>
                                    <div className="check-box sqeare ">
                                        <div className="round">
                                            <input type="checkbox" onClick={(e) => selectCheckbox(e, "other_medi")} name="other_medi_emla" value="Emla cream" id="other_medi_emla" defaultChecked={patientValues && patientValues.other_medi && patientValues.other_medi["other_medi_emla"] ? true : false} />
                                            <label for="other_medi_emla"></label>
                                        </div>
                                        Emla cream
                                    </div>
                                    <div className="check-box sqeare ">
                                        <div className="round">
                                            <input type="checkbox" onClick={(e) => selectCheckbox(e, "other_medi")} name="other_medi_ace" value="ACE inhibitors omitted on the day" id="other_medi_ace" defaultChecked={patientValues && patientValues.other_medi && patientValues.other_medi["other_medi_ace"] ? true : false} />
                                            <label for="other_medi_ace"></label>
                                        </div>
                                        ACE inhibitors omitted on the day
                                    </div>
                                    <div className="check-box sqeare ">
                                        <div className="round">
                                            <input type="checkbox" onClick={(e) => selectCheckbox(e, "other_medi")} name="other_medi_inhal" value="Inhalers / sprays taken to theatre" id="other_medi_inhal" defaultChecked={patientValues && patientValues.other_medi && patientValues.other_medi["other_medi_inhal"] ? true : false} />
                                            <label for="other_medi_inhal"></label>
                                        </div>
                                        Inhalers / sprays taken to theatre
                                    </div>
                                    <div className="check-box sqeare ">
                                        <div className="round">
                                            <input type="checkbox" onClick={(e) => selectCheckbox(e, "other_medi")} name="other_medi_sec" value="Secondary fracture prevention  Cholecalciferol 100,000U" id="other_medi_sec" defaultChecked={patientValues && patientValues.other_medi && patientValues.other_medi["other_medi_sec"] ? true : false} />
                                            <label for="other_medi_sec"></label>
                                        </div>
                                        Secondary fracture prevention  Cholecalciferol 100,000U
                                    </div>
                                </>}


                                <p className="field_title mt-20 mb-20">INSULIN SLIDING SCALE</p>
                                <div className="insulin_section">
                                    <div className="left">
                                        <div className="hed">Blood Glucose</div>
                                        <div className="item">0-4</div>
                                        <div className="item">4.1 - 8</div>
                                        <div className="item">8.1 - 10</div>
                                        <div className="item">10.1 - 12</div>
                                        <div className="item">12.1 - 14</div>
                                        <div className="item">14.1 - 16</div>
                                        <div className="item">16.1 - 18</div>
                                        <div className="item">18.1 - 20</div>
                                        <div className="item">&gt; 20</div>
                                    </div>
                                    <div className="right">
                                        <div className="hed">Humulin R (units) S/C</div>
                                        <div className="item">Call MD 1 amp D50 or Glass of Orange Juice</div>
                                        <div className="item">None</div>
                                        <div className="item">2</div>
                                        <div className="item">4</div>
                                        <div className="item">6</div>
                                        <div className="item">8</div>
                                        <div className="item">10</div>
                                        <div className="item">12</div>
                                        <div className="item">14 - Call MD</div>
                                    </div>
                                </div>
                                <p className="field_title mt-20 mb-0">Must also be accompanied by an order for blood glucose
                                    monitoring</p>
                            </div>
                            <div className="sub_section">
                                <p className="sub_title mt-20">THEATRE PREPARATION</p>
                                <p className="field_title mt-20 mb-20">READY FOR THEATRE <span className="check_btn2" onClick={(e) => updateClinicValues(e, "theatre_flag", true)}><span className="material-icons"> done </span></span></p>


                                {patientValues.theatre_flag && patientValues.theatre && Object.keys(patientValues.theatre).length > 0 ? <div className="check_list w_white mb-0">

                                    {Object.keys(patientValues.theatre).map((item, i) => (
                                        <div className="check_item active" key={i} onClick={(e) => updateClinicValues(e, "theatre_flag", false)} ><label className="check">{patientValues.theatre[item]}</label></div>
                                    ))}

                                </div> : <>
                                    <div className="check-box sqeare ">
                                        <div className="round">
                                            <input type="checkbox" onClick={(e) => selectCheckbox(e, "theatre")} name="theatre_aware" value="Theatre aware, Details on trauma list, Trauma conference" id="theatre_aware" defaultChecked={patientValues && patientValues.theatre && patientValues.theatre["theatre_aware"] ? true : false} />
                                            <label for="theatre_aware"></label>
                                        </div>
                                        Theatre aware, Details on trauma list, Trauma conference
                                    </div>
                                    <div className="check-box sqeare ">
                                        <div className="round">
                                            <input type="checkbox" onClick={(e) => selectCheckbox(e, "theatre")} name="theatre_consent" value="Consented, Skin marked & verified" id="theatre_consent" defaultChecked={patientValues && patientValues.theatre && patientValues.theatre["theatre_consent"] ? true : false} />
                                            <label for="theatre_consent"></label>
                                        </div>
                                        Consented, Skin marked & verified
                                    </div>
                                    <div className="check-box sqeare ">
                                        <div className="round">
                                            <input type="checkbox" onClick={(e) => selectCheckbox(e, "theatre")} name="theatre_group" value="Group & Save 2 units – Sent" id="theatre_group" defaultChecked={patientValues && patientValues.theatre && patientValues.theatre["theatre_group"] ? true : false} />
                                            <label for="theatre_group"></label>
                                        </div>
                                        Group & Save 2 units – Sent
                                    </div>
                                </>}


                                <p className="field_title mt-30 mb-20">REASON NOT READY <span className="check_btn2" onClick={(e) => updateClinicValues(e, "ready_flag", true)}><span className="material-icons"> done </span></span></p>

                                {patientValues.ready_flag && patientValues.ready && Object.keys(patientValues.ready).length > 0 ? <div className="check_list w_white mb-0">

                                    {Object.keys(patientValues.ready).map((item, i) => (
                                        <div className="check_item active" key={i} onClick={(e) => updateClinicValues(e, "ready_flag", false)} ><label className="check">{patientValues.ready[item]}</label></div>
                                    ))}

                                </div> : <>
                                    <div className="check-box sqeare ">
                                        <div className="round">
                                            <input type="checkbox" onClick={(e) => selectCheckbox(e, "ready")} name="ready_stable" value="Stable / Unstable" id="ready_stable" defaultChecked={patientValues && patientValues.ready && patientValues.ready["ready_stable"] ? true : false} />
                                            <label for="ready_stable"></label>
                                        </div>
                                        Stable / Unstable
                                    </div>
                                    <div className="check-box sqeare ">
                                        <div className="round">
                                            <input type="checkbox" onClick={(e) => selectCheckbox(e, "ready")} name="ready_need" value="Need for anaesthesia or internal medicine referral (andwhether called/seen/cleared for
                                    operation)" id="ready_need" defaultChecked={patientValues && patientValues.ready && patientValues.ready["ready_need"] ? true : false} />
                                            <label for="ready_need"></label>
                                        </div>
                                        Need for anaesthesia or internal medicine referral (andwhether called/seen/cleared for
                                        operation)
                                    </div>
                                    <div className="check-box sqeare ">
                                        <div className="round">
                                            <input type="checkbox" onClick={(e) => selectCheckbox(e, "ready")} name="ready_await" value="Awaiting more information" id="ready_await" defaultChecked={patientValues && patientValues.ready && patientValues.ready["ready_await"] ? true : false} />
                                            <label for="ready_await"></label>
                                        </div>
                                        Awaiting more information
                                    </div>
                                    <div className="check-box sqeare ">
                                        <div className="round">
                                            <input type="checkbox" onClick={(e) => selectCheckbox(e, "ready")} name="ready_evaluate" value="Need to evaluate" id="ready_evaluate" defaultChecked={patientValues && patientValues.ready && patientValues.ready["ready_evaluate"] ? true : false} />
                                            <label for="ready_evaluate"></label>
                                        </div>
                                        Need to evaluate
                                    </div>
                                    <div className="check-box sqeare ">
                                        <div className="round">
                                            <input type="checkbox" onClick={(e) => selectCheckbox(e, "ready")} name="ready_interpreter" value="Consent 4 / Interpreter" id="ready_interpreter" defaultChecked={patientValues && patientValues.ready && patientValues.ready["ready_interpreter"] ? true : false} />
                                            <label for="ready_interpreter"></label>
                                        </div>
                                        Consent 4 / Interpreter
                                    </div>
                                    <div className="check-box sqeare ">
                                        <div className="round">
                                            <input type="checkbox" onClick={(e) => selectCheckbox(e, "ready")} name="ready_any" value="Anything else preventing from taking patient to theatre?" id="ready_any" defaultChecked={patientValues && patientValues.ready && patientValues.ready["ready_any"] ? true : false} />
                                            <label for="ready_any"></label>
                                        </div>
                                        Anything else preventing from taking patient to theatre?
                                    </div>
                                </>}

                            </div>
                            <div className="sub_section mb-0">

                                <p className="field_title mt-20 mb-20">OUTSTANDING ISSUES <span className="check_btn2" onClick={(e) => updateClinicValues(e, "outstanding_flag", true)}><span className="material-icons"> done </span></span></p>


                                {patientValues.outstanding_flag && patientValues.outstanding && Object.keys(patientValues.outstanding).length > 0 ? <div className="check_list w_white mb-0">

                                    {Object.keys(patientValues.outstanding).map((item, i) => (
                                        <div className="check_item active" key={i} onClick={(e) => updateClinicValues(e, "outstanding_flag", false)} ><label className="check">{patientValues.outstanding[item]}</label></div>
                                    ))}

                                </div> : <>
                                    <div className="check-box sqeare ">
                                        <div className="round">
                                            <input type="checkbox" onClick={(e) => selectCheckbox(e, "outstanding")} name="outstanding_dis" value="Discussion with seniors/Senior review" id="outstanding_dis" defaultChecked={patientValues && patientValues.outstanding && patientValues.outstanding["outstanding_dis"] ? true : false} />
                                            <label for="outstanding_dis"></label>
                                        </div>
                                        Discussion with seniors/Senior review
                                    </div>
                                    <div className="check-box sqeare ">
                                        <div className="round">
                                            <input type="checkbox" onClick={(e) => selectCheckbox(e, "outstanding")} name="outstanding_consult" value="Consultant post-takeward round" id="outstanding_consult" defaultChecked={patientValues && patientValues.outstanding && patientValues.outstanding["outstanding_consult"] ? true : false} />
                                            <label for="outstanding_consult"></label>
                                        </div>
                                        Consultant post-takeward round
                                    </div>
                                    <div className="check-box sqeare ">
                                        <div className="round">
                                            <input type="checkbox" onClick={(e) => selectCheckbox(e, "outstanding")} name="outstanding_input" value="Input from Care of the Elderly Consultant?" id="outstanding_input" defaultChecked={patientValues && patientValues.outstanding && patientValues.outstanding["outstanding_input"] ? true : false} />
                                            <label for="outstanding_input"></label>
                                        </div>
                                        Input from Care of the Elderly Consultant?
                                    </div>
                                    <p className="field_title mt-30 mb-20"></p>
                                    <div className="check-box sqeare ">
                                        <div className="round">
                                            <input type="checkbox" onClick={(e) => selectCheckbox(e, "outstanding")} name="outstanding_further" value="Further investigations required" id="outstanding_further" defaultChecked={patientValues && patientValues.outstanding && patientValues.outstanding["outstanding_further"] ? true : false} />
                                            <label for="outstanding_further"></label>
                                        </div>
                                        Further investigations required
                                    </div>
                                    <div className="check-box sqeare ">
                                        <div className="round">
                                            <input type="checkbox" onClick={(e) => selectCheckbox(e, "outstanding")} name="outstanding_diagnostic" value="Diagnostic imaging still needing to be ordered/reviewed (i.e.,cervical spine for
                                    rheumatoid arthritis patient, CT scan for preoperativeplanning)" id="outstanding_diagnostic" defaultChecked={patientValues && patientValues.outstanding && patientValues.outstanding["outstanding_diagnostic"] ? true : false} />
                                            <label for="outstanding_diagnostic"></label>
                                        </div>
                                        Diagnostic imaging still needing to be ordered/reviewed (i.e.,cervical spine for
                                        rheumatoid arthritis patient, CT scan for preoperativeplanning)
                                    </div>
                                    <div className="check-box sqeare ">
                                        <div className="round">
                                            <input type="checkbox" onClick={(e) => selectCheckbox(e, "outstanding")} name="outstanding_management" value="Management options with patient and, whereappropriate, family Inform relatives if
                                    indicated" id="outstanding_management" defaultChecked={patientValues && patientValues.outstanding && patientValues.outstanding["outstanding_management"] ? true : false} />
                                            <label for="outstanding_management"></label>
                                        </div>
                                        Management options with patient and, whereappropriate, family Inform relatives if
                                        indicated
                                    </div>
                                    <div className="check-box sqeare ">
                                        <div className="round">
                                            <input type="checkbox" onClick={(e) => selectCheckbox(e, "outstanding")} name="outstanding_discharge" value="Discharge planning" id="outstanding_discharge" defaultChecked={patientValues && patientValues.outstanding && patientValues.outstanding["outstanding_discharge"] ? true : false} />
                                            <label for="outstanding_discharge"></label>
                                        </div>
                                        Discharge planning
                                    </div>
                                    <p className="field_title mt-30 mb-20"></p>
                                    <div className="check-box sqeare ">
                                        <div className="round">
                                            <input type="checkbox" onClick={(e) => selectCheckbox(e, "outstanding")} name="outstanding_senior" value="Senior Review" id="outstanding_senior" defaultChecked={patientValues && patientValues.outstanding && patientValues.outstanding["outstanding_senior"] ? true : false} />
                                            <label for="outstanding_senior"></label>
                                        </div>
                                        Senior Review
                                    </div>
                                    <div className="check-box sqeare ">
                                        <div className="round">
                                            <input type="checkbox" onClick={(e) => selectCheckbox(e, "outstanding")} name="outstanding_post" value="Consultant post-take ward round" id="outstanding_post" defaultChecked={patientValues && patientValues.outstanding && patientValues.outstanding["outstanding_post"] ? true : false} />
                                            <label for="outstanding_post"></label>
                                        </div>
                                        Consultant post-take ward round
                                    </div>
                                </>}
                            </div>
                            <button type="button" onClick={(e) => { submitUpdate(e) }} className="update_btn">UPDATE</button>
                            <br /><br /><br />
                        </div>

                        <div className="modal fade view_popup" id="exampleModalLong" tabIndex="-1" role="dialog"
                            aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">Preview</h5>
                                        <span className="material-icons" data-dismiss="modal" aria-label="Close">
                                            close
                                        </span>
                                    </div>
                                    <div className="modal-body">
                                        <img className="w-100" data-toggle="modal" data-target="#exampleModalLong"
                                            src={xray} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal fade aide_popup" id="aide" tabIndex="-1" role="dialog"
                            aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">Aide Memoire</h5>
                                        <span className="material-icons" data-dismiss="modal" aria-label="Close">
                                            close
                                        </span>
                                    </div>
                                    <div className="modal-body">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="form_group">
                                                    <label className="label">HB:</label>
                                                    <p></p>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form_group">
                                                    <label className="label">WBC:</label>
                                                    <p></p>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form_group">
                                                    <label className="label">HCT:</label>
                                                    <p></p>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form_group">
                                                    <label className="label">PLTS:</label>
                                                    <p></p>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form_group">
                                                    <label className="label">CRP:</label>
                                                    <p></p>
                                                </div>
                                            </div>
                                            <div className="col-md-8">
                                                <div className="form_group">
                                                    <label className="label">ESR:</label>
                                                    <p></p>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form_group">
                                                    <label className="label">NA:</label>
                                                    <p></p>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form_group">
                                                    <label className="label">K:</label>
                                                    <p></p>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form_group">
                                                    <label className="label">CR:</label>
                                                    <p></p>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form_group">
                                                    <label className="label">UR:</label>
                                                    <p></p>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form_group">
                                                    <label className="label">CHLORIDE:</label>
                                                    <p></p>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form_group">
                                                    <label className="label">VENOUS HCO3:</label>
                                                    <p></p>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form_group">
                                                    <label className="label">CA2+/ALBUMIN:</label>
                                                    <p></p>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form_group">
                                                    <label className="label">MG2+ PO4:</label>
                                                    <p></p>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form_group">
                                                    <label className="label">EGFR:</label>
                                                    <p></p>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form_group">
                                                    <label className="label">BLOOD GLUCOSE:</label>
                                                    <p></p>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form_group">
                                                    <label className="label">BM:</label>
                                                    <p></p>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form_group">
                                                    <label className="label">HBA1C:</label>
                                                    <p></p>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form_group">
                                                    <label className="label">PT/INR(IF WARFARIN) :</label>
                                                    <p></p>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form_group">
                                                    <label className="label">CLOTTING SCREEN     :</label>
                                                    <p></p>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form_group">
                                                    <label className="label">APTT (ACTIVATED PARTIAL THROMBOPLASTIN TIME):</label>
                                                    <p></p>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form_group">
                                                    <label className="label">TT (THROMBIN TIME):</label>
                                                    <p></p>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form_group">
                                                    <label className="label">FIBRINOGEN:</label>
                                                    <p></p>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form_group">
                                                    <label className="label">FIBRINOGEN DEGRADATION PRODUCTS:</label>
                                                    <p></p>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form_group">
                                                    <label className="label">BLOOD GROUP AND SAVE   :</label>
                                                    <p></p>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form_group">
                                                    <label className="label">GROUPED AND CROSS MATCHED? Y/ NA:</label>
                                                    <p></p>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form_group">
                                                    <label className="label">UNITS OF BLOOD AVAILABLE:</label>
                                                    <p></p>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form_group">
                                                    <label className="label">ECG:</label>
                                                    <p></p>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form_group">
                                                    <label className="label">ECHOCARDIOGRAM:</label>
                                                    <p></p>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form_group">
                                                    <label className="label">CXR:</label>
                                                    <p></p>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form_group">
                                                    <label className="label">LIMB XRAY:</label>
                                                    <p></p>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form_group">
                                                    <label className="label">CT+/- CONTRAST:</label>
                                                    <p></p>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form_group">
                                                    <label className="label">MRI:</label>
                                                    <p></p>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form_group">
                                                    <label className="label">ULTRASOUND SCANS - DVT, ABDOMEN, BREAST ETC:</label>
                                                    <p></p>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form_group">
                                                    <label className="label">BONE SCAN,  V/Q SCAN:</label>
                                                    <p></p>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form_group">
                                                    <label className="label">MICROBIOLOGY / VIROLOGY: SWABS FOR MIC, CULT. &
                                                        SENSITIVITIES
                                                        (MC&S):</label>
                                                    <p></p>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form_group">
                                                    <label className="label">MRSA SCREEN:</label>
                                                    <p></p>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form_group">
                                                    <label className="label">BLOOD CULTURES:</label>
                                                    <p></p>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form_group">
                                                    <label className="label">SPUTUM CULTURES:</label>
                                                    <p></p>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form_group">
                                                    <label className="label">MSU:</label>
                                                    <p></p>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form_group">
                                                    <label className="label">URINALYSIS - URINE DIP:</label>
                                                    <p></p>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form_group">
                                                    <label className="label">BHCG:</label>
                                                    <p></p>
                                                </div>
                                            </div>
                                        </div>
                                        <button type="button" className="btn mt-20">Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="modal fade aide_popup awp_popup" id="awp" tabIndex="-1" role="dialog"
                            aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">REVERSIBLE MEDICAL
                                            CONDITIONS/COMORBIDITIES</h5>
                                        <span className="material-icons" data-dismiss="modal" aria-label="Close">
                                            close
                                        </span>
                                    </div>
                                    <div className="modal-body">



                                        <div className="check-box sqeare ">
                                            <div className="round">
                                                <input type="checkbox" onClick={(e) => selectCheckbox(e, "comorbidities")} name="comorbidities_none" value="None" id="comorbidities_none" defaultChecked={patientValues && patientValues.comorbidities && patientValues.comorbidities["comorbidities_none"] ? true : false} />
                                                <label for="comorbidities_none"></label>
                                            </div>
                                            None
                                        </div>
                                        <div className="check-box sqeare ">
                                            <div className="round">
                                                <input type="checkbox" onClick={(e) => selectCheckbox(e, "comorbidities")} name="comorbidities_recent" value="RECENT MI, stroke, PE" id="comorbidities_recent" defaultChecked={patientValues && patientValues.comorbidities && patientValues.comorbidities["comorbidities_recent"] ? true : false} />
                                                <label for="comorbidities_recent"></label>
                                            </div>
                                            RECENT MI, stroke, PE
                                        </div>
                                        <div className="check-box sqeare ">
                                            <div className="round">
                                                <input type="checkbox" onClick={(e) => selectCheckbox(e, "comorbidities")} name="comorbidities_diabetes" value="Diabetes mellitus" id="comorbidities_diabetes" defaultChecked={patientValues && patientValues.comorbidities && patientValues.comorbidities["comorbidities_diabetes"] ? true : false} />
                                                <label for="comorbidities_diabetes"></label>
                                            </div>
                                            Diabetes mellitus
                                        </div>
                                        <div className="check-box sqeare ">
                                            <div className="round">
                                                <input type="checkbox" onClick={(e) => selectCheckbox(e, "comorbidities")} name="comorbidities_htn" value="HTN disorder" id="comorbidities_htn" defaultChecked={patientValues && patientValues.comorbidities && patientValues.comorbidities["comorbidities_htn"] ? true : false} />
                                                <label for="comorbidities_htn"></label>
                                            </div>
                                            HTN disorder
                                        </div>
                                        <div className="check-box sqeare ">
                                            <div className="round">
                                                <input type="checkbox" onClick={(e) => selectCheckbox(e, "comorbidities")} name="comorbidities_af" value="AF" id="comorbidities_af" defaultChecked={patientValues && patientValues.comorbidities && patientValues.comorbidities["comorbidities_af"] ? true : false} />
                                                <label for="comorbidities_af"></label>
                                            </div>
                                            AF
                                        </div>
                                        <div className="check-box sqeare ">
                                            <div className="round">
                                                <input type="checkbox" onClick={(e) => selectCheckbox(e, "comorbidities")} name="comorbidities_heart" value="Ischemic heart disease" id="comorbidities_heart" defaultChecked={patientValues && patientValues.comorbidities && patientValues.comorbidities["comorbidities_heart"] ? true : false} />
                                                <label for="comorbidities_heart"></label>
                                            </div>
                                            Ischemic heart disease
                                        </div>
                                        <div className="check-box sqeare ">
                                            <div className="round">
                                                <input type="checkbox" onClick={(e) => selectCheckbox(e, "comorbidities")} name="comorbidities_cva" value="CVA" id="comorbidities_cva" defaultChecked={patientValues && patientValues.comorbidities && patientValues.comorbidities["comorbidities_cva"] ? true : false} />
                                                <label for="comorbidities_cva"></label>
                                            </div>
                                            CVA
                                        </div>
                                        <div className="check-box sqeare ">
                                            <div className="round">
                                                <input type="checkbox" onClick={(e) => selectCheckbox(e, "comorbidities")} name="comorbidities_peripheral" value="Peripheral vascular disease" id="comorbidities_peripheral" defaultChecked={patientValues && patientValues.comorbidities && patientValues.comorbidities["comorbidities_peripheral"] ? true : false} />
                                                <label for="comorbidities_peripheral"></label>
                                            </div>
                                            Peripheral vascular disease
                                        </div>
                                        <div className="check-box sqeare ">
                                            <div className="round">
                                                <input type="checkbox" onClick={(e) => selectCheckbox(e, "comorbidities")} name="comorbidities_dvt" value="DVT/PE" id="comorbidities_dvt" defaultChecked={patientValues && patientValues.comorbidities && patientValues.comorbidities["comorbidities_dvt"] ? true : false} />
                                                <label for="comorbidities_dvt"></label>
                                            </div>
                                            DVT/PE
                                        </div>
                                        <div className="check-box sqeare ">
                                            <div className="round">
                                                <input type="checkbox" onClick={(e) => selectCheckbox(e, "comorbidities")} name="comorbidities_copd" value="COPD" id="comorbidities_copd" defaultChecked={patientValues && patientValues.comorbidities && patientValues.comorbidities["comorbidities_copd"] ? true : false} />
                                                <label for="comorbidities_copd"></label>
                                            </div>
                                            COPD
                                        </div>
                                        <div className="check-box sqeare ">
                                            <div className="round">
                                                <input type="checkbox" onClick={(e) => selectCheckbox(e, "comorbidities")} name="comorbidities_immu" value="Immunocompromised" id="comorbidities_immu" defaultChecked={patientValues && patientValues.comorbidities && patientValues.comorbidities["comorbidities_immu"] ? true : false} />
                                                <label for="comorbidities_immu"></label>
                                            </div>
                                            Immunocompromised
                                        </div>
                                        <div className="check-box sqeare ">
                                            <div className="round">
                                                <input type="checkbox" onClick={(e) => selectCheckbox(e, "comorbidities")} name="comorbidities_impaired" value="Impaired cognition" id="comorbidities_impaired" defaultChecked={patientValues && patientValues.comorbidities && patientValues.comorbidities["comorbidities_impaired"] ? true : false} />
                                                <label for="comorbidities_impaired"></label>
                                            </div>
                                            Impaired cognition
                                        </div>
                                        <div className="check-box sqeare ">
                                            <div className="round">
                                                <input type="checkbox" onClick={(e) => selectCheckbox(e, "comorbidities")} name="comorbidities_blood" value="Blood coagulation disorder" id="comorbidities_blood" defaultChecked={patientValues && patientValues.comorbidities && patientValues.comorbidities["comorbidities_blood"] ? true : false} />
                                                <label for="comorbidities_blood"></label>
                                            </div>
                                            Blood coagulation disorder
                                        </div>
                                        <div className="check-box sqeare ">
                                            <div className="round">
                                                <input type="checkbox" onClick={(e) => selectCheckbox(e, "comorbidities")} name="comorbidities_chronic" value="Chronic renal failure" id="comorbidities_chronic" defaultChecked={patientValues && patientValues.comorbidities && patientValues.comorbidities["comorbidities_chronic"] ? true : false} />
                                                <label for="comorbidities_chronic"></label>
                                            </div>
                                            Chronic renal failure
                                        </div>
                                        <div className="check-box sqeare ">
                                            <div className="round">
                                                <input type="checkbox" onClick={(e) => selectCheckbox(e, "comorbidities")} name="comorbidities_depressive" value="Depressive disorder" id="comorbidities_depressive" defaultChecked={patientValues && patientValues.comorbidities && patientValues.comorbidities["comorbidities_depressive"] ? true : false} />
                                                <label for="comorbidities_depressive"></label>
                                            </div>
                                            Depressive disorder
                                        </div>
                                        <div className="check-box sqeare ">
                                            <div className="round">
                                                <input type="checkbox" onClick={(e) => selectCheckbox(e, "comorbidities")} name="comorbidities_anxiety" value="Anxiety disorder" id="comorbidities_anxiety" defaultChecked={patientValues && patientValues.comorbidities && patientValues.comorbidities["comorbidities_anxiety"] ? true : false} />
                                                <label for="comorbidities_anxiety"></label>
                                            </div>
                                            Anxiety disorder
                                        </div>

                                        {arr && arr.comorbidities_input && Object.keys(arr.comorbidities_input).map((item, i) => {
                                                    return (
                                                        <div className="form_group" key={i}>
                                                        <input
                                                            onChange={(e) => updateInputValue(e)}
                                                            type="text"
                                                            className="form-control"
                                                            name={item}
                                                        /></div>
                                                    );
                                                })}
                                             
                                        <div className="form_group mt-30">
                                            <a onClick={(e) => { addInput("comorbidities_input") }} className="add_btn"> <span className="icon"> + </span> ADD ANOTHER MORBIDITY</a>
                                        </div>

                                        
                                        <button type="button" onClick={(e) => updateClinicValues(e, "comorbidities_flag", true)} className="btn mt-20" data-dismiss="modal">Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="modal fade aide_popup awp_popup" id="awp2" tabIndex="-1" role="dialog"
                            aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">ALLERGIES & ALERTS</h5>
                                        <span className="material-icons" data-dismiss="modal" aria-label="Close">
                                            close
                                        </span>
                                    </div>
                                    <div className="modal-body">
                                        <div className="check-box sqeare ">
                                            <div className="round">
                                                <input type="checkbox"  onClick={(e) => selectCheckbox(e, "allergies")} name="allergies_metal" value="Allergies (Metal –Latex allergy):" id="allergies_metal" defaultChecked={patientValues && patientValues.allergies && patientValues.allergies["allergies_metal"] ? true : false} />
                                                <label for="allergies_metal"></label>
                                            </div>
                                            Allergies (Metal –Latex allergy):
                                        </div>
                                        <div className="check-box sqeare ">
                                            <div className="round">
                                                <input type="checkbox" onClick={(e) => selectCheckbox(e, "allergies")} name="allergies_bariatric" value="Bariatric (Morbidlyobese) Patient: Weight:" id="allergies_bariatric" defaultChecked={patientValues && patientValues.allergies && patientValues.allergies["allergies_bariatric"] ? true : false} />
                                                <label for="allergies_bariatric"></label>
                                            </div>
                                            Bariatric (Morbidlyobese) Patient: Weight:
                                        </div>
                                        <div className="check-box sqeare ">
                                            <div className="round">
                                                <input type="checkbox" onClick={(e) => selectCheckbox(e, "allergies")} name="allergies_hiv" value="HIV/Hep B/C" id="allergies_hiv" defaultChecked={patientValues && patientValues.allergies && patientValues.allergies["allergies_hiv"] ? true : false} />
                                                <label for="allergies_hiv"></label>
                                            </div>
                                            HIV/Hep B/C
                                        </div>
                                        <div className="check-box sqeare ">
                                            <div className="round">
                                                <input type="checkbox" onClick={(e) => selectCheckbox(e, "allergies")} name="allergies_mrsa" value="MRSA/ COVID" id="allergies_mrsa" defaultChecked={patientValues && patientValues.allergies && patientValues.allergies["allergies_mrsa"] ? true : false} />
                                                <label for="allergies_mrsa"></label>
                                            </div>
                                            MRSA/ COVID
                                        </div>
                                        <div className="check-box sqeare ">
                                            <div className="round">
                                                <input type="checkbox" onClick={(e) => selectCheckbox(e, "allergies")} name="allergies_pace" value="Patient has a Pacemaker / ICD defibrillator - seen by technician" id="allergies_pace" defaultChecked={patientValues && patientValues.allergies && patientValues.allergies["allergies_pace"] ? true : false} />
                                                <label for="allergies_pace"></label>
                                            </div>
                                            Patient has a Pacemaker / ICD defibrillator - seen by technician
                                        </div>
                                        <div className="check-box sqeare ">
                                            <div className="round">
                                                <input type="checkbox" onClick={(e) => selectCheckbox(e, "allergies")} name="allergies_witness" value="Jehovah’s witness: Patient refuses blood/blood products" id="allergies_witness" defaultChecked={patientValues && patientValues.allergies && patientValues.allergies["allergies_witness"] ? true : false} />
                                                <label for="allergies_witness"></label>
                                            </div>
                                            Jehovah’s witness: Patient refuses blood/blood products
                                        </div>
                                        <button type="button" onClick={(e) => updateClinicValues(e, "allergies_flag", true)}  data-dismiss="modal" className="btn mt-20">Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="modal fade aide_popup awp_popup" id="awp3" tabIndex="-1" role="dialog"
                            aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">RELEVANT DRUG HISTORY</h5>
                                        <span className="material-icons" data-dismiss="modal" aria-label="Close">
                                            close
                                        </span>
                                    </div>
                                    <div className="modal-body">
                                        <div className="check_item ">
                                            <div className="select_btns mt-0">
                                                <div className="select_btn"><label><input onClick={(e) => selectCheckbox(e, "relevant")} name="relevant_aspirin"  type="radio"
                                                    value="ASPIRIN" /><span>ASPIRIN</span></label></div>
                                                <div className="select_btn"><label><input onClick={(e) => selectCheckbox(e, "relevant")} name="relevant_warfarin" type="radio"
                                                    value="WARFARIN" /><span> WARFARIN</span></label> </div>
                                                <div className="select_btn"><label><input onClick={(e) => selectCheckbox(e, "relevant")} name="relevant_clo" type="radio"
                                                    value="CLOPIDOGRELPRODUCT" /><span> CLOPIDOGRELPRODUCT</span></label> </div>
                                                <div className="select_btn"><label><input onClick={(e) => selectCheckbox(e, "relevant")} name="relevant_riva" type="radio"
                                                    value="RIVAROXABAN" /><span> RIVAROXABAN</span></label> </div>
                                                <div className="select_btn"><label><input onClick={(e) => selectCheckbox(e, "relevant")} name="relevant_apix" type="radio"
                                                    value="APIXABAN PRODUCT" /><span> APIXABAN PRODUCT</span></label> </div>
                                                <div className="select_btn"><label><input onClick={(e) => selectCheckbox(e, "relevant")} name="relevant_insulin" type="radio"
                                                    value="INSULIN" /><span> INSULIN</span></label> </div>
                                                <div className="select_btn"><label><input onClick={(e) => selectCheckbox(e, "relevant")} name="relevant_steroids" type="radio"
                                                    value="STEROIDS" /><span> STEROIDS</span></label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form_group mt-30">
                                        {arr && arr.relevant_input && Object.keys(arr.relevant_input).map((item, i) => {
                                                    return (
                                                        <div className="form_group" key={i}>
                                                        <input
                                                            onChange={(e) => updateInputValue(e)}
                                                            type="text"
                                                            className="form-control"
                                                            name={item}
                                                        /></div>
                                                    );
                                                })}
                                            
                                                <div className="form_group">
                                                    <a onClick={(e) => { addInput("relevant_input") }} className="add_btn"> <span className="icon"> + </span> ADD ANOTHER DRUG</a>
                                                </div>

                                           
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form_group mt-20">
                                                    <label className="label">Last Dose taken at</label>
                                                    <input type="date" onChange={(e) => updateInputValue(e)} name="relevant_last_dose" className="form-control" />
                                                </div>
                                            </div>
                                        </div>
                                        <button type="button" onClick={(e) => updateClinicValues(e, "relevant_flag", true)}  data-dismiss="modal" className="btn mt-20">Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="modal fade aide_popup awp_popup" id="awp4" tabIndex="-1" role="dialog"
                            aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">SOCIAL HISTORY</h5>
                                        <span className="material-icons" data-dismiss="modal" aria-label="Close">
                                            close
                                        </span>
                                    </div>
                                    <div className="modal-body">
                                        <div className="check-box sqeare ">
                                            <div className="round">
                                                <input type="checkbox" onClick={(e) => selectCheckbox(e, "social_history")} name="social_history_handedness" value="HANDEDNESS (IF RELEVANT)" id="social_history_handedness" defaultChecked={patientValues && patientValues.allergies && patientValues.allergies["social_history_handedness"] ? true : false} />
                                                <label for="social_history_handedness"></label>
                                            </div>
                                            HANDEDNESS (IF RELEVANT)
                                        </div>
                                        <div className="check-box sqeare ">
                                            <div className="round">
                                                <input type="checkbox" onClick={(e) => selectCheckbox(e, "social_history")} name="social_history_language" value="LANGUAGE" id="social_history_language" defaultChecked={patientValues && patientValues.allergies && patientValues.allergies["social_history_language"] ? true : false} />
                                                <label for="social_history_language"></label>
                                            </div>
                                            LANGUAGE
                                        </div>
                                        <div className="check-box sqeare ">
                                            <div className="round">
                                                <input type="checkbox" onClick={(e) => selectCheckbox(e, "social_history")} name="social_history_occupation" value="OCCUPATION" id="social_history_occupation" defaultChecked={patientValues && patientValues.allergies && patientValues.allergies["social_history_occupation"] ? true : false} />
                                                <label for="social_history_occupation"></label>
                                            </div>
                                            OCCUPATION
                                        </div>
                                        <div className="check-box sqeare ">
                                            <div className="round">
                                                <input type="checkbox" onClick={(e) => selectCheckbox(e, "social_history")} name="social_history_work" value="WORK STATUS" id="social_history_work" defaultChecked={patientValues && patientValues.allergies && patientValues.allergies["social_history_work"] ? true : false} />
                                                <label for="social_history_work"></label>
                                            </div>
                                            WORK STATUS
                                        </div>
                                        <div className="check-box sqeare ">
                                            <div className="round">
                                                <input type="checkbox" onClick={(e) => selectCheckbox(e, "social_history")} name="social_history_smoke" value="SMOKING" id="social_history_smoke" defaultChecked={patientValues && patientValues.allergies && patientValues.allergies["social_history_smoke"] ? true : false} />
                                                <label for="social_history_smoke"></label>
                                            </div>
                                            SMOKING
                                        </div>
                                        <div className="check-box sqeare ">
                                            <div className="round">
                                                <input type="checkbox" onClick={(e) => selectCheckbox(e, "social_history")} name="social_history_sub" value="SUBSTANCE ABUSE (I.E., ETOH, DRUGS)" id="social_history_sub" defaultChecked={patientValues && patientValues.allergies && patientValues.allergies["social_history_sub"] ? true : false} />
                                                <label for="social_history_sub"></label>
                                            </div>
                                            SUBSTANCE ABUSE (I.E., ETOH, DRUGS)
                                        </div>
                                        <div className="check_item mt-0"><label className="check">FROM
                                        </label>
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="social_history_residence" type="radio"
                                                    value="Residential home" /><span>Residential home
                                                    </span></label></div>
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="social_history_residence" type="radio"
                                                    value="Nursing home" /><span>Nursing home
                                                    </span></label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="check_item mt-0"><label className="check">Other (Please specify)
                                        </label>
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="social_history_other_residence" type="radio"
                                                    value="Own House" /><span>Own House
                                                    </span></label></div>
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="social_history_other_residence" type="radio"
                                                    value="Own Bungalow" /><span>Own Bungalow
                                                    </span></label>
                                                </div>
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="social_history_other_residence" type="radio"
                                                    value="Own Flat Lives alone" /><span>Own Flat Lives alone
                                                    </span></label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-10">

                                            <div className="col-md-6">
                                                <div className="form_group">
                                                    <label className="label">Lives with</label>
                                                    <input type="text" className="form-control" onChange={(e) => updateInputValue(e)} name="social_history_lives_with" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form_group">
                                                    <label className="label">If living at home, list care services in place</label>
                                                    <input type="text" className="form-control" onChange={(e) => updateInputValue(e)} name="social_history_living_home" />
                                                </div>

                                            </div>
                                            <div className="col-md-6">
                                                <div className="form_group">
                                                    <label className="label">Formal</label>
                                                    <input type="text" className="form-control" onChange={(e) => updateInputValue(e)} name="social_history_formal"  />
                                                </div>

                                            </div>
                                            <div className="col-md-6">
                                                <div className="form_group">
                                                    <label className="label">Informal</label>
                                                    <input type="text" className="form-control" onChange={(e) => updateInputValue(e)} name="social_history_informal" />
                                                </div>

                                            </div>
                                        </div>
                                        <button type="button"  onClick={(e) => updateClinicValues(e, "social_history_flag", true)}  data-dismiss="modal" className="btn">Submit</button>
                                    </div>

                                </div>


                            </div>
                        </div>
                        <div className="modal fade aide_popup awp_popup" id="awp5" tabIndex="-1" role="dialog"
                            aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">PREGNANCY </h5>
                                        <span className="material-icons" data-dismiss="modal" aria-label="Close">
                                            close
                                        </span>
                                    </div>
                                    <div className="modal-body">
                                        <div className="row">

                                            <div className="col-md-6">
                                                <div className="form_group">
                                                    <label className="label">Major Emergency Admission</label>
                                                    <input type="text" onChange={(e) => updateInputValue(e)} name="pregnancy_major" className="form-control" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form_group">
                                                    <label className="label">Intermediate Emergency Admission   </label>
                                                    <input type="text" onChange={(e) => updateInputValue(e)} name="pregnancy_intermediate"  className="form-control" />
                                                </div>

                                            </div>
                                            <div className="col-md-6">
                                                <div className="form_group">
                                                    <label className="label">Minor / Day case Emergency Admission</label>
                                                    <input type="text" onChange={(e) => updateInputValue(e)} name="pregnancy_minor"  className="form-control" />
                                                </div>

                                            </div>

                                        </div>
                                        <div className="check-box sqeare mt-20">
                                            <div className="round">
                                                <input type="checkbox" onClick={(e) => selectCheckbox(e, "pregnancy")} name="pregnancy_ad" value="EMERGENCY ADMISSION" id="pregnancy_ad" defaultChecked={patientValues && patientValues.pregnancy && patientValues.pregnancy["pregnancy_ad"] ? true : false} />
                                                <label for="pregnancy_ad"></label>
                                            </div>
                                            EMERGENCY ADMISSION
                                        </div>
                                        <div className="check-box sqeare ">
                                            <div className="round">
                                                <input type="checkbox" onClick={(e) => selectCheckbox(e, "pregnancy")} name="pregnancy_tra" value="TRAUMA" id="pregnancy_tra" defaultChecked={patientValues && patientValues.pregnancy && patientValues.pregnancy["pregnancy_tra"] ? true : false} />
                                                <label for="pregnancy_tra"></label>
                                            </div>
                                            TRAUMA
                                        </div>
                                        <div className="check-box sqeare ">
                                            <div className="round">
                                                <input type="checkbox" onClick={(e) => selectCheckbox(e, "pregnancy")} name="pregnancy_poly" value="POLY TRAUMA" id="pregnancy_poly" defaultChecked={patientValues && patientValues.pregnancy && patientValues.pregnancy["pregnancy_poly"] ? true : false} />
                                                <label for="pregnancy_poly"></label>
                                            </div>
                                            POLY TRAUMA
                                        </div>
                                        <div className="check-box sqeare ">
                                            <div className="round">
                                                <input type="checkbox" onClick={(e) => selectCheckbox(e, "pregnancy")} name="pregnancy_spinal" value="SPINAL INJURY" id="pregnancy_spinal" defaultChecked={patientValues && patientValues.pregnancy && patientValues.pregnancy["pregnancy_spinal"] ? true : false} />
                                                <label for="pregnancy_spinal"></label>
                                            </div>
                                            SPINAL INJURY
                                        </div>
                                        <div className="check-box sqeare ">
                                            <div className="round">
                                                <input type="checkbox" onClick={(e) => selectCheckbox(e, "pregnancy")} name="pregnancy_paediatric" value="PAEDIATRIC PATIENT" id="pregnancy_paediatric" defaultChecked={patientValues && patientValues.pregnancy && patientValues.pregnancy["pregnancy_paediatric"] ? true : false} />
                                                <label for="pregnancy_paediatric"></label>
                                            </div>
                                            PAEDIATRIC PATIENT
                                        </div>
                                        <div className="check-box sqeare ">
                                            <div className="round">
                                                <input type="checkbox" onClick={(e) => selectCheckbox(e, "pregnancy")} name="pregnancy_pre" value="PREGNANT PATIENT" id="pregnancy_pre" defaultChecked={patientValues && patientValues.pregnancy && patientValues.pregnancy["pregnancy_pre"] ? true : false} />
                                                <label for="pregnancy_pre"></label>
                                            </div>
                                            PREGNANT PATIENT
                                        </div>
                                        <div className="check-box sqeare ">
                                            <div className="round">
                                                <input type="checkbox" onClick={(e) => selectCheckbox(e, "pregnancy")} name="pregnancy_day" value="TRAUMA DAY CASE" id="pregnancy_day" defaultChecked={patientValues && patientValues.pregnancy && patientValues.pregnancy["pregnancy_day"] ? true : false} />
                                                <label for="pregnancy_day"></label>
                                            </div>
                                            TRAUMA DAY CASE
                                        </div>


                                        <button type="button"  onClick={(e) => updateClinicValues(e, "pregnancy_flag", true)}  data-dismiss="modal" className="btn mt-20">Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="modal fade aide_popup awp_popup" id="awp6" tabIndex="-1" role="dialog"
                            aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">USUAL BASELINE MOBILITY</h5>
                                        <span className="material-icons" data-dismiss="modal" aria-label="Close">
                                            close
                                        </span>
                                    </div>
                                    <div className="modal-body">
                                        <div className="check_item mt-0">
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="mobility_independent"  type="radio"
                                                    value="Indoors Independent" /><span>Indoors Independent
                                                    </span></label></div>
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="mobility_independent" type="radio"
                                                    value="Outdoors Independent" /><span>Outdoors Independent
                                                    </span></label>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="check-box sqeare ">
                                            <div className="round">
                                                <input type="checkbox"  onClick={(e) => selectCheckbox(e, "mobility")} name="mobility_aids" value="With Aids" id="mobility_aids" defaultChecked={patientValues && patientValues.mobility && patientValues.mobility["mobility_aids"] ? true : false} />
                                                <label for="mobility_aids"></label>
                                            </div>
                                            With Aids
                                        </div>
                                        <div className="check-box sqeare ">
                                            <div className="round">
                                                <input type="checkbox" onClick={(e) => selectCheckbox(e, "mobility")} name="mobility_elbow" value="One Aid (stick or elbow crutch)" id="mobility_elbow" defaultChecked={patientValues && patientValues.mobility && patientValues.mobility["mobility_elbow"] ? true : false} />
                                                <label for="mobility_elbow"></label>
                                            </div>
                                            One Aid (stick or elbow crutch)
                                        </div>
                                        <div className="check-box sqeare ">
                                            <div className="round">
                                                <input type="checkbox" onClick={(e) => selectCheckbox(e, "mobility")} name="mobility_two" value="Two Aids (2 sticks or 2 elbow crutches)" id="mobility_two" defaultChecked={patientValues && patientValues.mobility && patientValues.mobility["mobility_two"] ? true : false} />
                                                <label for="mobility_two"></label>
                                            </div>
                                            Two Aids (2 sticks or 2 elbow crutches)
                                        </div>
                                        <div className="check-box sqeare ">
                                            <div className="round">
                                                <input type="checkbox" onClick={(e) => selectCheckbox(e, "mobility")} name="mobility_frame" value="Walking Frame" id="mobility_frame" defaultChecked={patientValues && patientValues.mobility && patientValues.mobility["mobility_frame"] ? true : false} />
                                                <label for="mobility_frame"></label>
                                            </div>
                                            Walking Frame
                                        </div>
                                        <div className="check-box sqeare ">
                                            <div className="round">
                                                <input type="checkbox" onClick={(e) => selectCheckbox(e, "mobility")} name="mobility_scooter" value="Scooter" id="mobility_scooter" defaultChecked={patientValues && patientValues.mobility && patientValues.mobility["mobility_scooter"] ? true : false} />
                                                <label for="mobility_scooter"></label>
                                            </div>
                                            Scooter
                                        </div>
                                        <div className="check-box sqeare ">
                                            <div className="round">
                                                <input type="checkbox" onClick={(e) => selectCheckbox(e, "mobility")} name="mobility_wheel" value="Wheelchair" id="mobility_wheel" defaultChecked={patientValues && patientValues.mobility && patientValues.mobility["mobility_wheel"] ? true : false} />
                                                <label for="mobility_wheel"></label>
                                            </div>
                                            Wheelchair
                                        </div>
                                        <div className="check-box sqeare ">
                                            <div className="round">
                                                <input type="checkbox" onClick={(e) => selectCheckbox(e, "mobility")} name="mobility_Bedbound" value="Bedbound" id="mobility_Bedbound" defaultChecked={patientValues && patientValues.mobility && patientValues.mobility["mobility_Bedbound"] ? true : false} />
                                                <label for="mobility_Bedbound"></label>
                                            </div>
                                            Bedbound
                                        </div>
                                        <div className="check-box sqeare mb-30">
                                            <div className="round">
                                                <input type="checkbox" onClick={(e) => selectCheckbox(e, "mobility")} name="mobility_Unknown" value="Unknown" id="mobility_Unknown" defaultChecked={patientValues && patientValues.mobility && patientValues.mobility["mobility_Unknown"] ? true : false}  />
                                                <label for="mobility_Unknown"></label>
                                            </div>
                                            Unknown
                                        </div>
                                        
                                        <button type="button" onClick={(e) => updateClinicValues(e, "mobility_flag", true)}  data-dismiss="modal" className="btn mt-20">Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="modal fade aide_popup awp_popup" id="awp7" tabIndex="-1" role="dialog"
                            aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">SPECIALIST TEMPLATES</h5>
                                        <span className="material-icons" data-dismiss="modal" aria-label="Close">
                                            close
                                        </span>
                                    </div>
                                    <div className="modal-body">
                                        <div className="check-box sqeare ">
                                            <div className="round">
                                                <input type="checkbox" onClick={(e) => selectCheckbox(e, "templates")} name="templates_frailty" value="Frailty fracture / Orthogeriatric template" id="templates_frailty" defaultChecked={patientValues && patientValues.templates && patientValues.templates["templates_frailty"] ? true : false} />
                                                <label for="templates_frailty"></label>
                                            </div>
                                            Frailty fracture / Orthogeriatric template
                                        </div>
                                        <div className="check-box sqeare ">
                                            <div className="round">
                                                <input type="checkbox" onClick={(e) => selectCheckbox(e, "templates")} name="templates_fracture" value="Open fracture" id="templates_fracture" defaultChecked={patientValues && patientValues.templates && patientValues.templates["templates_fracture"] ? true : false} />
                                                <label for="templates_fracture"></label>
                                            </div>
                                            Open fracture
                                        </div>
                                        <div className="check-box sqeare ">
                                            <div className="round">
                                                <input type="checkbox" onClick={(e) => selectCheckbox(e, "templates")} name="templates_Pathological" value="Pathological fracture" id="templates_Pathological" defaultChecked={patientValues && patientValues.templates && patientValues.templates["templates_Pathological"] ? true : false} />
                                                <label for="templates_Pathological"></label>
                                            </div>
                                            Pathological fracture
                                        </div>
                                        <div className="check-box sqeare ">
                                            <div className="round">
                                                <input type="checkbox" onClick={(e) => selectCheckbox(e, "templates")} name="templates_Periprosthetic" value="Periprosthetic fracture" id="templates_Periprosthetic" defaultChecked={patientValues && patientValues.templates && patientValues.templates["templates_Periprosthetic"] ? true : false} />
                                                <label for="templates_Periprosthetic"></label>
                                            </div>
                                            Periprosthetic fracture
                                        </div>
                                        <div className="check-box sqeare ">
                                            <div className="round">
                                                <input type="checkbox" onClick={(e) => selectCheckbox(e, "templates")} name="templates_joint" value="Infected joint replacement" id="templates_joint" defaultChecked={patientValues && patientValues.templates && patientValues.templates["templates_joint"] ? true : false} />
                                                <label for="templates_joint"></label>
                                            </div>
                                            Infected joint replacement
                                        </div>
                                        <div className="check-box sqeare ">
                                            <div className="round">
                                                <input type="checkbox" onClick={(e) => selectCheckbox(e, "templates")} name="templates_poly" value="Poly trauma" id="templates_poly" defaultChecked={patientValues && patientValues.templates && patientValues.templates["templates_poly"] ? true : false} />
                                                <label for="templates_poly"></label>
                                            </div>
                                            Poly trauma
                                        </div>
                                        <div className="check-box sqeare ">
                                            <div className="round">
                                                <input type="checkbox" onClick={(e) => selectCheckbox(e, "templates")} name="templates_spinal" value="Spinal injury" id="templates_spinal" defaultChecked={patientValues && patientValues.templates && patientValues.templates["templates_spinal"] ? true : false} />
                                                <label for="templates_spinal"></label>
                                            </div>
                                            Spinal injury
                                        </div>
                                        <div className="check-box sqeare ">
                                            <div className="round">
                                                <input type="checkbox" onClick={(e) => selectCheckbox(e, "templates")} name="templates_Cauda" value="Cauda 6quine" id="templates_Cauda" defaultChecked={patientValues && patientValues.templates && patientValues.templates["templates_Cauda"] ? true : false} />
                                                <label for="templates_Cauda"></label>
                                            </div>
                                            Cauda 6quine
                                        </div>
                                        <div className="check-box sqeare ">
                                            <div className="round">
                                                <input type="checkbox" onClick={(e) => selectCheckbox(e, "templates")} name="templates_Paediatric" value="Paediatric patient supracondylar fracture, septic arthritis- SUFE- Perthes" id="templates_Paediatric" defaultChecked={patientValues && patientValues.templates && patientValues.templates["templates_Paediatric"] ? true : false} />
                                                <label for="templates_Paediatric"></label>
                                            </div>
                                            Paediatric patient supracondylar fracture, septic arthritis- SUFE- Perthes
                                        </div>
                                        <div className="check-box sqeare ">
                                            <div className="round">
                                                <input type="checkbox" onClick={(e) => selectCheckbox(e, "templates")} name="templates_Pregnant" value="Pregnant patient template" id="templates_Pregnant" defaultChecked={patientValues && patientValues.templates && patientValues.templates["templates_Pregnant"] ? true : false} />
                                                <label for="templates_Pregnant"></label>
                                            </div>
                                            Pregnant patient template
                                        </div>
                                        <button type="button" onClick={(e) => updateClinicValues(e, "templates_flag", true)}  data-dismiss="modal" className="btn mt-20">Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="modal fade aide_popup awp_popup" id="awp8" tabIndex="-1" role="dialog"
                            aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">ON CALL PROCEDURES</h5>
                                        <span className="material-icons" data-dismiss="modal" aria-label="Close">
                                            close
                                        </span>
                                    </div>
                                    <div className="modal-body">
                                        <div className="check-box sqeare ">
                                            <div className="round">
                                                <input type="checkbox" onClick={(e) => selectCheckbox(e, "oncall")} name="oncall_Manpulation" value="Manpulation" id="oncall_Manpulation" defaultChecked={patientValues && patientValues.oncall && patientValues.oncall["oncall_Manpulation"] ? true : false} />
                                                <label for="oncall_Manpulation"></label>
                                            </div>
                                            Manpulation
                                        </div>
                                        <div className="check-box sqeare ">
                                            <div className="round">
                                                <input type="checkbox" onClick={(e) => selectCheckbox(e, "oncall")} name="oncall_Castings" value="Castings" id="oncall_Castings" defaultChecked={patientValues && patientValues.oncall && patientValues.oncall["oncall_Castings"] ? true : false} />
                                                <label for="oncall_Castings"></label>
                                            </div>
                                            Castings
                                        </div>
                                        <div className="check-box sqeare ">
                                            <div className="round">
                                                <input type="checkbox" onClick={(e) => selectCheckbox(e, "oncall")} name="oncall_Sutures" value="Sutures etc" id="oncall_Sutures" defaultChecked={patientValues && patientValues.oncall && patientValues.oncall["oncall_Sutures"] ? true : false} />
                                                <label for="oncall_Sutures"></label>
                                            </div>
                                            Sutures etc
                                        </div>
                                        <button type="button" onClick={(e) => updateClinicValues(e, "oncall_flag", true)}  data-dismiss="modal" className="btn mt-20">Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="modal fade aide_popup awp_popup" id="awp9" tabIndex="-1" role="dialog"
                            aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">CONSENT </h5>
                                        <span className="material-icons" data-dismiss="modal" aria-label="Close">
                                            close
                                        </span>
                                    </div>
                                    <div className="modal-body">
                                        <div className="check-box sqeare ">
                                            <div className="round">
                                                <input type="checkbox" onClick={(e) => selectCheckbox(e, "consent")} name="consent_trauma" value="ADD TO TRAUMA LIST" id="consent_trauma" defaultChecked={patientValues && patientValues.consent && patientValues.consent["consent_trauma"] ? true : false} />
                                                <label for="consent_trauma"></label>
                                            </div>
                                            ADD TO TRAUMA LIST
                                        </div>

                                        <div className="check-box sqeare ">
                                            <div className="round">
                                                <input type="checkbox" onClick={(e) => selectCheckbox(e, "consent")} name="consent_book" value="BOOK EMERGENCY THEATRE" id="consent_book" defaultChecked={patientValues && patientValues.consent && patientValues.consent["consent_book"] ? true : false} />
                                                <label for="consent_book"></label>
                                            </div>
                                            BOOK EMERGENCY THEATRE
                                        </div>
                                        <div className="check-box sqeare ">
                                            <div className="round">
                                                <input type="checkbox" onClick={(e) => selectCheckbox(e, "consent")} name="consent_referral" value="REFERRALS within hospital/to regional centres, E Referral to fracture clinic" id="consent_referral" defaultChecked={patientValues && patientValues.consent && patientValues.consent["consent_referral"] ? true : false} />
                                                <label for="consent_referral"></label>
                                            </div>
                                            REFERRALS within hospital/to regional centres, E Referral to fracture clinic

                                        </div>
                                        <button type="button" onClick={(e) => updateClinicValues(e, "consent_flag", true)}  data-dismiss="modal" className="btn mt-20">Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <Modal
                            isOpen={success_msg}
                            toggle={() => {
                                setsuccess_msg(false)
                            }}
                            centered={true}
                        >
                            <div className="modal-header">
                                <h5 className="modal-title mt-0"></h5>
                                <button
                                    type="button"
                                    onClick={() => {
                                        setsuccess_msg(false)
                                    }}
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <h1>Updated Successfully</h1>
                            </div>
                        </Modal>

                    </div>
                </div>
            </div>




        </React.Fragment>
    )
}

AddNewPatient.propTypes = {
    getWards: PropTypes.func,
    getTheatres: PropTypes.func,
    wards: PropTypes.array,
    user: PropTypes.any,
    theatres: PropTypes.array,
    clinic: PropTypes.array,
    patients: PropTypes.array,
    doctors: PropTypes.array,
    profile: PropTypes.object,
    news: PropTypes.array,
}

const mapStateToProps = ({ Layout, Login }) => ({
    wards: Layout.wards,
    user: Login.user,
    theatres: Layout.theatres,
    clinic: Layout.clinic,
    patients: Layout.patients,
    doctors: Layout.doctors,
    profile: Layout.profile,
    news: Layout.news,
    dailyrounds: Layout.dailyrounds
})

const mapDispatchToProps = dispatch => ({
    getWards: () => dispatch(getWardsList()),
    getTheatres: () => dispatch(getTheatresList()),
    getClinic: () => dispatch(getClinicList()),
    getPatient: () => dispatch(getPatientList()),
    getUserprofile: (id) => dispatch(getUprofile(id)),
    getDoctor: () => dispatch(getDoctorList()),
    patientupdate: (id, data) => dispatch(updatePatient(id, data)),
    newsList: (data) => dispatch(getNews(data)),
    dailyRoundsList: (data) => dispatch(getDailyRounds(data)),
    dailyUpdate: (data) => dispatch(updateDailyRounds(data)),
    teamupdatelist: (data, tablename) => dispatch(teamListUpdate(data, tablename))
})

export default connect(mapStateToProps, mapDispatchToProps)(AddNewPatient)