import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import MetaTags from 'react-meta-tags';


import PatientBlock from "../Clinic/patientBlock";
import { connect } from "react-redux"
import { getWardsList, getTheatresList, getClinicList, getPatientList, getUprofile, getDoctorList, updatePatient } from "../../store/actions"

const VipScore = props => {


    const [visiblePatients, setPatient] = useState(true)
    const [visiblelanding, setClanding] = useState(false)
    const [successmessage, setSuccess] = useState("")


    const [assesmentValues, setAssesment] = useState({})

    const {
        wards, user, theatres, clinic, patients, profile, doctors
    } = props;

    useEffect(() => {
        const { getWards, getTheatres, getClinic, getDoctor, getPatient, getUserprofile } = props
        // getWards()
        // getTheatres()
        // getClinic()
        getPatient()
        getDoctor()
        let userId = localStorage.getItem("authUser")
        getUserprofile(userId)
    }, [])

    const updateRadioValue = (e) => {
        let val = e.target.value;
        let name = e.target.name;
        let updatedValue = { [name]: val };
        setAssesment(assesmentValues => ({
            ...assesmentValues,
            ...updatedValue
        }));
    }


    const closeClick = (e) => {
        e.preventDefault()
        localStorage.removeItem("a")
        props.history.push("/riskassesment")
    }

    let assesmentPage = JSON.parse(localStorage.getItem("a"))
    let clinicPatient = JSON.parse(localStorage.getItem("p"))

    return (
        <React.Fragment>

            <MetaTags>
                <title>{assesmentPage}</title>
            </MetaTags>
            <div className="dashboard ward">
                <div className="main">

                    <div className="popup_wrp">
                        <div className="popup_full prescribe">
                            <div className="popup_hed">
                                <h2>VIP SCORE </h2>
                                <div className="dsp-flex"> <a className="popup_close" onClick={(e) => closeClick(e)}><span className="material-icons">close</span></a></div>

                            </div>
                            <div className="popup_content">
                                <div className="popup_scroll w-btns main nutrition">


                                <div className="modal add_form guidance_popup fade" id="guidance" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                    <div className="modal-dialog" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title">GUIDANCE</h5>
                                                <span className="material-icons" data-dismiss="modal" aria-label="Close">
                                                    close
                                                </span>
                                            </div>
                                            <div className="modal-body">
                                                <p>Observe cannula 8 hourly or more frequently if clinically indicated
                                                </p>
                                                <p>Secure cannula with an appropriate dressing – change if soiled or contaminated
                                                </p>
                                                <p>Aseptic technique must be followed for insertion</p>
                                                <p>Consider re-siting the cannula every 48-72 hours or as indicated by VIP score
                                                    Plan and document care
                                                </p>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                    <PatientBlock patient={clinicPatient} />
                                    <div className="bar">
                                        <div className="left">
                                            <div className="tag" data-toggle="modal"
                                                    data-target="#guidance"> Guidance </div>
                                            <h3 className="sub_title">VIP SCORE </h3>
                                        </div>
                                        <div className="right">
                                            <a href="#" className="btn"> <span className="material-symbols-outlined"> assignment </span> Not
                                                Yet Done</a>
                                            <div className="tag"> Due </div>
                                        </div>
                                    </div>
                                    <div className="section ctr fb_table poc is">
                                        <div className="sub_section">
                                            <div className="check-box mb-0">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-401" />
                                                        <label for="checkbox-401"></label>
                                                </div>
                                                IV site appears healthy
                                            </div>
                                        </div>
                                        <div className="sub_section">
                                            <div className="check-box">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-402" />
                                                        <label for="checkbox-402"></label>
                                                </div>
                                                One of the following is evident
                                            </div>
                                            <div className="check_item mt-0">
                                                <div className="select_btns popup_select mt-0">
                                                    <div className="select_btn"><label><input name="site" type="radio"
                                                        value="Site 1" /><span>Slight pain
                                                        </span></label></div>
                                                    <div className="select_btn"><label><input name="site" type="radio"
                                                        value="Site 2" /><span>Redness near site
                                                        </span></label></div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="sub_section">
                                            <div className="check-box mb-10 mt-20">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-403" />
                                                        <label for="checkbox-403"></label>
                                                </div>
                                                Two or more of the following are evident
                                            </div>
                                            <p className=" ps1 mb-0"> Pain <br />
                                                Redness<br />
                                                Swelling
                                            </p>
                                        </div>
                                        <div className="sub_section">
                                            <div className="check-box mb-10 mt-20">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-404" />
                                                        <label for="checkbox-404"></label>
                                                </div>
                                                All of the following are evident
                                            </div>
                                            <p className="ps1 mb-0"> Pain <br />
                                                Redness <br />
                                                Hardening of surrounding tissue
                                            </p>
                                        
                                            <div className="check-box mb-10 mt-20">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-404" />
                                                        <label for="checkbox-404"></label>
                                                </div>
                                                As above Palpable venous cord
                                            </div>

                                            <div className="check-box mb-10 mt-20">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-404" />
                                                        <label for="checkbox-404"></label>
                                                </div>
                                                As above + Pyrexia
                                            </div>

                                            
                                            <button type="button" className="update_btn mt-10">UPDATE</button>
                                        </div>
                                        <div className="pua shr">
                                            <div className="poc green">
                                                <div className="check-box">

                                                    <div>
                                                        <p>
                                                            <span>No phlebitis :
                                                            </span> <br/>
                                                                Observe cannula
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="poc yello">
                                                <div className="check-box">

                                                    <div>
                                                        <p>
                                                            <span>Possible first signs: </span> <br/>
                                                                Observe cannula
                                                        </p>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="poc red">
                                                <div className="check-box">

                                                    <div>
                                                        <p>
                                                            <span>Early stage of phlebitis : </span> <br/>
                                                                Remove & resite cannula
                                                        </p>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="poc red">
                                                <div className="check-box">

                                                    <div>
                                                        <p>
                                                            <span>Phlebitis/Thrombophlebitis:
                                                            </span> <br/>
                                                                Remove & resite cannula
                                                                Seek further advice
                                                        </p>
                                                    </div>
                                                </div>

                                            </div>
                                            <p className="pau mt-20"> <a href="#" className="big_btn ml-0">Take a
                                                Picture</a></p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="popup_footer">
                                <div className="prescribe_footer">
                                    <div className="dsp-flex">
                                        <a className="icon_btn"> <span className="material-icons">save</span> SAVE</a>
                                        <button className="border_btn"><span className="material-icons">save_as</span>SAVE &amp;
                                            CLOSE
                                        </button>
                                    </div>
                                    <button className="btn dsp_flex"><span className="material-icons"><span
                                        className="material-icons">close</span></span>DISCARD </button>
                                </div>
                            </div>
                        </div>
                    </div>


                  


                </div>
            </div>




        </React.Fragment>
    )
}

VipScore.propTypes = {
    t: PropTypes.any,
    getWards: PropTypes.func,
    getTheatres: PropTypes.func,
    wards: PropTypes.array,
    user: PropTypes.any,
    theatres: PropTypes.array,
    clinic: PropTypes.array,
    patients: PropTypes.array,
    doctors: PropTypes.array,
    profile: PropTypes.object
}

const mapStateToProps = ({ Layout, Login }) => ({
    wards: Layout.wards,
    user: Login.user,
    theatres: Layout.theatres,
    clinic: Layout.clinic,
    patients: Layout.patients,
    doctors: Layout.doctors,
    profile: Layout.profile
})

const mapDispatchToProps = dispatch => ({
    getWards: () => dispatch(getWardsList()),
    getTheatres: () => dispatch(getTheatresList()),
    getClinic: () => dispatch(getClinicList()),
    getPatient: () => dispatch(getPatientList()),
    getUserprofile: (id) => dispatch(getUprofile(id)),
    getDoctor: () => dispatch(getDoctorList()),
    patientupdate: (id, data) => dispatch(updatePatient(id, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(VipScore)
