import React from "react"
import MetaTags from 'react-meta-tags';
import Patient2 from '../../assets/images/patient2.png';
import VideoCam from '../../assets/images/videocam.svg';
import straighten from '../../assets/images/straighten.svg';
import scale from '../../assets/images/scale.svg';
import monitroWeight from '../../assets/images/monitor_weight.svg';
import favorite from '../../assets/images/favorite.svg';
import PulseOxy from '../../assets/images/PulseOxy.svg';
import thermostat from '../../assets/images/thermostat.svg';
import play from '../../assets/images/play.svg';
import doc from '../../assets/images/doc.jpg';
import bloodtype from '../../assets/images/bloodtype.svg';


const PatientProfile = () => {
    return (
        <React.Fragment>

            <MetaTags>
                <title>Patient Profile</title>
            </MetaTags>
            <div className="dashboard">
            <div className="main">
                <div className="wrapper">
                    <div className="out_patient_section out_patient_details">
                        <div className="patient_block">
                            <div className="content">
                                <div className="img">
                                    <img src={Patient2} />
                                </div>
                                <div className="name">
                                    <div className="title">Finley Goldsmith</div  >
                                    <div className="sub">NHS - 2225554569</div>
                                </div>
                                <div className="age">
                                    <div className="sub">Age</div>
                                    <div className="title">60</div  >

                                </div>
                                <div className="sex">
                                    <div className="sub">Sex</div>
                                    <div className="title">Male</div  >
                                </div>
                                <div className="number">
                                    <div className="sub">Hospital Number </div>
                                    <div className="title">11256897</div  >
                                </div>
                            </div>
                        </div>
                        <div className="visit_info">
                            <div className="visit">
                                <h3>Visit  </h3>
                                <div className="count">01</div>
                            </div>
                            <div className="problem">
                                <h3>Problem</h3>
                                <p>Lateral Epicondylitis (Tennis Elbow)</p>
                            </div>
                            <a href="#" className="icon_btn">   <img src={VideoCam} />Video Call</a>
                        </div>
                        <div className="sections_block">
                            <div className="section">
                                <h3 className="section_title">Measurements</h3>
                                <div className="measurements">
                                    <div className="box">
                                        <img src={straighten} className="icon" />
                                        <span className="text">162 <span className="sub">cm </span> </span>
                                    </div>
                                    <div className="box">
                                        <img src={scale} className="icon" />
                                        <span className="text">76 <span className="sub">kg </span> </span>
                                    </div>
                                    <div className="box">
                                        <img src={monitroWeight} className="icon" />
                                        <span className="text">26.5 </span>
                                    </div>
                                    <div className="box">
                                        <img src={favorite} className="icon" />
                                        <span className="text">105 <span className="sub">BPM </span></span>
                                    </div>
                                    <div className="box">
                                        <img src={PulseOxy} className="icon" />
                                        <span className="text">98 <span className="sub">% </span> </span>
                                    </div>
                                    <div className="box">
                                        <img src={thermostat} className="icon" />
                                        <span className="text">98.20  <span className="sub">F </span> </span>
                                    </div>
                                    <div className="box">
                                        <img src={bloodtype} className="icon" />
                                        <span className="text">120 <span className="sub">mgdi </span> </span>
                                    </div>
                                </div>
                            </div>
                            <div className="section">
                                <div className="row">
                                    <div className="col-sm-4">
                                        <h3 className="section_title mb-0">Referral Letters</h3>
                                    </div>
                                    <div className="col-sm-8">
                                        <a href="#" className="referral">   <img src={play} className="icon" /> GP - Last Dictated Letter / All Previous Clinic Letters</a>
                                    </div>
                                </div>
                            </div>
                            <div className="section">
                                <div className="row">
                                    <div className="col-sm-4">
                                        <h3 className="section_title">X Rays</h3>
                                        <a href="#" className="btn-light">Order</a>
                                    </div>
                                    <div className="col-sm-8">
                                        <div className="documents_section">
                                            <div className="document_box">
                                                <img src={doc} className="img" />
                                                <a href="#" className="doc_title">
                                                    <h4>Complete Blood C..</h4>
                                                    <p>22-7-2022</p>
                                                </a>
                                            </div>
                                            <div className="document_box">
                                                <img src={doc} className="img" />
                                                <a href="#" className="doc_title">
                                                    <h4>Complete Blood C..</h4>
                                                    <p>22-7-2022</p>
                                                </a>
                                            </div>
                                            <div className="document_box">
                                                <img src={doc} className="img" />
                                                <a href="#" className="doc_title">
                                                    <h4>Complete Blood C..</h4>
                                                    <p>22-7-2022</p>
                                                </a>
                                            </div>
                                            <div className="document_box">
                                                <img src={doc} className="img" />
                                                <a href="#" className="doc_title">
                                                    <h4>Complete Blood C..</h4>
                                                    <p>22-7-2022</p>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="section">
                                <div className="row">
                                    <div className="col-sm-4">
                                        <h3 className="section_title">Tests</h3>
                                        <a href="#" className="btn-light">Order</a>
                                    </div>
                                    <div className="col-sm-8">
                                        <div className="documents_section">
                                            <div className="document_box">
                                                <img src={doc} className="img" />
                                                <a href="#" className="doc_title">
                                                    <h4>Complete Blood C..</h4>
                                                    <p>22-7-2022</p>
                                                </a>
                                            </div>
                                            <div className="document_box">
                                                <img src={doc} className="img" />
                                                <a href="#" className="doc_title">
                                                    <h4>Complete Blood C..</h4>
                                                    <p>22-7-2022</p>
                                                </a>
                                            </div>
                                            <div className="document_box">
                                                <img src={doc} className="img" />
                                                <a href="#" className="doc_title">
                                                    <h4>Complete Blood C..</h4>
                                                    <p>22-7-2022</p>
                                                </a>
                                            </div>
                                            <div className="document_box">
                                                <img src={doc} className="img" />
                                                <a href="#" className="doc_title">
                                                    <h4>Complete Blood C..</h4>
                                                    <p>22-7-2022</p>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="section">
                                <div className="row">
                                    <div className="col-sm-4">
                                        <h3 className="section_title">Prescriptions</h3>
                                        <a href="#" className="btn-light">Add</a>
                                    </div>
                                    <div className="col-sm-8">
                                        <div className="documents_section">
                                            <div className="document_box">
                                                <img src={doc} className="img" />
                                                <a href="#" className="doc_title">
                                                    <h4>Complete Blood C..</h4>
                                                    <p>22-7-2022</p>
                                                </a>
                                            </div>
                                            <div className="document_box">
                                                <img src={doc} className="img" />
                                                <a href="#" className="doc_title">
                                                    <h4>Complete Blood C..</h4>
                                                    <p>22-7-2022</p>
                                                </a>
                                            </div>
                                            <div className="document_box">
                                                <img src={doc} className="img" />
                                                <a href="#" className="doc_title">
                                                    <h4>Complete Blood C..</h4>
                                                    <p>22-7-2022</p>
                                                </a>
                                            </div>
                                            <div className="document_box">
                                                <img src={doc} className="img" />
                                                <a href="#" className="doc_title">
                                                    <h4>Complete Blood C..</h4>
                                                    <p>22-7-2022</p>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="section">
                                <div className="row">
                                    <div className="col-sm-4">
                                        <h3 className="section_title">Procedures</h3>
                                        <a href="#" className="btn-light">Add</a>
                                    </div>
                                    <div className="col-sm-8">
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <a href="#" className="referral">  <img src={play} className="icon" /> ACL Reconstruction Surgery</a>
                                            </div>
                                            <div className="col-sm-6">
                                                <a href="#" className="referral">  <img src={play} className="icon" />Template 2</a>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </React.Fragment>
    )
}

export default PatientProfile
