import React, { useState, useEffect } from "react";
import styles from "./index.module.scss";
import { useHistory } from "react-router-dom";
import axios from "../../../configuration/axios.js";
import config from "../../../configuration/config.js";
import { IconRightFilled, IconSearch, IconPlus } from "../../icons/index.jsx";

const Category = () => {
  const history = useHistory();
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredCategoriesWard, setFilteredCategoriesWard] = useState([]);
  const [filteredCategoriesSurgical, setFilteredCategoriesSurgical] = useState(
    []
  );
  const getCategory = async () => {
    try {
      const response = await axios.get(config.category + "?pageNumber=1&limit=20&appname=ipr", {});
      return response?.data?.result?.data;
    } catch (error) {
      console.error("Error fetching category:", error);
      throw error;
    }
  };
  const fetchData = async () => {
    try {
      const categories = await getCategory();
      console.log("categories ", categories)
      const filteredWard = [];
      const filteredSurgical = [];

      categories.forEach((category) => {
        if (category.isSurgical === false) {
          filteredWard.push(category);
        } else  {
          filteredSurgical.push(category);
        }
      });
      
      // console.log("filteredWard ", filteredWard)
      // console.log("filteredSurgical ", filteredSurgical)
      setFilteredCategoriesWard(filteredWard);
      setFilteredCategoriesSurgical(filteredSurgical);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };
 


  useEffect(() => {
    fetchData();
  }, [searchTerm]);

  const categoryDocument = (item) => {
    history.push( {
      pathname: "/categorydocuments",
      state: {
        id: item._id,
        name: item.name,
      },
    });
    
  };

  const openQuiz = () => {
    // navigate("/quiz");
  };

  return (
    <div className={styles["page-container"]}>
      <div className={styles["page-main-header"]}>
        <div className={styles["hearder-left"]}>
          <div className={styles["search-content"]}>
            <input
              type="text"
              placeholder="Search topics here"
              className={styles["search-box"]}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <span className={styles["search-button"]}>
              <IconSearch />
            </span>
          </div>
          <button className={styles["suggest-button"]}>
            <IconPlus />
            Suggest category
          </button>
        </div>
      </div>
      <div className={styles["page-content-head"]}>
        EMERGENCY MANAGEMENT OF WARD PATIENTS
      </div>
      <div className={styles["page-content"]}>
        <div className={styles["page-content-categories"]}>
          {filteredCategoriesWard?.map((item, index) => (
            <div
              className={styles["item-wrapper"]}
              key={item._id + index}
              onClick={() => categoryDocument(item)}
            >
              <div className={styles["item-name"]}> {item.name} </div>
              <IconRightFilled />
            </div>
          ))}
        </div>
      </div>
      <div className={styles["page-content-head"]}>
        EMERGENCY MANAGEMENT OF SURGICAL PATIENTS
      </div>
      <div className={styles["page-content"]}>
        <div className={styles["page-content-categories"]}>
          {filteredCategoriesSurgical.map((item, index) => (
            <div
              className={styles["item-wrapper"]}
              key={item._id}
              onClick={() => categoryDocument(item)}
            >
              <div className={styles["item-name"]}> {item.name} </div>
              <IconRightFilled />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Category;
