import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import MetaTags from 'react-meta-tags';
import surgery from '../../assets/images/surgery.png';
import Select from "react-select"
import { Label } from "reactstrap"
import { connect } from "react-redux"
import { useHistory } from "react-router-dom";
import { getWardsList, getTheatresList, getClinicList, getPatientList, getUprofile, getDoctorList, updateTheatrePatient, updateWardPatient } from "../../store/actions"

const TheatreFlow = props => {
    const history = useHistory();
    const [visiblePatients, setPatient] = useState(true)
    const [visiblelanding, setClanding] = useState(false)
    const [successmessage, setSuccess] = useState("")


    const [theatreValues, setTheatre] = useState({})
    const [wardValues, setWard] = useState({})
    const [clinicPatient, setCPatient] = useState({})
    const [selectedGroup, setselectedGroup] = useState(null)
    const [patientGroup, setpatientGroup] = useState([])

    const {
        wards, user, theatres, clinic, patients, profile, doctors
    } = props;


    useEffect(() => {
        const { getWards, getTheatres, getClinic, getDoctor, getPatient, getUserprofile } = props
        // getWards()
        // getTheatres()
        // getClinic()
        getPatient()
        getDoctor()
        let userId = localStorage.getItem("authUser")
        getUserprofile(userId)
    }, [])


    useEffect(() => {
        if(patients && patients.length > 0){
            const patientList = []
            patients.forEach(function (item) {
                console.log("item", item)
                let obj = { value: item._id, label: item.firstname + " " +item.lastname }
                patientList.push(obj)
            });
            setpatientGroup(patientList)
        }
    }, [patients])


    const theatreBack = (e) => {
        e.preventDefault()
        // props.history.push("/theatre")
        history.goBack()
    }

    const updateWard = (e) => {
        const { wardupdate } = props
        wardupdate(null, wardValues)
    }

    const updateWardValue = (e) => {
        let val = e.target.value;
        let name = e.target.name;
        let updatedValue = { [name]: val };
        setWard(wardValues => ({
            ...wardValues,
            ...updatedValue
        }));
    }

    const handleWardSelectGroup = (selectedGroup, name) => {
        let updatedValue = { [name]: selectedGroup };
        setWard(wardValues => ({
            ...wardValues,
            ...updatedValue
        }));
    }

    const updateTheatre = (e) => {
        const { theatreupdate } = props
        theatreupdate(null, theatreValues)
    }

    const updateTheatreValue = (e) => {
        let val = e.target.value;
        let name = e.target.name;
        let updatedValue = { [name]: val };
        setTheatre(theatreValues => ({
            ...theatreValues,
            ...updatedValue
        }));
    }

    const handleSelectGroup = (selectedGroup, name) => {
        // console.log("selectedGroup ", selectedGroup)
        let updatedValue = { [name]: selectedGroup };
        setTheatre(theatreValues => ({
            ...theatreValues,
            ...updatedValue
        }));
    }


    //console.log("wardValues ", wardValues)
    //console.log("theatreValues ", theatreValues)


    return (
        <React.Fragment>

            <MetaTags>
                <title>Machine Equipment / Checks</title>
            </MetaTags>
            <div className="theatre">

                <div className='popup_wrp'>
                    <div className="popup_full prescribe">
                        <div className='popup_hed'>
                            <h2> THEATRE FLOW</h2>
                            <a onClick={(e) => theatreBack(e)} className='popup_close'><span className="material-icons">close</span></a>
                        </div>
                        <div className='popup_content'>
                            <div className='popup_scroll main pr '>
                                <h2 className="popup_title2">THEATRE TO WARD COMMUNICATION <br />
                                    <span> (Ward nurses get their status board alert)</span>
                                </h2>

                                <div className="ctr cont consent tla">
                                    <div className="consent_btn">
                                        <a className="landing_btn">
                                            <img src={surgery} className="icon" />
                                            Procedure complete</a>
                                    </div>
                                    <div className="consent_btn">
                                        <a className="landing_btn">
                                            <img src={surgery} className="icon" />
                                            Patient out of theatre</a>
                                    </div>
                                    <div className="consent_btn">
                                        <a className="landing_btn">
                                            <img src={surgery} className="icon" />
                                            Start of Recovery</a>
                                    </div>
                                    <div className="consent_btn">
                                        <a className="landing_btn">
                                            <img src={surgery} className="icon" />
                                            Ready to return to ward</a>
                                    </div>

                                </div>
                                <div className="section simple_wound ctr">
                                    <h2 className="popup_title">TO WARD</h2>
                                    <div className="pd_border"></div>

                                    <div className="search_dropdown_blk">
                                        <div className="form_group">
                                            <div className="floating-label-group">
                                                <Label>Completed patient</Label>
                                                <Select
                                                
                                                    name="ward_completed_patient"
                                                    onChange={(e) => {
                                                        handleWardSelectGroup(e, "ward_completed_patient")
                                                    }}
                                                    options={patientGroup}
                                                    classNamePrefix="select2-selection"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <p className="field_title mt-0">Next patient on the list</p>
                                    <div className="search_dropdown_blk">
                                        <div className="form_group">
                                            <div className="floating-label-group">

                                                <Select
                                                   
                                                    name="ward_nextpatient"
                                                    onChange={(e) => {
                                                        handleWardSelectGroup(e, "ward_nextpatient")
                                                    }}
                                                    options={patientGroup}
                                                    classNamePrefix="select2-selection"
                                                />


                                            </div>
                                        </div>
                                    </div>
                                    <div className="check_item"><label className="check">Availability of assistance</label>
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input name="ward_availablityofassitance" type="radio" value="Trolley" onClick={(e) => updateWardValue(e)} /><span>Trolley</span></label></div>
                                            <div className="select_btn"><label><input name="ward_availablityofassitance" type="radio" value="Chair" onClick={(e) => updateWardValue(e)} /><span>Chair</span></label></div>
                                            <div className="select_btn"><label><input name="ward_availablityofassitance" type="radio" value="Bed" onClick={(e) => updateWardValue(e)} /><span>Bed</span></label></div>
                                        </div>

                                    </div>
                                    <div className="form_group">
                                        <textarea className="textarea" onChange={(e) => updateWardValue(e)} name="ward_notes" placeholder="Notes " rows="2" cols="30"></textarea>

                                    </div>
                                    <div className="form_group">
                                        <div className="floating-label-group"><input type="text" className="form-control" onChange={(e) => updateWardValue(e)} name="ward_call_extension" /><label className="floating-label">Call extension no </label>
                                        </div>
                                    </div>

                                </div>
                                <div className="section simple_wound ctr">
                                    <h2 className="popup_title">TO THEATRE</h2>
                                    <div className="pd_border"></div>
                                    <p className="field_title mt-0">Next patient on the list</p>
                                    <div className="search_dropdown_blk">
                                        <div className="form_group">
                                            <div className="floating-label-group">
                                                <Select
                                                    // value={selectedGroup}
                                                    name="theatre_nextpatient"
                                                    onChange={(e) => {
                                                        handleSelectGroup(e, "theatre_nextpatient")
                                                    }}
                                                    options={patientGroup}
                                                    classNamePrefix="select2-selection"
                                                />


                                            </div>
                                        </div>
                                    </div>
                                    <div className="form_group ">
                                        <label>Status</label>
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input name="theatre_patient_status" type="radio" value="No" onClick={(e) => updateTheatreValue(e)} /><span>No</span></label></div>
                                            <div className="select_btn"><label><input name="theatre_patient_status" type="radio" value="Ready" onClick={(e) => updateTheatreValue(e)} /><span>Ready</span></label></div>

                                        </div>
                                    </div>
                                    {/* <div className="yn_switch ">
                                        <div className="label-container">
                                            <p>Status</p>
                                        </div>
                                        <div className="yn_switch tf ">
                                            
                                            
                                            <label className="switch-container">
                                                <input type="checkbox" onChange={(e) => handleCheckbox(e)} name="tflow_status" className="switch_check" />
                                                <div className="switch-bg"></div>
                                                <div className="round-box"></div>
                                                <div className="switch-left">
                                                    <span>YES</span>
                                                </div>
                                                <div className="switch-right">
                                                    <span>NO</span>
                                                </div>
                                               
                                            </label> 
                                        </div>
                                    </div>*/}
                                    <div className="form_group">
                                        <textarea className="textarea" onChange={(e) => updateTheatreValue(e)} name="theatre_notes" placeholder="Notes " rows="2" cols="30"></textarea>

                                    </div>
                                    <div className="form_group">
                                        <div className="floating-label-group"><input type="text" onChange={(e) => updateTheatreValue(e)} name="theatre_call_extension" className="form-control" /><label className="floating-label">Call extension no </label>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className='popup_footer'>
                            <div className="prescribe_footer">
                                <div className="dsp-flex">

                                    <button className="border_btn" onClick={(e) => { updateTheatre(e), updateWard(e), theatreBack(e) }}><span className="material-icons">save_as</span>SAVE & CLOSE
                                    </button>
                                </div>

                                <button className="btn dsp_flex" onClick={(e) => { theatreBack(e) }}><span className="material-icons"><span
                                    className="material-icons">close</span></span>DISCARD </button>
                            </div>
                        </div>
                    </div>
                </div>



            </div>

        </React.Fragment>
    )
}

TheatreFlow.propTypes = {
    t: PropTypes.any,
    getWards: PropTypes.func,
    getTheatres: PropTypes.func,
    wards: PropTypes.array,
    user: PropTypes.any,
    theatres: PropTypes.array,
    clinic: PropTypes.array,
    patients: PropTypes.array,
    doctors: PropTypes.array,
    profile: PropTypes.object
}

const mapStateToProps = ({ Layout, Login }) => ({
    wards: Layout.wards,
    user: Login.user,
    theatres: Layout.theatres,
    clinic: Layout.clinic,
    patients: Layout.patients,
    doctors: Layout.doctors,
    profile: Layout.profile
})

const mapDispatchToProps = dispatch => ({
    getWards: () => dispatch(getWardsList()),
    getTheatres: () => dispatch(getTheatresList()),
    getClinic: () => dispatch(getClinicList()),
    getPatient: () => dispatch(getPatientList()),
    getUserprofile: (id) => dispatch(getUprofile(id)),
    getDoctor: () => dispatch(getDoctorList()),
    theatreupdate: (id, data) => dispatch(updateTheatrePatient(id, data)),
    wardupdate: (id, data) => dispatch(updateWardPatient(id, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(TheatreFlow)
