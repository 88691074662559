import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import MetaTags from 'react-meta-tags';
import Moment from 'moment';
import { removeSpecialCharacters } from '../../utils/utils';
import SignaturePad from 'react-signature-canvas'
import SurgicalRecordPage from './surgicalRecord'
import {
    Modal
} from "reactstrap"
import { connect } from "react-redux"
import { getWardsList, getTheatresList, getClinicList, getPatientList, getUprofile, getDoctorList, updateTheatrePatient, updateWardPatient, updatePatient } from "../../store/actions"

const surgicalRecord = props => {

    const [clinicValues, setClinic] = useState({})
    const [consentValues, setConsent] = useState({})
    const [file, setFile] = useState({});
    const [success_msg, setsuccess_msg] = useState(false)
    const [discard_popup, setdiscard_popup] = useState(false)
    const {
        wards, user, theatres, clinic, patients, profile, doctors
    } = props;

    let staffsigPad = useRef(null);

    useEffect(() => {
        const { getPatient } = props
        getPatient()
    }, [])

    useEffect(() => {
        let clinicPatient = JSON.parse(localStorage.getItem("p"))
        if (patients && patients.length > 0 && clinicPatient && clinicPatient._id) {
            const cPatient = patients.filter(
                usr => usr._id === clinicPatient._id
            )
            if (cPatient && cPatient[0]) {
                let cpatientData = cPatient[0]
                delete cpatientData["_id"]
                setClinic(clinicValues => ({
                    ...clinicValues,
                    ...cpatientData
                }));
            }

        }
    }, [patients])

    const updateInputValue = (e) => {
        let val = e.target.value;
        let name = e.target.name;
        let updatedValue = {};
        updatedValue = { [name]: val };
        setClinic(clinicValues => ({
            ...clinicValues,
            ...updatedValue
        }));
        setConsent(consentValues => ({
            ...consentValues,
            ...updatedValue
        }));
        
    }

    const updateClinicValues = (e, name, val) => {
        e.preventDefault()
        let updatedValue = {};
        updatedValue = { [name]: val };
        setClinic(clinicValues => ({
            ...clinicValues,
            ...updatedValue
        }));
    }

    const updateClinic = (e) => {
        const { patientupdate } = props
        let clinicPatient = JSON.parse(localStorage.getItem("p"))
        let id = clinicPatient._id
        clinicValues["consent"] = "surgical1"
        patientupdate(id, clinicValues)
        let patient = {
            ...clinicPatient,
            ...clinicValues
        }
        localStorage.setItem("p", JSON.stringify(patient))
        setsuccess_msg(!success_msg)

    }

    const theatreBack = (e) => {
        e.preventDefault()
        props.history.push("/tpatient")
    }

    const handleChange = (event) => {
        updateClinicValues(event, event.target.name, event.target.checked)
    }

    const updateMultipleValues = (e, name, val, type) => {
        e.preventDefault()
        let tvalues = clinicValues
        e.target.parentElement.classList.add('active');
        if (tvalues[name]) {
            const exists = tvalues[name].includes(val);
            if (!exists) {
                tvalues[name].push(val)
            }

        } else {
            tvalues = {
                [name]: [val]
            }
        }
        // let updatedValue = {};
        // tvalues[type] = true
        setClinic(clinicValues => ({
            ...clinicValues,
            ...tvalues
        }));

    }

    const handleSelectGroup = (selectedGroup, name) => {
        let updatedValue = { [name]: selectedGroup };
        setClinic(clinicValues => ({
            ...clinicValues,
            ...updatedValue
        }));
    }


    const handleFile = async (e, name) => {

        const file = e.target.files[0]
        console.log("file ", file, name)
        let filename = e.target.files[0].name;
        // const base64 = await convertBase64(file)
        console.log("filename ", filename)
        let pdetails = JSON.parse(localStorage.getItem("p"))
        // var reader = new FileReader();
        //   reader.readAsDataURL(file);
        // reader.onload = async function (e) {
        // console.log("e ", e)
        let uploadFilePath = "ipr/" + pdetails._id + "/" + name + "_result" + "/" + removeSpecialCharacters(filename);
        let data = { source_bucket: "ipr-resources", sourcepath: uploadFilePath }
        console.log("data ", data)

        const response = await fetch(API_URL+'upload?appname=ipr', {
            method: "POST",
            mode: "cors",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
        const result = await response.json();

        console.log("result ", result)
        let url = result.result
        console.log("url ", url)
        const uploadImage = await fetch(url, {
            method: "PUT",
            mode: "cors",
            headers: {
                "Content-Type": "multipart/form-data",
                "Accept": "/",
                "Cache-Control": "no-cache",
                "Accept-Encoding": "gzip, deflate",
                "Connection": "keep-alive",
                "cache-control": "no-cache"
            },
            body: file
        })

        console.log("resultImage ", uploadImage)

        let imagePath = "https://ipr-resources.s3.amazonaws.com/"
        let imageUploadPath = imagePath + uploadFilePath;

        const { patientupdate } = props
        let updateObj = {
            [name + "_result"]: imageUploadPath,
            [name + "_update"]: new Date()
        }
        patientupdate(pdetails._id, updateObj)
        setFile(file => ({
            ...file,
            ...updateObj
        }));
        setClinic(clinicValues => ({
            ...clinicValues,
            ...updateObj
        }));

        let patient = {
            ...pdetails,
            ...updateObj
        }
        localStorage.setItem("p", JSON.stringify(patient))

    };

    const menuClick = (e, menu) => {
       // console.log("consentValues ", consentValues)
        let consentFormStatus = Object.keys(consentValues)
       // console.log("consentFormStatus ", consentFormStatus.length)
        if(consentFormStatus.length > 0){
            setdiscard_popup(true)
        }else {
            props.history.push("/" + menu)
        }
        
    }

    //console.log("clinicValues 1 ", clinicValues)

    return (
        <React.Fragment>

            <MetaTags>
                <title>SURGICAL RECORD</title>
            </MetaTags>
            <div className="theatre">

                <div className='popup_wrp'>
                    <div className="popup_full prescribe">
                        <div className='popup_hed'>
                            <h2>SURGICAL RECORD - CONSENT 1</h2>

                            <a className='popup_close' onClick={(e) => { theatreBack(e) }}><span className="material-icons">close</span></a>
                        </div>
                        <div className='popup_content'>

                            <div className='popup_scroll main pr height175 '>
                                {!clinicValues.consent &&
                                <div className="cnsnt_btns">
                                    <div className="consent_btn">
                                        <a className="icon_btn active" onClick={(e) => menuClick(e, 'surgery')}> CONSENT 1 </a>

                                    </div>
                                    <div className="consent_btn">
                                        <a className="icon_btn" onClick={(e) => menuClick(e, 'surgery_consent2')}> CONSENT 2 </a>

                                    </div>
                                    <div className="consent_btn">
                                        <a className="icon_btn" onClick={(e) => menuClick(e, 'surgery_consent3')}> CONSENT 3</a>

                                    </div>
                                    <div className="consent_btn">
                                        <a className="icon_btn" onClick={(e) => menuClick(e, 'surgery_consent4')}> CONSENT 4 </a>

                                    </div>

                                </div>}

                                <div className="section simple_wound ctr">
                                    <h2 className="popup_title"> CONSENT FORM 1 </h2>
                                    <div className="pd_border"></div>

                                    <h2 className="popup_title">PATIENT AGREEMENT TO INVESTIGATION OR TREATMENT</h2>

                                    <div className=" mb-20">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form_group">
                                                    <div className="floating-label-group"><input type="text" value={clinicValues.firstname}
                                                        className="form-control" autocomplete="off" /><label
                                                            className="floating-label">First Name</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form_group">
                                                    <div className="floating-label-group"><input type="text" value={clinicValues.lastname}
                                                        className="form-control" autocomplete="off" /><label
                                                            className="floating-label">Second Name</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form_group">
                                                    <div className="floating-label-group"><input type="text" value={clinicValues.sex}
                                                        className="form-control" autocomplete="off" /><label
                                                            className="floating-label">Sex</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form_group">
                                                    <div className="floating-label-group"><input type="text" value={clinicValues.age}
                                                        className="form-control" autocomplete="off" /><label
                                                            className="floating-label">Age</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form_group">
                                                    <div className="floating-label-group"><input type="text" value={clinicValues.dob}
                                                        className="form-control" autocomplete="off" /><label
                                                            className="floating-label">DOB</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form_group">
                                                    <div className="floating-label-group"><input type="text" value={clinicValues.hospitalNo}
                                                        className="form-control" autocomplete="off" /><label
                                                            className="floating-label">Hospital Number</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form_group">
                                                    <div className="floating-label-group"><input type="text" value={clinicValues.hospitalNo}
                                                        className="form-control" autocomplete="off" /><label
                                                            className="floating-label">NHS Number</label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form_group">
                                                    <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="con1_responsible" value={clinicValues.con1_responsible}
                                                        className="form-control" autocomplete="off" /><label
                                                            className="floating-label">Responsible
                                                            Consultant</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form_group">
                                                    <div className="floating-label-group"><input type="time" onChange={(e) => updateInputValue(e)} name="con1_time" value={clinicValues.con1_time}
                                                        className="form-control" autocomplete="off" /><label
                                                            className="floating-label">Time</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form_group">
                                                    <div className="floating-label-group"><input type="date" onChange={(e) => updateInputValue(e)} name="con1_date" value={clinicValues.con1_date}
                                                        className="form-control" autocomplete="off" /><label
                                                            className="floating-label">Date</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form_group">
                                                    <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="con1_loc" value={clinicValues.con1_loc}
                                                        className="form-control" autocomplete="off" /><label
                                                            className="floating-label">Location</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <p className="field_title mt-10">Special requirements</p>
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="con1_spcl_requirement" value={clinicValues.con1_spcl_requirement}
                                                className="form-control" autocomplete="off" /><label
                                                    className="floating-label">eg other language/other
                                                    communication method</label>
                                            </div>
                                        </div>
                                        <div className="check_item mt-20"><label className="check">STATEMENT OF HEALTH
                                            PROFESSIONAL</label>
                                            {clinicValues.con1_health_professional ?
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input type="radio"
                                                    value={clinicValues.con1_health_professional} checked /><span>{clinicValues.con1_health_professional} </span></label>
                                                </div> {clinicValues.con1_health_professional && <a onClick={(e) => updateClinicValues(e, "con1_health_professional", false)}><span className="material-symbols-outlined"> reply
                                                </span></a>}

                                            </div>
                                            :
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="con1_health_professional" type="radio"
                                                    value="BLANK"/><span>BLANK</span></label></div>
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="con1_health_professional" type="radio"
                                                    value="PROCEDURE SPECIFIC"/><span>PROCEDURE SPECIFIC</span></label></div>

                                            </div>}
                                        </div>
                                        <p className="note2">(To be filled in by health professional with appropriate knowledge
                                            of
                                            proposed procedure, as specified in consent policy)</p>
                                        <div className="check_item mt-20"><label className="check">NAME OF PROPOSED PROCEDURE OR
                                            COURSE
                                            OF TREATMENT</label>
                                            {clinicValues.con1_course_treatment ?
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input type="radio"
                                                    value={clinicValues.con1_course_treatment} checked /><span>{clinicValues.con1_course_treatment} </span></label>
                                                </div> {clinicValues.con1_course_treatment && <a onClick={(e) => updateClinicValues(e, "con1_course_treatment", false)}><span className="material-symbols-outlined"> reply
                                                </span></a>}

                                            </div>
                                            :
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="con1_course_treatment" type="radio"
                                                    value="RIGHT" /><span>RIGHT</span></label></div>
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="con1_course_treatment" type="radio"
                                                    value="LEFT" /><span>LEFT</span></label></div>
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="con1_course_treatment" type="radio"
                                                    value="BILATER AL" /><span>BILATER AL</span></label></div>
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="con1_course_treatment" type="radio"
                                                    value="NA" /><span>NA</span></label></div>

                                            </div>}
                                        </div>


                                        <div className="form_group mt-10">
                                            <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="con1_procedure_to_patient" value={clinicValues.con1_procedure_to_patient}
                                                className="form-control" autocomplete="off" /><label
                                                    className="floating-label">I have explained the procedure to
                                                    the patient. In
                                                    particular, I have explained</label>
                                            </div>
                                        </div>
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="con1_intended" value={clinicValues.con1_intended}
                                                className="form-control" autocomplete="off" /><label
                                                    className="floating-label">THE INTENDED BENEFITS</label>
                                            </div>
                                        </div>
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="con1_significant" value={clinicValues.con1_significant}
                                                className="form-control" autocomplete="off" /><label
                                                    className="floating-label">SIGNIFICANT, UNAVOIDABLE
                                                    OR FREQUENTLY OCCURRING
                                                    RISKS</label>
                                            </div>
                                        </div>
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="con1_extra_procedure" value={clinicValues.con1_extra_procedure}
                                                className="form-control" autocomplete="off" /><label
                                                    className="floating-label">ANY EXTRA PROCEDURES
                                                    WHICH MAY BECOME
                                                    NECESSARY DURING THE
                                                    PROCEDURE</label>
                                            </div>
                                        </div>
                                        <p className="note2" >Select all that apply for Multiselect forms {clinicValues.con1_transfusion && <a onClick={(e) => updateClinicValues(e, "con1_transfusion", false)}><span className="material-symbols-outlined"> reply </span></a>}</p>
                                        {clinicValues.con1_transfusion && clinicValues.con1_transfusion_list && clinicValues.con1_transfusion_list.length > 0 ? <div className="check_list w_white mb-0">

                                            {clinicValues.con1_transfusion_list.map((item, i) => (
                                                <div className="check_item active" key={i} onClick={(e) => updateClinicValues(e, "con1_transfusion", false)} ><label className="check">{item}</label></div>
                                            ))}

                                        </div> :
                                        <div className="check_list w_white">
                                            <span className="check_btn2" onClick={(e) => updateClinicValues(e, "con1_transfusion", true)}><span className="material-icons"> done </span></span>

                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "con1_transfusion_list", "Blood transfusion", "con1_transfusion")}><label className="check">Blood transfusion</label>
                                            </div>

                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "con1_transfusion_list", "Medical photographs or Videos may be recorded for your Medical record", "con1_transfusion")}><label className="check">Medical photographs or Videos may be recorded for your Medical record</label>
                                            </div>
                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "con1_transfusion_list", "Medical photographs or Videos may be recorded for Teaching or medical publication with no patient identifiable information", "con1_transfusion")}><label className="check">Medical photographs or Videos may be recorded for Teaching or medical publication with no patient identifiable information</label>
                                            </div>
                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "con1_transfusion_list", "Other procedure (please specify)", "con1_transfusion")}><label className="check">Other procedure (please specify)</label>
                                            </div>
                                        </div>}

                                        <p className="note2">I have also discussed what the procedure is likely to involve, the
                                            benefits and risks of any available
                                            alternative treatments (including no treatment) and any particular concerns of
                                            this
                                            patient.</p>
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="con1_alternative_treatment" value={clinicValues.con1_alternative_treatment}
                                                className="form-control" autocomplete="off" /><label
                                                    className="floating-label">The following leaflet/tape has been
                                                    provided</label>
                                            </div>
                                        </div>
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="con1_involve" value={clinicValues.con1_involve}
                                                className="form-control" autocomplete="off" /><label
                                                    className="floating-label">This procedure will involve</label>
                                            </div>
                                        </div>
                                        <p className="note2" >Select all that apply for Multiselect forms {clinicValues.con1_general_anaesthesia && <a onClick={(e) => updateClinicValues(e, "con1_general_anaesthesia", false)}><span className="material-symbols-outlined"> reply </span></a>}</p>
                                        {clinicValues.con1_general_anaesthesia && clinicValues.con1_general_anaesthesia_list && clinicValues.con1_general_anaesthesia_list.length > 0 ? <div className="check_list w_white mb-0">

                                            {clinicValues.con1_general_anaesthesia_list.map((item, i) => (
                                                <div className="check_item active" key={i} onClick={(e) => updateClinicValues(e, "con1_general_anaesthesia", false)} ><label className="check">{item}</label></div>
                                            ))}

                                        </div> :
                                        <div className="check_list w_white mb-0">
                                            <span className="check_btn2" onClick={(e) => updateClinicValues(e, "con1_general_anaesthesia", true)}><span className="material-icons"> done </span></span>

                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "con1_general_anaesthesia_list", "General and/or Regional anaesthesia", "con1_general_anaesthesia")}><label className="check">General and/or Regional anaesthesia</label>
                                            </div>
                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "con1_general_anaesthesia_list", "Local anaesthesia", "con1_general_anaesthesia")}><label className="check">Local anaesthesia</label>
                                            </div>
                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "con1_general_anaesthesia_list", "Sedation", "con1_general_anaesthesia")}><label className="check">Sedation</label>
                                            </div>
                                        </div>}


                                        <div className="form_group mt-20">
                                            <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="con1_signed" value={clinicValues.con1_signed}
                                                className="form-control" autocomplete="off" /><label
                                                    className="floating-label">Signed</label>
                                            </div>
                                        </div>
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="date"  onChange={(e) => updateInputValue(e)} name="con1_signeddate" value={clinicValues.con1_signeddate}
                                                className="form-control" autocomplete="off" /><label
                                                    className="floating-label">Date</label>
                                            </div>
                                        </div>
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text"  onChange={(e) => updateInputValue(e)} name="con1_signed_name" value={clinicValues.con1_signed_name}
                                                className="form-control" autocomplete="off" /><label
                                                    className="floating-label">Name</label>
                                            </div>
                                        </div>
                                        {clinicValues.con1_consult ?
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input type="radio"
                                                    value={clinicValues.con1_consult} checked /><span>{clinicValues.con1_consult} </span></label>
                                                </div> {clinicValues.con1_consult && <a onClick={(e) => updateClinicValues(e, "con1_consult", false)}><span className="material-symbols-outlined"> reply
                                                </span></a>}

                                            </div>
                                            :
                                        <div className="select_btns popup_select mb-0">
                                            <div className="select_btn"><label><input name="con1_consult" onClick={(e) => updateInputValue(e)} type="radio" value="Consultant" /><span>Consultant</span></label></div>
                                            <div className="select_btn"><label><input name="con1_consult" onClick={(e) => updateInputValue(e)} type="radio" value="Registrar" /><span>Registrar</span></label></div>
                                            <div className="select_btn"><label><input name="con1_consult" onClick={(e) => updateInputValue(e)} type="radio" value="Junior Doctor" /><span>Junior Doctor</span></label></div>
                                            <div className="select_btn"><label><input name="con1_consult" onClick={(e) => updateInputValue(e)} type="radio" value="Nurse practitioner" /><span>Nurse practitioner</span></label></div>

                                        </div>}
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="con1_patientwishes" value={clinicValues.con1_patientwishes}
                                                className="form-control" autocomplete="off" /><label
                                                    className="floating-label">Contact details (if patient wishes
                                                    to discuss options
                                                    later)</label>
                                            </div>
                                        </div>

                                        <h2 className="popup_title2 mt-20">STATEMENT OF INTERPRETER<span> (where
                                            appropriate)</span></h2>
                                        <p className="note2">I have interpreted the information above to the patient to the best
                                            of
                                            my ability and in a way in which I believe
                                            she/ he can understand.</p>
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="con1_interpreter" value={clinicValues.con1_interpreter}
                                                className="form-control" autocomplete="off" /><label
                                                    className="floating-label">Interpreter’s signature</label>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form_group">
                                                    <div className="floating-label-group"><input type="date" onChange={(e) => updateInputValue(e)} name="con1_interpreterdate" value={clinicValues.con1_interpreterdate}
                                                        className="form-control" autocomplete="off" /><label
                                                            className="floating-label">Date</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form_group">
                                                    <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="con1_interpretername" value={clinicValues.con1_interpretername}
                                                        className="form-control" autocomplete="off" /><label
                                                            className="floating-label">Name</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="note mt-20">
                                            <h2 className="popup_title2">STATEMENT OF PATIENT</h2>
                                            <p>(Please read this form carefully. If your treatment has been planned in
                                                advance,
                                                you should already have your own
                                                copy, which describes the benefits and risks of the proposed treatment. If
                                                not
                                                you will be offered a copy now. If you
                                                have any further questions, do ask — we are here to help you. You have the
                                                right
                                                to change your mind at any time,
                                                including after you have signed this form.)</p>

                                            <ul>
                                                <li>I agree to the procedure or course of treatment described on this form.
                                                </li>
                                                <li>I understand that you cannot give me a guarantee that a particular
                                                    person
                                                    will perform the procedure.
                                                    The person will, however, have appropriate experience.</li>
                                                <li>I understand that I will have the opportunity to discuss the details of
                                                    anaesthesia with an anaesthetist
                                                    before the procedure, unless the urgency of my situation prevents this.
                                                    (This only applies to patients
                                                    having general or regional anaesthesia.)</li>
                                                <li>I understand that any procedure in addition to those described on this
                                                    form
                                                    will only be carried out if it is
                                                    necessary to save my life or to prevent serious harm to my health.
                                                </li>
                                                <li>I have been told about additional procedures which may become necessary
                                                    during my treatment. I have
                                                    listed below any procedures which I do not wish to be carried out
                                                    without
                                                    further discussions.</li>
                                            </ul>

                                        </div>
                                        <div className="signature_block">
                                       
                                        <SignaturePad canvasProps={{ className: "sigPad" }} ref={(ref) => { staffsigPad = ref }} />
                                        <p className="mt-30 mb-30">Dr Signature:</p>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form_group">
                                                    <div className="floating-label-group"><input type="date" onChange={(e) => updateInputValue(e)} name="con1_dr_date" value={clinicValues.con1_dr_date}
                                                        className="form-control" autocomplete="off" /><label
                                                            className="floating-label">Date</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form_group">
                                                    <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="con1_dr_name" value={clinicValues.con1_dr_name}
                                                        className="form-control" autocomplete="off" /><label
                                                            className="floating-label">Name</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="note mt-20">
                                            <h2 className="popup_title2">WITNESS SIGNATURE</h2>
                                            <p>A witness should sign below if the patient is unable to sign but has
                                                indicated
                                                his or her consent.
                                                Young people/children may also like a parent to sign here (see notes).</p>

                                        </div>
                                        <div className="signature_block">
                                       
                                       <SignaturePad canvasProps={{ className: "sigPad" }} ref={(ref) => { staffsigPad = ref }} />
                                       <p className="mt-30 mb-30">Witness Signature:</p>
                                       </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form_group">
                                                    <div className="floating-label-group"><input type="date" onChange={(e) => updateInputValue(e)} name="con1_witness_date" value={clinicValues.con1_witness_date}
                                                        className="form-control" autocomplete="off" /><label
                                                            className="floating-label">Date</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form_group">
                                                    <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="con1_witness_name" value={clinicValues.con1_witness_name}
                                                        className="form-control" autocomplete="off" /><label
                                                            className="floating-label">Name</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="note mt-20">
                                            <h2 className="popup_title2">RECONFIRMATION OF CONSENT</h2>
                                            <p className="note2">(To be completed by a health professional when the patient is
                                                admitted to the procedure, if the patient has signed the form in advance.)
                                            </p>
                                            <p>On behalf of the team treating the patient, I have confirmed with the patient
                                                that she/he has no further
                                                questions and wishes the procedure to go ahead.</p>

                                        </div>
                                        <div className="signature_block">
                                       
                                       <SignaturePad canvasProps={{ className: "sigPad" }} ref={(ref) => { staffsigPad = ref }} />
                                       <p className="mt-30 mb-30">Dr Signature:</p>
                                       </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form_group">
                                                    <div className="floating-label-group"><input type="date" onChange={(e) => updateInputValue(e)} name="con1_reconfirmation_date" value={clinicValues.con1_reconfirmation_date}
                                                        className="form-control" autocomplete="off" /><label
                                                            className="floating-label">Date</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form_group">
                                                    <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="con1_reconfirmation_name" value={clinicValues.con1_reconfirmation_name}
                                                        className="form-control" autocomplete="off" /><label
                                                            className="floating-label">Name</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="check_item">
                                        {clinicValues.con1_reg_practitioner ?
                                                <div className="select_btns popup_select">
                                                    <div className="select_btn"><label><input type="radio"
                                                        value={clinicValues.con1_reg_practitioner} checked /><span>{clinicValues.con1_reg_practitioner} </span></label>
                                                    </div> {clinicValues.con1_reg_practitioner && <a onClick={(e) => updateClinicValues(e, "con1_reg_practitioner", false)}><span className="material-symbols-outlined"> reply
                                                    </span></a>}

                                                </div>
                                                :
                                            <div className="select_btns popup_select mt-0">
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="con1_reg_practitioner" type="radio"
                                                    value="Consultant" /><span>Consultant</span></label></div>
                                                <div className="select_btn"><label><input  onClick={(e) => updateInputValue(e)} name="con1_reg_practitioner" type="radio"
                                                    value="Registrar" /><span>Registrar</span></label>
                                                </div>
                                                <div className="select_btn"><label><input  onClick={(e) => updateInputValue(e)} name="con1_reg_practitioner" type="radio"
                                                    value="Junior Doctor" /><span>Junior Doctor</span></label></div>
                                                <div className="select_btn"><label><input  onClick={(e) => updateInputValue(e)} name="con1_reg_practitioner" type="radio"
                                                    value="Nurse practitioner" /><span>Nurse practitioner</span></label></div>
                                            </div>}
                                        </div>


                                        <h2 className="popup_title">IMPORTANT NOTES</h2>

                                        <div className="check_list w_white  mt-20 mb-0">
                                            <div className="check_item"><label className="check">PATIENT HAS WITHDRAWN
                                                CONSENT</label>
                                            </div>
                                        </div>
                                        <div className="signature_block">
                                       
                                       <SignaturePad canvasProps={{ className: "sigPad" }} ref={(ref) => { staffsigPad = ref }} />
                                       <p className="mt-30 mb-30">Dr Signature:</p>
                                       </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form_group">
                                                    <div className="floating-label-group"><input type="date" onChange={(e) => updateInputValue(e)} name="con1_pt_date" value={clinicValues.con1_pt_date}
                                                        className="form-control" autocomplete="off" /><label
                                                            className="floating-label">Date</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form_group">
                                                    <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="con1_pt_name" value={clinicValues.con1_pt_name}
                                                        className="form-control" autocomplete="off" /><label
                                                            className="floating-label">Name</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="check_list w_white mb-0">
                                            <div className="check_item"><label className="check">See any advance decision (e.g
                                                Jehovah&#39;s Witness form)</label>
                                            </div>
                                        </div>
                                        <div className="yn_switch mt-20 ">
                                            <div className="label-container">
                                                <p>Copy accepted by patient</p>
                                            </div>
                                            <label className="switch-container">
                                                <input type="checkbox" className="switch_check" name="con1_copyaccepted" onChange={(e) => handleChange(e)} checked={clinicValues.con1_copyaccepted} />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>YES</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>NO</span>
                                                    </div>
                                            </label>
                                        </div>
                                        <div className="check_item">
                                            <div className="select_btns popup_select mt-0">
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 1" /><span>SAVE
                                                        PDF</span></label></div>
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 1" /><span>VIEW
                                                        PDF</span></label>
                                                </div>
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 1" /><span>SEND TO PATIENT
                                                        RECORD</span></label></div>
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 1" /><span>SEND MAIL TO
                                                        PATIENT</span></label></div>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                                <SurgicalRecordPage updateInputValue={(e)=> updateInputValue(e)} clinicValues={clinicValues} handleChange={(e) => handleChange(e)} handleSelectGroup={handleSelectGroup} handleFile={handleFile} file={file} />

                            </div>
                            <div className='popup_footer'>
                                <div className="prescribe_footer">
                                    <div className="dsp-flex">
                                        <a className="icon_btn" onClick={(e) => { updateClinic(e), theatreBack(e) }}> <span className="material-icons">save</span> SAVE AS COMPLETE </a>
                                        <button className="border_btn" onClick={(e) => { updateClinic(e) }} ><span className="material-icons">save_as</span>SEND TO PATIENT
                                            RECORD
                                        </button>
                                    </div>
                                    <button className="btn dsp_flex" onClick={(e) => { theatreBack(e) }}><span className="material-icons"><span
                                        className="material-icons">close</span></span>CANCEL
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <Modal
                    isOpen={success_msg}
                    toggle={() => {
                        setsuccess_msg(false)
                    }}
                    centered={true}
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0"></h5>
                        <button
                            type="button"
                            onClick={() => {
                                setsuccess_msg(false)
                            }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <h1>Updated Successfully</h1>
                    </div>
                </Modal>

                <Modal
                    isOpen={discard_popup}
                    toggle={() => {
                        setdiscard_popup(false)
                    }}
                    centered={true}
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0"></h5>
                        <button
                            type="button"
                            onClick={() => {
                                setdiscard_popup(false)
                            }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <h4>Discard the Changes</h4>
                        <button onClick={() => { setConsent({}), setdiscard_popup(false)  }} className='btn btn-success' >Yes</button>
                        <button onClick={() => { setdiscard_popup(false) }} className='btn btn-danger' >No</button>
                    </div>
                </Modal>

            </div>

        </React.Fragment>
    )
}

surgicalRecord.propTypes = {
    t: PropTypes.any,
    getWards: PropTypes.func,
    getTheatres: PropTypes.func,
    wards: PropTypes.array,
    user: PropTypes.any,
    theatres: PropTypes.array,
    clinic: PropTypes.array,
    patients: PropTypes.array,
    doctors: PropTypes.array,
    profile: PropTypes.object
}

const mapStateToProps = ({ Layout, Login }) => ({
    wards: Layout.wards,
    user: Login.user,
    theatres: Layout.theatres,
    clinic: Layout.clinic,
    patients: Layout.patients,
    doctors: Layout.doctors,
    profile: Layout.profile
})

const mapDispatchToProps = dispatch => ({
    getWards: () => dispatch(getWardsList()),
    getTheatres: () => dispatch(getTheatresList()),
    getClinic: () => dispatch(getClinicList()),
    getPatient: () => dispatch(getPatientList()),
    getUserprofile: (id) => dispatch(getUprofile(id)),
    getDoctor: () => dispatch(getDoctorList()),
    theatreupdate: (id, data) => dispatch(updateTheatrePatient(id, data)),
    wardupdate: (id, data) => dispatch(updateWardPatient(id, data)),
    patientupdate: (id, data) => dispatch(updatePatient(id, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(surgicalRecord)
