import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux"
import { getWardsList, getTheatresList, getClinicList, getPatientList, getUprofile, getDoctorList, updatePatient } from "../../store/actions"
import PatientBlock from "../Clinic/patientBlock";

const PatientRecord = props => {

    const [modal_standard, setmodal_standard] = useState(false)
    const [clinicValues, setClinic] = useState({})

    const [selectedGroup, setselectedGroup] = useState(null)
    const [popupname, setpopup_name] = useState({})
    const [anydelay, setDelay] = useState(false)

    const {
        wards, user, theatres, clinic, patients, profile, doctors
    } = props;

    useEffect(() => {
        const { getWards, getTheatres, getClinic, getDoctor, getPatient, getUserprofile } = props
        // getWards()
        // getTheatres()
        // getClinic()
        getPatient()
        getDoctor()
        let userId = localStorage.getItem("authUser")
        getUserprofile(userId)
    }, [])


    const patient = (e, item) => {
        e.preventDefault()
        localStorage.setItem("p", JSON.stringify(item))
        props.history.push("/prdetails")
    }



    return (
        <React.Fragment>

            <MetaTags>
                <title>Patient Record</title>
            </MetaTags>
            <div className='ward '>
              
                    <div className="main">
                        <div className="wrapper">
                            <div className="patient_record">
                                <h3 className="title ">Search Patients </h3>
                                <div className="search_block">
                                    <span className="material-icons search_icon"> search </span>
                                    <input type="text" className="search" placeholder="Search Patient Name, NHS Number" />
                                    <span className="material-icons qr_icon"> qr_code_scanner </span>
                                </div>
                                <button type="button" className="search_btn">Search</button>
                                <h3 className="title mt-30">Recent Patients </h3>
                                {patients && patients.length > 0 &&
                                    patients.map((item, i) => (
                                        <div key={i} onClick={(e)=> patient(e, item)} >
                                        <PatientBlock patient={item} />
                                            </div>
                                    ))}
                                
                            </div>
                        </div>
                    </div>

            </div>


        </React.Fragment>
    )
}

PatientRecord.propTypes = {
    t: PropTypes.any,
    getWards: PropTypes.func,
    getTheatres: PropTypes.func,
    wards: PropTypes.array,
    user: PropTypes.any,
    theatres: PropTypes.array,
    clinic: PropTypes.array,
    patients: PropTypes.array,
    doctors: PropTypes.array,
    profile: PropTypes.object
}

const mapStateToProps = ({ Layout, Login }) => ({
    wards: Layout.wards,
    user: Login.user,
    theatres: Layout.theatres,
    clinic: Layout.clinic,
    patients: Layout.patients,
    doctors: Layout.doctors,
    profile: Layout.profile
})

const mapDispatchToProps = dispatch => ({
    getWards: () => dispatch(getWardsList()),
    getTheatres: () => dispatch(getTheatresList()),
    getClinic: () => dispatch(getClinicList()),
    getPatient: () => dispatch(getPatientList()),
    getUserprofile: (id) => dispatch(getUprofile(id)),
    getDoctor: () => dispatch(getDoctorList()),
    patientupdate: (id, data) => dispatch(updatePatient(id, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(PatientRecord)
