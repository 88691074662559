import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import MetaTags from 'react-meta-tags';


import PatientBlock from "../Clinic/patientBlock";
import { connect } from "react-redux"
import { getWardsList, getTheatresList, getClinicList, getPatientList, getUprofile, getDoctorList, updatePatient } from "../../store/actions"

const Assesment = props => {


    const [visiblePatients, setPatient] = useState(true)
    const [visiblelanding, setClanding] = useState(false)
    const [successmessage, setSuccess] = useState("")


    const [assesmentValues, setAssesment] = useState({})

    const {
        wards, user, theatres, clinic, patients, profile, doctors
    } = props;

    useEffect(() => {
        const { getWards, getTheatres, getClinic, getDoctor, getPatient, getUserprofile } = props
        // getWards()
        // getTheatres()
        // getClinic()
        getPatient()
        getDoctor()
        let userId = localStorage.getItem("authUser")
        getUserprofile(userId)
    }, [])

    const updateRadioValue = (e) => {
        let val = e.target.value;
        let name = e.target.name;
        let updatedValue = { [name]: val };
        setAssesment(assesmentValues => ({
            ...assesmentValues,
            ...updatedValue
        }));
    }


    const closeClick = (e) => {
        e.preventDefault()
        localStorage.removeItem("a")
        props.history.push("/riskassesment")
    }

    const yesClick = (e) => {
        e.preventDefault()
        if(assesmentPage == "INFECTION"){
            props.history.push("/infection")
        }else if(assesmentPage == "MENTAL STATE"){
            props.history.push("/delirium")
        }else if(assesmentPage == "SAFEGUARDING"){
            props.history.push("/safeguarding")
        }else if(assesmentPage == "VIP SCORE"){
            props.history.push("/vip")
        }else if(assesmentPage == "SMOKING"){
            props.history.push("/smoking")
        }else if(assesmentPage == "DEMENTIA"){
            props.history.push("/dementia")
        }else if(assesmentPage == "DRUG & ALCOHOL"){
            props.history.push("/drug")
        }else if(assesmentPage == "SKIN INTEGRITY"){
            props.history.push("/skinassesment")
        }else if(assesmentPage == "NUTRITION - MUST"){
            props.history.push("/nutrition")
        }else if(assesmentPage == "MOVING & HANDLING"){
            props.history.push("/moving")
        }else if(assesmentPage == "CONTINENCE"){
            props.history.push("/continence")
        }else if(assesmentPage == "SUPERVISION BED RAILS"){
            props.history.push("/supervision")
        }else if(assesmentPage == "FALLS RISK"){
            props.history.push("/fallsrisk")
        }else if(assesmentPage == "ADMISSION"){
            props.history.push("/nursingadmission")
        }

        
       
    }

    let assesmentPage = JSON.parse(localStorage.getItem("a"))
    let clinicPatient = JSON.parse(localStorage.getItem("p"))

   

    return (
        <React.Fragment>

            <MetaTags>
                <title>{assesmentPage} Assesment</title>
            </MetaTags>
            <div className="dashboard ward">
                <div className="main">

                    <div className="popup_wrp">
                        <div className="popup_full prescribe">
                            <div className="popup_hed">
                                <h2>{assesmentPage} ASSESMENT </h2>
                                <div className="dsp-flex"> <a className="popup_close" onClick={(e) => closeClick(e)}><span className="material-icons">close</span></a></div>

                            </div>
                            <div className="popup_content na_section">
                                <div className="popup_scroll w-btns main nutrition">
                                    <PatientBlock patient={clinicPatient} />
                                    <div className="bar">
                                        <div className="left">
                                            <div className="tag"> Guidance </div>
                                            <h3 className="sub_title">Skin Assessment </h3>
                                        </div>
                                        <div className="right">
                                            <a href="#" className="btn"> <span className="material-symbols-outlined"> assignment </span> Not
                                                Yet Done</a>
                                            <div className="tag"> Due </div>
                                        </div>
                                    </div>
                                    {assesmentValues && assesmentValues.appropriate_assess != "YES" &&
                                        <div className="step_block">
                                            <div className="step_wrp">
                                                <a href="#" className="back material-symbols-outlined"> arrow_back </a>
                                                <div className="check_item mt-10 mb-20">
                                                    <label className="check">Is it appropriate to assess the patient ?</label>
                                                    <div className="select_btns popup_select">
                                                        <div className="select_btn"><label><input onClick={(e) => updateRadioValue(e)} name="appropriate_assess" type="radio" value="YES" /><span>YES </span></label></div>
                                                        <div className="select_btn"><label><input onClick={(e) => updateRadioValue(e)} name="appropriate_assess" type="radio" value="NO" /><span> No </span></label></div>
                                                    </div>
                                                </div>
                                                {assesmentValues && assesmentValues.appropriate_assess == "NO" &&
                                                    <>
                                                        <div className="form_group mb-30">
                                                            <label className="label">Reason</label>
                                                            <textarea className="form-control" placeholder="Enter" rows="2" cols="50"> </textarea>
                                                        </div>
                                                        <div className="form_group">
                                                            <label className="label">Next Assessment Due</label>
                                                            <input type="date" className="form-control" placeholder="Enter" />
                                                        </div>
                                                        <button type="button" className="btn">NEXT</button>
                                                    </>
                                                }
                                            </div>
                                        </div>}

                                    {assesmentValues && assesmentValues.appropriate_assess == "YES" &&
                                        <div className="step_block stp3">
                                            <div className="step_wrp poc">
                                                <div className="check-box">
                                                    <div className="round">
                                                        <input type="checkbox" name="assesment"  />
                                                        <label for="checkbox-20"></label>
                                                    </div>
                                                    Patient is fully MOBILE without equipment/assistance
                                                </div>
                                                <div className="check-box">
                                                    <div className="round">
                                                        <input type="checkbox" name="assesment" />
                                                        <label for="checkbox-20"></label>
                                                    </div>
                                                    Fully CONTINENT
                                                </div>
                                                <div className="check-box">
                                                    <div className="round">
                                                        <input type="checkbox" name="assesment"  />
                                                        <label for="checkbox-20"></label>
                                                    </div>
                                                    Appears WELL NOURISHED and able to eat and drink
                                                </div>
                                                <div className="btn-group">
                                                    <button type="button" onClick={(e) => yesClick(e)}  className="btn"> <span className="material-symbols-outlined icon">
                                                        emergency_home
                                                    </span> No to Any Statement</button>
                                                   
                                                    <button type="button" className="btn"><span className="material-symbols-outlined icon">
                                                        done
                                                    </span>  Yes to All Statements</button>
                                                  
                                                </div>

                                            </div>
                                        </div>
                                    }



                                </div>

                            </div>

                        </div>
                    </div>




                </div>
            </div>




        </React.Fragment>
    )
}

Assesment.propTypes = {
    t: PropTypes.any,
    getWards: PropTypes.func,
    getTheatres: PropTypes.func,
    wards: PropTypes.array,
    user: PropTypes.any,
    theatres: PropTypes.array,
    clinic: PropTypes.array,
    patients: PropTypes.array,
    doctors: PropTypes.array,
    profile: PropTypes.object
}

const mapStateToProps = ({ Layout, Login }) => ({
    wards: Layout.wards,
    user: Login.user,
    theatres: Layout.theatres,
    clinic: Layout.clinic,
    patients: Layout.patients,
    doctors: Layout.doctors,
    profile: Layout.profile
})

const mapDispatchToProps = dispatch => ({
    getWards: () => dispatch(getWardsList()),
    getTheatres: () => dispatch(getTheatresList()),
    getClinic: () => dispatch(getClinicList()),
    getPatient: () => dispatch(getPatientList()),
    getUserprofile: (id) => dispatch(getUprofile(id)),
    getDoctor: () => dispatch(getDoctorList()),
    patientupdate: (id, data) => dispatch(updatePatient(id, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Assesment)
