import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import MetaTags from 'react-meta-tags';
import PatientBlock from "../Clinic/patientBlock";
import { Link, useHistory } from "react-router-dom"

import Wardnotes from '../../assets/images/Wardnotes.svg';
import theatreNotes from '../../assets/images/theatreNotes.svg';
import clinicNotes from '../../assets/images/clinicNotes.svg';
import reports from '../../assets/images/reports.svg';
import MedRecRoom from '../../assets/images/MedRecRoom.svg';
import LifetimePatientRecord from '../../assets/images/LifetimePatientRecord.svg';
import diagnosis from '../../assets/images/diagnosis.svg';



import { connect } from "react-redux"
import { getWardsList, getTheatresList, getClinicList, getPatientList, getUprofile, getDoctorList, updatePatient, getNews, updateDailyRounds, getDailyRounds } from "../../store/actions"

const WLanding = props => {
    document.body.style.overflow = '';
    let clinicPatient = JSON.parse(localStorage.getItem("p"))
    const history = useHistory();
    const btnClick = (e) => {
        e.preventDefault()
        history.push("/wlist")
        localStorage.setItem('rrd', 'wl')
    }

    useEffect(() => {
        $(".modal-backdrop").hide()
    }, [])

    return (
        <React.Fragment>

            <MetaTags>
                <title>Ward</title>
            </MetaTags>
            <div className='ward '>
                <div className="main">
                    <div className="main">

                        <div className="wrapper">
                            <div className="patient_record prd">
                                <div className="prd_top">

                                    {clinicPatient && <PatientBlock patient={clinicPatient} accordion={true} selectPatientList={true} />}

                                    {!clinicPatient && <div className="find">
                                        <button type="button" onClick={(e) => btnClick(e)} className="btn"> <span className="material-symbols-outlined icon">
                                            person_search </span> Find Patient</button>
                                    </div>}
                                    {!clinicPatient && <div className="guided_tour">
                                        <div className="guided_tour_arrow"></div>
                                        <p>Find and Add a patient to access the Ward</p>
                                        <span className="material-icons icon"> close </span>
                                    </div>}
                                    <div className="prd_btns">
                                        <div className="btns">
                                            <Link to='pinfo' className="btn">PATIENT INFO</Link>
                                            <button type="button" className="btn">HAND OVER</button>
                                        </div>
                                        <div className="btn-group">
                                            <button type="button" className="btn btn-primary">JACS</button>
                                            <button type="button" className="btn btn-primary">ICE</button>
                                            <button type="button" className="btn btn-primary">PACS</button>
                                        </div>
                                    </div>
                                </div>
                                <h2>CURRENT EPISODE</h2>
                                <ul className={!clinicPatient ? "oncall_dboard disabled" : "oncall_dboard "}>

                                    <li>
                                        <a data-bs-toggle="modal" data-bs-target="#wordnotes" className="item_card">
                                            <span className="icon"> <img src={Wardnotes} /></span>
                                            <span className="wn"> Ward Notes <span className="material-symbols-outlined icon2">
                                                keyboard_arrow_down </span></span>

                                        </a>
                                    </li>
                                    <li>
                                        <Link to='uc' className="item_card">
                                            <span className="icon"> <img src={theatreNotes} /></span>
                                            Theatre Notes
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to='uc' className="item_card">
                                            <span className="icon"> <img src={clinicNotes} /></span>
                                            Clinic Notes
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/fluid" className="item_card">
                                            <span className="icon"> <img src={reports} /></span>
                                            Reports
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to='uc' className="item_card">
                                            <span className="icon"> <img src={MedRecRoom} /></span>
                                            Medical records Room
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/lifetimerecord" className="item_card">
                                            <span className="icon"> <img src={LifetimePatientRecord} /></span>
                                            Life Time Patient Record
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal wordnotes fade" id="wordnotes" tabindex="-1" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">
                                <span className="icon"><img src={diagnosis} /></span>
                                Ward Notes
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="list_block">
                                <div className="list">
                                    <Link to="/front" className="link">Front sheet</Link>
                                </div>
                                <div className="list">
                                    <h3 className="sub_title">Admission</h3>
                                    <div className="sub_list">
                                        <Link to='uc' className="sub_link">Medical Admission</Link>
                                        <Link to="/nursingadmission" className="sub_link">Nursing Admission & Assessment</Link>
                                        <Link to="/allied" className="sub_link">Allied Health Initial</Link>
                                        <Link to="/riskassesment" className="sub_link">Assessment</Link>
                                    </div>
                                    <h3 className="sub_title">Hospital Stay</h3>
                                    <div className="sub_list">
                                        <Link to='uc' className="sub_link">E OBs</Link>
                                        <Link to="/weekendhandover" className="sub_link">Daily Summary 7 handover</Link>
                                        <Link to='uc' className="sub_link">Daily progress Notes</Link>
                                        <Link to='uc' className="sub_link">Nursing assessments</Link>
                                        <Link to='uc' className="sub_link">End of Life care & death</Link>
                                    </div>
                                </div>
                                <div className="list">
                                    <Link to="/discharge" className="link">Discharge</Link>
                                </div>
                                <div className="list">
                                    <Link to='uc' className="link">Reports</Link>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>


        </React.Fragment>
    )
}

WLanding.propTypes = {
    getWards: PropTypes.func,
    getTheatres: PropTypes.func,
    wards: PropTypes.array,
    user: PropTypes.any,
    theatres: PropTypes.array,
    clinic: PropTypes.array,
    patients: PropTypes.array,
    doctors: PropTypes.array,
    profile: PropTypes.object,
    news: PropTypes.array,
}

const mapStateToProps = ({ Layout, Login }) => ({
    wards: Layout.wards,
    user: Login.user,
    theatres: Layout.theatres,
    clinic: Layout.clinic,
    patients: Layout.patients,
    doctors: Layout.doctors,
    profile: Layout.profile,
    news: Layout.news,
    dailyrounds: Layout.dailyrounds
})

const mapDispatchToProps = dispatch => ({
    getWards: () => dispatch(getWardsList()),
    getTheatres: () => dispatch(getTheatresList()),
    getClinic: () => dispatch(getClinicList()),
    getPatient: () => dispatch(getPatientList()),
    getUserprofile: (id) => dispatch(getUprofile(id)),
    getDoctor: () => dispatch(getDoctorList()),
    patientupdate: (id, data) => dispatch(updatePatient(id, data)),
    newsList: (data) => dispatch(getNews(data)),
    dailyRoundsList: (data) => dispatch(getDailyRounds(data)),
    dailyUpdate: (data) => dispatch(updateDailyRounds(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(WLanding)
