import React, { useState, useEffect } from "react";
import styles from "./index.module.scss";
import {
  IconAngleDown,
  IconAngleUp,
  IconViewArrow,
  IconEye,
  IconGraph,
  IconAvatar,
} from "../../../icons/index.jsx";
import { progress } from "../../../../test-data/data.js";
import pdf from "../../../../assets/images/pdf.png";
// import { IconButton, Tooltip } from "@mui/material";
import axios from "../../../../configuration/axios.js";
import config from "../../../../configuration/config.js";

const Progress = () => {
  // const location = useLocation();
  const [openDiv, setOpenDiv] = useState({});
  const user = JSON.parse(localStorage.getItem("p"));
  const [result, setResult] = useState([]);
  console.log(result);
  const fetchResult = async () => {
    try {
      const response = await axios.get(config.result+"/?appname=ipr&userId="+user._id);
      console.log("response ", response)
      setResult(response?.data?.result?.resultsWithSummary);
    } catch (error) {
      console.error("Error fetching result:", error);
    }
  };
  useEffect(() => {
    fetchResult();
  }, []);

  const toggleDiv = (id) => {
    setOpenDiv((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  return (
    <div className={styles["page-container"]}>
      <div className={styles["user-container"]}>
        <div className={styles["wrapper"]}>
          <IconAvatar />
        </div>
        <div className={styles["user-details"]}>
          <span className={styles["email"]}>{user?.email}</span>
          <span className={styles["role"]}>{user?.role}</span>
        </div>
      </div>

      <div className={styles["progress-section"]}>
        {result.map((item) => (
          <div className={styles["item-wrapper"]} key={item._id}>
            <div className={styles["item-wrapper-head"]}>
              <div className={styles["item-wrapper-head-name"]}>
                {item.categoryName}
              </div>
              <div className={styles["item-wrapper-head-details"]}>
                <div className={styles["item-wrapper-head-details-name"]}>
                  {item.finishedSubCategories}/{item.totalSubCategories} &nbsp; Course in progress
                </div>
                <div className={styles["item-wrapper-head-details-name"]}>
                  {/* <Tooltip title="Watch Time" disableFocusListener> */}
                    {/* <IconButton style={{ fontSize: "15px" }}> */}
                      {/* <IconEye /> */}
                       &nbsp; {item.time}{" "}
                    {/* </IconButton> */}
                  {/* </Tooltip> */}
                </div>
                <span
                  className={styles["item-wrapper-head-details-arrow"]}
                  onClick={() => toggleDiv(item.id)}
                >
                  {openDiv[item.id] ? <IconAngleUp /> : <IconAngleDown />}
                </span>
              </div>
            </div>

            {openDiv[item.id] && (
              <div className={styles["progress-inside-section"]}>
                {item.subCategories?.map((doc) => (
                  <div className={styles["item-wrapper-inside"]} key={doc.id}>
                    <div className={styles["item-wrapper-inside-head"]}>
                      <div className={styles["item-wrapper-inside-head-name"]}>
                        <img src={pdf} alt="/" /> {doc.subCategoryName}
                      </div>
                      <div
                        className={styles["item-wrapper-inside-head-details"]}
                      >
                        <div
                          className={
                            styles["item-wrapper-inside-head-details-name"]
                          }
                        >
                          <IconGraph /> &nbsp;
                          <span>
                            {doc.result}/{doc.outOf}
                          </span>
                          <span
                            className={
                              styles["item-wrapper-inside-head-details-time"]
                            }
                          >
                            score
                          </span>
                        </div>
                      </div>
                      <span >
                        <IconViewArrow  />
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Progress;
