import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import MetaTags from 'react-meta-tags';


import PatientBlock from "../Clinic/patientBlock";
import { connect } from "react-redux"
import { getWardsList, getTheatresList, getClinicList, getPatientList, getUprofile, getDoctorList, updatePatient } from "../../store/actions"

const Smoking = props => {


    const [visiblePatients, setPatient] = useState(true)
    const [visiblelanding, setClanding] = useState(false)
    const [successmessage, setSuccess] = useState("")


    const [assesmentValues, setAssesment] = useState({})

    const {
        wards, user, theatres, clinic, patients, profile, doctors
    } = props;

    useEffect(() => {
        const { getWards, getTheatres, getClinic, getDoctor, getPatient, getUserprofile } = props
        // getWards()
        // getTheatres()
        // getClinic()
        getPatient()
        getDoctor()
        let userId = localStorage.getItem("authUser")
        getUserprofile(userId)
    }, [])

    const updateRadioValue = (e) => {
        let val = e.target.value;
        let name = e.target.name;
        let updatedValue = { [name]: val };
        setAssesment(assesmentValues => ({
            ...assesmentValues,
            ...updatedValue
        }));
    }


    const closeClick = (e) => {
        e.preventDefault()
        localStorage.removeItem("a")
        props.history.push("/riskassesment")
    }

    let assesmentPage = JSON.parse(localStorage.getItem("a"))
    let clinicPatient = JSON.parse(localStorage.getItem("p"))

    return (
        <React.Fragment>

            <MetaTags>
                <title>{assesmentPage}</title>
            </MetaTags>
            <div className="dashboard ward">
                <div className="main">

                    <div className="popup_wrp">
                        <div className="popup_full prescribe">
                            <div className="popup_hed">
                                <h2>SMOKING SCREENING </h2>
                                <div className="dsp-flex"> <a className="popup_close" onClick={(e) => closeClick(e)}><span className="material-icons">close</span></a></div>

                            </div>
                            <div className="popup_content">
                                <div className="popup_scroll w-btns main nutrition">


                                    <div className="modal add_form guidance_popup fade" id="guidance" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                        <div className="modal-dialog" role="document">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title">GUIDANCE</h5>
                                                    <span className="material-icons" data-dismiss="modal" aria-label="Close">
                                                        close
                                                    </span>
                                                </div>
                                                <div className="modal-body">
                                                    <p>Observe cannula 8 hourly or more frequently if clinically indicated
                                                    </p>
                                                    <p>Secure cannula with an appropriate dressing – change if soiled or contaminated
                                                    </p>
                                                    <p>Aseptic technique must be followed for insertion</p>
                                                    <p>Consider re-siting the cannula every 48-72 hours or as indicated by VIP score
                                                        Plan and document care
                                                    </p>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <PatientBlock patient={clinicPatient} />
                                    <div className="bar">
                                        <div className="left">
                                            <div className="tag" data-toggle="modal"
                                                data-target="#guidance"> Guidance </div>
                                            <h3 className="sub_title">SMOKING SCREENING </h3>
                                        </div>
                                        <div className="right">
                                            <a href="#" className="btn"> <span className="material-symbols-outlined"> assignment </span> Not
                                                Yet Done</a>
                                            <div className="tag"> Due </div>
                                        </div>
                                    </div>
                                    <div className="section ctr pua poc height">

                                        <div className="sub_section">

                                            <div className="check-box mb-0 mt-10">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-200" />
                                                    <label for="checkbox-200"></label>
                                                </div>
                                                Patient is
                                                Non-smoker / Ex-smoker(Quit more than 6 weeks ago)

                                            </div>
                                            <div className="check-box mb-0 mt-10">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-210" />
                                                    <label for="checkbox-210"></label>
                                                </div>
                                                No further Action necessary.
                                            </div>
                                        </div>

                                        <div className="sub_section">
                                            <div className="row">
                                                <div className="col-md-8">
                                                    <p className="field_title mb-0 mt-0 dsp-flex align-item-center">Patient is
                                                        Current smoker and smokes per day
                                                        <input type="text" className="form-control patient" placeholder="Enter" />
                                                    </p>
                                                </div>
                                                <div className="col-md-8">
                                                    <p className="field_title mb-0 dsp-flex align-item-center">for years
                                                        <input type="text" className="form-control patient" placeholder="Enter" />
                                                    </p>
                                                    <p className="ps1 mb-20">Stop smoking advice given</p>
                                                </div>
                                            </div>
                                            <a href="#" className="link_btn">Smoking Advice <span className="material-icons icon">
                                                arrow_right </span></a>
                                        </div>

                                        <div className="sub_section">
                                            <div className="check-box mb-0 mt-10">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-201" />
                                                    <label for="checkbox-201"></label>
                                                </div>
                                                Patient is interested in stopping smoking:

                                            </div>
                                            <p className="field_title mt-20">Referral to smoking cessation service </p>
                                            <div className="check_item mt-0 mb_10">
                                                <div className="select_btns popup_select border-0 pb-0">
                                                    <div className="select_btn"><label><input name="site" type="radio"
                                                        value="Site 1" /><span>offered
                                                        </span></label></div>
                                                    <div className="select_btn"><label><input name="site" type="radio"
                                                        value="Site 2" /><span> Accepted </span></label></div>
                                                    <div className="select_btn"><label><input name="site" type="radio"
                                                        value="Site 2" /><span> Referred </span></label></div>

                                                </div>
                                            </div>
                                            <a href="#" className="link_btn">Referral to smoking cessation service <span
                                                className="material-icons icon"> arrow_right </span></a>
                                        </div>

                                        <div className="sub_section">
                                            <div className="check-box mb-0 mt-10">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-203" />
                                                    <label for="checkbox-203"></label>
                                                </div>
                                                Stop smoking medication offered whilst in hospital
                                                Accepted
                                            </div>
                                            <div className="check-box mb-0 mt-15">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-204" />
                                                    <label for="checkbox-204"></label>
                                                </div>
                                                Prescribed for up to 2 weeks after discharge
                                                Not Accepted

                                            </div>
                                            <div className="check-box mb-15 mt-15">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-205" />
                                                    <label for="checkbox-205"></label>
                                                </div>
                                                No further action

                                            </div>

                                            <a href="#" className="link_btn">Notify medic to prescribe “Stop Smoking medications” for up
                                                to 2 weeks after discharge <span className="material-icons icon"> arrow_right
                                                </span></a>
                                        </div>

                                        <div className="sub_section">
                                            <div className="check-box mb-0 mt-10">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-206" />
                                                    <label for="checkbox-206"></label>
                                                </div>
                                                Patient does not want to stop smoking
                                            </div>
                                            <div className="check-box mb-15 mt-15">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-207" />
                                                    <label for="checkbox-207"></label>
                                                </div>
                                                Advised on hospital smoke free policy
                                            </div>


                                            <a href="#" className="link_btn">Hospital smoke free policy to patient <span
                                                className="material-icons icon"> arrow_right </span></a>
                                        </div>


                                    </div>
                                </div>

                            </div>
                            <div className="popup_footer">
                                <div className="prescribe_footer">
                                    <div className="dsp-flex">
                                        <a className="icon_btn"> <span className="material-icons">save</span> SAVE</a>
                                        <button className="border_btn"><span className="material-icons">save_as</span>SAVE &amp;
                                            CLOSE
                                        </button>
                                    </div>
                                    <button className="btn dsp_flex"><span className="material-icons"><span
                                        className="material-icons">close</span></span>DISCARD </button>
                                </div>
                            </div>
                        </div>
                    </div>





                </div>
            </div>




        </React.Fragment>
    )
}

Smoking.propTypes = {
    t: PropTypes.any,
    getWards: PropTypes.func,
    getTheatres: PropTypes.func,
    wards: PropTypes.array,
    user: PropTypes.any,
    theatres: PropTypes.array,
    clinic: PropTypes.array,
    patients: PropTypes.array,
    doctors: PropTypes.array,
    profile: PropTypes.object
}

const mapStateToProps = ({ Layout, Login }) => ({
    wards: Layout.wards,
    user: Login.user,
    theatres: Layout.theatres,
    clinic: Layout.clinic,
    patients: Layout.patients,
    doctors: Layout.doctors,
    profile: Layout.profile
})

const mapDispatchToProps = dispatch => ({
    getWards: () => dispatch(getWardsList()),
    getTheatres: () => dispatch(getTheatresList()),
    getClinic: () => dispatch(getClinicList()),
    getPatient: () => dispatch(getPatientList()),
    getUserprofile: (id) => dispatch(getUprofile(id)),
    getDoctor: () => dispatch(getDoctorList()),
    patientupdate: (id, data) => dispatch(updatePatient(id, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Smoking)
