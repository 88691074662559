import React, { useState, useEffect } from "react"
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types'
import Moment from 'moment';
import { connect } from "react-redux"
import logoDark from '../../assets/images/mainLogo.svg';
import ProfilePic from '../../assets/images/profile_pic.svg';
import noProfilePic from '../../assets/images/no-user.svg';
import mailPic from '../../assets/images/mail.svg';
import Leaf from '../../assets/images/leaf.png';
import { Link } from "react-router-dom"
//i18n
import { withTranslation } from "react-i18next"
import { logoutUser, getUprofile } from "../../store/actions"
// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
} from "../../store/actions"

const Header = (props) => {
  const [search, setsearch] = useState(false)
  const [singlebtn, setSinglebtn] = useState(false)

  const {
    profile
  } = props;

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
  const history = useHistory();

  useEffect(() => {
    const { getUprofile } = props
    let userId = localStorage.getItem("authUser")
    getUprofile(userId)

  }, [])

  useEffect(() => {

  }, [profile])

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        )
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }

  function tToggle() {
    var body = document.body;
    if (window.screen.width <= 992) {
      body.classList.toggle("sidebar-enable");
    } else {
      body.classList.toggle("vertical-collpsed");
      body.classList.toggle("sidebar-enable");
    }
  }

  const handleLogout = (e) => {
    props.logoutUser(history)
  }

  const btnClick = (e) => {
    e.preventDefault()
    localStorage.removeItem("pt")
    localStorage.removeItem("p")
    history.push("/dashboard")
  }

  const backClick = (e) => {
    e.preventDefault()
    history.goBack()
  }

  const time = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })
  const formatDate = Moment().format('MMM DD, YYYY')

  let ptValue = localStorage.getItem("pt")

  let locationValue = ""
  let specialityValue = ""

  // profile?.speciality && profile?.speciality?.label.substr(0, 5)


  if (ptValue && ptValue.toLowerCase().replaceAll(' ', '') == "ward") {
    locationValue = profile?.ward && profile?.ward?.label
    specialityValue = profile?.speciality && profile?.speciality?.label
  } else if (
    (ptValue && ptValue.toLowerCase().replaceAll(' ', '') == "oncall") ||
    (ptValue && ptValue.toLowerCase().replaceAll(' ', '') == "tea-mroom")
  ) {
    locationValue = profile?.oncall && profile?.oncall?.label
    specialityValue = profile?.default_hospital && profile?.default_hospital?.label
  } else if (ptValue && ptValue.toLowerCase().replaceAll(' ', '') == "outpatientclinic&telemedicine") {
    locationValue = profile?.clinic && profile?.clinic?.label
    specialityValue = profile?.speciality && profile?.speciality?.label
  } else if (ptValue && ptValue.toLowerCase().replaceAll(' ', '') == "theatre") {
    locationValue = profile?.theatre && profile?.theatre?.label
    specialityValue = profile?.speciality && profile?.speciality?.label
  } else if (
    (ptValue && ptValue.toLowerCase().replaceAll(' ', '') == "nursingoffice") ||
    (ptValue && ptValue.toLowerCase().replaceAll(' ', '') == "operations") ||
    (ptValue && ptValue.toLowerCase().replaceAll(' ', '') == "businessoffice") ||
    (ptValue && ptValue.toLowerCase().replaceAll(' ', '') == "adminoffice") ||
    (ptValue && ptValue.toLowerCase().replaceAll(' ', '') == "myoffice") ||
    (ptValue && ptValue.toLowerCase().replaceAll(' ', '') == "patientportal") ||
    (ptValue && ptValue.toLowerCase().replaceAll(' ', '') == "cqms") ||
    (ptValue && ptValue.toLowerCase().replaceAll(' ', '') == "patientrecord")
  ) {
    locationValue = profile?.nursing && profile?.nursing?.label
    specialityValue = profile?.default_hospital && profile?.default_hospital?.label
  }




  return (
    <React.Fragment>
      <div className="dashboard ward">
        <div className="header">
          <div className="wrapper wrp">
            {/*<div className="logo"> <Link to="/dashboard"><img src={logoDark} /></Link></div>
             <a href="#" className="page_back">
              <div className="dcrp">DRI - TR &amp; Ortho</div>
              <div className="title">Ward 1<span className="material-icons">arrow_left</span></div>
            </a>*/}
            <div className="hed_left">
              <div className="logo" onClick={(e) => btnClick(e)}> <img src={logoDark} /></div>
              <h3 className="hed_title2">{ptValue && ptValue.toUpperCase()}</h3>
            </div>

            <div className="header_right">
              {locationValue != "" &&
                <div className="select_ward" >
                  <h3>{specialityValue}</h3>
                  <p>
                    {locationValue}

                    <span className="icon material-symbols-outlined"> keyboard_arrow_down </span>
                  </p>
                </div>}


              {/* <Link to="/myoffice" className="setting">
                    <span className="material-symbols-outlined">  settings</span>
                  </Link>
                <span className="profile_pic"> <img src={ProfilePic} /></span> */}
              <span className="notifications">
                <img className="icon" src={mailPic} />
                <span className="num">2</span>
              </span>
              <div className="dropdown profile">
                <span id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <span className="profile_pic">
                    <img src={ProfilePic} />
                    <span className="material-symbols-outlined icon"> arrow_drop_down </span>
                  </span>
                </span>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <div className="prof_info">
                    <img src={noProfilePic} className="icon" />
                    <h3 className="name">{profile && profile.firstname} {profile && profile.lastname} </h3>
                    <span className="badge">Sr Doctor</span>
                    <p className="sub">DIABETES & ENDOCRINOLOGY</p>
                  </div>
                  <Link to="/myoffice" className="dropdown-item"> <span className="material-symbols-outlined icon"> tune </span> MY Preferences</Link>
                  <a className="dropdown-item" href="#"><span className="material-symbols-outlined icon"> support </span>IPR Support</a>
                  <a className="dropdown-item" href="#"><span className="material-symbols-outlined icon"> import_contacts </span>Tutorials</a>
                  <a className="dropdown-item" href="#"> <span className="material-symbols-outlined icon"> not_started </span> Getting Started</a>
                  <a className="dropdown-item" href="#"> <span className="material-symbols-outlined icon"> quiz </span> FAQ</a>
                  <a className="dropdown-item logout" onClick={(e) => handleLogout()} > <span className="material-symbols-outlined icon"> power_settings_new </span> Logout</a>
                </div>
              </div>
              <span className="hed_time"> <strong> {time}, </strong> <br />
                {formatDate}</span>

            </div>
          </div>
        </div>

        {ptValue &&
          <div className="back_strip">
            <div className="wrapper">
              <span className="back" onClick={(e) => backClick(e)}> <span className="material-symbols-outlined icon"> chevron_left </span> Back</span>
              <h3>Keep Smiling and make others smile too. Have a nice day!</h3>
              <div className="leafe"><img src={Leaf} /></div>
            </div>
          </div>}

        <div className="modal wordnotes ward_list fade" id="wardList" tabIndex="-1" aria-labelledby="exampleModalLabel"
          aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  Select Site Location
                </h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body main">
                <h3>Location</h3>
                <div className="select_box">
                  <label className="label">Preferred Speciality</label>
                  <br />
                  {profile?.speciality && profile?.speciality?.label}

                </div>
                <div className="ward_list_items">
                  <div className="check_item mt-0 mb-10">

                    <div className="select_btns popup_select">
                      <div className="select_btn"><label><input name="site" type="radio"
                        value="Site 1" /><span>Ward 01 </span></label></div>
                      <div className="select_btn"><label><input name="site" type="radio"
                        value="Site 2" /><span>Ward 02 </span></label></div>
                      <div className="select_btn"><label><input name="site" type="radio"
                        value="Site 2" /><span>Ward 03 </span></label></div>
                      <div className="select_btn"><label><input name="site" type="radio"
                        value="Site 2" /><span>Ward 04 </span></label></div>
                      <div className="select_btn"><label><input name="site" type="radio"
                        value="Site 2" /><span>Ward 05 </span></label></div>
                      <div className="select_btn"><label><input name="site" type="radio"
                        value="Site 1" /><span>Ward 06 </span></label></div>
                      <div className="select_btn"><label><input name="site" type="radio"
                        value="Site 2" /><span>Ward 07 </span></label></div>
                      <div className="select_btn"><label><input name="site" type="radio"
                        value="Site 2" /><span>Ward 08 </span></label></div>
                      <div className="select_btn"><label><input name="site" type="radio"
                        value="Site 2" /><span>Ward 09 </span></label></div>
                      <div className="select_btn"><label><input name="site" type="radio"
                        value="Site 2" /><span>Ward 10 </span></label></div>
                      <div className="select_btn"><label><input name="site" type="radio"
                        value="Site 1" /><span>Ward 11 </span></label></div>
                      <div className="select_btn"><label><input name="site" type="radio"
                        value="Site 2" /><span>Ward 12 </span></label></div>
                      <div className="select_btn"><label><input name="site" type="radio"
                        value="Site 2" /><span>Ward 13 </span></label></div>
                      <div className="select_btn"><label><input name="site" type="radio"
                        value="Site 2" /><span>Ward 14 </span></label></div>
                      <div className="select_btn"><label><input name="site" type="radio"
                        value="Site 2" /><span>Ward 15 </span></label></div>
                      <div className="select_btn"><label><input name="site" type="radio"
                        value="Site 1" /><span>Ward 16 </span></label></div>
                      <div className="select_btn"><label><input name="site" type="radio"
                        value="Site 2" /><span>Ward 17 </span></label></div>
                      <div className="select_btn"><label><input name="site" type="radio"
                        value="Site 2" /><span>Ward 18 </span></label></div>
                      <div className="select_btn"><label><input name="site" type="radio"
                        value="Site 2" /><span>Ward 19 </span></label></div>
                      <div className="select_btn"><label><input name="site" type="radio"
                        value="Site 2" /><span>Ward 20 </span></label></div>
                      <div className="select_btn"><label><input name="site" type="radio"
                        value="Site 1" /><span>Ward 21 </span></label></div>
                      <div className="select_btn"><label><input name="site" type="radio"
                        value="Site 2" /><span>Ward 22 </span></label></div>
                      <div className="select_btn"><label><input name="site" type="radio"
                        value="Site 2" /><span>Ward 23 </span></label></div>
                      <div className="select_btn"><label><input name="site" type="radio"
                        value="Site 2" /><span>Ward 24 </span></label></div>
                      <div className="select_btn"><label><input name="site" type="radio"
                        value="Site 2" /><span>Ward 25 </span></label></div>
                      <div className="select_btn"><label><input name="site" type="radio"
                        value="Site 1" /><span>Ward 26 </span></label></div>
                      <div className="select_btn"><label><input name="site" type="radio"
                        value="Site 2" /><span>Ward 27 </span></label></div>
                      <div className="select_btn"><label><input name="site" type="radio"
                        value="Site 2" /><span>Ward 28 </span></label></div>
                      <div className="select_btn"><label><input name="site" type="radio"
                        value="Site 2" /><span>Ward 29 </span></label></div>
                      <div className="select_btn"><label><input name="site" type="radio"
                        value="Site 2" /><span>Ward 30 </span></label></div>
                      <div className="select_btn"><label><input name="site" type="radio"
                        value="Site 1" /><span>Ward 31 </span></label></div>
                      <div className="select_btn"><label><input name="site" type="radio"
                        value="Site 2" /><span>Ward 32 </span></label></div>
                      <div className="select_btn"><label><input name="site" type="radio"
                        value="Site 2" /><span>Ward 33 </span></label></div>
                      <div className="select_btn"><label><input name="site" type="radio"
                        value="Site 2" /><span>Ward 34 </span></label></div>
                      <div className="select_btn"><label><input name="site" type="radio"
                        value="Site 2" /><span>Ward 35 </span></label></div>
                      <div className="select_btn"><label><input name="site" type="radio"
                        value="Site 1" /><span>Ward 36 </span></label></div>
                      <div className="select_btn"><label><input name="site" type="radio"
                        value="Site 2" /><span>Ward 37 </span></label></div>
                      <div className="select_btn"><label><input name="site" type="radio"
                        value="Site 2" /><span>Ward 38 </span></label></div>
                      <div className="select_btn"><label><input name="site" type="radio"
                        value="Site 2" /><span>Ward 39 </span></label></div>
                      <div className="select_btn"><label><input name="site" type="radio"
                        value="Site 2" /><span>Ward 40 </span></label></div>
                      <div className="select_btn"><label><input name="site" type="radio"
                        value="Site 1" /><span>Ward 41 </span></label></div>
                      <div className="select_btn"><label><input name="site" type="radio"
                        value="Site 2" /><span>Ward 42 </span></label></div>
                      <div className="select_btn"><label><input name="site" type="radio"
                        value="Site 2" /><span>Ward 43 </span></label></div>
                      <div className="select_btn"><label><input name="site" type="radio"
                        value="Site 2" /><span>Ward 44 </span></label></div>
                      <div className="select_btn"><label><input name="site" type="radio"
                        value="Site 2" /><span>Ward 45 </span></label></div>
                      <div className="select_btn"><label><input name="site" type="radio"
                        value="Site 1" /><span>Ward 46 </span></label></div>
                      <div className="select_btn"><label><input name="site" type="radio"
                        value="Site 2" /><span>Ward 47 </span></label></div>
                      <div className="select_btn"><label><input name="site" type="radio"
                        value="Site 2" /><span>Ward 48 </span></label></div>
                      <div className="select_btn"><label><input name="site" type="radio"
                        value="Site 2" /><span>Ward 49 </span></label></div>
                      <div className="select_btn"><label><input name="site" type="radio"
                        value="Site 2" /><span>Ward 50 </span></label></div>

                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
  history: PropTypes.object,
  profile: PropTypes.object
}

const mapStatetoProps = state => {
  const {
    layoutType,
    showRightSidebar,
    leftMenu,
    leftSideBarType,
    error,
    profile
  } = state.Layout
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType, error, profile }
}


export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
  logoutUser,
  getUprofile
})(withTranslation()(Header))
