import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import MetaTags from 'react-meta-tags';
import {
    Modal
} from "reactstrap"
import { connect } from "react-redux"
import { getWardsList, getTheatresList, getClinicList, getPatientList, getUprofile, getDoctorList, teamListUpdate } from "../../store/actions"

const TheTeam = props => {
    document.body.style.overflow = '';
    document.body.style.padding = '';
    const [success_msg, setsuccess_msg] = useState(false)
    const [assesmentValues, setAssesment] = useState({})

    const {
        wards, user, theatres, clinic, patients, profile, doctors
    } = props;

    useEffect(() => {
        const {  getDoctor, getPatient, getUserprofile } = props
        getPatient()
        getDoctor()
        let userId = localStorage.getItem("authUser")
        getUserprofile(userId)
    }, [])

    const updateInputValue = (e) => {
        let val = e.target.value;
        let name = e.target.name;
        let updatedValue = {};
        updatedValue = { [name]: val };
        setAssesment(assesmentValues => ({
            ...assesmentValues,
            ...updatedValue
        }));
        
    }

    const teamUpdate = (e) => {
        const { teamupdatelist } = props
        let clinicPatient = JSON.parse(localStorage.getItem("p"))
        let id = clinicPatient.patientId
        let bodyObj = {
            obj: assesmentValues,
            query: { "patientId": id },
            method: "Update"
        }
        teamupdatelist(bodyObj, "team")
        setsuccess_msg(!success_msg)

    }


    return (
        <React.Fragment>

            <MetaTags>
                <title>The Team</title>
            </MetaTags>
            <div className="dashboard ward">
                <div className="main na_section oncall">
                    <div className="wrapper">

                        <div className="step_block">

                            <div className="oncall_wrp">
                                <h2> <a href="#" className="oncall_back"><span className="material-icons icon"> arrow_back </span></a>
                                    THE TEAM</h2>
                                <div className="the_team">
                                    <div className="time_block">
                                        <div className="from">
                                            <p><span className="material-icons icon"> arrow_outward </span>From</p>
                                            <h3>08:00 <span>AM</span> &nbsp;&nbsp; <input type="date" onChange={(e) => updateInputValue(e)} name="onCall_fromdate" className="form-control" />
                                            
                                            </h3>
                                        </div>
                                        <div className="to">
                                            <p><span className="material-icons icon"> arrow_outward </span>To</p>
                                            <h3>08:00 <span>AM</span> &nbsp;&nbsp; <input type="date" onChange={(e) => updateInputValue(e)} name="onCall_todate" className="form-control" /></h3>
                                        </div>
                                    </div>
                                    <div className="consultant">
                                        <div className="form-group">
                                            <label>On-Call Consultant</label>
                                            <input type="text" onChange={(e) => updateInputValue(e)} name="onCall_Consultant" className="form-control" />
                                            <span className="material-symbols-outlined icon"> stethoscope </span>
                                        </div>
                                        <div className="form-group">
                                            <label>Registrar</label>
                                            <input type="text" onChange={(e) => updateInputValue(e)} name="onCall_Registrar" className="form-control" />
                                            <span className="material-symbols-outlined icon"> stethoscope </span>
                                        </div>
                                        <div className="form-group">
                                            <label>Junior Doctor Day</label>
                                            <input type="text" onChange={(e) => updateInputValue(e)} name="onCall_Jr_dr_day" className="form-control" />
                                            <span className="material-symbols-outlined icon"> clear_day </span>
                                        </div>
                                        <div className="form-group">
                                            <label>Junior Doctor Night</label>
                                            <input type="text" onChange={(e) => updateInputValue(e)} name="onCall_Jr_dr_night" className="form-control" />
                                            <span className="material-symbols-outlined icon"> bedtime </span>
                                        </div>
                                        <button type="button" className="call_btn" onClick={(e) => { teamUpdate(e)}} >Start On Call Shift <span className="material-symbols-outlined icon"> chevron_right </span></button>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <Modal
                            isOpen={success_msg}
                            toggle={() => {
                                setsuccess_msg(false)
                            }}
                            centered={true}
                        >
                            <div className="modal-header">
                                <h5 className="modal-title mt-0"></h5>
                                <button
                                    type="button"
                                    onClick={() => {
                                        setsuccess_msg(false)
                                    }}
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <h1>Updated Successfully</h1>
                            </div>
                        </Modal>



                    </div>

                </div>
            </div>




        </React.Fragment>
    )
}

TheTeam.propTypes = {
    t: PropTypes.any,
    getWards: PropTypes.func,
    getTheatres: PropTypes.func,
    wards: PropTypes.array,
    user: PropTypes.any,
    theatres: PropTypes.array,
    clinic: PropTypes.array,
    patients: PropTypes.array,
    doctors: PropTypes.array,
    profile: PropTypes.object
}

const mapStateToProps = ({ Layout, Login }) => ({
    wards: Layout.wards,
    user: Login.user,
    theatres: Layout.theatres,
    clinic: Layout.clinic,
    patients: Layout.patients,
    doctors: Layout.doctors,
    profile: Layout.profile
})

const mapDispatchToProps = dispatch => ({
    getWards: () => dispatch(getWardsList()),
    getTheatres: () => dispatch(getTheatresList()),
    getClinic: () => dispatch(getClinicList()),
    getPatient: () => dispatch(getPatientList()),
    getUserprofile: (id) => dispatch(getUprofile(id)),
    getDoctor: () => dispatch(getDoctorList()),
    teamupdatelist: (data, tablename) => dispatch(teamListUpdate(data, tablename))
})

export default connect(mapStateToProps, mapDispatchToProps)(TheTeam)
