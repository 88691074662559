import React, { useState, useCallback } from 'react'
import Cropper from 'react-easy-crop'
import getCroppedImg from './crop'
import { removeSpecialCharacters } from '../../utils/utils';
import { Buffer } from 'buffer';
const dogImg =
  'https://img.huffingtonpost.com/asset/5ab4d4ac2000007d06eb2c56.jpeg?cache=sih0jwle4e&ops=1910_1000'


const CropperImage = (props) => {
  
  
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [rotation, setRotation] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [croppedImage, setCroppedImage] = useState(null)
  const [file, setFile] = useState({});

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        dogImg,
        croppedAreaPixels,
        rotation
      )
      console.log('donee', { croppedImage })
      sigUpload(croppedImage, "xrayfile")
      setCroppedImage(croppedImage)


      
    } catch (e) {
      console.error(e)
    }
  }, [croppedAreaPixels, rotation])

  const sigUpload = async (filedata, name) => {
    const file = filedata
    //console.log("file ", file, name)
    let filename = name+".png";
    // const base64 = await convertBase64(file)
    //console.log("filename ", filename)
    let pdetails = JSON.parse(localStorage.getItem("p"))

    const dataf = file.replace(/^data:image\/\w+;base64,/, "");

    const buf = Buffer.from(dataf, "base64");
    let uploadFilePath = "ipr/" + pdetails._id + "/" + name + "_result" + "/" + removeSpecialCharacters(filename);
    let data = { source_bucket: "ipr-resources", sourcepath: uploadFilePath }
   // console.log("data ", data)

    const response = await fetch(API_URL+'upload?appname=ipr', {
        method: "POST",
        mode: "cors",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    const result = await response.json();
   // console.log("result ", result, buf.length)
    let url = result.result
   // console.log("url ", url)
    const base64Data = new Buffer.from(filedata.replace(/^data:image\/\w+;base64,/, ""), 'base64');
    // Getting the file type, ie: jpeg, png or gif
    const type = filedata.split(';')[0].split('/')[1];
    const uploadImage = await fetch(url, {
        method: "PUT",
        mode: "cors",
        headers: {
           'Content-Type':`image/${type}`,
            'Content-Encoding': 'base64',
            ACL: 'public-read',
        },
        body: base64Data
    })

    //console.log("resultImage ", uploadImage)

    let imagePath = "https://ipr-resources.s3.amazonaws.com/"
    let imageUploadPath = imagePath + uploadFilePath;

    let updateObj = {
        [name + "_result"]: imageUploadPath,
        [name + "_update"]: new Date()
    }

    setFile(file => ({
        ...file,
        ...updateObj
    }));


  }

 

//console.log("viewImage ", viewImage)
  return (
    <div className='ward '>
    <div className="main">
        <div className="wrapper">
        <div className="cropContainer">
        <Cropper
          image={dogImg}
          crop={crop}
          rotation={rotation}
          zoom={zoom}
          aspect={4 / 3}
          onCropChange={setCrop}
          onRotationChange={setRotation}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
 </div>

 <button onClick={showCroppedImage} className='btn btn-primary' >
          Show Result
        </button>

        <img src={croppedImage} alt="Cropped" />
    </div>
    </div>
    </div>
  );
}

export default CropperImage;