// navigationHandler.js
let navigate = null;

export const setNavigate = (navigateFunction) => {
  navigate = navigateFunction;
};

export const handleNavigation = (status) => {
  if (!navigate) {
    console.error("Navigate function is not set");
    return;
  }

  if (status === 403) {
   // navigate('/forbidden');
  } else if (status === 401) {
    //navigate('/forbidden');
  }
};
