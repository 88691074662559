import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import MetaTags from 'react-meta-tags';


import PatientBlock from "../Clinic/patientBlock";
import { connect } from "react-redux"
import { getWardsList, getTheatresList, getClinicList, getPatientList, getUprofile, getDoctorList, updatePatient } from "../../store/actions"

const Nutrition = props => {


    const [visiblePatients, setPatient] = useState(true)
    const [visiblelanding, setClanding] = useState(false)
    const [successmessage, setSuccess] = useState("")
    const [heightprops, setHeightProps] = useState("Imperial")
    const [bmiheightprops, setBMIHeightProps] = useState("Imperial")
    const [nutritionValues, setNutrition] = useState({
        weight_planned: false,
        acutely_ill: false
    })



    const [assesmentValues, setAssesment] = useState({})

    const {
        wards, user, theatres, clinic, patients, profile, doctors
    } = props;

    useEffect(() => {
        const { getWards, getTheatres, getClinic, getDoctor, getPatient, getUserprofile } = props
        // getWards()
        // getTheatres()
        // getClinic()
        getPatient()
        getDoctor()
        let userId = localStorage.getItem("authUser")
        getUserprofile(userId)
    }, [])

    const updateInputValue = (e) => {
        let val = e.target.value;
        let name = e.target.name;
        let updatedValue = {};
        updatedValue = { [name]: val };
        setNutrition(nutritionValues => ({
            ...nutritionValues,
            ...updatedValue
        }));
    }

    const updatenutValues = (e, name, val) => {
        e.preventDefault()
        let updatedValue = {};
        updatedValue = { [name]: val };
        setNutrition(nutritionValues => ({
            ...nutritionValues,
            ...updatedValue
        }));
    }

    const handleChange = (event) => {
        updatenutValues(event, event.target.name, event.target.checked)
    }

    const closeClick = (e) => {
        e.preventDefault()
        localStorage.removeItem("a")
        props.history.push("/riskassesment")
    }

    const showResults = (e) => {
        e.preventDefault()
        console.log("nutritionValues", nutritionValues)
        let weighLossPercentage = nutritionValues["6months_weight"] / nutritionValues["weight"] * 100
        let bmiResult = nutritionValues["bmi_weight"] / nutritionValues["bmi_height"]

        console.log("weighLossPercentage ", weighLossPercentage)
        console.log("bmiResult ", bmiResult)
        let riskscoreValue = 0
        if(weighLossPercentage < 5){
            riskscoreValue = riskscoreValue + 0
        }else if(weighLossPercentage > 5 || weighLossPercentage < 10){
            riskscoreValue = riskscoreValue + 1
        }else if(weighLossPercentage > 10){
            riskscoreValue = riskscoreValue + 2
        }
        if(nutritionValues["acutely_ill"] == true){
            riskscoreValue = riskscoreValue + 2
        }

        if(nutritionValues["estimated_bmi"]){
            bmiResult = nutritionValues["estimated_bmi"]
        }

        if(bmiResult > 20){
            riskscoreValue = riskscoreValue + 0
        }else if(bmiResult > 18.5 || bmiResult < 20){
            riskscoreValue = riskscoreValue + 1
        }else if(bmiResult < 18.5){
            riskscoreValue = riskscoreValue + 2
        }

        
        let updatedValue = {};
        updatedValue = { bmiResult, weighLossPercentage, riskscore: riskscoreValue };


        setNutrition(nutritionValues => ({
            ...nutritionValues,
            ...updatedValue
        }));

      

    }

    let assesmentPage = JSON.parse(localStorage.getItem("a"))
    let clinicPatient = JSON.parse(localStorage.getItem("p"))

    console.log("nutritionValues", nutritionValues)

    return (
        <React.Fragment>

            <MetaTags>
                <title>{assesmentPage}</title>
            </MetaTags>
            <div className="dashboard ward">
                <div className="main">

                    <div className="popup_wrp">
                        <div className="popup_full prescribe nwm">
                            <div className="popup_hed">
                                <h2>Nutrition ( Weight & MUST) </h2>
                                <div className="dsp-flex"> <a className="popup_close" onClick={(e) => closeClick(e)}><span className="material-icons">close</span></a></div>

                            </div>
                            <div className="popup_content">
                                <div className="popup_scroll w-btns main nutrition">


                                    <div className="modal add_form guidance_popup fade" id="guidance" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                        <div className="modal-dialog" role="document">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title">GUIDANCE</h5>
                                                    <span className="material-icons" data-dismiss="modal" aria-label="Close">
                                                        close
                                                    </span>
                                                </div>
                                                <div className="modal-body">
                                                    <p>Observe cannula 8 hourly or more frequently if clinically indicated
                                                    </p>
                                                    <p>Secure cannula with an appropriate dressing – change if soiled or contaminated
                                                    </p>
                                                    <p>Aseptic technique must be followed for insertion</p>
                                                    <p>Consider re-siting the cannula every 48-72 hours or as indicated by VIP score
                                                        Plan and document care
                                                    </p>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <PatientBlock patient={clinicPatient} />
                                    <div className="bar">
                                        <div className="left">
                                            <div className="tag" data-toggle="modal"
                                                data-target="#guidance"> Guidance </div>
                                            <h3 className="sub_title">Nutrition ( Weight & MUST) </h3>
                                        </div>
                                        <div className="right">
                                            <a href="#" className="btn"> <span className="material-symbols-outlined"> assignment </span> Not
                                                Yet Done</a>
                                            <div className="tag"> Due </div>
                                        </div>
                                    </div>

                                    <p className="field_title bold ">Complete all relevant fields and click 'Show Results'</p>

                                    
                                    <div className="select_btns">
                                    {["Imperial", "Metric" ].map((num) => (
                                         
                                    <div className="select_btn"><label>
                                    <input
                                        defaultChecked={num === heightprops}  // returns bool, only true for first input
                                        type="radio"
                                        name="propImperial"
                                        value={num}
                                        onChange={(e) => setHeightProps(e.target.value)}
                                    /><span>{num} </span></label></div>
                                    ))}
                                    </div>

                                   

                                    <div className="section">
                                        <div className="row align_center">
                                            <div className="col-md-6">
                                                <div className="icon_text"><span className="material-symbols-outlined icon"> scale
                                                </span>Current Weight</div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="swich_block">
                                                    <div className="select_box">
                                                        <input type="text" name="weight" onChange={(e) => updateInputValue(e)}  className="form-control" placeholder="0.0" />
                                                        <select className="select">
                                                            {heightprops == "Imperial" ?
                                                            ["Lb", "St" ].map((item) => (
                                                            <option>{item}</option>
                                                            ))
                                                            :
                                                             ["Kgs" ].map((item) => (
                                                            <option>{item}</option>
                                                            ))
                                                             }
                                                           
                                                        </select>
                                                    </div>
                                                   
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="section">
                                        <div className="row align_center">
                                            <div className="col-md-6">
                                                <div className="icon_text"><span className="material-symbols-outlined icon rt"> straighten
                                                </span>Current Height</div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="swich_block">
                                                    <div className="select_box">
                                                        <input type="text" name="height" onChange={(e) => updateInputValue(e)} className="form-control" placeholder="0.0" />
                                                        <select className="select">
                                                        {heightprops == "Imperial" ?
                                                            ["Ft", "In" ].map((item) => (
                                                            <option>{item}</option>
                                                            ))
                                                            :
                                                             ["Cms" ].map((item) => (
                                                            <option>{item}</option>
                                                            ))
                                                             }

                                                         
                                                        </select>
                                                    </div>
                                                    
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="section">
                                        <div className="row align_center">
                                            <div className="col-md-6">
                                                <div className="icon_text"><span className="material-symbols-outlined icon"> scale
                                                </span>Weight 3 to 6 months ago</div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="swich_block">
                                                    <div className="select_box">
                                                        <input type="text" name="6months_weight" onChange={(e) => updateInputValue(e)} className="form-control" placeholder="0.0" />
                                                        <select className="select">
                                                            {heightprops == "Imperial" ?
                                                            ["Lb", "St" ].map((item) => (
                                                            <option>{item}</option>
                                                            ))
                                                            :
                                                             ["Kgs" ].map((item) => (
                                                            <option>{item}</option>
                                                            ))
                                                             }
                                                        </select>
                                                    </div>
                                                    
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="section">
                                        <div className="row align_center">
                                            <div className="col-md-6">
                                                <div className="icon_text">Was the weight loss planned</div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="yn_switch mb-0 mt-0 ">

                                                    <label htmlFor='weight_planned' className="switch-container ml_0">
                                                        <input onChange={(e) => handleChange(e)}  name="weight_planned" id="weight_planned" type="checkbox" className="switch_check"  />
                                                        <div className="switch-bg"></div>
                                                        <div className="round-box"></div>
                                                        <div className="switch-left">
                                                            <span>YES</span>
                                                        </div>
                                                        <div className="switch-right">
                                                            <span>NO</span>
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="section">
                                        <div className="row align_center">
                                            <div className="col-md-6">
                                                <div className="icon_text">Acutely Ill</div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="yn_switch mb-0 mt-0 ">

                                                    <label htmlFor='acutely_ill' className="switch-container ml_0">
                                                        <input  onChange={(e) => handleChange(e)} name="acutely_ill" type="checkbox" className="switch_check" id="acutely_ill"  />
                                                        <div className="switch-bg"></div>
                                                        <div className="round-box"></div>
                                                        <div className="switch-left">
                                                            <span>YES</span>
                                                        </div>
                                                        <div className="switch-right">
                                                            <span>NO</span>
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <h3 className="bg_title text_center mt-30 mb-30">BMI Calculator</h3>
                                    
                                    <div className="select_btns">
                                    {["Imperial", "Metric" ].map((num) => (
                                         
                                    <div className="select_btn"><label>
                                    <input
                                        defaultChecked={num === bmiheightprops}  // returns bool, only true for first input
                                        type="radio"
                                        name="propBMIImperial"
                                        value={num}
                                        onChange={(e) => setBMIHeightProps(e.target.value)}
                                    /><span>{num} </span></label></div>
                                    ))}
                                    </div>

                                    


                                    <div className="section">
                                        <div className="row align_center">
                                            <div className="col-md-6">
                                                <div className="icon_text"><span className="material-symbols-outlined icon rt"> straighten
                                                </span> Height</div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="swich_block">
                                                    <div className="select_box">
                                                        <input type="text"  name="bmi_height" onChange={(e) => updateInputValue(e)} className="form-control" placeholder="0.0" />
                                                        <select className="select">
                                                            {bmiheightprops == "Imperial" ?
                                                            ["Ft", "In" ].map((item) => (
                                                            <option>{item}</option>
                                                            ))
                                                            :
                                                             ["Cms" ].map((item) => (
                                                            <option>{item}</option>
                                                            ))
                                                             }
                                                            
                                                        </select>
                                                    </div>
                                                    
                                                </div>

                                            </div>
                                        </div>
                                        <div className="row align_center mt-30">
                                            <div className="col-md-6">
                                                <div className="icon_text"><span className="material-symbols-outlined icon"> scale
                                                </span>Weight</div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="swich_block">
                                                    <div className="select_box">
                                                        <input type="text"  name="bmi_weight" onChange={(e) => updateInputValue(e)} className="form-control" placeholder="0.0" />
                                                        <select className="select">
                                                            {bmiheightprops == "Imperial" ?
                                                            ["Lb", "St" ].map((item) => (
                                                            <option>{item}</option>
                                                            ))
                                                            :
                                                             ["Kgs" ].map((item) => (
                                                            <option>{item}</option>
                                                            ))
                                                             }
                                                        </select>
                                                    </div>
                                                   
                                                </div>

                                            </div>
                                        </div>
                                        <div className="row estimated">
                                            <div className="col-md-6">
                                                <div className="form_group">
                                                    <label className="label">Estimated B.M.I</label>
                                                    <input type="text" className="form-control" placeholder="Enter"  name="estimated_bmi" onChange={(e) => updateInputValue(e)} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form_group">
                                                    <label className="label">ULNA Length (CM), if height unknown</label>
                                                    <input type="text"  name="ulna" onChange={(e) => updateInputValue(e)} className="form-control" placeholder="Enter" />
                                                </div>
                                            </div>
                                            <div className="col-md-6 mt-10">
                                                <div className="form_group">
                                                    <label className="label">Does the patient have Ascites / Oedema/
                                                        Amputation ?</label>

                                                </div>
                                            </div>
                                            <div className="col-md-6 mt-10">
                                                <div className="yn_switch mb-0 mt-0 ">

                                                    <label for="check115" className="switch-container ml_0">
                                                        <input hidden=""  onChange={(e) => handleChange(e)} name="ascites" type="checkbox" className="switch_check" id="check115" />
                                                        <div className="switch-bg"></div>
                                                        <div className="round-box"></div>
                                                        <div className="switch-left">
                                                            <span>NO</span>
                                                        </div>
                                                        <div className="switch-right">
                                                            <span>YES</span>
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row align_center mt-30">
                                            <div className="col-md-6">
                                                <div className="icon_text"><span className="material-symbols-outlined icon"> scale
                                                </span>Adjusted Weight</div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="swich_block">
                                                    <div className="select_box">
                                                        <input type="text"  name="bmi_adjusted_weight" onChange={(e) => updateInputValue(e)} className="form-control" placeholder="0.0" />
                                                        <select className="select">
                                                            {bmiheightprops == "Imperial" ?
                                                            ["Lb", "St" ].map((item) => (
                                                            <option>{item}</option>
                                                            ))
                                                            :
                                                             ["Kgs" ].map((item) => (
                                                            <option>{item}</option>
                                                            ))
                                                             }
                                                        </select>
                                                    </div>
                                                   
                                                </div>

                                            </div>
                                        </div>
                                    </div>


                                    <a onClick={(e) => showResults(e)} className="show_more">SHOW RESULTS</a>
                                    
                                    
                                    {nutritionValues.riskscore == 0 && 
                                    <div className="bmi_results green">
                                        <h3 className="bg_title text_center mt-30 mb-30">BMI Results - {nutritionValues.bmiResult && nutritionValues.bmiResult}</h3>
                                        
                                        <div className="section bmi_points">
                                            <div className="points"> <span>{nutritionValues.riskscore && Math.round(nutritionValues.riskscore)}</span> Points <br />
                                                MUST Score
                                            </div>
                                            <div className="points"> <span>Low</span> <br />
                                                Risk
                                            </div>
                                        </div>

                                    </div>}


                                    {nutritionValues.riskscore == 1 && 
                                    <div className="bmi_results orange">
                                        <h3 className="bg_title text_center mt-30 mb-30">BMI Results - {nutritionValues.bmiResult && nutritionValues.bmiResult}</h3>
                                        
                                        <div className="section bmi_points">
                                            <div className="points"> <span>{nutritionValues.riskscore && Math.round(nutritionValues.riskscore)}</span> Points <br />
                                                MUST Score
                                            </div>
                                            <div className="points"> <span>Medium </span> <br />
                                                Risk
                                            </div>
                                        </div>
                                        <div className="section pd_25">
                                            <h4>MUST 1 Medium Risk Observe & Repeat screening in 1 week</h4>
                                            <p>1. Encourage Energy Dense Diet Enriched diet ordered from
                                                <a href="#" className="link_btn">catering <span className="material-icons icon"> arrow_right
                                                </span></a>

                                            </p>
                                            <p>2. Offer snacks between meals - ward snacks, milky drinks, snacks from family.</p>
                                            <div className="row mt-20 mb-10">
                                                <div className="col-md-6">
                                                    <div className="form_group">
                                                        <label className="label">Snacks ordered for between meals </label>
                                                        <input type="text" className="form-control" placeholder="Enter" />
                                                        <span className="material-symbols-outlined input_icon"> lunch_dining </span>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form_group">
                                                        <label className="label">Preferred snacks</label>
                                                        <input type="text" className="form-control" placeholder="Enter" />
                                                        <span className="material-symbols-outlined input_icon"> lunch_dining </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <p>3. Identify and correct any barriers to improved nutrition </p>
                                            <div className="poc is">
                                                <div className="check-box">
                                                    <div className="round">
                                                        <input type="checkbox" id="checkbox-20" />
                                                        <label for="checkbox-20"></label>
                                                    </div>
                                                    Dentures too big: Unable to chew effectively
                                                </div>
                                                <div className="check-box">
                                                    <div className="round">
                                                        <input type="checkbox" id="checkbox-21" />
                                                        <label for="checkbox-21"></label>
                                                    </div>
                                                    Sore mouth: Oral thrush - Dentures rubbing - Head and neck illness
                                                </div>
                                                <div className="check-box">
                                                    <div className="round">
                                                        <input type="checkbox" id="checkbox-22" />
                                                        <label for="checkbox-22"></label>
                                                    </div>
                                                    Loss of smell and taste - Due to drugs or disease
                                                </div>
                                                <div className="check-box">
                                                    <div className="round">
                                                        <input type="checkbox" id="checkbox-23" />
                                                        <label for="checkbox-23"></label>
                                                    </div>
                                                    Unable to physically feed themselves: Broken arms - Unable sit up - Cognitive
                                                    barrier
                                                </div>
                                                <div className="check-box">
                                                    <div className="round">
                                                        <input type="checkbox" id="checkbox-24" />
                                                        <label for="checkbox-24"></label>
                                                    </div>
                                                    Depression: Mental health illness - Loss of spouse - Lack of motivation -
                                                    Loneliness
                                                </div>
                                                <div className="check-box">
                                                    <div className="round">
                                                        <input type="checkbox" id="checkbox-25" />
                                                        <label for="checkbox-25"></label>
                                                    </div>
                                                    Unable to swallow safely: Maybe temporary or long standing
                                                </div>
                                                <div className="check-box">
                                                    <div className="round">
                                                        <input type="checkbox" id="checkbox-26" />
                                                        <label for="checkbox-26"></label>
                                                    </div>
                                                    Restrictive Diets: Preference for home cooked meals - Lack of knowledge
                                                </div>
                                                <div className="check-box">
                                                    <div className="round">
                                                        <input type="checkbox" id="checkbox-27" />
                                                        <label for="checkbox-27"></label>
                                                    </div>
                                                    For dementia patients – Would finger foods help?
                                                </div>
                                                <div className="check-box">
                                                    <div className="round">
                                                        <input type="checkbox" id="checkbox-28" />
                                                        <label for="checkbox-28"></label>
                                                    </div>
                                                    Does the patient need assistance at meal times?
                                                </div>
                                                <div className="check-box">
                                                    <div className="round">
                                                        <input type="checkbox" id="checkbox-29" />
                                                        <label for="checkbox-29"></label>
                                                    </div>
                                                    Do they manage better with different food consistencies?
                                                </div>
                                                <div className="check-box">
                                                    <div className="round">
                                                        <input type="checkbox" id="checkbox-30" />
                                                        <label for="checkbox-30"></label>
                                                    </div>
                                                    Is a large meal off putting?
                                                </div>
                                            </div>
                                            <p>4. If weight stable/gained, continue. If no improvement in oral intake, commence Oral
                                                Nutrition Supplements(ONS)1-2 If the patient is already on ONS check compliance. 2
                                                bottles is usual recommended daily dose
                                            </p>
                                            <p>5. If the patient is already on ONS check compliance. 2 bottles is usual recommended
                                                daily dose </p>
                                            <div className="row mt-20">
                                                <div className="col-md-12">
                                                    <div className="form_group">
                                                        <label className="label">Oral nutritional supplements ordered. Preferred oral
                                                            nutritional supplement </label>
                                                        <input type="text" className="form-control" placeholder="Enter" />

                                                    </div>
                                                </div>

                                            </div>
                                            <p>If weight continues to fall: Refer to the Dietitian and start a <a href="#"
                                                className="link_btn">3 day food record chart <span className="material-icons icon">
                                                    arrow_right </span></a></p>
                                        </div>
                                    </div>
                                    }


                                    {nutritionValues.riskscore >= 2 && 
                                    <div className="bmi_results red">
                                        <h3 className="bg_title text_center mt-30 mb-30">BMI Results - {nutritionValues.bmiResult && nutritionValues.bmiResult}</h3>
                                        
                                        <div className="section bmi_points">
                                            <div className="points"> <span>{nutritionValues.riskscore && Math.round(nutritionValues.riskscore)}</span> Points <br />
                                                MUST Score
                                            </div>
                                            <div className="points"> <span>High</span> <br />
                                                Risk
                                            </div>
                                        </div>
                                        <div className="section pd_25">
                                            <h4>MUST 2 or more: High Risk Repeat screening in 1 week</h4>
                                            <p>1. Refer to the Dietitian - Whilst waiting… Keep accurate food charts including
                                                amounts eaten – <br />
                                                <a href="#" className="link_btn" >3 day
                                                    food record chart <span className="material-icons icon">
                                                        arrow_right </span></a>

                                            </p>
                                            <p>2. Identify and correct any barriers to improved nutrition </p>
                                            <p>3. Order and encourage Energy Dense Diet - Enriched diet ordered from catering </p>
                                            <p>4. Offer snacks between meals and/or ONS. Use ONS if parts of meals are not taken.
                                            </p>
                                            <p>5. If the patient is already on ONS check compliance. 2 bottles is usual recommended
                                                daily dose </p>
                                            <div className="row mt-30">
                                                <div className="col-md-6">
                                                    <div className="form_group">
                                                        <label className="label">Snacks ordered for between meals</label>
                                                        <input type="text" className="form-control" placeholder="Enter" />
                                                        <span className="material-symbols-outlined input_icon"> lunch_dining </span>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form_group">
                                                        <label className="label">Oral nutritional supplements ordered</label>
                                                        <input type="text" className="form-control" placeholder="Enter" />
                                                        <span className="material-symbols-outlined input_icon"> water_full </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="section ">
                                        <h3 className="bg_title text_center mt-10 mb-10">3 DAY FOOD RECORD CHART</h3>
                                        <table className="table day_food" colspace="2">
                                            <thead>
                                                <tr>
                                                    <th width="10%"></th>

                                                    <th width="15%">PORTION</th>
                                                    <th width="45%">AMOUNT
                                                        CONSUMED</th>
                                                    <th width="20%">If &gt; 1/2 state why</th>
                                                    <th width="10%">Electronic
                                                        ID</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td> <b>Cereal</b> </td>

                                                    <td> <input type="text" className="form-control" /></td>
                                                    <td>
                                                        <div className="select_btns popup_select">
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 1" /><span> Nil</span></label> </div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span>1/4 </span></label></div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span>1/2 </span></label></div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span>3/4 </span></label></div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span>All </span></label></div>
                                                        </div>

                                                    </td>
                                                    <td><input type="text" className="form-control" /></td>
                                                    <td><input type="text" className="form-control" /></td>
                                                </tr>
                                                <tr>
                                                    <td> Milk/Sugar </td>

                                                    <td> <input type="text" className="form-control" /></td>
                                                    <td>
                                                        <div className="select_btns popup_select">
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 1" /><span> Nil</span></label> </div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span>1/4 </span></label></div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span>1/2 </span></label></div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span>3/4 </span></label></div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span>All </span></label></div>
                                                        </div>

                                                    </td>
                                                    <td><input type="text" className="form-control" /></td>
                                                    <td><input type="text" className="form-control" /></td>
                                                </tr>
                                                <tr>
                                                    <td> Bread / Toast </td>
                                                    <td> <input type="text" className="form-control" /></td>
                                                    <td>
                                                        <div className="select_btns popup_select">
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 1" /><span> Nil</span></label> </div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span>1/4 </span></label></div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span>1/2 </span></label></div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span>3/4 </span></label></div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span>All </span></label></div>
                                                        </div>

                                                    </td>
                                                    <td><input type="text" className="form-control" /></td>
                                                    <td><input type="text" className="form-control" /></td>
                                                </tr>
                                                <tr>
                                                    <td> Spread </td>
                                                    <td> <input type="text" className="form-control" /></td>
                                                    <td>
                                                        <div className="select_btns popup_select">
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 1" /><span> Nil</span></label> </div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span>1/4 </span></label></div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span>1/2 </span></label></div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span>3/4 </span></label></div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span>All </span></label></div>
                                                        </div>

                                                    </td>
                                                    <td><input type="text" className="form-control" /></td>
                                                    <td><input type="text" className="form-control" /></td>
                                                </tr>
                                                <tr>
                                                    <td> Cooked item </td>
                                                    <td> <input type="text" className="form-control" /></td>
                                                    <td>
                                                        <div className="select_btns popup_select">
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 1" /><span> Nil</span></label> </div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span>1/4 </span></label></div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span>1/2 </span></label></div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span>3/4 </span></label></div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span>All </span></label></div>
                                                        </div>

                                                    </td>
                                                    <td><input type="text" className="form-control" /></td>
                                                    <td><input type="text" className="form-control" /></td>
                                                </tr>
                                                <tr>
                                                    <td> Drinks </td>
                                                    <td> <input type="text" className="form-control" /></td>
                                                    <td>
                                                        <div className="select_btns popup_select">
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 1" /><span> Nil</span></label> </div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span>1/4 </span></label></div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span>1/2 </span></label></div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span>3/4 </span></label></div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span>All </span></label></div>
                                                        </div>

                                                    </td>
                                                    <td><input type="text" className="form-control" /></td>
                                                    <td><input type="text" className="form-control" /></td>
                                                </tr>
                                                <tr>
                                                    <td> Supplement </td>
                                                    <td> <input type="text" className="form-control" /></td>
                                                    <td>
                                                        <div className="select_btns popup_select">
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 1" /><span> Nil</span></label> </div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span>1/4 </span></label></div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span>1/2 </span></label></div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span>3/4 </span></label></div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span>All </span></label></div>
                                                        </div>

                                                    </td>
                                                    <td><input type="text" className="form-control" /></td>
                                                    <td><input type="text" className="form-control" /></td>
                                                </tr>
                                                <tr>
                                                    <td> Snacks </td>
                                                    <td> <input type="text" className="form-control" /></td>
                                                    <td>
                                                        <div className="select_btns popup_select">
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 1" /><span> Nil</span></label> </div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span>1/4 </span></label></div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span>1/2 </span></label></div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span>3/4 </span></label></div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span>All </span></label></div>
                                                        </div>

                                                    </td>
                                                    <td><input type="text" className="form-control" /></td>
                                                    <td><input type="text" className="form-control" /></td>
                                                </tr>
                                                <tr>
                                                    <td> Drinks </td>
                                                    <td> <input type="text" className="form-control" /></td>
                                                    <td>
                                                        <div className="select_btns popup_select">
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 1" /><span> Nil</span></label> </div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span>1/4 </span></label></div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span>1/2 </span></label></div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span>3/4 </span></label></div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span>All </span></label></div>
                                                        </div>

                                                    </td>
                                                    <td><input type="text" className="form-control" /></td>
                                                    <td><input type="text" className="form-control" /></td>
                                                </tr>
                                                <tr>
                                                    <td> Supplement </td>
                                                    <td> <input type="text" className="form-control" /></td>
                                                    <td>
                                                        <div className="select_btns popup_select">
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 1" /><span> Nil</span></label> </div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span>1/4 </span></label></div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span>1/2 </span></label></div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span>3/4 </span></label></div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span>All </span></label></div>
                                                        </div>

                                                    </td>
                                                    <td><input type="text" className="form-control" /></td>
                                                    <td><input type="text" className="form-control" /></td>
                                                </tr>
                                                <tr>
                                                    <td> Soup </td>
                                                    <td> <input type="text" className="form-control" /></td>
                                                    <td>
                                                        <div className="select_btns popup_select">
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 1" /><span> Nil</span></label> </div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span>1/4 </span></label></div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span>1/2 </span></label></div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span>3/4 </span></label></div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span>All </span></label></div>
                                                        </div>

                                                    </td>
                                                    <td><input type="text" className="form-control" /></td>
                                                    <td><input type="text" className="form-control" /></td>
                                                </tr>
                                                <tr>
                                                    <td> <b>Main Item</b> </td>
                                                    <td> <input type="text" className="form-control" /></td>
                                                    <td>
                                                        <div className="select_btns popup_select">
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 1" /><span> Nil</span></label> </div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span>1/4 </span></label></div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span>1/2 </span></label></div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span>3/4 </span></label></div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span>All </span></label></div>
                                                        </div>

                                                    </td>
                                                    <td><input type="text" className="form-control" /></td>
                                                    <td><input type="text" className="form-control" /></td>
                                                </tr>
                                                <tr>
                                                    <td> Potato / Rice </td>
                                                    <td> <input type="text" className="form-control" /></td>
                                                    <td>
                                                        <div className="select_btns popup_select">
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 1" /><span> Nil</span></label> </div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span>1/4 </span></label></div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span>1/2 </span></label></div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span>3/4 </span></label></div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span>All </span></label></div>
                                                        </div>

                                                    </td>
                                                    <td><input type="text" className="form-control" /></td>
                                                    <td><input type="text" className="form-control" /></td>
                                                </tr>
                                                <tr>
                                                    <td> Vegetables </td>
                                                    <td> <input type="text" className="form-control" /></td>
                                                    <td>
                                                        <div className="select_btns popup_select">
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 1" /><span> Nil</span></label> </div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span>1/4 </span></label></div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span>1/2 </span></label></div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span>3/4 </span></label></div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span>All </span></label></div>
                                                        </div>

                                                    </td>
                                                    <td><input type="text" className="form-control" /></td>
                                                    <td><input type="text" className="form-control" /></td>
                                                </tr>
                                                <tr>
                                                    <td> Pudding </td>
                                                    <td> <input type="text" className="form-control" /></td>
                                                    <td>
                                                        <div className="select_btns popup_select">
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 1" /><span> Nil</span></label> </div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span>1/4 </span></label></div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span>1/2 </span></label></div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span>3/4 </span></label></div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span>All </span></label></div>
                                                        </div>

                                                    </td>
                                                    <td><input type="text" className="form-control" /></td>
                                                    <td><input type="text" className="form-control" /></td>
                                                </tr>
                                                <tr>
                                                    <td> Drinks </td>
                                                    <td> <input type="text" className="form-control" /></td>
                                                    <td>
                                                        <div className="select_btns popup_select">
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 1" /><span> Nil</span></label> </div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span>1/4 </span></label></div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span>1/2 </span></label></div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span>3/4 </span></label></div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span>All </span></label></div>
                                                        </div>

                                                    </td>
                                                    <td><input type="text" className="form-control" /></td>
                                                    <td><input type="text" className="form-control" /></td>
                                                </tr>
                                                <tr>
                                                    <td> Supplement </td>
                                                    <td> <input type="text" className="form-control" /></td>
                                                    <td>
                                                        <div className="select_btns popup_select">
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 1" /><span> Nil</span></label> </div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span>1/4 </span></label></div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span>1/2 </span></label></div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span>3/4 </span></label></div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span>All </span></label></div>
                                                        </div>

                                                    </td>
                                                    <td><input type="text" className="form-control" /></td>
                                                    <td><input type="text" className="form-control" /></td>
                                                </tr>
                                                <tr>
                                                    <td> Snacks </td>
                                                    <td> <input type="text" className="form-control" /></td>
                                                    <td>
                                                        <div className="select_btns popup_select">
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 1" /><span> Nil</span></label> </div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span>1/4 </span></label></div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span>1/2 </span></label></div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span>3/4 </span></label></div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span>All </span></label></div>
                                                        </div>

                                                    </td>
                                                    <td><input type="text" className="form-control" /></td>
                                                    <td><input type="text" className="form-control" /></td>
                                                </tr>
                                                <tr>
                                                    <td> Drinks </td>
                                                    <td> <input type="text" className="form-control" /></td>
                                                    <td>
                                                        <div className="select_btns popup_select">
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 1" /><span> Nil</span></label> </div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span>1/4 </span></label></div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span>1/2 </span></label></div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span>3/4 </span></label></div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span>All </span></label></div>
                                                        </div>

                                                    </td>
                                                    <td><input type="text" className="form-control" /></td>
                                                    <td><input type="text" className="form-control" /></td>
                                                </tr>
                                                <tr>
                                                    <td> Supplement </td>
                                                    <td> <input type="text" className="form-control" /></td>
                                                    <td>
                                                        <div className="select_btns popup_select">
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 1" /><span> Nil</span></label> </div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span>1/4 </span></label></div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span>1/2 </span></label></div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span>3/4 </span></label></div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span>All </span></label></div>
                                                        </div>

                                                    </td>
                                                    <td><input type="text" className="form-control" /></td>
                                                    <td><input type="text" className="form-control" /></td>
                                                </tr>
                                                <tr>
                                                    <td> Soup </td>

                                                    <td> <input type="text" className="form-control" /></td>
                                                    <td>
                                                        <div className="select_btns popup_select">
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 1" /><span> Nil</span></label> </div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span>1/4 </span></label></div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span>1/2 </span></label></div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span>3/4 </span></label></div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 2" /><span>All </span></label></div>
                                                        </div>

                                                    </td>
                                                    <td><input type="text" className="form-control" /></td>
                                                    <td><input type="text" className="form-control" /></td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>

                                    </div>
                                    }

                                   

                                </div>

                            </div>
                            <div className="popup_footer">
                                <div className="prescribe_footer">
                                    <div className="dsp-flex">
                                        <a className="icon_btn"> <span className="material-icons">save</span> SAVE</a>
                                        <button className="border_btn"><span className="material-icons">save_as</span>SAVE &amp;
                                            CLOSE
                                        </button>
                                    </div>
                                    <button className="btn dsp_flex"><span className="material-icons"><span
                                        className="material-icons">close</span></span>DISCARD </button>
                                </div>
                            </div>
                        </div>
                    </div>





                </div>
            </div>




        </React.Fragment>
    )
}

Nutrition.propTypes = {
    t: PropTypes.any,
    getWards: PropTypes.func,
    getTheatres: PropTypes.func,
    wards: PropTypes.array,
    user: PropTypes.any,
    theatres: PropTypes.array,
    clinic: PropTypes.array,
    patients: PropTypes.array,
    doctors: PropTypes.array,
    profile: PropTypes.object
}

const mapStateToProps = ({ Layout, Login }) => ({
    wards: Layout.wards,
    user: Login.user,
    theatres: Layout.theatres,
    clinic: Layout.clinic,
    patients: Layout.patients,
    doctors: Layout.doctors,
    profile: Layout.profile
})

const mapDispatchToProps = dispatch => ({
    getWards: () => dispatch(getWardsList()),
    getTheatres: () => dispatch(getTheatresList()),
    getClinic: () => dispatch(getClinicList()),
    getPatient: () => dispatch(getPatientList()),
    getUserprofile: (id) => dispatch(getUprofile(id)),
    getDoctor: () => dispatch(getDoctorList()),
    patientupdate: (id, data) => dispatch(updatePatient(id, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Nutrition)
