import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import MetaTags from 'react-meta-tags';
import { Buffer } from 'buffer';
import surgery from '../../assets/images/surgery.png';
import CalendarEvent from './calendar-react-schedule'
import SignaturePad from 'react-signature-canvas'
import {
    Nav,
    NavItem,
    Row,
    TabContent,
    TabPane,
} from "reactstrap"
import { connect } from "react-redux"
import { getWardsList, getTheatresList, getClinicList, getPatientList, getUprofile, getDoctorList, updatePatient } from "../../store/actions"
import { removeSpecialCharacters } from '../../utils/utils';
const Anesthetic = props => {

    const [clinicValues, setClinic] = useState({})
    const [file, setFile] = useState({});
    const [customActiveTab, setcustomActiveTab] = useState("1");

    let patientsigPad = useRef(null);
    let patientguardiansigPad = useRef(null);
    let witnessSign = useRef(null);
    let anaesthetistSign = useRef(null);
    let ane_preop_sig1 = useRef(null);
    let ane_preop_sig2 = useRef(null);
    let ane_preop_sig3 = useRef(null);
    let ane_preop_sig4 = useRef(null);
    let anaestheticPractitionerSign = useRef(null);
    let ane_pre_sig1 = useRef(null);
    let ane_pre_sig2 = useRef(null);
    let ane_pre_sig3 = useRef(null);
    let ane_pre_sig4 = useRef(null);
    let providerSign = useRef(null);
    let witnessSign2 = useRef(null);
    

    const {
        wards, user, theatres, clinic, patients, profile, doctors
    } = props;

    useEffect(() => {
        const { getPatient } = props
        getPatient()
    }, [])

    useEffect(() => {
        let clinicPatient = JSON.parse(localStorage.getItem("p"))
        if (patients && patients.length > 0 && clinicPatient && clinicPatient._id) {
            const cPatient = patients.filter(
                usr => usr._id === clinicPatient._id
            )
            if (cPatient && cPatient[0]) {
                let cpatientData = cPatient[0]
                delete cpatientData["_id"]
                setClinic(clinicValues => ({
                    ...clinicValues,
                    ...cpatientData
                }));
            }

        }
    }, [patients])

    const updateInputValue = (e) => {
        let val = e.target.value;
        let name = e.target.name;
        let updatedValue = {};
        updatedValue = { [name]: val };
        setClinic(clinicValues => ({
            ...clinicValues,
            ...updatedValue
        }));
    }

    const updateClinicValues = (e, name, val) => {
        //  e.preventDefault()
        let updatedValue = {};
        updatedValue = { [name]: val };
        setClinic(clinicValues => ({
            ...clinicValues,
            ...updatedValue
        }));
    }

    const updateClinic = (e) => {
        const { patientupdate } = props
        let clinicPatient = JSON.parse(localStorage.getItem("p"))
        let id = clinicPatient._id
        patientupdate(id, clinicValues)
        let patient = {
            ...clinicPatient,
            ...clinicValues
        }
        localStorage.setItem("p", JSON.stringify(patient))

    }

    const updateMultipleValues = (e, name, val, type) => {
        e.preventDefault()
        let tvalues = clinicValues
        e.target.parentElement.classList.add('active');
        if (tvalues[name]) {
            const exists = tvalues[name].includes(val);
            if (!exists) {
                tvalues[name].push(val)
            }

        } else {
            tvalues = {
                [name]: [val]
            }
        }
        // let updatedValue = {};
        // tvalues[type] = true
        setClinic(clinicValues => ({
            ...clinicValues,
            ...tvalues
        }));

    }

    const clear = (e, refvalue) => {
        e.preventDefault()
        console.log("clear ", refvalue)
        // if (type == "patient_signature") {
        //     patientsigPad.clear()
        // } else if (type == "patient_relation") {
        //     patientrelationsigPad.clear()
        // }
        refvalue.clear()
        // patientguardiansigPad.clear()

    }
    const trim = (e, type, refvalue) => {
        e.preventDefault()
        // if (type == "patient_signature") {
        //     console.log("doc url", patientsigPad.getTrimmedCanvas()
        //         .toDataURL('image/png'))
        //     sigUpload(patientsigPad.getCanvas().toDataURL('image/png'), "patient_signature")
        // } else if (type == "patient_relation") {
        //     console.log("url", patientrelationsigPad.getTrimmedCanvas()
        //         .toDataURL('image/png'))
        //     sigUpload(patientrelationsigPad.getCanvas().toDataURL('image/png'), "patient_relation")
        // }

        //console.log("tp ",type, refvalue)
        //console.log("url", refvalue.getTrimmedCanvas().toDataURL('image/png'))
        //console.log("patientguardiansigPad", patientguardiansigPad.getTrimmedCanvas().toDataURL('image/png'))
        sigUpload(refvalue.getCanvas().toDataURL('image/png'), type)

    }

    const handleFile = async (e, name) => {

        const file = e.target.files[0]
        console.log("file ", file, name)
        let filename = e.target.files[0].name;
        // const base64 = await convertBase64(file)
        console.log("filename ", filename)
        let pdetails = JSON.parse(localStorage.getItem("p"))
        // var reader = new FileReader();
        //   reader.readAsDataURL(file);
        // reader.onload = async function (e) {
        // console.log("e ", e)
        let uploadFilePath = "ipr/" + pdetails._id + "/" + name + "_result" + "/" + removeSpecialCharacters(filename);
        let data = { source_bucket: "ipr-resources", sourcepath: uploadFilePath }
        console.log("data ", data)

        const response = await fetch(API_URL+'upload?appname=ipr', {
            method: "POST",
            mode: "cors",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
        const result = await response.json();

        console.log("result ", result)
        let url = result.result
        console.log("url ", url)
        const uploadImage = await fetch(url, {
            method: "PUT",
            mode: "cors",
            headers: {
                "Content-Type": "multipart/form-data",
                "Accept": "/",
                "Cache-Control": "no-cache",
                "Accept-Encoding": "gzip, deflate",
                "Connection": "keep-alive",
                "cache-control": "no-cache"
            },
            body: file
        })

        console.log("resultImage ", uploadImage)

        let imagePath = "https://ipr-resources.s3.amazonaws.com/"
        let imageUploadPath = imagePath + uploadFilePath;

        const { patientupdate } = props
        let updateObj = {
            [name + "_result"]: imageUploadPath,
            [name + "_update"]: new Date()
        }
        patientupdate(pdetails._id, updateObj)
        setFile(file => ({
            ...file,
            ...updateObj
        }));
        setClinic(clinicValues => ({
            ...clinicValues,
            ...updateObj
        }));

        let patient = {
            ...pdetails,
            ...updateObj
        }
        localStorage.setItem("p", JSON.stringify(patient))

    };

    const sigUpload = async (filedata, name) => {

        const file = filedata
        console.log("file ", file, name)
        let filename = name+".png";
        // const base64 = await convertBase64(file)
        console.log("filename ", filename)
        let pdetails = JSON.parse(localStorage.getItem("p"))

        const dataf = file.replace(/^data:image\/\w+;base64,/, "");
  
        const buf = Buffer.from(dataf, "base64");
        let uploadFilePath = "ipr/" + pdetails._id + "/" + name + "_result" + "/" + removeSpecialCharacters(filename);
        let data = { source_bucket: "ipr-resources", sourcepath: uploadFilePath }
        console.log("data ", data)

        const response = await fetch(API_URL+'upload?appname=ipr', {
            method: "POST",
            mode: "cors",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
        const result = await response.json();

        console.log("result ", result, buf.length)
        let url = result.result
        console.log("url ", url)

        const base64Data = new Buffer.from(filedata.replace(/^data:image\/\w+;base64,/, ""), 'base64');

        // Getting the file type, ie: jpeg, png or gif
        const type = filedata.split(';')[0].split('/')[1];

    
        
        const uploadImage = await fetch(url, {
            method: "PUT",
            mode: "cors",
            headers: {
               // "Content-Type": "multipart/form-data",
            //    'Content-Length': buf.length,
               'Content-Type':`image/${type}`,
                'Content-Encoding': 'base64',
                ACL: 'public-read',
                // "Accept": "/",
                // "Cache-Control": "no-cache",
                // "Accept-Encoding": "gzip, deflate",
                // "Connection": "keep-alive",
                // "cache-control": "no-cache"
            },
            body: base64Data
        })

        console.log("resultImage ", uploadImage)

        let imagePath = "https://ipr-resources.s3.amazonaws.com/"
        let imageUploadPath = imagePath + uploadFilePath;

        const { patientupdate } = props
        let updateObj = {
            [name + "_result"]: imageUploadPath,
            [name + "_update"]: new Date()
        }
        patientupdate(pdetails._id, updateObj)
        setFile(file => ({
            ...file,
            ...updateObj
        }));
        setClinic(clinicValues => ({
            ...clinicValues,
            ...updateObj
        }));

        let patient = {
            ...pdetails,
            ...updateObj
        }
        localStorage.setItem("p", JSON.stringify(patient))

    };

    const menuClick = (e, menu) => {
        props.history.push("/" + menu)
    }

    const handleChange = (event) => {
        updateClinicValues(event, event.target.name, event.target.checked)
    }

    let clinicPatient = JSON.parse(localStorage.getItem("p"))

    // console.log("anydelay ", anydelay)
    //console.log("clinicValues ", clinicValues)
    // console.log("file ", file)
    return (
        <React.Fragment>

            <MetaTags>
                <title>ANAESTHETIC RECORD</title>
            </MetaTags>
            <div className='popup_wrp theatre'>
                <div className="popup_full prescribe">
                    <div className='popup_hed'>
                        <h2>ANAESTHETIC RECORD</h2>
                        <a className='popup_close' onClick={(e) => menuClick(e, 'tpatient')}><span className="material-icons">close</span></a>
                    </div>


                    <div className='popup_content'>

                        <div className='popup_scroll main pr height175 '>

                            <div className="section simple_wound ctr">
                                <h2 className="popup_title"> Pre anaesthetic Evaluation</h2>
                                <div className="pd_border"></div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text"
                                                onChange={(e) => updateInputValue(e)} name="ane_anaesthetic_assessor" value={clinicValues.ane_anaesthetic_assessor} className="form-control" autoComplete="off" /><label
                                                    className="floating-label">Anaesthetic Assessor</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="ane_gmc_num" value={clinicValues.ane_gmc_num} className="form-control" autoComplete="off" /><label
                                                className="floating-label">GMC Number</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="date" onChange={(e) => updateInputValue(e)} name="ane_date_of_assess" value={clinicValues.ane_date_of_assess}
                                                className="form-control" autoComplete="off" /><label
                                                    className="floating-label font-12" >Date of assessment</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="time" onChange={(e) => updateInputValue(e)} name="ane_time" value={clinicValues.ane_time}
                                                className="form-control" autoComplete="off" /><label
                                                    className="floating-label">Time</label>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className='tab'>


                                    <Nav tabs className="nav-tabs-custom">
                                        <NavItem style={{ cursor: "pointer" }}
                                            className={customActiveTab == "1" ? "nav-link active" : "nav-link"}
                                            onClick={() => {
                                                setcustomActiveTab("1")
                                            }}>

                                            <span className="d-none d-sm-block">Relevant Information</span>

                                        </NavItem>
                                        <NavItem style={{ cursor: "pointer" }}
                                            className={customActiveTab == "2" ? "nav-link active" : "nav-link"}
                                            onClick={() => {
                                                setcustomActiveTab("2")
                                            }}>

                                            <span className="d-none d-sm-block">VITAL SIGNS</span>

                                        </NavItem>
                                        <NavItem style={{ cursor: "pointer" }}
                                            className={customActiveTab == "3" ? "nav-link active" : "nav-link"}
                                            onClick={() => {
                                                setcustomActiveTab("3")
                                            }}>

                                            <span className="d-none d-sm-block">Investigation</span>

                                        </NavItem>

                                    </Nav>

                                    <TabContent activeTab={customActiveTab} className="p-3 text-muted">
                                        <TabPane tabId="1">
                                            <Row>
                                                <div className="row ctr">
                                                    <div className="col-md-4">
                                                        <div className="measure">
                                                            <label>Age</label>
                                                            <div className="dsp_flex"> <input type="text" onChange={(e) => updateInputValue(e)} name="ane_age" value={clinicValues.ane_age} className="field" />
                                                                <span>Years</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="measure">
                                                            <label>WEIGHT</label>
                                                            <div className="dsp_flex"> <input type="text" onChange={(e) => updateInputValue(e)} name="ane_weight" value={clinicValues.ane_weight} className="field" />
                                                                <span>Kgs</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="measure">
                                                            <label>HEIGHT</label>
                                                            <div className="dsp_flex"> <input type="text" onChange={(e) => updateInputValue(e)} name="ane_height" value={clinicValues.ane_height} className="field" />
                                                                <span>cms</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-4">
                                                        <div className="measure">
                                                            <label>BMI</label>
                                                            <div className="dsp_flex"> <input type="text" onChange={(e) => updateInputValue(e)} name="ane_bmi" value={clinicValues.ane_bmi} className="field" />
                                                                <span>kg/m2</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <div className="check_item"><label className="check">ASA</label>

                                                            {clinicValues.ane_asa ? <div className="select_btns popup_select">
                                                                <div className="select_btn"><label><input type="radio"
                                                                    value={clinicValues.ane_asa} checked /><span>{clinicValues.ane_asa} </span></label>
                                                                </div> {clinicValues.ane_asa && <a onClick={(e) => updateClinicValues(e, "ane_asa", false)}><span className="material-symbols-outlined"> reply
                                                                </span></a>}

                                                            </div>
                                                                :
                                                                <div className="select_btns popup_select">
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_asa" type="radio"
                                                                        value="1" /><span>1</span></label></div>
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_asa"
                                                                        type="radio"
                                                                        value="2" /><span>2</span></label></div>
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_asa"
                                                                        type="radio"
                                                                        value="3" /><span>3</span></label></div>
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_asa"
                                                                        type="radio"
                                                                        value="4" /><span>4</span></label></div>
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_asa"
                                                                        type="radio"
                                                                        value="5" /><span>5</span></label></div>
                                                                </div>
                                                            }


                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="check_item"><label className="check">Hand</label>

                                                            {clinicValues.ane_hand ? <div className="select_btns popup_select">
                                                                <div className="select_btn"><label><input type="radio"
                                                                    value={clinicValues.ane_hand} checked /><span>{clinicValues.ane_hand} </span></label>
                                                                </div> {clinicValues.ane_hand && <a onClick={(e) => updateClinicValues(e, "ane_hand", false)}><span className="material-symbols-outlined"> reply
                                                                </span></a>}

                                                            </div>
                                                                :
                                                                <div className="select_btns popup_select">
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_hand"
                                                                        type="radio" value="Left" /><span>Left
                                                                        </span></label></div>
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_hand"
                                                                        type="radio" value="Right" /><span>Right
                                                                        </span></label></div>

                                                                </div>}

                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form_group">
                                                            <div className="floating-label-group"><input type="text"
                                                                onChange={(e) => updateInputValue(e)} name="ane_axillary" value={clinicValues.ane_axillary} className="form-control" autoComplete="off"
                                                            /><label className="floating-label">Axillary
                                                                clearance (in women)</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form_group">
                                                            <div className="floating-label-group"><input type="text"
                                                                onChange={(e) => updateInputValue(e)} name="ane_needle" value={clinicValues.ane_needle} className="form-control" autoComplete="off"
                                                            /><label className="floating-label">Any needle
                                                                phobia</label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="row mt-10">
                                                    <div className="col-md-4">
                                                        <div className="form_group">
                                                            <div className="floating-label-group"><input type="text"
                                                                onChange={(e) => updateInputValue(e)} name="ane_nbmfluids" value={clinicValues.ane_nbmfluids} className="form-control" autoComplete="off"
                                                            /><label className="floating-label">NBM from
                                                                Fluids(hrs) </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form_group">
                                                            <div className="floating-label-group"><input type="text"
                                                                onChange={(e) => updateInputValue(e)} name="ane_nbmsolids" value={clinicValues.ane_nbmsolids} className="form-control" autoComplete="off"
                                                            /><label className="floating-label">NBM From
                                                                Solids(hrs)</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form_group">
                                                            <div className="floating-label-group"><input type="text"
                                                                onChange={(e) => updateInputValue(e)} name="ane_prlanguage" value={clinicValues.ane_prlanguage} className="form-control" autoComplete="off"
                                                            /><label className="floating-label">Primary
                                                                language English</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mt-10">

                                                    <div className="form_group">
                                                        <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="ane_Resuscitation" value={clinicValues.ane_Resuscitation}
                                                            className="form-control" autoComplete="off" /><label
                                                                className="floating-label">Resuscitation
                                                                status</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="yn_switch  mt-10">
                                                    <div className="label-container">
                                                        <p>Any body piercings?</p>
                                                    </div>
                                                    <label className="switch-container">
                                                        <input type="checkbox" className="switch_check"
                                                            name="ane_anybody_piercing" onChange={(e) => handleChange(e)} checked={clinicValues.ane_anybody_piercing} />
                                                        <div className="switch-bg"></div>
                                                        <div className="round-box"></div>
                                                        <div className="switch-left">
                                                            <span>YES</span>
                                                        </div>
                                                        <div className="switch-right">
                                                            <span>NO</span>
                                                        </div>
                                                    </label>
                                                </div>
                                                <div className="yn_switch  mt-10">
                                                    <div className="label-container">
                                                        <p>Contact lenses / glasses?</p>
                                                    </div>
                                                    <label className="switch-container">
                                                        <input type="checkbox" className="switch_check"
                                                            name="ane_contact_glasses" onChange={(e) => handleChange(e)} checked={clinicValues.ane_contact_glasses} />
                                                        <div className="switch-bg"></div>
                                                        <div className="round-box"></div>
                                                        <div className="switch-left">
                                                            <span>YES</span>
                                                        </div>
                                                        <div className="switch-right">
                                                            <span>NO</span>
                                                        </div>
                                                    </label>
                                                </div>
                                                <div className="yn_switch  mt-10">
                                                    <div className="label-container">
                                                        <p>Any artificial limbs/joints/prostheses/metalwork?</p>
                                                    </div>
                                                    <label className="switch-container">
                                                        <input type="checkbox" className="switch_check"
                                                            name="ane_limbs" onChange={(e) => handleChange(e)} checked={clinicValues.ane_limbs} />
                                                        <div className="switch-bg"></div>
                                                        <div className="round-box"></div>
                                                        <div className="switch-left">
                                                            <span>YES</span>
                                                        </div>
                                                        <div className="switch-right">
                                                            <span>NO</span>
                                                        </div>
                                                    </label>
                                                </div>
                                            </Row>
                                        </TabPane>
                                        <TabPane tabId="2">
                                            <Row>
                                                <div className="row ctr">
                                                    <div className="col-md-4">
                                                        <div className="measure">
                                                            <label>Patient Temp</label>
                                                            <div className="dsp_flex"> <input type="text" onChange={(e) => updateInputValue(e)} name="ane_patient_temp" value={clinicValues.ane_patient_temp} className="field" />
                                                                <span>C</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="measure">
                                                            <label>PR</label>
                                                            <div className="dsp_flex"> <input type="text" onChange={(e) => updateInputValue(e)} name="ane_patient_bpm" value={clinicValues.ane_patient_bpm} className="field" />
                                                                <span>bpm</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="measure">
                                                            <label>BP </label>
                                                            <div className="dsp_flex"> <input type="text" onChange={(e) => updateInputValue(e)} name="ane_patient_mmhg" value={clinicValues.ane_patient_mmhg} className="field" />
                                                                <span>mmHg</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-4">
                                                        <div className="measure">
                                                            <label>Resp. Rate</label>
                                                            <div className="dsp_flex"> <input type="text" onChange={(e) => updateInputValue(e)} name="ane_patient_resp_rate" value={clinicValues.ane_patient_resp_rate} className="field" />
                                                                <span>min</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="measure">
                                                            <label>Spo2 </label>
                                                            <div className="dsp_flex"> <input type="text" onChange={(e) => updateInputValue(e)} name="ane_patient_spo2" value={clinicValues.ane_patient_spo2} className="field" />

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="measure">
                                                            <label>BM </label>
                                                            <div className="dsp_flex"> <input type="text" onChange={(e) => updateInputValue(e)} name="ane_patient_bm" value={clinicValues.ane_patient_bm} className="field" />

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Row>
                                        </TabPane>
                                        <TabPane tabId="3">
                                            <Row>
                                                <div className="row ctr">
                                                    <div className="col-md-3">
                                                        <div className="measure">
                                                            <label>HB</label>
                                                            <div className="dsp_flex"> <input type="text" onChange={(e) => updateInputValue(e)} name="ane_patient_hb" value={clinicValues.ane_patient_hb} className="field" />

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="measure">
                                                            <label>WBC</label>
                                                            <div className="dsp_flex"> <input type="text" onChange={(e) => updateInputValue(e)} name="ane_patient_wbc" value={clinicValues.ane_patient_wbc} className="field" />

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="measure">
                                                            <label> Hct</label>
                                                            <div className="dsp_flex"> <input type="text" onChange={(e) => updateInputValue(e)} name="ane_patient_hct" value={clinicValues.ane_patient_hct} className="field" />

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-3">
                                                        <div className="measure">
                                                            <label>PLTS</label>
                                                            <div className="dsp_flex"> <input type="text" onChange={(e) => updateInputValue(e)} name="ane_patient_plts" value={clinicValues.ane_patient_plts} className="field" />

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="measure">
                                                            <label>U&Es </label>
                                                            <div className="dsp_flex"> <input type="text" onChange={(e) => updateInputValue(e)} name="ane_patient_ue" value={clinicValues.ane_patient_ue} className="field" />

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="measure">
                                                            <label>Na </label>
                                                            <div className="dsp_flex"> <input type="text" onChange={(e) => updateInputValue(e)} name="ane_patient_na" value={clinicValues.ane_patient_na} className="field" />

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="measure">
                                                            <label> K </label>
                                                            <div className="dsp_flex"> <input type="text" onChange={(e) => updateInputValue(e)} name="ane_patient_k" value={clinicValues.ane_patient_k} className="field" />

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="measure">
                                                            <label>Cr </label>
                                                            <div className="dsp_flex"> <input type="text" onChange={(e) => updateInputValue(e)} name="ane_patient_cr" value={clinicValues.ane_patient_cr} className="field" />

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="measure">
                                                            <label>Ur </label>
                                                            <div className="dsp_flex"> <input type="text" onChange={(e) => updateInputValue(e)} name="ane_patient_ur" value={clinicValues.ane_patient_ur} className="field" />

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="measure">
                                                            <label>Cl </label>
                                                            <div className="dsp_flex"> <input type="text" onChange={(e) => updateInputValue(e)} name="ane_patient_cl" value={clinicValues.ane_patient_cl} className="field" />

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="measure">
                                                            <label>Venous HCO3 </label>
                                                            <div className="dsp_flex"> <input type="text" onChange={(e) => updateInputValue(e)} name="ane_patient_Venous" value={clinicValues.ane_patient_Venous} className="field" />

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="measure">
                                                            <label>GLUCOSE </label>
                                                            <div className="dsp_flex"> <input type="text" onChange={(e) => updateInputValue(e)} name="ane_patient_glucose" value={clinicValues.ane_patient_glucose} className="field" />

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="measure">
                                                            <label>BLOOD GROUP </label>
                                                            <div className="dsp_flex"> <input type="text" onChange={(e) => updateInputValue(e)} name="ane_patient_bloodgroup" value={clinicValues.ane_patient_bloodgroup} className="field" />

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="measure">
                                                            <label>COAGULATION</label>
                                                            <div className="dsp_flex"> <input type="text" onChange={(e) => updateInputValue(e)} name="ane_patient_coagulation" value={clinicValues.ane_patient_coagulation} className="field" />

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="yn_switch  mt-10">
                                                    <div className="label-container">
                                                        <p>INR APTT VALID G </p>
                                                    </div>
                                                    <label className="switch-container">
                                                        <input type="checkbox" className="switch_check"
                                                            name="ane_inr_aptt" onChange={(e) => handleChange(e)} checked={clinicValues.ane_inr_aptt} />
                                                        <div className="switch-bg"></div>
                                                        <div className="round-box"></div>
                                                        <div className="switch-left">
                                                            <span>YES</span>
                                                        </div>
                                                        <div className="switch-right">
                                                            <span>NO</span>
                                                        </div>
                                                    </label>
                                                </div>
                                                <div className="yn_switch  mt-10">
                                                    <div className="label-container">
                                                        <p>UNITS OF BLOOD AVAILABLE </p>
                                                    </div>
                                                    <label className="switch-container">
                                                        <input type="checkbox" className="switch_check"
                                                            name="ane_unit_blood" onChange={(e) => handleChange(e)} checked={clinicValues.ane_unit_blood} />
                                                        <div className="switch-bg"></div>
                                                        <div className="round-box"></div>
                                                        <div className="switch-left">
                                                            <span>YES</span>
                                                        </div>
                                                        <div className="switch-right">
                                                            <span>NO</span>
                                                        </div>
                                                    </label>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form_group">
                                                            <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="ane_unitsofblood" value={clinicValues.ane_unitsofblood}
                                                                className="form-control" autoComplete="off" /><label
                                                                    className="floating-label">UNITS OF BLOOD AVAILABLE</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form_group">
                                                            <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="ane_ecg" value={clinicValues.ane_ecg}
                                                                className="form-control" autoComplete="off" /><label
                                                                    className="floating-label">ECG</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form_group">
                                                            <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="ane_cxr" value={clinicValues.ane_cxr}
                                                                className="form-control" autoComplete="off" /><label
                                                                    className="floating-label">CXR</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form_group">
                                                            <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="ane_covid" value={clinicValues.ane_covid}
                                                                className="form-control" autoComplete="off" /><label
                                                                    className="floating-label">COVID / MRSA
                                                                    Status</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form_group">
                                                            <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="ane_Hepatitis" value={clinicValues.ane_Hepatitis}
                                                                className="form-control" autoComplete="off" /><label
                                                                    className="floating-label">Hepatitis B,
                                                                    Hepatitis C, HIV</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Row>
                                        </TabPane>

                                    </TabContent>


                                </div>

                            </div>



                            <div className="section simple_wound ctr">
                                <h2 className="popup_title">ANAESTHETIC ALERTS </h2>
                                <div className="pd_border"></div>
                                <div className="form_group">
                                    <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="ane_alerts" value={clinicValues.ane_alerts} className="form-control"
                                        autoComplete="off" /><label className="floating-label">Allergy </label>
                                    </div>
                                </div>
                                <div className="yn_switch  mt-20">
                                    <div className="label-container">
                                        <p>Difficult airway</p>
                                    </div>
                                    <label className="switch-container">
                                        <input type="checkbox" className="switch_check"
                                            name="ane_Difficult_airway" onChange={(e) => handleChange(e)} checked={clinicValues.ane_Difficult_airway} />
                                        <div className="switch-bg"></div>
                                        <div className="round-box"></div>
                                        <div className="switch-left">
                                            <span>YES</span>
                                        </div>
                                        <div className="switch-right">
                                            <span>NO</span>
                                        </div>
                                    </label>
                                </div>

                                <div className="yn_switch  mt-10">
                                    <div className="label-container">
                                        <p>Reflux/aspiration risk</p>
                                    </div>
                                    <label className="switch-container">
                                        <input type="checkbox" className="switch_check"
                                            name="ane_Reflux" onChange={(e) => handleChange(e)} checked={clinicValues.ane_Reflux} />
                                        <div className="switch-bg"></div>
                                        <div className="round-box"></div>
                                        <div className="switch-left">
                                            <span>YES</span>
                                        </div>
                                        <div className="switch-right">
                                            <span>NO</span>
                                        </div>
                                    </label>
                                </div>
                                <div className="yn_switch  mt-10">
                                    <div className="label-container">
                                        <p>Risk of more than 500 ml blood loss in adults</p>
                                    </div>
                                    <label className="switch-container">
                                        <input type="checkbox" className="switch_check"
                                            name="ane_Riskbloodloss" onChange={(e) => handleChange(e)} checked={clinicValues.ane_Riskbloodloss} />
                                        <div className="switch-bg"></div>
                                        <div className="round-box"></div>
                                        <div className="switch-left">
                                            <span>YES</span>
                                        </div>
                                        <div className="switch-right">
                                            <span>NO</span>
                                        </div>
                                    </label>
                                </div>
                                <div className="yn_switch  mt-10">
                                    <div className="label-container">
                                        <p>Risk of more than 7 ml/kg blood loss in children </p>
                                    </div>
                                    <label className="switch-container">
                                        <input type="checkbox" className="switch_check"
                                            name="ane_Riskbloodloss7ml" onChange={(e) => handleChange(e)} checked={clinicValues.ane_Riskbloodloss7ml} />
                                        <div className="switch-bg"></div>
                                        <div className="round-box"></div>
                                        <div className="switch-left">
                                            <span>YES</span>
                                        </div>
                                        <div className="switch-right">
                                            <span>NO</span>
                                        </div>
                                    </label>
                                </div>
                                <p className="note2" >Select all that apply for Multiselect forms {clinicValues.ane_highcomorbidities && <a onClick={(e) => updateClinicValues(e, "ane_highcomorbidities", false)}><span className="material-symbols-outlined"> reply </span></a>}</p>
                                {clinicValues.ane_highcomorbidities && clinicValues.ane_highcomorbidities_list && clinicValues.ane_highcomorbidities_list.length > 0 ? <div className="check_list w_white mb-0">

                                    {clinicValues.ane_highcomorbidities_list.map((item, i) => (
                                        <div className="check_item active" key={i} onClick={(e) => updateClinicValues(e, "ane_highcomorbidities", false)} ><label className="check">{item}</label></div>
                                    ))}

                                </div> :
                                    <div className="check_list w_white mb-0">
                                        <span className="check_btn2" onClick={(e) => updateClinicValues(e, "ane_highcomorbidities", true)}><span className="material-icons"> done </span></span>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_highcomorbidities_list", "High-risk patient: Multiple comorbidities ASA3,4", "ane_highcomorbidities")} ><label className="check">High-risk patient: Multiple comorbidities ASA3,4 </label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_highcomorbidities_list", "Morbid obesity", "ane_highcomorbidities")}><label className="check">Morbid obesity </label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_highcomorbidities_list", "Obstructive Sleep apnoea / Disturbed sleep pattern: Hepworth/STOPBANG", "ane_highcomorbidities")}><label className="check">Obstructive Sleep apnoea / Disturbed sleep pattern: Hepworth/STOPBANG</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_highcomorbidities_list", "CPAP q HS", "ane_highcomorbidities")}><label className="check">CPAP q HS</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_highcomorbidities_list", "Adverse reactions to Anaesthesia", "ane_highcomorbidities")}><label className="check">Adverse reactions to Anaesthesia</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_highcomorbidities_list", "Malignant hyperthermia", "ane_highcomorbidities")}><label className="check">Malignant hyperthermia</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_highcomorbidities_list", "Any inherited muscle disease such as ? Duchenne’s muscular dystrophy", "ane_highcomorbidities")}><label className="check">Any inherited muscle disease such as ? Duchenne’s muscular dystrophy</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_highcomorbidities_list", "Suxamethonium (Scoline) Apnoea", "ane_highcomorbidities")}><label className="check">Suxamethonium (Scoline) Apnoea</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_highcomorbidities_list", "H/O Fibreoptic intubation / Laryngospasm", "ane_highcomorbidities")}><label className="check">H/O Fibreoptic intubation / Laryngospasm</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_highcomorbidities_list", "Delayed emergence", "ane_highcomorbidities")}><label className="check">Delayed emergence</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_highcomorbidities_list", "Had cough or cold in the past 2 weeks?", "ane_highcomorbidities")}><label className="check">Had cough or cold in the past 2 weeks? </label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_highcomorbidities_list", "Recent chest infection or pneumonia?", "ane_highcomorbidities")}><label className="check">Recent chest infection or pneumonia?
                                        </label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_highcomorbidities_list", "Chronic pain? Depression / mental illness / anxiety attacks?", "ane_highcomorbidities")}><label className="check">Chronic pain? Depression / mental illness / anxiety attacks? </label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_highcomorbidities_list", "Excessive Alcohol Consumption", "ane_highcomorbidities")}><label className="check">Excessive Alcohol Consumption</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_highcomorbidities_list", "Substance Misuse", "ane_highcomorbidities")}><label className="check">Substance Misuse</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_highcomorbidities_list", "Smoker? Within 6 weeks Will it affect anaesthesia?", "ane_highcomorbidities")}><label className="check">Smoker? Within 6 weeks Will it affect anaesthesia? </label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_highcomorbidities_list", "Religious Belief: Jehovah’s Witnesses", "ane_highcomorbidities")}><label className="check">Religious Belief: Jehovah’s Witnesses</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_highcomorbidities_list", "Malignancy", "ane_highcomorbidities")}><label className="check">Malignancy</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_highcomorbidities_list", "Trauma", "ane_highcomorbidities")}><label className="check">Trauma</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_highcomorbidities_list", "Patient’s advanced directives", "ane_highcomorbidities")}><label className="check">Patient’s advanced directives</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_highcomorbidities_list", "Patient with Incapacity Issues", "ane_highcomorbidities")}><label className="check">Patient with Incapacity Issues</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_highcomorbidities_list", "Learning Disability/Autism", "ane_highcomorbidities")}><label className="check">Learning Disability/Autism</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_highcomorbidities_list", "Limited Communication", "ane_highcomorbidities")}><label className="check">Limited Communication</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_highcomorbidities_list", "Limited Comprehension", "ane_highcomorbidities")}><label className="check">Limited Comprehension</label>
                                        </div>
                                    </div>}

                            </div>
                            <div className="section simple_wound ctr">
                                <h2 className="popup_title">PRESENT MEDICATIONS </h2>
                                <div className="pd_border"></div>
                                <p className="note2" >Select all that apply for Multiselect forms {clinicValues.ane_pr_medication && <a onClick={(e) => updateClinicValues(e, "ane_pr_medication", false)}><span className="material-symbols-outlined"> reply </span></a>}</p>
                                {clinicValues.ane_pr_medication && clinicValues.ane_pr_medication_list && clinicValues.ane_pr_medication_list.length > 0 ? <div className="check_list w_white mb-0">

                                    {clinicValues.ane_pr_medication_list.map((item, i) => (
                                        <div className="check_item active" key={i} onClick={(e) => updateClinicValues(e, "ane_pr_medication", false)} ><label className="check">{item}</label></div>
                                    ))}

                                </div> :
                                    <div className="check_list w_white mb-0">
                                        <span className="check_btn2" onClick={(e) => updateClinicValues(e, "ane_pr_medication", true)}><span className="material-icons"> done </span></span>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_pr_medication_list", "Prescription medications", "ane_pr_medication")}><label className="check">Prescription medications </label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_pr_medication_list", "Over the counter medications", "ane_pr_medication")}><label className="check">Over the counter medications </label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_pr_medication_list", "Complementary or Alternative Medication", "ane_pr_medication")}><label className="check">Complementary or Alternative Medication</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_pr_medication_list", "HTN Medications", "ane_pr_medication")}><label className="check">HTN Medications</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_pr_medication_list", "MAOI (Need to be avoided on the day of op)", "ane_pr_medication")}><label className="check">MAOI (Need to be avoided on the day of op) </label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_pr_medication_list", "Nerve pills", "ane_pr_medication")}><label className="check">Nerve pills</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_pr_medication_list", "Pain killers including aspirin", "ane_pr_medication")}><label className="check">Pain killers including aspirin
                                        </label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_pr_medication_list", "Steroids", "ane_pr_medication")}><label className="check">Steroids</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_pr_medication_list", "Muscle relaxants", "ane_pr_medication")}><label className="check">Muscle relaxants</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_pr_medication_list", "Insulin", "ane_pr_medication")}><label className="check">Insulin</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_pr_medication_list", "Antidepressants", "ane_pr_medication")}><label className="check">Antidepressants </label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_pr_medication_list", "Bone density medications or bisphosphonates such as Fosamax etc", "ane_pr_medication")}><label className="check">Bone density medications or
                                            bisphosphonates such as Fosamax etc</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_pr_medication_list", "Patient carries Emergency Medications: Nitro-glycerine/Glucose/Insulin/Inhaler/Epi pen", "ane_pr_medication")}><label className="check">Patient carries Emergency
                                            Medications: Nitro-glycerine/Glucose/Insulin/Inhaler/Epi pen</label>
                                        </div>
                                    </div>}
                            </div>
                            <div className="section simple_wound ctr">
                                <h2 className="popup_title">BIOMEDICAL DEVICES </h2>
                                <div className="pd_border"></div>
                                <p className="note2" >Select all that apply for Multiselect forms {clinicValues.ane_biomedical && <a onClick={(e) => updateClinicValues(e, "ane_biomedical", false)}><span className="material-symbols-outlined"> reply </span></a>}</p>
                                {clinicValues.ane_biomedical && clinicValues.ane_biomedical_list && clinicValues.ane_biomedical_list.length > 0 ? <div className="check_list w_white mb-0">

                                    {clinicValues.ane_biomedical_list.map((item, i) => (
                                        <div className="check_item active" key={i} onClick={(e) => updateClinicValues(e, "ane_biomedical", false)} ><label className="check">{item}</label></div>
                                    ))}

                                </div> :
                                    <div className="check_list w_white mb-0">
                                        <span className="check_btn2" onClick={(e) => updateClinicValues(e, "ane_biomedical", true)}><span className="material-icons"> done </span></span>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_biomedical_list", "Cardiac Pacemaker", "ane_biomedical")}><label className="check">Cardiac Pacemaker </label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_biomedical_list", "Implantable Defibrillator", "ane_biomedical")}><label className="check">Implantable Defibrillator </label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_biomedical_list", "Internal Pacing Wires", "ane_biomedical")}><label className="check">Internal Pacing Wires </label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_biomedical_list", "Cardiac Valve Prosthesis", "ane_biomedical")}><label className="check">Cardiac Valve Prosthesis</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_biomedical_list", "Respiratory support device: CPAP", "ane_biomedical")}><label className="check">Respiratory support device: CPAP
                                        </label>
                                        </div>

                                    </div>}
                            </div>
                            <div className="section simple_wound ctr">
                                <h2 className="popup_title">AIRWAY </h2>
                                <div className="pd_border"></div>
                                <div className="check_item mt-10"><label className="check">MALLAMPATI SCORE </label>
                                    {clinicValues.ane_mallampati ?
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input type="radio"
                                                value={clinicValues.ane_mallampati} checked /><span>{clinicValues.ane_mallampati} </span></label>
                                            </div> {clinicValues.ane_mallampati && <a onClick={(e) => updateClinicValues(e, "ane_mallampati", false)}><span className="material-symbols-outlined"> reply
                                            </span></a>}

                                        </div>
                                        :
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input type="radio"
                                                onClick={(e) => updateInputValue(e)} name="ane_mallampati" value="1" /><span>1 </span></label>
                                            </div>
                                            <div className="select_btn"><label><input type="radio"
                                                onClick={(e) => updateInputValue(e)} name="ane_mallampati" value="2" /><span>2 </span></label></div>
                                            <div className="select_btn"><label><input type="radio"
                                                onClick={(e) => updateInputValue(e)} name="ane_mallampati" value="3" /><span>3
                                                </span></label></div>
                                            <div className="select_btn"><label><input type="radio"
                                                onClick={(e) => updateInputValue(e)} name="ane_mallampati" value="4" /><span>4
                                                </span></label></div>
                                        </div>}
                                </div>
                                <p>I visualization of the soft palate, fauces, uvula, and both anterior and posterior
                                    pillars</p>
                                <p>II Visualization of the soft palate, fauces, and uvula</p>
                                <p>III Visualization of the soft palate and the base of the uvula</p>
                                <p>IV - the soft palate is not visible at all</p>
                                <div className="check_item mt-10"><label className="check">MOUTH OPENING (TMJ) </label>
                                    {clinicValues.ane_mouthopening ?
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input type="radio"
                                                value={clinicValues.ane_mouthopening} checked /><span>{clinicValues.ane_mouthopening} </span></label>
                                            </div> {clinicValues.ane_mouthopening && <a onClick={(e) => updateClinicValues(e, "ane_mouthopening", false)}><span className="material-symbols-outlined"> reply
                                            </span></a>}

                                        </div>
                                        :
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_mouthopening" type="radio"
                                                value="Normal" /><span>Normal</span></label>
                                            </div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_mouthopening" type="radio"
                                                value="Narrow" /><span>Narrow </span></label></div>
                                        </div>}
                                </div>
                                <div className="check_item mt-10"><label className="check">MANDIBLE PROTRUSION </label>
                                    {clinicValues.ane_mandible ?
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input type="radio"
                                                value={clinicValues.ane_mandible} checked /><span>{clinicValues.ane_mandible} </span></label>
                                            </div> {clinicValues.ane_mandible && <a onClick={(e) => updateClinicValues(e, "ane_mandible", false)}><span className="material-symbols-outlined"> reply
                                            </span></a>}

                                        </div>
                                        :
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_mandible" type="radio"
                                                value="Lower incisors anterior to upper Incisors" /><span>Lower incisors anterior to upper Incisors</span></label>
                                            </div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_mandible" type="radio"
                                                value="Lower incisors not anterior to upper Incisors" /><span>Lower incisors not anterior to upper Incisors
                                                </span></label></div>
                                        </div>}
                                </div>
                                <div className="check_item mt-10"><label className="check">NECK MOBILITY/RANGE OF MOTION
                                </label>
                                    {clinicValues.ane_neck ?
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input type="radio"
                                                value={clinicValues.ane_neck} checked /><span>{clinicValues.ane_neck} </span></label>
                                            </div> {clinicValues.ane_neck && <a onClick={(e) => updateClinicValues(e, "ane_neck", false)}><span className="material-symbols-outlined"> reply
                                            </span></a>}

                                        </div>
                                        :
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_neck" type="radio" value="Full" /><span>Full</span></label>
                                            </div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_neck" type="radio"
                                                value="Limited" /><span>Limited </span></label></div>
                                        </div>}
                                </div>
                                <div className="check_item mt-10"><label className="check">THYROMENTAL DISTANCE</label>
                                    {clinicValues.ane_thyromental ?
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input type="radio"
                                                value={clinicValues.ane_thyromental} checked /><span>{clinicValues.ane_thyromental} </span></label>
                                            </div> {clinicValues.ane_thyromental && <a onClick={(e) => updateClinicValues(e, "ane_thyromental", false)}><span className="material-symbols-outlined"> reply
                                            </span></a>}

                                        </div>
                                        :
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_thyromental" type="radio"
                                                value="< 3 finger breadths/6cm" /><span> &lt; 3 finger breadths/6cm</span></label>
                                            </div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_thyromental" type="radio"
                                                value="> 3 finger breadths/6 cm" /><span>
                                                    &gt; 3 finger breadths/6 cm </span></label></div>
                                        </div>}
                                </div>
                                <p className="note2" >The distance from the mentum to the thyroid notch while the patient’s
                                    neck is fully extended. </p>
                                <div className="check_item mt-20"><label className="check">NECK CIRCUMFERENCE </label>
                                    {clinicValues.ane_neck_circumference ?
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input type="radio"
                                                value={clinicValues.ane_neck_circumference} checked /><span>{clinicValues.ane_neck_circumference} </span></label>
                                            </div> {clinicValues.ane_neck_circumference && <a onClick={(e) => updateClinicValues(e, "ane_neck_circumference", false)}><span className="material-symbols-outlined"> reply
                                            </span></a>}

                                        </div>
                                        :
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_neck_circumference" type="radio"
                                                value="Normal" /><span>Normal</span></label>
                                            </div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_neck_circumference" type="radio"
                                                value="Thick" /><span>Thick </span></label></div>
                                        </div>}
                                </div>
                                <div className="check_item mt-10"><label className="check">TEETH : Caps, Crowns, Loose or
                                    artificial teeth </label>
                                    {clinicValues.ane_teeth ?
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input type="radio"
                                                value={clinicValues.ane_teeth} checked /><span>{clinicValues.ane_teeth} </span></label>
                                            </div> {clinicValues.ane_teeth && <a onClick={(e) => updateClinicValues(e, "ane_teeth", false)}><span className="material-symbols-outlined"> reply
                                            </span></a>}

                                        </div>
                                        :
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_teeth" type="radio"
                                                value="RIGHT UPPER" /><span>RIGHT UPPER</span></label>
                                            </div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_teeth" type="radio"
                                                value="LEFT UPPER" /><span>LEFT UPPER </span></label></div>
                                        </div>}
                                </div>
                                <div className="check_item mt-10"><label className="check">or Dentures? </label>
                                    {clinicValues.ane_dentures ?
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input type="radio"
                                                value={clinicValues.ane_dentures} checked /><span>{clinicValues.ane_dentures} </span></label>
                                            </div> {clinicValues.ane_dentures && <a onClick={(e) => updateClinicValues(e, "ane_dentures", false)}><span className="material-symbols-outlined"> reply
                                            </span></a>}

                                        </div>
                                        :
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_dentures" type="radio"
                                                value="RIGHT LOWER" /><span>RIGHT LOWER </span></label>
                                            </div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_dentures" type="radio"
                                                value="LEFT LOWER" /><span> LEFT LOWER</span></label></div>
                                        </div>}
                                </div>
                            </div>
                            <div className="section simple_wound ctr">
                                <h2 className="popup_title">PREANAESTHETIC DISCUSSION </h2>
                                <div className="pd_border"></div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="consent_btn">
                                            <a className="landing_btn">
                                                <img src={surgery} className="icon" />
                                                GENERAL ANAESTHESIA</a>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="consent_btn">
                                            <a className="landing_btn">
                                                <img src={surgery} className="icon" />
                                                SPINAL/CSE/EPIDURAL ANAESTHESIA </a>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="consent_btn">
                                            <a className="landing_btn">
                                                <img src={surgery} className="icon" />
                                                PERIPHERAL NERVE BLOCK </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="section simple_wound ctr">
                                <h2 className="popup_title">GENERAL ANAESTHESIA</h2>
                                <div className="pd_border"></div>
                                <p className="note2" >Select all that apply for Multiselect forms{clinicValues.ane_general_anaesthesia && <a onClick={(e) => updateClinicValues(e, "ane_general_anaesthesia", false)}><span className="material-symbols-outlined"> reply </span></a>}</p>
                                {clinicValues.ane_general_anaesthesia && clinicValues.ane_general_anaesthesia_list && clinicValues.ane_general_anaesthesia_list.length > 0 ? <div className="check_list w_white mb-0">

                                    {clinicValues.ane_general_anaesthesia_list.map((item, i) => (
                                        <div className="check_item active" key={i} onClick={(e) => updateClinicValues(e, "ane_general_anaesthesia", false)} ><label className="check">{item}</label></div>
                                    ))}

                                </div> :
                                    <div className="check_list w_white mb-0">
                                        <span className="check_btn2" onClick={(e) => updateClinicValues(e, "ane_general_anaesthesia", true)}><span className="material-icons"> done </span></span>

                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_general_anaesthesia_list", "Description of process", "ane_general_anaesthesia")} ><label className="check">Description of process</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_general_anaesthesia_list", "Monitoring/ IV access", "ane_general_anaesthesia")}><label className="check">Monitoring/ IV access</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_general_anaesthesia_list", "Face mask and oxygen", "ane_general_anaesthesia")}><label className="check">Face mask and oxygen </label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_general_anaesthesia_list", "Pain and analgesia: SUPPOSITORIES - PREMED", "ane_general_anaesthesia")}><label className="check">Pain and analgesia: SUPPOSITORIES - PREMED</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_general_anaesthesia_list", "Aspiration of stomach contents into the lungs", "ane_general_anaesthesia")}><label className="check">Aspiration of stomach contents into the lungs
                                        </label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_general_anaesthesia_list", "Nausea and vomiting", "ane_general_anaesthesia")}><label className="check">Nausea and vomiting
                                        </label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_general_anaesthesia_list", "Bleeding, Transfusion / Blood salvage", "ane_general_anaesthesia")}><label className="check">Bleeding, Transfusion / Blood salvage
                                        </label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_general_anaesthesia_list", "Allergic reaction", "ane_general_anaesthesia")}><label className="check">Allergic reaction
                                        </label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_general_anaesthesia_list", "IV/gas induction", "ane_general_anaesthesia")}><label className="check">IV/gas induction
                                        </label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_general_anaesthesia_list", ">Sore throat", "ane_general_anaesthesia")}><label className="check">Sore throat
                                        </label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_general_anaesthesia_list", "Dental damage", "ane_general_anaesthesia")}><label className="check">Dental damage
                                        </label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_general_anaesthesia_list", "Arterial line / Central venous line", "ane_general_anaesthesia")}><label className="check">Arterial line / Central venous line
                                        </label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_general_anaesthesia_list", "Patient controlled analgesia (PCA)", "ane_general_anaesthesia")}><label className="check">Patient controlled analgesia (PCA)
                                        </label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_general_anaesthesia_list", "Critical care / Post op CPAP", "ane_general_anaesthesia")}><label className="check">Critical care / Post op CPAP
                                        </label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_general_anaesthesia_list", "Awareness (Rare)", "ane_general_anaesthesia")}><label className="check">Awareness (Rare)
                                        </label>
                                        </div>

                                    </div>}

                            </div>
                            <div className="section simple_wound ctr">
                                <h2 className="popup_title">SPINAL/CSE/EPIDURAL ANAESTHESIA</h2>
                                <div className="pd_border"></div>
                                <p className="note2" >Select all that apply for Multiselect forms{clinicValues.ane_spinal && <a onClick={(e) => updateClinicValues(e, "ane_spinal", false)}><span className="material-symbols-outlined"> reply </span></a>}</p>
                                {clinicValues.ane_spinal && clinicValues.ane_spinal_list && clinicValues.ane_spinal_list.length > 0 ? <div className="check_list w_white mb-0">

                                    {clinicValues.ane_spinal_list.map((item, i) => (
                                        <div className="check_item active" key={i} onClick={(e) => updateClinicValues(e, "ane_spinal", false)} ><label className="check">{item}</label></div>
                                    ))}

                                </div> :
                                    <div className="check_list w_white mb-0">
                                        <span className="check_btn2" onClick={(e) => updateClinicValues(e, "ane_spinal", true)}><span className="material-icons"> done </span></span>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_spinal_list", "Description of process", "ane_spinal")}><label className="check">Description of process</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_spinal_list", "Monitoring/ IV access", "ane_spinal")}><label className="check">Monitoring/ IV access</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_spinal_list", "Face mask and oxygen", "ane_spinal")}><label className="check">Face mask and oxygen </label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_spinal_list", "Pain and analgesia: SUPPOSITORIES - PREMED", "ane_spinal")}><label className="check">Pain and analgesia: SUPPOSITORIES - PREMED</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_spinal_list", "Nausea and vomiting", "ane_spinal")}><label className="check">Nausea and vomiting </label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_spinal_list", "Bleeding, Transfusion / Blood salvage", "ane_spinal")}><label className="check">Bleeding, Transfusion / Blood salvage
                                        </label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_spinal_list", "Allergic reaction", "ane_spinal")}><label className="check">Allergic reaction</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_spinal_list", "Failure", "ane_spinal")}><label className="check">Failure</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_spinal_list", "Need for conversion to GA", "ane_spinal")}><label className="check">Need for conversion to GA </label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_spinal_list", "Motor block", "ane_spinal")}><label className="check">Motor block </label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_spinal_list", "Short-term back pain", "ane_spinal")}><label className="check">Short-term back pain</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_spinal_list", "Hypotension", "ane_spinal")}><label className="check">Hypotension </label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_spinal_list", "Post Dural Puncture Headache > 1%", "ane_spinal")}><label className="check"> Post Dural Puncture Headache &lt; 1% </label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_spinal_list", "Abcess/haematoma 1 in 8-20,000", "ane_spinal")}><label className="check">Abcess/haematoma 1 in 8-20,000 </label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_spinal_list", "Nerve damage 1 in 20-50,000", "ane_spinal")}><label className="check">Nerve damage 1 in 20-50,000 </label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_spinal_list", "Paraplegia/death 1 in 50-140,000", "ane_spinal")}><label className="check">Paraplegia/death 1 in 50-140,000</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_spinal_list", "Itch & shivering", "ane_spinal")}><label className="check">Itch & shivering</label>
                                        </div>
                                    </div>}
                            </div>
                            <div className="section simple_wound ctr">
                                <h2 className="popup_title">PERIPHERAL NERVE BLOCK </h2>
                                <div className="pd_border"></div>
                                <p className="note2" >Select all that apply for Multiselect forms {clinicValues.ane_peripheral && <a onClick={(e) => updateClinicValues(e, "ane_peripheral", false)}><span className="material-symbols-outlined"> reply </span></a>}</p>
                                {clinicValues.ane_peripheral && clinicValues.ane_peripheral_list && clinicValues.ane_peripheral_list.length > 0 ? <div className="check_list w_white mb-0">

                                    {clinicValues.ane_peripheral_list.map((item, i) => (
                                        <div className="check_item active" key={i} onClick={(e) => updateClinicValues(e, "ane_peripheral", false)} ><label className="check">{item}</label></div>
                                    ))}

                                </div> :
                                    <div className="check_list w_white mb-0">
                                        <span className="check_btn2" onClick={(e) => updateClinicValues(e, "ane_peripheral", true)}><span className="material-icons"> done </span></span>

                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_peripheral_list", "Description of process", "ane_peripheral")}><label className="check">Description of process</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_peripheral_list", "Monitoring/ IV access", "ane_peripheral")}><label className="check">Monitoring/ IV access</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_peripheral_list", "Face mask and oxygen", "ane_peripheral")}><label className="check">Face mask and oxygen</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_peripheral_list", "Pain and analgesia", "ane_peripheral")}><label className="check">Pain and analgesia</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_peripheral_list", "Nausea and vomiting", "ane_peripheral")}><label className="check">Nausea and vomiting </label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_peripheral_list", "Bleeding, Transfusion", "ane_peripheral")}><label className="check">Bleeding, Transfusion </label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_peripheral_list", "Allergic reaction", "ane_peripheral")}><label className="check">Allergic reaction</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_peripheral_list", "Pain on injection", "ane_peripheral")}><label className="check">Pain on injection</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_peripheral_list", "Some sensation intact", "ane_peripheral")}><label className="check">Some sensation intact </label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_peripheral_list", "Motor block", "ane_peripheral")}><label className="check">Motor block </label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_peripheral_list", "Failure", "ane_peripheral")}><label className="check">Failure </label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_peripheral_list", "Sedation", "ane_peripheral")}><label className="check">Sedation </label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_peripheral_list", "Neuropraxia 3-8 in 100 > 6 weeks", "ane_peripheral")}><label className="check">Neuropraxia 3-8 in 100 &lt; 6 weeks</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_peripheral_list", "Permanent nerve damage 1 in 2-5,000", "ane_peripheral")}><label className="check">Permanent nerve damage 1 in 2-5,000</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_peripheral_list", "Local anaesthetic toxicity", "ane_peripheral")}><label className="check">Local anaesthetic toxicity</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_peripheral_list", "Infection", "ane_peripheral")}><label className="check">Infection</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_peripheral_list", "Bleeding", "ane_peripheral")} ><label className="check">Bleeding</label>
                                        </div>
                                    </div>}
                            </div>
                            <div className="section simple_wound ctr">
                                <h2 className="popup_title">FURTHER DISCUSSION AND NOTES </h2>
                                <div className="pd_border"></div>
                                <p className="note2" >Select all that apply for Multiselect forms {clinicValues.ane_further && <a onClick={(e) => updateClinicValues(e, "ane_further", false)}><span className="material-symbols-outlined"> reply </span></a>}</p>
                                {clinicValues.ane_further && clinicValues.ane_further_list && clinicValues.ane_further_list.length > 0 ? <div className="check_list w_white mb-0">

                                    {clinicValues.ane_further_list.map((item, i) => (
                                        <div className="check_item active" key={i} onClick={(e) => updateClinicValues(e, "ane_further", false)} ><label className="check">{item}</label></div>
                                    ))}

                                </div> :
                                    <div className="check_list w_white mb-0">

                                        <span className="check_btn2" onClick={(e) => updateClinicValues(e, "ane_further", true)}><span className="material-icons"> done </span></span>

                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_further_list", "You And Your Anaesthetic received and understood", "ane_further")}><label className="check">You And Your Anaesthetic received and understood</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_further_list", "Questions sought and answered", "ane_further")}><label className="check">Questions sought and answered </label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_further_list", "Anaesthetic procedure understood", "ane_further")}><label className="check">Anaesthetic procedure understood
                                        </label>
                                        </div>
                                    </div>}
                                <div className="check_item mt-10"><label className="check">OBTAINED CONSENT </label>
                                    {clinicValues.ane_obtained ?
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input type="radio"
                                                value={clinicValues.ane_obtained} checked /><span>{clinicValues.ane_obtained} </span></label>
                                            </div> {clinicValues.ane_obtained && <a onClick={(e) => updateClinicValues(e, "ane_obtained", false)}><span className="material-symbols-outlined"> reply
                                            </span></a>}

                                        </div>
                                        :
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_obtained" type="radio" value="GA" /><span>GA</span></label>
                                            </div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_obtained" type="radio"
                                                value="SPINAL/CSE/EPIDURAL" /><span> SPINAL/CSE/EPIDURAL </span></label></div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_obtained" type="radio"
                                                value="REGIONAL BLOCK" /><span> REGIONAL BLOCK </span></label></div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_obtained" type="radio"
                                                value="LA INFILTRATION" /><span> LA INFILTRATION </span></label></div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_obtained" type="radio"
                                                value="SEDATION" /><span> SEDATION </span></label></div>

                                        </div>}
                                </div>
                            </div>
                            <div className="section simple_wound ctr">
                                <h2 className="popup_title">ANAESTHESIA CONSENT </h2>
                                <div className="pd_border"></div>
                                <p>I acknowledge and understand that anaesthesia involves certain risks. The risks of
                                    anaesthesia include but are not limited to</p>
                                <div className="check_item mt-10">
                                    {clinicValues.ane_anaesthesia_content ?
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input type="radio"
                                                value={clinicValues.ane_anaesthesia_content} checked /><span>{clinicValues.ane_anaesthesia_content} </span></label>
                                            </div> {clinicValues.ane_anaesthesia_content && <a onClick={(e) => updateClinicValues(e, "ane_anaesthesia_content", false)}><span className="material-symbols-outlined"> reply
                                            </span></a>}

                                        </div> :
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_anaesthesia_content" type="radio"
                                                value="Nausea" /><span>Nausea</span></label>
                                            </div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_anaesthesia_content" type="radio"
                                                value="Vomiting - Sore throat" /><span> Vomiting - Sore throat </span></label></div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_anaesthesia_content" type="radio"
                                                value="Hoarseness of voice" /><span> Hoarseness of voice </span></label></div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_anaesthesia_content" type="radio"
                                                value="Injury to the teeth or other oral structures" /><span> Injury to the teeth or other oral structures
                                                </span></label></div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_anaesthesia_content" type="radio"
                                                value="Allergic reaction" /><span> Allergic reaction </span></label></div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_anaesthesia_content" type="radio"
                                                value="Aspiration of stomach contents into the
                                                lungs" /><span> Aspiration of stomach contents into the
                                                    lungs </span></label></div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_anaesthesia_content" type="radio"
                                                value="Headache" /><span> Headache</span></label></div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_anaesthesia_content" type="radio"
                                                value="Nerve injury" /><span> Nerve injury </span></label></div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_anaesthesia_content" type="radio"
                                                value=" Brain damage/Stroke" /><span> Brain damage/Stroke </span></label></div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_anaesthesia_content" type="radio"
                                                value="Cardiac arrest or death" /><span> Cardiac arrest or death </span></label>
                                            </div>

                                        </div>}
                                </div>
                                <p>I acknowledge and understand that it may become necessary to change the type of
                                    anaesthetic or perform additional procedures during the surgery/procedure if
                                    difficulties or complications develop.</p>
                                <p>I have no further questions and feel that the counselling was satisfactory, therefore
                                    after adequate time for consideration, I consent to the proposed anaesthetic during
                                    the planned surgery/ procedure.</p>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="ane_consent_given" value={clinicValues.ane_consent_given}
                                                className="form-control" autoComplete="off" /><label
                                                    className="floating-label">Consent given </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="ane_refusal" value={clinicValues.ane_refusal}
                                                className="form-control" autoComplete="off" /><label
                                                    className="floating-label">Refusal to consent</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                    {file.patient_signature_result || clinicValues.patient_signature_result ? <img src={file.patient_signature_result || clinicValues.patient_signature_result} /> :
                                        <div className="signature_block mb-10">
                                            <SignaturePad canvasProps={{ className: "sigPad" }} ref={(ref) => { patientsigPad = ref }} />
                                            <p>Patient’s Signature </p>
                                            <button onClick={(e) => trim(e, "patient_signature", patientsigPad)}>Trim</button>
                                            <button onClick={(e) => clear(e, patientsigPad)}> Clear</button>
                                           
                                        </div>}
                                    </div>
                                    <div className="col-md-6">
                                    {file.patient_relation_sign_result || clinicValues.patient_relation_sign_result ? <img src={file.patient_relation_sign_result || clinicValues.patient_relation_sign_result} /> :
                                        <div className="signature_block mb-10">
                                            <SignaturePad canvasProps={{ className: "sigPad" }} ref={(ref) => { patientguardiansigPad = ref }} />
                                            <p>Guardian’s signature (Patient representative that is legally responsible)
                                            </p>
                                            <button onClick={(e) => trim(e, "patient_relation_sign", patientguardiansigPad)}>Trim</button>
                                            <button onClick={(e) => clear(e, patientguardiansigPad)}> Clear</button>
                                        </div>}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="ane_sig_name" value={clinicValues.ane_sig_name}
                                                className="form-control" autoComplete="off" /><label
                                                    className="floating-label">Name </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="ane_sig_relation" value={clinicValues.ane_sig_relation}
                                                className="form-control" autoComplete="off" /><label
                                                    className="floating-label">Relationship</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="yn_switch  mt-10">
                                        <div className="label-container">
                                            <p>Interpreter Required </p>
                                        </div>
                                        <label className="switch-container">
                                            <input type="checkbox" className="switch_check"
                                                name="ane_interpreter" onChange={(e) => handleChange(e)} checked={clinicValues.ane_interpreter} />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>YES</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>NO</span>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form_group">
                                        <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="ane_witness_name" value={clinicValues.ane_witness_name}
                                            className="form-control" autoComplete="off" /><label
                                                className="floating-label">Witness/employee name</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form_group">
                                        <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="ane_witness_signature" value={clinicValues.ane_witness_signature}
                                            className="form-control" autoComplete="off" /><label
                                                className="floating-label">Witness signature</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">

                                {file.witnessSign_result || clinicValues.witnessSign_result ? <img src={file.witnessSign_result || clinicValues.witnessSign_result} /> :
                                        <div className="signature_block mb-10">
                                            <SignaturePad canvasProps={{ className: "sigPad" }} ref={(ref) => { witnessSign = ref }} />
                                            <p>Signature </p>
                                            <button onClick={(e) => trim(e, "witnessSign", witnessSign)}>Trim</button>
                                            <button onClick={(e) => clear(e, witnessSign)}> Clear</button>
                                        </div>}

                                    
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="ane_anaesthetic_name" value={clinicValues.ane_anaesthetic_name}
                                                className="form-control" autoComplete="off" /><label
                                                    className="floating-label">Anaesthetist’s name </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="ane_anaesthetic_signature" value={clinicValues.ane_anaesthetic_signature}
                                                className="form-control" autoComplete="off" />
                                                <label
                                                    className="floating-label">Anaesthetist’s Signature </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="date"  onChange={(e) => updateInputValue(e)} name="ane_anaesthetic_date" value={clinicValues.ane_anaesthetic_date}
                                                className="form-control" autoComplete="off" /><label
                                                    className="floating-label">Date </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                    {file.anaesthetistSign_result || clinicValues.anaesthetistSign_result ? <img src={file.anaesthetistSign_result || clinicValues.anaesthetistSign_result} /> :
                                        <div className="signature_block mb-10">
                                            <SignaturePad canvasProps={{ className: "sigPad" }} ref={(ref) => { anaesthetistSign = ref }} />
                                            <p>Signature </p>
                                            <button onClick={(e) => trim(e, "anaesthetistSign", anaesthetistSign)}>Trim</button>
                                            <button onClick={(e) => clear(e, anaesthetistSign)}> Clear</button>
                                        </div>}
                                    </div>
                                </div>
                            </div>



                            <div className="section simple_wound ctr">
                                <h2 className="popup_title">PRE-OPERATIVE PRESCRIPTIONS (For use by Anaesthetists ONLY)</h2>
                                <div className="pd_border"></div>
                                <div className="prescription_tbl mb-20">
                                    <table className="code_table table-bordered sin">
                                        <thead>
                                            <tr>
                                                <th width="10%">Date</th>
                                                <th width="10%">Time</th>
                                                <th width="20%">Drug</th>
                                                <th width="10%">Dose</th>
                                                <th width="10%">Route</th>
                                                <th width="15%">Signature </th>
                                                <th width="15%">Given by </th>
                                                <th width="10%">Time </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td> <input type="date" className="field" onChange={(e) => updateInputValue(e)} name="ane_preop_date1" value={clinicValues.ane_preop_date1} /></td>
                                                <td><input type="time" className="field" onChange={(e) => updateInputValue(e)} name="ane_preop_time1" value={clinicValues.ane_preop_time1} /></td>
                                                <td><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="ane_preop_drug1" value={clinicValues.ane_preop_drug1} /></td>
                                                <td><input type="text" className="field" placeholder="ml" onChange={(e) => updateInputValue(e)} name="ane_preop_dose1" value={clinicValues.ane_preop_dose1} /></td>
                                                <td><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="ane_preop_route1" value={clinicValues.ane_preop_route1} /></td>
                                                <td>

                                                {file.ane_preop_sig1_result || clinicValues.ane_preop_sig1_result ? <img src={file.ane_preop_sig1_result || clinicValues.ane_preop_sig1_result} /> :
                                                <div className="signature_block">
                                                    <SignaturePad canvasProps={{ className: "sigPad" }} ref={(ref) => { ane_preop_sig1 = ref }} />
                                                    <p>Signature </p>
                                                    <button onClick={(e) => trim(e, "ane_preop_sig1", ane_preop_sig1)}>Trim</button>
                                                    <button onClick={(e) => clear(e, ane_preop_sig1)}> Clear</button>
                                                </div>}

                                                </td>
                                                <td> <input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="ane_preop_given1" value={clinicValues.ane_preop_given1} /> </td>
                                                <td> <input type="time" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="ane_preop_sig_time1" value={clinicValues.ane_preop_sig_time1} /> </td>
                                            </tr>
                                            <tr>
                                            <td> <input type="date" className="field" onChange={(e) => updateInputValue(e)} name="ane_preop_date2" value={clinicValues.ane_preop_date2} /></td>
                                                <td><input type="time" className="field" onChange={(e) => updateInputValue(e)} name="ane_preop_time2" value={clinicValues.ane_preop_time2} /></td>
                                                <td><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="ane_preop_drug2" value={clinicValues.ane_preop_drug2} /></td>
                                                <td><input type="text" className="field" placeholder="ml" onChange={(e) => updateInputValue(e)} name="ane_preop_dose2" value={clinicValues.ane_preop_dose2} /></td>
                                                <td><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="ane_preop_route2" value={clinicValues.ane_preop_route2} /></td>
                                                <td>
                                                {file.ane_preop_sig2_result || clinicValues.ane_preop_sig2_result ? <img src={file.ane_preop_sig2_result || clinicValues.ane_preop_sig2_result} /> :
                                                <div className="signature_block">
                                                    <SignaturePad canvasProps={{ className: "sigPad" }} ref={(ref) => { ane_preop_sig2 = ref }} />
                                                    <p>Signature </p>
                                                    <button onClick={(e) => trim(e, "ane_preop_sig2", ane_preop_sig2)}>Trim</button>
                                                    <button onClick={(e) => clear(e, ane_preop_sig2)}> Clear</button>
                                                </div>}

                                                </td>
                                                <td> <input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="ane_preop_given2" value={clinicValues.ane_preop_given2} /> </td>
                                                <td> <input type="time" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="ane_preop_sig_time2" value={clinicValues.ane_preop_sig_time2} /> </td>
                                            </tr>
                                            <tr>
                                            <td> <input type="date" className="field" onChange={(e) => updateInputValue(e)} name="ane_preop_date3" value={clinicValues.ane_preop_date3} /></td>
                                                <td><input type="time" className="field" onChange={(e) => updateInputValue(e)} name="ane_preop_time3" value={clinicValues.ane_preop_time3} /></td>
                                                <td><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="ane_preop_drug3" value={clinicValues.ane_preop_drug3} /></td>
                                                <td><input type="text" className="field" placeholder="ml" onChange={(e) => updateInputValue(e)} name="ane_preop_dose3" value={clinicValues.ane_preop_dose3} /></td>
                                                <td><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="ane_preop_route3" value={clinicValues.ane_preop_route3} /></td>
                                                <td>
                                                {file.ane_preop_sig3_result || clinicValues.ane_preop_sig3_result ? <img src={file.ane_preop_sig3_result || clinicValues.ane_preop_sig3_result} /> :
                                                <div className="signature_block">
                                                    <SignaturePad canvasProps={{ className: "sigPad" }} ref={(ref) => { ane_preop_sig3 = ref }} />
                                                    <p>Signature </p>
                                                    <button onClick={(e) => trim(e, "ane_preop_sig3", ane_preop_sig3)}>Trim</button>
                                                    <button onClick={(e) => clear(e, ane_preop_sig3)}> Clear</button>
                                                </div>}
                                                </td>
                                                <td> <input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="ane_preop_given3" value={clinicValues.ane_preop_given3} /> </td>
                                                <td> <input type="time" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="ane_preop_sig_time3" value={clinicValues.ane_preop_sig_time3} /> </td>
                                            </tr>
                                            <tr>
                                            <td> <input type="date" className="field" onChange={(e) => updateInputValue(e)} name="ane_preop_date4" value={clinicValues.ane_preop_date4} /></td>
                                                <td><input type="time" className="field" onChange={(e) => updateInputValue(e)} name="ane_preop_time4" value={clinicValues.ane_preop_time4} /></td>
                                                <td><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="ane_preop_drug4" value={clinicValues.ane_preop_drug4} /></td>
                                                <td><input type="text" className="field" placeholder="ml" onChange={(e) => updateInputValue(e)} name="ane_preop_dose4" value={clinicValues.ane_preop_dose4} /></td>
                                                <td><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="ane_preop_route4" value={clinicValues.ane_preop_route4} /></td>
                                                <td>
                                                {file.ane_preop_sig4_result || clinicValues.ane_preop_sig4_result ? <img src={file.ane_preop_sig4_result || clinicValues.ane_preop_sig4_result} /> :
                                                <div className="signature_block">
                                                    <SignaturePad canvasProps={{ className: "sigPad" }} ref={(ref) => { ane_preop_sig4 = ref }} />
                                                    <p>Signature </p>
                                                    <button onClick={(e) => trim(e, "ane_preop_sig4", ane_preop_sig4)}>Trim</button>
                                                    <button onClick={(e) => clear(e, ane_preop_sig4)}> Clear</button>
                                                </div>}
                                                </td>
                                                <td> <input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="ane_preop_given4" value={clinicValues.ane_preop_given4} /> </td>
                                                <td> <input type="time" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="ane_preop_sig_time4" value={clinicValues.ane_preop_sig_time4} /> </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                </div>
                                <p className="note2" >Select all that apply for Multiselect forms {clinicValues.ane_mac_equp && <a onClick={(e) => updateClinicValues(e, "ane_mac_equp", false)}><span className="material-symbols-outlined"> reply </span></a>}</p>
                                {clinicValues.ane_mac_equp && clinicValues.ane_mac_equp_list && clinicValues.ane_mac_equp_list.length > 0 ? <div className="check_list w_white mb-0">

                                    {clinicValues.ane_mac_equp_list.map((item, i) => (
                                        <div className="check_item active" key={i} onClick={(e) => updateClinicValues(e, "ane_mac_equp", false)} ><label className="check">{item}</label></div>
                                    ))}

                                </div> :

                                <div className="check_list w_white mb-0">
                                    <span className="check_btn2" onClick={(e) => updateClinicValues(e, "ane_mac_equp", true)}><span className="material-icons"> done </span></span>

                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_mac_equp_list", "Machine & equipment checked and functional", "ane_mac_equp")}><label className="check">Machine & equipment checked and functional </label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_mac_equp_list", "Appropriate drugs set out for the list", "ane_mac_equp")}><label className="check">Appropriate drugs set out for the list</label>
                                    </div>
                                </div>}
                                <p className="field_title bold mt-20">ANAESTHETIC INDUCTION </p>
                                <div className="row mt-10">
                                    <div className="col-md-4">
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="time" onChange={(e) => updateInputValue(e)} name="ane_anaesthetic_induction" value={clinicValues.ane_anaesthetic_induction}
                                                className="form-control" autoComplete="off" /><label
                                                    className="floating-label">Time</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="date" onChange={(e) => updateInputValue(e)} name="ane_anaesthetic_induction_date" value={clinicValues.ane_anaesthetic_induction_date}
                                                className="form-control" autoComplete="off" /><label
                                                    className="floating-label">Date</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p className="field_title bold mt-20">PRIOR TO INDUCTION </p>

                               
                                <p className="note2" >Select all that apply for Multiselect forms {clinicValues.ane_sign_in_stage && <a onClick={(e) => updateClinicValues(e, "ane_sign_in_stage", false)}><span className="material-symbols-outlined"> reply </span></a>}</p>
                                {clinicValues.ane_sign_in_stage && clinicValues.ane_sign_in_stage_list && clinicValues.ane_sign_in_stage_list.length > 0 ? <div className="check_list w_white mb-0">

                                    {clinicValues.ane_sign_in_stage_list.map((item, i) => (
                                        <div className="check_item active" key={i} onClick={(e) => updateClinicValues(e, "ane_sign_in_stage", false)} ><label className="check">{item}</label></div>
                                    ))}

                                </div> :
                                 <div className="check_list w_white mb-0">
                                    <span className="check_btn2" onClick={(e) => updateClinicValues(e, "ane_sign_in_stage", true)}><span className="material-icons"> done </span></span>
                                    <div className="check_item"  onClick={(e) => updateMultipleValues(e, "ane_sign_in_stage_list", "‘Sign in’ stage of WHO Surgical Safety Final Verbal Checklist read OUT LOUD with no discrepancies", "ane_sign_in_stage")} ><label className="check">‘Sign in’ stage of WHO Surgical Safety Final Verbal Checklist read OUT LOUD with no discrepancies</label>
                                    </div>

                                </div>}

                                <p className="field_title bold mt-20">PERIPHERAL CANNULA </p>
                                <div className="row mt-10">
                                    <div className="col-md-3">
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="ane_peripheral_time_inserted1" value={clinicValues.ane_peripheral_time_inserted1}
                                                className="form-control" autoComplete="off" /><label
                                                    className="floating-label">Time inserted</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-2">
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="ane_peripheral_size1" value={clinicValues.ane_peripheral_size1}
                                                className="form-control" autoComplete="off" /><label
                                                    className="floating-label">Size</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="ane_peripheral_location1" value={clinicValues.ane_peripheral_location1}
                                                className="form-control" autoComplete="off" /><label
                                                    className="floating-label">Location</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="ane_peripheral_attempts1" value={clinicValues.ane_peripheral_attempts1}
                                                className="form-control" autoComplete="off" /><label
                                                    className="floating-label">Number of attempts</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-10">
                                    <div className="col-md-3">
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="ane_peripheral_time_inserted2" value={clinicValues.ane_peripheral_time_inserted2}
                                                className="form-control" autoComplete="off" /><label
                                                    className="floating-label">Time inserted</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-2">
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text"  onChange={(e) => updateInputValue(e)} name="ane_peripheral_size2" value={clinicValues.ane_peripheral_size2}
                                                className="form-control" autoComplete="off" /><label
                                                    className="floating-label">Size</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="ane_peripheral_location2" value={clinicValues.ane_peripheral_location2}
                                                className="form-control" autoComplete="off" /><label
                                                    className="floating-label">Location</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text"  onChange={(e) => updateInputValue(e)} name="ane_peripheral_attempts2" value={clinicValues.ane_peripheral_attempts2}
                                                className="form-control" autoComplete="off" /><label
                                                    className="floating-label">Number of attempts</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p className="field_title bold mt-20">ANAESTHETIC GIVEN </p>
                                <div className="check_item mt-0">
                                {clinicValues.ane_anaesthetic_given ?
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input type="radio"
                                            value={clinicValues.ane_anaesthetic_given} checked /><span>{clinicValues.ane_anaesthetic_given} </span></label>
                                        </div> {clinicValues.ane_anaesthetic_given && <a onClick={(e) => updateClinicValues(e, "ane_anaesthetic_given", false)}><span className="material-symbols-outlined"> reply
                                        </span></a>}

                                    </div>
                                    :
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_anaesthetic_given" type="radio"
                                            value="General" /><span>General</span></label>
                                        </div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_anaesthetic_given" type="radio"
                                            value="Epidural" /><span>
                                                Epidural </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_anaesthetic_given" type="radio"
                                            value="Spinal Block" /><span>
                                                Spinal Block </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_anaesthetic_given" type="radio"
                                            value="Regional" /><span>
                                                Regional </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_anaesthetic_given" type="radio"
                                            value="Sedation" /><span>
                                                Sedation </span></label></div>
                                    </div>
                                }
                                </div>
                                <div className="form_group mb-20">
                                    <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="ane_anaesthetic_given_other" value={clinicValues.ane_anaesthetic_given_other} className="form-control"
                                        autoComplete="off" /><label className="floating-label">Other</label>
                                    </div>
                                </div>
                                <p className="field_title bold mt-20">AIRWAY ADJUNCTS </p>
                                <div className="check_item mt-0">
                                {clinicValues.ane_airway_adjunct ?
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input type="radio"
                                            value={clinicValues.ane_airway_adjunct} checked /><span>{clinicValues.ane_airway_adjunct} </span></label>
                                        </div> {clinicValues.ane_airway_adjunct && <a onClick={(e) => updateClinicValues(e, "ane_airway_adjunct", false)}><span className="material-symbols-outlined"> reply
                                        </span></a>}

                                    </div>
                                    :
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_airway_adjunct" type="radio"
                                            value="LMA" /><span>LMA</span></label>
                                        </div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_airway_adjunct" type="radio"
                                            value="ET tube" /><span>
                                                ET tube </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_airway_adjunct" type="radio"
                                            value="Mask & airway" /><span>
                                                Mask & airway </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_airway_adjunct" type="radio"
                                            value="Nasal tube Airway" /><span>
                                                Nasal tube Airway </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_airway_adjunct" type="radio"
                                            value="Oral Airway" /><span> Oral Airway </span></label></div>
                                    </div>}
                                </div>
                                <p className="field_title bold mt-20">BREATHING SYSTEM</p>
                                <div className="check_item mt-0">
                                    {clinicValues.ane_breathing_system ?
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input type="radio"
                                            value={clinicValues.ane_breathing_system} checked /><span>{clinicValues.ane_breathing_system} </span></label>
                                        </div> {clinicValues.ane_breathing_system && <a onClick={(e) => updateClinicValues(e, "ane_breathing_system", false)}><span className="material-symbols-outlined"> reply
                                        </span></a>}

                                    </div>
                                    :
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_breathing_system"  type="radio"
                                            value="Spontaneous" /><span>Spontaneous </span></label>
                                        </div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_breathing_system" type="radio"
                                            value="Assisted" /><span>
                                                Assisted </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_breathing_system" type="radio"
                                            value="Ventilator" /><span>
                                                Ventilator </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_breathing_system" type="radio"
                                            value="Manual" /><span>
                                                Manual </span></label></div>
                                    </div>}
                                </div>
                                <div className="yn_switch mb-20  mt-10">
                                    <div className="label-container">
                                        <p>THROAT PACK PRESENT? </p>
                                    </div>
                                    <label className="switch-container">
                                        <input name="ane_throat" onChange={(e) => handleChange(e)} checked={clinicValues.ane_throat} type="checkbox" className="switch_check"  />
                                        <div className="switch-bg"></div>
                                        <div className="round-box"></div>
                                        <div className="switch-left">
                                            <span>YES</span>
                                        </div>
                                        <div className="switch-right">
                                            <span>NO</span>
                                        </div>
                                    </label>
                                </div>
                                <p className="field_title bold mt-20">EYE PROTECTION </p>
                                <div className="check_item mt-0">
                                {clinicValues.ane_eye_protection ?
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input type="radio"
                                            value={clinicValues.ane_eye_protection} checked /><span>{clinicValues.ane_eye_protection} </span></label>
                                        </div> {clinicValues.ane_eye_protection && <a onClick={(e) => updateClinicValues(e, "ane_eye_protection", false)}><span className="material-symbols-outlined"> reply
                                        </span></a>}

                                    </div>
                                    :
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_eye_protection" type="radio"
                                            value="Tape" /><span>Tape</span></label>
                                        </div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_eye_protection" type="radio"
                                            value="Shields" /><span>
                                                Shields </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_eye_protection" type="radio"
                                            value="Pads" /><span>
                                                Pads </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_eye_protection" type="radio"
                                            value="Ointment" /><span>
                                                Ointment </span></label></div>
                                    </div>}
                                </div>
                                <div className="form_group ">
                                    <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="ane_eye_other" value={clinicValues.ane_eye_other} className="form-control"
                                        autoComplete="off" /><label className="floating-label">Other</label>
                                    </div>
                                </div>
                                <div className="form_group mb-20 mt-30">
                                    <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="ane_eye_drugs" value={clinicValues.ane_eye_drugs} className="form-control"
                                        autoComplete="off" /><label className="floating-label">DRUGS
                                            GIVEN</label>
                                    </div>
                                </div>
                                <div className="form_group mb-20">
                                    <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="ane_eye_additional" value={clinicValues.ane_eye_additional} className="form-control"
                                        autoComplete="off" /><label className="floating-label">Additional
                                            comments</label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form_group mb-20">
                                            <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="ane_eye_anaesthetic" value={clinicValues.ane_eye_anaesthetic} 
                                                className="form-control" autoComplete="off" /><label
                                                    className="floating-label">Anaesthetic Practitioner</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">

                                    {file.anaestheticPractitionerSign_result || clinicValues.anaestheticPractitionerSign_result ? <img src={file.anaestheticPractitionerSign_result || clinicValues.anaestheticPractitionerSign_result} /> :
                                <div className="signature_block">
                                    <SignaturePad canvasProps={{ className: "sigPad" }} ref={(ref) => { anaestheticPractitionerSign = ref }} />
                                    <p>Signature </p>
                                    <button onClick={(e) => trim(e, "anaestheticPractitionerSign", anaestheticPractitionerSign)}>Trim</button>
                                    <button onClick={(e) => clear(e, anaestheticPractitionerSign)}> Clear</button>
                                </div>}

                                    
                                    </div>
                                </div>
                            </div>
                            <div className="section simple_wound ctr">
                                <h2 className="popup_title">ANAESTHETIC RECORD </h2>
                                <div className="pd_border"></div>
                                <div className="row">
                                    <div className="col-md-5">
                                        <div className="form_group mb-20">
                                            <div className="floating-label-group"><input type="text"  value={clinicValues.firstname + " "+ clinicValues.lastname}
                                                className="form-control" autoComplete="off" /><label
                                                    className="floating-label">Patient name</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form_group mb-20">
                                            <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="ane_medicalrecord" value={clinicValues.ane_medicalrecord}
                                                className="form-control" autoComplete="off" /><label
                                                    className="floating-label">Medical record number</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form_group mb-20">
                                            <div className="floating-label-group"><input type="date" onChange={(e) => updateInputValue(e)} name="ane_medicalrecord_dob" value={clinicValues.ane_medicalrecord_dob}
                                                className="form-control" autoComplete="off" /><label
                                                    className="floating-label">Date of birth</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form_group mb-20">
                                            <div className="floating-label-group"><input type="date" onChange={(e) => updateInputValue(e)} name="ane_medicalrecord_doa" value={clinicValues.ane_medicalrecord_doa}
                                                className="form-control" autoComplete="off" /><label
                                                    className="floating-label">Date of anaesthesia</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-7">
                                        <div className="check_item mt-0">
                                        {clinicValues.ane_elective ?
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input type="radio"
                                                    value={clinicValues.ane_elective} checked /><span>{clinicValues.ane_elective} </span></label>
                                                </div> {clinicValues.ane_elective && <a onClick={(e) => updateClinicValues(e, "ane_elective", false)}><span className="material-symbols-outlined"> reply
                                                </span></a>}

                                            </div>
                                            :
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_elective" type="radio"
                                                    value="Elective" /><span>Elective </span></label>
                                                </div>
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_elective" type="radio"
                                                    value="Trauma/Urgent" /><span> Trauma/Urgent </span></label></div>
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_elective" type="radio"
                                                    value="Emergency" /><span> Emergency </span></label></div>

                                            </div>}
                                        </div>
                                    </div>

                                    <div className="form_group mb-20">
                                        <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="ane_medicalrecord_operation" value={clinicValues.ane_medicalrecord_operation}
                                            className="form-control" autoComplete="off" /><label
                                                className="floating-label">OPERATION</label>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-5">
                                        <div className="form_group mb-20">
                                            <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="ane_anaethetist" value={clinicValues.ane_anaethetist}
                                                className="form-control" autoComplete="off" /><label
                                                    className="floating-label">ANAESTHETIST</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form_group mb-20">
                                            <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="ane_gmc_no" value={clinicValues.ane_gmc_no}
                                                className="form-control" autoComplete="off" /><label
                                                    className="floating-label">GMC
                                                    Number</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form_group mb-20">
                                            <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="ane_grade" value={clinicValues.ane_grade}
                                                className="form-control" autoComplete="off" /><label
                                                    className="floating-label">Grade</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="form_group mb-20">
                                            <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="ane_other_anaesthetist" value={clinicValues.ane_other_anaesthetist}
                                                className="form-control" autoComplete="off" /><label
                                                    className="floating-label">OTHER
                                                    ANAESTHETIST</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form_group mb-20">
                                            <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="ane_other_gmc_no" value={clinicValues.ane_other_gmc_no}
                                                className="form-control" autoComplete="off" /><label
                                                    className="floating-label">GMC
                                                    Number</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form_group mb-20">
                                            <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="ane_other_grade" value={clinicValues.ane_other_grade}
                                                className="form-control" autoComplete="off" /><label
                                                    className="floating-label">Grade</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="check_item mt-10"><label className="check">ANAESTHESIA</label>
                                {clinicValues.ane_anaesthesia_ga ?
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input type="radio"
                                                    value={clinicValues.ane_anaesthesia_ga} checked /><span>{clinicValues.ane_anaesthesia_ga} </span></label>
                                                </div> {clinicValues.ane_anaesthesia_ga && <a onClick={(e) => updateClinicValues(e, "ane_anaesthesia_ga", false)}><span className="material-symbols-outlined"> reply
                                                </span></a>}

                                            </div>
                                            :
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_anaesthesia_ga" type="radio"
                                            value="GA" /><span>GA</span></label>
                                        </div>
                                        <div className="select_btn"><label><input  onClick={(e) => updateInputValue(e)} name="ane_anaesthesia_ga" type="radio" value="Spinal / Epidural" /><span>
                                            Spinal / Epidural </span></label></div>
    
                                        <div className="select_btn"><label><input  onClick={(e) => updateInputValue(e)} name="ane_anaesthesia_ga" type="radio" value="Regional block" /><span>
                                            Regional block </span></label></div>
                                        <div className="select_btn"><label><input  onClick={(e) => updateInputValue(e)} name="ane_anaesthesia_ga" type="radio" value="LA" /><span>
                                            LA
                                        </span></label></div>

                                    </div>}
                                </div>
                            </div>

                            <div className="section simple_wound ctr">
                                <h2 className="popup_title">START</h2>
                                <div className="pd_border"></div>
                                <div className="check_item mt-0">
                                {clinicValues.ane_start ?
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input type="radio"
                                            value={clinicValues.ane_start} checked /><span>{clinicValues.ane_start} </span></label>
                                        </div> {clinicValues.ane_start && <a onClick={(e) => updateClinicValues(e, "ane_start", false)}><span className="material-symbols-outlined"> reply
                                        </span></a>}

                                    </div>
                                    :
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_start" type="radio" value="Elective Case" /><span>Elective Case </span></label>
                                        </div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_start" type="radio" value="Emergency case" /><span>Emergency case </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_start" type="radio" value="Take over case" /><span> Take over case </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_start" type="radio" value="View case" /><span> View case</span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_start" type="radio" value="Finalize case" /><span> Finalize case</span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_start" type="radio" value="Intubation / Blood patch" /><span> Intubation / Blood patch</span></label></div>
                                    </div>}
                                </div>
                            </div>
                            <div className="section simple_wound ctr">
                                <h2 className="popup_title">SAFETY </h2>
                                <div className="pd_border"></div>
                                <p className="note2" >Select all that apply for Multiselect forms {clinicValues.ane_safety && <a onClick={(e) => updateClinicValues(e, "ane_safety", false)}><span className="material-symbols-outlined"> reply </span></a>}</p>
                                {clinicValues.ane_safety && clinicValues.ane_safety_list && clinicValues.ane_safety_list.length > 0 ? <div className="check_list w_white mb-0">

                                    {clinicValues.ane_safety_list.map((item, i) => (
                                        <div className="check_item active" key={i} onClick={(e) => updateClinicValues(e, "ane_safety", false)} ><label className="check">{item}</label></div>
                                    ))}

                                </div> :
                                <div className="check_list w_white mb-0">
                                    <span className="check_btn2" onClick={(e) => updateClinicValues(e, "ane_safety", true)}><span className="material-icons"> done </span></span>

                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_safety_list", "Machine Checks Done", "ane_safety")}><label className="check">Machine Checks Done </label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_safety_list", "Patient identified", "ane_safety")}><label className="check">Patient identified</label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_safety_list", "Notes reviewed", "ane_safety")}><label className="check">Notes reviewed</label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_safety_list", "Consent signed", "ane_safety")}><label className="check">Consent signed</label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_safety_list", "Time out performed", "ane_safety")}><label className="check">Time out performed</label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_safety_list", "Antibiotics given (if indicated)", "ane_safety")}><label className="check">Antibiotics given (if indicated)</label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_safety_list", "Beta-blockers given (if indicated)", "ane_safety")}><label className="check">Beta-blockers given (if indicated)</label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_safety_list", "Proceed", "ane_safety")}><label className="check">Proceed</label>
                                    </div>

                                </div>}
                            </div>
                            <div className="section simple_wound ctr">

                                <div className="prescription_tbl mb-20">
                                    <table className="code_table table-bordered sin">
                                        <thead>
                                            <tr>
                                                <th width="20%">PREMEDICATION Medication</th>
                                                <th width="10%">Dose</th>
                                                <th width="10%">volume</th>
                                                <th width="10%">Route </th>
                                                <th width="10%">Time administered</th>
                                                <th width="20%">Administered by </th>
                                                <th width="20%">Signature </th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td> <input type="text" className="field" onChange={(e) => updateInputValue(e)} name="ane_pre_medication1" value={clinicValues.ane_pre_medication1} /></td>
                                                <td><input type="text" className="field" onChange={(e) => updateInputValue(e)} name="ane_dose1" value={clinicValues.ane_dose1} /></td>
                                                <td><input type="text" className="field" onChange={(e) => updateInputValue(e)} name="ane_volume1" value={clinicValues.ane_volume1} /></td>
                                                <td><input type="text" className="field" onChange={(e) => updateInputValue(e)} name="ane_route1" value={clinicValues.ane_route1} /></td>
                                                <td><input type="text" className="field" onChange={(e) => updateInputValue(e)} name="ane_time_admin1" value={clinicValues.ane_time_admin1} /></td>
                                                <td> <input type="text" className="field" onChange={(e) => updateInputValue(e)} name="ane_sig1" value={clinicValues.ane_sig1} /> </td>
                                                <td>

                                                    
                                                {file.ane_pre_sig1_result || clinicValues.ane_pre_sig1_result ? <img src={file.ane_pre_sig1_result || clinicValues.ane_pre_sig1_result} /> :
                                                <div className="signature_block">
                                                    <SignaturePad canvasProps={{ className: "sigPad" }} ref={(ref) => { ane_pre_sig1 = ref }} />
                                                    <p>Signature </p>
                                                    <button onClick={(e) => trim(e, "ane_pre_sig1", ane_pre_sig1)}>Trim</button>
                                                    <button onClick={(e) => clear(e, ane_pre_sig1)}> Clear</button>
                                                </div>}

                                                </td>
                                            </tr>
                                            <tr>
                                                <td> <input type="text" className="field" onChange={(e) => updateInputValue(e)} name="ane_pre_medication2" value={clinicValues.ane_pre_medication2} /></td>
                                                <td><input type="text" className="field" onChange={(e) => updateInputValue(e)} name="ane_dose2" value={clinicValues.ane_dose2} /></td>
                                                <td><input type="text" className="field" onChange={(e) => updateInputValue(e)} name="ane_volume2" value={clinicValues.ane_volume2} /></td>
                                                <td><input type="text" className="field" onChange={(e) => updateInputValue(e)} name="ane_route2" value={clinicValues.ane_route2} /></td>
                                                <td><input type="text" className="field" onChange={(e) => updateInputValue(e)} name="ane_time_admin2" value={clinicValues.ane_time_admin2} /></td>
                                                <td> <input type="text" className="field" onChange={(e) => updateInputValue(e)} name="ane_sig2" value={clinicValues.ane_sig2} /> </td>
                                                <td>
                                               
                                                {file.ane_pre_sig2_result || clinicValues.ane_pre_sig2_result ? <img src={file.ane_pre_sig2_result || clinicValues.ane_pre_sig2_result} /> :
                                                <div className="signature_block">
                                                    <SignaturePad canvasProps={{ className: "sigPad" }} ref={(ref) => { ane_pre_sig2 = ref }} />
                                                    <p>Signature </p>
                                                    <button onClick={(e) => trim(e, "ane_pre_sig2", ane_pre_sig2)}>Trim</button>
                                                    <button onClick={(e) => clear(e, ane_pre_sig2)}> Clear</button>
                                                </div>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td> <input type="text" className="field" onChange={(e) => updateInputValue(e)} name="ane_pre_medication3" value={clinicValues.ane_pre_medication3} /></td>
                                                <td><input type="text" className="field" onChange={(e) => updateInputValue(e)} name="ane_dose3" value={clinicValues.ane_dose3} /></td>
                                                <td><input type="text" className="field" onChange={(e) => updateInputValue(e)} name="ane_volume3" value={clinicValues.ane_volume3} /></td>
                                                <td><input type="text" className="field" onChange={(e) => updateInputValue(e)} name="ane_route3" value={clinicValues.ane_route3} /></td>
                                                <td><input type="text" className="field" onChange={(e) => updateInputValue(e)} name="ane_time_admin3" value={clinicValues.ane_time_admin3} /></td>
                                                <td> <input type="text" className="field" onChange={(e) => updateInputValue(e)} name="ane_sig3" value={clinicValues.ane_sig3} /> </td>
                                                <td>
                                                {file.ane_pre_sig3_result || clinicValues.ane_pre_sig3_result ? <img src={file.ane_pre_sig3_result || clinicValues.ane_pre_sig3_result} /> :
                                                <div className="signature_block">
                                                    <SignaturePad canvasProps={{ className: "sigPad" }} ref={(ref) => { ane_pre_sig3 = ref }} />
                                                    <p>Signature </p>
                                                    <button onClick={(e) => trim(e, "ane_pre_sig3", ane_pre_sig3)}>Trim</button>
                                                    <button onClick={(e) => clear(e, ane_pre_sig3)}> Clear</button>
                                                </div>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td> <input type="text" className="field" onChange={(e) => updateInputValue(e)} name="ane_pre_medication4" value={clinicValues.ane_pre_medication4} /></td>
                                                <td><input type="text" className="field" onChange={(e) => updateInputValue(e)} name="ane_dose4" value={clinicValues.ane_dose4} /></td>
                                                <td><input type="text" className="field" onChange={(e) => updateInputValue(e)} name="ane_volume4" value={clinicValues.ane_volume4} /></td>
                                                <td><input type="text" className="field" onChange={(e) => updateInputValue(e)} name="ane_route4" value={clinicValues.ane_route4} /></td>
                                                <td><input type="text" className="field" onChange={(e) => updateInputValue(e)} name="ane_time_admin4" value={clinicValues.ane_time_admin4} /></td>
                                                <td> <input type="text" className="field" onChange={(e) => updateInputValue(e)} name="ane_sig4" value={clinicValues.ane_sig4} /> </td>
                                                <td>
                                                {file.ane_pre_sig4_result || clinicValues.ane_pre_sig4_result ? <img src={file.ane_pre_sig4_result || clinicValues.ane_pre_sig4_result} /> :
                                                <div className="signature_block">
                                                    <SignaturePad canvasProps={{ className: "sigPad" }} ref={(ref) => { ane_pre_sig4 = ref }} />
                                                    <p>Signature </p>
                                                    <button onClick={(e) => trim(e, "ane_pre_sig4", ane_pre_sig4)}>Trim</button>
                                                    <button onClick={(e) => clear(e, ane_pre_sig4)}> Clear</button>
                                                </div>}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <p className="field_title bold mt-20">VASCULAR ACCESS</p>
                                <div className="check_item mt-0">
                                {clinicValues.ane_vascular ?
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input type="radio"
                                            value={clinicValues.ane_vascular} checked /><span>{clinicValues.ane_vascular} </span></label>
                                        </div> {clinicValues.ane_vascular && <a onClick={(e) => updateClinicValues(e, "ane_vascular", false)}><span className="material-symbols-outlined"> reply
                                        </span></a>}

                                    </div>
                                    :
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_vascular" type="radio" value="Hand Existing" /><span>Hand Existing</span></label>
                                        </div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_vascular" type="radio" value="PICC Existing" /><span> PICC Existing </span></label></div>

                                    </div>}
                                </div>
                                <p className="field_title bold mt-10">IV 1</p>
                                <div className="row">
                                    <div className="col-md-2">
                                        <div className="form_group ">
                                            <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="ane_iv1_size" value={clinicValues.ane_iv1_size}

                                                className="form-control" autoComplete="off" /><label
                                                    className="floating-label">SIZE</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="check_item mt-0">
                                        {clinicValues.ane_iv1_site ?
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input type="radio"
                                                    value={clinicValues.ane_iv1_site} checked /><span>{clinicValues.ane_iv1_site} </span></label>
                                                </div> {clinicValues.ane_iv1_site && <a onClick={(e) => updateClinicValues(e, "ane_iv1_site", false)}><span className="material-symbols-outlined"> reply
                                                </span></a>}

                                            </div>
                                            :
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input  onClick={(e) => updateInputValue(e)} name="ane_iv1_site" type="radio"
                                                    value="Site Left" /><span>Site Left</span></label>
                                                </div>
                                                <div className="select_btn"><label><input  onClick={(e) => updateInputValue(e)} name="ane_iv1_site" type="radio"
                                                    value="Right antecubital forearm" /><span> Right antecubital forearm
                                                    </span></label></div>

                                            </div>}
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form_group ">
                                            <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="ane_iv1_inserted" value={clinicValues.ane_iv1_inserted}
                                                className="form-control" autoComplete="off" /><label
                                                    className="floating-label">Inserted
                                                    by</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p className="note2" >Select all that apply for Multiselect forms {clinicValues.ane_iv1 && <a onClick={(e) => updateClinicValues(e, "ane_iv1", false)}><span className="material-symbols-outlined"> reply </span></a>}</p>
                                {clinicValues.ane_iv1 && clinicValues.ane_iv1_list && clinicValues.ane_iv1_list.length > 0 ? <div className="check_list w_white mb-0">

                                    {clinicValues.ane_iv1_list.map((item, i) => (
                                        <div className="check_item active" key={i} onClick={(e) => updateClinicValues(e, "ane_iv2", false)} ><label className="check">{item}</label></div>
                                    ))}

                                </div> :
                                <div className="check_list w_white mb-0">
                                    <span className="check_btn2" onClick={(e) => updateClinicValues(e, "ane_iv1", true)}><span className="material-icons"> done </span></span>

                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_iv1_list", "Complied with Aseptic Precautions", "ane_iv1")}><label className="check">Complied with Aseptic Precautions</label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_iv1_list", "Replace within 24 hours", "ane_iv1")}><label className="check">Replace within 24 hours</label>
                                    </div>
                                </div>}

                                <p className="field_title bold mt-30">IV 2</p>
                                <div className="row">
                                    <div className="col-md-2">
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="ane_iv2_size" value={clinicValues.ane_iv2_size}
                                                className="form-control" autoComplete="off" /><label
                                                    className="floating-label">SIZE</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="check_item mt-0">
                                        {clinicValues.ane_iv2_site ?
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input type="radio"
                                                    value={clinicValues.ane_iv2_site} checked /><span>{clinicValues.ane_iv2_site} </span></label>
                                                </div> {clinicValues.ane_iv2_site && <a onClick={(e) => updateClinicValues(e, "ane_iv2_site", false)}><span className="material-symbols-outlined"> reply
                                                </span></a>}

                                            </div>
                                            :
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input  onClick={(e) => updateInputValue(e)} name="ane_iv2_site" type="radio"
                                                    value="Site Left" /><span>Site Left</span></label>
                                                </div>
                                                <div className="select_btn"><label><input  onClick={(e) => updateInputValue(e)} name="ane_iv2_site" type="radio"
                                                    value="Right antecubital forearm" /><span> Right antecubital forearm
                                                    </span></label></div>

                                            </div>}
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="ane_iv2_inserted" value={clinicValues.ane_iv2_inserted}
                                                className="form-control" autoComplete="off" /><label
                                                    className="floating-label">Inserted
                                                    by</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p className="note2" >Select all that apply for Multiselect forms {clinicValues.ane_iv2 && <a onClick={(e) => updateClinicValues(e, "ane_iv2", false)}><span className="material-symbols-outlined"> reply </span></a>}</p>
                                {clinicValues.ane_iv2 && clinicValues.ane_iv2_list && clinicValues.ane_iv2_list.length > 0 ? <div className="check_list w_white mb-0">

                                    {clinicValues.ane_iv2_list.map((item, i) => (
                                        <div className="check_item active" key={i} onClick={(e) => updateClinicValues(e, "ane_iv2", false)} ><label className="check">{item}</label></div>
                                    ))}

                                </div> :
                                <div className="check_list w_white mb-0">
                                    <span className="check_btn2" onClick={(e) => updateClinicValues(e, "ane_iv2", true)}><span className="material-icons"> done </span></span>

                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_iv2_list", "Complied with Aseptic Precautions", "ane_iv2")}><label className="check">Complied with Aseptic Precautions</label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_iv2_list", "Replace within 24 hours", "ane_iv2")}><label className="check">Replace within 24 hours</label>
                                    </div>
                                </div>}


                            </div>
                            <div className="section simple_wound ctr">
                                <h2 className="popup_title">ARTERIAL LINE</h2>
                                <div className="pd_border"></div>
                                <div className="check_item mt-0">
                                {clinicValues.ane_arterial ?
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input type="radio"
                                            value={clinicValues.ane_arterial} checked /><span>{clinicValues.ane_arterial} </span></label>
                                        </div> {clinicValues.ane_arterial && <a onClick={(e) => updateClinicValues(e, "ane_arterial", false)}><span className="material-symbols-outlined"> reply
                                        </span></a>}

                                    </div>
                                    :
                                    <div className="select_btns popup_select mt-0">
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_arterial" type="radio" value="Arterial existing" /><span>Arterial existing
                                            </span></label>
                                        </div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_arterial" type="radio" value="Swan ganz existing" /><span> Swan ganz existing
                                            </span></label></div>
                                    </div>}
                                </div>
                                <div className="check_item mt-0">
                                    <label className="check">Site</label>
                                    {clinicValues.ane_arterial_left ?
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input type="radio"
                                            value={clinicValues.ane_arterial_left} checked /><span>{clinicValues.ane_arterial_left} </span></label>
                                        </div> {clinicValues.ane_arterial_left && <a onClick={(e) => updateClinicValues(e, "ane_arterial_left", false)}><span className="material-symbols-outlined"> reply
                                        </span></a>}

                                    </div>
                                    :
                                    <div className="select_btns popup_select mt-0">
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_arterial_left" type="radio"
                                            value="Left" /><span>Left
                                            </span></label>
                                        </div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_arterial_left" type="radio"
                                            value="Right" /><span> Right
                                            </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_arterial_left" type="radio"
                                            value="RA" /><span> RA
                                            </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_arterial_left" type="radio"
                                            value="BA" /><span> BA
                                            </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_arterial_left" type="radio"
                                            value="FA" /><span> FA
                                            </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_arterial_left" type="radio"
                                            value="DPA" /><span> DPA
                                            </span></label></div>
                                    </div>}
                                </div>
                                <div className="check_item">
                                {clinicValues.ane_arterial_gown ?
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input type="radio"
                                            value={clinicValues.ane_arterial_gown} checked /><span>{clinicValues.ane_arterial_gown} </span></label>
                                        </div> {clinicValues.ane_arterial_gown && <a onClick={(e) => updateClinicValues(e, "ane_arterial_gown", false)}><span className="material-symbols-outlined"> reply
                                        </span></a>}

                                    </div>
                                    :
                                    <div className="select_btns popup_select mt-0">
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_arterial_gown" type="radio"
                                            value="Gown" /><span>Gown
                                            </span></label>
                                        </div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_arterial_gown" type="radio"
                                            value="Gloves" /><span>
                                                Gloves
                                            </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_arterial_gown" type="radio"
                                            value="Hat" /><span> Hat
                                            </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_arterial_gown" type="radio"
                                            value="Mask" /><span> Mask
                                            </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_arterial_gown" type="radio"
                                            value="Drape(s)" /><span>
                                                Drape(s)
                                            </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_arterial_gown" type="radio"
                                            value="2% CHG / 70% IPA" /><span>2% CHG / 70% IPA
                                            </span></label></div>
                                    </div>}
                                </div>
                            </div>
                            <div className="section simple_wound ctr">
                                <h2 className="popup_title">CENTRAL VENOUS LINE</h2>
                                <div className="pd_border"></div>

                                <div className="yn_switch mb-20  mt-10">
                                    <div className="label-container">
                                        <p>Central line site existing </p>
                                    </div>
                                    <label className="switch-container">
                                        <input name="ane_central_line" onChange={(e) => handleChange(e)} checked={clinicValues.ane_central_line} type="checkbox" className="switch_check"  />
                                        <div className="switch-bg"></div>
                                        <div className="round-box"></div>
                                        <div className="switch-left">
                                            <span>YES</span>
                                        </div>
                                        <div className="switch-right">
                                            <span>NO</span>
                                        </div>
                                    </label>
                                </div>
                                <div className="check_item mt-10">
                                    <label className="check">Site</label>
                                    {clinicValues.ane_venous_site ?
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input type="radio"
                                            value={clinicValues.ane_venous_site} checked /><span>{clinicValues.ane_venous_site} </span></label>
                                        </div> {clinicValues.ane_venous_site && <a onClick={(e) => updateClinicValues(e, "ane_venous_site", false)}><span className="material-symbols-outlined"> reply
                                        </span></a>}

                                    </div>
                                    :
                                    <div className="select_btns popup_select mt-0">
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_venous_site" type="radio"
                                            value="Left" /><span>Left
                                            </span></label>
                                        </div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_venous_site"  type="radio"
                                            value="Right" /><span> Right
                                            </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_venous_site"  type="radio"
                                            value="JV" /><span> JV
                                            </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_venous_site"  type="radio"
                                            value="SCV" /><span> SCV
                                            </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_venous_site"  type="radio"
                                            value="FV" /><span> FV
                                            </span></label></div>
                                    </div>}
                                </div>
                                <div className="check_item mt-10">
                                    {clinicValues.ane_venous_ultrasound ?
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input type="radio"
                                            value={clinicValues.ane_venous_ultrasound} checked /><span>{clinicValues.ane_venous_ultrasound} </span></label>
                                        </div> {clinicValues.ane_venous_ultrasound && <a onClick={(e) => updateClinicValues(e, "ane_venous_ultrasound", false)}><span className="material-symbols-outlined"> reply
                                        </span></a>}

                                    </div>
                                    :
                                    <div className="select_btns popup_select mt-0">
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_venous_ultrasound" type="radio"
                                            value="Ultrasound guided" /><span>Ultrasound guided
                                            </span></label>
                                        </div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_venous_ultrasound"  type="radio"
                                            value="Landmark technique" /><span>
                                                Landmark technique
                                            </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_venous_ultrasound"  type="radio"
                                            value="Wire removed" /><span> Wire removed
                                            </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_venous_ultrasound" type="radio"
                                            value="Tunnelled" /><span>
                                                Tunnelled
                                            </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_venous_ultrasound"  type="radio"
                                            value="Non-tunnelled" /><span>
                                                Non-tunnelled
                                            </span></label></div>
                                    </div>}
                                </div>
                                <div className="check_item mt-10">
                                {clinicValues.ane_venous_gown ?
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input type="radio"
                                            value={clinicValues.ane_venous_gown} checked /><span>{clinicValues.ane_venous_gown} </span></label>
                                        </div> {clinicValues.ane_venous_gown && <a onClick={(e) => updateClinicValues(e, "ane_venous_gown", false)}><span className="material-symbols-outlined"> reply
                                        </span></a>}

                                    </div>
                                    :
                                    <div className="select_btns popup_select mt-0">
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_venous_gown"  type="radio"
                                            value="Gown" /><span>Gown
                                            </span></label>
                                        </div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_venous_gown" type="radio"
                                            value="Gloves" /><span>
                                                Gloves
                                            </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_venous_gown" type="radio"
                                            value="Hat" /><span> Hat
                                            </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_venous_gown" type="radio"
                                            value="Mask" /><span> Mask
                                            </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_venous_gown" type="radio"
                                            value="Drape(s)" /><span>
                                                Drape(s)
                                            </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_venous_gown" type="radio"
                                            value="2% CHG / 70% IPA" /><span> 2% CHG / 70% IPA
                                            </span></label></div>
                                    </div>}
                                </div>
                            </div>
                            <div className="section simple_wound ctr">
                                <h2 className="popup_title">ANTIBIOTIC PROPHYLAXIS</h2>
                                <div className="pd_border"></div>
                                <div className="row">
                                    <div className="col-md-2">
                                        <div className="form_group mb-20">
                                            <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="ane_antibiotic_dose" value={clinicValues.ane_antibiotic_dose}
                                                className="form-control" autoComplete="off" /><label
                                                    className="floating-label">Dose</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form_group mb-20">
                                            <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="ane_antibiotic_time_given" value={clinicValues.ane_antibiotic_time_given}
                                                className="form-control" autoComplete="off" /><label
                                                    className="floating-label">Time
                                                    given</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-7">
                                        <div className="form_group mb-20">
                                            <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="ane_antibiotic_drug" value={clinicValues.ane_antibiotic_drug}
                                                className="form-control" autoComplete="off" /><label
                                                    className="floating-label">Drug</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="check_item mt-0">
                                {clinicValues.ane_flucloxacillin ?
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input type="radio"
                                            value={clinicValues.ane_flucloxacillin} checked /><span>{clinicValues.ane_flucloxacillin} </span></label>
                                        </div> {clinicValues.ane_flucloxacillin && <a onClick={(e) => updateClinicValues(e, "ane_flucloxacillin", false)}><span className="material-symbols-outlined"> reply
                                        </span></a>}

                                    </div>
                                    :
                                    <div className="select_btns popup_select mt-0">
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_flucloxacillin" type="radio"
                                            value="Flucloxacillin" /><span>Flucloxacillin
                                            </span></label>
                                        </div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_flucloxacillin" type="radio"
                                            value="Clindamycin" /><span>
                                                Clindamycin
                                            </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_flucloxacillin" type="radio"
                                            value="Teicoplanin" /><span>
                                                Teicoplanin
                                            </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_flucloxacillin" type="radio"
                                            value="Gentamicin" /><span>
                                                Gentamicin
                                            </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_flucloxacillin" type="radio"
                                            value="Vancomycin" /><span>
                                                Vancomycin
                                            </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_flucloxacillin" type="radio"
                                            value="Metronidazole" /><span>
                                                Metronidazole
                                            </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_flucloxacillin" type="radio"
                                            value="Ampicillin" /><span>
                                                Ampicillin
                                            </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_flucloxacillin" type="radio"
                                            value="Cefazolin" /><span>
                                                Cefazolin
                                            </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_flucloxacillin" type="radio"
                                            value="Ciprofloxacin" /><span>
                                                Ciprofloxacin
                                            </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_flucloxacillin" type="radio"
                                            value="Piperacillin" /><span>
                                                Piperacillin
                                            </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_flucloxacillin" type="radio"
                                            value="Tazobactam" /><span>
                                                Tazobactam
                                            </span></label></div>
                                    </div>}
                                </div>
                            </div>
                            <div className="section simple_wound ctr">
                                <h2 className="popup_title">TECHNIQUE</h2>
                                <div className="pd_border"></div>
                                <p className="note2" >Select all that apply for Multiselect forms {clinicValues.ane_epidural && <a onClick={(e) => updateClinicValues(e, "ane_epidural", false)}><span className="material-symbols-outlined"> reply </span></a>}</p>
                                {clinicValues.ane_epidural && clinicValues.ane_epidural_list && clinicValues.ane_epidural_list.length > 0 ? <div className="check_list w_white mb-0">

                                    {clinicValues.ane_epidural_list.map((item, i) => (
                                        <div className="check_item active" key={i} onClick={(e) => updateClinicValues(e, "ane_epidural", false)} ><label className="check">{item}</label></div>
                                    ))}

                                </div> :
                                <div className="check_list w_white mb-0">
                                    <span className="check_btn2" onClick={(e) => updateClinicValues(e, "ane_epidural", true)}><span className="material-icons"> done </span></span>

                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_epidural_list", "Epidural/Spinal", "ane_epidural")}><label className="check">Epidural/Spinal </label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_epidural_list", "General: with block /without block", "ane_epidural")}><label className="check">General: with block /without block</label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_epidural_list", "Regional/Local with sedation", "ane_epidural")}><label className="check">Regional/Local with sedation</label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_epidural_list", "IV Sedation only (Fentanyl, Midazolam, Propofol)", "ane_epidural")}><label className="check">IV Sedation only (Fentanyl, Midazolam, Propofol)</label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_epidural_list", "TIVA (Propofol, Remifentanyl)", "ane_epidural")}><label className="check">TIVA (Propofol, Remifentanyl)</label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_epidural_list", "Topical (Ophthalmic) ", "ane_epidural")}><label className="check">Topical (Ophthalmic) </label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_epidural_list", "Anaesthetist on standby", "ane_epidural")}><label className="check">Anaesthetist on standby </label>
                                    </div>
                                </div>}
                            </div>
                            <div className="section simple_wound ctr">
                                <h2 className="popup_title">INDUCTION</h2>
                                <div className="pd_border"></div>
                                <div className="check_item mt-0">
                                {clinicValues.ane_induction ?
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input type="radio"
                                            value={clinicValues.ane_induction} checked /><span>{clinicValues.ane_induction} </span></label>
                                        </div> {clinicValues.ane_induction && <a onClick={(e) => updateClinicValues(e, "ane_induction", false)}><span className="material-symbols-outlined"> reply
                                        </span></a>}

                                    </div>
                                    :
                                    <div className="select_btns popup_select mt-0">
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_induction" type="radio" value="Intravenous" /><span>Intravenous
                                            </span></label>
                                        </div>
                                        <div className="select_btn"><label><input  onClick={(e) => updateInputValue(e)} name="ane_induction" type="radio" value="Inhalational" /><span>
                                                Inhalational
                                            </span></label></div>
                                        <div className="select_btn"><label><input  onClick={(e) => updateInputValue(e)} name="ane_induction" type="radio" value="Sedation" /><span>
                                                Sedation
                                            </span></label></div>
                                    </div>}
                                </div>
                                <div className="form_group">
                                    <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="ane_induction_other" value={clinicValues.ane_induction_other} className="form-control"
                                        autoComplete="off" /><label className="floating-label">Other</label>
                                    </div>
                                </div>
                            </div>
                            <div className="section simple_wound ctr">
                                <h2 className="popup_title">MAINTAINING OWN AIRWAY (Eg: Spinal) </h2>
                                <div className="pd_border"></div>

                            </div>
                            <div className="section simple_wound ctr">
                                <h2 className="popup_title">CONTROLLED AIRWAY</h2>
                                <div className="pd_border"></div>
                                <div className="check_item mt-0 mb-0">
                                {clinicValues.ane_controlled_airway ?
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input type="radio"
                                            value={clinicValues.ane_controlled_airway} checked /><span>{clinicValues.ane_controlled_airway} </span></label>
                                        </div> {clinicValues.ane_controlled_airway && <a onClick={(e) => updateClinicValues(e, "ane_controlled_airway", false)}><span className="material-symbols-outlined"> reply
                                        </span></a>}

                                    </div>
                                    :
                                    <div className="select_btns popup_select mt-0">
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_controlled_airway" type="radio" value="Pre-oxygenation" /><span>Pre-oxygenation
                                            </span></label>
                                        </div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_controlled_airway" type="radio" value="FACE MASK" /><span> FACE MASK
                                            </span></label></div>
                                    </div>}
                                </div>
                                <div className="check_item mt-10 mb-0">
                                {clinicValues.ane_lma ?
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input type="radio"
                                            value={clinicValues.ane_lma} checked /><span>{clinicValues.ane_lma} </span></label>
                                        </div> {clinicValues.ane_lma && <a onClick={(e) => updateClinicValues(e, "ane_lma", false)}><span className="material-symbols-outlined"> reply
                                        </span></a>}

                                    </div>
                                    :
                                    <div className="select_btns popup_select mt-0">
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_lma" type="radio" value="LARYNGEAL MASK LMA" /><span>LARYNGEAL MASK LMA
                                            </span></label>
                                        </div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_lma" type="radio" value="IGEL#" /><span> IGEL#
                                            </span></label></div>
                                    </div>}
                                </div>
                            </div>
                            <div className="section simple_wound ctr">
                                <h2 className="popup_title">ENDOTRACHEAL TUBE</h2>
                                <div className="pd_border"></div>


                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="check_item mt-0 mb-0">
                                        {clinicValues.ane_endo_cuffed ?
                                                <div className="select_btns popup_select">
                                                    <div className="select_btn"><label><input type="radio"
                                                        value={clinicValues.ane_endo_cuffed} checked /><span>{clinicValues.ane_endo_cuffed} </span></label>
                                                    </div> {clinicValues.ane_endo_cuffed && <a onClick={(e) => updateClinicValues(e, "ane_endo_cuffed", false)}><span className="material-symbols-outlined"> reply
                                                    </span></a>}

                                                </div>
                                                :
                                            <div className="select_btns popup_select mt-0">
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_endo_cuffed" type="radio"
                                                    value="Cuffed" /><span>Cuffed </span></label> </div>
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_endo_cuffed" type="radio"
                                                    value="Uncuffed" /><span> Uncuffed </span></label></div>
                                            </div>}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="check_item mt-0 mb-0">
                                        {clinicValues.ane_endo_oral ?
                                                <div className="select_btns popup_select">
                                                    <div className="select_btn"><label><input type="radio"
                                                        value={clinicValues.ane_endo_oral} checked /><span>{clinicValues.ane_endo_oral} </span></label>
                                                    </div> {clinicValues.ane_endo_oral && <a onClick={(e) => updateClinicValues(e, "ane_endo_oral", false)}><span className="material-symbols-outlined"> reply
                                                    </span></a>}

                                                </div>
                                                :
                                            <div className="select_btns popup_select mt-0">
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_endo_oral" type="radio"
                                                    value="Oral #" /><span>Oral # </span></label> </div>
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_endo_oral" type="radio"
                                                    value="Nasal #<" /><span>Nasal #</span></label></div>
                                            </div>}
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="ane_endo_tube" value={clinicValues.ane_endo_tube}
                                                className="form-control" autoComplete="off" /><label
                                                    className="floating-label">Size</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="ane_endo_type" value={clinicValues.ane_endo_type}
                                                className="form-control" autoComplete="off" /><label
                                                    className="floating-label">Type</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="ane_endo_length" value={clinicValues.ane_endo_length}
                                                className="form-control" autoComplete="off" /><label
                                                    className="floating-label">Length</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="section simple_wound ctr">
                                <h2 className="popup_title">INTUBATION</h2>
                                <div className="pd_border"></div>
                                <div className="check_item mt-0 mb-0">
                                {clinicValues.ane_intubation ?
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input type="radio"
                                            value={clinicValues.ane_intubation} checked /><span>{clinicValues.ane_intubation} </span></label>
                                        </div> {clinicValues.ane_intubation && <a onClick={(e) => updateClinicValues(e, "ane_intubation", false)}><span className="material-symbols-outlined"> reply
                                        </span></a>}

                                    </div>
                                    :
                                    <div className="select_btns popup_select mt-0">
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_intubation" type="radio" value="Routine" /><span>Routine </span></label> </div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_intubation" type="radio" value="Rapid sequence" /><span>Rapid sequence</span></label></div>
                                    </div>}
                                </div>
                                <p className="field_title mt-0">Throat pack </p>
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="ane_throat_pack_in" value={clinicValues.ane_throat_pack_in}
                                                className="form-control" autoComplete="off" /><label
                                                    className="floating-label">In</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="ane_throat_pack_out" value={clinicValues.ane_throat_pack_out}
                                                className="form-control" autoComplete="off" /><label
                                                    className="floating-label">Out</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="check_item"><label className="check">Grade of view </label>
                                {clinicValues.ane_grade_view ?
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input type="radio"
                                            value={clinicValues.ane_grade_view} checked /><span>{clinicValues.ane_grade_view} </span></label>
                                        </div> {clinicValues.ane_grade_view && <a onClick={(e) => updateClinicValues(e, "ane_grade_view", false)}><span className="material-symbols-outlined"> reply
                                        </span></a>}

                                    </div>
                                    :
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_grade_view" type="radio" value="1" /><span>1</span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_grade_view" type="radio" value="2" /><span>2</span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_grade_view" type="radio" value="3" /><span>3</span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_grade_view" type="radio" value="4" /><span>4</span></label></div>

                                    </div>}

                                </div>
                                <div className="check_item mt-0 mb-0">
                                {clinicValues.ane_grade_view_direct ?
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input type="radio"
                                            value={clinicValues.ane_grade_view_direct} checked /><span>{clinicValues.ane_grade_view_direct} </span></label>
                                        </div> {clinicValues.ane_grade_view_direct && <a onClick={(e) => updateClinicValues(e, "ane_grade_view_direct", false)}><span className="material-symbols-outlined"> reply
                                        </span></a>}

                                    </div>
                                    :
                                    <div className="select_btns popup_select mt-0">
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_grade_view_direct" type="radio"
                                            value="Direct" /><span>Direct
                                            </span></label> </div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_grade_view_direct" type="radio"
                                            value="Laryngoscope" /><span>Laryngoscope</span></label></div>
                                    </div>}
                                </div>
                                <div className="yn_switch  mt-10">
                                    <div className="label-container">
                                        <p>Bougie Required </p>
                                    </div>
                                    <label className="switch-container">
                                        <input name="ane_bougie" onChange={(e) => handleChange(e)} checked={clinicValues.ane_bougie} type="checkbox" className="switch_check" />
                                        <div className="switch-bg"></div>
                                        <div className="round-box"></div>
                                        <div className="switch-left">
                                            <span>YES</span>
                                        </div>
                                        <div className="switch-right">
                                            <span>NO</span>
                                        </div>
                                    </label>
                                </div>
                                <div className="yn_switch  mt-10">
                                    <div className="label-container">
                                        <p>Video laryngoscope</p>
                                    </div>
                                    <label className="switch-container">
                                        <input name="ane_video_laryngoscope" onChange={(e) => handleChange(e)} checked={clinicValues.ane_video_laryngoscope} type="checkbox" className="switch_check" />
                                        <div className="switch-bg"></div>
                                        <div className="round-box"></div>
                                        <div className="switch-left">
                                            <span>YES</span>
                                        </div>
                                        <div className="switch-right">
                                            <span>NO</span>
                                        </div>
                                    </label>
                                </div>
                                <div className="check_item"><label className="check">Fibre-optic </label>
                                {clinicValues.ane_fibre_optic ?
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input type="radio"
                                            value={clinicValues.ane_fibre_optic} checked /><span>{clinicValues.ane_fibre_optic} </span></label>
                                        </div> {clinicValues.ane_fibre_optic && <a onClick={(e) => updateClinicValues(e, "ane_fibre_optic", false)}><span className="material-symbols-outlined"> reply
                                        </span></a>}

                                    </div>
                                    :
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_fibre_optic" type="radio" value="Asleep" /><span>Asleep
                                            </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_fibre_optic" type="radio" value="Awake" /><span>Awake</span></label></div>
                                    </div>}

                                </div>
                                <div className="row">
                                    <div className="col-md-5">
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="ane_intubation_easy" value={clinicValues.ane_intubation_easy}
                                                className="form-control" autoComplete="off" /><label
                                                    className="floating-label">Easy</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="ane_intubation_difficult" value={clinicValues.ane_intubation_difficult}
                                                className="form-control" autoComplete="off" /><label
                                                    className="floating-label">Difficult</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="check_item mt-10"><label className="check">Some manipulation of larynx</label>
                                {clinicValues.ane_manipulation ?
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input type="radio"
                                            value={clinicValues.ane_manipulation} checked /><span>{clinicValues.ane_manipulation} </span></label>
                                        </div> {clinicValues.ane_manipulation && <a onClick={(e) => updateClinicValues(e, "ane_manipulation", false)}><span className="material-symbols-outlined"> reply
                                        </span></a>}

                                    </div>
                                    :
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input  onClick={(e) => updateInputValue(e)} name="ane_manipulation" type="radio" value="Laryngoscope" /><span>Laryngoscope </span></label></div>
                                        <div className="select_btn"><label><input  onClick={(e) => updateInputValue(e)} name="ane_manipulation" type="radio"  value="Endotracheal tube required" /><span>Endotracheal tube required</span></label></div>
                                    </div>}
                                </div>
                                <div className="check_item mt-10"><label className="check">Passed endotracheal tube </label>
                                {clinicValues.ane_endotracheal ?
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input type="radio"
                                            value={clinicValues.ane_endotracheal} checked /><span>{clinicValues.ane_endotracheal} </span></label>
                                        </div> {clinicValues.ane_endotracheal && <a onClick={(e) => updateClinicValues(e, "ane_endotracheal", false)}><span className="material-symbols-outlined"> reply
                                        </span></a>}

                                    </div>
                                    :
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_endotracheal" type="radio" value="Blind" /><span>Blind
                                            </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_endotracheal" type="radio"
                                            value="Failed first attempt" /><span>Failed first attempt</span></label></div>
                                  
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_endotracheal" type="radio"
                                            value="More than two attempts" /><span>More than two attempts </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_endotracheal" type="radio"
                                            value="Failed intubation" /><span>Failed intubation</span></label></div>
                                    </div>}
                                   
                                </div>
                            </div>
                            <div className="section simple_wound ctr">
                                <h2 className="popup_title">TRACHEOSTOMY</h2>
                                <div className="pd_border"></div>

                            </div>
                            <div className="section simple_wound ctr">
                                <h2 className="popup_title">BREATHING CIRCUIT</h2>
                                <div className="pd_border"></div>
                                <p className="field_title mt-0">Anaesthetic room </p>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="check_item">
                                        {clinicValues.ane_Anaesthetic_room ?
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input type="radio"
                                                    value={clinicValues.ane_Anaesthetic_room} checked /><span>{clinicValues.ane_Anaesthetic_room} </span></label>
                                                </div> {clinicValues.ane_Anaesthetic_room && <a onClick={(e) => updateClinicValues(e, "ane_Anaesthetic_room", false)}><span className="material-symbols-outlined"> reply
                                                </span></a>}

                                            </div>
                                            :
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_Anaesthetic_room" type="radio"
                                                    value="Circle" /><span>Circle </span></label></div>
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_Anaesthetic_room" type="radio"
                                                    value="Bain" /><span>Bain </span></label></div>
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_Anaesthetic_room" type="radio"
                                                    value="T-Piece" /><span>T-Piece </span></label></div>
                                            </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="ane_Anaesthetic_room_other" value={clinicValues.ane_Anaesthetic_room_other}
                                                className="form-control" autoComplete="off" /><label
                                                    className="floating-label">Other
                                                    (specify)</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p className="field_title mt-10">Theatre</p>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="check_item">
                                        {clinicValues.ane_theatre_room ?
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input type="radio"
                                                    value={clinicValues.ane_theatre_room} checked /><span>{clinicValues.ane_Anaesthetic_room} </span></label>
                                                </div> {clinicValues.ane_theatre_room && <a onClick={(e) => updateClinicValues(e, "ane_theatre_room", false)}><span className="material-symbols-outlined"> reply
                                                </span></a>}

                                            </div>
                                            :
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_theatre_room"  type="radio"
                                                    value="Circle" /><span>Circle </span></label></div>
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_theatre_room"  type="radio"
                                                    value="Bain" /><span>Bain </span></label></div>
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_theatre_room"  type="radio"
                                                    value="T-Piece" /><span>T-Piece </span></label></div>
                                            </div>}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="ane_theatre_room_other" value={clinicValues.ane_theatre_room_other}
                                                className="form-control" autoComplete="off" /><label
                                                    className="floating-label">Other
                                                    (specify)</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="section simple_wound ctr">
                                <h2 className="popup_title">VENTILATION MODE</h2>
                                <div className="pd_border"></div>
                                <div className="check_item mt-0">
                                {clinicValues.ane_ventilation_mode ?
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input type="radio"
                                                value={clinicValues.ane_ventilation_mode} checked /><span>{clinicValues.ane_ventilation_mode} </span></label>
                                            </div> {clinicValues.ane_ventilation_mode && <a onClick={(e) => updateClinicValues(e, "ane_ventilation_mode", false)}><span className="material-symbols-outlined"> reply
                                            </span></a>}

                                        </div>
                                        :
                                    <div className="select_btns popup_select mt-0">
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_ventilation_mode" type="radio"
                                            value="IPPV" /><span>IPPV
                                            </span></label></div>
                                        <div className="select_btn"><label><input  onClick={(e) => updateInputValue(e)} name="ane_ventilation_mode" type="radio"
                                            value="Pressure Support" /><span>
                                                Pressure Support </span></label></div>
                                        <div className="select_btn"><label><input  onClick={(e) => updateInputValue(e)} name="ane_ventilation_mode" type="radio"
                                            value="Pressure Control" /><span>Pressure Control </span></label></div>
                                        <div className="select_btn"><label><input  onClick={(e) => updateInputValue(e)} name="ane_ventilation_mode" type="radio"
                                            value="Volume Control VCV/PCV" /><span>Volume
                                                Control VCV/PCV </span></label></div>
                                        <div className="select_btn"><label><input  onClick={(e) => updateInputValue(e)} name="ane_ventilation_mode" type="radio"
                                            value="VCAF (Volume Control Auto Flow) Ventilator" /><span>VCAF (Volume Control Auto Flow) Ventilator
                                            </span></label></div>

                                    </div>}
                                </div>
                                <div className="form_group">
                                    <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="ane_ventilation_mode_other" value={clinicValues.ane_ventilation_mode_other} className="form-control"
                                        autoComplete="off" /><label className="floating-label">Other</label>
                                    </div>
                                </div>
                            </div>
                            <div className="section simple_wound ctr">
                                <h2 className="popup_title">MONITORS EMPLOYED DURING THE PROCEDURE</h2>
                                <div className="pd_border"></div>
                                <p className="note2" >Select all that apply for Multiselect forms {clinicValues.ane_monitors && <a onClick={(e) => updateClinicValues(e, "ane_monitors", false)}><span className="material-symbols-outlined"> reply </span></a>}</p>
                                {clinicValues.ane_monitors && clinicValues.ane_monitors_list && clinicValues.ane_monitors_list.length > 0 ? <div className="check_list w_white mb-0">

                                    {clinicValues.ane_monitors_list.map((item, i) => (
                                        <div className="check_item active" key={i} onClick={(e) => updateClinicValues(e, "ane_monitors", false)} ><label className="check">{item}</label></div>
                                    ))}

                                </div> :
                                <div className="check_list w_white mb-0">
                                    <span className="check_btn2" onClick={(e) => updateClinicValues(e, "ane_monitors", true)}><span className="material-icons"> done </span></span>
                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_monitors_list", "ECG", "ane_monitors")}><label className="check">ECG </label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_monitors_list", "BP", "ane_monitors")}><label className="check">BP </label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_monitors_list", "Pulse oximeter (SpO2)", "ane_monitors")}><label className="check">Pulse oximeter (SpO2) </label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_monitors_list", "Temperature check", "ane_monitors")}><label className="check">Temperature check </label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_monitors_list", "Stethoscope", "ane_monitors")}><label className="check">Stethoscope </label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_monitors_list", "CVP", "ane_monitors")}><label className="check">CVP </label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_monitors_list", "Arterial Line", "ane_monitors")}><label className="check">Arterial Line </label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_monitors_list", "Capnograph", "ane_monitors")}><label className="check">Capnograph </label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_monitors_list", "Airway pressure gauge", "ane_monitors")}><label className="check">Airway pressure gauge </label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_monitors_list", "Peripheral Nerve Stimulator", "ane_monitors")}><label className="check">Peripheral Nerve Stimulator </label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_monitors_list", "Foley catheter", "ane_monitors")}><label className="check">Foley catheter </label>
                                    </div>
                                </div>}
                            </div>

                            <div className="section simple_wound ctr">
                            <CalendarEvent />
                            </div>
                            <div className="section simple_wound ctr">
                                <h2 className="popup_title">SPINAL ANAESTHESIA</h2>
                                <div className="pd_border"></div>
                                <div className="check_item mt-0">
                                {clinicValues.ane_spinal_anaesthesia ?
                                <div className="select_btns popup_select">
                                    <div className="select_btn"><label><input type="radio"
                                        value={clinicValues.ane_spinal_anaesthesia} checked /><span>{clinicValues.ane_spinal_anaesthesia} </span></label>
                                    </div> {clinicValues.ane_spinal_anaesthesia && <a onClick={(e) => updateClinicValues(e, "ane_spinal_anaesthesia", false)}><span className="material-symbols-outlined"> reply
                                    </span></a>}

                                </div>
                                :
                                    <div className="select_btns popup_select mt-0">
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_spinal_anaesthesia" type="radio"
                                            value="For Anaesthesia" /><span>For Anaesthesia </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_spinal_anaesthesia" type="radio"
                                            value="For Analgesia" /><span> For Analgesia </span></label></div>
                                    </div>}
                                </div>
                                <p className="field_title bold mt-10">PATIENT PREPARATION</p>
                                <p className="note2" >Select all that apply for Multiselect forms {clinicValues.ane_patient_pre && <a onClick={(e) => updateClinicValues(e, "ane_patient_pre", false)}><span className="material-symbols-outlined"> reply </span></a>}</p>
                                {clinicValues.ane_patient_pre && clinicValues.ane_patient_pre_list && clinicValues.ane_patient_pre_list.length > 0 ? <div className="check_list w_white mb-0">

                                    {clinicValues.ane_patient_pre_list.map((item, i) => (
                                        <div className="check_item active" key={i} onClick={(e) => updateClinicValues(e, "ane_patient_pre", false)} ><label className="check">{item}</label></div>
                                    ))}

                                </div> :
                                <div className="check_list w_white mb-0">
                                    <span className="check_btn2" onClick={(e) => updateClinicValues(e, "ane_patient_pre", true)}><span className="material-icons"> done </span></span>

                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_patient_pre_list", "Consent obtained", "ane_patient_pre")}><label className="check">Consent obtained </label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_patient_pre_list", "Coagulation status considered", "ane_patient_pre")}><label className="check">Coagulation status considered</label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_patient_pre_list", "IV Access -G", "ane_patient_pre")}><label className="check">IV Access -G </label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_patient_pre_list", "AAGBI Monitoring", "ane_patient_pre")}><label className="check">AAGBI Monitoring</label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_patient_pre_list", "Awake / Under GA ", "ane_patient_pre")}><label className="check">Awake / Under GA </label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_patient_pre_list", "Patient position", "ane_patient_pre")}><label className="check">Patient position</label>
                                    </div>
                                </div>}
                                <p className="field_title bold mt-20">PROCEDURE</p>
                                <div className="yn_switch  mt-10">
                                    <div className="label-container">
                                        <p>Chlorhexidine 0.5% spray & air dry</p>
                                    </div>
                                    <label className="switch-container">
                                        <input type="checkbox" className="switch_check" name="ane_procedure" onChange={(e) => handleChange(e)} checked={clinicValues.ane_procedure} />
                                        <div className="switch-bg"></div>
                                        <div className="round-box"></div>
                                        <div className="switch-left">
                                            <span>YES</span>
                                        </div>
                                        <div className="switch-right">
                                            <span>NO</span>
                                        </div>
                                    </label>
                                </div>
                                <div className="check_item mt-0">
                                {clinicValues.ane_asepsis ?
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input type="radio"
                                            value={clinicValues.ane_asepsis} checked /><span>{clinicValues.ane_asepsis} </span></label>
                                        </div> {clinicValues.ane_asepsis && <a onClick={(e) => updateClinicValues(e, "ane_asepsis", false)}><span className="material-symbols-outlined"> reply
                                        </span></a>}

                                    </div>
                                    :
                                    <div className="select_btns popup_select mt-0">
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_asepsis" type="radio" value="Asepsis" /><span>Asepsis
                                            </span></label>
                                        </div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_asepsis"  type="radio" value="Hat" /><span> Hat
                                            </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_asepsis"  type="radio" value="Mask" /><span> Mask
                                            </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_asepsis" type="radio"  value="Gown" /><span> Gown
                                            </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_asepsis"  type="radio" value="Glove" /><span> Glove
                                            </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_asepsis"  type="radio" value="Drape" /><span> Drape
                                            </span></label></div>
                                    </div>}
                                </div>
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="form_group form_tag">
                                            <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="ane_skin_infiltration" value={clinicValues.ane_skin_infiltration}
                                                className="form-control" autoComplete="off" /><label
                                                    className="floating-label">Skin infiltration
                                                </label>
                                            </div>
                                            %
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form_group form_tag">
                                            <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="ane_Lignocaine" value={clinicValues.ane_Lignocaine}
                                                className="form-control" autoComplete="off" /><label
                                                    className="floating-label">Lignocaine</label>
                                            </div>
                                            ml
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="form_group form_tag">
                                            <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="ane_spinal_needle" value={clinicValues.ane_spinal_needle}
                                                className="form-control" autoComplete="off" /><label
                                                    className="floating-label">Type of spinal needle </label>
                                            </div>
                                            &
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="form_group form_tag">
                                            <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="ane_anaesthetic_G" value={clinicValues.ane_anaesthetic_G}
                                                className="form-control" autoComplete="off" /><label
                                                    className="floating-label">G </label>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-20">
                                        <div className="form_group form_tag">
                                            <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="ane_anaesthetic_level" value={clinicValues.ane_anaesthetic_level}
                                                className="form-control" autoComplete="off" /><label
                                                    className="floating-label">Level</label>
                                            </div>
                                            No. of attempts
                                        </div>
                                    </div>
                                </div>
                                <div className="check_item mt-20">
                                {clinicValues.ane_flowing_csf ?
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input type="radio"
                                            value={clinicValues.ane_flowing_csf} checked /><span>{clinicValues.ane_flowing_csf} </span></label>
                                        </div> {clinicValues.ane_flowing_csf && <a onClick={(e) => updateClinicValues(e, "ane_flowing_csf", false)}><span className="material-symbols-outlined"> reply
                                        </span></a>}

                                    </div>
                                    :
                                    <div className="select_btns popup_select mt-0">

                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_flowing_csf" type="radio" value="Clear free flowing CSF" /><span> Clear free flowing CSF
                                            </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_flowing_csf" type="radio" value="No blood" /><span> No blood
                                            </span></label></div>

                                    </div>}
                                </div>
                                <p className="field_title mt-10">LA Bolus </p>
                                <div className="row mt-20">
                                    <div className="col-md-4">
                                        <div className="form_group form_tag">
                                            <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="ane_marcain" value={clinicValues.ane_marcain} className="form-control"
                                                autoComplete="off" /><label className="floating-label">0.5% Heavy
                                                    Marcain
                                                </label>
                                            </div>
                                            ml+
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form_group form_tag ">
                                            <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="ane_opioid" value={clinicValues.ane_opioid}
                                                className="form-control" autoComplete="off" /><label
                                                    className="floating-label">Opioid </label>
                                            </div>
                                            mcg
                                        </div>
                                    </div>

                                </div>
                                <p className="field_title mt-10">Diamorphine / Fentanyl </p>
                                <div className="row mt-20">
                                    
                                   
                                    <div className="col-md-12">
                                        <div className="form_group form_tag">
                                        
                                        <div className="floating-label-group "><input type="time" onChange={(e) => updateInputValue(e)} name="ane_Diamorphine" value={clinicValues.ane_Diamorphine}
                                            className="form-control" autoComplete="off" /><label
                                                className="floating-label">Time </label>
                                        </div>
                                        
                                    </div>
                                </div>
                                </div>

                                <div className="check_item mt-20"><label className="check">Injection</label>
                                {clinicValues.ane_injection ?
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input type="radio"
                                            value={clinicValues.ane_injection} checked /><span>{clinicValues.ane_injection} </span></label>
                                        </div> {clinicValues.ane_injection && <a onClick={(e) => updateClinicValues(e, "ane_injection", false)}><span className="material-symbols-outlined"> reply
                                        </span></a>}

                                    </div>
                                    :
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_injection" type="radio" value="No pain" /><span>No pain
                                        </span></label>
                                        </div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_injection" type="radio" value="Paraesthesia" /><span>
                                            Paraesthesia</span></label></div>

                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_injection" type="radio" value="Complication" /><span>
                                            Complication</span></label></div>
                                    </div>}
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="ane_Block_level" value={clinicValues.ane_Block_level}
                                                className="form-control" autoComplete="off" /><label
                                                    className="floating-label">Block level </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="ane_Time_checked" value={clinicValues.ane_Time_checked}
                                                className="form-control" autoComplete="off" /><label
                                                    className="floating-label">Time checked </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form_group mb-10">
                                    <textarea className="textarea" placeholder="Comments" onChange={(e) => updateInputValue(e)} name="ane_patient_pre_comments" value={clinicValues.ane_patient_pre_comments} rows="2"
                                        cols="30"></textarea>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="ane_additional" value={clinicValues.ane_additional}
                                                className="form-control" autoComplete="off" /><label
                                                    className="floating-label">Please document any
                                                    additional
                                                    details</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="ane_Neuraxial" value={clinicValues.ane_Neuraxial}
                                                className="form-control" autoComplete="off" /><label
                                                    className="floating-label">Central Neuraxial
                                                    Block (specify) </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="check_item mt-0">
                                {clinicValues.ane_patient_pro_awake ?
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input type="radio"
                                            value={clinicValues.ane_patient_pro_awake} checked /><span>{clinicValues.ane_patient_pro_awake} </span></label>
                                        </div> {clinicValues.ane_patient_pro_awake && <a onClick={(e) => updateClinicValues(e, "ane_patient_pro_awake", false)}><span className="material-symbols-outlined"> reply
                                        </span></a>}

                                    </div>
                                    :
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)}  name="ane_patient_pro_awake" type="radio"
                                            value="Awake" /><span>Awake
                                            </span></label>
                                        </div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)}  name="ane_patient_pro_awake" type="radio" value="Asleep" /><span>
                                            Asleep
                                        </span></label></div>

                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)}  name="ane_patient_pro_awake" type="radio" value="Sedation" /><span>
                                            Sedation</span></label></div>
                                    </div>}

                                </div>
                                <div className="form_group">
                                    <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="ane_Asepsis" value={clinicValues.ane_Asepsis}  className="form-control"
                                        autoComplete="off" /><label className="floating-label">Asepsis </label>
                                    </div>
                                </div>
                                <div className="check_item mt-10">
                                {clinicValues.ane_gloves ?
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input type="radio"
                                            value={clinicValues.ane_gloves} checked /><span>{clinicValues.ane_gloves} </span></label>
                                        </div> {clinicValues.ane_gloves && <a onClick={(e) => updateClinicValues(e, "ane_gloves", false)}><span className="material-symbols-outlined"> reply
                                        </span></a>}

                                    </div>
                                    :
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_gloves" type="radio"
                                            value="Gown" /><span>Gown
                                            </span></label>
                                        </div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_gloves"  type="radio" value="Gloves" /><span>
                                            Gloves
                                        </span></label></div>

                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_gloves"  type="radio" value="Hat" /><span>
                                            Hat
                                        </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_gloves"  type="radio" value="Drape" /><span>
                                            Drape
                                        </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_gloves"  type="radio" value="0.5% CHG / 70% DEB" /><span>0.5% CHG / 70% DEB </span></label></div>
                                    </div>}
                                </div>
                                <div className="check_item mt-0"><label className="check">Position</label>
                                {clinicValues.ane_position ?
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input type="radio"
                                            value={clinicValues.ane_position} checked /><span>{clinicValues.ane_position} </span></label>
                                        </div> {clinicValues.ane_position && <a onClick={(e) => updateClinicValues(e, "ane_position", false)}><span className="material-symbols-outlined"> reply
                                        </span></a>}

                                    </div>
                                    :
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_position" type="radio" value="Sitting" /><span>Sitting</span></label>
                                        </div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_position" type="radio" value="Lateral L R" /><span> Lateral L R</span></label></div>

                                    </div>}

                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="ane_Needle_level" value={clinicValues.ane_Needle_level} 
                                                className="form-control" autoComplete="off" /><label
                                                    className="floating-label">Needle Level </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="ane_LA_to_skin" value={clinicValues.ane_LA_to_skin} 
                                                className="form-control" autoComplete="off" /><label
                                                    className="floating-label">LA to skin </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="ane_Needle" value={clinicValues.ane_Needle} 
                                                className="form-control" autoComplete="off" /><label
                                                    className="floating-label">Needle(s) </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="ane_Catheter" value={clinicValues.ane_Catheter} 
                                                className="form-control" autoComplete="off" /><label
                                                    className="floating-label">Catheter</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="ane_Drugs" value={clinicValues.ane_Drugs} 
                                                className="form-control" autoComplete="off" /><label
                                                    className="floating-label">Drugs</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="ane_concentration" value={clinicValues.ane_concentration} 
                                                className="form-control" autoComplete="off" /><label
                                                    className="floating-label"> Dose / concentration
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="check_item mt-0">
                                {clinicValues.ane_resistence ?
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input type="radio"
                                            value={clinicValues.ane_resistence} checked /><span>{clinicValues.ane_resistence} </span></label>
                                        </div> {clinicValues.ane_resistence && <a onClick={(e) => updateClinicValues(e, "ane_resistence", false)}><span className="material-symbols-outlined"> reply
                                        </span></a>}

                                    </div>
                                    :
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_resistence" type="radio" value="Loss Of Resistence at" /><span>Loss Of Resistence at </span></label>
                                        </div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_resistence" type="radio" value="Centimetres catheter tip" /><span>
                                            Centimetres catheter tip </span></label></div>

                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_resistence" type="radio" value="Centimetres/skin LO twitch" /><span>
                                            Centimetres/skin LO twitch </span></label>
                                        </div>

                                    </div>}
                                </div>
                                <p className="note2" >Select all that apply for Multiselect forms {clinicValues.ane_patient_pre_dose && <a onClick={(e) => updateClinicValues(e, "ane_patient_pre_dose", false)}><span className="material-symbols-outlined"> reply </span></a>}</p>
                                {clinicValues.ane_patient_pre_dose && clinicValues.ane_patient_pre_dose_list && clinicValues.ane_patient_pre_dose_list.length > 0 ? <div className="check_list w_white mb-0">

                                    {clinicValues.ane_patient_pre_dose_list.map((item, i) => (
                                        <div className="check_item active" key={i} onClick={(e) => updateClinicValues(e, "ane_patient_pre_dose", false)} ><label className="check">{item}</label></div>
                                    ))}

                                </div> :
                                <div className="check_list w_white mb-0">
                                    <span className="check_btn2" onClick={(e) => updateClinicValues(e, "ane_patient_pre_dose", true)}><span className="material-icons"> done </span></span>

                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_patient_pre_dose_list", "Test dose", "ane_patient_pre_dose")}><label className="check">Test dose</label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_patient_pre_dose_list", "Attempts", "ane_patient_pre_dose")}><label className="check">Attempts</label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_patient_pre_dose_list", "No blood", "ane_patient_pre_dose")}><label className="check">No blood </label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_patient_pre_dose_list", "No CSF", "ane_patient_pre_dose")}><label className="check">No CSF</label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_patient_pre_dose_list", "No paraesthesia", "ane_patient_pre_dose")}><label className="check">No paraesthesia </label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_patient_pre_dose_list", "No local anaesthesia toxicity", "ane_patient_pre_dose")}><label className="check">No local anaesthesia toxicity</label>
                                    </div>
                                </div>}
                                <div className="form_group mb-20 mt-20">
                                    <textarea className="textarea" placeholder="Comments" onChange={(e) => updateInputValue(e)} name="ane_patient_pre_comments2" value={clinicValues.ane_patient_pre_comments2} rows="2"
                                        cols="30"></textarea>
                                </div>
                                <div className="yn_switch mb-10 mt-10">
                                    <div className="label-container">
                                        <p>Catheter to be pulled out on the ward</p>
                                    </div>
                                    <label className="switch-container">
                                        <input type="checkbox" className="switch_check" name="ane_catheter" onChange={(e) => handleChange(e)} checked={clinicValues.ane_catheter} />
                                        <div className="switch-bg"></div>
                                        <div className="round-box"></div>
                                        <div className="switch-left">
                                            <span>YES</span>
                                        </div>
                                        <div className="switch-right">
                                            <span>NO</span>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div className="section simple_wound ctr">
                                <h2 className="popup_title">PERIPHERAL NERVE BLOCK</h2>
                                <div className="pd_border"></div>
                                <p className="field_title bold">Clinical indications</p>
                                <div className="form_group">
                                    <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="ane_peripheral_nerve" value={clinicValues.ane_peripheral_nerve} className="form-control"
                                        autoComplete="off" /><label className="floating-label">Diagnosis
                                        </label>
                                    </div>
                                </div>
                                <p className="note2" >Select all that apply for Multiselect forms {clinicValues.ane_post_operative && <a onClick={(e) => updateClinicValues(e, "ane_post_operative", false)}><span className="material-symbols-outlined"> reply </span></a>}</p>
                                {clinicValues.ane_post_operative && clinicValues.ane_post_operative_list && clinicValues.ane_post_operative_list.length > 0 ? <div className="check_list w_white mb-0">

                                    {clinicValues.ane_post_operative_list.map((item, i) => (
                                        <div className="check_item active" key={i} onClick={(e) => updateClinicValues(e, "ane_post_operative", false)} ><label className="check">{item}</label></div>
                                    ))}

                                </div> :
                                <div className="check_list w_white mb-0">
                                    <span className="check_btn2" onClick={(e) => updateClinicValues(e, "ane_post_operative", true)}><span className="material-icons"> done </span></span>

                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_post_operative_list", "Post-operative pain body area", "ane_post_operative")}><label className="check">Post-operative pain body area</label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_post_operative_list", "Post-operative pain expected to be/is inadequately managed by oral or IV medicines", "ane_post_operative")}><label className="check">Post-operative pain expected to be/is inadequately managed by oral or IV medicines</label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_post_operative_list", "Regional anaesthesia expected to facilitate timely discharge from facility", "ane_post_operative")}><label className="check">Regional anaesthesia expected to facilitate timely discharge from facility </label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_post_operative_list", "Other", "ane_post_operative")}><label className="check">Other</label>
                                    </div>
                                </div>}


                                <p className="field_title bold mt-20">Procedure performed</p>
                                <p className="note2" >Select all that apply for Multiselect forms {clinicValues.ane_Thoracic && <a onClick={(e) => updateClinicValues(e, "ane_Thoracic", false)}><span className="material-symbols-outlined"> reply </span></a>}</p>
                                {clinicValues.ane_Thoracic && clinicValues.ane_Thoracic_list && clinicValues.ane_Thoracic_list.length > 0 ? <div className="check_list w_white mb-0">

                                    {clinicValues.ane_Thoracic_list.map((item, i) => (
                                        <div className="check_item active" key={i} onClick={(e) => updateClinicValues(e, "ane_Thoracic", false)} ><label className="check">{item}</label></div>
                                    ))}

                                </div> :
                                <div className="check_list w_white mb-0">
                                    <span className="check_btn2" onClick={(e) => updateClinicValues(e, "ane_Thoracic", true)}><span className="material-icons"> done </span></span>

                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_Thoracic_list", "Thoracic epidural", "ane_Thoracic")}><label className="check">Thoracic epidural </label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_Thoracic_list", "Lumbar epidural", "ane_Thoracic")}><label className="check">Lumbar epidural </label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_Thoracic_list", "Intraspinal narcotics", "ane_Thoracic")}><label className="check">Intraspinal narcotics </label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_Thoracic_list", "Epidural blood patch", "ane_Thoracic")}><label className="check">Epidural blood patch </label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_Thoracic_list", "Interscalene block", "ane_Thoracic")}><label className="check">Interscalene block </label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_Thoracic_list", "Popliteal block", "ane_Thoracic")}><label className="check">Popliteal block </label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_Thoracic_list", "Axillary block", "ane_Thoracic")}><label className="check">Axillary block </label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_Thoracic_list", "Ankle block", "ane_Thoracic")}><label className="check">Ankle block </label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_Thoracic_list", "Wrist block", "ane_Thoracic")}><label className="check">Wrist block </label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_Thoracic_list", "Lumbar plexus(psoas compartment) block", "ane_Thoracic")}><label className="check">Lumbar plexus(psoas compartment) block
                                    </label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_Thoracic_list", "Sciatic block", "ane_Thoracic")}><label className="check">Sciatic block </label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_Thoracic_list", "Femoral block", "ane_Thoracic")}><label className="check">Femoral block </label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_Thoracic_list", "Other block", "ane_Thoracic")}><label className="check">Other block </label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_Thoracic_list", "Single injection", "ane_Thoracic")}><label className="check">Single injection </label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_Thoracic_list", "Combination catheter insertion", "ane_Thoracic")}><label className="check">Combination catheter insertion </label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_Thoracic_list", "Dual catheters", "ane_Thoracic")}><label className="check">Dual catheters </label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_Thoracic_list", "Continuous pump", "ane_Thoracic")}><label className="check">Continuous pump </label>
                                    </div>

                                </div>}

                                <p className="field_title bold mt-20">PROCEDURE DETAILS</p>
                                <p className="note2" >Select all that apply for Multiselect forms {clinicValues.ane_procedure_details && <a onClick={(e) => updateClinicValues(e, "ane_procedure_details", false)}><span className="material-symbols-outlined"> reply </span></a>}</p>
                                {clinicValues.ane_procedure_details && clinicValues.ane_procedure_details_list && clinicValues.ane_procedure_details_list.length > 0 ? <div className="check_list w_white mb-0">

                                    {clinicValues.ane_procedure_details_list.map((item, i) => (
                                        <div className="check_item active" key={i} onClick={(e) => updateClinicValues(e, "ane_procedure_details", false)} ><label className="check">{item}</label></div>
                                    ))}

                                </div> :
                                <div className="check_list w_white mt-0 mb-10">
                                    <span className="check_btn2" onClick={(e) => updateClinicValues(e, "ane_procedure_details", true)}><span className="material-icons"> done </span></span>

                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_procedure_details_list", "Risks benefits explained - Patient wishes to proceed", "ane_procedure_details")}><label className="check">Risks benefits explained - Patient wishes to proceed </label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_procedure_details_list", "Informed consent obtained", "ane_procedure_details")}><label className="check">Informed consent obtained </label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_procedure_details_list", "Conscious sedation with indicated monitors", "ane_procedure_details")}><label className="check">Conscious sedation with indicated monitors </label>
                                    </div>

                                </div>}

                                <div className="check_item mt-0">
                                {clinicValues.ane_procedure_awake ?
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input type="radio"
                                                value={clinicValues.ane_procedure_awake} checked /><span>{clinicValues.ane_procedure_awake} </span></label>
                                            </div> {clinicValues.ane_procedure_awake && <a onClick={(e) => updateClinicValues(e, "ane_procedure_awake", false)}><span className="material-symbols-outlined"> reply
                                            </span></a>}

                                        </div>
                                        :
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_procedure_awake" type="radio" value="Awake" /><span>Awake
                                            </span></label>
                                        </div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_procedure_awake" type="radio"
                                            value="Asleep" /><span> Asleep
                                            </span></label></div>

                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_procedure_awake" type="radio"
                                            value="Sedation" /><span>
                                                Sedation</span></label></div>
                                    </div>}
                                </div>
                                <div className="form_group">
                                    <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="ane_pro_Asepsis" value={clinicValues.ane_pro_Asepsis} className="form-control"
                                        autoComplete="off" /><label className="floating-label">Asepsis </label>
                                    </div>
                                </div>
                                <div className="check_item mt-10">
                                {clinicValues.ane_pro_gown ?
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input type="radio"
                                            value={clinicValues.ane_pro_gown} checked /><span>{clinicValues.ane_pro_gown} </span></label>
                                        </div> {clinicValues.ane_pro_gown && <a onClick={(e) => updateClinicValues(e, "ane_pro_gown", false)}><span className="material-symbols-outlined"> reply
                                        </span></a>}

                                    </div>
                                    :
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_pro_gown" type="radio" value="Gown" /><span>Gown
                                            </span></label>
                                        </div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_pro_gown" type="radio" value="Gloves" /><span> Gloves
                                            </span></label></div>

                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_pro_gown" type="radio" value="Hat" /><span> Hat
                                            </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_pro_gown" type="radio" value="Mask" /><span> Mask
                                            </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_pro_gown" type="radio" value="Drape(s)" /><span> Drape(s)
                                            </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_pro_gown" type="radio" value="0.5% CHG / 70% DEB" /><span> 0.5% CHG / 70% DEB</span></label></div>
                                    </div>}
                                </div>
                                <p className="field_title bold mt-20">Location aid(s)</p>
                                <div className="check_item mt-10"><label className="check">Real time US </label>
                                {clinicValues.ane_pro_real ?
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input type="radio"
                                            value={clinicValues.ane_pro_real} checked /><span>{clinicValues.ane_pro_real} </span></label>
                                        </div> {clinicValues.ane_pro_real && <a onClick={(e) => updateClinicValues(e, "ane_pro_real", false)}><span className="material-symbols-outlined"> reply
                                        </span></a>}

                                    </div>
                                    :
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_pro_real" type="radio" value="In Plane" /><span>In Plane
                                            </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_pro_real" type="radio" value="Out of Plane" /><span>Out of Plane </span></label></div>
                                    </div>}
                                </div>
                                <div className="yn_switch mb-10 mt-10">
                                    <div className="label-container">
                                        <p>Nerve Stimulator </p>
                                    </div>
                                    <label className="switch-container">
                                        <input name="periop_omit" onChange={(e) => handleChange(e)} checked={clinicValues.ane_pro_Nerve_stimulator} type="checkbox" className="switch_check" />
                                        <div className="switch-bg"></div>
                                        <div className="round-box"></div>
                                        <div className="switch-left">
                                            <span>YES</span>
                                        </div>
                                        <div className="switch-right">
                                            <span>NO</span>
                                        </div>
                                    </label>
                                </div>
                                <div className="row mt-20">
                                    <div className="col-md-4">
                                        <div className="form_group form_tag ">
                                            <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="ane_pro_Twitch" value={clinicValues.ane_pro_Twitch} 
                                                className="form-control" autoComplete="off" /><label
                                                    className="floating-label">Twitch threshold
                                                </label>
                                            </div>
                                            mA
                                        </div>
                                    </div>
                                </div>
                                <p className="field_title bold mt-20">Position</p>
                                <div className="row mt-10">
                                    <div className="col-md-4">
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="ane_pro_Entry_site" value={clinicValues.ane_pro_Entry_site} 
                                                className="form-control" autoComplete="off" /><label
                                                    className="floating-label">Entry site /
                                                    Level</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="ane_pro_LA" value={clinicValues.ane_pro_LA} 
                                                className="form-control" autoComplete="off" /><label
                                                    className="floating-label">LA to skin</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="ane_pro_Needle" value={clinicValues.ane_pro_Needle} 
                                                className="form-control" autoComplete="off" /><label
                                                    className="floating-label">Needle(s):</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="ane_pro_Drugs" value={clinicValues.ane_pro_Drugs} 
                                                className="form-control" autoComplete="off" /><label
                                                    className="floating-label">Drugs</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="ane_pro_Dose" value={clinicValues.ane_pro_Dose} 
                                                className="form-control" autoComplete="off" /><label
                                                    className="floating-label">Dose /
                                                    concentration</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form_group">
                                        <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="ane_pro_Comments" value={clinicValues.ane_pro_Comments} 
                                            className="form-control" autoComplete="off" /><label
                                                className="floating-label">Comments</label>
                                        </div>
                                    </div>
                                </div>

                                <p className="note2" >Select all that apply for Multiselect forms {clinicValues.ane_pro_patient_pos && <a onClick={(e) => updateClinicValues(e, "ane_pro_patient_pos", false)}><span className="material-symbols-outlined"> reply </span></a>}</p>
                                {clinicValues.ane_pro_patient_pos && clinicValues.ane_pro_patient_pos_list && clinicValues.ane_pro_patient_pos_list.length > 0 ? <div className="check_list w_white mb-0">

                                    {clinicValues.ane_pro_patient_pos_list.map((item, i) => (
                                        <div className="check_item active" key={i} onClick={(e) => updateClinicValues(e, "ane_pro_patient_pos", false)} ><label className="check">{item}</label></div>
                                    ))}

                                </div> :
                                <div className="check_list w_white mt-20 mb-0">
                                    <span className="check_btn2" onClick={(e) => updateClinicValues(e, "ane_pro_patient_pos", true)}><span className="material-icons"> done </span></span>

                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_pro_patient_pos_list", "Patient position", "ane_pro_patient_pos")}><label className="check">Patient position </label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_pro_patient_pos_list", "Pertinent anatomy defined", "ane_pro_patient_pos")}><label className="check">Pertinent anatomy defined </label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_pro_patient_pos_list", "Sterile technique", "ane_pro_patient_pos")}><label className="check">Sterile technique</label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_pro_patient_pos_list", "Time out completed", "ane_pro_patient_pos")}><label className="check">Time out completed </label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_pro_patient_pos_list", "Needing used", "ane_pro_patient_pos")}><label className="check">Needing used</label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_pro_patient_pos_list", "Loss of twitch at –Ma regain of twitch at Ma", "ane_pro_patient_pos")}><label className="check">Loss of twitch at –Ma regain of twitch at Ma</label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_pro_patient_pos_list", "Medicines injected", "ane_pro_patient_pos")}><label className="check">Medicines injected </label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_pro_patient_pos_list", "Ultrasound guidance used", "ane_pro_patient_pos")}><label className="check">Ultrasound guidance used </label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_pro_patient_pos_list", "Intermittent aspiration during local anaesthetic administration", "ane_pro_patient_pos")}><label className="check">Intermittent aspiration during local anaesthetic administration</label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_pro_patient_pos_list", "No symptoms of intraneural or intra-venous injection", "ane_pro_patient_pos")}><label className="check">No symptoms of intraneural or intra-venous injection</label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_pro_patient_pos_list", "Patient tolerated procedure well", "ane_pro_patient_pos")}><label className="check">Patient tolerated procedure well</label>
                                    </div>

                                </div>}


                            </div>
                            <div className="section simple_wound ctr">
                                <h2 className="popup_title">ANAESTHETIC DRUGS</h2>
                                <div className="pd_border"></div>
                                <p className="field_title bold">CARRIER GASES</p>
                                <div className="row mt-20">
                                    <div className="col-md-6">
                                        <div className="form_group form_tag ">
                                            <div className="floating-label-group width-100"><input type="text" onChange={(e) => updateInputValue(e)} name="ane_carrier_o2" value={clinicValues.ane_carrier_o2}
                                                className="form-control" autoComplete="off" /><label
                                                    className="floating-label">O2</label>
                                            </div>
                                            litres per minute
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form_group form_tag ">
                                            <div className="floating-label-group width-100"><input type="text" onChange={(e) => updateInputValue(e)} name="ane_carrier_air" value={clinicValues.ane_carrier_air}
                                                className="form-control" autoComplete="off" /><label
                                                    className="floating-label">Air</label>
                                            </div>
                                            litres per minute
                                        </div>
                                    </div>
                                    <div className="col-md-6 mt-10">
                                        <div className="form_group form_tag ">
                                            <div className="floating-label-group "><input type="text" onChange={(e) => updateInputValue(e)} name="ane_carrier_Nitrous" value={clinicValues.ane_carrier_Nitrous}
                                                className="form-control" autoComplete="off" /><label
                                                    className="floating-label">Nitrous oxided </label>
                                            </div>
                                            litres per minute
                                        </div>
                                    </div>
                                </div>
                                <p className="field_title bold mt-20">INHALATIONAL AGENTS/VAPOURS</p>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form_group form_tag ">
                                            <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="ane_carrier_Isoflurane" value={clinicValues.ane_carrier_Isoflurane}
                                                className="form-control" autoComplete="off" /><label
                                                    className="floating-label">Isoflurane</label>
                                            </div>
                                            %
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form_group form_tag ">
                                            <div className="floating-label-group width-100"><input type="text" onChange={(e) => updateInputValue(e)} name="ane_carrier_Sevoflurane" value={clinicValues.ane_carrier_Sevoflurane}
                                                className="form-control" autoComplete="off" /><label
                                                    className="floating-label">Sevoflurane</label>
                                            </div>
                                            %
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form_group form_tag ">
                                            <div className="floating-label-group "><input type="text" onChange={(e) => updateInputValue(e)} name="ane_carrier_Desflurane" value={clinicValues.ane_carrier_Desflurane}
                                                className="form-control" autoComplete="off" /><label
                                                    className="floating-label">Desflurane </label>
                                            </div>
                                            %
                                        </div>
                                    </div>
                                </div>
                                <div className="yn_switch mb-0 mt-20">
                                    <div className="label-container">
                                        <p>Halothane </p>
                                    </div>
                                    <label className="switch-container">
                                        <input name="ane_Halothane" onChange={(e) => handleChange(e)} checked={clinicValues.ane_Halothane} type="checkbox" className="switch_check" />
                                        <div className="switch-bg"></div>
                                        <div className="round-box"></div>
                                        <div className="switch-left">
                                           <span>YES</span>
                                        </div>
                                        <div className="switch-right">
                                            <span>NO</span>
                                        </div>
                                    </label>
                                </div>
                                <p className="field_title bold mt-20">IV INDUCTION DRUGS</p>
                                <div className="check_item mt-10">
                                {clinicValues.ane_induction_drug ?
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input type="radio"
                                            value={clinicValues.ane_induction_drug} checked /><span>{clinicValues.ane_induction_drug} </span></label>
                                        </div> {clinicValues.ane_induction_drug && <a onClick={(e) => updateClinicValues(e, "ane_induction_drug", false)}><span className="material-symbols-outlined"> reply
                                        </span></a>}

                                    </div>
                                    :
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)}  name="ane_induction_drug" type="radio"
                                            value="Midazolam mg" /><span>Midazolam mg
                                            </span></label>
                                        </div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)}  name="ane_induction_drug" type="radio"
                                            value="Fentanyl mcg" /><span> Fentanyl mcg
                                            </span></label></div>

                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)}  name="ane_induction_drug" type="radio"
                                            value="Alfentanyl" /><span> Alfentanyl
                                            </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)}  name="ane_induction_drug" type="radio"
                                            value="Propofol mg" /><span> Propofol mg
                                            </span></label></div>

                                   
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)}  name="ane_induction_drug" type="radio"
                                            value="Etomidate" /><span>Etomidate
                                            </span></label>
                                        </div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)}  name="ane_induction_drug" type="radio"
                                            value="Ketamine mg" /><span>Ketamine mg
                                            </span></label></div>

                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)}  name="ane_induction_drug" type="radio"
                                            value="Buprenorphine" /><span> Buprenorphine
                                            </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)}  name="ane_induction_drug" type="radio"
                                            value="Thiopentone" /><span> Thiopentone
                                            </span></label></div>

                                    
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)}  name="ane_induction_drug" type="radio"
                                            value="Morphine mg" /><span>Morphine mg
                                            </span></label>
                                        </div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)}  name="ane_induction_drug" type="radio"
                                            value="Diamorphine" /><span>Diamorphine
                                            </span></label></div>
                                    </div>}

                                </div>

                                <p className="field_title bold mt-20">TIVA (IV) INFUSION</p>
                                <div className="check_item mt-0">
                                {clinicValues.ane_tiva_infusion ?
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input type="radio"
                                            value={clinicValues.ane_tiva_infusion} checked /><span>{clinicValues.ane_tiva_infusion} </span></label>
                                        </div> {clinicValues.ane_tiva_infusion && <a onClick={(e) => updateClinicValues(e, "ane_tiva_infusion", false)}><span className="material-symbols-outlined"> reply
                                        </span></a>}

                                    </div>
                                    :
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)}  name="ane_tiva_infusion" type="radio"
                                            value="Propofol infusion (mcg/kg/min)" /><span>Propofol infusion (mcg/kg/min) </span></label>
                                        </div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)}  name="ane_tiva_infusion" type="radio"
                                            value="Remifentanyl infusion (mcg/kg/min)" /><span>Remifentanyl infusion (mcg/kg/min)
                                            </span></label></div>
                                    </div>}
                                </div>
                                <div className="check_item mt-0"><label className="check">REVERSAL</label>
                                {clinicValues.ane_tiva_reversal ?
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input type="radio"
                                            value={clinicValues.ane_tiva_reversal} checked /><span>{clinicValues.ane_tiva_reversal} </span></label>
                                        </div> {clinicValues.ane_tiva_reversal && <a onClick={(e) => updateClinicValues(e, "ane_tiva_reversal", false)}><span className="material-symbols-outlined"> reply
                                        </span></a>}

                                    </div>
                                    :
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_tiva_reversal" type="radio"
                                            value="Naloxone mg" /><span>Naloxone mg
                                            </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_tiva_reversal" type="radio"
                                            value="Flumazenil mg" /><span>Flumazenil mg</span></label></div>
                                    </div>}
                                </div>
                                <p className="field_title bold mt-20">MUSCLE RELAXANTS</p>
                                <div className="check_item mt-0">
                                {clinicValues.ane_muscle_relaxants ?
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input type="radio"
                                            value={clinicValues.ane_muscle_relaxants} checked /><span>{clinicValues.ane_muscle_relaxants} </span></label>
                                        </div> {clinicValues.ane_muscle_relaxants && <a onClick={(e) => updateClinicValues(e, "ane_muscle_relaxants", false)}><span className="material-symbols-outlined"> reply
                                        </span></a>}

                                    </div>
                                    :
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_muscle_relaxants" type="radio"
                                            value="Succinylcholine mg" /><span>Succinylcholine mg </span></label>
                                        </div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_muscle_relaxants" type="radio"
                                            value="Rocuronium mg" /><span>Rocuronium mg
                                            </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_muscle_relaxants" type="radio"
                                            value="Vecuronium mg" /><span>Vecuronium mg
                                            </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_muscle_relaxants" type="radio"
                                            value="Mevacuronium mg" /><span>Mevacuronium mg </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_muscle_relaxants" type="radio"
                                            value="Cisatracurium mg" /><span>Cisatracurium mg </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_muscle_relaxants" type="radio"
                                            value="Atracurium mg" /><span>Atracurium mg
                                            </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_muscle_relaxants" type="radio"
                                            value="Other (State)" /><span>Other (State)
                                            </span></label></div>
                                    </div>}
                                </div>
                                <div className="check_item mt-0 mb-0"><label className="check">REVERSALS</label>
                                {clinicValues.ane_reversals ?
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input type="radio"
                                            value={clinicValues.ane_reversals} checked /><span>{clinicValues.ane_reversals} </span></label>
                                        </div> {clinicValues.ane_reversals && <a onClick={(e) => updateClinicValues(e, "ane_reversals", false)}><span className="material-symbols-outlined"> reply
                                        </span></a>}

                                    </div>
                                    :
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_reversals" type="radio"
                                            value="Atropine mg" /><span>Atropine mg
                                            </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_reversals" type="radio"
                                            value="Neostigmine mg" /><span>Neostigmine mg
                                            </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_reversals" type="radio"
                                            value="Sugammadex mg (Very expensive)" /><span>Sugammadex mg (Very expensive) </span></label>
                                        </div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_reversals" type="radio"
                                            value="Glycopyrrolate" /><span>Glycopyrrolate
                                            </span></label></div>
                                    </div>}
                                </div>
                                <p className="field_title bold mt-20">EMERGENCY DRUGS</p>
                                <div className="check_item mt-10"><label className="check">VASO ACTIVE AGENTS</label>
                                {clinicValues.ane_vaso_active ?
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input type="radio"
                                            value={clinicValues.ane_vaso_active} checked /><span>{clinicValues.ane_vaso_active} </span></label>
                                        </div> {clinicValues.ane_vaso_active && <a onClick={(e) => updateClinicValues(e, "ane_vaso_active", false)}><span className="material-symbols-outlined"> reply
                                        </span></a>}

                                    </div>
                                    :
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_vaso_active" type="radio" value="Ephedrine mg" /><span>Ephedrine mg
                                            </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_vaso_active" type="radio"  value="Phenylephrine mcg" /><span>Phenylephrine mcg </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_vaso_active" type="radio" value="Epinephrine (Adrenaline)" /><span>Epinephrine (Adrenaline) </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_vaso_active" type="radio" value="Norepinephrine" /><span>Norepinephrine
                                            </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_vaso_active" type="radio" value="Dopamine" /><span>Dopamine
                                            </span></label></div>
                                    </div>}
                                </div>

                                <div className="check_item mt-0"><label className="check">ANTIHYPERTENSIVES</label>
                                {clinicValues.ane_antihyper ?
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input type="radio"
                                            value={clinicValues.ane_antihyper} checked /><span>{clinicValues.ane_antihyper} </span></label>
                                        </div> {clinicValues.ane_antihyper && <a onClick={(e) => updateClinicValues(e, "ane_antihyper", false)}><span className="material-symbols-outlined"> reply
                                        </span></a>}

                                    </div>
                                    :
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_antihyper" type="radio"
                                            value="Furosemide" /><span>Furosemide
                                            </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_antihyper" type="radio"
                                            value="Propranolol" /><span>Propranolol
                                            </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_antihyper" type="radio"
                                            value="Labetolol" /><span>Labetolol
                                            </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_antihyper" type="radio"
                                            value="Nifedipine" /><span>Nifedipine
                                            </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_antihyper" type="radio"
                                            value="GTN Spray" /><span>GTN Spray
                                            </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_antihyper" type="radio"
                                            value="Infusion" /><span>Infusion</span></label></div>
                                    </div>}
                                </div>
                                <div className="check_item mt-0"><label className="check">ANTIARRHYTHMIC</label>
                                {clinicValues.ane_antiarrhythmic ?
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input type="radio"
                                            value={clinicValues.ane_antiarrhythmic} checked /><span>{clinicValues.ane_antiarrhythmic} </span></label>
                                        </div> {clinicValues.ane_antiarrhythmic && <a onClick={(e) => updateClinicValues(e, "ane_antiarrhythmic", false)}><span className="material-symbols-outlined"> reply
                                        </span></a>}

                                    </div>
                                    :
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_antiarrhythmic" type="radio"
                                            value="Propranolol" /><span>Propranolol
                                            </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_antiarrhythmic" type="radio"
                                            value="Diltiazem" /><span>Diltiazem
                                            </span></label></div>

                                    </div>}
                                </div>
                                <p className="field_title bold mt-20">CUPBOARD DRUGS</p>
                                <div className="check_item mt-10"><label className="check">SEDATIVES, HYPNOTICS,
                                    ANXIOLYTICS</label>
                                    {clinicValues.ane_sedatives ?
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input type="radio"
                                            value={clinicValues.ane_sedatives} checked /><span>{clinicValues.ane_sedatives} </span></label>
                                        </div> {clinicValues.ane_sedatives && <a onClick={(e) => updateClinicValues(e, "ane_sedatives", false)}><span className="material-symbols-outlined"> reply
                                        </span></a>}

                                    </div>
                                    :
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_sedatives" type="radio" value="Diazepam" /><span>Diazepam
                                                (Epilepsy) </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_sedatives" type="radio" value="Lorazepam" /><span>Lorazepam
                                            </span></label></div>
                                    </div>}
                                </div>
                                <div className="check_item mt-0"><label className="check">ANALGESICS</label>
                                {clinicValues.ane_analegesics ?
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input type="radio"
                                            value={clinicValues.ane_analegesics} checked /><span>{clinicValues.ane_analegesics} </span></label>
                                        </div> {clinicValues.ane_analegesics && <a onClick={(e) => updateClinicValues(e, "ane_analegesics", false)}><span className="material-symbols-outlined"> reply
                                        </span></a>}

                                    </div>
                                    :
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_analegesics" type="radio" value="Paracetamol" /><span>Paracetamol
                                            </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_analegesics" type="radio" value="Diclofenac" /><span>Diclofenac
                                            </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_analegesics" type="radio" value="Ketorolac" /><span>Ketorolac
                                            </span></label></div>
                                    </div>}
                                </div>
                                <div className="check_item mt-0"><label className="check">ANTISIALOGUES</label>
                                {clinicValues.ane_antisialogues ?
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input type="radio"
                                            value={clinicValues.ane_antisialogues} checked /><span>{clinicValues.ane_antisialogues} </span></label>
                                        </div> {clinicValues.ane_antisialogues && <a onClick={(e) => updateClinicValues(e, "ane_antisialogues", false)}><span className="material-symbols-outlined"> reply
                                        </span></a>}

                                    </div>
                                    :
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_antisialogues" type="radio"
                                            value="Atropine mg" /><span>Atropine mg
                                            </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_antisialogues" type="radio"
                                            value="Glycopyrrolate 0.2 mg" /><span>Glycopyrrolate 0.2 mg </span></label></div>

                                    </div>}
                                </div>
                                <div className="check_item mt-0"><label className="check">ANTIEMETICS</label>
                                {clinicValues.ane_antimetics ?
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input type="radio"
                                            value={clinicValues.ane_antimetics} checked /><span>{clinicValues.ane_antimetics} </span></label>
                                        </div> {clinicValues.ane_antimetics && <a onClick={(e) => updateClinicValues(e, "ane_antimetics", false)}><span className="material-symbols-outlined"> reply
                                        </span></a>}

                                    </div>
                                    :
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_antimetics" type="radio"
                                            value="Ondansetron" /><span>Ondansetron
                                            </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_antimetics" type="radio"
                                            value="Cyclizine" /><span> Cyclizine
                                            </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_antimetics" type="radio"
                                            value="Metoclopramide" /><span>Metoclopramide
                                            </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_antimetics" type="radio"
                                            value="Droperidol" /><span>Droperidol
                                            </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_antimetics" type="radio"
                                            value="Dexamethasone" /><span>Dexamethasone
                                            </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_antimetics" type="radio"
                                            value="Prochlorperazine(Stemitil)" /><span>Prochlorperazine(Stemitil) </span></label>
                                        </div>

                                    </div>}
                                </div>
                                <div className="check_item mt-0"><label className="check">H2 RECEPTOR ANTAGONISTS</label>
                                {clinicValues.ane_h2_receptor ?
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input type="radio"
                                            value={clinicValues.ane_h2_receptor} checked /><span>{clinicValues.ane_h2_receptor} </span></label>
                                        </div> {clinicValues.ane_h2_receptor && <a onClick={(e) => updateClinicValues(e, "ane_h2_receptor", false)}><span className="material-symbols-outlined"> reply
                                        </span></a>}

                                    </div>
                                    :
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input name="ane_h2_receptor" onClick={(e) => updateInputValue(e)} type="radio"
                                            value="Lansoprazole" /><span>Lansoprazole
                                            </span></label></div>
                                        <div className="select_btn"><label><input name="ane_h2_receptor" onClick={(e) => updateInputValue(e)} type="radio"
                                            value="Ranitidine" /><span> Ranitidine
                                            </span></label></div>
                                    </div>}
                                </div>
                                <div className="check_item mt-0"><label className="check">ANTICOAGULANTS</label>
                                {clinicValues.ane_anticogulants ?
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input type="radio"
                                            value={clinicValues.ane_anticogulants} checked /><span>{clinicValues.ane_anticogulants} </span></label>
                                        </div> {clinicValues.ane_anticogulants && <a onClick={(e) => updateClinicValues(e, "ane_anticogulants", false)}><span className="material-symbols-outlined"> reply
                                        </span></a>}

                                    </div>
                                    :
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_anticogulants" type="radio"
                                            value="Fragmin" /><span>Fragmin
                                            </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_anticogulants" type="radio"
                                            value="Heparin" /><span> Heparin
                                            </span></label></div>
                                    </div>}
                                </div>
                                <div className="check_item mt-0"><label className="check">OTHERS</label>
                                {clinicValues.ane_cupboard_others ?
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input type="radio"
                                            value={clinicValues.ane_cupboard_others} checked /><span>{clinicValues.ane_cupboard_others} </span></label>
                                        </div> {clinicValues.ane_cupboard_others && <a onClick={(e) => updateClinicValues(e, "ane_cupboard_others", false)}><span className="material-symbols-outlined"> reply
                                        </span></a>}

                                    </div>
                                    :
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_cupboard_others" type="radio"
                                            value="Tranexamic acid" /><span>Tranexamic acid </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_cupboard_others" type="radio"
                                            value="Steroids" /><span> Steroids
                                            </span></label></div>
                                    </div>}
                                </div>
                                <p className="field_title bold mt-20">FLUIDS AND ELECTROLYTES</p>
                                <div className="check_item mt-0">
                                {clinicValues.ane_fluids_electrolytes ?
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input type="radio"
                                            value={clinicValues.ane_fluids_electrolytes} checked /><span>{clinicValues.ane_fluids_electrolytes} </span></label>
                                        </div> {clinicValues.ane_fluids_electrolytes && <a onClick={(e) => updateClinicValues(e, "ane_fluids_electrolytes", false)}><span className="material-symbols-outlined"> reply
                                        </span></a>}

                                    </div>
                                    :
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_fluids_electrolytes" type="radio"
                                            value="5% Dextrose" /><span>5% Dextrose
                                            </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_fluids_electrolytes" type="radio"
                                            value="Ringers" /><span> Ringers
                                                lactate </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_fluids_electrolytes" type="radio"
                                            value="Normal Saline" /><span> Normal Saline
                                            </span></label></div>
                                    </div>}
                                </div>
                                <div className="check_item mt-0"><label className="check">COLLOIDS</label>
                                {clinicValues.ane_colloids ?
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input type="radio"
                                            value={clinicValues.ane_colloids} checked /><span>{clinicValues.ane_colloids} </span></label>
                                        </div> {clinicValues.ane_colloids && <a onClick={(e) => updateClinicValues(e, "ane_colloids", false)}><span className="material-symbols-outlined"> reply
                                        </span></a>}

                                    </div>
                                    :
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)}  name="ane_colloids" type="radio"  value="Albumin5%" /><span>Albumin5%
                                            </span></label></div>

                                    </div>}
                                </div>
                                <div className="check_item mt-0"><label className="check">BLOOD PRODUCTS</label>
                                {clinicValues.ane_Blood_product ?
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input type="radio"
                                            value={clinicValues.ane_Blood_product} checked /><span>{clinicValues.ane_Blood_product} </span></label>
                                        </div> {clinicValues.ane_Blood_product && <a onClick={(e) => updateClinicValues(e, "ane_Blood_product", false)}><span className="material-symbols-outlined"> reply
                                        </span></a>}

                                    </div>
                                    :
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_Blood_product" type="radio"
                                            value="Blood" /><span>Blood
                                            </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_Blood_product" type="radio"
                                            value="Packed red cells" /><span> Packed red cells </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_Blood_product" type="radio"
                                            value="Fresh frozen plasma" /><span> Fresh frozen plasma </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_Blood_product" type="radio"
                                            value="Platelets" /><span> Platelets
                                            </span></label></div>
                                    </div>}

                                </div>
                                <p className="field_title bold mt-20">LOCAL ANAESTHETICS</p>
                                <div className="check_item mt-0"><label className="check">Lignocaine</label>
                                {clinicValues.ane_Lignocaine ?
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input type="radio"
                                            value={clinicValues.ane_Lignocaine} checked /><span>{clinicValues.ane_Lignocaine} </span></label>
                                        </div> {clinicValues.ane_Lignocaine && <a onClick={(e) => updateClinicValues(e, "ane_Lignocaine", false)}><span className="material-symbols-outlined"> reply
                                        </span></a>}

                                    </div>
                                    :
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_Lignocaine" type="radio" value="1%" /><span>1%
                                            </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_Lignocaine" type="radio"
                                            value="2%" /><span> 2%
                                            </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_Lignocaine" type="radio"
                                            value="Lignocaine Gel" /><span>Lignocaine Gel</span></label></div>

                                    </div>}
                                </div>
                                <div className="check_item mt-0"><label className="check">Bupivacaine</label>
                                {clinicValues.ane_Bupivacaine ?
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input type="radio"
                                            value={clinicValues.ane_Bupivacaine} checked /><span>{clinicValues.ane_Bupivacaine} </span></label>
                                        </div> {clinicValues.ane_Bupivacaine && <a onClick={(e) => updateClinicValues(e, "ane_Bupivacaine", false)}><span className="material-symbols-outlined"> reply
                                        </span></a>}

                                    </div>
                                    :
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_Bupivacaine" type="radio" value="0.25%" /><span>0.25%
                                            </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_Bupivacaine" type="radio" value="0.5%" /><span> 0.5%
                                            </span></label></div>

                                    </div>}
                                </div>
                                <div className="check_item mt-0"><label className="check">Chirocaine (Levo bupivacaine)</label>
                                {clinicValues.ane_Chirocaine ?
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input type="radio"
                                            value={clinicValues.ane_Chirocaine} checked /><span>{clinicValues.ane_Chirocaine} </span></label>
                                        </div> {clinicValues.ane_Chirocaine && <a onClick={(e) => updateClinicValues(e, "ane_Chirocaine", false)}><span className="material-symbols-outlined"> reply
                                        </span></a>}

                                    </div>
                                    :
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_Chirocaine" type="radio" value="0.25%" /><span>0.25%
                                            </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_Chirocaine" type="radio" value="0.5%" /><span> 0.5%
                                            </span></label></div>

                                    </div>}
                                </div>
                                <div className="yn_switch mb-10 mt-10">
                                    <div className="label-container">
                                        <p>Prilocaine</p>
                                    </div>
                                    <label className="switch-container">
                                        <input name="ane_Prilocaine" onChange={(e) => handleChange(e)} checked={clinicValues.ane_Prilocaine} type="checkbox" className="switch_check"  />
                                        <div className="switch-bg"></div>
                                        <div className="round-box"></div>
                                        <div className="switch-left">
                                            <span>YES</span>
                                        </div>
                                        <div className="switch-right">
                                            <span>NO</span>
                                        </div>
                                    </label>
                                </div>
                                <p className="field_title bold mt-20">MISCELLANEOUS</p>
                                <div className="check_item mt-0"><label className="check">OBG medications</label>
                                {clinicValues.ane_miscellaneous ?
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input type="radio"
                                            value={clinicValues.ane_miscellaneous} checked /><span>{clinicValues.ane_miscellaneous} </span></label>
                                        </div> {clinicValues.ane_miscellaneous && <a onClick={(e) => updateClinicValues(e, "ane_miscellaneous", false)}><span className="material-symbols-outlined"> reply
                                        </span></a>}

                                    </div>
                                    :
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_miscellaneous" type="radio" value="Oxytocin" /><span>Oxytocin
                                            </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_miscellaneous" type="radio"
                                            value="Ergometrine" /><span> Ergometrine
                                            </span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ane_miscellaneous" type="radio"
                                            value="Carboprost" /><span>Carboprost
                                            </span></label></div>

                                    </div>}
                                </div>

                                <p className="note2" >Select all that apply for Multiselect forms {clinicValues.ane_obg_medications && <a onClick={(e) => updateClinicValues(e, "ane_obg_medications", false)}><span className="material-symbols-outlined"> reply </span></a>}</p>
                                {clinicValues.ane_obg_medications && clinicValues.ane_obg_medications_list && clinicValues.ane_obg_medications_list.length > 0 ? <div className="check_list w_white mb-0">

                                    {clinicValues.ane_obg_medications_list.map((item, i) => (
                                        <div className="check_item active" key={i} onClick={(e) => updateClinicValues(e, "ane_obg_medications", false)} ><label className="check">{item}</label></div>
                                    ))}

                                </div> :
                                <div className="check_list w_white mt-0">
                                    <span className="check_btn2" onClick={(e) => updateClinicValues(e, "ane_obg_medications", true)}><span className="material-icons"> done </span></span>

                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_obg_medications_list", "Insulin", "ane_obg_medications")}><label className="check">Insulin </label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_obg_medications_list", "Potassium chloride", "ane_obg_medications")}><label className="check">Potassium chloride </label>
                                    </div>
                                </div>}

                            </div>
                            <div className="section simple_wound ctr">
                                <h2 className="popup_title">CONTROLLED DRUGS</h2>
                                <div className="pd_border"></div>
                                <div className="prescription_tb2 mb-10">
                                    <table className="code_table table-bordered sin">
                                        <thead>
                                            <tr>
                                                <th width="50%">DRUG </th>
                                                <th width="50%">WASTE</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td> <input type="text" className="field" onChange={(e) => updateInputValue(e)} name="ane_c_drug1" value={clinicValues.ane_c_drug1} /></td>
                                                <td><input type="text" className="field" onChange={(e) => updateInputValue(e)} name="ane_c_waste1" value={clinicValues.ane_c_waste1} /></td>
                                            </tr>
                                            <tr>
                                            <td> <input type="text" className="field" onChange={(e) => updateInputValue(e)} name="ane_c_drug2" value={clinicValues.ane_c_drug2} /></td>
                                                <td><input type="text" className="field" onChange={(e) => updateInputValue(e)} name="ane_c_waste2" value={clinicValues.ane_c_waste2} /></td>
                                            </tr>
                                            <tr>
                                            <td> <input type="text" className="field" onChange={(e) => updateInputValue(e)} name="ane_c_drug3" value={clinicValues.ane_c_drug3} /></td>
                                                <td><input type="text" className="field" onChange={(e) => updateInputValue(e)} name="ane_c_waste3" value={clinicValues.ane_c_waste3} /></td>
                                            </tr>
                                            <tr>
                                            <td> <input type="text" className="field" onChange={(e) => updateInputValue(e)} name="ane_c_drug4" value={clinicValues.ane_c_drug4} /></td>
                                                <td><input type="text" className="field" onChange={(e) => updateInputValue(e)} name="ane_c_waste4" value={clinicValues.ane_c_waste4} /></td>
                                            </tr>
                                            <tr>
                                            <td> <input type="text" className="field" onChange={(e) => updateInputValue(e)} name="ane_c_drug5" value={clinicValues.ane_c_drug5} /></td>
                                                <td><input type="text" className="field" onChange={(e) => updateInputValue(e)} name="ane_c_waste5" value={clinicValues.ane_c_waste5} /></td>
                                            </tr>
                                            <tr>
                                            <td> <input type="text" className="field" onChange={(e) => updateInputValue(e)} name="ane_c_drug6" value={clinicValues.ane_c_drug6} /></td>
                                                <td><input type="text" className="field" onChange={(e) => updateInputValue(e)} name="ane_c_waste6" value={clinicValues.ane_c_waste6} /></td>
                                            </tr>
                                            <tr>
                                                <td>
                                                {file.providerSign_result || clinicValues.providerSign_result ? <img src={file.providerSign_result || clinicValues.providerSign_result} /> :
                                                <div className="signature_block">
                                                    <SignaturePad canvasProps={{ className: "sigPad" }} ref={(ref) => { providerSign = ref }} />
                                                    <p>Provider Signature </p>
                                                    <button onClick={(e) => trim(e, "providerSign", providerSign)}>Trim</button>
                                                    <button onClick={(e) => clear(e, providerSign)}> Clear</button>
                                                </div>}

                                               
                                                </td>
                                                <td>

                                                {file.witnessSign2_result || clinicValues.witnessSign2_result ? <img src={file.witnessSign2_result || clinicValues.witnessSign2_result} /> :
                                                <div className="signature_block">
                                                    <SignaturePad canvasProps={{ className: "sigPad" }} ref={(ref) => { witnessSign2 = ref }} />
                                                    <p>Provider Signature </p>
                                                    <button onClick={(e) => trim(e, "witnessSign2", witnessSign2)}>Trim</button>
                                                    <button onClick={(e) => clear(e, witnessSign2)}> Clear</button>
                                                </div>}

                                               
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="form_group">
                                    <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="ane_Noteworthy" value={clinicValues.ane_Noteworthy} className="form-control"
                                        autoComplete="off" /><label className="floating-label">Noteworthy complications or events that occur during the procedure </label>
                                    </div>
                                </div>
                                <p className="field_title bold mt-20">Action list</p>
                                <p className="field_title mt-10">AIRWAY MANAGEMENT</p>
                                <p className="note2" >Select all that apply for Multiselect forms {clinicValues.ane_airway_manage && <a onClick={(e) => updateClinicValues(e, "ane_airway_manage", false)}><span className="material-symbols-outlined"> reply </span></a>}</p>
                                {clinicValues.ane_airway_manage && clinicValues.ane_airway_manage_list && clinicValues.ane_airway_manage_list.length > 0 ? <div className="check_list w_white mb-0">

                                    {clinicValues.ane_airway_manage_list.map((item, i) => (
                                        <div className="check_item active" key={i} onClick={(e) => updateClinicValues(e, "ane_airway_manage", false)} ><label className="check">{item}</label></div>
                                    ))}

                                </div> :
                                <div className="check_list w_white mt-0 mb-10">
                                    <span className="check_btn2" onClick={(e) => updateClinicValues(e, "ane_airway_manage", true)}><span className="material-icons"> done </span></span>
                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_airway_manage_list", "LMA", "ane_airway_manage")}><label className="check">LMA</label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_airway_manage_list", "Mask ventilation", "ane_airway_manage")}><label className="check">Mask ventilation </label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_airway_manage_list", "O2 supplementation", "ane_airway_manage")}><label className="check">O2 supplementation</label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_airway_manage_list", "Suctioning", "ane_airway_manage")}><label className="check">Suctioning</label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_airway_manage_list", "Tee probe placement", "ane_airway_manage")}><label className="check">Tee probe placement</label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_airway_manage_list", "Tracheal in intubation", "ane_airway_manage")}><label className="check">Tracheal in intubation</label>
                                    </div>

                                </div>}

                                <p className="field_title mt-20">CARDIOVASCULAR</p>
                                <p className="note2" >Select all that apply for Multiselect forms {clinicValues.ane_cardiovascular && <a onClick={(e) => updateClinicValues(e, "ane_cardiovascular", false)}><span className="material-symbols-outlined"> reply </span></a>}</p>
                                {clinicValues.ane_cardiovascular && clinicValues.ane_cardiovascular_list && clinicValues.ane_cardiovascular_list.length > 0 ? <div className="check_list w_white mb-0">

                                    {clinicValues.ane_cardiovascular_list.map((item, i) => (
                                        <div className="check_item active" key={i} onClick={(e) => updateClinicValues(e, "ane_cardiovascular", false)} ><label className="check">{item}</label></div>
                                    ))}

                                </div> :
                                <div className="check_list w_white mt-0 mb-10">
                                    <span className="check_btn2" onClick={(e) => updateClinicValues(e, "ane_cardiovascular", true)}><span className="material-icons"> done </span></span>
                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_cardiovascular_list", "Asystole", "ane_cardiovascular")}><label className="check">Asystole</label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_cardiovascular_list", "Chest compression", "ane_cardiovascular")}><label className="check">Chest compression</label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_cardiovascular_list", "External cardioversion/defib", "ane_cardiovascular")}><label className="check">External cardioversion/defib</label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_cardiovascular_list", "Pacemaker", "ane_cardiovascular")}><label className="check">Pacemaker </label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_cardiovascular_list", "Bradycardia", "ane_cardiovascular")}><label className="check">Bradycardia</label>
                                    </div>


                                </div>}

                                <p className="field_title mt-20">EMERGENCY EVENTS</p>
                                <p className="note2" >Select all that apply for Multiselect forms {clinicValues.ane_emergency_events && <a onClick={(e) => updateClinicValues(e, "ane_emergency_events", false)}><span className="material-symbols-outlined"> reply </span></a>}</p>
                                {clinicValues.ane_emergency_events && clinicValues.ane_emergency_events_list && clinicValues.ane_emergency_events_list.length > 0 ? <div className="check_list w_white mb-0">

                                    {clinicValues.ane_emergency_events_list.map((item, i) => (
                                        <div className="check_item active" key={i} onClick={(e) => updateClinicValues(e, "ane_emergency_events", false)} ><label className="check">{item}</label></div>
                                    ))}

                                </div> :
                                <div className="check_list w_white mt-0 mb-10">
                                    <span className="check_btn2" onClick={(e) => updateClinicValues(e, "ane_emergency_events", true)}><span className="material-icons"> done </span></span>
                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_emergency_events_list", "Sedation emergency", "ane_emergency_events")}><label className="check">Sedation emergency</label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_emergency_events_list", "Severe bleeding", "ane_emergency_events")}><label className="check">Severe bleeding</label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_emergency_events_list", "Allergic reaction", "ane_emergency_events")}><label className="check">Allergic reaction</label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_emergency_events_list", "Intraoperative death", "ane_emergency_events")}><label className="check">Intraoperative death </label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateMultipleValues(e, "ane_emergency_events_list", "Local anaesthetic toxicity", "ane_emergency_events")}><label className="check">Local anaesthetic toxicity</label>
                                    </div>


                                </div>}
                               
                            </div>
                        </div>
                    </div>

                    <div className='popup_footer'>
                        <div className="prescribe_footer">
                            <div className="dsp-flex">
                                <a className="icon_btn" onClick={(e) => { updateClinic(e) }} > <span className="material-icons">save</span> SAVE</a>
                                <button className="border_btn" onClick={(e) => { updateClinic(e), menuClick(e, 'tpatient') }}><span className="material-icons">save_as</span>SAVE & CLOSE
                                </button>
                            </div>

                            <button className="btn dsp_flex" onClick={(e) => menuClick(e, 'tpatient')}><span className="material-icons"><span
                                className="material-icons">close</span></span>DISCARD </button>
                        </div>
                    </div>
                </div>
            </div>


        </React.Fragment>
    )
}

Anesthetic.propTypes = {
    t: PropTypes.any,
    getWards: PropTypes.func,
    getTheatres: PropTypes.func,
    wards: PropTypes.array,
    user: PropTypes.any,
    theatres: PropTypes.array,
    clinic: PropTypes.array,
    patients: PropTypes.array,
    doctors: PropTypes.array,
    profile: PropTypes.object
}

const mapStateToProps = ({ Layout, Login }) => ({
    wards: Layout.wards,
    user: Login.user,
    theatres: Layout.theatres,
    clinic: Layout.clinic,
    patients: Layout.patients,
    doctors: Layout.doctors,
    profile: Layout.profile
})

const mapDispatchToProps = dispatch => ({
    getWards: () => dispatch(getWardsList()),
    getTheatres: () => dispatch(getTheatresList()),
    getClinic: () => dispatch(getClinicList()),
    getPatient: () => dispatch(getPatientList()),
    getUserprofile: (id) => dispatch(getUprofile(id)),
    getDoctor: () => dispatch(getDoctorList()),
    patientupdate: (id, data) => dispatch(updatePatient(id, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Anesthetic)
