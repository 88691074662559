import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import MetaTags from 'react-meta-tags';
import Select from "react-select"
import { connect } from "react-redux"
import { getWardsList, getTheatresList, getClinicList, getPatientList, getUprofile, getDoctorList, updatePatient } from "../../store/actions"

const FrontSheet = props => {

    const [modal_standard, setmodal_standard] = useState(false)
    const [clinicValues, setClinic] = useState({})

    const [selectedGroup, setselectedGroup] = useState(null)
    const [popupname, setpopup_name] = useState({})
    const [anydelay, setDelay] = useState(false)

    const {
        wards, user, theatres, clinic, patients, profile, doctors
    } = props;

    const optionGroup = [
        { label: "Male", value: "Male" },
        { label: "Female", value: "Female" },
        { label: "Other", value: "Other" }
    ]

    const front_by_doc_asList = [
        {
            name: "front_by_doc_as",
            value: "Emergency Admission"
        },
        {
            name: "front_by_doc_as",
            value: "Day case"
        },
        {
            name: "front_by_doc_as",
            value: "Elective Admission"
        }
    ]

    const front_admint_fromList = [
        {
            name: "front_admint_from",
            value: "HOME"
        },
        {
            name: "front_admint_from",
            value: "A&E"
        },
        {
            name: "front_admint_from",
            value: "G.P. Residential home"
        },
        {
            name: "front_admint_from",
            value: "Care home"
        },
        {
            name: "front_admint_from",
            value: "Nursing home"
        }
    ]

    useEffect(() => {
        const { getWards, getTheatres, getClinic, getDoctor, getPatient, getUserprofile } = props
        // getWards()
        // getTheatres()
        // getClinic()
        getPatient()
        getDoctor()
        let userId = localStorage.getItem("authUser")
        getUserprofile(userId)
    }, [])


    useEffect(() => {
        let clinicPatient = JSON.parse(localStorage.getItem("p"))
        if (patients && patients.length > 0 && clinicPatient && clinicPatient._id) {
            const cPatient = patients.filter(
                usr => usr._id === clinicPatient._id
            )
            if (cPatient && cPatient[0]) {
                let cpatientData = cPatient[0]
                delete cpatientData["_id"]
                setClinic(clinicValues => ({
                    ...clinicValues,
                    ...cpatientData
                }));
            }

        }
    }, [patients])

    const updateClinicValues = (e, name, val) => {
        e.preventDefault()
        let updatedValue = {};
        updatedValue = { [name]: val };
        setClinic(clinicValues => ({
            ...clinicValues,
            ...updatedValue
        }));
    }


    const updateInputValue = (e) => {
        let val = e.target.value;
        let name = e.target.name;
        let updatedValue = {};
        updatedValue = { [name]: val };
        setClinic(clinicValues => ({
            ...clinicValues,
            ...updatedValue
        }));
        
    }

    const handleSelectGroup = (selectedGroup, name) => {
        // console.log("selectedGroup ", selectedGroup)
        let updatedValue = { [name]: selectedGroup };
        setClinic(clinicValues => ({
            ...clinicValues,
            ...updatedValue
        }));
    }

    const updateClinic = (e) => {
        const { patientupdate } = props
        let clinicPatient = JSON.parse(localStorage.getItem("p"))
        let id = clinicPatient._id
        patientupdate(id, clinicValues)
        let patient = {
            ...clinicPatient,
            ...clinicValues
        }
        localStorage.setItem("p", JSON.stringify(patient))
        wardBack(3)


    }

    const wardBack = (e) => {
        e.preventDefault()
        props.history.push("/wl")
        document.body.style.overflow = '';
    }

    let clinicPatient = JSON.parse(localStorage.getItem("p"))



    return (
        <React.Fragment>

            <MetaTags>
                <title>Legal Section</title>
            </MetaTags>
            <div className='ward '>
            <div className="popup_wrp">
            <div className="popup_full prescribe">
                <div className="popup_hed">
                    <h2>FRONT SHEET </h2>
                    <div className="dsp-flex"> <a className="popup_close" onClick={(e) => { wardBack(e) }}><span className="material-icons">close</span></a></div>

                </div>
                <div className="popup_content">
                    <div className="popup_scroll w-btns main ">
                        <div className="section ctr mb-0">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form_group">
                                        <label className="label">Admitted on</label>
                                        <input type="text" onChange={(e) => updateInputValue(e)} name="front_admitted" value={clinicValues.front_admitted} className="form-control" placeholder="Enter" />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form_group">
                                        <label className="label">Days since</label>
                                        <input type="text" onChange={(e) => updateInputValue(e)} name="front_dayssince" value={clinicValues.front_dayssince} className="form-control" placeholder="Enter" />
                                    </div>
                                </div>
                            </div>
                            <div className="check_item mt-0 mb-10"><label className="check">Admitted from</label>
                            {clinicValues.front_admint_from ? <div className="select_btns popup_select">
    <div className="select_btn"><label><input type="radio" value={clinicValues.front_admint_from}
                checked /><span>{clinicValues.front_admint_from} </span></label>
    </div> {clinicValues.front_admint_from && <a onClick={(e)=> updateClinicValues(e, "front_admint_from", false)}><span
            className="material-symbols-outlined"> reply
        </span></a>}

</div>
:

<div className="select_btns popup_select mt-0">
    {front_admint_fromList && front_admint_fromList.length > 0 && front_admint_fromList.map((item, i) => (
    <div className="select_btn" key={i}><label><input onClick={(e)=> updateInputValue(e)} name={item.name} type="radio"
            value={item.value} /><span>{item.value} </span></label></div>
    ))}
</div>
}
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form_group">
                                        <label className="label">Transfer from</label>
                                        <input type="text" onChange={(e) => updateInputValue(e)} name="front_tf_from" value={clinicValues.front_tf_from} className="form-control" placeholder="Enter" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form_group">
                                        <label className="label">By Doctor</label>
                                        <input type="text" onChange={(e) => updateInputValue(e)} name="front_by_doc" value={clinicValues.front_by_doc} className="form-control" placeholder="Enter" />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form_group">
                                        <label className="label">Grade</label>
                                        <input type="text" onChange={(e) => updateInputValue(e)} name="front_grade" value={clinicValues.front_grade} className="form-control" placeholder="Enter" />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form_group">
                                        <label className="label">Sleep</label>
                                        <input type="text" onChange={(e) => updateInputValue(e)} name="front_sleep" value={clinicValues.front_sleep} className="form-control" placeholder="Enter" />
                                    </div>
                                </div>
                            </div>
                            <div className="check_item mt-0"><label className="check">As</label>
                            {clinicValues.front_by_doc_as ? <div className="select_btns popup_select">
    <div className="select_btn"><label><input type="radio" value={clinicValues.front_by_doc_as}
                checked /><span>{clinicValues.front_by_doc_as} </span></label>
    </div> {clinicValues.front_by_doc_as && <a onClick={(e)=> updateClinicValues(e, "front_by_doc_as", false)}><span
            className="material-symbols-outlined"> reply
        </span></a>}

</div>
:

<div className="select_btns popup_select mt-0">
    {front_by_doc_asList && front_by_doc_asList.length > 0 && front_by_doc_asList.map((item, i) => (
    <div className="select_btn" key={i}><label><input onClick={(e)=> updateInputValue(e)} name={item.name} type="radio"
            value={item.value} /><span>{item.value} </span></label></div>
    ))}
</div>
}
                            </div>
                            <p className="sub_title mt-20 mb-10">Admitted under </p>
                            <div className="form_group">
                                <label className="label">Consultant</label>
                                <input type="text" onChange={(e) => updateInputValue(e)} name="front_consultant" value={clinicValues.front_consultant} className="form-control" placeholder="Enter" />
                            </div>
                            <p className="sub_title mt-20 mb-10">PATIENT DETAILS</p>
                            <div className="row">
                                <div className="col-md-5">
                                    <div className="form_group">
                                        <label className="label">Forename </label>
                                        <input type="text" onChange={(e) => updateInputValue(e)} name="front_forename" value={clinicValues.front_forename} className="form-control" placeholder="Enter" />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form_group">
                                        <label className="label">Surname </label>
                                        <input type="text" onChange={(e) => updateInputValue(e)} name="front_surname" value={clinicValues.front_surname} className="form-control" placeholder="Enter" />
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="search_dropdown_blk mb-0">
                                        <div className="form_group">
                                            <label className="label">Gender</label>
                                            <div className="floating-label-group mb-0">
                                                <div className="search_dropdown">
                                                    <Select
                                                            onChange={(e) => {
                                                                handleSelectGroup(e, "front_sex")
                                                            }}
                                                            options={optionGroup}
                                                            classNamePrefix="select2-selection"
                                                           // value={{ label: clinicValues.pinfo_team, value: clinicValues.pinfo_team }}
                                                        />
                                                    <select className="form-control">
                                                        <option value="1">Select</option>
                                                        <option value="2">Male</option>
                                                        <option value="3">Female</option>
                                                        <option value="4">Other</option>

                                                    </select>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-md-2">
                                    <div className="form_group">
                                        <label className="label">Age</label>
                                        <input type="text" onChange={(e) => updateInputValue(e)} name="front_age" value={clinicValues.front_age} className="form-control" placeholder="Enter" />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form_group">
                                        <label className="label">DOB </label>
                                        <input type="text" onChange={(e) => updateInputValue(e)} name="front_dob" value={clinicValues.front_dob} className="form-control" placeholder="Enter" />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form_group">
                                        <label className="label">NHS Number </label>
                                        <input type="text" onChange={(e) => updateInputValue(e)} name="front_nhsnumber" value={clinicValues.front_nhsnumber} className="form-control" placeholder="Enter" />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form_group">
                                        <label className="label">Hospital Number</label>
                                        <input type="text" onChange={(e) => updateInputValue(e)} name="front_hospitalnumber" value={clinicValues.front_hospitalnumber} className="form-control" placeholder="Enter" />
                                    </div>
                                </div>
                            </div>
                            <div className="form_group">
                                <label className="label">Likes to be called </label>
                                <input type="text" onChange={(e) => updateInputValue(e)} name="front_likes" value={clinicValues.front_likes} className="form-control" placeholder="Enter" />
                            </div>
                            <p className="sub_title mt-20 mb-10">CONTACT </p>
                            <div className="row">

                                <div className="col-md-4">
                                    <div className="form_group">
                                        <label className="label">Mobile </label>
                                        <input type="text" onChange={(e) => updateInputValue(e)} name="front_mobile" value={clinicValues.front_mobile} className="form-control" placeholder="Enter" />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form_group">
                                        <label className="label">Home Phone </label>
                                        <input type="text" onChange={(e) => updateInputValue(e)} name="front_home_phone" value={clinicValues.front_home_phone} className="form-control" placeholder="Enter" />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form_group">
                                        <label className="label">Work Phone </label>
                                        <input type="text" onChange={(e) => updateInputValue(e)} name="front_work_phone" value={clinicValues.front_work_phone} className="form-control" placeholder="Enter" />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form_group">
                                        <label className="label">Address </label>
                                        <input type="text" onChange={(e) => updateInputValue(e)} name="front_address" value={clinicValues.front_address} className="form-control" placeholder="Enter" />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form_group">
                                        <label className="label">Post code </label>
                                        <input type="text" onChange={(e) => updateInputValue(e)} name="front_postcode" value={clinicValues.front_postcode} className="form-control" placeholder="Enter" />
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <div className="form_group">
                                        <label className="label">Email address</label>
                                        <input type="text" onChange={(e) => updateInputValue(e)} name="front_email" value={clinicValues.front_email} className="form-control" placeholder="Enter" />
                                    </div>
                                </div>
                            </div>
                            <p className="sub_title mt-20 mb-10">GP </p>
                            <div className="row">

                                <div className="col-md-6">
                                    <div className="form_group">
                                        <label className="label">Usual GP’s name </label>
                                        <input type="text" onChange={(e) => updateInputValue(e)} name="front_usual_gp_name" value={clinicValues.front_usual_gp_name} className="form-control" placeholder="Enter" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form_group">
                                        <label className="label">Phone No. </label>
                                        <input type="text" onChange={(e) => updateInputValue(e)} name="front_usual_phone" value={clinicValues.front_usual_phone} className="form-control" placeholder="Enter" />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form_group">
                                        <label className="label">Address </label>
                                        <input type="text" onChange={(e) => updateInputValue(e)} name="front_usual_address" value={clinicValues.front_usual_address} className="form-control" placeholder="Enter" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form_group">
                                        <label className="label">Next of Kin </label>
                                        <input type="text" onChange={(e) => updateInputValue(e)} name="front_next_of_kin" value={clinicValues.front_next_of_kin} className="form-control" placeholder="Enter" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form_group">
                                        <label className="label">None listed </label>
                                        <input type="text" onChange={(e) => updateInputValue(e)} name="front_none_listed" value={clinicValues.front_none_listed} className="form-control" placeholder="Enter" />
                                    </div>
                                </div>

                            </div>
                            <p className="sub_title mt-20 mb-10">PERSONS TO CONTACT FOR ROUTINE COMMUNICATIONS: </p>
                            <div className="row">

                                <div className="col-md-6">
                                    <div className="form_group">
                                        <label className="label">Name </label>
                                        <input type="text" onChange={(e) => updateInputValue(e)} name="front_person_name" value={clinicValues.front_person_name} className="form-control" placeholder="Enter" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form_group">
                                        <label className="label">Relationship </label>
                                        <input type="text" onChange={(e) => updateInputValue(e)} name="front_person_relation" value={clinicValues.front_person_relation} className="form-control" placeholder="Enter" />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form_group">
                                        <label className="label">Mobile </label>
                                        <input type="text" onChange={(e) => updateInputValue(e)} name="front_person_mobile" value={clinicValues.front_person_mobile} className="form-control" placeholder="Enter" />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form_group">
                                        <label className="label">Home Ph </label>
                                        <input type="text" onChange={(e) => updateInputValue(e)} name="front_person_homeph" value={clinicValues.front_person_homeph} className="form-control" placeholder="Enter" />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form_group">
                                        <label className="label">Work Ph </label>
                                        <input type="text" onChange={(e) => updateInputValue(e)} name="front_person_workph" value={clinicValues.front_person_workph}  className="form-control" placeholder="Enter" />
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <div className="form_group">
                                        <label className="label">Street address (if different to above) </label>
                                        <input type="text" onChange={(e) => updateInputValue(e)} name="front_person_streetaddress" value={clinicValues.front_person_streetaddress} className="form-control" placeholder="Enter" />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form_group">
                                        <label className="label">Post code </label>
                                        <input type="text" onChange={(e) => updateInputValue(e)} name="front_person_postcode" value={clinicValues.front_person_postcode} className="form-control" placeholder="Enter" />
                                    </div>
                                </div>
                            </div>
                            <p className="sub_title mt-20 mb-10">PERSONS TO CONTACT IN AN EMERGENCY: </p>
                            <div className="row">

                                <div className="col-md-7">
                                    <div className="form_group">
                                        <label className="label">Name of other Emergency contact</label>
                                        <input type="text" onChange={(e) => updateInputValue(e)} name="front_emergency_name" value={clinicValues.front_emergency_name} className="form-control" placeholder="Enter" />
                                    </div>
                                </div>
                                <div className="col-md-5">
                                    <div className="form_group">
                                        <label className="label">Contact Phone No.(s) </label>
                                        <input type="text" onChange={(e) => updateInputValue(e)} name="front_emergency_contact" value={clinicValues.front_emergency_contact} className="form-control" placeholder="Enter" />
                                    </div>
                                </div>
                          
                             
                            </div>
                            <div className="yn_switch mb-10  mt-10">
                                <div className="label-container">
                                    <p>Relative informed of admission? </p>
                                </div>
                                <label for="front_relative_informed" className="switch-container">
                                    <input type="checkbox" id="front_relative_informed" onChange={(e) => updateInputValue(e)} name="front_relative_informed" checked={clinicValues.pinfo_discharge_today} className="switch_check" />
                                    <div className="switch-bg"></div>
                                    <div className="round-box"></div>
                                    <div className="switch-left">
                                        <span>YES</span>
                                    </div>
                                    <div className="switch-right">
                                        <span>NO</span>
                                    </div>
                                </label>
                            </div>
                            <div className="row">

                                <div className="col-md-7">
                                    <div className="form_group">
                                        <label className="label">Daughter, power of Attorney for Care and Finance </label>
                                        <input type="text" onChange={(e) => updateInputValue(e)} name="front_emergency_attorney" value={clinicValues.front_emergency_attorney} className="form-control" placeholder="Enter" />
                                    </div>
                                </div>
                                <div className="col-md-5">
                                    <div className="form_group">
                                        <label className="label">Tel</label>
                                        <input type="text" onChange={(e) => updateInputValue(e)} name="front_emergency_attorney_tel" value={clinicValues.front_emergency_attorney_tel} className="form-control" placeholder="Enter" />
                                    </div>
                                </div>
                                <div className="col-md-7">
                                    <div className="form_group">
                                        <label className="label">Spouse, power of Attorney for Finance</label>
                                        <input type="text" onChange={(e) => updateInputValue(e)} name="front_emergency_spouse" value={clinicValues.front_emergency_spouse} className="form-control" placeholder="Enter" />
                                    </div>
                                </div>
                                <div className="col-md-5">
                                    <div className="form_group">
                                        <label className="label">Tel</label>
                                        <input type="text" onChange={(e) => updateInputValue(e)} name="front_emergency_spouse_tel" value={clinicValues.front_emergency_spouse_tel} className="form-control" placeholder="Enter" />
                                    </div>
                                </div>
                                <div className="col-md-7">
                                    <div className="form_group">
                                        <label className="label">Doctor</label>
                                        <input type="text" onChange={(e) => updateInputValue(e)} name="front_emergency_doc" value={clinicValues.front_emergency_doc} className="form-control" placeholder="Enter" />
                                    </div>
                                </div>
                                <div className="col-md-5">
                                    <div className="form_group">
                                        <label className="label">Tel</label>
                                        <input type="text" onChange={(e) => updateInputValue(e)} name="front_emergency_doc_tel" value={clinicValues.front_emergency_doc_tel} className="form-control" placeholder="Enter" />
                                    </div>
                                </div>
                            </div>
                            <p className="sub_title mt-20 mb-10">Care Home details </p>
                            <div className="row">

                                <div className="col-md-7">
                                    <div className="form_group">
                                        <label className="label">Name </label>
                                        <input type="text" onChange={(e) => updateInputValue(e)} name="front_care_name" value={clinicValues.front_care_name} className="form-control" placeholder="Enter" />
                                    </div>
                                </div>
                                <div className="col-md-5">
                                    <div className="form_group">
                                        <label className="label">Tel </label>
                                        <input type="text" onChange={(e) => updateInputValue(e)} name="front_care_tel" value={clinicValues.front_care_tel} className="form-control" placeholder="Enter" />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form_group">
                                        <label className="label">Address </label>
                                        <input type="text" onChange={(e) => updateInputValue(e)} name="front_care_address" value={clinicValues.front_care_address} className="form-control" placeholder="Enter" />
                                    </div>
                                </div>
                             
                           
                            </div>
                        </div>

                    </div>
            
                </div>
                <div className="popup_footer">
                        <div className="prescribe_footer">
                            <div className="dsp-flex">
                                <a className="icon_btn" onClick={(e) => { updateClinic(e) }} > <span className="material-icons">save</span> SAVE</a>
                                <button className="border_btn" onClick={(e) => { wardBack(e), updateClinic(e) }}><span className="material-icons">save_as</span>SAVE &amp;
                                    CLOSE
                                </button>
                            </div>

                            <button className="btn dsp_flex" onClick={(e) => { wardBack(e) }}><span className="material-icons"><span
                                        className="material-icons">close</span></span>DISCARD </button>
                        </div>
                    </div>
            </div>

        </div>
            </div>


        </React.Fragment>
    )
}

FrontSheet.propTypes = {
    t: PropTypes.any,
    getWards: PropTypes.func,
    getTheatres: PropTypes.func,
    wards: PropTypes.array,
    user: PropTypes.any,
    theatres: PropTypes.array,
    clinic: PropTypes.array,
    patients: PropTypes.array,
    doctors: PropTypes.array,
    profile: PropTypes.object
}

const mapStateToProps = ({ Layout, Login }) => ({
    wards: Layout.wards,
    user: Login.user,
    theatres: Layout.theatres,
    clinic: Layout.clinic,
    patients: Layout.patients,
    doctors: Layout.doctors,
    profile: Layout.profile
})

const mapDispatchToProps = dispatch => ({
    getWards: () => dispatch(getWardsList()),
    getTheatres: () => dispatch(getTheatresList()),
    getClinic: () => dispatch(getClinicList()),
    getPatient: () => dispatch(getPatientList()),
    getUserprofile: (id) => dispatch(getUprofile(id)),
    getDoctor: () => dispatch(getDoctorList()),
    patientupdate: (id, data) => dispatch(updatePatient(id, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(FrontSheet)
