import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes"
import { apiError, loginSuccess} from "./actions"

import {
  postJwtLogin
} from "../../../helpers/fakebackend_helper"


function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(postJwtLogin, user)
    if(response == "false" || response == false){
      yield put(apiError("Invalid Login"))
    }else {
      localStorage.setItem("authUser", response._id)
      yield put(loginSuccess(response))
      history.push("/dashboard")
    }
   
  } catch (error) {
    yield put(apiError(error))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    // localStorage.removeItem("authUser")
    localStorage.clear()
    history.push("/login")
  } catch (error) {
    yield put(apiError(error))
  }
}


function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
