import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import React, { useRef, useState, useEffect } from "react"
// Redux
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"
import Select from "react-select"

// actions
import { loginUser, apiError, teamListUpdate, gettableList } from "../../store/actions"

// import images
import logoDark from '../../assets/images/mainLogo.svg';
import logoLight from '../../assets/images/logo-light.svg';
import dummy from '../../assets/images/dummy.png';

const Login = props => {

  const [signupVisible, setsignup] = useState("personal")
  const [visibleStatus, setVisible] = useState("login")
  const [mpin, setInput] = useState({})
  const [passcode, setPasscode] = useState(false)
  const [userValues, setUserValue] = useState({})
  const [passMatch, setpassMatch] = useState(false)
  const [mpinselect, setmpinMatch] = useState(false)
  const [setSuccess, setSuccessMsg] = useState(false)
  const [roles, setRoles] = useState([])
  const [locations, setLocations] = useState([])
  const [specialities, setSpeciality] = useState([])
  const [wards, setWards] = useState([])
  const [clinics, setClinic] = useState([])
  const [theatres, setTheatre] = useState([])
  const [nursing, setNursing] = useState([])
  const [oncallList, setoncallList] = useState([])
  const [flows, setFlowManagement] = useState([])
  const [consultants, setConsultants] = useState([])
  const [hospitals, setHospital] = useState([])
  
  const [selectedMulti, setselectedMulti] = useState(null)

  const optionGroup = [
    {
      label: "Picnic",
      options: [
        { label: "Mustard", value: "Mustard" },
        { label: "Ketchup", value: "Ketchup" },
        { label: "Relish", value: "Relish" }
      ]
    },
    {
      label: "Camping",
      options: [
        { label: "Tent", value: "Tent" },
        { label: "Flashlight", value: "Flashlight" },
        { label: "Toilet Paper", value: "Toilet Paper" }
      ]
    }
  ]

  const one = useRef();
  const two = useRef();
  const three = useRef();
  const four = useRef();
  const five = useRef();
  const six = useRef();

  const oneSelect = useRef();
  const twoSelect = useRef();
  const threeSelect = useRef();
  const fourSelect = useRef();
  const fiveSelect = useRef();
  const sixSelect = useRef();

  const oneSelect1 = useRef();
  const twoSelect1 = useRef();
  const threeSelect1 = useRef();
  const fourSelect1 = useRef();
  const fiveSelect1 = useRef();
  const sixSelect1 = useRef();


  const {
    tablesData
  } = props;


  useEffect(() => {
    const { gettableList } = props

    gettableList({
      method: "Get"
    }, "lookups")

  }, [])

  useEffect(() => {

    if (tablesData && tablesData.length > 0) {
      const specialityGroup = typeGrouping(tablesData, 'type', 'speciality');
      setSpeciality(specialityGroup)
      const locationGroup = typeGrouping(tablesData, 'type', 'location');
      setLocations(locationGroup)
      const roleGroup = typeGrouping(tablesData, 'type', 'role');
      setRoles(roleGroup)
      const hospitalGroup = typeGrouping(tablesData, 'type', 'hospital');
      setHospital(hospitalGroup)

      const wardGroup = typeGrouping(tablesData, 'type', 'ward');
      setWards(wardGroup)
      const clinicGroup = typeGrouping(tablesData, 'type', 'clinic');
      setClinic(clinicGroup)
      const theatreGroup = typeGrouping(tablesData, 'type', 'theatre');
      setTheatre(theatreGroup)
      const oncallGroup = typeGrouping(tablesData, 'type', 'oncall');
      setoncallList(oncallGroup)
      const nursingGroup = typeGrouping(tablesData, 'type', 'nursing_office');
      setNursing(nursingGroup)
      const flowGroup = typeGrouping(tablesData, 'type', 'flow_management');
      setFlowManagement(flowGroup)
      const consultantGroup = typeGrouping(tablesData, 'type', 'consultant');
      setConsultants(consultantGroup)
    }


  }, [tablesData])

  const typeGrouping = (assets, field, value) => {
    let filterArray = []
    assets.filter((item, i) => {
      if (item[field] == value) {

        filterArray.push({ "label": item.value, "value": item.name })
      }
    }, []);
    return filterArray
  }

  const handleSelectGroup = (selectedGroup, name) => {
    // console.log("selectedGroup ", selectedGroup)
    let updatedValue = { [name]: selectedGroup };
    setUserValue(userValues => ({
        ...userValues,
        ...updatedValue
    }));
}

  // handleValidSubmit
  const handleValidSubmit = (e) => {
    let user = {
      email: mpin.email,
      password: mpin.password
    }
    props.loginUser(user, props.history)
  }

  const handleMpinSubmit = (mpin) => {
    props.loginUser(mpin, props.history)
  }

  const onChangeValue = (e) => {
    let val = e.target.value;
    let name = e.target.name;
    let updatedValue = {};
    updatedValue = { [name]: val };
    setUserValue(userValues => ({
      ...userValues,
      ...updatedValue
    }));

    if (e.target.name == "oneSelect") {
      twoSelect.current.focus();
    } else if (e.target.name == "twoSelect") {
      threeSelect.current.focus();
    } else if (e.target.name == "threeSelect") {
      fourSelect.current.focus();
    } else if (e.target.name == "fourSelect") {
      fiveSelect.current.focus();
    } else if (e.target.name == "fiveSelect") {
      sixSelect.current.focus();
    } else if (e.target.name == "sixSelect") {
      oneSelect1.current.focus();
    } else if (e.target.name == "oneSelect1") {
      twoSelect1.current.focus();
    } else if (e.target.name == "twoSelect1") {
      threeSelect1.current.focus();
    } else if (e.target.name == "threeSelect1") {
      fourSelect1.current.focus();
    } else if (e.target.name == "fourSelect1") {
      fiveSelect1.current.focus();
    } else if (e.target.name == "fiveSelect1") {
      sixSelect1.current.focus();
    }

  }

  const passwordMatch = (e) => {
    if (userValues && userValues.password && userValues.passwordConfirm) {
      if (userValues.password == userValues.passwordConfirm) {
        setsignup("setpin")
      } else {
        setpassMatch(true)
      }
    }
  }


  const mpinMatch = (e) => {
    if (userValues && userValues.oneSelect && userValues.oneSelect1
      && userValues.twoSelect && userValues.twoSelect1
      && userValues.threeSelect && userValues.threeSelect1
      && userValues.fourSelect && userValues.fourSelect1
      && userValues.fiveSelect && userValues.fiveSelect1
      && userValues.sixSelect && userValues.sixSelect1
    ) {
      let mpinOneValue = userValues.oneSelect + userValues.twoSelect + userValues.threeSelect + userValues.fourSelect + userValues.fiveSelect + userValues.sixSelect
      let mpinConfirmValue = userValues.oneSelect1 + userValues.twoSelect1 + userValues.threeSelect1 + userValues.fourSelect1 + userValues.fiveSelect1 + userValues.sixSelect1

      if (mpinOneValue == mpinConfirmValue) {
        // setPasscode(false)
        setsignup("preferences")
        let updatedValue = { "mpin": mpinOneValue };
        setUserValue(userValues => ({
          ...userValues,
          ...updatedValue
        }));

      } else {
        setmpinMatch(true)
      }
    }
  }


  const updateInputValue = (e) => {
    const val = e.target.value;

    let updatedValue = {};
    updatedValue = { [e.target.name]: val };
    setInput(mpin => ({
      ...mpin,
      ...updatedValue
    }));

    if (e.target.name == "one") {
      two.current.focus();
    } else if (e.target.name == "two") {
      three.current.focus();
    } else if (e.target.name == "three") {
      four.current.focus();
    } else if (e.target.name == "four") {
      five.current.focus();
    } else if (e.target.name == "five") {
      six.current.focus();
    } else if (e.target.name == "six") {
      if (mpin.one && mpin.two && mpin.three && mpin.four && mpin.five && val) {
        let mpinValue = mpin.one + mpin.two + mpin.three + mpin.four + mpin.five + val
        handleMpinSubmit(mpinValue);
      }
    }

  }

  const handleKeypress = e => {
    //it triggers by pressing the enter key
    let mpinValue = mpin.one + mpin.two + mpin.three + mpin.four + mpin.five + mpin.six
    if (e.key === 'Enter') {
      handleMpinSubmit(mpinValue);
    } else if (e.keyCode === 13) {
      handleMpinSubmit(mpinValue);
    }
  };

  const submitUpdate = (e) => {
    const { teamListUpdate } = props
    let userObj = {
      firstname: userValues.firstname,
      lastname: userValues.lastname,
      mobile: userValues.mobile,
      mpin: userValues.mpin,
      password: userValues.password,
      speciality: userValues.speciality,
      role: userValues.role,
      ward: userValues.ward,
      clinic: userValues.clinic,
      theatre: userValues.theatre,
      default_hospital: userValues.default_hospital,
      oncall: userValues.oncall,
      consultant: userValues.consultant,
      nursing: userValues.nursing,
      flowmanagement: userValues.flowmanagement,
      email: userValues.email,

    }
    console.log("userObj ", userObj, userValues)
    let bodyObj = {
      obj: userObj,
      method: "Insert"
    }
    teamListUpdate(bodyObj, "users")
    setSuccessMsg(true)

  }

  console.log("userValues ", userValues)
  let currentYear = new Date().getFullYear()

  return (
    <React.Fragment>
      <MetaTags>
        <title>Login</title>
      </MetaTags>

      {visibleStatus == "pin" &&
        <div className="landing_page">
          <div className="login_wrp">
            <div className='login_block'>
              <div className="landing_logo">
                <img src={logoDark} />
              </div>

              {/* {props && props.error && <h2 className="error text-center p-5">{props.error}</h2>} */}
              <div className="landing_signup">
                <p className="or"><span>OR</span></p>
                <div className="signup_wrp">
                  <a onClick={(e) => setVisible("login")} className="btn-primary">Login</a>
                  <a onClick={(e) => setVisible("register")} className="btn-secondary">Register</a>
                  <p className="copy_right">All Rights reserved. IPR {currentYear}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      }

      {visibleStatus == "login" &&
        <div className="landing_page">
          <div className="login_wrp login_form">
            <div className='login_block'>
              <div className="landing_logo">
                <img src={logoDark} />
              </div>
              <div className="signup_form">

                <div className="form-group">
                  <label>User Name</label>
                  <input type="text" id="username" name="email" onChange={(e) => updateInputValue(e)} className="form-control" autoComplete="off" placeholder='Email' autoFocus />
                </div>
                <div className="form-group">
                  <label>Password</label>
                  <input type="password" id="password" name="password" onChange={(e) => updateInputValue(e)} className="form-control" placeholder='Password' autoComplete="off" />
                </div>
                <div className='dsp_flex'>
                  <label className="checkbox">Keep me signed in
                    <input type="checkbox" />
                    <span className="checkmark"></span>
                  </label>
                  <p className=' mb-0 text-right'>  <a href="#" className="link">Forgot Password?</a></p>

                </div>
                <div className='dsp_flex mt-30'>
                  <button className="btn-primary loginbtn" onClick={(e) => handleValidSubmit(true)} type="button"> LOGIN </button>
                  <a onClick={(e) => setVisible("register")} className="link">Register</a>

                </div>
                <div className='option' data-bs-toggle="offcanvas" data-bs-target="#offcanvasBottom" aria-controls="offcanvasBottom">
                <span className="material-symbols-outlined icon"> vpn_key </span>
                <span className='btn_text'>PASSCODE</span>
              </div>


                {/* <div className=" switch_block">
                  <label className="switch">
                    <input type="checkbox" />
                    <span className="slider round"></span>
                  </label>
                  Keep me logged in
                </div> */}


              </div>

            </div>

          </div>
          <div className="login_footer">
            {/* <div className='login_options'>
              <div className='option' data-bs-toggle="offcanvas" data-bs-target="#offcanvasBottom" aria-controls="offcanvasBottom">
                <span className="material-symbols-outlined icon"> vpn_key </span>
                <span className='btn_text'>PASSCODE</span>
              </div>
              <div className='option'>
                <span className="material-symbols-outlined icon"> fingerprint </span>
                <span className='btn_text'>FINGER PRINT</span>
              </div>
              <div className='option'>
                <span className="material-symbols-outlined icon"> ar_on_you </span>
                <span className='btn_text'>FACE ID</span>
              </div>
            </div> */}



            <div className="offcanvas offcanvas-bottom passcode" tabIndex="-1" id="offcanvasBottom" aria-labelledby="offcanvasBottomLabel">
              <div className="offcanvas-header">
                <h5 >Enter Passcode</h5>
                <span className="material-symbols-outlined close_btn" data-bs-dismiss="offcanvas" aria-label="Close"> close </span>
              </div>
              <div className="offcanvas-body small">
                <div className="login_pin">

                  <div className="pin_block">
                    <input type="text" name="one" ref={one} autoComplete="off" onChange={(e) => updateInputValue(e)} className="form-control" maxLength="1" autoFocus />
                    <input type="text" name="two" ref={two} autoComplete="off" onChange={(e) => updateInputValue(e)} className="form-control" maxLength="1" />
                    <input type="text" name="three" ref={three} autoComplete="off" onChange={(e) => updateInputValue(e)} className="form-control" maxLength="1" />
                    <input type="text" name="four" ref={four} autoComplete="off" onChange={(e) => updateInputValue(e)} className="form-control" maxLength="1" />
                    <input type="text" name="five" ref={five} autoComplete="off" onChange={(e) => updateInputValue(e)} className="form-control" maxLength="1" />
                    <input type="text" name="six" ref={six} autoComplete="off" onChange={(e) => updateInputValue(e)} onKeyPress={handleKeypress} className="form-control" maxLength="1" />
                  </div>
                  <button className="btn-primary loginbtn" type="button"> Enter </button>
                  <a href="#" className="forgotpin">Change Passcode?</a>
                </div>
              </div>
            </div>
            <div className="copy_right">
              <div className='links'>
                <a href='#' className='link'>Dr.Thrinath Kumar Kakarlapudi</a>
                <a href='#' className='link'>Satya Prasad Narem</a>
                <a href='#' className='link'>Harikumar Nollu</a>
                <a href='#' className='link'>Prithviraj Chintalapti</a>
              </div>
              <p> All Rights reserved. IPR {currentYear}</p>
            </div>
          </div>
        </div>
      }

      {visibleStatus == "register" &&
        <div className="signup">
          <div className="signup_hed">
            <div className='hed_top'>
              <img src={logoLight} className='signup_logo' />
              <h4 className='su_title'>Register</h4>
              <a href="#" onClick={(e) => { setVisible("login"); setsignup("personal"); }} className="close_btn">Close<span className="material-icons icon">close</span></a>
            </div>
            <ul className="signup_nav">
              <li className={signupVisible == "personal" ? "current" :"completed"} onClick={(e) => setsignup("personal")}> <span className="checkbox"> <span className="material-icons">done</span></span> User Details</li>
              <li className={signupVisible == "setpin" ? "current" :"completed"} onClick={(e) => setsignup("setpin")}> <span className="checkbox"> <span className="material-icons">done</span></span>Set PIN</li>
              <li className={signupVisible == "preferences" ? "current" :"completed"} onClick={(e) => setsignup("preferences")}> <span className="checkbox"> <span className="material-icons">done</span></span>Preferences</li>
            </ul>
            {/* <ul className="signup_nav">
              <li className={signupVisible == "personal" ? "active current" : null} onClick={(e) => setsignup("personal")}> <span className="checkbox">{signupVisible == "personal" && <span className="material-icons">done</span>}</span> User Details</li>
              <li className={signupVisible == "setpin" ? "active current" : null} onClick={(e) => setsignup("setpin")}> <span className="checkbox">{signupVisible == "setpin" && <span className="material-icons">done</span>}</span>Set PIN</li>
              <li className={signupVisible == "preferences" ? "active current" : null} onClick={(e) => setsignup("preferences")}> <span className="checkbox">{signupVisible == "preferences" && <span className="material-icons">done</span>}</span>Preferences</li>
            </ul> */}
          </div>
          {/* <div className='form_title step1'>
            <h4>USEr DETAILS</h4>
          </div> */}
          <div className='form_title'>
            <a href='#' className='prv '> <span className="material-icons icon">chevron_left </span> Prev </a>

            <h4>USEr DETAILS</h4>
            <a onClick={(e) => { setVisible("login"); setsignup("personal"); }} className='skip'>Skip, I�ll do it later</a>
          </div>
          <div className="signup_form">
            <div className="signup_wrp">

              {signupVisible == "personal" &&
                <div id="personal">
                  <h4 className='form_subtitle'>My DETAILS</h4>
                  <p className='form_subtag'>Enter your details to get started</p>
                  <div className="form-group">
                    <label>First Name</label>
                    <input type="text" id="firstname" name="firstname" onChange={(e) => onChangeValue(e)} className="form-control" placeholder='Firstname' autoComplete="off" autoFocus required />
                  </div>
                  <div className="form-group">
                    <label>Last Name</label>
                    <input type="text" id="lastname" name="lastname" onChange={(e) => onChangeValue(e)} className="form-control" placeholder='Lastname' autoComplete="off" autoFocus required />
                  </div>
                  <div className="form-group">
                    <label>E Mail</label>
                    <input type="text" id="emailid" name="email" onChange={(e) => onChangeValue(e)} className="form-control" placeholder='Email ID' autoComplete="off" autoFocus required />
                  </div>
                  <div className="form-group">
                    <label>Contact Number</label>
                    <input type="text" id="mobile" name="mobile" onChange={(e) => onChangeValue(e)} className="form-control" placeholder='Enter Phone Number' autoComplete="off" autoFocus required />
                  </div>
                  {/* <h4 className='form_subtitle mb-10 mt-30'>Profile Pic</h4>
                  <div className="form_group2">
                    <input type="file" className="custom-file-input" />
                  </div> */}
                  <h4 className='form_subtitle'>Set Password</h4>
                  <div className="form-group">
                    <label>Password</label>
                    <input type="password" id="password" name="password" onChange={(e) => onChangeValue(e)} className="form-control" placeholder='**************' autoComplete="off" autoFocus required />
                  </div>
                  <div className="form-group">
                    <label>Confirm Password</label>
                    <input type="password" id="passwordConfirm" name="passwordConfirm" onChange={(e) => onChangeValue(e)} className="form-control" placeholder='**************' autoComplete="off" autoFocus required />
                  </div>


                  {passMatch && <div className="alert alert-danger" role="alert">
                    Password Mismatch
                  </div>}
                  <button className="btn-primary mt-30" onClick={(e) => { passwordMatch(e) }} type="button"> SAVE & CONTINUE </button>

                </div>
              }

              {signupVisible == "setpin" &&
                <div id="setpin">
                  {/* {!passcode &&
                    <div className='sem'>
                      <h4 className='form_subtitle'>SELECT MODE</h4>
                      <p className='form_subtag'>Set a quick way to login</p>
                      <button type='button' className='sem_btn' onClick={(e) => setPasscode(true)}  >
                        <span className="material-symbols-outlined icon"> vpn_key </span>
                        SET PASSCODE
                        {userValues.mpin && <span className="material-symbols-outlined check"> done </span>}
                      </button>
                      <button type='button' className='sem_btn'>
                        <span className="material-symbols-outlined icon"> fingerprint </span>
                        SET FINGER PRINT
                      </button>
                      <button type='button' className='sem_btn'>
                        <span className="material-symbols-outlined icon"> ar_on_you </span>
                        SET FACE ID
                      </button>

                      {userValues.mpin && <button className="btn-primary loginbtn" onClick={(e) => setsignup("preferences")} type="button"> Continue </button>}

                    </div>} */}
                  {/* <div className='sem'>
                    <h4 className='form_subtitle'>SELECT MODE</h4>
                    <p className='form_subtag'>Set a strong password to protect your account </p>
                    <button type='button' className='sem_btn'>
                      <span className="material-symbols-outlined icon"> vpn_key </span>
                      SET PASSCODE
                      <span className="material-symbols-outlined check"> done </span>
                    </button>
                    <button type='button' className='sem_btn'>
                      <span className="material-symbols-outlined icon"> fingerprint </span>
                      SET FINGER PRINT
                    </button>
                    <button type='button' className='sem_btn'>
                      <span className="material-symbols-outlined icon"> ar_on_you </span>
                      SET FACE ID
                    </button>
                    <button className="btn-primary loginbtn" type="button"> Continue </button>
                  </div> */}
                 
                    <div className="login_pin">
                      <label>Set Code</label>
                      <div className="pin_block">
                        <input type="text" name="oneSelect" ref={oneSelect} onChange={(e) => onChangeValue(e)} autoComplete="off" className="form-control" maxLength="1" />
                        <input type="text" name="twoSelect" ref={twoSelect} onChange={(e) => onChangeValue(e)} autoComplete="off" className="form-control" maxLength="1" />
                        <input type="text" name="threeSelect" ref={threeSelect} onChange={(e) => onChangeValue(e)} autoComplete="off" className="form-control" maxLength="1" />
                        <input type="text" name="fourSelect" ref={fourSelect} onChange={(e) => onChangeValue(e)} autoComplete="off" className="form-control" maxLength="1" />
                        <input type="text" name="fiveSelect" ref={fiveSelect} onChange={(e) => onChangeValue(e)} autoComplete="off" className="form-control" maxLength="1" />
                        <input type="text" name="sixSelect" ref={sixSelect} onChange={(e) => onChangeValue(e)} autoComplete="off" className="form-control" maxLength="1" />
                      </div>

                    </div>
                    <div className="login_pin">
                      <label>Set Code</label>
                      <div className="pin_block">
                        <input type="text" name="oneSelect1" ref={oneSelect1} onChange={(e) => onChangeValue(e)} autoComplete="off" className="form-control" maxLength="1" />
                        <input type="text" name="twoSelect1" ref={twoSelect1} onChange={(e) => onChangeValue(e)} autoComplete="off" className="form-control" maxLength="1" />
                        <input type="text" name="threeSelect1" ref={threeSelect1} onChange={(e) => onChangeValue(e)} autoComplete="off" className="form-control" maxLength="1" />
                        <input type="text" name="fourSelect1" ref={fourSelect1} onChange={(e) => onChangeValue(e)} autoComplete="off" className="form-control" maxLength="1" />
                        <input type="text" name="fiveSelect1" ref={fiveSelect1} onChange={(e) => onChangeValue(e)} autoComplete="off" className="form-control" maxLength="1" />
                        <input type="text" name="sixSelect1" ref={sixSelect1} onChange={(e) => onChangeValue(e)} autoComplete="off" className="form-control" maxLength="1" />
                      </div>

                      {mpinselect && <div className="alert alert-danger" role="alert">
                        MPIN Mismatch
                      </div>}

                      <button className="btn-primary loginbtn" onClick={(e) => { mpinMatch(e) }} type="button"> Set </button>

                    </div>
                  {/* <h3>Set PIN</h3>
                  <div className="form_group switch_block">
                    <div className="floating-label-group">
                      <input type="text" id="username" className="form-control" autoComplete="off" autoFocus required />
                      <label className="floating-label">Enter PIN</label>
                    </div>
                    <div className="floating-label-group">
                      <input type="text" id="username" className="form-control" autoComplete="off" autoFocus required />
                      <label className="floating-label">Confirm PIN</label>
                    </div>
                  </div>
                  <button className="btn-primary" type="button"> SAVE & CONTINUE <span className="material-icons">arrow_forward_ios</span></button> */}
                </div>
              }

              {signupVisible == "preferences" &&
                <div id="preferences">
                  <div className='pref_block'>
                  <h4 className='form_subtitle'>Workflow PREFERENCES</h4>
                  <p className='form_subtag'>Set a quick way to login</p>
                    {/* <h3>Set preferences</h3> */}
              
                    <div className="form-group">
                    <label>My Speciality</label>
                    <Select
                      isMulti={false}
                      onChange={(e) => {
                          handleSelectGroup(e, "speciality")
                      }}
                      options={specialities}
                      classNamePrefix="select2-selection"
                    />
                    

                  </div>
                  <div className="form-group mb-0">
                    <label>User Role</label>
                    <Select
                      isMulti={false}
                      onChange={(e) => {
                        handleSelectGroup(e, "role")
                    }}
                      options={roles}
                      classNamePrefix="select2-selection"
                    />
                   

                  </div>
                  </div>
                  <div className='pref_block'>
                  <h4 className='form_subtitle'>Preferred Site</h4>
                  <div className="form-group mb-0">
                    <label>Select Site</label>
                    <Select
                      isMulti={false}
                      onChange={(e) => {
                        handleSelectGroup(e, "default_hospital")
                    }}
                      options={hospitals}
                      classNamePrefix="select2-selection"
                    />
                  </div>

                
                  </div>
                  <div className='pref_block'>
                  <h4 className='form_subtitle mb-10'>Preferred LOCATION</h4>
                  <div className="form-group">
                    <label>WARD</label>
                    <Select
                      isMulti={false}
                      onChange={(e) => {
                        handleSelectGroup(e, "ward")
                    }}
                      options={wards}
                      classNamePrefix="select2-selection"
                    />
                  </div>

                  <div className="form-group">
                    <label>CLINIC</label>
                    <Select
                      isMulti={false}
                      onChange={(e) => {
                        handleSelectGroup(e, "clinic")
                    }}
                      options={clinics}
                      classNamePrefix="select2-selection"
                    />
                  </div>
                  <div className="form-group">
                    <label>THEATRE</label>
                    <Select
                      isMulti={false}
                      onChange={(e) => {
                        handleSelectGroup(e, "theatre")
                    }}
                      options={theatres}
                      classNamePrefix="select2-selection"
                    />
                  </div>

                  <div className="form-group">
                    <label>ON CALL / TEAM ROOM</label>
                    <Select
                      isMulti={false}
                      onChange={(e) => {
                        handleSelectGroup(e, "oncall")
                    }}
                      options={oncallList}
                      classNamePrefix="select2-selection"
                    />
                  </div>
                  <div className="form-group">
                    <label>CONSULTANT / REGISTRAR OFFICE</label>
                    <Select
                      isMulti={false}
                      onChange={(e) => {
                        handleSelectGroup(e, "consultant")
                    }}
                      options={consultants}
                      classNamePrefix="select2-selection"
                    />
                  </div>
                  {/* <div className="form-group">
                    <label>REGISTRAR OFFICE</label>
                    <Select
                      isMulti={true}
                      onChange={(e) => {
                        handleSelectGroup(e, "location")
                    }}
                      options={locations}
                      classNamePrefix="select2-selection"
                    />
                  </div> */}
              
                  <div className="form-group">
                    <label>NURSING OFFICE</label>
                    <Select
                      isMulti={false}
                      onChange={(e) => {
                        handleSelectGroup(e, "nursing")
                    }}
                      options={nursing}
                      classNamePrefix="select2-selection"
                    />
                  </div>
                  <div className="form-group  mb-0">
                    <label>FLOW MANAGEMENT</label>
                    <Select
                      isMulti={false}
                      onChange={(e) => {
                        handleSelectGroup(e, "flowmanagement")
                    }}
                      options={flows}
                      classNamePrefix="select2-selection"
                    />
                  </div>
                  </div>
                
                  <label className="checkbox">I agree to the <a href='#'>TERMS OF USAGE OF IPR</a>
                    <input type="checkbox" />
                    <span className="checkmark"></span>
                  </label>
                  <button className="btn-primary loginbtn mt-20" onClick={(e) => { submitUpdate(e) }} type="button"> Agree and Continue </button>
                  <br />

                  {setSuccess && <div className="alert alert-success" role="alert">
                    User Created Successfully
                  </div>}
                  {setSuccess &&
                    <button className="btn-primary loginbtn mt-20" onClick={(e) => { setVisible("login"); setsignup("personal"); }} type="button"> Back to Login </button>}


                  {/* <h4 className='form_subtitle'>Enter Employee details</h4>
                  <p className='form_subtag'>Set a quick way to login</p>
                  <div className="form-group">
                    <label>Employee Number</label>
                    <input type="text" id="username" className="form-control" placeholder='Enter' />
                  </div>
                  <div className="form-group">
                    <label>Tenure</label>
                    <input type="text" id="username" className="form-control" placeholder='Enter' />
                  </div>
                  <div className="form-group">
                    <label>Activated Upto</label>
                    <input type="text" id="username" className="form-control" placeholder='Enter' />
                  </div>
                  <button className="btn-primary loginbtn mt-20" type="button"> Done </button> */}

                </div>
              }

            </div>
          </div>
          <div className="copy_right">
            <div className='links'>
                <a href='#' className='link'>Dr.Thrinath Kumar Kakarlapudi</a>
                <a href='#' className='link'>Satya Prasad Narem</a>
                <a href='#' className='link'>Harikumar Nollu</a>
                <a href='#' className='link'>Prithviraj Chintalapti</a>
              </div>
            <p> All Rights reserved. IPR {currentYear}</p>
          </div>
        </div>
      }

    </React.Fragment>
  )
}



const mapStateToProps = ({ Layout, Login }) => ({

  user: Login.user,
  tablesData: Layout.tableData,
})


export default withRouter(
  connect(mapStateToProps, { loginUser, apiError, teamListUpdate, gettableList })(Login)
)

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
}