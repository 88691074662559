import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import MetaTags from 'react-meta-tags';
import SignaturePad from 'react-signature-canvas'
import Moment from 'moment';
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf';
import MicRecorder from 'mic-recorder-to-mp3';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition'
import { removeSpecialCharacters } from '../../utils/utils';
import { useReactToPrint } from 'react-to-print';
import { Link, useHistory } from "react-router-dom"
import Patient from '../../assets/images/user.png';
import WelcomeGfx from '../../assets/images/welcome-gfx.png';
import Bed from '../../assets/images/bed.svg';

import VideoCam from '../../assets/images/videocam.svg';
import straighten from '../../assets/images/straighten.svg';
import scale from '../../assets/images/scale.svg';
import monitroWeight from '../../assets/images/monitor_weight.svg';
import favorite from '../../assets/images/favorite.svg';
import PulseOxy from '../../assets/images/PulseOxy.svg';
import thermostat from '../../assets/images/thermostat.svg';
import play from '../../assets/images/play.svg';
import doc from '../../assets/images/doc.jpg';
import bloodtype from '../../assets/images/bloodtype.svg';
import rx from '../../assets/images/Rx.svg';
// import audioclip from '../../assets/images/AudioClip.jpg';
// import signature from '../../assets/images/signature.png';
import speech from '../../assets/images/speech_to_text.svg';
import referralLetter from '../../assets/images/Referrel-Letter.svg';
import Procedures from '../../assets/images/Procedures.svg';
import NeedsSurgery from '../../assets/images/Needs-Surgery.svg';
import Session from '../../assets/images/Session.svg';
import PatientRecordSVG from '../../assets/images/Patient-record.svg';
import OutcomeSVG from '../../assets/images/Outcome.svg';
import EpisodeDocumentSVG from '../../assets/images/Episode-Document.svg';
import CopySVG from '../../assets/images/Copy.svg';
import diagnosis from '../../assets/images/diagnosis.svg';


import PatientBlock from "./patientBlock";
import AudioWaveform from './AudioWaveform';
import Consent1 from "./clinic_consent1"
import Consent2 from "./clinic_consent2"
import Consent3 from "./clinic_consent3"
import Consent4 from "./clinic_consent4"

import { connect } from "react-redux"
import { getWardsList, getTheatresList, getClinicList, getPatientList, getUprofile, getDoctorList, updatePatient } from "../../store/actions"

const Clinic = props => {

    // const recorder = new MicRecorder({
    //     bitRate: 128
    // });
    const history = useHistory();
    let visibleList = {
        outcome_diagnotic: true,
        outcome_discharged: true,
        patientView: true
    }

    const [Mp3Recorder, setMp3Recorder] = useState(new MicRecorder({ bitRate: 128 }))

    const [visiblePatients, setPatient] = useState(false)
    const [visiblelanding, setClanding] = useState(false)
    const [visiblexray, setxray] = useState(false)
    const [visibletests, setTests] = useState(false)
    const [visibleProcedure, setProcedure] = useState(false)
    const [visiblePrescription, setPrescription] = useState(false)
    const [visibleEpisodeDocument, setEpisodeDocument] = useState(false)
    const [visibleOutpatient, setVisibleOutpatient] = useState(false)
    const [visisblePlasterRef, setVisiblePlasterRef] = useState(false)
    const [visisbleSpecialityRef, setVisibleSpecialityRef] = useState(false)
    const [visisbleSpeechtotext, setVisibleSpeechtotext] = useState(false)
    const [visibleDictate, setVisibleDictate] = useState(false)

    const [clinicValues, setClinic] = useState({})
    const [clinicPatient, setCPatient] = useState(false)
    const [errormessage, seterrormessage] = useState("")

    const [selectList, setList] = useState(["Plaster Referral", "Dressing Clinic", "Physio", "OT"])
    const [selectsurgery, setSurgries] = useState(["Consent Form", "Booking Form", "Refer to pre assement"])
    const [showMultiple, setMultiple] = useState(false)
    const [showsurgery, setsurgery] = useState(false)
    const [showDictate, setDictate] = useState(false)
    const [listItems, setListItem] = useState({})
    const [medicines, setMedicines] = useState([])
    const [visibleSet, setVisibleSet] = useState(visibleList)

    const [message, setMessage] = useState('')

    const [visibleProcedureCode, setvisibleProcedureCode] = useState(false)
    const [visibleProcedureCodeSecond, setvisibleProcedureCodeSecond] = useState(false)
    const [visibleClinicalTherapy, setvisibleClinicalTherapy] = useState(false)
    const [visibleDressing, setvisibleDressing] = useState(false)
    const [visibleSimpleWound, setvisibleSimpleWound] = useState(false)
    const [visibleComplexWound, setvisibleComplexWound] = useState(false)
    const [visibleBookingForm, setvisibleBookingForm] = useState(false)
    const [visibleConsentForm, setvisibleConsentForm] = useState(false)

    const [visibleConsentForm1, setvisibleConsentForm1] = useState(false)
    const [visibleConsentForm2, setvisibleConsentForm2] = useState(false)
    const [visibleConsentForm3, setvisibleConsentForm3] = useState(false)
    const [visibleConsentForm4, setvisibleConsentForm4] = useState(false)


    const [visisbleArrangeFollowup, setvisisbleArrangeFollowup] = useState(false)


    const singlemulitpleSelectShow = (flag) => setMultiple(flag)
    const singlemulitpleSurgeryShow = (flag) => setsurgery(flag)

    const {
        wards, user, theatres, clinic, patients, profile, doctors
    } = props;

    useEffect(() => {
        document.body.style = '';
        // $(".modal-backdrop").hide()
    }, [])

    useEffect(() => {
        const { getWards, getTheatres, getClinic, getDoctor, getPatient, getUserprofile } = props
        // getWards()
        // getTheatres()
        // getClinic()
        getPatient()
        getDoctor()
        let userId = localStorage.getItem("authUser")
        getUserprofile(userId)
        let clinicPatientLocalStorage = JSON.parse(localStorage.getItem("p")) || false
        setCPatient(clinicPatientLocalStorage)

    }, [])

    const updateInputValue = (e) => {

        let val;
        let name;
        if (e.firstname) {
            val = e;
            name = "patient";
            setCPatient(val)
            setClinic(clinicValues => ({
                ...clinicValues,
                ...val
            }));
            setMedicines(val.prescription || [])

            let updatedValue = {
                prescription: val.prescription
            };
            setClinic(clinicValues => ({
                ...clinicValues,
                ...updatedValue
            }));

            if (val.referrals && val.referrals.length > 0) {
                setMultiple(true)
            }
            if (val.surgery && val.surgery.length > 0) {
                setsurgery(true)
            }
            setListItem(val)
            setClanding(true)
            const { patientupdate } = props
            // let clinic = {
            //     clinic: clinicValues
            // }
            patientupdate(e._id, clinicValues)

        } else {
            val = e.target.value;
            name = e.target.name;
            let updatedValue = {};
            updatedValue = { [name]: val };

            if (name == "oc_arrange_duration") {
                let startdate = new Date()
                let estimated = Moment(startdate, "DD-MM-YYYY").add('days', val).format('MMM DD, YYYY');
                let dateArrangeEstimate = startdate;
                dateArrangeEstimate.setDate(dateArrangeEstimate.getDate() + val);
                console.log("dateArrangeEstimate ", dateArrangeEstimate)
                updatedValue["oc_arrange_estimated"] = estimated
                updatedValue["oc_arrange_estimated_date"] = dateArrangeEstimate
            }
            setClinic(clinicValues => ({
                ...clinicValues,
                ...updatedValue
            }));
        }

    }

    const updateClinicValues = (e, name, val) => {
        //  e.preventDefault()
        let updatedValue = {};
        updatedValue = { [name]: val };
        setClinic(clinicValues => ({
            ...clinicValues,
            ...updatedValue
        }));
    }

    const updateXray = (e) => {
        if (e.target.checked) {
            let val = e.target.value;
            let name = e.target.name;
            let updatedValue = {};
            updatedValue = { [name]: val };
            setClinic(clinicValues => ({
                ...clinicValues,
                ...updatedValue
            }));
        } else {
            let name = e.target.name;
            delete clinicValues[name]
            setClinic(clinicValues)
        }
    }

    const updateClinic = (e) => {
        const { patientupdate } = props
        let id = clinicPatient._id
        patientupdate(id, clinicValues)
    }

    const referralsSave = (name) => {
        const { patientupdate } = props
        let id = clinicPatient._id
        updateClinicValues(null, name, true)
        let savedRef = {
            [name]: true
        }
        patientupdate(id, savedRef)
    }

    const selectPatient = (e) => {
        if (clinicValues.site && clinicValues.clinic && clinicValues.doctor && clinicValues.otherloc) {
            setPatient(true)
        } else {
            seterrormessage("Please select above")
        }

    }

    const backPatientList = (e) => {
        //console.log("ssse")
        //if (clinicValues.site && clinicValues.clinic && clinicValues.doctor && clinicValues.otherloc) {
        // console.log("nnn")
        setPatient(true)
        setClanding(false)
        //} else {
        //  seterrormessage("Please select above")
        // }

    }


    const getprofile = (e) => {
        const { getUserprofile } = props
        let userId = localStorage.getItem("authUser")
        getUserprofile(userId)
    }

    const mulitpleSelect = (e, item, flag) => {
        if (flag == "surgery") {
            setsurgery(true)
        } else {
            setMultiple(true)
        }
        listSetProps(item, flag, "mulitple")
    }

    const listSetProps = (item, flag, type) => {
        if (type == "mulitple") {
            if (listItems[flag]) {
                if (!listItems[flag].includes(item)) {
                    listItems[flag].push(item)
                } else {
                    let filterItems = listItems[flag]
                    let filter = filterItems.filter(function (name) {
                        return name != item
                    })
                    listItems[flag] = filter
                }

            } else {
                listItems[flag] = [item]
            }
        }
        setListItem(listItems)
        const { patientupdate } = props
        let id = clinicPatient._id
        patientupdate(id, listItems)
    }

    const updateSetVisible = (e, name, val) => {
        //  e.preventDefault()
        let updatedValue = {};
        updatedValue = { [name]: val };
        setVisibleSet(visibleSet => ({
            ...visibleSet,
            ...updatedValue
        }));
    }

    // console.log("clinicValues", clinicValues)
    //console.log(" visibleSet ", visibleSet)
    // console.log("listItems", listItems)
    // console.log("visibleSet", visibleSet)
    // console.log("clinicPatient", clinicPatient)
    // console.log("profile", profile)

    let doctorsigPad = useRef(null);
    let patientsigPad = useRef(null);
    const clear = (type) => {
        if (type == "doctor") {
            doctorsigPad.clear()
        } else if (type == "patient") {
            patientsigPad.clear()
        }

    }
    const trim = (type) => {
        if (type == "doctor") {
            console.log("doc url", doctorsigPad.getTrimmedCanvas()
                .toDataURL('image/png'))
        } else if (type == "patient") {
            console.log("url", patientsigPad.getTrimmedCanvas()
                .toDataURL('image/png'))
        }

    }

    const exportPdf = (e) => {


        // let mywindow = window.open('', 'PRINT', 'height=650,width=900,top=100,left=150');

        //     mywindow.document.write(`<html><head><title>Doc</title>`);
        //     mywindow.document.write('</head><body >');
        //     mywindow.document.write(document.getElementById("capture").innerHTML);
        //     mywindow.document.write('</body></html>');

        //     mywindow.document.close(); // necessary for IE >= 10
        //     mywindow.focus(); // necessary for IE >= 10*/

        //     mywindow.print();
        //     mywindow.close();


        console.log("export pdf")
        var rep = new jsPDF("p", "pt", "a4");
        rep.html(document.querySelector("#capture"), {
            callback: function (pdf) {
                pdf.save("downloadk.pdf");
            }
        });

        //     html2canvas(document.querySelector("#capture")).then(canvas => {
        //        document.body.appendChild(canvas);  // if you want see your screenshot in body.
        //        console.log("canvas ", canvas)
        //        const imgData = canvas.toDataURL('image/png');
        //        console.log("imgData ", imgData)
        //        const pdf = new jsPDF();
        //        pdf.addImage(imgData, 'PNG', 0, 0);
        //        pdf.save("downloadn.pdf"); 
        //    });

    }

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    // const handleDownload = useReactToPrint({
    //     onPrintError: (error) => console.log(error),
    //     content: () => componentRef.current,
    //     removeAfterPrint: true,
    //     print: async (printIframe) => {
    //       const document = printIframe.contentDocument;
    //       if (document) {
    //         const html = document.getElementsByTagName("html")[0];
    //         console.log(html);
    //         const exporter = new Html2Pdf(html);
    //         await exporter.getPdf(true);
    //       }
    //     },
    //   });

    const handlePage = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: `d1.pdf`,
        copyStyles: true,
        print: async (printIframe) => {
            const document = printIframe.contentDocument;
            if (document) {
                const html = document.getElementsByTagName('html')[0];
                console.log(html);
                //await html2pdf().from(html).save();
                //const exporter = new Html2Pdf(html);
                //await exporter.getPdf(true);
            }
        }
    });

    const addMedicine = (e) => {

        let medicine = clinicValues.medicine;
        let dosagetype = clinicValues.dosagetype;
        let dosage = clinicValues.dosage;
        let duration = clinicValues.duration;
        let fm = clinicValues.fm;
        let fn = clinicValues.fn;
        let fa = clinicValues.fa;
        let updateMedicines = [{ medicine, dosagetype, dosage, duration, fm, fn, fa }]

        const newTasks = [...medicines, { medicine, dosagetype, dosage, duration, fm, fn, fa }];
        setMedicines(newTasks);

        let prescription = {
            prescription: newTasks
        };

        const { patientupdate } = props
        let id = clinicPatient._id
        patientupdate(id, { prescription: newTasks })
        setClinic(clinicValues => ({
            ...clinicValues,
            ...prescription
        }));
    };

    const startRecording = () => {

        Mp3Recorder.start().then(() => {
            setDictate(true)
        }).catch((e) => {
            console.error(e);
        });
    }

    const stopRecording = async () => {
        Mp3Recorder.stop().getMp3().then(async ([buffer, blob]) => {
            const file = new File(buffer, 'music.mp3', {
                type: blob.type,
                lastModified: Date.now()
            });

            let pdetails = JSON.parse(localStorage.getItem("p"))

            let uploadFilePath = "ipr/" + pdetails._id + "/dictate" + "/" + removeSpecialCharacters("music.mp3");
            let data = { source_bucket: "ipr-resources", sourcepath: uploadFilePath }
            console.log("data ", data)

            const response = await fetch(API_URL + 'upload?appname=ipr', {
                method: "POST",
                mode: "cors",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            })
            const result = await response.json();
            let url = result.result
            const uploadImage = await fetch(url, {
                method: "PUT",
                mode: "cors",
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Accept": "/",
                    "Cache-Control": "no-cache",
                    "Accept-Encoding": "gzip, deflate",
                    "Connection": "keep-alive",
                    "cache-control": "no-cache"
                },
                body: file
            })

            let imagePath = "https://ipr-resources.s3.amazonaws.com/"
            let audioUploadPath = imagePath + uploadFilePath;


            const { patientupdate } = props
            let updateObj = {
                dictate: audioUploadPath,
                dictateUpdate: new Date()
            }
            patientupdate(clinicPatient._id, updateObj)

            let updatedValue = {};
            updatedValue = { "dictate": audioUploadPath };
            setClinic(clinicValues => ({
                ...clinicValues,
                ...updatedValue
            }));

            //    const li = document.createElement('li');
            //    const player = new Audio(audioUploadPath);
            //    player.controls = true;
            //    li.appendChild(player);
            //    document.querySelector('#playlist').appendChild(li);

            setDictate(false)

        }).catch((e) => {
            console.error(e);
        });
    }

    const dateFormat = (date) => {
        return Moment(date, "DD-MM-YYYY").format('MMM DD, YYYY');
    }

    const btnClick = (e) => {
        e.preventDefault()
        history.push("/wlist")
        localStorage.setItem('rrd', 'cl')
    }

    const [transcribeText, settranscribeText] = useState("")

    const deleteSelect = (e) => {
        let selection = window.getSelection();
        let html = document.getElementById("transcribeResultText").innerHTML;
        console.log("selection", selection.toString())
        console.log("transcript", transcript)
        console.log("html", html)
        let replacedText = html.replace(selection.toString(), "")
        console.log("replacedText", replacedText)
        let ft = replacedText
        settranscribeText(ft)
        selection.deleteFromDocument();
        resetTranscript()
        SpeechRecognition.stopListening()
        SpeechRecognition.startListening({ continuous: true })

    }


    const commands = [
        // {
        //   command: 'I would like to order *',
        //   callback: (food) => setMessage(`Your order is for: ${food}`)
        // },
        // {
        //   command: 'The weather is :condition today',
        //   callback: (condition) => setMessage(`Today, the weather is ${condition}`)
        // },
        // {
        //   command: 'My top sports are * and *',
        //   callback: (sport1, sport2) => setMessage(`#1: ${sport1}, #2: ${sport2}`)
        // },
        // {
        //   command: 'Pass the salt (please)',
        //   callback: () => setMessage('My pleasure')
        // },
        // {
        //   command: 'delete',
        //   callback: () => deleteSelect()
        //  // matchInterim: true
        // },
        {
            command: 'Yes',
            callback: (command, spokenPhrase, similarityRatio) => setMessage(`${command} and ${spokenPhrase} are ${similarityRatio * 100}% similar`),
            // If the spokenPhrase is "Benji", the message would be "Beijing and Benji are 40% similar"
            isFuzzyMatch: true,
            fuzzyMatchingThreshold: 0.2
        },
        {
            command: 'clear',
            callback: ({ resetTranscript }) => resetTranscript()
        }
    ]

    const { transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition } = useSpeechRecognition({ commands })

    const time = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })
    const formatDate = Moment().format('MMM DD, YYYY')


    return (
        <React.Fragment>

            <MetaTags>
                <title>Clinic</title>
            </MetaTags>
            <div className="dashboard ward current_episode">
                <div className="main">

                    <div className="wrapper">
                        <div className="patient_record prd">
                            <div className="prd_top">
                                {clinicPatient && clinicPatient._id && <PatientBlock patient={clinicPatient} accordion={true} selectPatientList={true} />}

                                {!clinicPatient && <div className="find">
                                    <button type="button" onClick={(e) => btnClick(e)} className="btn"> <span className="material-symbols-outlined icon">
                                        person_search </span> Find Patient</button>
                                </div>}
                                {!clinicPatient && <div className="guided_tour">
                                    <div className="guided_tour_arrow"></div>
                                    <p>Find and Add a patient to access the Ward</p>
                                    <span className="material-icons icon"> close </span>
                                </div>}



                                {clinicPatient && clinicPatient._id && <div className="visit_block">
                                    <div className="left">
                                        <div className="visit">
                                            Visit
                                            <span className="num">{clinicPatient.visitno}</span>
                                        </div>
                                        <div className="text">
                                            <h4>Problem</h4>
                                            <p>{clinicPatient.problem}</p>
                                        </div>
                                    </div>
                                    <div className="right">
                                        <Link to="vc" className="icon_btn">
                                            <span className="material-symbols-outlined icon"> videocam </span>
                                            Video Call</Link>
                                    </div>
                                </div>}
                                {clinicPatient && clinicPatient._id &&
                                    <div className="measurements">
                                        <h3>Measurements</h3>
                                        <div className="boxs">
                                            <div className="box">
                                                <span className="material-symbols-outlined icon rotate"> straighten </span>
                                                <p> <span className="bold">{clinicPatient.measurement}</span> cm</p>
                                            </div>
                                            <div className="box">
                                                <span className="material-symbols-outlined icon"> scale </span>
                                                <p> <span className="bold">{clinicPatient.kg} </span> kg</p>
                                            </div>

                                            <div className="box">
                                                <span className="material-symbols-outlined icon"> monitor_weight </span>
                                                <p> <span className="bold">{clinicPatient.weight}</span> </p>
                                            </div>
                                            <div className="box">
                                                <span className="material-symbols-outlined icon"> favorite </span>
                                                <p> <span className="bold">{clinicPatient.bpm} </span> BPM</p>
                                            </div>
                                            <div className="box">
                                                <img className="icon" src={PulseOxy} alt="" />
                                                <p> <span className="bold">{clinicPatient.pulse}</span> </p>
                                            </div>
                                            <div className="box">
                                                <span className="material-symbols-outlined icon"> thermostat </span>
                                                <p> <span className="bold">{clinicPatient.thermo} </span> F</p>
                                            </div>
                                            <div className="box">
                                                <span className="material-symbols-outlined icon"> bloodtype </span>
                                                <p> <span className="bold">{clinicPatient.bloodtype} </span> mgdl</p>
                                            </div>
                                        </div>
                                    </div>}
                                <div className="prd_btns">
                                    <div className="btns">
                                        <button type="button" onClick={(e) => setVisibleDictate(true)} className="btn">DICTATE </button>
                                        <button type="button" onClick={(e) => { setVisibleSpeechtotext(true) }} className="btn">SPEECH </button>
                                        <button type="button" className="btn">DICTATION SETUP </button>
                                    </div>
                                    <div className="btn-group">
                                        <button type="button" onClick={(e) => setxray(true)} className="btn btn-primary"> X-RAYS </button>
                                        <button type="button" className="btn btn-primary">LABS </button>
                                        <button type="button" onClick={(e) => setTests(true)} className="btn btn-primary">ORDER TESTS </button>
                                        <button type="button" onClick={(e) => setPrescription(true)} className="btn btn-primary">PRESCRIBE </button>
                                    </div>
                                </div>

                            </div>
                            <h2 className="mb_15">OUT PATIENT CLINIC & TELEMEDICINE</h2>

                            <ul className={!clinicPatient ? "oncall_dboard col_3 disabled" : "oncall_dboard col_3"} >
                                <li>
                                    <Link to="uc" className="item_card">
                                        <span className="icon"> <img src={referralLetter} /></span>
                                        Referrel Letter

                                    </Link>
                                </li>
                                <li>
                                    <a onClick={(e) => setProcedure(true)} className="item_card">
                                        <span className="icon"> <img src={Procedures} /></span>
                                        <span className="wn">
                                            Procedures
                                            <span className="material-symbols-outlined icon2"> keyboard_arrow_down </span>

                                        </span>
                                    </a>
                                </li>
                                <li>
                                    <a data-bs-toggle="modal" data-bs-target="#referrals" className="item_card">
                                        <span className="icon"> <img src={referralLetter} /></span>
                                        <span className="wn">
                                            Referrals
                                            <span className="material-symbols-outlined icon2"> keyboard_arrow_down </span>

                                        </span>
                                    </a>
                                </li>
                                <li>
                                    <a data-bs-toggle="modal" data-bs-target="#needstosurgery" className="item_card">
                                        <span className="icon"> <img src={NeedsSurgery} /></span>
                                        <span className="wn">
                                        Needs Surgery
                                            <span className="material-symbols-outlined icon2"> keyboard_arrow_down </span>

                                        </span> 
                                    </a>
                                </li>
                                <li>
                                    <Link to="uc" className="item_card">
                                        <span className="icon"> <img src={Session} /></span>
                                        Session Admin
                                        & Quality Report
                                    </Link>
                                </li>
                                <li>
                                    <Link to="prdetails" className="item_card">
                                        <span className="icon"> <img src={PatientRecordSVG} /></span>
                                        Patient Record
                                    </Link>
                                </li>
                                <li>
                                    <a onClick={(e) => { setVisibleOutpatient(true) }} className="item_card">
                                        <span className="icon"> <img src={OutcomeSVG} /></span>
                                        Outcome
                                    </a>
                                </li>
                                <li>
                                    <a onClick={(e) => { setEpisodeDocument(true), getprofile(e) }} className="item_card">
                                        <span className="icon"> <img src={EpisodeDocumentSVG} /></span>
                                        Episode Document
                                    </a>
                                </li>
                                <li>
                                    <Link to="pr" className="item_card">
                                        <span className="icon"> <img src={CopySVG} /></span>
                                        Copy to Theatre
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>


                   

                                {/* <div className="section">
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <h3 className="section_title">X Rays</h3>
                                            <a onClick={(e) => setxray(true)} className="btn-light">Order</a>
                                        </div>
                                        <div className="col-sm-8">
                                            <div className="documents_section">
                                                {clinicPatient.xray_result ?
                                                    <div className="document_box">
                                                        <img src={clinicPatient.xray_result} className="img" />
                                                        <a href="#" className="doc_title">
                                                            <h4>{clinicPatient.xray}</h4>
                                                            <p>{clinicPatient.xray_update}</p>
                                                        </a>
                                                    </div> : (clinicValues.xray || clinicPatient.xray) &&
                                                    <div className="col-sm-6">
                                                        <a href="#" className="referral">  <img src={play} className="icon" /> {clinicValues.xray || clinicPatient.xray}</a>
                                                    </div>}

                                                {clinicPatient.xray2_result ?
                                                    <div className="document_box">
                                                        <img src={clinicPatient.xray2_result} className="img" />
                                                        <a href="#" className="doc_title">
                                                            <h4>{clinicPatient.xray2}</h4>
                                                            <p>{clinicPatient.xray2_update}</p>
                                                        </a>
                                                    </div> : (clinicValues.xray2 || clinicPatient.xray2) &&
                                                    <div className="col-sm-6">
                                                        <a href="#" className="referral">  <img src={play} className="icon" /> {clinicValues.xray2 || clinicPatient.xray2}</a>
                                                    </div>}




                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="section">
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <h3 className="section_title">Tests</h3>
                                            <a href="#" onClick={(e) => setTests(true)} className="btn-light">Order</a>
                                        </div>
                                        <div className="col-sm-8">
                                            <div className="documents_section">
                                                {clinicPatient.test1_result ?
                                                    <div className="document_box">
                                                        <img src={clinicPatient.test1_result} className="img" />
                                                        <a href="#" className="doc_title">
                                                            <h4>{clinicPatient.test1}</h4>
                                                            <p>{clinicPatient.test1_update}</p>
                                                        </a>
                                                    </div> : (clinicValues.test1 || clinicPatient.test1) &&
                                                    <div className="col-sm-6">
                                                        <a href="#" className="referral">  <img src={play} className="icon" /> {clinicValues.test1 || clinicPatient.test1}</a>
                                                    </div>}

                                                {clinicPatient.test2_result ?
                                                    <div className="document_box">
                                                        <img src={clinicPatient.test2_result} className="img" />
                                                        <a href="#" className="doc_title">
                                                            <h4>{clinicPatient.test2}</h4>
                                                            <p>{clinicPatient.test2_update}</p>
                                                        </a>
                                                    </div> : (clinicValues.test2 || clinicPatient.test2) &&
                                                    <div className="col-sm-6">
                                                        <a href="#" className="referral">  <img src={play} className="icon" /> {clinicValues.test2 || clinicPatient.test2}</a>
                                                    </div>}

                                                {clinicPatient.test3_result ?
                                                    <div className="document_box">
                                                        <img src={clinicPatient.test3_result} className="img" />
                                                        <a href="#" className="doc_title">
                                                            <h4>{clinicPatient.test3}</h4>
                                                            <p>{clinicPatient.test3_update}</p>
                                                        </a>
                                                    </div> : (clinicValues.test3 || clinicPatient.test3) &&
                                                    <div className="col-sm-6">
                                                        <a href="#" className="referral">  <img src={play} className="icon" /> {clinicValues.test3 || clinicPatient.test3}</a>
                                                    </div>}


                                                {clinicPatient.test4_result ?
                                                    <div className="document_box">
                                                        <img src={clinicPatient.test4_result} className="img" />
                                                        <a href="#" className="doc_title">
                                                            <h4>{clinicPatient.test4}</h4>
                                                            <p>{clinicPatient.test4_update}</p>
                                                        </a>
                                                    </div> : (clinicValues.test4 || clinicPatient.test4) &&
                                                    <div className="col-sm-6">
                                                        <a href="#" className="referral">  <img src={play} className="icon" /> {clinicValues.test4 || clinicPatient.test4}</a>
                                                    </div>}




                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="section">
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <h3 className="section_title">Prescriptions</h3>
                                            <a href="#" onClick={(e) => setPrescription(true)} className="btn-light">Add</a>
                                        </div>
                                        <div className="col-sm-8">
                                            <div className="documents_section">
                                                {clinicValues.prescription && clinicValues.prescription.length > 0 &&
                                                    <div className="section">
                                                        <div className="row">
                                                            <div className="col-sm-12">

                                                                <div className='rx_table'>
                                                                    <div className='rx_img'>
                                                                        <img src={rx} />
                                                                    </div>
                                                                    <table className="table table-striped">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Medicine Name</th>
                                                                                <th>Dosage</th>
                                                                                <th>
                                                                                    <div className='frc_icons'> <span className="material-icons">sunny_snowing</span>
                                                                                        <span className="material-icons">light_mode</span>
                                                                                        <span className="material-icons">dark_mode</span></div>

                                                                                </th>
                                                                                <th>Duration</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {clinicValues.prescription.map((item, i) =>
                                                                            (
                                                                                <tr key={i}>
                                                                                    <td> {item.medicine}</td>
                                                                                    <td>{item.dosage + " " + item.dosagetype}</td>
                                                                                    <td><div className='frequency'>
                                                                                        {item.fm ?
                                                                                            <span className="material-icons">check_circle_outline</span> :
                                                                                            <span className="material-icons calcel">cancel</span>}
                                                                                        {item.fa ?
                                                                                            <span className="material-icons">check_circle_outline</span> :
                                                                                            <span className="material-icons calcel">cancel</span>}
                                                                                        {item.fn ?
                                                                                            <span className="material-icons">check_circle_outline</span> :
                                                                                            <span className="material-icons calcel">cancel</span>}

                                                                                    </div></td>
                                                                                    <td>{item.duration} Days</td>
                                                                                </tr>
                                                                            )
                                                                            )}



                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                               

                               
                           
                <br/>
                <br/><br/>



                </div>
            </div>


            <div class="modal wordnotes oncall fade" id="referrals" tabindex="-1" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">
                                <span class="icon"><img src={diagnosis} /></span>
                                Referrals Letter

                            </h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="list_block">
                                <div class="list">
                                    <a onClick={(e) => { setVisiblePlasterRef(true) }} data-bs-dismiss="modal" class="link">Plaster Referral</a>
                                </div>

                                <div class="list">
                                    <a onClick={(e) => { setVisibleSpecialityRef(true) }} data-bs-dismiss="modal" class="link">Specialist Referral</a>
                                </div>
                                <div class="list">
                                    <a onClick={(e) => setvisibleClinicalTherapy(true)} data-bs-dismiss="modal" class="link">Clinical Therapy Referral</a>
                                </div>
                                <div class="list">
                                    <a onClick={(e) => setvisibleDressing(true)} data-bs-dismiss="modal" class="link">Dressing Clinic Referral</a>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div class="modal wordnotes oncall fade" id="needstosurgery" tabindex="-1" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">
                                <span class="icon"><img src={diagnosis} /></span>
                                Needs Surgery

                            </h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="list_block">
                                <div class="list">
                                    <a onClick={(e) => { setvisibleConsentForm(true) }} data-bs-dismiss="modal" class="link">Consent Form</a>
                                </div>

                                <div class="list">
                                    <a onClick={(e) => { setvisibleBookingForm(true) }} data-bs-dismiss="modal" class="link">Booking Form</a>
                                </div>
                                <div class="list">
                                    <Link to="uc" class="link">Refer to pre assesment</Link>
                                </div>
                                

                            </div>
                        </div>

                    </div>
                </div>
            </div>


            {visiblexray &&
                <div className='popup_wrp'>
                    <div className="popup_full" >
                        <div className='popup_hed'>
                            <h2>Order X-Ray</h2>
                            <a onClick={(e) => setxray(false)} className='popup_close'><span className="material-icons">close</span></a>
                        </div>
                        <div className='popup_content'>
                            <div className='search_block'>
                                <input type="text" id="fname" name="fname" placeholder='Search' />
                                <a href='#' className="material-icons">search</a>
                            </div>
                            <div className='popup_scroll'>
                                <div className='check_list'>
                                    <div className='check_item'>
                                        <label className="checkbox">Leg
                                            <input type="checkbox" name="xray" value="leg" onChange={(e) => updateXray(e)} />
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                    <div className='check_item'>
                                        <label className="checkbox">Hand
                                            <input type="checkbox" name="xray2" value="hand" onChange={(e) => updateXray(e)} />
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='popup_footer'>
                            <button className='btn' onClick={(e) => { updateClinic(e), setxray(false) }}  >SELECT & ORDER</button>
                        </div>
                    </div>
                </div>
            }


            {visibletests &&
                <div className='popup_wrp'>
                    <div className="popup_full">
                        <div className='popup_hed'>
                            <h2>Order Lab Tests</h2>
                            <a onClick={(e) => setTests(false)} className='popup_close'><span className="material-icons">close</span></a>
                        </div>
                        <div className='popup_content'>

                            <div className='popup_scroll'>

                                <div className='check_list'>

                                    <h3 className='subtitle mt-20'>Diabetic Profile</h3>
                                    <div className='check_item'>
                                        <label className="checkbox">Abdomen KUB
                                            <input type="checkbox" name="test1" value="Abdomen KUB" onChange={(e) => updateXray(e)} />
                                            <span className="checkmark"></span>
                                        </label></div>
                                    <div className='check_item'>
                                        <label className="checkbox">Stomach
                                            <input type="checkbox" name="test2" value="Stomach" onChange={(e) => updateXray(e)} />
                                            <span className="checkmark"></span>
                                        </label></div>

                                    <h3 className='subtitle mt-20'>Ear & Nose</h3>
                                    <div className='check_item'>
                                        <label className="checkbox">Ear
                                            <input type="checkbox" name="test3" value="Ear" onChange={(e) => updateXray(e)} />
                                            <span className="checkmark"></span>
                                        </label></div>
                                    <div className='check_item'>
                                        <label className="checkbox">Nose
                                            <input type="checkbox" name="test4" value="Nose" onChange={(e) => updateXray(e)} />
                                            <span className="checkmark"></span>
                                        </label></div>

                                </div>
                            </div>
                        </div>
                        <div className='popup_footer'>
                            <button className='btn' onClick={(e) => { updateClinic(e), setTests(false) }}>SELECT & DONE</button>
                        </div>
                    </div>
                </div>
            }


            {visibleProcedure &&
                <div className='popup_wrp'>
                    <div className="popup_full">
                        <div className='popup_hed'>
                            <h2>Add Procedures</h2>
                            <a onClick={(e) => setProcedure(false)} className='popup_close'><span className="material-icons">close</span></a>
                        </div>
                        <div className='popup_content'>
                            <h3 className='subtitle'>Preferred Templates</h3>
                            <div className='templates'>
                                {/* <div className='tmp_wrp'>
                                <div className='tmp_block'>
                                    <span className="material-icons tmp_icon">play_circle_filled</span>
                                    <span className='tmp_title'>ACL Reconstruction Surgery</span>
                                </div>
                                <div className='tmp_block'>
                                    <span className="material-icons tmp_icon">play_circle_filled</span>
                                    <span className='tmp_title'>ACL Reconstruction Surgery</span>
                                </div>
                                <div className='tmp_block'>
                                    <span className="material-icons tmp_icon">play_circle_filled</span>
                                    <span className='tmp_title'>ACL Reconstruction Surgery</span>
                                </div>
                                <div className='tmp_block'>
                                    <span className="material-icons tmp_icon">play_circle_filled</span>
                                    <span className='tmp_title'>ACL Reconstruction Surgery</span>
                                </div>
                                <div className='tmp_block'>
                                    <span className="material-icons tmp_icon">play_circle_filled</span>
                                    <span className='tmp_title'>ACL Reconstruction Surgery</span>
                                </div>
                            </div> */}
                            </div>
                            <h3 className='subtitle'>Templates</h3>

                            <div className='popup_scroll'>
                                <div className='check_item'>
                                    <label className="checkbox">Ankle Replacement Surgery
                                        <input type="checkbox" name="pro1" value="Ankle Replacement Surgery" onChange={(e) => updateXray(e)} />
                                        <span className="checkmark"></span>
                                    </label></div>
                                <div className='check_item'>
                                    <label className="checkbox">Shoulder Replacement Surgery
                                        <input type="checkbox" name="pro2" value="Shoulder Replacement Surgery" onChange={(e) => updateXray(e)} />
                                        <span className="checkmark"></span>
                                    </label></div>


                                <div className='tmp_item select'>
                                    <h3> <span className="material-icons tmp_icon">play_circle_filled</span>Ankle Replacement Surgery</h3>
                                    <p>If you take blood thinners or anticoagulants, your doctor will discuss when to stop taking them before having surgery. These include anti-inflammatory pain relievers like aspirin and ibuprofen. They can cause extra bleeding if you take them too close to surgery.</p>
                                </div>
                                <div className='tmp_item'>
                                    <h3> <span className="material-icons tmp_icon">play_circle_filled</span>Shoulder Replacement Surgery</h3>
                                    <p>If you take blood thinners or anticoagulants, your doctor will discuss when to stop taking them before having surgery. These include anti-inflammatory pain relievers like aspirin and ibuprofen. They can cause extra bleeding if you take them too close to surgery.</p>
                                </div>

                            </div>
                        </div>
                        <div className='popup_footer'>
                            <button className='btn' onClick={(e) => { updateClinic(e), setProcedure(false) }}>SELECT & ORDER</button>
                        </div>
                    </div>
                </div>
            }

            {visiblePrescription &&
                <div className='popup_wrp'>
                    <div className="popup_full">
                        <div className='popup_hed'>
                            <h2>Prescribe</h2>
                            <a onClick={(e) => setPrescription(false)} className='popup_close'><span className="material-icons">close</span></a>
                        </div>
                        <div className='popup_content outcome prescribe'>
                            <div className="out_patient_section out_patient_details mt-0 mb-0">

                                <div className='popup_scroll '>
                                    <div className="sections_block">
                                        <div className='prb_search'>
                                            <input type="text" name="medicine" onChange={(e) => updateInputValue(e)} />
                                        </div>
                                        <div className='add_prescribe'>
                                            <div className='left'>
                                                <div className='dsg'>
                                                    <label>Dosage</label>
                                                    <div className='dsp_flex'>
                                                        <input type="text" name="dosage" onChange={(e) => updateInputValue(e)} />
                                                        <select name="dosagetype" onChange={(e) => updateInputValue(e)}>
                                                            <option value="mg">mg</option>
                                                            <option value="ml">ml</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className='fqc'>
                                                    <label>Frequency</label>
                                                    <div className='fqc_btns'>
                                                        <div className="check_btn">
                                                            <label>
                                                                <input type="checkbox" onClick={(e) => updateInputValue(e)} value="morning" name="fm" /><span className="material-symbols-outlined">sunny_snowing</span>
                                                            </label>
                                                        </div>
                                                        <div className="check_btn">
                                                            <label>
                                                                <input type="checkbox" onClick={(e) => updateInputValue(e)} value="afternoon" name="fa" /><span className="material-symbols-outlined">light_mode</span>
                                                            </label>
                                                        </div>
                                                        <div className="check_btn">
                                                            <label>
                                                                <input type="checkbox" onClick={(e) => updateInputValue(e)} value="night" name="fn" /><span className="material-symbols-outlined">dark_mode</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='drn'>
                                                    <label>Duration</label>
                                                    <div className='dsp_flex'>
                                                        <input type="text" name="duration" onChange={(e) => updateInputValue(e)} />
                                                        <div className='days'>Days</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='right'>
                                                <button onClick={(e) => addMedicine(e)} className='add'><span className="material-icons">add</span></button>
                                            </div>
                                        </div>
                                        {clinicValues.prescription && clinicValues.prescription.length > 0 &&
                                            <div className="section pt-0">
                                                <div className="row">
                                                    <div className="col-sm-12">

                                                        <div className='rx_table'>
                                                            <div className='rx_img'>
                                                                <img src={rx} />
                                                            </div>
                                                            <table className="table table-striped">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Medicine Name</th>
                                                                        <th>Dosage</th>
                                                                        <th>
                                                                            <div className='frc_icons'> <span className="material-symbols-outlined">sunny_snowing</span>
                                                                                <span className="material-symbols-outlined">light_mode</span>
                                                                                <span className="material-symbols-outlined">dark_mode</span></div>

                                                                        </th>
                                                                        <th>Duration</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {clinicValues.prescription && clinicValues.prescription.map((item, i) =>
                                                                    (
                                                                        <tr key={i}>
                                                                            <td> {item.medicine}</td>
                                                                            <td>{item.dosage + " " + item.dosagetype}</td>
                                                                            <td><div className='frequency'>
                                                                                {item.fm ?
                                                                                    <span className="material-icons">check_circle_outline</span> :
                                                                                    <span className="material-icons cancel">cancel</span>}
                                                                                {item.fa ?
                                                                                    <span className="material-icons">check_circle_outline</span> :
                                                                                    <span className="material-icons cancel">cancel</span>}
                                                                                {item.fn ?
                                                                                    <span className="material-icons">check_circle_outline</span> :
                                                                                    <span className="material-icons cancel">cancel</span>}

                                                                            </div></td>
                                                                            <td>{item.duration} Days</td>
                                                                        </tr>
                                                                    )
                                                                    )}


                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        }


                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className='popup_footer'>
                            <div className='prescribe_footer'>
                                <button className='border_btn'><span className="material-icons">save</span>Add to Patient Record </button>
                                <button className='btn dsp_flex' onClick={(e) => setPrescription(false)} ><span className="material-icons"><span className="material-icons">close</span></span>DISCARD </button>
                            </div>
                        </div>
                    </div>
                </div>

            }


            {visibleEpisodeDocument &&
                <div className='popup_wrp'>
                    <div className="popup_full"  >
                        <div className='popup_hed'>
                            <h2>Episode Document</h2>
                            <a onClick={(e) => { setEpisodeDocument(false) }} className='popup_close'><span className="material-icons">close</span></a>
                        </div>
                        <div className='popup_content outcome' ref={componentRef} id="capture">
                            <div className="out_patient_section out_patient_details mt-0 mb-0">

                                <PatientBlock patient={clinicPatient} />


                                <div className='popup_scroll ' >
                                    <div className="sections_block" >
                                        <div className="section">
                                            <h3 className="section_title">Measurements</h3>
                                            <div className="measurements">
                                                <div className="box">
                                                    <img src={straighten} className="icon" />
                                                    <span className="text">{clinicPatient.measurement} <span className="sub">cm </span> </span>
                                                </div>
                                                <div className="box">
                                                    <img src={scale} className="icon" />
                                                    <span className="text">{clinicPatient.kg} <span className="sub">kg </span> </span>
                                                </div>
                                                <div className="box">
                                                    <img src={monitroWeight} className="icon" />
                                                    <span className="text">{clinicPatient.weight} </span>
                                                </div>
                                                <div className="box">
                                                    <img src={favorite} className="icon" />
                                                    <span className="text">{clinicPatient.bpm} <span className="sub">BPM </span></span>
                                                </div>
                                                <div className="box">
                                                    <img src={PulseOxy} className="icon" />
                                                    <span className="text">{clinicPatient.pulse} <span className="sub">% </span> </span>
                                                </div>
                                                <div className="box">
                                                    <img src={thermostat} className="icon" />
                                                    <span className="text">{clinicPatient.thermo}  <span className="sub">F </span> </span>
                                                </div>
                                                <div className="box">
                                                    <img src={bloodtype} className="icon" />
                                                    <span className="text">{clinicPatient.bloodtype} <span className="sub">mgdi </span> </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="section">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <h3 className="section_title">X Rays</h3>
                                                    <div className="documents_section">
                                                        {clinicPatient.xray_result ?
                                                            <div className="document_box">
                                                                <img src={clinicPatient.xray_result} className="img" />
                                                                <a href="#" className="doc_title">
                                                                    <h4>{clinicPatient.xray}</h4>
                                                                    <p>{clinicPatient.xray_update}</p>
                                                                </a>
                                                            </div> : (clinicValues.xray || clinicPatient.xray) &&
                                                            <div className="col-sm-6">
                                                                <a href="#" className="referral">  <img src={play} className="icon" /> {clinicValues.xray || clinicPatient.xray}</a>
                                                            </div>}

                                                        {clinicPatient.xray2_result ?
                                                            <div className="document_box">
                                                                <img src={clinicPatient.xray2_result} className="img" />
                                                                <a href="#" className="doc_title">
                                                                    <h4>{clinicPatient.xray2}</h4>
                                                                    <p>{clinicPatient.xray2_update}</p>
                                                                </a>
                                                            </div> : (clinicValues.xray2 || clinicPatient.xray2) &&
                                                            <div className="col-sm-6">
                                                                <a href="#" className="referral">  <img src={play} className="icon" /> {clinicValues.xray2 || clinicPatient.xray2}</a>
                                                            </div>}

                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="section">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <h3 className="section_title">Tests</h3>
                                                    <div className="documents_section">
                                                        {clinicPatient.test1_result ?
                                                            <div className="document_box">
                                                                <img src={clinicPatient.test1_result} className="img" />
                                                                <a href="#" className="doc_title">
                                                                    <h4>{clinicPatient.test1}</h4>
                                                                    <p>{clinicPatient.test1_update}</p>
                                                                </a>
                                                            </div> : (clinicValues.test1 || clinicPatient.test1) &&
                                                            <div className="col-sm-6">
                                                                <a href="#" className="referral">  <img src={play} className="icon" /> {clinicValues.test1 || clinicPatient.test1}</a>
                                                            </div>}

                                                        {clinicPatient.test2_result ?
                                                            <div className="document_box">
                                                                <img src={clinicPatient.test2_result} className="img" />
                                                                <a href="#" className="doc_title">
                                                                    <h4>{clinicPatient.test2}</h4>
                                                                    <p>{clinicPatient.test2_update}</p>
                                                                </a>
                                                            </div> : (clinicValues.test2 || clinicPatient.test2) &&
                                                            <div className="col-sm-6">
                                                                <a href="#" className="referral">  <img src={play} className="icon" /> {clinicValues.test2 || clinicPatient.test2}</a>
                                                            </div>}

                                                        {clinicPatient.test3_result ?
                                                            <div className="document_box">
                                                                <img src={clinicPatient.test3_result} className="img" />
                                                                <a href="#" className="doc_title">
                                                                    <h4>{clinicPatient.test3}</h4>
                                                                    <p>{clinicPatient.test3_update}</p>
                                                                </a>
                                                            </div> : (clinicValues.test3 || clinicPatient.test3) &&
                                                            <div className="col-sm-6">
                                                                <a href="#" className="referral">  <img src={play} className="icon" /> {clinicValues.test3 || clinicPatient.test3}</a>
                                                            </div>}


                                                        {clinicPatient.test4_result ?
                                                            <div className="document_box">
                                                                <img src={clinicPatient.test4_result} className="img" />
                                                                <a href="#" className="doc_title">
                                                                    <h4>{clinicPatient.test4}</h4>
                                                                    <p>{clinicPatient.test4_update}</p>
                                                                </a>
                                                            </div> : (clinicValues.test4 || clinicPatient.test4) &&
                                                            <div className="col-sm-6">
                                                                <a href="#" className="referral">  <img src={play} className="icon" /> {clinicValues.test4 || clinicPatient.test4}</a>
                                                            </div>}
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="section">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <h3 className="section_title">Prescribed Medicines</h3>


                                                    {clinicValues.prescription && clinicValues.prescription.length > 0 &&


                                                        <div className='rx_table'>
                                                            <div className='rx_img'>
                                                                <img src={rx} />
                                                            </div>
                                                            <table className="table table-striped">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Medicine Name</th>
                                                                        <th>Dosage</th>
                                                                        <th>
                                                                            <div className='frc_icons'> <span className="material-icons">sunny_snowing</span>
                                                                                <span className="material-icons">light_mode</span>
                                                                                <span className="material-icons">dark_mode</span></div>

                                                                        </th>
                                                                        <th>Duration</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {clinicValues.prescription.map((item, i) =>
                                                                    (
                                                                        <tr key={i}>
                                                                            <td> {item.medicine}</td>
                                                                            <td>{item.dosage + " " + item.dosagetype}</td>
                                                                            <td><div className='frequency'>
                                                                                {item.fm ?
                                                                                    <span className="material-icons">check_circle_outline</span> :
                                                                                    <span className="material-icons calcel">cancel</span>}
                                                                                {item.fa ?
                                                                                    <span className="material-icons">check_circle_outline</span> :
                                                                                    <span className="material-icons calcel">cancel</span>}
                                                                                {item.fn ?
                                                                                    <span className="material-icons">check_circle_outline</span> :
                                                                                    <span className="material-icons calcel">cancel</span>}

                                                                            </div></td>
                                                                            <td>{item.duration} Days</td>
                                                                        </tr>
                                                                    )
                                                                    )}



                                                                </tbody>
                                                            </table>
                                                        </div>

                                                    }


                                                </div>

                                            </div>
                                        </div>

                                        <div className="section">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <h3 className="section_title">Procedures</h3>
                                                    <div className='tmp_item'>

                                                        {(clinicValues.pro1 || clinicPatient.pro1) &&
                                                            <h3> <span className="material-icons tmp_icon">play_circle_filled</span>{(clinicValues.pro1 || clinicPatient.pro1)}</h3>
                                                        }

                                                        {(clinicValues.pro2 || clinicPatient.pro2) &&
                                                            <h3> <span className="material-icons tmp_icon">play_circle_filled</span>{(clinicValues.pro2 || clinicPatient.pro2)}</h3>
                                                        }

                                                        <p>If you take blood thinners or anticoagulants, your doctor will discuss when to stop taking them before having surgery. These include anti-inflammatory pain relievers like aspirin and ibuprofen. They can cause extra bleeding if you take them too close to surgery.</p>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="section">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <h3 className="section_title">Referrals</h3>
                                                    <div className="mlt_links">{listItems.referrals && listItems.referrals.map((item, i) =>
                                                    (<span key={i} className="referral">  <img src={play} className="icon" /> {item}</span>
                                                    )
                                                    )}</div>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="section border_none">
                                            {/* <img src={audioclip} /> */}
                                            <ul id="playlist"></ul>
                                            {(clinicValues.dictate || clinicPatient.dictate) && <audio controls>
                                                <source src={clinicValues.dictate || clinicPatient.dictate} type="audio/mpeg" />
                                                Your browser does not support the audio element.
                                            </audio>}


                                        </div>
                                        <div className="section border_none">

                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <h3 className="section_title">Needs Surgery </h3>
                                                    <div className="mlt_links">{listItems.surgery && listItems.surgery.map((item, i) =>
                                                    (<span key={i} className="referral">  <img src={play} className="icon" /> {item}</span>
                                                    )
                                                    )} </div>
                                                    <div className='tmp_item consent_form'>
                                                        <h3> <span className="material-icons tmp_icon mt-0">arrow_right</span>Proposed Treatment</h3>
                                                        <p>The doctor has explained that I, <b>Finley Goldsmith,</b> have a Tennis Elbow  in my right leg which is a risk factor for
                                                            Cryosurgery is proposed.</p>
                                                        <h3> <span className="material-icons tmp_icon">arrow_right</span>Risks</h3>
                                                        <p>These are the commoner risks. There may be other unusual risks that have not been listed here.<br />
                                                            I understand there are risks associated with any anesthetic agent (in case of children).
                                                            I may have side effects from any of the drugs used. The commoner side effects include light-headedness, nausea, skin rash and constipation.<br />
                                                            I understand the procedure has the following specific risks and limitations:<br /><br />

                                                            1. Although most retinal lesions can be treated, it is not 100% effective. In some cases, more than two sittings may be required.<br />
                                                            2. Corneal burns<br />
                                                            3. Retinal detachment or macular puckering that may require additional surgery<br />
                                                            4. Inflammation<br />
                                                            5. Pigmentary disturbances<br />
                                                            6. Bleeding in eye<br />
                                                            Local complications of anesthesia injections around the eye include:<br />
                                                            1. Perforation of eyeball<br />
                                                            2. Destruction of optic nerve<br />
                                                            3. Interference with circulation of retina<br />
                                                            4. Possible drooping of eyelid<br />
                                                            5. Respiratory depression<br />
                                                            6. Hypotension</p>
                                                        <h3> <span className="material-icons tmp_icon mt-0">arrow_right</span>Individual Risks</h3>
                                                        <p>I understand the following are possible significant risks and complications specific to my individual circumstances, that I have considered
                                                            in deciding to have this operation:</p>
                                                        <h3> <span className="material-icons tmp_icon mt-0">arrow_right</span>Individual Risks</h3>
                                                        <p>I acknowledge doctors from the ophthalmic team have informed me about the procedure, alternative treatments and answered my
                                                            specific queries and concerns about this matter.
                                                            I acknowledge that I have discussed with the surgical team any significant risks and complications specific to my individual circumstances
                                                            that I have considered in deciding to have this operation.
                                                            I understand that a doctor other than the specialist surgeon may perform the procedure</p>
                                                        <h3> <span className="material-icons tmp_icon mt-0">arrow_right</span>Declaration by Doctor</h3>
                                                        <p>I declare that I have explained the nature and consequences of the procedure to be performed, and discussed the risks that particularly
                                                            concern the patient.</p>
                                                        <div className='row signature_block'>
                                                            <div className='col-md-6'>
                                                                <h3>Doctor’s name</h3>
                                                                <h4>{profile && profile.firstname} {profile && profile.lastname}</h4>
                                                                {/* <img src={signature} /> */}
                                                                <div className="sigContainer">
                                                                    <SignaturePad canvasProps={{ className: "sigPad" }} ref={(ref) => { doctorsigPad = ref }} />
                                                                </div>
                                                                <button onClick={(e) => clear("doctor")}>
                                                                    Clear
                                                                </button>
                                                                <button onClick={(e) => trim("doctor")}>
                                                                    Trim
                                                                </button>

                                                            </div>
                                                            <div className='col-md-6'>
                                                                <h3> Patient’s name</h3>
                                                                <h4>{clinicPatient.firstname + " " + clinicPatient.lastname}</h4>

                                                                <div className="sigContainer">
                                                                    <SignaturePad canvasProps={{ className: "sigPad" }} ref={(ref) => { patientsigPad = ref }} />
                                                                </div>
                                                                <button onClick={(e) => clear("patient")}>
                                                                    Clear
                                                                </button>
                                                                <button onClick={(e) => trim("patient")}>
                                                                    Trim
                                                                </button>

                                                            </div>
                                                            <div className='buttons'>
                                                                <a onClick={(e) => exportPdf(e)} className='icon_link'><span className="material-icons">file_download</span>DOWNLOAD</a>
                                                                <a onClick={(e) => handlePrint(e)} className='icon_link'><span className="material-icons">print</span>PRINT</a>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className='popup_footer'>
                            <button className='btn dsp_flex'><span className="material-icons">north_east</span>SHARE </button>
                        </div>
                    </div>
                </div>
            }


            {visibleOutpatient &&
                <div className='popup_wrp'>
                    <div className="popup_full prescribe">
                        <div className='popup_hed'>
                            <h2>Outpatient Appointment Outcome</h2>
                            <a onClick={(e) => { setVisibleOutpatient(false) }} className='popup_close'><span className="material-icons">close</span></a>
                        </div>
                        <div className='popup_content'>
                            <div className='popup_scroll main pr'>
                                <div className='outcome_hed'>
                                    <div className='left'>
                                        <div className='date'>
                                            <strong>{time}, </strong>  <br />
                                            {formatDate}
                                        </div>
                                    </div>
                                    <div className='right'>
                                        <div className='name'>
                                            Tr & Orthopaedic  - OPD
                                            <h3>{clinicValues.clinic} -DRI</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className='out_patient_section out_patient_details ld mt-0 mb-0'>

                                    <PatientBlock patient={clinicPatient} />

                                </div>
                                <h2 className="popup_title">Type of Consultation</h2>

                                {clinicValues.outpatient_consult ? <div className="check_list">
                                    <div className="check_item" onClick={(e) => updateClinicValues(e, "outpatient_consult", false)} ><label className="check">{clinicValues.outpatient_consult}</label></div>

                                </div> : (clinicValues.outpatient_consult == undefined || clinicValues.outpatient_consult == false) &&
                                <div className="check_list">
                                    <div className="check_item" onClick={(e) => updateClinicValues(e, "outpatient_consult", "Consultation without a procedure (Y909)")} ><label className="check">Consultation without a procedure (Y909)</label></div>
                                    <div className="check_item" onClick={(e) => { updateClinicValues(e, "outpatient_consult", "Consultation with a procedure"), setvisibleProcedureCode(true) }}><label className="check">Consultation with a procedure </label></div>
                                    <div className="check_item" onClick={(e) => updateClinicValues(e, "outpatient_consult", "Multidisciplinary clinic with clinicians from the same speciality")}><label className="check">Multidisciplinary clinic with clinicians from the same speciality</label></div>
                                    <div className="check_item" onClick={(e) => updateClinicValues(e, "outpatient_consult", "Multi professional clinic with clinicians from the other speciality X622")}><label className="check">Multi professional clinic with clinicians from the other speciality X622</label></div>
                                    <div className="check_item" onClick={(e) => updateClinicValues(e, "outpatient_consult", "Multi-professional consultation (incl. Physio, dressing, plaster room etc X622)")}><label className="check">Multi-professional consultation (incl. Physio, dressing, plaster room etc X622)</label></div>
                                    <div className="check_item" onClick={(e) => updateClinicValues(e, "outpatient_consult", "Pre-assessment X621")}><label className="check">Pre-assessment X621</label></div>
                                    <div className="check_item" onClick={(e) => updateClinicValues(e, "outpatient_consult", "Defined as enhanced recovery patient at pre-assessment X628")}><label className="check">Defined as enhanced recovery patient at pre-assessment X628</label></div>
                                </div>}

                                {clinicValues.outpatient_consult == "Consultation with a procedure" &&
                                    <h2 className="popup_title mt-20">PROCEDURE <span className="bred_crumb">{clinicValues.outpatient_procedure}</span> <span className="bred_crumb">{clinicValues.outpatient_Z943} - {clinicValues.outpatient_middle} - {clinicValues.outpatient_performed}</span><br /></h2>}


                                <h2 className="popup_title2">Diagnostic Coding<span> (Disease Condition treated)</span></h2>

                                {clinicValues.outcome_diagnotic_coding && clinicValues.outcome_diagnotic_coding_list && !visibleSet.outcome_diagnotic &&
                                    <div className="leavel_1">
                                        <h2 className="popup_title mt-20">{clinicValues.outcome_diagnotic_coding.toUpperCase().replace(/_/g, " & ")} <span className="bred_crumb">{clinicValues.outcome_diagnotic_coding_list}</span>
                                            <a href="#" onClick={(e) => updateSetVisible(e, "outcome_diagnotic", true)}  ><span className="material-symbols-outlined"> reply
                                            </span></a></h2>
                                        <br /><br />
                                    </div>}


                                {visibleSet.outcome_diagnotic &&
                                    <div className="select_btns popup_select dc">
                                        <div className="select_btn"><label><input value="shoulder" onClick={(e) => updateInputValue(e)} type="radio" name="outcome_diagnotic_coding" checked={clinicValues.outcome_diagnotic_coding == "shoulder" ? "checked" : ""} /><span>SHOULDER</span></label></div>
                                        <div className="select_btn"><label><input value="elbow" onClick={(e) => updateInputValue(e)} type="radio" name="outcome_diagnotic_coding" checked={clinicValues.outcome_diagnotic_coding == "elbow" ? "checked" : ""} /><span>ELBOW</span></label></div>
                                        <div className="select_btn"><label><input value="hand_wrist" onClick={(e) => updateInputValue(e)} type="radio" name="outcome_diagnotic_coding" checked={clinicValues.outcome_diagnotic_coding == "hand_wrist" ? "checked" : ""} /><span>HAND & WRIST</span></label></div>
                                        <div className="select_btn"><label><input value="spine" onClick={(e) => updateInputValue(e)} type="radio" name="outcome_diagnotic_coding" checked={clinicValues.outcome_diagnotic_coding == "spine" ? "checked" : ""} /><span>SPINE</span></label></div>
                                        <div className="select_btn"><label><input value="hip" onClick={(e) => updateInputValue(e)} type="radio" name="outcome_diagnotic_coding" checked={clinicValues.outcome_diagnotic_coding == "hip" ? "checked" : ""} /><span>HIP</span></label></div>
                                        <div className="select_btn"><label><input value="knee" onClick={(e) => updateInputValue(e)} type="radio" name="outcome_diagnotic_coding" checked={clinicValues.outcome_diagnotic_coding == "knee" ? "checked" : ""} /><span>KNEE</span></label></div>
                                        <div className="select_btn"><label><input value="foot_ankle" onClick={(e) => updateInputValue(e)} type="radio" name="outcome_diagnotic_coding" checked={clinicValues.outcome_diagnotic_coding == "foot_ankle" ? "checked" : ""} /><span>FOOT & ANKLE</span></label></div>
                                        <div className="select_btn"><label><input value="general" onClick={(e) => updateInputValue(e)} type="radio" name="outcome_diagnotic_coding" checked={clinicValues.outcome_diagnotic_coding == "general" ? "checked" : ""} /><span>GENERAL</span></label></div>
                                    </div>}



                                {clinicValues.outcome_diagnotic_coding == "shoulder" && visibleSet.outcome_diagnotic &&
                                    <div className="leavel_1">

                                        <h2 className="popup_title mt-20">SHOULDER <a href="#"><span
                                            className="material-symbols-outlined"> reply </span></a></h2>
                                        <div className="check_list">
                                            <div className="check_item" onClick={(e) => { updateClinicValues(e, "outcome_diagnotic_coding_list", "Shoulder pain M2551"), updateSetVisible(e, "outcome_diagnotic", false) }} ><label className="check">Shoulder pain M2551</label></div>
                                            <div className="check_item" onClick={(e) => { updateClinicValues(e, "outcome_diagnotic_coding_list", "Non specific mononeuropathy upper limb G569"), updateSetVisible(e, "outcome_diagnotic", false) }}><label className="check">Non specific mononeuropathy upper limb G569</label></div>
                                            <div className="check_item" onClick={(e) => { updateClinicValues(e, "outcome_diagnotic_coding_list", "Shoulder injury"), updateSetVisible(e, "outcome_diagnotic", false) }}><label className="check">Shoulder injury</label></div>
                                            <div className="check_item" onClick={(e) => { updateClinicValues(e, "outcome_diagnotic_coding_list", "Instability of joint (all) M253"), updateSetVisible(e, "outcome_diagnotic", false) }}><label className="check">Instability of joint (all) M253</label>
                                            </div>
                                        </div>
                                    </div>}

                                {clinicValues.outcome_diagnotic_coding == "elbow" && visibleSet.outcome_diagnotic &&
                                    <div className="leavel_1">
                                        <h2 className="popup_title mt-20">ELBOW <a href="#"><span className="material-symbols-outlined">
                                            reply </span></a></h2>
                                        <div className="check_list">
                                            <div className="check_item" onClick={(e) => { updateClinicValues(e, "outcome_diagnotic_coding_list", "Elbow pain M2552"), updateSetVisible(e, "outcome_diagnotic", false) }}><label className="check">Elbow pain M2552</label></div>
                                            <div className="check_item" onClick={(e) => { updateClinicValues(e, "outcome_diagnotic_coding_list", "Instability of joint (all) M253"), updateSetVisible(e, "outcome_diagnotic", false) }}><label className="check">Instability of joint (all) M253</label>
                                            </div>
                                        </div>
                                    </div>
                                }

                                {clinicValues.outcome_diagnotic_coding == "hand_wrist" && visibleSet.outcome_diagnotic &&
                                    <div className="leavel_1">
                                        <h2 className="popup_title mt-20">HAND & WRIST <a href="#"><span
                                            className="material-symbols-outlined"> reply </span></a></h2>
                                        <div className="check_list">
                                            <div className="check_item" onClick={(e) => { updateClinicValues(e, "outcome_diagnotic_coding_list", "Wrist pain M2553"), updateSetVisible(e, "outcome_diagnotic", false) }}><label className="check">Wrist pain M2553</label></div>
                                            <div className="check_item" onClick={(e) => { updateClinicValues(e, "outcome_diagnotic_coding_list", "Instability of joint (all) M2539"), updateSetVisible(e, "outcome_diagnotic", false) }}><label className="check">Instability of joint (all) M2539</label></div>
                                            <div className="check_item" onClick={(e) => { updateClinicValues(e, "outcome_diagnotic_coding_list", "Carpal Tunnel Syndrome G560"), updateSetVisible(e, "outcome_diagnotic", false) }}><label className="check">Carpal Tunnel Syndrome G560</label>
                                            </div>
                                            <div className="check_item" onClick={(e) => { updateClinicValues(e, "outcome_diagnotic_coding_list", "Deformity of limb (including fingers and/or toes) M219"), updateSetVisible(e, "outcome_diagnotic", false) }}><label className="check">Deformity of limb (including fingers and/or toes) M219</label></div>
                                            <div className="check_item" onClick={(e) => { updateClinicValues(e, "outcome_diagnotic_coding_list", "Amputation of finger complete\partial S681"), updateSetVisible(e, "outcome_diagnotic", false) }}><label className="check">Amputation of finger complete\partial S681</label></div>
                                            <div className="check_item" onClick={(e) => { updateClinicValues(e, "outcome_diagnotic_coding_list", "Injury finger\nail S699<"), updateSetVisible(e, "outcome_diagnotic", false) }}><label className="check">Injury finger\nail S699</label></div>
                                        </div>
                                    </div>
                                }
                                {clinicValues.outcome_diagnotic_coding == "spine" && visibleSet.outcome_diagnotic &&
                                    <div className="leavel_1">
                                        <h2 className="popup_title mt-20">SPINE <a href="#"><span className="material-symbols-outlined">
                                            reply </span></a></h2>
                                        <div className="check_list">
                                            <div className="check_item" onClick={(e) => { updateClinicValues(e, "outcome_diagnotic_coding_list", "Neck pain M542"), updateSetVisible(e, "outcome_diagnotic", false) }}><label className="check">Neck pain M542</label></div>
                                            <div className="check_item" onClick={(e) => { updateClinicValues(e, "outcome_diagnotic_coding_list", "Lower back pain M545"), updateSetVisible(e, "outcome_diagnotic", false) }}><label className="check">Lower back pain M545</label></div>
                                            <div className="check_item" onClick={(e) => { updateClinicValues(e, "outcome_diagnotic_coding_list", "Disc prolapse M512"), updateSetVisible(e, "outcome_diagnotic", false) }}><label className="check">Disc prolapse M512</label></div>
                                            <div className="check_item" onClick={(e) => { updateClinicValues(e, "outcome_diagnotic_coding_list", "Fracture of vertebra\spine T08X"), updateSetVisible(e, "outcome_diagnotic", false) }}><label className="check">Fracture of vertebra\spine T08X</label>
                                            </div>
                                            <div className="check_item" onClick={(e) => { updateClinicValues(e, "outcome_diagnotic_coding_list", "Fracture of lumbar spine\pelvis S328"), updateSetVisible(e, "outcome_diagnotic", false) }}><label className="check">Fracture of lumbar spine\pelvis S328</label></div>

                                        </div>
                                    </div>
                                }
                                {clinicValues.outcome_diagnotic_coding == "hip" && visibleSet.outcome_diagnotic &&
                                    <div className="leavel_1">
                                        <h2 className="popup_title mt-20">HIP <a href="#"><span className="material-symbols-outlined">
                                            reply </span></a></h2>
                                        <div className="check_list">
                                            <div className="check_item" onClick={(e) => { updateClinicValues(e, "outcome_diagnotic_coding_list", "Hip pain M2555"), updateSetVisible(e, "outcome_diagnotic", false) }}><label className="check">Hip pain M2555</label></div>


                                        </div>
                                    </div>}
                                {clinicValues.outcome_diagnotic_coding == "knee" && visibleSet.outcome_diagnotic &&
                                    <div className="leavel_1">
                                        <h2 className="popup_title mt-20">KNEE <a href="#"><span className="material-symbols-outlined">
                                            reply </span></a></h2>
                                        <div className="check_list">
                                            <div className="check_item" onClick={(e) => { updateClinicValues(e, "outcome_diagnotic_coding_list", "Knee pain M2556"), updateSetVisible(e, "outcome_diagnotic", false) }}><label className="check">Knee pain M2556</label></div>
                                            <div className="check_item" onClick={(e) => { updateClinicValues(e, "outcome_diagnotic_coding_list", "Knee derangement / disorder of patella M23"), updateSetVisible(e, "outcome_diagnotic", false) }}><label className="check">Knee derangement / disorder of patella M239</label></div>
                                            <div className="check_item" onClick={(e) => { updateClinicValues(e, "outcome_diagnotic_coding_list", "Instability of joint (all) M253"), updateSetVisible(e, "outcome_diagnotic", false) }}><label className="check">Instability of joint (all) M253</label>
                                            </div>
                                            <div className="check_item" onClick={(e) => { updateClinicValues(e, "outcome_diagnotic_coding_list", "Injury knee S836"), updateSetVisible(e, "outcome_diagnotic", false) }}><label className="check">Injury knee S836</label>
                                            </div>


                                        </div>
                                    </div>}
                                {clinicValues.outcome_diagnotic_coding == "foot_ankle" && visibleSet.outcome_diagnotic &&
                                    <div className="leavel_1">
                                        <h2 className="popup_title mt-20">FOOT & ANKLE <a href="#"><span
                                            className="material-symbols-outlined"> reply </span></a></h2>
                                        <div className="check_list">
                                            <div className="check_item" onClick={(e) => { updateClinicValues(e, "outcome_diagnotic_coding_list", "Ankle pain M2557"), updateSetVisible(e, "outcome_diagnotic", false) }}><label className="check">Ankle pain M2557</label></div>
                                            <div className="check_item" onClick={(e) => { updateClinicValues(e, "outcome_diagnotic_coding_list", "Instability of joint (all) M253"), updateSetVisible(e, "outcome_diagnotic", false) }}><label className="check">Instability of joint (all) M253</label>
                                            </div>
                                            <div className="check_item" onClick={(e) => { updateClinicValues(e, "outcome_diagnotic_coding_list", "Hallux valgus (bunion) M201"), updateSetVisible(e, "outcome_diagnotic", false) }}><label className="check">Hallux valgus (bunion) M201</label>
                                            </div>
                                            <div className="check_item" onClick={(e) => { updateClinicValues(e, "outcome_diagnotic_coding_list", "Deformity of limb (including fingers and/or toes) M219"), updateSetVisible(e, "outcome_diagnotic", false) }}><label className="check">Deformity of limb (including fingers and/or toes) M219</label></div>


                                        </div>
                                    </div>}
                                {clinicValues.outcome_diagnotic_coding == "general" && visibleSet.outcome_diagnotic &&
                                    <div className="leavel_1">
                                        <h2 className="popup_title mt-20">GENERAL<a href="#"><span
                                            className="material-symbols-outlined"> reply </span></a></h2>
                                        <div className="check_list">
                                            <div className="check_item" onClick={(e) => { updateClinicValues(e, "outcome_diagnotic_coding_list", "Deformity of limb (including fingers and/or toes) M219"), updateSetVisible(e, "outcome_diagnotic", false) }}><label className="check">Deformity of limb (including fingers and/or toes) M219</label></div>
                                            <div className="check_item" onClick={(e) => { updateClinicValues(e, "outcome_diagnotic_coding_list", "Symptom/diagnosis other than listed above R298"), updateSetVisible(e, "outcome_diagnotic", false) }}><label className="check">Symptom/diagnosis other than listed above R298</label></div>
                                            <div className="check_item" onClick={(e) => { updateClinicValues(e, "outcome_diagnotic_coding_list", "Metastatic bone disease C795"), updateSetVisible(e, "outcome_diagnotic", false) }}><label className="check">Metastatic bone disease C795</label>
                                            </div>
                                            <div className="check_item" onClick={(e) => { updateClinicValues(e, "outcome_diagnotic_coding_list", "Bone stimulator Code in 1st position (PAS) M841"), updateSetVisible(e, "outcome_diagnotic", false) }}><label className="check">Bone stimulator Code in 1st position (PAS) M841</label></div>
                                            <div className="check_item" onClick={(e) => { updateClinicValues(e, "outcome_diagnotic_coding_list", "Cellulitis L039"), updateSetVisible(e, "outcome_diagnotic", false) }}><label className="check">Cellulitis L039 </label></div>
                                            <div className="check_item" onClick={(e) => { updateClinicValues(e, "outcome_diagnotic_coding_list", "Haematoma T140"), updateSetVisible(e, "outcome_diagnotic", false) }}><label className="check">Haematoma T140 </label></div>
                                        </div>
                                    </div>}


                                <h2 className="popup_title2">Out Come<span> (RTT Status)</span></h2>

                                <h2 className="popup_title mt-20">PATIENT ATTENDANCE</h2>
                                {clinicValues.outcome_patientattendance ? <div className="check_list">
                                    <div className="check_item" onClick={(e) => updateClinicValues(e, "outcome_patientattendance", false)} ><label className="check">{clinicValues.outcome_patientattendance}</label></div>

                                </div> : (clinicValues.outcome_patientattendance == undefined || clinicValues.outcome_patientattendance == false) &&

                                <div className="check_list">
                                    <div className="check_item" onClick={(e) => updateClinicValues(e, "outcome_patientattendance", "Attended Appointment")}><label className="check">Attended Appointment</label></div>
                                    <div className="check_item" onClick={(e) => updateClinicValues(e, "outcome_patientattendance", "DNA")}><label className="check">DNA</label></div>
                                    <div className="check_item" onClick={(e) => updateClinicValues(e, "outcome_patientattendance", "Rescheduled by patient")}><label className="check">Rescheduled by patient</label></div>
                                    <div className="check_item" onClick={(e) => updateClinicValues(e, "outcome_patientattendance", "Left before being seen")}><label className="check">Left before being seen</label></div>
                                </div>}

                                <h2 className="popup_title mt-20">OUTCOME</h2>

                                {visibleSet.outcome_discharged &&
                                    <div className="select_btns popup_select oc">
                                        <div className="select_btn"><label><input name="outcome_discharged" onClick={(e) => updateInputValue(e)} type="radio" value="discharge_or_patient_dna" checked={clinicValues.outcome_discharged == "discharge_or_patient_dna" ? "checked" : ""} /><span>DISCHARGE OR PATIENT DNA</span></label></div>
                                        <div className="select_btn"><label><input name="outcome_discharged" onClick={(e) => updateInputValue(e)} type="radio" value="for_follow-Up_/_ongoing" checked={clinicValues.outcome_discharged == "for_follow-Up_/_ongoing" ? "checked" : ""} /><span>FOR FOLLOW-UP / ONGOING</span></label></div>
                                        <div className="select_btn"><label><input name="outcome_discharged" onClick={(e) => updateInputValue(e)} type="radio" value="referral" checked={clinicValues.outcome_discharged == "referral" ? "checked" : ""} /><span>REFERRAL</span></label></div>

                                    </div>}

                                {clinicValues.outcome_discharged_dna && !visibleSet.outcome_discharged &&
                                    <div className="leavel_1">
                                        <h2 className="popup_title">{clinicValues.outcome_discharged.toUpperCase().replace(/_/g, " ")}  <a href="#" onClick={(e) => updateSetVisible(e, "outcome_discharged", true)} ><span
                                            className="material-symbols-outlined"> reply
                                        </span></a></h2>
                                        <div className="check_list column">
                                            <div className="check_item"><label className="check">{clinicValues.outcome_discharged_dna}</label><span className="check-column">{clinicValues.outcome_discharged_dna_value}</span></div>

                                        </div></div>}


                                {clinicValues.outcome_discharged == "discharge_or_patient_dna" && visibleSet.outcome_discharged &&
                                    <div className="leavel_1">
                                        <h2 className="popup_title">DISCHARGE OR PATIENT DNA <a href="#"><span
                                            className="material-symbols-outlined"> reply
                                        </span></a></h2>
                                        <div className="check_list column">
                                            <div className="check_item" onClick={(e) => { updateClinicValues(e, "outcome_discharged_dna", "Patient treated - First treatment given today and patient discharged"), updateClinicValues(e, "outcome_discharged_dna_value", "30"), updateSetVisible(e, "outcome_discharged", false) }}><label className="check">Patient treated - First treatment given today and patient discharged</label><span className="check-column">30</span>
                                            </div>
                                            <div className="check_item" onClick={(e) => { updateClinicValues(e, "outcome_discharged_dna", "Patient treated - Treatment previously given and patient discharged"), updateClinicValues(e, "outcome_discharged_dna_value", "90"), updateSetVisible(e, "outcome_discharged", false) }}><label className="check">Patient treated - Treatment previously given and patient discharged </label><span className="check-column">90</span>
                                            </div>
                                            <div className="check_item" onClick={(e) => { updateClinicValues(e, "outcome_discharged_dna", "Patient declined treatment"), updateClinicValues(e, "outcome_discharged_dna_value", "35"), updateSetVisible(e, "outcome_discharged", false) }}><label className="check">Patient declined treatment</label>
                                                <span className="check-column">35</span>
                                            </div>
                                            <div className="check_item" onClick={(e) => { updateClinicValues(e, "outcome_discharged_dna", "New Patient DNA: Discharged"), updateClinicValues(e, "outcome_discharged_dna_value", "33"), updateSetVisible(e, "outcome_discharged", false) }}><label className="check">New Patient DNA: Discharged</label><span className="check-column">33</span></div>
                                            <div className="check_item" onClick={(e) => { updateClinicValues(e, "outcome_discharged_dna", "New Patient DNA: Rescheduled"), setvisisbleArrangeFollowup(true), updateClinicValues(e, "outcome_discharged_dna_value", "33"), updateSetVisible(e, "outcome_discharged", false) }} ><label className="check">New Patient DNA: Rescheduled - Arrange Follow Up
                                            </label><span className="check-column">33</span></div>
                                            <div className="check_item" onClick={(e) => { updateClinicValues(e, "outcome_discharged_dna", "Follow-up patient DNA’d - Discharged"), updateClinicValues(e, "outcome_discharged_dna_value", "34"), updateSetVisible(e, "outcome_discharged", false) }}><label className="check">Follow-up patient DNA’d - Discharged
                                            </label><span className="check-column">34</span></div>

                                            <div className="check_item" onClick={(e) => { updateClinicValues(e, "outcome_discharged_dna", "Follow-up patient DNA’d - Rescheduled - Previously treated - Arrange Follow Up"), setvisisbleArrangeFollowup(true), updateClinicValues(e, "outcome_discharged_dna_value", "90"), updateSetVisible(e, "outcome_discharged", false) }} ><label className="check">Follow-up patient DNA’d - Rescheduled - Previously treated - Arrange Follow Up </label><span className="check-column">90</span></div>

                                            <div className="check_item" onClick={(e) => { updateClinicValues(e, "outcome_discharged_dna", "Follow up Patient DNA‘d – Rescheduled – NOT treated previously"), setvisisbleArrangeFollowup(true), updateClinicValues(e, "outcome_discharged_dna_value", "20"), updateSetVisible(e, "outcome_discharged", false) }}><label className="check">Follow up Patient DNA‘d – Rescheduled – NOT treated previously - Arrange Follow Up</label><span className="check-column">20</span></div>

                                            <div className="check_item" onClick={(e) => { updateClinicValues(e, "outcome_discharged_dna", "Clinical decision not to treat"), updateClinicValues(e, "outcome_discharged_dna_value", "34"), updateSetVisible(e, "outcome_discharged", false) }}><label className="check">Clinical decision not to treat</label><span className="check-column">34</span></div>
                                        </div>
                                    </div>}

                                {clinicValues.outcome_discharged == "for_follow-Up_/_ongoing" && visibleSet.outcome_discharged &&
                                    <div className="leavel_1">
                                        <h2 className="popup_title">FOR FOLLOW-UP / ONGOING <a href="#"><span
                                            className="material-symbols-outlined"> reply
                                        </span></a></h2>
                                        <div className="check_list column">
                                            <div className="check_item" onClick={(e) => { updateClinicValues(e, "outcome_discharged_dna", "Start clinician initiated Active monitoring"), updateClinicValues(e, "outcome_discharged_dna_value", "32"), updateSetVisible(e, "outcome_discharged", false) }} ><label className="check">Start clinician initiated Active monitoring</label><span className="check-column">32</span>
                                            </div>

                                            <div className="check_item" onClick={(e) => { updateClinicValues(e, "outcome_discharged_dna", "Start patient initiated Active monitoring"), updateClinicValues(e, "outcome_discharged_dna_value", "31"), updateSetVisible(e, "outcome_discharged", false) }}><label className="check">Start patient initiated Active monitoring </label><span className="check-column">31</span>
                                            </div>

                                            <div className="check_item" onClick={(e) => { updateClinicValues(e, "outcome_discharged_dna", "Active monitoring continues"), updateClinicValues(e, "outcome_discharged_dna_value", "91"), updateSetVisible(e, "outcome_discharged", false) }}><label className="check">Active monitoring continues</label>
                                                <span className="check-column">91</span>
                                            </div>

                                            <div className="check_item" onClick={(e) => { updateClinicValues(e, "outcome_discharged_dna", "Active monitoring continues"), updateClinicValues(e, "outcome_discharged_dna_value", "91"), updateSetVisible(e, "outcome_discharged", false), setvisisbleArrangeFollowup(true) }} ><label className="check">Patient received first treatment today and followup required - Arrange Follow Up</label><span className="check-column">30</span></div>

                                            <div className="check_item" onClick={(e) => { updateClinicValues(e, "outcome_discharged_dna", "Patient previously treated and further follow up required"), updateClinicValues(e, "outcome_discharged_dna_value", "90"), updateSetVisible(e, "outcome_discharged", false), setvisisbleArrangeFollowup(true) }}><label className="check">Patient previously treated and further follow up required - Arrange Follow Up
                                            </label><span className="check-column">90</span></div>

                                            <div className="check_item" onClick={(e) => { updateClinicValues(e, "outcome_discharged_dna", "No treatment given and follow-up required"), updateClinicValues(e, "outcome_discharged_dna_value", "20"), updateSetVisible(e, "outcome_discharged", false), setvisisbleArrangeFollowup(true) }}><label className="check">No treatment given and follow-up required - Arrange Follow Up
                                            </label><span className="check-column">20</span></div>


                                            <div className="check_item" onClick={(e) => { updateClinicValues(e, "outcome_discharged_dna", "Added to the waiting list for IP / DC treatment group"), updateClinicValues(e, "outcome_discharged_dna_value", "20"), updateSetVisible(e, "outcome_discharged", false) }} ><label className="check">Added to the waiting list for IP / DC treatment group </label><span className="check-column">20</span></div>

                                            <div className="check_item" onClick={(e) => { updateClinicValues(e, "outcome_discharged_dna", "Added to waiting list for diagnostic test"), updateClinicValues(e, "outcome_discharged_dna_value", "20"), updateSetVisible(e, "outcome_discharged", false) }} ><label className="check">Added to waiting list for diagnostic test  </label><span className="check-column">20</span></div>

                                            <div className="check_item" onClick={(e) => { updateClinicValues(e, "outcome_discharged_dna", "Decision to treat after a period of active monitoring"), updateClinicValues(e, "outcome_discharged_dna_value", "11"), updateSetVisible(e, "outcome_discharged", false) }}><label className="check">Decision to treat after a period of active monitoring</label><span className="check-column">11</span></div>

                                            <div className="check_item" onClick={(e) => { updateClinicValues(e, "outcome_discharged_dna", "Decision to end a period of active monitoring and add to waiting list"), updateClinicValues(e, "outcome_discharged_dna_value", "11"), updateSetVisible(e, "outcome_discharged", false) }}><label className="check">Decision to end a period of active monitoring and add to waiting list </label><span className="check-column">11</span></div>

                                        </div>
                                    </div>}


                                {clinicValues.outcome_discharged == "referral" && visibleSet.outcome_discharged &&
                                    <div className="leavel_1">
                                        <h2 className="popup_title">REFERRAL <a href="#"><span
                                            className="material-symbols-outlined"> reply
                                        </span></a></h2>
                                        <div className="check_list column">
                                            <div className="check_item" onClick={(e) => { updateClinicValues(e, "outcome_discharged_dna", "Refer internally for a new condition, No treatment given/required, discharge from your care"), updateClinicValues(e, "outcome_discharged_dna_value", "90"), updateSetVisible(e, "outcome_discharged", false) }}><label className="check">Refer internally for a new condition, No treatment given/required, discharge from your care</label><span className="check-column">90</span>
                                            </div>
                                            <div className="check_item" onClick={(e) => { updateClinicValues(e, "outcome_discharged_dna", "Refer internally for a new condition, previously treated and discharged from your care"), updateClinicValues(e, "outcome_discharged_dna_value", "34"), updateSetVisible(e, "outcome_discharged", false) }}><label className="check">Refer internally for a new condition, previously treated and discharged from your care </label><span className="check-column">34</span>
                                            </div>
                                            <div className="check_item" onClick={(e) => { updateClinicValues(e, "outcome_discharged_dna", "Refer internally for a new condition, previously treated and continues under your care"), updateClinicValues(e, "outcome_discharged_dna_value", "90"), updateSetVisible(e, "outcome_discharged", false), setvisisbleArrangeFollowup(true) }}><label className="check">Refer internally for a new condition, previously treated and continues under your care - Arrange Follow Up</label>
                                                <span className="check-column">90</span>
                                            </div>
                                            <div className="check_item" onClick={(e) => { updateClinicValues(e, "outcome_discharged_dna", "Refer internally for a new condition, No treatment given/required and continue under your care"), updateClinicValues(e, "outcome_discharged_dna_value", "20"), updateSetVisible(e, "outcome_discharged", false), setvisisbleArrangeFollowup(true) }}><label className="check">Refer internally for a new condition, No treatment given/required and continue under your care - Arrange Follow Up</label><span className="check-column">20</span></div>
                                            <div className="check_item" onClick={(e) => { updateClinicValues(e, "outcome_discharged_dna", "Transfer to another trust/provider for the same condition"), updateClinicValues(e, "outcome_discharged_dna_value", "21"), updateSetVisible(e, "outcome_discharged", false) }}><label className="check">Transfer to another trust/provider for the same condition
                                            </label><span className="check-column">21</span></div>

                                        </div>
                                    </div>}


                                {clinicValues.arrangeFollowUp &&
                                    <div className="arrange_block">
                                        <h2 className="popup_title mb-30">ARRANGE FOLLOW-UP APPOINTMENT</h2>
                                        <div className="arrange_form">
                                            <div className="arrange_left">
                                                <h3>Appointment in</h3>
                                            </div>
                                            <div className="arrange_right">
                                                <p>{clinicValues.oc_arrange_duration ? clinicValues.oc_arrange_duration + " Days" : ""}</p>
                                            </div>
                                        </div>
                                        <div className="arrange_form">
                                            <div className="arrange_left">
                                                <h3>Estimated date for next appointment</h3>
                                            </div>
                                            <div className="arrange_right">
                                                <p>{clinicValues.oc_arrange_estimated}</p>
                                            </div>
                                        </div>
                                        <div className="arrange_form">
                                            <div className="arrange_left">
                                                <h3>Time tolerance</h3>
                                            </div>
                                            <div className="arrange_right">
                                                <p>{clinicValues.oc_arrange_timetolerance}</p>

                                            </div>
                                        </div>
                                        <div className="arrange_form">
                                            <div className="arrange_left">
                                                <h3>Requested Date</h3>
                                            </div>
                                            <div className="arrange_right">
                                                <p>{clinicValues.oc_arrange_requested_date && dateFormat(clinicValues.oc_arrange_requested_date)}</p>

                                            </div>
                                        </div>
                                        <div className="arrange_form">
                                            <div className="arrange_left">
                                                <h3>Appointment to be</h3>
                                            </div>
                                            <div className="arrange_right">
                                                <p>{clinicValues.oc_arrange_appointmenttobe}</p>

                                            </div>
                                        </div>
                                        <div className="arrange_form">
                                            <div className="arrange_left">
                                                <h3>To see</h3>
                                            </div>
                                            <div className="arrange_right">
                                                <p>{clinicValues.oc_arrange_tosee}</p>

                                            </div>
                                        </div>
                                        <div className="arrange_form">
                                            <div className="arrange_left">
                                                <h3>Appointment Duration</h3>
                                            </div>
                                            <div className="arrange_right">
                                                <p>{clinicValues.oc_arrange_appointment_duration}</p>

                                            </div>
                                        </div>
                                        <div className="arrange_form">
                                            <div className="arrange_left">
                                                <h3>Reason for re-appointment</h3>
                                            </div>
                                            <div className="arrange_right">
                                                <p>{clinicValues.oc_arrange_re_appointment}</p>


                                            </div>
                                        </div>
                                        <div className="arrange_form">
                                            <div className="arrange_left">
                                                <h3>Clinic Name</h3>
                                            </div>
                                            <div className="arrange_right">
                                                <p>{clinicValues.oc_arrange_clinic}</p>
                                            </div>
                                        </div>

                                        <p className="ps_2">{clinicValues.oc_arrange_assesment} <span className="ps_1">{clinicValues.oc_arrange_language_transport}</span></p>

                                        <p className="ps_2">Pre-consultation services needed<span className="ps_1">{clinicValues.oc_arrange_preconsult}</span></p>

                                        <p className="ps_2">Comments<span className="ps_1">{clinicValues.oc_arrange_comments}</span></p>


                                    </div>}



                                {/* <h2 className="popup_title mt-30">ADMIN TEAM USE ONLY</h2>
                                <div className="popup_section">

                                    <div className='switch_block'>
                                        Current RTT Status
                                    </div>
                                    <div className='switch_block'>
                                        RTT Breach Date
                                    </div>
                                    <div className='switch_block'>
                                        Flag a DQ issue
                                        <label className="switch">

                                            <input type="checkbox" />
                                            <span className="slider round"></span>
                                        </label></div>
                                </div> */}


                            </div>
                        </div>
                        <div className='popup_footer'>
                            <div className="prescribe_footer">
                                <div className='left_btns'>
                                    <button className="border_btn" onClick={(e) => { updateClinic(e), setVisibleOutpatient(false) }} ><span className="material-icons">save</span>SAVE </button>
                                    {/* <a className="icon_btn"> <span className="material-icons">done</span> COMPLETE</a> */}
                                </div>
                                <button onClick={(e) => { setVisibleOutpatient(false) }} className="btn dsp_flex"><span className="material-icons"><span className="material-icons">close</span></span>DISCARD </button></div>
                        </div>
                    </div>
                </div>
            }

            {visisblePlasterRef &&
                <div className='popup_wrp'>
                    <div className="popup_full prescribe">
                        <div className='popup_hed'>
                            <h2>PLASTER REFERRAL</h2>
                            <a onClick={(e) => { setVisiblePlasterRef(false) }} className='popup_close'><span className="material-icons">close</span></a>
                        </div>
                        <div className='popup_content'>

                            <div className='popup_scroll main pr'>
                                <div className='out_patient_section out_patient_details ld mt-0 mb-0'>

                                    <PatientBlock patient={clinicPatient} />

                                </div>
                                <h2 className="popup_title" >DIAGNOSIS / INJURY: </h2>

                                {clinicValues.diagonisInjury ? <div className="check_list">
                                    <div className="check_item" onClick={(e) => updateClinicValues(e, "diagonisInjury", false)} ><label className="check">{clinicValues.diagonisInjury}</label></div>

                                </div> : (clinicValues.diagonisInjury == undefined || clinicValues.diagonisInjury == false) &&
                                <div className="check_list">
                                    <div className="check_item" onClick={(e) => updateClinicValues(e, "diagonisInjury", "Undisplaced Distal Radius Fracture")} ><label className="check">Undisplaced Distal Radius Fracture</label></div>
                                    <div className="check_item" onClick={(e) => updateClinicValues(e, "diagonisInjury", "Clinical Scaphoid Fracture (normal x-rays)")}><label className="check">Clinical Scaphoid Fracture (normal x-rays)</label></div>
                                    <div className="check_item" onClick={(e) => updateClinicValues(e, "diagonisInjury", "Isolated Lateral Malleolus Fracture")}><label className="check">Isolated Lateral Malleolus Fracture</label></div>
                                    <div className="check_item" onClick={(e) => updateClinicValues(e, "diagonisInjury", "Base of the 5th Metatarsal Fracture")}><label className="check">Base of the 5th Metatarsal Fracture</label></div>
                                    <div className="check_item" onClick={(e) => updateClinicValues(e, "diagonisInjury", "Undisplaced Metatarsal Fracture")}><label className="check">Undisplaced Metatarsal Fracture</label></div>
                                    <div className="check_item" onClick={(e) => updateClinicValues(e, "diagonisInjury", "Other")}><label className="check">Other</label></div>
                                </div>}



                                <h2 className="popup_title">TREATMENT REQUIRED:</h2>

                                {clinicValues.treatmentRequired ?
                                    <div className="check_list">
                                        <div className="check_item" onClick={(e) => updateClinicValues(e, "treatmentRequired", false)} ><label className="check">{clinicValues.treatmentRequired}</label>

                                        </div>

                                    </div>
                                    : (clinicValues.treatmentRequired == undefined || clinicValues.treatmentRequired == false) &&

                                    <div className="check_list">
                                        <div className="check_item" onClick={(e) => updateClinicValues(e, "treatmentRequired", "Splinting/Casting")} ><label className="check">Splinting/Casting</label></div>
                                        <div className="check_item" ><label className="check">Wrist Splint</label>
                                            <div className="select_btns popup_select">
                                                <div className="select_btn" onClick={(e) => updateClinicValues(e, "treatmentRequired", "Wrist Splint - Routine")} ><label><input name="WristSplint" type="radio" value="Routine" /><span>Routine</span></label></div>
                                                <div className="select_btn" onClick={(e) => updateClinicValues(e, "treatmentRequired", "Wrist Splint - Urgent")} ><label><input name="WristSplint" type="radio" value="Urgent" /><span>Urgent</span></label></div>
                                                <div className="select_btn" onClick={(e) => updateClinicValues(e, "treatmentRequired", "Wrist Splint - 2 week rule")} ><label><input name="WristSplint" type="radio" value="2 week rule" /><span>2 week rule</span></label></div>
                                            </div>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateClinicValues(e, "treatmentRequired", "Splinting/Casting")} ><label className="check">Thumb Spica</label></div>
                                        <div className="check_item" onClick={(e) => updateClinicValues(e, "treatmentRequired", "Metacarpal")} ><label className="check">Metacarpal</label></div>
                                        <div className="check_item" onClick={(e) => updateClinicValues(e, "treatmentRequired", "Air-stirrup Ankle Brace")} ><label className="check">Air-stirrup Ankle Brace</label></div>
                                        <div className="check_item" onClick={(e) => updateClinicValues(e, "treatmentRequired", "Low Profile Air Foam Walker")}><label className="check">Low Profile Air Foam Walker</label></div>
                                        <div className="check_item" onClick={(e) => updateClinicValues(e, "treatmentRequired", "Non-weight Bearing Posterior Slab + Crutches")}><label className="check">Non-weight Bearing Posterior Slab + Crutches</label></div>
                                        <div className="check_item" onClick={(e) => updateClinicValues(e, "treatmentRequired", "Below Knee Walking Cast - Scaphoid Cast -Other")}><label className="check">Below Knee Walking Cast - Scaphoid Cast -Other</label></div>

                                    </div>}



                                <h2 className="popup_title2">COMPLETE VTE RISK ASSESSMENT <span> (For Lower limb plaster cast only)</span></h2>

                                <h2 className="popup_title">FOLLOW UP CARE:</h2>
                                <div className="select_btns popup_select">
                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="plasterReferFollowup" type="radio" value="Next apt" checked={clinicValues.plasterReferFollowup == "Next apt" ? "checked" : ""} /><span>Next apt</span></label></div>
                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="plasterReferFollowup" type="radio" value="Family Doctor" checked={clinicValues.plasterReferFollowup == "Family Doctor" ? "checked" : ""} /><span>Family Doctor</span></label></div>
                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="plasterReferFollowup" type="radio" value="Fracture Clinic" checked={clinicValues.plasterReferFollowup == "Fracture Clinic" ? "checked" : ""} /><span>Fracture Clinic</span></label></div>
                                </div>

                                <div className="prb_search">
                                    <textarea placeholder='Comments' name="plasterrefComments" onChange={(e) => updateInputValue(e)} value={clinicValues.plasterrefComments} ></textarea>
                                </div>
                                <h2 className="popup_title">PLASTER RECORD</h2>

                                {clinicValues.plasterecord ?
                                    <div className="check_list">
                                        <div className="check_item" onClick={(e) => updateClinicValues(e, "plasterecord", false)} ><label className="check">{clinicValues.plasterecord}</label>
                                        </div>

                                    </div>
                                    : (clinicValues.plasterecord == undefined || clinicValues.plasterecord == false) &&
                                    <div className="check_list">
                                        <div className="check_item" onClick={(e) => updateClinicValues(e, "plasterecord", "Undisplaced Distal Radius Fracture")}><label className="check">Undisplaced Distal Radius Fracture</label></div>
                                        <div className="check_item" onClick={(e) => updateClinicValues(e, "plasterecord", "Clinical Scaphoid Fracture (normal x-rays)")}><label className="check">Clinical Scaphoid Fracture (normal x-rays)</label></div>
                                        <div className="check_item" onClick={(e) => updateClinicValues(e, "plasterecord", "Isolated Lateral Malleolus Fracture")}><label className="check">Isolated Lateral Malleolus Fracture</label></div>
                                        <div className="check_item" onClick={(e) => updateClinicValues(e, "plasterecord", "Base of the 5th Metatarsal Fracture")}><label className="check">Base of the 5th Metatarsal Fracture</label></div>
                                        <div className="check_item" onClick={(e) => updateClinicValues(e, "plasterecord", "Undisplaced Metatarsal Fracture")} ><label className="check">Undisplaced Metatarsal Fracture</label></div>
                                        <div className="check_item" onClick={(e) => updateClinicValues(e, "plasterecord", "Other")} ><label className="check">Other</label></div>
                                    </div>}


                            </div>
                        </div>
                        <div className='popup_footer'>
                            <div className="prescribe_footer"><button className="border_btn" onClick={(e) => { updateClinic(e), referralsSave("plasterReferral"), setVisiblePlasterRef(false) }} ><span className="material-icons">save</span>SAVE </button><button className="btn dsp_flex" onClick={(e) => { setVisiblePlasterRef(false) }} ><span className="material-icons"><span className="material-icons">close</span></span>DISCARD </button></div>
                        </div>
                    </div>
                </div>
            }

            {visisbleSpecialityRef &&
                <div className='popup_wrp'>
                    <div className="popup_full prescribe">
                        <div className='popup_hed'>
                            <h2>SPECIALIST REFERRAL</h2>
                            <a onClick={(e) => setVisibleSpecialityRef(false)} className='popup_close'><span className="material-icons">close</span></a>
                        </div>
                        <div className='popup_content'>

                            <div className='popup_scroll main'>
                                <div className='out_patient_section out_patient_details ld mt-0 mb-0'>
                                    <PatientBlock patient={clinicPatient} />
                                </div>
                                <h2 className="popup_title">REFERRAL</h2>
                                <div className="select_btns popup_select">
                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="specialist_Ref" type="radio" value="Routine" checked={clinicValues.specialist_Ref == "Routine" ? "checked" : ""} /><span>Routine</span></label></div>
                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="specialist_Ref" type="radio" value="Urgent" checked={clinicValues.specialist_Ref == "Urgent" ? "checked" : ""} /><span>Urgent</span></label></div>
                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="specialist_Ref" type="radio" value="2 week rule" checked={clinicValues.specialist_Ref == "2 week rule" ? "checked" : ""} /><span>2 week rule</span></label></div>
                                </div>
                                <h2 className="popup_title">Standard outbound Referral:</h2>
                                <div className="select_btns popup_select">
                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="specailist_standard" type="radio" value="Routine" checked={clinicValues.specailist_standard == "Routine" ? "checked" : ""} /><span>Routine</span></label></div>
                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="specailist_standard" type="radio" value="Urgent" checked={clinicValues.specailist_standard == "Urgent" ? "checked" : ""} /><span>Urgent</span></label></div>
                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="specailist_standard" type="radio" value="2 week rule" checked={clinicValues.specailist_standard == "2 week rule" ? "checked" : ""} /><span>2 week rule</span></label></div>
                                </div>
                                <h2 className="popup_title">Inbound Referral:</h2>
                                <div className="select_btns popup_select">
                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="specailist_inbound" type="radio" value="Routine" checked={clinicValues.specailist_inbound == "Routine" ? "checked" : ""} /><span>Routine</span></label></div>
                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="specailist_inbound" type="radio" value="Urgent" checked={clinicValues.specailist_inbound == "Urgent" ? "checked" : ""} /><span>Urgent</span></label></div>
                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="specailist_inbound" type="radio" value="2 week rule" checked={clinicValues.specailist_inbound == "2 week rule" ? "checked" : ""} /><span>2 week rule</span></label></div>
                                </div>
                            </div>
                        </div>
                        <div className='popup_footer'>
                            <div className="prescribe_footer"><button className="border_btn" onClick={(e) => { updateClinic(e), referralsSave("specialistReferral"), setVisibleSpecialityRef(false) }}  ><span className="material-icons">save</span>SAVE </button><button className="btn dsp_flex" onClick={(e) => setVisibleSpecialityRef(false)}><span className="material-icons"><span className="material-icons">close</span></span>DISCARD </button></div>
                        </div>
                    </div>
                </div>
            }

            {visisbleSpeechtotext &&
                <div className='popup_wrp'>
                    <div className="popup_full prescribe">
                        <div className='popup_hed'>
                            <h2>Speech to Text</h2>
                            <a onClick={(e) => { setVisibleSpeechtotext(false) }} className='popup_close'><span className="material-icons">close</span></a>
                        </div>
                        <div className='popup_content'>

                            <div className='popup_scroll'>
                                <div className='speech_block'>
                                    <div className='icon_blk'>
                                        <img src={speech} />

                                    </div>
                                    <div className='speeck_text'>


                                        <p id="transcribeResultText">{transcribeText}{transcript}</p>
                                    </div>
                                    <p>Microphone: <strong>{listening ? 'On' : 'Off'}</strong></p>
                                </div>
                                <div className='speeck_btns'>
                                    <div className='btn_left'>    <button className='btn sp_start' onClick={(e) => SpeechRecognition.startListening({ continuous: true })}><span className="material-icons"> radio_button_checked </span>Start</button>
                                        <button className='btn' onClick={(e) => SpeechRecognition.stopListening(e)}><span className="material-icons"> pause_presentation </span>Stop</button>
                                        <button className='btn' onClick={(e) => resetTranscript(e)}><span className="material-icons"> replay</span>Reset</button></div>
                                    <div className='btn_right'>  <button className='btn' type="text" id="myTextdelete" onClick={(e) => deleteSelect(e)}><span className="material-symbols-outlined"> delete </span>CLEAR TEXT</button></div>
                                </div>
                            </div>
                        </div>
                        <div className='popup_footer'>
                            <div className="prescribe_footer">
                                <button className="border_btn"><span className="material-icons">save</span>SAVE </button>
                                <button className="btn dsp_flex" onClick={(e) => { setVisibleSpeechtotext(false) }} ><span className="material-icons"><span className="material-icons">close</span></span>DISCARD </button></div>
                        </div>
                    </div>
                </div>
            }
            {visibleDictate &&
                <div className='popup_wrp'>
                    <div className="popup_full prescribe">
                        <div className='popup_hed'>
                            <h2>Dictate</h2>
                            <a onClick={(e) => { setVisibleDictate(false) }} className='popup_close'><span className="material-icons">close</span></a>
                        </div>
                        <div className='popup_content'>

                            <div className='popup_scroll'>
                                <AudioWaveform />
                            </div>
                        </div>
                    </div>
                </div>
            }

            {visibleProcedureCode &&
                <div className='popup_wrp'>
                    <div className="popup_full prescribe white_bg">
                        <div className='popup_hed'>
                            <h2>Procedure Code</h2>
                            <a onClick={(e) => setvisibleProcedureCode(false)} className='popup_close'><span className="material-icons">close</span></a>
                        </div>
                        <div className='popup_content'>
                            <div className='popup_scroll main pr'>

                                <div className="check_list">
                                    <div className="check_item" onClick={(e) => { updateClinicValues(e, "outpatient_procedure", "Aspiration of joint W901"), setvisibleProcedureCodeSecond(true) }}><label className="check">Aspiration of joint W901</label></div>
                                    <div className="check_item" onClick={(e) => { updateClinicValues(e, "outpatient_procedure", "Injection of therapeutic substance into joint W903"), setvisibleProcedureCodeSecond(true) }}><label className="check">Injection of therapeutic substance into joint W903 </label></div>
                                    <div className="check_item" onClick={(e) => { updateClinicValues(e, "outpatient_procedure", "Injection of therapeutic substance into soft tissue X309"), setvisibleProcedureCodeSecond(true) }}><label className="check">Injection of therapeutic substance into soft tissue X309</label></div>

                                    <div className="check_item" onClick={(e) => { updateClinicValues(e, "outpatient_procedure", "Adjustment to external fixation of bone W302"), setvisibleProcedureCodeSecond(true) }}><label className="check">Adjustment to external fixation of bone W302</label></div>

                                    <div className="check_item" onClick={(e) => { updateClinicValues(e, "outpatient_procedure", "Manipulation of fracture W262"), setvisibleProcedureCodeSecond(true) }}><label className="check">Manipulation of fracture W262</label></div>

                                    <div className="check_item" onClick={(e) => { updateClinicValues(e, "outpatient_procedure", "Re manipulation of fracture W264"), setvisibleProcedureCodeSecond(true) }}><label className="check">Re manipulation of fracture W264</label></div>

                                    <div className="check_item" onClick={(e) => { updateClinicValues(e, "outpatient_procedure", "Removal of wires W283"), setvisibleProcedureCodeSecond(true) }}><label className="check">Removal of wires W283</label></div>

                                    <div className="check_item" onClick={(e) => { updateClinicValues(e, "outpatient_procedure", "Ponsetti manipulation T701"), setvisibleProcedureCodeSecond(true) }}><label className="check">Ponsetti manipulation T701</label></div>

                                    <div className="check_item" onClick={(e) => { updateClinicValues(e, "outpatient_procedure", "Removal of clip from skin S432"), setvisibleProcedureCodeSecond(true) }}><label className="check">Removal of clip from skin S432</label></div>

                                    <div className="check_item" onClick={(e) => { updateClinicValues(e, "outpatient_procedure", "Removal of suture from skin S434"), setvisibleProcedureCodeSecond(true) }}><label className="check">Removal of suture from skin S434</label></div>

                                    <div className="check_item" onClick={(e) => { updateClinicValues(e, "outpatient_procedure", "Application of splint X491"), setvisibleProcedureCodeSecond(true) }}><label className="check">Application of splint X491</label></div>

                                    <div className="check_item" onClick={(e) => { updateClinicValues(e, "outpatient_procedure", "Change of splint X492"), setvisibleProcedureCodeSecond(true) }}><label className="check">Change of splint X492</label></div>

                                    <div className="check_item" onClick={(e) => { updateClinicValues(e, "outpatient_procedure", "Removal of splint X493"), setvisibleProcedureCodeSecond(true) }}><label className="check">Removal of splint X493</label></div>

                                    <div className="check_item" onClick={(e) => { updateClinicValues(e, "outpatient_procedure", "Application of elastic support bandage X496"), setvisibleProcedureCodeSecond(true) }}><label className="check">Application of elastic support bandage X496</label></div>

                                    <div className="check_item" onClick={(e) => { updateClinicValues(e, "outpatient_procedure", "Application of gauze support bandage X497"), setvisibleProcedureCodeSecond(true) }}><label className="check">Application of gauze support bandage X497</label></div>

                                    <div className="check_item" onClick={(e) => { updateClinicValues(e, "outpatient_procedure", "Application of plaster cast X481"), setvisibleProcedureCodeSecond(true) }}><label className="check">Application of plaster cast X481</label></div>

                                    <div className="check_item" onClick={(e) => { updateClinicValues(e, "outpatient_procedure", "Change of plaster cast X482"), setvisibleProcedureCodeSecond(true) }}><label className="check">Change of plaster cast X482</label></div>

                                    <div className="check_item" onClick={(e) => { updateClinicValues(e, "outpatient_procedure", "Removal of plaster cast X483"), setvisibleProcedureCodeSecond(true) }}><label className="check">Removal of plaster cast X483</label></div>

                                    <div className="check_item" onClick={(e) => { updateClinicValues(e, "outpatient_procedure", "Attention to plaster cast X488"), setvisibleProcedureCodeSecond(true) }}><label className="check">Attention to plaster cast X488</label></div>

                                    <div className="check_item" onClick={(e) => { updateClinicValues(e, "outpatient_procedure", "Removal of slough from skin S572"), setvisibleProcedureCodeSecond(true) }}><label className="check">Removal of slough from skin S572</label></div>

                                    <div className="check_item" onClick={(e) => { updateClinicValues(e, "outpatient_procedure", "Wound cleansing S576"), setvisibleProcedureCodeSecond(true) }}><label className="check">Wound cleansing S576</label></div>

                                    <div className="check_item" onClick={(e) => { updateClinicValues(e, "outpatient_procedure", "Negative pressure wound therapy S577"), setvisibleProcedureCodeSecond(true) }}><label className="check">Negative pressure wound therapy S577</label></div>

                                    <div className="check_item" onClick={(e) => { updateClinicValues(e, "outpatient_procedure", "Dressing of skin (including compression bandage) S574"), setvisibleProcedureCodeSecond(true) }}><label className="check">Dressing of skin (including compression bandage) S574</label></div>
                                </div>

                            </div>
                        </div>
                        <div className='popup_footer'>
                            <div className="prescribe_footer">
                                <div className='left_btns'>


                                </div>
                                <button onClick={(e) => setvisibleProcedureCode(false)} className="btn dsp_flex"><span className="material-icons"><span className="material-icons">close</span></span>DISCARD </button></div>
                        </div>
                    </div>
                </div>
            }

            {visibleProcedureCodeSecond &&
                <div className='popup_wrp'>
                    <div className="popup_full prescribe white_bg">
                        <div className='popup_hed'>
                            <h2>Procedure Code</h2>
                            <a onClick={(e) => setvisibleProcedureCodeSecond(false)} className='popup_close'><span className="material-icons">close</span></a>
                        </div>
                        <div className='popup_content'>
                            <div className='popup_scroll'>
                                <div className='pc_hed'>
                                    <h4>{clinicValues.outpatient_procedure} <span className="material-icons">turn_left</span> </h4>
                                </div>
                                <div className='pc_body main'>
                                    <div className='col1'>
                                        <div className="select_btn">
                                            <label><input name="outpatient_Z943" onChange={(e) => updateInputValue(e)} type="radio" value="Z943 Left" /><span>Z943 Left</span></label>
                                        </div>
                                        <div className="select_btn">
                                            <label><input name="outpatient_Z943" onChange={(e) => updateInputValue(e)} type="radio" value="Z943 Right" /><span>Z943 Right</span></label>
                                        </div>
                                        <div className="select_btn">
                                            <label><input name="outpatient_Z943" onChange={(e) => updateInputValue(e)} type="radio" value="Z943 Bilateral" /><span>Z943 Bilateral</span></label>
                                        </div>

                                    </div>
                                    <div className='col2'>
                                        <div className="select_btn">
                                            <label><input name="outpatient_middle" onChange={(e) => updateInputValue(e)} type="radio" value="Shoulder Z891" /><span>Shoulder Z891</span></label>
                                        </div>
                                        <div className="select_btn">
                                            <label><input name="outpatient_middle" onChange={(e) => updateInputValue(e)} type="radio" value="Upper arm" /><span>Upper arm Z892</span></label>
                                        </div>
                                        <div className="select_btn">
                                            <label><input name="outpatient_middle" onChange={(e) => updateInputValue(e)} type="radio" value="Elbow Z815" /><span>Elbow Z815</span></label>
                                        </div>
                                        <div className="select_btn">
                                            <label><input name="outpatient_middle" onChange={(e) => updateInputValue(e)} type="radio" value="Forearm Z893" /><span>Forearm Z893</span></label>
                                        </div>
                                        <div className="select_btn">
                                            <label><input name="outpatient_middle" onChange={(e) => updateInputValue(e)} type="radio" value="Wrist Z828" /><span>Wrist Z828</span></label>
                                        </div>
                                        <div className="select_btn">
                                            <label><input name="outpatient_middle" onChange={(e) => updateInputValue(e)} type="radio" value="Hand Z894" /><span>Hand Z894</span></label>
                                        </div>
                                        <div className="select_btn">
                                            <label><input name="outpatient_middle" onChange={(e) => updateInputValue(e)} type="radio" value="Finger Z896" /><span>Finger Z896</span></label>
                                        </div>
                                        <div className="select_btn">
                                            <label><input name="outpatient_middle" onChange={(e) => updateInputValue(e)} type="radio" value="Multiple digits of hand Z897" /><span>Multiple digits of hand Z897 </span></label>
                                        </div>
                                        <div className="select_btn">
                                            <label><input name="outpatient_middle" onChange={(e) => updateInputValue(e)} type="radio" value="Thumb Z895" /><span>Thumb Z895</span></label>
                                        </div>
                                    </div>
                                    <div className='col3'>
                                        <div className='pc_search'>
                                            <h3>Performed By</h3>
                                            <div className='pc_field'>
                                                <input type="text" name="outpatient_performed" onChange={(e) => updateInputValue(e)} />
                                                {/* <button type='button' className='btn'>
                                                    <span className="material-icons">search</span>
                                                </button> */}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className='pc_btn' onClick={(e) => { updateClinic(e), setvisibleProcedureCode(false), setvisibleProcedureCodeSecond(false) }}><a className="icon_btn"> <span className="material-icons">done</span> Add</a></div>
                            </div>
                        </div>

                    </div>
                </div>
            }


            {visibleClinicalTherapy &&
                <div className='popup_wrp'>
                    <div className="popup_full prescribe">
                        <div className='popup_hed'>
                            <h2>CLINICAL THERAPY REFERRAL</h2>
                            <a onClick={(e) => setvisibleClinicalTherapy(false)} className='popup_close'><span className="material-icons">close</span></a>
                        </div>
                        <div className='popup_content'>

                            <div className='popup_scroll main pr'>
                                <div className='out_patient_section out_patient_details ld mt-0 mb-0'>
                                    <PatientBlock patient={clinicPatient} />
                                </div>
                                <div className="simple_wound ctr">
                                    <div className="check_list mb-10">
                                        <div className="check_item"><label className="check">Interpreter required</label>
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ct_interpreter" type="radio"
                                                    value="Yes" checked={clinicValues.ct_interpreter == "Yes" ? "checked" : ""} /><span>Yes</span></label>
                                                </div>
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ct_interpreter" type="radio"
                                                    value="No" checked={clinicValues.ct_interpreter == "No" ? "checked" : ""} /><span>No</span></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="check_list mb-10">
                                        <div className="check_item"><label className="check">Language:</label>
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ct_language" type="radio"
                                                    value="Yes" checked={clinicValues.ct_interpreter == "Yes" ? "checked" : ""} /><span>Yes</span></label>
                                                </div>
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ct_language" type="radio"
                                                    value="No" checked={clinicValues.ct_interpreter == "No" ? "checked" : ""} /><span>No</span></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="check_list mb-10">
                                        <div className="check_item"><label className="check">Ambulance required?</label>
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ct_ambulance" type="radio"
                                                    value="Yes" checked={clinicValues.ct_ambulance == "Yes" ? "checked" : ""} /><span>Yes</span></label>
                                                </div>
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ct_ambulance" type="radio"
                                                    value="No" checked={clinicValues.ct_ambulance == "No" ? "checked" : ""} /><span>No</span></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="check_list mb-0">
                                        <div className="check_item"><label className="check">Urgent</label>
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ct_urgent" type="radio"
                                                    value="Yes" checked={clinicValues.ct_urgent == "Yes" ? "checked" : ""} /><span>Yes</span></label>
                                                </div>
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ct_urgent" type="radio"
                                                    value="No" checked={clinicValues.ct_urgent == "No" ? "checked" : ""} /><span>No</span></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <h2 className="popup_title mt-20">Referral to</h2>

                                {clinicValues.ct_referral ? <div className="check_list">
                                    <div className="check_item" onClick={(e) => updateClinicValues(e, "ct_referral", false)} ><label className="check">{clinicValues.ct_referral}</label></div>

                                </div> : (clinicValues.ct_referral == undefined || clinicValues.ct_referral == false) &&

                                <div className="check_list">
                                    <div className="check_item" onClick={(e) => updateClinicValues(e, "ct_referral", "Physiotherapy")} ><label className="check">Physiotherapy</label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateClinicValues(e, "ct_referral", "Paediatric Physiotherapy/Occupational Therapy")}><label className="check">Paediatric Physiotherapy/Occupational Therapy</label></div>
                                    <div className="check_item" onClick={(e) => updateClinicValues(e, "ct_referral", "Neurology Physiotherapy")}><label className="check">Neurology Physiotherapy</label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateClinicValues(e, "ct_referral", "Hand Occupational Therapy")}><label className="check">Hand Occupational Therapy</label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateClinicValues(e, "ct_referral", "Rheumatology Occupational Therapy")}><label className="check">Rheumatology Occupational Therapy</label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateClinicValues(e, "ct_referral", "Speech and Language Therapy")}><label className="check">Speech and Language Therapy</label></div>
                                    <div className="check_item" onClick={(e) => updateClinicValues(e, "ct_referral", "For swallowing assessment, fill in Swallowing Referral Form")}><label className="check">For swallowing assessment, fill in Swallowing Referral Form</label></div>
                                    <div className="check_item" onClick={(e) => updateClinicValues(e, "ct_referral", "Facial Clinic (MMH)")}><label className="check">Facial Clinic (MMH)</label></div>
                                    <div className="check_item" onClick={(e) => updateClinicValues(e, "ct_referral", "For Community Physiotherapy/Respiratory Physiotherapy, fill in Community Referral forms")}><label className="check">For Community Physiotherapy/Respiratory Physiotherapy, fill in Community Referral forms</label></div>
                                    <div className="check_item" onClick={(e) => updateClinicValues(e, "ct_referral", "For Community Occupational Therapy, refer via the Adult Contact Team on 01302 737391")}><label className="check">For Community Occupational Therapy, refer via the Adult Contact Team on 01302 737391</label></div>

                                </div>}


                                <div className="simple_wound ctr">
                                    <h2 className="popup_title">18 WEEKS DATA (Mandatory)</h2>
                                    <div className="form_group mb-20">
                                        <div className="floating-label-group"><input onChange={(e) => updateInputValue(e)} type="text"
                                            className="form-control" autoComplete="off" name="ct_pathwayId" value={clinicValues.ct_pathwayId} /><label className="floating-label">Pathway ID No</label>
                                        </div>
                                    </div>
                                    <div className="check_list">
                                        <div className="check_item"><label className="check">Has first treatment been given by the
                                            Consultant?</label>
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ct_consultant" type="radio"
                                                    value="Yes" checked={clinicValues.ct_consultant == "Yes" ? "checked" : ""} /><span>Yes</span></label>
                                                </div>
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ct_consultant" type="radio"
                                                    value="No" checked={clinicValues.ct_consultant == "No" ? "checked" : ""} /><span>No</span></label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="check_item"><label className="check">Is this referral intended as first
                                            treatment?</label>
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ct_treatment" type="radio"
                                                    value="Yes" checked={clinicValues.ct_treatment == "Yes" ? "checked" : ""} /><span>Yes</span></label>
                                                </div>
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ct_treatment" type="radio"
                                                    value="No" checked={clinicValues.ct_treatment == "No" ? "checked" : ""} /><span>No</span></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form_group">
                                        <div className="floating-label-group"><input onSelect={(e) => updateInputValue(e)} type="date"
                                            className="form-control" autoComplete="off" name="ct_clockdate" value={clinicValues.ct_clockdate} /><label className="floating-label">Clock start date</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="simple_wound ctr mt-10">
                                    <h2 className="popup_title">For office use only:</h2>
                                    <div className="form_group">
                                        <div className="floating-label-group"><input onChange={(e) => updateInputValue(e)} type="text" name="ct_therapist" value={clinicValues.ct_therapist}
                                            className="form-control" autoComplete="off" /><label
                                                className="floating-label">Therapist</label>
                                        </div>
                                        <div className="floating-label-group"><input onSelect={(e) => updateInputValue(e)} type="date" name="ct_date_appointment" value={clinicValues.ct_date_appointment}
                                            className="form-control" autoComplete="off" /><label
                                                className="floating-label">Date of appointment</label>
                                        </div>
                                        <div className="floating-label-group"><input onChange={(e) => updateInputValue(e)} type="text" name="ct_time_appointment" value={clinicValues.ct_time_appointment}
                                            className="form-control" autoComplete="off" /><label
                                                className="floating-label">Time of appointment</label>
                                        </div>
                                        <div className="floating-label-group"><input onChange={(e) => updateInputValue(e)} type="text" name="ct_location" value={clinicValues.ct_location}
                                            className="form-control" autoComplete="off" /><label
                                                className="floating-label">Location</label>
                                        </div>
                                    </div>
                                </div>
                                <h2 className="popup_title2 mt-30 mb-30">OUTPATIENT PHYSIOTHERAPY ASSESSMENT AND TREATMENT</h2>
                                <div className="simple_wound ctr mb-30">
                                    <h2 className="popup_title">Referral</h2>
                                    <div className="check_item"><label className="check">Type of Referral:</label>
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ct_assesment_ref" type="radio" checked={clinicValues.ct_assesment_ref == "Clinic" ? "checked" : ""} value="Clinic" /><span>Clinic</span></label></div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ct_assesment_ref" type="radio" checked={clinicValues.ct_assesment_ref == "A&E" ? "checked" : ""} value="A&E" /><span>A&E</span></label></div>
                                        </div>

                                    </div>
                                    <div className="form_group">
                                        <div className="floating-label-group"><input onChange={(e) => updateInputValue(e)} type="text" name="ct_consultant_initial" value={clinicValues.ct_consultant_initial}
                                            className="form-control" autoComplete="off" /><label
                                                className="floating-label">Consultant initials</label>
                                        </div>


                                    </div>
                                    <div className="check_item mt-30 mb-20"><label className="check">Doctor&#39;s signature:</label>
                                    </div>
                                    <div className="form_group mb-20">
                                        <div className="floating-label-group"><input onSelect={(e) => updateInputValue(e)} type="date" name="ct_assesment_ref_date" value={clinicValues.ct_consultant_initial}
                                            className="form-control" autoComplete="off" /><label
                                                className="floating-label">Referral date:</label>
                                        </div>
                                    </div>
                                    <div className="check_item"><label className="check">Diagnosis &amp; HPC/Management:</label>
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ct_diagnosis" type="radio" checked={clinicValues.ct_diagnosis == "NWB" ? "checked" : ""} value="NWB" /><span>NWB</span></label></div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ct_diagnosis" type="radio" checked={clinicValues.ct_diagnosis == "PWB" ? "checked" : ""} value="PWB" /><span>PWB</span></label></div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ct_diagnosis" type="radio" checked={clinicValues.ct_diagnosis == "FWB" ? "checked" : ""} value="FWB" /><span>FWB</span></label></div>
                                        </div>
                                    </div>
                                    <div className="form_group mb-20">
                                        <div className="floating-label-group"><input type="text" name="ct_other_relevant"
                                            className="form-control" autoComplete="off" value={clinicValues.ct_other_relevant} /><label
                                                className="floating-label">Other Relevant Information (PMH/DH/SH):</label>
                                        </div>
                                    </div>
                                    <div className="check_item"><label className="check">Referral Purpose:</label>
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ct_assesment_ref_purpose" type="radio" checked={clinicValues.ct_assesment_ref_purpose == "Advice" ? "checked" : ""}
                                                value="Advice" /><span>Advice</span></label></div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ct_assesment_ref_purpose" type="radio" checked={clinicValues.ct_assesment_ref_purpose == "Education" ? "checked" : ""}
                                                value="Education" /><span>Education</span></label></div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ct_assesment_ref_purpose" type="radio" checked={clinicValues.ct_assesment_ref_purpose == "Assess & Treat" ? "checked" : ""}
                                                value="Assess & Treat" /><span>Assess &amp;Treat</span></label></div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ct_assesment_ref_purpose" type="radio" checked={clinicValues.ct_assesment_ref_purpose == "Other" ? "checked" : ""}
                                                value="Other" /><span>Other</span></label></div>
                                        </div>
                                    </div>
                                </div>


                                <div className="simple_wound ctr mb-30">
                                    <div className="form_group mb-20">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="floating-label-group"><input onChange={(e) => updateInputValue(e)} type="text" name="ct_assesment_therapist" value={clinicValues.ct_assesment_therapist}
                                                    className="form-control" autoComplete="off" /><label
                                                        className="floating-label">Therapist</label>
                                                </div>
                                                <div className="floating-label-group"><input onChange={(e) => updateInputValue(e)} type="text" name="ct_assesment_direct_time" value={clinicValues.ct_assesment_direct_time}
                                                    className="form-control" autoComplete="off" /><label
                                                        className="floating-label">Direct Time</label>
                                                </div>
                                                <div className="floating-label-group"><input onChange={(e) => updateInputValue(e)} type="text" name="ct_assesment_diagnosist" value={clinicValues.ct_assesment_diagnosist}
                                                    className="form-control" autoComplete="off" /><label
                                                        className="floating-label">Clinical Diagnosis</label>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="floating-label-group"><input onChange={(e) => updateInputValue(e)} type="text" name="ct_assesment_assistant" value={clinicValues.ct_assesment_assistant}
                                                    className="form-control" autoComplete="off" /><label
                                                        className="floating-label">Assistant</label>
                                                </div>
                                                <div className="floating-label-group"><input onChange={(e) => updateInputValue(e)} type="text" name="ct_assesment_indirect_time" value={clinicValues.ct_assesment_indirect_time}
                                                    className="form-control" autoComplete="off" /><label
                                                        className="floating-label">Indirect Time</label>
                                                </div>
                                                <div className="floating-label-group"><input onChange={(e) => updateInputValue(e)} type="text" name="ct_assesment_appliance" value={clinicValues.ct_assesment_appliance}
                                                    className="form-control" autoComplete="off" /><label
                                                        className="floating-label">Appliance Provided</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="check_item mb-20"><label className="check">Outcome</label>
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ct_assesment_outcome" type="radio" checked={clinicValues.ct_assesment_outcome == "D/C" ? "checked" : ""}
                                                value="D/C" /><span>D/C</span></label></div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ct_assesment_outcome" type="radio" checked={clinicValues.ct_assesment_outcome == "Hands Physio" ? "checked" : ""}
                                                value="Hands Physio" /><span>Hands Physio</span></label></div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ct_assesment_outcome" type="radio" checked={clinicValues.ct_assesment_outcome == "OT" ? "checked" : ""}
                                                value="OT" /><span>OT</span></label></div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ct_assesment_outcome" type="radio" checked={clinicValues.ct_assesment_outcome == "Gym" ? "checked" : ""}
                                                value="Gym" /><span>Gym</span></label></div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ct_assesment_outcome" type="radio" checked={clinicValues.ct_assesment_outcome == "OPD" ? "checked" : ""}
                                                value="OPD" /><span>OPD</span></label></div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ct_assesment_outcome" type="radio" checked={clinicValues.ct_assesment_outcome == "Hydro" ? "checked" : ""}
                                                value="Hydro" /><span>Hydro</span></label></div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ct_assesment_outcome" type="radio" checked={clinicValues.ct_assesment_outcome == "Community Paeds" ? "checked" : ""}
                                                value="Community Paeds" /><span>Community Paeds</span></label></div>
                                        </div>

                                    </div>
                                    <div className="check_item mb-20"><label className="check">Follow Up: # Clinic</label>
                                        <div className="form_group">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="floating-label-group"><input onSelect={(e) => updateInputValue(e)} type="date" name="ct_clinic_appointment_date" value={clinicValues.ct_clinic_appointment_date}
                                                        className="form-control" autoComplete="off" /><label
                                                            className="floating-label">Appointment date</label>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="floating-label-group"><input onChange={(e) => updateInputValue(e)} type="text" name="ct_clinic_appointment_time" value={clinicValues.ct_clinic_appointment_time}
                                                        className="form-control" autoComplete="off" /><label
                                                            className="floating-label">Appointment time</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="check_item"><label className="check">Discharge type</label>
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ct_discharge_type" type="radio" checked={clinicValues.ct_discharge_type == "Treatment programme complete" ? "checked" : ""}
                                                value="Treatment programme complete" /><span>Treatment programme complete</span></label></div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ct_discharge_type" type="radio" checked={clinicValues.ct_discharge_type == "D/C for DNA" ? "checked" : ""}
                                                value="D/C for DNA" /><span>D/C for DNA</span></label>
                                            </div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ct_discharge_type" type="radio" checked={clinicValues.ct_discharge_type == "Patient relocated" ? "checked" : ""}
                                                value="Patient relocated" /><span>Patient relocated</span></label></div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ct_discharge_type" type="radio" checked={clinicValues.ct_discharge_type == "Same diagnosis" ? "checked" : ""}
                                                value="Same diagnosis" /><span>Same diagnosis</span></label></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="simple_wound ctr mb-30">
                                    <h2 className="popup_title">Findings</h2>

                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="check_item">
                                                <div className="select_btns popup_select">
                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ct_assesment_findings" type="radio" checked={clinicValues.ct_assesment_findings == "New POP" ? "checked" : ""}
                                                        value="New POP" /><span>New POP</span></label></div>
                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ct_assesment_findings" type="radio" checked={clinicValues.ct_assesment_findings == "POP removed" ? "checked" : ""}
                                                        value="POP removed" /><span>POP removed</span></label></div>
                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ct_assesment_findings" type="radio" checked={clinicValues.ct_assesment_findings == "Boot" ? "checked" : ""}
                                                        value="Boot" /><span>Boot</span></label></div>
                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ct_assesment_findings" type="radio" checked={clinicValues.ct_assesment_findings == "Collar & Cuff" ? "checked" : ""}
                                                        value="Collar & Cuff" /><span>Collar &amp;Cuff</span></label></div>
                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ct_assesment_findings" type="radio" checked={clinicValues.ct_assesment_findings == "Sling" ? "checked" : ""}
                                                        value="Sling" /><span>Sling</span></label></div>
                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ct_assesment_findings" type="radio" checked={clinicValues.ct_assesment_findings == "Same Splint/Brace" ? "checked" : ""}
                                                        value="Splint/Brace" /><span>Splint/Brace</span></label></div>
                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ct_assesment_findings" type="radio" checked={clinicValues.ct_assesment_findings == "Tubigrip" ? "checked" : ""}
                                                        value="Tubigrip" /><span>Tubigrip</span></label></div>


                                                </div>
                                            </div>
                                            <div className="check_item"><label className="check">Swelling</label>
                                                <div className="select_btns popup_select">
                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ct_assesment_swelling" type="radio" checked={clinicValues.ct_assesment_swelling == "None" ? "checked" : ""}
                                                        value="None" /><span>None</span></label></div>

                                                </div>
                                            </div>
                                            <div className="check_item"><label className="check">Skin Condition</label>
                                                <div className="select_btns popup_select">
                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ct_assesment_skin" type="radio" checked={clinicValues.ct_assesment_skin == "Good" ? "checked" : ""}
                                                        value="Good" /><span>Good</span></label></div>

                                                </div>
                                            </div>
                                            <div className="check_item"><label className="check">Pain</label>
                                                <div className="select_btns popup_select">
                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ct_assement_pain" type="radio" checked={clinicValues.ct_assement_pain == "None - Pins" ? "checked" : ""}
                                                        value="None - Pins" /><span>None - Pins</span></label></div>
                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ct_assement_pain" type="radio" checked={clinicValues.ct_assement_pain == "Needles" ? "checked" : ""}
                                                        value="Needles" /><span>Needles</span></label></div>
                                                </div>
                                            </div>

                                            <div className="check_item"><label className="check">Numbness</label>
                                                <div className="select_btns popup_select">
                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ct_assement_numbness" type="radio" checked={clinicValues.ct_assement_numbness == "Joint Rom" ? "checked" : ""}
                                                        value="Joint Rom" /><span>Joint Rom</span></label></div>
                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ct_assement_numbness" type="radio" checked={clinicValues.ct_assement_numbness == "Strength" ? "checked" : ""}
                                                        value="Strength" /><span>Strength</span></label></div>

                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ct_assement_numbness" type="radio" checked={clinicValues.ct_assement_numbness == "Mobility" ? "checked" : ""}
                                                        value="Mobility" /><span>Mobility</span></label></div>


                                                </div>
                                            </div>


                                        </div>
                                        <div className="col-md-6">

                                            {/* <img className="img" src="images/Findings.jpg" /> */}



                                        </div>
                                    </div>
                                </div>


                                <div className="simple_wound ctr">

                                    <div className="check_item mb-20"><label className="check">Exercises taught &amp; advice sheet
                                        given:</label>
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ct_assesment_exercises" type="radio" checked={clinicValues.ct_assesment_exercises == "Neck- Shoulder" ? "checked" : ""}
                                                value="Neck- Shoulder" /><span>Neck- Shoulder</span></label></div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ct_assesment_exercises" type="radio" checked={clinicValues.ct_assesment_exercises == "Elbow" ? "checked" : ""}
                                                value="Elbow" /><span>Elbow</span></label></div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ct_assesment_exercises" type="radio" checked={clinicValues.ct_assesment_exercises == "Wrist" ? "checked" : ""}
                                                value="Wrist" /><span>Wrist</span></label></div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ct_assesment_exercises" type="radio" checked={clinicValues.ct_assesment_exercises == "Good" ? "checked" : ""}
                                                value="Fingers" /><span>Fingers</span></label></div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ct_assesment_exercises" type="radio" checked={clinicValues.ct_assesment_exercises == "Thumb" ? "checked" : ""}
                                                value="Thumb" /><span>Thumb</span></label></div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ct_assesment_exercises" type="radio" checked={clinicValues.ct_assesment_exercises == "Hand" ? "checked" : ""}
                                                value="Hand" /><span>Hand</span></label></div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ct_assesment_exercises" type="radio" checked={clinicValues.ct_assesment_exercises == "Flexor Tendon" ? "checked" : ""}
                                                value="Flexor Tendon" /><span>Flexor Tendon</span></label></div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ct_assesment_exercises" type="radio" checked={clinicValues.ct_assesment_exercises == "Extensor Tendon" ? "checked" : ""}
                                                value="Extensor Tendon" /><span>Extensor Tendon</span></label></div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ct_assesment_exercises" type="radio" checked={clinicValues.ct_assesment_exercises == "Fasciectomy" ? "checked" : ""}
                                                value="Fasciectomy" /><span>Fasciectomy</span></label></div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ct_assesment_exercises" type="radio" checked={clinicValues.ct_assesment_exercises == "Hip" ? "checked" : ""}
                                                value="Hip" /><span>Hip</span></label></div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ct_assesment_exercises" type="radio" checked={clinicValues.ct_assesment_exercises == "Knee" ? "checked" : ""}
                                                value="Knee" /><span>Knee</span></label></div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ct_assesment_exercises" type="radio" checked={clinicValues.ct_assesment_exercises == "Ankle" ? "checked" : ""}
                                                value="Ankle" /><span>Ankle</span></label></div>

                                        </div>
                                    </div>
                                    <div className="select_btns popup_select dc">
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ct_assesment_heat" type="radio" checked={clinicValues.ct_assesment_heat == "Heat" ? "checked" : ""}
                                            value="Heat" /><span>Heat</span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ct_assesment_heat" type="radio" checked={clinicValues.ct_assesment_heat == "Ice" ? "checked" : ""}
                                            value="Ice" /><span>Ice</span></label></div>
                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ct_assesment_heat" type="radio" checked={clinicValues.ct_assesment_heat == "PRICE" ? "checked" : ""}
                                            value="PRICE" /><span>PRICE</span></label></div>

                                    </div>

                                    <div className="check_item"><label className="check">Walking Aid Issued</label>
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ct_assesment_walking" type="radio" checked={clinicValues.ct_assesment_walking == "Frame" ? "checked" : ""}
                                                value="Frame" /><span>Frame</span></label></div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ct_assesment_walking" type="radio" checked={clinicValues.ct_assesment_walking == "Elbow Crutches" ? "checked" : ""}
                                                value="Elbow Crutches" /><span>Elbow Crutches</span></label></div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ct_assesment_walking" type="radio" checked={clinicValues.ct_assesment_walking == "Fischer sticks" ? "checked" : ""}
                                                value="Fischer sticks" /><span>Fischer sticks</span></label></div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ct_assesment_walking" type="radio" checked={clinicValues.ct_assesment_walking == "2 Sticks" ? "checked" : ""}
                                                value="2 Sticks" /><span>2 Sticks</span></label>
                                            </div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ct_assesment_walking" type="radio" checked={clinicValues.ct_assesment_walking == "1 Stick" ? "checked" : ""}
                                                value="1 Stick" /><span>1 Stick</span></label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="check_item"><label className="check">Gait taught</label>
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ct_assesment_gait" type="radio" checked={clinicValues.ct_assesment_gait == "NWB" ? "checked" : ""}
                                                value="NWB" /><span>NWB</span></label></div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ct_assesment_gait" type="radio" checked={clinicValues.ct_assesment_gait == "PWB" ? "checked" : ""}
                                                value="PWB" /><span>PWB
                                                </span></label></div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ct_assesment_gait" type="radio" checked={clinicValues.ct_assesment_gait == "FWB" ? "checked" : ""}
                                                value="FWB" /><span>FWB
                                                </span></label></div>

                                        </div>
                                    </div>
                                    <div className="check_item"><label className="check">Stairs</label>
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ct_assesment_stairs" type="radio" checked={clinicValues.ct_assesment_stairs == "Taught" ? "checked" : ""}
                                                value="Taught" /><span>Taught</span></label></div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ct_assesment_stairs" type="radio" checked={clinicValues.ct_assesment_stairs == "Advised only" ? "checked" : ""}
                                                value="Advised only" /><span>Advised only
                                                </span></label></div>


                                        </div>
                                    </div>
                                    <div className="check_item"><label className="check">Mobility on leaving department</label>
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ct_assesment_mobility" type="radio" checked={clinicValues.ct_assesment_mobility == "Independent" ? "checked" : ""}
                                                value="Independent" /><span>Independent </span></label></div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ct_assesment_mobility" type="radio" checked={clinicValues.ct_assesment_mobility == "Supervision" ? "checked" : ""}
                                                value="Supervision" /><span>Supervision
                                                </span></label></div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ct_assesment_mobility" type="radio" checked={clinicValues.ct_assesment_mobility == "Physical assistance" ? "checked" : ""}
                                                value="Physical assistance" /><span>Physical assistance
                                                </span></label></div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="ct_assesment_mobility" type="radio" checked={clinicValues.ct_assesment_mobility == "Unable without physical assist" ? "checked" : ""}
                                                value="Unable without physical assist" /><span>Unable without physical assist
                                                </span></label></div>


                                        </div>
                                    </div>
                                    <div className="form_group">
                                        <div className="floating-label-group"><input onChange={(e) => updateInputValue(e)} type="text" name="ct_assesment_advice" value={clinicValues.ct_assesment_advice}
                                            className="form-control" autoComplete="off" /><label
                                                className="floating-label">Additional Treatment / Advice</label>
                                        </div>


                                    </div>

                                </div>





                            </div>
                        </div>
                        <div className='popup_footer'>
                            <div className="prescribe_footer">
                                <button className="border_btn" type="button" onClick={(e) => { updateClinic(e), referralsSave("clinicalReferral"), setvisibleClinicalTherapy(false) }}><span
                                    className="material-icons">save</span>SAVE </button>

                                <button className="btn dsp_flex" onClick={(e) => setvisibleClinicalTherapy(false)} ><span
                                    className="material-icons"><span className="material-icons">close</span></span>DISCARD
                                </button></div>
                        </div>
                    </div>
                </div>
            }

            {visisbleArrangeFollowup &&
                <div className='popup_wrp'>
                    <div className="popup_full prescribe">
                        <div className='popup_hed'>
                            <h2>ARRANGE FOLLOW-UP APPOINTMENT</h2>
                            <a onClick={(e) => setvisisbleArrangeFollowup(false)} className='popup_close'><span className="material-icons">close</span></a>
                        </div>
                        <div className='popup_content'>

                            <div className='popup_scroll main'>
                                <div className="arrange_block">

                                    <div className="arrange_form">
                                        <div className="arrange_left">
                                            <h3>Appointment in</h3>
                                        </div>
                                        <div className="arrange_right">
                                            <div className="dsp_flex"><input onChange={(e) => updateInputValue(e)} type="text" name="oc_arrange_duration" autoComplete="off" />
                                                <div className="days">Days</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="arrange_form">
                                        <div className="arrange_left">
                                            <h3>Estimated date for next appointment</h3>
                                        </div>
                                        <div className="arrange_right">
                                            <p>{clinicValues.oc_arrange_estimated}</p>
                                        </div>
                                    </div>
                                    <div className="arrange_form">
                                        <div className="arrange_left">
                                            <h3>Time tolerance</h3>
                                        </div>
                                        <div className="arrange_right">
                                            <div className="check_item">
                                                <div className="select_btns popup_select">
                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="oc_arrange_timetolerance" type="radio"
                                                        value="No time tolerance" /><span>No time tolerance</span></label>
                                                    </div>
                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="oc_arrange_timetolerance" type="radio"
                                                        value="Date +4 Weeks" /><span>Date +4 Weeks</span></label>
                                                    </div>
                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="oc_arrange_timetolerance" type="radio"
                                                        value="Date +8 Weeks" /><span>Date +8 Weeks</span></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="arrange_form">
                                        <div className="arrange_left">
                                            <h3>Requested Date</h3>
                                        </div>
                                        <div className="arrange_right">
                                            <input onSelect={(e) => updateInputValue(e)} type="date" className="date" name="oc_arrange_requested_date" />

                                        </div>
                                    </div>
                                    <div className="arrange_form">
                                        <div className="arrange_left">
                                            <h3>Appointment to be</h3>
                                        </div>
                                        <div className="arrange_right">
                                            <div className="check_item">
                                                <div className="select_btns popup_select">
                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="oc_arrange_appointmenttobe" type="radio"
                                                        value="Face to Face" /><span>Face to Face</span></label>
                                                    </div>
                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="oc_arrange_appointmenttobe" type="radio"
                                                        value="Telephone" /><span>Telephone</span></label>
                                                    </div>
                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="oc_arrange_appointmenttobe" type="radio"
                                                        value="Video" /><span>Video</span></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="arrange_form">
                                        <div className="arrange_left">
                                            <h3>To see</h3>
                                        </div>
                                        <div className="arrange_right">
                                            <div className="check_item">
                                                <div className="select_btns popup_select">
                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="oc_arrange_tosee" type="radio"
                                                        value="Same Clinician" /><span>Same Clinician</span></label>
                                                    </div>
                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="oc_arrange_tosee" type="radio"
                                                        value="Consultant" /><span>Consultant</span></label>
                                                    </div>
                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="oc_arrange_tosee" type="radio"
                                                        value="Registrar" /><span>Registrar</span></label>
                                                    </div>
                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="oc_arrange_tosee" type="radio"
                                                        value="Other HCP" /><span>Other HCP</span></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="arrange_form">
                                        <div className="arrange_left">
                                            <h3>Appointment Duration</h3>
                                        </div>
                                        <div className="arrange_right">
                                            <div className="check_item">
                                                <div className="select_btns popup_select">
                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="oc_arrange_appointment_duration" type="radio" value="Standard" /><span>Standard</span></label>
                                                    </div>
                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="oc_arrange_appointment_duration" type="radio" value="Long" /><span>Long</span></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="arrange_form">
                                        <div className="arrange_left">
                                            <h3>Reason for re-appointment</h3>
                                        </div>
                                        <div className="arrange_right">
                                            <div className="check_item">
                                                <div className="select_btns popup_select">
                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="oc_arrange_re_appointment" type="radio" value="Ongoing Clinical Care" /><span>Ongoing Clinical Care</span></label>
                                                    </div>
                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="oc_arrange_re_appointment" type="radio" value="Other" /><span>Other</span></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="arrange_form">
                                        <div className="arrange_left">
                                            <h3>Clinic Name</h3>
                                        </div>
                                        <div className="arrange_right">
                                            <div className="check_item">
                                                <div className="select_btns popup_select">
                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="oc_arrange_clinic" type="radio"
                                                        value="Ortho Clinic" /><span>Ortho Clinic</span></label>
                                                    </div>
                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="oc_arrange_clinic" type="radio"
                                                        value="Knee Clinic" /><span>Knee Clinic</span></label>
                                                    </div>
                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="oc_arrange_clinic" type="radio"
                                                        value="Specialist Clinic" /><span>Specialist Clinic</span></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {clinicValues.oc_arrange_assesment ? <div className="check_list">
                                        <div className="check_item" onClick={(e) => updateClinicValues(e, "oc_arrange_assesment", false)} ><label className="check">{clinicValues.oc_arrange_assesment}</label></div>

                                    </div> : (clinicValues.oc_arrange_assesment == undefined || clinicValues.oc_arrange_assesment == false) &&
                                    <div className="check_list mt-30 mb-30">
                                        <div className="check_item" onClick={(e) => updateClinicValues(e, "oc_arrange_assesment", "Cancer Assessment or management of cancer as part of the assessment")}><label className="check">Cancer Assessment or management of cancer as part of the assessment</label></div>
                                        <div className="check_item" onClick={(e) => updateClinicValues(e, "oc_arrange_assesment", "Interpreter required")}><label className="check">Interpreter required</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateClinicValues(e, "oc_arrange_assesment", "BSL Interpreter required")}><label className="check">BSL Interpreter required</label></div>
                                        <div className="check_item" onClick={(e) => updateClinicValues(e, "oc_arrange_assesment", "Transport required")}><label className="check">Transport required</label></div>

                                    </div>}

                                    {clinicValues.oc_arrange_assesment == "Transport required" &&
                                        <div className="arrange_form">

                                            <div className="arrange_right">
                                                <div className="check_item">
                                                    <div className="select_btns popup_select">
                                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="oc_arrange_language_transport" type="radio" value="Walking" /><span>Walking</span></label>
                                                        </div>
                                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="oc_arrange_language_transport" type="radio" value="Chair" /><span>Chair</span></label>
                                                        </div>
                                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="oc_arrange_language_transport" type="radio" value="Stretcher" /><span>Stretcher</span></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>}

                                    {clinicValues.oc_arrange_assesment == "Interpreter required" &&
                                        <div className="arrange_form mt-10">
                                            <div className="arrange_left">
                                                <h3>Language</h3>
                                            </div>
                                            <div className="arrange_right">
                                                <div className="dsp_flex"><input onChange={(e) => updateInputValue(e)} type="text" name="oc_arrange_language_transport" autoComplete="off" />
                                                </div>
                                            </div>
                                        </div>}


                                    <h4 className="mt-10">Pre-consultation services needed</h4>


                                    {clinicValues.oc_arrange_preconsult ? <div className="check_list">
                                        <div className="check_item" onClick={(e) => updateClinicValues(e, "oc_arrange_preconsult", false)} ><label className="check">{clinicValues.oc_arrange_preconsult}</label></div>

                                    </div> : (clinicValues.oc_arrange_preconsult == undefined || clinicValues.oc_arrange_preconsult == false) &&

                                    <div className="check_list">
                                        <div className="check_item" onClick={(e) => updateClinicValues(e, "oc_arrange_preconsult", "None")}><label className="check">None</label></div>
                                        <div className="check_item" onClick={(e) => updateClinicValues(e, "oc_arrange_preconsult", "XOA (x-ray on arrival)")}><label className="check">XOA (x-ray on arrival)</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateClinicValues(e, "oc_arrange_preconsult", "POA (POP off on arrival)")}><label className="check">POA (POP off on arrival)</label></div>
                                        <div className="check_item" onClick={(e) => updateClinicValues(e, "oc_arrange_preconsult", "OFF XOA (POP off and x-ray on arrival)")}><label className="check">OFF XOA (POP off and x-ray on arrival)</label></div>
                                        <div className="check_item" onClick={(e) => updateClinicValues(e, "oc_arrange_preconsult", "Dressing room")}><label className="check">Dressing room</label></div>
                                        <div className="check_item" onClick={(e) => updateClinicValues(e, "oc_arrange_preconsult", "Stitches out")}><label className="check">Stitches out</label></div>
                                        <div className="check_item" onClick={(e) => updateClinicValues(e, "oc_arrange_preconsult", "To be seen by Consultant")}><label className="check">To be seen by Consultant</label></div>
                                    </div>
                                    }
                                    <h4 className="mt-30">Comments</h4>
                                    <textarea name="oc_arrange_comments" onChange={(e) => updateInputValue(e)} ></textarea>

                                </div>
                            </div>
                        </div>
                        <div className='popup_footer'>
                            <div className="prescribe_footer"><button className="border_btn" onClick={(e) => { updateClinic(e), referralsSave("arrangeFollowUp"), setvisisbleArrangeFollowup(false) }}  ><span className="material-icons">save</span>SAVE </button><button className="btn dsp_flex" onClick={(e) => setvisisbleArrangeFollowup(false)}><span className="material-icons"><span className="material-icons">close</span></span>DISCARD </button></div>
                        </div>
                    </div>
                </div>
            }

            {visibleDressing &&
                <div className='popup_wrp'>
                    <div className="popup_full prescribe">
                        <div className='popup_hed'>
                            <h2>WOUND CARE</h2>
                            <a className='popup_close' onClick={(e) => setvisibleDressing(false)}><span className="material-icons">close</span></a>
                        </div>
                        <div className='popup_content'>
                            <div className='popup_scroll main'>
                                <div className="wound_care">
                                    <div className="btn" onClick={(e) => setvisibleSimpleWound(true)}>SIMPLE WOUND CARE</div>
                                    <div className="btn" onClick={(e) => setvisibleComplexWound(true)}>COMPLEX WOUND CARE</div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            }

            {visibleSimpleWound &&

                <div className='popup_wrp'>
                    <div className="popup_full prescribe">
                        <div className='popup_hed'>
                            <h2>SIMPLE WOUND CARE</h2>
                            <a className='popup_close' onClick={(e) => setvisibleSimpleWound(false)}><span className="material-icons">close</span></a>
                        </div>
                        <div className='popup_content'>

                            <div className='popup_scroll main pr'>
                                <div className='out_patient_section out_patient_details ld mt-0 mb-0'>
                                    <PatientBlock patient={clinicPatient} />
                                </div>
                                <div className="simple_wound">
                                    <div className="form_group">
                                        <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} className="form-control" autoComplete="off" name="sw_diagnosis" value={clinicValues.sw_diagnosis} /><label className="floating-label">DIAGNOSIS</label>
                                        </div>
                                        <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} className="form-control" autoComplete="off" name="sw_treatment" value={clinicValues.sw_treatment} /><label className="floating-label">TREATMENT REQUIRED</label>
                                        </div>
                                        <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} className="form-control" autoComplete="off" name="sw_contactno" value={clinicValues.sw_contactno} /><label className="floating-label">Contact Number</label></div>
                                    </div>
                                    <div className="check_list">

                                        <div className="check_item"><label className="check">FOLLOW UP CARE</label>
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="sw_followup" type="radio" value="Next appointment" checked={clinicValues.sw_followup == "Next appointment" ? "checked" : ""} /><span>Next appointment</span></label></div>
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="sw_followup" type="radio" value="Family Doctor" checked={clinicValues.sw_followup == "Family Doctor" ? "checked" : ""} /><span>Family Doctor</span></label></div>
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="sw_followup" type="radio" value="Minor Fracture Clinic" checked={clinicValues.sw_followup == "Minor Fracture Clinic" ? "checked" : ""} /><span>Minor Fracture Clinic</span></label></div>
                                            </div>
                                        </div>


                                    </div>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley</p>
                                    <div className="prb_search">
                                        <textarea placeholder='Wound Care Nurse Notes' name="sw_notes" value={clinicValues.sw_notes} onChange={(e) => updateInputValue(e)} ></textarea>
                                    </div>
                                    {/* <p>Dr Signature:</p> */}
                                </div>



                            </div>
                        </div>
                        <div className='popup_footer'>
                            <div className="prescribe_footer"><button className="border_btn" onClick={(e) => { updateClinic(e), referralsSave("dressingReferral"), setvisibleSimpleWound(false), setvisibleDressing(false) }}><span className="material-icons">save</span>SAVE </button>
                                <button className="btn dsp_flex" onClick={(e) => { setvisibleSimpleWound(false) }}><span className="material-icons"><span className="material-icons">close</span></span>DISCARD </button></div>
                        </div>

                    </div>
                </div>

            }

            {visibleComplexWound &&
                <div className='popup_wrp'>
                    <div className="popup_full prescribe">
                        <div className='popup_hed'>
                            <h2>COMPLEX WOUND CARE</h2>
                            <a className='popup_close' onClick={(e) => { setvisibleComplexWound(false) }}><span className="material-icons">close</span></a>
                        </div>
                        <div className='popup_content'>

                            <div className='popup_scroll main pr'>
                                <div className='out_patient_section out_patient_details ld mt-0 mb-0'>
                                    <PatientBlock patient={clinicPatient} />
                                </div>
                                <div className="simple_wound">
                                    <div className="form_group">
                                        <div className="floating-label-group"><input onChange={(e) => updateInputValue(e)} type="text" name="cw_address" value={clinicValues.cw_address}
                                            className="form-control" autoComplete="off" /><label
                                                className="floating-label">Address</label>
                                        </div>
                                        <div className="floating-label-group"><input onChange={(e) => updateInputValue(e)} type="text" name="cw_home_phone" value={clinicValues.cw_home_phone}
                                            className="form-control" autoComplete="off" /><label
                                                className="floating-label">Home Phone</label>
                                        </div>
                                        <div className="floating-label-group"><input onChange={(e) => updateInputValue(e)} type="text" name="cw_mobile_phone" value={clinicValues.cw_mobile_phone}
                                            className="form-control" autoComplete="off" /><label
                                                className="floating-label">Mobile Phone</label></div>
                                        <div className="floating-label-group"><input onChange={(e) => updateInputValue(e)} type="text" name="cw_wound_location" value={clinicValues.cw_wound_location}
                                            className="form-control" autoComplete="off" /><label
                                                className="floating-label">Wound Location</label></div>
                                        <div className="floating-label-group"><input onChange={(e) => updateInputValue(e)} type="text" name="cw_wound_startday" value={clinicValues.cw_wound_startday}
                                            className="form-control" autoComplete="off" /><label
                                                className="floating-label">Wound Start Day</label></div>
                                        <div className="floating-label-group"><input onChange={(e) => updateInputValue(e)} type="text" name="cw_wound_reason" value={clinicValues.cw_wound_reason} className="form-control" autoComplete="off" /><label
                                            className="floating-label">Reason / Goal for Referral</label></div>
                                    </div>
                                    <div className="check_list">

                                        <div className="check_item"><label className="check">Is Patient recieving wound care from district nurse?</label>
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input name="cw_wound_nurse_care" onClick={(e) => updateInputValue(e)} type="radio" value="Yes" checked={clinicValues.cw_wound_aeitology == "Yes" ? "checked" : ""} /><span>Yes</span></label></div>
                                                <div className="select_btn"><label><input name="cw_wound_nurse_care" onClick={(e) => updateInputValue(e)} type="radio" value="No" checked={clinicValues.cw_wound_aeitology == "No" ? "checked" : ""} /><span>No</span></label></div>

                                            </div>
                                        </div>


                                    </div>
                                    <div className="form_group">
                                        <div className="floating-label-group"><input onSelect={(e) => updateInputValue(e)} type="date" name="cw_start_date" value={clinicValues.cw_start_date}
                                            className="form-control" autoComplete="off" /><label
                                                className="floating-label">Start Day</label>
                                        </div>
                                        <div className="floating-label-group"><input onChange={(e) => updateInputValue(e)} type="text" name="cw_known_allergy" value={clinicValues.cw_known_allergy}
                                            className="form-control" autoComplete="off" /><label
                                                className="floating-label">Known Allergy</label>
                                        </div>


                                    </div>
                                    <div className="check_list">

                                        <div className="check_item"><label className="check">Wound Aetiology</label>
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input name="cw_wound_aeitology" onClick={(e) => updateInputValue(e)} type="radio" checked={clinicValues.cw_wound_aeitology == "Pressure Ulcer" ? "checked" : ""} value="Pressure Ulcer" /><span>Pressure Ulcer</span></label></div>
                                                <div className="select_btn"><label><input name="cw_wound_aeitology" type="radio" checked={clinicValues.cw_wound_aeitology == "Diabetic/Neuropathic Foot Ulcer" ? "checked" : ""} value="Diabetic/Neuropathic Foot Ulcer" /><span>Diabetic/Neuropathic Foot Ulcer</span></label></div>
                                                <div className="select_btn"><label><input name="cw_wound_aeitology" onClick={(e) => updateInputValue(e)} type="radio" checked={clinicValues.cw_wound_aeitology == "Lower Leg Ulcer (venous/arterial/Mixed)" ? "checked" : ""} value="Lower Leg Ulcer (venous/arterial/Mixed)" /><span>Lower Leg Ulcer (venous/arterial/Mixed)</span></label></div>
                                                <div className="select_btn"><label><input name="cw_wound_aeitology" onClick={(e) => updateInputValue(e)} type="radio" checked={clinicValues.cw_wound_aeitology == "Trauma" ? "checked" : ""} value="Trauma" /><span>Trauma</span></label></div>
                                                <div className="select_btn"><label><input name="cw_wound_aeitology" onClick={(e) => updateInputValue(e)} type="radio" checked={clinicValues.cw_wound_aeitology == "Post Surgical" ? "checked" : ""} value="Post Surgical" /><span>Post Surgical</span></label></div>
                                                <div className="select_btn"><label><input name="cw_wound_aeitology" onClick={(e) => updateInputValue(e)} type="radio" checked={clinicValues.cw_wound_aeitology == "Burn/Scald" ? "checked" : ""} value="Burn/Scald" /><span>Burn/Scald</span></label></div>
                                                <div className="select_btn"><label><input name="cw_wound_aeitology" onClick={(e) => updateInputValue(e)} type="radio" checked={clinicValues.cw_wound_aeitology == "Infectious" ? "checked" : ""} value="Infectious" /><span>Infectious</span></label></div>
                                                <div className="select_btn"><label><input name="cw_wound_aeitology" onClick={(e) => updateInputValue(e)} type="radio" checked={clinicValues.cw_wound_aeitology == "Inflammatory" ? "checked" : ""} value="Inflammatory" /><span>Inflammatory</span></label></div>
                                                <div className="select_btn"><label><input name="cw_wound_aeitology" onClick={(e) => updateInputValue(e)} type="radio" checked={clinicValues.cw_wound_aeitology == "Unknown" ? "checked" : ""} value="Unknown" /><span>Unknown</span></label></div>
                                                <div className="select_btn"><label><input name="cw_wound_aeitology" onClick={(e) => updateInputValue(e)} type="radio" checked={clinicValues.cw_wound_aeitology == "Other" ? "checked" : ""} value="Other" /><span>Other</span></label></div>
                                            </div>
                                        </div>


                                    </div>


                                </div>
                                <h2 className="popup_title mt-20">COMORBIDITIES/WOUND HEALING IMPEDING FACTORS</h2>

                                {clinicValues.cw_comorbidities ? <div className="check_list">
                                    <div className="check_item" onClick={(e) => updateClinicValues(e, "cw_comorbidities", false)} ><label className="check">{clinicValues.cw_comorbidities}</label></div>

                                </div> : (clinicValues.cw_comorbidities == undefined || clinicValues.cw_comorbidities == false) &&

                                <div className="check_list">
                                    <div className="check_item" onClick={(e) => updateClinicValues(e, "cw_comorbidities", "Pressure")}><label className="check">Pressure</label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateClinicValues(e, "cw_comorbidities", "Diabetes/Neuropathy")}><label className="check">Diabetes/Neuropathy</label></div>
                                    <div className="check_item" onClick={(e) => updateClinicValues(e, "cw_comorbidities", "Venous Stasis")}><label className="check">Venous Stasis</label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateClinicValues(e, "cw_comorbidities", "Peripheral Edema")}><label className="check">Peripheral Edema</label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateClinicValues(e, "cw_comorbidities", "Arterial Insufficiency")}><label className="check">Arterial Insufficiency</label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateClinicValues(e, "cw_comorbidities", "Nutrition")}><label className="check">Nutrition</label></div>
                                    <div className="check_item" onClick={(e) => updateClinicValues(e, "cw_comorbidities", "Obesity")}><label className="check">Obesity</label></div>
                                    <div className="check_item" onClick={(e) => updateClinicValues(e, "cw_comorbidities", "Tobacco Use")}><label className="check">Tobacco Use</label></div>
                                    <div className="check_item" onClick={(e) => updateClinicValues(e, "cw_comorbidities", "Immobility")}><label className="check">Immobility</label></div>
                                    <div className="check_item" onClick={(e) => updateClinicValues(e, "cw_comorbidities", "Immunosuppression")}><label className="check">Immunosuppression</label></div>
                                    <div className="check_item" onClick={(e) => updateClinicValues(e, "cw_comorbidities", "Prednisone/Steroid Use &gt;20mg/day")}><label className="check">Prednisone/Steroid Use &gt;20mg/day</label></div>
                                    <div className="check_item" onClick={(e) => updateClinicValues(e, "cw_comorbidities", "Infection")}><label className="check">Infection</label></div>
                                    <div className="check_item" onClick={(e) => updateClinicValues(e, "cw_comorbidities", "Inflammation")}><label className="check">Inflammation</label></div>
                                    <div className="check_item" onClick={(e) => updateClinicValues(e, "cw_comorbidities", "Foreign Body")}><label className="check">Foreign Body</label></div>
                                    <div className="check_item" onClick={(e) => updateClinicValues(e, "cw_comorbidities", "Granuloma")}><label className="check">Granuloma</label></div>
                                    <div className="check_item" onClick={(e) => updateClinicValues(e, "cw_comorbidities", "Patient Adherence")}><label className="check">Patient Adherence</label></div>
                                    <div className="check_item" onClick={(e) => updateClinicValues(e, "cw_comorbidities", "Other")}><label className="check">Other</label></div>
                                </div>}


                                <div className="simple_wound">
                                    <div className="form_group">
                                        <div className="floating-label-group"><input onChange={(e) => updateInputValue(e)} type="text" name="cw_current_dressing" value={clinicValues.cw_current_dressing} className="form-control" autoComplete="off" /><label className="floating-label">Current Dressing ProtocolL</label>
                                        </div>
                                        <div className="floating-label-group"><input onChange={(e) => updateInputValue(e)} type="text" name="cw_dressing_product" value={clinicValues.cw_dressing_product} className="form-control" autoComplete="off" /><label className="floating-label">Dressing Products Previously Used</label>
                                        </div>


                                    </div>
                                    <div className="check_item"><label className="check">Is Patient able to transfer independently?</label>
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input name="cw_transfer_independently" onClick={(e) => updateInputValue(e)} checked={clinicValues.cw_transfer_independently == "Yes" ? "checked" : ""} type="radio" value="Yes" /><span>Yes</span></label></div>
                                            <div className="select_btn"><label><input name="cw_transfer_independently" onClick={(e) => updateInputValue(e)} checked={clinicValues.cw_transfer_independently == "No" ? "checked" : ""} type="radio" value="No" /><span>No</span></label></div>

                                        </div>
                                    </div>
                                    <div className="check_item"><label className="check">Interpreter Required?</label>
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input name="cw_interpreter" onClick={(e) => updateInputValue(e)} type="radio" value="Yes" checked={clinicValues.cw_interpreter == "Yes" ? "checked" : ""} /><span>Yes</span></label></div>
                                            <div className="select_btn"><label><input name="cw_interpreter" onClick={(e) => updateInputValue(e)} type="radio" value="No" checked={clinicValues.cw_interpreter == "No" ? "checked" : ""} /><span>No</span></label></div>

                                        </div>
                                    </div>
                                    <div className="form_group">
                                        <div className="floating-label-group"><input onChange={(e) => updateInputValue(e)} type="text" name="cw_refreee_name" value={clinicValues.cw_refreee_name}
                                            className="form-control" autoComplete="off" /><label
                                                className="floating-label">Referee Name</label>
                                        </div>
                                        <div className="floating-label-group"><input onChange={(e) => updateInputValue(e)} type="text" name="cw_refer_title" value={clinicValues.cw_refer_title}
                                            className="form-control" autoComplete="off" /><label
                                                className="floating-label">Title</label>
                                        </div>
                                        <div className="floating-label-group"><input onChange={(e) => updateInputValue(e)} type="text" name="cw_refer_phone" value={clinicValues.cw_refer_phone}
                                            className="form-control" autoComplete="off" /><label
                                                className="floating-label">Phone Number</label>
                                        </div>
                                        <div className="floating-label-group"><input onChange={(e) => updateInputValue(e)} type="text" name="cw_refer_email" value={clinicValues.cw_refer_email}
                                            className="form-control" autoComplete="off" /><label
                                                className="floating-label">e-mail address</label>
                                        </div>


                                    </div>
                                    <div className="note">
                                        <h2>Referral Criteria</h2>
                                        <p>Providing person-centered education to promote healthy life-style change, and
                                            ability to
                                            self-management</p>
                                        <h3>Inclusion Criteria</h3>
                                        <ul>
                                            <li>Patients of age 19 and over</li>
                                            <li>Patient of age under 19 in collaboration with Child and Youth Services</li>
                                            <li>Patients with lower leg/foot ulcers with ABI &gt;0.5(if ABI&lt;0.5, refer to
                                                vascular surgeon
                                                first)</li>
                                            <li>Even with standard wound care (Sheehan 2013, Coerper et al 2009):
                                                <ol className="alpha">
                                                    <li>Patients with acute wounds that fail to heal in 6 week</li>
                                                    <li>Patients with chronic wounds that fail to heal in 12 weeks</li>
                                                    <li>Patients with chronic wounds that fail to have 30% size reduction
                                                        (length x width)
                                                        after 4 weeks of standard wound care</li>

                                                </ol>
                                            </li>
                                            <li>Patient can transfer independently or assisted by accompanied caregiver</li>
                                            <li>Patient is committed to:
                                                <ol className="alpha">
                                                    <li>Adherence to clinic visits</li>
                                                    <li>Adherence to treatment plan</li>
                                                    <li>Tobacco reduction/cessation</li>
                                                    <li>Optimal nutrition, including protein, calorie, fluid</li>
                                                    <li>Optimal Diabetic control with A1C &lt; 9</li>
                                                    <li>Patients with chronic wounds that fail to have 30% size reduction length x </li>

                                                </ol>
                                            </li>

                                        </ul>
                                        <h3>Exclusion Criteria:</h3>
                                        <ul>
                                            <li>Patients’ wounds have not been managed with standard wound care</li>
                                            <li>Home bound patients who cannot attend the clinic visit</li>
                                            <li>Patients who require mechanical lift for transfer</li>
                                            <li>Patients with lower leg/foot ulcers with ABI &lt;0.5, and are not a
                                                candidate for
                                                revascularization</li>
                                            <li>Patients cannot commit to the wound treatment plan, clinic visits, healthy
                                                life style
                                                modifications, tobacco reduction/cessation, optimal nutrition intake,
                                                Diabetic control, etc.</li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className='popup_footer'>
                            <div className="prescribe_footer">
                                <button className="border_btn" onClick={(e) => { updateClinic(e), referralsSave("dressingReferral"), setvisibleComplexWound(false), setvisibleDressing(false) }}><span className="material-icons">save</span>SAVE </button>
                                <button className="btn dsp_flex" onClick={(e) => { setvisibleComplexWound(false) }}><span className="material-icons"><span className="material-icons">close</span></span>DISCARD
                                </button></div>
                        </div>
                    </div>
                </div>
            }

            {visibleBookingForm &&
                <div className='popup_wrp'>
                    <div className="popup_full prescribe">
                        <div className='popup_hed'>
                            <h2>SURGERY BOOKING FORM</h2>
                            <a className='popup_close' onClick={(e) => { setvisibleBookingForm(false) }}><span className="material-icons">close</span></a>
                        </div>
                        <div className='popup_content'>

                            <div className='popup_scroll main pr'>
                                <div className='out_patient_section out_patient_details ld mt-0 mb-0'>
                                    <PatientBlock patient={clinicPatient} />
                                </div>
                                <div className="simple_wound ctr mb-20">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="floating-label-group"><input type="text" name="bk_address"
                                                    className="form-control" autocomplete="off" onChange={(e) => updateInputValue(e)} value={clinicValues.bk_address} /><label
                                                        className="floating-label">Address</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="floating-label-group"><input type="text" name="bk_email"
                                                    className="form-control" autocomplete="off" onChange={(e) => updateInputValue(e)} value={clinicValues.bk_email} /><label
                                                        className="floating-label">Email</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <h5 className="field_title">Phone</h5>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="floating-label-group"><input type="text" name="bk_homenumber"
                                                    className="form-control" autocomplete="off" onChange={(e) => updateInputValue(e)} value={clinicValues.bk_homenumber} />
                                                    <label className="floating-label">Home Number</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="floating-label-group"><input type="text" name="bk_mobilenumber"
                                                    className="form-control" autocomplete="off" onChange={(e) => updateInputValue(e)} value={clinicValues.bk_mobilenumber} /><label
                                                        className="floating-label">Mobile Number</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">

                                            <div className="form_group">
                                                <div className="floating-label-group"><input type="text" name="bk_insurance"
                                                    className="form-control" autocomplete="off" onChange={(e) => updateInputValue(e)} value={clinicValues.bk_insurance} /><label
                                                        className="floating-label">Insurance</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form_group">
                                                        <div className="floating-label-group"><input type="text" name="bk_id"
                                                            className="form-control" autocomplete="off" onChange={(e) => updateInputValue(e)} value={clinicValues.bk_id} /><label
                                                                className="floating-label">ID#</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form_group">
                                                        <div className="floating-label-group"><input type="text" name="bk_auth"
                                                            className="form-control" autocomplete="off" onChange={(e) => updateInputValue(e)} value={clinicValues.bk_auth} /><label
                                                                className="floating-label">Auth#</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="simple_wound ctr mb-20">
                                    <div className="form_group mb-20">
                                        <select name="cars" className="form-control select">
                                            <option value="volvo">Preferred Location</option>

                                        </select>
                                        <span className="dwn_aro material-icons"> arrow_drop_down </span>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form_group">
                                                <div className="floating-label-group"><input type="text" name="bk_patient_non"
                                                    className="form-control" autocomplete="off" onChange={(e) => updateInputValue(e)} value={clinicValues.bk_patient_non} /><label
                                                        className="floating-label">Patient non availability</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form_group">
                                                <div className="floating-label-group"><input type="text" name="bk_holidays"
                                                    className="form-control" autocomplete="off" onChange={(e) => updateInputValue(e)} value={clinicValues.bk_holidays} /><label
                                                        className="floating-label">Holidays</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form_group">
                                                <div className="floating-label-group"><input type="text" name="bk_work"
                                                    className="form-control" autocomplete="off" onChange={(e) => updateInputValue(e)} value={clinicValues.bk_work} /><label
                                                        className="floating-label">Work</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="check_list w_white">

                                        <div className="check_item"><label className="check">Interpreter required</label>
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input name="bk_interpreter" type="radio"
                                                    value="Language" onClick={(e) => updateInputValue(e)} checked={clinicValues.bk_interpreter == "Language" ? "checked" : ""} /><span>Language</span></label></div>
                                                <div className="select_btn"><label><input name="bk_interpreter" type="radio"
                                                    value="BSL" onClick={(e) => updateInputValue(e)} checked={clinicValues.bk_interpreter == "BSL" ? "checked" : ""} /><span>BSL</span></label></div>

                                            </div>
                                        </div>
                                        <div className="check_item"><label className="check">Transport required</label>
                                        </div>

                                        <div className="check_item"><label className="check">Rehabilitation required</label>
                                        </div>

                                    </div>

                                </div>
                                <div className="simple_wound ctr mb-20">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="form_group">
                                                        <div className="floating-label-group"><input type="text" name="bk_weight"
                                                            className="form-control" autocomplete="off" onChange={(e) => updateInputValue(e)} value={clinicValues.bk_weight} /><label
                                                                className="floating-label">Weight</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form_group">
                                                        <div className="floating-label-group"><input type="text" name="bk_height"
                                                            className="form-control" autocomplete="off" onChange={(e) => updateInputValue(e)} value={clinicValues.bk_height} /><label
                                                                className="floating-label">Height</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form_group">
                                                        <div className="floating-label-group"><input type="text" name="bk_BMI"
                                                            className="form-control" autocomplete="off" onChange={(e) => updateInputValue(e)} value={clinicValues.bk_BMI} /><label
                                                                className="floating-label">BMI</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form_group">
                                                        <div className="floating-label-group"><input type="date" name="bk_mrsa_screen_date"
                                                            className="form-control" autocomplete="off" onChange={(e) => updateInputValue(e)} value={clinicValues.bk_mrsa_screen_date} /><label
                                                                className="floating-label">MRSA screen Date</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="check_item"><label className="check">MRSA Result</label>
                                                        <div className="select_btns popup_select mt-0">
                                                            <div className="select_btn"><label><input name="bk_mrsa_result" type="radio"
                                                                value="Positive" onClick={(e) => updateInputValue(e)} checked={clinicValues.bk_mrsa_result == "Positive" ? "checked" : ""} /><span>+ve</span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input name="bk_mrsa_result" type="radio"
                                                                value="Negative" onClick={(e) => updateInputValue(e)} checked={clinicValues.bk_mrsa_result == "Negative" ? "checked" : ""} /><span>-ve</span></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-7">
                                            <div className="check_item">
                                                <div className="select_btns popup_select">
                                                    <div className="select_btn"><label><input name="bk_allergy" type="radio"
                                                        value="Sensitive to" onClick={(e) => updateInputValue(e)} checked={clinicValues.bk_allergy == "Sensitive to" ? "checked" : ""} /><span>Sensitive to</span></label></div>
                                                    <div className="select_btn"><label><input name="bk_allergy" type="radio"
                                                        value="Allergic to" onClick={(e) => updateInputValue(e)} checked={clinicValues.bk_allergy == "Allergic to" ? "checked" : ""} /><span>Allergic to</span></label></div>
                                                    <div className="select_btn"><label><input name="bk_allergy" type="radio"
                                                        value="None known" onClick={(e) => updateInputValue(e)} checked={clinicValues.bk_allergy == "None known" ? "checked" : ""} /><span>None known</span></label></div>
                                                    <div className="select_btn"><label><input name="bk_allergy" type="radio"
                                                        value="Latex Allergy" onClick={(e) => updateInputValue(e)} checked={clinicValues.bk_allergy == "Latex Allergy" ? "checked" : ""} /><span>Latex Allergy</span></label></div>
                                                    <div className="select_btn"><label><input name="bk_allergy" type="radio"
                                                        value="Metal llergy" onClick={(e) => updateInputValue(e)} checked={clinicValues.bk_allergy == "Metal llergy" ? "checked" : ""} /><span>Metal llergy</span></label></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-5">
                                            <div className="form_group mb-20">
                                                <select name="bk_steroid_type" className="form-control select" onSelect={(e) => updateInputValue(e)} value={clinicValues.bk_steroid_type}>
                                                    <option value="">Select</option>
                                                    <option value="On steroid type">On steroid type</option>
                                                </select>
                                                <span className="dwn_aro material-icons"> arrow_drop_down </span>
                                            </div>
                                        </div>
                                        <div className="col-md-5">
                                            <div className="check_item">
                                                <div className="select_btns popup_select">
                                                    <div className="select_btn"><label><input name="bk_bleeding" type="radio"
                                                        value="Bleeding" onClick={(e) => updateInputValue(e)} checked={clinicValues.bk_bleeding == "Bleeding" ? "checked" : ""} /><span>Bleeding</span></label></div>
                                                    <div className="select_btn"><label><input name="bk_bleeding" type="radio"
                                                        value="Clotting problems" onClick={(e) => updateInputValue(e)} checked={clinicValues.bk_bleeding == "Clotting problems" ? "checked" : ""} /><span>Clotting problems</span></label>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-7">
                                            <div className="form_group mb-20">
                                                <select name="bk_anticoagulant" onSelect={(e) => updateInputValue(e)} value={clinicValues.bk_anticoagulant} className="form-control select">
                                                    <option value="">Select</option>
                                                    <option value="On Anticoagulant/antiplatelet therapy
                                                        Type">On Anticoagulant/antiplatelet therapy
                                                        Type</option>
                                                </select>
                                                <span className="dwn_aro material-icons"> arrow_drop_down </span>
                                            </div>
                                        </div>
                                        <div className="col-md-5">
                                            <div className="form_group mb-20">
                                                <div className="floating-label-group"><input type="text" name="bk_whentostop"
                                                    className="form-control" autocomplete="off" onChange={(e) => updateInputValue(e)} value={clinicValues.bk_whentostop} /><label
                                                        className="floating-label">When to be stopped</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-7">
                                            <div className="form_group mb-20">
                                                <div className="floating-label-group"><input type="text" name="bk_otherconcern"
                                                    className="form-control" autocomplete="off" onChange={(e) => updateInputValue(e)} value={clinicValues.bk_otherconcern} /><label
                                                        className="floating-label">OTHER CONCERNS</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="simple_wound ctr">
                                    <h2 className="popup_title">PROCEDURE DETAILS</h2>
                                    <div className="row">
                                        <div className="col-md-5">
                                            <div className="form_group mb-20">
                                                <div className="floating-label-group"><input type="text" name="bk_procedure"
                                                    className="form-control" autocomplete="off" onChange={(e) => updateInputValue(e)} value={clinicValues.bk_procedure} /><label
                                                        className="floating-label">PROCEDURE</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="form_group mb-20">
                                                <div className="floating-label-group"><input type="text" name="bk_ccsd"
                                                    className="form-control" autocomplete="off" onChange={(e) => updateInputValue(e)} value={clinicValues.bk_ccsd} /><label
                                                        className="floating-label">CCSD</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form_group mb-20">
                                                <div className="floating-label-group"><input type="text" name="bk_opcs"
                                                    className="form-control" autocomplete="off" onChange={(e) => updateInputValue(e)} value={clinicValues.bk_opcs} /><label
                                                        className="floating-label">OPCS codes</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="form_group">
                                                <div className="floating-label-group"><input type="text" name="bk_side"
                                                    className="form-control" autocomplete="off" onChange={(e) => updateInputValue(e)} value={clinicValues.bk_side} /><label
                                                        className="floating-label">SIDE</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="field_title">Add another Procedure</p>
                                    <div className="row">
                                        <div className="col-md-5">
                                            <div className="form_group mb-20">
                                                <div className="floating-label-group"><input type="text" name="bk_another_procedure"
                                                    className="form-control" autocomplete="off" onChange={(e) => updateInputValue(e)} value={clinicValues.bk_another_procedure} /><label
                                                        className="floating-label">PROCEDURE</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="form_group mb-20">
                                                <div className="floating-label-group"><input type="text" name="bk_another_ccsd"
                                                    className="form-control" autocomplete="off" onChange={(e) => updateInputValue(e)} value={clinicValues.bk_another_ccsd} /><label
                                                        className="floating-label">CCSD</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form_group mb-20">
                                                <div className="floating-label-group"><input type="text" name="bk_another_opcs"
                                                    className="form-control" autocomplete="off" onChange={(e) => updateInputValue(e)} value={clinicValues.bk_another_opcs} /><label
                                                        className="floating-label">OPCS codes</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="form_group mb-20">
                                                <div className="floating-label-group"><input type="text" name="bk_another_side"
                                                    className="form-control" autocomplete="off" onChange={(e) => updateInputValue(e)} value={clinicValues.bk_another_side} /><label
                                                        className="floating-label">SIDE</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="eds mb-20">
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text" name="bk_estimated_surgery"
                                                className="form-control" autocomplete="off" onChange={(e) => updateInputValue(e)} value={clinicValues.bk_estimated_surgery} /><label
                                                    className="floating-label">Estimated duration of surgery</label>
                                            </div>
                                        </div>
                                        <p className="field_title">Minutes</p>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="eds">
                                                <p className="field_title">Priority</p>
                                                <div className="form_group ml-15">
                                                    <div className="floating-label-group"><input type="text" name="bk_routine"
                                                        className="form-control" autocomplete="off" onChange={(e) => updateInputValue(e)} value={clinicValues.bk_routine} /><label
                                                            className="floating-label">Routine</label>
                                                    </div>
                                                </div>
                                                <p className="field_title">Urgent</p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group mb-20">
                                                <div className="floating-label-group"><input type="date" name="bk_expected_date"
                                                    className="form-control" autocomplete="off" onChange={(e) => updateInputValue(e)} value={clinicValues.bk_expected_date} /><label
                                                        className="floating-label">Expected date of surgery</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="check_item"><label className="check">Expected Stay</label>
                                                <div className="select_btns popup_select mt-0">
                                                    <div className="select_btn"><label><input name="bk_expected_stay" type="radio"
                                                        value="Day case" onClick={(e) => updateInputValue(e)} checked={clinicValues.bk_expected_stay == "Day case" ? "checked" : ""} /><span>Day case</span></label></div>
                                                    <div className="select_btn"><label><input name="bk_expected_stay" type="radio"
                                                        value="In-patient" onClick={(e) => updateInputValue(e)} checked={clinicValues.bk_expected_stay == "In-patient" ? "checked" : ""} /><span>In-patient</span></label></div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="eds eds2">

                                                <div className="form_group ml-15">
                                                    <div className="floating-label-group"><input type="text" name="bk_expected_length"
                                                        className="form-control" autocomplete="off" onChange={(e) => updateInputValue(e)} value={clinicValues.bk_expected_length} /><label
                                                            className="floating-label">Expected length of stay</label>
                                                    </div>
                                                </div>
                                                <p className="field_title">days</p>
                                            </div>
                                        </div>

                                    </div>


                                </div>
                                <div className="simple_wound ctr mt-20">
                                    <h2 className="popup_title">SURGEON</h2>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form_group mb-20">
                                                <div className="floating-label-group"><input type="text" name="bk_surgeon"
                                                    className="form-control" autocomplete="off" onChange={(e) => updateInputValue(e)} value={clinicValues.bk_surgeon} /><label
                                                        className="floating-label">Named Surgeon</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form_group mb-20">
                                                <div className="floating-label-group"><input type="text" name="bk_surgeon_phone"
                                                    className="form-control" autocomplete="off" onChange={(e) => updateInputValue(e)} value={clinicValues.bk_surgeon_phone} /><label
                                                        className="floating-label">Surgeon’s Phone Number</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="check_item"><label className="check">Procedure can be carried out by</label>
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input name="bk_procedure_carried" type="radio"
                                                value="Any consultant" onClick={(e) => updateInputValue(e)} checked={clinicValues.bk_procedure_carried == "Any consultant" ? "checked" : ""} /><span>Any consultant</span></label></div>
                                            <div className="select_btn"><label><input name="bk_procedure_carried" type="radio"
                                                value="SPR" onClick={(e) => updateInputValue(e)} checked={clinicValues.bk_procedure_carried == "SPR" ? "checked" : ""} /><span>SPR</span></label></div>
                                            <div className="select_btn"><label><input name="bk_procedure_carried" type="radio"
                                                value="Associate specialist" onClick={(e) => updateInputValue(e)} checked={clinicValues.bk_procedure_carried == "Associate specialist" ? "checked" : ""} /><span>Associate specialist</span></label></div>
                                            <div className="select_btn"><label><input name="bk_procedure_carried" type="radio"
                                                value="other HCP" onClick={(e) => updateInputValue(e)} checked={clinicValues.bk_procedure_carried == "other HCP" ? "checked" : ""} /><span>other HCP</span></label></div>

                                        </div>
                                    </div>
                                    <div className="check_item"><label className="check">ASSISTANCE</label>
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input name="bk_assistance" type="radio"
                                                value="Assistant Surgeon" onClick={(e) => updateInputValue(e)} checked={clinicValues.bk_assistance == "Assistant Surgeon" ? "checked" : ""} /><span>Assistant Surgeon</span></label></div>
                                            <div className="select_btn"><label><input name="bk_assistance" type="radio"
                                                value="Surgical first assistant" onClick={(e) => updateInputValue(e)} checked={clinicValues.bk_assistance == "Surgical first assistant" ? "checked" : ""} /><span>Surgical first assistant</span></label></div>
                                            <div className="select_btn"><label><input name="bk_assistance" type="radio"
                                                value="None required" onClick={(e) => updateInputValue(e)} checked={clinicValues.bk_assistance == "None required" ? "checked" : ""} /><span>None required</span></label></div>


                                        </div>
                                    </div>



                                </div>
                                <div className="simple_wound ctr mt-20">
                                    <h2 className="popup_title">ANAESTHESIA</h2>
                                    <div className="form_group mb-20">
                                        <select name="bk_anaesthesia" onClick={(e) => updateInputValue(e)} values={clinicValues.bk_anaesthesia} className="form-control select">
                                            <option value="">Select</option>
                                            <option value="OGA">OGA</option>
                                            <option value="Spinal">Spinal</option>
                                            <option value="Regional">Regional</option>
                                            <option value="Sedation">Sedation</option>
                                            <option value="LA">LA</option>
                                            <option value="Other">Other</option>
                                        </select>
                                        <span className="dwn_aro material-icons"> arrow_drop_down </span>
                                    </div>
                                    <div className="form_group mb-20">
                                        <div className="floating-label-group"><input type="text" name="bk_anaesthesia_other"
                                            className="form-control" autocomplete="off" onChange={(e) => updateInputValue(e)} value={clinicValues.bk_anaesthesia_other} /><label
                                                className="floating-label">Other</label>
                                        </div>
                                    </div>




                                    <div className="check_item"><label className="check">Pain Block</label>
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input name="bk_pain_block" type="radio"
                                                value="Yes" onClick={(e) => updateInputValue(e)} checked={clinicValues.bk_pain_block == "Yes" ? "checked" : ""} /><span>yes</span></label></div>
                                            <div className="select_btn"><label><input name="bk_pain_block" type="radio"
                                                value="No" onClick={(e) => updateInputValue(e)} checked={clinicValues.bk_pain_block == "No" ? "checked" : ""} /><span>No</span></label></div>


                                        </div>
                                    </div>

                                    <div className="check_item"><label className="check"> Assessment of risk by Anaesthetist Required</label>
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input name="bk_pain_assesment" type="radio"
                                                value="Yes" onClick={(e) => updateInputValue(e)} checked={clinicValues.bk_pain_assesment == "Yes" ? "checked" : ""} /><span>yes</span></label></div>
                                            <div className="select_btn"><label><input name="bk_pain_assesment" type="radio"
                                                value="No" onClick={(e) => updateInputValue(e)} checked={clinicValues.bk_pain_assesment == "No" ? "checked" : ""} /><span>No</span></label></div>


                                        </div>
                                    </div>



                                    <div className="check_item"><label className="check">Recovery</label>
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input name="bk_pain_recovery" type="radio"
                                                value="Normal" onClick={(e) => updateInputValue(e)} checked={clinicValues.bk_pain_recovery == "Normal" ? "checked" : ""} /><span>Normal</span></label></div>
                                            <div className="select_btn"><label><input name="bk_pain_recovery" type="radio"
                                                value="Extended Recovery" onClick={(e) => updateInputValue(e)} checked={clinicValues.bk_pain_recovery == "Extended Recovery" ? "checked" : ""} /><span>Extended Recovery</span></label></div>
                                            <div className="select_btn"><label><input name="bk_pain_recovery" type="radio"
                                                value="HDU bed required" onClick={(e) => updateInputValue(e)} checked={clinicValues.bk_pain_recovery == "HDU bed required" ? "checked" : ""} /><span>HDU bed required</span></label></div>
                                            <div className="select_btn"><label><input name="bk_pain_recovery" type="radio"
                                                value="ICU bed required" onClick={(e) => updateInputValue(e)} checked={clinicValues.bk_pain_recovery == "ICU bed required" ? "checked" : ""} /><span>ICU bed required</span></label></div>


                                        </div>
                                    </div>
                                </div>

                                <div className="simple_wound ctr mt-20">
                                    <h2 className="popup_title">SPECIAL EQUIPMENT &amp; PERSONNEL NEEDED</h2>

                                    {clinicValues.bk_special_equipment ? <div className="check_list w_white">
                                        <div className="check_item" onClick={(e) => updateClinicValues(e, "bk_special_equipment", false)} ><label className="check">{clinicValues.bk_special_equipment}</label></div>

                                    </div> : (clinicValues.bk_special_equipment == undefined || clinicValues.bk_special_equipment == false) &&
                                    <div className="check_list w_white">

                                        <div className="check_item" onClick={(e) => updateClinicValues(e, "bk_special_equipment", "Image Intensifier required")}><label className="check">Image Intensifier required</label>
                                        </div>

                                        <div className="check_item" onClick={(e) => updateClinicValues(e, "bk_special_equipment", "Radiographer needed")}><label className="check">Radiographer needed</label>
                                        </div>

                                    </div>}
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form_group mb-20">
                                                <div className="floating-label-group"><input type="text" name="bk_implants"
                                                    className="form-control" autocomplete="off" onChange={(e) => updateInputValue(e)} value={clinicValues.bk_implants} /><label
                                                        className="floating-label">Implants, grafts, and injectables:</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form_group mb-20">
                                                <select name="bk_implant_types" onSelect={(e) => updateInputValue(e)} value={clinicValues.bk_implant_types} className="form-control select">
                                                    <option value="">Type</option>
                                                    <option value="needlist">Need list</option>
                                                </select>
                                                <span className="dwn_aro material-icons"> arrow_drop_down </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="check_item"><label className="check">Company Contacted</label>
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input name="bk_company_contacted" type="radio"
                                                value="Yes"
                                                onClick={(e) => updateInputValue(e)} checked={clinicValues.bk_company_contacted == "Yes" ? "checked" : ""} /><span>Yes</span></label></div>
                                            <div className="select_btn"><label><input name="bk_company_contacted" type="radio"
                                                value="To be contacted"
                                                onClick={(e) => updateInputValue(e)} checked={clinicValues.bk_company_contacted == "To be contacted" ? "checked" : ""} /><span>To be contacted</span></label></div>
                                        </div>
                                    </div>
                                    <div className="check_list w_white">

                                        <div className="check_item"><label className="check">Microscope required</label>
                                        </div>
                                    </div>
                                    <div className="check_item">
                                        <div className="select_btns popup_select mt-0">
                                            <div className="select_btn"><label><input name="bk_microscope" type="radio"
                                                value="Frozen specimen" onClick={(e) => updateInputValue(e)} checked={clinicValues.bk_microscope == "Frozen specimen" ? "checked" : ""} /><span>Frozen specimen</span></label></div>
                                            <div className="select_btn"><label><input name="bk_microscope" type="radio"
                                                value="Biopsy" onClick={(e) => updateInputValue(e)} checked={clinicValues.bk_microscope == "Biopsy" ? "checked" : ""} /><span>Biopsy</span></label>
                                            </div>
                                            <div className="select_btn"><label><input name="bk_microscope" type="radio"
                                                value="Culture required" onClick={(e) => updateInputValue(e)} checked={clinicValues.bk_microscope == "Culture required" ? "checked" : ""} /><span>Culture required</span></label></div>
                                        </div>
                                    </div>
                                    <div className="form_group">
                                        <div className="floating-label-group"><input type="text" name="bk_spine_cases" onChange={(e) => updateInputValue(e)} value={clinicValues.bk_spine_cases}
                                            className="form-control" autocomplete="off" /><label
                                                className="floating-label">Spine cases - # of Levels</label>
                                        </div>
                                    </div>
                                </div>

                                <div className="simple_wound ctr mt-20">
                                    <div className="prb_search"><textarea placeholder="Comments" name="bk_comments" onChange={(e) => updateInputValue(e)} value={clinicValues.bk_comments}></textarea></div>
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="form_group">
                                                <div className="floating-label-group"><input type="text" name="bk_completed_by"
                                                    className="form-control" autocomplete="off" onChange={(e) => updateInputValue(e)} value={clinicValues.bk_completed_by} /><label
                                                        className="floating-label">Completed by</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form_group">
                                                <div className="floating-label-group"><input type="date" name="bk_completed_date"
                                                    className="form-control" autocomplete="off" onChange={(e) => updateInputValue(e)} value={clinicValues.bk_completed_date} /><label
                                                        className="floating-label">Date</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form_group">
                                                <div className="floating-label-group"><input type="text" name="bk_completed_time"
                                                    className="form-control" autocomplete="off" onChange={(e) => updateInputValue(e)} value={clinicValues.bk_completed_time} /><label
                                                        className="floating-label">Time</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form_group">
                                                <div className="floating-label-group"><input type="text" name="bk_completed_phone"
                                                    className="form-control" autocomplete="off" onChange={(e) => updateInputValue(e)} value={clinicValues.bk_completed_phone} /><label
                                                        className="floating-label">Phone #</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className='popup_footer'>
                            <div className="prescribe_footer"><button className="border_btn" onClick={(e) => { updateClinic(e), referralsSave("bookingForm"), setvisibleBookingForm(false) }}><span className="material-icons">save</span>SAVE </button>
                                <button onClick={(e) => { setvisibleBookingForm(false) }} className="btn dsp_flex"><span className="material-icons"><span className="material-icons">close</span></span>DISCARD </button></div>
                        </div>
                    </div>
                </div>
            }

            {visibleConsentForm &&
                <div className='popup_wrp'>
                    <div className="popup_full prescribe">
                        <div className='popup_hed'>
                            <h2>CONSENT</h2>
                            <a className='popup_close' onClick={(e) => { setvisibleConsentForm(false) }}><span className="material-icons">close</span></a>
                        </div>
                        <div className='popup_content'>

                            <div className='popup_scroll main pr '>

                                <div className="simple_wound ctr cont consent">
                                    {(clinicValues.clinic_consent == undefined || clinicValues.clinic_consent == "consent1") &&
                                        <div className="consent_btn" onClick={(e) => setvisibleConsentForm1(true)}>
                                            <a className="icon_btn"> CONSENT 1 </a>
                                            <div className="material-symbols-outlined info tooltip1"> info
                                                <span className="tooltiptext">For adults or competent young adults.</span>
                                            </div>
                                        </div>}
                                    {(clinicValues.clinic_consent == undefined || clinicValues.clinic_consent == "consent2") &&
                                        <div className="consent_btn" onClick={(e) => setvisibleConsentForm2(true)}>
                                            <a className="icon_btn"> CONSENT 2 </a>
                                            <div className="material-symbols-outlined info tooltip1"> info
                                                <span className="tooltiptext">For parental consent for a child or young
                                                    person.</span>
                                            </div>
                                        </div>}
                                    {(clinicValues.clinic_consent == undefined || clinicValues.clinic_consent == "consent3") &&
                                        <div className="consent_btn" onClick={(e) => setvisibleConsentForm3(true)}>
                                            <a className="icon_btn"> CONSENT 3</a>
                                            <div className="material-symbols-outlined info tooltip1"> info
                                                <span className="tooltiptext tt_3">For adults/young person and child in cases where
                                                    it is
                                                    envisaged that the patient will
                                                    remain alert throughout the procedure and no anaesthetist will be involved
                                                    in their care. The use of
                                                    form 3 is optional, but may be thought more appropriate than form 1 in
                                                    situations where patients do
                                                    not need to be made aware of issues surrounding general or regional
                                                    anaesthesia, and do not need
                                                    to make any advance decisions about additional procedures because they will
                                                    be in a position to
                                                    make any such decisions at the time if necessary.</span>
                                            </div>
                                        </div>}
                                    {(clinicValues.clinic_consent == undefined || clinicValues.clinic_consent == "consent4") &&
                                        <div className="consent_btn" onClick={(e) => setvisibleConsentForm4(true)}>
                                            <a className="icon_btn"> CONSENT 4 </a>
                                            <div className="material-symbols-outlined info tooltip1"> info
                                                <span className="tooltiptext tt_4">For adults who are unable to consent to
                                                    investigation
                                                    or treatment.</span>
                                            </div>
                                        </div>}

                                </div>

                            </div>
                        </div>
                        <div className='popup_footer'>
                            <div className="prescribe_footer">
                                {/* <button className="border_btn"><span className="material-icons">save</span>SAVE </button> */}
                                <button className="btn dsp_flex" onClick={(e) => { setvisibleConsentForm(false) }}><span
                                    className="material-icons"><span className="material-icons">close</span></span>DISCARD
                                </button></div>
                        </div>
                    </div>
                </div>
            }


            {visibleConsentForm1 &&
                <Consent1 setvisibleConsentForm1={(e) => { setvisibleConsentForm1(e) }} />
            }

            {visibleConsentForm2 &&
                <Consent2 setvisibleConsentForm2={(e) => { setvisibleConsentForm2(e) }} />
            }

            {visibleConsentForm3 &&
                <Consent3 setvisibleConsentForm3={(e) => { setvisibleConsentForm3(e) }} />
            }


            {visibleConsentForm4 &&
                <Consent4 setvisibleConsentForm4={(e) => { setvisibleConsentForm4(e) }} />
            }



        </React.Fragment>
    )
}

Clinic.propTypes = {
    t: PropTypes.any,
    getWards: PropTypes.func,
    getTheatres: PropTypes.func,
    wards: PropTypes.array,
    user: PropTypes.any,
    theatres: PropTypes.array,
    clinic: PropTypes.array,
    patients: PropTypes.array,
    doctors: PropTypes.array,
    profile: PropTypes.object
}

const mapStateToProps = ({ Layout, Login }) => ({
    wards: Layout.wards,
    user: Login.user,
    theatres: Layout.theatres,
    clinic: Layout.clinic,
    patients: Layout.patients,
    doctors: Layout.doctors,
    profile: Layout.profile
})

const mapDispatchToProps = dispatch => ({
    getWards: () => dispatch(getWardsList()),
    getTheatres: () => dispatch(getTheatresList()),
    getClinic: () => dispatch(getClinicList()),
    getPatient: () => dispatch(getPatientList()),
    getUserprofile: (id) => dispatch(getUprofile(id)),
    getDoctor: () => dispatch(getDoctorList()),
    patientupdate: (id, data) => dispatch(updatePatient(id, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Clinic)
