import React from "react"
import { Container, Row, Col } from "reactstrap"
import FooterLogo from '../../assets/images/footer_logo.png';
const Footer = () => {
  return (
    <React.Fragment>
      <div className="footer">
            <div className="wrapper wrp">
              <div className="footer_logo">
                <img src={FooterLogo} />
              </div>
              <div><div className="dropdown">
                <a className="footer_submenu" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                  To get the best out of IPR<span className="material-icons"> arrow_drop_up</span>
                </a>
                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <li><a className="dropdown-item" href="#">Action</a></li>
                  <li><a className="dropdown-item" href="#">Another action</a></li>
                  <li><a className="dropdown-item" href="#">Something else here</a></li>
                </ul>
              </div></div>
              <div className="footer_menu">
                <a href="#" className="footer_link"><span className="material-icons">bedtime</span> Sleep</a>
                <a href="#" onClick={(e) => setVisible("login")} className="footer_link"><span className="material-icons">power_settings_new</span> logout</a>
              </div>
            </div>
          </div>
    </React.Fragment>
  )
}

export default Footer
