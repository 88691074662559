import React from "react";

function IconPlus(props) {
  const { iconColor = "#14427D", iconSize = 14 } = props;
  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6 6V0H8V6H14V8H8V14H6V8H0V6H6Z" fill={iconColor} />
    </svg>
  );
}

export default IconPlus;
