import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Pages Calendar
import Calendar from "../pages/Calendar/index"

//Email
import EmailInbox from "../pages/Email/email-inbox"
import EmailRead from "../pages/Email/email-read"
import EmailCompose from "../pages/Email/email-compose"

import Emailtemplatealert from "../pages/EmailTemplate/email-template-alert";
import Emailtemplatebasic from "../pages/EmailTemplate/email-template-basic";
import Emailtemplatebilling from "../pages/EmailTemplate/email-template-billing";

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

//  // Inner Authentication
import Login2 from "../pages/AuthenticationInner/Login2"
import Register1 from "../pages/AuthenticationInner/Register"
import Register2 from "../pages/AuthenticationInner/Register2"
import Recoverpw from "../pages/AuthenticationInner/Recoverpw"
import Recoverpw2 from "../pages/AuthenticationInner/Recoverpw2"
import ForgetPwd1 from "../pages/AuthenticationInner/ForgetPassword"
import LockScreen from "../pages/AuthenticationInner/auth-lock-screen"
import LockScreen2 from "../pages/AuthenticationInner/auth-lock-screen-2"
import ConfirmMail from "../pages/AuthenticationInner/page-confirm-mail"
import ConfirmMail2 from "../pages/AuthenticationInner/page-confirm-mail-2"
import EmailVerification from "../pages/AuthenticationInner/auth-email-verification"
import EmailVerification2 from "../pages/AuthenticationInner/auth-email-verification-2"
import TwostepVerification from "../pages/AuthenticationInner/auth-two-step-verification"
import TwostepVerification2 from "../pages/AuthenticationInner/auth-two-step-verification-2"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
// Theatre
import Theatre from "../pages/Theatre/index"
import TPatients from "../pages/Theatre/patients"
import TheatreNew from "../pages/Theatre/theatreList"
import TPatient from "../pages/Theatre/Tpatient"
import SessionTeam from "../pages/Theatre/sessionTeam"
import NursingPick from "../pages/Theatre/nursingpick"
import MachineCheck from "../pages/Theatre/MachineCheck"
import TeamBrief from "../pages/Theatre/TeamBrief"
import TheatreR from "../pages/Theatre/theatreR"
import TheatreLanding from "../pages/Theatre/landing"
import TheatreFlow from "../pages/Theatre/theatreFlow"
import preOpAssesment from "../pages/Theatre/preOpAssesment"
import periOperative from "../pages/Theatre/periOperative"
import surgicalRecord from "../pages/Theatre/surgical"
import surgicalConsent2 from "../pages/Theatre/surgical_consent2"
import surgicalConsent3 from "../pages/Theatre/surgical_consent3"
import surgicalConsent4 from "../pages/Theatre/surgical_consent4"
import recoveryRecord from "../pages/Theatre/recovery"
import calendarReactSchedule from "../pages/Theatre/calendar-react-schedule"
import theatreLatest from "../pages/Theatre/theatreLatest"

// Patient profile
import PatientProfile from "../pages/Profile/patient"
// Clinic
import Clinic from "../pages/Clinic/index"
import ClinicLanding from "../pages/Clinic/landing"
import clinicLandingLatest from "../pages/Clinic/clinicLanding"

// Ward
import WardLanding from "../pages/Ward/wLanding"
import WardHome from "../pages/Ward/Landing"
import LegalSection from "../pages/Ward/LegalSection"
import PatientInfo from "../pages/Ward/PatientInfo"
import News from "../pages/Ward/News"
import WardList from "../pages/Ward/WardList"
import FrontSheet from "../pages/Ward/FrontSheet"
import IcePopup from "../pages/Ward/Ice_Popup"
import PacsPopup from "../pages/Ward/Pacs_Popup"
import DemoGraphics from "../pages/Ward/DemoGraphics"
import Todos from "../pages/Ward/todo"
import DischargeCheckList from "../pages/Ward/dischargeChecklist"
import FluidBalance from "../pages/Ward/fluidBalance"
import DischargePlanning from "../pages/Ward/dischargePlanning"
import WeekendHandover from "../pages/Ward/weekendHandover"
import AdvanceStatement from "../pages/Ward/advanceStatement"
import LifeTimeRecord from "../pages/Ward/LifetimeRecord"
import Allied from "../pages/Ward/Allied"
import Procedures from "../pages/Ward/Procedures"
import DemoGraphicsData from "../pages/Ward/DemoGraphicsData"
import Interventions from "../pages/Ward/Interventions"
// import MyOfficeLanding from "../pages/Ward/MyOfficeLanding"
import MyOffice from "../pages/Ward/myOffice"
import MyContacts from "../pages/Ward/MyContacts"
import MyFiles from "../pages/Ward/MyFiles"
import ToDoList from "../pages/Ward/ToDoList"
import PostOpComplications from "../pages/Ward/PostOpComplications"

import CropImage from "../pages/Ward/Cropper"

// Lab
import LabDetails from "../pages/Lab/details"

// Charts
import ChartApex from "../pages/Charts/Apexcharts"
import ChartistChart from "../pages/Charts/ChartistChart"
import ChartjsChart from "../pages/Charts/ChartjsChart"
import EChart from "../pages/Charts/EChart"
import SparklineChart from "../pages/Charts/SparklineChart"

// Maps
import MapsGoogle from "../pages/Maps/MapsGoogle"
import MapsVector from "../pages/Maps/MapsVector"
import MapsLeaflet from "../pages/Maps/MapsLeaflet"

//Icons
import IconDripicons from "../pages/Icons/IconDripicons"
import IconMaterialdesign from "../pages/Icons/IconMaterialdesign"
import TypiconsIcon from "../pages/Icons/IconTypicons"
import IconIon from "../pages/Icons/IconIon";
import ThemifyIcon from "../pages/Icons/IconThemify"
import IconFontawesome from "../pages/Icons/IconFontawesome"

//Tables
import BasicTables from "../pages/Tables/BasicTables"
import DatatableTables from "../pages/Tables/DatatableTables"
import ResponsiveTables from "../pages/Tables/ResponsiveTables"
import EditableTables from "../pages/Tables/EditableTables"

// Forms
import FormElements from "../pages/Forms/FormElements"
import FormAdvanced from "../pages/Forms/FormAdvanced"
import FormEditors from "../pages/Forms/FormEditors"
import FormValidations from "../pages/Forms/FormValidations"
import FormMask from "../pages/Forms/FormMask"
import FormRepeater from "../pages/Forms/FormRepeater"
import FormUpload from "../pages/Forms/FormUpload"
import FormWizard from "../pages/Forms/FormWizard"
import FormXeditable from "../pages/Forms/FormXeditable"

//Ui
import UiAlert from "../pages/Ui/UiAlert"
import UiButtons from "../pages/Ui/UiButtons"
import UiCards from "../pages/Ui/UiCards"
import UiCarousel from "../pages/Ui/UiCarousel"
import UiColors from "../pages/Ui/UiColors"
import UiDropdown from "../pages/Ui/UiDropdown"
import UiGeneral from "../pages/Ui/UiGeneral"
import UiGrid from "../pages/Ui/UiGrid"
import UiImages from "../pages/Ui/UiImages"
import UiLightbox from "../pages/Ui/UiLightbox"
import UiModal from "../pages/Ui/UiModal"
import UiProgressbar from "../pages/Ui/UiProgressbar"
// import UiSweetAlert from "../pages/Ui/UiSweetAlert"
import UiTabsAccordions from "../pages/Ui/UiTabsAccordions"
import UiTypography from "../pages/Ui/UiTypography"
import UiVideo from "../pages/Ui/UiVideo"
// import UiSessionTimeout from "../pages/Ui/UiSessionTimeout"
// import UiRating from "../pages/Ui/UiRating"
import UiRangeSlider from "../pages/Ui/UiRangeSlider"

//Pages
import PagesStarter from "../pages/Utility/pages-starter"
import PagesMaintenance from "../pages/Utility/pages-maintenance"
import SelectAdmin from "../pages/Utility/select-administration"
import PagesComingsoon from "../pages/Utility/pages-comingsoon"
import PagesTimeline from "../pages/Utility/pages-timeline"
import PagesInvoice from "../pages/Utility/PagesInvoice";
import PagesFaqs from "../pages/Utility/pages-faqs"
import PagesPricing from "../pages/Utility/pages-pricing"
import Pages404 from "../pages/Utility/pages-404"
import Pages500 from "../pages/Utility/pages-500"
import PagesGallery from "../pages/Utility/PagesGallery";
import PagesDirectory from "../pages/Utility/PagesDirectory";


//Software and Device Administration
import ConfigManagement from "../pages/Software/config"
import RemoteSupport from "../pages/Software/remote"

// Patient and User Records
import Patients from "../pages/Patients/patient"
import Anesthetic from "../pages/Theatre/anesthetic"

// Patient and User Records
import VideoChat from "../pages/VideoChat/index"

// Nurse Patients
import NursePatientConsult from "../pages/Nurse/index"
import NurseLanding from "../pages/Nurse/nurseLanding"
import NursePatientList from "../pages/Nurse/patients"
import RiskAssesment from "../pages/Nurse/riskAssesment"
import Assesment from "../pages/Nurse/assesment"
import Infection from "../pages/Nurse/infection"
import Safeguarding from "../pages/Nurse/safeguarding"
import MentalState from "../pages/Nurse/mentalstate"
import VipScore from "../pages/Nurse/vipscore"
import Smoking from "../pages/Nurse/smoking"
import Dementia from "../pages/Nurse/dementia"
import DrugAlcohol from "../pages/Nurse/drugalcohol"
import SkinAssesment from "../pages/Nurse/skinassesment"
import Nutrition from "../pages/Nurse/nutrition"
import VTERisk from "../pages/Nurse/vterisk"
import MovingHandling from "../pages/Nurse/movinghandling"
import SafeHandling from "../pages/Nurse/safehandling"
import Delirium from "../pages/Nurse/delirium"
import Continence from "../pages/Nurse/continence"
import SuperVision from "../pages/Nurse/superVision"
import FallsRisk from "../pages/Nurse/fallsRisk"
import NursingAdmission from "../pages/Nurse/nursingAdmission"

import TheTeam from "../pages/OnCall/team"
import onCall from "../pages/OnCall/onCall"
import onCallMeet from "../pages/OnCall/onCallMeet"
import teamMembers from "../pages/OnCall/TeamList"
import onCallList from "../pages/OnCall/onCallList"
import Fracture from "../pages/OnCall/fracture"
import AddNewPatient from "../pages/OnCall/addNewPatient"
import Trauma from "../pages/OnCall/trauma"
import SericalSpinal from "../pages/OnCall/sericalSpinal"
import SericalSpinalList from "../pages/OnCall/sericalSpinalList"
import PList from "../pages/OnCall/patientList"

import underConstruction from "../pages/OnCall/underConstruction"

import patientRecord from "../pages/PatientRecord/patientRecord"
import patientRecordDetails from "../pages/PatientRecord/patientRecordDetails"

//CME Centre
import QuizCategory from "../components/pages/Category/category";
import CategoryDocument from "../components/pages/Category/CategoryDocument/categorydocument";
import CategoryDocumentDetails from "../components/pages/Category/CategoryDocumentDetail/categorydocumentdetails";
import Quiz from "../components/pages/Quiz/quiz";
import Question from "../components/pages/Quiz/Question/question";
import Result from "../components/pages/Quiz/Result/result";
import Progress from "../components/pages/Quiz/Progress/progress";
import PatientPictures from "../components/pages/PatientPictures/PatientPictures";
import PatientInformation from "../components/pages/PatientInformation/PatientInformation";
import onCallLanding from "../pages/OnCall/onCallLanding"
import nursingOffice from "../pages/Nurse/nursingOffice"
import theatrePatient from "../pages/Theatre/theatrePatient"
import myPreferences from "../pages/Ward/myPreferences"



const userRoutes = [
  { path: "/dashboard", component: Dashboard },

 
  { path: "/patients", component: TPatients },
  { path: "/patientprofile", component: PatientProfile },
  
  { path: "/clinic", component: Clinic },
  { path: "/clanding", component: ClinicLanding },
  { path: "/cl", component: clinicLandingLatest },


  { path: "/lab", component: LabDetails },
  

  // CME Centre
  { path: "/category", component: QuizCategory },
  { path: "/categorydocuments", component: CategoryDocument },
  { path: "/categorydocumentdetails", component: CategoryDocumentDetails },
  { path: "/quiz", component: Quiz },
  { path: "/questions", component: Question },
  { path: "/result", component: Result },
  { path: "/progress", component: Progress },
  { path: "/patient-pictures", component: PatientPictures },
  { path: "/patient-information", component: PatientInformation },


  // Nurse 
  { path: "/npatient", component: NursePatientConsult },
  { path: "/nlanding", component: NurseLanding },
  { path: "/plist", component: NursePatientList },
  { path: "/riskassesment", component: RiskAssesment },
  { path: "/noffice", component: nursingOffice },
  { path: "/assesment", component: Assesment },
  { path: "/infection", component: Infection },
  { path: "/safeguarding", component: Safeguarding },
  { path: "/mentalstate", component: MentalState },
  { path: "/vip", component: VipScore },
  { path: "/smoking", component: Smoking },
  { path: "/dementia", component: Dementia },
  { path: "/drug", component: DrugAlcohol },
  { path: "/skinassesment", component: SkinAssesment },
  { path: "/nutrition", component: Nutrition },
  { path: "/vterisk", component: VTERisk },
  { path: "/moving", component: MovingHandling },
  { path: "/safe", component: SafeHandling },
  { path: "/delirium", component: Delirium },
  { path: "/continence", component: Continence },
  { path: "/supervision", component: SuperVision },
  { path: "/fallsrisk", component: FallsRisk },
  { path: "/nursingadmission", component: NursingAdmission },

  // OnCall
  { path: "/team", component: TheTeam },
  { path: "/oncall", component: onCall },
  { path: "/ol", component: onCallLanding },
  { path: "/oncallmeet", component: onCallMeet },
  { path: "/teamlist", component: teamMembers },
  { path: "/calllist", component: onCallList },
  { path: "/fracture", component: Fracture },
  { path: "/addpatient", component: AddNewPatient },
  { path: "/trauma", component: Trauma },
  { path: "/serical", component: SericalSpinal },
  { path: "/sericallist", component: SericalSpinalList },
  { path: "/onlist", component: PList },

  { path: "/uc", component: underConstruction },

  // Patient Record
  { path: "/pr", component: patientRecord },
  { path: "/prdetails", component: patientRecordDetails },
  
  
  // Software and Device Administration
  { path: "/config", component: ConfigManagement },
  { path: "/remote", component: RemoteSupport },

  // Patient and User Records
  { path: "/patient", component: Patients },

  // Video Chat
  { path: "/vc", component: VideoChat },

  // Theatre
  { path: "/theatre2", component: Theatre },
  { path: "/theatrelandingold", component: TheatreLanding },
  { path: "/theatre", component: theatreLatest },
  { path: "/tlist", component: TheatreNew },
  // { path: "/tpatient", component: TPatient },
  { path: "/tpatient", component: theatrePatient },
  { path: "/sessionteam", component: SessionTeam },
  { path: "/nursingpick", component: NursingPick },
  { path: "/machine", component: MachineCheck },
  { path: "/teambrief", component: TeamBrief },
  { path: "/theatreR", component: TheatreR },
  { path: "/tflow", component: TheatreFlow },
  { path: "/preop", component: preOpAssesment },
  { path: "/periop", component: periOperative },
  { path: "/anesthetic", component: Anesthetic },
  { path: "/surgery", component: surgicalRecord },
  { path: "/surgery_consent2", component: surgicalConsent2 },
  { path: "/surgery_consent3", component: surgicalConsent3 },
  { path: "/surgery_consent4", component: surgicalConsent4 },
  
  { path: "/recovery", component: recoveryRecord },
   { path: "/cal4", component: calendarReactSchedule },

   // ward
   { path: "/wl", component: WardLanding },
   { path: "/ward", component: WardHome },
   { path: "/legal", component: LegalSection },
   { path: "/pinfo", component: PatientInfo },
   { path: "/news", component: News },
   { path: "/wlist", component: WardList },
   { path: "/front", component: FrontSheet },
   { path: "/crop", component: CropImage },
   { path: "/ice", component: IcePopup },
   { path: "/pacs", component: PacsPopup },
   { path: "/demo", component: DemoGraphics },
   { path: "/todos", component: Todos },
   { path: "/discharge", component: DischargeCheckList },
   { path: "/fluid", component: FluidBalance },
   { path: "/dischargePlanning", component: DischargePlanning },
   { path: "/weekendhandover", component: WeekendHandover },
   { path: "/advanceStatement", component: AdvanceStatement },
   { path: "/lifetimerecord", component: LifeTimeRecord },
   { path: "/allied", component: Allied },
   { path: "/procedure", component: Procedures },
   { path: "/demographicsdata", component: DemoGraphicsData },
   { path: "/interventions", component: Interventions },
  //  { path: "/myoffice", component: MyOfficeLanding },
   { path: "/myoffice", component: MyOffice },
   { path: "/mycontacts", component: MyContacts },
   { path: "/todolist", component: ToDoList },
   { path: "/postop", component: PostOpComplications },
   { path: "/files/:filename", component: MyFiles },
   { path: "/mypreferences", component: myPreferences },
   
  // //calendar
  { path: "/calendar", component: Calendar },

  // //profile
  { path: "/profile", component: UserProfile },

  //Email
  { path: "/email-inbox", component: EmailInbox },
  { path: "/email-read", component: EmailRead },
  { path: "/email-compose", component: EmailCompose },

    // Email Template
    { path: "/email-template-alert", component: Emailtemplatealert },
    { path: "/email-template-basic", component: Emailtemplatebasic },
    { path: "/email-template-billing", component: Emailtemplatebilling },
  
  //Charts
  { path: "/apex-charts", component: ChartApex },
  { path: "/chartist-charts", component: ChartistChart },
  { path: "/chartjs-charts", component: ChartjsChart },
  { path: "/e-charts", component: EChart },
  { path: "/sparkline-charts", component: SparklineChart },

  // Icons
  { path: "/icons-dripicons", component: IconDripicons },
  { path: "/icons-materialdesign", component: IconMaterialdesign },
  { path: "/icons-fontawesome", component: IconFontawesome },
  { path: "/icons-ion", component: IconIon },
  { path: "/icons-themify", component: ThemifyIcon },
  { path: "/icons-typicons", component: TypiconsIcon },

  // Tables
  { path: "/tables-basic", component: BasicTables },
  { path: "/tables-datatable", component: DatatableTables },
  { path: "/tables-responsive", component: ResponsiveTables },
  { path: "/tables-editable", component: EditableTables },

  // Maps
  { path: "/maps-google", component: MapsGoogle },
  { path: "/maps-vector", component: MapsVector },
  { path: "/maps-leaflet", component: MapsLeaflet },

  // Forms
  { path: "/form-elements", component: FormElements },
  { path: "/form-advanced", component: FormAdvanced },
  { path: "/form-editors", component: FormEditors },
  { path: "/form-mask", component: FormMask },
  { path: "/form-repeater", component: FormRepeater },
  { path: "/form-uploads", component: FormUpload },
  { path: "/form-wizard", component: FormWizard },
  { path: "/form-validation", component: FormValidations },
  { path: "/form-xeditable", component: FormXeditable },

  // Ui
  { path: "/ui-alerts", component: UiAlert },
  { path: "/ui-buttons", component: UiButtons },
  { path: "/ui-cards", component: UiCards },
  { path: "/ui-carousel", component: UiCarousel },
  { path: "/ui-colors", component: UiColors },
  { path: "/ui-dropdowns", component: UiDropdown },
  { path: "/ui-general", component: UiGeneral },
  { path: "/ui-grid", component: UiGrid },
  { path: "/ui-images", component: UiImages },
  { path: "/ui-lightbox", component: UiLightbox },
  { path: "/ui-modals", component: UiModal },
  { path: "/ui-progressbars", component: UiProgressbar },
  // { path: "/ui-sweet-alert", component: UiSweetAlert },
  { path: "/ui-tabs-accordions", component: UiTabsAccordions },
  { path: "/ui-typography", component: UiTypography },
  { path: "/ui-video", component: UiVideo },
  // { path: "/ui-session-timeout", component: UiSessionTimeout },
  // { path: "/ui-rating", component: UiRating },
  { path: "/ui-rangeslider", component: UiRangeSlider },

  //Utility
  { path: "/pages-starter", component: PagesStarter },
  { path: "/pages-timeline", component: PagesTimeline },
  { path: "/pages-invoice", component: PagesInvoice },
  { path: "/pages-directory", component: PagesDirectory },
  { path: "/pages-faqs", component: PagesFaqs },
  { path: "/pages-pricing", component: PagesPricing },
  { path: "/pages-gallery", component: PagesGallery },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/login-2", component: Login2 },
  { path: "/forgot-password", component: ForgetPwd },
  // { path: "/register", component: Register },
  { path: "/admin", component: SelectAdmin },
  { path: "/pages-maintenance", component: PagesMaintenance },
  { path: "/pages-comingsoon", component: PagesComingsoon },
  { path: "/pages-404", component: Pages404 },
  { path: "/pages-500", component: Pages500 },

  // Authentication Inner
  { path: "/pages-login-2", component: Login2 },
  { path: "/pages-register", component: Register1 },
  { path: "/pages-register-2", component: Register2 },
  { path: "/page-recoverpw", component: Recoverpw },
  { path: "/page-recoverpw-2", component: Recoverpw2 },
  { path: "/pages-forgot-pwd", component: ForgetPwd1 },
  { path: "/auth-lock-screen", component: LockScreen },
  { path: "/auth-lock-screen-2", component: LockScreen2 },
  { path: "/page-confirm-mail", component: ConfirmMail },
  { path: "/page-confirm-mail-2", component: ConfirmMail2 },
  { path: "/auth-email-verification", component: EmailVerification },
  { path: "/auth-email-verification-2", component: EmailVerification2 },
  { path: "/auth-two-step-verification", component: TwostepVerification },
  { path: "/auth-two-step-verification-2", component: TwostepVerification2 },
]

export { userRoutes, authRoutes }
