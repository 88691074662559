import styles from "./index.module.scss";
import { useLocation, useHistory } from "react-router-dom";
import { IconSuccess } from "../../../icons/index.jsx";
import { exportComponentAsJPEG } from "react-component-export-image";
// import ClearIcon from "@mui/icons-material/Clear";
import imgData from "../../../../assets/images/certificate_template.png";

import { createRef } from "react";
const Result = () => {
  const location = useLocation();
  const history = useHistory();
  const user = JSON.parse(localStorage.getItem("p"));
  const {
    correctAnswers,
    date,
    time,
    totalQuestions,
    categoryName,
    timeTakenInSeconds,
    subCategoryName,
  } = location.state || {};
  const certificateRef = createRef();
  const handleSubmit = () => {
     history.push("/progress");
  };
  console.log(subCategoryName);
  const handleCertificate = () => {
    exportComponentAsJPEG(certificateRef, {
      html2CanvasOptions: { backgroundColor: null },
    });
  };

  return (
    <div className={styles["page-container"]}>
      <div className={styles["result-container"]}>
        <div className={styles["result-top"]}>
          {correctAnswers == totalQuestions ? (
            <IconSuccess />
          ) : (
            // <ClearIcon
            //   color="white"
            //   sx={{ background: "red", color: "white", borderRadius: "50%" }}
            // />
            <p>Clear</p>
          )}

          {correctAnswers == totalQuestions ? "Quiz completed" : "Quiz Failed"}
        </div>
        <div className={styles["middle-div"]}>
          <div className={styles["congrats-div"]}>
            <div className={styles["congrats-label"]}>
              {" "}
              {correctAnswers == totalQuestions
                ? "You have successfully completed the knowledge review"
                : "Better Luck Next Time!"}
            </div>
            <div
              className={styles["successful-label"]}
              hidden={correctAnswers !== totalQuestions}
            >
              You have successfully completed the knowledge review
            </div>
          </div>

          <div className={styles["result-score"]}>
            <div className={styles["correct-label"]}>
              {correctAnswers}
              <span className={styles["total-label"]}>/{totalQuestions}</span>
            </div>
            <div className={styles["score-label"]}>Your score</div>
          </div>
          <div className={styles["box-wrapper"]}>
            <div className={styles["box-wrapper-section"]}>
              <span className={styles["box-wrapper-head"]}>At</span>
              <span className={styles["box-wrapper-content"]}>{time}</span>
            </div>
            <div className={styles["box-wrapper-section"]}>
              <span className={styles["box-wrapper-head"]}>On</span>
              <span className={styles["box-wrapper-content"]}>{date}</span>
            </div>
            <div className={styles["box-wrapper-section"]}>
              <span className={styles["box-wrapper-head"]}>
                Time taken to read the topic
              </span>
              <span className={styles["box-wrapper-content"]}>
                {timeTakenInSeconds} Mins
              </span>
            </div>
          </div>
        </div>
        <div className={styles["button-container"]}>
          <div
            className={styles["added-label"]}
            hidden={correctAnswers !== totalQuestions}
          >
            Your CME event has been successfully added to your learning record
          </div>
          <button
            className={styles["submit-button"]}
            onClick={() => handleSubmit()}
          >
            Okay
          </button>
        </div>
        <button
          className={styles["certificate-button"]}
          onClick={() => handleCertificate()}
          hidden={correctAnswers !== totalQuestions}
        >
          Download Certficate
        </button>
        <div className={styles["downloadWrapper"]} ref={certificateRef}>
          <div className={styles["certificateWrapper"]}>
            <p className={styles["name"]}>{user?.firstname + " " + user?.lastname}</p>
            <img src={imgData} alt="Certificate" />
            <h2 className={styles["sub_category"]}>{subCategoryName}</h2>
            <h1 className={styles["category"]}>{categoryName}</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Result;
