import React, { useState, useEffect } from "react"
import MetaTags from 'react-meta-tags';
import TheatreSubHeader from "./theatreSubHeader";

const TheatreLanding = (props) => {
    const dropdownMenuClick = (e, menu) => {
        props.history.push("/" + menu)
    }

    return (
        <React.Fragment>

            <MetaTags>
                <title>Theatre</title>
            </MetaTags>
            <div className="dashboard theatre">
                <TheatreSubHeader backPage="dashboard" />
                <div className="main">
                    <div className="wrapper">
                        <div className="tl_list">
                            <ul className="accordion" id="accordionExample">
                                <li> <a onClick={(e) => dropdownMenuClick(e, 'teambrief')} className="list_item">Team Brief/ Debrief</a> </li>
                                <li> <a onClick={(e) => dropdownMenuClick(e, 'tlist')} className="list_item">Theatre List</a> </li>
                                <div className="list_item">
                                    <div className="accordion-item">
                                        <button className=" accordion-button btn" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            Session
                                        </button>
                                        <div id="collapseOne" className="accordion-collapse collapse show"
                                            aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">

                                                <a onClick={(e) => dropdownMenuClick(e, 'sessionteam')} className="sub_item">SESSION TEAM</a>
                                                <a onClick={(e) => dropdownMenuClick(e, 'machine')} className="sub_item">MACHINE EQUIPMENTS</a>
                                                <a onClick={(e) => dropdownMenuClick(e, 'nursingpick')} className="sub_item">ADVANCE PREPARATION</a>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <li> <a onClick={(e) => dropdownMenuClick(e, 'tflow')} className="list_item">Theatre Flow</a> </li>

                                <div className=" list_item">

                                    <div className="accordion-item">

                                        <button className=" accordion-button collapsed btn " type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                                            Reports
                                        </button>

                                        <div id="collapseTwo" className="accordion-collapse collapse show" aria-labelledby="headingTwo"
                                            data-bs-parent="#accordionExample2">
                                            <div className="accordion-body">

                                                <a className="sub_item">SESSION REPORT</a>
                                                <a onClick={(e) => dropdownMenuClick(e, 'theatreR')} className="sub_item">PRODUCTIVITY REPORT</a>
                                                <a className="sub_item">FINANCE REPORT</a>
                                                <a className="sub_item">PATIENT QUALITY REPORT</a>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </ul>

                        </div>
                    </div>
                </div>

            </div>

        </React.Fragment>
    )
}

export default TheatreLanding

