import PropTypes from 'prop-types'
import React, { useState, useEffect, useCallback } from "react"
import MetaTags from 'react-meta-tags';
import xray from '../../assets/images/x-ray.jpg';
import mri from '../../assets/images/mri.jpg';
import ct from '../../assets/images/ctscan.jpg';
import us from '../../assets/images/us_scan.jpg';
import otherscan from '../../assets/images/otherscan.jpg';
import speciality_scan from '../../assets/images/speciality_scan.png';
import PatientBlock from "../Clinic/patientBlock";
import Cropper from 'react-easy-crop'
import getCroppedImg from './crop'
import { removeSpecialCharacters } from '../../utils/utils';
import { Buffer } from 'buffer';

import { connect } from "react-redux"
import { getWardsList, getTheatresList, getClinicList, getPatientList, getUprofile, getDoctorList, updatePatient } from "../../store/actions"

const PacsPopup = props => {


    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [rotation, setRotation] = useState(0)
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [croppedImage, setCroppedImage] = useState(null)
    const [popupimage, setImage] = useState({});

    const pacpops = [{
        image: xray,
        title: "X Ray",
        value: "pacs_xray"
    }, {
        image: mri,
        title: "MRI",
        value: "pacs_mri"
    }, {
        image: ct,
        title: "CT",
        value: "pacs_ct"
    }, {
        image: us,
        title: "US",
        value: "pacs_us"
    }, {
        image: otherscan,
        title: "Others",
        value: "pacs_other_scan"
    }, {
        image: speciality_scan,
        title: "Speciality Specific Tests",
        value: "pacs_speciality_scan"
    }]

    const {
        patients
    } = props;

    useEffect(() => {
        const { getDoctor, getPatient, getUserprofile } = props
        getPatient()
        getDoctor()
        let userId = localStorage.getItem("authUser")
        getUserprofile(userId)
    }, [])


    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    const showCroppedImage = useCallback(async () => {
        try {
            const croppedImage = await getCroppedImg(
                popupimage.image,
                croppedAreaPixels,
                rotation
            )
            console.log('donee', { croppedImage })
            sigUpload(croppedImage, popupimage.filename)
            setCroppedImage(croppedImage)

        } catch (e) {
            console.error(e)
        }
    }, [croppedAreaPixels, rotation])

    const sigUpload = async (filedata, name) => {
        const file = filedata
        //console.log("file ", file, name)
        let filename = name + ".png";
        // const base64 = await convertBase64(file)
        //console.log("filename ", filename)
        let pdetails = JSON.parse(localStorage.getItem("p"))

        const dataf = file.replace(/^data:image\/\w+;base64,/, "");

        const buf = Buffer.from(dataf, "base64");
        let uploadFilePath = "ipr/" + pdetails._id + "/" + name + "_result" + "/" + removeSpecialCharacters(filename);
        let data = { source_bucket: "ipr-resources", sourcepath: uploadFilePath }
        // console.log("data ", data)

        const response = await fetch(API_URL+'upload?appname=ipr', {
            method: "POST",
            mode: "cors",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
        const result = await response.json();
        // console.log("result ", result, buf.length)
        let url = result.result
        // console.log("url ", url)
        const base64Data = new Buffer.from(filedata.replace(/^data:image\/\w+;base64,/, ""), 'base64');
        // Getting the file type, ie: jpeg, png or gif
        const type = filedata.split(';')[0].split('/')[1];
        const uploadImage = await fetch(url, {
            method: "PUT",
            mode: "cors",
            headers: {
                'Content-Type': `image/${type}`,
                'Content-Encoding': 'base64',
                ACL: 'public-read',
            },
            body: base64Data
        })

        //console.log("resultImage ", uploadImage)

        let imagePath = "https://ipr-resources.s3.amazonaws.com/"
        let imageUploadPath = imagePath + uploadFilePath;


        const { patientupdate } = props
        let updateObj = {
            [name + "_result"]: imageUploadPath,
            [name + "_update"]: new Date()
        }
        patientupdate(pdetails._id, updateObj)


        let patient = {
            ...pdetails,
            ...updateObj
        }
        localStorage.setItem("p", JSON.stringify(patient))


    }

    const getimageHeight = async (e, source, filename) => {
        e.preventDefault()
        const img = new Image();
        img.src = source;
        img.onload = async () => {
            //console.log(img.height);
            //console.log(img.width);
            let croppedArea = { width: img.width, height: img.height, x: 0, y: 0 }
            const croppedImage = await getCroppedImg(
                source,
                croppedArea,
                rotation
            )

            //console.log('croppedAreaPixels', { croppedArea, rotation, source })
            //console.log('donee', { croppedImage })
            sigUpload(croppedImage, filename)

        };
    }

    const wardBack = (e) => {
        e.preventDefault()
        props.history.push("/wl")
        document.body.style.overflow = '';
    }

    let clinicPatient = JSON.parse(localStorage.getItem("p"))



    return (
        <React.Fragment>

            <MetaTags>
                <title>Pacs</title>
            </MetaTags>
            <div className='ward '>
                <div className="popup_wrp">
                    <div className="popup_full prescribe">
                        <div className="popup_hed">
                            <h2>PACS </h2>
                            <div className="dsp-flex"> <a className="popup_close" onClick={(e) => { wardBack(e) }}><span className="material-icons">close</span></a></div>

                        </div>
                        <div className="popup_content">
                            <div className="popup_scroll w-btns main ">

                                <PatientBlock patient={clinicPatient} />
                                <div className="section ctr pacs">
                                    <div className="row thumbs">

                                    {pacpops.map((item, i) => (
                                            <div className="col-md-4" key={i}>
                                                <div className="thumbnail" >
                                                    <div className='img_block'>
                                                        {/* <button className='capture_btn' onClick={(e) => { getimageHeight(e, item.image, item.value) }}> <span className="material-icons">
                                                            crop_free
                                                        </span> </button> */}
                                                        <img data-bs-toggle="modal" onClick={(e) => { setImage({ "image": item.image, "filename": item.value }) }} data-bs-target="#xray-popup" src={item.image} className="img" alt="" /></div>
                                                    <h3>{item.title}</h3>
                                                    <div className="btn-group" role="group" aria-label="Basic outlined example">
                                                        <button type="button" className='btn' data-bs-toggle="modal" onClick={(e) => { setImage({ "image": item.image, "filename": item.value }) }} data-bs-target="#xray-popup"> <span className="material-icons"> crop_free </span> View</button>
                                                        <button type="button" onClick={(e) => { getimageHeight(e, item.image, item.value) }} className="btn"> <span className="material-icons"> center_focus_weak </span> Capture</button>
                                                    </div>
                                                </div>

                                            </div>


                                        ))}

                                    </div>
                                </div>

                                <div className="modal xray_popup fade" id="xray-popup" aria-labelledby="exampleModalLabel"
                                    aria-hidden="true">
                                    <div className="modal-dialog guidance">
                                        <div className="modal-content">
                                            <div className="modal-header">

                                                <button type="button" data-bs-dismiss="modal" aria-label="Close" className="material-icons close">close</button>
                                            </div>
                                            <div className="modal-body">
                                                <Cropper
                                                    image={popupimage.image}
                                                    crop={crop}
                                                    rotation={rotation}
                                                    zoom={zoom}
                                                    aspect={4 / 3}
                                                    onCropChange={setCrop}
                                                    onRotationChange={setRotation}
                                                    onCropComplete={onCropComplete}
                                                    onZoomChange={setZoom}
                                                />

                                            </div>
                                            <div className='modal-footer'>
                                                <button className="border_btn" data-bs-dismiss="modal" aria-label="Close" onClick={(e) => { showCroppedImage() }}><span className="material-icons">save_as</span>SAVE &amp;
                                                    CLOSE
                                                </button>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="popup_footer">
                            <div className="prescribe_footer">


                                <button className="btn dsp_flex" onClick={(e) => { wardBack(e) }}><span className="material-icons"><span
                                    className="material-icons">close</span></span>DISCARD </button>
                            </div>
                        </div>


                    </div>

                </div>
            </div>


        </React.Fragment>
    )
}

PacsPopup.propTypes = {
    getWards: PropTypes.func,
    getTheatres: PropTypes.func,
    wards: PropTypes.array,
    user: PropTypes.any,
    theatres: PropTypes.array,
    clinic: PropTypes.array,
    patients: PropTypes.array,
    doctors: PropTypes.array,
    profile: PropTypes.object
}

const mapStateToProps = ({ Layout, Login }) => ({
    wards: Layout.wards,
    user: Login.user,
    theatres: Layout.theatres,
    clinic: Layout.clinic,
    patients: Layout.patients,
    doctors: Layout.doctors,
    profile: Layout.profile
})

const mapDispatchToProps = dispatch => ({
    getWards: () => dispatch(getWardsList()),
    getTheatres: () => dispatch(getTheatresList()),
    getClinic: () => dispatch(getClinicList()),
    getPatient: () => dispatch(getPatientList()),
    getUserprofile: (id) => dispatch(getUprofile(id)),
    getDoctor: () => dispatch(getDoctorList()),
    patientupdate: (id, data) => dispatch(updatePatient(id, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(PacsPopup)
