import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import MetaTags from 'react-meta-tags';


import PatientBlock from "../Clinic/patientBlock";
import { connect } from "react-redux"
import { getWardsList, getTheatresList, getClinicList, getPatientList, getUprofile, getDoctorList, updatePatient } from "../../store/actions"

const SuperVision = props => {


    const [visiblePatients, setPatient] = useState(true)
    const [visiblelanding, setClanding] = useState(false)
    const [successmessage, setSuccess] = useState("")


    const [assesmentValues, setAssesment] = useState({})

    const {
        wards, user, theatres, clinic, patients, profile, doctors
    } = props;

    useEffect(() => {
        const { getWards, getTheatres, getClinic, getDoctor, getPatient, getUserprofile } = props
        // getWards()
        // getTheatres()
        // getClinic()
        getPatient()
        getDoctor()
        let userId = localStorage.getItem("authUser")
        getUserprofile(userId)
    }, [])

    const updateRadioValue = (e) => {
        let val = e.target.value;
        let name = e.target.name;
        let updatedValue = { [name]: val };
        setAssesment(assesmentValues => ({
            ...assesmentValues,
            ...updatedValue
        }));
    }


    const closeClick = (e) => {
        e.preventDefault()
        localStorage.removeItem("a")
        props.history.push("/riskassesment")
    }

    let assesmentPage = JSON.parse(localStorage.getItem("a"))
    let clinicPatient = JSON.parse(localStorage.getItem("p"))

    return (
        <React.Fragment>

            <MetaTags>
                <title>{assesmentPage}</title>
            </MetaTags>
            <div className="dashboard ward">
                <div className="main">

                    <div className="popup_wrp">
                        <div className="popup_full prescribe">
                            <div className="popup_hed">
                                <h2>DAILY SUPERVISION & BED RAILS </h2>
                                <div className="dsp-flex"> <a className="popup_close" onClick={(e) => closeClick(e)}><span className="material-icons">close</span></a></div>

                            </div>
                            <div className="popup_content">
                                <div className="popup_scroll w-btns main nutrition">


                                    <div className="modal add_form guidance_popup fade" id="guidance" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                        <div className="modal-dialog" role="document">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title">GUIDANCE</h5>
                                                    <span className="material-icons" data-dismiss="modal" aria-label="Close">
                                                        close
                                                    </span>
                                                </div>
                                                <div className="modal-body">
                                                    <p>Observe cannula 8 hourly or more frequently if clinically indicated
                                                    </p>
                                                    <p>Secure cannula with an appropriate dressing – change if soiled or contaminated
                                                    </p>
                                                    <p>Aseptic technique must be followed for insertion</p>
                                                    <p>Consider re-siting the cannula every 48-72 hours or as indicated by VIP score
                                                        Plan and document care
                                                    </p>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <PatientBlock patient={clinicPatient} />
                                    <div className="bar">
                                        <div className="left">
                                            <div className="tag" data-toggle="modal"
                                                data-target="#guidance"> Guidance </div>
                                            <h3 className="sub_title">DAILY SUPERVISION & BED RAILS </h3>
                                        </div>
                                        <div className="right">
                                            <a href="#" className="btn"> <span className="material-symbols-outlined"> assignment </span> Not
                                                Yet Done</a>
                                            <div className="tag"> Due </div>
                                        </div>
                                    </div>


                                    <div className="section ctr pua shr">
                                        <h2 className="popup_title">LEVEL OF SUPERVISION REQUIRED </h2>
                                        <p className="field_title">Risk of Falls</p>
                                        <div className="sub_section">
                                            <div className="poc green">
                                                <div className="check-box">
                                                    <div className="round">
                                                        <input type="checkbox" id="checkbox-33" />
                                                        <label for="checkbox-33"></label>
                                                    </div>
                                                    <div>
                                                        <p> <span>No history of falls / No falls risk identified </span> </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="poc yello">
                                                <div className="check-box w-100">
                                                    <div className="round">
                                                        <input type="checkbox" id="checkbox-34" />
                                                        <label for="checkbox-34"></label>
                                                    </div>
                                                    <div>
                                                        <p>
                                                            <span>History of falls</span> <br />
                                                            Requires supervision or assistance to transfer or mobilise/Experiencing
                                                            dizziness/Likely to remember to use call bell
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="poc red">
                                                <div className="check-box w-100">
                                                    <div className="round">
                                                        <input type="checkbox" id="checkbox-35" />
                                                        <label for="checkbox-35"></label>
                                                    </div>
                                                    <div>
                                                        <p>
                                                            <span>At risk of falls </span> <br />
                                                            With one or more of the following: An actual fall has
                                                            occurred/Impulsive/Non-Compliant with using call bell/AMBER Level
                                                            interventions have not made patient safe
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="poc purple">
                                                <div className="check-box w-100">
                                                    <div className="round">
                                                        <input type="checkbox" id="checkbox-36" />
                                                        <label for="checkbox-36"></label>
                                                    </div>
                                                    <div>
                                                        <p>
                                                            <span>Significant risk of falls </span> <br />
                                                            With serious harm and one or more of the following: All RED Actions have
                                                            been attempted but risk remains/An actual fall with harm has occurred
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <p className="field_title">Risk of getting up unaided Or Attempting to leave ward</p>
                                        <div className="sub_section">
                                            <div className="poc green">
                                                <div className="check-box">
                                                    <div className="round">
                                                        <input type="checkbox" id="checkbox-41" />
                                                        <label for="checkbox-41"></label>
                                                    </div>
                                                    <div>
                                                        <p> <span>Independently mobile around ward area with/without aid</span> </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="poc yello">
                                                <div className="check-box w-100">
                                                    <div className="round">
                                                        <input type="checkbox" id="checkbox-42" />
                                                        <label for="checkbox-42"></label>
                                                    </div>
                                                    <div>
                                                        <p>
                                                            <span>At risk of getting up unaided or attempting to leave the
                                                                ward</span>

                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="poc red">
                                                <div className="check-box w-100">
                                                    <div className="round">
                                                        <input type="checkbox" id="checkbox-43" />
                                                        <label for="checkbox-43"></label>
                                                    </div>
                                                    <div>
                                                        <p>
                                                            <span>Showing signs of attempting to stand or leave the
                                                                ward/Unpredictable </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="poc purple">
                                                <div className="check-box w-100">
                                                    <div className="round">
                                                        <input type="checkbox" id="checkbox-44" />
                                                        <label for="checkbox-44"></label>
                                                    </div>
                                                    <div>
                                                        <p>
                                                            <span>Wandering and/or standing unaided/Attempting to leave ward </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <p className="field_title">Confusion/Delirium or Dementia</p>
                                        <div className="sub_section">
                                            <div className="poc green">
                                                <div className="check-box">
                                                    <div className="round">
                                                        <input type="checkbox" id="checkbox-51" />
                                                        <label for="checkbox-51"></label>
                                                    </div>
                                                    <div>
                                                        <p> <span>No evidence of confusion or delirium</span> </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="poc yello">
                                                <div className="check-box w-100">
                                                    <div className="round">
                                                        <input type="checkbox" id="checkbox-52" />
                                                        <label for="checkbox-52"></label>
                                                    </div>
                                                    <div>
                                                        <p>
                                                            <span>Mild confusion</span> <br />
                                                            Requires intermittent reassurance and re-orientation to Ward
                                                            area/Responds to distraction techniques
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="poc red">
                                                <div className="check-box w-100">
                                                    <div className="round">
                                                        <input type="checkbox" id="checkbox-53" />
                                                        <label for="checkbox-53"></label>
                                                    </div>
                                                    <div>
                                                        <p>
                                                            <span>Moderate confusion </span> <br />
                                                            Frequently agitated and restless/Requires regular reassurance and
                                                            re-orientation to Ward environment/At risk of pulling out indwelling
                                                            device/Unable to make needs known/Lack of insight into risk
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="poc purple">
                                                <div className="check-box w-100">
                                                    <div className="round">
                                                        <input type="checkbox" id="checkbox-54" />
                                                        <label for="checkbox-54"></label>
                                                    </div>
                                                    <div>
                                                        <p>
                                                            <span>Severe confusion </span> <br />
                                                            with regular episodes of agitation, hallucinations, violent behaviour
                                                            and/or aggression towards staff, other patients or relatives/Delirium
                                                            due to alcohol withdrawal/Unstable Mental Health
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="sub_section">
                                            <p className="field_title green">Routine Supervision. Re-assess all patients daily.</p>
                                            <p className="ps1"><b>Interventions: </b> <br />
                                                Routine Ward Based Care
                                            </p>
                                        </div>
                                        <div className="sub_section">
                                            <p className="field_title yello">Intermittent Supervision - Where possible in line of sight
                                                Maintain hourly checks during the day and 15 minute checks during the night
                                            </p>
                                            <p className="ps1"><b>Interventions: </b> </p>
                                            <ol className="mt-10">
                                                <li>Consider location of allocated bed-Use Enhanced Care Plan </li>
                                                <li>‘This is Me’ Document</li>
                                                <li>Review medications with Doctor and Pharmacist</li>
                                                <li>Communicate and escalate at safety Huddle</li>
                                                <li>Consider additional Family Support-Johns campaign</li>
                                            </ol>
                                        </div>
                                        <div className="sub_section">
                                            <p className="field_title red">Continual Cohorting of at risk patients -1 staff member per
                                                bay (within eyesight)
                                            </p>
                                            <p className="ps1"><b>Interventions: </b> </p>
                                            <ol className="mt-10">
                                                <li>Distraction techniques to be utilised </li>
                                                <li>Consider use of low beds/crash mats</li>
                                                <li>Commence patient engagement activities</li>
                                                <li>Request additional family support</li>
                                                <li>Consider: MCA assessment - DOLS application - Mental Health Assessment -
                                                    Alcohol withdrawal assessment - Referral to Care of the Elderly Team
                                                </li>
                                            </ol>
                                        </div>
                                        <div className="sub_section">
                                            <p className="field_title purple">Continuous 1:1supervision. Patient must not be left
                                                unsupervised at any time.
                                            </p>

                                            <ol className="mt-10">
                                                <li>The 1:1 level of supervision to be maintained day and night unless identified
                                                    otherwise by ward sister. If staffing levels cannot support patient, escalate as
                                                    per hospital ‘Safe Staffing Escalation Policy’. </li>
                                                <li>Request family to support.</li>

                                            </ol>
                                        </div>
                                    </div>

                                    <div className="section ctr srf">
                                        <h2 className="popup_title">SUPERVISION RECORD FORM</h2>
                                        <div className="time_hed">
                                            <div className="date"> 4/8/2017</div>
                                            <div className="time_block">
                                                <a href="#" className="time">1:00</a>
                                                <a href="#" className="time">2:00</a>
                                                <a href="#" className="time">3:00</a>
                                                <a href="#" className="time">4:00</a>
                                                <a href="#" className="time">5:00</a>
                                                <a href="#" className="time">6:00</a>
                                                <a href="#" className="time">7:00</a>
                                                <a href="#" className="time">8:00</a>
                                                <a href="#" className="time">9:00</a>
                                                <a href="#" className="time">10:00</a>
                                                <a href="#" className="time">11:00</a>
                                                <a href="#" className="time">12:00</a>
                                            </div>
                                        </div>
                                        <div className="fb_table is_tab mb-20">
                                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home2" type="button"
                                                        role="tab">Assessments</button>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile2" type="button"
                                                        role="tab">Interventions</button>
                                                </li>

                                            </ul>
                                            <div className="tab-content" id="myTabContent">
                                                <div className="tab-pane fade show active" id="home2" role="tabpanel" aria-labelledby="home-tab">
                                                    <div className="section ctr fb_table pua is">
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className="check_item mt-0"><label className="check">Falls
                                                                </label>
                                                                    <div className="select_btns popup_select mt-0">
                                                                        <div className="select_btn"><label><input name="site" type="radio"
                                                                            value="Site 1" /><span>Amber
                                                                            </span></label></div>
                                                                        <div className="select_btn"><label><input name="site" type="radio"
                                                                            value="Site 2" /><span>History of
                                                                                falls </span></label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">

                                                                <div className="check_item mt-0"><label className="check">Mobility/
                                                                    Absconding risk
                                                                </label>
                                                                    <div className="select_btns popup_select mt-0">
                                                                        <div className="select_btn"><label><input name="site" type="radio"
                                                                            value="Site 1" /><span>Amber
                                                                            </span></label></div>
                                                                        <div className="select_btn"><label><input name="site" type="radio"
                                                                            value="Site 2" /><span>Risk of
                                                                                absconding
                                                                            </span></label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="check_item mt-0"><label className="check">Confusion/ Delirium or
                                                            Dementia
                                                        </label>
                                                            <div className="select_btns popup_select mt-0">
                                                                <div className="select_btn"><label><input name="site" type="radio" value="Site 1" /><span>Amber
                                                                </span></label></div>
                                                                <div className="select_btn"><label><input name="site" type="radio"
                                                                    value="Site 2" /><span>Confusion</span></label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <p className="ps1">Level of supervision required after interventions</p>
                                                        <p className="field_title">FREQUENCY OF OBSERVATIONS <br />
                                                            <span className="ps3">(i.e. maximum time between checks)</span>
                                                        </p>
                                                        <div className="form_group mt-10">
                                                            <label className="label">SPECIFIC DETAILS </label>
                                                            <textarea className="form-control" rows="2"></textarea>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className="yn_switch mb-15 mt-10">
                                                                    <div className="label-container">
                                                                        <p>NEED FOR BEDRAILS </p>
                                                                    </div>
                                                                    <label for="check814" className="switch-container">
                                                                        <input hidden="" type="checkbox" className="switch_check" id="check814" />
                                                                        <div className="switch-bg"></div>
                                                                        <div className="round-box"></div>
                                                                        <div className="switch-left">
                                                                            <span>NO</span>
                                                                        </div>
                                                                        <div className="switch-right">
                                                                            <span>YES</span>
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="yn_switch mb-15 mt-10">
                                                                    <div className="label-container">
                                                                        <p>NEED FOR LOW BED </p>
                                                                    </div>
                                                                    <label for="check815" className="switch-container">
                                                                        <input hidden="" type="checkbox" className="switch_check" id="check815" />
                                                                        <div className="switch-bg"></div>
                                                                        <div className="round-box"></div>
                                                                        <div className="switch-left">
                                                                            <span>NO</span>
                                                                        </div>
                                                                        <div className="switch-right">
                                                                            <span>YES</span>
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form_group mt-0">
                                                            <label className="label">GUIDELINES FOR REDUCING LEVELS OF SUPERVISION
                                                            </label>
                                                            <textarea className="form-control" rows="2"></textarea>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <div className="form_group mt-10">
                                                                    <label className="label">NEXT REVIEW </label>
                                                                    <input type="date" className="form-control" placeholder="Enter" />
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>
                                                <div className="tab-pane fade" id="profile2" role="tabpanel" aria-labelledby="profile-tab">

                                                    <div className="section ctr ">
                                                        <div className="check_item ">
                                                            <div className="select_btns popup_select">
                                                                <div className="select_btn"><label><input name="site" type="radio" value="Site 1" /><span>Amber
                                                                    1 - 5 for falls
                                                                </span></label></div>
                                                                <div className="select_btn"><label><input name="site" type="radio" value="Site 2" /><span> Amber
                                                                    1-9</span></label> </div>
                                                                <div className="select_btn"><label><input name="site" type="radio" value="Site 2" /><span>Amber
                                                                    1-5 </span></label></div>
                                                            </div>
                                                        </div>
                                                        <div className="check_item ">
                                                            <div className="select_btns popup_select">
                                                                <div className="select_btn"><label><input name="site" type="radio" value="Site 1" /><span>Day:
                                                                    Amber </span></label></div>
                                                                <div className="select_btn"><label><input name="site" type="radio" value="Site 2" /><span>
                                                                    Night: Amber</span></label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <p className="ps1 mt-10">5 minutes - 10 minutes 15 minutes - 20 minutes 30
                                                            minutes - 45 minutes 60 minutes </p>
                                                        <div className="form_group mt-0">
                                                            <label className="label">Other </label>
                                                            <input type="text" className="form-control" placeholder="Enter" />
                                                            <p className="ps3 mt-5">(e.g. to be supervised in toilet) </p>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="section ctr">
                                        <h2 className="popup_title">SAFETY IN HOSPITAL BED ASSESSMENT <span className="field_title">- Update
                                            as required.</span></h2>
                                        <div className="sub_section">
                                            <p className="sub_title">NURSE CALL BELL COMPLIANCE ASSESSMENT</p>
                                            <div className="form_group mt-10">
                                                <label className="label">Patient unlikely to use Nurse call buzzer</label>
                                                <input type="text" className="form-control" placeholder="Enter" />
                                            </div>
                                            <div className="check_item mt-0">
                                                <div className="select_btns popup_select mt-10">
                                                    <div className="select_btn"><label><input name="site" type="radio" value="Site 1" /><span>Due to cognitive
                                                        or physical condition
                                                    </span></label></div>
                                                    <div className="select_btn"><label><input name="site" type="radio" value="Site 2" /><span> Due to being
                                                        non-compliant, despite
                                                        capacity.</span></label>
                                                    </div>
                                                </div>
                                            </div>
                                            <p className="ps1">Patient placed within sight.</p>
                                            <div className="yn_switch mb-10 mt-15">
                                                <div className="label-container">
                                                    <p>Patient likely to use call buzzer </p>
                                                </div>
                                                <label for="check10" className="switch-container">
                                                    <input hidden="" type="checkbox" className="switch_check" id="check10" />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>NO</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>YES</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="check_item mt-0">
                                                <div className="select_btns popup_select mt-10">
                                                    <div className="select_btn"><label><input name="site" type="radio" value="Site 1" /><span>Call bell
                                                    </span></label></div>
                                                    <div className="select_btn"><label><input name="site" type="radio" value="Site 2" /><span>light is within
                                                        reach and patient - family
                                                        instructed on its use.</span></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="sub_section">
                                            <p className="sub_title">NEED FOR SAFETY SIDES (BED RAILS) ASSESSMENT</p>
                                            <div className="check_item mt-0">
                                                <div className="select_btns popup_select mt-10">
                                                    <div className="select_btn"><label><input name="site" type="radio" value="Site 1" /><span>Unconscious
                                                        patient </span></label></div>
                                                    <div className="select_btn"><label><input name="site" type="radio" value="Site 2" /><span>Needs safety sides
                                                    </span></label>
                                                    </div>
                                                </div>
                                            </div>
                                            <p className="ps1">Bed rails are in the upright position to protect patient from fall.</p>
                                            <div className="check_item mt-10">
                                                <div className="select_btns popup_select mt-10">
                                                    <div className="select_btn"><label><input name="site" type="radio" value="Site 1" /><span>Conscious but
                                                        restless patient
                                                    </span></label></div>
                                                    <div className="select_btn"><label><input name="site" type="radio" value="Site 2" /><span>Needs safety sides
                                                        + safety
                                                        bumper</span></label>
                                                    </div>
                                                </div>
                                            </div>
                                            <p className="ps1">Bed rails are in the upright position to protect patient from fall.</p>
                                            <div className="check_item mt-10">
                                                <div className="select_btns popup_select mt-10">
                                                    <div className="select_btn"><label><input name="site" type="radio" value="Site 1" /><span>Patient is likely
                                                        to roll out of bed
                                                    </span></label></div>
                                                    <div className="select_btn"><label><input name="site" type="radio" value="Site 2" /><span>Needs safety
                                                        sides. Consider air mattress
                                                    </span></label>
                                                    </div>
                                                </div>
                                            </div>
                                            <p className="ps1">Bed rails are in the upright position to protect patient from fall.</p>
                                            <div className="check_item mt-10">
                                                <div className="select_btns popup_select mt-10">
                                                    <div className="select_btn"><label><input name="site" type="radio" value="Site 1" /><span>Patient requested
                                                        safety sides
                                                    </span></label></div>
                                                    <div className="select_btn"><label><input name="site" type="radio" value="Site 2" /><span>Needs safety
                                                        sides</span></label>
                                                    </div>
                                                </div>
                                            </div>
                                            <p className="ps1">Bed rails are in the upright position to protect patient from fall.</p>
                                            <div className="check_item mt-10">
                                                <div className="select_btns popup_select mt-10">
                                                    <div className="select_btn"><label><input name="site" type="radio" value="Site 1" /><span>Need for safety
                                                        sides (bed rails) assessed
                                                    </span></label></div>
                                                    <div className="select_btn"><label><input name="site" type="radio" value="Site 2" /><span>Does not need
                                                        safety sides.</span></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="sub_section">
                                            <p className="sub_title">NEED FOR LOW BED ASSESSMENT</p>
                                            <div className="check_item mt-10">
                                                <div className="select_btns popup_select mt-10">
                                                    <div className="select_btn"><label><input name="site" type="radio" value="Site 1" /><span>Patient restless
                                                        or agitated or likely to climb over safety sides
                                                    </span></label></div>
                                                    <div className="select_btn"><label><input name="site" type="radio" value="Site 2" /><span>Needs Low
                                                        bed</span></label>
                                                    </div>
                                                </div>
                                            </div>
                                            <p className="ps1">Bed height is at the lowest position</p>
                                            <div className="check_item mt-10">
                                                <div className="select_btns popup_select mt-10">
                                                    <div className="select_btn"><label><input name="site" type="radio" value="Site 1" /><span>Patient frequently
                                                        attempts to stand / mobilise
                                                    </span></label></div>
                                                    <div className="select_btn"><label><input name="site" type="radio" value="Site 2" /><span>Needs Low
                                                        bed</span></label>
                                                    </div>
                                                </div>
                                            </div>
                                            <p className="ps1">Bed height is at the lowest position</p>
                                            <div className="form_group mt-10">
                                                <label className="label">To order or return Low/Bariatric Low beds, Click on</label>
                                                <input type="text" className="form-control" placeholder="Enter" />
                                            </div>
                                            <div className="check_item mt-10">
                                                <div className="select_btns popup_select mt-0">
                                                    <div className="select_btn"><label><input name="site" type="radio" value="Site 1" /><span>Need for low bed
                                                        assessed
                                                    </span></label></div>
                                                    <div className="select_btn"><label><input name="site" type="radio" value="Site 2" /><span>Patient does not
                                                        require low bed.</span></label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="check_item mt-10">
                                                <div className="select_btns popup_select mt-0">
                                                    <div className="select_btn"><label><input name="site" type="radio" value="Site 1" /><span>Patient needs
                                                        safety measures Day & Night
                                                    </span></label></div>
                                                    <div className="select_btn"><label><input name="site" type="radio" value="Site 2" /><span>Patient needs
                                                        safety measures only at night</span></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="section ctr">
                                        <h2 className="field_title mt-0">“NEED FOR BED RAILS” ASSESSMENT </h2>
                                        <p className="ps1">GUIDANCE: Where appropriate ensure that the patient and their family are involved in the decision
                                            making process
                                            If there is conflicting evidence, then using professional judgement in conjunction with the above assessment
                                            will allow you to determine whether or not to use bed rails. Please document your rationale in the comments
                                            section below.
                                        </p>
                                    </div>
                                    <div className="section ctr">
                                        <h2 className="popup_title dsp_flex">BEDRAIL RE-ASSESSMENT RECORD <span className="field_title mt-0">Date: 08-02-2024</span>
                                        </h2>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <p className="field_title">Indication for use</p>
                                                <div className="check-box sqeare  mt-10">
                                                    <div className="round">
                                                        <input type="checkbox" id="checkbox-711" />
                                                        <label for="checkbox-711"></label>
                                                    </div>
                                                    Fluctuating conscious levels
                                                </div>
                                                <div className="check-box sqeare  mt-10">
                                                    <div className="round">
                                                        <input type="checkbox" id="checkbox-712" />
                                                        <label for="checkbox-712"></label>
                                                    </div>
                                                    Sensory loss
                                                </div>
                                                <div className="check-box sqeare  mt-10">
                                                    <div className="round">
                                                        <input type="checkbox" id="checkbox-713" />
                                                        <label for="checkbox-713"></label>
                                                    </div>
                                                    Lack of spatial awareness
                                                </div>
                                                <div className="check-box sqeare  mt-10">
                                                    <div className="round">
                                                        <input type="checkbox" id="checkbox-714" />
                                                        <label for="checkbox-714"></label>
                                                    </div>
                                                    Physical limitations / to support the patient
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <p className="field_title">Indications for non-use</p>
                                                <div className="check-box sqeare  mt-10">
                                                    <div className="round">
                                                        <input type="checkbox" id="checkbox-715" />
                                                        <label for="checkbox-715"></label>
                                                    </div>
                                                    Agitation / confusion
                                                </div>
                                                <div className="check-box sqeare  mt-10">
                                                    <div className="round">
                                                        <input type="checkbox" id="checkbox-716" />
                                                        <label for="checkbox-716"></label>
                                                    </div>
                                                    Risk of patient climbing over the bed rails
                                                </div>
                                                <div className="check-box sqeare  mt-10">
                                                    <div className="round">
                                                        <input type="checkbox" id="checkbox-717" />
                                                        <label for="checkbox-717"></label>
                                                    </div>
                                                    Patient totally immobile
                                                </div>
                                                <div className="check-box sqeare  mt-10">
                                                    <div className="round">
                                                        <input type="checkbox" id="checkbox-718" />
                                                        <label for="checkbox-718"></label>
                                                    </div>
                                                    Aware of limitations
                                                </div>
                                            </div>
                                        </div>
                                        <div className="sub_section">
                                            <p className="sub_title">Outcome of assessment:</p>
                                            <div className="check-box sqeare  mt-10">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-716" />
                                                    <label for="checkbox-716"></label>
                                                </div>
                                                Bed rails are indicated
                                            </div>
                                            <div className="check-box sqeare  mt-10">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-717" />
                                                    <label for="checkbox-717"></label>
                                                </div>
                                                They are appropriate for the type of bed and securely attached.
                                            </div>
                                            <div className="check-box sqeare  mt-10">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-718" />
                                                    <label for="checkbox-718"></label>
                                                </div>
                                                Bed rail bumpers are required to prevent entrapment or patient harm
                                            </div>
                                            <div className="check-box sqeare  mt-10">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-719" />
                                                    <label for="checkbox-719"></label>
                                                </div>
                                                Family has been informed of the decision
                                            </div>
                                            <div className="check-box sqeare  mt-10">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-720" />
                                                    <label for="checkbox-720"></label>
                                                </div>
                                                Patient/carer has been involved in the decision
                                            </div>
                                            <div className="form_group mt-0">
                                                <label className="label">Comments
                                                </label>
                                                <textarea className="form-control" rows="2"></textarea>
                                            </div>
                                            <div className="check-box sqeare  mt-10">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-721" />
                                                    <label for="checkbox-721"></label>
                                                </div>
                                                The bed is at its lowest height
                                            </div>
                                            <div className="check-box sqeare  mt-10">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-722" />
                                                    <label for="checkbox-722"></label>
                                                </div>
                                                Ultralow beds/crash mats on floor alongside the bed.
                                            </div>
                                            <div className="check-box sqeare  mt-10">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-723" />
                                                    <label for="checkbox-723"></label>
                                                </div>
                                                Objects needed (including call bell) are within reach
                                            </div>
                                            <div className="check-box sqeare  mt-10">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-724" />
                                                    <label for="checkbox-724"></label>
                                                </div>
                                                Toilet offered regularly through intentional rounding.
                                            </div>
                                            <div className="check-box sqeare  mt-10">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-725" />
                                                    <label for="checkbox-725"></label>
                                                </div>
                                                Bed moved to side of wall / to a less isolated area
                                            </div>
                                            <div className="check-box sqeare  mt-10">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-726" />
                                                    <label for="checkbox-726"></label>
                                                </div>
                                                Medication reviewed with medical team.
                                            </div>
                                            <div className="check-box sqeare  mt-10">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-727" />
                                                    <label for="checkbox-727"></label>
                                                </div>
                                                Patient orientated to surroundings
                                            </div>
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="form_group mt-10">
                                                        <label className="label">Date of next assessment</label>
                                                        <input type="date" className="form-control" placeholder="Enter" />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>

                            </div>
                            <div className="popup_footer">
                                <div className="prescribe_footer">
                                    <div className="dsp-flex">
                                        <a className="icon_btn"> <span className="material-icons">save</span> SAVE</a>
                                        <button className="border_btn"><span className="material-icons">save_as</span>SAVE &amp;
                                            CLOSE
                                        </button>
                                    </div>
                                    <button className="btn dsp_flex"><span className="material-icons"><span
                                        className="material-icons">close</span></span>DISCARD </button>
                                </div>
                            </div>
                        </div>
                    </div>





                </div>
            </div>




        </React.Fragment>
    )
}

SuperVision.propTypes = {
    t: PropTypes.any,
    getWards: PropTypes.func,
    getTheatres: PropTypes.func,
    wards: PropTypes.array,
    user: PropTypes.any,
    theatres: PropTypes.array,
    clinic: PropTypes.array,
    patients: PropTypes.array,
    doctors: PropTypes.array,
    profile: PropTypes.object
}

const mapStateToProps = ({ Layout, Login }) => ({
    wards: Layout.wards,
    user: Login.user,
    theatres: Layout.theatres,
    clinic: Layout.clinic,
    patients: Layout.patients,
    doctors: Layout.doctors,
    profile: Layout.profile
})

const mapDispatchToProps = dispatch => ({
    getWards: () => dispatch(getWardsList()),
    getTheatres: () => dispatch(getTheatresList()),
    getClinic: () => dispatch(getClinicList()),
    getPatient: () => dispatch(getPatientList()),
    getUserprofile: (id) => dispatch(getUprofile(id)),
    getDoctor: () => dispatch(getDoctorList()),
    patientupdate: (id, data) => dispatch(updatePatient(id, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(SuperVision)
