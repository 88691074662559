import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import MetaTags from 'react-meta-tags';
import TheatreSubHeader from "./theatreSubHeader";

import { connect } from "react-redux"
import { getWardsList, getTheatresList, getClinicList, getPatientList, getUprofile, getDoctorList, updatePatient } from "../../store/actions"

const Theatre = props => {


    const [visiblePatients, setPatient] = useState(true)
    const [visiblelanding, setClanding] = useState(false)
    const [successmessage, setSuccess] = useState("")


    const [clinicValues, setClinic] = useState({})
    const [clinicPatient, setCPatient] = useState({})

    const {
        wards, user, theatres, clinic, patients, profile, doctors
    } = props;

    useEffect(() => {
        const { getWards, getTheatres, getClinic, getDoctor, getPatient, getUserprofile } = props
        // getWards()
        // getTheatres()
        // getClinic()
        getPatient()
        getDoctor()
        let userId = localStorage.getItem("authUser")
        getUserprofile(userId)
    }, [])

    const selectPatient = (e) => {

        setCPatient(e)
        setPatient(false)
        setClanding(true)


    }
    const updateInputValue = (e) => {
        let val = e.target.value;
        let name = e.target.name;
        let updatedValue = {};
        updatedValue = { [name]: val };
        setClinic(clinicValues => ({
            ...clinicValues,
            ...updatedValue
        }));
    }

    const updateClinic = (e) => {
        const { patientupdate } = props
        let id = clinicPatient._id
        patientupdate(id, clinicValues)
        setSuccess("Update successfully")

        setTimeout(() => {
            setSuccess("")
        }, 2000);
    }

    const patient = (e, item) => {
        e.preventDefault()
        localStorage.setItem("p", JSON.stringify(item))
        props.history.push("/tpatient")
    }

    const theatreBack = (e) => {
        e.preventDefault()
        props.history.push("/theatre")
    }


    return (
        <React.Fragment>

            <MetaTags>
                <title>Theatre</title>
            </MetaTags>
            <div className="main theatre">
                {/* <TheatreSubHeader backPage="theatre" /> */}
                <div className="wrapper">
                        
                        {/* <div className='row mt-20'>
                        <a onClick={(e) => theatreBack(e)} className="icon_link pull-right"><span className="material-icons">
                                arrow_back_ios
                            </span> Back</a>
                            </div> */}
                    <div className="patients_section">
                        <div className="sections_block">
                          
                            <div className="section flex">
                                <h3 className="sub_title">Theatre List</h3>
                              
                            </div>
                            <div className="select_doctor">
                                <div className="select_group">
                                    <div className="select">
                                        <p className="select_title">Surgeon</p>
                                        <select className="form-control">
                                            <option>Dr. T Kumar</option>
                                          
                                        </select>
                                        
                                    </div>
                                    <div className="select">
                                        <p className="select_title">Anaesthetist</p>
                                        <select className="form-control">
                                            <option>Dr. Jansen Hoe</option>
                                            
                                        </select>
                                        
                                    </div>
                                </div>
                                <div className="select_group right">
                                    <div className="select">
                                        <p className="select_title">Service</p>
                                        <select className="form-control">
                                            <option>Trauma</option>
                                
                                        </select>
                                       
                                    </div>
                                    <div className="select">
                                        <p className="select_title">Time</p>
                                        <select className="form-control">
                                            <option>All Day</option>

                                        </select>
                                       
                                    </div>
                                </div>
                            </div>
                            <div className="patients_table">

                                <table>
                                    <thead>
                                        <tr>
                                            <td className="headcol">Patient</td>
                                            <th>Procedure</th>
                                            <th>Anaesthetic</th>
                                            <th>Notes</th>
                                            <th>Alerts</th>
                                            <th>Ward</th>
                                            <th>Tracker</th>
                                        </tr>
                                    </thead>

                                    {patients && patients.length > 0 &&
                                    patients.map((item, i) => (
                                        <tr onClick={(e)=> patient(e, item)} key={i}>
                                            <td className="headcol">
                                                <div className="ptient_name">{item.firstname + " " + item.lastname}</div>
                                                <div className="ptient_age"> <span>{item.sex}</span> <span>{item.age} Yrs</span></div>
                                                <div className="ptient_number">NHS : {item.hospitalNo}</div>
                                            </td>
                                            <td className="long">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                                                tristique felis ex, sed malesuada odio malesuada in. </td>
                                            <td className="long">Dr. Jansen Hoe</td>
                                            <td className="long">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                                                tristique felis</td>
                                            <td className="long">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</td>
                                            <td className="long">Ward 1</td>
                                            <td className="long">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</td>
                                            </tr>
                                        ))}
                                   
                                    
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </React.Fragment>
    )
}

Theatre.propTypes = {
    t: PropTypes.any,
    getWards: PropTypes.func,
    getTheatres: PropTypes.func,
    wards: PropTypes.array,
    user: PropTypes.any,
    theatres: PropTypes.array,
    clinic: PropTypes.array,
    patients: PropTypes.array,
    doctors: PropTypes.array,
    profile: PropTypes.object
}

const mapStateToProps = ({ Layout, Login }) => ({
    wards: Layout.wards,
    user: Login.user,
    theatres: Layout.theatres,
    clinic: Layout.clinic,
    patients: Layout.patients,
    doctors: Layout.doctors,
    profile: Layout.profile
})

const mapDispatchToProps = dispatch => ({
    getWards: () => dispatch(getWardsList()),
    getTheatres: () => dispatch(getTheatresList()),
    getClinic: () => dispatch(getClinicList()),
    getPatient: () => dispatch(getPatientList()),
    getUserprofile: (id) => dispatch(getUprofile(id)),
    getDoctor: () => dispatch(getDoctorList()),
    patientupdate: (id, data) => dispatch(updatePatient(id, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Theatre)
