import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import MetaTags from 'react-meta-tags';
import { useHistory } from "react-router-dom";
import Moment from 'moment';
import {
    Collapse
} from "reactstrap"

import { connect } from "react-redux"
import { getWardsList, getTheatresList, getClinicList, getPatientList, getUprofile, getDoctorList, updateTheatrePatient, updateTheatreBrief } from "../../store/actions"

const TeamBrief = props => {

    const history = useHistory();
    const [visiblePatients, setPatient] = useState(true)
    const [visiblelanding, setClanding] = useState(false)
    const [successmessage, setSuccess] = useState("")

    const [theatreValues, setTheatre] = useState({})
    const [teambriefValues, setTeamBrief] = useState({})
    const [patientAccordion, setPatientAccordion] = useState(null)

    const {
        wards, user, theatres, clinic, patients, profile, doctors
    } = props;

    useEffect(() => {
        const { getWards, getTheatres, getClinic, getDoctor, getPatient, getUserprofile } = props
        // getWards()
        // getTheatres()
        // getClinic()
        getPatient()
        getDoctor()
        let userId = localStorage.getItem("authUser")
        getUserprofile(userId)
    }, [])



    const updateTeamBrief = (e) => {
        const { teambriefUpdate } = props
        let theatreSelected = JSON.parse(localStorage.getItem("t"))
        teambriefValues["theatreId"] = theatreSelected._id
        teambriefUpdate(null, teambriefValues)
    }

    const updateTeamBriefValues = (name, val) => {
        //  e.preventDefault()
        let updatedValue = {};
        updatedValue = { [name]: val };
        setTeamBrief(teambriefValues => ({
            ...teambriefValues,
            ...updatedValue
        }));
    }

    const updateTeamBriefInputValue = (e) => {
        let val = e.target.value;
        let name = e.target.name;
        let updatedValue = {};
        updatedValue = { [name]: val };
        setTeamBrief(teambriefValues => ({
            ...teambriefValues,
            ...updatedValue
        }));
    }

    const updateInputValue = (e) => {
        let val = e.target.value;
        let name = e.target.name;
        let updatedValue = {};
        updatedValue = { [name]: val };
        setTheatre(theatreValues => ({
            ...theatreValues,
            ...updatedValue
        }));
    }

    const updateTheatre = (e, patient, i) => {

        const { theatrepatientupdate } = props
        let theatreSelected = JSON.parse(localStorage.getItem("t"))
        //console.log("theatreValuesvvvv ", theatreValues, i)
        theatreValues["theatreId"] = theatreSelected._id

        theatreValues["patientId"] = patient._id
        let dbSetObj = {}
        Object.keys(theatreValues).forEach(item => {
            // console.log("theat ", item)
            let fieldName = item.replace('_' + i, "");
            //console.log("fieldName ", fieldName)
            dbSetObj[fieldName] = theatreValues[item]

        })
        //console.log("dbSetObj ", dbSetObj)
        theatrepatientupdate(null, dbSetObj)
    }

    const updateTheatreValues = (e, name, val) => {
        //  e.preventDefault()
        let updatedValue = {};
        updatedValue = { [name]: val };
        setTheatre(theatreValues => ({
            ...theatreValues,
            ...updatedValue
        }));
    }

    const theatreBack = (e) => {
        e.preventDefault()
        // props.history.push("/theatre")
        history.goBack()
    }

    const checkboxChange = (event) => {
        updateTheatreValues(event, event.target.name, event.target.checked)
    }

    const handleChange = (event) => {
        updateTeamBriefValues(event.target.name, event.target.checked)
    }

    const accordion_patient = (id) => {
        if (id == patientAccordion) {
            id = null
        }
        setPatientAccordion(id)
    }

    // console.log("theatreValues", theatreValues)
    const time = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })
    const formatDate = Moment().format('MMM DD, YYYY')

    return (
        <React.Fragment>

            <MetaTags>
                <title>Team Brief / Debrief</title>
            </MetaTags>
            <div className="theatre">


                <div className='popup_wrp'>
                    <div className="popup_full prescribe">
                        <div className='popup_hed'>
                            <h2> <span className="sub_text">Session Details</span> Team Brief/ Debrief</h2>
                            <a className='popup_close' onClick={(e) => theatreBack(e)}><span className="material-icons">close</span></a>
                        </div>
                        <div className='popup_content'>

                            <div className='popup_scroll main pr tb'>
                                <div className="team_brief_time">
                                    <h3>Team Brief</h3>
                                    <div className="dsp-flex">
                                        <div className="icon_text date"><span className="material-icons"> calendar_month </span>{formatDate} </div>
                                        <div className="icon_text"><span className="material-icons"> schedule </span> {time}</div>
                                    </div>
                                </div>
                                <div className="section simple_wound">

                                    <h2 className="popup_title">Machine Equipment Checks</h2>
                                    <div className="pd_border"></div>
                                    <div className="yn_switch ">
                                        <div className="label-container w100">
                                            <p>Team Introduction done</p>
                                        </div>
                                        <label className="switch-container">
                                            <input onChange={(e) => handleChange(e)} name="teamIntroduction" type="checkbox" className="switch_check" />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>YES</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>NO</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="yn_switch ">
                                        <div className="label-container w100">
                                            <p>Introduction of any new staff, agency, students and their role done</p>
                                        </div>
                                        <label className="switch-container">
                                            <input onChange={(e) => handleChange(e)} name="newstaff" type="checkbox" className="switch_check" />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>YES</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>NO</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="row mb-10">
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="floating-label-group"><input type="text" name="theatreteamleader"
                                                    className="form-control" onChange={(e) => updateTeamBriefInputValue(e)} /><label
                                                        className="floating-label">Theatre team leader</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="floating-label-group"><input type="text" name="floorcoordinator"
                                                    className="form-control" onChange={(e) => updateTeamBriefInputValue(e)} /><label
                                                        className="floating-label">Floor Co Ordinator</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="yn_switch ">
                                        <div className="label-container w100">
                                            <p>Any list changes</p>
                                        </div>
                                        <label className="switch-container ">
                                            <input onChange={(e) => handleChange(e)} name="listchanges" type="checkbox" className="switch_check" />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>YES</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>NO</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="yn_switch ">
                                        <div className="label-container w100">
                                            <p>All anaesthetic safety checks complete and signed for</p>
                                        </div>
                                        <label className="switch-container">
                                            <input onChange={(e) => handleChange(e)} name="anaestheticsafety" type="checkbox" className="switch_check" />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>YES</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>NO</span>
                                            </div>
                                        </label>
                                    </div>

                                    <a className="icon_btn" onClick={(e) => updateTeamBrief(e)}> <span className="material-icons">save</span> SAVE</a>

                                </div>

                                <div className="accordion" id="accordion">


                                    {patients && patients.length > 0 &&
                                        patients.map((item, i) => (

                                            <div className="accordion-item" key={i}>
                                                <h2 className="accordion-header" id="headingOne">
                                                    <button className={patientAccordion == item._id ? "accordion-button fw-medium" : "accordion-button fw-medium collapsed"} type="button" onClick={(e) => accordion_patient(item._id)} style={{ cursor: "pointer" }}>
                                                        <div className="accordion_patient">
                                                            <div>
                                                                <div className="title">{item.firstname + " " + item.lastname}</div>
                                                                <div className="title">{item.age} Years</div>
                                                            </div>
                                                            <div>
                                                                <div><span className="title">Hospital : </span> <span
                                                                    className="sub">{item.patientId}</span> </div>
                                                                <div><span className="title">NHS: </span> <span
                                                                    className="sub">{item.hospitalNo}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </button>
                                                </h2>


                                                <Collapse isOpen={patientAccordion == item._id ? true : false} className="accordion-collapse">
                                                    <div className="accordion-body">
                                                        <div className="section simple_wound ctr">
                                                            <div className="check_item"><label className="check">Planned surgical
                                                                procedure</label>

                                                                {theatreValues["plannedsurgical_" + i] ? <div className="select_btns popup_select">
                                                                    <div className="select_btn"><label><input name="nodelay" type="radio"
                                                                        value={theatreValues["plannedsurgical_" + i]} checked /><span>{theatreValues["plannedsurgical_" + i]} </span></label>
                                                                    </div> {theatreValues["plannedsurgical_" + i] && <a onClick={(e) => updateTheatreValues(e, "plannedsurgical_" + i, false)}><span className="material-symbols-outlined"> reply
                                                                    </span></a>}

                                                                </div>
                                                                    :
                                                                    <div className="select_btns popup_select">
                                                                        <div className="select_btn"><label><input name={"plannedsurgical_" + i} onClick={(e) => updateInputValue(e)} type="radio"
                                                                            value="As Listed" /><span>As Listed</span></label></div>
                                                                        <div className="select_btn"><label><input name={"plannedsurgical_" + i} onClick={(e) => updateInputValue(e)} type="radio" value="Not Listed" /><span>Not Listed</span></label></div>

                                                                    </div>}

                                                            </div>
                                                            <div className="form_group">
                                                                <div className="floating-label-group"><input type="text" name={"performingsurgey_" + i} onChange={(e) => updateInputValue(e)} className="form-control" /><label className="floating-label">Who is performing the surgery</label>
                                                                </div>
                                                            </div>
                                                            <div className="yn_switch ">
                                                                <div className="label-container">
                                                                    <p>Availability of assistance</p>
                                                                </div>
                                                                <label className="switch-container">
                                                                    <input type="checkbox" className="switch_check"
                                                                        name={"availbilityassistance_" + i} onChange={(e) => checkboxChange(e)} />
                                                                    <div className="switch-bg"></div>
                                                                    <div className="round-box"></div>
                                                                    <div className="switch-left">
                                                                        <span>YES</span>
                                                                    </div>
                                                                    <div className="switch-right">
                                                                        <span>NO</span>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                            <div className="form_group">
                                                                <div className="floating-label-group"><input type="text" name={"expectedduration_" + i} onChange={(e) => updateInputValue(e)}
                                                                    className="form-control" /><label
                                                                        className="floating-label">Expected duration(300 min)</label>
                                                                </div>
                                                            </div>
                                                            <div className="yn_switch rf ">
                                                                <div className="label-container">
                                                                    <p>Respect form</p>
                                                                </div>
                                                                <label className="switch-container">
                                                                    <input type="checkbox" className="switch_check"
                                                                        name={"respectform_" + i} onChange={(e) => checkboxChange(e)} />
                                                                    <div className="switch-bg"></div>
                                                                    <div className="round-box"></div>
                                                                    <div className="switch-left">
                                                                        <span>YES</span>
                                                                    </div>
                                                                    <div className="switch-right">
                                                                        <span>Done</span>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                            <div className="form_group">
                                                                <div className="floating-label-group"><input type="text"
                                                                    className="form-control" name={"anticipatedbloodloss_" + i} onChange={(e) => updateInputValue(e)} /><label
                                                                        className="floating-label">Anticipated blood loss(200
                                                                        ml)</label>
                                                                </div>
                                                            </div>
                                                            <div className="yn_switch ">
                                                                <div className="label-container">
                                                                    <p>If &gt; 500 ml or &gt; 7ml/kg in children - has blood been ordered
                                                                    </p>
                                                                </div>
                                                                <label className="switch-container">
                                                                    <input type="checkbox" className="switch_check"
                                                                        name={"bloodbeenordered_" + i} onChange={(e) => checkboxChange(e)} />
                                                                    <div className="switch-bg"></div>
                                                                    <div className="round-box"></div>
                                                                    <div className="switch-left">
                                                                        <span>YES</span>
                                                                    </div>
                                                                    <div className="switch-right">
                                                                        <span>NO</span>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                            <div className="yn_switch ">
                                                                <div className="label-container">
                                                                    <p>Critical or unexpected steps, Specifics surgical risks</p>
                                                                </div>
                                                                <label className="switch-container">
                                                                    <input type="checkbox" className="switch_check"
                                                                        name={"unexpectedsteps_" + i} onChange={(e) => checkboxChange(e)} />
                                                                    <div className="switch-bg"></div>
                                                                    <div className="round-box"></div>
                                                                    <div className="switch-left">
                                                                        <span>YES</span>
                                                                    </div>
                                                                    <div className="switch-right">
                                                                        <span>text</span>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                            <div className="yn_switch ">
                                                                <div className="label-container">
                                                                    <p>Any specific equipment requirements or special</p>
                                                                </div>
                                                                <label className="switch-container">
                                                                    <input type="checkbox" className="switch_check"
                                                                        name={"specificequipment_" + i} onChange={(e) => checkboxChange(e)} />
                                                                    <div className="switch-bg"></div>
                                                                    <div className="round-box"></div>
                                                                    <div className="switch-left">
                                                                        <span>YES</span>
                                                                    </div>
                                                                    <div className="switch-right">
                                                                        <span>text</span>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                            <h2 className="popup_title mb-20">NEED FOR THE FOLLOWING</h2>
                                                            <div className="yn_switch ">
                                                                <div className="label-container">
                                                                    <p>Preop antibiotics</p>
                                                                </div>
                                                                <label className="switch-container">
                                                                    <input type="checkbox" className="switch_check"
                                                                        name={"preopantibiotics_" + i} onChange={(e) => checkboxChange(e)} />
                                                                    <div className="switch-bg"></div>
                                                                    <div className="round-box"></div>
                                                                    <div className="switch-left">
                                                                        <span>YES</span>
                                                                    </div>
                                                                    <div className="switch-right">
                                                                        <span>NO</span>
                                                                    </div>
                                                                </label>
                                                            </div>

                                                            <div className="form_group">
                                                                <div className="floating-label-group"><input type="text" name={"vteprophylaxis_" + i} onChange={(e) => updateInputValue(e)}
                                                                    className="form-control" /><label
                                                                        className="floating-label">VTE Prophylaxis</label>
                                                                </div>
                                                            </div>
                                                            <div className="yn_switch ">
                                                                <div className="label-container">
                                                                    <p>Tourniquet</p>
                                                                </div>
                                                                <label className="switch-container">
                                                                    <input type="checkbox" className="switch_check"
                                                                        name={"tourniquet_" + i} onChange={(e) => checkboxChange(e)} />
                                                                    <div className="switch-bg"></div>
                                                                    <div className="round-box"></div>
                                                                    <div className="switch-left">
                                                                        <span>YES</span>
                                                                    </div>
                                                                    <div className="switch-right">
                                                                        <span>NO</span>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                            <div className="yn_switch ">
                                                                <div className="label-container">
                                                                    <p>TEDS</p>
                                                                </div>
                                                                <label className="switch-container">
                                                                    <input type="checkbox" className="switch_check"
                                                                        name={"teds_" + i} onChange={(e) => checkboxChange(e)} />
                                                                    <div className="switch-bg"></div>
                                                                    <div className="round-box"></div>
                                                                    <div className="switch-left">
                                                                        <span>YES</span>
                                                                    </div>
                                                                    <div className="switch-right">
                                                                        <span>NO</span>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                            <div className="yn_switch ">
                                                                <div className="label-container">
                                                                    <p>Image intensifier</p>
                                                                </div>
                                                                <label className="switch-container">
                                                                    <input type="checkbox" className="switch_check"
                                                                        name={"imageintensifier_" + i} onChange={(e) => checkboxChange(e)} />
                                                                    <div className="switch-bg"></div>
                                                                    <div className="round-box"></div>
                                                                    <div className="switch-left">
                                                                        <span>YES</span>
                                                                    </div>
                                                                    <div className="switch-right">
                                                                        <span>NO</span>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                            <div className="yn_switch ">
                                                                <div className="label-container">
                                                                    <p>Catheterization</p>
                                                                </div>
                                                                <label className="switch-container">
                                                                    <input type="checkbox" className="switch_check"
                                                                        name={"catheterization_" + i} onChange={(e) => checkboxChange(e)} />
                                                                    <div className="switch-bg"></div>
                                                                    <div className="round-box"></div>
                                                                    <div className="switch-left">
                                                                        <span>YES</span>
                                                                    </div>
                                                                    <div className="switch-right">
                                                                        <span>NO</span>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                            <div className="yn_switch ">
                                                                <div className="label-container">
                                                                    <p>Pathology specimens</p>
                                                                </div>
                                                                <label className="switch-container">
                                                                    <input type="checkbox" className="switch_check"
                                                                        name={"pathologyspecimens_" + i} onChange={(e) => checkboxChange(e)} />
                                                                    <div className="switch-bg"></div>
                                                                    <div className="round-box"></div>
                                                                    <div className="switch-left">
                                                                        <span>YES</span>
                                                                    </div>
                                                                    <div className="switch-right">
                                                                        <span>NO</span>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                            <div className="yn_switch ">
                                                                <div className="label-container">
                                                                    <p>Cell saver</p>
                                                                </div>
                                                                <label className="switch-container">
                                                                    <input type="checkbox" className="switch_check"
                                                                        name={"cellsaver_" + i} onChange={(e) => checkboxChange(e)} />
                                                                    <div className="switch-bg"></div>
                                                                    <div className="round-box"></div>
                                                                    <div className="switch-left">
                                                                        <span>YES</span>
                                                                    </div>
                                                                    <div className="switch-right">
                                                                        <span>NO</span>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                            <div className="yn_switch ">
                                                                <div className="label-container">
                                                                    <p>Operating table and positioning requirements</p>
                                                                </div>
                                                                <label className="switch-container">
                                                                    <input type="checkbox" className="switch_check"
                                                                        name={"operatingtable_" + i} onChange={(e) => checkboxChange(e)} />
                                                                    <div className="switch-bg"></div>
                                                                    <div className="round-box"></div>
                                                                    <div className="switch-left">
                                                                        <span>YES</span>
                                                                    </div>
                                                                    <div className="switch-right">
                                                                        <span>NO</span>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                            <div className="yn_switch ">
                                                                <div className="label-container">
                                                                    <p>MRSA status, Infection risk / Any need for barrier
                                                                        precautions</p>
                                                                </div>
                                                                <label className="switch-container">
                                                                    <input type="checkbox" className="switch_check"
                                                                        name={"MRSAstatus_" + i} onChange={(e) => checkboxChange(e)} />
                                                                    <div className="switch-bg"></div>
                                                                    <div className="round-box"></div>
                                                                    <div className="switch-left">
                                                                        <span>YES</span>
                                                                    </div>
                                                                    <div className="switch-right">
                                                                        <span>NO</span>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                            <div className="form_group">
                                                                <div className="floating-label-group"><input type="text"
                                                                    className="form-control" name={"anyother_" + i} onChange={(e) => updateInputValue(e)}
                                                                /><label
                                                                    className="floating-label">Any other</label>
                                                                </div>
                                                            </div>
                                                            <h2 className="popup_title mt-20">ANAESTHETICS</h2>
                                                            <div className="yn_switch ">
                                                                <div className="label-container">
                                                                    <p>Planned anaesthetic technique</p>
                                                                </div>
                                                                <label className="switch-container">
                                                                    <input type="checkbox" className="switch_check"
                                                                        name={"plannedanaesthetic_" + i} onChange={(e) => checkboxChange(e)} />
                                                                    <div className="switch-bg"></div>
                                                                    <div className="round-box"></div>
                                                                    <div className="switch-left">
                                                                        <span>YES</span>
                                                                    </div>
                                                                    <div className="switch-right">
                                                                        <span>NO</span>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                            <div className="yn_switch ">
                                                                <div className="label-container">
                                                                    <p> The patient’s ASA grade</p>
                                                                </div>
                                                                <label className="switch-container">
                                                                    <input type="checkbox" className="switch_check"
                                                                        name={"asagrade_" + i} onChange={(e) => checkboxChange(e)} />
                                                                    <div className="switch-bg"></div>
                                                                    <div className="round-box"></div>
                                                                    <div className="switch-left">
                                                                        <span>YES</span>
                                                                    </div>
                                                                    <div className="switch-right">
                                                                        <span>NO</span>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                            <div className="yn_switch ">
                                                                <div className="label-container">
                                                                    <p>Difficult airway/aspiration risk</p>
                                                                </div>
                                                                <label className="switch-container">
                                                                    <input type="checkbox" className="switch_check"
                                                                        name={"difficultairway_" + i} onChange={(e) => checkboxChange(e)} />
                                                                    <div className="switch-bg"></div>
                                                                    <div className="round-box"></div>
                                                                    <div className="switch-left">
                                                                        <span>YES</span>
                                                                    </div>
                                                                    <div className="switch-right">
                                                                        <span>NO</span>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                            <div className="yn_switch ">
                                                                <div className="label-container">
                                                                    <p>Allergies</p>
                                                                </div>
                                                                <label className="switch-container">
                                                                    <input type="checkbox" className="switch_check"
                                                                        name={"allergies_" + i} onChange={(e) => checkboxChange(e)} />
                                                                    <div className="switch-bg"></div>
                                                                    <div className="round-box"></div>
                                                                    <div className="switch-left">
                                                                        <span>YES</span>
                                                                    </div>
                                                                    <div className="switch-right">
                                                                        <span>NO</span>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                            <div className="yn_switch ">
                                                                <div className="label-container">
                                                                    <p>Does the patient require HDU bed</p>
                                                                </div>
                                                                <label className="switch-container">
                                                                    <input type="checkbox" className="switch_check"
                                                                        name={"HDUbed_" + i} onChange={(e) => checkboxChange(e)} />
                                                                    <div className="switch-bg"></div>
                                                                    <div className="round-box"></div>
                                                                    <div className="switch-left">
                                                                        <span>YES</span>
                                                                    </div>
                                                                    <div className="switch-right">
                                                                        <span>NO</span>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                            <div className="yn_switch ">
                                                                <div className="label-container">
                                                                    <p>Relevant past medical history etc</p>
                                                                </div>
                                                                <label className="switch-container">
                                                                    <input type="checkbox" className="switch_check"
                                                                        name={"relevantpastmedical_" + i} onChange={(e) => checkboxChange(e)} />
                                                                    <div className="switch-bg"></div>
                                                                    <div className="round-box"></div>
                                                                    <div className="switch-left">
                                                                        <span>YES</span>
                                                                    </div>
                                                                    <div className="switch-right">
                                                                        <span>NO</span>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                            <div className="yn_switch ">
                                                                <div className="label-container">
                                                                    <p>Glycaemic control</p>
                                                                </div>
                                                                <label className="switch-container">
                                                                    <input type="checkbox" className="switch_check"
                                                                        name={"glycaemic_" + i} onChange={(e) => checkboxChange(e)} />
                                                                    <div className="switch-bg"></div>
                                                                    <div className="round-box"></div>
                                                                    <div className="switch-left">
                                                                        <span>YES</span>
                                                                    </div>
                                                                    <div className="switch-right">
                                                                        <span>NO</span>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                            <div className="yn_switch ">
                                                                <div className="label-container">
                                                                    <p>Temp control</p>
                                                                </div>
                                                                <label className="switch-container">
                                                                    <input type="checkbox" className="switch_check"
                                                                        name={"tempcontrol_" + i} onChange={(e) => checkboxChange(e)} />
                                                                    <div className="switch-bg"></div>
                                                                    <div className="round-box"></div>
                                                                    <div className="switch-left">
                                                                        <span>YES</span>
                                                                    </div>
                                                                    <div className="switch-right">
                                                                        <span>NO</span>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                            <div className="form_group">
                                                                <div className="floating-label-group"><input type="text"
                                                                    className="form-control" name={"anyotherlevel_" + i} onChange={(e) => updateInputValue(e)}
                                                                /><label
                                                                    className="floating-label">Any other</label>
                                                                </div>
                                                            </div>
                                                            <h2 className="popup_title mt-20">SCRUB NURSES</h2>
                                                            <div className="yn_switch ">
                                                                <div className="label-container">
                                                                    <p>Sterility been confirmed</p>
                                                                </div>
                                                                <label className="switch-container">
                                                                    <input type="checkbox" className="switch_check"
                                                                        name={"sterility_" + i} onChange={(e) => checkboxChange(e)} />
                                                                    <div className="switch-bg"></div>
                                                                    <div className="round-box"></div>
                                                                    <div className="switch-left">
                                                                        <span>YES</span>
                                                                    </div>
                                                                    <div className="switch-right">
                                                                        <span>NO</span>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                            <div className="yn_switch ">
                                                                <div className="label-container">
                                                                    <p>Any equipment issues or any concerns</p>
                                                                </div>
                                                                <label className="switch-container">
                                                                    <input type="checkbox" className="switch_check"
                                                                        name={"equipmentissues_" + i} onChange={(e) => checkboxChange(e)} />
                                                                    <div className="switch-bg"></div>
                                                                    <div className="round-box"></div>
                                                                    <div className="switch-left">
                                                                        <span>YES</span>
                                                                    </div>
                                                                    <div className="switch-right">
                                                                        <span>NO</span>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                            <div className="form_group">
                                                                <div className="floating-label-group"><input type="text"
                                                                    className="form-control" name={"anyotherlevel2_" + i} onChange={(e) => updateInputValue(e)}
                                                                /><label
                                                                    className="floating-label">Any other</label>
                                                                </div>
                                                            </div>
                                                            <h2 className="popup_title mt-20">GENERAL</h2>
                                                            <div className="yn_switch ">
                                                                <div className="label-container">
                                                                    <p>Time appropriate for list</p>
                                                                </div>
                                                                <label className="switch-container">
                                                                    <input type="checkbox" className="switch_check"
                                                                        name={"timeappropriate_" + i} onChange={(e) => checkboxChange(e)} />
                                                                    <div className="switch-bg"></div>
                                                                    <div className="round-box"></div>
                                                                    <div className="switch-left">
                                                                        <span>YES</span>
                                                                    </div>
                                                                    <div className="switch-right">
                                                                        <span>NO</span>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                            <div className="yn_switch ">
                                                                <div className="label-container">
                                                                    <p>Arrangement for breaks</p>
                                                                </div>
                                                                <label className="switch-container">
                                                                    <input type="checkbox" className="switch_check"
                                                                        name={"arrangementforbreaks_" + i} onChange={(e) => checkboxChange(e)} />
                                                                    <div className="switch-bg"></div>
                                                                    <div className="round-box"></div>
                                                                    <div className="switch-left">
                                                                        <span>YES</span>
                                                                    </div>
                                                                    <div className="switch-right">
                                                                        <span>NO</span>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                            <div className="yn_switch ">
                                                                <div className="label-container">
                                                                    <p>Any staffing /Skills issues</p>
                                                                </div>
                                                                <label className="switch-container">
                                                                    <input type="checkbox" className="switch_check"
                                                                        name={"staffingskills_" + i} onChange={(e) => checkboxChange(e)} />
                                                                    <div className="switch-bg"></div>
                                                                    <div className="round-box"></div>
                                                                    <div className="switch-left">
                                                                        <span>YES</span>
                                                                    </div>
                                                                    <div className="switch-right">
                                                                        <span>NO</span>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                            <div className="yn_switch ">
                                                                <div className="label-container">
                                                                    <p>Any issues pertaining to recovery</p>
                                                                </div>
                                                                <label className="switch-container">
                                                                    <input type="checkbox" className="switch_check"
                                                                        name={"pertainingtorecovery_" + i} onChange={(e) => checkboxChange(e)} />
                                                                    <div className="switch-bg"></div>
                                                                    <div className="round-box"></div>
                                                                    <div className="switch-left">
                                                                        <span>YES</span>
                                                                    </div>
                                                                    <div className="switch-right">
                                                                        <span>NO</span>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                            <div className="form_group">
                                                                <textarea className="textarea" name={"comments_" + i} onChange={(e) => updateInputValue(e)} placeholder="Comments"
                                                                    rows="2" cols="30"></textarea>

                                                            </div>
                                                            <h2 className="popup_title mt-20">EMERGENCY THEATRE ACTION PLAN</h2>
                                                            <div className="form_group">
                                                                <div className="floating-label-group"><input type="text"
                                                                    className="form-control" name={"listsafetyofficer_" + i} onChange={(e) => updateInputValue(e)} /><label
                                                                        className="floating-label">List Safety Officer</label>
                                                                </div>
                                                            </div>
                                                            <div className="form_group">
                                                                <div className="floating-label-group"><input type="text"
                                                                    className="form-control" name={"callforhelp_" + i} onChange={(e) => updateInputValue(e)} /><label
                                                                        className="floating-label">Call for HELP? Ring 2222</label>
                                                                </div>
                                                            </div>
                                                            <div className="form_group">
                                                                <div className="floating-label-group"><input type="text"
                                                                    className="form-control" name={"intubationtrolley_" + i} onChange={(e) => updateInputValue(e)} /><label
                                                                        className="floating-label">Get difficult Intubation
                                                                        Trolley</label>
                                                                </div>
                                                            </div>
                                                            <div className="form_group">
                                                                <div className="floating-label-group"><input type="text"
                                                                    className="form-control" name={"defibrillator_" + i} onChange={(e) => updateInputValue(e)} /><label
                                                                        className="floating-label">Get Defibrillator and Crash
                                                                        trolley</label>
                                                                </div>
                                                            </div>
                                                            <div className="form_group">
                                                                <div className="floating-label-group"><input type="text"
                                                                    className="form-control" name={"airwaylead_" + i} onChange={(e) => updateInputValue(e)} /><label
                                                                        className="floating-label">Anaesthetist to Airway/ Lead</label>
                                                                </div>
                                                            </div>
                                                            <div className="form_group">
                                                                <div className="floating-label-group"><input type="text"
                                                                    className="form-control" name={"alstrained_" + i} onChange={(e) => updateInputValue(e)} /><label
                                                                        className="floating-label">ALS trained staff to use
                                                                        skills</label>
                                                                </div>
                                                            </div>
                                                            <div className="form_group">
                                                                <div className="floating-label-group"><input type="text"
                                                                    className="form-control" name={"ilstrainedstaff_" + i} onChange={(e) => updateInputValue(e)} /><label
                                                                        className="floating-label">ILS trained staff to use CPR skills
                                                                        and alternate accordingly</label>
                                                                </div>
                                                            </div>
                                                            <div className="form_group mb-20">
                                                                <textarea className="textarea" name={"othercomments_" + i} onChange={(e) => updateInputValue(e)}
                                                                    placeholder="Does anyone have any other comments"
                                                                    rows="2" cols="30"></textarea>

                                                            </div>
                                                            <div className="yn_switch ">
                                                                <div className="label-container">
                                                                    <p>End of team brief. Time finished</p>
                                                                </div>
                                                                <label className="switch-container">
                                                                    <input type="checkbox" className="switch_check" name={"timefinished_" + i} onChange={(e) => checkboxChange(e)} />
                                                                    <div className="switch-bg"></div>
                                                                    <div className="round-box"></div>
                                                                    <div className="switch-left">
                                                                        <span>YES</span>
                                                                    </div>
                                                                    <div className="switch-right">
                                                                        <span>NO</span>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                            <div className="yn_switch ">
                                                                <div className="label-container">
                                                                    <p>Briefing led by</p>
                                                                </div>
                                                                <label className="switch-container">
                                                                    <input type="checkbox" className="switch_check"
                                                                        name={"briefingledby_" + i} onChange={(e) => checkboxChange(e)} />
                                                                    <div className="switch-bg"></div>
                                                                    <div className="round-box"></div>
                                                                    <div className="switch-left">
                                                                        <span>YES</span>
                                                                    </div>
                                                                    <div className="switch-right">
                                                                        <span>NO</span>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                            <div className="yn_switch ">
                                                                <div className="label-container">
                                                                    <p>Send for first patient</p>
                                                                </div>
                                                                <label className="switch-container">
                                                                    <input type="checkbox" className="switch_check"
                                                                        name={"firstpatient_" + i} onChange={(e) => checkboxChange(e)} />
                                                                    <div className="switch-bg"></div>
                                                                    <div className="round-box"></div>
                                                                    <div className="switch-left">
                                                                        <span>YES</span>
                                                                    </div>
                                                                    <div className="switch-right">
                                                                        <span>NO</span>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                            <div className="yn_switch ">
                                                                <div className="label-container">
                                                                    <p>Information that may impact on operational management</p>
                                                                </div>
                                                                <label className="switch-container">
                                                                    <input type="checkbox" className="switch_check"
                                                                        name={"operationalmanagement_" + i} onChange={(e) => checkboxChange(e)} />
                                                                    <div className="switch-bg"></div>
                                                                    <div className="round-box"></div>
                                                                    <div className="switch-left">
                                                                        <span>YES</span>
                                                                    </div>
                                                                    <div className="switch-right">
                                                                        <span>NO</span>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="team_brief_time">
                                                            <h3>Team debrief</h3>
                                                        </div>
                                                        <div className="section simple_wound ctr">
                                                            <div className="form_group">
                                                                <div className="floating-label-group"><input type="text"
                                                                    className="form-control" name={"wentwell_" + i} onChange={(e) => updateInputValue(e)} /><label
                                                                        className="floating-label">What went well?</label>
                                                                </div>
                                                            </div>
                                                            <div className="form_group">
                                                                <div className="floating-label-group"><input type="text"
                                                                    className="form-control" name={"sowell_" + i} onChange={(e) => updateInputValue(e)} /><label
                                                                        className="floating-label">What didn’t go so well</label>
                                                                </div>
                                                            </div>
                                                            <div className="form_group">
                                                                <div className="floating-label-group"><input type="text"
                                                                    className="form-control" name={"nexttime_" + i} onChange={(e) => updateInputValue(e)} /><label
                                                                        className="floating-label">What could we improve on next
                                                                        time</label>
                                                                </div>
                                                            </div>
                                                            <div className="form_group">
                                                                <div className="floating-label-group"><input type="text"
                                                                    className="form-control" name={"wastime_" + i} onChange={(e) => updateInputValue(e)} /><label
                                                                        className="floating-label">Was time an issue</label>
                                                                </div>
                                                            </div>
                                                            <div className="yn_switch mt-10 ">
                                                                <div className="label-container">
                                                                    <p>Any incidents that require escalation/reporting</p>
                                                                </div>
                                                                <label className="switch-container">
                                                                    <input type="checkbox" className="switch_check"
                                                                        name={"escalation_" + i} onChange={(e) => checkboxChange(e)} />
                                                                    <div className="switch-bg"></div>
                                                                    <div className="round-box"></div>
                                                                    <div className="switch-left">
                                                                        <span>YES</span>
                                                                    </div>
                                                                    <div className="switch-right">
                                                                        <span>NO</span>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                            <div className="form_group">
                                                                <div className="floating-label-group"><input type="text"
                                                                    className="form-control" name={"personresponsible_" + i} onChange={(e) => updateInputValue(e)} /><label
                                                                        className="floating-label">If so, person responsible for
                                                                        reporting</label>
                                                                </div>
                                                            </div>
                                                            <div className="yn_switch ">
                                                                <div className="label-container">
                                                                    <p>Did we perform as an effective team</p>
                                                                </div>
                                                                <label className="switch-container">
                                                                    <input type="checkbox" className="switch_check"
                                                                        name={"effectiveteam_" + i} onChange={(e) => checkboxChange(e)} />
                                                                    <div className="switch-bg"></div>
                                                                    <div className="round-box"></div>
                                                                    <div className="switch-left">
                                                                        <span>YES</span>
                                                                    </div>
                                                                    <div className="switch-right">
                                                                        <span>NO</span>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                            <div className="yn_switch ">
                                                                <div className="label-container">
                                                                    <p>Were there any communication issues</p>
                                                                </div>
                                                                <label className="switch-container">
                                                                    <input type="checkbox" className="switch_check"
                                                                        name={"communicationissues_" + i} onChange={(e) => checkboxChange(e)} />
                                                                    <div className="switch-bg"></div>
                                                                    <div className="round-box"></div>
                                                                    <div className="switch-left">
                                                                        <span>YES</span>
                                                                    </div>
                                                                    <div className="switch-right">
                                                                        <span>NO</span>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                            <div className="yn_switch ">
                                                                <div className="label-container">
                                                                    <p>Any further comments / learning points</p>
                                                                </div>
                                                                <label className="switch-container">
                                                                    <input type="checkbox" className="switch_check"
                                                                        name={"learningpoints_" + i} onChange={(e) => checkboxChange(e)} />
                                                                    <div className="switch-bg"></div>
                                                                    <div className="round-box"></div>
                                                                    <div className="switch-left">
                                                                        <span>YES</span>
                                                                    </div>
                                                                    <div className="switch-right">
                                                                        <span>NO</span>
                                                                    </div>
                                                                </label>
                                                            </div>

                                                            <a className="icon_btn" onClick={(e) => updateTheatre(e, item, i)} > <span className="material-icons">save</span> SAVE</a>
                                                        </div>
                                                    </div>
                                                </Collapse>
                                            </div>


                                        ))}





                                </div>




                            </div>
                        </div>
                        <div className='popup_footer'>
                            <div className="prescribe_footer">
                                <div className="dsp-flex" >
                                    {/* <a className="icon_btn"> <span className="material-icons">save</span> SAVE</a>
                                    <button className="border_btn"><span className="material-icons">save_as</span>SAVE & CLOSE
                                    </button> */}
                                </div>

                                <button className="btn dsp_flex" onClick={(e) => theatreBack(e)}><span className="material-icons"><span
                                    className="material-icons">close</span></span>DISCARD </button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </React.Fragment>
    )
}

TeamBrief.propTypes = {
    t: PropTypes.any,
    getWards: PropTypes.func,
    getTheatres: PropTypes.func,
    wards: PropTypes.array,
    user: PropTypes.any,
    theatres: PropTypes.array,
    clinic: PropTypes.array,
    patients: PropTypes.array,
    doctors: PropTypes.array,
    profile: PropTypes.object
}

const mapStateToProps = ({ Layout, Login }) => ({
    wards: Layout.wards,
    user: Login.user,
    theatres: Layout.theatres,
    clinic: Layout.clinic,
    patients: Layout.patients,
    doctors: Layout.doctors,
    profile: Layout.profile
})

const mapDispatchToProps = dispatch => ({
    getWards: () => dispatch(getWardsList()),
    getTheatres: () => dispatch(getTheatresList()),
    getClinic: () => dispatch(getClinicList()),
    getPatient: () => dispatch(getPatientList()),
    getUserprofile: (id) => dispatch(getUprofile(id)),
    getDoctor: () => dispatch(getDoctorList()),
    theatrepatientupdate: (id, data) => dispatch(updateTheatrePatient(id, data)),
    teambriefUpdate: (id, data) => dispatch(updateTheatreBrief(id, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(TeamBrief)
