import React from "react";

function IconLeftAngleCovered(props) {
   return (
    <svg
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="17.5" cy="17.5" r="17" fill="white" stroke="#E2EAF5" />
      <path
        d="M15.8284 17.0007L20.7782 21.9504L19.364 23.3646L13 17.0007L19.364 10.6367L20.7782 12.0509L15.8284 17.0007Z"
        fill="black"
      />
    </svg>
  );
}

export default IconLeftAngleCovered;
