import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import MetaTags from 'react-meta-tags';
import SignaturePad from 'react-signature-canvas'
import PatientBlock from "../Clinic/patientBlock";
import { removeSpecialCharacters } from '../../utils/utils';
import { Buffer } from 'buffer';
import { connect } from "react-redux"
import { getWardsList, getTheatresList, getClinicList, getPatientList, getUprofile, getDoctorList, updatePatient } from "../../store/actions"

const WeekendHandover = props => {

    let provider_sign = useRef(null);
    let responsibility_sign = useRef(null);
    let photograph_sign = useRef(null);

    const [file, setFile] = useState({});
    const [legalInfo, setLegalInfo] = useState(false)
    const [clinicValues, setClinic] = useState({})

    const [selectedGroup, setselectedGroup] = useState(null)
    const [popupname, setpopup_name] = useState({})
    const [anydelay, setDelay] = useState(false)

    const {
        wards, user, theatres, clinic, patients, profile, doctors
    } = props;

    useEffect(() => {
        const { getWards, getTheatres, getClinic, getDoctor, getPatient, getUserprofile } = props
        // getWards()
        // getTheatres()
        // getClinic()
        getPatient()
        getDoctor()
        let userId = localStorage.getItem("authUser")
        getUserprofile(userId)
    }, [])


    useEffect(() => {
        let clinicPatient = JSON.parse(localStorage.getItem("p"))
        if (patients && patients.length > 0 && clinicPatient && clinicPatient._id) {
            const cPatient = patients.filter(
                usr => usr._id === clinicPatient._id
            )
            if (cPatient && cPatient[0]) {
                let cpatientData = cPatient[0]
                delete cpatientData["_id"]
                setClinic(clinicValues => ({
                    ...clinicValues,
                    ...cpatientData
                }));
            }

        }
    }, [patients])


    const updateInputValue = (e) => {
        let val = e.target.value;
        let name = e.target.name;
        let updatedValue = {};
        updatedValue = { [name]: val };
        setLegalInfo(legalInfo => ({
            ...legalInfo,
            ...updatedValue
        }));

    }

    const updateInputValues = (name, popupname, val) => {
        //  e.preventDefault()
        // let updatedValue = {
        //    [popupname]:{[name] : val}
        // };
        let updatedValue = clinicValues;
        //updatedValue = { [name]: val };
        // updatedValue[popupname][name] = val;
        if (updatedValue[popupname]) {
            updatedValue[popupname][name] = val;
        } else {
            updatedValue = {
                [popupname]: { [name]: val }

            };
        }
        console.log("updatedValue 2", updatedValue)
        setClinic(clinicValues => ({
            ...clinicValues,
            ...updatedValue
        }));
    }

    const updateClinic = (e) => {
        const { patientupdate } = props
        let clinicPatient = JSON.parse(localStorage.getItem("p"))
        let id = clinicPatient._id
        patientupdate(id, clinicValues)
        let patient = {
            ...clinicPatient,
            ...clinicValues
        }
        localStorage.setItem("p", JSON.stringify(patient))



    }

    const updateSwitchValue = (name, value) => {
        let updatedValue = {};
        updatedValue = { [name]: value };
        setLegalInfo(legalInfo => ({
            ...legalInfo,
            ...updatedValue
        }));

    }

    const handleChange = (event) => {
        updateSwitchValue(event.target.name, event.target.checked)
    }


    const clear = (e, refvalue) => {
        e.preventDefault()
        refvalue.clear()
    }
    const trim = (e, type, refvalue) => {
        e.preventDefault()
        sigUpload(refvalue.getCanvas().toDataURL('image/png'), type)
    }

    const sigUpload = async (filedata, name) => {
        const file = filedata
        //console.log("file ", file, name)
        let filename = name + ".png";
        // const base64 = await convertBase64(file)
        //console.log("filename ", filename)
        let pdetails = JSON.parse(localStorage.getItem("p"))

        const dataf = file.replace(/^data:image\/\w+;base64,/, "");

        const buf = Buffer.from(dataf, "base64");
        let uploadFilePath = "ipr/" + pdetails._id + "/" + name + "_result" + "/" + removeSpecialCharacters(filename);
        let data = { source_bucket: "ipr-resources", sourcepath: uploadFilePath }
        // console.log("data ", data)

        const response = await fetch(API_URL+'upload?appname=ipr', {
            method: "POST",
            mode: "cors",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
        const result = await response.json();
        // console.log("result ", result, buf.length)
        let url = result.result
        // console.log("url ", url)
        const base64Data = new Buffer.from(filedata.replace(/^data:image\/\w+;base64,/, ""), 'base64');
        // Getting the file type, ie: jpeg, png or gif
        const type = filedata.split(';')[0].split('/')[1];
        const uploadImage = await fetch(url, {
            method: "PUT",
            mode: "cors",
            headers: {
                'Content-Type': `image/${type}`,
                'Content-Encoding': 'base64',
                ACL: 'public-read',
            },
            body: base64Data
        })

        //console.log("resultImage ", uploadImage)

        let imagePath = "https://ipr-resources.s3.amazonaws.com/"
        let imageUploadPath = imagePath + uploadFilePath;


        let updateObj = {
            [name + "_result"]: imageUploadPath,
            [name + "_update"]: new Date()
        }

        setFile(file => ({
            ...file,
            ...updateObj
        }));
        setLegalInfo(legalInfo => ({
            ...legalInfo,
            ...updateObj
        }));

        // let patient = {
        //     ...pdetails,
        //     ...updateObj
        // }
        // localStorage.setItem("p", JSON.stringify(patient))

    };

    const handleFile = async (e, name) => {

        const file = e.target.files[0]
        // console.log("file ", file, name)
        let filename = e.target.files[0].name;
        // const base64 = await convertBase64(file)
        //console.log("filename ", filename)
        let pdetails = JSON.parse(localStorage.getItem("p"))
        // var reader = new FileReader();
        //   reader.readAsDataURL(file);
        // reader.onload = async function (e) {
        // console.log("e ", e)
        let uploadFilePath = "ipr/" + pdetails._id + "/" + name + "_result" + "/" + removeSpecialCharacters(filename);
        let data = { source_bucket: "ipr-resources", sourcepath: uploadFilePath }
        // console.log("data ", data)

        const response = await fetch(API_URL+'upload?appname=ipr', {
            method: "POST",
            mode: "cors",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
        const result = await response.json();

        // console.log("result ", result)
        let url = result.result
        // console.log("url ", url)
        const uploadImage = await fetch(url, {
            method: "PUT",
            mode: "cors",
            headers: {
                "Content-Type": "multipart/form-data",
                "Accept": "/",
                "Cache-Control": "no-cache",
                "Accept-Encoding": "gzip, deflate",
                "Connection": "keep-alive",
                "cache-control": "no-cache"
            },
            body: file
        })

        // console.log("resultImage ", uploadImage)

        let imagePath = "https://ipr-resources.s3.amazonaws.com/"
        let imageUploadPath = imagePath + uploadFilePath;

        let updateObj = {
            [name + "_result"]: imageUploadPath,
            [name + "_update"]: new Date()
        }
        // patientupdate(pdetails._id, updateObj)
        setFile(file => ({
            ...file,
            ...updateObj
        }));
        setLegalInfo(legalInfo => ({
            ...legalInfo,
            ...updateObj
        }));



    };

    const wardBack = (e) => {
        e.preventDefault()
        props.history.push("/wl")
        document.body.style.overflow = '';
    }

    let clinicPatient = JSON.parse(localStorage.getItem("p"))

    // console.log(" legalInfo ", legalInfo)

    return (
        <React.Fragment>

            <MetaTags>
                <title>WEEKEND HANDOVER</title>
            </MetaTags>
            <div className='ward '>
                <div className="popup_wrp">
                    <div className="popup_full prescribe">
                        <div className="popup_hed">
                            <h2>WEEKEND HANDOVER </h2>
                            <div className="dsp-flex"> <a className="popup_close" onClick={(e) => { wardBack(e) }}><span className="material-icons">close</span></a></div>

                        </div>
                        <div className="popup_content">
                            <div className="popup_scroll w-btns main ">
                                <div className="section ctr mb-0">
                                    <div className="form_group">
                                        <label className="label">ID Bar </label>
                                        <input type="text" className="form-control" placeholder="Enter" />
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="check_item mt-0 mb-10">
                                                <div className="select_btns popup_select">
                                                    <div className="select_btn"><label><input name="site" type="radio"
                                                        value="Site 1" /><span>Primary</span></label>
                                                    </div>
                                                    <div className="select_btn"><label><input name="site" type="radio"
                                                        value="Site 2 " /><span>working Diagnosis </span></label></div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="check_item mt-0 mb-10">
                                                <div className="select_btns popup_select">
                                                    <div className="select_btn"><label><input name="site" type="radio"
                                                        value="Site 1" /><span>Operation</span></label>
                                                    </div>
                                                    <div className="select_btn"><label><input name="site" type="radio"
                                                        value="Site 2" /><span>Post op day </span></label></div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <h2 className="sub_title">PATIENT STATUS</h2>
                                    <div className="check_item mt-0 mb-10">
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input name="site" type="radio"
                                                value="Site 1" /><span>Well & Stable </span></label>
                                            </div>
                                            <div className="select_btn"><label><input name="site" type="radio"
                                                value="Site 2" /><span>Unwell</span></label></div>

                                        </div>
                                    </div>
                                    <div className="check_item mt-0 mb-10"><label className="check">Is Resuscitation appropriate
                                    </label>
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input name="site" type="radio"
                                                value="Site 1" /><span>DNACPR documented </span></label>
                                            </div>
                                            <div className="select_btn"><label><input name="site" type="radio"
                                                value="Site 2" /><span>Not documented </span></label></div>

                                        </div>
                                    </div>
                                    <div className="check_item mt-0 mb-10"><label className="check">Is ITU appropriate
                                    </label>
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input name="site" type="radio"
                                                value="Site 1" /><span>Reason if No </span></label>
                                            </div>
                                            <div className="select_btn"><label><input name="site" type="radio"
                                                value="Site 2" /><span>Futility </span></label></div>
                                            <div className="select_btn"><label><input name="site" type="radio"
                                                value="Site 2" /><span>Pt wish </span></label></div>

                                        </div>
                                    </div>
                                    <h2 className="sub_title">REASON FOR WEEKEND REVIEW</h2>
                                    <div className="row">
                                        <div className="col-md-5">
                                            <div className="check_item mt-0 mb-10">
                                                <div className="select_btns popup_select">
                                                    <div className="select_btn"><label><input name="site" type="radio"
                                                        value="Site 1" /><span>Issues </span></label>
                                                    </div>
                                                    <div className="select_btn"><label><input name="site" type="radio"
                                                        value="Site 2" /><span>Plan</span></label></div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-7">
                                            <div className="check_item mt-0 mb-10">
                                                <div className="select_btns popup_select">
                                                    <div className="select_btn"><label><input name="site" type="radio"
                                                        value="Site 1" /><span>Potential complications </span></label>
                                                    </div>
                                                    <div className="select_btn"><label><input name="site" type="radio"
                                                        value="Site 2" /><span>Management Required</span></label></div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form_group">
                                        <label className="label">Special Instructions </label>
                                        <input type="text" className="form-control" placeholder="Enter" />
                                    </div>
                                    <div className="yn_switch mb-15 mt-10">
                                        <div className="label-container">
                                            <p>Results to be checked </p>
                                        </div>
                                        <label for="check812" className="switch-container">
                                            <input hidden="" type="checkbox" className="switch_check" id="check812" />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>NO</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>YES</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="form_group">
                                        <label className="label">Specify </label>
                                        <input type="text" className="form-control" placeholder="Enter" />
                                    </div>
                                    <div className="check_item mt-0 mb-10"><label className="check">Review
                                    </label>
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input name="site" type="radio"
                                                value="Site 1" /><span>Saturday </span></label>
                                            </div>
                                            <div className="select_btn"><label><input name="site" type="radio"
                                                value="Site 2" /><span>Sunday </span></label></div>


                                        </div>
                                    </div>
                                    <div className="yn_switch mb-15 mt-0">
                                        <div className="label-container">
                                            <p>Bloods required </p>
                                        </div>
                                        <label for="check813" className="switch-container">
                                            <input hidden="" type="checkbox" className="switch_check" id="check813" />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>NO</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>YES</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="check_item mt-0 mb-10">
                                        <label className="check">Saturday </label>
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input name="site" type="radio"
                                                value="Site 1" /><span>FBC </span></label> </div>
                                            <div className="select_btn"><label><input name="site" type="radio"
                                                value="Site 2" /><span>U&E </span></label></div>
                                            <div className="select_btn"><label><input name="site" type="radio"
                                                value="Site 2" /><span>LFT </span></label></div>
                                            <div className="select_btn"><label><input name="site" type="radio"
                                                value="Site 2" /><span>CRP </span></label></div>
                                            <div className="select_btn"><label><input name="site" type="radio"
                                                value="Site 2" /><span>G&S </span></label></div>
                                            <div className="select_btn"><label><input name="site" type="radio"
                                                value="Site 2" /><span>OTHER </span></label></div>
                                        </div>
                                    </div>
                                    <div className="form_group">
                                        <label className="label">OTHER </label>
                                        <input type="text" className="form-control" placeholder="Enter" />
                                    </div>
                                    <div className="check_item mt-0 mb-10">
                                        <label className="check">Sunday </label>
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input name="site" type="radio"
                                                value="Site 1" /><span>FBC </span></label> </div>
                                            <div className="select_btn"><label><input name="site" type="radio"
                                                value="Site 2" /><span>U&E </span></label></div>
                                            <div className="select_btn"><label><input name="site" type="radio"
                                                value="Site 2" /><span>LFT </span></label></div>
                                            <div className="select_btn"><label><input name="site" type="radio"
                                                value="Site 2" /><span>CRP </span></label></div>
                                            <div className="select_btn"><label><input name="site" type="radio"
                                                value="Site 2" /><span>G&S </span></label></div>
                                            <div className="select_btn"><label><input name="site" type="radio"
                                                value="Site 2" /><span>OTHER </span></label></div>
                                        </div>
                                    </div>
                                    <div className="form_group">
                                        <label className="label">OTHER </label>
                                        <input type="text" className="form-control" placeholder="Enter" />
                                    </div>
                                    <div className="check_item mt-0 mb-10">
                                        <label className="check">Pending investigations </label>
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input name="site" type="radio"
                                                value="Site 1" /><span>CXR </span></label> </div>
                                            <div className="select_btn"><label><input name="site" type="radio"
                                                value="Site 2" /><span>AXR </span></label></div>
                                            <div className="select_btn"><label><input name="site" type="radio"
                                                value="Site 2" /><span>USS </span></label></div>
                                            <div className="select_btn"><label><input name="site" type="radio"
                                                value="Site 2" /><span>CT </span></label></div>
                                            <div className="select_btn"><label><input name="site" type="radio"
                                                value="Site 2" /><span>OTHER </span></label></div>

                                        </div>
                                    </div>
                                    <div className="form_group">
                                        <label className="label">OTHER </label>
                                        <input type="text" className="form-control" placeholder="Enter" />
                                    </div>
                                    <div className="yn_switch mb-15 mt-0">
                                        <div className="label-container">
                                            <p>Antibiotics </p>
                                        </div>
                                        <label for="check814" className="switch-container">
                                            <input hidden="" type="checkbox" className="switch_check" id="check814" />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>NO</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>YES</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="check_item mt-0 mb-10">
                                        <label className="check">Continue  Switch to oral Stop  </label>
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input name="site" type="radio"
                                                value="Site 1" /><span>Sat </span></label> </div>
                                            <div className="select_btn"><label><input name="site" type="radio"
                                                value="Site 2" /><span>Sun </span></label></div>
                                        </div>
                                    </div>
                                    <div className="check_item mt-0 mb-10">
                                        <label className="check">IV Fluids  </label>
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input name="site" type="radio"
                                                value="Site 1" /><span>Continue & Review   </span></label> </div>
                                            <div className="select_btn"><label><input name="site" type="radio"
                                                value="Site 2" /><span>Oral fluids </span></label></div>
                                        </div>
                                    </div>
                                    <div className="yn_switch mb-15 mt-0">
                                        <div className="label-container">
                                            <p>TWOC</p>
                                        </div>
                                        <label for="check815" className="switch-container">
                                            <input hidden="" type="checkbox" className="switch_check" id="check815" />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>NO</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>YES</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="yn_switch mb-10 mt-0">
                                        <div className="label-container">
                                            <p>Mobilization </p>
                                        </div>
                                        <label for="check877" className="switch-container">
                                            <input hidden="" type="checkbox" className="switch_check" id="check877" />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>NO</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>YES</span>
                                            </div>
                                        </label>
                                    </div>
                                    <p className="field_title bold">Which of the following have been completed so that a SAFE DISCHARGE could be considered over the weekend</p>
                                    <p className="note2">Select all that apply for Multiselect forms</p>
                                    <div className="check_list w_white mb-0">
                                        <span className="check_btn2"><span className="material-icons"> done </span></span>
                                        <div className="check_item"><label className="check">Passing Urine </label>
                                        </div>
                                        <div className="check_item"><label className="check">Opened bowels
                                        </label>
                                        </div>
                                        <div className="check_item"><label className="check">Adequate pain control </label>
                                        </div>
                                        <div className="check_item"><label className="check">Eating & Drinking</label>
                                        </div>
                                        <div className="check_item"><label className="check">OT/Physio assessments completed </label>
                                        </div>
                                        <div className="check_item"><label className="check">TTO/Discharge summary completed </label>
                                        </div>

                                    </div>
                                    <p className="note mb-0 mt-15"> Criteria-led discharge pro forma eg ‘if CRP &gt; 50 mg/L and remains apyrexial, patient can go home on Saturday </p>
                                    <div className="row mt-20">
                                        <div className="col-md-3"></div>
                                        <div className="col-md-6">
                                            <div className="signature_block mb-10">
                                                <p>Signature</p>
                                            </div>
                                        </div>
                                        <div className="col-md-3"></div>
                                    </div>

                                </div>



                            </div>
                         
                        </div>
                        <div className="popup_footer">
                                <div className="prescribe_footer">
                                    <div className="dsp-flex">
                                        <a className="icon_btn" onClick={(e) => { wardBack(e) }}> <span className="material-icons">save</span> SAVE</a>
                                        <button className="border_btn" onClick={(e) => { wardBack(e) }}><span className="material-icons">save_as</span>SAVE &amp;
                                            CLOSE
                                        </button>
                                    </div>

                                    <button className="btn dsp_flex" onClick={(e) => { wardBack(e) }}><span className="material-icons"><span className="material-icons">close</span></span>DISCARD </button>
                                </div>
                            </div>
                    </div>

                </div>
            </div>


        </React.Fragment>
    )
}

WeekendHandover.propTypes = {
    t: PropTypes.any,
    getWards: PropTypes.func,
    getTheatres: PropTypes.func,
    wards: PropTypes.array,
    user: PropTypes.any,
    theatres: PropTypes.array,
    clinic: PropTypes.array,
    patients: PropTypes.array,
    doctors: PropTypes.array,
    profile: PropTypes.object
}

const mapStateToProps = ({ Layout, Login }) => ({
    wards: Layout.wards,
    user: Login.user,
    theatres: Layout.theatres,
    clinic: Layout.clinic,
    patients: Layout.patients,
    doctors: Layout.doctors,
    profile: Layout.profile
})

const mapDispatchToProps = dispatch => ({
    getWards: () => dispatch(getWardsList()),
    getTheatres: () => dispatch(getTheatresList()),
    getClinic: () => dispatch(getClinicList()),
    getPatient: () => dispatch(getPatientList()),
    getUserprofile: (id) => dispatch(getUprofile(id)),
    getDoctor: () => dispatch(getDoctorList()),
    patientupdate: (id, data) => dispatch(updatePatient(id, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(WeekendHandover)
