import { useEffect } from "react";
import styles from "./index.module.scss";
import { useLocation, useHistory } from "react-router-dom";
import { IconPlus } from "../../icons/index.jsx";

const Quiz = () => {
  const location = useLocation();
  const history = useHistory();
  const { categoryId, categoryName, timeTakenInSeconds, pdf, subCategoryName } =
    location.state || {};

  const startQuiz = () => {
    history.push( {
      pathname: "/questions",
      state: {
        categoryId: categoryId,
        categoryName: categoryName,
        timeTakenInSeconds,
        pdf,
        subCategoryName,
      },
    });
  };

  return (
    <div className={styles["page-container"]}>
      <div className={styles["left-pane"]}></div>
      <div className={styles["right-pane"]}>
        <div className={styles["right-pane-top"]}>
          <div className={styles["right-pane-top-box"]}>
            CME - CONTINUING MEDICAL EDUCATION
          </div>
          <div className={styles["right-pane-top-heading"]}>
            Like to earn CME points?
          </div>
        </div>
        <div className={styles["right-pane-bottom"]}>
          <div className={styles["right-pane-bottom-instruction"]}>
            <div className={styles["right-pane-bottom-instruction-heading"]}>
              Instructions
            </div>
            <div className={styles["right-pane-bottom-instruction-points"]}>
              <ul className={styles["right-pane-bottom-instruction-points-ul"]}>
                <li>Take a single, carefully crafted test.</li>
                <li>
                  Read the topic thoroughly before proceeding to the test as the
                  test can only be taken once, and your scores will be final.
                </li>
                <li>Once the test is initiated, it cannot be retaken.</li>
                <li>
                  Answer 5 multiple-choice questions derived from the topic
                  material.
                </li>
                <li>
                  To earn CME points, you need to achieve a minimum of 3 correct
                  answers out of the 5 questions.
                </li>
              </ul>
            </div>
          </div>
          <div
            className={styles["right-pane-bottom-button"]}
            onClick={startQuiz}
          >
            <IconPlus iconColor="#ffffff" iconSize={10} />
            Start quiz
          </div>
        </div>
      </div>
    </div>
  );
};

export default Quiz;
