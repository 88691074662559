import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import MetaTags from 'react-meta-tags';
import { Link, useHistory } from "react-router-dom"
import WardMenus from "./wardMenus";
import { connect } from "react-redux"
import myProfile from '../../assets/images/MY-PROFILE.svg';
import MYCONTACTS from '../../assets/images/MY-CONTACTS.svg';
import MYCALENDAR from '../../assets/images/MY-CALENDAR.svg';
import MYDAY from '../../assets/images/MY-DAY.svg';
import TODOLIST from '../../assets/images/TO-DO-LIST.svg';
import USERPREFERENCES from '../../assets/images/USER-PREFERENCES.svg';
import diagnosis from '../../assets/images/diagnosis.svg';

import { getWardsList, getTheatresList, getClinicList, getPatientList, getUprofile, getDoctorList, updatePatient, getNews, updateDailyRounds, getDailyRounds } from "../../store/actions"

const MyOfficeLanding = props => {
    const history = useHistory();
    const [clinicValues, setClinic] = useState({})
    const {
        wards, user, theatres, clinic, patients, profile, doctors
    } = props;

    // useEffect(() => {
    //     const { getWards, getTheatres, getClinic, getDoctor, getPatient, getUserprofile } = props

    //     getPatient()
    //     getDoctor()
    //     let userId = localStorage.getItem("authUser")
    //     getUserprofile(userId)
    // }, [])

    // useEffect(() => {
    //     let clinicPatient = JSON.parse(localStorage.getItem("p"))
    //     if (patients && patients.length > 0 && clinicPatient && clinicPatient._id) {
    //         const cPatient = patients.filter(
    //             usr => usr._id === clinicPatient._id
    //         )
    //         if (cPatient && cPatient[0]) {
    //             let cpatientData = cPatient[0]
    //             delete cpatientData["_id"]
    //             setClinic(clinicValues => ({
    //                 ...clinicValues,
    //                 ...cpatientData
    //             }));
    //         }

    //     }
    // }, [patients])

    const menuClick = (e, menu) => {
        history.push("/" + menu)
    }



    return (
        <React.Fragment>

            <MetaTags>
                <title>My Office</title>
            </MetaTags>
            <div className='ward '>
                <div className="main mo_list">

                    <div className="wrapper">
                        <div className="patient_record prd">

                            <h2>MY OFFICE</h2>
                            <ul className="oncall_dboard">

                                <li>
                                    <a data-bs-toggle="modal" data-bs-target="#MYPROFILE" className="item_card">
                                        <span className="icon"> <img src={myProfile} /></span>
                                        <span className="wn"> MY PROFILE <span className="material-symbols-outlined icon2">
                                            keyboard_arrow_down </span></span>

                                    </a>
                                </li>
                                <li>
                                    <a onClick={(e) => menuClick(e, 'mycontacts')} className="item_card">
                                        <span className="icon"> <img src={MYCONTACTS} /></span>
                                        MY CONTACTS
                                    </a>
                                </li>
                                <li>
                                    <Link to="/files/my_calendar" className="item_card">
                                        <span className="icon"> <img src={MYCALENDAR} /></span>
                                        MY CALENDAR
                                    </Link>
                                </li>
                                <li>
                                    <a data-bs-toggle="modal" data-bs-target="#MYDAY" className="item_card">
                                        <span className="icon"> <img src={MYDAY} /></span>
                                        <span className="wn">  MY DAY<span className="material-symbols-outlined icon2">
                                            keyboard_arrow_down </span></span>

                                    </a>
                                </li>
                                <li>
                                    <Link to="/files/to_do_list" className="item_card">
                                        <span className="icon"> <img src={TODOLIST} /></span>
                                        TO DO LIST
                                    </Link>
                                </li>
                                <li>
                                    <a data-bs-toggle="modal" data-bs-target="#USERPREFERENCES" className="item_card">
                                        <span className="icon"> <img src={USERPREFERENCES} /></span>

                                        <span className="wn">   USER PREFERENCES<span className="material-symbols-outlined icon2">
                                            keyboard_arrow_down </span></span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    {/* <div className="wrapper">

                        <div className=" out_patient_section out_patient_details mb-0">
                            <WardMenus />
                        </div>

                        <div className="info_menu">
                            <Link className="menu_item">MY SETTINGS &amp; PREFERANCES</Link>
                            <Link to="/files/my_files_cabinet" className="menu_item">MY FILING CABINET</Link>
                            <Link to="/mycontacts" className="menu_item">MY CONTACTS </Link>
                            <Link to="/files/my_calendar" className="menu_item">MY CALENDAR</Link>
                            <Link to="/files/my_rota" className="menu_item">MY ROTA</Link>
                            <Link to="/files/week_at_a_glance" className="menu_item">WEEK AT A GLANCE</Link>
                            <Link to="/files/my_day" className="menu_item">MY DAY</Link>
                            <Link to="/files/my_ward_patients" className="menu_item">MY WARD PATIENTS – THEIR LOCATION</Link>
                            <Link to="/files/patients_linked_to_me" className="menu_item">PATIENTS LINKED TO ME </Link>
                            <Link to="/files/my_on_call_admissions" className="menu_item">MY ON CALL ADMISSIONS </Link>
                            <Link to="/files/my_elective_clinic" className="menu_item">MY ELECTIVE CLINIC</Link>
                            <Link to="/files/my_fracture_clinic" className="menu_item">MY FRACTURE CLINIC</Link>
                            <Link to="/files/my_theatre_list" className="menu_item">MY THEATRE LIST</Link>
                            <Link to="/files/my_trauma_list" className="menu_item">MY TRAUMA LIST</Link>
                            <Link to="/files/my_admin_-_letters_to_sign" className="menu_item">MY ADMIN - LETTERS TO SIGN</Link>
                            <Link to="/files/my_appraisal_folder" className="menu_item">MY APPRAISAL FOLDER</Link>
                            <Link to="/files/my_surgeon_profile" className="menu_item">MY SURGEON PROFILE </Link>
                            <Link to="/files/my_performance_-_bi" className="menu_item">MY PERFORMANCE- BI</Link>
                            <Link to="/files/my_learning_record" className="menu_item">MY LEARNING RECORD / CME CENTRE </Link>
                            <Link to="/files/activity" className="menu_item">ACTIVITY</Link>
                            <Link to="/files/my_log_book" className="menu_item">MY LOG BOOK</Link>
                            <Link to="/files/njr_results" className="menu_item">NJR RESULTS </Link>
                            <Link to="/files/my_audit_reflections" className="menu_item">MY AUDIT REFLECTIONS </Link>
                            <Link to="/files/my_roles_education_supervisor" className="menu_item">MY ROLES: EDUCATION SUPERVISOR</Link>
                        </div>
                        <br /> <br /> <br />
                    </div> */}


                </div>
            </div>

            <div className="modal wordnotes fade" id="MYPROFILE" tabIndex="-1" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">
                                <span className="icon"><img src={diagnosis} /></span>
                                MY PROFILE
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="list_block">
                                <div className="list">
                                <Link to="/files/performance_bi" className="link">PERFORMANCE- BI</Link>
                                </div>
                                <div className="list">
                                <Link to="/files/my_log_book" className="link">MY LOG BOOK</Link>
                                </div>
                                <div className="list">
                                <Link to="/files/njr_results" className="link">NJR RESULTS</Link>
                                </div>
                                <div className="list">
                                <Link to="/files/my_mandatory_training" className="link">MY MANDATORY TRAINING</Link>
                                </div>
                                <div className="list">
                                <Link to="/files/my_cme" className="link">MY CME</Link>
                                </div>
                                <div className="list">
                                <Link to="/files/my_appraisal_audit_reflections" className="link">MY APPRAISAL + AUDIT REFLECTIONS</Link>
                                </div>
                                <div className="list">
                                <Link to="/files/my_roles_education_supervisor" className="link">MY ROLES:</Link>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="modal wordnotes fade" id="MYDAY" tabIndex="-1" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">
                                <span className="icon"><img src={diagnosis} /></span>
                                MY DAY
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="list_block">
                                <div className="list">
                                    <Link to="/files/patient_linked" className="link">PATIENTS LINKED TO ME - THEIR LOCATION</Link>
                                </div>
                                <div className="list">
                                    <Link to="/files/my_on_call_admissions" className="link">MY ON CALL ADMISSIONS</Link>
                                </div>
                                <div className="list">
                                    <Link to="/files/my_clinic_list" className="link">MY CLINIC LIST</Link>
                                </div>
                                <div className="list">
                                    <Link to="/files/my_admin_letter_to_sign" className="link">MY ADMIN - LETTERS TO SIGN</Link>
                                </div>
                                <div className="list">
                                    <Link to="/files/my_theatre_list" className="link">MY THEATRE LIST</Link>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="modal wordnotes fade" id="USERPREFERENCES" tabIndex="-1" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">
                                <span className="icon"><img src={diagnosis} /></span>
                                USER PREFERENCES
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="list_block">
                                <div className="list">
                                    <Link to="/mypreferences" className="link">MY USER PREFERENCES</Link>
                                </div>
                                <div className="list">
                                    <Link to="/files/my_templates" className="link">My Templates</Link>
                                </div>
                                <div className="list">
                                    <Link to="/files/my_routine_labs" className="link">My Routine Labs</Link>
                                </div>
                                <div className="list">
                                    <Link to="/files/my_common_medications" className="link">My common Medications</Link>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>


        </React.Fragment>
    )
}

MyOfficeLanding.propTypes = {
    getWards: PropTypes.func,
    getTheatres: PropTypes.func,
    wards: PropTypes.array,
    user: PropTypes.any,
    theatres: PropTypes.array,
    clinic: PropTypes.array,
    patients: PropTypes.array,
    doctors: PropTypes.array,
    profile: PropTypes.object,
    news: PropTypes.array,
}

const mapStateToProps = ({ Layout, Login }) => ({
    wards: Layout.wards,
    user: Login.user,
    theatres: Layout.theatres,
    clinic: Layout.clinic,
    patients: Layout.patients,
    doctors: Layout.doctors,
    profile: Layout.profile,
    news: Layout.news,
    dailyrounds: Layout.dailyrounds
})

const mapDispatchToProps = dispatch => ({
    getWards: () => dispatch(getWardsList()),
    getTheatres: () => dispatch(getTheatresList()),
    getClinic: () => dispatch(getClinicList()),
    getPatient: () => dispatch(getPatientList()),
    getUserprofile: (id) => dispatch(getUprofile(id)),
    getDoctor: () => dispatch(getDoctorList()),
    patientupdate: (id, data) => dispatch(updatePatient(id, data)),
    newsList: (data) => dispatch(getNews(data)),
    dailyRoundsList: (data) => dispatch(getDailyRounds(data)),
    dailyUpdate: (data) => dispatch(updateDailyRounds(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(MyOfficeLanding)
