
import React, {useEffect} from "react"
import MetaTags from 'react-meta-tags';
import construction from '../../assets/images/construction.svg'
import { Link, useHistory } from 'react-router-dom';

const underConstruction = props => {
    const history = useHistory();



    let pageTitle = localStorage.getItem("pt")
    useEffect(() => {
        document.body.style = '';
        $(".modal-backdrop").hide()
    }, [])

    return (
        <React.Fragment>

            <MetaTags>
                <title>Page Under Construction</title>
            </MetaTags>
            <div className='ward '>
            <div className="main">
                <div className="wrapper">
                    <div className="underconstruction">
                <div className="underconstruction_wrp">
                    <img class="img" src={construction} />
                    <h3>{pageTitle && pageTitle.toUpperCase()} page is currently under construction. 
                        Will be added soon!</h3>
                        <p> <button type="button" onClick={() => history.goBack()} className="back">Back</button></p>
                    
                </div>
                    
                    </div>
                </div>
            </div>
            
                
            </div>




        </React.Fragment>
    )
}

export default underConstruction
