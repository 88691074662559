import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import MetaTags from 'react-meta-tags';
import hospitalbed from '../../assets/images/hospital-bed.png';
import { useHistory } from "react-router-dom";

import { connect } from "react-redux"
import { getWardsList, getTheatresList, getClinicList, getPatientList, getUprofile, getDoctorList, updateTheatrePatient } from "../../store/actions"

const MachineCheck = props => {

    const history = useHistory();
    const [visiblePatients, setPatient] = useState(true)
    const [visiblelanding, setClanding] = useState(false)
    const [successmessage, setSuccess] = useState("")


    const [theatreValues, setTheatre] = useState({})
    const [clinicPatient, setCPatient] = useState({})

    const {
        wards, user, theatres, clinic, patients, profile, doctors
    } = props;

    useEffect(() => {
        const { getWards, getTheatres, getClinic, getDoctor, getPatient, getUserprofile } = props
        // getWards()
        // getTheatres()
        // getClinic()
        getPatient()
        getDoctor()
        let userId = localStorage.getItem("authUser")
        getUserprofile(userId)
    }, [])

    useEffect(() => {
        $(".modal-backdrop").hide()
    }, [])

    const theatreBack = (e) => {
        e.preventDefault()
        // props.history.push("/theatre")
        history.goBack()
    }

    const updateTheatre = (e) => {
        const { patientupdate } = props
        let id = clinicPatient._id
        patientupdate(id, theatreValues)
    }

    const updateTheatreValues = (e, name, val) => {
        //  e.preventDefault()
        let updatedValue = {};
        updatedValue = { [name]: val };
        setTheatre(theatreValues => ({
            ...theatreValues,
            ...updatedValue
        }));
    }



    return (
        <React.Fragment>

            <MetaTags>
                <title>Machine Equipment / Checks</title>
            </MetaTags>
            <div className="theatre">
                <div className='popup_wrp'>
                    <div className="popup_full prescribe">
                        <div className='popup_hed'>
                            <h2> <span className="sub_text">Perioperative Record</span> MACHINE & EQUIPMENT CHECKS</h2>
                            <a className='popup_close' onClick={(e) => theatreBack(e)}><span className="material-icons">close</span></a>
                        </div>
                        <div className='popup_content'>

                            <div className='popup_scroll main pr'>
                                <div className="section">
                                    <h2 className="popup_title">Anaesthetic room</h2>
                                    <div className="pd_border"></div>

                                



                                {theatreValues.anaesthetic_room ?  <div className="check_list">
                                        <div className="check_item" onClick={(e) => updateTheatreValues(e, "anaesthetic_room", false)}><label className="check">{theatreValues.anaesthetic_room}</label></div>
                                       
                                    </div>
                                : (theatreValues.anaesthetic_room == undefined || theatreValues.anaesthetic_room == false) &&

                                    <div className="check_list">
                                        <div className="check_item" onClick={(e) => updateTheatreValues(e, "anaesthetic_room", "Monitor(s)")} ><label className="check"> Monitor(s)</label></div>
                                        <div className="check_item" onClick={(e) => updateTheatreValues(e, "anaesthetic_room", "Airway equipment")}><label className="check">Airway equipment </label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateTheatreValues(e, "anaesthetic_room", "Oxygen supply")}><label className="check"> Oxygen supply </label></div>
                                        <div className="check_item" onClick={(e) => updateTheatreValues(e, "anaesthetic_room", "Alarms")}><label className="check">Alarms </label></div>
                                    </div>}

                                </div>
                                <div className="section">
                                    <h2 className="popup_title">Operating theatre</h2>
                                    <div className="pd_border"></div>

                                    {theatreValues.operating_theatre ?  
                                    <div className="check_list">
                                        <div className="check_item" onClick={(e) => updateTheatreValues(e, "operating_theatre", false)}><label className="check">{theatreValues.operating_theatre}</label></div>
                                        </div>  
                                : (theatreValues.operating_theatre == undefined || theatreValues.operating_theatre == false) &&
                                    <div className="check_list">
                                        <div className="check_item" onClick={(e) => updateTheatreValues(e, "operating_theatre", "Monitor(s)")}><label className="check">Monitor(s)</label></div>
                                        <div className="check_item" onClick={(e) => updateTheatreValues(e, "operating_theatre", "Airway equipment")}><label className="check">Airway equipment </label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateTheatreValues(e, "operating_theatre", "Oxygen supply")}><label className="check"> Oxygen supply  </label></div>
                                        <div className="check_item" onClick={(e) => updateTheatreValues(e, "operating_theatre", "Machine & equipment checked and functional")}><label className="check">Machine & equipment checked and functional    </label></div>
                                        <div className="check_item" onClick={(e) => updateTheatreValues(e, "operating_theatre", "Controlled drugs: Checked as per trust checklist - ODP and RN")}><label className="check">Controlled drugs: Checked as per trust checklist - ODP and RN </label></div>
                                        <div className="check_item" onClick={(e) => updateTheatreValues(e, "operating_theatre", "Checked fridge drugs and recorded temperature - Ordered CDs (NA)")}><label className="check">Checked fridge drugs and recorded temperature - Ordered CDs (NA) </label></div>
                                        <div className="check_item" onClick={(e) => updateTheatreValues(e, "operating_theatre", "General drugs: All required available ")}><label className="check">General drugs: All required available </label></div>
                                        <div className="check_item" onClick={(e) => updateTheatreValues(e, "operating_theatre", "Checked / stocked intubation trolley / Specialist trolley")}><label className="check">Checked / stocked intubation trolley / Specialist trolley</label></div>
                                        <div className="check_item" onClick={(e) => updateTheatreValues(e, "operating_theatre", "Emergency intubating fibrescope: Clean, checked and available")}><label className="check">Emergency intubating fibrescope: Clean, checked and available  </label></div>
                                        <div className="check_item" onClick={(e) => updateTheatreValues(e, "operating_theatre", "Emergency box in date - Present and correct")}><label className="check">Emergency box in date - Present and correct </label></div>
                                        <div className="check_item" onClick={(e) => updateTheatreValues(e, "operating_theatre", "Ensured all stock / equipment is available for the list")}><label className="check">Ensured all stock / equipment is available for the list </label></div>
                                        <div className="check_item" onClick={(e) => updateTheatreValues(e, "operating_theatre", "Set-up drip for first patient")}><label className="check">Set-up drip for first patient</label></div>

                                    </div>}


                                </div>

                            </div>
                        </div>
                        <div className='popup_footer'>
                            <div className="prescribe_footer">
                                <div className="dsp-flex">
                                    <a className="icon_btn" onClick={(e) => { updateTheatre(e)}} > <span className="material-icons">save</span> SAVE</a>
                                    <button className="border_btn"  onClick={(e) => { updateTheatre(e), theatreBack(e) }}><span className="material-icons">save_as</span>SAVE & CLOSE
                                    </button>
                                </div>

                                <button className="btn dsp_flex"  onClick={(e) => {  theatreBack(e) }}><span className="material-icons"><span
                                    className="material-icons">close</span></span>DISCARD </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

MachineCheck.propTypes = {
    t: PropTypes.any,
    getWards: PropTypes.func,
    getTheatres: PropTypes.func,
    wards: PropTypes.array,
    user: PropTypes.any,
    theatres: PropTypes.array,
    clinic: PropTypes.array,
    patients: PropTypes.array,
    doctors: PropTypes.array,
    profile: PropTypes.object
}

const mapStateToProps = ({ Layout, Login }) => ({
    wards: Layout.wards,
    user: Login.user,
    theatres: Layout.theatres,
    clinic: Layout.clinic,
    patients: Layout.patients,
    doctors: Layout.doctors,
    profile: Layout.profile
})

const mapDispatchToProps = dispatch => ({
    getWards: () => dispatch(getWardsList()),
    getTheatres: () => dispatch(getTheatresList()),
    getClinic: () => dispatch(getClinicList()),
    getPatient: () => dispatch(getPatientList()),
    getUserprofile: (id) => dispatch(getUprofile(id)),
    getDoctor: () => dispatch(getDoctorList()),
    patientupdate: (id, data) => dispatch(updateTheatrePatient(id, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(MachineCheck)
