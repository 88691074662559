import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import MetaTags from 'react-meta-tags';
import SignaturePad from 'react-signature-canvas'
import Menus from "./onCallMenus";
import PatientBlock from "../Clinic/patientBlock";
import { connect } from "react-redux"
import { getWardsList, getTheatresList, getClinicList, getPatientList, getUprofile, getDoctorList, updatePatient, getNews, updateDailyRounds, getDailyRounds, teamListUpdate } from "../../store/actions"

const Fracture = props => {
    document.body.style.overflow = '';
    document.body.style.padding = '';
    let clinicPatient = JSON.parse(localStorage.getItem("p"))
    const [discard_popup, setdiscard_popup] = useState(false)
    const [allied_value, setallied_value] = useState(null)
    const [clinicValues, setClinic] = useState({})
    const [wardValues, setWard] = useState({})
    const [patientValues, setPatient] = useState({})
    const {
        wards, user, theatres, clinic, patients, profile, doctors
    } = props;

    let fractureSigPad = useRef(null);


    const medical_unif = [
        {
            name: "medical_unif_orthopaedic",
            value: "Medically unfit – awaiting orthopaedic diagnosis/investigation"
        },
        {
            name: "medical_unif_stabilisation",
            value: "Medically unfit – awaiting medical review/investigation or stabilisation"
        },
        {
            name: "medical_unif_inpatient",
            value: "Admin/logistical – awaiting inpatient or high dependancy bed"
        },
        {
            name: "medical_unif_space",
            value: "Admin/logistical – awaiting space on theatre list"
        },
        {
            name: "medical_unif_theatre",
            value: "Admin/logistical – problem with theatre/equipment"
        },
        {
            name: "medical_unif_surgical",
            value: "Admin/logistical – problem with theatre/surgical/anaesthetic staff cover"
        },
        {
            name: "medical_unif_cancelled",
            value: "Admin/logistical – Cancelled due to theatre over-run"
        }
    ]

    const surgeryDelay = [
        {
            name: "surgeryDelayCheck",
            value: "Delay"
        },
        {
            name: "surgeryDelayCheck",
            value: "No Delay"
        }
    ]

    const medicalPre = [
        {
            name: "medicalPre_already",
            value: "Already under care of Geriatrician/Physician"
        },
        {
            name: "medicalPre_routine_genria",
            value: "Routine by Geriatrician"
        },
        {
            name: "medicalPre_physician",
            value: "Routine by Physician"
        },
        {
            name: "medicalPre_specialist",
            value: "Routine by Specialist Nurse"
        },
        {
            name: "medicalPre_review",
            value: "Medical review following request"
        }

    ]
    const boneProtectionStop = [
        {
            name: "boneProtectionStop_await",
            value: "Awaiting DEXA Scan, O/P Referral sent"
        },
        {
            name: "boneProtectionStop_clinical",
            value: "Awaiting Bone clinic assessment"
        },
        {
            name: "boneProtectionStop_referral",
            value: "Referral sent: Not required/appropriate"
        }

    ]

    const boneProtectionContinue = [
        {
            name: "boneProtectionContinue_start",
            value: "Start"
        },
        {
            name: "boneProtectionContinue_adcal",
            value: "Adcal-D3 2 tablets OD"
        },
        {
            name: "boneProtectionContinue_alend",
            value: "Alendronic Acid 70mg OW"
        },
        {
            name: "boneProtectionContinue_rise",
            value: "Risedronate 35mg OW"
        },
        {
            name: "boneProtectionContinue_stron",
            value: "Strontium Renelate 2mg OD"
        },
        {
            name: "boneProtectionContinue_iv",
            value: "IV Bisphosphonate, Referral sent"
        }

    ]

    const boneProtectionlist = [
        {
            name: "boneProtectionCheck",
            value: "Continue"
        },
        {
            name: "boneProtectionCheck",
            value: "Stop"
        }
    ]

    const required_request = ["Endomysial antibodies", "Serum Electrophoresis", "Urine Bence Jones", "Vitamin D", "PTH", "TSH (0.35-5.5)", "T4", "Testosterone (9.9-27.8)", "PSA (9.9-27.8)"]
    const fratcure_osteoporosis = [
        {
            name: "fratcure_osteoporosis_loss",
            value: "Loss of height"
        },
        {
            name: "fratcure_osteoporosis_pre",
            value: "Previous fracture"
        },
        {
            name: "fratcure_osteoporosis_parent",
            value: "Parent fractured hip"
        },
        {
            name: "fratcure_osteoporosis_smoking",
            value: "Current smoking"
        },
        {
            name: "fratcure_osteoporosis_glu",
            value: "Glucocorticoids"
        },
        {
            name: "fratcure_osteoporosis_rhe",
            value: "Rheumatoid arthritis"
        },
        {
            name: "fratcure_osteoporosis_osteo",
            value: "Secondary osteoporosis"
        },
        {
            name: "fratcure_osteoporosis_alcohol",
            value: "Alcohol &gt; 3 units/day"
        }

    ]
    const neck_femur = [
        {
            name: "neck_femur_sym",
            value: "Preceeding symptoms"
        },
        {
            name: "neck_femur_con",
            value: "Loss of consciousness"
        },
        {
            name: "neck_femur_aura",
            value: "Aura Tongue-biting incontinence"
        },
        {
            name: "neck_femur_pal",
            value: "Palpitations chest Pain SOB"
        },
        {
            name: "neck_femur_dizzy",
            value: "Dizzy light headedness Pale/Sweaty"
        }
    ]

    useEffect(() => {
        const { getWards, getTheatres, getClinic, getDoctor, getPatient, getUserprofile } = props

        getPatient()
        getDoctor()
        let userId = localStorage.getItem("authUser")
        getUserprofile(userId)
    }, [])

    useEffect(() => {
        let clinicPatient = JSON.parse(localStorage.getItem("p"))
        if (patients && patients.length > 0 && clinicPatient && clinicPatient._id) {
            const cPatient = patients.filter(
                usr => usr._id === clinicPatient._id
            )
            if (cPatient && cPatient[0]) {
                let cpatientData = cPatient[0]
                delete cpatientData["_id"]
                setClinic(clinicValues => ({
                    ...clinicValues,
                    ...cpatientData
                }));
            }

        }
    }, [patients])

    const clear = (e) => {
        e.preventDefault()
        fractureSigPad.clear()
    }
    const trim = (e) => {
        e.preventDefault()
        console.log("doc url", fractureSigPad.getTrimmedCanvas()
            .toDataURL('image/png'))

    }

    const updateInputValue = (e) => {
        let val = e.target.value;
        let name = e.target.name;
        let updatedValue = {};
        updatedValue = { [name]: val };
        setPatient(patientValues => ({
            ...patientValues,
            ...updatedValue
        }));

    }

    const updateMultipleValues = (e, name, val, type) => {
        e.preventDefault()
        let tvalues = clinicValues
        e.target.parentElement.classList.add('active');
        if (tvalues[name]) {
            const exists = tvalues[name].includes(val);
            if (!exists) {
                tvalues[name].push(val)
            }

        } else {
            tvalues = {
                [name]: [val]
            }
        }
        // let updatedValue = {};
        // tvalues[type] = true
        setPatient(patientValues => ({
            ...patientValues,
            ...tvalues
        }));

    }

    const handleSelectGroup = (selectedGroup, name) => {
        // console.log("selectedGroup ", selectedGroup)
        let updatedValue = { [name]: selectedGroup };
        setPatient(patientValues => ({
            ...patientValues,
            ...updatedValue
        }));
    }

    const updateClinicValues = (e, name, val) => {
        e.preventDefault()
        let updatedValue = {};
        updatedValue = { [name]: val };
        setPatient(patientValues => ({
            ...patientValues,
            ...updatedValue
        }));
    }

    const switchChange = (event) => {
        updateClinicValues(event, event.target.name, event.target.checked)
    }

    const selectCheckbox = (e, labelname) => {
        // console.log(e.target.name, e.target.checked)
        let name = e.target.name;
        if (e.target.checked) {
            let val = e.target.value;
            let updatedValue = {};
            if (patientValues[labelname]) {
                updatedValue[labelname] = patientValues[labelname]
                updatedValue[labelname][name] = val;
            } else {
                updatedValue[labelname] = { [name]: val };
            }

            setPatient(patientValues => ({
                ...patientValues,
                ...updatedValue
            }));
        } else {
            delete patientValues[labelname][name]
            setPatient(patientValues)
        }
    }

    const updateCheckbox = (e) => {
        // console.log(e.target.name, e.target.checked)
        if (e.target.checked) {
            let val = e.target.value;
            let name = e.target.name;
            let updatedValue = {};
            updatedValue = { [name]: val };
            setPatient(patientValues => ({
                ...patientValues,
                ...updatedValue
            }));
        } else {
            let name = e.target.name;
            delete patientValues[name]
            setPatient(patientValues)
        }
    }
    const submitUpdate = (e) => {
        const { teamupdatelist } = props
        let clinicPatient = JSON.parse(localStorage.getItem("p"))
        let id = clinicPatient.patientId
        let bodyObj = {
            obj: patientValues,
            query: { "patientId": id },
            method: "Update"
        }
        teamupdatelist(bodyObj, "fracture")

    }

    return (
        <React.Fragment>

            <MetaTags>
                <title>FRACTURE NECK OF FEMUR</title>
            </MetaTags>
            <div className='ward '>
                <div className="main n_office oncall">
                    <div className="wrapper">
                        <Menus menuName={"FRACTURE NECK OF FEMUR"} />

                        <PatientBlock patient={clinicPatient} />
                        <div className="section ctr pua">
                            <h2 className="popup_title">History of fall - Neck of Femur </h2>
                            <div className="yn_switch mb-10 mt-0">
                                <div className="label-container">
                                    <p>Clear story of trip, Slip or Accident </p>
                                </div>
                                <label for="slipAccident" className="switch-container">
                                    <input hidden="" type="checkbox" name="slipAccident" onChange={(e) => switchChange(e)} className="switch_check" id="slipAccident" />
                                    <div className="switch-bg"></div>
                                    <div className="round-box"></div>
                                    <div className="switch-left">
                                        <span>YES</span>
                                    </div>
                                    <div className="switch-right">
                                        <span>NO</span>
                                    </div>
                                </label>
                            </div>

                            <div className="check_list w_white mb-0">
                                <span className="check_btn2" onClick={(e) => updateClinicValues(e, "neck_femur_flag", true)}><span className="material-icons"> done </span></span>
                            </div>

                            {patientValues.neck_femur_flag && patientValues.neck_femur && Object.keys(patientValues.neck_femur).length > 0 ? <div className="check_list w_white mb-0">

                                {Object.keys(patientValues.neck_femur).map((item, i) => (
                                    <div className="check_item active" key={i} onClick={(e) => updateClinicValues(e, "neck_femur_flag", false)} ><label className="check">{patientValues.neck_femur[item]}</label></div>
                                ))}

                            </div> : <>

                                {neck_femur && neck_femur.length > 0 && neck_femur.map((item, i) => (

                                    <div className="check-box sqeare" key={i}>
                                        <div className="round">
                                            <input type="checkbox" onClick={(e) => selectCheckbox(e, "neck_femur")} name={item.name} value={item.value} id={item.name} defaultChecked={patientValues && patientValues.neck_femur && patientValues.neck_femur[item.name] ? true : false}
                                            />
                                            <label for={item.name}></label>
                                        </div>
                                        {item.value}
                                    </div>


                                ))} </>}





                            <div className="yn_switch mb-10 mt-10">
                                <div className="label-container">
                                    <p>Prolonged Lie? </p>
                                </div>
                                <label for="prolonged" className="switch-container">
                                    <input hidden="" type="checkbox" name="prolonged" onChange={(e) => switchChange(e)} className="switch_check" id="prolonged" />
                                    <div className="switch-bg"></div>
                                    <div className="round-box"></div>
                                    <div className="switch-left">
                                        <span>YES</span>
                                    </div>
                                    <div className="switch-right">
                                        <span>NO</span>
                                    </div>
                                </label>
                            </div>
                            <div className="yn_switch mb-10 mt-10">
                                <div className="label-container">
                                    <p>Head Injury? </p>
                                </div>
                                <label for="headinjury" className="switch-container">
                                    <input hidden="" type="checkbox" name="headinjury" onChange={(e) => switchChange(e)} className="switch_check" id="headinjury" />
                                    <div className="switch-bg"></div>
                                    <div className="round-box"></div>
                                    <div className="switch-left">
                                        <span>YES</span>
                                    </div>
                                    <div className="switch-right">
                                        <span>NO</span>
                                    </div>
                                </label>
                            </div>
                            <div className="sub_section">
                                <div className="note_block mt-20">
                                    <p className="sub_title mb-10"> Admission AMT </p>
                                    <ol>
                                        <li> Age </li>
                                        <li> Time (nearest hour)</li>
                                        <li>Address Recall (42 West St)</li>
                                        <li> Year</li>
                                        <li> Location: Name of hospital</li>
                                        <li> ID 2 people - Recognise two objects</li>
                                        <li> Date of birth</li>
                                        <li>WW2 (1939-1945) </li>
                                        <li> Present Monarch </li>
                                        <li> Count backwards 201 </li>

                                    </ol>
                                    <p className="bold mb-0">Total /10</p>
                                </div>
                            </div>
                            <div className="sub_section">
                                <p className="sub_title mb-10"> Nottingham Hip Fracture Score <span>(patients &lt; 65)</span> </p>
                                <table className="table table-bordered table_blue">
                                    <thead>
                                        <tr>
                                            <th width="85%">Criteria</th>
                                            <th width="15%">Score</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td> Age: 66-85 </td>
                                            <td> 3 </td>
                                        </tr>
                                        <tr>
                                            <td> Age: &lt; 86 </td>
                                            <td> 4 </td>
                                        </tr>
                                        <tr>
                                            <td> Gender: Female</td>
                                            <td> 0 </td>
                                        </tr>
                                        <tr>
                                            <td> Male </td>
                                            <td> 1 </td>
                                        </tr>
                                        <tr>
                                            <td>Admission Hb: &gt; 100mg /dl </td>
                                            <td> 1 </td>
                                        </tr>
                                        <tr>
                                            <td> Admission AMT: &gt; 7 /10 </td>
                                            <td> 1 </td>
                                        </tr>
                                        <tr>
                                            <td> Living in institution (NH/RH)</td>
                                            <td> 1 </td>
                                        </tr>
                                        <tr>
                                            <td> 2 or more co-morbidities </td>
                                            <td> 1 </td>
                                        </tr>
                                        <tr>
                                            <td> Malignancy </td>
                                            <td> 1 </td>
                                        </tr>

                                    </tbody>
                                </table>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form_group">
                                            <label className="label">Total points</label>
                                            <input type="text" onChange={(e) => updateInputValue(e)} name="fracture_totalpoint" className="form-control" placeholder="Enter" />
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="sub_section">
                                <div className="row">
                                    <div className="col-md-6">
                                        <table className="table table-bordered table_blue">
                                            <thead>
                                                <tr>
                                                    <th width="50%">Total</th>
                                                    <th width="50%" className="text-center">Predicted Mortality</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td> 3 </td>
                                                    <td className="text-center"> 4% </td>
                                                </tr>
                                                <tr>
                                                    <td> 4 </td>
                                                    <td className="text-center"> 6% </td>
                                                </tr>
                                                <tr>
                                                    <td> 5 </td>
                                                    <td className="text-center"> 10% </td>
                                                </tr>
                                                <tr>
                                                    <td> 6 </td>
                                                    <td className="text-center">15% </td>
                                                </tr>
                                                <tr>
                                                    <td> 7 </td>
                                                    <td className="text-center"> 23% </td>
                                                </tr>
                                                <tr>
                                                    <td> 8 </td>
                                                    <td className="text-center"> 32% </td>
                                                </tr>
                                                <tr>
                                                    <td> 9 </td>
                                                    <td className="text-center"> 45% </td>
                                                </tr>
                                                <tr>
                                                    <td> 10 </td>
                                                    <td className="text-center"> 57% </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>
                            <div className="sub_section">
                                <p className="sub_title mb_15">
                                    Ortho-Geriatric Review Pre / Postoperative
                                </p>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form_group">
                                            <label className="label">Date</label>
                                            <input type="date" onChange={(e) => updateInputValue(e)} name="fracture_date" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form_group">
                                            <label className="label">Time</label>
                                            <input type="time" onChange={(e) => updateInputValue(e)} name="fracture_time" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form_group">
                                            <label className="label">Cons/SpR</label>
                                            <input type="text" onChange={(e) => updateInputValue(e)} name="fracture_cons" className="form-control" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="sub_section">
                                <p className="sub_title mt-10 mb_15">Ortho-Geriatric Review Bone Assessment</p>
                                <p className="field_title">
                                    Osteoporosis RF’s
                                </p>

                                <div className="check_list w_white mb-0">
                                    <span className="check_btn2" onClick={(e) => updateClinicValues(e, "fratcure_osteoporosis_flag", true)}><span className="material-icons"> done </span></span></div>

                                {patientValues.fratcure_osteoporosis_flag && patientValues.fratcure_osteoporosis && Object.keys(patientValues.fratcure_osteoporosis).length > 0 ? <div className="check_list w_white mb-0">

                                    {Object.keys(patientValues.fratcure_osteoporosis).map((item, i) => (
                                        <div className="check_item active" key={i} onClick={(e) => updateClinicValues(e, "fratcure_osteoporosis_flag", false)} ><label className="check">{patientValues.fratcure_osteoporosis[item]}</label></div>
                                    ))}

                                </div> : <>

                                    {fratcure_osteoporosis && fratcure_osteoporosis.length > 0 && fratcure_osteoporosis.map((item, i) => (

                                        <div className="check-box sqeare" key={i}>
                                            <div className="round">
                                                <input type="checkbox" onClick={(e) => selectCheckbox(e, "fratcure_osteoporosis")} name={item.name} value={item.value} id={item.name} defaultChecked={patientValues && patientValues.fratcure_osteoporosis && patientValues.fratcure_osteoporosis[item.name] ? true : false}
                                                />
                                                <label for={item.name}></label>
                                            </div>
                                            {item.value}
                                        </div>


                                    ))} </>}

                            </div>
                            <div className="sub_section">
                                <div className="row">
                                    <div className="col-md-8">
                                        <div className="yn_switch mb-10 mt-10">
                                            <div className="label-container">
                                                <p>Previous DEXA scan </p>
                                            </div>
                                            <label for="fracture_dexa" className="switch-container">
                                                <input hidden="" name="fracture_dexa" onChange={(e) => switchChange(e)} type="checkbox" className="switch_check" id="fracture_dexa" />
                                                <div className="switch-bg"></div>
                                                <div className="round-box"></div>
                                                <div className="switch-left">
                                                    <span>YES</span>
                                                </div>
                                                <div className="switch-right">
                                                    <span>NO</span>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form_group">
                                            <label className="label">Result</label>
                                            <input type="text" onChange={(e) => updateInputValue(e)} name="fracture_result" className="form-control" />
                                        </div>
                                    </div>
                                </div>
                                <table className="table table-bordered table_blue bg2">
                                    <thead>
                                        <tr>
                                            <th width="50%">Test</th>
                                            <th width="15%">Required?</th>
                                            <th width="15%">Requested</th>
                                            <th width="20%" className="text-center">Result & Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {required_request && required_request.length > 0 && required_request.map((item, i) => (

                                            <tr key={i}>
                                                <td>  {item}</td>
                                                <td>
                                                    <div className="yn_switch mb-0 mt-0">
                                                        <label for={"require_" + i} className="switch-container ml-0">
                                                            <input hidden="" name={"require_" + i} onChange={(e) => switchChange(e)} type="checkbox" className="switch_check" id={"require_" + i} />
                                                            <div className="switch-bg"></div>
                                                            <div className="round-box"></div>
                                                            <div className="switch-left">
                                                                <span>YES</span>
                                                            </div>
                                                            <div className="switch-right">
                                                                <span>NO</span>
                                                            </div>
                                                        </label>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="yn_switch mb-0 mt-0">
                                                        <label for={"request_" + i} className="switch-container ml-0">
                                                            <input hidden="" name={"request_" + i} onChange={(e) => switchChange(e)} type="checkbox" className="switch_check" id={"request_" + i} />
                                                            <div className="switch-bg"></div>
                                                            <div className="round-box"></div>
                                                            <div className="switch-left">
                                                                <span>YES</span>
                                                            </div>
                                                            <div className="switch-right">
                                                                <span>NO</span>
                                                            </div>
                                                        </label>
                                                    </div>
                                                </td>
                                                <td className="text-center"> <a className="link_btn">View</a> </td>
                                            </tr>
                                        ))}

                                    </tbody>
                                </table>

                            </div>
                            <div className="sub_section">
                                <div className="check_item mt-0"><label className="check">Bone Protection Medication
                                </label>

                                    {patientValues.boneProtectionCheck ? <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input type="radio"
                                            value={patientValues.boneProtectionCheck} checked /><span>{patientValues.boneProtectionCheck} </span></label>
                                        </div> {patientValues.boneProtectionCheck && <a onClick={(e) => updateClinicValues(e, "boneProtectionCheck", false)}><span className="material-symbols-outlined"> reply
                                        </span></a>}

                                    </div>
                                        :

                                        <div className="select_btns popup_select mt-0">
                                            {boneProtectionlist && boneProtectionlist.length > 0 && boneProtectionlist.map((item, i) => (
                                                <div className="select_btn" key={i}><label><input onClick={(e) => updateInputValue(e)} name={item.name} type="radio"
                                                    value={item.value} /><span>{item.value} </span></label></div>
                                            ))}
                                        </div>
                                    }


                                </div>


                                {patientValues.boneProtectionCheck == "Continue" &&
                                    <>
                                        <p className="field_title">Continue</p>
                                        <div className="check_list w_white mb-0">
                                            <span className="check_btn2" onClick={(e) => updateClinicValues(e, "boneProtectionContinue_flag", true)}><span className="material-icons"> done </span></span></div>


                                        {patientValues.boneProtectionContinue_flag && patientValues.boneProtectionContinue && Object.keys(patientValues.boneProtectionContinue).length > 0 ? <div className="check_list w_white mb-0">

                                            {Object.keys(patientValues.boneProtectionContinue).map((item, i) => (
                                                <div className="check_item active" key={i} onClick={(e) => updateClinicValues(e, "boneProtectionContinue_flag", false)} ><label className="check">{patientValues.boneProtectionContinue[item]}</label></div>
                                            ))}

                                        </div> : <>

                                            {boneProtectionContinue && boneProtectionContinue.length > 0 && boneProtectionContinue.map((item, i) => (

                                                <div className="check-box sqeare" key={i}>
                                                    <div className="round">
                                                        <input type="checkbox" onClick={(e) => selectCheckbox(e, "boneProtectionContinue")} name={item.name} value={item.value} id={item.name} defaultChecked={patientValues && patientValues.boneProtectionContinue && patientValues.boneProtectionContinue[item.name] ? true : false}
                                                        />
                                                        <label for={item.name}></label>
                                                    </div>
                                                    {item.value}
                                                </div>


                                            ))} </>}</>}



                                {patientValues.boneProtectionCheck == "Stop" &&
                                    <>
                                        <p className="field_title">Stop</p>

                                        <div className="check_list w_white mb-0">
                                            <span className="check_btn2" onClick={(e) => updateClinicValues(e, "boneProtectionStop_flag", true)}><span className="material-icons"> done </span></span></div>


                                        {patientValues.boneProtectionStop_flag && patientValues.boneProtectionStop && Object.keys(patientValues.boneProtectionStop).length > 0 ? <div className="check_list w_white mb-0">

                                            {Object.keys(patientValues.boneProtectionStop).map((item, i) => (
                                                <div className="check_item active" key={i} onClick={(e) => updateClinicValues(e, "boneProtectionStop_flag", false)} ><label className="check">{patientValues.boneProtectionStop[item]}</label></div>
                                            ))}

                                        </div> : <>

                                            {boneProtectionStop && boneProtectionStop.length > 0 && boneProtectionStop.map((item, i) => (

                                                <div className="check-box sqeare" key={i}>
                                                    <div className="round">
                                                        <input type="checkbox" onClick={(e) => selectCheckbox(e, "boneProtectionStop")} name={item.name} value={item.value} id={item.name} defaultChecked={patientValues && patientValues.boneProtectionStop && patientValues.boneProtectionStop[item.name] ? true : false}
                                                        />
                                                        <label for={item.name}></label>
                                                    </div>
                                                    {item.value}
                                                </div>


                                            ))} </>} </>}




                            </div>
                        </div>
                        <div className="section ctr pua">
                            <h2 className="popup_title">Medication review <span className="sub">Sedatives, Antihypertensives </span>
                            </h2>
                            <div className="form_group">
                                <label className="label">Changes</label>
                                <input type="text" onChange={(e) => updateInputValue(e)} name="fracture_medication_review" className="form-control" placeholder="Enter" />
                            </div>
                            <div className="sub_section">
                                <p className="sub_title mb_15">Pre-op medical assessment</p>

                                <div className="check_list w_white mb-0">
                                    <span className="check_btn2" onClick={(e) => updateClinicValues(e, "medicalPre_flag", true)}><span className="material-icons"> done </span></span></div>


                                {patientValues.medicalPre_flag && patientValues.medicalPre && Object.keys(patientValues.medicalPre).length > 0 ? <div className="check_list w_white mb-0">

                                    {Object.keys(patientValues.medicalPre).map((item, i) => (
                                        <div className="check_item active" key={i} onClick={(e) => updateClinicValues(e, "medicalPre_flag", false)} ><label className="check">{patientValues.medicalPre[item]}</label></div>
                                    ))}

                                </div> : <>

                                    {medicalPre && medicalPre.length > 0 && medicalPre.map((item, i) => (

                                        <div className="check-box sqeare" key={i}>
                                            <div className="round">
                                                <input type="checkbox" onClick={(e) => selectCheckbox(e, "medicalPre")} name={item.name} value={item.value} id={item.name} defaultChecked={patientValues && patientValues.medicalPre && patientValues.medicalPre[item.name] ? true : false}
                                                />
                                                <label for={item.name}></label>
                                            </div>
                                            {item.value}
                                        </div>


                                    ))} </>}


                            </div>
                            <div className="sub_section">
                                <div className="check_item mt-0"><label className="check">Surgery
                                </label>

                                    {patientValues.surgeryDelayCheck ? <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input type="radio"
                                            value={patientValues.surgeryDelayCheck} checked /><span>{patientValues.surgeryDelayCheck} </span></label>
                                        </div> {patientValues.surgeryDelayCheck && <a onClick={(e) => updateClinicValues(e, "surgeryDelayCheck", false)}><span className="material-symbols-outlined"> reply
                                        </span></a>}

                                    </div>
                                        :

                                        <div className="select_btns popup_select mt-0">
                                            {surgeryDelay && surgeryDelay.length > 0 && surgeryDelay.map((item, i) => (
                                                <div className="select_btn" key={i}><label><input onClick={(e) => updateInputValue(e)} name={item.name} type="radio"
                                                    value={item.value} /><span>{item.value} </span></label></div>
                                            ))}
                                        </div>
                                    }




                                </div>
                                {patientValues.surgeryDelayCheck == "Delay" &&
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form_group">
                                                <label className="label">Duration of Delay </label>
                                                <input type="text" onChange={(e) => updateInputValue(e)} name="fracture_duration_delay" className="form-control" placeholder="Enter" />
                                            </div>
                                        </div>
                                    </div>}



                                <div className="check_list w_white mb-0">
                                    <span className="check_btn2" onClick={(e) => updateClinicValues(e, "medical_unif_flag", true)}><span className="material-icons"> done </span></span></div>


                                {patientValues.medical_unif_flag && patientValues.medical_unif && Object.keys(patientValues.medical_unif).length > 0 ? <div className="check_list w_white mb-0">

                                    {Object.keys(patientValues.medical_unif).map((item, i) => (
                                        <div className="check_item active" key={i} onClick={(e) => updateClinicValues(e, "medical_unif_flag", false)} ><label className="check">{patientValues.medical_unif[item]}</label></div>
                                    ))}

                                </div> : <>

                                    {medical_unif && medical_unif.length > 0 && medical_unif.map((item, i) => (

                                        <div className="check-box sqeare" key={i}>
                                            <div className="round">
                                                <input type="checkbox" onClick={(e) => selectCheckbox(e, "medical_unif")} name={item.name} value={item.value} id={item.name} defaultChecked={patientValues && patientValues.medical_unif && patientValues.medical_unif[item.name] ? true : false}
                                                />
                                                <label for={item.name}></label>
                                            </div>
                                            {item.value}
                                        </div>


                                    ))} </>}



                                <div className="form_group">
                                    <label className="label">Other</label>
                                    <input type="text" onChange={(e) => updateInputValue(e)} name="fracture_other" className="form-control" placeholder="Enter" />
                                </div>
                            </div>
                            <div className="sub_section">
                                <div className="note_block mt-20">
                                    <p className="sub_title mb-10"> Day 3 post op AMT</p>
                                    <ol>
                                        <li> Age </li>
                                        <li> Time (nearest hour)</li>
                                        <li>Address Recall (42 West St)</li>
                                        <li> Year</li>
                                        <li> Location: Name of hospital</li>
                                        <li> ID 2 people - Recognise two objects</li>
                                        <li> Date of birth</li>
                                        <li>WW2 (1939-1945) </li>
                                        <li> Present Monarch </li>
                                        <li> Count backwards 201 </li>

                                    </ol>
                                    <p className="bold mb-0">Total /10</p>
                                </div>
                            </div>
                            <div className="sub_section">
                                <p className="sub_title mb_15">If Score &gt; 8</p>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form_group">
                                            <label className="label">CT head Findings</label>
                                            <input type="text" onChange={(e) => updateInputValue(e)} name="fracture_ct_head" className="form-control" placeholder="Enter" />
                                        </div>
                                    </div>
                                </div>
                                <div className="yn_switch mb-10 mt-10">
                                    <div className="label-container">
                                        <p>Is patient in delirium </p>
                                    </div>
                                    <label for="fracture_delirium" className="switch-container">
                                        <input hidden="" name="fracture_delirium" onChange={(e) => switchChange(e)} type="checkbox" className="switch_check" id="fracture_delirium" />
                                        <div className="switch-bg"></div>
                                        <div className="round-box"></div>
                                        <div className="switch-left">
                                            <span>YES</span>
                                        </div>
                                        <div className="switch-right">
                                            <span>NO</span>
                                        </div>
                                    </label>
                                </div>
                                <p>If yes, repeat when delirium has resolved</p>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form_group">
                                            <label className="label">Dementia Blood Screen findings</label>
                                            <input type="text" onChange={(e) => updateInputValue(e)} name="fracture_dementia" className="form-control" placeholder="Enter" />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="signature_block height152">
                                            <SignaturePad canvasProps={{ className: "sigPad" }} ref={(ref) => { fractureSigPad = ref }} />
                                            <p>Signature</p>
                                        </div>
                                        <button onClick={(e) => clear(e)}> Clear </button>
                                        <button onClick={(e) => trim(e)}> Trim </button>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form_group">
                                            <label className="label">Name </label>
                                            <input type="text" onChange={(e) => updateInputValue(e)} name="fracture_name" className="form-control" placeholder="Enter" />
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form_group">
                                                    <label className="label">Grade </label>
                                                    <input type="text" onChange={(e) => updateInputValue(e)} name="fracture_grade" className="form-control" placeholder="Enter" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form_group">
                                                    <label className="label">Bleep </label>
                                                    <input type="text" onChange={(e) => updateInputValue(e)} name="fracture_bleep" className="form-control" placeholder="Enter" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form_group">
                                                    <label className="label">Date </label>
                                                    <input type="date" onChange={(e) => updateInputValue(e)} name="fracture_sig_date" className="form-control" placeholder="Enter" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form_group">
                                                    <label className="label">Time </label>
                                                    <input type="time" onChange={(e) => updateInputValue(e)} name="fracture_sig_time" className="form-control" placeholder="Enter" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>




                                </div>
                                <button type="button" onClick={(e) => { submitUpdate(e) }} className="update_btn mt-0 mb-20">Submit</button>
                                <br /><br /><br /><br />
                            </div>



                        </div>


                    </div>
                </div>
            </div>




        </React.Fragment>
    )
}

Fracture.propTypes = {
    getWards: PropTypes.func,
    getTheatres: PropTypes.func,
    wards: PropTypes.array,
    user: PropTypes.any,
    theatres: PropTypes.array,
    clinic: PropTypes.array,
    patients: PropTypes.array,
    doctors: PropTypes.array,
    profile: PropTypes.object,
    news: PropTypes.array,
}

const mapStateToProps = ({ Layout, Login }) => ({
    wards: Layout.wards,
    user: Login.user,
    theatres: Layout.theatres,
    clinic: Layout.clinic,
    patients: Layout.patients,
    doctors: Layout.doctors,
    profile: Layout.profile,
    news: Layout.news,
    dailyrounds: Layout.dailyrounds
})

const mapDispatchToProps = dispatch => ({
    getWards: () => dispatch(getWardsList()),
    getTheatres: () => dispatch(getTheatresList()),
    getClinic: () => dispatch(getClinicList()),
    getPatient: () => dispatch(getPatientList()),
    getUserprofile: (id) => dispatch(getUprofile(id)),
    getDoctor: () => dispatch(getDoctorList()),
    patientupdate: (id, data) => dispatch(updatePatient(id, data)),
    newsList: (data) => dispatch(getNews(data)),
    dailyRoundsList: (data) => dispatch(getDailyRounds(data)),
    dailyUpdate: (data) => dispatch(updateDailyRounds(data)),
    teamupdatelist: (data, tablename) => dispatch(teamListUpdate(data, tablename))
})

export default connect(mapStateToProps, mapDispatchToProps)(Fracture)
