import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import MetaTags from 'react-meta-tags';
import SignaturePad from 'react-signature-canvas'
import PatientBlock from "../Clinic/patientBlock";
import { removeSpecialCharacters } from '../../utils/utils';
import { Buffer } from 'buffer';
import { connect } from "react-redux"
import { getWardsList, getTheatresList, getClinicList, getPatientList, getUprofile, getDoctorList, updatePatient } from "../../store/actions"

const PostOpComplications = props => {


    const [clinicValues, setClinic] = useState({})


    const {
        patients,
    } = props;

    useEffect(() => {
        const { getWards, getTheatres, getClinic, getDoctor, getPatient, getUserprofile } = props
        // getWards()
        // getTheatres()
        // getClinic()
        getPatient()
        getDoctor()
        let userId = localStorage.getItem("authUser")
        getUserprofile(userId)
    }, [])


    useEffect(() => {
        let clinicPatient = JSON.parse(localStorage.getItem("p"))
        if (patients && patients.length > 0 && clinicPatient && clinicPatient._id) {
            const cPatient = patients.filter(
                usr => usr._id === clinicPatient._id
            )
            if (cPatient && cPatient[0]) {
                let cpatientData = cPatient[0]
                delete cpatientData["_id"]
                setClinic(clinicValues => ({
                    ...clinicValues,
                    ...cpatientData
                }));
            }

        }
    }, [patients])

    const wardBack = (e) => {
        e.preventDefault()
        props.history.push("/wl")
        document.body.style.overflow = '';
    }


    return (
        <React.Fragment>

            <MetaTags>
                <title>Post OP Complications</title>
            </MetaTags>
            <div className='ward '>
                <div className="popup_wrp">
                    <div className="popup_full prescribe">
                        <div className="popup_hed">
                            <h2>POST OP COMPLICATIONS</h2>
                            <div className="dsp-flex"> <a className="popup_close" onClick={(e) => { wardBack(e) }}><span className="material-icons">close</span></a></div>

                        </div>
                        <div className="popup_content">
                            <div className="popup_scroll poc main ">
                                <div className="section pd-0 fb_table">
                                    <div className="tbl_block fbe ">

                                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link active" id="home-tab" data-bs-toggle="tab"
                                                    data-bs-target="#home2" type="button" role="tab" aria-controls="home2"
                                                    aria-selected="true">Has post op complications</button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link" id="profile-tab" data-bs-toggle="tab"
                                                    data-bs-target="#profile2" type="button" role="tab" aria-controls="profile2"
                                                    aria-selected="false"> No post op complications</button>
                                            </li>

                                        </ul>
                                        <div className="tab-content" id="myTabContent">
                                            <div className="tab-pane fade show active" id="home2" role="tabpanel"
                                                aria-labelledby="home-tab">
                                                <div className="tbl_block">
                                                    <div className="row m-0">
                                                        <div className="col-sm-6 col1">
                                                            <div className="check-box">
                                                                <div className="round">
                                                                    <input type="checkbox" id="checkbox-18" />
                                                                    <label for="checkbox-18"></label>
                                                                </div>
                                                                Post-operative Vomiting
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col2">
                                                            <div className="check-box disable">
                                                                <div className="round">
                                                                    <input type="checkbox" id="checkbox-19" />
                                                                    <label for="checkbox-19"></label>
                                                                </div>
                                                                Anti-emetic given
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col1">
                                                            <div className="check-box">
                                                                <div className="round">
                                                                    <input type="checkbox" id="checkbox-20" />
                                                                    <label for="checkbox-20"></label>
                                                                </div>
                                                                Post-operative Urine retention
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col2">
                                                            <div className="check_item mt-0 mb-0 disable">
                                                                <div className="select_btns popup_select">
                                                                    <div className="select_btn"><label><input name="site"
                                                                        type="radio" value="Site 1" /><span>Catheter
                                                                            inserted
                                                                        </span></label>
                                                                    </div>
                                                                    <div className="select_btn"><label><input name="site"
                                                                        type="radio" value="Site 2" /><span>Catheter
                                                                            removed
                                                                        </span></label></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col1">
                                                            <div className="check-box ">
                                                                <div className="round">
                                                                    <input type="checkbox" id="checkbox-21" />
                                                                    <label for="checkbox-21"></label>
                                                                </div>
                                                                Post-operative wound Bleeding/Haemorrhage/Dropped BP
                                                            </div>

                                                        </div>
                                                        <div className="col-sm-6 col2">
                                                            <div className="check-box mt-10 disable">
                                                                <div className="round">
                                                                    <input type="checkbox" id="checkbox-95" />
                                                                    <label for="checkbox-95"></label>
                                                                </div>
                                                                Blood transfusion/ Return to theatre
                                                            </div>

                                                            <div className="check-box mt-10 disable">
                                                                <div className="round">
                                                                    <input type="checkbox" id="checkbox-22" />
                                                                    <label for="checkbox-22"></label>
                                                                </div>
                                                                Senior Review
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col1">
                                                            <div className="check-box ">
                                                                <div className="round">
                                                                    <input type="checkbox" id="checkbox-22" />
                                                                    <label for="checkbox-22"></label>
                                                                </div>
                                                                Post-operative wound haematoma
                                                            </div>

                                                        </div>
                                                        <div className="col-sm-6 col2">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <div className="check-box disable">
                                                                        <div className="round">
                                                                            <input type="checkbox" id="checkbox-22" />
                                                                            <label for="checkbox-22"></label>
                                                                        </div>
                                                                        Return to theatre
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="check-box disable">
                                                                        <div className="round">
                                                                            <input type="checkbox" id="checkbox-22" />
                                                                            <label for="checkbox-22"></label>
                                                                        </div>
                                                                        Senior Review
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col1">
                                                            <div className="check-box ">
                                                                <div className="round">
                                                                    <input type="checkbox" id="checkbox-23" />
                                                                    <label for="checkbox-23"></label>
                                                                </div>
                                                                Dislocation
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col2">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <div className="check-box disable">
                                                                        <div className="round">
                                                                            <input type="checkbox" id="checkbox-24" />
                                                                            <label for="checkbox-24"></label>
                                                                        </div>
                                                                        Senior Review
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col1">
                                                            <div className="check-box ">
                                                                <div className="round">
                                                                    <input type="checkbox" id="checkbox-25" />
                                                                    <label for="checkbox-25"></label>
                                                                </div>
                                                                Potential VTE
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col2">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <div className="check-box disable">
                                                                        <div className="round">
                                                                            <input type="checkbox" id="checkbox-26" />
                                                                            <label for="checkbox-26"></label>
                                                                        </div>
                                                                        Investigations
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col1">
                                                            <div className="check-box ">
                                                                <div className="round">
                                                                    <input type="checkbox" id="checkbox-27" />
                                                                    <label for="checkbox-27"></label>
                                                                </div>
                                                                Post-operative Constipation
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col2">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <div className="check-box disable">
                                                                        <div className="round">
                                                                            <input type="checkbox" id="checkbox-28" />
                                                                            <label for="checkbox-28"></label>
                                                                        </div>
                                                                        Treatment
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col1">
                                                            <div className="check-box ">
                                                                <div className="round">
                                                                    <input type="checkbox" id="checkbox-29" />
                                                                    <label for="checkbox-29"></label>
                                                                </div>
                                                                Post-operative Diarrhoea
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col2">

                                                            <div className="check-box disable">
                                                                <div className="round">
                                                                    <input type="checkbox" id="checkbox-30" />
                                                                    <label for="checkbox-30"></label>
                                                                </div>
                                                                Treatment
                                                            </div>

                                                        </div>
                                                        <div className="col-sm-6 col1">
                                                            <div className="check-box ">
                                                                <div className="round">
                                                                    <input type="checkbox" id="checkbox-31" />
                                                                    <label for="checkbox-31"></label>
                                                                </div>
                                                                Post-operative Anaemia
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col2">

                                                            <div className="check-box disable">
                                                                <div className="round">
                                                                    <input type="checkbox" id="checkbox-32" />
                                                                    <label for="checkbox-32"></label>
                                                                </div>
                                                                Treatment
                                                            </div>

                                                        </div>
                                                        <div className="col-sm-6 col1">
                                                            <div className="check-box ">
                                                                <div className="round">
                                                                    <input type="checkbox" id="checkbox-33" />
                                                                    <label for="checkbox-33"></label>
                                                                </div>
                                                                Hospital acquired pneumonia
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col2">
                                                            <div className="check-box disable">
                                                                <div className="round">
                                                                    <input type="checkbox" id="checkbox-91" />
                                                                    <label for="checkbox-91"></label>
                                                                </div>
                                                                Organism - Treatment
                                                            </div>

                                                        </div>
                                                        <div className="col-sm-6 col1">
                                                            <div className="check-box ">
                                                                <div className="round">
                                                                    <input type="checkbox" id="checkbox-34" />
                                                                    <label for="checkbox-34"></label>
                                                                </div>
                                                                Post-operative UTI
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col2">
                                                            <div className="check-box disable">
                                                                <div className="round">
                                                                    <input type="checkbox" id="checkbox-92" />
                                                                    <label for="checkbox-92"></label>
                                                                </div>
                                                                Organism - Treatment
                                                            </div>

                                                        </div>
                                                        <div className="col-sm-6 col1">
                                                            <div className="check-box ">
                                                                <div className="round">
                                                                    <input type="checkbox" id="checkbox-35" />
                                                                    <label for="checkbox-35"></label>
                                                                </div>
                                                                Post –operative wound infection
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col2">
                                                            <div className="check-box disable">
                                                                <div className="round">
                                                                    <input type="checkbox" id="checkbox-93" />
                                                                    <label for="checkbox-93"></label>
                                                                </div>
                                                                Organism - Treatment
                                                            </div>

                                                        </div>
                                                        <div className="col-sm-6 col1">
                                                            <div className="check-box ">
                                                                <div className="round">
                                                                    <input type="checkbox" id="checkbox-36" />
                                                                    <label for="checkbox-36"></label>
                                                                </div>
                                                                Collapse (eg faint)/Fall injury
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col2">
                                                            <div className="check-box disable">
                                                                <div className="round">
                                                                    <input type="checkbox" id="checkbox-37" />
                                                                    <label for="checkbox-37"></label>
                                                                </div>
                                                                Treatment
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col1">
                                                            <div className="check-box ">
                                                                <div className="round">
                                                                    <input type="checkbox" id="checkbox-38" />
                                                                    <label for="checkbox-38"></label>
                                                                </div>
                                                                Pressure sore
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col2">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <div className="check-box disable">
                                                                        <div className="round">
                                                                            <input type="checkbox" id="checkbox-39" />
                                                                            <label for="checkbox-39"></label>
                                                                        </div>
                                                                        state grade
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="check-box disable">
                                                                        <div className="round">
                                                                            <input type="checkbox" id="checkbox-40" />
                                                                            <label for="checkbox-40"></label>
                                                                        </div>
                                                                        Treatment
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className="col-sm-6 col1">
                                                            <div className="check-box ">
                                                                <div className="round">
                                                                    <input type="checkbox" id="checkbox-41" />
                                                                    <label for="checkbox-41"></label>
                                                                </div>
                                                                External transfer
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col2">
                                                            <div className="check-box disable">
                                                                <div className="round">
                                                                    <input type="checkbox" id="checkbox-41" />
                                                                    <label for="checkbox-41"></label>
                                                                </div>
                                                                State reason for transfer
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col1">
                                                            <div className="check_item mt-0 mb-0 ">
                                                                <div className="select_btns popup_select">
                                                                    <div className="select_btn"><label><input name="site"
                                                                        type="radio" value="Site 1" /><span>Other
                                                                            clinical incidents
                                                                        </span></label>
                                                                    </div>
                                                                    <div className="select_btn"><label><input name="site"
                                                                        type="radio"
                                                                        value="Site 2" /><span>complications
                                                                        </span></label></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col2">
                                                            <div className="check-box disable">
                                                                <div className="round">
                                                                    <input type="checkbox" id="checkbox-42" />
                                                                    <label for="checkbox-42"></label>
                                                                </div>
                                                                state incident type
                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>
                                                <div className="tbl_block fbe">
                                                    <h2 className="tbl_hed">
                                                        <div className="left">ENHANCED RECOVERY </div>
                                                    </h2>
                                                    <div className="row m-0">
                                                        <div className="col-sm-6 col1">
                                                            <div className="check-box">
                                                                <div className="round">
                                                                    <input type="checkbox" id="checkbox-43" />
                                                                    <label for="checkbox-43"></label>
                                                                </div>
                                                                Analgesia - opioid sparing
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col2">

                                                        </div>
                                                        <div className="col-sm-6 col1">
                                                            <div className="check-box">
                                                                <div className="round">
                                                                    <input type="checkbox" id="checkbox-44" />
                                                                    <label for="checkbox-44"></label>
                                                                </div>
                                                                Protective ventilation
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col2">

                                                        </div>
                                                        <div className="col-sm-6 col1">
                                                            <div className="check-box ">
                                                                <div className="round">
                                                                    <input type="checkbox" id="checkbox-45" />
                                                                    <label for="checkbox-45"></label>
                                                                </div>
                                                                Nutrition - oral diet where possible
                                                            </div>

                                                        </div>
                                                        <div className="col-sm-6 col2">

                                                        </div>
                                                        <div className="col-sm-6 col1">
                                                            <div className="check-box ">
                                                                <div className="round">
                                                                    <input type="checkbox" id="checkbox-46" />
                                                                    <label for="checkbox-46"></label>
                                                                </div>
                                                                Patient-approved physiological targets
                                                            </div>

                                                        </div>
                                                        <div className="col-sm-6 col2">

                                                        </div>
                                                        <div className="col-sm-6 col1">
                                                            <div className="check-box ">
                                                                <div className="round">
                                                                    <input type="checkbox" id="checkbox-47" />
                                                                    <label for="checkbox-47"></label>
                                                                </div>
                                                                Fluids - minimum necessary
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col2">

                                                        </div>
                                                        <div className="col-sm-6 col1">
                                                            <div className="check-box ">
                                                                <div className="round">
                                                                    <input type="checkbox" id="checkbox-48" />
                                                                    <label for="checkbox-48"></label>
                                                                </div>
                                                                Lines / drains / catheters - remove within 48 hours where
                                                                possible
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col2">

                                                        </div>
                                                        <div className="col-sm-6 col1">
                                                            <div className="check-box ">
                                                                <div className="round">
                                                                    <input type="checkbox" id="checkbox-49" />
                                                                    <label for="checkbox-49"></label>
                                                                </div>
                                                                Stress ulcer prophylaxis
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col2">

                                                        </div>
                                                        <div className="col-sm-6 col1">
                                                            <div className="check-box ">
                                                                <div className="round">
                                                                    <input type="checkbox" id="checkbox-50" />
                                                                    <label for="checkbox-50"></label>
                                                                </div>
                                                                Mobility -Aim chair day 1,
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col2">

                                                        </div>
                                                        <div className="col-sm-6 col1">
                                                            <div className="check-box ">
                                                                <div className="round">
                                                                    <input type="checkbox" id="checkbox-51" />
                                                                    <label for="checkbox-51"></label>
                                                                </div>
                                                                Walk day 2
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col2">

                                                        </div>
                                                        <div className="col-sm-6 col1">
                                                            <div className="check-box ">
                                                                <div className="round">
                                                                    <input type="checkbox" id="checkbox-52" />
                                                                    <label for="checkbox-52"></label>
                                                                </div>
                                                                VTE prophylaxis - as per risk assessment
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col2">

                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="tbl_block fbe">
                                                    <h2 className="tbl_hed">
                                                        <div className="left">OPERATIVE SITE </div>
                                                    </h2>
                                                    <div className="row m-0">
                                                        <div className="col-sm-6 col1">
                                                            <div className="check-box">
                                                                <div className="round">
                                                                    <input type="checkbox" id="checkbox-53" />
                                                                    <label for="checkbox-53"></label>
                                                                </div>
                                                                Surgical site reviewed <br />
                                                                    Clean & dry with no signs of infection. <br />
                                                                        Wound healing well

                                                                    </div>
                                                            </div>
                                                            <div className="col-sm-6 col2">

                                                            </div>
                                                            <div className="col-sm-6 col1">
                                                                <div className="check-box">
                                                                    <div className="round">
                                                                        <input type="checkbox" id="checkbox-54" />
                                                                        <label for="checkbox-54"></label>
                                                                    </div>
                                                                    Sutures/Clips
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-6 col2">
                                                                <div className="check-box">
                                                                    <div className="round">
                                                                        <input type="checkbox" id="checkbox-55" />
                                                                        <label for="checkbox-55"></label>
                                                                    </div>
                                                                    To be removed
                                                                </div>
                                                                <div className="form-group mt-10">
                                                                    <input type="text" placeholder="Actions" className="form-control" />
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-6 col1">
                                                                <div className="check-box ">
                                                                    <div className="round">
                                                                        <input type="checkbox" id="checkbox-56" />
                                                                        <label for="checkbox-56"></label>
                                                                    </div>
                                                                    Surgical site infection or poor wound healing +
                                                                    Wound has dry ooze, stable

                                                                </div>
                                                            </div>
                                                            <div className="col-sm-6 col2 disable">
                                                                <div className="check_item mt-0 mb-0 ">
                                                                    <div className="select_btns popup_select">
                                                                        <div className="select_btn"><label><input name="site"
                                                                            type="radio" value="Site 1" /><span>Ongoing ooze
                                                                            </span></label>
                                                                        </div>
                                                                        <div className="select_btn"><label><input name="site"
                                                                            type="radio" value="Site 2" /><span>Soaked
                                                                                dressing
                                                                            </span></label></div>
                                                                    </div>
                                                                </div>
                                                                <div className="form-group mt-10">
                                                                    <input type="text" placeholder="Actions" className="form-control" />
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-6 col1">

                                                            </div>
                                                            <div className="col-sm-6 col2 disable">
                                                                <div className="check-box ">
                                                                    <div className="round">
                                                                        <input type="checkbox" id="checkbox-57" />
                                                                        <label for="checkbox-57"></label>
                                                                    </div>
                                                                    Erythema around wound
                                                                    Infected-Exudates-Pain
                                                                </div>
                                                                <div className="check-box mt-10">
                                                                    <div className="round">
                                                                        <input type="checkbox" id="checkbox-58" />
                                                                        <label for="checkbox-58"></label>
                                                                    </div>
                                                                    Senior Review+
                                                                </div>
                                                                <div className="form-group mt-10">
                                                                    <input type="text" placeholder="Actions" className="form-control" />
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-6 col1">
                                                                <div className="form-group">
                                                                    <input type="text" placeholder="Last culture date"
                                                                        className="form-control" />
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-6 col2">
                                                                <div className="check_item mt-0 mb-0 ">
                                                                    <div className="select_btns popup_select">
                                                                        <div className="select_btn"><label><input name="site"
                                                                            type="radio" value="Site 1" /><span>Culture
                                                                                positive
                                                                            </span></label>
                                                                        </div>
                                                                        <div className="select_btn"><label><input name="site"
                                                                            type="radio" value="Site 2" /><span>Organism
                                                                            </span></label></div>
                                                                        <div className="select_btn"><label><input name="site"
                                                                            type="radio" value="Site 2" /><span>Sensitive to
                                                                            </span></label></div>
                                                                    </div>
                                                                </div>
                                                                <div className="form-group mt-10">
                                                                    <input type="text" placeholder="Actions" className="form-control" />
                                                                </div>
                                                            </div>


                                                        </div>
                                                    </div>
                                                    <div className="tbl_block fbe">
                                                        <h2 className="tbl_hed">
                                                            <div className="left">SPECIALIST POSTOP INSTRUCTIONS </div>
                                                        </h2>
                                                        <div className="row m-0">
                                                            <div className="col-sm-6 col1">
                                                                <div className="check-box">
                                                                    <div className="round">
                                                                        <input type="checkbox" id="checkbox-71" />
                                                                        <label for="checkbox-71"></label>
                                                                    </div>
                                                                    Limb elevated if required using
                                                                    Slings / Pillows/Braun frame


                                                                </div>
                                                                <div className="check-box mt-10">
                                                                    <div className="round">
                                                                        <input type="checkbox" id="checkbox-72" />
                                                                        <label for="checkbox-72"></label>
                                                                    </div>
                                                                    Icepacks applied as unit standard
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-6 col2">

                                                            </div>
                                                            <div className="col-sm-6 col1">
                                                                <div className="check-box">
                                                                    <div className="round">
                                                                        <input type="checkbox" id="checkbox-73" />
                                                                        <label for="checkbox-73"></label>
                                                                    </div>
                                                                    CPM used. Set at 0 - degrees
                                                                </div>

                                                            </div>
                                                            <div className="col-sm-6 col2">

                                                            </div>
                                                            <div className="col-sm-6 col1">
                                                                <div className="check-box">
                                                                    <div className="round">
                                                                        <input type="checkbox" id="checkbox-74" />
                                                                        <label for="checkbox-74"></label>
                                                                    </div>
                                                                    No Cast
                                                                </div>
                                                                <div className="form-group mt-10">
                                                                    <input type="text" placeholder="Cast" className="form-control" />
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-6 col2">
                                                                <div className="check-box">
                                                                    <div className="round">
                                                                        <input type="checkbox" id="checkbox-75" />
                                                                        <label for="checkbox-75"></label>
                                                                    </div>
                                                                    Tight or rubbing
                                                                </div>
                                                                <div className="check-box mt-10">
                                                                    <div className="round">
                                                                        <input type="checkbox" id="checkbox-76" />
                                                                        <label for="checkbox-76"></label>
                                                                    </div>
                                                                    Skin underneath needs attention
                                                                </div>
                                                                <div className="form-group mt-10">
                                                                    <input type="text" placeholder="Action" className="form-control" />
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-6 col1">
                                                                <div className="check-box ">
                                                                    <div className="round">
                                                                        <input type="checkbox" id="checkbox-77" />
                                                                        <label for="checkbox-77"></label>
                                                                    </div>
                                                                    Braces/Splints/ Popliteal wedge/ Abd. pillow
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-6 col2 ">
                                                                <div className="check-box disable">
                                                                    <div className="round">
                                                                        <input type="checkbox" id="checkbox-78" />
                                                                        <label for="checkbox-78"></label>
                                                                    </div>
                                                                    Checked
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-6 col1">
                                                                <div className="form-group ">
                                                                    <input type="text" placeholder="Traction" className="form-control" />
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-6 col2 ">
                                                                <div className="check-box ">
                                                                    <div className="round">
                                                                        <input type="checkbox" id="checkbox-79" />
                                                                        <label for="checkbox-79"></label>
                                                                    </div>
                                                                    Checked & adjusted
                                                                </div>

                                                                <div className="form-group mt-10">
                                                                    <input type="text" placeholder="Weight" className="form-control" />
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-6 col1">
                                                                <div className="check-box ">
                                                                    <div className="round">
                                                                        <input type="checkbox" id="checkbox-80" />
                                                                        <label for="checkbox-80"></label>
                                                                    </div>
                                                                    Joint ROM
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-6 col2">

                                                            </div>
                                                            <div className="col-sm-6 col1">
                                                                <div className="check-box ">
                                                                    <div className="round">
                                                                        <input type="checkbox" id="checkbox-81" />
                                                                        <label for="checkbox-81"></label>
                                                                    </div>
                                                                    Distal NV Status - Intact
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-6 col2">
                                                                <div className="check-box disable">
                                                                    <div className="round">
                                                                        <input type="checkbox" id="checkbox-82" />
                                                                        <label for="checkbox-82"></label>
                                                                    </div>
                                                                    Injured limb NV observations - 4 hourly / 2 hourly.   Alert if…
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-6 col1">
                                                                <div className="check-box ">
                                                                    <div className="round">
                                                                        <input type="checkbox" id="checkbox-83" />
                                                                        <label for="checkbox-83"></label>
                                                                    </div>
                                                                    No evidence of Compartment syndrome
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-6 col2 disable">
                                                                <div className="check-box ">
                                                                    <div className="round">
                                                                        <input type="checkbox" id="checkbox-84" />
                                                                        <label for="checkbox-84"></label>
                                                                    </div>
                                                                    Need to rule out compartment syndrome
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="tab-pane fade" id="profile2" role="tabpanel"
                                                    aria-labelledby="profile-tab">
                                                    <div className="patients_table">
                                                        <div className="sidebarNews">
                                                            <ul>
                                                                <li className="hed">TIME</li>
                                                                <li>ENTER</li>

                                                            </ul>
                                                        </div>
                                                        <div className="news_result ">
                                                            <div className="news_result_wrp ">
                                                                <ul className="news_item ">
                                                                    <li className="hed bgwhite">Urine / Catheter
                                                                    </li>
                                                                    <li> <input type="text" className="form-control"
                                                                        placeholder="Enter" /></li>

                                                                </ul>
                                                                <ul className="news_item ">
                                                                    <li className="hed bgwhite">Faeces / stoma</li>
                                                                    <li> <input type="text" className="form-control"
                                                                        placeholder="Enter" /></li>

                                                                </ul>
                                                                <ul className="news_item ">
                                                                    <li className="hed bgwhite">Other
                                                                        Eg: Drains
                                                                        TOTAL
                                                                    </li>
                                                                    <li> <input type="text" className="form-control"
                                                                        placeholder="Enter" /></li>

                                                                </ul>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                </div>
                            </div>
                            <div className="popup_footer">
                                <div className="prescribe_footer">
                                    <div className="dsp-flex">
                                        <a className="icon_btn"> <span className="material-icons">save</span> SAVE</a>
                                        <button className="border_btn"><span className="material-icons">save_as</span>SAVE &amp;
                                            CLOSE
                                        </button>
                                    </div>

                                    <button className="btn dsp_flex" onClick={(e) => { wardBack(e) }}><span className="material-icons"><span
                                        className="material-icons">close</span></span>DISCARD </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>


        </React.Fragment>
    )
}

PostOpComplications.propTypes = {
    t: PropTypes.any,
    getWards: PropTypes.func,
    getTheatres: PropTypes.func,
    wards: PropTypes.array,
    user: PropTypes.any,
    theatres: PropTypes.array,
    clinic: PropTypes.array,
    patients: PropTypes.array,
    doctors: PropTypes.array,
    profile: PropTypes.object
}

const mapStateToProps = ({ Layout, Login }) => ({
    wards: Layout.wards,
    user: Login.user,
    theatres: Layout.theatres,
    clinic: Layout.clinic,
    patients: Layout.patients,
    doctors: Layout.doctors,
    profile: Layout.profile
})

const mapDispatchToProps = dispatch => ({
    getWards: () => dispatch(getWardsList()),
    getTheatres: () => dispatch(getTheatresList()),
    getClinic: () => dispatch(getClinicList()),
    getPatient: () => dispatch(getPatientList()),
    getUserprofile: (id) => dispatch(getUprofile(id)),
    getDoctor: () => dispatch(getDoctorList()),
    patientupdate: (id, data) => dispatch(updatePatient(id, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(PostOpComplications)
