import PropTypes from 'prop-types'
import React, { useState, useEffect } from "react"
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux"
import { Link } from "react-router-dom"

import WelcomeGfx from '../../assets/images/welcome-gfx.png';
import Patient from '../../assets/images/user.png';
import HospitalBed from '../../assets/images/hospital-bed.png';

import PatientRecord from '../../assets/images/Patient-record.svg';
import CQMS from '../../assets/images/CQMS.svg';
import Ward from '../../assets/images/Ward.svg';
import Oncall from '../../assets/images/On-call.svg';
import Theatre from '../../assets/images/Theatre.svg';
import TeamRoom from '../../assets/images/Tea-m-Room.svg';
import Clinic from '../../assets/images/Clinic.svg';
import PatientPortal from '../../assets/images/Patient-Portal.svg';
import Operations from '../../assets/images/Operations.svg';
import NursingOffice from '../../assets/images/Nursing-Office.svg';
import BusinessOffice from '../../assets/images/Business-Office.svg';
import AdminOffice from '../../assets/images/Admin-Office.svg';
import Admin from '../../assets/images/Admin.svg';
import myOffice from '../../assets/images/myOffice.svg'
import betterfly from '../../assets/images/betterfly.svg'

// actions
import { getWardsList, getTheatresList, getClinicList, getPatientList, getUprofile } from "../../store/actions"



const Dashboard = (props) => {

  // const [me, setMe] = useState("");
  const {
    wards, user, theatres, clinic, patients, profile
  } = props;


  useEffect(() => {
    const { getWards, getTheatres, getClinic, getPatient, getUserprofile } = props
    getWards()
    getTheatres()
    getClinic()
    getPatient()
    let userId = localStorage.getItem("authUser")
    getUserprofile(userId)

  }, [])

 


  const labdetails = (e, item) => {
    e.preventDefault()
    localStorage.setItem("p", JSON.stringify(item))
    props.history.push("/lab")
  }

  const theatreClick = (e, item) => {
    e.preventDefault()
    localStorage.setItem("t", JSON.stringify(item))
    props.history.push("/theatre")
  }

  const wardClick = (e, item) => {
    e.preventDefault()
    localStorage.setItem("w", JSON.stringify(item))
    props.history.push("/wlist")
  }
  const btnClick = (e, page, title) => {
    e.preventDefault()
    localStorage.setItem("pt", title)
    props.history.push("/"+page)
  }
  // console.log("me ", me)

  return (
    <React.Fragment>

      <MetaTags>
        <title>Dashboard</title>
      </MetaTags>

    <div className='dashboard ward'> 
      <div className="main">

      <div className="wrapper">
      <ul className="oncall_dboard">
                <li className="item_card1">
                    <div className="item_card">
                        <p>Welcome</p>
                        <h3>{profile && profile.firstname} {profile && profile.lastname},</h3>
                        <p>Where do you want to work today?</p>
                    </div>
                 
                </li>
                <li>
                    <a onClick={(e) => btnClick(e, "pr", "Patient Record")} className="item_card">
                        <span className="icon"> <img src={PatientRecord} /></span>
                        Patient Record
                    </a>
                </li>
                <li>
                    <a onClick={(e) => btnClick(e, "uc", "CQMS")} className="item_card">
                        <span className="icon"> <img src={CQMS} /></span>
                        CQMS
                    </a>
                </li>
                <li>
                    <a onClick={(e) => btnClick(e, "wl", "Ward")} className="item_card">
                        <span className="icon"> <img src={Ward} /></span>
                        Ward
                    </a>
                </li>
                <li>
                    <a onClick={(e) => btnClick(e, "theatre", "Theatre")} className="item_card">
                        <span className="icon"> <img src={Theatre} /></span>
                        Theatre 
                    </a>
                </li>
                <li>
                    <a onClick={(e) => btnClick(e, "cl", "OUT PATIENT CLINIC & TELEMEDICINE")} className="item_card">
                        <span className="icon"> <img src={Clinic} /></span>
                        Clinic
                    </a>
                </li>
                <li>
                    <a onClick={(e) => btnClick(e, "ol", "On call")} className="item_card">
                        <span className="icon"> <img src={Oncall} /></span>
                        On call
                    </a>
                </li>
                
                <li>
                    <a onClick={(e) => btnClick(e, "teamlist", "Tea-m Room")} className="item_card">
                        <span className="icon"> <img src={TeamRoom} /></span>
                        Tea-m Room
                    </a>
                </li>

                <li>
                    <a onClick={(e) => btnClick(e, "myoffice", "My Office")} className="item_card">
                        <span className="icon"> <img src={myOffice} /></span>
                        My Office
                    </a>
                </li>
                <li>
                    <a  className="item_card betterfly">
                        <span className="icon"> <img src={betterfly}/></span>
                     
                    </a>
                </li>
               
                <li>
                    <a onClick={(e) => btnClick(e, "uc", "Patient Portal")} className="item_card">
                        <span className="icon"> <img src={PatientPortal} /></span>
                        Patient Portal
                    </a>
                </li> 
                <li>
                    <a onClick={(e) => btnClick(e, "noffice", "Nursing Office")} className="item_card">
                        <span className="icon"> <img src={NursingOffice} /></span>
                        Nursing Office
                    </a>
                </li>
                <li>
                    <a onClick={(e) => btnClick(e, "uc", "Operations")} className="item_card">
                        <span className="icon"> <img src={Operations} /></span>
                        Operations
                    </a>
                </li>
                
                <li>
                    <a onClick={(e) => btnClick(e, "uc", "Business Office")} className="item_card">
                        <span className="icon"> <img src={BusinessOffice} /></span>
                        Business Office
                    </a>
                </li>
                <li>
                    <a onClick={(e) => btnClick(e, "uc", "Admin Office")} className="item_card">
                        <span className="icon"> <img src={AdminOffice} /></span>
                        Admin Office
                    </a>
                </li>
                {/* <li>
                    <a href="#" className="item_card">
                        <span className="icon"> <img src={Admin} /></span>
                        Admin
                    </a>
                </li> */}
                
              
            </ul>
            </div>


        {/* {profile && profile.designation == "patient" &&
          <div className="wrapper">
            <div className="welcome_block">
              <h3>Welcome to Dashboard, Mr {profile && profile.firstname} {profile && profile.lastname}</h3>
              <Link to="/vc"> {profile.call} </Link>
            </div>
          </div>} */}

        {/* {profile && profile.designation == "lab" &&
          <div className="wrapper">
            <div className="out_patient_section out_patient_details ld">
              <h2 className='main_title'>Tests</h2>


              {patients && patients.length > 0 &&
                patients.map((item, i) => (

                  (item.xray || item.xray2) &&


                  <div className="visit_info" key={i}>
                    <div className="visit">
                      <h3 className='uppercase'>{item.xray || item.xray2}  </h3>
                      <div className="count">{i < 10 ? "0" + i : i}</div>
                    </div>
                    <div className="problem">
                      <h3>{item.pro1 || item.pro2}</h3>
                      <p>Referred by {"Dr " + item.doctor}  <span className='ml-20'>  {item.updated} </span></p>
                    </div>
                    <a onClick={(e) => labdetails(e, item)} className="icon_btn">NEW</a>
                  </div>

                ))}

            </div>
          </div>
        } */}

        {/* {profile && (profile.designation == "nurse" ||  profile.designation == "receptionist" )&&
          <div className="wrapper">
            <div className="welcome_block">
              <div className="wel-1"> <img src={WelcomeGfx} /></div>
              <div className="wel-2">
                <h3>Hello, Mr {profile && profile.firstname} {profile && profile.lastname}</h3>
                <p>It's going to be a great day, You have 3 preferred locations.</p>
              </div>
              <div className="wel-3">
                <a href="#" className="btn-primary">Preferred Locations <span className="material-icons">play_circle_filled</span></a>
              </div>
            </div>

            <div className="locations_block">

              <div className="wards_block">
                <h3>Wards</h3>
                <div className="wards_list">

                  {wards && wards.length > 0 &&
                    wards.map((item, i) => (
                      <div className="ward_item" key={i}>
                        <Link to="/npatient"><div className="ward_thumb">
                          <img src={HospitalBed} />
                        </div></Link>
                        <h4>{item.name}</h4>
                      </div>
                    ))}
                </div>

              </div>



            </div>

          </div>} */}


{/* 
        {profile && (profile.designation == "juniorDoctor" || profile.designation == "seniorDoctor") &&
          <div className="wrapper">
            <div className="welcome_block">
              <div className="wel-1"> <img src={WelcomeGfx} /></div>
              <div className="wel-2">
                <h3>Hello, Mr {profile && profile.firstname} {profile && profile.lastname}</h3>
                <p>It's going to be a great day, You have 3 preferred locations.</p>
              </div>
              <div className="wel-3">
                <a href="#" className="btn-primary">Preferred Locations <span className="material-icons">play_circle_filled</span></a>
              </div>
            </div>

            <div className="locations_block">
              <div className="locations_hed">
                <h2 className="title">Select a Location</h2>
                <select className="form-control">
                  <option>NHS</option>
                </select>
              </div>


              <div className="wards_block">
                <h3>Wards</h3>
                <div className="wards_list">

                  {wards && wards.length > 0 &&
                    wards.map((item, i) => (
                      <div className="ward_item" key={i}>
                        <div className="ward_thumb">
                        <a onClick={(e) => wardClick(e, item)}><img src={HospitalBed} /></a>
                        </div>
                        <h4>{item.name}</h4>
                      </div>
                    ))}
                </div>

              </div>


              <div className="wards_block">
                <h3>Theatres</h3>
                <div className="wards_list">

                  {theatres && theatres.length > 0 &&
                    theatres.map((item, i) => (
                      <div className="ward_item" key={i}>
                        <div className="ward_thumb">
                          <a onClick={(e) => theatreClick(e, item)}><img src={HospitalBed} /></a>
                        </div>
                        <h4>{item.name}</h4>
                      </div>
                    ))}
                </div>
              </div>



              <div className="wards_block">
                <h3>Clinics</h3>
                <div className="wards_list">

                  {clinic && clinic.length > 0 &&
                    clinic.map((item, i) => (
                      <div className="ward_item" key={i}>
                        <div className="ward_thumb">
                          <Link to="/clinic"><img src={HospitalBed} /></Link>
                        </div>
                        <h4>{item.name}</h4>
                      </div>
                    ))}

                </div>

              </div>

              <div className="wards_block">
                <h3>Oncall</h3>
                <div className="wards_list">
                      <div className="ward_item">
                        <div className="ward_thumb">
                        <a onClick={(e) => btnClick(e)}><img src={HospitalBed} /></a>
                        </div>
                        <h4>Oncall</h4>
                      </div>
                   
                </div>

              </div>


              
            </div>

          </div>} */}

      </div>

      </div>

    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
  getWards: PropTypes.func,
  getTheatres: PropTypes.func,
  wards: PropTypes.array,
  user: PropTypes.any,
  theatres: PropTypes.array,
  clinic: PropTypes.array,
  patients: PropTypes.array,
  profile: PropTypes.object
}

const mapStateToProps = ({ Layout, Login }) => ({
  wards: Layout.wards,
  user: Login.user,
  theatres: Layout.theatres,
  clinic: Layout.clinic,
  patients: Layout.patients,
  profile: Layout.profile
})

const mapDispatchToProps = dispatch => ({
  getWards: () => dispatch(getWardsList()),
  getTheatres: () => dispatch(getTheatresList()),
  getClinic: () => dispatch(getClinicList()),
  getPatient: () => dispatch(getPatientList()),
  getUserprofile: (id) => dispatch(getUprofile(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
