import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import MetaTags from 'react-meta-tags';
import PatientBlock from "../Clinic/patientBlock";
import { Buffer } from 'buffer';
import SignaturePad from 'react-signature-canvas'
import {
    Modal
} from "reactstrap"
import { connect } from "react-redux"
import { getWardsList, getTheatresList, getClinicList, getPatientList, getUprofile, getDoctorList, updateTheatrePatient, updateWardPatient } from "../../store/actions"

const preOpAssesment = props => {


    const [modal_guidance, setmodal_guidance] = useState(false)
    const [modal_advnotification, setmodal_advnotification] = useState(false)
    const [modal_riskassesment, setmodal_riskassesment] = useState(false)
    const [file, setFile] = useState({});
    const [theatreValues, setTheatre] = useState({})

    const {
        wards, user, theatres, clinic, patients, profile, doctors
    } = props;

    let staffsigPad = useRef(null);
    let nursesigPad = useRef(null);
    let departmentsigPad = useRef(null);

    useEffect(() => {
        const { getDoctor, getPatient, getUserprofile } = props
        // getWards()
        // getTheatres()
        // getClinic()
        getPatient()
        getDoctor()
        let userId = localStorage.getItem("authUser")
        getUserprofile(userId)
    }, [])



    const theatreBack = (e) => {
        e.preventDefault()
        props.history.push("/tpatient")
    }

    const updateTheatre = (e) => {
        const { theatreupdate } = props
        theatreupdate(null, theatreValues)
    }

    const updateTheatreValue = (e) => {
        let val = e.target.value;
        let name = e.target.name;
        let updatedValue = { [name]: val };
        setTheatre(theatreValues => ({
            ...theatreValues,
            ...updatedValue
        }));
    }

    const updateTheatreValues = (e, name, val) => {
        e.preventDefault()
        let updatedValue = {};
        updatedValue = { [name]: val };
        setTheatre(theatreValues => ({
            ...theatreValues,
            ...updatedValue
        }));
    }


    const updateMultipleValues = (e, name, val, type) => {
        e.preventDefault()
        let tvalues = theatreValues
        if (tvalues[name]) {
            const exists = tvalues[name].includes(val);
            if(!exists){
                tvalues[name].push(val)
            }
            
        } else {
            tvalues = {
                [name]: [val]
            }
        }
        // let updatedValue = {};
        tvalues[type] = true
        setTheatre(theatreValues => ({
            ...theatreValues,
            ...tvalues
        }));

    }

    const clear = (e, refvalue) => {
        e.preventDefault()
        refvalue.clear()
    }
    const trim = (e, type, refvalue) => {
        e.preventDefault()
        sigUpload(refvalue.getCanvas().toDataURL('image/png'), type)
    }

    const sigUpload = async (filedata, name) => {
        const file = filedata
        //console.log("file ", file, name)
        let filename = name+".png";
        // const base64 = await convertBase64(file)
        //console.log("filename ", filename)
        let pdetails = JSON.parse(localStorage.getItem("p"))

        const dataf = file.replace(/^data:image\/\w+;base64,/, "");
  
        const buf = Buffer.from(dataf, "base64");
        let uploadFilePath = "ipr/" + pdetails._id + "/" + name + "_result" + "/" + removeSpecialCharacters(filename);
        let data = { source_bucket: "ipr-resources", sourcepath: uploadFilePath }
       // console.log("data ", data)

        const response = await fetch(API_URL+'upload?appname=ipr', {
            method: "POST",
            mode: "cors",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
        const result = await response.json();
       // console.log("result ", result, buf.length)
        let url = result.result
       // console.log("url ", url)
        const base64Data = new Buffer.from(filedata.replace(/^data:image\/\w+;base64,/, ""), 'base64');
        // Getting the file type, ie: jpeg, png or gif
        const type = filedata.split(';')[0].split('/')[1];
        const uploadImage = await fetch(url, {
            method: "PUT",
            mode: "cors",
            headers: {
               'Content-Type':`image/${type}`,
                'Content-Encoding': 'base64',
                ACL: 'public-read',
            },
            body: base64Data
        })

        //console.log("resultImage ", uploadImage)

        let imagePath = "https://ipr-resources.s3.amazonaws.com/"
        let imageUploadPath = imagePath + uploadFilePath;

        const { patientupdate } = props
        let updateObj = {
            [name + "_result"]: imageUploadPath,
            [name + "_update"]: new Date()
        }
        patientupdate(pdetails._id, updateObj)
        setFile(file => ({
            ...file,
            ...updateObj
        }));
        setTheatre(theatreValues => ({
            ...theatreValues,
            ...updateObj
        }));
       
        let patient = {
            ...pdetails,
            ...updateObj
        }
        localStorage.setItem("p", JSON.stringify(patient))

    };


   
    let clinicPatient = JSON.parse(localStorage.getItem("p"))

    return (
        <React.Fragment>

            <MetaTags>
                <title>Machine Equipment / Checks</title>
            </MetaTags>
            <div className="theatre">

                <div className='popup_wrp'>
                    <div className="popup_full prescribe">
                        <div className='popup_hed'>
                            <h2>PREOP ASSESSMENT CLINIC</h2>
                            <a onClick={(e) => theatreBack(e)} className='popup_close'><span className="material-icons">close</span></a>
                        </div>
                        <div className='popup_content'>
                            <div className='popup_scroll main pr atp height190 pac'>
                                <div className="out_patient_section">
                                    <PatientBlock patient={clinicPatient} />
                                </div>

                                <div className="section simple_wound ctr">
                                    <h2 className="popup_title"> Each Patient</h2>
                                    <div className="pd_border"></div>
                                    <h2 className="popup_title">MEDICAL QUESTIONNAIRE</h2>
                                    <div className="check_item mt-10">
                                        <label className="check">
                                            <div className="check_item"><label className="check">CARDIOVASCULAR</label>
                                                <div className="select_btns popup_select">
                                                    <div className="select_btn"><label><input onClick={(e) => updateTheatreValue(e)} name="cardiovascular" type="radio"
                                                        value="NORMAL" /><span>NORMAL</span></label></div>
                                                    <div className="select_btn"><label><input onClick={(e) => updateTheatreValue(e)} name="cardiovascular" type="radio"
                                                        value="ABNORMAL" /><span>ABNORMAL</span></label></div>
                                                </div>
                                            </div>
                                        </label>
                                    </div>

                                    {theatreValues.cardiovascular == "ABNORMAL" &&
                                        <div>
                                            <div className="leavel_1">
                                                <h2 className="popup_title">CARDIOVASCULAR </h2>
                                                <h2 className="selected">ABNORMAL<a onClick={(e) => updateTheatreValues(e, "cardiovascular_assesment_abnormal", false)} ><span className="material-symbols-outlined"> reply
                                                </span></a></h2>
                                            </div>

                                            {theatreValues.cardiovascular_assesment_abnormal && theatreValues.cardiovascular_abnormal && theatreValues.cardiovascular_abnormal.length > 0 ? <div className="check_list w_white">

                                                {theatreValues.cardiovascular_abnormal.map((item, i) => (
                                                    <div className="check_item active" onClick={(e) => updateTheatreValues(e, "cardiovascular_assesment_abnormal", false)} ><label className="check">{item}</label></div>
                                                ))}

                                            </div> : (theatreValues.cardiovascular_assesment_abnormal == undefined || theatreValues.cardiovascular_assesment_abnormal == false) &&
                                            <div className="check_list w_white">
                                                <div className="check_item" onClick={(e) => updateMultipleValues(e, "cardiovascular_abnormal", "Shortness of breath: At rest, Minimal exertion, PND", "cardiovascular_assesment_abnormal")} ><label className="check">Shortness of breath: At rest, Minimal exertion, PND</label>
                                                </div>
                                                <div className="check_item" onClick={(e) => updateMultipleValues(e, "cardiovascular_abnormal", "Poor exercise tolerance", "cardiovascular_assesment_abnormal")}><label className="check">Poor exercise tolerance</label>
                                                </div>
                                                <div className="check_item" onClick={(e) => updateMultipleValues(e, "cardiovascular_abnormal", "Chest pain Unstable Angina", "cardiovascular_assesment_abnormal")}><label className="check">Chest pain Unstable Angina</label>
                                                </div>
                                                <div className="check_item" onClick={(e) => updateMultipleValues(e, "cardiovascular_abnormal", "MI Within the last six months", "cardiovascular_assesment_abnormal")}><label className="check">MI Within the last six months</label>
                                                </div>
                                                <div className="check_item" onClick={(e) => updateMultipleValues(e, "cardiovascular_abnormal", "Hypertension-Uncontrolled", "cardiovascular_assesment_abnormal")}><label className="check">Hypertension-Uncontrolled</label>
                                                </div>
                                                <div className="check_item" onClick={(e) => updateMultipleValues(e, "cardiovascular_abnormal", "Murmur", "cardiovascular_assesment_abnormal")}><label className="check">Murmur</label>
                                                </div>
                                                <div className="check_item" onClick={(e) => updateMultipleValues(e, "cardiovascular_abnormal", "Aortic Stenosis", "cardiovascular_assesment_abnormal")}><label className="check">Aortic Stenosis</label>
                                                </div>
                                                <div className="check_item" onClick={(e) => updateMultipleValues(e, "cardiovascular_abnormal", "Significant Valvular Disorder", "cardiovascular_assesment_abnormal")}><label className="check">Significant Valvular Disorder</label>
                                                </div>
                                                <div className="check_item" onClick={(e) => updateMultipleValues(e, "cardiovascular_abnormal", "Heart Failure", "cardiovascular_assesment_abnormal")}><label className="check">Heart Failure</label>
                                                </div>
                                                <div className="check_item" onClick={(e) => updateMultipleValues(e, "cardiovascular_abnormal", "Arrhythmias", "cardiovascular_assesment_abnormal")}><label className="check">Arrhythmias</label>
                                                </div>
                                                <div className="check_item" onClick={(e) => updateMultipleValues(e, "cardiovascular_abnormal", "Chronic beta blocker", "cardiovascular_assesment_abnormal")}><label className="check">Chronic beta blocker</label>
                                                </div>
                                                <div className="check_item" onClick={(e) => updateMultipleValues(e, "cardiovascular_abnormal", "Cardiomyopathy", "cardiovascular_assesment_abnormal")}><label className="check">Cardiomyopathy</label>
                                                </div>
                                                <div className="check_item" onClick={(e) => updateMultipleValues(e, "cardiovascular_abnormal", "Dyslipidemia", "cardiovascular_assesment_abnormal")}><label className="check">Dyslipidemia</label>
                                                </div>
                                                <div className="check_item" onClick={(e) => updateMultipleValues(e, "cardiovascular_abnormal", "Abnormal ECG", "cardiovascular_assesment_abnormal")}><label className="check">Abnormal ECG</label>
                                                </div>
                                                <div className="check_item" onClick={(e) => updateMultipleValues(e, "cardiovascular_abnormal", "Paced", "cardiovascular_assesment_abnormal")}><label className="check">Paced</label>
                                                </div>
                                                <div className="check_item" onClick={(e) => updateMultipleValues(e, "cardiovascular_abnormal", "Aneurysm", "cardiovascular_assesment_abnormal")}><label className="check">Aneurysm</label>
                                                </div>
                                            </div>}


                                            <div className=" mb-20">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form_group">
                                                            <div className="floating-label-group"><input type="text" name="dictate" onChange={(e) => updateTheatreValue(e)}
                                                                className="form-control" /><label
                                                                    className="floating-label">Dictate or type additional cardiac
                                                                    details here</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form_group">
                                                            <div className="floating-label-group"><input type="text" name="needstest" onChange={(e) => updateTheatreValue(e)}
                                                                className="form-control" /><label
                                                                    className="floating-label">Needs a test</label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>


                                            </div>
                                        </div>}

                                    <div className="check_item mt-10">
                                        <label className="check">
                                            <div className="check_item"><label className="check">RESPIRATORY</label>
                                                <div className="select_btns popup_select">
                                                    <div className="select_btn"><label><input type="radio"
                                                        value="NORMAL" onClick={(e) => updateTheatreValue(e)} name="respiratory" /><span>NORMAL</span></label></div>
                                                    <div className="select_btn"><label><input onClick={(e) => updateTheatreValue(e)} name="respiratory" type="radio"
                                                        value="ABNORMAL" /><span>ABNORMAL</span></label></div>
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                    {theatreValues.respiratory == "ABNORMAL" &&
                                        <div>
                                            <div className="leavel_1">
                                                <h2 className="popup_title">RESPIRATORY </h2>
                                                <h2 className="selected">ABNORMAL<a onClick={(e) => updateTheatreValues(e, "respiratory_assesment_abnormal", false)} ><span className="material-symbols-outlined"> reply
                                                </span></a></h2>
                                            </div>
                                            {theatreValues.respiratory_assesment_abnormal && theatreValues.respiratory_abnormal && theatreValues.respiratory_abnormal.length > 0 ? <div className="check_list w_white">

                                                {theatreValues.respiratory_abnormal.map((item, i) => (
                                                    <div className="check_item active" onClick={(e) => updateTheatreValues(e, "respiratory_assesment_abnormal", false)} ><label className="check">{item}</label></div>
                                                ))}

                                            </div> : (theatreValues.respiratory_assesment_abnormal == undefined || theatreValues.respiratory_assesment_abnormal == false) &&
                                            <div className="check_list w_white">
                                                <div className="check_item" onClick={(e) => updateMultipleValues(e, "respiratory_abnormal", "Asthma", "respiratory_assesment_abnormal")}><label className="check">Asthma</label>
                                                </div>
                                                <div className="check_item" onClick={(e) => updateMultipleValues(e, "respiratory_abnormal", "SOB", "respiratory_assesment_abnormal")}><label className="check">SOB</label>
                                                </div>
                                                <div className="check_item" onClick={(e) => updateMultipleValues(e, "respiratory_abnormal", "Cough", "respiratory_assesment_abnormal")}><label className="check">Cough</label>
                                                </div>
                                                <div className="check_item" onClick={(e) => updateMultipleValues(e, "respiratory_abnormal", "COPD", "respiratory_assesment_abnormal")}><label className="check">COPD</label>
                                                </div>
                                                <div className="check_item" onClick={(e) => updateMultipleValues(e, "respiratory_abnormal", "Hospital admission", "respiratory_assesment_abnormal")}><label className="check">Hospital admission</label>
                                                </div>
                                                <div className="check_item" onClick={(e) => updateMultipleValues(e, "respiratory_abnormal", "Emphysema", "respiratory_assesment_abnormal")}><label className="check">Emphysema</label>
                                                </div>
                                                <div className="check_item" onClick={(e) => updateMultipleValues(e, "respiratory_abnormal", "Pneumothorax", "respiratory_assesment_abnormal")}><label className="check">Pneumothorax</label>
                                                </div>
                                                <div className="check_item" onClick={(e) => updateMultipleValues(e, "respiratory_abnormal", "Pneumonia", "respiratory_assesment_abnormal")}><label className="check">Pneumonia</label>
                                                </div>
                                                <div className="check_item" onClick={(e) => updateMultipleValues(e, "respiratory_abnormal", "Lung mass ", "respiratory_assesment_abnormal")}><label className="check">Lung mass </label>
                                                </div>
                                                <div className="check_item" onClick={(e) => updateMultipleValues(e, "respiratory_abnormal", "Pleural effusion", "respiratory_assesment_abnormal")}><label className="check">Pleural effusion</label>
                                                </div>

                                            </div>}



                                            <div className=" mb-20">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form_group">
                                                            <div className="floating-label-group"><input type="text" name="respiratory_dictate" onChange={(e) => updateTheatreValue(e)}
                                                                className="form-control" /><label
                                                                    className="floating-label">Dictate or type additional
                                                                    comments</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form_group">
                                                            <div className="floating-label-group"><input type="text" name="respiratory_needstest" onChange={(e) => updateTheatreValue(e)}
                                                                className="form-control" /><label
                                                                    className="floating-label">Needs a test</label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>}





                                    <div className="check_item mt-10">
                                        <label className="check">
                                            <div className="check_item"><label className="check">HEPATIC / GI</label>
                                                <div className="select_btns popup_select">
                                                    <div className="select_btn"><label><input type="radio"
                                                        value="NORMAL" onClick={(e) => updateTheatreValue(e)} name="hepatic" /><span>NORMAL</span></label></div>
                                                    <div className="select_btn"><label><input onClick={(e) => updateTheatreValue(e)} name="hepatic" type="radio"
                                                        value="ABNORMAL" /><span>ABNORMAL</span></label></div>
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                    {theatreValues.hepatic == "ABNORMAL" &&
                                        <div>
                                            <div className="leavel_1">
                                                <h2 className="popup_title">HEPATIC / GI </h2>
                                                <h2 className="selected">ABNORMAL<a onClick={(e) => updateTheatreValues(e, "hepatic_assesment_abnormal", false)} ><span className="material-symbols-outlined"> reply
                                                </span></a></h2>
                                            </div>
                                            {theatreValues.hepatic_assesment_abnormal && theatreValues.hepatic_abnormal && theatreValues.hepatic_abnormal.length > 0 ? <div className="check_list w_white">

                                                {theatreValues.hepatic_abnormal.map((item, i) => (
                                                    <div className="check_item active" onClick={(e) => updateTheatreValues(e, "hepatic_assesment_abnormal", false)} ><label className="check">{item}</label></div>
                                                ))}

                                            </div> : (theatreValues.hepatic_assesment_abnormal == undefined || theatreValues.hepatic_assesment_abnormal == false) &&
                                            <div className="check_list w_white">
                                                <div className="check_item" onClick={(e) => updateMultipleValues(e, "hepatic_abnormal", "Hiatal hernia", "hepatic_assesment_abnormal")}><label className="check">Hiatal hernia</label>
                                                </div>
                                                <div className="check_item" onClick={(e) => updateMultipleValues(e, "hepatic_abnormal", "Reflux", "hepatic_assesment_abnormal")}><label className="check">Reflux</label>
                                                </div>
                                                <div className="check_item" onClick={(e) => updateMultipleValues(e, "hepatic_abnormal", "Ulcers", "hepatic_assesment_abnormal")}><label className="check">Ulcers</label>
                                                </div>
                                                <div className="check_item" onClick={(e) => updateMultipleValues(e, "hepatic_abnormal", "Hepatitis", "hepatic_assesment_abnormal")}><label className="check">Hepatitis</label>
                                                </div>
                                                <div className="check_item" onClick={(e) => updateMultipleValues(e, "hepatic_abnormal", "Recent jaundice in last 6months", "hepatic_assesment_abnormal")}><label className="check">Recent jaundice in last 6months</label>
                                                </div>
                                                <div className="check_item" onClick={(e) => updateMultipleValues(e, "hepatic_abnormal", "Cirrhosis", "hepatic_assesment_abnormal")}><label className="check">Cirrhosis</label>
                                                </div>
                                                <div className="check_item" onClick={(e) => updateMultipleValues(e, "hepatic_abnormal", "Oesophageal varices", "hepatic_assesment_abnormal")}><label className="check">Oesophageal varices</label>
                                                </div>


                                            </div>}



                                            <div className=" mb-20">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form_group">
                                                            <div className="floating-label-group"><input type="text" name="hepatic_dictate" onChange={(e) => updateTheatreValue(e)}
                                                                className="form-control" /><label
                                                                    className="floating-label">Dictate or type additional
                                                                    comments</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form_group">
                                                            <div className="floating-label-group"><input type="text" name="hepatic_needstest" onChange={(e) => updateTheatreValue(e)}
                                                                className="form-control" /><label
                                                                    className="floating-label">Needs a test</label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>}




                                    <div className="check_item mt-10">
                                        <label className="check">
                                            <div className="check_item"><label className="check">RENAL</label>
                                                <div className="select_btns popup_select">
                                                    <div className="select_btn"><label><input type="radio"
                                                        value="NORMAL" onClick={(e) => updateTheatreValue(e)} name="renal" /><span>NORMAL</span></label></div>
                                                    <div className="select_btn"><label><input onClick={(e) => updateTheatreValue(e)} name="renal" type="radio"
                                                        value="ABNORMAL" /><span>ABNORMAL</span></label></div>
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                    {theatreValues.renal == "ABNORMAL" &&
                                        <div>
                                            <div className="leavel_1">
                                                <h2 className="popup_title">RENAL </h2>
                                                <h2 className="selected">ABNORMAL<a onClick={(e) => updateTheatreValues(e, "renal_assesment_abnormal", false)} ><span className="material-symbols-outlined"> reply
                                                </span></a></h2>
                                            </div>
                                            {theatreValues.renal_assesment_abnormal && theatreValues.renal_abnormal && theatreValues.renal_abnormal.length > 0 ? <div className="check_list w_white">

                                                {theatreValues.renal_abnormal.map((item, i) => (
                                                    <div className="check_item active" onClick={(e) => updateTheatreValues(e, "renal_assesment_abnormal", false)} ><label className="check">{item}</label></div>
                                                ))}

                                            </div> : (theatreValues.renal_assesment_abnormal == undefined || theatreValues.renal_assesment_abnormal == false) &&
                                            <div className="check_list w_white">
                                                <div className="check_item" onClick={(e) => updateMultipleValues(e, "renal_abnormal", "Chronic/Acute Renal failure", "renal_assesment_abnormal")}><label className="check">Chronic/Acute Renal failure</label>
                                                </div>
                                                <div className="check_item" onClick={(e) => updateMultipleValues(e, "renal_abnormal", "Dialysis", "renal_assesment_abnormal")}><label className="check">Dialysis</label>
                                                </div>
                                                <div className="check_item" onClick={(e) => updateMultipleValues(e, "renal_abnormal", "BPH", "renal_assesment_abnormal")}><label className="check">BPH</label>
                                                </div>
                                                <div className="check_item" onClick={(e) => updateMultipleValues(e, "renal_abnormal", "Urine retention", "renal_assesment_abnormal")}><label className="check">Urine retention</label>
                                                </div>
                                                <div className="check_item" onClick={(e) => updateMultipleValues(e, "renal_abnormal", "UTI", "renal_assesment_abnormal")}><label className="check">UTI</label>
                                                </div>
                                                <div className="check_item" onClick={(e) => updateMultipleValues(e, "renal_abnormal", "Incontinent", "renal_assesment_abnormal")}><label className="check">Incontinent</label>
                                                </div>
                                            </div>}



                                            <div className=" mb-20">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form_group">
                                                            <div className="floating-label-group"><input type="text" name="renal_dictate" onChange={(e) => updateTheatreValue(e)}
                                                                className="form-control" /><label
                                                                    className="floating-label">Dictate or type additional
                                                                    comments</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form_group">
                                                            <div className="floating-label-group"><input type="text" name="renal_needstest" onChange={(e) => updateTheatreValue(e)}
                                                                className="form-control" /><label
                                                                    className="floating-label">Needs a test</label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>}




                                    <div className="check_item mt-10">
                                        <label className="check">
                                            <div className="check_item"><label className="check">ENDOCRINE</label>
                                                <div className="select_btns popup_select">
                                                    <div className="select_btn"><label><input type="radio"
                                                        value="NORMAL" onClick={(e) => updateTheatreValue(e)} name="endocrine" /><span>NORMAL</span></label></div>
                                                    <div className="select_btn"><label><input onClick={(e) => updateTheatreValue(e)} name="endocrine" type="radio"
                                                        value="ABNORMAL" /><span>ABNORMAL</span></label></div>
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                    {theatreValues.endocrine == "ABNORMAL" &&
                                        <div>
                                            <div className="leavel_1">
                                                <h2 className="popup_title">ENDOCRINE </h2>
                                                <h2 className="selected">ABNORMAL<a onClick={(e) => updateTheatreValues(e, "endocrine_assesment_abnormal", false)} ><span className="material-symbols-outlined"> reply
                                                </span></a></h2>
                                            </div>
                                            {theatreValues.endocrine_assesment_abnormal && theatreValues.endocrine_abnormal && theatreValues.endocrine_abnormal.length > 0 ? <div className="check_list w_white">

                                                {theatreValues.endocrine_abnormal.map((item, i) => (
                                                    <div className="check_item active" onClick={(e) => updateTheatreValues(e, "endocrine_assesment_abnormal", false)} ><label className="check">{item}</label></div>
                                                ))}

                                            </div> : (theatreValues.endocrine_assesment_abnormal == undefined || theatreValues.endocrine_assesment_abnormal == false) &&
                                            <div className="check_list w_white">
                                                <div className="check_item" onClick={(e) => updateMultipleValues(e, "endocrine_abnormal", "IDDM", "endocrine_assesment_abnormal")}><label className="check">IDDM</label>
                                                </div>
                                                <div className="check_item" onClick={(e) => updateMultipleValues(e, "endocrine_abnormal", "NIDDM", "endocrine_assesment_abnormal")}><label className="check">NIDDM</label>
                                                </div>
                                                <div className="check_item" onClick={(e) => updateMultipleValues(e, "endocrine_abnormal", "Hypothyroidism", "endocrine_assesment_abnormal")}><label className="check">Hypothyroidism</label>
                                                </div>
                                                <div className="check_item" onClick={(e) => updateMultipleValues(e, "endocrine_abnormal", "Recent steroids", "endocrine_assesment_abnormal")}><label className="check">Recent steroids</label>
                                                </div>
                                                <div className="check_item" onClick={(e) => updateMultipleValues(e, "endocrine_abnormal", "Unintentional Weight loss/gain", "endocrine_assesment_abnormal")}><label className="check">Unintentional Weight loss/gain</label>
                                                </div>
                                            </div>}

                                            <div className=" mb-20">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form_group">
                                                            <div className="floating-label-group"><input type="text" name="endocrine_dictate" onChange={(e) => updateTheatreValue(e)}
                                                                className="form-control" /><label
                                                                    className="floating-label">Dictate or type additional
                                                                    comments</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form_group">
                                                            <div className="floating-label-group"><input type="text" name="endocrine_needstest" onChange={(e) => updateTheatreValue(e)}
                                                                className="form-control" /><label
                                                                    className="floating-label">Needs a test</label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>}





                                    <div className="check_item mt-10">
                                        <label className="check">
                                            <div className="check_item"><label className="check">HAEMATOLOGICAL</label>
                                                <div className="select_btns popup_select">
                                                    <div className="select_btn"><label><input type="radio"
                                                        value="NORMAL" onClick={(e) => updateTheatreValue(e)} name="haematological" /><span>NORMAL</span></label></div>
                                                    <div className="select_btn"><label><input onClick={(e) => updateTheatreValue(e)} name="haematological" type="radio"
                                                        value="ABNORMAL" /><span>ABNORMAL</span></label></div>
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                    {theatreValues.haematological == "ABNORMAL" &&
                                        <div>
                                            <div className="leavel_1">
                                                <h2 className="popup_title">HAEMATOLOGICAL </h2>
                                                <h2 className="selected">ABNORMAL<a onClick={(e) => updateTheatreValues(e, "haematological_assesment_abnormal", false)} ><span className="material-symbols-outlined"> reply
                                                </span></a></h2>
                                            </div>
                                            {theatreValues.haematological_assesment_abnormal && theatreValues.haematological_abnormal && theatreValues.haematological_abnormal.length > 0 ? <div className="check_list w_white">

                                                {theatreValues.haematological_abnormal.map((item, i) => (
                                                    <div className="check_item active" onClick={(e) => updateTheatreValues(e, "haematological_assesment_abnormal", false)} ><label className="check">{item}</label></div>
                                                ))}

                                            </div> : (theatreValues.haematological_assesment_abnormal == undefined || theatreValues.haematological_assesment_abnormal == false) &&
                                            <div className="check_list w_white">
                                                <div className="check_item" onClick={(e) => updateMultipleValues(e, "haematological_abnormal", "Anaemia", "haematological_assesment_abnormal")}><label className="check">Anaemia</label>
                                                </div>
                                                <div className="check_item" onClick={(e) => updateMultipleValues(e, "haematological_abnormal", "Haemoglobinopathies Haemophilia Sickle cell", "haematological_assesment_abnormal")}><label className="check">Haemoglobinopathies Haemophilia Sickle cell</label>
                                                </div>
                                                <div className="check_item" onClick={(e) => updateMultipleValues(e, "haematological_abnormal", "Coagulation Disorder Bleeding disorder", "haematological_assesment_abnormal")}><label className="check">Coagulation Disorder Bleeding disorder</label>
                                                </div>
                                                <div className="check_item" onClick={(e) => updateMultipleValues(e, "haematological_abnormal", "Cancer - Chemotherapy", "haematological_assesment_abnormal")}><label className="check">Cancer - Chemotherapy</label>
                                                </div>
                                            </div>}

                                            <div className=" mb-20">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form_group">
                                                            <div className="floating-label-group"><input type="text" name="haematological_dictate" onChange={(e) => updateTheatreValue(e)}
                                                                className="form-control" /><label
                                                                    className="floating-label">Dictate or type additional
                                                                    comments</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form_group">
                                                            <div className="floating-label-group"><input type="text" name="haematological_needstest" onChange={(e) => updateTheatreValue(e)}
                                                                className="form-control" /><label
                                                                    className="floating-label">Needs a test</label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>}



                                    <div className="check_item mt-10">
                                        <label className="check">
                                            <div className="check_item"><label className="check">NEURO / MSK</label>
                                                <div className="select_btns popup_select">
                                                    <div className="select_btn"><label><input type="radio"
                                                        value="NORMAL" onClick={(e) => updateTheatreValue(e)} name="neuro" /><span>NORMAL</span></label></div>
                                                    <div className="select_btn"><label><input onClick={(e) => updateTheatreValue(e)} name="neuro" type="radio"
                                                        value="ABNORMAL" /><span>ABNORMAL</span></label></div>
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                    {theatreValues.neuro == "ABNORMAL" &&
                                        <div>
                                            <div className="leavel_1">
                                                <h2 className="popup_title">NEURO / MSK </h2>
                                                <h2 className="selected">ABNORMAL<a onClick={(e) => updateTheatreValues(e, "neuro_assesment_abnormal", false)} ><span className="material-symbols-outlined"> reply
                                                </span></a></h2>
                                            </div>
                                            {theatreValues.neuro_assesment_abnormal && theatreValues.neuro_abnormal && theatreValues.neuro_abnormal.length > 0 ? <div className="check_list w_white">

                                                {theatreValues.neuro_abnormal.map((item, i) => (
                                                    <div className="check_item active" onClick={(e) => updateTheatreValues(e, "neuro_assesment_abnormal", false)} ><label className="check">{item}</label></div>
                                                ))}

                                            </div> : (theatreValues.neuro_assesment_abnormal == undefined || theatreValues.neuro_assesment_abnormal == false) &&
                                            <div className="check_list w_white">
                                                <div className="check_item" onClick={(e) => updateMultipleValues(e, "neuro_abnormal", "Epilepsy", "neuro_assesment_abnormal")}><label className="check">Epilepsy</label>
                                                </div>
                                                <div className="check_item" onClick={(e) => updateMultipleValues(e, "neuro_abnormal", "CVA/TIA", "neuro_assesment_abnormal")}><label className="check">CVA/TIA</label>
                                                </div>
                                                <div className="check_item" onClick={(e) => updateMultipleValues(e, "neuro_abnormal", "LOC", "neuro_assesment_abnormal")}><label className="check">LOC</label>
                                                </div>
                                                <div className="check_item" onClick={(e) => updateMultipleValues(e, "neuro_abnormal", "Motor Neurone Disease/wheel chair", "neuro_assesment_abnormal")}><label className="check">Motor Neurone Disease/wheel chair</label>
                                                </div>
                                                <div className="check_item" onClick={(e) => updateMultipleValues(e, "neuro_abnormal", "Spinal Injury", "neuro_assesment_abnormal")}><label className="check">Spinal Injury</label>
                                                </div>
                                                <div className="check_item" onClick={(e) => updateMultipleValues(e, "neuro_abnormal", "Fibromyalgia", "neuro_assesment_abnormal")}><label className="check">Fibromyalgia</label>
                                                </div>
                                                <div className="check_item" onClick={(e) => updateMultipleValues(e, "neuro_abnormal", "Rheumatoid Arthritis", "neuro_assesment_abnormal")}><label className="check">Rheumatoid Arthritis</label>
                                                </div>
                                                <div className="check_item" onClick={(e) => updateMultipleValues(e, "neuro_abnormal", "Ankylosing spondylitis", "neuro_assesment_abnormal")}><label className="check">Ankylosing spondylitis</label>
                                                </div>
                                                <div className="check_item" onClick={(e) => updateMultipleValues(e, "neuro_abnormal", "Scoliosis", "neuro_assesment_abnormal")}><label className="check">Scoliosis</label>
                                                </div>
                                            </div>}

                                            <div className=" mb-20">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form_group">
                                                            <div className="floating-label-group"><input type="text" name="neuro_dictate" onChange={(e) => updateTheatreValue(e)}
                                                                className="form-control" /><label
                                                                    className="floating-label">Dictate or type additional
                                                                    comments</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form_group">
                                                            <div className="floating-label-group"><input type="text" name="neuro_needstest" onChange={(e) => updateTheatreValue(e)}
                                                                className="form-control" /><label
                                                                    className="floating-label">Needs a test</label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>}











                                    <div className="form_group">
                                        <div className="floating-label-group"><input type="text" name="pregnancy" onChange={(e) => updateTheatreValue(e)}
                                            className="form-control" /><label
                                                className="floating-label">PREGNANCY</label>
                                        </div>
                                    </div>

                                    {theatreValues.pregnancy_abnormal && theatreValues.pregnancy_required && theatreValues.pregnancy_required.length > 0 ? <div className="check_list w_white">

                                        {theatreValues.pregnancy_required.map((item, i) => (
                                            <div className="check_item active" onClick={(e) => updateTheatreValues(e, "pregnancy_abnormal", false)} ><label className="check">{item}</label></div>
                                        ))}

                                    </div> : (theatreValues.pregnancy_abnormal == undefined || theatreValues.pregnancy_abnormal == false) &&
                                    <div className="check_list w_white">

                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "pregnancy_required", "YES", "pregnancy_abnormal")} ><label className="check">YES</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "pregnancy_required", "1st Trimester of Pregnancy", "pregnancy_abnormal")}><label className="check">1st Trimester of Pregnancy</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "pregnancy_required", "2nd / Trimester of Pregnancy", "pregnancy_abnormal")}><label className="check">2nd / Trimester of Pregnancy</label>
                                        </div>


                                    </div>}


                                    <div className=" mb-20">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form_group">
                                                    <div className="floating-label-group"><input type="text" name="pregnancy_dictate" onChange={(e) => updateTheatreValue(e)}
                                                        className="form-control" /><label
                                                            className="floating-label">Dictate or type additional
                                                            comments</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form_group">
                                                    <div className="floating-label-group"><input type="text" name="pregnancy_needstest" onChange={(e) => updateTheatreValue(e)}
                                                        className="form-control" /><label
                                                            className="floating-label">Needs a test(Urine dipstick)</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form_group">
                                                    <div className="floating-label-group"><input type="text" name="needs_special_consent" onChange={(e) => updateTheatreValue(e)}
                                                        className="form-control" /><label
                                                            className="floating-label">Needs special Consent</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                </div>
                                <div className="section simple_wound ctr">
                                    <h2 className="popup_title"> ANY PREVIOUS ANAESTHETIC RECORD</h2>
                                    <div className="pd_border"></div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="floating-label-group"><input type="text" name="anaesthesia_sedation_type" onChange={(e) => updateTheatreValue(e)}
                                                    className="form-control" /><label
                                                        className="floating-label">Anaesthesia and/or Sedation Type</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="floating-label-group"><input type="text" name="anaesthesia_sedation_admin" onChange={(e) => updateTheatreValue(e)}
                                                    className="form-control" /><label
                                                        className="floating-label">Anaesthetic/Sedation Administration</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="floating-label-group"><input type="text" name="anaesthesia_complications" onChange={(e) => updateTheatreValue(e)}
                                                    className="form-control" /><label
                                                        className="floating-label">Anaesthesia Complications</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="floating-label-group"><input type="text" name="anaesthesia_alerts" onChange={(e) => updateTheatreValue(e)}
                                                    className="form-control" /><label
                                                        className="floating-label">Anaesthetic Alerts</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="floating-label-group"><input type="date" name="anaesthesia_date" onChange={(e) => updateTheatreValue(e)}
                                                    className="form-control" /><label
                                                        className="floating-label">Date</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="floating-label-group"><input type="time" name="anaesthesia_time" onChange={(e) => updateTheatreValue(e)}
                                                    className="form-control" /><label
                                                        className="floating-label">Time</label>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>


                                <div className="section simple_wound ctr">
                                    <h2 className="popup_title"> TYPE OF ASSESSMENT REQUIRED <a href="#" className="title_btn"
                                        onClick={(e) => setmodal_guidance(!modal_guidance)}>See Guidance</a></h2>
                                    <div className="pd_border"></div>

                                    {theatreValues.assesment_abnormal && theatreValues.assesment_required && theatreValues.assesment_required.length > 0 ? <div className="check_list w_white">

                                        {theatreValues.assesment_required.map((item, i) => (
                                            <div className="check_item active" onClick={(e) => updateTheatreValues(e, "assesment_abnormal", false)} ><label className="check">{item}</label></div>
                                        ))}

                                    </div> : (theatreValues.assesment_abnormal == undefined || theatreValues.assesment_abnormal == false) &&
                                    <div className="check_list w_white">
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "assesment_required", "No further assessment needed (medical questionnaire provides necessary information)", "assesment_abnormal")} ><label className="check">No further assessment needed (medical questionnaire provides necessary information)</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "assesment_required", "Telephone assessment needed", "assesment_abnormal")} ><label className="check">Telephone assessment needed</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "assesment_required", "Face-to-face clinic assessment needed", "assesment_abnormal")}><label className="check">Face-to-face clinic assessment needed</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "assesment_required", "Consultant Anaesthetist needed for assessment", "assesment_abnormal")}><label className="check">Consultant Anaesthetist needed for assessment</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "assesment_required", "Appointment for physiotherapist needed", "assesment_abnormal")}><label className="check">Appointment for physiotherapist needed</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "assesment_required", "Appointment for tests / investigations", "assesment_abnormal")}><label className="check">Appointment for tests /
                                            investigations</label>
                                        </div>
                                    </div>}

                                </div>


                                <div className="section simple_wound ctr">
                                    <div className="row">
                                        <div className="col-md-8">
                                            <div className="check_item">
                                                <label className="check">
                                                    <div className="check_item">
                                                        <div className="select_btns popup_select mt-0">
                                                            <div className="select_btn"><label><input type="radio"
                                                                value="TELEPHONE CALL" onClick={(e) => updateTheatreValue(e)} name="visit" /><span>TELEPHONE CALL </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input type="radio"
                                                                value="FACE TO FACE CLINIC VISIT" onClick={(e) => updateTheatreValue(e)} name="visit" /><span>FACE TO FACE CLINIC VISIT
                                                                </span></label></div>
                                                        </div>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form_group">
                                                        <div className="floating-label-group"><input type="date" onClick={(e) => updateTheatreValue(e)} name="visitdate"
                                                            className="form-control" /><label
                                                                className="floating-label">DATE</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form_group">
                                                        <div className="floating-label-group"><input type="time" onClick={(e) => updateTheatreValue(e)} name="visittime"
                                                            className="form-control" /><label
                                                                className="floating-label">TIME</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {theatreValues.patient_history && theatreValues.patient_required && theatreValues.patient_required.length > 0 ? <div className="check_list w_white">

                                        {theatreValues.patient_required.map((item, i) => (
                                            <div className="check_item active" onClick={(e) => updateTheatreValues(e, "patient_history", false)} ><label className="check">{item}</label></div>
                                        ))}

                                    </div> : (theatreValues.patient_history == undefined || theatreValues.patient_history == false) &&
                                    <div className="check_list w_white">
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "patient_required", "Patient’s medical history confirmed with medical questionnaire", "patient_history")} ><label className="check">Patient’s medical history confirmed with medical questionnaire</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "patient_required", "Patient’s physical and social needs assessed and no concerns apparent", "patient_history")} ><label className="check">Patient’s physical and social needs assessed and no concerns apparent</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "patient_required", "Vital signs, weight, height and BMI recorded on relevant charts and within normal parameters", "patient_history")}><label className="check">Vital signs, weight, height and BMI recorded on relevant charts and within normal parameters</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "patient_required", "Advance notification of Alerts", "patient_history")}><label className="check"> <a href="#" onClick={(e) => setmodal_advnotification(!modal_advnotification)}><b>Advance notification of Alerts</b>&nbsp;
                                        </a>form  completed and sent (if applicable)</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "patient_required", "Risk assessments", "patient_history")}><label className="check"><a href="#" onClick={() => { setmodal_riskassesment(!modal_riskassesment) }}> <b>Risk assessments</b> </a> completed (refer to risk assessment score sheets) and actions taken documented</label>
                                        </div>

                                    </div>
                                    }

                                    <div className="col-md-12">
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text" name="patient_comments" onChange={(e) => updateTheatreValue(e)}
                                                className="form-control" /><label
                                                    className="floating-label">COMMENTS</label>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                                <div className="section simple_wound ctr">
                                    <h2 className="popup_title"> CHECK LIST OF INTERVENTIONS </h2>
                                    <div className="pd_border"></div>
                                    {theatreValues.preop_checklist && theatreValues.preop_checklist_inventions && theatreValues.preop_checklist_inventions.length > 0 ? <div className="check_list w_white">

                                        {theatreValues.preop_checklist_inventions.map((item, i) => (
                                            <div className="check_item active" onClick={(e) => updateTheatreValues(e, "preop_checklist", false)} ><label className="check">{item}</label></div>
                                        ))}

                                    </div> : (theatreValues.preop_checklist == undefined || theatreValues.preop_checklist == false) &&
                                    <div className="check_list w_white">
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "preop_checklist_inventions", "Pre operative tests taken in accordance with NICE guidelines (2016)", "preop_checklist")}><label className="check">Pre operative tests taken in accordance with NICE guidelines (2016)</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "preop_checklist_inventions", "Bloods: MRSA | ECG | Urinalysis D CPE", "preop_checklist")}><label className="check">Bloods: MRSA | ECG | Urinalysis D CPE </label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "preop_checklist_inventions", "Chest xray and all other relevant radiological studies taken in line with IRMER guidelines (2006)", "preop_checklist")}><label className="check">Chest xray and all other relevant radiological studies taken in line with IRMER guidelines (2006)</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "preop_checklist_inventions", "Urinalysis (if clinically indicated) performed and abnormalities recorded in clinical notes", "preop_checklist")}><label className="check">Urinalysis (if clinically indicated) performed and abnormalities recorded in clinical notes</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "preop_checklist_inventions", "Allergies identified and recorded on drug chart", "preop_checklist")}><label className="check">Allergies identified and recorded on drug chart</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "preop_checklist_inventions", "Advised to bring all their medications in original packaging and encouraged to self medicate according to local policy", "preop_checklist")}><label className="check">Advised to bring all their medications in original packaging and encouraged to self medicate according to local policy</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "preop_checklist_inventions", "Advised on present medication (including herbal/supplements and anticoagulant advice)", "preop_checklist")}><label className="check">Advised on present medication (including herbal/supplements and anticoagulant advice)</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "preop_checklist_inventions", "Discharge plan initiated", "preop_checklist")}><label className="check">Discharge plan initiated</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "preop_checklist_inventions", "Patient aware that discharge time will be when discharge criteria met", "preop_checklist")}><label className="check">Patient aware that discharge time will
                                            be when discharge criteria met</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "preop_checklist_inventions", "Patient advised to shower/bath on day of admission if able and not to shave operative area", "preop_checklist")}><label className="check">Patient advised to shower/bath on day of admission if able and not to shave operative area</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "preop_checklist_inventions", "Confirmation from the patient that a responsible adult (name ) will be with them for 24 hours post discharge", "preop_checklist")}><label className="check">Confirmation from the patient that a responsible adult (name ) will be with them for 24 hours post discharge
                                        </label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "preop_checklist_inventions", "Confirmation from the patient that a responsible adult (name ) will be with them for 24 hours post discharge", "preop_checklist")}><label className="check">Confirmation from the patient that a responsible adult (name ) will be with them for 24 hours post discharge
                                        </label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "preop_checklist_inventions", "Patient advises that skin integrity is intact", "preop_checklist")}><label className="check">Patient advises that skin integrity is intact</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "preop_checklist_inventions", "Advised of pre-operative fasting times  (solid food 6 hours and clear fluids 2 hours prior to anaesthetic time) AAGBI 2011", "preop_checklist")}><label className="check">Advised of pre-operative fasting times  (solid food 6 hours and clear fluids 2 hours prior to anaesthetic time) AAGBI 2011</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "preop_checklist_inventions", "Anti embolic prophylactics explained (stockings, early mobility, fluid intake etc.)", "preop_checklist")}><label className="check">Anti embolic prophylactics explained (stockings, early mobility, fluid intake etc.)</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "preop_checklist_inventions", "Control of post-operative pain and  nausea explained. Shown pain scale 0-10", "preop_checklist")}><label className="check">Control of post-operative pain and  nausea explained. Shown pain scale 0-10</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "preop_checklist_inventions", "Surgery and any anxieties or concerns discussed and patient has realistic expectations", "preop_checklist")}><label className="check">Surgery and any anxieties or concerns discussed and patient has realistic expectations</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "preop_checklist_inventions", "Given following patient information leaflets (circle): EIDO / Transfusion / MRSA / VTE / Anaesthetic / Pain / Consent / PROMs (if applicable) / health promotion x3 / NJR (if applicable) / BCIR (if applicable) / Other", "preop_checklist")}><label className="check">Given following patient information leaflets (circle): EIDO / Transfusion / MRSA / VTE / Anaesthetic / Pain /
                                            Consent / PROMs (if applicable) / health promotion x3 / NJR (if applicable)
                                            / BCIR (if applicable) / Other</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "preop_checklist_inventions", "Aware of informed consent process & has contact details for further information if required", "preop_checklist")}><label className="check">Aware of informed consent process & has contact details for further information if required</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "preop_checklist_inventions", "Patient consents to sending information to other health care professionals", "preop_checklist")}><label className="check">Patient consents to sending information to other health care professionals</label>
                                        </div>
                                    </div>
                                    }


                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="floating-label-group"><input type="text" name="initialled_by" onChange={(e) => updateTheatreValue(e)}
                                                    className="form-control" /><label
                                                        className="floating-label">The interventions initialled by</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <p>Signature of non registered staff</p>
                                            <div className="signature_block mb-10">
                                                
                                                <SignaturePad canvasProps={{ className: "sigPad" }} ref={(ref) => { staffsigPad = ref }} />
                                            </div>

                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="floating-label-group"><input type="text" name="delegated_by" onChange={(e) => updateTheatreValue(e)}
                                                    className="form-control" /><label
                                                        className="floating-label">have been delegated by</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <p>Signature of responsible Registered Nurse</p>
                                            <div className="signature_block">
                                                
                                                <SignaturePad canvasProps={{ className: "sigPad" }} ref={(ref) => { nursesigPad = ref }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="section simple_wound ctr">
                                    <h2 className="popup_title"> PRE-OP ASSESSMENT OUTCOME </h2>
                                    <div className="pd_border"></div>

                                    {theatreValues.preop_outcome && theatreValues.preop_outcome_assesment && theatreValues.preop_outcome_assesment.length > 0 ? <div className="check_list w_white">

                                        {theatreValues.preop_outcome_assesment.map((item, i) => (
                                            <div className="check_item active" onClick={(e) => updateTheatreValues(e, "preop_outcome", false)} ><label className="check">{item}</label></div>
                                        ))}

                                    </div> : (theatreValues.preop_outcome == undefined || theatreValues.preop_outcome == false) &&
                                    <div className="check_list w_white">
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "preop_outcome_assesment", "Patient has been physically assessed as fit for inpatient stay", "preop_outcome")}><label className="check">Patient has been physically assessed as fit for inpatient stay</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "preop_outcome_assesment", "Patient has been given verbal and written information to assist informed consent", "preop_outcome")}><label className="check">Patient has been given verbal and written information to assist informed consent</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "preop_outcome_assesment", "Patient’s post operative needs have been assessed and a discharge plan agreed", "preop_outcome")}><label className="check">Patient’s post operative needs have been assessed and a discharge plan agreed</label>
                                        </div>
                                    </div>
                                    }

                                </div>
                                <div className="section simple_wound ctr">
                                    <h2 className="popup_title"> 48 HOURS PRE OP PHONE CALL </h2>
                                    <div className="pd_border"></div>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="yn_switch mt-15 ">
                                                <div className="label-container">
                                                    <p>Completed at</p>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-md-8">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form_group">
                                                        <div className="floating-label-group"><input type="date" name="preop_date" onChange={(e) => updateTheatreValue(e)}
                                                            className="form-control" /><label
                                                                className="floating-label">DATE</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form_group">
                                                        <div className="floating-label-group"><input type="time" name="preop_time" onChange={(e) => updateTheatreValue(e)}
                                                            className="form-control" /><label
                                                                className="floating-label">TIME</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form_group">
                                                <div className="floating-label-group"><input type="text" name="fully_prepared" onChange={(e) => updateTheatreValue(e)}
                                                    className="form-control" /><label
                                                        className="floating-label">and patient is fully prepared for admission
                                                        and is aware of</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {theatreValues.preop_phonecall && theatreValues.preop_phonecall_assesment && theatreValues.preop_phonecall_assesment.length > 0 ? <div className="check_list w_white">

                                        {theatreValues.preop_phonecall_assesment.map((item, i) => (
                                            <div className="check_item active" onClick={(e) => updateTheatreValues(e, "preop_phonecall", false)} ><label className="check">{item}</label></div>
                                        ))}

                                    </div> : (theatreValues.preop_phonecall == undefined || theatreValues.preop_phonecall == false) &&
                                    <div className="check_list w_white">
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "preop_phonecall_assesment", "Fasting Requirements", "preop_phonecall")} ><label className="check">Fasting Requirements </label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "preop_phonecall_assesment", "Preparation and what to bring", "preop_phonecall")} ><label className="check">Preparation and what to bring </label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "preop_phonecall_assesment", "Discharge Process", "preop_phonecall")} ><label className="check">Discharge Process </label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "preop_phonecall_assesment", "Responsible adult to escort patient home confirmed", "preop_phonecall")} ><label className="check">Responsible adult to escort patient home confirmed</label>
                                        </div>
                                    </div>
                                    }

                                </div>
                                <div className="section simple_wound ctr">
                                    <h2 className="popup_title"> ALERTS</h2>
                                    <div className="pd_border"></div>

                                    {theatreValues.preop_alerts && theatreValues.preop_alerts_assesment && theatreValues.preop_alerts_assesment.length > 0 ? <div className="check_list w_white">

                                        {theatreValues.preop_alerts_assesment.map((item, i) => (
                                            <div className="check_item active" onClick={(e) => updateTheatreValues(e, "preop_alerts", false)} ><label className="check">{item}</label></div>
                                        ))}

                                    </div> : (theatreValues.preop_alerts == undefined || theatreValues.preop_alerts == false) &&
                                    <div className="check_list w_white">
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "preop_alerts_assesment", "Latex allergy", "preop_alerts")} ><label className="check">Latex allergy</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "preop_alerts_assesment", "Metal allergy", "preop_alerts")}><label className="check">Metal allergy</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "preop_alerts_assesment", "Other relevant allergy", "preop_alerts")}><label className="check">Other relevant allergy</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "preop_alerts_assesment", "High BMI (over35) Weight", "preop_alerts")}><label className="check">High BMI (over35) Weight</label>
                                        </div>

                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "preop_alerts_assesment", "HIV/Hep B/C", "preop_alerts")}><label className="check">HIV/Hep B/C</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "preop_alerts_assesment", "Axillary clearance etc in women", "preop_alerts")}><label className="check">Axillary clearance etc in women</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "preop_alerts_assesment", "Primary language: English", "preop_alerts")}><label className="check">Primary language: English</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "preop_alerts_assesment", "MRSA/ COVID", "preop_alerts")}><label className="check">MRSA/ COVID</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "preop_alerts_assesment", "Patient has a Pacemaker / ICD - seen by technician", "preop_alerts")}><label className="check">Patient has a Pacemaker / ICD - seen by technician</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "preop_alerts_assesment", "Jehovah’s witness: Patient refuses blood/blood products", "preop_alerts")}><label className="check">Jehovah’s witness: Patient refuses blood/blood products</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "preop_alerts_assesment", "Disability / Mobility problem / Difficulty", "preop_alerts")}><label className="check">Disability / Mobility problem / Difficulty</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "preop_alerts_assesment", "Diabetic", "preop_alerts")}><label className="check">Diabetic</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "preop_alerts_assesment", "On anticoagulants", "preop_alerts")}><label className="check">On anticoagulants</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "preop_alerts_assesment", "Needle phobia", "preop_alerts")}><label className="check">Needle phobia</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "preop_alerts_assesment", "Sleep apnoea", "preop_alerts")}><label className="check">Sleep apnoea</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "preop_alerts_assesment", "Previous anaesthetic problem", "preop_alerts")}><label className="check">Previous anaesthetic problem</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "preop_alerts_assesment", "Nut allergy", "preop_alerts")}><label className="check">Nut allergy</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "preop_alerts_assesment", "Special diet", "preop_alerts")}><label className="check">Special diet</label>
                                        </div>
                                        <div className="check_item" onClick={(e) => updateMultipleValues(e, "preop_alerts_assesment", "Other concerns", "preop_alerts")}><label className="check">Other concerns</label>
                                        </div>
                                    </div>}


                                </div>
                                <div className="section simple_wound ctr">
                                    <h2 className="popup_title"> Receiving department</h2>
                                    <div className="pd_border"></div>

                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form_group">
                                                <div className="floating-label-group"><input type="text" name="received_staff" onChange={(e) => updateTheatreValue(e)}
                                                    className="form-control" /><label
                                                        className="floating-label">Staff receiving alert</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 align-center">
                                            <p>Signature</p>
                                            <div className="signature_block">
                                                <SignaturePad canvasProps={{ className: "sigPad" }} ref={(ref) => { departmentsigPad = ref }} />
                                                
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form_group">
                                                        <div className="floating-label-group"><input type="date" name="received_date" onChange={(e) => updateTheatreValue(e)}
                                                            className="form-control" /><label
                                                                className="floating-label">DATE</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form_group">
                                                        <div className="floating-label-group"><input type="time" name="received_time" onChange={(e) => updateTheatreValue(e)}
                                                            className="form-control" /><label
                                                                className="floating-label">TIME</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>

                        </div>
                        <div className='popup_footer'>
                            <div className="prescribe_footer">
                                <div className="dsp-flex">

                                    <button className="border_btn" onClick={(e) => { updateTheatre(e), theatreBack(e) }}><span className="material-icons">save_as</span>SAVE & CLOSE
                                    </button>
                                </div>

                                <button className="btn dsp_flex" onClick={(e) => { theatreBack(e) }}><span className="material-icons"><span
                                    className="material-icons">close</span></span>DISCARD </button>
                            </div>
                        </div>
                    </div>
                </div>


                <Modal
                    isOpen={modal_guidance}
                    toggle={() => {
                        setmodal_guidance(!modal_guidance)
                    }}
                >  <div className="timing_popup">

                        <div className="modal-header">

                            <h5 className="modal-title">
                                <span className="sub_text">Guidance</span>

                            </h5>
                            <button type="button" onClick={() => {
                                setmodal_guidance(!modal_guidance)
                            }} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col" width="55%">PREDICTED ASA SCORING</th>
                                        <th scope="col" width="45%">TYPE OF ASSESSMENT REQUIRED</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>ASA 1 A normal healthy patient </td>
                                        <td>No further assessment required</td>
                                    </tr>
                                    <tr>
                                        <td>ASA 2 A patient with mild systemic disease </td>
                                        <td>Telephone or face-to-face</td>
                                    </tr>
                                    <tr>
                                        <td>ASA 3 A patient with severe systemic disease</td>
                                        <td>Face-to-face assessment</td>
                                    </tr>
                                    <tr>
                                        <td>ASA 4 A patient with severe systemic disease that is a constant threat to
                                            life</td>
                                        <td>Contact consultant Anaesthetist</td>
                                    </tr>
                                    <tr>
                                        <td>Defined surgical procedures:
                                            <ul>
                                                <li>All major open Orthopaedic procedures</li>
                                                <li>All major bowel surgery</li>
                                                <li>All major gynaecological surgery</li>
                                                <li>All major urological surgery</li>
                                                <li>All mastectomy patients</li>
                                                <li>All patients 17 years and under</li>
                                            </ul>
                                        </td>
                                        <td>Face-to-face assessment</td>
                                    </tr>
                                    <tr>
                                        <td>Trigger question/s positive (any “yes” in ‘red’ medical questionnaire
                                            questions)</td>
                                        <td>Telephone or face-to-face</td>
                                    </tr>
                                    <tr>
                                        <td>Relevant social history</td>
                                        <td>Telephone or face-to-face</td>
                                    </tr>
                                    <tr>
                                        <td>Preoperative investigations</td>
                                        <td>Appointment</td>
                                    </tr>
                                    <tr>
                                        <td>Physiotherapy assessment required</td>
                                        <td>Appointment</td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>


                    </div>
                </Modal>

                <Modal
                    isOpen={modal_advnotification}
                    toggle={() => {
                        setmodal_advnotification(!modal_advnotification)
                    }}
                >  <div className="timing_popup">

                        <div className="modal-header">

                            <h5 className="modal-title">
                                <span className="sub_text">Advance Notification of Alerts</span>

                            </h5>
                            <button type="button" onClick={() => {
                                setmodal_advnotification(!modal_advnotification)
                            }} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="section simple_wound ctr">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text" id="username"
                                                className="form-control" /><label
                                                    className="floating-label">Theatre date</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text" id="username"
                                                className="form-control" /><label
                                                    className="floating-label">Operation</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="yn_switch mt-20 ">
                                    <div className="label-container">
                                        <p>Consultant Surgeon informed? </p>
                                    </div>
                                    <label for="check735" className="switch-container">
                                        <input hidden="" type="checkbox" className="switch_check" id="check735" />
                                        <div className="switch-bg"></div>
                                        <div className="round-box"></div>
                                        <div className="switch-left">
                                            <span>NO</span>
                                        </div>
                                        <div className="switch-right">
                                            <span>YES</span>
                                        </div>
                                    </label>
                                </div>
                                <div className="yn_switch mt-20 ">
                                    <div className="label-container">
                                        <p>Consultant Anaesthetist informed? </p>
                                    </div>
                                    <label for="check736" className="switch-container">
                                        <input hidden="" type="checkbox" className="switch_check" id="check736" />
                                        <div className="switch-bg"></div>
                                        <div className="round-box"></div>
                                        <div className="switch-left">
                                            <span>NO</span>
                                        </div>
                                        <div className="switch-right">
                                            <span>YES</span>
                                        </div>
                                    </label>
                                </div>
                                <p className="field_title mt-0">Sent to: </p>
                                <div className="check_list w_white">
                                    <div className="check_item"><label className="check">Theatre </label>
                                    </div>
                                    <div className="check_item"><label className="check">Ward </label>
                                    </div>
                                    <div className="check_item"><label className="check">Physiotherapy </label>
                                    </div>
                                    <div className="check_item"><label className="check">Kitchen</label>
                                    </div>
                                    <div className="check_item"><label className="check">Infection control team </label>
                                    </div>
                                    <div className="check_item"><label className="check">Ambulatory care</label>
                                    </div>

                                </div>
                            </div>

                        </div>


                    </div>
                </Modal>

                <Modal
                    isOpen={modal_riskassesment}
                    toggle={() => {
                        setmodal_riskassesment(!modal_riskassesment)
                    }}
                >  <div className="timing_popup">

                        <div className="modal-header">

                            <h5 className="modal-title">
                                <span className="sub_text">Risk Assesments</span>

                            </h5>
                            <button type="button" onClick={() => {
                                setmodal_riskassesment(!modal_riskassesment)
                            }} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="section simple_wound ctr">
                                <p className="field_title mt-0">VTE risk </p>
                                <div className="yn_switch ">
                                    <div className="label-container">
                                        <p>Risk factors identified </p>
                                    </div>
                                    <label for="check735" className="switch-container">
                                        <input hidden="" type="checkbox" className="switch_check" id="check735" />
                                        <div className="switch-bg"></div>
                                        <div className="round-box"></div>
                                        <div className="switch-left">
                                            <span>NO</span>
                                        </div>
                                        <div className="switch-right">
                                            <span>YES</span>
                                        </div>
                                    </label>
                                </div>
                                <p className="field_title mt-20">Moving and Handling Assessment Summary </p>
                                <div className="yn_switch">
                                    <div className="label-container">
                                        <p>Can patient do all tasks independently </p>
                                    </div>
                                    <label for="check735" className="switch-container">
                                        <input hidden="" type="checkbox" className="switch_check" id="check735" />
                                        <div className="switch-bg"></div>
                                        <div className="round-box"></div>
                                        <div className="switch-left">
                                            <span>NO</span>
                                        </div>
                                        <div className="switch-right">
                                            <span>YES</span>
                                        </div>
                                    </label>
                                </div>
                                <p>If no, detail activities requiring assistance below; </p>

                                <div className="row">
                                    <div className="col-md-2">
                                        <p className="field_title mt-15">Task1 </p>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text" id="username"
                                                className="form-control" /><label
                                                    className="floating-label">Carers </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text" id="username"
                                                className="form-control" /><label
                                                    className="floating-label">Aids</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-2">
                                        <p className="field_title mt-15">Task2 </p>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text" id="username"
                                                className="form-control" /><label
                                                    className="floating-label">Carers </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text" id="username"
                                                className="form-control" /><label
                                                    className="floating-label">Aids</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-2">
                                        <p className="field_title mt-15">Task3 </p>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text" id="username"
                                                className="form-control" /><label
                                                    className="floating-label">Carers </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text" id="username"
                                                className="form-control" /><label
                                                    className="floating-label">Aids</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-2">
                                        <p className="field_title mt-15">Task4 </p>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text" id="username"
                                                className="form-control" /><label
                                                    className="floating-label">Carers </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text" id="username"
                                                className="form-control" /><label
                                                    className="floating-label">Aids</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p className="field_title mt-20">Waterlow Score </p>
                                <div className="row">

                                    <div className="col-md-4">
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text" id="username"
                                                className="form-control" /><label
                                                    className="floating-label">Build/weight </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text" id="username"
                                                className="form-control" /><label
                                                    className="floating-label">Skin type</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text" id="username"
                                                className="form-control" /><label
                                                    className="floating-label">Sex / Age</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text" id="username"
                                                className="form-control" /><label
                                                    className="floating-label">Malnutrition </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text" id="username"
                                                className="form-control" /><label
                                                    className="floating-label"> Continence </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text" id="username"
                                                className="form-control" /><label
                                                    className="floating-label">Mobility </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text" id="username"
                                                className="form-control" /><label
                                                    className="floating-label">Special Risks </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text" id="username"
                                                className="form-control" /><label
                                                    className="floating-label">Total Score </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p className="field_title mt-20">MUST (nutritional risk) </p>
                                <div className="row">
                                    <div className="col-md-6">
                                        <p className=" mt-15 ">if yes to any of the questions below</p>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text" id="username"
                                                className="form-control" /><label
                                                    className="floating-label">full MUST assessment completed</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p>Patient has BMI of
                                    &#60; 20 <br /> &#62;5% unintentional weight loss in last 3-6 months <br />
                                    Is acutely III and/or no nutritional intake for &#62; 5 days
                                </p>
                                <p className="field_title mt-20">Infection Control </p>
                                <div className="yn_switch">
                                    <div className="label-container">
                                        <p>MRSA / C. Difficile / CPE risk assessed MRSA swabs taken </p>
                                    </div>
                                    <label for="check738" className="switch-container">
                                        <input hidden="" type="checkbox" className="switch_check" id="check738" />
                                        <div className="switch-bg"></div>
                                        <div className="round-box"></div>
                                        <div className="switch-left">
                                            <span>NO</span>
                                        </div>
                                        <div className="switch-right">
                                            <span>YES</span>
                                        </div>
                                    </label>
                                </div>
                                <div className="check_list w_white">
                                    <div className="check_item"><label className="check">Nasal</label>
                                    </div>
                                    <div className="check_item"><label className="check">Perineum/Groin </label>
                                    </div>
                                    <div className="check_item"><label className="check">Axilla</label>
                                    </div>
                                    <div className="check_item"><label className="check">Broken skin and/or wound</label>
                                    </div>
                                    <div className="check_item"><label className="check">Other</label>
                                    </div>
                                </div>
                                <p className="field_title mt-20">Falls </p>
                                <p>if yes to any of the questions below, full falls prevention assessment completed</p>
                                <div className="yn_switch">
                                    <div className="label-container">
                                        <p>History of falls before admission? </p>
                                    </div>
                                    <label for="check738" className="switch-container">
                                        <input hidden="" type="checkbox" className="switch_check" id="check738" />
                                        <div className="switch-bg"></div>
                                        <div className="round-box"></div>
                                        <div className="switch-left">
                                            <span>NO</span>
                                        </div>
                                        <div className="switch-right">
                                            <span>YES</span>
                                        </div>
                                    </label>
                                </div>
                                <div className="yn_switch">
                                    <div className="label-container">
                                        <p>Tries to walk alone but unsteady/unsafe? </p>
                                    </div>
                                    <label for="check739" className="switch-container">
                                        <input hidden="" type="checkbox" className="switch_check" id="check739" />
                                        <div className="switch-bg"></div>
                                        <div className="round-box"></div>
                                        <div className="switch-left">
                                            <span>NO</span>
                                        </div>
                                        <div className="switch-right">
                                            <span>YES</span>
                                        </div>
                                    </label>
                                </div>
                                <div className="yn_switch">
                                    <div className="label-container">
                                        <p>Patient or relatives anxious about falls? </p>
                                    </div>
                                    <label for="check740" className="switch-container">
                                        <input hidden="" type="checkbox" className="switch_check" id="check740" />
                                        <div className="switch-bg"></div>
                                        <div className="round-box"></div>
                                        <div className="switch-left">
                                            <span>NO</span>
                                        </div>
                                        <div className="switch-right">
                                            <span>YES</span>
                                        </div>
                                    </label>
                                </div>
                            </div>

                        </div>


                    </div>
                </Modal>

            </div>

        </React.Fragment>
    )
}

preOpAssesment.propTypes = {
    t: PropTypes.any,
    getWards: PropTypes.func,
    getTheatres: PropTypes.func,
    wards: PropTypes.array,
    user: PropTypes.any,
    theatres: PropTypes.array,
    clinic: PropTypes.array,
    patients: PropTypes.array,
    doctors: PropTypes.array,
    profile: PropTypes.object
}

const mapStateToProps = ({ Layout, Login }) => ({
    wards: Layout.wards,
    user: Login.user,
    theatres: Layout.theatres,
    clinic: Layout.clinic,
    patients: Layout.patients,
    doctors: Layout.doctors,
    profile: Layout.profile
})

const mapDispatchToProps = dispatch => ({
    getWards: () => dispatch(getWardsList()),
    getTheatres: () => dispatch(getTheatresList()),
    getClinic: () => dispatch(getClinicList()),
    getPatient: () => dispatch(getPatientList()),
    getUserprofile: (id) => dispatch(getUprofile(id)),
    getDoctor: () => dispatch(getDoctorList()),
    theatreupdate: (id, data) => dispatch(updateTheatrePatient(id, data)),
    wardupdate: (id, data) => dispatch(updateWardPatient(id, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(preOpAssesment)
