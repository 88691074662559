import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"


import Moment from 'moment';
import {
    Modal
} from "reactstrap"
import MultiRangeSlider from "./multiRangeSlider/MultiRangeSlider";
import { connect } from "react-redux"
import { getWardsList, getTheatresList, getClinicList, getPatientList, getUprofile, getDoctorList, updateTheatrePatient } from "../../store/actions"

const DayCalendar = props => {
    const [modal_guidance, setmodal_guidance] = useState(false)
    const [selectedTime, setSelected_Time] = useState(false)
    const [selectedType, setSelected_Type] = useState({})
    const [selectedBpRange, setselectedBpRange] = useState({})

    const [timeList, setTimelist] = useState([])

    var quarterHours = ["00", "05", "10", "15", "20", "25", "30", "35", "40", "45", "50", "55"];
    const times = [];
    const hours = 24


    // const [visiblePatients, setPatient] = useState(true)
    // const [visiblelanding, setClanding] = useState(false)
    // const [successmessage, setSuccess] = useState("")


    // const [theatreValues, setTheatre] = useState({})
    // const [clinicPatient, setCPatient] = useState({})

    // const {
    //     wards, user, theatres, clinic, patients, profile, doctors
    // } = props;

    // useEffect(() => {
    //     const { getWards, getTheatres, getClinic, getDoctor, getPatient, getUserprofile } = props
    //     // getWards()
    //     // getTheatres()
    //     // getClinic()
    //     getPatient()
    //     getDoctor()
    //     let userId = localStorage.getItem("authUser")
    //     getUserprofile(userId)
    // }, [])


    const theatreBack = (e) => {
        e.preventDefault()
        props.history.push("/theatre")
    }

    // const updateTheatre = (e) => {
    //     const { patientupdate } = props
    //     let id = clinicPatient._id
    //     patientupdate(id, theatreValues)
    // }

    // const updateTheatreValues = (e, name, val) => {
    //     //  e.preventDefault()
    //     let updatedValue = {};
    //     updatedValue = { [name]: val };
    //     setTheatre(theatreValues => ({
    //         ...theatreValues,
    //         ...updatedValue
    //     }));
    // }

    useEffect(() => {

        if (timeList.length == 0) {
            console.log("setTimelist useeffect ", timeList)
            setTimeMap()

        } else {
            //  timeScroll()
        }

    }, [timeList])

    const setTimeMap = () => {

        for (var i = 0; i < hours; i++) {
            for (var j = 0; j < 12; j++) {
                var time = i + ":" + quarterHours[j];
                if (i < 10) {
                    time = "0" + time;
                }
                times.push({
                    time
                });
                // setTimes(times => [...times, {
                //     time,
                //     value: null
                // }]);
            }
        }
        setTimelist(times)

    }

    const merge = (a, b, prop) => {
        var reduced = a.filter(aitem => !b.find(bitem => aitem[prop] === bitem[prop]))
        return reduced.concat(b);
    }

    const saveClick = (e) => {
        e.preventDefault()
        // let saveObj = [{
        //     time: selectedTime,
        //     [selectedType.type]: {
        //         title: selectedType.title,
        //         min: selectedBpRange.min,
        //         max: selectedBpRange.max
        //     }
        // }]
        // console.log("saveObj", saveObj)
        const index = timeList.findIndex(x => x.time === selectedTime);
        // console.log("selectedType", selectedType)
        let obj = {
            // time: selectedTime,
            //[selectedType.type]: {
            title: selectedType.title,
            min: selectedBpRange.min,
            max: selectedBpRange.max
            // }
        }
        if (selectedType.type == "sugar") {
            obj = {
                title: selectedType.title,
                value: selectedType.value
            }
        }
        //  console.log("index", index, obj)
        timeList[index]["time"] = selectedTime
        timeList[index][selectedType.type] = obj
        //const merged = merge(timeList, saveObj, "time")
        // const merged = [].concat(timeList, saveObj);
        //  const merged = [...timeList, ...saveObj];
        setTimelist(timeList)
        // console.log("merged", timeList)
        setmodal_guidance(!modal_guidance)
    }

    const bpranges = [
        { name: "bloodpressue", title: "BLOOD PRESSURE", class: "clr1" },
        { name: "gases", title: "GASES", class: "clr2" },
        { name: "ivinfusions", title: "IV INFUSIONS", class: "clr3" },
        { name: "crystalloids", title: "CRYSTALLOIDS", class: "clr4" },
        { name: "colloids", title: "COLLOIDS", class: "clr1" },
        { name: "bloodproducts", title: "BLOOD PRODUCTS", class: "clr2" },
        { name: "fluidsout", title: "FLUIDS OUT", class: "clr3" }
    ]

    const updateBp = (time, title, type) => {
        // console.log("time", time)
        //  console.log("type", type)
        setmodal_guidance(!modal_guidance)
        setSelected_Time(time.time)
        setSelected_Type({ type, title })

    }

    const updateSugarValue = (e) => {
        let updatedValue = { value: e.target.value }
        setSelected_Type(selectedType => ({
            ...selectedType,
            ...updatedValue
        }));

        //  setSelected_Type({value: e.target.value})
    }

    const timeScroll = (e) => {
        const Datetimefield = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })
        console.log("datetimefield ", Datetimefield)
        const formatTime = Moment().format('HH:mm')
        console.log("datetimefield xx", formatTime)
        console.log("timeList ", timeList)
        let ddd = timeList.find((x) => (x.time >= formatTime || formatTime <= x.time))
        console.log("ddd ", ddd)

        if (ddd && ddd.time) {
            let timeReplace = ddd.time.replace(/\:/g, '')
            console.log("timeReplace ", timeReplace)
            console.log(" dayCalendar010x n", $('#' + timeReplace).position())
            console.log(" offset n", $('#' + timeReplace).offset())
            let pos = $('#' + timeReplace).offset().left
            console.log("pos ", pos)
            $('#dayCalendar').scrollLeft(pos)
        } else {
            $('#dayCalendar').scrollLeft($('#0000').offset().left)
        }

        //$('#dayCalendar').scrollLeft( $('#'+ddd.time).offset().left )


        // $('#dayCalendar').animate({scrollLeft:$('#'+ddd.time).offset().left});
        //$.scrollTo($('#'+ddd.time), 1000);
        // console.log("ggghfjjgh ", ddd)

    }

    const Calendar_formatDate = Moment().format('MMM DD, YYYY')

    return (
        <React.Fragment>


           
                <h2 className="popup_title dc_title">Day Calendar <a onClick={(e) => timeScroll(e)} className="material-icons"> restart_alt </a></h2>
                <table className='day_calendar_table table'>
                    <div className='day_calendar' id="dayCalendar">
                        <tbody>
                            <tr>
                                <th className="headcol">
                                    <div className='timedisplay' > {Calendar_formatDate}</div>
                                </th>
                                {timeList.map(time => (
                                    <td className="long" key={time.time} id={time.time.replace(/\:/g, '')}>
                                        <div className='timedisplay'>{time.time}</div>
                                    </td>
                                ))}
                            </tr>
                            {bpranges.map(bp => (
                                <tr key={bp.name}>
                                    <th className={bp.class ? bp.class +" headcol" : "headcol"} key={bp.name}>
                                        <div className='timedisplay'> {bp.title} <a href='#' className='material-icons add'>control_point</a></div>
                                    </th>
                                    {timeList.map(time => (
                                        <td onClick={(e) => updateBp(time, bp.title, bp.name)} className="long" key={time.time}>
                                            <div className='timedisplay'>
                                                {time[bp.name] && time[bp.name].min && time[bp.name].max && <span className='timedisplayres'> {`${time[bp.name].min} / ${time[bp.name].max}`}</span>}

                                                {time[bp.name] && time[bp.name].value && <span className='timedisplayres'> {`${time[bp.name].value}`}</span>}
                                            </div>
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </div>

                </table>




                <Modal
                    isOpen={modal_guidance}
                    toggle={() => {
                        setmodal_guidance(!modal_guidance)
                    }}
                >  <div className="timing_popup">

                        <div className="modal-header">

                            <h5 className="modal-title">
                                <span className="sub_text">{selectedType.title}</span>

                            </h5>
                            <button type="button" onClick={() => {
                                setmodal_guidance(!modal_guidance)
                            }} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="section simple_wound ctr">
                                <div className="row">

                                    <div className="col-md-6">
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text"
                                                className="form-control" name="time" defaultValue={selectedTime} /><label
                                                    className="floating-label">Time</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form_group">
                                            {selectedType.type == "bloodpressue" ?
                                                <MultiRangeSlider
                                                    min={50}
                                                    max={250}
                                                    onChange={setselectedBpRange}
                                                // onChange={(e, min, max ) => { addAppointment(min, max);  }}
                                                //  onChange={({ min, max }) =>  {console.log(`min = ${min}, max = ${max}`)}}

                                                /> :
                                                <div className="floating-label-group"><input type="text" name="sugarlevels"
                                                    className="form-control" onChange={(e) => updateSugarValue(e)} defaultValue={selectedType.value} /><label
                                                        className="floating-label">levels</label>
                                                </div>
                                            }
                                        </div>
                                    </div>


                                </div>
                                {selectedType.type == "bloodpressue" &&
                                    <div className="row">

                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="floating-label-group"><input type="text"
                                                    className="form-control" name="min" value={selectedBpRange.min} defaultValue="" /><label
                                                        className="floating-label">Min</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="floating-label-group"><input type="text"
                                                    className="form-control" name="max" value={selectedBpRange.max} defaultValue="" /><label
                                                        className="floating-label">Max</label>
                                                </div>
                                            </div>
                                        </div>

                                    </div>}

                                <button className="border_btn" onClick={(e) => saveClick(e)} ><span className="material-icons">save_as</span>SAVE
                                </button>

                            </div>

                        </div>


                    </div>
                </Modal>


         


        </React.Fragment>
    )
}

DayCalendar.propTypes = {
    t: PropTypes.any,
    getWards: PropTypes.func,
    getTheatres: PropTypes.func,
    wards: PropTypes.array,
    user: PropTypes.any,
    theatres: PropTypes.array,
    clinic: PropTypes.array,
    patients: PropTypes.array,
    doctors: PropTypes.array,
    profile: PropTypes.object
}

const mapStateToProps = ({ Layout, Login }) => ({
    wards: Layout.wards,
    user: Login.user,
    theatres: Layout.theatres,
    clinic: Layout.clinic,
    patients: Layout.patients,
    doctors: Layout.doctors,
    profile: Layout.profile
})

const mapDispatchToProps = dispatch => ({
    getWards: () => dispatch(getWardsList()),
    getTheatres: () => dispatch(getTheatresList()),
    getClinic: () => dispatch(getClinicList()),
    getPatient: () => dispatch(getPatientList()),
    getUserprofile: (id) => dispatch(getUprofile(id)),
    getDoctor: () => dispatch(getDoctorList()),
    patientupdate: (id, data) => dispatch(updateTheatrePatient(id, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(DayCalendar)
