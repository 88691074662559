import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import MetaTags from 'react-meta-tags';
import Patient from '../../assets/images/user.png';
import straighten from '../../assets/images/straighten.svg';
import scale from '../../assets/images/scale.svg';
import monitroWeight from '../../assets/images/monitor_weight.svg';
import favorite from '../../assets/images/favorite.svg';
import PulseOxy from '../../assets/images/PulseOxy.svg';
import thermostat from '../../assets/images/thermostat.svg';
import bloodtype from '../../assets/images/bloodtype.svg';


import { connect } from "react-redux"
import { getWardsList, getTheatresList, getClinicList, getPatientList, getUprofile, getDoctorList, updatePatient } from "../../store/actions"

const NPatient = props => {


    const [visiblePatients, setPatient] = useState(true)
    const [visiblelanding, setClanding] = useState(false)
    const [successmessage, setSuccess] = useState("")


    const [clinicValues, setClinic] = useState({})
    const [clinicPatient, setCPatient] = useState({})

    const {
        wards, user, theatres, clinic, patients, profile, doctors
    } = props;

    useEffect(() => {
        const { getWards, getTheatres, getClinic, getDoctor, getPatient, getUserprofile } = props
        // getWards()
        // getTheatres()
        // getClinic()
        getPatient()
        getDoctor()
        let userId = localStorage.getItem("authUser")
        getUserprofile(userId)
    }, [])

    const selectPatient = (e) => {

            setCPatient(e)
            setPatient(false)
            setClanding(true)


    }
    const updateInputValue = (e) => {
        let val = e.target.value;
        let name = e.target.name;
        let updatedValue = {};
        updatedValue = { [name]: val };
        setClinic(clinicValues => ({
            ...clinicValues,
            ...updatedValue
        }));
    }

    const updateClinic = (e) => {
        const { patientupdate } = props
        let id = clinicPatient._id
       patientupdate(id, clinicValues)
       setSuccess("Update successfully")

       setTimeout(() => {
        setSuccess("")
       }, 2000);
    }


    return (
        <React.Fragment>

            <MetaTags>
                <title>Nurse</title>
            </MetaTags>
            <div className="dashboard ward">
                <div className="main">

                   

                    {visiblePatients && 
                        <div className="wrapper">
                            <div className="out_patient_section">

                               

                                {patients && patients.length > 0 &&
                                    patients.map((item, i) => (

                                           
                                        <div className=" out_patient_section out_patient_details mb-0" key={i}>

                                        <div className="patient_block"  onClick={(e) => selectPatient(item)}>
                                            <div className="content">
                                                <div className="block">
                                                    <div className="title">{item.firstname + " " + item.lastname}</div>
                                                    <div className="title">{item.age} Years</div>
                                                    <div className="sub">Aug 20th 1940 <div className="badge ">{item.sex}</div>
                                                    </div>
                                                </div>
                                                <div className="block">
                                                    <div><span className="title">Hospital : </span> <span className="sub">{item.hospitalNo}</span> </div>
                                                    <div><span className="title">NHS: : </span> <span className="sub">{item.patientId}</span>
                                                    </div>
                                                </div>
                                                <div className="block">
                                                    <div className="sub">Ward </div>
                                                    <div className="title">{item.site}</div>
                                                </div>
                                                <div className="block">
                                                    <div className="title">{item.doctor}</div>
                                                    <div className="badge ">Tr. &amp; Ortho Surgeon </div> 
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    

                                    ))}

                            </div>
                        </div>}

                    {visiblelanding && clinicPatient &&
                        <div className="wrapper">
                            <div className="out_patient_section out_patient_details">

                            <div className="patient_block">
                                            <div className="content">
                                                <div className="block">
                                                    <div className="title">{clinicPatient.firstname + " " + clinicPatient.lastname}</div>
                                                    <div className="title">{clinicPatient.age} Years</div>
                                                    <div className="sub"><div className="badge ">{clinicPatient.sex}</div>
                                                    </div>
                                                </div>
                                                <div className="block">
                                                    <div><span className="title">Hospital : </span> <span className="sub">{clinicPatient.hospitalNo}</span> </div>
                                                    <div><span className="title">NHS: : </span> <span className="sub">{clinicPatient.patientId}</span>
                                                    </div>
                                                </div>
                                                <div className="block">
                                                    <div className="sub">Ward </div>
                                                    <div className="title">{clinicPatient.site}</div>
                                                </div>
                                                <div className="block">
                                                    <div className="title">{clinicPatient.doctor}</div>
                                                    {/* <div className="badge ">Tr. &amp; Ortho Surgeon </div> */}
                                                </div>
                                            </div>
                                        </div>

                                
                                <div className="form_group">
                                    <div className="problem">
                                        <h3>Visit No </h3>
                                        <p><input name="visitno" onChange={(e) => updateInputValue(e)} ></input></p>
                                        <h3>Problem</h3>
                                        <p><input name="problem" onChange={(e) => updateInputValue(e)}  ></input></p>
                                    </div>
                                    
                                    
                                </div>
                                <div className="section ctr">
                                    <div className="form_group">
                                        <h3 className="section_title">Measurements</h3>
                                        <div className="measurements">
                                            <div className="box">
                                                <img src={straighten} className="icon" />
                                                <input name="measurement" className='width100' onChange={(e) => updateInputValue(e)} placeholder="cm"></input>
                                                {(clinicValues.measurement || clinicPatient.measurement) && <span className="text">{(clinicValues.measurement || clinicPatient.measurement)} <span className="sub">cm </span> </span>}
                                            </div>
                                            <div className="box">
                                                <img src={scale} className="icon" />
                                                <input name="kg" className='width100' onChange={(e) => updateInputValue(e)} placeholder="kg"></input>
                                                { (clinicValues.kg || clinicPatient.kg) &&  <span className="text">{(clinicValues.kg || clinicPatient.kg)} <span className="sub">kg </span> </span>}
                                            </div>
                                            <div className="box">
                                                <img src={monitroWeight} className="icon" />
                                                <input name="weight" className='width100' onChange={(e) => updateInputValue(e)} placeholder="weight"></input>
                                                {(clinicValues.weight || clinicPatient.weight) &&  <span className="text">{(clinicValues.weight || clinicPatient.weight)}  </span> }
                                            </div>
                                            <div className="box">
                                                <img src={favorite} className="icon" />
                                                <input name="bpm" className='width100' onChange={(e) => updateInputValue(e)} placeholder="BPM"></input>
                                                {(clinicValues.bpm || clinicPatient.bpm) && <span className="text">{(clinicValues.bpm || clinicPatient.bpm)} <span className="sub">BPM </span></span>}
                                            </div>
                                            <div className="box">
                                                <img src={PulseOxy} className="icon" />
                                                <input name="pulse" className='width100' onChange={(e) => updateInputValue(e)} placeholder="PULSE"></input>
                                                {(clinicValues.pulse || clinicPatient.pulse) &&  <span className="text">{(clinicValues.pulse || clinicPatient.pulse)} <span className="sub">% </span> </span> }
                                            </div>
                                            <div className="box">
                                                <img src={thermostat} className="icon" />
                                                <input name="thermo" className='width100' onChange={(e) => updateInputValue(e)} placeholder="temperature"></input>
                                                {(clinicValues.thermo || clinicPatient.thermo) && <span className="text">{(clinicValues.thermo || clinicPatient.thermo)} <span className="sub">F </span> </span>}
                                            </div>
                                            <div className="box">
                                                <img src={bloodtype} className="icon" />
                                                <input name="bloodtype" className='width100' onChange={(e) => updateInputValue(e)} placeholder="MGDI"></input>
                                                {(clinicValues.bloodtype || clinicPatient.bloodtype) && <span className="text">{(clinicValues.bloodtype || clinicPatient.bloodtype)} <span className="sub">mgdi </span> </span>}
                                            </div>
                                        </div>
                                    </div>
                               

                                    <button className='btn btn-primary' onClick={(e) => { updateClinic(e) }}  >SUBMIT</button>

                                    <h4>{successmessage}</h4>

                                </div>

                            </div>
                        </div>
                    }



                </div>
            </div>


        

        </React.Fragment>
    )
}

NPatient.propTypes = {
    t: PropTypes.any,
    getWards: PropTypes.func,
    getTheatres: PropTypes.func,
    wards: PropTypes.array,
    user: PropTypes.any,
    theatres: PropTypes.array,
    clinic: PropTypes.array,
    patients: PropTypes.array,
    doctors: PropTypes.array,
    profile: PropTypes.object
}

const mapStateToProps = ({ Layout, Login }) => ({
    wards: Layout.wards,
    user: Login.user,
    theatres: Layout.theatres,
    clinic: Layout.clinic,
    patients: Layout.patients,
    doctors: Layout.doctors,
    profile: Layout.profile
})

const mapDispatchToProps = dispatch => ({
    getWards: () => dispatch(getWardsList()),
    getTheatres: () => dispatch(getTheatresList()),
    getClinic: () => dispatch(getClinicList()),
    getPatient: () => dispatch(getPatientList()),
    getUserprofile: (id) => dispatch(getUprofile(id)),
    getDoctor: () => dispatch(getDoctorList()),
    patientupdate: (id, data) => dispatch(updatePatient(id, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(NPatient)
