import React, { useState, useRef } from "react"
import { Link } from "react-router-dom"

const onCallMenus = (props) => {
    const ref = useRef()
    const [showSidemenu, setshowSidemenu] = useState(false)
    const menuClick = (e) => {
        setshowSidemenu(!showSidemenu)
    }

    const redirectLink = (e, route) => {
        e.preventDefault()
        // console.log("rot", route)
        localStorage.setItem("re", route)
         console.log("props", props)
        // props.history.push("/onlist")
    }
    return (
        <div className="hed">
             <div className="left">
                        <div className="side_menu">
                            <a className="menu" data-bs-toggle="offcanvas" href="#offcanvasExample" role="button"
                                aria-controls="offcanvasExample">
                                <span className="material-icons"> menu </span>
                            </a>
                            <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasExample"
                                aria-labelledby="offcanvasExampleLabel">
                                <div className="offcanvas-body">
                                    <ul className="nav nav-pills flex-column">

                                        <li className="nav-item first_level">
                                            <a className="nav-link collapsed" href="#submenu1" data-toggle="collapse"
                                                data-target="#submenu1"> <span className="material-symbols-outlined icon">
                                                    groups </span> ON CALL/ POST ON CALL MEETING
                                                <span className="material-icons menu_arrow"> navigate_next </span></a>
                                            <div className="collapse" id="submenu1" aria-expanded="false">
                                                <ul className="flex-column pl-2 nav">
                                                    <li className="nav-item secend_level">
                                                    <Link to="/calllist" className="nav-link collapsed"> <span
                                                                className="dot"></span> Meet </Link>

                                                    </li>

                                                    <li className="nav-item secend_level" onClick={(e) => redirectLink(e, 'addpatient')}>
                                                    <Link to="/onlist" className="nav-link collapsed"> <span
                                                                className="dot"></span> Add New Patient </Link>

                                                    </li>
                                                    <li className="nav-item secend_level"><a className="nav-link" href="#">
                                                            <span className="dot"></span> Referrals </a>
                                                    </li>
                                                    <li className="nav-item secend_level"><a className="nav-link" href="#">
                                                            <span className="dot"></span> Within Hospitals </a>
                                                    </li>
                                                    <li className="nav-item secend_level"><a className="nav-link" href="#">
                                                            <span className="dot"></span> To regional Centres </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li className="nav-item first_level">
                                        <Link to="/teamlist" className="nav-link" > <span className="material-symbols-outlined icon"> group </span> TEAM</Link>
                                        </li>
                                        <li className="nav-item first_level" onClick={(e) => redirectLink(e, 'fracture')}>
                                        <Link to="/onlist" className="nav-link" > <span className="material-symbols-outlined icon"> group </span> FRACTURE NECK OF FEMUR</Link>
                                        </li>
                                        <li className="nav-item first_level" onClick={(e) => redirectLink(e, 'serical')}>
                                        <Link to="/onlist" className="nav-link" > <span className="material-symbols-outlined icon"> group </span> SERICAL SPINAL</Link>
                                        </li>
                                        <li className="nav-item first_level">
                                        <Link to="/trauma" className="nav-link" > <span className="material-symbols-outlined icon"> personal_injury </span> TRAUMA CALL</Link>
                                        </li>
                                        <li className="nav-item first_level">
                                            <a className="nav-link" href="#"> <span className="material-symbols-outlined icon"> amp_stories </span> POST ON CALL</a>
                                        </li>
                                        <li className="nav-item first_level">
                                            <a className="nav-link" href="#"> <span className="material-symbols-outlined icon"> event_repeat </span> FOLLOW UP</a>
                                        </li>
                                        <li className="nav-item first_level">
                                            <a className="nav-link" href="#"> <span className="material-symbols-outlined icon"> patient_list </span> PATIENTS WAITING</a>
                                        </li>
                                        <li className="nav-item first_level">
                                            <a className="nav-link" href="#"> <span className="material-symbols-outlined icon">list </span> THEATRE LIST (TRAUMA)</a>
                                        </li>

                                        <li className="nav-item first_level">
                                            <a className="nav-link collapsed" href="#submenu2" data-toggle="collapse"
                                                data-target="#submenu2"><span className="material-symbols-outlined icon">pending </span>OTHERS
                                                ROOM
                                                <span className="material-icons menu_arrow"> navigate_next </span></a>
                                            <div className="collapse" id="submenu2" aria-expanded="false">
                                                <ul className="flex-column pl-2 nav">
                                                    <li className="nav-item secend_level"><a className="nav-link" href="#">
                                                            <span className="dot"></span> Tel Directory </a>
                                                    </li>
                                                    <li className="nav-item secend_level"><a className="nav-link" href="#">
                                                            <span className="dot"></span>Code </a>
                                                    </li>
                                                    <li className="nav-item secend_level"><a className="nav-link" href="#">
                                                            <span className="dot"></span> Quality </a>
                                                    </li>
                                                    <li className="nav-item secend_level"><a className="nav-link" href="#">
                                                            <span className="dot"></span>Log Book</a>
                                                    </li>
                                                    
                                                </ul>
                                            </div>
                                        </li>
                                        <li className="nav-item first_level">
                                            <a className="nav-link" href="#"> <span className="material-symbols-outlined icon"> amp_stories  </span> THEATRE TEA(M) ROOM</a>
                                        </li>
                                        <li className="nav-item first_level">
                                            <a className="nav-link" href="#"> <span className="material-symbols-outlined icon">record_voice_over </span> VOICE NOTE</a>
                                        </li>
                                        <li className="nav-item first_level">
                                            <a className="nav-link" href="#"> <span className="material-symbols-outlined icon">add_notes </span> TEXT NOTE</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <h2 className="title">{props.menuName}</h2>
                    </div>
            <div className="right">
                <div className="btn-group">
                    <button type="button" className="btn btn-primary">JACS</button>
                    <button type="button" className="btn btn-primary">ICE</button>
                    <button type="button" className="btn btn-primary">PACS</button>
                </div>
            </div>
        </div>
    )
}

export default onCallMenus