import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import MetaTags from 'react-meta-tags';
import skin from '../../assets/images/nurse-icons/skin.svg';
import nutrition from '../../assets/images/nurse-icons/nutrition.svg';
import movinghandling from '../../assets/images/nurse-icons/moving-handling.svg';
import summary from '../../assets/images/nurse-icons/summary.svg';
import smoking from '../../assets/images/nurse-icons/smoking.svg';
import drugalcohol from '../../assets/images/nurse-icons/drug-alcohol.svg';
import infection from '../../assets/images/nurse-icons/infection.svg';
import checkicon from '../../assets/images/nurse-icons/check-icon.svg';
import pendingicon from '../../assets/images/nurse-icons/pending-icon.svg';
import vipscore from '../../assets/images/nurse-icons/vipscore.svg';
import falling from '../../assets/images/nurse-icons/falling.svg';
import supervision from '../../assets/images/nurse-icons/supervision.svg';
import safe from '../../assets/images/nurse-icons/safe.svg';
import assess from '../../assets/images/nurse-icons/assess.svg';
import cintinence from '../../assets/images/nurse-icons/cintinence.svg';
import mental from '../../assets/images/nurse-icons/mental.svg';
import dementia from '../../assets/images/nurse-icons/dementia.svg';
import PatientBlock from "../Clinic/patientBlock";

import { connect } from "react-redux"
import { getWardsList, getTheatresList, getClinicList, getPatientList, getUprofile, getDoctorList, updatePatient } from "../../store/actions"

const RiskAssesment = props => {


    const [visiblePatients, setPatient] = useState(true)
    const [visiblelanding, setClanding] = useState(false)
    const [successmessage, setSuccess] = useState("")


    const [clinicValues, setClinic] = useState({})
   
    const {
        wards, user, theatres, clinic, patients, profile, doctors
    } = props;

    useEffect(() => {
        $(".modal-backdrop").hide()
    }, [])

    useEffect(() => {
        const { getWards, getTheatres, getClinic, getDoctor, getPatient, getUserprofile } = props
        // getWards()
        // getTheatres()
        // getClinic()
        getPatient()
        getDoctor()
        let userId = localStorage.getItem("authUser")
        getUserprofile(userId)
    }, [])

 
    const assesment = (e, item) => {
        e.preventDefault()
        localStorage.setItem("a", JSON.stringify(item))
        props.history.push("/assesment")
    }

    let clinicPatient = JSON.parse(localStorage.getItem("p"))

    return (
        <React.Fragment>

            <MetaTags>
                <title>Risk Assesment</title>
            </MetaTags>
            <div className="dashboard ward">
                <div className="main">

                    <div className='wrapper'>

                        <div className=" patient_record prd">
                        {clinicPatient && <PatientBlock patient={clinicPatient} />}
                      
                        
                        <div className="titleandinp">
                            <h3>NURSING RISK ASSESSMENT</h3>
                            <a href="#" className="icon_btn">Risk Assessment <span className="material-icons icon">
                                arrow_forward_ios
                            </span></a>
                        </div>
                        <div className="ward-icons-wrapper">
                            <div className="thumb-grid">
                                <div className="thumb a nurse-thumb">
                                    <div className="status">
                                        <span><img src={checkicon} className="status-icon show" /></span>
                                        <span><img src={pendingicon} className="status-icon hide" /></span>
                                    </div>
                                    <div className="thumb-content" onClick={(e)=> assesment(e, "SKIN INTEGRITY")}>
                                        <img src={skin} className="nurse-icons" />
                                        <p className="btn-title">Skin Integrity</p>
                                    </div>
                                </div>
                                <div className="thumb b nurse-thumb" onClick={(e)=> assesment(e, "NUTRITION - MUST")}>
                                    <div className="status">
                                        <span><img src={checkicon} className="status-icon hide" /></span>
                                        <span><img src={pendingicon} className="status-icon show" /></span>
                                    </div>
                                    <div className="thumb-content">
                                        <img src={nutrition} className="nurse-icons" />
                                        <p className="btn-title">Nutrition - MUST</p>
                                    </div>
                                </div>
                                <div className="thumb c nurse-thumb" onClick={(e)=> assesment(e, "MOVING & HANDLING")}>
                                    <div className="status">
                                        <span><img src={checkicon} className="status-icon hide" /></span>
                                        <span><img src={pendingicon} className="status-icon show" /></span>
                                    </div>
                                    <div className="thumb-content">
                                        <img src={movinghandling} className="nurse-icons" />
                                        <p className="btn-title">MOVING & HANDLING</p>
                                    </div>
                                </div>
                                <div className="thumb e nurse-thumb" onClick={(e)=> assesment(e, "SMOKING")}>
                                    <div className="status">
                                        <span><img src={checkicon} className="status-icon hide" /></span>
                                        <span><img src={pendingicon} className="status-icon show" /></span>
                                    </div>
                                    <div className="thumb-content">
                                        <img src={smoking} className="nurse-icons" />
                                        <p className="btn-title">SMOKING</p>
                                    </div>
                                </div>

                                <div className="thumb f nurse-thumb" onClick={(e)=> assesment(e, "DRUG & ALCOHOL")}>
                                    <div className="status">
                                        <span><img src={checkicon} className="status-icon hide" /></span>
                                        <span><img src={pendingicon} className="status-icon show" /></span>
                                    </div>
                                    <div className="thumb-content">
                                        <img src={drugalcohol} className="nurse-icons" />
                                        <p className="btn-title">DRUG & ALCOHOL</p>
                                    </div>
                                </div>

                                <div className="thumb g nurse-thumb" onClick={(e)=> assesment(e, "INFECTION")}>
                                    <div className="status">
                                        <span><img src={checkicon} className="status-icon hide" /></span>
                                        <span><img src={pendingicon} className="status-icon show" /></span>
                                    </div>
                                    <div className="thumb-content">
                                        <img src={infection} className="nurse-icons" />
                                        <p className="btn-title">INFECTION</p>
                                    </div>
                                </div>

                                <div className="thumb g nurse-thumb" onClick={(e)=> assesment(e, "VIP SCORE")}>
                                    <div className="status">
                                        <span><img src={checkicon} className="status-icon hide" /></span>
                                        <span><img src={pendingicon} className="status-icon show" /></span>
                                    </div>
                                    <div className="thumb-content">
                                        <img src={vipscore} className="nurse-icons" />
                                        <p className="btn-title">VIP SCORE</p>
                                    </div>
                                </div>

                                <div className="thumb c nurse-thumb" onClick={(e)=> assesment(e, "SUMMARY")}>
                                    <div className="status">
                                        <span><img src={checkicon} className="status-icon hide" /></span>
                                        <span><img src={pendingicon} className="status-icon show" /></span>
                                    </div>
                                    <div className="thumb-content">
                                        <img src={summary} className="nurse-icons" />
                                        <p className="btn-title">Summary</p>
                                    </div>
                                </div>

                            </div>

                            {clinicPatient && clinicPatient.age > 60 &&
                            <div>
                                <div className="thumb-grid grid-marg" >
                                    <div className="thumb a opt-thumb" onClick={(e)=> assesment(e, "FALLS RISK")}>
                                        <div className="status">
                                            <span><img src={checkicon} className="status-icon hide" /></span>
                                            <span><img src={pendingicon} className="status-icon show" /></span>
                                        </div>
                                        <div className="thumb-content">
                                            <img src={falling} className="nurse-icons" />
                                            <p className="btn-title">Falls risk</p>
                                        </div>
                                    </div>
                                    <div className="thumb b opt-thumb" onClick={(e)=> assesment(e, "SUPERVISION BED RAILS")}>
                                        <div className="status">
                                            <span><img src={checkicon} className="status-icon hide" /></span>
                                            <span><img src={pendingicon} className="status-icon show" /></span>
                                        </div>
                                        <div className="thumb-content">
                                            <img src={supervision} className="nurse-icons" />
                                            <p className="btn-title"> SUPERVISION BED RAILS</p>
                                        </div>
                                    </div>
                                    <div className="thumb c opt-thumb" onClick={(e)=> assesment(e, "SAFEGUARDING")}>
                                        <div className="status">
                                            <span><img src={checkicon} className="status-icon hide" /></span>
                                            <span><img src={pendingicon} className="status-icon show" /></span>
                                        </div>
                                        <div className="thumb-content">
                                            <img src={safe} className="nurse-icons" />
                                            <p className="btn-title">SAFEGUARDING</p>
                                        </div>
                                    </div>

                                    <div className="thumb d opt-thumb" onClick={(e)=> assesment(e, "ADMISSION")}>
                                        <div className="status">
                                            <span><img src={checkicon} className="status-icon hide" /></span>
                                            <span><img src={pendingicon} className="status-icon show" /></span>
                                        </div>
                                        <div className="thumb-content">
                                            <img src={assess} className="nurse-icons" />
                                            <p className="btn-title">ADMISSION</p>
                                        </div>
                                    </div>

                                    <div className="thumb e opt-thumb" onClick={(e)=> assesment(e, "CONTINENCE")}>
                                        <div className="status">
                                            <span><img src={checkicon} className="status-icon hide" /></span>
                                            <span><img src={pendingicon} className="status-icon show" /></span>
                                        </div>
                                        <div className="thumb-content">
                                            <img src={cintinence} className="nurse-icons" />
                                            <p className="btn-title">CONTINENCE</p>
                                        </div>
                                    </div>

                                    <div className="thumb f opt-thumb" onClick={(e)=> assesment(e, "MENTAL STATE")}>
                                        <div className="status">
                                            <span><img src={checkicon} className="status-icon hide" /></span>
                                            <span><img src={pendingicon} className="status-icon show" /></span>
                                        </div>
                                        <div className="thumb-content">
                                            <img src={mental} className="nurse-icons" />
                                            <p className="btn-title">MENTAL STATE</p>
                                        </div>
                                    </div>

                                    <div className="thumb g opt-thumb" onClick={(e)=> assesment(e, "DEMENTIA")}>
                                        <div className="status">
                                            <span><img src={checkicon} className="status-icon hide" /></span>
                                            <span><img src={pendingicon} className="status-icon show" /></span>
                                        </div>
                                        <div className="thumb-content">
                                            <img src={dementia} className="nurse-icons" />
                                            <p className="btn-title">DEMENTIA</p>
                                        </div>
                                    </div>
                                </div>

                            </div>}

                        </div>
                        </div>
                    </div>

                </div>
            </div>




        </React.Fragment>
    )
}

RiskAssesment.propTypes = {
    t: PropTypes.any,
    getWards: PropTypes.func,
    getTheatres: PropTypes.func,
    wards: PropTypes.array,
    user: PropTypes.any,
    theatres: PropTypes.array,
    clinic: PropTypes.array,
    patients: PropTypes.array,
    doctors: PropTypes.array,
    profile: PropTypes.object
}

const mapStateToProps = ({ Layout, Login }) => ({
    wards: Layout.wards,
    user: Login.user,
    theatres: Layout.theatres,
    clinic: Layout.clinic,
    patients: Layout.patients,
    doctors: Layout.doctors,
    profile: Layout.profile
})

const mapDispatchToProps = dispatch => ({
    getWards: () => dispatch(getWardsList()),
    getTheatres: () => dispatch(getTheatresList()),
    getClinic: () => dispatch(getClinicList()),
    getPatient: () => dispatch(getPatientList()),
    getUserprofile: (id) => dispatch(getUprofile(id)),
    getDoctor: () => dispatch(getDoctorList()),
    patientupdate: (id, data) => dispatch(updatePatient(id, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(RiskAssesment)
