import React from "react";

function IconGraph(props) {
   return (
    <svg
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.5 14.0001H1.33333V7.33341H5.5V14.0001ZM10.5 14.0001H6.33333V4.00008H10.5V14.0001ZM15.5 14.0001H11.3333V0.666748H15.5V14.0001ZM16.3333 17.3334H0.5V15.6667H16.3333V17.3334Z"
        fill="#0097DE"
      />
    </svg>
  );
}

export default IconGraph;
