import React, { useState } from "react"
import { Link } from "react-router-dom"
const PatientBlock = (props) => {
    const [visibleaccordion, setvisibleaccordion] = useState(false)
    return (
        <>

            <div className="patient_block">
                <div className="content">
                   
                    <div class="switch">
                    {props.selectPatientList && <Link to="wlist"  > <span class="material-symbols-outlined icon"> arrow_left </span> </Link>}
                    {props.selectPatientList && <Link to="wlist" > <span class="material-symbols-outlined icon"> arrow_right </span> </Link>}
                    </div>
                    <div className="block">
                        <div className="sub">{props.patient.firstname + " " + props.patient.lastname}</div  >
                        <div className="sub">{props.patient.age} Years <div className="badge ">{props.patient.sex}</div></div  >
                        <div className="sub">{props.patient.dob}  </div>
                    </div>
                    <div className="block">
                        <div><span className="title">Hospital : </span> <span className="sub">{props.patient.patientId}</span> </div>
                        <div><span className="title">NHS: </span> <span className="sub">{props.patient.hospitalNo}</span> </div>
                    </div>
                    <div className="block">
                        <div className="sub">Ward</div>
                        <div className="sub">{props.patient.clinic}</div>
                    </div>
                    <div className="block">
                        <div className="sub">{props.patient.doctor}</div>
                        <div className="badge ">Tr. & Ortho Surgeon </div>

                    </div>

                </div>
                {props.accordion &&
                <div className="accordion">
                    <div className="accordion-item">
                        {visibleaccordion &&
                        <div className="accordion-collapse">
                            <div className="accordion-body patient_expand">
                                <div className="row bb-1">
                                    <div className="col-md-5">
                                        <div className="yn_switch mt-0">
                                            <div className="label-container">
                                                <p>For Resucitation</p>
                                            </div>
                                            <label className="switch-container">
                                                <input hidden="" type="checkbox" className="switch_check" />
                                                <div className="switch-bg"></div>
                                                <div className="round-box"></div>
                                                <div className="switch-left">
                                                    <span>NO</span>
                                                </div>
                                                <div className="switch-right">
                                                    <span>YES</span>
                                                </div>
                                            </label>
                                        </div>
                                        <div className="yn_switch">
                                            <div className="label-container">
                                                <p>DNACPR In Place?</p>
                                            </div>
                                            <label className="switch-container">
                                                <input hidden="" type="checkbox" className="switch_check" />
                                                <div className="switch-bg"></div>
                                                <div className="round-box"></div>
                                                <div className="switch-left">
                                                    <span>NO</span>
                                                </div>
                                                <div className="switch-right">
                                                    <span>YES</span>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="check_item mt-0 mb-0"><label className="check">Signed?</label>
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 1" /><span>
                                                        SIGNED</span></label>
                                                </div>
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 2" /><span>TO BE SIGNED</span></label>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form_group">
                                            <label className="label">When last verified?</label>
                                            <input type="date" className="form-control" />
                                        </div>
                                    </div>
                                </div>
                                <div className="check_item mt-10 mb-0"><label className="check">Likely Ceiling of Care
                                    if detoriorates</label>
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input name="site" type="radio"
                                            value="Site 1" /><span>
                                                Full Critical Care</span></label>
                                        </div>
                                        <div className="select_btn"><label><input name="site" type="radio"
                                            value="Site 2" /><span>Critical care not
                                                ventilation</span></label></div>
                                        <div className="select_btn"><label><input name="site" type="radio"
                                            value="Site 2" /><span>Full Ward care</span></label></div>
                                        <div className="select_btn"><label><input name="site" type="radio"
                                            value="Site 2" /><span>Comfort care</span></label></div>
                                    </div>
                                </div>
                            </div>
                        </div>}

                        <button className="accordion-button" type="button" onClick={(e) => { setvisibleaccordion(!visibleaccordion) }} >
                            <div className="exp_switch"></div>
                        </button>
                    </div>
                </div>}
            </div>
           

        </>
    )
}

export default PatientBlock
