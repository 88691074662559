import React, { useState, useEffect } from "react"
import MetaTags from 'react-meta-tags';
import TheatreSubHeader from "./theatreSubHeader";
import teambrief from '../../assets/images/tr_teamBrief.svg';
import tlist from '../../assets/images/tr_theatreList.svg';
import Session from '../../assets/images/tr_sesison.svg';
import TheareFlow from '../../assets/images/tr_theatreFlow.svg';
import Reports from '../../assets/images/tr_reports.svg';
import LifetimePatientRecord from '../../assets/images/LifetimePatientRecord.svg';
import diagnosis from '../../assets/images/diagnosis.svg';

const TheatreLatest = (props) => {
    const dropdownMenuClick = (e, menu) => {
        props.history.push("/" + menu)
    }
    
    useEffect(() => {
        document.body.style = '';
    }, [])

    return (
        <React.Fragment>

            <MetaTags>
                <title>Theatre</title>
            </MetaTags>
            <div className="dashboard ward current_episode">
                {/* <TheatreSubHeader backPage="dashboard" /> */}
                <div className="main">
                <div className="wrapper">
                    <div className="patient_record prd">


                        <ul className="oncall_dboard col_3">

                            <li>
                                <a onClick={(e) => dropdownMenuClick(e, 'teambrief')} className="item_card">
                                    <span className="icon"> <img src={teambrief} /></span>
                                    <span className="wn"> Team breif/debrief </span>

                                </a>
                            </li>
                            <li>
                                <a onClick={(e) => dropdownMenuClick(e, 'tlist')} className="item_card">
                                    <span className="icon"> <img src={tlist} /></span>
                                    Theatre list
                                </a>
                            </li>
                            <li>
                                <a data-bs-toggle="modal" data-bs-target="#session" className="item_card">
                                    <span className="icon"> <img src={Session} /></span>
                                    <span className="wn">  Session <span className="material-symbols-outlined icon2">
                                        keyboard_arrow_down </span></span>
                                </a>
                            </li>
                            <li>
                                <a onClick={(e) => dropdownMenuClick(e, 'tflow')} className="item_card">
                                    <span className="icon"> <img src={TheareFlow} /></span>
                                    Theare Flow
                                </a>
                            </li>
                            <li>
                                <a data-bs-toggle="modal" data-bs-target="#reports" className="item_card">
                                    <span className="icon"> <img src={Reports} /></span>
                                    <span className="wn">  Reports<span className="material-symbols-outlined icon2">
                                        keyboard_arrow_down </span></span>

                                </a>
                            </li>

                        </ul>
                    </div>

                   
                      
                    </div>
                </div>

            </div>

            <div className="modal wordnotes fade" id="session" tabIndex="-1" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">
                                <span className="icon"><img src={diagnosis} /></span>
                                Session
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="list_block">
                                <div className="list">
                                    <a onClick={(e) => dropdownMenuClick(e, 'sessionteam')} className="link">SESSION TEAM</a>
                                </div>

                                <div className="list">
                                    <a onClick={(e) => dropdownMenuClick(e, 'machine')} className="link">MACHINE EQUIPMENTS</a>
                                </div>
                                <div className="list">
                                    <a onClick={(e) => dropdownMenuClick(e, 'nursingpick')} className="link">ADVANCE PREPARATION</a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="modal wordnotes fade" id="reports" tabIndex="-1" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">
                                <span className="icon"><img src={diagnosis} /></span>
                                Reports
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="list_block">
                                <div className="list">
                                    <a href="#" className="link">SESSION REPORT</a>
                                </div>

                                <div className="list">
                                    <a onClick={(e) => dropdownMenuClick(e, 'theatreR')}  className="link">PRODUCTIVITY REPORT</a>
                                </div>
                                <div className="list">
                                    <a href="#" className="link">FINANCE REPORT</a>
                                </div>
                                <div className="list">
                                    <a href="#" className="link">PATIENT QUALITY REPORT</a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

export default TheatreLatest

