import React, { useEffect } from "react"
import MetaTags from 'react-meta-tags';
import {
    Container,
    Row,
    Col,
    Button
} from "reactstrap"

import Video from "./Video";
import VideoState from "../../pages/context/VideoState";

import Options from "./Options";
import { connect } from "react-redux"
import { getUprofile } from "../../store/actions"

const VideoChat = (props) => {
    useEffect(() => {
        const { getUserprofile } = props

        let userId = localStorage.getItem("authUser")
        getUserprofile(userId)
    }, [])


    return (
        <React.Fragment>
            <div className="wrapper">
                <div className="video_reg sections_block">
                    <MetaTags>
                        <title>Video Chat</title>
                    </MetaTags>
                    <div className="main">

                        <div className="wrapper">
                            
                            <Container fluid>
                                <VideoState>

                                    <Video />
                                    <Options />

                                </VideoState>

                            </Container>
                        </div>
                    </div>
                </div></div>
        </React.Fragment>
    )
}



const mapStateToProps = ({ Layout }) => ({
    profile: Layout.profile
})

const mapDispatchToProps = dispatch => ({
    getUserprofile: (id) => dispatch(getUprofile(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(VideoChat)
