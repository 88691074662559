import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import MetaTags from 'react-meta-tags';
import Moment from 'moment';
import { removeSpecialCharacters } from '../../utils/utils';
import { Buffer } from 'buffer';
import SignaturePad from 'react-signature-canvas'
import {
    Modal
} from "reactstrap"
import { connect } from "react-redux"
import { getWardsList, getTheatresList, getClinicList, getPatientList, getUprofile, getDoctorList, updateTheatrePatient, updateWardPatient, updatePatient } from "../../store/actions"

const periOperative = props => {

    const [clinicValues, setClinic] = useState({})
    const [file, setFile] = useState({});
    const [success_msg, setsuccess_msg] = useState(false)
    const {
        wards, user, theatres, clinic, patients, profile, doctors
    } = props;

    let periop_Sign = useRef(null);
    let periop_registered_practitioner_Sign = useRef(null);
    let periop_site_Sign = useRef(null);
    let periop_assessed_Sign = useRef(null);
    let periop_Scrub_practitioner_Sign = useRef(null);
    let periop_Circulating_practitioner_Sign = useRef(null);
    let periop_circulating_Sign = useRef(null);
    let periop_nurse_Sign = useRef(null);
    let periop_scrub_Sign = useRef(null);
    

    useEffect(() => {
        const { getPatient } = props
        getPatient()
    }, [])

    useEffect(() => {
        let clinicPatient = JSON.parse(localStorage.getItem("p"))
        if (patients && patients.length > 0 && clinicPatient && clinicPatient._id) {
            const cPatient = patients.filter(
                usr => usr._id === clinicPatient._id
            )
            if (cPatient && cPatient[0]) {
                let cpatientData = cPatient[0]
                delete cpatientData["_id"]
                setClinic(clinicValues => ({
                    ...clinicValues,
                    ...cpatientData
                }));
            }

        }
    }, [patients])

    const updateInputValue = (e) => {
        let val = e.target.value;
        let name = e.target.name;
        let updatedValue = {};
        updatedValue = { [name]: val };
        setClinic(clinicValues => ({
            ...clinicValues,
            ...updatedValue
        }));
    }

    const updateClinicValues = (e, name, val) => {
        e.preventDefault()
        let updatedValue = {};
        updatedValue = { [name]: val };
        setClinic(clinicValues => ({
            ...clinicValues,
            ...updatedValue
        }));
    }

    const updateClinic = (e) => {
        const { patientupdate } = props
        let clinicPatient = JSON.parse(localStorage.getItem("p"))
        let id = clinicPatient._id
        patientupdate(id, clinicValues)
        let patient = {
            ...clinicPatient,
            ...clinicValues
        }
        localStorage.setItem("p", JSON.stringify(patient))
        setsuccess_msg(!success_msg)

    }

    const theatreBack = (e) => {
        e.preventDefault()
        props.history.push("/tpatient")
    }

    const handleChange = (event) => {
        updateClinicValues(event, event.target.name, event.target.checked)
    }

    const updateMultipleValues = (e, name, val, type) => {
        e.preventDefault()
        let tvalues = clinicValues
        e.target.parentElement.classList.add('active');
        if (tvalues[name]) {
            const exists = tvalues[name].includes(val);
            if (!exists) {
                tvalues[name].push(val)
            }

        } else {
            tvalues = {
                [name]: [val]
            }
        }
        // let updatedValue = {};
        // tvalues[type] = true
        setClinic(clinicValues => ({
            ...clinicValues,
            ...tvalues
        }));

    }



    const handleFile = async (e, name) => {

        const file = e.target.files[0]
        console.log("file ", file, name)
        let filename = e.target.files[0].name;
        // const base64 = await convertBase64(file)
        console.log("filename ", filename)
        let pdetails = JSON.parse(localStorage.getItem("p"))
        // var reader = new FileReader();
        //   reader.readAsDataURL(file);
        // reader.onload = async function (e) {
        // console.log("e ", e)
        let uploadFilePath = "ipr/" + pdetails._id + "/" + name + "_result" + "/" + removeSpecialCharacters(filename);
        let data = { source_bucket: "ipr-resources", sourcepath: uploadFilePath }
        console.log("data ", data)

        const response = await fetch(API_URL+'upload?appname=ipr', {
            method: "POST",
            mode: "cors",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
        const result = await response.json();

        console.log("result ", result)
        let url = result.result
        console.log("url ", url)
        const uploadImage = await fetch(url, {
            method: "PUT",
            mode: "cors",
            headers: {
                "Content-Type": "multipart/form-data",
                "Accept": "/",
                "Cache-Control": "no-cache",
                "Accept-Encoding": "gzip, deflate",
                "Connection": "keep-alive",
                "cache-control": "no-cache"
            },
            body: file
        })

        console.log("resultImage ", uploadImage)

        let imagePath = "https://ipr-resources.s3.amazonaws.com/"
        let imageUploadPath = imagePath + uploadFilePath;

        const { patientupdate } = props
        let updateObj = {
            [name + "_result"]: imageUploadPath,
            [name + "_update"]: new Date()
        }
        patientupdate(pdetails._id, updateObj)
        setFile(file => ({
            ...file,
            ...updateObj
        }));
        setClinic(clinicValues => ({
            ...clinicValues,
            ...updateObj
        }));

        let patient = {
            ...pdetails,
            ...updateObj
        }
        localStorage.setItem("p", JSON.stringify(patient))

    };

    const clear = (e, refvalue) => {
        e.preventDefault()
        refvalue.clear()
    }
    const trim = (e, type, refvalue) => {
        e.preventDefault()
        sigUpload(refvalue.getCanvas().toDataURL('image/png'), type)
    }

    const sigUpload = async (filedata, name) => {
        const file = filedata
        //console.log("file ", file, name)
        let filename = name+".png";
        // const base64 = await convertBase64(file)
        //console.log("filename ", filename)
        let pdetails = JSON.parse(localStorage.getItem("p"))

        const dataf = file.replace(/^data:image\/\w+;base64,/, "");
  
        const buf = Buffer.from(dataf, "base64");
        let uploadFilePath = "ipr/" + pdetails._id + "/" + name + "_result" + "/" + removeSpecialCharacters(filename);
        let data = { source_bucket: "ipr-resources", sourcepath: uploadFilePath }
       // console.log("data ", data)

        const response = await fetch(API_URL+'upload?appname=ipr', {
            method: "POST",
            mode: "cors",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
        const result = await response.json();
       // console.log("result ", result, buf.length)
        let url = result.result
       // console.log("url ", url)
        const base64Data = new Buffer.from(filedata.replace(/^data:image\/\w+;base64,/, ""), 'base64');
        // Getting the file type, ie: jpeg, png or gif
        const type = filedata.split(';')[0].split('/')[1];
        const uploadImage = await fetch(url, {
            method: "PUT",
            mode: "cors",
            headers: {
               'Content-Type':`image/${type}`,
                'Content-Encoding': 'base64',
                ACL: 'public-read',
            },
            body: base64Data
        })

        //console.log("resultImage ", uploadImage)

        let imagePath = "https://ipr-resources.s3.amazonaws.com/"
        let imageUploadPath = imagePath + uploadFilePath;

        const { patientupdate } = props
        let updateObj = {
            [name + "_result"]: imageUploadPath,
            [name + "_update"]: new Date()
        }
        patientupdate(pdetails._id, updateObj)
        setFile(file => ({
            ...file,
            ...updateObj
        }));
        setClinic(clinicValues => ({
            ...clinicValues,
            ...updateObj
        }));

        let patient = {
            ...pdetails,
            ...updateObj
        }
        localStorage.setItem("p", JSON.stringify(patient))

    };



    const time = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })
    const formatDate = Moment().format('MMM DD, YYYY')

    //console.log("clinicValues ", clinicValues)

    return (
        <React.Fragment>

            <MetaTags>
                <title>PERIOPERATIVE RECORD</title>
            </MetaTags>
            <div className="theatre">

                <div className='popup_wrp'>
                    <div className="popup_full prescribe">
                        <div className='popup_hed'>
                            <h2>PERIOPERATIVE RECORD</h2>
                            <button className="border_btn" onClick={(e) => { updateClinic(e) }}><span className="material-icons">save_as</span>QUICK SAVE
                            </button>
                            <a className='popup_close' onClick={(e) => { theatreBack(e) }}><span className="material-icons">close</span></a>
                        </div>
                        <div className='popup_content'>

                            <div className='popup_scroll main pr height175 '>
                                <div className="team_brief_time">
                                    <h3>PREOP CHECKLIST BEFORE LEAVING THE WARD </h3>
                                    <div className="dsp-flex">
                                        <div className="icon_text date"><span className="material-icons"> calendar_month </span> {formatDate}</div>
                                        <div className="icon_text"><span className="material-icons"> schedule </span> {time}</div>
                                    </div>
                                </div>
                                <div className="section simple_wound">
                                    <p className="note2">Select all that apply for Multiselect forms {clinicValues.periop_check && <a onClick={(e) => updateClinicValues(e, "periop_check", false)}><span className="material-symbols-outlined"> reply
                                    </span></a>}</p>
                                    {clinicValues.periop_check && clinicValues.periop_checklist && clinicValues.periop_checklist.length > 0 ? <div className="check_list w_white mb-0">

                                        {clinicValues.periop_checklist.map((item, i) => (
                                            <div className="check_item active" key={i} onClick={(e) => updateClinicValues(e, "periop_check", false)} ><label className="check">{item}</label></div>
                                        ))}

                                    </div> :
                                        <div className="check_list w_white mb-0">
                                            <span className="check_btn2" onClick={(e) => updateClinicValues(e, "periop_check", true)}><span className="material-icons"> done </span></span>
                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "periop_checklist", "Patient identity confirmed: Wrist label worn & correct(red if there are allergies)", "periop_check")} ><label className="check">Patient identity confirmed: Wrist label worn & correct(red if there are allergies)</label></div>
                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "periop_checklist", "Surgical site checked using the patients notes and consent form", "periop_check")}><label className="check">Surgical site checked using the patients notes and consent form </label>
                                            </div>
                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "periop_checklist", "Operation site marked (site / side limb)", "periop_check")}><label className="check"> Operation site marked (site / side limb) </label></div>
                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "periop_checklist", "Consent form signed and information correct - Consent form 4? DNACPR?", "periop_check")}><label className="check">Consent form signed and information correct - Consent form 4? DNACPR?</label></div>
                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "periop_checklist", "VTE risk assessment fully completed by nurse and Surgeon", "periop_check")}><label className="check">VTE risk assessment fully completed by nurse and Surgeon </label></div>
                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "periop_checklist", "Reviewed by Anaesthetist and confirmed fit for surgery", "periop_check")}><label className="check">Reviewed by Anaesthetist and confirmed fit for surgery </label></div>
                                        </div>
                                    }

                                </div>
                                <div className="section simple_wound ctr">
                                    <h2 className="popup_title"> Patient Preparation</h2>
                                    <div className="pd_border"></div>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form_group">
                                                <div className="floating-label-group"><input type="text" name="periop_fasting" onChange={(e) => updateInputValue(e)} value={clinicValues.periop_fasting}
                                                    className="form-control" /><label
                                                        className="floating-label">Fasting</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form_group">
                                                <div className="floating-label-group"><input type="text" name="periop_food" onChange={(e) => updateInputValue(e)} value={clinicValues.periop_food}
                                                    className="form-control" /><label
                                                        className="floating-label">Food at</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form_group">
                                                <div className="floating-label-group"><input type="text" name="periop_fluid" onChange={(e) => updateInputValue(e)} value={clinicValues.periop_fluid}
                                                    className="form-control" /><label
                                                        className="floating-label">Clear fluid at</label>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                    <div className="check_item mt-10"><label className="check">Prophylactic medication given as
                                        prescribed</label>
                                        {clinicValues.periop_prophylactic ? <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input type="radio"
                                                value={clinicValues.periop_prophylactic} checked /><span>{clinicValues.periop_prophylactic} </span></label>
                                            </div> {clinicValues.periop_prophylactic && <a onClick={(e) => updateClinicValues(e, "periop_prophylactic", false)}><span className="material-symbols-outlined"> reply
                                            </span></a>}

                                        </div>
                                            :
                                            <div className="select_btns popup_select">

                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_prophylactic" type="radio" value="Analgesics" /><span>Analgesics </span></label>
                                                </div>
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_prophylactic" type="radio"
                                                    value="Premeds" /><span>Premeds</span></label></div>
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_prophylactic" type="radio"
                                                    value="Heparin" /><span>Heparin
                                                    </span></label></div>
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_prophylactic" type="radio"
                                                    value="Insulin" /><span>Insulin </span></label>
                                                </div>
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_prophylactic" type="radio"
                                                    value="IV Fluids etc" /><span>IV Fluids etc
                                                    </span></label></div>
                                            </div>}
                                    </div>
                                    <div className="yn_switch  mt-10">
                                        <div className="label-container">
                                            <p>Omit ACE inhibitors on the day. Paracetamol given on ward</p>
                                        </div>
                                        <label className="switch-container">
                                            <input type="checkbox" className="switch_check"
                                                name="periop_omit" onChange={(e) => handleChange(e)} checked={clinicValues.periop_omit} />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>YES</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>NO</span>
                                            </div>
                                        </label>


                                    </div>
                                    <div className="yn_switch  mt-10">
                                        <div className="label-container">
                                            <p>Consent gained for rectal analgesia if required</p>
                                        </div>
                                        <label className="switch-container">
                                            <input onChange={(e) => handleChange(e)} name="periop_consent_gained" type="checkbox" className="switch_check" checked={clinicValues.periop_consent_gained} />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>YES</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>NO</span>
                                            </div>
                                        </label>


                                    </div>
                                    <div className="check_item mt-10"><label className="check">Jewellery / Piercings /
                                        Metalwork</label>
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_jewellery" type="radio"
                                                value="Removed" /><span>Removed </span></label>
                                            </div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_jewellery" type="radio"
                                                value="Taped" /><span>Taped </span></label></div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_jewellery" type="radio"
                                                value="Un removable" /><span>Un removable
                                                </span></label></div>

                                        </div>
                                        <p className="note2">(Piercings to tongue/facial area must be removed by the patient
                                            prior to induction)</p>
                                    </div>
                                    <div className="yn_switch  mt-10">
                                        <div className="label-container">
                                            <p>Cosmetics / Nail varnish removed False nails present? </p>
                                        </div>
                                        <label className="switch-container">
                                            <input onChange={(e) => handleChange(e)} name="periop_cosmetics" type="checkbox" className="switch_check" checked={clinicValues.periop_cosmetics} />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>YES</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>NO</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="yn_switch  mt-10">
                                        <div className="label-container">
                                            <p>Hearing aid/ Contact lenses / glasses removed </p>
                                        </div>
                                        <label className="switch-container">
                                            <input onChange={(e) => handleChange(e)} name="periop_glasses" type="checkbox" className="switch_check" checked={clinicValues.periop_glasses} />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>YES</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>NO</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="yn_switch  mt-10">
                                        <div className="label-container">
                                            <p>Dentures removed / in situ / loose / capped / crowned teeth </p>
                                        </div>
                                        <label className="switch-container">
                                            <input onChange={(e) => handleChange(e)} name="periop_dentures" type="checkbox" className="switch_check" checked={clinicValues.periop_dentures} />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>YES</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>NO</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="yn_switch  mt-10">
                                        <div className="label-container">
                                            <p>Prosthesis: removed (if necessary)/ in situ </p>
                                        </div>
                                        <label className="switch-container">
                                            <input onChange={(e) => handleChange(e)} name="periop_prosthesis" type="checkbox" className="switch_check" checked={clinicValues.periop_prosthesis} />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>YES</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>NO</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="form_group">
                                        <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="periop_prosthesis_type" value={clinicValues.periop_prosthesis_type}
                                            className="form-control" /><label
                                                className="floating-label">Type</label>
                                        </div>
                                    </div>

                                    {clinicValues.periop_prosthesis_visit_type && clinicValues.periop_prosthesis_visit && clinicValues.periop_prosthesis_visit.length > 0 ? <div className="check_list w_white">

                                        {clinicValues.periop_prosthesis_visit.map((item, i) => (
                                            <div className="check_item active" key={i} onClick={(e) => updateClinicValues(e, "periop_prosthesis_visit_type", false)} ><label className="check">{item}</label></div>
                                        ))}

                                    </div> :

                                        <div className="check_list w_white ">

                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "periop_prosthesis_visit", "Toilet visit - Bladder emptied", "periop_prosthesis_visit_type")}><label className="check">Toilet visit - Bladder emptied</label>
                                            </div>
                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "periop_prosthesis_visit", "Theatre clothing and anti embolism stockings worn (delete if not required)", "periop_prosthesis_visit_type")}><label className="check">Theatre clothing and anti embolism stockings worn (delete if not required)</label>
                                            </div>
                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "periop_prosthesis_visit", "Walk / bed", "periop_prosthesis_visit_type")}><label className="check">Walk / bed</label>
                                            </div>

                                        </div>
                                    }
                                    <div className="row mt-30 mb-20">
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="floating-label-group"><input type="date" onChange={(e) => updateInputValue(e)} name="periop_LMP_confirmed_date"
                                                    className="form-control" /><label
                                                        className="floating-label">LMP confirmed Date</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="yn_switch  mt-10">
                                                <div className="label-container">
                                                    <p>Tampon present </p>
                                                </div>
                                                <label className="switch-container">
                                                    <input onChange={(e) => handleChange(e)} name="periop_tampon_present" type="checkbox" className="switch_check" />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>YES</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>NO</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="yn_switch  mt-10">
                                                <div className="label-container">
                                                    <p>Possibility of Pregnancy? </p>
                                                </div>
                                                <label className="switch-container">
                                                    <input onChange={(e) => handleChange(e)} name="periop_possibility_of_pregnancy" type="checkbox" className="switch_check" />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>YES</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>NO</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="yn_switch mb-10  mt-10">
                                                <div className="label-container">
                                                    <p>Pregnancy test required </p>
                                                </div>
                                                <label className="switch-container">
                                                    <input onChange={(e) => handleChange(e)} name="periop_pregnancy_test" type="checkbox" className="switch_check" />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>YES</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>NO</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <p className="note2 mb-15">If pregnancy test required, the result is negative</p>
                                        </div>
                                    </div>
                                    {clinicValues.periop_pregnancy_test_request && clinicValues.periop_pregnancy_test_status && clinicValues.periop_pregnancy_test_status.length > 0 ? <div className="check_list w_white mb-30">

                                        {clinicValues.periop_pregnancy_test_status.map((item, i) => (
                                            <div className="check_item active" key={i} onClick={(e) => updateClinicValues(e, "periop_pregnancy_test_request", false)} ><label className="check">{item}</label></div>
                                        ))}

                                    </div> :
                                        <div className="check_list w_white mb-30">
                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "periop_pregnancy_test_status", "Skin integrity intact/reddened /wound", "periop_pregnancy_test_request")}><label className="check">Skin integrity intact/reddened /wound
                                            </label>
                                            </div>
                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "periop_pregnancy_test_status", "Physiological state (eg early warning score )", "periop_pregnancy_test_request")}><label className="check">Physiological state (eg early warning score )</label>
                                            </div>
                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "periop_pregnancy_test_status", "Patient temperature has been recorded within I hour of transfer to theatre and is above 36°", "periop_pregnancy_test_request")}><label className="check">Patient temperature has been recorded within I hour of transfer to theatre and is above 36°</label>
                                            </div>
                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "periop_pregnancy_test_status", "Operation site: Swollen ankle?", "periop_pregnancy_test_request")}><label className="check">Operation site: Swollen ankle? </label>
                                            </div>

                                        </div>}

                                    <p className="note2">Select all that apply for Multiselect forms</p>
                                    {clinicValues.periop_pregnancy_AV_fistula && clinicValues.periop_pregnancy_AV_fistula_list && clinicValues.periop_pregnancy_AV_fistula_list.length > 0 ? <div className="check_list w_white mb-0">

                                        {clinicValues.periop_pregnancy_AV_fistula_list.map((item, i) => (
                                            <div className="check_item active" key={i} onClick={(e) => updateClinicValues(e, "periop_pregnancy_AV_fistula", false)} ><label className="check">{item}</label></div>
                                        ))}

                                    </div> :
                                        <div className="check_list w_white mb-0">

                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "periop_pregnancy_AV_fistula_list", "Please tick if in situ: IVI CVP/arterial Cannula NGT Catheter VIP score AV fistula left/right", "periop_pregnancy_AV_fistula")}><label className="check">Please tick if in situ: IVI CVP/arterial Cannula NGT Catheter VIP score AV fistula left/right </label>
                                            </div>

                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "periop_pregnancy_AV_fistula_list", "Drugs by skin patches Emla cream etc", "periop_pregnancy_AV_fistula")}><label className="check">Drugs by skin patches Emla cream etc</label>
                                            </div>
                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "periop_pregnancy_AV_fistula_list", "Inhalers / sprays taken to theatre", "periop_pregnancy_AV_fistula")}><label className="check">Inhalers / sprays taken to theatre</label>
                                            </div>
                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "periop_pregnancy_AV_fistula_list", "Patient property returned with ward practitioner", "periop_pregnancy_AV_fistula")}><label className="check">Patient property returned with ward practitioner</label>
                                            </div>

                                        </div>
                                    }
                                </div>
                                <div className="section simple_wound ctr">
                                    <h2 className="popup_title"> GREEN FOR GO!</h2>
                                    <div className="pd_border"></div>
                                    <div className="check_item mt-10">
                                        <label className="check">Check performed by</label>
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_greengo" type="radio" value="Doctor" /><span>Doctor </span></label>
                                            </div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_greengo" type="radio" value="Ward nurse" /><span>Ward nurse </span></label>
                                            </div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_greengo" type="radio" value="ODP" /><span>ODP</span></label></div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                          
                                            {file.periop_Sign_result || clinicValues.periop_Sign_result ? <img src={file.periop_Sign_result || clinicValues.periop_Sign_result} /> :
                                            <div className="signature_block mb-10">
                                                <SignaturePad canvasProps={{ className: "sigPad" }} ref={(ref) => { periop_Sign = ref }} />
                                                <p>Signature </p>
                                                <button onClick={(e) => trim(e, "periop_Sign", periop_Sign)}>Trim</button>
                                                <button onClick={(e) => clear(e, periop_Sign)}> Clear</button>
                                            </div>}

                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="periop_printname"
                                                    className="form-control" /><label
                                                        className="floating-label">Print name</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="field_title mt-20">If applicable, ward / admitting nurse hand over to escort
                                        practitioner / HCA </p>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form_group">
                                                <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="periop_application_ward"
                                                    className="form-control" /><label
                                                        className="floating-label">Name of Escort</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form_group">
                                                <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="periop_time_of_handover"
                                                    className="form-control" /><label
                                                        className="floating-label">Time of handover</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form_group an">
                                                <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="periop_admitting_nurse"
                                                    className="form-control" /><label
                                                        className="floating-label font-12">Name of ward/admitting nurse</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="field_title ">Ward nurse / Escort hand over to theatre practitioner and is
                                        present for theatre checks </p>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form_group">
                                                <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="periop_nurse_escort"
                                                    className="form-control" /><label
                                                        className="floating-label">Name of ward nurse/escort </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form_group">
                                                <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="periop_time_of_handover"
                                                    className="form-control" /><label
                                                        className="floating-label">Time of handover</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form_group an">
                                                <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="periop_theatre_nurse"
                                                    className="form-control" /><label
                                                        className="floating-label">Name of theatre Nurse</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>

                                <div className="section simple_wound ctr">
                                    <h2 className="popup_title">TIME IN <div className="timein"> <span className="material-icons">
                                        schedule </span> 4:30 Hrs</div>
                                    </h2>
                                    <div className="pd_border"></div>
                                    {clinicValues.periop_timein && clinicValues.periop_timein_list && clinicValues.periop_timein_list.length > 0 ? <div className="check_list w_white mb-0">

                                        {clinicValues.periop_timein_list.map((item, i) => (
                                            <div className="check_item active" key={i} onClick={(e) => updateClinicValues(e, "periop_timein", false)} ><label className="check">{item}</label></div>
                                        ))}

                                    </div> :
                                        <div className="check_list w_white mb-0">
                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "periop_timein_list", "Handover received from escort nurse", "periop_timein")} ><label className="check">Handover received from escort nurse
                                            </label>
                                            </div>
                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "periop_timein_list", "Care in Anaesthetic room explained", "periop_timein")}><label className="check">Care in Anaesthetic room
                                                explained</label>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="section simple_wound ctr">
                                    <h2 className="popup_title">Patient Details</h2>
                                    <div className="pd_border"></div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form_group">
                                                <div className="floating-label-group"><input type="text" defaultValue={clinicValues.firstname + " " + clinicValues.lastname} name="patientname"
                                                    className="form-control" /><label
                                                        className="floating-label">Patient Name </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form_group">
                                                <div className="floating-label-group"><input type="text" defaultValue={clinicValues.dob} name="patientname"
                                                    className="form-control" /><label
                                                        className="floating-label">DOB</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form_group an">
                                                <div className="floating-label-group"><input type="text" defaultValue={clinicValues.sex} name="patientname"
                                                    className="form-control" /><label
                                                        className="floating-label">SEX</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form_group an">
                                                <div className="floating-label-group"><input type="text" defaultValue={clinicValues.hospitalNo} name="patientname"
                                                    className="form-control" /><label
                                                        className="floating-label">NO</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="section simple_wound ctr">
                                    <h2 className="popup_title">Perioperative Record</h2>
                                    <div className="pd_border"></div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="periop_surgeon"
                                                    className="form-control" /><label
                                                        className="floating-label">SURGEON </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="periop_first_asst"
                                                    className="form-control" /><label
                                                        className="floating-label">FIRST ASSISTANT</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group an">
                                                <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="periop_anaesthetist"
                                                    className="form-control" /><label
                                                        className="floating-label">ANAESTHETIST</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group an">
                                                <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="periop_odp"
                                                    className="form-control" /><label
                                                        className="floating-label">ODP</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group an">
                                                <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="periop_scrub"
                                                    className="form-control" /><label
                                                        className="floating-label">SCRUB</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group an">
                                                <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="periop_circulating"
                                                    className="form-control" /><label
                                                        className="floating-label">CIRCULATING</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form_group an">
                                                <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="periop_procedure"
                                                    className="form-control" /><label
                                                        className="floating-label">PROCEDURE</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="check_item">
                                                <div className="select_btns popup_select">
                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_elective" type="radio"
                                                        value="ELECTIVE" /><span>ELECTIVE </span></label>
                                                    </div>
                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_elective" type="radio"
                                                        value="TRAUMA" /><span>TRAUMA </span></label>
                                                    </div>
                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_elective" type="radio"
                                                        value="ADD ON" /><span>ADD ON</span></label></div>
                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_elective" type="radio"
                                                        value="EMERGENCY" /><span>EMERGENCY</span></label></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group an">
                                                <div className="floating-label-group"><input type="text" onClick={(e) => updateInputValue(e)} name="periop_opcs_code"
                                                    className="form-control" /><label
                                                        className="floating-label">OPCS CODE</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group an">
                                                <div className="floating-label-group"><input type="text" onClick={(e) => updateInputValue(e)} name="periop_opcs_desc"
                                                    className="form-control" /><label
                                                        className="floating-label">OPCS DESCRIPTION</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group an">
                                                <div className="floating-label-group"><input type="text" onClick={(e) => updateInputValue(e)} name="periop_icd"
                                                    className="form-control" /><label
                                                        className="floating-label">ICD 11 CODE</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group an">
                                                <div className="floating-label-group"><input type="text" onClick={(e) => updateInputValue(e)} name="periop_icd_desc"
                                                    className="form-control" /><label
                                                        className="floating-label">ICD 11DESCRIPTION</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="section simple_wound ctr">
                                    <h2 className="popup_title">CASE TEAM</h2>
                                    <div className="pd_border"></div>
                                    <div className="search_dropdown_blk">
                                        <div className="form_group">
                                            <div className="floating-label-group">
                                                <div className="">
                                                    <select className="form-control" name="periop_operating_surgeon" onChange={(e) => updateInputValue(e)}>
                                                        <option value="1">Select</option>
                                                        <option value="2">Select3</option>
                                                        <option value="3">Select3</option>
                                                        <option value="4">Select4</option>
                                                        <option value="5">Select5</option>
                                                    </select>
                                                </div>
                                                <label className="floating-label">Operating Surgeon</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="search_dropdown_blk">
                                        <div className="form_group">
                                            <div className="floating-label-group">
                                                <div className="">
                                                    <select className="form-control" name="periop_surgical_assistant" onChange={(e) => updateInputValue(e)}>
                                                        <option value="1">Select</option>
                                                        <option value="2">Select3</option>
                                                        <option value="3">Select3</option>
                                                        <option value="4">Select4</option>
                                                        <option value="5">Select5</option>
                                                    </select>
                                                </div>
                                                <label className="floating-label">Surgical Assistant</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="search_dropdown_blk">
                                        <div className="form_group">
                                            <div className="floating-label-group">
                                                <div className="">
                                                    <select className="form-control" name="periop_anaesthetist" onChange={(e) => updateInputValue(e)}>
                                                        <option value="1">Select</option>
                                                        <option value="2">Select3</option>
                                                        <option value="3">Select3</option>
                                                        <option value="4">Select4</option>
                                                        <option value="5">Select5</option>
                                                    </select>
                                                </div>
                                                <label className="floating-label">Anaesthetist</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="search_dropdown_blk">
                                        <div className="form_group">
                                            <div className="floating-label-group">
                                                <div className="">
                                                    <select className="form-control" name="periop_operating_practitioner" onChange={(e) => updateInputValue(e)}>
                                                        <option value="1">Select</option>
                                                        <option value="2">Select3</option>
                                                        <option value="3">Select3</option>
                                                        <option value="4">Select4</option>
                                                        <option value="5">Select5</option>
                                                    </select>
                                                </div>
                                                <label className="floating-label">Operating dept. Practitioner</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="search_dropdown_blk">
                                        <div className="form_group">
                                            <div className="floating-label-group">
                                                <div className="">
                                                    <select className="form-control" name="periop_scrub_nurse" onChange={(e) => updateInputValue(e)}>
                                                        <option value="1">Select</option>
                                                        <option value="2">Select3</option>
                                                        <option value="3">Select3</option>
                                                        <option value="4">Select4</option>
                                                        <option value="5">Select5</option>
                                                    </select>
                                                </div>
                                                <label className="floating-label">Scrub Nurse</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="search_dropdown_blk">
                                        <div className="form_group">
                                            <div className="floating-label-group">
                                                <div className="">
                                                    <select className="form-control" name="periop_circulating Nurse" onChange={(e) => updateInputValue(e)}>
                                                        <option value="1">Select</option>
                                                        <option value="2">Select3</option>
                                                        <option value="3">Select3</option>
                                                        <option value="4">Select4</option>
                                                        <option value="5">Select5</option>
                                                    </select>
                                                </div>
                                                <label className="floating-label">Circulating Nurse</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form_group mb-20">
                                        <textarea className="textarea" placeholder="Other" name="periop_case_team_other" onChange={(e) => updateInputValue(e)} rows="2"
                                            cols="30"></textarea>
                                    </div>
                                </div>
                                <div className="section simple_wound ctr">
                                    <h2 className="popup_title">ODP Check in</h2>
                                    <div className="pd_border"></div>
                                    <p className="field_title bold mt-0">Patient has confirmed </p>
                                    <p className="note2">Select all that apply for Multiselect forms</p>

                                    {clinicValues.periop_patient_confirm && clinicValues.periop_patient_confirmlist && clinicValues.periop_patient_confirmlist.length > 0 ? <div className="check_list w_white mb-0">

                                        {clinicValues.periop_patient_confirmlist.map((item, i) => (
                                            <div className="check_item active" key={i} onClick={(e) => updateClinicValues(e, "periop_patient_confirm", false)} ><label className="check">{item}</label></div>
                                        ))}

                                    </div> :
                                        <div className="check_list w_white mb-0">

                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "periop_patient_confirmlist", "Please select if in situ: IVI CVP/arterial Cannula NGT Catheter VIP score AV fistula left/right", "periop_patient_confirm")}><label className="check">Please select if in situ: IVI
                                                CVP/arterial Cannula NGT Catheter VIP score AV fistula left/right </label>
                                            </div>
                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "periop_patient_confirmlist", "Name - Matches ID band and consent form", "periop_patient_confirm")}><label className="check">Name - Matches ID band and consent form</label>
                                            </div>
                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "periop_patient_confirmlist", "Date of birth - Matches ID band and consent form", "periop_patient_confirm")}><label className="check">Date of birth - Matches ID band and consent form</label>
                                            </div>
                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "periop_patient_confirmlist", "What Procedure patient is having - Matches operation list and consent form", "periop_patient_confirm")}><label className="check">What Procedure patient is having - Matches operation list and consent form</label>
                                            </div>
                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "periop_patient_confirmlist", "Op. site is marked: Matches Consent form", "periop_patient_confirm")}><label className="check">Op. site is marked: Matches Consent form</label>
                                            </div>
                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "periop_patient_confirmlist", "Signature on the consent form", "periop_patient_confirm")}><label className="check">Signature on the consent form</label>
                                            </div>
                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "periop_patient_confirmlist", "Consent verified for: Surgery - Anaesthesia", "periop_patient_confirm")}><label className="check">Consent verified for: Surgery -
                                                Anaesthesia</label>
                                            </div>
                                        </div>
                                    }
                                    <div className="check_item mt-10">
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_patient_consent" type="radio"
                                                value="Patient consents to blood-blood products" /><span>Patient consents to blood-blood products
                                                </span></label>
                                            </div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_patient_consent" type="radio"
                                                value="Patient refused blood-blood products" /><span>Patient refused blood-blood products
                                                </span></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="check_item mt-0">
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_patient_allergy" type="radio"
                                                value="Patient has No allergies" /><span>Patient has No allergies
                                                </span></label>
                                            </div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_patient_allergy" type="radio"
                                                value="Patient is allergic to" /><span>Patient is allergic to
                                                </span></label></div>
                                        </div>
                                    </div>
                                    <div className="check_item mt-0">
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_patient_metal_implants" type="radio"
                                                value="Patient has No metal implants in the body" /><span>Patient has No metal implants in the body
                                                </span></label>
                                            </div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_patient_metal_implants" type="radio"
                                                value="Patient has metal implants in the body" /><span>Patient has metal implants in the body
                                                </span></label></div>
                                        </div>
                                    </div>
                                    <p className="note2">Select all that apply for Multiselect forms</p>


                                    {clinicValues.periop_relevant && clinicValues.periop_relevantlist && clinicValues.periop_relevantlist.length > 0 ? <div className="check_list w_white mb-0">

                                        {clinicValues.periop_relevantlist.map((item, i) => (
                                            <div className="check_item active" key={i} onClick={(e) => updateClinicValues(e, "periop_relevant", false)} ><label className="check">{item}</label></div>
                                        ))}

                                    </div> :
                                        <div className="check_list w_white mb-0">

                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "periop_relevantlist", "Relevant diagnostic reports available", "periop_relevant")}><label className="check">Relevant diagnostic reports available</label>
                                            </div>
                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "periop_relevantlist", "MRSA screening completed", "periop_relevant")} ><label className="check">MRSA screening completed</label>
                                            </div>
                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "periop_relevantlist", "VTE risk assessment completed", "periop_relevant")}><label className="check">VTE risk assessment completed</label>
                                            </div>
                                        </div>}


                                    <div className="check_item mt-10">
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_patient_pregnancy" type="radio"
                                                value="Patient confirmed Pregnancy test consent" /><span>Patient confirmed Pregnancy test consent
                                                </span></label>
                                            </div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_patient_pregnancy" type="radio"
                                                value="Patient refused Pregnancy test" /><span>Patient refused Pregnancy test
                                                </span></label></div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_patient_pregnancy" type="radio"
                                                value="Pregnancy test Not Applicable" /><span>Pregnancy test Not Applicable
                                                </span></label></div>
                                        </div>
                                    </div>

                                    {clinicValues.periop_guradian && clinicValues.periop_guradianlist && clinicValues.periop_guradianlist.length > 0 ? <div className="check_list w_white mb-0">

                                        {clinicValues.periop_guradianlist.map((item, i) => (
                                            <div className="check_item active" key={i} onClick={(e) => updateClinicValues(e, "periop_guradian", false)} ><label className="check">{item}</label></div>
                                        ))}

                                    </div> :
                                        <div className="check_list w_white mb-0">
                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "periop_guradianlist", "Signed dated and timed by patient or guardian", "periop_guradian")}><label className="check">Signed dated and timed by patient or guardian</label>
                                            </div>
                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "periop_guradianlist", "Witnessed, dated and timed", "periop_guradian")}><label className="check">Witnessed, dated and timed</label>
                                            </div>

                                        </div>}

                                    <div className="check_item mt-10">
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_patient_glucose" type="radio"
                                                value="Patient does not require glucose monitoring" /><span>Patient does not require glucose monitoring
                                                </span></label>
                                            </div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_patient_glucose" type="radio"
                                                value="Patient requires glucose monitoring" /><span>Patient requires glucose monitoring
                                                </span></label></div>

                                        </div>
                                    </div>
                                    <div className="check_item">
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_patient_warming" type="radio"
                                                value="Patient require warming" /><span>Patient require warming
                                                </span></label>
                                            </div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_patient_warming" type="radio"
                                                value="Patient does not require warming" /><span>Patient does not require warming
                                                </span></label></div>

                                        </div>
                                    </div>
                                    {clinicValues.periop_theatre_checklist ? <div className="check_list w_white mb-20">


                                        <div className="check_item active" onClick={(e) => updateClinicValues(e, "periop_theatre_checklist", false)} ><label className="check">{clinicValues.periop_theatre_checklist}</label></div>


                                    </div> :
                                        <div className="check_list w_white mb-20">
                                            <div className="check_item" onClick={(e) => updateClinicValues(e, "periop_theatre_checklist", "Theatre checklist completed")}><label className="check">Theatre checklist completed</label>
                                            </div>
                                        </div>
                                    }
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="periop_registered_practitioner"
                                                    className="form-control" /><label
                                                        className="floating-label">Registered Practitioner - Print name</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            
                                            {file.periop_registered_practitioner_Sign_result || clinicValues.periop_registered_practitioner_Sign_result ? <img src={file.periop_registered_practitioner_Sign_result || clinicValues.periop_registered_practitioner_Sign_result} /> :
                                            <div className="signature_block mb-10">
                                                <SignaturePad canvasProps={{ className: "sigPad" }} ref={(ref) => { periop_registered_practitioner_Sign = ref }} />
                                                <p>Signature </p>
                                                <button onClick={(e) => trim(e, "periop_registered_practitioner_Sign", periop_registered_practitioner_Sign)}>Trim</button>
                                                <button onClick={(e) => clear(e, periop_registered_practitioner_Sign)}> Clear</button>
                                            </div>}
                                        </div>
                                    </div>
                                    <p className="field_title">Fasting</p>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="periop_food_at"
                                                    className="form-control" /><label
                                                        className="floating-label">Food at</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="periop_fluid_at"
                                                    className="form-control" /><label
                                                        className="floating-label">Clear fluid at</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="check_item mt-10"><label className="check">Prophylactic medication given as
                                        prescribed</label>
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_prophylactic" type="radio" value="Analgesics" /><span>Analgesics </span></label>
                                            </div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_prophylactic" type="radio"
                                                value="Premeds" /><span>Premeds </span></label></div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_prophylactic" type="radio"
                                                value="Heparin" /><span>Heparin
                                                </span></label></div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_prophylactic" type="radio"
                                                value="Insulin" /><span>Insulin </span></label>
                                            </div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_prophylactic" type="radio"
                                                value="IV Fluids etc" /><span>IV Fluids etc
                                                </span></label></div>
                                        </div>
                                    </div>
                                    <div className="yn_switch  mt-10">
                                        <div className="label-container">
                                            <p>Omit ACE inhibitors on the day. Paracetamol given on ward</p>
                                        </div>
                                        <label className="switch-container">

                                            <input onChange={(e) => handleChange(e)} name="periop_Omit_ACE_inhibitors" type="checkbox" className="switch_check" defaultChecked={clinicValues.periop_Omit_ACE_inhibitors} />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>YES</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>NO</span>
                                            </div>
                                        </label>

                                    </div>
                                    <div className="yn_switch  mt-10">
                                        <div className="label-container">
                                            <p>Consent gained for rectal analgesia if required</p>
                                        </div>
                                        <label className="switch-container">
                                            <input onChange={(e) => handleChange(e)} name="periop_Consent_gained" type="checkbox" className="switch_check" defaultChecked={clinicValues.periop_Consent_gained} />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>YES</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>NO</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="check_item mt-10"><label className="check">Jewellery / Piercings /
                                        Metalwork</label>
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input onClick={(e) => handleChange(e)} name="periop_Jewellery" type="radio"
                                                value="Removed" /><span>Removed </span></label>
                                            </div>
                                            <div className="select_btn"><label><input onClick={(e) => handleChange(e)} name="periop_Jewellery" type="radio"
                                                value="Taped" /><span>Taped </span></label></div>
                                            <div className="select_btn"><label><input onClick={(e) => handleChange(e)} name="periop_Jewellery" type="radio"
                                                value="Un removable" /><span>Un removable
                                                </span></label></div>

                                        </div>
                                        <p className="note2">(Piercings to tongue/facial area must be removed by the patient
                                            prior to induction)</p>
                                    </div>
                                    <div className="yn_switch  mt-10">
                                        <div className="label-container">
                                            <p>Cosmetics / Nail varnish removed False nails present? </p>
                                        </div>
                                        <label className="switch-container">
                                            <input onChange={(e) => handleChange(e)} name="periop_Cosmetics" type="checkbox" className="switch_check" defaultChecked={clinicValues.periop_Cosmetics} />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>YES</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>NO</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="yn_switch  mt-10">
                                        <div className="label-container">
                                            <p>Hearing aid/ Contact lenses / glasses removed </p>
                                        </div>
                                        <label className="switch-container">
                                            <input onChange={(e) => handleChange(e)} name="periop_Hearing_aid" type="checkbox" className="switch_check" defaultChecked={clinicValues.periop_Hearing_aid} />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>YES</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>NO</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="yn_switch  mt-10">
                                        <div className="label-container">
                                            <p>Dentures removed / in situ / loose / capped / crowned teeth </p>
                                        </div>
                                        <label className="switch-container">
                                            <input onChange={(e) => handleChange(e)} name="periop_Dentures" type="checkbox" className="switch_check" defaultChecked={clinicValues.periop_Dentures} />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>YES</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>NO</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="yn_switch  mt-10">
                                        <div className="label-container">
                                            <p>Prosthesis: removed (if necessary)/ in situ </p>
                                        </div>
                                        <label className="switch-container">
                                            <input onChange={(e) => handleChange(e)} name="periop_Prosthesis" type="checkbox" className="switch_check" defaultChecked={clinicValues.periop_Prosthesis} />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>YES</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>NO</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="form_group">
                                        <div className="floating-label-group"><input type="text" onChange={(e) => handleChange(e)} name="periop_ODP_Type"
                                            className="form-control" /><label
                                                className="floating-label">Type</label>
                                        </div>
                                    </div>

                                    {clinicValues.periop_toilet && clinicValues.periop_toiletlist && clinicValues.periop_toiletlist.length > 0 ? <div className="check_list w_white mb-0">

                                        {clinicValues.periop_toiletlist.map((item, i) => (
                                            <div className="check_item active" key={i} onClick={(e) => updateClinicValues(e, "periop_toilet", false)} ><label className="check">{item}</label></div>
                                        ))}

                                    </div> :
                                        <div className="check_list w_white mt-30">
                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "periop_toiletlist", "Toilet visit - Bladder emptied", "periop_toilet")}><label className="check">Toilet visit - Bladder emptied</label>
                                            </div>
                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "periop_toiletlist", "Theatre clothing and anti embolism stockings worn (delete if not required)", "periop_toilet")}><label className="check">Theatre clothing and anti embolism stockings worn (delete if not required)</label>
                                            </div>
                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "periop_toiletlist", "Walk / bed", "periop_toilet")}><label className="check">Walk / bed</label>
                                            </div>

                                        </div>}

                                    <div className="row mt-30 mb-20">
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="floating-label-group"><input type="date" onChange={(e) => handleChange(e)} name="periop_LMP_confirmed_Date"
                                                    className="form-control" /><label
                                                        className="floating-label">LMP confirmed Date</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="yn_switch  mt-10">
                                                <div className="label-container">
                                                    <p>Tampon present </p>
                                                </div>
                                                <label className="switch-container">
                                                    <input onChange={(e) => handleChange(e)} name="periop_Tampon_present" type="checkbox" className="switch_check" defaultChecked={clinicValues.periop_Tampon_present} />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>YES</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>NO</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="yn_switch  mt-10">
                                                <div className="label-container">
                                                    <p>Possibility of Pregnancy? </p>
                                                </div>
                                                <label className="switch-container">
                                                    <input onChange={(e) => handleChange(e)} name="periop_Possibility_of_Pregnancy" type="checkbox" className="switch_check" defaultChecked={clinicValues.periop_Possibility_of_Pregnancy} />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>YES</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>NO</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="yn_switch mb-10  mt-10">
                                                <div className="label-container">
                                                    <p>Pregnancy test required </p>
                                                </div>
                                                <label className="switch-container">
                                                    <input onChange={(e) => handleChange(e)} name="periop_Pregnancy_test_required" type="checkbox" className="switch_check" defaultChecked={clinicValues.periop_Pregnancy_test_required} />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>YES</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>NO</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <p className="note2 mb-15">If pregnancy test required, the result is negative</p>
                                        </div>
                                    </div>

                                    {clinicValues.periop_pregnancy_check && clinicValues.periop_pregnancy_list && clinicValues.periop_pregnancy_list.length > 0 ? <div className="check_list w_white mb-0">

                                        {clinicValues.periop_pregnancy_list.map((item, i) => (
                                            <div className="check_item active" key={i} onClick={(e) => updateClinicValues(e, "periop_pregnancy_check", false)} ><label className="check">{item}</label></div>
                                        ))}

                                    </div> :
                                        <div className="check_list w_white mt-10">
                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "periop_pregnancy_list", "Please tick if in situ: IVI  CVP/arterial Cannula NGT Catheter VIP score AV fistula left/right", "periop_pregnancy_check")}><label className="check">Please tick if in situ: IVI  CVP/arterial Cannula NGT Catheter VIP score AV fistula left/right</label>
                                            </div>
                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "periop_pregnancy_list", "Drugs by skin patches Emla cream etc", "periop_pregnancy_check")}><label className="check">Drugs by skin patches Emla cream etc</label>
                                            </div>
                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "periop_pregnancy_list", "Inhalers / sprays taken to theatre", "periop_pregnancy_check")}><label className="check">Inhalers / sprays taken to theatre</label>
                                            </div>


                                        </div>}

                                </div>
                                <div className="section simple_wound ctr">
                                    <h2 className="popup_title">PATIENT MONITORING</h2>
                                    <div className="pd_border"></div>
                                    <p className="note2">Select all that apply for Multiselect forms</p>

                                    {clinicValues.periop_patient_montoring_check && clinicValues.periop_patient_montoring_list && clinicValues.periop_patient_montoring_list.length > 0 ? <div className="check_list w_white mb-10">

                                        {clinicValues.periop_patient_montoring_list.map((item, i) => (
                                            <div className="check_item active" key={i} onClick={(e) => updateClinicValues(e, "periop_patient_montoring_check", false)} ><label className="check">{item}</label></div>
                                        ))}

                                    </div> :
                                        <div className="check_list w_white mt-10">

                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "periop_patient_montoring_list", "Peripheral line", "periop_patient_montoring_check")}><label className="check">Peripheral line</label>
                                            </div>
                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "periop_patient_montoring_list", "ECG", "periop_patient_montoring_check")}><label className="check">ECG</label>
                                            </div>
                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "periop_patient_montoring_list", "Blood pressure", "periop_patient_montoring_check")}><label className="check">Blood pressure </label>
                                            </div>
                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "periop_patient_montoring_list", "Sp02", "periop_patient_montoring_check")}><label className="check">Sp02</label>
                                            </div>
                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "periop_patient_montoring_list", "CVP", "periop_patient_montoring_check")}><label className="check">CVP</label>
                                            </div>
                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "periop_patient_montoring_list", "Temperature", "periop_patient_montoring_check")}><label className="check">Temperature</label>
                                            </div>
                                        </div>
                                    }

                                </div>
                                <div className="section simple_wound ctr">
                                    <h2 className="popup_title">IV LINES AND CANNULAE</h2>
                                    <div className="pd_border"></div>

                                    {clinicValues.periop_IV_LINES_check && clinicValues.periop_IV_LINES_list && clinicValues.periop_IV_LINES_list.length > 0 ? <div className="check_list w_white mb-10">

                                        {clinicValues.periop_IV_LINES_list.map((item, i) => (
                                            <div className="check_item active" key={i} onClick={(e) => updateClinicValues(e, "periop_IV_LINES_check", false)} ><label className="check">{item}</label></div>
                                        ))}

                                    </div> :
                                        <div className="check_list w_white mt-10">
                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "periop_IV_LINES_list", "IV lines and cannulae flushed", "periop_IV_LINES_check")}><label className="check">IV lines and cannulae flushed</label>
                                            </div>
                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "periop_IV_LINES_list", "Multi lumen catheters clamped and occluded", "periop_IV_LINES_check")}><label className="check">Multi lumen catheters clamped and occluded</label>
                                            </div>
                                        </div>
                                    }

                                </div>
                                <div className="section simple_wound ctr">
                                    <h2 className="popup_title">URINARY CATHETER </h2>
                                    <div className="pd_border"></div>
                                    <div className="yn_switch mb-10  mt-10">
                                        <div className="label-container">
                                            <p>Suprapubic or urethral Used?
                                            </p>
                                        </div>
                                        <label className="switch-container">
                                            <input onChange={(e) => handleChange(e)} name="periop_urethral" type="checkbox" className="switch_check" defaultChecked={clinicValues.periop_urethral} />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>YES</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>NO</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="yn_switch mb-10  mt-20">
                                        <div className="label-container">
                                            <p>then catheter pathway commenced
                                            </p>
                                        </div>
                                        <label className="switch-container">
                                            <input onChange={(e) => handleChange(e)} name="periop_catheter" type="checkbox" className="switch_check" defaultChecked={clinicValues.periop_catheter} />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>YES</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>NO</span>
                                            </div>
                                        </label>
                                    </div>
                                    <p className="note2 mb-15">Intermittent catheter use only (LoFric)</p>
                                    <div className="yn_switch mb-10  mt-20">
                                        <div className="label-container">
                                            <p>If suprapubic, sutured in place?
                                            </p>
                                        </div>
                                        <label className="switch-container">
                                            <input onChange={(e) => handleChange(e)} name="periop_Intermittent" type="checkbox" className="switch_check" defaultChecked={clinicValues.periop_Intermittent} />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>YES</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>NO</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="row mt-20">

                                        <div className="col-md-4">
                                            <div className="form_group">
                                                <div className="floating-label-group"><input type="text" onChange={(e) => handleChange(e)} name="periop_lot_no"
                                                    className="form-control" /><label
                                                        className="floating-label">Lot no</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form_group an">
                                                <div className="floating-label-group"><input type="text" onChange={(e) => handleChange(e)} name="periop_size"
                                                    className="form-control" /><label
                                                        className="floating-label">Size</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form_group an">
                                                <div className="floating-label-group"><input type="date" onChange={(e) => handleChange(e)} name="periop_expiry_Date"
                                                    className="form-control" /><label
                                                        className="floating-label">Expiry date</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="section simple_wound ctr">
                                    <h2 className="popup_title">TIME OUT </h2>
                                    <div className="pd_border"></div>
                                    <p className="field_title bold mt-0">Surgeon - Anaesthetist and Nurse verbally confirm</p>
                                    <p className="note2">Select all that apply for Multiselect forms</p>

                                    {clinicValues.periop_Anaesthetist && clinicValues.periop_Anaesthetist_list && clinicValues.periop_Anaesthetist_list.length > 0 ? <div className="check_list w_white mb-0">

                                        {clinicValues.periop_Anaesthetist_list.map((item, i) => (
                                            <div className="check_item active" key={i} onClick={(e) => updateClinicValues(e, "periop_Anaesthetist", false)} ><label className="check">{item}</label></div>
                                        ))}

                                    </div> :
                                        <div className="check_list w_white mb-0">

                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "periop_Anaesthetist_list", "Patient ID band matches details on consent form", "periop_Anaesthetist")}><label className="check">Patient ID band matches details on consent form </label>
                                            </div>
                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "periop_Anaesthetist_list", "Procedure on op list matches consent form", "periop_Anaesthetist")}><label className="check">Procedure on op list matches consent form</label>
                                            </div>
                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "periop_Anaesthetist_list", "Site on consent form matches skin mark & imaging", "periop_Anaesthetist")}><label className="check">Site on consent form matches skin mark & imaging</label>
                                            </div>
                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "periop_Anaesthetist_list", "Consent verified", "periop_Anaesthetist")}><label className="check">Consent verified</label>
                                            </div>
                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "periop_Anaesthetist_list", "Allergies identified", "periop_Anaesthetist")}><label className="check">Allergies identified </label>
                                            </div>
                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "periop_Anaesthetist_list", "Patient positioned safely", "periop_Anaesthetist")}><label className="check">Patient positioned safely </label>
                                            </div>
                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "periop_Anaesthetist_list", "Diathermy plate in place away from Implants", "periop_Anaesthetist")}><label className="check">Diathermy plate in place away from Implants</label>
                                            </div>
                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "periop_Anaesthetist_list", "Essential imaging displayed", "periop_Anaesthetist")}><label className="check">Essential imaging displayed</label>
                                            </div>
                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "periop_Anaesthetist_list", "Implant size checked correct", "periop_Anaesthetist")}><label className="check">Implant size checked correct</label>
                                            </div>
                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "periop_Anaesthetist_list", "VTE prophylaxis been undertaken - Anticoagulant prescribed", "periop_Anaesthetist")}><label className="check">VTE prophylaxis been undertaken - Anticoagulant prescribed </label>
                                            </div>
                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "periop_Anaesthetist_list", "TEDS-flowtrons on", "periop_Anaesthetist")}><label className="check">TEDS-flowtrons on</label>
                                            </div>
                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "periop_Anaesthetist_list", "Throat pack in-situ", "periop_Anaesthetist")}><label className="check">Throat pack in-situ</label>
                                            </div>
                                        </div>
                                    }
                                    <p className="field_title bold mt-30">Surgical site infection (SSI) bundle</p>
                                    <p className="note2">Select all that apply for Multiselect forms</p>
                                    {clinicValues.periop_site_infection && clinicValues.periop_site_infection_list && clinicValues.periop_site_infection_list.length > 0 ? <div className="check_list w_white mb-0">

                                        {clinicValues.periop_site_infection_list.map((item, i) => (
                                            <div className="check_item active" key={i} onClick={(e) => updateClinicValues(e, "periop_site_infection", false)} ><label className="check">{item}</label></div>
                                        ))}

                                    </div> :
                                        <div className="check_list w_white mb-0">

                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "periop_site_infection_list", "Antibiotic prophylaxis given within the last 60 minutes", "periop_site_infection")}><label className="check">Antibiotic prophylaxis given within the last 60 minutes </label>
                                            </div>
                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "periop_site_infection_list", "Patient warming on", "periop_site_infection")}><label className="check">Patient warming on</label>
                                            </div>
                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "periop_site_infection_list", "Glycaemic control done", "periop_site_infection")}><label className="check">Hair removal done</label>
                                            </div>
                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "periop_site_infection_list", "Antibiotic prophylaxis given within the last 60 minutes", "periop_site_infection")}><label className="check">Glycaemic control done</label>
                                            </div>
                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "periop_site_infection_list", "Does anyone have any other questions or concerns before proceeding", "periop_site_infection")}><label className="check">Does anyone have any other questions or concerns before proceeding </label>
                                            </div>

                                        </div>
                                    }
                                    <div className="row mt-20">
                                        <div className="col-md-6">
                                           
                                            {file.periop_site_Sign_result || clinicValues.periop_site_Sign_result ? <img src={file.periop_site_Sign_result || clinicValues.periop_site_Sign_result} /> :
                                            <div className="signature_block mb-10">
                                                <SignaturePad canvasProps={{ className: "sigPad" }} ref={(ref) => { periop_site_Sign = ref }} />
                                                <p>Signature </p>
                                                <button onClick={(e) => trim(e, "periop_site_Sign", periop_site_Sign)}>Trim</button>
                                                <button onClick={(e) => clear(e, periop_site_Sign)}> Clear</button>
                                            </div>}
                                        </div>
                                    </div>

                                </div>
                                <div className="section simple_wound ctr">
                                    <h2 className="popup_title">POSITION OF PATIENT </h2>
                                    <div className="pd_border"></div>
                                    <div className="check_item mt-0">
                                        <div className="select_btns popup_select mt-0">
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_position_patient" type="radio"
                                                value="Supine" /><span>Supine </span></label>
                                            </div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_position_patient" type="radio"
                                                value="Left lateral" /><span>Left lateral </span></label></div>
                                            <div className="select_btn">
                                                <label><input onClick={(e) => updateInputValue(e)} name="periop_position_patient" type="radio" value="Right lateral" /><span>Right lateral
                                                </span>
                                                </label>
                                            </div>
                                            <div className="select_btn">
                                                <label><input onClick={(e) => updateInputValue(e)} name="periop_position_patient" type="radio" value="Prone" /><span>Prone </span>
                                                </label>
                                            </div>
                                            <div className="select_btn">
                                                <label><input onClick={(e) => updateInputValue(e)} name="periop_position_patient" type="radio" value="Knee" /><span>Knee </span>
                                                </label>
                                            </div>
                                            <div className="select_btn">
                                                <label><input onClick={(e) => updateInputValue(e)} name="periop_position_patient" type="radio" value="chest" /><span>chest </span>
                                                </label>
                                            </div>
                                            <div className="select_btn">
                                                <label><input onClick={(e) => updateInputValue(e)} name="periop_position_patient" type="radio" value="Sitting Beach Chair" /><span>Sitting Beach
                                                    Chair </span>
                                                </label>
                                            </div>
                                            <div className="select_btn">
                                                <label><input onClick={(e) => updateInputValue(e)} name="periop_position_patient" type="radio" value="Lloyd Davies" /><span>Lloyd Davies
                                                </span>
                                                </label>
                                            </div>
                                            <div className="select_btn">
                                                <label><input onClick={(e) => updateInputValue(e)} name="periop_position_patient" type="radio" value="Lithotomy" /><span>Lithotomy
                                                </span>
                                                </label>
                                            </div>
                                            <div className="select_btn">
                                                <label><input onClick={(e) => updateInputValue(e)} name="periop_position_patient" type="radio" value="Trendelenberg" /><span>Trendelenberg
                                                </span>
                                                </label>
                                            </div>
                                            <div className="select_btn">
                                                <label><input onClick={(e) => updateInputValue(e)} name="periop_position_patient" type="radio" value="Other" /><span>Other </span>
                                                </label>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                                <div className="section simple_wound ctr">
                                    <h2 className="popup_title">POSITIONING DEVICES UTILISED </h2>
                                    <div className="pd_border"></div>
                                    <div className="check_item mt-0">
                                        <div className="select_btns popup_select mt-0">
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_position_devices" type="radio"
                                                value="Fracture table" /><span>Fracture table </span></label>
                                            </div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_position_devices" type="radio"
                                                value="Normal table" /><span>Normal table </span></label></div>
                                            <div className="select_btn">
                                                <label><input onClick={(e) => updateInputValue(e)} name="periop_position_devices" type="radio" value="Hand table" /><span>Hand table
                                                </span>
                                                </label>
                                            </div>
                                            <div className="select_btn">
                                                <label><input onClick={(e) => updateInputValue(e)} name="periop_position_devices" type="radio" value="Arm board Pillows" /><span>Arm board Pillows </span>
                                                </label>
                                            </div>
                                            <div className="select_btn">
                                                <label><input onClick={(e) => updateInputValue(e)} name="periop_position_devices" type="radio" value="Head ring" /><span>Head ring
                                                </span>
                                                </label>
                                            </div>
                                            <div className="select_btn">
                                                <label><input onClick={(e) => updateInputValue(e)} name="periop_position_devices" type="radio" value="Arm supports" /><span>Arm supports
                                                </span>
                                                </label>
                                            </div>
                                            <div className="select_btn">
                                                <label><input onClick={(e) => updateInputValue(e)} name="periop_position_devices" type="radio" value="Lateral thigh support" /><span>Lateral thigh support </span>
                                                </label>
                                            </div>
                                            <div className="select_btn">
                                                <label><input onClick={(e) => updateInputValue(e)} name="periop_position_devices" type="radio" value="Side supports for lateral positioning Lumbar support" /><span>Side supports for lateral positioning Lumbar support
                                                </span>
                                                </label>
                                            </div>
                                            <div className="select_btn">
                                                <label><input onClick={(e) => updateInputValue(e)} name="periop_position_devices" type="radio" value="Pelvic support" /><span>Pelvic support
                                                </span>
                                                </label>
                                            </div>
                                            <div className="select_btn">
                                                <label><input onClick={(e) => updateInputValue(e)} name="periop_position_devices" type="radio" value="Sand bags" /><span>Sand bags
                                                </span>
                                                </label>
                                            </div>
                                            <div className="select_btn">
                                                <label><input onClick={(e) => updateInputValue(e)} name="periop_position_devices" type="radio" value="Body Strap Gamgee" /><span>Body Strap Gamgee </span>
                                                </label>
                                            </div>
                                            <div className="select_btn">
                                                <label><input onClick={(e) => updateInputValue(e)} name="periop_position_devices" type="radio" value="Gel pads " /><span>Gel pads </span>
                                                </label>
                                            </div>
                                            <div className="select_btn">
                                                <label><input onClick={(e) => updateInputValue(e)} name="periop_position_devices" type="radio" value="Other Surgical knee holder" /><span>Other Surgical knee holder</span>
                                                </label>
                                            </div>
                                            <div className="select_btn">
                                                <label><input onClick={(e) => updateInputValue(e)} name="periop_position_devices" type="radio" value="Oxford table attachment" /><span>Oxford table attachment </span>
                                                </label>
                                            </div>
                                            <div className="select_btn">
                                                <label><input onClick={(e) => updateInputValue(e)} name="periop_position_devices" type="radio" value="Vein board" /><span>Vein board
                                                </span>
                                                </label>
                                            </div>
                                            <div className="select_btn">
                                                <label><input onClick={(e) => updateInputValue(e)} name="periop_position_devices" type="radio" value="Montreal mattress" /><span>Montreal mattress </span>
                                                </label>
                                            </div>
                                            <div className="select_btn">
                                                <label><input onClick={(e) => updateInputValue(e)} name="periop_position_devices" type="radio" value="Wilson frame" /><span>Wilson frame
                                                </span>
                                                </label>
                                            </div>
                                            <div className="select_btn">
                                                <label><input onClick={(e) => updateInputValue(e)} name="periop_position_devices" type="radio" value="Lithotomy fin supports" /><span>Lithotomy fin supports </span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="section simple_wound ctr">
                                    <h2 className="popup_title">PRESSURE POINTS </h2>
                                    <div className="pd_border"></div>
                                    <div className="check_item mt-0">
                                        <div className="select_btns popup_select mt-0">
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_pressure_points" type="radio"
                                                value="Pressure Points padded" /><span>Pressure Points padded </span></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="section simple_wound ctr">
                                    <h2 className="popup_title">ANTI-EMBOLIC STOCKINGS </h2>
                                    <div className="pd_border"></div>
                                    <div className="check_item mt-0">
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="select_btns popup_select mt-0">
                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_anti_embolic" type="radio"
                                                        value="YES" /><span>YES </span></label>
                                                    </div>
                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_anti_embolic" type="radio"
                                                        value="NO" /><span>NO </span></label>
                                                    </div>
                                                </div>
                                            </div>
                                            {clinicValues.periop_anti_embolic == "YES" &&
                                                <div className="col-md-3">
                                                    <div className="select_btns popup_select mt-0">
                                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_anti_embolic_position" type="radio"
                                                            value="Left" /><span>Left </span></label>
                                                        </div>
                                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_anti_embolic_position" type="radio"
                                                            value="Right" /><span>Right </span></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            }

                                        </div>
                                    </div>
                                </div>
                                <div className="section simple_wound ctr">
                                    <h2 className="popup_title">FLOWTRONS </h2>
                                    <div className="pd_border"></div>
                                    <div className="check_item mt-0">
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="select_btns popup_select mt-0">
                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_flowtrons" type="radio"
                                                        value="YES" /><span>YES </span></label>
                                                    </div>
                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_flowtrons" type="radio"
                                                        value="NO" /><span>NO </span></label>
                                                    </div>
                                                </div>
                                            </div>
                                            {clinicValues.periop_flowtrons == "YES" &&
                                                <div className="col-md-3">
                                                    <div className="select_btns popup_select mt-0">
                                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_flowtrons_pos" type="radio"
                                                            value="Left" /><span>Left </span></label>
                                                        </div>
                                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_flowtrons_pos" type="radio"
                                                            value="Right" /><span>Right </span></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="section simple_wound ctr">
                                    <h2 className="popup_title">PATIENT WARMING DEVICE? </h2>
                                    <div className="pd_border"></div>
                                    <div className="check_item mt-0">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="select_btns popup_select mt-0">
                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_patient_warming_device" type="radio"
                                                        value="YES" /><span>YES </span></label>
                                                    </div>
                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_patient_warming_device" type="radio"
                                                        value="NO" /><span>NO </span></label>
                                                    </div>
                                                </div>
                                                <p className="note2">(temp. maintained to at least 36C)</p>
                                            </div>

                                        </div>

                                    </div>

                                    {clinicValues.periop_patient_warming_device == "YES" &&
                                        <div className="check_item mt-0">
                                            <div className="select_btns popup_select mt20">
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_patient_warming_list" type="radio"
                                                    value="Warm air Blanket" /><span>Warm air Blanket </span></label>
                                                </div>
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_patient_warming_list" type="radio"
                                                    value="Thermal hat " /><span>Thermal hat </span></label>
                                                </div>
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_patient_warming_list" type="radio"
                                                    value="Easy Warm" /><span> Easy Warm</span></label>
                                                </div>
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_patient_warming_list" type="radio"
                                                    value="Body hugger/warmer" /><span>Body hugger/warmer </span></label>
                                                </div>
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_patient_warming_list" type="radio"
                                                    value="Space blanket" /><span>Space blanket</span></label>
                                                </div>
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_patient_warming_list" type="radio"
                                                    value="Thermal wrap" /><span> Thermal wrap </span></label>
                                                </div>
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_patient_warming_list" type="radio"
                                                    value="Fluid warmer HME" /><span> Fluid warmer HME</span></label>
                                                </div>

                                            </div>
                                        </div>}
                                    {clinicValues.periop_patient_warming_device == "YES" &&
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="periop_patient_warming_other"
                                                className="form-control" /><label
                                                    className="floating-label">Other</label>
                                            </div>
                                        </div>}


                                </div>
                                <div className="section simple_wound ctr">
                                    <h2 className="popup_title">DIATHERMY </h2>
                                    <div className="pd_border"></div>
                                    <div className="check_item mt-0">
                                        <div className="row">
                                            <div className="col-md-4 ">
                                                <div className="select_btns popup_select mt-0 dsp-flex">
                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_diathermy" type="radio"
                                                        value="YES" /><span>YES </span></label>
                                                    </div>
                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_diathermy" type="radio"
                                                        value="NO" /><span>NO </span></label>
                                                    </div>

                                                </div>

                                            </div>
                                            {clinicValues.periop_diathermy == "YES" &&
                                                <div className="col-md-4">
                                                    <div className="select_btns popup_select mt-0">
                                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_diathermy_type" type="radio"
                                                            value="Monopolar" /><span> Monopolar </span></label>
                                                        </div>
                                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_diathermy_type" type="radio"
                                                            value="Bipolar" /><span>Bipolar </span></label>
                                                        </div>
                                                    </div>

                                                </div>}

                                        </div>
                                    </div>
                                    {clinicValues.periop_diathermy == "YES" &&
                                        <div className="row mt-10">
                                            <div className="col-md-6">
                                                <div className="form_group">
                                                    <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="periop_diathermy_Settings"
                                                        className="form-control" /><label
                                                            className="floating-label">Settings (if various, state highest
                                                            used)</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form_group an">
                                                    <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="periop_diathermy_Cutting"
                                                        className="form-control" /><label
                                                            className="floating-label">Cutting</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form_group an">
                                                    <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="periop_diathermy_Coagulation"
                                                        className="form-control" /><label
                                                            className="floating-label">Coagulation</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form_group an">
                                                    <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="periop_diathermy_bipolar"
                                                        className="form-control" /><label
                                                            className="floating-label">Bi-polar</label>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>}


                                    <div className="check_item mt-10">
                                        <label className="check">Ground pad site</label>
                                        <div className="select_btns popup_select mt20">
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_ground_pad" type="radio"
                                                value="Abdomen" /><span>Abdomen </span></label>
                                            </div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_ground_pad" type="radio"
                                                value="Back" /><span>Back </span></label>
                                            </div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_ground_pad" type="radio"
                                                value="Buttock" /><span> Buttock</span></label>
                                            </div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_ground_pad" type="radio"
                                                value="Thigh" /><span>Thigh </span></label>
                                            </div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_ground_pad" type="radio"
                                                value="Flank" /><span>Flank</span></label>
                                            </div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_ground_pad" type="radio"
                                                value="Thermal wrap" /><span> Thermal wrap </span></label>
                                            </div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_ground_pad" type="radio"
                                                value=" Fluid warmer HME" /><span> Fluid warmer HME</span></label>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="check_item mt-10">
                                        <label className="check">Diathermy site on removal</label>
                                        <div className="select_btns popup_select mt20">
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_Diathermy_removal" type="radio"
                                                value="Intact" /><span>Intact </span></label>
                                            </div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_Diathermy_removal" type="radio"
                                                value="Red" /><span>Red </span></label>
                                            </div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_Diathermy_removal" type="radio"
                                                value="Broken" /><span> Broken</span></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form_group">
                                        <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="periop_Specify"
                                            className="form-control" /><label
                                                className="floating-label">Specify</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="section simple_wound ctr">
                                    <h2 className="popup_title">TOURNIQUET </h2>
                                    <div className="pd_border"></div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="periop_LeftTourniquet"
                                                    className="form-control" /><label
                                                        className="floating-label">Left Tourniquet Pressure mmHg</label>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="measure tqt">
                                                        <label>On</label>
                                                        <div className="dsp_flex"> <input type="text" onChange={(e) => updateInputValue(e)} name="periop_LeftTourniquet_on_hrs" className="field" />
                                                            <span>hrs </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="measure tqt">
                                                        <label>Off</label>
                                                        <div className="dsp_flex"> <input type="text" onChange={(e) => updateInputValue(e)} name="periop_LeftTourniquet_off_hrs" className="field" />
                                                            <span>hrs </span>
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                            <div className="form_group">
                                                <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="periop_LeftTourniquet_total"
                                                    className="form-control" /><label
                                                        className="floating-label">Total time</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="periop_RightTourniquet"
                                                    className="form-control" /><label
                                                        className="floating-label">Right Tourniquet Pressure mmHg</label>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="measure tqt">
                                                        <label>On</label>
                                                        <div className="dsp_flex"> <input type="text" onChange={(e) => updateInputValue(e)} name="periop_RightTourniquet_on_hrs" className="field" />
                                                            <span>hrs </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="measure tqt">
                                                        <label>Off</label>
                                                        <div className="dsp_flex"> <input type="text" onChange={(e) => updateInputValue(e)} name="periop_RightTourniquet_off_hrs" className="field" />
                                                            <span>hrs </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form_group">
                                                <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="periop_RightTourniquet_total"
                                                    className="form-control" /><label
                                                        className="floating-label">Total time</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="yn_switch mb-10  mt-10">
                                        <div className="label-container">
                                            <p>Skin intact on removal? </p>
                                        </div>
                                        <label className="switch-container">
                                            <input onChange={(e) => handleChange(e)} name="periop_Skin_intact" type="checkbox" className="switch_check" />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>YES</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>NO</span>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                <div className="section simple_wound ctr">
                                    <h2 className="popup_title">SKIN PREPARATION </h2>
                                    <div className="pd_border"></div>
                                    <div className="check_item mt-0">
                                        <div className="select_btns popup_select mt-0">
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_Skin_preparation" type="radio"
                                                value="2% Alcoholic Chlorhexidine" /><span>2% Alcoholic Chlorhexidine </span></label>
                                            </div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_Skin_preparation" type="radio"
                                                value="Aqueous Chlorhexidine" /><span>Aqueous Chlorhexidine </span></label>
                                            </div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_Skin_preparation" type="radio"
                                                value="Alcoholic betadine" /><span> Alcoholic betadine </span></label>
                                            </div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_Skin_preparation" type="radio"
                                                value="Aqueous betadine" /><span>Aqueous betadine </span></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form_group">
                                        <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="periop_Skin_preparation_other"
                                            className="form-control" /><label
                                                className="floating-label">Other</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="section simple_wound ctr">
                                    <h2 className="popup_title">IRRIGATION </h2>
                                    <div className="pd_border"></div>
                                    <div className="check_item mt-0">
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="select_btns popup_select mt-0">
                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_irrigation" type="radio"
                                                        value="YES" /><span>YES </span></label>
                                                    </div>
                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_irrigation" type="radio"
                                                        value="NO" /><span>NO </span></label>
                                                    </div>
                                                </div>
                                            </div>
                                            {clinicValues.periop_irrigation == "YES" &&
                                                <div className="col-md-9">
                                                    <div className="select_btns popup_select mt-0">
                                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_irrigation_type" type="radio"
                                                            value="Saline" /><span>Saline </span></label>
                                                        </div>
                                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_irrigation_type" type="radio"
                                                            value="Water" /><span>Water </span></label>
                                                        </div>
                                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_irrigation_type" type="radio"
                                                            value="Glycine" /><span>Glycine </span></label>
                                                        </div>
                                                    </div>
                                                </div>}

                                        </div>
                                    </div>
                                </div>
                                <div className="section simple_wound ctr">
                                    <h2 className="popup_title">DRAINS IN SITU </h2>
                                    <div className="pd_border"></div>
                                    <div className="check_item mt-0">
                                        <div className="row">

                                            <div className="col-md-3">
                                                <div className="select_btns popup_select mt-0">
                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_drains_in_situ" type="radio"
                                                        value="YES" /><span>YES </span></label>
                                                    </div>
                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_drains_in_situ" type="radio"
                                                        value="NO" /><span>NO </span></label>
                                                    </div>
                                                </div>
                                            </div>

                                            {clinicValues.periop_drains_in_situ == "YES" &&
                                                <div className="col-md-9">
                                                    <div className="form_group">
                                                        <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)}
                                                            name="periop_drains_type" className="form-control" /><label
                                                                className="floating-label">Specify number and type</label>
                                                        </div>
                                                    </div>
                                                </div>}

                                            {clinicValues.periop_drains_in_situ == "YES" && <div>
                                                <div className="col-md-4">
                                                    <div className="form_group">
                                                        <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)}
                                                            name="periop_drains_redivac"
                                                            className="form-control" /><label
                                                                className="floating-label">Redivac </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form_group">
                                                        <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)}
                                                            name="periop_drains_batch"
                                                            className="form-control" /><label
                                                                className="floating-label">Batch number</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form_group">
                                                        <div className="floating-label-group"><input type="date" onChange={(e) => updateInputValue(e)}
                                                            name="periop_drains_expiry_date"
                                                            className="form-control" /><label
                                                                className="floating-label">Expiry date</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>}

                                        </div>
                                    </div>
                                </div>
                                <div className="section simple_wound ctr">
                                    <h2 className="popup_title">PACKS IN SITU </h2>
                                    <div className="pd_border"></div>
                                    <div className="check_item mt-0">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="select_btns popup_select mt-0">
                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_packs_in_situ" type="radio"
                                                        value="YES" /><span>YES </span></label>
                                                    </div>
                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_packs_in_situ" type="radio"
                                                        value="NO" /><span>NO </span></label>
                                                    </div>
                                                </div>
                                            </div>
                                            {clinicValues.periop_packs_in_situ == "YES" && <div>
                                                <div className="col-md-4">
                                                    <div className="form_group">
                                                        <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)}
                                                            name="periop_packs_site"
                                                            className="form-control" /><label
                                                                className="floating-label">Site</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form_group">
                                                        <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)}
                                                            name="periop_packs_type"
                                                            className="form-control" /><label
                                                                className="floating-label">Type of pack </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>}

                                        </div>
                                    </div>
                                </div>
                                <div className="section simple_wound ctr">
                                    <h2 className="popup_title">WOUND INFILTRATION</h2>
                                    <div className="pd_border"></div>
                                    <div className="check_item mt-0">
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="select_btns popup_select mt-0">
                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)}
                                                        name="periop_wound_infiltration" type="radio"
                                                        value="YES" /><span>YES </span></label>
                                                    </div>
                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)}
                                                        name="periop_wound_infiltration" type="radio"
                                                        value="NO" /><span>NO </span></label>
                                                    </div>
                                                </div>
                                            </div>
                                            {clinicValues.periop_wound_infiltration == "YES" && <div>
                                                <div className="col-md-3">
                                                    <div className="form_group">
                                                        <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)}
                                                            name="periop_wound_infiltration_site"
                                                            className="form-control" /><label
                                                                className="floating-label">Site</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form_group">
                                                        <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)}
                                                            name="periop_wound_infiltration_drug"
                                                            className="form-control" /><label
                                                                className="floating-label">Drug used </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form_group">
                                                        <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)}
                                                            name="periop_wound_infiltration_carried"
                                                            className="form-control" /><label
                                                                className="floating-label">Carried out by</label>
                                                        </div>
                                                    </div>
                                                </div></div>}

                                        </div>
                                    </div>
                                </div>
                                <div className="section simple_wound ctr">
                                    <h2 className="popup_title">SKIN CLOSURE</h2>
                                    <div className="pd_border"></div>
                                    <div className="check_item mt-0">
                                        <div className="select_btns popup_select mt-0">
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)}
                                                name="periop_skin_closure" type="radio"
                                                value="bsorbable (Monocryl)" /><span>Absorbable (Monocryl) </span></label>
                                            </div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)}
                                                name="periop_skin_closure" type="radio"
                                                value="Non absorbable (Ethilon)" /><span>Non absorbable (Ethilon) </span></label>
                                            </div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)}
                                                name="periop_skin_closure" type="radio"
                                                value="Steristrips" /><span>Steristrips </span></label>
                                            </div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)}
                                                name="periop_skin_closure" type="radio"
                                                value="Glue" /><span>Glue </span></label>
                                            </div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)}
                                                name="periop_skin_closure" type="radio"
                                                value="Staples" /><span>Staples </span></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)}
                                                    name="periop_skin_closure_other"
                                                    className="form-control" /><label
                                                        className="floating-label">Other </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)}
                                                    name="periop_skin_closure_instructions"
                                                    className="form-control" /><label
                                                        className="floating-label">Special instructions</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="section simple_wound ctr">
                                    <h2 className="popup_title">DRESSINGS</h2>
                                    <div className="pd_border"></div>
                                    <div className="check_item mt-0">
                                        <div className="select_btns popup_select mt-0">
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)}
                                                name="periop_dressings" type="radio"
                                                value="Melolin" /><span>Melolin </span></label>
                                            </div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)}
                                                name="periop_dressings" type="radio"
                                                value="Blue gauze" /><span>Blue gauze </span></label>
                                            </div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)}
                                                name="periop_dressings" type="radio"
                                                value="Leukomed" /><span>Leukomed</span></label>
                                            </div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)}
                                                name="periop_dressings" type="radio"
                                                value="Elastoplast" /><span>Elastoplast </span></label>
                                            </div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)}
                                                name="periop_dressings" type="radio"
                                                value="Crepe" /><span>Crepe </span></label>
                                            </div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)}
                                                name="periop_dressings" type="radio"
                                                value="Jelonet" /><span>Jelonet </span></label>
                                            </div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)}
                                                name="periop_dressings" type="radio"
                                                value="Sanitary pad" /><span>Sanitary pad </span></label>
                                            </div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)}
                                                name="periop_dressings" type="radio"
                                                value="Eye dressing" /><span>Eye dressing </span></label>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="form_group">
                                        <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)}
                                            name="periop_dressings_other"
                                            className="form-control" /><label
                                                className="floating-label">Other </label>
                                        </div>
                                    </div>

                                </div>
                                <div className="section simple_wound ctr">
                                    <h2 className="popup_title">SPLINTS/PLASTERS</h2>
                                    <div className="pd_border"></div>
                                    <div className="check_item mt-0">
                                        <div className="select_btns popup_select mt-0">
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)}
                                                name="periop_splints" type="radio"
                                                value="YES" /><span>YES </span></label>
                                            </div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)}
                                                name="periop_splints" type="radio"
                                                value="NO" /><span>NO </span></label>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="section simple_wound ctr">
                                    <h2 className="popup_title">SPECIMENS</h2>
                                    <div className="pd_border"></div>
                                    <div className="check_item mt-0">
                                        <div className="select_btns popup_select mt-0">
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)}
                                                name="periop_specimens" type="radio"
                                                value="YES" /><span>YES </span></label>
                                            </div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)}
                                                name="periop_specimens" type="radio"
                                                value="NO" /><span>NO </span></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="yn_switch mb-10  mt-10">
                                        <div className="label-container">
                                            <p>Specimen labelled correctly </p>
                                        </div>
                                        <label className="switch-container">
                                            <input onChange={(e) => handleChange(e)} name="periop_specimen_labelled" type="checkbox" className="switch_check" />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>YES</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>NO</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="row mt-20">
                                        <div className="col-md-4">
                                            <div className="form_group">
                                                <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)}
                                                    name="periop_specimen_number"
                                                    className="form-control" /><label
                                                        className="floating-label">Number taken </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form_group">
                                                <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)}
                                                    name="periop_specimen_type"
                                                    className="form-control" /><label
                                                        className="floating-label">Type</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form_group">
                                                <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)}
                                                    name="periop_specimen_sent_for"
                                                    className="form-control" /><label className="floating-label">Sent for</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="check_item mt-10"><label className="check">Microbiology culture</label>
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)}
                                                name="periop_specimen_Microbiology" type="radio"
                                                value="Swab" /><span>Swab </span></label>
                                            </div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)}
                                                name="periop_specimen_Microbiology" type="radio"
                                                value="Culture bottle" /><span>Culture bottle </span></label></div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)}
                                                name="periop_specimen_Microbiology" type="radio"
                                                value="Universal container" /><span>Universal container
                                                </span></label></div>
                                        </div>
                                    </div>
                                    <div className="check_item mt-10"><label className="check">HistoPathology</label>
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)}
                                                name="periop_specimen_HistoPathology" type="radio"
                                                value="Dry Formalin" /><span>Dry Formalin </span></label>
                                            </div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)}
                                                name="periop_specimen_HistoPathology" type="radio"
                                                value="Fresh" /><span>Fresh </span></label></div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)}
                                                name="periop_specimen_HistoPathology" type="radio"
                                                value="Frozen" /><span>Frozen
                                                </span></label></div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)}
                                                name="periop_specimen_HistoPathology" type="radio"
                                                value="Discarded per surgeon" /><span>Discarded per surgeon
                                                </span></label></div>
                                        </div>
                                    </div>
                                    <div className="row mt-0">
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)}
                                                    name="periop_specimen_sent_in"
                                                    className="form-control" /><label
                                                        className="floating-label">Sent in</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)}
                                                    name="periop_specimen_other"
                                                    className="form-control" /><label
                                                        className="floating-label">Other</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form_group">
                                                <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)}
                                                    name="periop_specimen_hospital"
                                                    className="form-control" /><label
                                                        className="floating-label">Sent to other hospitals</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4"></div>
                                        <div className="col-md-4">
                                            <p className="field_title mt-20"></p>

                                            <div className="file_upload">
                                                <div className="upload_file">
                                                    {
                                                        clinicValues.adhere_result || (file && file.adhere_result) ?
                                                            <img src={(clinicValues.adhere_result) || (file && file.adhere_result)} className="img" />
                                                            :
                                                            <span className="material-symbols-outlined">
                                                                cloud_upload
                                                            </span>}
                                                </div>
                                                <input className="custom-file-input" type="file" onChange={(e) => handleFile(e, "adhere")} />

                                                <div className="file_ttile spcn">Adhere implant stickers - Document</div>
                                            </div>
                                        </div>
                                        <div className="col-md-4"></div>
                                    </div>

                                </div>
                                <div className="section simple_wound ctr">
                                    <h2 className="popup_title">URINARY CATHETER </h2>
                                    <div className="pd_border"></div>
                                    <div className="check_item mt-0">
                                        <div className="select_btns popup_select mt-0">
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)}
                                                name="periop_urinary_catheter" type="radio"
                                                value="YES" /><span>YES </span></label>
                                            </div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)}
                                                name="periop_urinary_catheter" type="radio"
                                                value="NO" /><span>NO </span></label>
                                            </div>
                                        </div>

                                    </div>
                                    {clinicValues.periop_urinary_catheter == "YES" && <div>
                                        <div className="yn_switch mb-10  mt-10">
                                            <div className="label-container">
                                                <p>If yes, then catheter pathway commenced </p>
                                            </div>
                                            <label className="switch-container">
                                                <input onChange={(e) => handleChange(e)} name="periop_catheter_pathway" type="checkbox" className="switch_check" />
                                                <div className="switch-bg"></div>
                                                <div className="round-box"></div>
                                                <div className="switch-left">
                                                    <span>YES</span>
                                                </div>
                                                <div className="switch-right">
                                                    <span>NO</span>
                                                </div>
                                            </label>
                                        </div>
                                        <p className="note2">Intermittent catheter use only (LoFric)</p>
                                        <div className="yn_switch mb-10  mt-20">
                                            <div className="label-container">
                                                <p>If suprapubic, sutured in place? </p>
                                            </div>
                                            <label className="switch-container">
                                                <input onChange={(e) => handleChange(e)} name="periop_Intermittent_catheter" type="checkbox" className="switch_check" />
                                                <div className="switch-bg"></div>
                                                <div className="round-box"></div>
                                                <div className="switch-left">
                                                    <span>YES</span>
                                                </div>
                                                <div className="switch-right">
                                                    <span>NO</span>
                                                </div>
                                            </label>
                                        </div>
                                        <div className="row mt-20">
                                            <div className="col-md-4">
                                                <div className="form_group">
                                                    <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="periop_urinary_catheter_lot"
                                                        className="form-control" /><label
                                                            className="floating-label">Lot no </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form_group">
                                                    <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="periop_urinary_catheter_size"
                                                        className="form-control" /><label
                                                            className="floating-label">Size</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form_group">
                                                    <div className="floating-label-group"><input type="date" onChange={(e) => updateInputValue(e)} name="periop_urinary_catheter_expirydate"
                                                        className="form-control" /><label
                                                            className="floating-label">Expiry date</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}
                                </div>
                                <div className="section simple_wound ctr">
                                    <h2 className="popup_title">IV LINES AND CANNULAE</h2>
                                    <div className="pd_border"></div>
                                    <div className="check_item mt-0">
                                        <div className="select_btns popup_select mt-0">
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_iv_lines_cannulae" type="radio"
                                                value="IV lines and cannulae flushed" /><span>IV lines and cannulae flushed </span></label>
                                            </div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_iv_lines_cannulae" type="radio"
                                                value="Multi lumen catheters clamped and occluded" /><span>Multi lumen catheters clamped and occluded
                                                </span></label>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="section simple_wound ctr sin">
                                    <h2 className="popup_title">SKIN INSPECTION</h2>
                                    <div className="pd_border"></div>
                                    <table className="table table-striped">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <div className="row">
                                                        <div className="col-md-4">

                                                        </div>
                                                        <div className="col-md-4">
                                                            <span className="point">PREOP </span>

                                                        </div>
                                                        <div className="col-md-4">
                                                            <span className="point">POSTOP </span>

                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <span className="point">Back of head </span>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="check_item mt-0">
                                                                <div className="select_btns popup_select mt-0">
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_backofhead_preop"
                                                                        type="radio" value="Intact" /><span>Intact
                                                                        </span></label>
                                                                    </div>
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_backofhead_preop"
                                                                        type="radio" value="Red" /><span>Red
                                                                        </span></label>
                                                                    </div>
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_backofhead_preop"
                                                                        type="radio" value="Broken" /><span>Broken
                                                                        </span></label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="check_item mt-0">
                                                                <div className="select_btns popup_select mt-0">
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_backofhead_postop"
                                                                        type="radio" value="Intact" /><span>Intact
                                                                        </span></label>
                                                                    </div>
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_backofhead_postop"
                                                                        type="radio" value="Red" /><span>Red
                                                                        </span></label>
                                                                    </div>
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_backofhead_postop"
                                                                        type="radio" value="Broken" /><span>Broken
                                                                        </span></label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <span className="point">Scapula: R - L</span>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="check_item mt-0">
                                                                <div className="select_btns popup_select mt-0">
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_Scapula_preop"
                                                                        type="radio" value="Intact" /><span>Intact
                                                                        </span></label>
                                                                    </div>
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_Scapula_preop"
                                                                        type="radio" value="Red" /><span>Red
                                                                        </span></label>
                                                                    </div>
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_Scapula_preop"
                                                                        type="radio" value="Broken" /><span>Broken
                                                                        </span></label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="check_item mt-0">
                                                                <div className="select_btns popup_select mt-0">
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_Scapula_postop"
                                                                        type="radio" value="Intact" /><span>Intact
                                                                        </span></label>
                                                                    </div>
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_Scapula_postop"
                                                                        type="radio" value="Red" /><span>Red
                                                                        </span></label>
                                                                    </div>
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_Scapula_postop"
                                                                        type="radio" value="Broken" /><span>Broken
                                                                        </span></label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <span className="point">Sacrum </span>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="check_item mt-0">
                                                                <div className="select_btns popup_select mt-0">
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_Sacrum_preop"
                                                                        type="radio" value="Intact" /><span>Intact
                                                                        </span></label>
                                                                    </div>
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_Sacrum_preop"
                                                                        type="radio" value="Red" /><span>Red
                                                                        </span></label>
                                                                    </div>
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_Sacrum_preop"
                                                                        type="radio" value="Broken" /><span>Broken
                                                                        </span></label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="check_item mt-0">
                                                                <div className="select_btns popup_select mt-0">
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_Sacrum_postop"
                                                                        type="radio" value="Intact" /><span>Intact
                                                                        </span></label>
                                                                    </div>
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_Sacrum_postop"
                                                                        type="radio" value="Red" /><span>Red
                                                                        </span></label>
                                                                    </div>
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_Sacrum_postop"
                                                                        type="radio" value="Broken" /><span>Broken
                                                                        </span></label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <span className="point">Buttocks: R - L</span>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="check_item mt-0">
                                                                <div className="select_btns popup_select mt-0">
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_Buttocks_preop"
                                                                        type="radio" value="Intact" /><span>Intact
                                                                        </span></label>
                                                                    </div>
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_Buttocks_preop"
                                                                        type="radio" value="Red" /><span>Red
                                                                        </span></label>
                                                                    </div>
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_Buttocks_preop"
                                                                        type="radio" value="Broken" /><span>Broken
                                                                        </span></label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="check_item mt-0">
                                                                <div className="select_btns popup_select mt-0">
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_Buttocks_postop"
                                                                        type="radio" value="Intact" /><span>Intact
                                                                        </span></label>
                                                                    </div>
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_Buttocks_postop"
                                                                        type="radio" value="Red" /><span>Red
                                                                        </span></label>
                                                                    </div>
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_Buttocks_postop"
                                                                        type="radio" value="Broken" /><span>Broken
                                                                        </span></label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <span className="point">Ribs R - L </span>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="check_item mt-0">
                                                                <div className="select_btns popup_select mt-0">
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_Ribs_preop"
                                                                        type="radio" value="Intact" /><span>Intact
                                                                        </span></label>
                                                                    </div>
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_Ribs_preop"
                                                                        type="radio" value="Red" /><span>Red
                                                                        </span></label>
                                                                    </div>
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_Ribs_preop"
                                                                        type="radio" value="Broken" /><span>Broken
                                                                        </span></label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="check_item mt-0">
                                                                <div className="select_btns popup_select mt-0">
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_Ribs_postop"
                                                                        type="radio" value="Intact" /><span>Intact
                                                                        </span></label>
                                                                    </div>
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_Ribs_postop"
                                                                        type="radio" value="Red" /><span>Red
                                                                        </span></label>
                                                                    </div>
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_Ribs_postop"
                                                                        type="radio" value="Broken" /><span>Broken
                                                                        </span></label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <span className="point">Spine</span>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="check_item mt-0">
                                                                <div className="select_btns popup_select mt-0">
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_Spine_preop"
                                                                        type="radio" value="Intact" /><span>Intact
                                                                        </span></label>
                                                                    </div>
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_Spine_preop"
                                                                        type="radio" value="Red" /><span>Red
                                                                        </span></label>
                                                                    </div>
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_Spine_preop"
                                                                        type="radio" value="Broken" /><span>Broken
                                                                        </span></label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="check_item mt-0">
                                                                <div className="select_btns popup_select mt-0">
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_Spine_postop"
                                                                        type="radio" value="Intact" /><span>Intact
                                                                        </span></label>
                                                                    </div>
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_Spine_postop"
                                                                        type="radio" value="Red" /><span>Red
                                                                        </span></label>
                                                                    </div>
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_Spine_postop"
                                                                        type="radio" value="Broken" /><span>Broken
                                                                        </span></label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <span className="point">Natal cleft </span>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="check_item mt-0">
                                                                <div className="select_btns popup_select mt-0">
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_Natal_preop"
                                                                        type="radio" value="Intact" /><span>Intact
                                                                        </span></label>
                                                                    </div>
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_Natal_preop"
                                                                        type="radio" value="Red" /><span>Red
                                                                        </span></label>
                                                                    </div>
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_Natal_preop"
                                                                        type="radio" value="Broken" /><span>Broken
                                                                        </span></label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="check_item mt-0">
                                                                <div className="select_btns popup_select mt-0">
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_Natal_postop"
                                                                        type="radio" value="Intact" /><span>Intact
                                                                        </span></label>
                                                                    </div>
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_Natal_postop"
                                                                        type="radio" value="Red" /><span>Red
                                                                        </span></label>
                                                                    </div>
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_Natal_postop"
                                                                        type="radio" value="Broken" /><span>Broken
                                                                        </span></label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <span className="point">Heels: R - L</span>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="check_item mt-0">
                                                                <div className="select_btns popup_select mt-0">
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_Heels_preop"
                                                                        type="radio" value="Intact" /><span>Intact
                                                                        </span></label>
                                                                    </div>
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_Heels_preop"
                                                                        type="radio" value="Red" /><span>Red
                                                                        </span></label>
                                                                    </div>
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_Heels_preop"
                                                                        type="radio" value="Broken" /><span>Broken
                                                                        </span></label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="check_item mt-0">
                                                                <div className="select_btns popup_select mt-0">
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_Heels_postop"
                                                                        type="radio" value="Intact" /><span>Intact
                                                                        </span></label>
                                                                    </div>
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_Heels_postop"
                                                                        type="radio" value="Red" /><span>Red
                                                                        </span></label>
                                                                    </div>
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_Heels_postop"
                                                                        type="radio" value="Broken" /><span>Broken
                                                                        </span></label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <span className="point">Foot: R - L</span>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="check_item mt-0">
                                                                <div className="select_btns popup_select mt-0">
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_Foot_preop"
                                                                        type="radio" value="Intact" /><span>Intact
                                                                        </span></label>
                                                                    </div>
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_Foot_preop"
                                                                        type="radio" value="Red" /><span>Red
                                                                        </span></label>
                                                                    </div>
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_Foot_preop"
                                                                        type="radio" value="Broken" /><span>Broken
                                                                        </span></label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="check_item mt-0">
                                                                <div className="select_btns popup_select mt-0">
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_Foot_postop"
                                                                        type="radio" value="Intact" /><span>Intact
                                                                        </span></label>
                                                                    </div>
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_Foot_postop"
                                                                        type="radio" value="Red" /><span>Red
                                                                        </span></label>
                                                                    </div>
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_Foot_postop"
                                                                        type="radio" value="Broken" /><span>Broken
                                                                        </span></label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <span className="point">Toes: R - L </span>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="check_item mt-0">
                                                                <div className="select_btns popup_select mt-0">
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_Toes_preop"
                                                                        type="radio" value="Intact" /><span>Intact
                                                                        </span></label>
                                                                    </div>
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_Toes_preop"
                                                                        type="radio" value="Red" /><span>Red
                                                                        </span></label>
                                                                    </div>
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_Toes_preop"
                                                                        type="radio" value="Broken" /><span>Broken
                                                                        </span></label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="check_item mt-0">
                                                                <div className="select_btns popup_select mt-0">
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_Toes_postop"
                                                                        type="radio" value="Intact" /><span>Intact
                                                                        </span></label>
                                                                    </div>
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_Toes_postop"
                                                                        type="radio" value="Red" /><span>Red
                                                                        </span></label>
                                                                    </div>
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_Toes_postop"
                                                                        type="radio" value="Broken" /><span>Broken
                                                                        </span></label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <span className="point">Knee: R - L</span>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="check_item mt-0">
                                                                <div className="select_btns popup_select mt-0">
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_Knee_preop"
                                                                        type="radio" value="Intact" /><span>Intact
                                                                        </span></label>
                                                                    </div>
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_Knee_preop"
                                                                        type="radio" value="Red" /><span>Red
                                                                        </span></label>
                                                                    </div>
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_Knee_preop"
                                                                        type="radio" value="Broken" /><span>Broken
                                                                        </span></label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="check_item mt-0">
                                                                <div className="select_btns popup_select mt-0">
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_Knee_postop"
                                                                        type="radio" value="Intact" /><span>Intact
                                                                        </span></label>
                                                                    </div>
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_Knee_postop"
                                                                        type="radio" value="Red" /><span>Red
                                                                        </span></label>
                                                                    </div>
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_Knee_postop"
                                                                        type="radio" value="Broken" /><span>Broken
                                                                        </span></label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <span className="point">Hip: R - L</span>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="check_item mt-0">
                                                                <div className="select_btns popup_select mt-0">
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_Hip_preop"
                                                                        type="radio" value="Intact" /><span>Intact
                                                                        </span></label>
                                                                    </div>
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_Hip_preop"
                                                                        type="radio" value="Red" /><span>Red
                                                                        </span></label>
                                                                    </div>
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_Hip_preop"
                                                                        type="radio" value="Broken" /><span>Broken
                                                                        </span></label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="check_item mt-0">
                                                                <div className="select_btns popup_select mt-0">
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_Hip_postop"
                                                                        type="radio" value="Intact" /><span>Intact
                                                                        </span></label>
                                                                    </div>
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_Hip_postop"
                                                                        type="radio" value="Red" /><span>Red
                                                                        </span></label>
                                                                    </div>
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_Hip_postop"
                                                                        type="radio" value="Broken" /><span>Broken
                                                                        </span></label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <span className="point">Elbow: R - L</span>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="check_item mt-0">
                                                                <div className="select_btns popup_select mt-0">
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_Elbow_preop"
                                                                        type="radio" value="Intact" /><span>Intact
                                                                        </span></label>
                                                                    </div>
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_Elbow_preop"
                                                                        type="radio" value="Red" /><span>Red
                                                                        </span></label>
                                                                    </div>
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_Elbow_preop"
                                                                        type="radio" value="Broken" /><span>Broken
                                                                        </span></label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="check_item mt-0">
                                                                <div className="select_btns popup_select mt-0">
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_Elbow_postop"
                                                                        type="radio" value="Intact" /><span>Intact
                                                                        </span></label>
                                                                    </div>
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_Elbow_postop"
                                                                        type="radio" value="Red" /><span>Red
                                                                        </span></label>
                                                                    </div>
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_Elbow_postop"
                                                                        type="radio" value="Broken" /><span>Broken
                                                                        </span></label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <span className="point">Shoulder: R - L</span>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="check_item mt-0">
                                                                <div className="select_btns popup_select mt-0">
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_Shoulder_preop"
                                                                        type="radio" value="Intact" /><span>Intact
                                                                        </span></label>
                                                                    </div>
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_Shoulder_preop"
                                                                        type="radio" value="Red" /><span>Red
                                                                        </span></label>
                                                                    </div>
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_Shoulder_preop"
                                                                        type="radio" value="Broken" /><span>Broken
                                                                        </span></label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="check_item mt-0">
                                                                <div className="select_btns popup_select mt-0">
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_Shoulder_postop"
                                                                        type="radio" value="Intact" /><span>Intact
                                                                        </span></label>
                                                                    </div>
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_Shoulder_postop"
                                                                        type="radio" value="Red" /><span>Red
                                                                        </span></label>
                                                                    </div>
                                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_Shoulder_postop"
                                                                        type="radio" value="Broken" /><span>Broken
                                                                        </span></label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>
                                    <div className="yn_switch mb-20  mt-20">
                                        <div className="label-container">
                                            <p>If pressure damage is identified complete Pressure Ulcer IPOC</p>
                                        </div>
                                        <label className="switch-container">
                                            <input onChange={(e) => handleChange(e)} name="periop_pressure_damage" type="checkbox" className="switch_check" />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>YES</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>NO</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="row mt-20">
                                        <div className="col-md-6">
                                            <div className="form_group an">
                                                <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="periop_assessed_by"
                                                    className="form-control" /><label
                                                        className="floating-label">Assessed by</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                        {file.periop_assessed_Sign_result || clinicValues.periop_assessed_Sign_result ? <img src={file.periop_assessed_Sign_result || clinicValues.periop_assessed_Sign_result} /> :
                                            <div className="signature_block mb-10">
                                                <SignaturePad canvasProps={{ className: "sigPad" }} ref={(ref) => { periop_assessed_Sign = ref }} />
                                                <p>Signature </p>
                                                <button onClick={(e) => trim(e, "periop_assessed_Sign", periop_assessed_Sign)}>Trim</button>
                                                <button onClick={(e) => clear(e, periop_assessed_Sign)}> Clear</button>
                                            </div>}
                                        </div>
                                    </div>
                                </div>
                                <div className="section simple_wound ctr">
                                    <h2 className="popup_title">BLOOD LOSS</h2>
                                    <div className="pd_border"></div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="measure tqt">
                                                <label>Estimated total blood loss</label>
                                                <div className="dsp_flex"> <input type="text" onChange={(e) => updateInputValue(e)} name="periop_estimated_blood_loss" className="field" />
                                                    <span>ml </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="measure tqt">
                                                <label>Suction volume</label>
                                                <div className="dsp_flex"> <input type="text" onChange={(e) => updateInputValue(e)} name="periop_Suction_volume" className="field" />
                                                    <span>ml </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="measure tqt mt-10">
                                                <label>Swab weight</label>
                                                <div className="dsp_flex"> <input type="text" onChange={(e) => updateInputValue(e)} name="periop_Swab_weight" className="field" />
                                                    <span>gm </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mt-10">
                                            <div className="measure tqt">
                                                <label>Irrigation used</label>
                                                <div className="dsp_flex"> <input type="text" onChange={(e) => updateInputValue(e)} name="periop_Irrigation_used" className="field" />
                                                    <span>gm </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="yn_switch mb-0  mt-20">
                                        <div className="label-container">
                                            <p>Blood loss charted on fluid balance chart Care pathway completed </p>
                                        </div>
                                        <label className="switch-container">
                                            <input onChange={(e) => handleChange(e)} name="periop_blood_loss_charted" type="checkbox" className="switch_check" />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>YES</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>NO</span>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                <div className="section simple_wound ctr">
                                    <h2 className="popup_title">BEFORE PATIENT LEAVES THE OPERATING ROOM</h2>
                                    <div className="pd_border"></div>
                                    <p className="note2">Select all that apply for Multiselect forms</p>
                                    {clinicValues.periop_patient_leaves && clinicValues.periop_patient_leaves_list && clinicValues.periop_patient_leaves_list.length > 0 ? <div className="check_list w_white mb-0">

                                        {clinicValues.periop_patient_leaves_list.map((item, i) => (
                                            <div className="check_item active" key={i} onClick={(e) => updateClinicValues(e, "periop_patient_leaves", false)} ><label className="check">{item}</label></div>
                                        ))}

                                    </div> :
                                        <div className="check_list w_white mb-0">

                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "periop_patient_leaves_list", "‘Sign Out’ stage of WHO Surgical Safety Final Verbal Checklist read OUT LOUD with no discrepancies", "periop_patient_leaves")} ><label className="check">‘Sign Out’ stage of WHO Surgical Safety Final Verbal Checklist read OUT LOUD with no discrepancies </label>
                                            </div>
                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "periop_patient_leaves_list", "Scrub practitioner & circulating practitioner have remained unchanged throughout procedure", "periop_patient_leaves")} ><label className="check">Scrub practitioner & circulating
                                                practitioner have remained unchanged throughout procedure</label>
                                            </div>
                                        </div>}


                                    <div className="row mt-20">
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="periop_Scrub_practitioner"
                                                    className="form-control" /><label
                                                        className="floating-label">Scrub practitioner</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                        {file.periop_Scrub_practitioner_Sign_result || clinicValues.periop_Scrub_practitioner_Sign_result ? <img src={file.periop_Scrub_practitioner_Sign_result || clinicValues.periop_Scrub_practitioner_Sign_result} /> :
                                            <div className="signature_block mb-10">
                                                <SignaturePad canvasProps={{ className: "sigPad" }} ref={(ref) => { periop_Scrub_practitioner_Sign = ref }} />
                                                <p>Signature </p>
                                                <button onClick={(e) => trim(e, "periop_Scrub_practitioner_Sign", periop_Scrub_practitioner_Sign)}>Trim</button>
                                                <button onClick={(e) => clear(e, periop_Scrub_practitioner_Sign)}> Clear</button>
                                            </div>}

                                        </div>
                                        <div className="col-md-3">
                                            <div className="form_group">
                                                <div className="floating-label-group"><input type="date" onChange={(e) => updateInputValue(e)} name="periop_Scrub_practitioner_date"
                                                    className="form-control" /><label
                                                        className="floating-label">Date</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form_group">
                                                <div className="floating-label-group"><input type="time" onChange={(e) => updateInputValue(e)} name="periop_Scrub_practitioner_time"
                                                    className="form-control" /><label
                                                        className="floating-label">Time</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-20">
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="periop_Circulating_practitioner"
                                                    className="form-control" /><label
                                                        className="floating-label">Circulating practitioner</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                        {file.periop_Circulating_practitioner_Sign_result || clinicValues.periop_Circulating_practitioner_Sign_result ? <img src={file.periop_Circulating_practitioner_Sign_result || clinicValues.periop_Circulating_practitioner_Sign_result} /> :
                                            <div className="signature_block mb-10">
                                                <SignaturePad canvasProps={{ className: "sigPad" }} ref={(ref) => { periop_Circulating_practitioner_Sign = ref }} />
                                                <p>Signature </p>
                                                <button onClick={(e) => trim(e, "periop_Circulating_practitioner_Sign", periop_Circulating_practitioner_Sign)}>Trim</button>
                                                <button onClick={(e) => clear(e, periop_Circulating_practitioner_Sign)}> Clear</button>
                                            </div>}
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form_group">
                                                <div className="floating-label-group"><input type="date" onChange={(e) => updateInputValue(e)} name="periop_Circulating_practitioner_date"
                                                    className="form-control" /><label
                                                        className="floating-label">Date</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form_group">
                                                <div className="floating-label-group"><input type="time" onChange={(e) => updateInputValue(e)} name="periop_Circulating_practitioner_time"
                                                    className="form-control" /><label
                                                        className="floating-label">Time</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="section simple_wound ctr">
                                    <h2 className="popup_title">TIME OUT</h2>
                                    <div className="pd_border"></div>

                                    {clinicValues.periop_timeout ? <div className="check_list w_white mb-20">


                                        <div className="check_item active" onClick={(e) => updateClinicValues(e, "periop_timeout", false)} ><label className="check">{clinicValues.periop_timeout}</label></div>


                                    </div> :
                                        <div className="check_list w_white mb-20">
                                            <div className="check_item" onClick={(e) => updateClinicValues(e, "periop_timeout", "Hand over from theatre to recovery check list completed and signed by both practitioners")}><label className="check">Hand over from theatre to recovery check list completed and signed by both practitioners</label>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="section simple_wound ctr">
                                    <h2 className="popup_title">COUNTS</h2>
                                    <div className="pd_border"></div>
                                    <table className="code_table table-bordered sin">
                                        <thead>
                                            <tr>
                                                <th width="30%"></th>
                                                <th width="10%">Initial count</th>
                                                <th width="10%">First count</th>
                                                <th width="10%">Cavity count</th>
                                                <th width="10%">Final count</th>
                                                <th width="30%">Count status </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td width="30%"> <b>Instruments number</b></td>
                                                <td width="10%"><input type="text" className="field" onChange={(e) => updateInputValue(e)} name="periop_intial_count_instrument" autoComplete="off" /></td>
                                                <td width="10%"><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="periop_first_count_instrument" /></td>
                                                <td width="10%"><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="periop_cavity_count_instrument" /></td>
                                                <td width="10%"><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="periop_final_count_instrument" /></td>
                                                <td width="30%">
                                                    <div className="check_item mt-0">
                                                        <div className="select_btns popup_select mt-0">
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_instrument" type="radio" value="Correct" /><span>Correct
                                                            </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_instrument" type="radio" value="Incorrect" /><span>Incorrect </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_instrument" type="radio" value="Emergency-life" /><span>Emergency-life </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_instrument" type="radio" value="limb no count" /><span>limb no count </span></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="30%"> <b>10x10 XRD Swabs</b></td>
                                                <td width="10%"><input type="text" className="field" onChange={(e) => updateInputValue(e)} name="periop_intial_count_Swabs" autoComplete="off" /></td>
                                                <td width="10%"><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="periop_first_count_Swabs" /></td>
                                                <td width="10%"><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="periop_cavity_count_Swabs" /></td>
                                                <td width="10%"><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="periop_final_count_Swabs" /></td>
                                                <td width="30%">
                                                    <div className="check_item mt-0">
                                                        <div className="select_btns popup_select mt-0">
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_Swabs" type="radio" value="Correct" /><span>Correct
                                                            </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_Swabs" type="radio" value="Incorrect" /><span>Incorrect </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_Swabs" type="radio" value="Emergency-life" /><span>Emergency-life </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_Swabs" type="radio" value="limb no count" /><span>limb no count </span></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="30%"> <b>45 x 45 XRD Swabs</b></td>
                                                <td width="10%"><input type="text" className="field" onChange={(e) => updateInputValue(e)} name="periop_intial_count_45Swabs" autoComplete="off" /></td>
                                                <td width="10%"><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="periop_first_count_45Swabs" /></td>
                                                <td width="10%"><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="periop_cavity_count_45Swabs" /></td>
                                                <td width="10%"><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="periop_final_count_45Swabs" /></td>
                                                <td width="30%">
                                                    <div className="check_item mt-0">
                                                        <div className="select_btns popup_select mt-0">
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_45Swabs" type="radio" value="Correct" /><span>Correct
                                                            </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_45Swabs" type="radio" value="Incorrect" /><span>Incorrect </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_45Swabs" type="radio" value="Emergency-life" /><span>Emergency-life </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_45Swabs" type="radio" value="limb no count" /><span>limb no count </span></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="30%"> <b>Tags</b></td>
                                                <td width="10%"><input type="text" className="field" onChange={(e) => updateInputValue(e)} name="periop_intial_count_Tags" autoComplete="off" /></td>
                                                <td width="10%"><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="periop_first_count_Tags" /></td>
                                                <td width="10%"><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="periop_cavity_count_Tags" /></td>
                                                <td width="10%"><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="periop_final_count_Tags" /></td>
                                                <td width="30%">
                                                    <div className="check_item mt-0">
                                                        <div className="select_btns popup_select mt-0">
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_Tags" type="radio" value="Correct" /><span>Correct
                                                            </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_Tags" type="radio" value="Incorrect" /><span>Incorrect </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_Tags" type="radio" value="Emergency-life" /><span>Emergency-life </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_Tags" type="radio" value="limb no count" /><span>limb no count </span></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="30%"> <b>Needles</b></td>
                                                <td width="10%"><input type="text" className="field" onChange={(e) => updateInputValue(e)} name="periop_intial_count_Needles" autoComplete="off" /></td>
                                                <td width="10%"><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="periop_first_count_Needles" /></td>
                                                <td width="10%"><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="periop_cavity_count_Needles" /></td>
                                                <td width="10%"><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="periop_final_count_Needles" /></td>
                                                <td width="30%">
                                                    <div className="check_item mt-0">
                                                        <div className="select_btns popup_select mt-0">
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_Needles" type="radio" value="Correct" /><span>Correct
                                                            </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_Needles" type="radio" value="Incorrect" /><span>Incorrect </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_Needles" type="radio" value="Emergency-life" /><span>Emergency-life </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_Needles" type="radio" value="limb no count" /><span>limb no count </span></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="30%"> <b>Blades</b></td>
                                                <td width="10%"><input type="text" className="field" onChange={(e) => updateInputValue(e)} name="periop_intial_count_Blades" autoComplete="off" /></td>
                                                <td width="10%"><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="periop_first_count_Blades" /></td>
                                                <td width="10%"><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="periop_cavity_count_Blades" /></td>
                                                <td width="10%"><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="periop_final_count_Blades" /></td>
                                                <td width="30%">
                                                    <div className="check_item mt-0">
                                                        <div className="select_btns popup_select mt-0">
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_Blades" type="radio" value="Correct" /><span>Correct
                                                            </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_Blades" type="radio" value="Incorrect" /><span>Incorrect </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_Blades" type="radio" value="Emergency-life" /><span>Emergency-life </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_Blades" type="radio" value="limb no count" /><span>limb no count </span></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="30%"> <b>Blade removers</b></td>
                                                <td width="10%"><input type="text" className="field" onChange={(e) => updateInputValue(e)} name="periop_intial_count_Blades" autoComplete="off" /></td>
                                                <td width="10%"><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="periop_first_count_Blades" /></td>
                                                <td width="10%"><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="periop_cavity_count_Blades" /></td>
                                                <td width="10%"><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="periop_final_count_Blades" /></td>
                                                <td width="30%">
                                                    <div className="check_item mt-0">
                                                        <div className="select_btns popup_select mt-0">
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_Blades" type="radio" value="Correct" /><span>Correct
                                                            </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_Blades" type="radio" value="Incorrect" /><span>Incorrect </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_Blades" type="radio" value="Emergency-life" /><span>Emergency-life </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_Blades" type="radio" value="limb no count" /><span>limb no count </span></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="30%"> <b>Nylon Tape</b></td>
                                                <td width="10%"><input type="text" className="field" onChange={(e) => updateInputValue(e)} name="periop_intial_count_Nylon" autoComplete="off" /></td>
                                                <td width="10%"><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="periop_first_count_Nylon" /></td>
                                                <td width="10%"><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="periop_cavity_count_Nylon" /></td>
                                                <td width="10%"><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="periop_final_count_Nylon" /></td>
                                                <td width="30%">
                                                    <div className="check_item mt-0">
                                                        <div className="select_btns popup_select mt-0">
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_Nylon" type="radio" value="Correct" /><span>Correct
                                                            </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_Nylon" type="radio" value="Incorrect" /><span>Incorrect </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_Nylon" type="radio" value="Emergency-life" /><span>Emergency-life </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_Nylon" type="radio" value="limb no count" /><span>limb no count </span></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="30%"> <b>Hypodermic Needles</b></td>
                                                <td width="10%"><input type="text" className="field" onChange={(e) => updateInputValue(e)} name="periop_intial_count_Hypodermic" autoComplete="off" /></td>
                                                <td width="10%"><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="periop_first_count_Hypodermic" /></td>
                                                <td width="10%"><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="periop_cavity_count_Hypodermic" /></td>
                                                <td width="10%"><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="periop_final_count_Hypodermic" /></td>
                                                <td width="30%">
                                                    <div className="check_item mt-0">
                                                        <div className="select_btns popup_select mt-0">
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_Hypodermic" type="radio" value="Correct" /><span>Correct
                                                            </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_Hypodermic" type="radio" value="Incorrect" /><span>Incorrect </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_Hypodermic" type="radio" value="Emergency-life" /><span>Emergency-life </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_Hypodermic" type="radio" value="limb no count" /><span>limb no count </span></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="30%"> <b>Patties</b></td>
                                                <td width="10%"><input type="text" className="field" onChange={(e) => updateInputValue(e)} name="periop_intial_count_Patties" autoComplete="off" /></td>
                                                <td width="10%"><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="periop_first_count_Patties" /></td>
                                                <td width="10%"><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="periop_cavity_count_Patties" /></td>
                                                <td width="10%"><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="periop_final_count_Patties" /></td>
                                                <td width="30%">
                                                    <div className="check_item mt-0">
                                                        <div className="select_btns popup_select mt-0">
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_Patties" type="radio" value="Correct" /><span>Correct
                                                            </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_Patties" type="radio" value="Incorrect" /><span>Incorrect </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_Patties" type="radio" value="Emergency-life" /><span>Emergency-life </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_Patties" type="radio" value="limb no count" /><span>limb no count </span></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="30%"> <b>Tip Cleaner</b></td>
                                                <td width="10%"><input type="text" className="field" onChange={(e) => updateInputValue(e)} name="periop_intial_count_TipCleaner" autoComplete="off" /></td>
                                                <td width="10%"><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="periop_first_count_TipCleaner" /></td>
                                                <td width="10%"><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="periop_cavity_count_TipCleaner" /></td>
                                                <td width="10%"><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="periop_final_count_TipCleaner" /></td>
                                                <td width="30%">
                                                    <div className="check_item mt-0">
                                                        <div className="select_btns popup_select mt-0">
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_TipCleaner" type="radio" value="Correct" /><span>Correct
                                                            </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_TipCleaner" type="radio" value="Incorrect" /><span>Incorrect </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_TipCleaner" type="radio" value="Emergency-life" /><span>Emergency-life </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_TipCleaner" type="radio" value="limb no count" /><span>limb no count </span></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="30%"> <b>Syringes</b></td>
                                                <td width="10%"><input type="text" className="field" onChange={(e) => updateInputValue(e)} name="periop_intial_count_Syringes" autoComplete="off" /></td>
                                                <td width="10%"><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="periop_first_count_Syringes" /></td>
                                                <td width="10%"><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="periop_cavity_count_Syringes" /></td>
                                                <td width="10%"><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="periop_final_count_Syringes" /></td>
                                                <td width="30%">
                                                    <div className="check_item mt-0">
                                                        <div className="select_btns popup_select mt-0">
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_Syringes" type="radio" value="Correct" /><span>Correct
                                                            </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_Syringes" type="radio" value="Incorrect" /><span>Incorrect </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_Syringes" type="radio" value="Emergency-life" /><span>Emergency-life </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_Syringes" type="radio" value="limb no count" /><span>limb no count </span></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="30%"> <b>Ribbon Gauze</b></td>
                                                <td width="10%"><input type="text" className="field" onChange={(e) => updateInputValue(e)} name="periop_intial_count_Ribbon" autoComplete="off" /></td>
                                                <td width="10%"><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="periop_first_count_Ribbon" /></td>
                                                <td width="10%"><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="periop_cavity_count_Ribbon" /></td>
                                                <td width="10%"><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="periop_final_count_Ribbon" /></td>
                                                <td width="30%">
                                                    <div className="check_item mt-0">
                                                        <div className="select_btns popup_select mt-0">
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_Ribbon" type="radio" value="Correct" /><span>Correct
                                                            </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_Ribbon" type="radio" value="Incorrect" /><span>Incorrect </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_Ribbon" type="radio" value="Emergency-life" /><span>Emergency-life </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_Ribbon" type="radio" value="limb no count" /><span>limb no count </span></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="30%"> <b>Saw Blades</b></td>
                                                <td width="10%"><input type="text" className="field" onChange={(e) => updateInputValue(e)} name="periop_intial_count_SawBlades" autoComplete="off" /></td>
                                                <td width="10%"><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="periop_first_count_SawBlades" /></td>
                                                <td width="10%"><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="periop_cavity_count_SawBlades" /></td>
                                                <td width="10%"><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="periop_final_count_SawBlades" /></td>
                                                <td width="30%">
                                                    <div className="check_item mt-0">
                                                        <div className="select_btns popup_select mt-0">
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_SawBlades" type="radio" value="Correct" /><span>Correct
                                                            </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_SawBlades" type="radio" value="Incorrect" /><span>Incorrect </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_SawBlades" type="radio" value="Emergency-life" /><span>Emergency-life </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_SawBlades" type="radio" value="limb no count" /><span>limb no count </span></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="30%"> <b>Wires</b></td>
                                                <td width="10%"><input type="text" className="field" onChange={(e) => updateInputValue(e)} name="periop_intial_count_Wires" autoComplete="off" /></td>
                                                <td width="10%"><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="periop_first_count_Wires" /></td>
                                                <td width="10%"><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="periop_cavity_count_Wires" /></td>
                                                <td width="10%"><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="periop_final_count_Wires" /></td>
                                                <td width="30%">
                                                    <div className="check_item mt-0">
                                                        <div className="select_btns popup_select mt-0">
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_Wires" type="radio" value="Correct" /><span>Correct
                                                            </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_Wires" type="radio" value="Incorrect" /><span>Incorrect </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_Wires" type="radio" value="Emergency-life" /><span>Emergency-life </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_Wires" type="radio" value="limb no count" /><span>limb no count </span></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="30%"> <b>Taps</b></td>
                                                <td width="10%"><input type="text" className="field" onChange={(e) => updateInputValue(e)} name="periop_intial_count_Taps" autoComplete="off" /></td>
                                                <td width="10%"><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="periop_first_count_Taps" /></td>
                                                <td width="10%"><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="periop_cavity_count_Taps" /></td>
                                                <td width="10%"><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="periop_final_count_Taps" /></td>
                                                <td width="30%">
                                                    <div className="check_item mt-0">
                                                        <div className="select_btns popup_select mt-0">
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_Taps" type="radio" value="Correct" /><span>Correct
                                                            </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_Taps" type="radio" value="Incorrect" /><span>Incorrect </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_Taps" type="radio" value="Emergency-life" /><span>Emergency-life </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_Taps" type="radio" value="limb no count" /><span>limb no count </span></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="30%"> <b>Drills</b></td>
                                                <td width="10%"><input type="text" className="field" onChange={(e) => updateInputValue(e)} name="periop_intial_count_Drills" autoComplete="off" /></td>
                                                <td width="10%"><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="periop_first_count_Drills" /></td>
                                                <td width="10%"><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="periop_cavity_count_Drills" /></td>
                                                <td width="10%"><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="periop_final_count_Drills" /></td>
                                                <td width="30%">
                                                    <div className="check_item mt-0">
                                                        <div className="select_btns popup_select mt-0">
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_Drills" type="radio" value="Correct" /><span>Correct
                                                            </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_Drills" type="radio" value="Incorrect" /><span>Incorrect </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_Drills" type="radio" value="Emergency-life" /><span>Emergency-life </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_Drills" type="radio" value="limb no count" /><span>limb no count </span></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="30%"> <b>Suction Cath</b></td>
                                                <td width="10%"><input type="text" className="field" onChange={(e) => updateInputValue(e)} name="periop_intial_count_Suction" autoComplete="off" /></td>
                                                <td width="10%"><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="periop_first_count_Suction" /></td>
                                                <td width="10%"><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="periop_cavity_count_Suction" /></td>
                                                <td width="10%"><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="periop_final_count_Suction" /></td>
                                                <td width="30%">
                                                    <div className="check_item mt-0">
                                                        <div className="select_btns popup_select mt-0">
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_Suction" type="radio" value="Correct" /><span>Correct
                                                            </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_Suction" type="radio" value="Incorrect" /><span>Incorrect </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_Suction" type="radio" value="Emergency-life" /><span>Emergency-life </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_Suction" type="radio" value="limb no count" /><span>limb no count </span></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="30%"> <b>Lampert & Stillett</b></td>
                                                <td width="10%"><input type="text" className="field" onChange={(e) => updateInputValue(e)} name="periop_intial_count_Lampert" autoComplete="off" /></td>
                                                <td width="10%"><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="periop_first_count_Lampert" /></td>
                                                <td width="10%"><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="periop_cavity_count_Lampert" /></td>
                                                <td width="10%"><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="periop_final_count_Lampert" /></td>
                                                <td width="30%">
                                                    <div className="check_item mt-0">
                                                        <div className="select_btns popup_select mt-0">
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_Lampert" type="radio" value="Correct" /><span>Correct
                                                            </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_Lampert" type="radio" value="Incorrect" /><span>Incorrect </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_Lampert" type="radio" value="Emergency-life" /><span>Emergency-life </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_Lampert" type="radio" value="limb no count" /><span>limb no count </span></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="30%"> <b>Diathermy Tip</b></td>
                                                <td width="10%"><input type="text" className="field" onChange={(e) => updateInputValue(e)} name="periop_intial_count_Diathermy" autoComplete="off" /></td>
                                                <td width="10%"><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="periop_first_count_Diathermy" /></td>
                                                <td width="10%"><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="periop_cavity_count_Diathermy" /></td>
                                                <td width="10%"><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="periop_final_count_Diathermy" /></td>
                                                <td width="30%">
                                                    <div className="check_item mt-0">
                                                        <div className="select_btns popup_select mt-0">
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_Diathermy" type="radio" value="Correct" /><span>Correct
                                                            </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_Diathermy" type="radio" value="Incorrect" /><span>Incorrect </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_Diathermy" type="radio" value="Emergency-life" /><span>Emergency-life </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_Diathermy" type="radio" value="limb no count" /><span>limb no count </span></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="30%"> <b>Pen</b></td>
                                                <td width="10%"><input type="text" className="field" onChange={(e) => updateInputValue(e)} name="periop_intial_count_Pen" autoComplete="off" /></td>
                                                <td width="10%"><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="periop_first_count_Pen" /></td>
                                                <td width="10%"><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="periop_cavity_count_Pen" /></td>
                                                <td width="10%"><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="periop_final_count_Pen" /></td>
                                                <td width="30%">
                                                    <div className="check_item mt-0">
                                                        <div className="select_btns popup_select mt-0">
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_Pen" type="radio" value="Correct" /><span>Correct
                                                            </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_Pen" type="radio" value="Incorrect" /><span>Incorrect </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_Pen" type="radio" value="Emergency-life" /><span>Emergency-life </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_Pen" type="radio" value="limb no count" /><span>limb no count </span></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="30%"> <b>Shaver blade</b></td>
                                                <td width="10%"><input type="text" className="field" onChange={(e) => updateInputValue(e)} name="periop_intial_count_Shaver" autoComplete="off" /></td>
                                                <td width="10%"><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="periop_first_count_Shaver" /></td>
                                                <td width="10%"><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="periop_cavity_count_Shaver" /></td>
                                                <td width="10%"><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="periop_final_count_Shaver" /></td>
                                                <td width="30%">
                                                    <div className="check_item mt-0">
                                                        <div className="select_btns popup_select mt-0">
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_Shaver" type="radio" value="Correct" /><span>Correct
                                                            </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_Shaver" type="radio" value="Incorrect" /><span>Incorrect </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_Shaver" type="radio" value="Emergency-life" /><span>Emergency-life </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_Shaver" type="radio" value="limb no count" /><span>limb no count </span></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="30%"> <b>Rulers</b></td>
                                                <td width="10%"><input type="text" className="field" onChange={(e) => updateInputValue(e)} name="periop_intial_count_Rulers" autoComplete="off" /></td>
                                                <td width="10%"><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="periop_first_count_Rulers" /></td>
                                                <td width="10%"><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="periop_cavity_count_Rulers" /></td>
                                                <td width="10%"><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="periop_final_count_Rulers" /></td>
                                                <td width="30%">
                                                    <div className="check_item mt-0">
                                                        <div className="select_btns popup_select mt-0">
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_Rulers" type="radio" value="Correct" /><span>Correct
                                                            </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_Rulers" type="radio" value="Incorrect" /><span>Incorrect </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_Rulers" type="radio" value="Emergency-life" /><span>Emergency-life </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_Rulers" type="radio" value="limb no count" /><span>limb no count </span></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="30%"> <b>Stickers</b></td>
                                                <td width="10%"><input type="text" className="field" onChange={(e) => updateInputValue(e)} name="periop_intial_count_Stickers" autoComplete="off" /></td>
                                                <td width="10%"><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="periop_first_count_Stickers" /></td>
                                                <td width="10%"><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="periop_cavity_count_Stickers" /></td>
                                                <td width="10%"><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="periop_final_count_Stickers" /></td>
                                                <td width="30%">
                                                    <div className="check_item mt-0">
                                                        <div className="select_btns popup_select mt-0">
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_Stickers" type="radio" value="Correct" /><span>Correct
                                                            </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_Stickers" type="radio" value="Incorrect" /><span>Incorrect </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_Stickers" type="radio" value="Emergency-life" /><span>Emergency-life </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_Stickers" type="radio" value="limb no count" /><span>limb no count </span></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="30%">
                                                    <div className="form_group">
                                                        <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="periop_performed_by" className="form-control" /><label className="floating-label">Performed by</label>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td width="10%"><input type="text" className="field" onChange={(e) => updateInputValue(e)} name="periop_intial_count_performed_by" autoComplete="off" /></td>
                                                <td width="10%"><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="periop_first_count_performed_by" /></td>
                                                <td width="10%"><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="periop_cavity_count_performed_by" /></td>
                                                <td width="10%"><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="periop_final_count_performed_by" /></td>
                                                <td width="30%">
                                                    <div className="check_item mt-0">
                                                        <div className="select_btns popup_select mt-0">
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_performed_by" type="radio" value="Correct" /><span>Correct
                                                            </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_performed_by" type="radio" value="Incorrect" /><span>Incorrect </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_performed_by" type="radio" value="Emergency-life" /><span>Emergency-life </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_performed_by" type="radio" value="limb no count" /><span>limb no count </span></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="30%"> <b>Surgeon notified of counts</b></td>
                                                <td width="10%"><input type="text" className="field" onChange={(e) => updateInputValue(e)} name="periop_intial_count_Surgeon" autoComplete="off" /></td>
                                                <td width="10%"><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="periop_first_count_Surgeon" /></td>
                                                <td width="10%"><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="periop_cavity_count_Surgeon" /></td>
                                                <td width="10%"><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="periop_final_count_Surgeon" /></td>
                                                <td width="30%">
                                                    <div className="check_item mt-0">
                                                        <div className="select_btns popup_select mt-0">
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_Surgeon" type="radio" value="Correct" /><span>Correct
                                                            </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_Surgeon" type="radio" value="Incorrect" /><span>Incorrect </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_Surgeon" type="radio" value="Emergency-life" /><span>Emergency-life </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_Surgeon" type="radio" value="limb no count" /><span>limb no count </span></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="30%">  <div className="form_group">
                                                    <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="periop_performed_by_another" className="form-control" /><label className="floating-label">Performed by</label>
                                                    </div>
                                                </div></td>
                                                <td width="10%"><input type="text" className="field" onChange={(e) => updateInputValue(e)} name="periop_intial_count_performed_by_another" autoComplete="off" /></td>
                                                <td width="10%"><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="periop_first_count_performed_by_another" /></td>
                                                <td width="10%"><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="periop_cavity_count_performed_by_another" /></td>
                                                <td width="10%"><input type="text" className="field" autoComplete="off" onChange={(e) => updateInputValue(e)} name="periop_final_count_performed_by_another" /></td>
                                                <td width="30%">
                                                    <div className="check_item mt-0">
                                                        <div className="select_btns popup_select mt-0">
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_performed_by_another" type="radio" value="Correct" /><span>Correct
                                                            </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_performed_by_another" type="radio" value="Incorrect" /><span>Incorrect </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_performed_by_another" type="radio" value="Emergency-life" /><span>Emergency-life </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="periop_count_status_performed_by_another" type="radio" value="limb no count" /><span>limb no count </span></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>


                                        </tbody>
                                    </table>
                                </div>

                                <div className="section simple_wound ctr">
                                    <h2 className="popup_title">NURSES CHECK OUT</h2>
                                    <div className="pd_border"></div>
                                    <p className="field_title mt-0">Initial count correct</p>
                                    <p className="note2">Select all that apply for Multiselect forms</p>
                                    {clinicValues.periop_Initial_check && clinicValues.periop_Initial_checklist && clinicValues.periop_Initial_checklist.length > 0 ? <div className="check_list w_white mb-0">

                                        {clinicValues.periop_Initial_checklist.map((item, i) => (
                                            <div className="check_item active" key={i} onClick={(e) => updateClinicValues(e, "periop_Initial_check", false)} ><label className="check">{item}</label></div>
                                        ))}

                                    </div> :
                                        <div className="check_list w_white mb-0">

                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "periop_Initial_checklist", "Swab", "periop_Initial_check")}><label className="check">Swab </label></div>
                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "periop_Initial_checklist", "Needle", "periop_Initial_check")}><label className="check">Needle </label>
                                            </div>
                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "periop_Initial_checklist", "Instrument", "periop_Initial_check")}><label className="check"> Instrument </label></div>
                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "periop_Initial_checklist", "Supplementary", "periop_Initial_check")}><label className="check">Supplementary </label></div>
                                        </div>}

                                    <p className="field_title mt-30">Interim count correct</p>
                                    <p className="note2">Select all that apply for Multiselect forms</p>
                                    {clinicValues.periop_Interim_check && clinicValues.periop_Interim_checklist && clinicValues.periop_Interim_checklist.length > 0 ? <div className="check_list w_white mb-0">

                                        {clinicValues.periop_Interim_checklist.map((item, i) => (
                                            <div className="check_item active" key={i} onClick={(e) => updateClinicValues(e, "periop_Interim_check", false)} ><label className="check">{item}</label></div>
                                        ))}

                                    </div> :
                                        <div className="check_list w_white mb-0">

                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "periop_Interim_checklist", "Swab", "periop_Interim_check")}><label className="check">Swab </label></div>
                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "periop_Interim_checklist", "Needle", "periop_Interim_check")}><label className="check">Needle </label>
                                            </div>
                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "periop_Interim_checklist", "Instrument", "periop_Interim_check")}><label className="check"> Instrument </label></div>
                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "periop_Interim_checklist", "Supplementary", "periop_Interim_check")}><label className="check">Supplementary </label></div>
                                        </div>}

                                    <p className="field_title mt-30">Final count correct</p>
                                    <p className="note2">Select all that apply for Multiselect forms</p>

                                    {clinicValues.periop_Final_check && clinicValues.periop_Final_checklist && clinicValues.periop_Final_checklist.length > 0 ? <div className="check_list w_white mb-0">

                                        {clinicValues.periop_Final_checklist.map((item, i) => (
                                            <div className="check_item active" key={i} onClick={(e) => updateClinicValues(e, "periop_Final_check", false)} ><label className="check">{item}</label></div>
                                        ))}

                                    </div> :

                                        <div className="check_list w_white mb-0">

                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "periop_Final_checklist", "Swab", "periop_Final_check")}><label className="check">Swab </label></div>
                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "periop_Final_checklist", "Needle", "periop_Final_check")}><label className="check">Needle </label>
                                            </div>
                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "periop_Final_checklist", "Instrument", "periop_Final_check")}><label className="check"> Instrument </label></div>
                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "periop_Final_checklist", "Supplementary", "periop_Final_check")}><label className="check">Supplementary </label></div>
                                        </div>}

                                </div>

                                <div className="section simple_wound ctr">
                                    <h2 className="popup_title">POST-OPERATIVE CHECK</h2>
                                    <div className="pd_border"></div>
                                    <div className="yn_switch mb-20  mt-20">
                                        <div className="label-container">
                                            <p>Swabs - Needles - Instruments - Sharps/Blades Count correct? </p>
                                        </div>
                                        <label className="switch-container">
                                            <input onChange={(e) => handleChange(e)} name="periop_post_operative" type="checkbox" className="switch_check" />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>YES</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>NO</span>
                                            </div>
                                        </label>

                                    </div>
                                    <div className="yn_switch mb-20  mt-20">
                                        <div className="label-container">
                                            <p>Surgeon's acknowledgement of count? </p>
                                        </div>
                                        <label className="switch-container">
                                            <input onChange={(e) => handleChange(e)} name="periop_Surgeon_acknowledgement" type="checkbox" className="switch_check" />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>YES</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>NO</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="form_group">
                                        <div className="floating-label-group"><input type="text" onChange={(e) => handleChange(e)} name="periop_Surgeon_Discrepancies"
                                            className="form-control" /><label
                                                className="floating-label">Discrepancies, if any (specify)</label>
                                        </div>
                                    </div>
                                    <div className="yn_switch mb-20  mt-20">
                                        <div className="label-container">
                                            <p>Surgeon informed? </p>
                                        </div>
                                        <label className="switch-container">
                                            <input onChange={(e) => handleChange(e)} name="periop_Surgeon_informed" type="checkbox" className="switch_check" />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>YES</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>NO</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="form_group">
                                        <div className="floating-label-group"><input type="text" onChange={(e) => handleChange(e)} name="periop_Action_taken"
                                            className="form-control" /><label
                                                className="floating-label">Action taken regarding discrepancy</label>
                                        </div>
                                    </div>
                                    <div className="yn_switch mb-20  mt-20">
                                        <div className="label-container">
                                            <p>IMPLANT DATA entered onto implant register</p>
                                        </div>
                                        <label className="switch-container">
                                            <input onChange={(e) => handleChange(e)} name="periop_implant_data" type="checkbox" className="switch_check" />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>YES</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>NO</span>
                                            </div>
                                        </label>
                                    </div>
                                    <p className="field_title mt-30 bold">TRAYS & INSTRUMENTS</p>
                                    <div className="form_group">
                                        <div className="floating-label-group"><input type="text" onChange={(e) => handleChange(e)} name="periop_Traceability"
                                            className="form-control" /><label
                                                className="floating-label">Batch Numbers-Traceability label</label>
                                        </div>
                                    </div>
                                    <p className="field_title mt-30 bold">SCAN LABLES</p>
                                    <div className="row mt-10">
                                        <div className="col-md-6">
                                            
                                            {file.periop_circulating_Sign_result || clinicValues.periop_circulating_Sign_result ? <img src={file.periop_circulating_Sign_result || clinicValues.periop_circulating_Sign_result} /> :
                                            <div className="signature_block mb-10">
                                                <SignaturePad canvasProps={{ className: "sigPad" }} ref={(ref) => { periop_circulating_Sign = ref }} />
                                                <p>Signed (circulating person) </p>
                                                <button onClick={(e) => trim(e, "periop_circulating_Sign", periop_circulating_Sign)}>Trim</button>
                                                <button onClick={(e) => clear(e, periop_circulating_Sign)}> Clear</button>
                                            </div>}

                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="floating-label-group"><input type="text" onChange={(e) => handleChange(e)} name="periop_circulating_printname"
                                                    className="form-control" /><label
                                                        className="floating-label">Print name</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                           
                                            {file.periop_scrub_Sign_result || clinicValues.periop_scrub_Sign_result ? <img src={file.periop_scrub_Sign_result || clinicValues.periop_scrub_Sign_result} /> :
                                            <div className="signature_block mb-10">
                                                <SignaturePad canvasProps={{ className: "sigPad" }} ref={(ref) => { periop_scrub_Sign = ref }} />
                                                <p>Signed (scrub person) </p>
                                                <button onClick={(e) => trim(e, "periop_scrub_Sign", periop_scrub_Sign)}>Trim</button>
                                                <button onClick={(e) => clear(e, periop_scrub_Sign)}> Clear</button>
                                            </div>}

                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="floating-label-group"><input type="text" onChange={(e) => handleChange(e)} name="periop_scrub_printname"
                                                    className="form-control" /><label
                                                        className="floating-label">Print name</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="yn_switch mb-20  mt-10">
                                        <div className="label-container">
                                            <p>Throat pack removed? </p>
                                        </div>
                                        <label className="switch-container">
                                            <input onChange={(e) => handleChange(e)} name="periop_Throat_pack" type="checkbox" className="switch_check" />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>YES</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>NO</span>
                                            </div>
                                        </label>

                                    </div>
                                    <div className="yn_switch mb-20  mt-20">
                                        <div className="label-container">
                                            <p>Have blood tags been checked? </p>
                                        </div>
                                        <label className="switch-container">
                                            <input onChange={(e) => handleChange(e)} name="periop_blood_tags" type="checkbox" className="switch_check" />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>YES</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>NO</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="yn_switch mb-20  mt-20">
                                        <div className="label-container">
                                            <p>Tourniquet removed? </p>
                                        </div>
                                        <label className="switch-container">
                                            <input onChange={(e) => handleChange(e)} name="periop_Tourniquet_removed" type="checkbox" className="switch_check" />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>YES</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>NO</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="row mt-10">
                                        <div className="col-md-12">
                                            <div className="form_group">
                                                <div className="floating-label-group"><input type="text" onChange={(e) => handleChange(e)} name="periop_procedure_actually"
                                                    className="form-control" /><label
                                                        className="floating-label">The name of the procedure actually done to be
                                                        recorded</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form_group">
                                                <div className="floating-label-group"><input type="text" onChange={(e) => handleChange(e)} name="periop_equipment_problems"
                                                    className="form-control" /><label
                                                        className="floating-label">Have any equipment problems been identified
                                                        that need to be addressed</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form_group">
                                                <div className="floating-label-group"><input type="text" onChange={(e) => handleChange(e)} name="periop_Significant"
                                                    className="form-control" /><label
                                                        className="floating-label">Significant intraoperative events</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form_group">
                                                <div className="floating-label-group"><input type="text" onChange={(e) => handleChange(e)} name="periop_key_concerns"
                                                    className="form-control" /><label
                                                        className="floating-label">What are the key concerns for recovery and
                                                        management of this patient</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="section simple_wound ctr">
                                    <h2 className="popup_title">ODP CHECK OUT</h2>
                                    <div className="pd_border"></div>
                                    <p className="field_title mt-0">THEATRE SIGN OUT BEFORE TEAM LEAVE OPERATING ROOM TO BE
                                        COMPLETED BY CIRCULATING NURSE (TO BE READ OUT LOUD)</p>
                                    <div className="check_item mt-10">
                                        <div className="select_btns popup_select mt-0">
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_theatre_signout" type="radio"
                                                value="Swab" /><span>Swab </span></label>
                                            </div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_theatre_signout" type="radio"
                                                value="Instrument" /><span>Instrument </span></label>
                                            </div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="periop_theatre_signout" type="radio"
                                                value="Sharp needle count correct" /><span>Sharp needle count correct </span></label>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="note2">Select all that apply for Multiselect forms</p>

                                    {clinicValues.periop_odp_check && clinicValues.periop_odp_checklist && clinicValues.periop_odp_checklist.length > 0 ? <div className="check_list w_white mb-0">

                                        {clinicValues.periop_odp_checklist.map((item, i) => (
                                            <div className="check_item active" key={i} onClick={(e) => updateClinicValues(e, "periop_odp_check", false)} ><label className="check">{item}</label></div>
                                        ))}

                                    </div> :
                                        <div className="check_list w_white mb-0">

                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "periop_odp_checklist", "Have blood tags been checked", "periop_odp_check")}><label className="check">Have blood tags been checked </label>
                                            </div>
                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "periop_odp_checklist", "Tourniquet removed - TOURNIQUET TIME", "periop_odp_check")}><label className="check">Tourniquet removed - TOURNIQUET TIME
                                            </label>
                                            </div>
                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "periop_odp_checklist", "Throat pack removed", "periop_odp_check")}><label className="check"> Throat pack removed </label></div>
                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "periop_odp_checklist", "Confirm actual procedure done - The name of the procedure to be recorded", "periop_odp_check")}><label className="check">Confirm actual procedure done - The
                                                name of the procedure to be recorded </label></div>
                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "periop_odp_checklist", "Specimen labelled correctly", "periop_odp_check")}><label className="check">Specimen labelled correctly </label>
                                            </div>
                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "periop_odp_checklist", "Have any equipment problems been identified that need to be addressed", "periop_odp_check")}><label className="check">Have any equipment problems been
                                                identified that need to be addressed </label></div>
                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "periop_odp_checklist", "Significant intraoperative events", "periop_odp_check")}><label className="check">Significant intraoperative events
                                            </label></div>
                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "periop_odp_checklist", "IPOC completed", "periop_odp_check")}><label className="check">IPOC completed </label></div>
                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "periop_odp_checklist", "What are the key concerns for recovery and management of this patient", "periop_odp_check")}><label className="check">What are the key concerns for recovery
                                                and management of this patient </label></div>
                                        </div>}


                                </div>
                                <div className="section simple_wound ctr">
                                    <h2 className="popup_title">Close perioperative Record</h2>
                                    <div className="pd_border"></div>
                                    <div className="form_group mb-20">
                                        <textarea className="textarea" placeholder="ADDITIONAL NOTES" onChange={(e) => updateInputValue(e)} name="periop_additional_notes" rows="2"
                                            cols="30"></textarea>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="yn_switch mb-20  mt-10">
                                                <div className="label-container">
                                                    <p>CRITICAL INCIDENT? </p>
                                                </div>
                                                <label className="switch-container">
                                                    <input onChange={(e) => handleChange(e)} name="periop_critical" type="checkbox" className="switch_check" />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>YES</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>NO</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            
                                            {file.periop_nurse_Sign_result || clinicValues.periop_nurse_Sign_result ? <img src={file.periop_nurse_Sign_result || clinicValues.periop_nurse_Sign_result} /> :
                                            <div className="signature_block mb-10">
                                                <SignaturePad canvasProps={{ className: "sigPad" }} ref={(ref) => { periop_nurse_Sign = ref }} />
                                                <p>Nurse Signature </p>
                                                <button onClick={(e) => trim(e, "periop_nurse_Sign", periop_nurse_Sign)}>Trim</button>
                                                <button onClick={(e) => clear(e, periop_nurse_Sign)}> Clear</button>
                                            </div>}
                                        </div>
                                    </div>
                                    <p className="field_title mt-10 bold">CODING</p>
                                    <table className="code_table table-bordered">
                                        <thead>
                                            <tr>
                                                <th width="60%">Procedure</th>
                                                <th width="20%">Code Type</th>
                                                <th width="20%">Code</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td width="60%"><input type="text" onChange={(e) => updateInputValue(e)} name="periop_procedure_1" className="field" /></td>
                                                <td width="20%"><input type="text" onChange={(e) => updateInputValue(e)} name="periop_codetype_1" className="field" /></td>
                                                <td width="20%"> <b> 2943 </b></td>
                                            </tr>
                                            <tr>
                                                <td width="60%"><input type="text" onChange={(e) => updateInputValue(e)} name="periop_procedure_2" className="field" /></td>
                                                <td width="20%"><input type="text" onChange={(e) => updateInputValue(e)} name="periop_codetype_2" className="field" /></td>
                                                <td width="20%"> <b> 2943 </b></td>
                                            </tr>
                                            <tr>
                                                <td width="60%"><input type="text" onChange={(e) => updateInputValue(e)} name="periop_procedure_3" className="field" /></td>
                                                <td width="20%"><input type="text" onChange={(e) => updateInputValue(e)} name="periop_codetype_3" className="field" /></td>
                                                <td width="20%"> <a className="btn" data-bs-toggle="offcanvas"
                                                    data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">Get
                                                    Code</a> </td>
                                            </tr>
                                            <tr>
                                                <td width="60%"><input type="text" onChange={(e) => updateInputValue(e)} name="periop_procedure_4" className="field" /></td>
                                                <td width="20%"><input type="text" onChange={(e) => updateInputValue(e)} name="periop_codetype_4" className="field" /></td>
                                                <td width="20%"> <b> 2943 </b></td>
                                            </tr>
                                            <tr>
                                                <td width="60%"><input type="text" onChange={(e) => updateInputValue(e)} name="periop_procedure_5" className="field" /></td>
                                                <td width="20%"><input type="text" onChange={(e) => updateInputValue(e)} name="periop_codetype_5" className="field" /></td>
                                                <td width="20%"> <b> 2943 </b></td>
                                            </tr>

                                        </tbody>
                                    </table>
                                    <div className="form_group mt-30">
                                        <textarea className="textarea" placeholder="QUALITY INDICATORS REPORT" onChange={(e) => updateInputValue(e)} name="periop_quality_indicators"
                                            rows="2" cols="30"></textarea>
                                    </div>

                                </div>
                            </div>
                            <div className='popup_footer'>
                                <div className="prescribe_footer">
                                    <div className="dsp-flex">
                                        <a className="icon_btn" onClick={(e) => { updateClinic(e), theatreBack(e) }}> <span className="material-icons">save</span> SAVE AS COMPLETE </a>
                                        <button className="border_btn" onClick={(e) => { updateClinic(e) }} ><span className="material-icons">save_as</span>SEND TO PATIENT
                                            RECORD
                                        </button>
                                    </div>
                                    <button className="btn dsp_flex" onClick={(e) => { theatreBack(e) }}><span className="material-icons"><span
                                        className="material-icons">close</span></span>CANCEL
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <Modal
                    isOpen={success_msg}
                    toggle={() => {
                        setsuccess_msg(false)
                    }}
                    centered={true}
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0"></h5>
                        <button
                            type="button"
                            onClick={() => {
                                setsuccess_msg(false)
                            }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <h1>Updated Successfully</h1>
                    </div>
                </Modal>

            </div>

        </React.Fragment>
    )
}

periOperative.propTypes = {
    t: PropTypes.any,
    getWards: PropTypes.func,
    getTheatres: PropTypes.func,
    wards: PropTypes.array,
    user: PropTypes.any,
    theatres: PropTypes.array,
    clinic: PropTypes.array,
    patients: PropTypes.array,
    doctors: PropTypes.array,
    profile: PropTypes.object
}

const mapStateToProps = ({ Layout, Login }) => ({
    wards: Layout.wards,
    user: Login.user,
    theatres: Layout.theatres,
    clinic: Layout.clinic,
    patients: Layout.patients,
    doctors: Layout.doctors,
    profile: Layout.profile
})

const mapDispatchToProps = dispatch => ({
    getWards: () => dispatch(getWardsList()),
    getTheatres: () => dispatch(getTheatresList()),
    getClinic: () => dispatch(getClinicList()),
    getPatient: () => dispatch(getPatientList()),
    getUserprofile: (id) => dispatch(getUprofile(id)),
    getDoctor: () => dispatch(getDoctorList()),
    theatreupdate: (id, data) => dispatch(updateTheatrePatient(id, data)),
    wardupdate: (id, data) => dispatch(updateWardPatient(id, data)),
    patientupdate: (id, data) => dispatch(updatePatient(id, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(periOperative)
