import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import MetaTags from 'react-meta-tags';
import xray from '../../assets/images/x-ray.jpg';
import doc from '../../assets/images/doc.jpg';
import Moment from 'moment';
import Menus from "./onCallMenus";
import { connect } from "react-redux"
import { getWardsList, getTheatresList, getClinicList, getPatientList, getUprofile, getDoctorList, updatePatient, getNews, updateDailyRounds, getDailyRounds, gettableList } from "../../store/actions"

const OnCall = props => {
    document.body.style.overflow = '';
    document.body.style.padding = '';
    let clinicPatient = JSON.parse(localStorage.getItem("p"))
    const [discard_popup, setdiscard_popup] = useState(false)
    const [allied_value, setallied_value] = useState(null)
    const [clinicValues, setClinic] = useState({})
    const [wardValues, setWard] = useState({})

    const {
        wards, user, theatres, clinic, patients, profile, doctors, tablesData
    } = props;

    useEffect(() => {
        const { getDoctor, getPatient, getUserprofile, getTableData } = props

        getPatient()
        getDoctor()
        getTableData({
            method: "Get",
            concat: "patient"
        }, "team")
        let userId = localStorage.getItem("authUser")
        getUserprofile(userId)
    }, [])
    useEffect(() => {
        let clinicPatient = JSON.parse(localStorage.getItem("p"))
        console.log("clinicPatient ", clinicPatient)
        if (patients && patients.length > 0 && clinicPatient && clinicPatient._id) {
            const cPatient = patients.filter(
                usr => usr._id === clinicPatient._id
            )
            if (cPatient && cPatient[0]) {
                let cpatientData = cPatient[0]
                delete cpatientData["_id"]
                setClinic(clinicValues => ({
                    ...clinicValues,
                    ...cpatientData
                }));
            }

        }
    }, [patients])

    const updateWardValue = (e) => {
        let val = e.target.value;
        let name = e.target.name;
        let updatedValue = { [name]: val };
        setWard(wardValues => ({
            ...wardValues,
            ...updatedValue
        }));
        setClinic(clinicValues => ({
            ...clinicValues,
            ...updatedValue
        }));
    }

    const updateWard = (e) => {
        const { patientupdate } = props
        let clinicPatient = JSON.parse(localStorage.getItem("p"))
        let id = clinicPatient._id
        patientupdate(id, wardValues)
        setdiscard_popup(false)

    }

    return (
        <React.Fragment>

            <MetaTags>
                <title>ON CALL/ POST ON-CALL MEETING VIEW</title>
            </MetaTags>
            <div className='ward '>
                <div className="main n_office oncall">
                    <div className="wrapper">
                        <Menus menuName={"ON CALL/ POST ON-CALL MEETING VIEW"} />
                        <div className="meeting_panel">
                            <div className="mp_hed">
                                <div className="form-group">
                                    <div className="icon">
                                        <span className="material-symbols-outlined "> calendar_clock </span>
                                    </div>
                                    <span className="dsp_flex">
                                        <label>From</label>
                                        <p className="mb-0">08:00 AM &nbsp; &nbsp; {tablesData && tablesData[0] && tablesData[0].onCall_fromdate}</p>
                                    </span>
                                </div>
                                <div className="form-group">
                                    <span className="material-symbols-outlined icon"> </span>
                                    <span className="dsp_flex">
                                        <label>To</label>
                                        <p className="mb-0">08:00 AM &nbsp; &nbsp; {tablesData && tablesData[0] && tablesData[0].onCall_todate}</p>
                                    </span>
                                </div>
                            </div>
                            <div className="mp_con">
                                <div className="form-group">
                                    <div className="icon">
                                        <span className="material-symbols-outlined "> stethoscope </span>
                                    </div>
                                    <span className="dsp_flex">
                                        <label>On Call Consultant</label>
                                        <p className="mb-10">{tablesData && tablesData[0] && tablesData[0].onCall_Consultant}</p>
                                        <label>Contact</label>
                                        <p className="mb-0">998984560</p>
                                    </span>
                                </div>
                                <div className="form-group">
                                    <div className="icon">
                                        <span className="material-symbols-outlined "> stethoscope </span>
                                    </div>
                                    <span className="dsp_flex">
                                        <label>Registrar</label>
                                        <p className="mb-10">{tablesData && tablesData[0] && tablesData[0].onCall_Registrar} </p>
                                        <label>Contact</label>
                                        <p className="mb-0">998984560</p>
                                    </span>
                                </div>
                                <div className="form-group">

                                    <div className="icon">
                                        <span className="material-symbols-outlined "> clear_day </span>
                                    </div>
                                    <span className="dsp_flex">
                                        <label>Jr Doctor Day</label>
                                        <p className="mb-10">{tablesData && tablesData[0] && tablesData[0].onCall_Jr_dr_day}</p>
                                        <label>Contact</label>
                                        <p className="mb-0">998984560</p>
                                    </span>
                                </div>
                                <div className="form-group">

                                    <div className="icon">
                                        <span className="material-symbols-outlined "> dark_mode </span>
                                    </div>
                                    <span className="dsp_flex">
                                        <label>Jr Doctor Night</label>
                                        <p className="mb-10">{tablesData && tablesData[0] && tablesData[0].onCall_Jr_dr_night} </p>
                                        <label>Contact</label>
                                        <p className="mb-0">998984560</p>
                                    </span>
                                </div>

                            </div>
                        </div>
                        <div className="patients_table">

                            <table>
                                <thead>
                                    <tr>
                                        <td className="headcol"><strong>ID</strong> <br /> Patient Name</td>
                                        <th><strong>&nbsp;</strong> <br />Number</th>
                                        <th><strong>&nbsp;</strong> <br />DoB</th>
                                        <th> <strong>&nbsp;</strong> <br />Age</th>
                                        <th> <strong>&nbsp;</strong> <br />Sex</th>
                                        <th> <strong>Admission details</strong> <br /> Consultant</th>
                                        <th> <strong>&nbsp;</strong> <br />Location</th>
                                        <th> <strong>&nbsp;</strong> <br />Status</th>
                                        <th> <strong>Diagnosis & Managment</strong> <br /> Diagnosis</th>
                                        <th> <strong>&nbsp;</strong> <br />Side</th>
                                        <th> <strong>&nbsp;</strong> <br />Planned Management/ Procedure</th>
                                        <th> <strong>The Condition</strong> <br /> Background & Medical Work</th>
                                        <th> <strong>&nbsp;</strong> <br />Imaging</th>
                                        <th> <strong>&nbsp;</strong> <br />Labs</th>
                                        <th> <strong>&nbsp;</strong> <br />Photo</th>
                                        <th> <strong>Planning & Actions</strong> <br />Outstanding Issues</th>
                                        <th> <strong>&nbsp;</strong> <br />Jobs List</th>
                                        <th> <strong>&nbsp;</strong> <br />Add to Trauma List</th>
                                        <th> <strong>&nbsp;</strong> <br />Send to Patient Notes</th>
                                        <th> <strong>Meeting Outcome</strong> <br />Date</th>

                                    </tr>
                                </thead>
                                <tbody>

                                {tablesData && tablesData.length > 0 &&
                                                tablesData.map((item, i) => (

                                    <tr key={i}>
                                        <td className="headcol">{item.patientData && (item.patientData[0].firstname + " " + item.patientData[0].lastname)} </td>
                                        <td>{item.patientData && (item.patientData[0].patientId)}</td>
                                        <td>{item.patientData && (item.patientData[0].dob)}</td>
                                        <td>{item.patientData && (item.patientData[0].age)}Yrs</td>
                                        <td>{item.patientData && (item.patientData[0].sex)}</td>
                                        <td>{item.patientData && (item.patientData[0].doctor)} </td>
                                        <td>{item.patientData && (item.patientData[0].hospital)}</td>
                                        <td></td>
                                        <td>{item.diagnosis_1}</td>
                                        <td>{item.diagnosis_1_right_left}</td>
                                        <td>{item.diagnosis_3} </td>
                                        <td>{item.planned_management} </td>
                                        <td>
                                            <div className="image_thumbs">
                                                <div className="img_thumb" data-toggle="modal" data-target="#exampleModalLong">
                                                    <img src={xray} />

                                                    <div className="modal fade" id="exampleModalLong" tabIndex="-1" role="dialog"
                                                        aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                                        <div className="modal-dialog" role="document">
                                                            <div className="modal-content">
                                                                <div className="modal-header">
                                                                    <h5 className="modal-title">Preview</h5>
                                                                    <span className="material-icons" data-dismiss="modal"
                                                                        aria-label="Close">
                                                                        close
                                                                    </span>
                                                                </div>
                                                                <div className="modal-body">
                                                                    <img data-toggle="modal" data-target="#exampleModalLong"
                                                                        src={xray} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="img_thumb" data-toggle="modal" data-target="#exampleModalLong">
                                                    <img src={xray} />
                                                </div>
                                                <div className="img_thumb" data-toggle="modal" data-target="#exampleModalLong">
                                                    <img src={xray} />
                                                </div>

                                            </div>
                                        </td>
                                        <td>
                                            <div className="image_thumbs">
                                                <div className="img_thumb" data-toggle="modal" data-target="#exampleModalLong1">
                                                    <img src={doc} />

                                                    <div className="modal fade" id="exampleModalLong1" tabIndex="-1" role="dialog"
                                                        aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                                        <div className="modal-dialog" role="document">
                                                            <div className="modal-content">
                                                                <div className="modal-header">
                                                                    <h5 className="modal-title">Preview</h5>
                                                                    <span className="material-icons" data-dismiss="modal"
                                                                        aria-label="Close">
                                                                        close
                                                                    </span>
                                                                </div>
                                                                <div className="modal-body">
                                                                    <img data-toggle="modal" data-target="#exampleModalLong"
                                                                        src={doc} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="img_thumb" data-toggle="modal" data-target="#exampleModalLong1">
                                                    <img src={doc} />
                                                </div>
                                                <div className="img_thumb" data-toggle="modal" data-target="#exampleModalLong1">
                                                    <img src={doc} />
                                                </div>

                                            </div>
                                        </td>
                                        <td>
                                            <div className="image_thumbs">
                                                <div className="img_thumb" data-toggle="modal" data-target="#exampleModalLong">
                                                    <img src={xray} />

                                                    <div className="modal fade" id="exampleModalLong" tabIndex="-1" role="dialog"
                                                        aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                                        <div className="modal-dialog" role="document">
                                                            <div className="modal-content">
                                                                <div className="modal-header">
                                                                    <h5 className="modal-title">Preview</h5>
                                                                    <span className="material-icons" data-dismiss="modal"
                                                                        aria-label="Close">
                                                                        close
                                                                    </span>
                                                                </div>
                                                                <div className="modal-body">
                                                                    <img data-toggle="modal" data-target="#exampleModalLong"
                                                                        src={xray} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="img_thumb" data-toggle="modal" data-target="#exampleModalLong">
                                                    <img src={xray} />
                                                </div>
                                                <div className="img_thumb" data-toggle="modal" data-target="#exampleModalLong">
                                                    <img src={xray} />
                                                </div>

                                            </div>
                                        </td>
                                        <td>{item.outstanding && Object.keys(item.outstanding).map((subitem, i) => (
                                        <label key={i} className="check">{item.outstanding[subitem]}</label>
                                    ))} </td>
                                        <td></td>
                                        <td>{item.trauma_loc && item.trauma_poly ?  item.trauma_loc + " " + item.trauma_poly : ""}</td>
                                        <td>Active</td>
                                        <td>{item.created ? Moment(item.created).format('MMM DD, YYYY') : ""} </td>
                                    </tr>
                                    ))}

                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>




        </React.Fragment>
    )
}

OnCall.propTypes = {
    getWards: PropTypes.func,
    getTheatres: PropTypes.func,
    wards: PropTypes.array,
    user: PropTypes.any,
    theatres: PropTypes.array,
    clinic: PropTypes.array,
    patients: PropTypes.array,
    doctors: PropTypes.array,
    tablesData: PropTypes.array,
    profile: PropTypes.object,
    news: PropTypes.array,
}

const mapStateToProps = ({ Layout, Login }) => ({
    wards: Layout.wards,
    user: Login.user,
    theatres: Layout.theatres,
    clinic: Layout.clinic,
    patients: Layout.patients,
    doctors: Layout.doctors,
    tablesData: Layout.tableData,
    profile: Layout.profile,
    news: Layout.news,
    dailyrounds: Layout.dailyrounds
})

const mapDispatchToProps = dispatch => ({
    getWards: () => dispatch(getWardsList()),
    getTheatres: () => dispatch(getTheatresList()),
    getClinic: () => dispatch(getClinicList()),
    getPatient: () => dispatch(getPatientList()),
    getUserprofile: (id) => dispatch(getUprofile(id)),
    getDoctor: () => dispatch(getDoctorList()),
    getTableData: (data, tablename) => dispatch(gettableList(data, tablename)),
    patientupdate: (id, data) => dispatch(updatePatient(id, data)),
    newsList: (data) => dispatch(getNews(data)),
    dailyRoundsList: (data) => dispatch(getDailyRounds(data)),
    dailyUpdate: (data) => dispatch(updateDailyRounds(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(OnCall)
