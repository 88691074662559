import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import MetaTags from 'react-meta-tags';
import Select from "react-select"
import { connect } from "react-redux"
import { getWardsList, getTheatresList, getClinicList, getPatientList, getUprofile, getDoctorList, updatePatient } from "../../store/actions"

const DemoGraphics = props => {

   
    const [clinicValues, setClinic] = useState({})
    const [wardValues, setWard] = useState({})

    const optionGroup =  [
        { label: "White	British", value: "White	British" },
        { label: "White	Irish", value: "White Irish" },
        { label: "White	Any other White background", value: "White	Any other White background" },
        { label: "Mixed	White and Black Caribbean", value: "Mixed	White and Black Caribbean" },
        { label: "Mixed	White and Black African", value: "Mixed	White and Black African" },
        { label: "Mixed	White and Asian", value: "Mixed	White and Asian" },
        { label: "Mixed	Any other mixed background", value: "Mixed	Any other mixed background" },
        { label: "Asian or British Asian Indian", value: "Asian or British Asian Indian" },
        { label: "Asian or British Asian Pakistani", value: "Asian or British Asian Pakistani" },
        { label: "Asian or British Asian Bangladeshi", value: "Asian or British Asian Bangladeshi" },
        { label: "Asian or British Asian Any other Asian background", value: "Asian or British Asian Any other Asian background" },
        { label: "Black or Black British Caribbean", value: "Black or Black British Caribbean" },
        { label: "Black or Black British African", value: "Black or Black British African" },
        { label: "Black or Black British Any other Black background", value: "Black or Black British Any other Black background" },
        { label: "Other ethnic groups Chinese", value: "Other ethnic groups Chinese" },
        { label: "Other ethnic groups Any other ethnic category", value: "Other ethnic groups Any other ethnic category" },
        { label: "Not Stated/ Declined to", value: "Not Stated/ Declined to" }
      ]

    const {
        wards, user, theatres, clinic, patients, profile, doctors
    } = props;

    useEffect(() => {
        const { getWards, getTheatres, getClinic, getDoctor, getPatient, getUserprofile } = props
        
        getPatient()
        getDoctor()
        let userId = localStorage.getItem("authUser")
        getUserprofile(userId)
    }, [])


    useEffect(() => {
        let clinicPatient = JSON.parse(localStorage.getItem("p"))
        if (patients && patients.length > 0 && clinicPatient && clinicPatient._id) {
            const cPatient = patients.filter(
                usr => usr._id === clinicPatient._id
            )
            if (cPatient && cPatient[0]) {
                let cpatientData = cPatient[0]
                delete cpatientData["_id"]
                setClinic(clinicValues => ({
                    ...clinicValues,
                    ...cpatientData
                }));
            }

        }
    }, [patients])

    const updateWard = (e) => {
        const { patientupdate } = props
        let clinicPatient = JSON.parse(localStorage.getItem("p"))
        let id = clinicPatient._id
        wardValues["demo_last_update"] = new Date()
        patientupdate(id, wardValues)
        
    }

    const updateWardValue = (e) => {
        let val = e.target.value;
        let name = e.target.name;
        let updatedValue = { [name]: val };
        setWard(wardValues => ({
            ...wardValues,
            ...updatedValue
        }));
    }

    const handleSelectGroup = (selectedGroup, name) => {
        // console.log("selectedGroup ", selectedGroup)
        let updatedValue = { [name]: selectedGroup };
        setWard(wardValues => ({
            ...wardValues,
            ...updatedValue
        }));
    }

    const updateSwitchValue = (name, value) => {
        let updatedValue = {};
        updatedValue = { [name]: value };
        setWard(wardValues => ({
            ...wardValues,
            ...updatedValue
        }));

    }

    const handleChange = (event) => {
        updateSwitchValue(event.target.name, event.target.checked)
    }

    const updateClinicValues = (e, name, val) => {
        e.preventDefault()
        let updatedValue = {};
        updatedValue = { [name]: val };
        setWard(wardValues => ({
            ...wardValues,
            ...updatedValue
        }));
        setClinic(clinicValues => ({
            ...clinicValues,
            ...updatedValue
        }));
    }


    const wardBack = (e) => {
        e.preventDefault()
        props.history.push("/wl")
        document.body.style.overflow = '';
    }

    let clinicPatient = JSON.parse(localStorage.getItem("p"))

    //  console.log(" wardValues ", wardValues, clinicValues)

    return (
        <React.Fragment>

            <MetaTags>
                <title>Demographics</title>
            </MetaTags>
            <div className='ward '>
                <div className="popup_wrp">
                    <div className="popup_full prescribe">
                        <div className="popup_hed">
                            <h2>DEMOGRAPHICS </h2>
                            <div className="dsp-flex"> <a className="popup_close" onClick={(e) => { wardBack(e) }}><span className="material-icons">close</span></a></div>

                        </div>
                        <div className="popup_content">
                            <div className="popup_scroll w-btns main ">
                                <div className="section ctr">
                                    <div className="yn_switch mb-10 mt-0">
                                        <div className="label-container">
                                            <p>Have you attended this Hospital as an in-patient or outpatient before? </p>
                                        </div>
                                        <label className="switch-container">
                                            <input type="checkbox" className="switch_check" name="demo_inpatient_outpatient" onChange={(e) => handleChange(e)} checked={clinicValues.demo_inpatient_outpatient}  />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>YES</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>NO</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="yn_switch mb-10 mt-10">
                                        <div className="label-container">
                                            <p>Under what name? </p>
                                        </div>
                                        <label className="switch-container">
                                            <input type="checkbox" className="switch_check" name="demo_whatname" onChange={(e) => handleChange(e)} checked={clinicValues.demo_whatname}/>
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>YES</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>NO</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="yn_switch mb-10 mt-10">
                                        <div className="label-container">
                                            <p>Have you filled this questionnaire before within the last 12 months or longer?
                                            </p>
                                        </div>
                                        <label className="switch-container">
                                            <input type="checkbox" className="switch_check" name="demo_questionnaire" onChange={(e) => handleChange(e)} checked={clinicValues.demo_questionnaire} />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>YES</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>NO</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="yn_switch mb-10 mt-10">
                                        <div className="label-container">
                                            <p>Any changes to your health since your last treatment E.g. Changes to medications,
                                                admissions, new diagnoses? </p>
                                        </div>
                                        <label className="switch-container">
                                            <input type="checkbox" className="switch_check" name="demo_change_medication" onChange={(e) => handleChange(e)} checked={clinicValues.demo_change_medication} />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>YES</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>NO</span>
                                            </div>
                                        </label>
                                    </div>

                                </div>
                                <div className="section ctr">
                                    <h2 className="popup_title"> Last updated</h2>

                                    {clinicValues.demo_information_obtained ?
                                                <div className="select_btns popup_select">
                                                    <div className="select_btn"><label><input type="radio"
                                                        value={clinicValues.demo_information_obtained} checked /><span>{clinicValues.demo_information_obtained} </span></label>
                                                    </div> {clinicValues.demo_information_obtained && <a onClick={(e) => updateClinicValues(e, "demo_information_obtained", false)}><span className="material-symbols-outlined"> reply
                                                    </span></a>}

                                                </div>
                                                :
                                    <div className="check_item mt-0 mb-10"><label className="check">Information obtained from</label>
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input name="demo_information_obtained" type="radio"
                                               onClick={(e) => updateWardValue(e)}  value="Patient" /><span>Patient </span></label></div>
                                            <div className="select_btn"><label><input name="demo_information_obtained" type="radio"
                                                value="Family" /><span>Family </span></label></div>
                                            <div className="select_btn"><label><input name="demo_information_obtained" type="radio"
                                                value="Other" /><span>Other
                                                </span></label></div>
                                        </div>
                                    </div>}


                                    <div className="form_group">
                                        <label className="label">Other</label>
                                        <input type="text" className="form-control" name="demo_other" onChange={(e) => updateWardValue(e)} value={clinicValues.demo_other}  />
                                    </div>
                                    <div className="yn_switch mb-10 mt-10">
                                        <div className="label-container">
                                            <p>Unable to answer screening questions within 48 hours </p>
                                        </div>
                                        <label className="switch-container">
                                            <input type="checkbox" className="switch_check" name="demo_screening_questions" onChange={(e) => handleChange(e)} checked={clinicValues.demo_screening_questions} />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>YES</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>NO</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="form_group">
                                        <label className="label">Reason</label>
                                        <input type="text" className="form-control" name="demo_reason" value={clinicValues.demo_reason}
 onChange={(e) => updateWardValue(e)} />
                                    </div>
                                    <div className="yn_switch mb-10 mt-10">
                                        <div className="label-container">
                                            <p>UNABLE TO OBTAIN HISTORY ON ADMISSION <sub> (obtain history from other sources
                                                within 48 hours)</sub> </p>
                                        </div>
                                        <label className="switch-container">
                                            <input type="checkbox" className="switch_check" name="demo_obtain_history" onChange={(e) => handleChange(e)} checked={clinicValues.demo_obtain_history}/>
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>YES</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>NO</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="check_item mt-0 mb-10">
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input name="demo_unable_obtain" onClick={(e) => updateWardValue(e)} type="radio" value="Family is aware of current admission" /><span>Family is aware of current admission </span></label></div>
                                            <div className="select_btn"><label><input name="demo_unable_obtain" onClick={(e) => updateWardValue(e)} type="radio" value="Family NOT to be informed about current admission" /><span>Family NOT to be informed about current admission
                                                </span></label></div>
                                        </div>
                                    </div>

                                </div>
                                <div className="section ctr">
                                    <h2 className="popup_title">COMMUNICATION</h2>
                                    <div className="check_item mt-0 mb-10"><label className="check">Primary language</label>
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input name="demo_communication" onClick={(e) => updateWardValue(e)} type="radio"
                                                value="English spoken" /><span>English spoken
                                                </span></label></div>
                                            <div className="select_btn"><label><input name="demo_communication" onClick={(e) => updateWardValue(e)} type="radio"
                                                value="Other" /><span>Other </span></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="yn_switch mb-10 mt-0">
                                        <div className="label-container">
                                            <p>Language Interpreter required</p>
                                        </div>
                                        <label className="switch-container">
                                            <input type="checkbox" className="switch_check" name="demo_language_intrepreter_required" onChange={(e) => handleChange(e)} />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>YES</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>NO</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="yn_switch mb-10 mt-10">
                                        <div className="label-container">
                                            <p>Language interpreter arranged </p>
                                        </div>
                                        <label className="switch-container">
                                            <input type="checkbox" className="switch_check" name="demo_language_intrepreter_arranged" onChange={(e) => handleChange(e)}/>
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>YES</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>NO</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="check_item mt-0 mb-10"><label className="check">Hearing/speech
                                                difficulty</label>
                                                <div className="select_btns popup_select">
                                                    <div className="select_btn"><label><input name="demo_hearing_difficulty" onClick={(e) => updateWardValue(e)} type="radio"
                                                        value="Left" /><span>Left </span></label></div>
                                                    <div className="select_btn"><label><input name="demo_hearing_difficulty" onClick={(e) => updateWardValue(e)} type="radio"
                                                        value="Right" /><span>Right </span></label></div>

                                                </div>
                                            </div>

                                        </div>
                                        <div className="col-md-6">
                                            <div className="check_item mt-0 mb-10"><label className="check">Hearing Aids</label>
                                                <div className="select_btns popup_select">
                                                    <div className="select_btn"><label><input name="demo_hearing_aids" onClick={(e) => updateWardValue(e)} type="radio"
                                                        value="Left" /><span>Left </span></label></div>
                                                    <div className="select_btn"><label><input name="demo_hearing_aids" onClick={(e) => updateWardValue(e)} type="radio"
                                                        value="Right" /><span>Right </span></label>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="yn_switch mb-10 mt-10">
                                        <div className="label-container">
                                            <p>Deaf interpreter required? </p>
                                        </div>
                                        <label className="switch-container">
                                            <input type="checkbox" className="switch_check" name="demo_deaf_intrepreter_required" onChange={(e) => handleChange(e)} />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>YES</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>NO</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="yn_switch mb-10 mt-10">
                                        <div className="label-container">
                                            <p>BSL interpreter arranged? </p>
                                        </div>
                                        <label className="switch-container">
                                            <input type="checkbox" className="switch_check" name="demo_bsl_intrepreter_arranged" onChange={(e) => handleChange(e)} />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>YES</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>NO</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="check_item mt-0 mb-10"><label className="check">Vision Difficulty</label>
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input name="demo_vision_difficulty" onClick={(e) => updateWardValue(e)} type="radio"
                                                value="Glasses Contacts" /><span>Glasses Contacts </span></label></div>
                                            <div className="select_btn"><label><input name="demo_vision_difficulty" onClick={(e) => updateWardValue(e)} type="radio"
                                                value="Eye Prosthesis" /><span>Eye Prosthesis </span></label></div>

                                        </div>
                                    </div>
                                    <div className="yn_switch mb-10 mt-10">
                                        <div className="label-container">
                                            <p>Dentures </p>
                                        </div>
                                        <label className="switch-container">
                                            <input type="checkbox" className="switch_check" name="demo_dentures" onChange={(e) => handleChange(e)}  />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>YES</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>NO</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="check_item mt-0 mb-10"><label className="check">Upper</label>
                                                <div className="select_btns popup_select">
                                                    <div className="select_btn"><label><input name="demo_upper" onClick={(e) => updateWardValue(e)} type="radio"
                                                        value="Partial" /><span>Partial
                                                        </span></label></div>
                                                    <div className="select_btn"><label><input name="demo_upper" onClick={(e) => updateWardValue(e)} type="radio"
                                                        value="Full" /><span>Full
                                                        </span></label></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="check_item mt-0 mb-10"><label className="check">Lower</label>
                                                <div className="select_btns popup_select">
                                                    <div className="select_btn"><label><input name="demo_lower" onClick={(e) => updateWardValue(e)} type="radio"
                                                        value="Partial" /><span>Partial
                                                        </span></label></div>
                                                    <div className="select_btn"><label><input name="demo_lower" onClick={(e) => updateWardValue(e)} type="radio"
                                                        value="Full" /><span>Full
                                                        </span></label></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="check_item mt-0 mb-10"><label className="check">Reading & Writing</label>
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input name="demo_reading_writing" onClick={(e) => updateWardValue(e)} type="radio"
                                                value="Can do" /><span>Can do
                                                </span></label></div>
                                            <div className="select_btn"><label><input name="demo_reading_writing" onClick={(e) => updateWardValue(e)} type="radio"
                                                value="Can not" /><span>Can not
                                                </span></label></div>
                                            <div className="select_btn"><label><input name="demo_reading_writing" onClick={(e) => updateWardValue(e)} type="radio"
                                                value="Impaired" /><span>Impaired
                                                </span></label></div>
                                        </div>
                                    </div>

                                </div>
                                <div className="section ctr">
                                    <h2 className="popup_title">LIFESTYLE & SOCIAL CIRCUMSTANCES</h2>
                                  
                                    <div className="check_item mt-20 mb-10"><label className="check">Occupation </label>

                                    <input type="text" className="form-control" name="demo_occupation" onChange={(e) => updateWardValue(e)} />
                                    </div>

                                    <div className="check_item mt-20 mb-10"><label className="check">Accommodation </label>
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input name="demo_accomdation" onClick={(e) => updateWardValue(e)}  type="radio"
                                                value="House" /><span>House
                                                </span></label></div>
                                            <div className="select_btn"><label><input name="demo_accomdation" onClick={(e) => updateWardValue(e)} type="radio"
                                                value="Bungalow" /><span>Bungalow </span></label>
                                            </div>
                                            <div className="select_btn"><label><input name="demo_accomdation" onClick={(e) => updateWardValue(e)} type="radio"
                                                value="Flat" /><span>Flat </span></label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="check_item mt-0 mb-10">
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input name="demo_familysupport" onClick={(e) => updateWardValue(e)}  type="radio"
                                                value="Family support Relatives" /><span>Family support Relatives
                                                </span></label></div>
                                            <div className="select_btn"><label><input name="demo_familysupport" onClick={(e) => updateWardValue(e)}  type="radio"
                                                value="Carer Carers ( x/day)" /><span>Carer Carers ( x/day)
                                                </span></label>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="yn_switch mb-10 mt-0">
                                                <div className="label-container">
                                                    <p>Home care </p>
                                                </div>
                                                <label className="switch-container">
                                                    <input type="checkbox" className="switch_check" name="demo_homecare" onChange={(e) => handleChange(e)} />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>YES</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>NO</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <label className="label">How often? </label>
                                                <input type="text" className="form-control" name="demo_howoften" onChange={(e) => updateWardValue(e)} />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <label className="label">Name of provider? </label>
                                                <input type="text" className="form-control" name="demo_provider_name" onChange={(e) => updateWardValue(e)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="check_item mt-0 mb-10"><label className="check">Formal Care Local Authority Warden
                                        Control </label>
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input name="demo_formalcare" onClick={(e) => updateWardValue(e)} type="radio"
                                                value="Residential home" /><span>Residential home
                                                </span></label></div>
                                            <div className="select_btn"><label><input name="demo_formalcare" onClick={(e) => updateWardValue(e)} type="radio"
                                                value="Nursing home" /><span>Nursing home </span></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="check_item mt-0 mb-10"><label className="check">Heating</label>
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input name="demo_heating" onClick={(e) => updateWardValue(e)} type="radio"
                                                value="Gas fire" /><span>Gas fire
                                                </span></label></div>
                                            <div className="select_btn"><label><input name="demo_heating" onClick={(e) => updateWardValue(e)} type="radio"
                                                value="C. Heating" /><span>C. Heating </span></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form_group">
                                                <label className="label">Domestic ADL</label>
                                                <input type="text" className="form-control" name="demo_domestic_adl" onChange={(e) => updateWardValue(e)} />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form_group">
                                                <label className="label">Drink </label>
                                                <input type="text" className="form-control" name="demo_drink" onChange={(e) => updateWardValue(e)} />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form_group">
                                                <label className="label">Snack </label>
                                                <input type="text" className="form-control" name="demo_snack" onChange={(e) => updateWardValue(e)} />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form_group">
                                                <label className="label">Shopping </label>
                                                <input type="text" className="form-control" name="demo_shopping" onChange={(e) => updateWardValue(e)} />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form_group">
                                                <label className="label">Cooking </label>
                                                <input type="text" className="form-control" name="demo_cooking" onChange={(e) => updateWardValue(e)} />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form_group">
                                                <label className="label">Laundry </label>
                                                <input type="text" className="form-control" name="demo_laundry" onChange={(e) => updateWardValue(e)} />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form_group">
                                                <label className="label">Cleaning </label>
                                                <input type="text" className="form-control" name="demo_cleaning" onChange={(e) => updateWardValue(e)} />
                                            </div>
                                        </div>
                                    </div>
                                    <p className="sub_title mt-20 mb-10">Lives with</p>
                                    <div className="yn_switch mb-10 mt-0">
                                        <div className="label-container">
                                            <p>Lives alone </p>
                                        </div>
                                        <label className="switch-container">
                                            <input type="checkbox" className="switch_check" name="demo_livealone" onChange={(e) => handleChange(e)}
 />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>YES</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>NO</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="check_item mt-10 mb-10"><label className="check">Marital Status </label>
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input name="demo_marital_status" onClick={(e) => updateWardValue(e)} type="radio"
                                                value="Married (including defacto)" /><span>Married (including defacto)
                                                </span></label></div>
                                            <div className="select_btn"><label><input name="demo_marital_status" onClick={(e) => updateWardValue(e)} type="radio"
                                                value="Single" /><span>Single </span></label>
                                            </div>
                                            <div className="select_btn"><label><input name="demo_marital_status" onClick={(e) => updateWardValue(e)} type="radio"
                                                value="Widowed" /><span>Widowed </span></label>
                                            </div>
                                            <div className="select_btn"><label><input name="demo_marital_status" onClick={(e) => updateWardValue(e)} type="radio"
                                                value="Separated" /><span>Separated </span></label>
                                            </div>
                                            <div className="select_btn"><label><input name="demo_marital_status" onClick={(e) => updateWardValue(e)} type="radio"
                                                value="Divorced" /><span>Divorced </span></label>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="sub_title mt-20 mb-10">Environment</p>
                                    <div className="yn_switch mb-10 mt-10">
                                        <div className="label-container">
                                            <p>Pendant alarm </p>
                                        </div>
                                        <label className="switch-container">
                                            <input type="checkbox" className="switch_check" name="demo_pendant_alarm" onChange={(e) => handleChange(e)} />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>YES</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>NO</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="yn_switch mb-20 mt-10">
                                        <div className="label-container">
                                            <p>Pets </p>
                                        </div>
                                        <label className="switch-container">
                                            <input type="checkbox" className="switch_check" name="demo_pets" onChange={(e) => handleChange(e)} />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>YES</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>NO</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="form_group">
                                        <label className="label">Location of key </label>
                                        <input type="text" className="form-control" name="demo_location_of_key" onChange={(e) => updateWardValue(e)} />
                                    </div>
                                    <p className="sub_title mt-20 mb-0">Sleeps</p>
                                    <div className="check_item mt-0 mb-0">
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input name="demo_sleeps" onClick={(e) => updateWardValue(e)} type="radio"
                                                value="Upstairs" /><span>Upstairs
                                                </span></label></div>
                                            <div className="select_btn"><label><input name="demo_sleeps" onClick={(e) => updateWardValue(e)} type="radio"
                                                value="Downstairs" /><span>Downstairs </span></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="check_item mt-0 mb-10">
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input name="demo_bed" onClick={(e) => updateWardValue(e)} type="radio"
                                                value="Single" /><span>Single
                                                </span></label></div>
                                            <div className="select_btn"><label><input name="demo_bed" onClick={(e) => updateWardValue(e)} type="radio"
                                                value="Double" /><span>Double </span></label>
                                            </div>
                                            <div className="select_btn"><label><input name="demo_bed" onClick={(e) => updateWardValue(e)} type="radio"
                                                value="Bed shared" /><span>Bed shared </span></label>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="sub_title mt-20 mb-0">Toilet</p>
                                    <div className="check_item mt-0 mb-0">
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input name="demo_toilet" onClick={(e) => updateWardValue(e)} type="radio"
                                                value="Upstairs" /><span>Upstairs
                                                </span></label></div>
                                            <div className="select_btn"><label><input name="demo_toilet" onClick={(e) => updateWardValue(e)} type="radio"
                                                value="Downstairs" /><span>Downstairs </span></label>
                                            </div>
                                            <div className="select_btn"><label><input name="demo_toilet" onClick={(e) => updateWardValue(e)} type="radio"
                                                value="Outside" /><span>Outside </span></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="check_item mt-0 mb-0">
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input name="demo_commode" onClick={(e) => updateWardValue(e)} type="radio"
                                                value="Commode" /><span>Commode
                                                </span></label></div>
                                            <div className="select_btn"><label><input name="demo_commode" onClick={(e) => updateWardValue(e)} type="radio"
                                                value="Chemical" /><span>Chemical </span></label>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="check_item mt-0 mb-10">
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input name="demo_rails" onClick={(e) => updateWardValue(e)} type="radio"
                                                value="Rails 1 / 2" /><span>Rails 1 / 2
                                                </span></label></div>
                                            <div className="select_btn"><label><input name="demo_rails" onClick={(e) => updateWardValue(e)} type="radio"
                                                value="Raised toilet seat" /><span>Raised toilet seat </span></label>
                                            </div>

                                        </div>
                                    </div>
                                    <p className="sub_title mt-20 mb-0">Adaptations</p>
                                    <div className="check_item mt-0 mb-0">
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input name="demo_adaptationns" onClick={(e) => updateWardValue(e)} type="radio"
                                                value="Air mattress" /><span>Air mattress
                                                </span></label></div>
                                            <div className="select_btn"><label><input name="demo_adaptationns" onClick={(e) => updateWardValue(e)} type="radio"
                                                value="Overlay mattress" /><span>Overlay mattress </span></label>
                                            </div>
                                            <div className="select_btn"><label><input name="demo_adaptationns" onClick={(e) => updateWardValue(e)} type="radio"
                                                value="Electric bed Hospital bed" /><span>Electric bed Hospital bed </span></label>
                                            </div>
                                            <div className="select_btn"><label><input name="demo_adaptationns" onClick={(e) => updateWardValue(e)} type="radio"
                                                value="Hoist" /><span>Hoist </span></label>
                                            </div>
                                            <div className="select_btn"><label><input name="demo_adaptationns" onClick={(e) => updateWardValue(e)} type="radio"
                                                value="Stair lift" /><span>Stair lift </span></label>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="sub_title mt-20 mb-10">Transport</p>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <label className="label">To hospital</label>
                                                <input type="text" className="form-control" name="demo_transport_to_hospital" onChange={(e) => updateWardValue(e)} />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <label className="label">On discharge</label>
                                                <input type="text" className="form-control" name="demo_on_discharge" onChange={(e) => updateWardValue(e)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="yn_switch mb-20 mt-10">
                                        <div className="label-container">
                                            <p>Assessment of transport needs </p>
                                        </div>
                                        <label className="switch-container">
                                            <input type="checkbox" className="switch_check" name="demo_assesment" onChange={(e) => handleChange(e)} />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>YES</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>NO</span>
                                            </div>
                                        </label>
                                    </div>
                                    <p className="sub_title mt-10 mb-0">Previous mobility</p>
                                    <div className="check_item mt-0 mb-0">
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input name="demo_mobility" onClick={(e) => updateWardValue(e)} type="radio"
                                                value="Normal" /><span>Normal
                                                </span></label></div>
                                            <div className="select_btn"><label><input name="demo_mobility" onClick={(e) => updateWardValue(e)} type="radio"
                                                value="Independent" /><span>Independent </span></label>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="check_item mt-0 mb-0">
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input name="demo_mobility_type" onClick={(e) => updateWardValue(e)}  type="radio"
                                                value=">Walking sticks" /><span>Walking sticks
                                                </span></label></div>
                                            <div className="select_btn"><label><input name="demo_mobility_type" onClick={(e) => updateWardValue(e)}  type="radio"
                                                value="Crutches" /><span>Crutches </span></label>
                                            </div>
                                            <div className="select_btn"><label><input name="demo_mobility_type" onClick={(e) => updateWardValue(e)}  type="radio"
                                                value="Frame" /><span>Frame </span></label>
                                            </div>
                                            <div className="select_btn"><label><input name="demo_mobility_type" onClick={(e) => updateWardValue(e)}  type="radio"
                                                value="Mobility scooter" /><span>Mobility scooter </span></label>
                                            </div>
                                        
                                            <div className="select_btn"><label><input name="demo_mobility_type" onClick={(e) => updateWardValue(e)}  type="radio"
                                                value="Wheelchair" /><span>Wheelchair
                                                </span></label></div>
                                            <div className="select_btn"><label><input name="demo_mobility_type" onClick={(e) => updateWardValue(e)}  type="radio"
                                                value="Bound" /><span>Bound </span></label>
                                            </div>
                                            <div className="select_btn"><label><input name="demo_mobility_type" onClick={(e) => updateWardValue(e)}  type="radio"
                                                value="Bed bound" /><span>Bed bound </span></label>
                                            </div>

                                        </div>
                                    </div>
                                    <p className="sub_title mt-20 mb-10">Exercise tolerance. distance, stairs, activity, etc</p>
                                    <div className="yn_switch mb-20 mt-10">
                                        <div className="label-container">
                                            <p>Do you drive </p>
                                        </div>
                                        <label className="switch-container">
                                            <input type="checkbox" className="switch_check" name="demo_exercise" onChange={(e) => handleChange(e)} />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>YES</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>NO</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="yn_switch mb-20 mt-10">
                                        <div className="label-container">
                                            <p>Would you like a visit from </p>
                                        </div>
                                        <label className="switch-container">
                                            <input type="checkbox" className="switch_check" name="demo_visit_form" onChange={(e) => handleChange(e)} />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>YES</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>NO</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="check_item mt-0 mb-10">
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input name="demo_religious" onClick={(e) => updateWardValue(e)} type="radio"
                                                value="Own pastor" /><span>Own pastor
                                                </span></label></div>
                                            <div className="select_btn"><label><input name="demo_religious" onClick={(e) => updateWardValue(e)} type="radio"
                                                value="Religious leader" /><span>Religious leader </span></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form_group">
                                                <label className="label">Referral made to Spiritual Care (where available)</label>
                                                <input type="text" className="form-control" name="demo_spiritual" onChange={(e) => updateWardValue(e)} />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form_group">
                                                <label className="label">Hospital chaplain (where available) </label>
                                                <input type="text" className="form-control" name="demo_hos_chaplain" onChange={(e) => updateWardValue(e)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="yn_switch mb-20 mt-10">
                                        <div className="label-container">
                                            <p>What cultural practices, religious customs </p>
                                        </div>
                                        <label className="switch-container">
                                            <input type="checkbox" className="switch_check" name="demo_cultural" onChange={(e) => handleChange(e)} />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>YES</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>NO</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="yn_switch mb-20 mt-10">
                                        <div className="label-container">
                                            <p>rites, or health beliefs are important to consider in your care? </p>
                                        </div>
                                        <label className="switch-container">
                                            <input type="checkbox" className="switch_check" name="demo_rites" onChange={(e) => handleChange(e)} />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>YES</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>NO</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="form_group">
                                        <label className="label">Support offered </label>
                                        <input type="text" className="form-control" name="demo_support_offered" onChange={(e) => updateWardValue(e)} />
                                    </div>
                                </div>
                                <div className="section ctr">
                                    <h2 className="popup_title">SERVICES /SUPPORT</h2>

                                    <div className="check_item mt-20 mb-10"><label className="check">Service </label>
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input name="demo_service" onClick={(e) => updateWardValue(e)} type="radio"
                                                value="Name" /><span>Name
                                                </span></label></div>
                                            <div className="select_btn"><label><input name="demo_service" onClick={(e) => updateWardValue(e)} type="radio"
                                                value="Agency" /><span>Agency </span></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="yn_switch mb-10 mt-0">
                                        <div className="label-container">
                                            <p>Community Matron</p>
                                        </div>
                                        <label className="switch-container">
                                            <input type="checkbox" className="switch_check" name="demo_community_matron" onChange={(e) => handleChange(e)} />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>YES</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>NO</span>
                                            </div>
                                        </label>
                                    </div>

                                    <div className="check_item mt-0 mb-10"><label className="check">Home Care </label>
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input name="demo_home_care" onClick={(e) => updateWardValue(e)} type="radio"
                                                value=">How many?" /><span>How many?
                                                </span></label></div>
                                            <div className="select_btn"><label><input name="demo_home_care" onClick={(e) => updateWardValue(e)} type="radio"
                                                value="How often?" /><span>How often? </span></label>
                                            </div>
                                            <div className="select_btn"><label><input name="demo_home_care" onClick={(e) => updateWardValue(e)} type="radio"
                                                value="Frequency?" /><span>Frequency? </span></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="yn_switch mb-10 mt-0">
                                                <div className="label-container">
                                                    <p>Social Worker</p>
                                                </div>
                                                <label className="switch-container">
                                                    <input type="checkbox" className="switch_check" name="demo_social_worker" onChange={(e) => handleChange(e)} />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>YES</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>NO</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="yn_switch mb-20 mt-10">
                                                <div className="label-container">
                                                    <p>District Nurse</p>
                                                </div>
                                                <label className="switch-container">
                                                    <input type="checkbox" className="switch_check" name="demo_district_nurse" onChange={(e) => handleChange(e)} />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>YES</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>NO</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="check_item mt-0 mb-10">
                                                <div className="select_btns popup_select">
                                                    <div className="select_btn"><label><input name="demo_macmillan" onClick={(e) => updateWardValue(e)} type="radio"
                                                        value="Macmillan" /><span>Macmillan </span></label></div>
                                                    <div className="select_btn"><label><input name="demo_macmillan" onClick={(e) => updateWardValue(e)} type="radio"
                                                        value="Specialist Nurse" /><span>Specialist Nurse </span></label>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="yn_switch mb-10 mt-0">
                                                <div className="label-container">
                                                    <p>Wound Care</p>
                                                </div>
                                                <label className="switch-container">
                                                    <input type="checkbox" className="switch_check" name="demo_wound_care" onChange={(e) => handleChange(e)} />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>YES</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>NO</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="yn_switch mb-20 mt-0">
                                                <div className="label-container">
                                                    <p>Health Visitor</p>
                                                </div>
                                                <label className="switch-container">
                                                    <input type="checkbox" className="switch_check" name="demo_health_visitor" onChange={(e) => handleChange(e)} />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>YES</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>NO</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="yn_switch mb-20 mt-0">
                                                <div className="label-container">
                                                    <p>Dietitian</p>
                                                </div>
                                                <label className="switch-container">
                                                    <input type="checkbox" className="switch_check" name="demo_dietitian" onChange={(e) => handleChange(e)}/>
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>YES</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>NO</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="yn_switch mb-20 mt-10">
                                                <div className="label-container">
                                                    <p>Physiotherapy</p>
                                                </div>
                                                <label className="switch-container">
                                                    <input type="checkbox" className="switch_check" name="demo_physiotherapy" onChange={(e) => handleChange(e)} />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>YES</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>NO</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="yn_switch mb-10 mt-10">
                                                <div className="label-container">
                                                    <p>OT</p>
                                                </div>
                                                <label className="switch-container">
                                                    <input type="checkbox" className="switch_check" name="demo_ot" onChange={(e) => handleChange(e)} />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>YES</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>NO</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="yn_switch mb-20 mt-10">
                                                <div className="label-container">
                                                    <p>Speech Therapy</p>
                                                </div>
                                                <label className="switch-container">
                                                    <input type="checkbox" className="switch_check" name="demo_speech_therapy" onChange={(e) => handleChange(e)}  />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>YES</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>NO</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="yn_switch mb-10 mt-10">
                                                <div className="label-container">
                                                    <p>Day Care</p>
                                                </div>
                                                <label className="switch-container">
                                                    <input type="checkbox" className="switch_check" name="demo_day_care" onChange={(e) => handleChange(e)} />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>YES</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>NO</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="yn_switch mb-20 mt-0">
                                                <div className="label-container">
                                                    <p>CPN</p>
                                                </div>
                                                <label className="switch-container">
                                                    <input type="checkbox" className="switch_check" name="demo_cpn" onChange={(e) => handleChange(e)} />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>YES</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>NO</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="yn_switch mb-20 mt-0">
                                                <div className="label-container">
                                                    <p>Garage</p>
                                                </div>
                                                <label className="switch-container">
                                                    <input type="checkbox" className="switch_check" name="demo_garage" onChange={(e) => handleChange(e)} />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>YES</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>NO</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="yn_switch mb-0 mt-0">
                                                <div className="label-container">
                                                    <p>DANS</p>
                                                </div>
                                                <label className="switch-container">
                                                    <input type="checkbox" className="switch_check" name="demo_dans" onChange={(e) => handleChange(e)} />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>YES</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>NO</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="yn_switch mb-0 mt-0">
                                                <div className="label-container">
                                                    <p>Older People Mental Health Team</p>
                                                </div>
                                                <label className="switch-container">
                                                    <input type="checkbox" className="switch_check" name="demo_older" onChange={(e) => handleChange(e)} />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>YES</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>NO</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form_group">
                                        <label className="label">Others </label>
                                        <input type="text" className="form-control"  name="demo_service_others" onChange={(e) => updateWardValue(e)}/>
                                    </div>
                                    <p className="note mb-0">Any appointment for treatment, investigation or social assessment is
                                        rearranged</p>


                                </div>
                                <div className="section ctr">
                                    <h3 className="sub_title mb-10">STRICTLY CONFIDENTIAL</h3>
                                    <p className="ps1">The National Health Service needs to know the ethnic group of patients for
                                        the purpose of planning. This is to make sure that all sectors of the community have
                                        equal access to the services provided.</p>
                                    <p className="ps1">Ethnic group describes how you see yourself and is a mixture of culture,
                                        religion, skin colour, language and the origins of yourself or your family. It is not
                                        the same as nationality.</p>
                                    <p className="ps1">The information will be treated in the strictest confidence.</p>
                                    <p className="ps1">The information is used only by National Health Service staff and will not be
                                        passed on to other agencies or used for any other purposes.</p>

                                    <div className="row fyb">
                                        <div className="col-md-12">
                                            <div className="search_dropdown_blk mb-0">
                                                <div className="form_group">
                                                    <label className="label">Please indicate the ethnic group to which you feel you belong:</label>
                                                    <div className="floating-label-group mb-0">
                                                        <div className="search_dropdown">
                                                        <Select
                                                    
                                                            onChange={(e) => {
                                                                handleSelectGroup(e, "demo_ethic_group")
                                                            }}
                                                            options={optionGroup}
                                                            classNamePrefix="select2-selection"
                                                        />
                                                            
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>

                                </div>
                                <div className="section ctr">
                                    <h2 className="popup_title">PERSONAL</h2>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="check_item mt-0 mb-10"><label className="check">Smoking</label>
                                                <div className="select_btns popup_select">
                                                    <div className="select_btn"><label><input name="demo_smoking" onClick={(e) => updateWardValue(e)} type="radio"
                                                        value="Current" /><span>Current
                                                        </span></label></div>
                                                    <div className="select_btn"><label><input name="demo_smoking" onClick={(e) => updateWardValue(e)} type="radio"
                                                        value="Ex Never" /><span> Ex Never </span></label>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="form_group">
                                                <label className="label"> per day </label>
                                                <input type="text" className="form-control" name="demo_smoke_per_day" onChange={(e) => updateWardValue(e)} />
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="form_group">
                                                <label className="label"> for Years</label>
                                                <input type="text" className="form-control" name="demo_smoke_years" onChange={(e) => updateWardValue(e)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="check_item mt-0 mb-10">
                                                <label className="check">Offered smoking cessation referral </label>
                                                <div className="select_btns popup_select">
                                                    <div className="select_btn"><label><input name="demo_offered_smoking" onClick={(e) => updateWardValue(e)} type="radio"
                                                        value="Accept" /><span>Accept
                                                        </span></label></div>
                                                    <div className="select_btn"><label><input name="demo_offered_smoking" onClick={(e) => updateWardValue(e)} type="radio"
                                                        value="Decline" /><span>Decline </span></label>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="check_item mt-0 mb-10">
                                                <label className="check">NRT offered </label>
                                                <div className="select_btns popup_select">
                                                    <div className="select_btn"><label><input name="demo_offered_nrt" onClick={(e) => updateWardValue(e)}  type="radio"
                                                        value="Accept" /><span>Accept
                                                        </span></label></div>
                                                    <div className="select_btn"><label><input name="demo_offered_nrt" onClick={(e) => updateWardValue(e)}  type="radio"
                                                        value="Decline" /><span>Decline </span></label>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row mt-10">
                                        <div className="col-md-4">
                                            <div className="check_item mt-0 mb-10"><label className="check">Alcohol</label>
                                                <div className="select_btns popup_select">
                                                    <div className="select_btn"><label><input name="demo_alcohol" onClick={(e) => updateWardValue(e)} type="radio"
                                                        value="Current" /><span>Current
                                                        </span></label></div>
                                                    <div className="select_btn"><label><input name="demo_alcohol" onClick={(e) => updateWardValue(e)} type="radio"
                                                        value="Ex Never" /><span> Ex Never </span></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="yn_switch mb-10 mt-0">
                                                <div className="label-container">
                                                    <p>Likely to develop withdrawl? </p>
                                                </div>
                                                <label className="switch-container">
                                                    <input type="checkbox" className="switch_check" name="demo_develop_withdrwal_alcohol" onChange={(e) => handleChange(e)} />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>YES</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>NO</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="check_item mt-0 mb-10"><label className="check">Wernicke’s Healthy Lifestyle
                                                advice given</label>
                                                <div className="select_btns popup_select">
                                                    <div className="select_btn"><label><input name="demo_wernicke" onClick={(e) => updateWardValue(e)} type="radio"
                                                        value="Smoking" /><span>Smoking
                                                        </span></label></div>
                                                    <div className="select_btn"><label><input name="demo_wernicke" onClick={(e) => updateWardValue(e)} type="radio"
                                                        value="Alcohol" /><span> Alcohol </span></label>
                                                    </div>
                                                    <div className="select_btn"><label><input name="demo_wernicke" onClick={(e) => updateWardValue(e)} type="radio"
                                                        value="Weight" /><span> Weight </span></label>
                                                    </div>
                                                    <div className="select_btn"><label><input name="demo_wernicke" onClick={(e) => updateWardValue(e)} type="radio"
                                                        value="Exercise" /><span> Exercise </span></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="check_item mt-0 mb-10"><label className="check">Recreational drug
                                                use</label>
                                                <div className="select_btns popup_select">
                                                    <div className="select_btn"><label><input name="demo_recreational" onClick={(e) => updateWardValue(e)} type="radio"
                                                        value="Current" /><span>Current
                                                        </span></label></div>
                                                    <div className="select_btn"><label><input name="demo_recreational" onClick={(e) => updateWardValue(e)} type="radio"
                                                        value="Ex" /><span> Ex </span></label>
                                                    </div>
                                                    <div className="select_btn"><label><input name="demo_recreational" onClick={(e) => updateWardValue(e)} type="radio"
                                                        value="Never" /><span> Never </span></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="yn_switch mb-10 mt-0">
                                                <div className="label-container">
                                                    <p>Likely to develop with drawl? </p>
                                                </div>
                                                <label className="switch-container">
                                                    <input type="checkbox" className="switch_check" name="demo_develop_withdrwal_drug" onChange={(e) => handleChange(e)} />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>YES</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>NO</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="check_item mt-0 mb-10"><label className="check">ALLERGIES & REACTIONS</label>
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input name="demo_allergies" onClick={(e) => updateWardValue(e)} type="radio"
                                                value="Medications" /><span>Medications
                                                </span></label></div>
                                            <div className="select_btn"><label><input name="demo_allergies" onClick={(e) => updateWardValue(e)} type="radio"
                                                value="Latex / Rubber" /><span> Latex / Rubber </span></label>
                                            </div>
                                            <div className="select_btn"><label><input name="demo_allergies" onClick={(e) => updateWardValue(e)} type="radio"
                                                value="Metals" /><span> Metals </span></label>
                                            </div>
                                            <div className="select_btn"><label><input name="demo_allergies" onClick={(e) => updateWardValue(e)} type="radio"
                                                value="Adhesive tape" /><span> Adhesive tape </span></label>
                                            </div>
                                            <div className="select_btn"><label><input name="demo_allergies" onClick={(e) => updateWardValue(e)} type="radio"
                                                value="Food: Nuts" /><span> Food: Nuts </span></label>
                                            </div>
                                            <div className="select_btn"><label><input name="demo_allergies" onClick={(e) => updateWardValue(e)} type="radio"
                                                value="Other" /><span> Other </span></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form_group">
                                        <label className="label">Others </label>
                                        <input type="text" className="form-control" name="demo_allery_others" onClick={(e) => updateWardValue(e)} />
                                    </div>
                                    <div className="check_item mt-0 mb-10"><label className="check">Infections</label>
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input name="demo_infections" onClick={(e) => updateWardValue(e)} type="radio"
                                                value="HIV Positive" /><span>HIV Positive
                                                </span></label></div>
                                            <div className="select_btn"><label><input name="demo_infections" onClick={(e) => updateWardValue(e)} type="radio"
                                                value="Hep B positive" /><span> Hep B positive </span></label>
                                            </div>
                                            <div className="select_btn"><label><input name="demo_infections" onClick={(e) => updateWardValue(e)} type="radio"
                                                value="Hep C positive" /><span> Hep C positive </span></label>
                                            </div>
                                            <div className="select_btn"><label><input name="demo_infections" onClick={(e) => updateWardValue(e)} type="radio"
                                                value="Any other infective disease" /><span> Any other infective disease </span></label>
                                            </div>


                                        </div>
                                    </div>
                                    <div className="form_group">
                                        <label className="label">Any other infective disease </label>
                                        <input type="text" className="form-control" name="demo_infective_disease" onChange={(e) => updateWardValue(e)} />
                                    </div>
                                    <div className="row">
                                        <div className="col-md-2">
                                            <div className="form_group">
                                                <label className="label">HEIGHT</label>
                                                <input type="text" className="form-control" name="demo_height" onChange={(e) => updateWardValue(e)} />
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="form_group">
                                                <label className="label">WEIGHT</label>
                                                <input type="text" className="form-control" name="demo_weight" onChange={(e) => updateWardValue(e)} />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="check_item mt-0 mb-10"><label className="check">BMI</label>
                                                <div className="select_btns popup_select">
                                                    <div className="select_btn"><label><input name="demo_bmi" onClick={(e) => updateWardValue(e)} type="radio"
                                                        value="BMI > 30 (simple obesity)" /><span>BMI &gt; 30 (simple obesity)
                                                        </span></label></div>
                                                    <div className="select_btn"><label><input name="demo_bmi" onClick={(e) => updateWardValue(e)} type="radio"
                                                        value="BMI > 35 with weight related co-morbidities" /><span> BMI &gt; 35 with weight related co-morbidities </span></label>
                                                    </div>
                                                    <div className="select_btn"><label><input name="demo_bmi" onClick={(e) => updateWardValue(e)} type="radio"
                                                        value="> 40 (morbid obesity)" /><span> &gt; 40 (morbid obesity)</span></label>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="check_item mt-0 mb-10"><label className="check">ASA</label>
                                                <div className="select_btns popup_select">
                                                    <div className="select_btn"><label><input  name="demo_asa" onClick={(e) => updateWardValue(e)} type="radio"
                                                        value="Right handed" /><span>Right handed
                                                        </span></label></div>
                                                    <div className="select_btn"><label><input  name="demo_asa" onClick={(e) => updateWardValue(e)} type="radio"
                                                        value="Left handed" /><span>Left handed  </span></label>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="popup_footer">
                                <div className="prescribe_footer">
                                    <div className="dsp-flex">
                                        <a className="icon_btn"  onClick={(e) => { updateWard(e) }}> <span className="material-icons">save</span> SAVE</a>
                                        <button className="border_btn" onClick={(e) => { updateWard(e), wardBack(e) }}><span className="material-icons">save_as</span>SAVE &amp;
                                            CLOSE
                                        </button>
                                    </div>

                                    <button onClick={(e) => { wardBack(e) }} className="btn dsp_flex"><span className="material-icons"><span className="material-icons">close</span></span>DISCARD </button>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>


        </React.Fragment>
    )
}

DemoGraphics.propTypes = {
    t: PropTypes.any,
    getWards: PropTypes.func,
    getTheatres: PropTypes.func,
    wards: PropTypes.array,
    user: PropTypes.any,
    theatres: PropTypes.array,
    clinic: PropTypes.array,
    patients: PropTypes.array,
    doctors: PropTypes.array,
    profile: PropTypes.object
}

const mapStateToProps = ({ Layout, Login }) => ({
    wards: Layout.wards,
    user: Login.user,
    theatres: Layout.theatres,
    clinic: Layout.clinic,
    patients: Layout.patients,
    doctors: Layout.doctors,
    profile: Layout.profile
})

const mapDispatchToProps = dispatch => ({
    getWards: () => dispatch(getWardsList()),
    getTheatres: () => dispatch(getTheatresList()),
    getClinic: () => dispatch(getClinicList()),
    getPatient: () => dispatch(getPatientList()),
    getUserprofile: (id) => dispatch(getUprofile(id)),
    getDoctor: () => dispatch(getDoctorList()),
    patientupdate: (id, data) => dispatch(updatePatient(id, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(DemoGraphics)
