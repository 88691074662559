import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import MetaTags from 'react-meta-tags';
import { useHistory } from "react-router-dom";
import Select from "react-select"
import { Label } from "reactstrap"
import { connect } from "react-redux"
import { getWardsList, getTheatresList, getClinicList, getPatientList, getUprofile, getDoctorList, updateTheatrePatient, getSessionList } from "../../store/actions"

const SessionTeam = props => {

    const history = useHistory();
    const [visiblePatients, setPatient] = useState(true)
    const [visiblelanding, setClanding] = useState(false)
    const [successmessage, setSuccess] = useState("")

    const [selectedGroup, setselectedGroup] = useState(null)
    const [clinicValues, setClinic] = useState({})
    const [clinicPatient, setCPatient] = useState({})

    const [nurseGroup, setNurseGroup] = useState([])
    const [surgeonGroup, setSurgeonGroup] = useState([])
    const [jrsurgeonGroup, setJRSurgeonGroup] = useState([])
    const [labGroup, setLabGroup] = useState([])
    const [receptionGroup, setReceptionGroup] = useState([])
    const [theatreValues, setTheatre] = useState({})

    const {
        wards, user, theatres, clinic, patients, profile, doctors, sessionTeams
    } = props;

    useEffect(() => {
        $(".modal-backdrop").hide()
    }, [])

    useEffect(() => {
        const { getWards, getTheatres, getClinic, getDoctor, getPatient, getUserprofile, getSessions } = props
        // getWards()
        // getTheatres()
        // getClinic()
        // getPatient()
        // getDoctor()
        getSessions()
        // let userId = localStorage.getItem("authUser")
        // getUserprofile(userId)
    }, [])

    useEffect(() => {
        console.log("sessionTeams useseffect ", sessionTeams)

        if (sessionTeams && sessionTeams.length > 0) {
            let nurseGroupList = []
            let surgeonGroupList = []
            let jrsurgeonGroupList = []
            let labGroupList = []
            let receptionGroupList = []
            sessionTeams.forEach(item => {
                if (item.designation == "nurse") {
                    let obj = {
                        label: item.firstname + " " + item.lastname,
                        value: item._id
                    }
                    nurseGroupList.push(obj)
                }
                if (item.designation == "seniorDoctor") {
                    let obj = {
                        label: item.firstname + " " + item.lastname,
                        value: item._id
                    }
                    surgeonGroupList.push(obj)
                }
                if (item.designation == "juniorDoctor") {
                    let obj = {
                        label: item.firstname + " " + item.lastname,
                        value: item._id
                    }
                    jrsurgeonGroupList.push(obj)
                }
                if (item.designation == "lab") {
                    let obj = {
                        label: item.firstname + " " + item.lastname,
                        value: item._id
                    }
                    labGroupList.push(obj)
                }
                if (item.designation == "receptionist") {
                    let obj = {
                        label: item.firstname + " " + item.lastname,
                        value: item._id
                    }
                    receptionGroupList.push(obj)
                }


            })


            setNurseGroup(nurseGroupList)
            setSurgeonGroup(surgeonGroupList)
            setJRSurgeonGroup(jrsurgeonGroupList)
            setLabGroup(labGroupList)
            setReceptionGroup(receptionGroupList)
        }

    }, [sessionTeams])


    const updateTheatre = (e) => {
        const { theatreupdate } = props
        let theatreSelected = JSON.parse(localStorage.getItem("t"))
        theatreValues["theatreId"] = theatreSelected._id
        theatreupdate(null, theatreValues)
    }

    const theatreBack = (e) => {
        e.preventDefault()
        // props.history.push("/theatre")
        history.goBack()
    }

    const handleSelectGroup = (selectedGroup, name) => {
        // console.log("selectedGroup ", selectedGroup)
        let updatedValue = { [name]: selectedGroup };
        setTheatre(theatreValues => ({
            ...theatreValues,
            ...updatedValue
        }));
    }


    //console.log("sessionTeams ", sessionTeams)

    return (
        <React.Fragment>

            <MetaTags>
                <title>Session Team</title>
            </MetaTags>
            <div className="main-content">
                <div className='popup_wrp'>
                    <div className="popup_full prescribe">
                        <div className='popup_hed'>
                            <h2> <span className="sub_text">Session Details</span> Session Team</h2>
                            <a className='popup_close' onClick={(e) => theatreBack(e)} ><span className="material-icons">close</span></a>
                        </div>
                        <div className='popup_content'>

                            <div className='popup_scroll main pr'>
                                <div className="section">
                                    <h2 className="popup_title">Nursing</h2>
                                    <div className="search_dropdown_blk">
                                        <div className="form_group">
                                            <div className="floating-label-group">

                                                <Label>Scrub</Label>
                                                <Select

                                                    onChange={(e) => {
                                                        handleSelectGroup(e, "scrub1")
                                                    }}
                                                    options={nurseGroup}
                                                    classNamePrefix="select2-selection"
                                                />

                                            </div>
                                        </div>
                                    </div>
                                    <div className="search_dropdown_blk">
                                        <div className="form_group">
                                            <div className="floating-label-group">
                                                <Label>Scrub</Label>
                                                <Select

                                                    onChange={(e) => {
                                                        handleSelectGroup(e, "scrub2")
                                                    }}
                                                    options={nurseGroup}
                                                    classNamePrefix="select2-selection"
                                                />

                                            </div>
                                        </div>
                                    </div>
                                    <div className="search_dropdown_blk">
                                        <div className="form_group">
                                            <div className="floating-label-group">
                                                <Label>Scrub</Label>
                                                <Select

                                                    onChange={(e) => {
                                                        handleSelectGroup(e, "scrub3")
                                                    }}
                                                    options={nurseGroup}
                                                    classNamePrefix="select2-selection"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="search_dropdown_blk">
                                        <div className="form_group">
                                            <div className="floating-label-group">
                                                <Label>Circulating</Label>
                                                <Select

                                                    onChange={(e) => {
                                                        handleSelectGroup(e, "circulating1")
                                                    }}
                                                    options={labGroup}
                                                    classNamePrefix="select2-selection"
                                                />

                                            </div>
                                        </div>
                                    </div>
                                    <div className="search_dropdown_blk">
                                        <div className="form_group">
                                            <div className="floating-label-group">
                                                <Label>Circulating</Label>
                                                <Select

                                                    onChange={(e) => {
                                                        handleSelectGroup(e, "circulating2")
                                                    }}
                                                    options={labGroup}
                                                    classNamePrefix="select2-selection"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="search_dropdown_blk">
                                        <div className="form_group">
                                            <div className="floating-label-group">
                                                <Label>Circulating</Label>
                                                <Select

                                                    onChange={(e) => {
                                                        handleSelectGroup(e, "circulating3")
                                                    }}
                                                    options={labGroup}
                                                    classNamePrefix="select2-selection"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="search_dropdown_blk">
                                        <div className="form_group">
                                            <div className="floating-label-group">
                                                <Label>Theatre Practitioner Incharge</Label>
                                                <Select

                                                    onChange={(e) => {
                                                        handleSelectGroup(e, "theatrePractitioner1")
                                                    }}
                                                    options={labGroup}
                                                    classNamePrefix="select2-selection"
                                                />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="section">
                                    <h2 className="popup_title">Anaesthesia</h2>
                                    <div className="search_dropdown_blk">
                                        <div className="form_group">
                                            <div className="floating-label-group">
                                                <Label>Anesthetist</Label>
                                                <Select

                                                    onChange={(e) => {
                                                        handleSelectGroup(e, "anesthetist1")
                                                    }}
                                                    options={labGroup}
                                                    classNamePrefix="select2-selection"
                                                />

                                            </div>
                                        </div>
                                    </div>
                                    <div className="search_dropdown_blk">
                                        <div className="form_group">
                                            <div className="floating-label-group">
                                                <Label>Asst. Anesthetist</Label>
                                                <Select

                                                    onChange={(e) => {
                                                        handleSelectGroup(e, "asst_anesthetist1")
                                                    }}
                                                    options={labGroup}
                                                    classNamePrefix="select2-selection"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="search_dropdown_blk">
                                        <div className="form_group">
                                            <div className="floating-label-group">
                                                <Label>Supporting Anesthetist</Label>
                                                <Select

                                                    onChange={(e) => {
                                                        handleSelectGroup(e, "asst_anesthetist2")
                                                    }}
                                                    options={labGroup}
                                                    classNamePrefix="select2-selection"
                                                />


                                            </div>
                                        </div>
                                    </div>
                                    <div className="search_dropdown_blk">
                                        <div className="form_group">
                                            <div className="floating-label-group">
                                                <Label>Circulating</Label>
                                                <Select

                                                    onChange={(e) => {
                                                        handleSelectGroup(e, "circulating4")
                                                    }}
                                                    options={labGroup}
                                                    classNamePrefix="select2-selection"
                                                />


                                            </div>
                                        </div>
                                    </div>
                                    <div className="search_dropdown_blk">
                                        <div className="form_group">
                                            <div className="floating-label-group">
                                                <Label>Circulating</Label>
                                                <Select

                                                    onChange={(e) => {
                                                        handleSelectGroup(e, "circulating5")
                                                    }}
                                                    options={labGroup}
                                                    classNamePrefix="select2-selection"
                                                />


                                            </div>
                                        </div>
                                    </div>
                                    <div className="search_dropdown_blk">
                                        <div className="form_group">
                                            <div className="floating-label-group">
                                                <Label>Circulating</Label>
                                                <Select

                                                    onChange={(e) => {
                                                        handleSelectGroup(e, "circulating6")
                                                    }}
                                                    options={labGroup}
                                                    classNamePrefix="select2-selection"
                                                />


                                            </div>
                                        </div>
                                    </div>
                                    <div className="search_dropdown_blk">
                                        <div className="form_group">
                                            <div className="floating-label-group">
                                                <Label>Theatre Practitioner Incharge</Label>
                                                <Select

                                                    onChange={(e) => {
                                                        handleSelectGroup(e, "theatrePractitioner2")
                                                    }}
                                                    options={labGroup}
                                                    classNamePrefix="select2-selection"
                                                />


                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h2 className="popup_title">ODP</h2>
                                <div className="section">
                                    <h2 className="popup_title">SURGEONS</h2>
                                    <div className="search_dropdown_blk">
                                        <div className="form_group">
                                            <div className="floating-label-group">
                                                <Label>Surgeon</Label>
                                                <Select

                                                    onChange={(e) => {
                                                        handleSelectGroup(e, "surgeon")
                                                    }}
                                                    options={surgeonGroup}
                                                    classNamePrefix="select2-selection"
                                                />


                                            </div>
                                        </div>
                                    </div>
                                    <div className="search_dropdown_blk">
                                        <div className="form_group">
                                            <div className="floating-label-group">
                                                <Label>First Asst</Label>
                                                <Select

                                                    onChange={(e) => {
                                                        handleSelectGroup(e, "firstAsst")
                                                    }}
                                                    options={jrsurgeonGroup}
                                                    classNamePrefix="select2-selection"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="search_dropdown_blk">
                                        <div className="form_group">
                                            <div className="floating-label-group">
                                                <Label>Second Asst</Label>
                                                <Select

                                                    onChange={(e) => {
                                                        handleSelectGroup(e, "secondAsst")
                                                    }}
                                                    options={jrsurgeonGroup}
                                                    classNamePrefix="select2-selection"
                                                />

                                            </div>
                                        </div>
                                    </div>
                                    <div className="search_dropdown_blk">
                                        <div className="form_group">
                                            <div className="floating-label-group">
                                                <Label>Visitors</Label>
                                                <Select

                                                    onChange={(e) => {
                                                        handleSelectGroup(e, "visitors")
                                                    }}
                                                    options={receptionGroup}
                                                    classNamePrefix="select2-selection"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className='popup_footer'>
                            <div className="prescribe_footer">
                                <div className="dsp-flex">
                                    <a className="icon_btn" onClick={(e) => { updateTheatre(e) }}> <span className="material-icons">save</span> SAVE</a>
                                    <button className="border_btn" onClick={(e) => { updateTheatre(e), theatreBack(e) }}><span className="material-icons">save_as</span>SAVE & CLOSE
                                    </button>
                                </div>

                                <button className="btn dsp_flex" onClick={(e) => { theatreBack(e) }}><span className="material-icons"><span
                                    className="material-icons">close</span></span>DISCARD </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </React.Fragment>
    )
}

SessionTeam.propTypes = {
    t: PropTypes.any,
    getWards: PropTypes.func,
    getTheatres: PropTypes.func,
    wards: PropTypes.array,
    user: PropTypes.any,
    theatres: PropTypes.array,
    clinic: PropTypes.array,
    patients: PropTypes.array,
    doctors: PropTypes.array,
    profile: PropTypes.object,
    sessionTeams: PropTypes.array,
}

const mapStateToProps = ({ Layout, Login }) => ({
    wards: Layout.wards,
    user: Login.user,
    theatres: Layout.theatres,
    clinic: Layout.clinic,
    patients: Layout.patients,
    sessionTeams: Layout.sessionTeams,
    doctors: Layout.doctors,
    profile: Layout.profile
})

const mapDispatchToProps = dispatch => ({
    getWards: () => dispatch(getWardsList()),
    getTheatres: () => dispatch(getTheatresList()),
    getClinic: () => dispatch(getClinicList()),
    getPatient: () => dispatch(getPatientList()),
    getUserprofile: (id) => dispatch(getUprofile(id)),
    getDoctor: () => dispatch(getDoctorList()),
    theatreupdate: (id, data) => dispatch(updateTheatrePatient(id, data)),
    getSessions: () => dispatch(getSessionList()),
})

export default connect(mapStateToProps, mapDispatchToProps)(SessionTeam)
