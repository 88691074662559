import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import MetaTags from 'react-meta-tags';
import PatientBlock from "../Clinic/patientBlock";
import { Link } from "react-router-dom"
import WardMenus from "./wardMenus";
import { connect } from "react-redux"
import { getWardsList, getTheatresList, getClinicList, getPatientList, getUprofile, getDoctorList, updatePatient, getNews, updateDailyRounds, getDailyRounds } from "../../store/actions"

const LifeTimeRecord = props => {
    document.body.style.overflow = '';
    let clinicPatient = JSON.parse(localStorage.getItem("p"))
 
    return (
        <React.Fragment>

            <MetaTags>
                <title>Lifetime Record</title>
            </MetaTags>
            <div className='ward '>
                <div className="main">
                    <div className="wrapper">

                        <div className=" out_patient_section out_patient_details mb-0">

                            <PatientBlock patient={clinicPatient} accordion={true} />
                            <WardMenus />


                        </div>


                        <div className="titleandinp">
                            <h3>Lifetime Record</h3>
                            <div className="search_dropdown_blk">
                                <div className="floating-label-group">
                                    <div className="search_dropdown">
                                        <select className="form-control">
                                            <option value="1">Filters</option>
                                            <option value="2">Lifetime Record</option>
                                            <option value="3">Ward</option>
                                            <option value="4">Clinic</option>
                                            <option value="5">Date</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="section mt-10">
                            <div className="search_block">
                                <span className="material-icons search_icon"> search </span>
                                <input type="text" className="search" placeholder="Search" />
                            </div>
                            <div className="patients_section ward_patients">
                                <div className="sections_block">
                                    <table className=" table table-bordered lf_record">
                                        <thead>
                                            <tr>
                                                <th width="65%"> Title</th>
                                                <th width="20%">Last Updated</th>
                                                <th width="15%" className="align-center">Actions </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>  <Link to="/demographicsdata"> Demographics</Link></td>
                                                <td>20-10-2023</td>
                                                <td>
                                                    <div className="dsp_flex">
                                                        <button className="icon_btn"> <span className="material-icons"> edit </span>
                                                            Edit</button>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td> <a href="#">Front Sheet</a> </td>
                                                <td>20-10-2023</td>
                                                <td>
                                                    <div className="dsp_flex">
                                                        <button className="icon_btn"> <span className="material-icons"> edit </span>
                                                            Edit</button>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td> <a href="#">Legal</a> </td>
                                                <td>20-10-2023</td>
                                                <td>
                                                    <div className="dsp_flex">
                                                        <button className="icon_btn"> <span className="material-icons"> edit </span>
                                                            Edit</button>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td> <a href="#"> Hospital Admission Episodes</a></td>
                                                <td>20-10-2023</td>
                                                <td>
                                                    <div className="dsp_flex">
                                                        <button className="icon_btn"> <span className="material-icons"> edit </span>
                                                            Edit</button>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td> <a href="#">Outpatient Episodes</a> </td>
                                                <td>20-10-2023</td>
                                                <td>
                                                    <div className="dsp_flex">
                                                        <button className="icon_btn"> <span className="material-icons"> edit </span>
                                                            Edit</button>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td> <a href="#">Surgical Procedures</a> </td>
                                                <td>20-10-2023</td>
                                                <td>
                                                    <div className="dsp_flex">
                                                        <button className="icon_btn"> <span className="material-icons"> edit </span>
                                                            Edit</button>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><a href="#">Investigations</a> </td>
                                                <td>20-10-2023</td>
                                                <td>
                                                    <div className="dsp_flex">
                                                        <button className="icon_btn"> <span className="material-icons"> edit </span>
                                                            Edit</button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>





                        </div>

                    </div>
                </div>
            </div>




        </React.Fragment>
    )
}

LifeTimeRecord.propTypes = {
    getWards: PropTypes.func,
    getTheatres: PropTypes.func,
    wards: PropTypes.array,
    user: PropTypes.any,
    theatres: PropTypes.array,
    clinic: PropTypes.array,
    patients: PropTypes.array,
    doctors: PropTypes.array,
    profile: PropTypes.object,
    news: PropTypes.array,
}

const mapStateToProps = ({ Layout, Login }) => ({
    wards: Layout.wards,
    user: Login.user,
    theatres: Layout.theatres,
    clinic: Layout.clinic,
    patients: Layout.patients,
    doctors: Layout.doctors,
    profile: Layout.profile,
    news: Layout.news,
    dailyrounds: Layout.dailyrounds
})

const mapDispatchToProps = dispatch => ({
    getWards: () => dispatch(getWardsList()),
    getTheatres: () => dispatch(getTheatresList()),
    getClinic: () => dispatch(getClinicList()),
    getPatient: () => dispatch(getPatientList()),
    getUserprofile: (id) => dispatch(getUprofile(id)),
    getDoctor: () => dispatch(getDoctorList()),
    patientupdate: (id, data) => dispatch(updatePatient(id, data)),
    newsList: (data) => dispatch(getNews(data)),
    dailyRoundsList: (data) => dispatch(getDailyRounds(data)),
    dailyUpdate: (data) => dispatch(updateDailyRounds(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(LifeTimeRecord)
