import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux"
import { getWardsList, getTheatresList, getClinicList, getPatientList, getUprofile, getDoctorList, updatePatient } from "../../store/actions"

const PatientList = props => {

    const [modal_standard, setmodal_standard] = useState(false)
    const [clinicValues, setClinic] = useState({})

    const [selectedGroup, setselectedGroup] = useState(null)
    const [popupname, setpopup_name] = useState({})
    const [anydelay, setDelay] = useState(false)

    const {
        wards, user, theatres, clinic, patients, profile, doctors
    } = props;

    useEffect(() => {
        const { getWards, getTheatres, getClinic, getDoctor, getPatient, getUserprofile } = props
        // getWards()
        // getTheatres()
        // getClinic()
        getPatient()
        getDoctor()
        let userId = localStorage.getItem("authUser")
        getUserprofile(userId)
    }, [])


    const patient = (e, item) => {
        e.preventDefault()
        localStorage.setItem("p", JSON.stringify(item))
        let redirectRoute = localStorage.getItem("re")
        
        props.history.push("/"+redirectRoute)
    }



    return (
        <React.Fragment>

            <MetaTags>
                <title>List of Patients</title>
            </MetaTags>
            <div className='ward '>
                <div className="wrapper">
                    <div className="titleandinp">
                        <h3>List of Patients - Please Select Patient</h3>
                        <div className="search_dropdown_blk">
                            <div className="floating-label-group">
                                <div className="search_dropdown">
                                    <select className="form-control">
                                        <option value="1">Sort By</option>
                                        <option value="2">1</option>
                                        <option value="3">2</option>
                                        <option value="4">3</option>
                                        <option value="5">4</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="section mt-10">
                        <div className="search_block">
                            <span className="material-icons search_icon"> search </span>
                            <input type="text" className="search" placeholder="Search Patients" />
                                <span className="material-icons qr_icon"> qr_code_scanner </span>
                        </div>
                        <div className="patients_section ward_patients">
                            <div className="sections_block">
                                <div className="patients_table">
                                    <table>
                                        <thead>
                                            <tr>
                                                <td className="headcol">Patient</td>
                                                <th>Consultant</th>
                                                <th>Assigned Nurse</th>
                                                <th>Aculty</th>
                                                <th>Team</th>

                                            </tr>
                                        </thead>
                                        {patients && patients.length > 0 &&
                                        patients.map((item, i) => (
                                            
                                            <tr onClick={(e)=> patient(e, item)}  key={i}>
                                                <td className="headcol">
                                                    <div className="ptient_name">{item.firstname + " " + item.lastname}</div>
                                                    <div className="ptient_age"> <span>{item.sex}</span> <span>{item.age}Yrs</span></div>
                                                </td>
                                                <td className="long">{item.doctor}</td>
                                                <td className="long">Dr. Jansen Hoe</td>
                                                <td className="long">Dr. Jansen Hoe</td>
                                                <td className="long">Dr. Jansen Hoe</td>
                                            </tr>
                                        ))}
                                        
                                       
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>


        </React.Fragment>
    )
}

PatientList.propTypes = {
    t: PropTypes.any,
    getWards: PropTypes.func,
    getTheatres: PropTypes.func,
    wards: PropTypes.array,
    user: PropTypes.any,
    theatres: PropTypes.array,
    clinic: PropTypes.array,
    patients: PropTypes.array,
    doctors: PropTypes.array,
    profile: PropTypes.object
}

const mapStateToProps = ({ Layout, Login }) => ({
    wards: Layout.wards,
    user: Login.user,
    theatres: Layout.theatres,
    clinic: Layout.clinic,
    patients: Layout.patients,
    doctors: Layout.doctors,
    profile: Layout.profile
})

const mapDispatchToProps = dispatch => ({
    getWards: () => dispatch(getWardsList()),
    getTheatres: () => dispatch(getTheatresList()),
    getClinic: () => dispatch(getClinicList()),
    getPatient: () => dispatch(getPatientList()),
    getUserprofile: (id) => dispatch(getUprofile(id)),
    getDoctor: () => dispatch(getDoctorList()),
    patientupdate: (id, data) => dispatch(updatePatient(id, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(PatientList)
