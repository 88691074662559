import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import MetaTags from 'react-meta-tags';


import PatientBlock from "../Clinic/patientBlock";
import { connect } from "react-redux"
import { getWardsList, getTheatresList, getClinicList, getPatientList, getUprofile, getDoctorList, updatePatient } from "../../store/actions"

const Delirium = props => {

    const [deliriumScore, setDeliriumScore] = useState(0)
    const [deliriumValues, setDelirium] = useState({})

    const {
        wards, user, theatres, clinic, patients, profile, doctors
    } = props;

    useEffect(() => {
        const { getWards, getTheatres, getClinic, getDoctor, getPatient, getUserprofile } = props
        // getWards()
        // getTheatres()
        // getClinic()
        getPatient()
        getDoctor()
        let userId = localStorage.getItem("authUser")
        getUserprofile(userId)
    }, [])

    const updateValue = (e) => {
        let val = e.target.value;
        let name = e.target.name;
        let updatedValue = { [name]: val };
        setDelirium(deliriumValues => ({
            ...deliriumValues,
            ...updatedValue
        }));
    }



    const closeClick = (e) => {
        e.preventDefault()
        localStorage.removeItem("a")
        props.history.push("/riskassesment")
    }

    let assesmentPage = JSON.parse(localStorage.getItem("a"))
    let clinicPatient = JSON.parse(localStorage.getItem("p"))

    console.log("dl ", deliriumValues, deliriumScore)

    return (
        <React.Fragment>

            <MetaTags>
                <title>{assesmentPage}</title>
            </MetaTags>
            <div className="dashboard ward">
                <div className="main">

                    <div className="popup_wrp">
                        <div className="popup_full prescribe">
                            <div className="popup_hed">
                                <h2>DELIRIUM </h2>
                                <div className="dsp-flex"> <a className="popup_close" onClick={(e) => closeClick(e)}><span className="material-icons">close</span></a></div>

                            </div>
                            <div className="popup_content">
                                <div className="popup_scroll w-btns main nutrition">


                                    <div className="modal add_form guidance_popup fade" id="guidance" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                        <div className="modal-dialog" role="document">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title">GUIDANCE</h5>
                                                    <span className="material-icons" data-dismiss="modal" aria-label="Close">
                                                        close
                                                    </span>
                                                </div>
                                                <div className="modal-body">
                                                    <p>Observe cannula 8 hourly or more frequently if clinically indicated
                                                    </p>
                                                    <p>Secure cannula with an appropriate dressing – change if soiled or contaminated
                                                    </p>
                                                    <p>Aseptic technique must be followed for insertion</p>
                                                    <p>Consider re-siting the cannula every 48-72 hours or as indicated by VIP score
                                                        Plan and document care
                                                    </p>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <PatientBlock patient={clinicPatient} />
                                    <div className="bar">
                                        <div className="left">
                                            <div className="tag" data-toggle="modal"
                                                data-target="#guidance"> Guidance </div>
                                            <h3 className="sub_title">DELIRIUM </h3>
                                        </div>
                                        <div className="right">
                                            <a href="#" className="btn"> <span className="material-symbols-outlined"> assignment </span> Not
                                                Yet Done</a>
                                            <div className="tag"> Due </div>
                                        </div>
                                    </div>


                                    <div className="section ctr">

                                        <p className="field_title mt-0">Delirium superimposed on Dementia</p>
                                        <ol className="mt-10">
                                            <li>Basic medical and drug history and examination to look for common causes: Hypoxia –
                                                Pain - Chest and urinary infection - Drug toxicity from sedatives and opiates -
                                                Urinary retention - Constipation – Dehydration – Environment - Devices.</li>
                                            <li>Need early assessment from COE team. – Ext….</li>
                                            <li>If pts do not settle with reassurance or first line intervention, urgently contact
                                                OPMH team.</li>
                                            <li>Consider enhanced care needs</li>
                                            <li>John’s campaign</li>
                                            <li>This is me</li>
                                            <li>Remember sensory aids</li>
                                            <li>Avoid restraint (including meds)</li>
                                            <li>Avoid unnecessary moves</li>
                                            <li>Consider 4AT to monitor cognition.</li>
                                            <li>Re-assess weekly where required and if a patient condition changes or on ward
                                                transfer.</li>
                                        </ol>

                                        <p className="field_title mt-0">Delirium without underlying Dementia</p>
                                        <ol className="mt-10">
                                            <li>Basic medical and drug history and examination to look for common causes: Hypoxia –
                                                Pain - Chest and urinary infection - Drug toxicity from sedatives and opiates -
                                                Urinary retention - Constipation – Dehydration – Environment - Devices.</li>
                                            <li>Need early assessment from COE team. – Ext….</li>
                                            <li>If pts do not settle with reassurance or first line intervention, urgently contact
                                                OPMH team.</li>
                                            <li>Re-assess weekly where required and if a patient condition changes or on ward
                                                transfer.</li>
                                        </ol>
                                    </div>
                                    <div className="section ctr">
                                        <h2 className="popup_title mt-0">4 AT Assessment Test for Delirium & Cognitive Impairment</h2>
                                        <div className="check_item full mt-0 mb-0"><label className="check">[1] ALERTNESS: Ask the patient
                                            to state their name and address to assist rating.
                                        </label>
                                            <p className="ps3">This includes patients who may be markedly drowsy (eg. difficult to rouse
                                                and/or obviously sleepy during assessment) or agitated/hyperactive. Observe the
                                                patient. If asleep, attempt to wake with speech or gentle touch on shoulder. </p>
                                            <div className="select_btns mt-0">
                                                <div className="select_btn"><label><input onClick={(e) => {updateValue(e), setDeliriumScore((prevCount) => prevCount + 0)}} name="delirium_assist" type="radio" value="Normal (fully alert,
                                                    but not agitated, throughout assessment" /><span> <span
                                                    className="num">0</span> Normal (fully alert,
                                                    but not agitated, throughout assessment) </span></label>
                                                </div>
                                                <div className="select_btn"><label><input onClick={(e) => {updateValue(e), setDeliriumScore((prevCount) => prevCount + 0)}} name="delirium_assist" type="radio" value="Mild sleepiness for
                                                   < 10 seconds after waking, then normal" /><span> <span
                                                    className="num">0</span> Mild sleepiness for
                                                    &lt; 10 seconds after waking, then normal </span></label>
                                                </div>
                                                <div className="select_btn"><label><input onClick={(e) => {updateValue(e), setDeliriumScore((prevCount) => prevCount + 4)}} name="delirium_assist" type="radio" value="Clearly abnormal" /><span> <span
                                                    className="num">4</span> Clearly abnormal
                                                </span></label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="check_item full mt-10 mb-0">
                                            <label className="check">[2] AMT4: Age, date of birth,
                                                place (name of the hospital or building), current year.
                                            </label>

                                            <div className="select_btns">
                                                <div className="select_btn"><label><input onClick={(e) => {updateValue(e), setDeliriumScore((prevCount) => prevCount + 0)}} name="delirium_age" type="radio" value="No mistakes" /><span> <span
                                                    className="num">0</span> No mistakes
                                                </span></label>
                                                </div>
                                                <div className="select_btn"><label><input onClick={(e) => {updateValue(e), setDeliriumScore((prevCount) => prevCount + 1)}} name="delirium_age" type="radio" value="1 mistake" /><span> <span
                                                    className="num">1</span> 1 mistake
                                                </span></label>
                                                </div>
                                                <div className="select_btn"><label><input onClick={(e) => {updateValue(e), setDeliriumScore((prevCount) => prevCount + 4)}} name="delirium_age" type="radio" value="2 or more mistakes/untestable" /><span> <span
                                                    className="num">4</span> 2 or more
                                                    mistakes/untestable </span></label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="check_item full mt-0 mb-0">
                                            <label className="check">[3] ATTENTION: Months of the
                                                year backwards
                                            </label>
                                            <p className="ps3">Ask the patient: “Please tell me the months of the year in backwards
                                                order, starting at December.” To assist initial understanding one prompt of “what is
                                                the month before December?” is permitted. </p>
                                            <div className="select_btns mt-0">
                                                <div className="select_btn"><label><input onClick={(e) => {updateValue(e), setDeliriumScore((prevCount) => prevCount + 0)}} name="delirium_months" type="radio" value="Achieves 7 months or
                                                    more correctly" /><span> <span
                                                    className="num">0</span>Achieves 7 months or
                                                    more correctly </span></label>
                                                </div>
                                                <div className="select_btn"><label><input onClick={(e) => {updateValue(e), setDeliriumScore((prevCount) => prevCount + 1)}} name="delirium_months" type="radio" value="Starts but scores < 7 months / refuses to start" /><span> <span
                                                    className="num">1</span> Starts but scores &lt; 7 months / refuses to start</span>
                                                </label>
                                                </div>
                                                <div className="select_btn"><label><input onClick={(e) => {updateValue(e), setDeliriumScore((prevCount) => prevCount + 2)}} name="delirium_months" type="radio" value="Untestable (cannot start because unwell, drowsy, inattentive)" /><span> <span
                                                    className="num">2</span>Untestable (cannot start because unwell, drowsy, inattentive)
                                                </span></label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="check_item full mt-0 mb-0">
                                            <label className="check">[4] ACUTE CHANGE OR FLUCTUATING COURSE
                                            </label>
                                            <p className="ps3">Evidence of significant change or fluctuation in: alertness, cognition,
                                                other mental function (eg.
                                                paranoia, hallucinations) arising over the last 2 weeks and still evident in last
                                                24hrs </p>
                                            <div className="select_btns mt-0">
                                                <div className="select_btn"><label><input onClick={(e) => {updateValue(e), setDeliriumScore((prevCount) => prevCount + 0)}} name="delirium_evidence" type="radio" value="No" /><span> <span
                                                    className="num">0</span>No</span></label>
                                                </div>
                                                <div className="select_btn"><label><input onClick={(e) => {updateValue(e), setDeliriumScore((prevCount) => prevCount + 4)}} name="delirium_evidence" type="radio" value="Yes" /><span> <span
                                                    className="num">4</span> Yes</span></label>
                                                </div>
                                            </div>
                                        </div>
                                        <p className="field_title">{deliriumScore} AT SCORE</p>

                                        <p className="ps1">{deliriumScore == 0 ? 'Delirium or severe cognitive impairment unlikely' 
                                        : deliriumScore > 3 ? 'Possible delirium +/- cognitive impairment'
                                        : 'Possible cognitive impairment'}</p>
                                        {/*
                                        <p className="ps1">
                                            4 or above: possible delirium +/- cognitive impairment 
                                        
                                         <br />
                                            1-3: possible cognitive impairment
                                            <br />
                                            0: delirium or severe cognitive impairment unlikely (but delirium still possible if [4]
                                            information incomplete) 

                                        </p>*/}
                                    </div>

                                </div>

                            </div>
                            <div className="popup_footer">
                                <div className="prescribe_footer">
                                    <div className="dsp-flex">
                                        <a className="icon_btn"> <span className="material-icons">save</span> SAVE</a>
                                        <button className="border_btn"><span className="material-icons">save_as</span>SAVE &amp;
                                            CLOSE
                                        </button>
                                    </div>
                                    <button className="btn dsp_flex"><span className="material-icons"><span
                                        className="material-icons">close</span></span>DISCARD </button>
                                </div>
                            </div>
                        </div>
                    </div>





                </div>
            </div>




        </React.Fragment>
    )
}

Delirium.propTypes = {
    t: PropTypes.any,
    getWards: PropTypes.func,
    getTheatres: PropTypes.func,
    wards: PropTypes.array,
    user: PropTypes.any,
    theatres: PropTypes.array,
    clinic: PropTypes.array,
    patients: PropTypes.array,
    doctors: PropTypes.array,
    profile: PropTypes.object
}

const mapStateToProps = ({ Layout, Login }) => ({
    wards: Layout.wards,
    user: Login.user,
    theatres: Layout.theatres,
    clinic: Layout.clinic,
    patients: Layout.patients,
    doctors: Layout.doctors,
    profile: Layout.profile
})

const mapDispatchToProps = dispatch => ({
    getWards: () => dispatch(getWardsList()),
    getTheatres: () => dispatch(getTheatresList()),
    getClinic: () => dispatch(getClinicList()),
    getPatient: () => dispatch(getPatientList()),
    getUserprofile: (id) => dispatch(getUprofile(id)),
    getDoctor: () => dispatch(getDoctorList()),
    patientupdate: (id, data) => dispatch(updatePatient(id, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Delirium)
