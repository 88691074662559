import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import MetaTags from 'react-meta-tags';
import MicRecorder from 'mic-recorder-to-mp3';
import { removeSpecialCharacters } from '../../utils/utils';


import { connect } from "react-redux"
import { getWardsList, getTheatresList, getClinicList, getPatientList, getUprofile, updatePatient } from "../../store/actions"
// import { ContactsOutlined } from '@material-ui/icons';

const LabDetails = props => {
    // const recorder = new MicRecorder({
    //     bitRate: 128
    // });


    const [file, setFile] = useState({}); // storing the uploaded file    
    const {
        wards, user, theatres, clinic, patients, profile
    } = props;

    useEffect(() => {
        const { getWards, getTheatres, getClinic, getPatient, getUserprofile } = props
        // getWards()
        // getTheatres()
        // getClinic()
        // getPatient()
        let userId = localStorage.getItem("authUser")
        getUserprofile(userId)

    }, [])


    // const convertBase64 = (file) => {
    //     return new Promise((resolve, reject) => {
    //         const fileReader = new FileReader();
    //         fileReader.readAsDataURL(file)
    //         fileReader.onload = () => {
    //             resolve(fileReader.result);
    //         }
    //         fileReader.onerror = (error) => {
    //             reject(error);
    //         }
    //     })
    // }

    // const getBlob = async (fileUri) => {
    //     const resp = await fetch(fileUri);
    //     const imageBody = await resp.blob();
    //     return imageBody;
    // };

    const handleFile = async (e, name) => {

        const file = e.target.files[0]
        console.log("file ", file, name)
        let filename = e.target.files[0].name;
        // const base64 = await convertBase64(file)
        console.log("filename ", filename)
        let pdetails = JSON.parse(localStorage.getItem("p"))
        // var reader = new FileReader();
        //   reader.readAsDataURL(file);
        // reader.onload = async function (e) {
        // console.log("e ", e)
        let uploadFilePath = "ipr/" + pdetails._id + "/" + name + "_result" + "/" + removeSpecialCharacters(filename);
        let data = { source_bucket: "ipr-resources", sourcepath: uploadFilePath }
        console.log("data ", data)

        const response = await fetch(API_URL+'upload?appname=ipr', {
            method: "POST",
            mode: "cors",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
        const result = await response.json();

        console.log("result ", result)
        let url = result.result
        console.log("url ", url)
        const uploadImage = await fetch(url, {
            method: "PUT",
            mode: "cors",
            headers: {
                "Content-Type": "multipart/form-data",
                "Accept": "/",
                "Cache-Control": "no-cache",
                "Accept-Encoding": "gzip, deflate",
                "Connection": "keep-alive",
                "cache-control": "no-cache"
            },
            body: file
        })

        console.log("resultImage ", uploadImage)

        let imagePath = "https://ipr-resources.s3.amazonaws.com/"
        let imageUploadPath = imagePath + uploadFilePath;

        const { patientupdate } = props
        let updateObj = {
            [name + "_result"]: imageUploadPath,
            [name + "_update"]: new Date()
        }
        patientupdate(pdetails._id, updateObj)
        setFile(file => ({
            ...file,
            ...updateObj
        }));
    };

    // const startRecording = () => {
    //     recorder.start().then(() => {
    //     }).catch((e) => {
    //         console.error(e);
    //     });
    // }

    // const stopRecording = async () => {
    //     recorder.stop().getMp3().then(async ([buffer, blob]) => {
    //         console.log(buffer, blob);
    //         const file = new File(buffer, 'music.mp3', {
    //             type: blob.type,
    //             lastModified: Date.now()
    //         });

    //         let pdetails = JSON.parse(localStorage.getItem("p"))

    //         let uploadFilePath = "ipr/" + pdetails._id + "/dictate" + "/" + removeSpecialCharacters("music.mp3");
    //         let data = { source_bucket: "ipr-resources", sourcepath: uploadFilePath }
    //         console.log("data ", data)

    //         const response = await fetch(API_URL+'upload?appname=ipr', {
    //             method: "POST",
    //             mode: "cors",
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //             body: JSON.stringify(data)
    //         })
    //         const result = await response.json();
    //         console.log("result ", result)
    //         let url = result.result
    //         console.log("url ", url)
    //         const uploadImage = await fetch(url, {
    //             method: "PUT",
    //             mode: "cors",
    //             headers: {
    //                 "Content-Type": "multipart/form-data",
    //                 "Accept": "/",
    //                 "Cache-Control": "no-cache",
    //                 "Accept-Encoding": "gzip, deflate",
    //                 "Connection": "keep-alive",
    //                 "cache-control": "no-cache"
    //             },
    //             body: file
    //         })

    //         let imagePath = "https://ipr-resources.s3.amazonaws.com/"
    //         let audioUploadPath = imagePath + uploadFilePath;
    
            
    //         const { patientupdate } = props
    //         let updateObj = {
    //             dictate: audioUploadPath,
    //             dictateUpdate: new Date()
    //         }
    //         patientupdate(pdetails._id, updateObj)

    //         const li = document.createElement('li');
    //         const player = new Audio(audioUploadPath);
    //         player.controls = true;
    //         li.appendChild(player);
    //         document.querySelector('#playlist').appendChild(li);
    //     }).catch((e) => {
    //         console.error(e);
    //     });
    // }

    let pdetails = JSON.parse(localStorage.getItem("p"))

   

    return (
        <React.Fragment>

            <MetaTags>
                <title>Lab</title>
            </MetaTags>
            <div className="dashboard">
                <div className="main">
                    <div className="wrapper">
                        <div className="out_patient_section out_patient_details ld">
                            <h2 className='main_title'>{pdetails.pro1 || pdetails.pro2}</h2>
                            <div className="patient_block">
                                <div className="content">
                                    {/* <div className="slide">
                                        <a href='#' className="material-icons">arrow_left</a>
                                        <a href='#' className="material-icons">arrow_right</a>
                                    </div> */}
                                    <div className="block">
                                        <div className="title">{pdetails.firstname + " " + pdetails.lastname}</div>
                                        <div className="title">{pdetails.age} Years</div  >
                                        <div className="sub"><div className="badge ">{pdetails.sex}</div></div>
                                    </div>
                                    <div className="block">
                                        <div><span className="title">Hospital : </span> <span className="sub">{pdetails.patientId}</span> </div>
                                        <div><span className="title">NHS: : </span> <span className="sub">{pdetails.hospitalNo}</span> </div>
                                    </div>
                                    <div className="block">
                                        <div className="sub">Ward  </div>
                                        <div className="title">{pdetails.site}</div>
                                    </div>
                                    <div className="block">
                                        <div className="title">{"Dr " + pdetails.doctor}</div>
                                        {/* <div className="badge ">Tr. & Ortho Surgeon </div> */}

                                    </div>
                                </div>
                            </div>
                            <div className="visit_info">
                                <div className="visit">
                                    <h3>Test  </h3>
                                    <div className="count">01</div>
                                </div>
                                <div className="problem">
                                    <h3>{pdetails.pro1 || pdetails.pro2}</h3>
                                    <p>Referred by {"Dr " + pdetails.doctor} <span className='ml-20'>  {pdetails.updated} </span></p>
                                </div>
                                <a href="#" className="icon_btn">SEND</a>
                            </div>
                            <div className="documents_section">

                                {pdetails.xray &&
                                    <div className="document_box">
                                        {(pdetails.xray_result) || (file && file.xray_result) ?
                                            <><img src={(pdetails.xray_result) || (file && file.xray_result)} className="img" />
                                                <a href="#" className="doc_title">
                                                    <h4>{pdetails.xray}</h4>
                                                    <p>{pdetails.xray_update}</p>
                                                </a> </>
                                            :
                                            <><label>{pdetails.xray}</label>
                                                <input type='file' name="xray" onChange={(e) => handleFile(e, "xray")} /></>
                                        }
                                    </div>
                                }
                                {pdetails.xray2 &&
                                    <div className="document_box">
                                        {(pdetails.xray2_result) || (file && file.xray2_result) ?
                                            <><img src={(pdetails.xray2_result) || (file && file.xray2_result)} className="img" />
                                                <a href="#" className="doc_title">
                                                    <h4>{pdetails.xray2}</h4>
                                                    <p>{pdetails.xray2_update}</p>
                                                </a> </>
                                            :
                                            <><label>{pdetails.xray2}</label>
                                                <input type='file' name="xray2" onChange={(e) => handleFile(e, "xray2")} /></>
                                        }
                                    </div>
                                }

                                {pdetails.test1 &&
                                    <div className="document_box">
                                        {(pdetails.test1_result) || (file && file.test1_result) ?
                                            <><img src={(pdetails.test1_result) || (file && file.test1_result)} className="img" />
                                                <a href="#" className="doc_title">
                                                    <h4>{pdetails.test1}</h4>
                                                    <p>{pdetails.test1_update}</p>
                                                </a> </>
                                            :
                                            <><label>{pdetails.test1}</label>
                                                <input type='file' name="test1" onChange={(e) => handleFile(e, "test1")} /></>
                                        }

                                    </div>
                                }
                                {pdetails.test2 &&
                                    <div className="document_box">
                                        {(pdetails.test2_result) || (file && file.test2_result) ?
                                            <><img src={(pdetails.test2_result) || (file && file.test2_result)} className="img" />
                                                <a href="#" className="doc_title">
                                                    <h4>{pdetails.test2}</h4>
                                                    <p>{pdetails.test2_update}</p>
                                                </a> </>
                                            :
                                            <><label>{pdetails.test2}</label>
                                                <input type='file' name="test2" onChange={(e) => handleFile(e, "test2")} /></>
                                        }

                                    </div>
                                }

                                {pdetails.test3 &&
                                    <div className="document_box">
                                        {(pdetails.test3_result) || (file && file.test3_result) ?
                                            <><img src={(pdetails.test3_result) || (file && file.test3_result)} className="img" />
                                                <a href="#" className="doc_title">
                                                    <h4>{pdetails.test3}</h4>
                                                    <p>{pdetails.test3_update}</p>
                                                </a> </>
                                            :
                                            <><label>{pdetails.test3}</label>
                                                <input type='file' name="test3" onChange={(e) => handleFile(e, "test3")} /></>
                                        }
                                    </div>
                                }
                                {pdetails.test4 &&
                                    <div className="document_box">
                                        {(pdetails.test4_result) || (file && file.test4_result) ?
                                            <><img src={(pdetails.test4_result) || (file && file.test4_result)} className="img" />
                                                <a href="#" className="doc_title">
                                                    <h4>{pdetails.test4}</h4>
                                                    <p>{pdetails.test4_update}</p>
                                                </a> </>
                                            :
                                            <><label>{pdetails.test4}</label>
                                                <input type='file' name="test4" onChange={(e) => handleFile(e, "test4")} /></>
                                        }
                                    </div>
                                }

                            </div>

                            {/* <button className="btn btn-primary" onClick={(e) => startRecording()}>Start recording</button>

                            <button className="btn btn-primary" onClick={(e) => stopRecording()}>Stop recording</button>

                            <ul id="playlist"></ul> */}

                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

LabDetails.propTypes = {
    t: PropTypes.any,
    getWards: PropTypes.func,
    getTheatres: PropTypes.func,
    wards: PropTypes.array,
    user: PropTypes.any,
    theatres: PropTypes.array,
    clinic: PropTypes.array,
    patients: PropTypes.array,
    profile: PropTypes.object
}

const mapStateToProps = ({ Layout, Login }) => ({
    wards: Layout.wards,
    user: Login.user,
    theatres: Layout.theatres,
    clinic: Layout.clinic,
    patients: Layout.patients,
    profile: Layout.profile
})

const mapDispatchToProps = dispatch => ({
    getWards: () => dispatch(getWardsList()),
    getTheatres: () => dispatch(getTheatresList()),
    getClinic: () => dispatch(getClinicList()),
    getPatient: () => dispatch(getPatientList()),
    getUserprofile: (id) => dispatch(getUprofile(id)),
    patientupdate: (id, data) => dispatch(updatePatient(id, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(LabDetails)
