import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux"
import { getWardsList, getTheatresList, getClinicList, getPatientList, getUprofile, getDoctorList, updatePatient } from "../../store/actions"
import PatientBlock from "../Clinic/patientBlock";
import { Link } from 'react-router-dom';

const PatientRecordDetails = props => {

    const [modal_standard, setmodal_standard] = useState(false)
    const [clinicValues, setClinic] = useState({})

    const [selectedGroup, setselectedGroup] = useState(null)
    const [popupname, setpopup_name] = useState({})
    const [anydelay, setDelay] = useState(false)

    const {
        wards, user, theatres, clinic, patients, profile, doctors
    } = props;

    useEffect(() => {
        const { getWards, getTheatres, getClinic, getDoctor, getPatient, getUserprofile } = props
        // getWards()
        // getTheatres()
        // getClinic()
        getPatient()
        getDoctor()
        let userId = localStorage.getItem("authUser")
        getUserprofile(userId)
    }, [])


    const backClick = (e) => {
        e.preventDefault()
        props.history.push("/pr")
    }

    let clinicPatient = JSON.parse(localStorage.getItem("p"))

    return (
        <React.Fragment>

            <MetaTags>
                <title>Patient Record Details</title>
            </MetaTags>
            <div className='ward '>
            
            <div className="main">
            <div className="wrapper">
                <div className="patient_record bg_none">
                    <h3 className="title dsp-flex">Patient Record <Link className="pg_back" onClick={(e)=>backClick(e)}><span
                                className="material-icons icon"> arrow_back_ios </span>Back</Link> </h3>
                   <PatientBlock patient={clinicPatient} />
                
                    <div className="links">
                        <h3 className="title">Front Sheet</h3>
                        <Link className="link" to="/front"><span className="material-icons icon"> arrow_outward </span>Patient ID</Link>
                        <Link className="link" to="/demo"><span className="material-icons icon"> arrow_outward </span>Demographic Data</Link>
                    </div>
                    <div className="links">
                        <Link className="link" to="/lifetimerecord"> <h3 className="title" >Lifetime Record</h3></Link>
                    </div>
                    <h3 className="title mt-20">Current Episode </h3>
                    <div className="links">
                        <h3 className="title">Ward Notes <span className="sub">: Admission</span></h3>
                        <Link className="link" to="/uc"><span className="material-icons icon"> arrow_outward </span>Medical Admission</Link>
                        <Link className="link" to="/uc"><span className="material-icons icon"> arrow_outward </span>Nursing Admission</Link>
                        <Link className="link" to="/uc"><span className="material-icons icon"> arrow_outward </span>Allied Health Initial Assessment</Link>
                    </div>
                    <div className="links">
                        <h3 className="title">Ward Notes <span className="sub">: Hospital Stay</span></h3>
                        <Link className="link" to="/weekendhandover"><span className="material-icons icon"> arrow_outward </span>Daily Summary & Handover</Link>
                        <Link className="link" to="/uc"><span className="material-icons icon"> arrow_outward </span>Medical Progress Notes</Link>
                        <Link className="link" to="/uc"><span className="material-icons icon"> arrow_outward </span>Nursing Progress Notes</Link>
                        <Link className="link" to="/allied"><span className="material-icons icon"> arrow_outward </span>Allied Health+ Visiting Notes</Link>
                        <Link className="link" to="/uc"><span className="material-icons icon"> arrow_outward </span>End of Life Care & Death</Link>
                    </div>
                    <div className="links">
                        <h3 className="title">Ward Notes <span className="sub"> : Discharge</span></h3>
                        <Link className="link" to="/discharge"><span className="material-icons icon"> arrow_outward </span>Discharge Summary</Link>
                        <Link className="link" to="/fluid"><span className="material-icons icon"> arrow_outward </span>Discharge Report</Link>
                    </div>
                    <div className="links">
                        <h3 className="title">Ward Notes <span className="sub"> : Reports</span></h3>
                        <Link className="link" to="/uc"><span className="material-icons icon"> arrow_outward </span>Patient Admin Report</Link>
                        <Link className="link" to="/uc"><span className="material-icons icon"> arrow_outward </span>Patient’s Quality Report</Link>
                        <Link className="link" to="/uc"><span className="material-icons icon"> arrow_outward </span>Patient’s Coding and Finance Report</Link>
                    </div>
                    <div className="links">
                    <Link className="link" to="/theatre"><h3 className="title">Theatre Notes</h3></Link>
                    </div>
                    <div className="links">
                    <Link className="link" to="/clinic"> <h3 className="title">Clinic Notes</h3></Link>
                    </div>
                    <div className="links">
                        <h3 className="title">Medical Records Room </h3>
                        <Link className="link" to="/uc"><span className="material-icons icon"> arrow_outward </span>MAR</Link>
                        <Link className="link" to="/uc"><span className="material-icons icon"> arrow_outward </span>Nursing Audits</Link>
                        <Link className="link" to="/uc"><span className="material-icons icon"> arrow_outward </span>Nursing Procedures</Link>
                        <Link className="link" to="/uc"><span className="material-icons icon"> arrow_outward </span>Nursing Assistant’s Entries</Link>
                        <Link className="link" to="/uc"><span className="material-icons icon"> arrow_outward </span>Pharmcist Template</Link>
                        <Link className="link" to="/uc"><span className="material-icons icon"> arrow_outward </span>Phlebotomist Template</Link>
                        <Link className="link" to="/uc"><span className="material-icons icon"> arrow_outward </span>Physiotherapy Template</Link>
                        <Link className="link" to="/uc"><span className="material-icons icon"> arrow_outward </span>OT Template</Link>
                        <Link className="link" to="/uc"><span className="material-icons icon"> arrow_outward </span>Social Worker Template</Link>
                        <Link className="link" to="/uc"><span className="material-icons icon"> arrow_outward </span>Discharge Co Ordinator Template</Link>
                        <Link className="link" to="/uc"><span className="material-icons icon"> arrow_outward </span>Skin Integrity Template</Link>
                    </div>
                </div>
            </div>
        </div>

            </div>


        </React.Fragment>
    )
}

PatientRecordDetails.propTypes = {
    t: PropTypes.any,
    getWards: PropTypes.func,
    getTheatres: PropTypes.func,
    wards: PropTypes.array,
    user: PropTypes.any,
    theatres: PropTypes.array,
    clinic: PropTypes.array,
    patients: PropTypes.array,
    doctors: PropTypes.array,
    profile: PropTypes.object
}

const mapStateToProps = ({ Layout, Login }) => ({
    wards: Layout.wards,
    user: Login.user,
    theatres: Layout.theatres,
    clinic: Layout.clinic,
    patients: Layout.patients,
    doctors: Layout.doctors,
    profile: Layout.profile
})

const mapDispatchToProps = dispatch => ({
    getWards: () => dispatch(getWardsList()),
    getTheatres: () => dispatch(getTheatresList()),
    getClinic: () => dispatch(getClinicList()),
    getPatient: () => dispatch(getPatientList()),
    getUserprofile: (id) => dispatch(getUprofile(id)),
    getDoctor: () => dispatch(getDoctorList()),
    patientupdate: (id, data) => dispatch(updatePatient(id, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(PatientRecordDetails)
