import axios from "axios"
import { post, del, get, put } from "./api_helper"
import * as url from "./url_helper"

// const API_URL = "http://localhost:9001/" // https://nm3crqsr1e.execute-api.us-east-1.amazonaws.com/latest/

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user")
  if (user) return JSON.parse(user)
  return null
}

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null
}

// Register Method
const postFakeRegister = (data) => post(url.POST_FAKE_REGISTER, data)

// Login Method
const postFakeLogin = data => post(url.POST_FAKE_LOGIN, data)

// postForgetPwd
const postFakeForgetPwd = data => post(url.POST_FAKE_PASSWORD_FORGET, data)

// Edit profile
const postJwtProfile = data => post(url.POST_EDIT_JWT_PROFILE, data)

const postFakeProfile = data => post(url.POST_EDIT_PROFILE, data)

// Register Method
const postJwtRegister = (url, data) => {
  return axios
    .post(url, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch(err => {
      let message
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found"
            break
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team"
            break
          case 401:
            message = "Invalid credentials"
            break
          default:
            message = err[1]
            break
        }
      }
      throw message
    })
}

// Login Method
const postJwtLogin = async (user) => {

  let url = API_URL+"authentication?appname=ipr"
  if(user.email){
    url = url + "&email="+user.email+"&password="+user.password
  }else {
    url = url + "&mpin="+user
  }
  console.log("url ", url)
  const response = await fetch(url, {
    method: "POST",
    mode: "cors"
  })
  const result = await response.json();
  console.log("result ", result)
  if (result.statusCode >= 200 || response.statusCode <= 299){
    return result.result
  } else {
    return result
  }
}

const getWards = async () => {

  const response = await fetch(API_URL+"wards?appname=ipr", {
    method: "GET",
    mode: "cors"
  })
  const result = await response.json();
  if (result.statusCode >= 200 || response.statusCode <= 299){
    return result.result
  } else {
    return result
  }
}

const getTheatres = async () => {

  const response = await fetch(API_URL+"theatres?appname=ipr", {
    method: "GET",
    mode: "cors"
  })
  const result = await response.json();
  if (result.statusCode >= 200 || response.statusCode <= 299){
    return result.result
  } else {
    return result
  }
}

const getClinics = async () => {

  const response = await fetch(API_URL+"clinic?appname=ipr", {
    method: "GET",
    mode: "cors"
  })
  const result = await response.json();
  if (result.statusCode >= 200 || response.statusCode <= 299){
    return result.result
  } else {
    return result
  }
}

const getPatients = async () => {

  const response = await fetch(API_URL+"patients?appname=ipr", {
    method: "GET",
    mode: "cors"
  })
  const result = await response.json();
  if (result.statusCode >= 200 || response.statusCode <= 299){
    return result.result
  } else {
    return result
  }
}

const getSessionTeam = async () => {

  const response = await fetch(API_URL+"sessionteam?appname=ipr", {
    method: "GET",
    mode: "cors"
  })
  const result = await response.json();
  if (result.statusCode >= 200 || response.statusCode <= 299){
    return result.result
  } else {
    return result
  }
}

const getDoctors = async () => {

  const response = await fetch(API_URL+"doctors?appname=ipr", {
    method: "GET",
    mode: "cors"
  })
  const result = await response.json();
  if (result.statusCode >= 200 || response.statusCode <= 299){
    return result.result
  } else {
    return result
  }
}



const getUserProfile = async (id) => {
  const response = await fetch(API_URL+"profile?appname=ipr&id="+id.payload, {
    method: "GET",
    mode: "cors"
  })
  const result = await response.json();
  if (result.statusCode >= 200 || response.statusCode <= 299){
    return result.result
  } else {
    return result
  }
}


const updatePatient = async (id, data) => {
  const response = await fetch(API_URL+"patientupdate?appname=ipr&id="+id, {
    method: "POST",
    mode: "cors",
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data)
  })
  const result = await response.json();
  if (result.statusCode >= 200 || response.statusCode <= 299){
    return result.result
  } else {
    return result
  }
}

const updateTable = async (data, tablename) => {
  const response = await fetch(API_URL+"tableData?appname=ipr&tableName="+tablename, {
    method: "POST",
    mode: "cors",
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data)
  })
  const result = await response.json();
  if (result.statusCode >= 200 || response.statusCode <= 299){
    return result.result
  } else {
    return result
  }
}

const getTable = async (data, tablename) => {
  const response = await fetch(API_URL+"tableData?appname=ipr&tableName="+tablename, {
    method: "POST",
    mode: "cors",
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data)
  })
  const result = await response.json();
  if (result.statusCode >= 200 || response.statusCode <= 299){
    return result.result
  } else {
    return result
  }
}

const updateNews = async (data) => {
  const response = await fetch(API_URL+"newsUpdate?appname=ipr", {
    method: "POST",
    mode: "cors",
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data.payload)
  })
  const result = await response.json();
  if (result.statusCode >= 200 || response.statusCode <= 299){
    return result.result
  } else {
    return result
  }
}

const getNews = async (data) => {
  const response = await fetch(API_URL+"news?appname=ipr", {
    method: "POST",
    mode: "cors",
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data.payload)
  })
  const result = await response.json();
  if (result.statusCode >= 200 || response.statusCode <= 299){
    return result.result
  } else {
    return result
  }
}

const updateDRounds = async (data) => {
  const response = await fetch(API_URL+"dailyroundUpdate?appname=ipr", {
    method: "POST",
    mode: "cors",
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data.payload)
  })
  const result = await response.json();
  if (result.statusCode >= 200 || response.statusCode <= 299){
    return result.result
  } else {
    return result
  }
}

const getDRounds = async (data) => {
  const response = await fetch(API_URL+"dailyrounds?appname=ipr", {
    method: "POST",
    mode: "cors",
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data.payload)
  })
  const result = await response.json();
  if (result.statusCode >= 200 || response.statusCode <= 299){
    return result.result
  } else {
    return result
  }
}


const patienttheatre = async (id, data) => {
  let url = API_URL+"theatreupdate?appname=ipr"
  if(id != null){
    url = url + "&id="+id
  }

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data)
  })
  const result = await response.json();
  if (result.statusCode >= 200 || response.statusCode <= 299){
    return result.result
  } else {
    return result
  }
}

const theatreReport = async (id, data) => {
  let url = API_URL+"theatrereports?appname=ipr"
  if(id != null){
    url = url + "&id="+id
  }

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data)
  })
  const result = await response.json();
  if (result.statusCode >= 200 || response.statusCode <= 299){
    return result.result
  } else {
    return result
  }
}

const theatreTeamBrief = async (id, data) => {
  let url = API_URL+"teamBrief?appname=ipr"
  if(id != null){
    url = url + "&id="+id
  }

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data)
  })
  const result = await response.json();
  if (result.statusCode >= 200 || response.statusCode <= 299){
    return result.result
  } else {
    return result
  }
}

const patientWard = async (id, data) => {
  let url = API_URL+"wardupdate?appname=ipr"
  if(id != null){
    url = url + "&id="+id
  }
  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data)
  })
  const result = await response.json();
  if (result.statusCode >= 200 || response.statusCode <= 299){
    return result.result
  } else {
    return result
  }
}


//const postJwtLogin = data => get(url.POST_FAKE_JWT_LOGIN+"?appname=ipr", data)

// postForgetPwd
const postJwtForgetPwd = data => post(url.POST_FAKE_JWT_PASSWORD_FORGET, data)

// get Events
export const getEvents = () => get(url.GET_EVENTS)

// add Events
export const addNewEvent = event => post(url.ADD_NEW_EVENT, event)

// update Event
export const updateEvent = event => put(url.UPDATE_EVENT, event)

// delete Event
export const deleteEvent = event =>
  del(url.DELETE_EVENT, { headers: { event } })

// get Categories
export const getCategories = () => get(url.GET_CATEGORIES)

export {
  getLoggedInUser,
  isUserAuthenticated,
  postFakeRegister,
  postFakeLogin,
  postFakeProfile,
  postFakeForgetPwd,
  postJwtRegister,
  postJwtLogin,
  postJwtForgetPwd,
  postJwtProfile,
  getWards,
  getTheatres,
  getClinics,
  getPatients,
  getSessionTeam,
  getDoctors,
  getUserProfile,
  updatePatient,
  patienttheatre,
  patientWard,
  theatreReport,
  theatreTeamBrief,
  updateNews,
  getNews,
  updateDRounds,
  getDRounds,
  updateTable,
  getTable
}
