import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import MetaTags from 'react-meta-tags';
import Moment from 'moment';
import { connect } from "react-redux"
import Select from "react-select"
import { getWardsList, getTheatresList, getClinicList, getPatientList, getUprofile, getDoctorList, updatePatient } from "../../store/actions"

const PatientInfo = props => {

    const [modal_standard, setmodal_standard] = useState(false)
    const [clinicValues, setClinic] = useState({})

    const [selectedGroup, setselectedGroup] = useState(null)
    const [popupname, setpopup_name] = useState({})
    const [anydelay, setDelay] = useState(false)

    
    const optionGroup = [
        { label: "Team1", value: "Team1" },
        { label: "Team2", value: "Team2" },
        { label: "Team3", value: "Team3" }
    ]

    const pinfo_pregnancy_testList = [
        {
            name: "pinfo_pregnancy_test",
            value: "Negative"
        },
        {
            name: "pinfo_pregnancy_test",
            value: "Positive"
        }
    ]

    const pinfo_newsList = [
        {
            name: "pinfo_newsCheck",
            value: "Patient of Concern"
        },
        {
            name: "pinfo_newsCheck",
            value: "Stable"
        },
        {
            name: "pinfo_newsCheck",
            value: "Sepsis"
        }
    ]


    const pinfo_bsaList = [
        {
            name: "pinfo_bsaCheck",
            value: "Right Handed"
        },
        {
            name: "pinfo_bsaCheck",
            value: "Left Handed"
        }
    ]

    const socialCircumstanceList = [
        {
            name: "socialCircumstance",
            value: "Home"
        },
        {
            name: "socialCircumstance",
            value: "Low level residential care"
        },
        {
            name: "socialCircumstance",
            value: "High level residential care"
        }
    ]
    const pinfo_destinationList = [
        {
            name: "pinfo_destination",
            value: "Rehab"
        },
        {
            name: "pinfo_destination",
            value: "Home"
        },
        {
            name: "pinfo_destination",
            value: "Residential"
        },
        {
            name: "pinfo_destination",
            value: "Care Home"
        } 
    ]

    const {
        wards, user, theatres, clinic, patients, profile, doctors
    } = props;

    useEffect(() => {
        const { getWards, getTheatres, getClinic, getDoctor, getPatient, getUserprofile } = props
        // getWards()
        // getTheatres()
        // getClinic()
        getPatient()
        getDoctor()
        let userId = localStorage.getItem("authUser")
        getUserprofile(userId)
    }, [])


    useEffect(() => {
        let clinicPatient = JSON.parse(localStorage.getItem("p"))
        if (patients && patients.length > 0 && clinicPatient && clinicPatient._id) {
            const cPatient = patients.filter(
                usr => usr._id === clinicPatient._id
            )
            if (cPatient && cPatient[0]) {
                let cpatientData = cPatient[0]
                delete cpatientData["_id"]
                setClinic(clinicValues => ({
                    ...clinicValues,
                    ...cpatientData
                }));
            }

        }
    }, [patients])

    const tog_standard = (name) => {
        if (name) {
            let stringReplace = name
            setpopup_name(
                {
                    popup: name,
                    id: stringReplace.replace(/[^A-Z0-9]/ig, "").toLowerCase()
                }
            )
        }
        setDelay(false)
        setmodal_standard(!modal_standard)
    }

    const handleSelectGroup = (selectedGroup, name) => {
        // console.log("selectedGroup ", selectedGroup)
        let updatedValue = { [name]: selectedGroup };
        setClinic(clinicValues => ({
            ...clinicValues,
            ...updatedValue
        }));
    }


    const updateInputValue = (e) => {
        let val = e.target.value;
        let name = e.target.name;
        let updatedValue = {};
        updatedValue = { [name]: val };
        setClinic(clinicValues => ({
            ...clinicValues,
            ...updatedValue
        }));
        
    }

    const updateClinicValues = (e, name, val) => {
        e.preventDefault()
        let updatedValue = {};
        updatedValue = { [name]: val };
        setClinic(clinicValues => ({
            ...clinicValues,
            ...updatedValue
        }));
    }

  
    const updateClinic = (e) => {
        const { patientupdate } = props
        let clinicPatient = JSON.parse(localStorage.getItem("p"))
        let id = clinicPatient._id
        patientupdate(id, clinicValues)
        let patient = {
            ...clinicPatient,
            ...clinicValues
        }
        localStorage.setItem("p", JSON.stringify(patient))
        wardBack(e)


    }

    const wardBack = (e) => {
        e.preventDefault()
        props.history.push("/wl")
        document.body.style.overflow = '';
    }

   // console.log("clinicValues ", clinicValues && clinicValues.pinfo_reason_for_admission)

   const formatDate = Moment().format('MMM DD, YYYY')
    return (
        <React.Fragment>

            <MetaTags>
                <title>Patient Info</title>
            </MetaTags>
            <div className='ward '>
                <div className="popup_wrp">
                    <div className="popup_full prescribe">
                        <div className="popup_hed">
                            <h2> Patient Info </h2>
                            <div className="dsp-flex"> <span className="title_date">Last updated: {formatDate}</span> <a
                                className="popup_close" onClick={(e) => { wardBack(e) }}><span className="material-icons">close</span></a></div>

                        </div>
                        <div className="info_menu">
                                    <div className="dsp_flex">
                                        <a href="#1" className="menu_item">DIAGNOSES & PROBLEM LIST</a>
                                        <a href="#2" className="menu_item">SOCIAL CIRCUMSTANCES</a>
                                        <a href="#3" className="menu_item">MEASUREMENTS</a>
                                        <a href="#4" className="menu_item">ALERTS</a>
                                    </div>
                                    <div className="dsp_flex border-top">
                                        <a href="#5" className="menu_item">SURGERY DETAILS</a>
                                        <a href="#6" className="menu_item">ADMISSION DETAILS</a>
                                        <a href="#7" className="menu_item">WOMEN</a>
                                        <a href="#8" className="menu_item">PATIENT SCHEDULE</a>
                                    </div>

                                </div>
                        <div className="popup_content">
                            <div className="popup_scroll main ">
                                
                                <div className="section ctr" id="1">
                                    <h2 className="popup_title"> DIANOSES & PROBLEM LIST</h2>
                                    <div className="form_group">
                                        <label className="label">Reason for Admission</label>
                                        <input type="text" className="form-control" onChange={(e) => updateInputValue(e)} name="pinfo_reason_for_admission" value={clinicValues.pinfo_reason_for_admission} />
                                    </div>
                                    <div className="form_group">
                                        <label className="label">Confirmed / Working Diagnosis</label>
                                        <input type="text" className="form-control" onChange={(e) => updateInputValue(e)} name="pinfo_working_diagnosis" value={clinicValues.pinfo_working_diagnosis} />
                                    </div>
                                    <div className="form_group">
                                        <label className="label">Main Stay Management</label>
                                        <input type="text" className="form-control" placeholder="Eg: Drugs Surgery" onChange={(e) => updateInputValue(e)} name="pinfo_main_stay" value={clinicValues.pinfo_main_stay} />
                                    </div>
                                    <div className="check_item mt-20"><label className="check">Main Problems now</label>
                                        {clinicValues.pinfo_main_problem ?
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input type="radio"
                                                    value={clinicValues.pinfo_main_problem} checked /><span>{clinicValues.pinfo_main_problem} </span></label>
                                                </div> {clinicValues.pinfo_main_problem && <a onClick={(e) => updateClinicValues(e, "pinfo_main_problem", false)}><span className="material-symbols-outlined"> reply
                                                </span></a>}

                                            </div>
                                            :

                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input type="radio" value="HTN" onClick={(e) => updateInputValue(e)} name="pinfo_main_problem" /><span>
                                                    HTN</span></label>
                                                </div>
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="pinfo_main_problem" type="radio"
                                                    value="OBESITY" /><span>OBESITY </span></label></div>
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="pinfo_main_problem" type="radio"
                                                    value="AF" /><span>AF </span></label></div>
                                            </div>}


                                    </div>
                                    <div className="check_item mt-20"><label className="check">Main Problems now</label>
                                    {clinicValues.pinfo_main_problem2 ?
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input type="radio"
                                                    value={clinicValues.pinfo_main_problem2} checked /><span>{clinicValues.pinfo_main_problem2} </span></label>
                                                </div> {clinicValues.pinfo_main_problem2 && <a onClick={(e) => updateClinicValues(e, "pinfo_main_problem", false)}><span className="material-symbols-outlined"> reply
                                                </span></a>}

                                            </div>
                                            :
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="pinfo_main_problem2" type="radio" value="DIGOXIN TOXICITY" /><span>
                                                DIGOXIN TOXICITY</span></label>
                                            </div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="pinfo_main_problem2" type="radio"
                                                value="HYPERKALAEMIA" /><span>HYPERKALAEMIA </span></label></div>
                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="pinfo_main_problem2" type="radio"
                                                value="CONGESTIVE CARDIAC FAILURE" /><span>CONGESTIVE CARDIAC FAILURE </span></label></div>
                                        </div>
                                        }

                                    </div>
                                    <div className="form_group mt-20">
                                        <label className="label">Resolved Problems</label>
                                        <input type="text" className="form-control" placeholder="Enter Problems" onChange={(e) => updateInputValue(e)} name="pinfo_resolved_problem" value={clinicValues.pinfo_resolved_problem} />
                                    </div>
                                    <button type="button" className="update_btn" onClick={(e) => { updateClinic(e)}} >UPDATE</button>
                                </div>
                                <div className="section ctr" id="2">
                                    <h2 className="popup_title"> SOCIAL CIRCUMSTANCES</h2>
                                    <div className="check_item mt-20"><label className="check">From</label>
                                    {clinicValues.socialCircumstance ? <div className="select_btns popup_select">
    <div className="select_btn"><label><input type="radio" value={clinicValues.socialCircumstance}
                checked /><span>{clinicValues.socialCircumstance} </span></label>
    </div> {clinicValues.socialCircumstance && <a onClick={(e)=> updateClinicValues(e, "socialCircumstance", false)}><span
            className="material-symbols-outlined"> reply
        </span></a>}

</div>
:

<div className="select_btns popup_select mt-0">
    {socialCircumstanceList && socialCircumstanceList.length > 0 && socialCircumstanceList.map((item, i) => (
    <div className="select_btn" key={i}><label><input onClick={(e)=> updateInputValue(e)} name={item.name} type="radio"
            value={item.value} /><span>{item.value} </span></label></div>
    ))}
</div>
}
                                    </div>
                                    <div className="check_item mt-20"><label className="check">Destination</label>
                                    {clinicValues.pinfo_destination ? <div className="select_btns popup_select">
    <div className="select_btn"><label><input type="radio" value={clinicValues.pinfo_destination}
                checked /><span>{clinicValues.pinfo_destination} </span></label>
    </div> {clinicValues.pinfo_destination && <a onClick={(e)=> updateClinicValues(e, "pinfo_destination", false)}><span
            className="material-symbols-outlined"> reply
        </span></a>}

</div>
:

<div className="select_btns popup_select mt-0">
    {pinfo_destinationList && pinfo_destinationList.length > 0 && pinfo_destinationList.map((item, i) => (
    <div className="select_btn" key={i}><label><input onClick={(e)=> updateInputValue(e)} name={item.name} type="radio"
            value={item.value} /><span>{item.value} </span></label></div>
    ))}
</div>
}
                                    </div>
                                    <div className="form_group">
                                        <label className="label">Previous Mobility</label>
                                        <input type="text" className="form-control" onChange={(e) => updateInputValue(e)} name="pinfo_prev_mobility" value={clinicValues.pinfo_prev_mobility} placeholder="Enter" />
                                    </div>
                                    <div className="form_group">
                                        <label className="label">Family Support</label>
                                        <input type="text" className="form-control" onChange={(e) => updateInputValue(e)} name="pinfo_family_support" value={clinicValues.pinfo_family_support} placeholder="Enter" />
                                    </div>

                                    <div className="form_group">
                                        <label className="label">NOK Aware</label>
                                        <input type="text" className="form-control" onChange={(e) => updateInputValue(e)} name="pinfo_nok_aware" value={clinicValues.pinfo_nok_aware} placeholder="Enter" />
                                    </div>
                                    <button type="button" onClick={(e) => { updateClinic(e)}} className="update_btn">UPDATE</button>
                                </div>
                                <div className="section ctr" id="3">
                                    <h2 className="popup_title"> MEASUREMENTS</h2>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form_group">
                                                <label className="label">Height</label>
                                                <div className="measure tqt">
                                                    <input type="text" className="field" onChange={(e) => updateInputValue(e)} name="pinfo_measure_height" value={clinicValues.pinfo_measure_height} placeholder="Height" />
                                                    <span>cm </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form_group">
                                                <label className="label">Weight</label>
                                                <div className="measure tqt">
                                                    <input type="text" className="field" onChange={(e) => updateInputValue(e)} name="pinfo_measure_weight" value={clinicValues.pinfo_measure_weight} placeholder="Weight" />
                                                    <span>Kg </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form_group">
                                                <label className="label">BMI</label>
                                                <div className="measure tqt">
                                                    <input type="text" className="field w-100"  onChange={(e) => updateInputValue(e)} name="pinfo_measure_bmi" value={clinicValues.pinfo_measure_bmi} placeholder="BMI" />

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="check_item mt-10 mb-10"><label className="check">BSA</label>
                                    {clinicValues.pinfo_bsaCheck ? <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input type="radio"
                                            value={clinicValues.pinfo_bsaCheck} checked /><span>{clinicValues.pinfo_bsaCheck} </span></label>
                                        </div> {clinicValues.pinfo_bsaCheck && <a onClick={(e) => updateClinicValues(e, "pinfo_bsaCheck", false)}><span className="material-symbols-outlined"> reply
                                        </span></a>}

                                    </div>
                                        :

                                        <div className="select_btns popup_select mt-0">
                                            {pinfo_bsaList && pinfo_bsaList.length > 0 && pinfo_bsaList.map((item, i) => (
                                                <div className="select_btn" key={i}><label><input onClick={(e) => updateInputValue(e)} name={item.name} type="radio"
                                                    value={item.value} /><span>{item.value} </span></label></div>
                                            ))}
                                        </div>
                                    }
                                    </div>
                                    <button type="button" onClick={(e) => { updateClinic(e)}} className="update_btn">UPDATE</button>
                                </div>
                                <div className="section ctr" id="4">
                                    <h2 className="popup_title dsp_flex">ALERTS <div className="yn_switch fc mt-0">
                                        <label for="pinfo_alerts" className="switch-container">
                                            <input hidden="" type="checkbox" name="pinfo_alerts" onChange={(e) => switchChange(e)} className="switch_check" id="pinfo_alerts" />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>NO</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>YES</span>
                                            </div>
                                        </label>

                                    </div>
                                    </h2>
                                    <div className="form_group">
                                        <label className="label">Alergic to</label>
                                        <input type="text" className="form-control" placeholder="Surgery" onChange={(e) => updateInputValue(e)} name="pinfo_alergic_to" value={clinicValues.pinfo_alergic_to} />
                                    </div>
                                    <div className="check_item mt-10 mb-10"><label className="check">News 3</label>
                                    {clinicValues.pinfo_newsCheck ? <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input type="radio"
                                            value={clinicValues.pinfo_newsCheck} checked /><span>{clinicValues.pinfo_newsCheck} </span></label>
                                        </div> {clinicValues.pinfo_newsCheck && <a onClick={(e) => updateClinicValues(e, "pinfo_newsCheck", false)}><span className="material-symbols-outlined"> reply
                                        </span></a>}

                                    </div>
                                        :

                                        <div className="select_btns popup_select mt-0">
                                            {pinfo_newsList && pinfo_newsList.length > 0 && pinfo_newsList.map((item, i) => (
                                                <div className="select_btn" key={i}><label><input onClick={(e) => updateInputValue(e)} name={item.name} type="radio"
                                                    value={item.value} /><span>{item.value} </span></label></div>
                                            ))}
                                        </div>
                                    }
                                    </div>
                                    <button type="button" onClick={(e) => { updateClinic(e)}} className="update_btn">UPDATE</button>
                                </div>
                                <div className="section ctr" id="5">
                                    <h2 className="popup_title"> Surgery Details</h2>
                                    <div className="form_group">
                                        <label className="label">Surgery Planned /Performed If Any</label>
                                        <input type="text" className="form-control" onChange={(e) => updateInputValue(e)} name="pinfo_surgery_planned" value={clinicValues.pinfo_surgery_planned} placeholder="Surgery" />
                                    </div>
                                    <div className="form_group">
                                        <label className="label">Date Of Surgery Done /Planned:</label>
                                        <input type="date" className="form-control" onChange={(e) => updateInputValue(e)} name="pinfo_surgery_date" value={clinicValues.pinfo_surgery_date} placeholder="Enter" />
                                    </div>
                                    <button type="button" onClick={(e) => { updateClinic(e)}} className="update_btn">UPDATE</button>
                                </div>
                                <div className="section ctr" id="6">
                                    <h2 className="popup_title"> ADMISSION Details</h2>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form_group">
                                                <label className="label">DOA</label>
                                                <input type="text" className="form-control" onChange={(e) => updateInputValue(e)} name="pinfo_ad_doa" value={clinicValues.pinfo_ad_doa} placeholder="Enter Name" />
                                            </div>
                                            <div className="form_group">
                                                <label className="label">EDD</label>
                                                <input type="text" className="form-control" onChange={(e) => updateInputValue(e)} name="pinfo_ad_eod" value={clinicValues.pinfo_ad_eod} placeholder="Enter Name" />
                                            </div>

                                            <div className="form_group">
                                                <label className="label">Junior doctor</label>
                                                <input type="text" className="form-control" onChange={(e) => updateInputValue(e)} name="pinfo_ad_junior" value={clinicValues.pinfo_ad_junior} placeholder="Enter Name" />
                                            </div>
                                            <div className="search_dropdown_blk">
                                                <div className="form_group">
                                                    <label className="label">Team</label>
                                                    <div className="floating-label-group">
                                                        <div className="search_dropdown">
                                                        <Select
                                                            onChange={(e) => {
                                                                handleSelectGroup(e, "pinfo_team")
                                                            }}
                                                            options={optionGroup}
                                                            classNamePrefix="select2-selection"
                                                           // value={{ label: clinicValues.pinfo_team, value: clinicValues.pinfo_team }}
                                                        />
                                                        
                                                            
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form_group">
                                                <label className="label">LOS</label>
                                                <input type="text" className="form-control" onChange={(e) => updateInputValue(e)} name="pinfo_ad_los" value={clinicValues.pinfo_ad_los} placeholder="Enter" />
                                            </div>
                                            <div className="form_group">
                                                <label className="label">Registrar</label>
                                                <input type="text" className="form-control" onChange={(e) => updateInputValue(e)} name="pinfo_ad_registrar" value={clinicValues.pinfo_ad_registrar} placeholder="Enter" />
                                            </div>
                                            <div className="form_group">
                                                <label className="label">Nurse</label>
                                                <input type="text" className="form-control" onChange={(e) => updateInputValue(e)} name="pinfo_ad_nurse" value={clinicValues.pinfo_ad_nurse} placeholder="Enter" />
                                            </div>
                                        </div>
                                    </div>

                                    <button type="button" onClick={(e) => { updateClinic(e)}} className="update_btn">UPDATE</button>
                                </div>
                                <div className="section ctr" id="7">
                                    <h2 className="popup_title dsp_flex">WOMEN
                                    </h2>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <label className="label">Last Menstrual Period LMP</label>
                                                <input type="date" className="form-control" onChange={(e) => updateInputValue(e)} name="pinfo_women_menstrual" value={clinicValues.pinfo_women_menstrual} placeholder="Surgery" />
                                            </div>

                                        </div>
                                        <div className="col-md-6">
                                            <div className="check_item">
                                                <label className="label">Pregnancy Test</label>
                                                {clinicValues.pinfo_pregnancy_test ? <div className="select_btns popup_select">
    <div className="select_btn"><label><input type="radio" value={clinicValues.pinfo_pregnancy_test}
                checked /><span>{clinicValues.pinfo_pregnancy_test} </span></label>
    </div> {clinicValues.pinfo_pregnancy_test && <a onClick={(e)=> updateClinicValues(e, "pinfo_pregnancy_test", false)}><span
            className="material-symbols-outlined"> reply
        </span></a>}

</div>
:

<div className="select_btns popup_select mt-0">
    {pinfo_pregnancy_testList && pinfo_pregnancy_testList.length > 0 && pinfo_pregnancy_testList.map((item, i) => (
    <div className="select_btn" key={i}><label><input onClick={(e)=> updateInputValue(e)} name={item.name} type="radio"
            value={item.value} /><span>{item.value} </span></label></div>
    ))}
</div>
}

                                            </div>
                                        </div>
                                    </div>
                                    <div className="yn_switch mb-10  mt-10">
                                        <div className="label-container">
                                            <p>On Oral Contraceptives</p>
                                        </div>
                                        <label for="pinfo_oral" className="switch-container">
                                            <input hidden="" id="pinfo_oral" name="pinfo_oral" onChange={(e) => switchChange(e)} type="checkbox" className="switch_check" />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>YES</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>NO</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <label className="label">Brand Name</label>
                                                <input type="text" onChange={(e) => updateInputValue(e)} name="pinfo_brand_name" value={clinicValues.pinfo_brand_name} className="form-control" placeholder="Surgery" />
                                            </div>
                                            <div className="yn_switch mb-10 ">
                                                <div className="label-container">
                                                    <p>Inform Medical Team</p>
                                                </div>
                                                <label for="pinfo_med_team" className="switch-container">
                                                    <input hidden="" id="pinfo_med_team" name="pinfo_med_team" onChange={(e) => switchChange(e)} type="checkbox" className="switch_check"  />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>YES</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>NO</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="yn_switch mb-10 ">
                                                <div className="label-container">
                                                    <p>Breastfeeding</p>
                                                </div>
                                                <label for="pinfo_breastfeeding" className="switch-container">
                                                    <input hidden="" id="pinfo_breastfeeding" name="pinfo_breastfeeding" onChange={(e) => switchChange(e)} type="checkbox" className="switch_check" />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>YES</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>NO</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="yn_switch mb-10 ">
                                                <div className="label-container">
                                                    <p>Post-Menopausal</p>
                                                </div>
                                                <label for="pinfo_postMenopausal" className="switch-container">
                                                    <input hidden="" id="pinfo_postMenopausal" name="pinfo_postMenopausal" onChange={(e) => switchChange(e)} type="checkbox" className="switch_check" />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>YES</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>NO</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <label className="label">Length of time Patient has been taking</label>
                                                <input type="time" onChange={(e) => updateInputValue(e)} name="pinfo_length_time" value={clinicValues.pinfo_length_time} className="form-control" placeholder="Surgery" />
                                            </div>
                                            <div className="yn_switch mb-10 ">
                                                <div className="label-container">
                                                    <p>Patient is pregnant</p>
                                                </div>
                                                <label for="pinfo_patientPregnant" className="switch-container">
                                                    <input hidden="" id="pinfo_patientPregnant" name="pinfo_patientPregnant" onChange={(e) => switchChange(e)} type="checkbox" className="switch_check" />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>YES</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>NO</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="yn_switch mb-10 ">
                                                <div className="label-container">
                                                    <p>Inform Medical Team</p>
                                                </div>
                                                <label for="pinfo_inform_medical_team" className="switch-container">
                                                    <input hidden="" id="pinfo_inform_medical_team" name="pinfo_inform_medical_team" onChange={(e) => switchChange(e)} type="checkbox" className="switch_check" />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>YES</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>NO</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="yn_switch">
                                                <div className="label-container">
                                                    <p>Pre menarche</p>
                                                </div>
                                                <label for="pinfo_pre_menarche" className="switch-container">
                                                    <input hidden="" type="checkbox" id="pinfo_pre_menarche" name="pinfo_pre_menarche" onChange={(e) => switchChange(e)} className="switch_check" />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>YES</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>NO</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <button type="button" onClick={(e) => { updateClinic(e)}} className="update_btn">UPDATE</button>
                                </div>
                                <div className="section ctr" id="8">
                                    <h2 className="popup_title dsp_flex">PATIENT SCHEDULE
                                    </h2>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="yn_switch mb-10 ">
                                                <div className="label-container">
                                                    <p>Theatre today</p>
                                                </div>
                                                <label className="switch-container">
                                                    <input onChange={(e) => updateInputValue(e)} name="pinfo_theatre_today" checked={clinicValues.pinfo_theatre_today} type="checkbox" className="switch_check" />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>NO</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>YES</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="check_item mt-0 mb-10">
                                            {clinicValues.pinfo_theatre_today_list ?
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input type="radio"
                                                    value={clinicValues.pinfo_theatre_today_list} checked /><span>{clinicValues.pinfo_theatre_today_list} </span></label>
                                                </div> {clinicValues.pinfo_theatre_today_list && <a onClick={(e) => updateClinicValues(e, "pinfo_theatre_today_list", false)}><span className="material-symbols-outlined"> reply
                                                </span></a>}

                                            </div>
                                            :
                                                <div className="select_btns popup_select">
                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="pinfo_theatre_today_list" type="radio" value="NBM" /><span>
                                                        NBM</span></label>
                                                    </div>
                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="pinfo_theatre_today_list" type="radio" value="CAN EAT" /><span>CAN EAT </span></label></div>

                                                </div>}

                                            </div>
                                            <div className="yn_switch">
                                                <div className="label-container">
                                                    <p>Discharge today</p>
                                                </div>
                                                <label className="switch-container">
                                                    <input onChange={(e) => updateInputValue(e)} name="pinfo_discharge_today" checked={clinicValues.pinfo_discharge_today}  type="checkbox" className="switch_check" />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>NO</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>YES</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="form_group">
                                                <label className="label">Last Staff Visit</label>
                                                <input type="date" className="form-control" onChange={(e) => updateInputValue(e)} name="pinfo_last_staff_visit" value={clinicValues.pinfo_last_staff_visit} />
                                            </div>


                                        </div>

                                    </div>

                                    <button type="button" onClick={(e) => { updateClinic(e)}} className="update_btn">UPDATE</button>
                                    <br/><br/><br/><br/><br/>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>


        </React.Fragment>
    )
}

PatientInfo.propTypes = {
    t: PropTypes.any,
    getWards: PropTypes.func,
    getTheatres: PropTypes.func,
    wards: PropTypes.array,
    user: PropTypes.any,
    theatres: PropTypes.array,
    clinic: PropTypes.array,
    patients: PropTypes.array,
    doctors: PropTypes.array,
    profile: PropTypes.object
}

const mapStateToProps = ({ Layout, Login }) => ({
    wards: Layout.wards,
    user: Login.user,
    theatres: Layout.theatres,
    clinic: Layout.clinic,
    patients: Layout.patients,
    doctors: Layout.doctors,
    profile: Layout.profile
})

const mapDispatchToProps = dispatch => ({
    getWards: () => dispatch(getWardsList()),
    getTheatres: () => dispatch(getTheatresList()),
    getClinic: () => dispatch(getClinicList()),
    getPatient: () => dispatch(getPatientList()),
    getUserprofile: (id) => dispatch(getUprofile(id)),
    getDoctor: () => dispatch(getDoctorList()),
    patientupdate: (id, data) => dispatch(updatePatient(id, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(PatientInfo)
