import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import MetaTags from 'react-meta-tags';
import SignaturePad from 'react-signature-canvas'
import PatientBlock from "../Clinic/patientBlock";
import { removeSpecialCharacters } from '../../utils/utils';
import { Buffer } from 'buffer';
import { connect } from "react-redux"
import { getWardsList, getTheatresList, getClinicList, getPatientList, getUprofile, getDoctorList, updatePatient } from "../../store/actions"

const LegalSection = props => {

    let provider_sign = useRef(null);
    let responsibility_sign = useRef(null);
    let photograph_sign = useRef(null); 
    
    const [file, setFile] = useState({});
    const [legalInfo, setLegalInfo] = useState(false)
    const [clinicValues, setClinic] = useState({})

    const [selectedGroup, setselectedGroup] = useState(null)
    const [popupname, setpopup_name] = useState({})
    const [anydelay, setDelay] = useState(false)

    const {
        wards, user, theatres, clinic, patients, profile, doctors
    } = props;

    useEffect(() => {
        const { getWards, getTheatres, getClinic, getDoctor, getPatient, getUserprofile } = props
        // getWards()
        // getTheatres()
        // getClinic()
        getPatient()
        getDoctor()
        let userId = localStorage.getItem("authUser")
        getUserprofile(userId)
    }, [])


    useEffect(() => {
        let clinicPatient = JSON.parse(localStorage.getItem("p"))
        if (patients && patients.length > 0 && clinicPatient && clinicPatient._id) {
            const cPatient = patients.filter(
                usr => usr._id === clinicPatient._id
            )
            if (cPatient && cPatient[0]) {
                let cpatientData = cPatient[0]
                delete cpatientData["_id"]
                setClinic(clinicValues => ({
                    ...clinicValues,
                    ...cpatientData
                }));
            }

        }
    }, [patients])


    const updateInputValue = (e) => {
        let val = e.target.value;
        let name = e.target.name;
        let updatedValue = {};
        updatedValue = { [name]: val };
        setLegalInfo(legalInfo => ({
            ...legalInfo,
            ...updatedValue
        }));
        
    }

    const updateInputValues = (name, popupname, val) => {
        //  e.preventDefault()
        // let updatedValue = {
        //    [popupname]:{[name] : val}
        // };
        let updatedValue = clinicValues;
        //updatedValue = { [name]: val };
        // updatedValue[popupname][name] = val;
        if (updatedValue[popupname]) {
            updatedValue[popupname][name] = val;
        } else {
            updatedValue = {
                [popupname]: { [name]: val }

            };
        }
        console.log("updatedValue 2", updatedValue)
        setClinic(clinicValues => ({
            ...clinicValues,
            ...updatedValue
        }));
    }

    const updateClinic = (e) => {
        const { patientupdate } = props
        let clinicPatient = JSON.parse(localStorage.getItem("p"))
        let id = clinicPatient._id
        patientupdate(id, clinicValues)
        let patient = {
            ...clinicPatient,
            ...clinicValues
        }
        localStorage.setItem("p", JSON.stringify(patient))



    }

    const updateSwitchValue = (name, value) => {
        let updatedValue = {};
        updatedValue = { [name]: value };
        setLegalInfo(legalInfo => ({
            ...legalInfo,
            ...updatedValue
        }));
        
    }

    const handleChange = (event) => {
        updateSwitchValue(event.target.name, event.target.checked)
    }

    
    const clear = (e, refvalue) => {
        e.preventDefault()
        refvalue.clear()
    }
    const trim = (e, type, refvalue) => {
        e.preventDefault()
        sigUpload(refvalue.getCanvas().toDataURL('image/png'), type)
    }

    const sigUpload = async (filedata, name) => {
        const file = filedata
        //console.log("file ", file, name)
        let filename = name+".png";
        // const base64 = await convertBase64(file)
        //console.log("filename ", filename)
        let pdetails = JSON.parse(localStorage.getItem("p"))

        const dataf = file.replace(/^data:image\/\w+;base64,/, "");
  
        const buf = Buffer.from(dataf, "base64");
        let uploadFilePath = "ipr/" + pdetails._id + "/" + name + "_result" + "/" + removeSpecialCharacters(filename);
        let data = { source_bucket: "ipr-resources", sourcepath: uploadFilePath }
       // console.log("data ", data)

        const response = await fetch(API_URL+'upload?appname=ipr', {
            method: "POST",
            mode: "cors",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
        const result = await response.json();
       // console.log("result ", result, buf.length)
        let url = result.result
       // console.log("url ", url)
        const base64Data = new Buffer.from(filedata.replace(/^data:image\/\w+;base64,/, ""), 'base64');
        // Getting the file type, ie: jpeg, png or gif
        const type = filedata.split(';')[0].split('/')[1];
        const uploadImage = await fetch(url, {
            method: "PUT",
            mode: "cors",
            headers: {
               'Content-Type':`image/${type}`,
                'Content-Encoding': 'base64',
                ACL: 'public-read',
            },
            body: base64Data
        })

        //console.log("resultImage ", uploadImage)

        let imagePath = "https://ipr-resources.s3.amazonaws.com/"
        let imageUploadPath = imagePath + uploadFilePath;


        let updateObj = {
            [name + "_result"]: imageUploadPath,
            [name + "_update"]: new Date()
        }

        setFile(file => ({
            ...file,
            ...updateObj
        }));
        setLegalInfo(legalInfo => ({
            ...legalInfo,
            ...updateObj
        }));

        // let patient = {
        //     ...pdetails,
        //     ...updateObj
        // }
        // localStorage.setItem("p", JSON.stringify(patient))

    };

    const handleFile = async (e, name) => {

        const file = e.target.files[0]
       // console.log("file ", file, name)
        let filename = e.target.files[0].name;
        // const base64 = await convertBase64(file)
        //console.log("filename ", filename)
        let pdetails = JSON.parse(localStorage.getItem("p"))
        // var reader = new FileReader();
        //   reader.readAsDataURL(file);
        // reader.onload = async function (e) {
        // console.log("e ", e)
        let uploadFilePath = "ipr/" + pdetails._id + "/" + name + "_result" + "/" + removeSpecialCharacters(filename);
        let data = { source_bucket: "ipr-resources", sourcepath: uploadFilePath }
       // console.log("data ", data)

        const response = await fetch(API_URL+'upload?appname=ipr', {
            method: "POST",
            mode: "cors",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
        const result = await response.json();

       // console.log("result ", result)
        let url = result.result
       // console.log("url ", url)
        const uploadImage = await fetch(url, {
            method: "PUT",
            mode: "cors",
            headers: {
                "Content-Type": "multipart/form-data",
                "Accept": "/",
                "Cache-Control": "no-cache",
                "Accept-Encoding": "gzip, deflate",
                "Connection": "keep-alive",
                "cache-control": "no-cache"
            },
            body: file
        })

       // console.log("resultImage ", uploadImage)

        let imagePath = "https://ipr-resources.s3.amazonaws.com/"
        let imageUploadPath = imagePath + uploadFilePath;

        let updateObj = {
            [name + "_result"]: imageUploadPath,
            [name + "_update"]: new Date()
        }
       // patientupdate(pdetails._id, updateObj)
        setFile(file => ({
            ...file,
            ...updateObj
        }));
        setLegalInfo(legalInfo => ({
            ...legalInfo,
            ...updateObj
        }));

 

    };

    const wardBack = (e) => {
        e.preventDefault()
        props.history.push("/wl")
        document.body.style.overflow = '';
    }

    let clinicPatient = JSON.parse(localStorage.getItem("p"))

// console.log(" legalInfo ", legalInfo)

    return (
        <React.Fragment>

            <MetaTags>
                <title>Legal Section</title>
            </MetaTags>
            <div className='ward '>
                <div className="popup_wrp">
                    <div className="popup_full prescribe">
                        <div className="popup_hed">
                            <h2>Legal Section </h2>
                            <div className="dsp-flex"> <a className="popup_close" onClick={(e) => { wardBack(e) }}><span className="material-icons">close</span></a></div>

                        </div>
                        <div className="popup_content">
                            <div className="popup_scroll w-btns main ">
                                    <PatientBlock patient={clinicPatient} />
                                <div className="section ctr">
                                    <h2 className="popup_title"> POWER OF ATTORNEY / ENDURING GUARDIAN </h2>


                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form_group">
                                                <label className="label">Name of Power of Attorney (if one appointed)</label>
                                                <input type="text" onChange={(e) => updateInputValue(e)} name="power_of_attorney" className="form-control" />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="search_dropdown_blk">
                                                <div className="form_group">
                                                    <label className="label">Relationship</label>
                                                    <input type="text" onChange={(e) => updateInputValue(e)} name="attorney_relation" className="form-control" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form_group">
                                                <label className="label">Phone No</label>
                                                <input type="text" className="form-control" onChange={(e) => updateInputValue(e)} name="attorney_phone"  />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form_group">
                                                <label className="label">&nbsp;</label>
                                                {(file && file.power_of_attorney_scancopy_result) ? <img src={(file && file.power_of_attorney_scancopy_result)} className="img" /> :
                                                <div className="border_btn"><input type="file" onChange={(e) => handleFile(e, "power_of_attorney_scancopy")}  /><span className="material-icons">document_scanner</span>Scan a copy</div>}
                                                
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form_group">
                                                <label className="label">Name of Enduring Guardian (if one appointed)</label>
                                                <input type="text" className="form-control" onChange={(e) => updateInputValue(e)} name="enduring_guardian" />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="search_dropdown_blk">
                                                <div className="form_group">
                                                    <label className="label">Relationship</label>
                                                    <input type="text" onChange={(e) => updateInputValue(e)} name="guardian_relation" className="form-control" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form_group">
                                                <label className="label">Phone No</label>
                                                <input type="text" className="form-control" onChange={(e) => updateInputValue(e)} name="guardian_phone"  />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form_group">
                                                <label className="label">&nbsp;</label>
                                                {(file && file.guardian_relation_scancopy_result) ? <img src={(file && file.guardian_relation_scancopy_result)} className="img" /> :
                                                <div className="border_btn"><input type="file" onChange={(e) => handleFile(e, "guardian_relation_scancopy")}  /><span className="material-icons">document_scanner</span>Scan a copy</div>}
                                            </div>
                                        </div>
                                    </div>





                                </div>
                                <div className="section ctr">
                                    <h2 className="popup_title"> ADVANCED CARE PLANNING </h2>
                                    <div className="yn_switch ward_acd ">
                                        <div className="label-container">
                                            <p>Do you have an Advance Care Directive? (If in future, you were unable to make
                                                decisions for yourself, do you have any written wishes about your future
                                                healthcare?) </p>
                                        </div>
                                        <label  className="switch-container">
                                            <input name="advanced_care" onChange={(e) => handleChange(e)} type="checkbox" className="switch_check"  />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>YES</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>NO</span>
                                            </div>
                                        </label>
                                    </div>

                                    <div className="row">

                                        <div className="col-md-4">
                                            <div className="form_group">

                                            {(file && file.care_directive_scancopy_result) ? <img src={(file && file.care_directive_scancopy_result)} className="img" /> :
                                                <div className="border_btn"><input type="file" onChange={(e) => handleFile(e, "care_directive_scancopy")}  /><span className="material-icons">document_scanner</span>Scan a copy</div>}
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="yn_switch mb-10  mt-10">
                                                <div className="label-container">
                                                    <p>Would you like to discuss with social work</p>
                                                </div>
                                                <label className="switch-container">
                                                    <input name="social_work" onChange={(e) => handleChange(e)} type="checkbox" className="switch_check" />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>YES</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>NO</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form_group">

                                                <button className="border_btn"><span
                                                    className="material-icons">social_distance</span>Refer to SW
                                                </button>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="yn_switch mb-10  mt-10">
                                                <div className="label-container">
                                                    <p>Patient is unable to discuss his final wishes due to his diagnosis of
                                                        Dementia.</p>
                                                </div>
                                                <label className="switch-container">
                                                    <input name="dementia" onChange={(e) => handleChange(e)} type="checkbox" className="switch_check" />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>YES</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>NO</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="yn_switch mb-10  mt-20">
                                                <div className="label-container">
                                                    <p>Patient family have not informed us of any decisions regarding this area
                                                        of his care.</p>
                                                </div>
                                                <label className="switch-container">
                                                    <input name="decision" onChange={(e) => handleChange(e)} type="checkbox" className="switch_check"  />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>YES</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>NO</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="section ctr">
                                    <h2 className="popup_title mb-10"> CONSENT TO SHARE INFORMATION WITH HEALTH CARE PROVIDERS</h2>
                                    <p className="note">I consent to share personal data with organisations associated with health
                                        and social care where it is considered there may be potential benefit of my or others
                                        health and social care eg doctor, hospitals, local authorities, podiatrist and that I
                                        consent for the data to be used securely for 8 years after I leave the service or longer
                                        if the law requires it. I understand my right to privacy and that if I have any concerns
                                        about privacy, I may raise them. I also understand that I may withdraw my consent at any
                                        time.</p>
                                    <div className="row">
                                        <div className="col-md-3"></div>
                                        <div className="col-md-6">
                                            <div className="signature_block mb-10">
                                            {file.provider_sign_result || legalInfo.provider_sign_result ? <img src={file.provider_sign_result || legalInfo.provider_sign_result} /> :
                                                <><SignaturePad canvasProps={{ className: "sigPad" }} ref={(ref) => { provider_sign = ref }} />
                                                <p>Signature </p>
                                                <button onClick={(e) => trim(e, "provider_sign", provider_sign)}>Trim</button>
                                                <button onClick={(e) => clear(e, provider_sign)}> Clear</button></>}
                                            </div>
                                        </div>
                                        <div className="col-md-3"></div>
                                        <div className="col-md-6">

                                            <div className="form_group">
                                                <label className="label">Print Name </label>
                                                <input type="text" className="form-control" onChange={(e) => updateInputValue(e)} name="provider_name" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">

                                            <div className="form_group">
                                                <label className="label">Date</label>
                                                <input type="date" className="form-control" onChange={(e) => updateInputValue(e)} name="provider_date" />
                                            </div>
                                        </div>
                                    </div>
                                    <h2 className="popup_title mb-10"> CONSENT TO HAVE PHOTOGRAPH TAKEN</h2>
                                    <p className="note">I consent to have my photographs taken</p>
                                    <div className="row">
                                        <div className="col-md-3"></div>
                                        <div className="col-md-6">
                                            <div className="signature_block mb-10">
                                            {file.photograph_sign_result || legalInfo.photograph_sign_result ? <img src={file.photograph_sign_result || legalInfo.photograph_sign_result} /> :
                                                <><SignaturePad canvasProps={{ className: "sigPad" }} ref={(ref) => { photograph_sign = ref }} />
                                                <p>Signature </p>
                                                <button onClick={(e) => trim(e, "photograph_sign", photograph_sign)}>Trim</button>
                                                <button onClick={(e) => clear(e, photograph_sign)}> Clear</button></>}
                                            </div>
                                        </div>
                                        <div className="col-md-3"></div>
                                        <div className="col-md-6">

                                            <div className="form_group">
                                                <label className="label">Print Name </label>
                                                <input type="text" className="form-control" onChange={(e) => updateInputValue(e)} name="photograph_name" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">

                                            <div className="form_group">
                                                <label className="label">Date</label>
                                                <input type="date" className="form-control" onChange={(e) => updateInputValue(e)} name="photograph_date" />
                                            </div>
                                        </div>
                                    </div>
                                    <h2 className="popup_title mb-10"> Signed on the behalf of hospital </h2>
                                    <div className="row">

                                        <div className="col-md-5">

                                            <div className="form_group">
                                                <label className="label">Name </label>
                                                <input type="text" className="form-control" onChange={(e) => updateInputValue(e)} name="staff_name" />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form_group">
                                                <label className="label">Date</label>
                                                <input type="date" className="form-control" onChange={(e) => updateInputValue(e)} name="staff_date" />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form_group">
                                                <label className="label">Position</label>
                                                <input type="text" className="form-control" onChange={(e) => updateInputValue(e)} name="staff_position" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="section ctr">
                                    <h2 className="popup_title"> ACKNOWLEDGEMENT OF RIGHTS & RESPONSIBILITIES</h2>
                                    <p className="note">I have read and understand the section entitled Patients’ Rights and
                                        Responsibilities in the Admission booklet and will discuss any queries with staff.</p>
                                    <div className="row">
                                        <div className="col-md-3"></div>
                                        <div className="col-md-6">
                                            <div className="signature_block mb-10">
                                            {file.responsibility_sign_result || legalInfo.responsibility_sign_result ? <img src={file.responsibility_sign_result || legalInfo.responsibility_sign_result} /> :
                                                <><SignaturePad canvasProps={{ className: "sigPad" }} ref={(ref) => { responsibility_sign = ref }} />
                                                <p>Signature </p>
                                                <button onClick={(e) => trim(e, "responsibility_sign", responsibility_sign)}>Trim</button>
                                                <button onClick={(e) => clear(e, responsibility_sign)}> Clear</button></>}
                                            </div>
                                        </div>
                                        <div className="col-md-3"></div>
                                        <div className="col-md-8">

                                            <div className="form_group">
                                                <label className="label">Print Name </label>
                                                <input type="text" className="form-control" onChange={(e) => updateInputValue(e)} name="responsibility_name" />
                                            </div>
                                        </div>
                                        <div className="col-md-4">

                                            <div className="form_group">
                                                <label className="label">Date</label>
                                                <input type="date" className="form-control" onChange={(e) => updateInputValue(e)} name="responsibility_date" />
                                            </div>
                                        </div>
                                    </div>


                                </div>

                            </div>
                       
                        </div>
                        <div className="popup_footer">
                                <div className="prescribe_footer">
                                    <div className="dsp-flex">
                                        <a className="icon_btn"> <span className="material-icons">save</span> SAVE</a>
                                        <button className="border_btn" onClick={(e) => { wardBack(e) }}><span className="material-icons">save_as</span>SAVE &amp;
                                            CLOSE
                                        </button>
                                    </div>

                                    <button className="btn dsp_flex" onClick={(e) => { wardBack(e) }}><span className="material-icons"><span className="material-icons">close</span></span>DISCARD </button>
                                </div>
                            </div>
                    </div>

                </div>
            </div>


        </React.Fragment>
    )
}

LegalSection.propTypes = {
    t: PropTypes.any,
    getWards: PropTypes.func,
    getTheatres: PropTypes.func,
    wards: PropTypes.array,
    user: PropTypes.any,
    theatres: PropTypes.array,
    clinic: PropTypes.array,
    patients: PropTypes.array,
    doctors: PropTypes.array,
    profile: PropTypes.object
}

const mapStateToProps = ({ Layout, Login }) => ({
    wards: Layout.wards,
    user: Login.user,
    theatres: Layout.theatres,
    clinic: Layout.clinic,
    patients: Layout.patients,
    doctors: Layout.doctors,
    profile: Layout.profile
})

const mapDispatchToProps = dispatch => ({
    getWards: () => dispatch(getWardsList()),
    getTheatres: () => dispatch(getTheatresList()),
    getClinic: () => dispatch(getClinicList()),
    getPatient: () => dispatch(getPatientList()),
    getUserprofile: (id) => dispatch(getUprofile(id)),
    getDoctor: () => dispatch(getDoctorList()),
    patientupdate: (id, data) => dispatch(updatePatient(id, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(LegalSection)
