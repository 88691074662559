// @flow
import {
  CHANGE_LAYOUT,
  CHANGE_LAYOUT_WIDTH,
  CHANGE_SIDEBAR_THEME,
  CHANGE_SIDEBAR_TYPE,
  CHANGE_TOPBAR_THEME,
  SHOW_RIGHT_SIDEBAR,
  TOGGLE_LEFTMENU,
  SHOW_SIDEBAR,
  SELECT_ADMINISTRATION,
  GET_WARDS_SUCCESS,
  GET_THEATRES_SUCCESS,
  GET_CLINIC_SUCCESS,
  GET_PATIENTS_SUCCESS,
  USER_PROFILE_SUCCESS,
  GET_DOCTOR_SUCCESS,
  GET_SESSION_TEAM_SUCCESS,
  GET_NEWS_SUCCESS,
  GET_DAILY_ROUNDS_SUCCESS,
  TEAM_LIST_SUCCESS,
  GET_TABLE_DATA_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
  layoutType: "vertical",
  layoutWidth: "fluid",
  leftSideBarTheme: "dark",
  leftSideBarType: "default",
  topbarTheme: "light",
  showRightSidebar: false,
  isMobile: false,
  showSidebar: true,
  leftMenu: false,
  defaultAdministration: "software",
  wards:[],
  theatres:[],
  clinic:[],
  patients: [],
  profile:{},
  team: [],
  doctors: [],
  sessionTeams: [],
  news: [],
  dailyrounds: [],
  tableData: []
}

const Layout = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_LAYOUT:
      return {
        ...state,
        layoutType: action.payload,
      }

    case CHANGE_LAYOUT_WIDTH:
      return {
        ...state,
        layoutWidth: action.payload,
      }
    case CHANGE_SIDEBAR_THEME:
      return {
        ...state,
        leftSideBarTheme: action.payload,
      }
    case SELECT_ADMINISTRATION:
      return {
        ...state,
        defaultAdministration: action.payload,
      }
    case GET_WARDS_SUCCESS:
      return {
        ...state,
        wards: action.payload,
      }
    case GET_THEATRES_SUCCESS:
      return {
        ...state,
        theatres: action.payload,
      }
    case GET_CLINIC_SUCCESS:
      return {
        ...state,
        clinic: action.payload,
      }
    case GET_PATIENTS_SUCCESS:
      return {
        ...state,
        patients: action.payload,
      }
    case GET_SESSION_TEAM_SUCCESS:
        return {
          ...state,
          sessionTeams: action.payload,
        }
    case GET_DOCTOR_SUCCESS:
      return {
        ...state,
        doctors: action.payload,
      }
    case GET_TABLE_DATA_SUCCESS:
        return {
          ...state,
          tableData: action.payload,
        }
    case GET_NEWS_SUCCESS:
      return {
        ...state,
        news: action.payload,
      }
    case GET_DAILY_ROUNDS_SUCCESS:
      return {
        ...state,
        dailyrounds: action.payload,
      }
    case USER_PROFILE_SUCCESS:
      return {
        ...state,
        profile: action.payload,
      }
    case TEAM_LIST_SUCCESS:
      return {
        ...state,
        team: action.payload,
      }
    case CHANGE_SIDEBAR_TYPE:
      return {
        ...state,
        leftSideBarType: action.payload.sidebarType,
      }
    case CHANGE_TOPBAR_THEME:
      return {
        ...state,
        topbarTheme: action.payload,
      }
    case SHOW_RIGHT_SIDEBAR:
      return {
        ...state,
        showRightSidebar: action.payload,
      }
    case SHOW_SIDEBAR:
      return {
        ...state,
        showSidebar: action.payload,
      }
    case TOGGLE_LEFTMENU:
      return {
        ...state,
        leftMenu: action.payload,
      }

    default:
      return state
  }
}

export default Layout
