import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import MetaTags from 'react-meta-tags';
import SignaturePad from 'react-signature-canvas'
import PatientBlock from "../Clinic/patientBlock";
import { removeSpecialCharacters } from '../../utils/utils';
import { Buffer } from 'buffer';
import { connect } from "react-redux"
import { getWardsList, getTheatresList, getClinicList, getPatientList, getUprofile, getDoctorList, updatePatient } from "../../store/actions"

const DischargeCheckList = props => {

    let provider_sign = useRef(null);
    let responsibility_sign = useRef(null);
    let photograph_sign = useRef(null);

    const [file, setFile] = useState({});
    const [legalInfo, setLegalInfo] = useState(false)
    const [clinicValues, setClinic] = useState({})

    const [selectedGroup, setselectedGroup] = useState(null)
    const [popupname, setpopup_name] = useState({})
    const [anydelay, setDelay] = useState(false)

    const {
        wards, user, theatres, clinic, patients, profile, doctors
    } = props;

    useEffect(() => {
        const { getWards, getTheatres, getClinic, getDoctor, getPatient, getUserprofile } = props
        // getWards()
        // getTheatres()
        // getClinic()
        getPatient()
        getDoctor()
        let userId = localStorage.getItem("authUser")
        getUserprofile(userId)
    }, [])


    useEffect(() => {
        let clinicPatient = JSON.parse(localStorage.getItem("p"))
        if (patients && patients.length > 0 && clinicPatient && clinicPatient._id) {
            const cPatient = patients.filter(
                usr => usr._id === clinicPatient._id
            )
            if (cPatient && cPatient[0]) {
                let cpatientData = cPatient[0]
                delete cpatientData["_id"]
                setClinic(clinicValues => ({
                    ...clinicValues,
                    ...cpatientData
                }));
            }

        }
    }, [patients])


    const updateInputValue = (e) => {
        let val = e.target.value;
        let name = e.target.name;
        let updatedValue = {};
        updatedValue = { [name]: val };
        setLegalInfo(legalInfo => ({
            ...legalInfo,
            ...updatedValue
        }));

    }

    const updateInputValues = (name, popupname, val) => {
        //  e.preventDefault()
        // let updatedValue = {
        //    [popupname]:{[name] : val}
        // };
        let updatedValue = clinicValues;
        //updatedValue = { [name]: val };
        // updatedValue[popupname][name] = val;
        if (updatedValue[popupname]) {
            updatedValue[popupname][name] = val;
        } else {
            updatedValue = {
                [popupname]: { [name]: val }

            };
        }
        console.log("updatedValue 2", updatedValue)
        setClinic(clinicValues => ({
            ...clinicValues,
            ...updatedValue
        }));
    }

    const updateClinic = (e) => {
        const { patientupdate } = props
        let clinicPatient = JSON.parse(localStorage.getItem("p"))
        let id = clinicPatient._id
        patientupdate(id, clinicValues)
        let patient = {
            ...clinicPatient,
            ...clinicValues
        }
        localStorage.setItem("p", JSON.stringify(patient))



    }

    const updateSwitchValue = (name, value) => {
        let updatedValue = {};
        updatedValue = { [name]: value };
        setLegalInfo(legalInfo => ({
            ...legalInfo,
            ...updatedValue
        }));

    }

    const handleChange = (event) => {
        updateSwitchValue(event.target.name, event.target.checked)
    }


    const clear = (e, refvalue) => {
        e.preventDefault()
        refvalue.clear()
    }
    const trim = (e, type, refvalue) => {
        e.preventDefault()
        sigUpload(refvalue.getCanvas().toDataURL('image/png'), type)
    }

    const sigUpload = async (filedata, name) => {
        const file = filedata
        //console.log("file ", file, name)
        let filename = name + ".png";
        // const base64 = await convertBase64(file)
        //console.log("filename ", filename)
        let pdetails = JSON.parse(localStorage.getItem("p"))

        const dataf = file.replace(/^data:image\/\w+;base64,/, "");

        const buf = Buffer.from(dataf, "base64");
        let uploadFilePath = "ipr/" + pdetails._id + "/" + name + "_result" + "/" + removeSpecialCharacters(filename);
        let data = { source_bucket: "ipr-resources", sourcepath: uploadFilePath }
        // console.log("data ", data)

        const response = await fetch(API_URL+'upload?appname=ipr', {
            method: "POST",
            mode: "cors",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
        const result = await response.json();
        // console.log("result ", result, buf.length)
        let url = result.result
        // console.log("url ", url)
        const base64Data = new Buffer.from(filedata.replace(/^data:image\/\w+;base64,/, ""), 'base64');
        // Getting the file type, ie: jpeg, png or gif
        const type = filedata.split(';')[0].split('/')[1];
        const uploadImage = await fetch(url, {
            method: "PUT",
            mode: "cors",
            headers: {
                'Content-Type': `image/${type}`,
                'Content-Encoding': 'base64',
                ACL: 'public-read',
            },
            body: base64Data
        })

        //console.log("resultImage ", uploadImage)

        let imagePath = "https://ipr-resources.s3.amazonaws.com/"
        let imageUploadPath = imagePath + uploadFilePath;


        let updateObj = {
            [name + "_result"]: imageUploadPath,
            [name + "_update"]: new Date()
        }

        setFile(file => ({
            ...file,
            ...updateObj
        }));
        setLegalInfo(legalInfo => ({
            ...legalInfo,
            ...updateObj
        }));

        // let patient = {
        //     ...pdetails,
        //     ...updateObj
        // }
        // localStorage.setItem("p", JSON.stringify(patient))

    };

    const handleFile = async (e, name) => {

        const file = e.target.files[0]
        // console.log("file ", file, name)
        let filename = e.target.files[0].name;
        // const base64 = await convertBase64(file)
        //console.log("filename ", filename)
        let pdetails = JSON.parse(localStorage.getItem("p"))
        // var reader = new FileReader();
        //   reader.readAsDataURL(file);
        // reader.onload = async function (e) {
        // console.log("e ", e)
        let uploadFilePath = "ipr/" + pdetails._id + "/" + name + "_result" + "/" + removeSpecialCharacters(filename);
        let data = { source_bucket: "ipr-resources", sourcepath: uploadFilePath }
        // console.log("data ", data)

        const response = await fetch(API_URL+'upload?appname=ipr', {
            method: "POST",
            mode: "cors",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
        const result = await response.json();

        // console.log("result ", result)
        let url = result.result
        // console.log("url ", url)
        const uploadImage = await fetch(url, {
            method: "PUT",
            mode: "cors",
            headers: {
                "Content-Type": "multipart/form-data",
                "Accept": "/",
                "Cache-Control": "no-cache",
                "Accept-Encoding": "gzip, deflate",
                "Connection": "keep-alive",
                "cache-control": "no-cache"
            },
            body: file
        })

        // console.log("resultImage ", uploadImage)

        let imagePath = "https://ipr-resources.s3.amazonaws.com/"
        let imageUploadPath = imagePath + uploadFilePath;

        let updateObj = {
            [name + "_result"]: imageUploadPath,
            [name + "_update"]: new Date()
        }
        // patientupdate(pdetails._id, updateObj)
        setFile(file => ({
            ...file,
            ...updateObj
        }));
        setLegalInfo(legalInfo => ({
            ...legalInfo,
            ...updateObj
        }));



    };

    const wardBack = (e) => {
        e.preventDefault()
        props.history.push("/wl")
        document.body.style.overflow = '';
    }

    let clinicPatient = JSON.parse(localStorage.getItem("p"))

    // console.log(" legalInfo ", legalInfo)

    return (
        <React.Fragment>

            <MetaTags>
                <title>DISCHARGE CHECKLIST</title>
            </MetaTags>
            <div className='ward '>
                <div className="popup_wrp">
                    <div className="popup_full prescribe">
                        <div className="popup_hed">
                            <h2>DISCHARGE CHECKLIST </h2>
                            <div className="dsp-flex"> <a className="popup_close" onClick={(e) => { wardBack(e) }}><span className="material-icons">close</span></a></div>

                        </div>
                        <div className="popup_content">
                            <div className="popup_scroll w-btns main ">
                                <PatientBlock patient={clinicPatient} />

                                <div className="section ctr">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <label className="label">Forename(s)</label>
                                                <input type="text" className="form-control" placeholder="" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <label className="label">Surname</label>
                                                <input type="text" className="form-control" placeholder="" />
                                            </div>
                                        </div>
                                        <div className="col-md-9">
                                            <div className="form_group">
                                                <label className="label">Address</label>
                                                <input type="text" className="form-control" placeholder="" />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form_group">
                                                <label className="label">Postcode</label>
                                                <input type="text" className="form-control" placeholder="" />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="yn_switch mb-15 mt-10">
                                                <div className="label-container">
                                                    <p>Relative informed of discharge date and time? </p>
                                                </div>
                                                <label for="check810" className="switch-container">
                                                    <input hidden="" type="checkbox" className="switch_check" id="check810" />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>NO</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>YES</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <label className="label">Name of person informed</label>
                                                <input type="text" className="form-control" placeholder="" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <label className="label"> Details</label>
                                                <input type="text" className="form-control" placeholder="" />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="yn_switch mb-10 mt-10">
                                                <div className="label-container">
                                                    <p>Care providers informed? </p>
                                                </div>
                                                <label for="check811" className="switch-container">
                                                    <input hidden="" type="checkbox" className="switch_check" id="check811"
                                                        value="" />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>NO</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>YES</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <label className="label">Confirmed by</label>
                                                <input type="text" className="form-control" placeholder="" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <label className="label"> Details</label>
                                                <input type="text" className="form-control" placeholder="" />
                                            </div>
                                        </div>
                                        <div className="col-md-12">

                                            <div className="yn_switch mb-10 mt-10">
                                                <div className="label-container">
                                                    <p>Nutritional needs considered and provisions supplied? (Consider
                                                        Nasogastric feeding, PEGs and feeding / nutritional supplements)
                                                    </p>
                                                </div>
                                                <label for="check812" className="switch-container">
                                                    <input hidden="" type="checkbox" className="switch_check" id="check812"
                                                        value="" />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>NO</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>YES</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <label className="label"> Details</label>
                                                <input type="text" className="form-control" placeholder="" />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="yn_switch mb-10 mt-10">
                                                <div className="label-container">
                                                    <p>Follow up appointment? </p>
                                                </div>
                                                <label for="check813" className="switch-container">
                                                    <input hidden="" type="checkbox" className="switch_check" id="check813"
                                                        value="" />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>NO</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>YES</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <label className="label">Confirmed by</label>
                                                <input type="text" className="form-control" placeholder="" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <label className="label"> Details</label>
                                                <input type="text" className="form-control" placeholder="" />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="yn_switch mb-10 mt-10">
                                                <div className="label-container">
                                                    <p>Take home medication? </p>
                                                </div>
                                                <label for="check961" className="switch-container">
                                                    <input hidden="" type="checkbox" className="switch_check" id="check961"
                                                        value="" />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>NO</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>YES</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <label className="label">Confirmed by</label>
                                                <input type="text" className="form-control" placeholder="" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <label className="label"> Details</label>
                                                <input type="text" className="form-control" placeholder="" />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="yn_switch mb-10 mt-10">
                                                <div className="label-container">
                                                    <p>Take home medication needing to be administered in the community?</p>
                                                </div>
                                                <label for="check962" className="switch-container">
                                                    <input hidden="" type="checkbox" className="switch_check" id="check962"
                                                        value="" />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>NO</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>YES</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <div className="form_group">
                                                <label className="label"> Details</label>
                                                <input type="text" className="form-control" placeholder="" />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="yn_switch mb-10 mt-10">
                                                <div className="label-container">
                                                    <p>Plaster of Paris check?</p>
                                                </div>
                                                <label for="check963" className="switch-container">
                                                    <input hidden="" type="checkbox" className="switch_check" id="check963"
                                                        value="" />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>NO</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>YES</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <div className="form_group">
                                                <label className="label"> Details</label>
                                                <input type="text" className="form-control" placeholder="" />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="yn_switch mb-10 mt-10">
                                                <div className="label-container">
                                                    <p>Peripheral Cannula removed?</p>
                                                </div>
                                                <label for="check964" className="switch-container">
                                                    <input hidden="" type="checkbox" className="switch_check" id="check964"
                                                        value="" />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>NO</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>YES</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="yn_switch mb-10 mt-10">
                                                <div className="label-container">
                                                    <p>Wound check on discharge?</p>
                                                </div>
                                                <label for="check964" className="switch-container">
                                                    <input hidden="" type="checkbox" className="switch_check" id="check964"
                                                        value="" />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>NO</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>YES</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <div className="form_group">
                                                <label className="label"> Details</label>
                                                <input type="text" className="form-control" placeholder="" />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="yn_switch mb-10 mt-10">
                                                <div className="label-container">
                                                    <p>Wound care post discharge?</p>
                                                </div>
                                                <label for="check965" className="switch-container">
                                                    <input hidden="" type="checkbox" className="switch_check" id="check965"
                                                        value="" />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>NO</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>YES</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="yn_switch mb-10 mt-10">
                                                <div className="label-container">
                                                    <p>Dressings Supplied</p>
                                                </div>
                                                <label for="check966" className="switch-container">
                                                    <input hidden="" type="checkbox" className="switch_check" id="check966"
                                                        value="" />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>NO</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>YES</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="yn_switch mb-10 mt-10">
                                                <div className="label-container">
                                                    <p>Suture remover</p>
                                                </div>
                                                <label for="check967" className="switch-container">
                                                    <input hidden="" type="checkbox" className="switch_check" id="check967"
                                                        value="" />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>NO</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>YES</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="yn_switch mb-10 mt-10">
                                                <div className="label-container">
                                                    <p>Staple remover</p>
                                                </div>
                                                <label for="check968" className="switch-container">
                                                    <input hidden="" type="checkbox" className="switch_check" id="check968"
                                                        value="" />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>NO</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>YES</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="yn_switch mb-10 mt-10">
                                                <div className="label-container">
                                                    <p>Clip remover</p>
                                                </div>
                                                <label for="check969" className="switch-container">
                                                    <input hidden="" type="checkbox" className="switch_check" id="check969"
                                                        value="" />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>NO</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>YES</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="yn_switch mb-10 mt-10">
                                                <div className="label-container">
                                                    <p>Drain(s) or device(s) In Situ?</p>
                                                </div>
                                                <label for="check970" className="switch-container">
                                                    <input hidden="" type="checkbox" className="switch_check" id="check970"
                                                        value="" />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>NO</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>YES</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form_group">
                                                <label className="label"> Details</label>
                                                <input type="text" className="form-control" placeholder="" />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="yn_switch mb-10 mt-10">
                                                <div className="label-container">
                                                    <p>Urinary Catheter In Situ?</p>
                                                </div>
                                                <label for="check971" className="switch-container">
                                                    <input hidden="" type="checkbox" className="switch_check" id="check971"
                                                        value="" />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>NO</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>YES</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="yn_switch mb-10 mt-10">
                                                <div className="label-container">
                                                    <p>Catheter passport provided? </p>
                                                </div>
                                                <label for="check971" className="switch-container">
                                                    <input hidden="" type="checkbox" className="switch_check" id="check971"
                                                        value="" />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>NO</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>YES</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form_group">
                                                <label className="label"> Details</label>
                                                <input type="text" className="form-control" placeholder="" />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="yn_switch mb-10 mt-10">
                                                <div className="label-container">
                                                    <p>Central Venous Catheter In Situ? </p>
                                                </div>
                                                <label for="check972" className="switch-container">
                                                    <input hidden="" type="checkbox" className="switch_check" id="check972"
                                                        value="" />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>NO</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>YES</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form_group">
                                                <label className="label"> Details</label>
                                                <input type="text" className="form-control" placeholder="" />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="yn_switch mb-10 mt-10">
                                                <div className="label-container">
                                                    <p>Continence products provided on discharge?</p>
                                                </div>
                                                <label for="check973" className="switch-container">
                                                    <input hidden="" type="checkbox" className="switch_check" id="check973"
                                                        value="" />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>NO</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>YES</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form_group">
                                                <label className="label"> Details</label>
                                                <input type="text" className="form-control" placeholder="" />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="yn_switch mb-10 mt-10">
                                                <div className="label-container">
                                                    <p>Arranged Practice Nurse (non-housebound patients)? </p>
                                                </div>
                                                <label for="check974" className="switch-container">
                                                    <input hidden="" type="checkbox" className="switch_check" id="check974"
                                                        value="" />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>NO</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>YES</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form_group">
                                                <label className="label"> Details</label>
                                                <input type="text" className="form-control" placeholder="" />
                                            </div>
                                        </div>
                                        <div className="col-md-12">

                                            <div className="check_item mt-0 mb-10"><label className="check">Arranged District Nurse (if
                                                the patient is housebound)? </label>
                                                <div className="select_btns popup_select">
                                                    <div className="select_btn"><label><input name="site" type="radio"
                                                        value="Site 2" /><span>Consider if the patient </span></label>
                                                    </div>
                                                    <div className="select_btn"><label><input name="site" type="radio"
                                                        value="Site 2" /><span>carer </span></label></div>
                                                    <div className="select_btn"><label><input name="site" type="radio"
                                                        value="Site 2" /><span>family are able to perform the care
                                                        </span></label></div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <div className="form_group">
                                                <label className="label"> Details</label>
                                                <input type="text" className="form-control" placeholder="" />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="yn_switch mb-10 mt-10">
                                                <div className="label-container">
                                                    <p>Arranged Community Resource Team / Specialist Team? </p>
                                                </div>
                                                <label for="check976" className="switch-container">
                                                    <input hidden="" type="checkbox" className="switch_check" id="check976"
                                                        value="" />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>NO</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>YES</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form_group">
                                                <label className="label"> Details</label>
                                                <input type="text" className="form-control" placeholder="" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <label className="label"> Arranged Other</label>
                                                <input type="text" className="form-control" placeholder="" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <label className="label"> Details</label>
                                                <input type="text" className="form-control" placeholder="" />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="yn_switch mb-10 mt-10">
                                                <div className="label-container">
                                                    <p>Equipment? </p>
                                                </div>
                                                <label for="check977" className="switch-container">
                                                    <input hidden="" type="checkbox" className="switch_check" id="check977"
                                                        value="" />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>NO</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>YES</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form_group">
                                                <label className="label"> Details</label>
                                                <input type="text" className="form-control" placeholder="" />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="yn_switch mb-10 mt-10">
                                                <div className="label-container">
                                                    <p>Transport? </p>
                                                </div>
                                                <label for="check978" className="switch-container">
                                                    <input hidden="" type="checkbox" className="switch_check" id="check978"
                                                        value="" />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>NO</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>YES</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form_group">
                                                <label className="label"> Details</label>
                                                <input type="text" className="form-control" placeholder="" />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="yn_switch mb-10 mt-10">
                                                <div className="label-container">
                                                    <p>Copy of DNACPR sent? </p>
                                                </div>
                                                <label for="check979" className="switch-container">
                                                    <input hidden="" type="checkbox" className="switch_check" id="check979"
                                                        value="" />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>NO</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>YES</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form_group">
                                                <label className="label"> Details</label>
                                                <input type="text" className="form-control" placeholder="" />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="yn_switch mb-10 mt-10">
                                                <div className="label-container">
                                                    <p>Patient property returned? </p>
                                                </div>
                                                <label for="check980" className="switch-container">
                                                    <input hidden="" type="checkbox" className="switch_check" id="check980"
                                                        value="" />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>NO</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>YES</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form_group">
                                                <label className="label"> Details</label>
                                                <input type="text" className="form-control" placeholder="" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <label className="label"> Completed by</label>
                                                <input type="text" className="form-control" placeholder="" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <label className="label"> Designation</label>
                                                <input type="text" className="form-control" placeholder="" />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form_group">
                                                <label className="label"> Date</label>
                                                <input type="text" className="form-control" placeholder="" />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form_group">
                                                <label className="label"> Time</label>
                                                <input type="text" className="form-control" placeholder="" />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                        </div>
                                        <div className="col-md-4">
                                            <div className="signature_block dc_sign">
                                                <p>Reviewer Signature</p>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form_group">
                                                <label className="label"> Review Date</label>
                                                <input type="text" className="form-control" placeholder="" />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form_group">
                                                <label className="label"> Time</label>
                                                <input type="text" className="form-control" placeholder="" />
                                            </div>
                                        </div>
                                    </div>




                                </div>
                                <div className="section ctr">
                                    <h2 className="popup_title"> DISCHARGE PLANNING</h2>
                                    <p className="note">EDD / / (To be recorded within 14 hours of admission unless the patient is
                                        acutely unwell and EDD has yet to be decided or EOL). </p>
                                    <p className="sub_title mb-10">Lives</p>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form_group">
                                                <label className="label"> Alone</label>
                                                <input type="text" className="form-control" placeholder="" />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form_group">
                                                <label className="label"> With </label>
                                                <input type="text" className="form-control" placeholder="" />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form_group">
                                                <label className="label"> No known residence </label>
                                                <input type="text" className="form-control" placeholder="" />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="check_item mt-0 mb-10"><label className="check">Intended Destination Post
                                                Discharge</label>
                                                <div className="select_btns popup_select">
                                                    <div className="select_btn"><label><input name="site" type="radio"
                                                        value="Site 2" /><span>Home </span></label></div>
                                                    <div className="select_btn"><label><input name="site" type="radio"
                                                        value="Site 2" /><span> Long-term care </span></label></div>
                                                    <div className="select_btn"><label><input name="site" type="radio"
                                                        value="Site 2" /><span>Homeless shelter
                                                        </span></label></div>
                                                    <div className="select_btn"><label><input name="site" type="radio"
                                                        value="Site 2" /><span>Boarding home
                                                        </span></label></div>
                                                    <div className="select_btn"><label><input name="site" type="radio"
                                                        value="Site 2" /><span>Undetermined
                                                        </span></label></div>
                                                    <div className="select_btn"><label><input name="site" type="radio"
                                                        value="Site 2" /><span>Other
                                                        </span></label></div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="col-md-12">
                                            <div className="form_group">
                                                <label className="label">Other </label>
                                                <input type="text" className="form-control" placeholder="" />
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <div className="check_item mt-0 mb-10"><label className="check">Previous Utilization of
                                                Community Resources </label>
                                                <div className="select_btns popup_select">
                                                    <div className="select_btn"><label><input name="site" type="radio"
                                                        value="Site 1" /><span>Home Care/Hospice </span></label></div>
                                                    <div className="select_btn"><label><input name="site" type="radio"
                                                        value="Site 2" /><span>Adult Day Care </span></label></div>
                                                    <div className="select_btn"><label><input name="site" type="radio"
                                                        value="Site 2" /><span>Church Groups
                                                        </span></label></div>
                                                    <div className="select_btn"><label><input name="site" type="radio"
                                                        value="Site 2" /><span>Other
                                                        </span></label></div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="col-md-12">
                                            <div className="form_group">
                                                <label className="label">Other </label>
                                                <input type="text" className="form-control" placeholder="" />
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <label className="label">Meals on Wheels </label>
                                                <input type="text" className="form-control" placeholder="" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <label className="label">Homemaker/Home Health Aid </label>
                                                <input type="text" className="form-control" placeholder="" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <label className="label">Community Support Group </label>
                                                <input type="text" className="form-control" placeholder="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="check_item mt-0 mb-10"><label className="check">Post-discharge
                                        Transportation</label>
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input name="site" type="radio"
                                                value="Site 1" /><span>Car </span></label></div>
                                            <div className="select_btn"><label><input name="site" type="radio"
                                                value="Site 2" /><span>Ambulance </span></label></div>
                                            <div className="select_btn"><label><input name="site" type="radio"
                                                value="Site 2" /><span>Bus/Taxi
                                                </span></label></div>
                                            <div className="select_btn"><label><input name="site" type="radio"
                                                value="Site 2" /><span>Unable to Determine at this time
                                                </span></label></div>
                                        </div>
                                    </div>

                                    <div className="yn_switch mb-10 mt-10">
                                        <div className="label-container">
                                            <p>Anticipated Financial Assistance Post-discharge? </p>
                                        </div>
                                        <label for="check1120" className="switch-container">
                                            <input hidden="" type="checkbox" className="switch_check" id="check1120" value="" />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>NO</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>YES</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="yn_switch mb-10 mt-10">
                                        <div className="label-container">
                                            <p>Anticipated Problems with Self-care Post-discharge? </p>
                                        </div>
                                        <label for="check1121" className="switch-container">
                                            <input hidden="" type="checkbox" className="switch_check" id="check1121" value="" />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>NO</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>YES</span>
                                            </div>
                                        </label>
                                    </div>

                                    <p className="sub_title mb-10">Self-care abilities</p>
                                    <div className="check_item mt-0 mb-10"><label className="check">Needs help with </label>
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input name="site" type="radio"
                                                value="Site 1" /><span>feeding </span></label></div>
                                            <div className="select_btn"><label><input name="site" type="radio"
                                                value="Site 2" /><span>bathing </span></label></div>
                                            <div className="select_btn"><label><input name="site" type="radio"
                                                value="Site 2" /><span>dressing
                                                </span></label></div>
                                            <div className="select_btn"><label><input name="site" type="radio"
                                                value="Site 2" /><span>grooming
                                                </span></label></div>
                                            <div className="select_btn"><label><input name="site" type="radio"
                                                value="Site 2" /><span>transferring
                                                </span></label></div>
                                            <div className="select_btn"><label><input name="site" type="radio"
                                                value="Site 2" /><span>taking medications
                                                </span></label></div>
                                            <div className="select_btn"><label><input name="site" type="radio"
                                                value="Site 2" /><span>cooking
                                                </span></label></div>
                                            <div className="select_btn"><label><input name="site" type="radio"
                                                value="Site 2" /><span>transportation
                                                </span></label></div>
                                            <div className="select_btn"><label><input name="site" type="radio"
                                                value="Site 2" /><span>using phone
                                                </span></label></div>
                                            <div className="select_btn"><label><input name="site" type="radio"
                                                value="Site 2" /><span>shopping
                                                </span></label></div>
                                        </div>
                                    </div>
                                    <div className="yn_switch mb-10 mt-10">
                                        <div className="label-container">
                                            <p>Assistive Devices Needed Post-discharge </p>
                                        </div>
                                        <label for="check1122" className="switch-container">
                                            <input hidden="" type="checkbox" className="switch_check" id="check1122" value="" />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>NO</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>YES</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="form_group">
                                        <label className="label">Type </label>
                                        <input type="text" className="form-control" placeholder="" />
                                    </div>
                                    <p className="sub_title mb-10">Referrals: (record date)</p>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <label className="label">Discharge Coordinator </label>
                                                <input type="text" className="form-control" placeholder="" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <label className="label">Home Health </label>
                                                <input type="text" className="form-control" placeholder="" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <label className="label">Social Service </label>
                                                <input type="text" className="form-control" placeholder="" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <label className="label">Financial counselor </label>
                                                <input type="text" className="form-control" placeholder="" />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form_group">
                                                <label className="label">Other Comments</label>
                                                <input type="text" className="form-control" placeholder="" />
                                            </div>
                                        </div>
                                        <div className="col-md-3"> </div>
                                        <div className="col-md-6">
                                            <div className="signature_block">
                                                <p>Signature</p>
                                            </div>
                                        </div>
                                        <div className="col-md-3"> </div>
                                    </div>
                                </div>

                            </div>
                           
                        </div>
                        <div className="popup_footer">
                                <div className="prescribe_footer">
                                    <div className="dsp-flex">
                                        <a className="icon_btn" onClick={(e) => { wardBack(e) }}> <span className="material-icons">save</span> SAVE</a>
                                        <button className="border_btn" onClick={(e) => { wardBack(e) }}><span className="material-icons">save_as</span>SAVE &amp;
                                            CLOSE
                                        </button>
                                    </div>

                                    <button className="btn dsp_flex" onClick={(e) => { wardBack(e) }}><span className="material-icons"><span className="material-icons">close</span></span>DISCARD </button>
                                </div>
                            </div>
                    </div>

                </div>
            </div>


        </React.Fragment>
    )
}

DischargeCheckList.propTypes = {
    t: PropTypes.any,
    getWards: PropTypes.func,
    getTheatres: PropTypes.func,
    wards: PropTypes.array,
    user: PropTypes.any,
    theatres: PropTypes.array,
    clinic: PropTypes.array,
    patients: PropTypes.array,
    doctors: PropTypes.array,
    profile: PropTypes.object
}

const mapStateToProps = ({ Layout, Login }) => ({
    wards: Layout.wards,
    user: Login.user,
    theatres: Layout.theatres,
    clinic: Layout.clinic,
    patients: Layout.patients,
    doctors: Layout.doctors,
    profile: Layout.profile
})

const mapDispatchToProps = dispatch => ({
    getWards: () => dispatch(getWardsList()),
    getTheatres: () => dispatch(getTheatresList()),
    getClinic: () => dispatch(getClinicList()),
    getPatient: () => dispatch(getPatientList()),
    getUserprofile: (id) => dispatch(getUprofile(id)),
    getDoctor: () => dispatch(getDoctorList()),
    patientupdate: (id, data) => dispatch(updatePatient(id, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(DischargeCheckList)
