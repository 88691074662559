import React from "react";

function IconAngleDown(props) {
  return (
    <svg
      width="14"
      height="8"
      viewBox="0 0 14 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.99965 5.1714L11.9495 0.22168L13.3637 1.63589L6.99965 7.9999L0.635742 1.63589L2.04995 0.22168L6.99965 5.1714Z"
        fill="black"
      />
    </svg>
  );
}

export default IconAngleDown;
