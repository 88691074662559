import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import Moment from 'moment';
import MetaTags from 'react-meta-tags';
import PatientBlock from "../Clinic/patientBlock";
import { Link } from "react-router-dom"
import speech from '../../assets/images/speech_to_text.svg';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition'
import WardMenus from "./wardMenus";
import { connect } from "react-redux"
import { removeSpecialCharacters } from '../../utils/utils';
import { getWardsList, getTheatresList, getClinicList, getPatientList, getUprofile, getDoctorList, updatePatient, getNews, updateDailyRounds, getDailyRounds } from "../../store/actions"

const WardLanding = props => {
    document.body.style.overflow = '';
    document.body.style.padding = '';
    const currentDate = Moment().format('MMM DD, YYYY')
    let clinicPatient = JSON.parse(localStorage.getItem("p"))

    const [file, setFile] = useState({});
    const [prevCount, setprevCount] = useState(0)
    const [clinicValues, setClinic] = useState({})
    const [previousDate, setprevDate] = useState(currentDate)
    const [visisbleSpeechtotext, setVisibleSpeechtotext] = useState(false)
    const [speechtextname, setspeechtextname] = useState(false)
    const [dailyround, set_dailyround] = useState({})
    const [ang, setAng] = useState(false)
    const [medication, setMedication] = useState({ antiemetics_laxatives: false, analgesics: false, antibiotics: false })
    const [transcribeText, settranscribeText] = useState("")
    const [verifiedStatus, setVerified] = useState(false)

    const {
        wards, user, theatres, clinic, patients, profile, doctors, news, dailyrounds
    } = props;

    useEffect(() => {
        const { getWards, getTheatres, getClinic, getDoctor, getPatient, getUserprofile, newsList, dailyRoundsList } = props
        // getWards()
        // getTheatres()
        // getClinic()
        getNewsDailyRounds()
        // const formatDate = Moment().subtract(1, 'day').format('MMM DD, YYYY')
        // const formattodayDate = Moment().format('MMM DD, YYYY')
        // let clinicPatient = JSON.parse(localStorage.getItem("p"))
        // let id = clinicPatient._id
        // let data = {
        //     // "date": formatDate, 
        //     "patientId": id
        // }
        // newsList(data)
        // let drdata = {
        //     "date": [formatDate, formattodayDate],
        //     "patientId": id
        // }
        // dailyRoundsList(drdata)

        getPatient()
        getDoctor()
        let userId = localStorage.getItem("authUser")
        getUserprofile(userId)
    }, [])


    useEffect(() => {
        let clinicPatient = JSON.parse(localStorage.getItem("p"))
        if (patients && patients.length > 0 && clinicPatient && clinicPatient._id) {
            const cPatient = patients.filter(
                usr => usr._id === clinicPatient._id
            )
            if (cPatient && cPatient[0]) {
                let cpatientData = cPatient[0]
                delete cpatientData["_id"]
                setClinic(clinicValues => ({
                    ...clinicValues,
                    ...cpatientData
                }));
            }

        }
    }, [patients])

    useEffect(() => {
    }, [news])

    useEffect(() => {
        
    }, [dailyrounds])

    const updateInputValue = (e) => {
        let val = e.target.value;
        let name = e.target.name;
        let updatedValue = {};
        updatedValue = { [name]: val };

        set_dailyround(dailyround => ({
            ...dailyround,
            ...updatedValue
        }));
    }

    const updateInputValues = (e, name, val) => {
        e.preventDefault()
        let updatedValue = {};
        updatedValue = { [name]: val };
        set_dailyround(dailyround => ({
            ...dailyround,
            ...updatedValue
        }));
    }

    const handleChange = (event) => {
        updateInputValues(event, event.target.name, event.target.checked)
    }

    const popupInOut = (e, name, val) => {
        let updatedValue = {};
        updatedValue = { [name]: val };
        setMedication(medication => ({
            ...medication,
            ...updatedValue
        }));
    }

    const saveTranscript = (e, name) => {

        let val = transcript;
        let updatedValue = {};
        updatedValue = { [name]: val };
        //console.log("updatedValue ", updatedValue)
        set_dailyround(dailyround => ({
            ...dailyround,
            ...updatedValue
        }));
    }

    const redirectLink = (e, route) => {
        e.preventDefault()
        props.history.push("/" + route)
    }
    const prevDate = (e, type) => {
        e.preventDefault()
        let datecountvalue = prevCount + 1
        if (type == "next") {
            datecountvalue = prevCount - 1
        }
        const formatDate = Moment().subtract(datecountvalue, 'day').format('MMM DD, YYYY')

        let dcountvalue = prevCount + 2
        if (type == "next") {
            dcountvalue = prevCount - 0
        }
        const pformatDate = Moment().subtract(dcountvalue, 'day').format('MMM DD, YYYY')
        //console.log("pformatDate ",pformatDate, formatDate)
        setprevCount(datecountvalue)
        const { dailyRoundsList } = props

        let clinicPatient = JSON.parse(localStorage.getItem("p"))
        let id = clinicPatient._id
        let data = {
            "date": [pformatDate, formatDate],
            "patientId": id
        }
        dailyRoundsList(data)
        setprevDate(formatDate)
    }

    const getNewsDailyRounds = () => {
        const { newsList, dailyRoundsList } = props
        const formatDate = Moment().subtract(1, 'day').format('MMM DD, YYYY')
        const formattodayDate = Moment().format('MMM DD, YYYY')
        let clinicPatient = JSON.parse(localStorage.getItem("p"))
        let id = clinicPatient._id
        let data = {
            // "date": formatDate, 
            "patientId": id
        }
        newsList(data)
        let drdata = {
            "date": [formatDate, formattodayDate],
            "patientId": id
        }
        dailyRoundsList(drdata)
    }

    const getdailyroundsResult = () => {
        const { dailyRoundsList } = props
        const formatDate = Moment().subtract(1, 'day').format('MMM DD, YYYY')
        const formattodayDate = Moment().format('MMM DD, YYYY')
        let clinicPatient = JSON.parse(localStorage.getItem("p"))
        let id = clinicPatient._id
        let drdata = {
            "date": [formatDate, formattodayDate],
            "patientId": id
        }
        dailyRoundsList(drdata)
    }

    const updateDRData = (e, section) => {
        const { dailyUpdate, dailyrounds } = props
        let clinicPatient = JSON.parse(localStorage.getItem("p"))
        let id = clinicPatient._id
        const formatDate = Moment().format('MMM DD, YYYY')
        let mapData = dailyround
        mapData['patientId'] = id
        mapData['date'] = formatDate

        if(section){
            mapData[section] = true
        }

        // console.log("dailyroundsmap ", dailyrounds)
        if (dailyrounds && dailyrounds.length > 0) {

            let mergeData = dailyrounds[0]
            // console.log("mergeData1 ", mergeData)
            delete mergeData.patientId
            // delete mergeData.date
            delete mergeData.created
            delete mergeData._id
            delete mergeData.hospital
            //  console.log("mergeData ", mergeData)
            mapData = { ...mergeData, ...mapData }
        }
        //  console.log("mapData fin ", mapData)

        dailyUpdate(mapData)
        getdailyroundsResult()
        setVerified(true)
        setTimeout(() => {
            setVerified(false)
            window.location.reload(); 
            // getNewsDailyRounds()
          }, 3000);
    }

    const handleFile = async (e, name) => {

        const file = e.target.files[0]
        // console.log("file ", file, name)
        let filename = e.target.files[0].name;
        // const base64 = await convertBase64(file)
        //console.log("filename ", filename)
        let pdetails = JSON.parse(localStorage.getItem("p"))
        // var reader = new FileReader();
        //   reader.readAsDataURL(file);
        // reader.onload = async function (e) {
        // console.log("e ", e)
        let uploadFilePath = "ipr/" + pdetails._id + "/" + name + "_result" + "/" + removeSpecialCharacters(filename);
        let data = { source_bucket: "ipr-resources", sourcepath: uploadFilePath }
        // console.log("data ", data)

        const response = await fetch(API_URL+'upload?appname=ipr', {
            method: "POST",
            mode: "cors",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
        const result = await response.json();

        // console.log("result ", result)
        let url = result.result
        // console.log("url ", url)
        const uploadImage = await fetch(url, {
            method: "PUT",
            mode: "cors",
            headers: {
                "Content-Type": "multipart/form-data",
                "Accept": "/",
                "Cache-Control": "no-cache",
                "Accept-Encoding": "gzip, deflate",
                "Connection": "keep-alive",
                "cache-control": "no-cache"
            },
            body: file
        })

        // console.log("resultImage ", uploadImage)

        let imagePath = "https://ipr-resources.s3.amazonaws.com/"
        let imageUploadPath = imagePath + uploadFilePath;

        let updateObj = {
            [name + "_result"]: imageUploadPath,
            [name + "_update"]: new Date()
        }
        // patientupdate(pdetails._id, updateObj)
        setFile(file => ({
            ...file,
            ...updateObj
        }));
        set_dailyround(dailyround => ({
            ...dailyround,
            ...updateObj
        }));



    };

    const deleteSelect = (e) => {
        let selection = window.getSelection();
        let html = document.getElementById("transcribeResultText").innerHTML;
        // console.log("selection", selection.toString())
        // console.log("transcript", transcript)
        // console.log("html", html)
        let replacedText = html.replace(selection.toString(), "")
        // console.log("replacedText", replacedText)
        let ft = replacedText
        settranscribeText(ft)
        selection.deleteFromDocument();
        resetTranscript()
        SpeechRecognition.stopListening()
        SpeechRecognition.startListening({ continuous: true })

    }

    const commands = [
        // {
        //   command: 'I would like to order *',
        //   callback: (food) => setMessage(`Your order is for: ${food}`)
        // },
        // {
        //   command: 'The weather is :condition today',
        //   callback: (condition) => setMessage(`Today, the weather is ${condition}`)
        // },
        // {
        //   command: 'My top sports are * and *',
        //   callback: (sport1, sport2) => setMessage(`#1: ${sport1}, #2: ${sport2}`)
        // },
        // {
        //   command: 'Pass the salt (please)',
        //   callback: () => setMessage('My pleasure')
        // },
        // {
        //   command: 'delete',
        //   callback: () => deleteSelect()
        //  // matchInterim: true
        // },
        {
            command: 'Yes',
            callback: (command, spokenPhrase, similarityRatio) => setMessage(`${command} and ${spokenPhrase} are ${similarityRatio * 100}% similar`),
            // If the spokenPhrase is "Benji", the message would be "Beijing and Benji are 40% similar"
            isFuzzyMatch: true,
            fuzzyMatchingThreshold: 0.2
        },
        {
            command: 'clear',
            callback: ({ resetTranscript }) => resetTranscript()
        }
    ]

    const { transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition } = useSpeechRecognition({ commands })


    const listMedication = [{
        "label": "ANTI-EMETICS LAXATIVES",
        "value": "antiemetics_laxatives"
    },
    {
        "label": "ANALGESICS",
        "value": "analgesics"
    },
    {
        "label": "ANTIBIOTICS",
        "value": "antibiotics"
    }]

    const dailyProgressNotes = [{
        "label": "WARD ROUND TEAM",
        "value": "dailyRound_ward_round_team"
    },
    {
        "label": "REVIEW OF PREVIOUS DAY'S ENTRIES",
        "value": "dailyRound_previous_day_entries"
    },
    {
        "label": "SIGNIFICANT EVENTS IN THE LAST 24 HOURS",
        "value": "dailyRound_significant_events"
    },
    {
        "label": "GENERAL CONDITION",
        "value": "dailyRound_generalcondition"
    }]

    const observationsReview = [{
        "label": "NEWS2",
        "value": "dailyRound_news2"
    },
    {
        "label": "FLUID BALANCE",
        "value": "dailyRound_fluidbalance"
    },
    {
        "label": "NV STATUS",
        "value": "dailyRound_nv_status"
    }
    ]

    const activeproblems = [{
        "label": "Low Sodium (Look up) Active / Resolved",
        "value": "dailyRound_low_sodium"
    },
    {
        "label": "STABLE, IMPROVING: Continue management ( course of abx)",
        "value": "dailyRound_stable_improving"
    },
    {
        "label": "SUBJECTIVE (What the pt. tells you)",
        "value": "dailyRound_subjective"
    },
    {
        "label": "OBJECTIVE (What you see)",
        "value": "dailyRound_objective"
    },
    {
        "label": "ASSESSMENT (Impression)",
        "value": "dailyRound_assesment"
    },
    {
        "label": "WORSENING",
        "value": "dailyRound_worsening"
    },
    {
        "label": "UTI (Look up) Active / Resolved",
        "value": "dailyRound_uti"
    },
    {
        "label": "AKI (Look up)",
        "value": "dailyRound_aki"
    },
    {
        "label": "HAP (Look up)",
        "value": "dailyRound_hap"
    },
    {
        "label": "PROBLEM (Look up) Active / Resolved",
        "value": "dailyRound_problem_lookup"
    },
    {
        "label": "KNOWN CAUSE / DD",
        "value": "dailyRound_known_cause"
    }]


    const preop_preparation = [{
        "label": "NOT ON OP LIST",
        "value": "dailyRound_notonoplist"
    },
    {
        "label": "ON OP LIST",
        "value": "dailyRound_onoplist"
    },
    {
        "label": "DIABETES MANAGEMENT",
        "value": "dailyRound_diabetes_management"
    },
    {
        "label": "ANTICOAGULATION MANAGEMENT",
        "value": "dailyRound_anticoagulation"
    },
    {
        "label": "STEROIDS",
        "value": "dailyRound_steroids"
    }
    ]

    const postop_checks = [{
        "label": "SURGICAL/NON SURGICAL WOUND: Healing demonstrated. DRESSING Intact. Wound ooze nil/minimal DRAINS Patent and draining within recommended amount",
        "value": "dailyRound_surgical"
    },
    {
        "label": "CAST /SPLINT TRACTION SKIN under plaster cast/skin traction",
        "value": "dailyRound_cast"
    }
    ]

    const intake_output = [{
        "label": "EATING & DRINKING",
        "value": "dailyRound_eating_drinking"
    },
    {
        "label": "URINE OUTPUT",
        "value": "dailyRound_urine_output"
    },
    {
        "label": "BOWELS",
        "value": "dailyRound_bowels"
    },
    {
        "label": "TOILETING",
        "value": "dailyRound_toileting"
    }
    ]

    const chest_abdomen = [{
        "label": "No evidence of chest infection",
        "value": "dailyRound_chest_infection"
    },
    {
        "label": "Calf Soft & Non tender",
        "value": "dailyRound_calf_soft"
    },
    {
        "label": "Receiving Peptic Ulcer prophylaxis (PPI)",
        "value": "dailyRound_peptic"
    }
    ]

    const infection_checks = [{
        "label": "SURGICAL SITE",
        "value": "dailyRound_surgical"
    },
    {
        "label": "WOUND SITE",
        "value": "dailyRound_wound_site"
    },
    {
        "label": "IV LINES",
        "value": "dailyRound_ivlines"
    },
    {
        "label": "CATHETER ACQUIRED",
        "value": "dailyRound_catheter"
    }
    ]

    const mobility_wbstatus = [{
        "label": "Today’s activity level",
        "value": "dailyRound_activity_level"
    },
    {
        "label": "Weight bearing status",
        "value": "dailyRound_weight_bearing_status"
    }
    ]

    const speciality_check_list = [{
        "label": "FURTHER EXAM Not Relevant",
        "value": "dailyRound_further_exam"
    },
    {
        "label": "AS NEEDED",
        "value": "dailyRound_sp_asneeded"
    },
    {
        "label": "BM: Blood sugar required? No Yes: …per day / AMT / GCS / Pupils / Neurological / Vascular / Compartment Syndrome / Night handover required",
        "value": "dailyRound_bm_required"
    }

    ]

    const overall = [{
        "label": "STABLE & IMPROVING HAS ONGOING PROBLEMS DETERIORATING",
        "value": "dailyRound_stable"
    },
    {
        "label": "No specific medical/nursing issues identified other than those highlighted.",
        "value": "dailyRound_medical_nursing"
    },
    {
        "label": "No major issues to address.",
        "value": "dailyRound_major_issues"
    },
    {
        "label": "There are no red flags of failure to progress or complications, and the patient is progressing appropriately with their treatment or appropriate for their postoperative day",
        "value": "dailyRound_complications"
    },
    {
        "label": "SAVE COMPLETE SIGN Changes made by: Name, GMC, Grade, Bleep",
        "value": "dailyRound_gmc_grade"
    }

    ]

    const hygiene_comfort = [{
        "label": "Patient independent with hygiene needs and attended to own hygiene needs",
        "value": "dailyRound_stable"
    },
    {
        "label": "Washing & Dressing: Needs attended to/ Patient managed by self",
        "value": "dailyRound_medical_nursing"
    },
    {
        "label": "Hair, mouth care: Needs attended to/ Patient managed by self",
        "value": "dailyRound_major_issues"
    },
    {
        "label": "Hair -Shave - Nail - Foot care: Needs met +",
        "value": "dailyRound_complications"
    },
    {
        "label": "Eyes and mouth clean and moist",
        "value": "dailyRound_gmc_grade"
    },
    {
        "label": "Oral mucosa intact and no signs of oral candidiasis",
        "value": "dailyRound_medical_nursing"
    },
    {
        "label": "Independent with Mouth care",
        "value": "dailyRound_major_issues"
    },
    {
        "label": "Washing: Strip wash only/ Patient managed Shower-Bath by self",
        "value": "dailyRound_complications"
    },
    {
        "label": "Dressing: Managed to get dressed independently",
        "value": "dailyRound_gmc_grade"
    },
    {
        "label": "Carer offered the opportunity to be involved in patient’s personal care",
        "value": "dailyRound_complications"
    },
    {
        "label": "Linen changed",
        "value": "dailyRound_gmc_grade"
    }

    ]




    const riskassesment = [{
        "label": "NORMAL",
        "value": "dailyRound_risk_normal"
    },
    {
        "label": "SKIN INTEGRITY",
        "value": "dailyRound_skin_integrity"
    },
    {
        "label": "NUTRITION - MUST",
        "value": "dailyRound_nutrition"
    },
    {
        "label": "MOVING & HANDLING",
        "value": "dailyRound_moving"
    },
    {
        "label": "SMOKING",
        "value": "dailyRound_smoking"
    },
    {
        "label": "ALCOHOL",
        "value": "dailyRound_alcohol"
    },
    {
        "label": "FALLS RISK",
        "value": "dailyRound_falls_risk"
    },
    {
        "label": "SUPERVISION + BED RAILS",
        "value": "dailyRound_supervision"
    },
    {
        "label": "SAFEGUARDING",
        "value": "dailyRound_safegauarding"
    },
    {
        "label": "CONTINENCE ASSESSMENT",
        "value": "dailyRound_continence_assesment"
    },
    {
        "label": "CHOKING RISK",
        "value": "dailyRound_choking"
    },
    {
        "label": "DAILY NURSING CARE (EACH SHIFT) Scan the patient (top to toe). Scan the surroundings Hand hygiene performed. Room checked for contact precautions. Principles of asepsis and safety followed. Patient ID: Confirmed using two patient identifiers (e.g., name and date of birth).",
        "value": "dailyRound_nursing_care"
    }, {
        "label": "UPDATES FROM PREVIOUS SHIFT",
        "value": "dailyRound_previous_shift"
    },
    {
        "label": "GENERAL CONDITION",
        "value": "dailyRound_general_condition"
    },
    {
        "label": "DAILY MENTAL STATUS ASSESSMENT",
        "value": "dailyRound_mental_status"
    },
    {
        "label": "SLEEPING",
        "value": "dailyRound_sleeping"
    },


    ]


    const breathing_respiratory = [{
        "label": "RESPIRATORY CARE attended",
        "value": "dailyRound_respiratory_care"
    }]

    const skin_pressure_care = [{
        "label": "Patient has normal/intact skin and can move independently or with aids Mobility ",
        "value": "dailyRound_intact_skin"
    },
    {
        "label": "Pressure areas ulcer prevention: No sores",
        "value": "dailyRound_areas_ulcer"
    },
    {
        "label": "Continence: Person is fully continent. Nutrition: Person appears well nourished and able to eat and drink",
        "value": "dailyRound_continence"
    },
    {
        "label": "Person is fully mobile without equipment / assistance",
        "value": "dailyRound_assistance"
    }

    ]

    const mobility_function = [{
        "label": "Patient is allowed walking / is on bed rest ",
        "value": "dailyRound_allowed_walking"
    },
    {
        "label": "Patient is Ambulating / transferring safely as per orders",
        "value": "dailyRound_ambulating"
    },
    {
        "label": "Patient is mobile independently",
        "value": "dailyRound_mobile_independently"
    },
    {
        "label": "Patient is safe on feet",
        "value": "dailyRound_safe_on_feet"
    },
    {
        "label": "TRANSFERS: (bed/chair/bath/shower/toilet/wheelchair): Independent",
        "value": "dailyRound_bed_chair"
    },
    {
        "label": "BED REST: Independently able to reposition self and able to relieve own pressure areas",
        "value": "dailyRound_bed_rest"
    },
    {
        "label": "SITTING: Able to reposition self in chair",
        "value": "dailyRound_sitting"
    },
    {
        "label": "STANDING: Independent",
        "value": "dailyRound_standing"
    },
    {
        "label": "WALKING: Independent (but may use any aid, eg. cane)",
        "value": "dailyRound_walking"
    },
    {
        "label": "STAIRS / STEPS: Independent",
        "value": "dailyRound_stairs_steps"
    },
    {
        "label": "BARIATRIC: Not over weight",
        "value": "dailyRound_bariatric"
    }

    ]


    const inpatient_complex = [{
        "label": "FALLS",
        "value": "dailyRound_falls"
    },
    {
        "label": "POLYPHARMACY",
        "value": "dailyRound_polypharmacy"
    },
    {
        "label": "POSTURAL HYPOTENSION",
        "value": "dailyRound_postural_hypotension"
    },
    {
        "label": "ACTIVE NURSING ISSUES",
        "value": "dailyRound_nursing_issues"
    },
    {
        "label": "TESTS / REFERRALS / TRANSFER / PROCEDURES",
        "value": "dailyRound_tests_referrals"
    },
    {
        "label": "DISCHARGE PLANNING",
        "value": "dailyRound_discharge_planning"
    }
    ]

    const communications = [{
        "label": "WITH PATIENT",
        "value": "dailyRound_withpatient"
    },
    {
        "label": "FAMILY & FRIENDS",
        "value": "dailyRound_family_friens"
    }
    ]

    const listMedicationAdditional = [{
        "label": "IV FLUIDS",
        "value": "dailyRound_additional_iv_fluids"
    },
    {
        "label": "BLOOD",
        "value": "dailyRound_additional_blood"
    },
    {
        "label": "O2 PRESCRIBING",
        "value": "dailyRound_additional_o2_prescribing"
    },
    {
        "label": "PATIENT’S REGULAR MEDICATIONS Prescribed",
        "value": "dailyRound_additional_medications"
    },
    {
        "label": "OTHER MEDICINES",
        "value": "dailyRound_additional_other_medicines"
    }
    ]

    const medical_plan = [{
        "label": "NEW TESTS AGREED TODAY",
        "value": "dailyRound_new_tests"
    },
    {
        "label": "REFERRALS TO BE MADE TO OTHER CLINICAL TEAMS",
        "value": "dailyRound_referrals"
    },
    {
        "label": "SENIOR ADVICE",
        "value": "dailyRound_senior_advice"
    },
    {
        "label": "DISCUSS WITH",
        "value": "dailyRound_discuss_with"
    },
    {
        "label": "INFECTION PLAN",
        "value": "dailyRound_infection_plan"
    },
    {
        "label": "AKI PLAN",
        "value": "dailyRound_aki_plan"
    },
    {
        "label": "WARD PROCEDURES",
        "value": "dailyRound_ward_procedures"
    },
    {
        "label": "OTHERS",
        "value": "dailyRound_medical_plan_others"
    }
    ]

    const instruction_nursing = [{
        "label": "DIET",
        "value": "dailyRound_instruction_diet"
    },
    {
        "label": "MEDICATION CHANGES TODAY",
        "value": "dailyRound_instruction_medication_changes"
    },
    {
        "label": "NURSING CARE",
        "value": "dailyRound_instruction_nursing_care"
    },
    {
        "label": "MOBILIZATION",
        "value": "dailyRound_instruction_mobilization"
    },
    {
        "label": "SPECIAL INSTRUCTIONS",
        "value": "dailyRound_instruction_special"
    },
    {
        "label": "FREQUENCY OF ROUTINE OBSERVATIONS",
        "value": "dailyRound_instruction_frequency"
    },
    {
        "label": "OTHER MONITORING REQUIRED",
        "value": "dailyRound_instruction_monitoring"
    },
    {
        "label": "RESUSCITATION/ESCALATION STATUS",
        "value": "dailyRound_instruction_resuscitation"
    },
    {
        "label": "LIKELY CEILING OF CARE IF DETERIORATES",
        "value": "dailyRound_instruction_ceiling"
    },
    {
        "label": "MEDICALLY OPTIMIZED FOR DISCHARGE?",
        "value": "dailyRound_instruction_optimzed"
    },
    {
        "label": "FORWARD PLANNING",
        "value": "dailyRound_instruction_forward_planning"
    },
    {
        "label": "ANY OTHER INSTRUCTIONS",
        "value": "dailyRound_instruction_others"
    }
    ]

    const daycase = [{
        "label": "PROCEDURE",
        "value": "dailyRound_daycase_procedure"
    },
    {
        "label": "GENERAL CONDITION",
        "value": "dailyRound_daycase_general_condition"
    },
    {
        "label": "NEWS2",
        "value": "dailyRound_daycase_news"
    },
    {
        "label": "NEUROVASCULAR STATUS",
        "value": "dailyRound_daycase_neurovascular"
    },
    {
        "label": "PREOP PREPARATION",
        "value": "dailyRound_daycase_preop"
    },
    {
        "label": "POSTOP CHECKS",
        "value": "dailyRound_daycase_postop"
    },
    {
        "label": "DIABETES MANAGEMENT",
        "value": "dailyRound_daycase_diabetes"
    },
    {
        "label": "ANTICOAGULATION MANAGEMENT",
        "value": "dailyRound_daycase_anticoagulation"
    },
    {
        "label": "STEROIDS",
        "value": "dailyRound_daycase_steriods"
    },
    {
        "label": "PAIN CONTROL",
        "value": "dailyRound_daycase_pain_control"
    },
    {
        "label": "NAUSEA OR VOMITING",
        "value": "dailyRound_daycase_nausea"
    },
    {
        "label": "DIET & FLUIDS",
        "value": "dailyRound_daycase_diet_fluids"
    },
    {
        "label": "URINE OUTPUT",
        "value": "dailyRound_daycase_urine_output"
    },
    {
        "label": "SPECIALTY SPECIFIC CHECK LIST",
        "value": "dailyRound_daycase_specific_check"
    }
    ]




  //  console.log("dailyround ", dailyround)
   // console.log("dailyrounds ", dailyrounds)
    return (
        <React.Fragment>

            <MetaTags>
                <title>Ward</title>
            </MetaTags>
            <div className='ward '>
                <div className="main">
                    <div className="wrapper">
                        {/* <div className='row mt-20'>
                            <a onClick={(e) => redirectLink(e, 'wlist')} className="icon_link pull-right"><span className="material-icons">
                                arrow_back_ios
                            </span> Back</a>
                        </div> */}
                        <div className=" out_patient_section out_patient_details mb-0">

                            <PatientBlock patient={clinicPatient} accordion={true} />
                            <WardMenus />


                        </div>
                    </div>

                    {verifiedStatus && <div className="wrapper verified">
      
                        <div className="alert alert-success">
                            <h3> <span class="material-icons icon"> check_circle </span> Verified Successfully</h3> 
                            <button className="alert_close" data-dismiss="alert" aria-label="close"><span class="material-icons icon"> close </span></button>
                        </div>
                    </div>}

                    <div className="wrapper">
                        {/* <div className='prevnext'>
                            <button className='btn btn-warning' onClick={(e) => { prevDate(e, "prev") }}>Prev</button>
                            <h6>{previousDate} &nbsp;</h6>
                            <button className='btn btn-success' onClick={(e) => { prevDate(e, "next") }}>Next</button>
                        </div> */}
                        <div className="tbl_block">
                            <div className='title_hed'>
                                <h3 onClick={(e) => { redirectLink(e, 'news') }}>NEWS2 </h3>
                                {/*<div className='right'>
                                    <span className='date'>{previousDate} &nbsp;</span>
                                    <div className="btn-group">
                                        <button className='btn material-icons' onClick={(e) => { prevDate(e, "prev") }}>chevron_left</button>
                                        <button className='btn material-icons' onClick={(e) => { prevDate(e, "next") }}>navigate_next</button>

                                    </div>

                                </div>*/}
                            </div>


                            <div className="patients_table">

                                <div className='sidebarNews'>
                                    <ul>
                                        <li className='hed'>NORMAL</li>
                                        <li>Respirations (Breath/Min)</li>
                                        <li>O2 Sat (%) (Scale 1 / 2)</li>
                                        <li>Air or Oxygen?</li>
                                        <li>Systolic BP (mm Hg)</li>
                                        <li>Heart Rate (BPM)</li>
                                        <li>Consciousness</li>
                                        <li>Body Temp 0C</li>
                                        <li>News Frequency</li>
                                        <li>News</li>
                                    </ul>

                                </div>



                                <div className='news_result'>
                                    <div className='news_result_wrp '>
                                        {news && news.length > 0 && news.map((item, i) => {
                                            const scoreValue = item.scoreValue;
                                            let btnvalue = ""
                                            if (scoreValue >= 1 && scoreValue < 4) {
                                                btnvalue = "newslow"
                                            } else if (scoreValue >= 5 && scoreValue < 7) {
                                                btnvalue = "newsmedium"
                                            } else if (scoreValue > 7) {
                                                btnvalue = "newshigh"
                                            }

                                            return (
                                                <ul className='news_item' key={i}>
                                                    <li className='hed bgwhite'>{item.created && Moment(item.created).format("MMM Do YYYY, HH:mm")}</li>
                                                    <li>{item.respiratory} / min</li>
                                                    <li>{item.scale} / {item.scale1} {item.scale2} %</li>
                                                    <li>{item.air_oxygen} {item.air_oxygen == "On O2" ? item.oxygen_mask : ""}</li>
                                                    <li>{item.bp_lying_systolic} / {item.bp_lying_diastolic}</li>
                                                    <li>{item.heart_rate} / min</li>
                                                    <li>&nbsp;</li>
                                                    <li>{item.temperature}C</li>
                                                    <li>{item.comments}</li>
                                                    <li className={btnvalue}> <div className="newstext_btn">News {scoreValue}</div></li>
                                                </ul>

                                            )
                                        })}
                                    </div> </div>


                            </div>
                        </div>

                        <div className="tbl_block">
                            <div className='title_hed'>
                                <h3>Observations </h3>

                            </div>


                            <div className="patients_table">
                                <div className='sidebarNews'>
                                    <ul>
                                        <li className='hed'>NORMAL</li>
                                        <li>FLUID BALANCE (-+)</li>
                                        <li>AnCVPU</li>
                                        <li className='headcol'>
                                            NEURO VASCULAR
                                            <div className="sub_title"> <span>Check for signs of compartment</span></div>
                                            <div className="sub_title"> <span>syndrome</span></div>
                                        </li>
                                        <li className='headcol'>
                                            PAIN
                                            <div className="sub_title"> <span>PAIN Score: 0 1 2 3 4 5 6 7 8 9 10</span></div>
                                            <div className="sub_title"> <span>Location:</span></div>
                                        </li>
                                        <li className='headcol'>
                                            NAUSEA & VOMITING
                                            <div className="sub_title"> <span>No nausea or vomiting</span></div>
                                            <div className="sub_title"> <span>Location:</span></div>
                                        </li>
                                        <li className='headcol'>
                                            BM

                                            <div className="sub_title"> <span>For type 1 patients or patients on </span></div>
                                            <div className="sub_title"> <span>insulin or pre op patients </span></div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="news_result ">
                                    <div className="news_result_wrp ">
                                        <ul className="news_item ">
                                            <li className="hed bgwhite">15:31</li>
                                            <li>8 / min</li>
                                            <li>SCALE 1 / 84  %</li>
                                            <li className='headcol'>Air </li>
                                            <li className='headcol'>118 / 134</li>
                                            <li className='headcol'>101 / min</li>
                                            <li className='headcol'>101 / min</li>
                                        </ul>
                                        <ul className="news_item ">
                                            <li className="hed bgwhite">14:31</li>
                                            <li>8 / min</li>
                                            <li>SCALE 1 / 84  %</li>
                                            <li className='headcol'>Air </li>
                                            <li className='headcol'>118 / 134</li>
                                            <li className='headcol'>101 / min</li>
                                            <li className='headcol'>101 / min</li>
                                        </ul>
                                        <ul className="news_item ">
                                            <li className="hed bgwhite">15:31</li>
                                            <li>8 / min</li>
                                            <li>SCALE 1 / 84  %</li>
                                            <li className='headcol'>Air </li>
                                            <li className='headcol'>118 / 134</li>
                                            <li className='headcol'>101 / min</li>
                                            <li className='headcol'>101 / min</li>
                                        </ul>
                                        <ul className="news_item ">
                                            <li className="hed bgwhite">14:31</li>
                                            <li>8 / min</li>
                                            <li>SCALE 1 / 84  %</li>
                                            <li className='headcol'>Air </li>
                                            <li className='headcol'>118 / 134</li>
                                            <li className='headcol'>101 / min</li>
                                            <li className='headcol'>101 / min</li>
                                        </ul>
                                        <ul className="news_item ">
                                            <li className="hed bgwhite">14:31</li>
                                            <li>8 / min</li>
                                            <li>SCALE 1 / 84  %</li>
                                            <li className='headcol'>Air </li>
                                            <li className='headcol'>118 / 134</li>
                                            <li className='headcol'>101 / min</li>
                                            <li className='headcol'>101 / min</li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>



                        <div className="tbl_block">
                            <h2 className="tbl_hed">
                                <div className="left">INVESTIGATIONS FLOW SHEET
                                    <Link to='ice' className="tbl_btn">ICE</Link>
                                    <Link to='pacs' className="tbl_btn">PACS</Link>
                                </div>
                                <div className="right"><a href="#" className="tbl_btn">Day of Admission</a></div>
                            </h2>
                            <div className="row m-0">
                                <div className="col-sm-6 col1">
                                    Full Blood Count
                                </div>
                                <div className="col-sm-6 col2">


                                    {clinicPatient?.ice_fullbloodcount_result &&
                                        <div className="image_thumbs">

                                            <div className="img_thumb" data-toggle="modal" data-target={"#ice_fullbloodcountdialog"}>
                                                <span className="material-icons zoom_out"> zoom_out_map </span>
                                                <img src={clinicPatient.ice_fullbloodcount_result} className="img" />

                                                <div className="modal fade" id="ice_fullbloodcountdialog" role="dialog"
                                                    aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                                    <div className="popup_full expo modal-dialog" role="document">
                                                        <div className="modal-content">
                                                            <div className="modal-body">
                                                            <span className="material-icons close_btn" data-dismiss="modal" aria-label="Close"> close </span>
                                                                <img data-toggle="modal" data-target="ice_fullbloodcountdialog"
                                                                    src={clinicPatient.ice_fullbloodcount_result} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>}

                                </div>
                                <div className="col-sm-6 col1">
                                    Renal function & Electrolytes
                                </div>
                                <div className="col-sm-6 col2">
                                    {clinicPatient?.ice_renal_electrolytes_result &&
                                        <div className="image_thumbs">

                                            <div className="img_thumb" data-toggle="modal" data-target={"#ice_renal_electrolytesdialog"}>
                                                <span className="material-icons zoom_out"> zoom_out_map </span>
                                                <img src={clinicPatient.ice_renal_electrolytes_result} className="img" />

                                                <div className="modal fade" id="ice_renal_electrolytesdialog" role="dialog"
                                                    aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                                    <div className="popup_full expo modal-dialog" role="document">
                                                        <div className="modal-content">
                                                            <div className="modal-body">
                                                            <span className="material-icons close_btn" data-dismiss="modal" aria-label="Close"> close </span>
                                                                <img data-toggle="modal" data-target="ice_renal_electrolytesdialog"
                                                                    src={clinicPatient.ice_renal_electrolytes_result} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>}
                                </div>
                                <div className="col-sm-6 col1">
                                    Clotting Profile
                                </div>
                                <div className="col-sm-6 col2">


                                    {clinicPatient?.ice_clotting_profile_result &&
                                        <div className="image_thumbs">

                                            <div className="img_thumb" data-toggle="modal" data-target={"#ice_clotting_profile_dialog"}>
                                                <span className="material-icons zoom_out"> zoom_out_map </span>
                                                <img src={clinicPatient.ice_clotting_profile_result} className="img" />

                                                <div className="modal fade" id="ice_clotting_profile_dialog" role="dialog"
                                                    aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                                    <div className="popup_full expo modal-dialog" role="document">
                                                        <div className="modal-content">
                                                            <div className="modal-body">
                                                            <span className="material-icons close_btn" data-dismiss="modal" aria-label="Close"> close </span>
                                                                <img data-toggle="modal" data-target="ice_clotting_profile_dialog"
                                                                    src={clinicPatient.ice_clotting_profile_result} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>}

                                </div>
                                <div className="col-sm-6 col1">
                                    Liver Function Tests
                                </div>
                                <div className="col-sm-6 col2">

                                    {clinicPatient?.ice_liver_function_test_result &&
                                        <div className="image_thumbs">

                                            <div className="img_thumb" data-toggle="modal" data-target={"#ice_liver_function_test_dialog"}>
                                                <span className="material-icons zoom_out"> zoom_out_map </span>
                                                <img src={clinicPatient.ice_liver_function_test_result} className="img" />

                                                <div className="modal fade" id="ice_liver_function_test_dialog" role="dialog"
                                                    aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                                    <div className="popup_full expo modal-dialog" role="document">
                                                        <div className="modal-content">
                                                            <div className="modal-body">
                                                            <span className="material-icons close_btn" data-dismiss="modal" aria-label="Close"> close </span>
                                                                <img data-toggle="modal" data-target="ice_liver_function_test_dialog"
                                                                    src={clinicPatient.ice_liver_function_test_result} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>}
                                </div>

                                <div className="col-sm-6 col1">
                                    Microbiology
                                </div>
                                <div className="col-sm-6 col2">
                                    {clinicPatient?.ice_microbiology_result &&
                                        <div className="image_thumbs">

                                            <div className="img_thumb" data-toggle="modal" data-target={"#ice_microbiology_dialog"}>
                                                <span className="material-icons zoom_out"> zoom_out_map </span>
                                                <img src={clinicPatient.ice_microbiology_result} className="img" />

                                                <div className="modal fade" id="ice_microbiology_dialog" role="dialog"
                                                    aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                                    <div className="popup_full expo modal-dialog" role="document">
                                                        <div className="modal-content">
                                                            <div className="modal-body">
                                                            <span className="material-icons close_btn" data-dismiss="modal" aria-label="Close"> close </span>
                                                                <img data-toggle="modal" data-target="ice_microbiology_dialog"
                                                                    src={clinicPatient.ice_microbiology_result} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>}

                                </div>
                                <div className="col-sm-6 col1">
                                    Pathology
                                </div>
                                <div className="col-sm-6 col2">
                                    {clinicPatient?.ice_pathology_result &&
                                        <div className="image_thumbs">

                                            <div className="img_thumb" data-toggle="modal" data-target={"#ice_pathology_dialog"}>
                                                <span className="material-icons zoom_out"> zoom_out_map </span>
                                                <img src={clinicPatient.ice_pathology_result} className="img" />

                                                <div className="modal fade" id="ice_pathology_dialog" role="dialog"
                                                    aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                                    <div className="popup_full expo modal-dialog" role="document">
                                                        <div className="modal-content">
                                                            <div className="modal-body">
                                                            <span className="material-icons close_btn" data-dismiss="modal" aria-label="Close"> close </span>
                                                                <img data-toggle="modal" data-target="ice_pathology_dialog"
                                                                    src={clinicPatient.ice_pathology_result} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>}
                                </div>



                                <div className="col-sm-6 col1">
                                    X Ray
                                </div>
                                <div className="col-sm-6 col2">

                                    {clinicPatient?.pacs_xray_result &&
                                        <div className="image_thumbs">

                                            <div className="img_thumb" data-toggle="modal" data-target={"#pacsxraydialog"}>
                                                <span className="material-icons zoom_out"> zoom_out_map </span>
                                                <img src={clinicPatient.pacs_xray_result} className="img" />

                                                <div className="modal fade" id="pacsxraydialog" role="dialog"
                                                    aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                                    <div className="popup_full expo modal-dialog" role="document">
                                                        <div className="modal-content">
                                                            <div className="modal-body">
                                                            <span className="material-icons close_btn" data-dismiss="modal" aria-label="Close"> close </span>
                                                                <img data-toggle="modal" data-target="pacsxraydialog"
                                                                    src={clinicPatient.pacs_xray_result} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>}

                                </div>
                                <div className="col-sm-6 col1">
                                    MRI
                                </div>
                                <div className="col-sm-6 col2">
                                    {clinicPatient?.pacs_mri_result &&
                                        <div className="image_thumbs">

                                            <div className="img_thumb" data-toggle="modal" data-target={"#pacs_mri_dialog"}>
                                                <span className="material-icons zoom_out"> zoom_out_map </span>
                                                <img src={clinicPatient.pacs_mri_result} className="img" />

                                                <div className="modal fade" id="pacs_mri_dialog" role="dialog"
                                                    aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                                    <div className="popup_full expo modal-dialog" role="document">
                                                        <div className="modal-content">
                                                            <div className="modal-body">
                                                            <span className="material-icons close_btn" data-dismiss="modal" aria-label="Close"> close </span>
                                                                <img data-toggle="modal" data-target="pacs_mri_dialog"
                                                                    src={clinicPatient.pacs_mri_result} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>}



                                </div>
                                <div className="col-sm-6 col1">
                                    CT
                                </div>
                                <div className="col-sm-6 col2">
                                    {clinicPatient?.pacs_ct_result &&
                                        <div className="image_thumbs">

                                            <div className="img_thumb" data-toggle="modal" data-target={"#pacs_ct_dialog"}>
                                                <span className="material-icons zoom_out"> zoom_out_map </span>
                                                <img src={clinicPatient.pacs_ct_result} className="img" />

                                                <div className="modal fade" id="pacs_ct_dialog" role="dialog"
                                                    aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                                    <div className="popup_full expo modal-dialog" role="document">
                                                        <div className="modal-content">
                                                            <div className="modal-body">
                                                            <span className="material-icons close_btn" data-dismiss="modal" aria-label="Close"> close </span>
                                                                <img data-toggle="modal" data-target="pacs_ct_dialog"
                                                                    src={clinicPatient.pacs_ct_result} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>}
                                </div>
                                <div className="col-sm-6 col1">
                                    US
                                </div>
                                <div className="col-sm-6 col2">
                                    {clinicPatient?.pacs_us_result &&
                                        <div className="image_thumbs">

                                            <div className="img_thumb" data-toggle="modal" data-target={"#pacs_us_dialog"}>
                                                <span className="material-icons zoom_out"> zoom_out_map </span>
                                                <img src={clinicPatient.pacs_us_result} className="img" />

                                                <div className="modal fade" id="pacs_us_dialog" role="dialog"
                                                    aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                                    <div className="popup_full expo modal-dialog" role="document">
                                                        <div className="modal-content">
                                                            <div className="modal-body">
                                                            <span className="material-icons close_btn" data-dismiss="modal" aria-label="Close"> close </span>
                                                                <img data-toggle="modal" data-target="pacs_us_dialog"
                                                                    src={clinicPatient.pacs_us_result} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>}
                                </div>
                                <div className="col-sm-6 col1">
                                    Others
                                </div>
                                <div className="col-sm-6 col2">
                                    {clinicPatient?.pacs_other_scan_result &&
                                        <div className="image_thumbs">

                                            <div className="img_thumb" data-toggle="modal" data-target={"#pacs_other_scan_dialog"}>
                                                <span className="material-icons zoom_out"> zoom_out_map </span>
                                                <img src={clinicPatient.pacs_other_scan_result} className="img" />

                                                <div className="modal fade" id="pacs_other_scan_dialog" role="dialog"
                                                    aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                                    <div className="popup_full expo modal-dialog" role="document">
                                                        <div className="modal-content">
                                                            <div className="modal-body">
                                                            <span className="material-icons close_btn" data-dismiss="modal" aria-label="Close"> close </span>
                                                                <img data-toggle="modal" data-target="pacs_other_scan_dialog"
                                                                    src={clinicPatient.pacs_other_scan_result} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>}
                                </div>
                                <div className="col-sm-6 col1">
                                    Speciality Specific Tests
                                </div>
                                <div className="col-sm-6 col2">
                                    {clinicPatient?.pacs_speciality_scan_result &&
                                        <div className="image_thumbs">

                                            <div className="img_thumb" data-toggle="modal" data-target={"#pacs_speciality_scan_dialog"}>
                                                <span className="material-icons zoom_out"> zoom_out_map </span>
                                                <img src={clinicPatient.pacs_speciality_scan_result} className="img" />

                                                <div className="modal fade" id="pacs_speciality_scan_dialog" role="dialog"
                                                    aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                                    <div className="popup_full expo modal-dialog" role="document">
                                                        <div className="modal-content">
                                                            <div className="modal-body">
                                                            <span className="material-icons close_btn" data-dismiss="modal" aria-label="Close"> close </span>
                                                                <img data-toggle="modal" data-target="pacs_speciality_scan_dialog"
                                                                    src={clinicPatient.pacs_speciality_scan_result} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>}
                                </div>


                            </div>

                        </div>



                        <div className={(dailyrounds && dailyrounds[0] && dailyrounds[0].date == currentDate && dailyrounds[0].listMedication) ? "tbl_block" : "tbl_block inactiveboard"} >
                            <h2 className="tbl_hed">
                                <div className="left">Medication Review <a href="#" className="tbl_btn">JACS</a></div>
                                {/* <div className="right"><a href="#" className="tbl_btn">Day of Admission</a></div> */}

                                <div className='right'>
                                    <span className='date'>{currentDate} &nbsp;</span>
                                    {/* <div className="btn-group">
                                        <button className='btn material-icons' onClick={(e) => { prevDate(e, "prev") }}>chevron_left</button>
                                        <button className='btn material-icons' onClick={(e) => { prevDate(e, "next") }}>navigate_next</button>

                                    </div> */}

                                </div>

                            </h2>
                            <div className="row m-0">

                                {listMedication && listMedication.length > 0 && listMedication.map((item, i) => {

                                    // console.log("item.value ", item.value, !medication[item.value])

                                    return (!medication[item.value] ? <><div className="col-sm-6 col1" onClick={(e) => { popupInOut(e, [item.value], true) }}>
                                        {item.label}
                                    </div>
                                        <div className="col-sm-6 col2">
                                            {((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"])) &&
                                                <div className="image_thumbs">

                                                    <div className="img_thumb" data-toggle="modal" data-target={"#" + item.value + "dialog"}>
                                                        <span className="material-icons zoom_out"> zoom_out_map </span>
                                                        <img src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} className="img" />

                                                        <div className="modal fade" id={item.value + "dialog"} role="dialog"
                                                            aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                                            <div className="popup_full expo modal-dialog" role="document">
                                                                <div className="modal-content">
                                                                    <div className="modal-body">
                                                                    <span className="material-icons close_btn" data-dismiss="modal" aria-label="Close"> close </span>
                                                                        <img data-toggle="modal" data-target={"#" + item.value + "dialog"}
                                                                            src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>}
                                            {(dailyround && dailyround[item.value]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value])}  &nbsp;
                                            {(dailyround && dailyround[item.value + "_action"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_action"])}
                                        </div></> :
                                        <>
                                            <div className="col-sm-12 col1 " onClick={(e) => { popupInOut(e, [item.value], false) }}>
                                                {item.label}
                                            </div>
                                            <div className="col-sm-6 col">
                                                <div className=" dsp_flex">
                                                    <div className=" form_btns">
                                                        <p className="red_text">Notable Changes</p>
                                                        <a onClick={(e) => { setVisibleSpeechtotext(true), setspeechtextname([item.value]) }} className="icon_btn"><span className="material-symbols-outlined">
                                                            mic
                                                        </span> Record</a>
                                                        {/* <a href="#" className="icon_btn"> <span className="material-symbols-outlined">
                                        photo_camera
                                    </span> Camera</a> */}
                                                        <div className="icon_btn input_btn"> <input className="custom-file-input" type="file" onChange={(e) => handleFile(e, [item.value])} /> </div>
                                                    </div>
                                                </div>
                                                {((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"])) &&
                                                    <div className="image_thumbs">

                                                        <div className="img_thumb" data-toggle="modal" data-target="#exampleModalLong">
                                                            <span className="material-icons zoom_out"> zoom_out_map </span>
                                                            <img src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} className="img" />

                                                            <div className="modal fade" id="exampleModalLong" role="dialog"
                                                                aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                                                <div className="popup_full expo modal-dialog" role="document">
                                                                    <div className="modal-content">
                                                                        <div className="modal-body">
                                                                        <span className="material-icons close_btn" data-dismiss="modal" aria-label="Close"> close </span>
                                                                            <img data-toggle="modal" data-target="#exampleModalLong"
                                                                                src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>}

                                                <div className="form_group">

                                                    <input className="textarea" onChange={(e) => updateInputValue(e)} name={item.value} value={(dailyround && dailyround[item.value]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value])} />
                                                </div>

                                            </div>

                                            <div className="col-sm-6 col">
                                                <div className=" form_btns">
                                                    <p className="red_text">Actions Needed</p>
                                                    <a onClick={(e) => { setVisibleSpeechtotext(true), setspeechtextname([item.value + "_action"]) }} className="icon_btn"><span className="material-symbols-outlined"> mic </span> Record</a>
                                                </div>
                                                <div className="form_group">
                                                    <input className="textarea" onChange={(e) => updateInputValue(e)} name={item.value + "_action"} value={(dailyround && dailyround[item.value + "_action"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_action"])} />


                                                </div>
                                                {/* <button className='btn' onClick={(e) => updateDRData(e)}>Save</button> */}
                                            </div>

                                        </>)
                                })}

                            </div>
                            <div className="data_row">
                                <div className="col1">GENERAL REVIEW</div>
                                <div className="col2">
                                    Medications reviewed today and checked for the following


                                    {(dailyround.general_review) || (dailyrounds && dailyrounds[0] && dailyround.general_review != false && dailyrounds[0].general_review) ?
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input type="radio"
                                                value={(dailyround.general_review) || (dailyrounds && dailyrounds[0] && dailyrounds[0].general_review)} checked /><span>{(dailyround.general_review) || (dailyrounds && dailyrounds[0] && dailyrounds[0].general_review)} </span></label>
                                            </div> {((dailyround.general_review) || (dailyrounds && dailyrounds[0] && dailyrounds[0].general_review)) && <a onClick={(e) => updateInputValues(e, "general_review", false)}><span className="material-symbols-outlined"> reply
                                            </span></a>}

                                        </div>
                                        :
                                        <div className="select_btns popup_select  mt-10 mb-0">
                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="general_review" type="radio" value="Missed Doses" /><span>Missed Doses</span></label></div>

                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="general_review" type="radio" value="Converted to Oral" /><span>Converted to Oral</span></label></div>

                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="general_review" type="radio" value="Any medicines to be discontinued" /><span>Any
                                                medicines to be discontinued</span></label></div>

                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="general_review" type="radio" value="Dose adjustments for renal" /><span>Dose
                                                adjustments for renal</span></label></div>

                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="general_review" type="radio" value="Hepatic organ dysfunction" /><span>Hepatic
                                                organ dysfunction</span></label></div>

                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="general_review" type="radio" value="Changed" /><span>Changed</span></label></div>

                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="general_review" type="radio" value="Stopped" /><span>Stopped</span></label></div>

                                            <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="general_review" type="radio" value="Adjusted" /><span>Adjusted</span></label></div>

                                        </div>}


                                    {/* <div className="select_btns popup_select mt-10 mb-0">
                                        <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="general_review" type="radio" value="Missed Doses" /><span>Missed Doses</span></label></div>
                                    </div>
                                    <div className="select_btns popup_select m-0">
                                        <div className="select_btn"><label><input name="site" type="radio"
                                            value="Site 1" /><span>Converted to Oral</span></label></div>
                                        <div className="select_btn"><label><input name="site" type="radio" value="Site 1" /><span>Any
                                            medicines to be discontinued</span></label></div>
                                    </div>
                                    <div className="select_btns popup_select m-0">
                                        <div className="select_btn"><label><input name="site" type="radio" value="Site 1" /><span>Dose
                                            adjustments for renal</span></label></div>
                                        <div className="select_btn"><label><input name="site" type="radio"
                                            value="Site 1" /><span>Hepatic
                                                organ dysfunction</span></label></div>
                                    </div>
                                    <div className="select_btns popup_select m-0">
                                        <div className="select_btn"><label><input name="site" type="radio"
                                            value="Site 1" /><span>Changed</span></label></div>
                                        <div className="select_btn"><label><input name="site" type="radio"
                                            value="Site 1" /><span>Stopped</span></label></div>
                                        <div className="select_btn"><label><input name="site" type="radio"
                                            value="Site 1" /><span>Adjusted</span></label></div>
                                    </div> */}
                                    <div className="yn_switch  mt-10">
                                        <div className="label-container">
                                            <p>Any Drug levels needed</p>
                                        </div>
                                        <label className="switch-container">
                                            <input type="checkbox" className="switch_check" name="anydrug_level" onChange={(e) => handleChange(e)} checked={(dailyround.anydrug_level) || (dailyrounds && dailyrounds[0] && dailyround.anydrug_level != false && dailyrounds[0].anydrug_level)} />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>YES</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>NO</span>
                                            </div>
                                        </label>


                                    </div>
                                    <div className="yn_switch  mt-10">
                                        <div className="label-container">
                                            <p>Polypharmacy</p>
                                        </div>
                                        <label className="switch-container">
                                            <input type="checkbox" className="switch_check" name="polypharmacy" onChange={(e) => handleChange(e)} checked={(dailyround.polypharmacy) || (dailyrounds && dailyrounds[0] && dailyround.polypharmacy != false && dailyrounds[0].polypharmacy)} />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>YES</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>NO</span>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="row m-0">
                                <div className="col-sm-12 col1"> <strong>ADDITIONAL</strong> </div>

                                {listMedicationAdditional && listMedicationAdditional.length > 0 && listMedicationAdditional.map((item, i) => {

                                    // console.log("item.value ", item.value, !medication[item.value])

                                    return (!medication[item.value] ? <><div className="col-sm-6 col1" onClick={(e) => { popupInOut(e, [item.value], true) }}>
                                        {item.label}
                                    </div>
                                        <div className="col-sm-6 col2">
                                            {((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"])) &&
                                                <div className="image_thumbs">

                                                    <div className="img_thumb" data-toggle="modal" data-target={"#" + item.value + "dialog"}>
                                                        <span className="material-icons zoom_out"> zoom_out_map </span>
                                                        <img src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} className="img" />

                                                        <div className="modal fade" id={item.value + "dialog"} role="dialog"
                                                            aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                                            <div className="modal-dialog" role="document">
                                                                <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <h5 className="modal-title">Preview</h5>
                                                                        <span className="material-icons" data-dismiss="modal"
                                                                            aria-label="Close">
                                                                            close
                                                                        </span>
                                                                    </div>
                                                                    <div className="modal-body">
                                                                        <img data-toggle="modal" data-target={"#" + item.value + "dialog"}
                                                                            src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>}
                                            {(dailyround && dailyround[item.value]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value])}  &nbsp;
                                            {(dailyround && dailyround[item.value + "_action"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_action"])}
                                        </div></> :
                                        <>
                                            <div className="col-sm-12 col1 " onClick={(e) => { popupInOut(e, [item.value], false) }}>
                                                {item.label}
                                            </div>
                                            <div className="col-sm-6 col">
                                                <div className=" dsp_flex">
                                                    <div className=" form_btns">
                                                        <p className="red_text">Notable Changes</p>
                                                        <a onClick={(e) => { setVisibleSpeechtotext(true), setspeechtextname([item.value]) }} className="icon_btn"><span className="material-symbols-outlined">
                                                            mic
                                                        </span> Record</a>
                                                        {/* <a href="#" className="icon_btn"> <span className="material-symbols-outlined">
    photo_camera
</span> Camera</a> */}
                                                        <div className="icon_btn input_btn"> <input className="custom-file-input" type="file" onChange={(e) => handleFile(e, [item.value])} /> </div>
                                                    </div>
                                                </div>
                                                {((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"])) &&
                                                    <div className="image_thumbs">

                                                        <div className="img_thumb" data-toggle="modal" data-target="#exampleModalLong">
                                                            <span className="material-icons zoom_out"> zoom_out_map </span>
                                                            <img src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} className="img" />

                                                            <div className="modal fade" id="exampleModalLong" role="dialog"
                                                                aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                                                <div className="modal-dialog" role="document">
                                                                    <div className="modal-content">
                                                                        <div className="modal-header">
                                                                            <h5 className="modal-title">Preview</h5>
                                                                            <span className="material-icons" data-dismiss="modal"
                                                                                aria-label="Close">
                                                                                close
                                                                            </span>
                                                                        </div>
                                                                        <div className="modal-body">
                                                                            <img data-toggle="modal" data-target="#exampleModalLong"
                                                                                src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>}

                                                <div className="form_group">

                                                    <input className="textarea" onChange={(e) => updateInputValue(e)} name={item.value} value={(dailyround && dailyround[item.value]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value])} />
                                                </div>

                                            </div>

                                            <div className="col-sm-6 col">
                                                <div className=" form_btns">
                                                    <p className="red_text">Actions Needed</p>
                                                    <a onClick={(e) => { setVisibleSpeechtotext(true), setspeechtextname([item.value + "_action"]) }} className="icon_btn"><span className="material-symbols-outlined"> mic </span> Record</a>
                                                </div>
                                                <div className="form_group">
                                                    <input className="textarea" onChange={(e) => updateInputValue(e)} name={item.value + "_action"} value={(dailyround && dailyround[item.value + "_action"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_action"])} />


                                                </div>
                                               
                                            </div>

                                        </>)
                                })}

                               


                                <div className="col-sm-12 col dsp_flex">
                                    <a onClick={(e) => updateDRData(e, "listMedication")} className="verified_btn">VERIFIED</a>
                                </div>
                            </div>
                        </div>


                        <div className={(dailyrounds && dailyrounds[0] && dailyrounds[0].date == currentDate && dailyrounds[0].dailyProgressNotes) ? "tbl_block" : "tbl_block inactiveboard"}>
                            <h2 className="tbl_hed">
                                <div className="left">DAILY PROGRESS NOTES</div>
                                <div className="right"><a href="#" className="tbl_btn">Day of Admission</a></div>
                            </h2>
                            <div className="row m-0">
                                <div className="col-sm-12 col dsp_flex">
                                    <h3 className="sub_title2">Ward Round Team</h3>
                                </div>
                                <div className="col-sm-6 col">
                                    <div className="form-group">
                                        <input type="text" placeholder="Con" className="form-control" />
                                    </div>
                                    <div className="form-group">
                                        <input type="text" placeholder="Jr Doctor" className="form-control" />
                                    </div>
                                </div>
                                <div className="col-sm-6 col ">
                                    <div className="form-group">
                                        <input type="text" placeholder="Reg" className="form-control" />
                                    </div>
                                    <div className="form-group">
                                        <input type="text" placeholder="Nurse" className="form-control" />
                                    </div>
                                </div>

                            </div>
                            <div className="row m-0">

                                {dailyProgressNotes && dailyProgressNotes.length > 0 && dailyProgressNotes.map((item, i) => {

                                    // console.log("item.value ", item.value, !medication[item.value])

                                    return (!medication[item.value] ? <><div className="col-sm-6 col1" onClick={(e) => { popupInOut(e, [item.value], true) }}>
                                        {item.label}
                                    </div>
                                        <div className="col-sm-6 col2">
                                            {((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"])) &&
                                                <div className="image_thumbs">

                                                    <div className="img_thumb" data-toggle="modal" data-target={"#" + item.value + "dialog"}>
                                                        <span className="material-icons zoom_out"> zoom_out_map </span>
                                                        <img src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} className="img" />

                                                        <div className="modal fade" id={item.value + "dialog"} role="dialog"
                                                            aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                                            <div className="modal-dialog" role="document">
                                                                <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <h5 className="modal-title">Preview</h5>
                                                                        <span className="material-icons" data-dismiss="modal"
                                                                            aria-label="Close">
                                                                            close
                                                                        </span>
                                                                    </div>
                                                                    <div className="modal-body">
                                                                        <img data-toggle="modal" data-target={"#" + item.value + "dialog"}
                                                                            src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>}
                                            {(dailyround && dailyround[item.value]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value])}  &nbsp;
                                            {(dailyround && dailyround[item.value + "_action"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_action"])}
                                        </div></> :
                                        <>
                                            <div className="col-sm-12 col1 " onClick={(e) => { popupInOut(e, [item.value], false) }}>
                                                {item.label}
                                            </div>
                                            <div className="col-sm-6 col">
                                                <div className=" dsp_flex">
                                                    <div className=" form_btns">
                                                        <p className="red_text">Notable Changes</p>
                                                        <a onClick={(e) => { setVisibleSpeechtotext(true), setspeechtextname([item.value]) }} className="icon_btn"><span className="material-symbols-outlined">
                                                            mic
                                                        </span> Record</a>
                                                        {/* <a href="#" className="icon_btn"> <span className="material-symbols-outlined">
    photo_camera
</span> Camera</a> */}
                                                        <div className="icon_btn input_btn"> <input className="custom-file-input" type="file" onChange={(e) => handleFile(e, [item.value])} /> </div>
                                                    </div>
                                                </div>
                                                {((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"])) &&
                                                    <div className="image_thumbs">

                                                        <div className="img_thumb" data-toggle="modal" data-target="#exampleModalLong">
                                                            <span className="material-icons zoom_out"> zoom_out_map </span>
                                                            <img src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} className="img" />

                                                            <div className="modal fade" id="exampleModalLong" role="dialog"
                                                                aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                                                <div className="modal-dialog" role="document">
                                                                    <div className="modal-content">
                                                                        <div className="modal-header">
                                                                            <h5 className="modal-title">Preview</h5>
                                                                            <span className="material-icons" data-dismiss="modal"
                                                                                aria-label="Close">
                                                                                close
                                                                            </span>
                                                                        </div>
                                                                        <div className="modal-body">
                                                                            <img data-toggle="modal" data-target="#exampleModalLong"
                                                                                src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>}

                                                <div className="form_group">

                                                    <input className="textarea" onChange={(e) => updateInputValue(e)} name={item.value} value={(dailyround && dailyround[item.value]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value])} />
                                                </div>

                                            </div>

                                            <div className="col-sm-6 col">
                                                <div className=" form_btns">
                                                    <p className="red_text">Actions Needed</p>
                                                    <a onClick={(e) => { setVisibleSpeechtotext(true), setspeechtextname([item.value + "_action"]) }} className="icon_btn"><span className="material-symbols-outlined"> mic </span> Record</a>
                                                </div>
                                                <div className="form_group">
                                                    <input className="textarea" onChange={(e) => updateInputValue(e)} name={item.value + "_action"} value={(dailyround && dailyround[item.value + "_action"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_action"])} />


                                                </div>
                                                {/* <button className='btn' onClick={(e) => updateDRData(e)}>Save</button> */}
                                            </div>

                                        </>)
                                })}



                                <div className="col-sm-12 col dsp_flex">
                                    <a onClick={(e) => updateDRData(e, "dailyProgressNotes")} className="verified_btn">VERIFIED</a>
                                </div>
                            </div>
                        </div>

                        <div className={(dailyrounds && dailyrounds[0] && dailyrounds[0].date == currentDate && dailyrounds[0].observationsReview) ? "tbl_block" : "tbl_block inactiveboard"}>
                            <h2 className="tbl_hed">
                                <div className="left">OBSERVATIONS REVIEW</div>
                                <div className="right"><a href="#" className="tbl_btn">Day of Admission</a></div>
                            </h2>

                            <div className="row m-0">
                                {observationsReview && observationsReview.length > 0 && observationsReview.map((item, i) => {

                                    // console.log("item.value ", item.value, !medication[item.value])

                                    return (!medication[item.value] ? <><div className="col-sm-6 col1" onClick={(e) => { popupInOut(e, [item.value], true) }}>
                                        {item.label}
                                    </div>
                                        <div className="col-sm-6 col2">
                                            {((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"])) &&
                                                <div className="image_thumbs">

                                                    <div className="img_thumb" data-toggle="modal" data-target={"#" + item.value + "dialog"}>
                                                        <span className="material-icons zoom_out"> zoom_out_map </span>
                                                        <img src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} className="img" />

                                                        <div className="modal fade" id={item.value + "dialog"} role="dialog"
                                                            aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                                            <div className="modal-dialog" role="document">
                                                                <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <h5 className="modal-title">Preview</h5>
                                                                        <span className="material-icons" data-dismiss="modal"
                                                                            aria-label="Close">
                                                                            close
                                                                        </span>
                                                                    </div>
                                                                    <div className="modal-body">
                                                                        <img data-toggle="modal" data-target={"#" + item.value + "dialog"}
                                                                            src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>}
                                            {(dailyround && dailyround[item.value]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value])}  &nbsp;
                                            {(dailyround && dailyround[item.value + "_action"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_action"])}
                                        </div></> :
                                        <>
                                            <div className="col-sm-12 col1 " onClick={(e) => { popupInOut(e, [item.value], false) }}>
                                                {item.label}
                                            </div>
                                            <div className="col-sm-6 col">
                                                <div className=" dsp_flex">
                                                    <div className=" form_btns">
                                                        <p className="red_text">Notable Changes</p>
                                                        <a onClick={(e) => { setVisibleSpeechtotext(true), setspeechtextname([item.value]) }} className="icon_btn"><span className="material-symbols-outlined">
                                                            mic
                                                        </span> Record</a>
                                                        {/* <a href="#" className="icon_btn"> <span className="material-symbols-outlined">
    photo_camera
</span> Camera</a> */}
                                                        <div className="icon_btn input_btn"> <input className="custom-file-input" type="file" onChange={(e) => handleFile(e, [item.value])} /> </div>
                                                    </div>
                                                </div>
                                                {((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"])) &&
                                                    <div className="image_thumbs">

                                                        <div className="img_thumb" data-toggle="modal" data-target="#exampleModalLong">
                                                            <span className="material-icons zoom_out"> zoom_out_map </span>
                                                            <img src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} className="img" />

                                                            <div className="modal fade" id="exampleModalLong" role="dialog"
                                                                aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                                                <div className="modal-dialog" role="document">
                                                                    <div className="modal-content">
                                                                        <div className="modal-header">
                                                                            <h5 className="modal-title">Preview</h5>
                                                                            <span className="material-icons" data-dismiss="modal"
                                                                                aria-label="Close">
                                                                                close
                                                                            </span>
                                                                        </div>
                                                                        <div className="modal-body">
                                                                            <img data-toggle="modal" data-target="#exampleModalLong"
                                                                                src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>}

                                                <div className="form_group">

                                                    <input className="textarea" onChange={(e) => updateInputValue(e)} name={item.value} value={(dailyround && dailyround[item.value]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value])} />
                                                </div>

                                            </div>

                                            <div className="col-sm-6 col">
                                                <div className=" form_btns">
                                                    <p className="red_text">Actions Needed</p>
                                                    <a onClick={(e) => { setVisibleSpeechtotext(true), setspeechtextname([item.value + "_action"]) }} className="icon_btn"><span className="material-symbols-outlined"> mic </span> Record</a>
                                                </div>
                                                <div className="form_group">
                                                    <input className="textarea" onChange={(e) => updateInputValue(e)} name={item.value + "_action"} value={(dailyround && dailyround[item.value + "_action"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_action"])} />


                                                </div>
                                                {/* <button className='btn' onClick={(e) => updateDRData(e)}>Save</button> */}
                                            </div>

                                        </>)
                                })}

                                <div className="col-sm-12 col dsp_flex">
                                    <a onClick={(e) => updateDRData(e, "observationsReview")} className="verified_btn">VERIFIED</a>

                                </div>

                            </div>
                        </div>

                        <div className={(dailyrounds && dailyrounds[0] && dailyrounds[0].date == currentDate && dailyrounds[0].activeproblems) ? "tbl_block" : "tbl_block inactiveboard"}>
                            <h2 className="tbl_hed">
                                <div className="left">ACTIVE PROBLEMS</div>
                                <div className="right"><a href="#" className="tbl_btn">Day of Admission</a></div>
                            </h2>
                            <div className="row m-0">

                                {activeproblems && activeproblems.length > 0 && activeproblems.map((item, i) => {


                                    return (!medication[item.value] ? <><div className="col-sm-6 col1" onClick={(e) => { popupInOut(e, [item.value], true) }}>
                                        {item.label}
                                    </div>
                                        <div className="col-sm-6 col2">
                                            {((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"])) &&
                                                <div className="image_thumbs">

                                                    <div className="img_thumb" data-toggle="modal" data-target={"#" + item.value + "dialog"}>
                                                        <span className="material-icons zoom_out"> zoom_out_map </span>
                                                        <img src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} className="img" />

                                                        <div className="modal fade" id={item.value + "dialog"} role="dialog"
                                                            aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                                            <div className="modal-dialog" role="document">
                                                                <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <h5 className="modal-title">Preview</h5>
                                                                        <span className="material-icons" data-dismiss="modal"
                                                                            aria-label="Close">
                                                                            close
                                                                        </span>
                                                                    </div>
                                                                    <div className="modal-body">
                                                                        <img data-toggle="modal" data-target={"#" + item.value + "dialog"}
                                                                            src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>}
                                            {(dailyround && dailyround[item.value]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value])}  &nbsp;
                                            {(dailyround && dailyround[item.value + "_action"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_action"])}
                                        </div></> :
                                        <>
                                            <div className="col-sm-12 col1 " onClick={(e) => { popupInOut(e, [item.value], false) }}>
                                                {item.label}
                                            </div>
                                            <div className="col-sm-6 col">
                                                <div className=" dsp_flex">
                                                    <div className=" form_btns">
                                                        <p className="red_text">Notable Changes</p>
                                                        <a onClick={(e) => { setVisibleSpeechtotext(true), setspeechtextname([item.value]) }} className="icon_btn"><span className="material-symbols-outlined">
                                                            mic
                                                        </span> Record</a>
                                                        {/* <a href="#" className="icon_btn"> <span className="material-symbols-outlined">
photo_camera
</span> Camera</a> */}
                                                        <div className="icon_btn input_btn"> <input className="custom-file-input" type="file" onChange={(e) => handleFile(e, [item.value])} /> </div>
                                                    </div>
                                                </div>
                                                {((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"])) &&
                                                    <div className="image_thumbs">

                                                        <div className="img_thumb" data-toggle="modal" data-target="#exampleModalLong">
                                                            <span className="material-icons zoom_out"> zoom_out_map </span>
                                                            <img src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} className="img" />

                                                            <div className="modal fade" id="exampleModalLong" role="dialog"
                                                                aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                                                <div className="modal-dialog" role="document">
                                                                    <div className="modal-content">
                                                                        <div className="modal-header">
                                                                            <h5 className="modal-title">Preview</h5>
                                                                            <span className="material-icons" data-dismiss="modal"
                                                                                aria-label="Close">
                                                                                close
                                                                            </span>
                                                                        </div>
                                                                        <div className="modal-body">
                                                                            <img data-toggle="modal" data-target="#exampleModalLong"
                                                                                src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>}

                                                <div className="form_group">

                                                    <input className="textarea" onChange={(e) => updateInputValue(e)} name={item.value} value={(dailyround && dailyround[item.value]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value])} />
                                                </div>

                                            </div>

                                            <div className="col-sm-6 col">
                                                <div className=" form_btns">
                                                    <p className="red_text">Actions Needed</p>
                                                    <a onClick={(e) => { setVisibleSpeechtotext(true), setspeechtextname([item.value + "_action"]) }} className="icon_btn"><span className="material-symbols-outlined"> mic </span> Record</a>
                                                </div>
                                                <div className="form_group">
                                                    <input className="textarea" onChange={(e) => updateInputValue(e)} name={item.value + "_action"} value={(dailyround && dailyround[item.value + "_action"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_action"])} />


                                                </div>
                                                {/* <button className='btn' onClick={(e) => updateDRData(e)}>Save</button> */}
                                            </div>

                                        </>)
                                })}
                                <div className="col-sm-12 col dsp_flex">
                                    <a onClick={(e) => updateDRData(e, "activeproblems")} className="verified_btn">VERIFIED</a>
                                </div>
                            </div>
                        </div>


                        <div className={(dailyrounds && dailyrounds[0] && dailyrounds[0].date == currentDate && dailyrounds[0].preop_preparation) ? "tbl_block" : "tbl_block inactiveboard"}>
                            <h2 className="tbl_hed">
                                <div className="left">PREOP PREPARATION</div>
                                <div className="right"><a href="#" className="tbl_btn">Day of Admission</a></div>
                            </h2>
                            <div className="row m-0">

                                {preop_preparation && preop_preparation.length > 0 && preop_preparation.map((item, i) => {

                                    // console.log("item.value ", item.value, !medication[item.value])

                                    return (!medication[item.value] ? <><div className="col-sm-6 col1" onClick={(e) => { popupInOut(e, [item.value], true) }}>
                                        {item.label}
                                    </div>
                                        <div className="col-sm-6 col2">
                                            {((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"])) &&
                                                <div className="image_thumbs">

                                                    <div className="img_thumb" data-toggle="modal" data-target={"#" + item.value + "dialog"}>
                                                        <span className="material-icons zoom_out"> zoom_out_map </span>
                                                        <img src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} className="img" />

                                                        <div className="modal fade" id={item.value + "dialog"} role="dialog"
                                                            aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                                            <div className="modal-dialog" role="document">
                                                                <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <h5 className="modal-title">Preview</h5>
                                                                        <span className="material-icons" data-dismiss="modal"
                                                                            aria-label="Close">
                                                                            close
                                                                        </span>
                                                                    </div>
                                                                    <div className="modal-body">
                                                                        <img data-toggle="modal" data-target={"#" + item.value + "dialog"}
                                                                            src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>}
                                            {(dailyround && dailyround[item.value]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value])}  &nbsp;
                                            {(dailyround && dailyround[item.value + "_action"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_action"])}
                                        </div></> :
                                        <>
                                            <div className="col-sm-12 col1 " onClick={(e) => { popupInOut(e, [item.value], false) }}>
                                                {item.label}
                                            </div>
                                            <div className="col-sm-6 col">
                                                <div className=" dsp_flex">
                                                    <div className=" form_btns">
                                                        <p className="red_text">Notable Changes</p>
                                                        <a onClick={(e) => { setVisibleSpeechtotext(true), setspeechtextname([item.value]) }} className="icon_btn"><span className="material-symbols-outlined">
                                                            mic
                                                        </span> Record</a>
                                                        {/* <a href="#" className="icon_btn"> <span className="material-symbols-outlined">
    photo_camera
</span> Camera</a> */}
                                                        <div className="icon_btn input_btn"> <input className="custom-file-input" type="file" onChange={(e) => handleFile(e, [item.value])} /> </div>
                                                    </div>
                                                </div>
                                                {((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"])) &&
                                                    <div className="image_thumbs">

                                                        <div className="img_thumb" data-toggle="modal" data-target="#exampleModalLong">
                                                            <span className="material-icons zoom_out"> zoom_out_map </span>
                                                            <img src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} className="img" />

                                                            <div className="modal fade" id="exampleModalLong" role="dialog"
                                                                aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                                                <div className="modal-dialog" role="document">
                                                                    <div className="modal-content">
                                                                        <div className="modal-header">
                                                                            <h5 className="modal-title">Preview</h5>
                                                                            <span className="material-icons" data-dismiss="modal"
                                                                                aria-label="Close">
                                                                                close
                                                                            </span>
                                                                        </div>
                                                                        <div className="modal-body">
                                                                            <img data-toggle="modal" data-target="#exampleModalLong"
                                                                                src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>}

                                                <div className="form_group">

                                                    <input className="textarea" onChange={(e) => updateInputValue(e)} name={item.value} value={(dailyround && dailyround[item.value]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value])} />
                                                </div>

                                            </div>

                                            <div className="col-sm-6 col">
                                                <div className=" form_btns">
                                                    <p className="red_text">Actions Needed</p>
                                                    <a onClick={(e) => { setVisibleSpeechtotext(true), setspeechtextname([item.value + "_action"]) }} className="icon_btn"><span className="material-symbols-outlined"> mic </span> Record</a>
                                                </div>
                                                <div className="form_group">
                                                    <input className="textarea" onChange={(e) => updateInputValue(e)} name={item.value + "_action"} value={(dailyround && dailyround[item.value + "_action"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_action"])} />


                                                </div>
                                                {/* <button className='btn' onClick={(e) => updateDRData(e)}>Save</button> */}
                                            </div>

                                        </>)
                                })}



                                <div className="col-sm-12 col dsp_flex">
                                    <a onClick={(e) => updateDRData(e, "preop_preparation")} className="verified_btn">VERIFIED</a>
                                </div>
                            </div>
                        </div>


                        <div className={(dailyrounds && dailyrounds[0] && dailyrounds[0].date == currentDate && dailyrounds[0].postop_checks) ? "tbl_block" : "tbl_block inactiveboard"}>
                            <h2 className="tbl_hed">
                                <div className="left">POSTOP CHECKS</div>
                                <div className="right"><a href="#" className="tbl_btn">Day of Admission</a></div>
                            </h2>
                            <div className="row m-0">

                                {postop_checks && postop_checks.length > 0 && postop_checks.map((item, i) => {


                                    return (!medication[item.value] ? <><div className="col-sm-6 col1" onClick={(e) => { popupInOut(e, [item.value], true) }}>
                                        {item.label}
                                    </div>
                                        <div className="col-sm-6 col2">
                                            {((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"])) &&
                                                <div className="image_thumbs">

                                                    <div className="img_thumb" data-toggle="modal" data-target={"#" + item.value + "dialog"}>
                                                        <span className="material-icons zoom_out"> zoom_out_map </span>
                                                        <img src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} className="img" />

                                                        <div className="modal fade" id={item.value + "dialog"} role="dialog"
                                                            aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                                            <div className="modal-dialog" role="document">
                                                                <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <h5 className="modal-title">Preview</h5>
                                                                        <span className="material-icons" data-dismiss="modal"
                                                                            aria-label="Close">
                                                                            close
                                                                        </span>
                                                                    </div>
                                                                    <div className="modal-body">
                                                                        <img data-toggle="modal" data-target={"#" + item.value + "dialog"}
                                                                            src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>}
                                            {(dailyround && dailyround[item.value]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value])}  &nbsp;
                                            {(dailyround && dailyround[item.value + "_action"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_action"])}
                                        </div></> :
                                        <>
                                            <div className="col-sm-12 col1 " onClick={(e) => { popupInOut(e, [item.value], false) }}>
                                                {item.label}
                                            </div>
                                            <div className="col-sm-6 col">
                                                <div className=" dsp_flex">
                                                    <div className=" form_btns">
                                                        <p className="red_text">Notable Changes</p>
                                                        <a onClick={(e) => { setVisibleSpeechtotext(true), setspeechtextname([item.value]) }} className="icon_btn"><span className="material-symbols-outlined">
                                                            mic
                                                        </span> Record</a>

                                                        <div className="icon_btn input_btn"> <input className="custom-file-input" type="file" onChange={(e) => handleFile(e, [item.value])} /> </div>
                                                    </div>
                                                </div>
                                                {((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"])) &&
                                                    <div className="image_thumbs">

                                                        <div className="img_thumb" data-toggle="modal" data-target="#exampleModalLong">
                                                            <span className="material-icons zoom_out"> zoom_out_map </span>
                                                            <img src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} className="img" />

                                                            <div className="modal fade" id="exampleModalLong" role="dialog"
                                                                aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                                                <div className="modal-dialog" role="document">
                                                                    <div className="modal-content">
                                                                        <div className="modal-header">
                                                                            <h5 className="modal-title">Preview</h5>
                                                                            <span className="material-icons" data-dismiss="modal"
                                                                                aria-label="Close">
                                                                                close
                                                                            </span>
                                                                        </div>
                                                                        <div className="modal-body">
                                                                            <img data-toggle="modal" data-target="#exampleModalLong"
                                                                                src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>}

                                                <div className="form_group">

                                                    <input className="textarea" onChange={(e) => updateInputValue(e)} name={item.value} value={(dailyround && dailyround[item.value]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value])} />
                                                </div>

                                            </div>

                                            <div className="col-sm-6 col">
                                                <div className=" form_btns">
                                                    <p className="red_text">Actions Needed</p>
                                                    <a onClick={(e) => { setVisibleSpeechtotext(true), setspeechtextname([item.value + "_action"]) }} className="icon_btn"><span className="material-symbols-outlined"> mic </span> Record</a>
                                                </div>
                                                <div className="form_group">
                                                    <input className="textarea" onChange={(e) => updateInputValue(e)} name={item.value + "_action"} value={(dailyround && dailyround[item.value + "_action"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_action"])} />


                                                </div>
                                                {/* <button className='btn' onClick={(e) => updateDRData(e)}>Save</button> */}
                                            </div>

                                        </>)
                                })}



                                <div className="col-sm-12 col dsp_flex">
                                    <a onClick={(e) => updateDRData(e, "postop_checks")} className="verified_btn">VERIFIED</a>
                                </div>

                            </div>
                        </div>


                        <div className={(dailyrounds && dailyrounds[0] && dailyrounds[0].date == currentDate && dailyrounds[0].intake_output) ? "tbl_block" : "tbl_block inactiveboard"}>
                            <h2 className="tbl_hed">
                                <div className="left">INTAKE & OUTPUT</div>
                                <div className="right"><a href="#" className="tbl_btn">Day of Admission</a></div>
                            </h2>
                            <div className="row m-0">

                                {intake_output && intake_output.length > 0 && intake_output.map((item, i) => {


                                    return (!medication[item.value] ? <><div className="col-sm-6 col1" onClick={(e) => { popupInOut(e, [item.value], true) }}>
                                        {item.label}
                                    </div>
                                        <div className="col-sm-6 col2">
                                            {((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"])) &&
                                                <div className="image_thumbs">

                                                    <div className="img_thumb" data-toggle="modal" data-target={"#" + item.value + "dialog"}>
                                                        <span className="material-icons zoom_out"> zoom_out_map </span>
                                                        <img src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} className="img" />

                                                        <div className="modal fade" id={item.value + "dialog"} role="dialog"
                                                            aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                                            <div className="modal-dialog" role="document">
                                                                <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <h5 className="modal-title">Preview</h5>
                                                                        <span className="material-icons" data-dismiss="modal"
                                                                            aria-label="Close">
                                                                            close
                                                                        </span>
                                                                    </div>
                                                                    <div className="modal-body">
                                                                        <img data-toggle="modal" data-target={"#" + item.value + "dialog"}
                                                                            src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>}
                                            {(dailyround && dailyround[item.value]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value])}  &nbsp;
                                            {(dailyround && dailyround[item.value + "_action"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_action"])}
                                        </div></> :
                                        <>
                                            <div className="col-sm-12 col1 " onClick={(e) => { popupInOut(e, [item.value], false) }}>
                                                {item.label}
                                            </div>
                                            <div className="col-sm-6 col">
                                                <div className=" dsp_flex">
                                                    <div className=" form_btns">
                                                        <p className="red_text">Notable Changes</p>
                                                        <a onClick={(e) => { setVisibleSpeechtotext(true), setspeechtextname([item.value]) }} className="icon_btn"><span className="material-symbols-outlined">
                                                            mic
                                                        </span> Record</a>

                                                        <div className="icon_btn input_btn"> <input className="custom-file-input" type="file" onChange={(e) => handleFile(e, [item.value])} /> </div>
                                                    </div>
                                                </div>
                                                {((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"])) &&
                                                    <div className="image_thumbs">

                                                        <div className="img_thumb" data-toggle="modal" data-target="#exampleModalLong">
                                                            <span className="material-icons zoom_out"> zoom_out_map </span>
                                                            <img src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} className="img" />

                                                            <div className="modal fade" id="exampleModalLong" role="dialog"
                                                                aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                                                <div className="modal-dialog" role="document">
                                                                    <div className="modal-content">
                                                                        <div className="modal-header">
                                                                            <h5 className="modal-title">Preview</h5>
                                                                            <span className="material-icons" data-dismiss="modal"
                                                                                aria-label="Close">
                                                                                close
                                                                            </span>
                                                                        </div>
                                                                        <div className="modal-body">
                                                                            <img data-toggle="modal" data-target="#exampleModalLong"
                                                                                src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>}

                                                <div className="form_group">

                                                    <input className="textarea" onChange={(e) => updateInputValue(e)} name={item.value} value={(dailyround && dailyround[item.value]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value])} />
                                                </div>

                                            </div>

                                            <div className="col-sm-6 col">
                                                <div className=" form_btns">
                                                    <p className="red_text">Actions Needed</p>
                                                    <a onClick={(e) => { setVisibleSpeechtotext(true), setspeechtextname([item.value + "_action"]) }} className="icon_btn"><span className="material-symbols-outlined"> mic </span> Record</a>
                                                </div>
                                                <div className="form_group">
                                                    <input className="textarea" onChange={(e) => updateInputValue(e)} name={item.value + "_action"} value={(dailyround && dailyround[item.value + "_action"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_action"])} />


                                                </div>
                                                {/* <button className='btn' onClick={(e) => updateDRData(e)}>Save</button> */}
                                            </div>

                                        </>)
                                })}


                                <div className="col-sm-12 col dsp_flex">
                                    <a onClick={(e) => updateDRData(e, "intake_output")} className="verified_btn">VERIFIED</a>
                                </div>

                            </div>
                        </div>


                        <div className={(dailyrounds && dailyrounds[0] && dailyrounds[0].date == currentDate && dailyrounds[0].chest_abdomen) ? "tbl_block" : "tbl_block inactiveboard"}>
                            <h2 className="tbl_hed">
                                <div className="left">CHEST, ABDOMEN, DVT/PE </div>
                                <div className="right"><a href="#" className="tbl_btn">Day of Admission</a></div>
                            </h2>
                            <div className="row m-0">

                                {chest_abdomen && chest_abdomen.length > 0 && chest_abdomen.map((item, i) => {


                                    return (!medication[item.value] ? <><div className="col-sm-6 col1" onClick={(e) => { popupInOut(e, [item.value], true) }}>
                                        {item.label}
                                    </div>
                                        <div className="col-sm-6 col2">
                                            {((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"])) &&
                                                <div className="image_thumbs">

                                                    <div className="img_thumb" data-toggle="modal" data-target={"#" + item.value + "dialog"}>
                                                        <span className="material-icons zoom_out"> zoom_out_map </span>
                                                        <img src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} className="img" />

                                                        <div className="modal fade" id={item.value + "dialog"} role="dialog"
                                                            aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                                            <div className="modal-dialog" role="document">
                                                                <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <h5 className="modal-title">Preview</h5>
                                                                        <span className="material-icons" data-dismiss="modal"
                                                                            aria-label="Close">
                                                                            close
                                                                        </span>
                                                                    </div>
                                                                    <div className="modal-body">
                                                                        <img data-toggle="modal" data-target={"#" + item.value + "dialog"}
                                                                            src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>}
                                            {(dailyround && dailyround[item.value]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value])}  &nbsp;
                                            {(dailyround && dailyround[item.value + "_action"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_action"])}
                                        </div></> :
                                        <>
                                            <div className="col-sm-12 col1 " onClick={(e) => { popupInOut(e, [item.value], false) }}>
                                                {item.label}
                                            </div>
                                            <div className="col-sm-6 col">
                                                <div className=" dsp_flex">
                                                    <div className=" form_btns">
                                                        <p className="red_text">Notable Changes</p>
                                                        <a onClick={(e) => { setVisibleSpeechtotext(true), setspeechtextname([item.value]) }} className="icon_btn"><span className="material-symbols-outlined">
                                                            mic
                                                        </span> Record</a>

                                                        <div className="icon_btn input_btn"> <input className="custom-file-input" type="file" onChange={(e) => handleFile(e, [item.value])} /> </div>
                                                    </div>
                                                </div>
                                                {((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"])) &&
                                                    <div className="image_thumbs">

                                                        <div className="img_thumb" data-toggle="modal" data-target="#exampleModalLong">
                                                            <span className="material-icons zoom_out"> zoom_out_map </span>
                                                            <img src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} className="img" />

                                                            <div className="modal fade" id="exampleModalLong" role="dialog"
                                                                aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                                                <div className="modal-dialog" role="document">
                                                                    <div className="modal-content">
                                                                        <div className="modal-header">
                                                                            <h5 className="modal-title">Preview</h5>
                                                                            <span className="material-icons" data-dismiss="modal"
                                                                                aria-label="Close">
                                                                                close
                                                                            </span>
                                                                        </div>
                                                                        <div className="modal-body">
                                                                            <img data-toggle="modal" data-target="#exampleModalLong"
                                                                                src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>}

                                                <div className="form_group">

                                                    <input className="textarea" onChange={(e) => updateInputValue(e)} name={item.value} value={(dailyround && dailyround[item.value]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value])} />
                                                </div>

                                            </div>

                                            <div className="col-sm-6 col">
                                                <div className=" form_btns">
                                                    <p className="red_text">Actions Needed</p>
                                                    <a onClick={(e) => { setVisibleSpeechtotext(true), setspeechtextname([item.value + "_action"]) }} className="icon_btn"><span className="material-symbols-outlined"> mic </span> Record</a>
                                                </div>
                                                <div className="form_group">
                                                    <input className="textarea" onChange={(e) => updateInputValue(e)} name={item.value + "_action"} value={(dailyround && dailyround[item.value + "_action"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_action"])} />


                                                </div>
                                                {/* <button className='btn' onClick={(e) => updateDRData(e)}>Save</button> */}
                                            </div>

                                        </>)
                                })}


                                <div className="col-sm-12 col dsp_flex">
                                    <a onClick={(e) => updateDRData(e, "chest_abdomen")} className="verified_btn">VERIFIED</a>
                                </div>

                            </div>
                        </div>

                        <div className={(dailyrounds && dailyrounds[0] && dailyrounds[0].date == currentDate  && dailyrounds[0].infection_checks) ? "tbl_block" : "tbl_block inactiveboard"}>
                            <h2 className="tbl_hed">
                                <div className="left">INFECTION CHECKS </div>
                                <div className="right"><a href="#" className="tbl_btn">Day of Admission</a></div>
                            </h2>
                            <div className="row m-0">

                                {infection_checks && infection_checks.length > 0 && infection_checks.map((item, i) => {


                                    return (!medication[item.value] ? <><div className="col-sm-6 col1" onClick={(e) => { popupInOut(e, [item.value], true) }}>
                                        {item.label}
                                    </div>
                                        <div className="col-sm-6 col2">
                                            {((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"])) &&
                                                <div className="image_thumbs">

                                                    <div className="img_thumb" data-toggle="modal" data-target={"#" + item.value + "dialog"}>
                                                        <span className="material-icons zoom_out"> zoom_out_map </span>
                                                        <img src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} className="img" />

                                                        <div className="modal fade" id={item.value + "dialog"} role="dialog"
                                                            aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                                            <div className="modal-dialog" role="document">
                                                                <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <h5 className="modal-title">Preview</h5>
                                                                        <span className="material-icons" data-dismiss="modal"
                                                                            aria-label="Close">
                                                                            close
                                                                        </span>
                                                                    </div>
                                                                    <div className="modal-body">
                                                                        <img data-toggle="modal" data-target={"#" + item.value + "dialog"}
                                                                            src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>}
                                            {(dailyround && dailyround[item.value]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value])}  &nbsp;
                                            {(dailyround && dailyround[item.value + "_action"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_action"])}
                                        </div></> :
                                        <>
                                            <div className="col-sm-12 col1 " onClick={(e) => { popupInOut(e, [item.value], false) }}>
                                                {item.label}
                                            </div>
                                            <div className="col-sm-6 col">
                                                <div className=" dsp_flex">
                                                    <div className=" form_btns">
                                                        <p className="red_text">Notable Changes</p>
                                                        <a onClick={(e) => { setVisibleSpeechtotext(true), setspeechtextname([item.value]) }} className="icon_btn"><span className="material-symbols-outlined">
                                                            mic
                                                        </span> Record</a>

                                                        <div className="icon_btn input_btn"> <input className="custom-file-input" type="file" onChange={(e) => handleFile(e, [item.value])} /> </div>
                                                    </div>
                                                </div>
                                                {((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"])) &&
                                                    <div className="image_thumbs">

                                                        <div className="img_thumb" data-toggle="modal" data-target="#exampleModalLong">
                                                            <span className="material-icons zoom_out"> zoom_out_map </span>
                                                            <img src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} className="img" />

                                                            <div className="modal fade" id="exampleModalLong" role="dialog"
                                                                aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                                                <div className="modal-dialog" role="document">
                                                                    <div className="modal-content">
                                                                        <div className="modal-header">
                                                                            <h5 className="modal-title">Preview</h5>
                                                                            <span className="material-icons" data-dismiss="modal"
                                                                                aria-label="Close">
                                                                                close
                                                                            </span>
                                                                        </div>
                                                                        <div className="modal-body">
                                                                            <img data-toggle="modal" data-target="#exampleModalLong"
                                                                                src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>}

                                                <div className="form_group">

                                                    <input className="textarea" onChange={(e) => updateInputValue(e)} name={item.value} value={(dailyround && dailyround[item.value]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value])} />
                                                </div>

                                            </div>

                                            <div className="col-sm-6 col">
                                                <div className=" form_btns">
                                                    <p className="red_text">Actions Needed</p>
                                                    <a onClick={(e) => { setVisibleSpeechtotext(true), setspeechtextname([item.value + "_action"]) }} className="icon_btn"><span className="material-symbols-outlined"> mic </span> Record</a>
                                                </div>
                                                <div className="form_group">
                                                    <input className="textarea" onChange={(e) => updateInputValue(e)} name={item.value + "_action"} value={(dailyround && dailyround[item.value + "_action"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_action"])} />


                                                </div>
                                                {/* <button className='btn' onClick={(e) => updateDRData(e)}>Save</button> */}
                                            </div>

                                        </>)
                                })}



                                <div className="col-sm-12 col dsp_flex">
                                    <a onClick={(e) => updateDRData(e, "infection_checks")} className="verified_btn">VERIFIED</a>
                                </div>

                            </div>
                        </div>


                        <div className={(dailyrounds && dailyrounds[0] && dailyrounds[0].date == currentDate  && dailyrounds[0].mobility_wbstatus) ? "tbl_block" : "tbl_block inactiveboard"}>
                            <h2 className="tbl_hed">
                                <div className="left">MOBILITY, WB STATUS </div>
                                <div className="right"><a href="#" className="tbl_btn">Day of Admission</a></div>
                            </h2>
                            <div className="row m-0">

                                {mobility_wbstatus && mobility_wbstatus.length > 0 && mobility_wbstatus.map((item, i) => {


                                    return (!medication[item.value] ? <><div className="col-sm-6 col1" onClick={(e) => { popupInOut(e, [item.value], true) }}>
                                        {item.label}
                                    </div>
                                        <div className="col-sm-6 col2">
                                            {((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"])) &&
                                                <div className="image_thumbs">

                                                    <div className="img_thumb" data-toggle="modal" data-target={"#" + item.value + "dialog"}>
                                                        <span className="material-icons zoom_out"> zoom_out_map </span>
                                                        <img src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} className="img" />

                                                        <div className="modal fade" id={item.value + "dialog"} role="dialog"
                                                            aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                                            <div className="modal-dialog" role="document">
                                                                <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <h5 className="modal-title">Preview</h5>
                                                                        <span className="material-icons" data-dismiss="modal"
                                                                            aria-label="Close">
                                                                            close
                                                                        </span>
                                                                    </div>
                                                                    <div className="modal-body">
                                                                        <img data-toggle="modal" data-target={"#" + item.value + "dialog"}
                                                                            src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>}
                                            {(dailyround && dailyround[item.value]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value])}  &nbsp;
                                            {(dailyround && dailyround[item.value + "_action"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_action"])}
                                        </div></> :
                                        <>
                                            <div className="col-sm-12 col1 " onClick={(e) => { popupInOut(e, [item.value], false) }}>
                                                {item.label}
                                            </div>
                                            <div className="col-sm-6 col">
                                                <div className=" dsp_flex">
                                                    <div className=" form_btns">
                                                        <p className="red_text">Notable Changes</p>
                                                        <a onClick={(e) => { setVisibleSpeechtotext(true), setspeechtextname([item.value]) }} className="icon_btn"><span className="material-symbols-outlined">
                                                            mic
                                                        </span> Record</a>

                                                        <div className="icon_btn input_btn"> <input className="custom-file-input" type="file" onChange={(e) => handleFile(e, [item.value])} /> </div>
                                                    </div>
                                                </div>
                                                {((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"])) &&
                                                    <div className="image_thumbs">

                                                        <div className="img_thumb" data-toggle="modal" data-target="#exampleModalLong">
                                                            <span className="material-icons zoom_out"> zoom_out_map </span>
                                                            <img src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} className="img" />

                                                            <div className="modal fade" id="exampleModalLong" role="dialog"
                                                                aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                                                <div className="modal-dialog" role="document">
                                                                    <div className="modal-content">
                                                                        <div className="modal-header">
                                                                            <h5 className="modal-title">Preview</h5>
                                                                            <span className="material-icons" data-dismiss="modal"
                                                                                aria-label="Close">
                                                                                close
                                                                            </span>
                                                                        </div>
                                                                        <div className="modal-body">
                                                                            <img data-toggle="modal" data-target="#exampleModalLong"
                                                                                src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>}

                                                <div className="form_group">

                                                    <input className="textarea" onChange={(e) => updateInputValue(e)} name={item.value} value={(dailyround && dailyround[item.value]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value])} />
                                                </div>

                                            </div>

                                            <div className="col-sm-6 col">
                                                <div className=" form_btns">
                                                    <p className="red_text">Actions Needed</p>
                                                    <a onClick={(e) => { setVisibleSpeechtotext(true), setspeechtextname([item.value + "_action"]) }} className="icon_btn"><span className="material-symbols-outlined"> mic </span> Record</a>
                                                </div>
                                                <div className="form_group">
                                                    <input className="textarea" onChange={(e) => updateInputValue(e)} name={item.value + "_action"} value={(dailyround && dailyround[item.value + "_action"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_action"])} />


                                                </div>
                                                {/* <button className='btn' onClick={(e) => updateDRData(e)}>Save</button> */}
                                            </div>

                                        </>)
                                })}



                                <div className="col-sm-12 col dsp_flex">
                                    <a onClick={(e) => updateDRData(e, "mobility_wbstatus")} className="verified_btn">VERIFIED</a>
                                </div>

                            </div>
                        </div>

                        <div className={(dailyrounds && dailyrounds[0] && dailyrounds[0].date == currentDate  && dailyrounds[0].speciality_check_list) ? "tbl_block" : "tbl_block inactiveboard"}>
                            <h2 className="tbl_hed">
                                <div className="left">SPECIALTY SPECIFIC CHECK LIST </div>
                                <div className="right"><a href="#" className="tbl_btn">Day of Admission</a></div>
                            </h2>
                            <div className="row m-0">


                                {speciality_check_list && speciality_check_list.length > 0 && speciality_check_list.map((item, i) => {


                                    return (!medication[item.value] ? <><div className="col-sm-6 col1" onClick={(e) => { popupInOut(e, [item.value], true) }}>
                                        {item.label}
                                    </div>
                                        <div className="col-sm-6 col2">
                                            {((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"])) &&
                                                <div className="image_thumbs">

                                                    <div className="img_thumb" data-toggle="modal" data-target={"#" + item.value + "dialog"}>
                                                        <span className="material-icons zoom_out"> zoom_out_map </span>
                                                        <img src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} className="img" />

                                                        <div className="modal fade" id={item.value + "dialog"} role="dialog"
                                                            aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                                            <div className="modal-dialog" role="document">
                                                                <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <h5 className="modal-title">Preview</h5>
                                                                        <span className="material-icons" data-dismiss="modal"
                                                                            aria-label="Close">
                                                                            close
                                                                        </span>
                                                                    </div>
                                                                    <div className="modal-body">
                                                                        <img data-toggle="modal" data-target={"#" + item.value + "dialog"}
                                                                            src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>}
                                            {(dailyround && dailyround[item.value]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value])}  &nbsp;
                                            {(dailyround && dailyround[item.value + "_action"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_action"])}
                                        </div></> :
                                        <>
                                            <div className="col-sm-12 col1 " onClick={(e) => { popupInOut(e, [item.value], false) }}>
                                                {item.label}
                                            </div>
                                            <div className="col-sm-6 col">
                                                <div className=" dsp_flex">
                                                    <div className=" form_btns">
                                                        <p className="red_text">Notable Changes</p>
                                                        <a onClick={(e) => { setVisibleSpeechtotext(true), setspeechtextname([item.value]) }} className="icon_btn"><span className="material-symbols-outlined">
                                                            mic
                                                        </span> Record</a>

                                                        <div className="icon_btn input_btn"> <input className="custom-file-input" type="file" onChange={(e) => handleFile(e, [item.value])} /> </div>
                                                    </div>
                                                </div>
                                                {((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"])) &&
                                                    <div className="image_thumbs">

                                                        <div className="img_thumb" data-toggle="modal" data-target="#exampleModalLong">
                                                            <span className="material-icons zoom_out"> zoom_out_map </span>
                                                            <img src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} className="img" />

                                                            <div className="modal fade" id="exampleModalLong" role="dialog"
                                                                aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                                                <div className="modal-dialog" role="document">
                                                                    <div className="modal-content">
                                                                        <div className="modal-header">
                                                                            <h5 className="modal-title">Preview</h5>
                                                                            <span className="material-icons" data-dismiss="modal"
                                                                                aria-label="Close">
                                                                                close
                                                                            </span>
                                                                        </div>
                                                                        <div className="modal-body">
                                                                            <img data-toggle="modal" data-target="#exampleModalLong"
                                                                                src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>}

                                                <div className="form_group">

                                                    <input className="textarea" onChange={(e) => updateInputValue(e)} name={item.value} value={(dailyround && dailyround[item.value]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value])} />
                                                </div>

                                            </div>

                                            <div className="col-sm-6 col">
                                                <div className=" form_btns">
                                                    <p className="red_text">Actions Needed</p>
                                                    <a onClick={(e) => { setVisibleSpeechtotext(true), setspeechtextname([item.value + "_action"]) }} className="icon_btn"><span className="material-symbols-outlined"> mic </span> Record</a>
                                                </div>
                                                <div className="form_group">
                                                    <input className="textarea" onChange={(e) => updateInputValue(e)} name={item.value + "_action"} value={(dailyround && dailyround[item.value + "_action"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_action"])} />


                                                </div>
                                                {/* <button className='btn' onClick={(e) => updateDRData(e)}>Save</button> */}
                                            </div>

                                        </>)
                                })}

                                <div className="col-sm-12 col dsp_flex">
                                    <a onClick={(e) => updateDRData(e, "speciality_check_list")} className="verified_btn">VERIFIED</a>
                                </div>

                            </div>
                        </div>

                        <div className={(dailyrounds && dailyrounds[0] && dailyrounds[0].date == currentDate && dailyrounds[0].overall) ? "tbl_block" : "tbl_block inactiveboard"}>
                            <h2 className="tbl_hed">
                                <div className="left">OVERALL </div>
                                <div className="right"><a href="#" className="tbl_btn">Day of Admission</a></div>
                            </h2>
                            <div className="row m-0">

                                {overall && overall.length > 0 && overall.map((item, i) => {


                                    return (!medication[item.value] ? <><div className="col-sm-6 col1" onClick={(e) => { popupInOut(e, [item.value], true) }}>
                                        {item.label}
                                    </div>
                                        <div className="col-sm-6 col2">
                                            {((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"])) &&
                                                <div className="image_thumbs">

                                                    <div className="img_thumb" data-toggle="modal" data-target={"#" + item.value + "dialog"}>
                                                        <span className="material-icons zoom_out"> zoom_out_map </span>
                                                        <img src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} className="img" />

                                                        <div className="modal fade" id={item.value + "dialog"} role="dialog"
                                                            aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                                            <div className="modal-dialog" role="document">
                                                                <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <h5 className="modal-title">Preview</h5>
                                                                        <span className="material-icons" data-dismiss="modal"
                                                                            aria-label="Close">
                                                                            close
                                                                        </span>
                                                                    </div>
                                                                    <div className="modal-body">
                                                                        <img data-toggle="modal" data-target={"#" + item.value + "dialog"}
                                                                            src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>}
                                            {(dailyround && dailyround[item.value]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value])}  &nbsp;
                                            {(dailyround && dailyround[item.value + "_action"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_action"])}
                                        </div></> :
                                        <>
                                            <div className="col-sm-12 col1 " onClick={(e) => { popupInOut(e, [item.value], false) }}>
                                                {item.label}
                                            </div>
                                            <div className="col-sm-6 col">
                                                <div className=" dsp_flex">
                                                    <div className=" form_btns">
                                                        <p className="red_text">Notable Changes</p>
                                                        <a onClick={(e) => { setVisibleSpeechtotext(true), setspeechtextname([item.value]) }} className="icon_btn"><span className="material-symbols-outlined">
                                                            mic
                                                        </span> Record</a>

                                                        <div className="icon_btn input_btn"> <input className="custom-file-input" type="file" onChange={(e) => handleFile(e, [item.value])} /> </div>
                                                    </div>
                                                </div>
                                                {((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"])) &&
                                                    <div className="image_thumbs">

                                                        <div className="img_thumb" data-toggle="modal" data-target="#exampleModalLong">
                                                            <span className="material-icons zoom_out"> zoom_out_map </span>
                                                            <img src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} className="img" />

                                                            <div className="modal fade" id="exampleModalLong" role="dialog"
                                                                aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                                                <div className="modal-dialog" role="document">
                                                                    <div className="modal-content">
                                                                        <div className="modal-header">
                                                                            <h5 className="modal-title">Preview</h5>
                                                                            <span className="material-icons" data-dismiss="modal"
                                                                                aria-label="Close">
                                                                                close
                                                                            </span>
                                                                        </div>
                                                                        <div className="modal-body">
                                                                            <img data-toggle="modal" data-target="#exampleModalLong"
                                                                                src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>}

                                                <div className="form_group">

                                                    <input className="textarea" onChange={(e) => updateInputValue(e)} name={item.value} value={(dailyround && dailyround[item.value]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value])} />
                                                </div>

                                            </div>

                                            <div className="col-sm-6 col">
                                                <div className=" form_btns">
                                                    <p className="red_text">Actions Needed</p>
                                                    <a onClick={(e) => { setVisibleSpeechtotext(true), setspeechtextname([item.value + "_action"]) }} className="icon_btn"><span className="material-symbols-outlined"> mic </span> Record</a>
                                                </div>
                                                <div className="form_group">
                                                    <input className="textarea" onChange={(e) => updateInputValue(e)} name={item.value + "_action"} value={(dailyround && dailyround[item.value + "_action"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_action"])} />


                                                </div>
                                                {/* <button className='btn' onClick={(e) => updateDRData(e)}>Save</button> */}
                                            </div>

                                        </>)
                                })}


                                <div className="col-sm-12 col dsp_flex">
                                    <a onClick={(e) => updateDRData(e, "overall")} className="verified_btn">VERIFIED</a>
                                </div>

                            </div>
                        </div>

                        <div className={(dailyrounds && dailyrounds[0] && dailyrounds[0].date == currentDate && dailyrounds[0].riskassesment) ? "tbl_block" : "tbl_block inactiveboard"}>
                            <h2 className="tbl_hed">
                                <div className="left">RISK ASSESSMENTS SUMMARY </div>
                                <div className="right"><a href="#" className="tbl_btn">Day of Admission</a></div>
                            </h2>
                            <div className="row m-0">

                                {riskassesment && riskassesment.length > 0 && riskassesment.map((item, i) => {


                                    return (!medication[item.value] ? <><div className="col-sm-6 col1" onClick={(e) => { popupInOut(e, [item.value], true) }}>
                                        {item.label}
                                    </div>
                                        <div className="col-sm-6 col2">
                                            {((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"])) &&
                                                <div className="image_thumbs">

                                                    <div className="img_thumb" data-toggle="modal" data-target={"#" + item.value + "dialog"}>
                                                        <span className="material-icons zoom_out"> zoom_out_map </span>
                                                        <img src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} className="img" />

                                                        <div className="modal fade" id={item.value + "dialog"} role="dialog"
                                                            aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                                            <div className="modal-dialog" role="document">
                                                                <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <h5 className="modal-title">Preview</h5>
                                                                        <span className="material-icons" data-dismiss="modal"
                                                                            aria-label="Close">
                                                                            close
                                                                        </span>
                                                                    </div>
                                                                    <div className="modal-body">
                                                                        <img data-toggle="modal" data-target={"#" + item.value + "dialog"}
                                                                            src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>}
                                            {(dailyround && dailyround[item.value]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value])}  &nbsp;
                                            {(dailyround && dailyround[item.value + "_action"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_action"])}
                                        </div></> :
                                        <>
                                            <div className="col-sm-12 col1 " onClick={(e) => { popupInOut(e, [item.value], false) }}>
                                                {item.label}
                                            </div>
                                            <div className="col-sm-6 col">
                                                <div className=" dsp_flex">
                                                    <div className=" form_btns">
                                                        <p className="red_text">Notable Changes</p>
                                                        <a onClick={(e) => { setVisibleSpeechtotext(true), setspeechtextname([item.value]) }} className="icon_btn"><span className="material-symbols-outlined">
                                                            mic
                                                        </span> Record</a>

                                                        <div className="icon_btn input_btn"> <input className="custom-file-input" type="file" onChange={(e) => handleFile(e, [item.value])} /> </div>
                                                    </div>
                                                </div>
                                                {((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"])) &&
                                                    <div className="image_thumbs">

                                                        <div className="img_thumb" data-toggle="modal" data-target="#exampleModalLong">
                                                            <span className="material-icons zoom_out"> zoom_out_map </span>
                                                            <img src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} className="img" />

                                                            <div className="modal fade" id="exampleModalLong" role="dialog"
                                                                aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                                                <div className="modal-dialog" role="document">
                                                                    <div className="modal-content">
                                                                        <div className="modal-header">
                                                                            <h5 className="modal-title">Preview</h5>
                                                                            <span className="material-icons" data-dismiss="modal"
                                                                                aria-label="Close">
                                                                                close
                                                                            </span>
                                                                        </div>
                                                                        <div className="modal-body">
                                                                            <img data-toggle="modal" data-target="#exampleModalLong"
                                                                                src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>}

                                                <div className="form_group">

                                                    <input className="textarea" onChange={(e) => updateInputValue(e)} name={item.value} value={(dailyround && dailyround[item.value]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value])} />
                                                </div>

                                            </div>

                                            <div className="col-sm-6 col">
                                                <div className=" form_btns">
                                                    <p className="red_text">Actions Needed</p>
                                                    <a onClick={(e) => { setVisibleSpeechtotext(true), setspeechtextname([item.value + "_action"]) }} className="icon_btn"><span className="material-symbols-outlined"> mic </span> Record</a>
                                                </div>
                                                <div className="form_group">
                                                    <input className="textarea" onChange={(e) => updateInputValue(e)} name={item.value + "_action"} value={(dailyround && dailyround[item.value + "_action"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_action"])} />


                                                </div>
                                                {/* <button className='btn' onClick={(e) => updateDRData(e)}>Save</button> */}
                                            </div>

                                        </>)
                                })}



                                <div className="col-sm-12 col dsp_flex">
                                    <a onClick={(e) => updateDRData(e, "riskassesment")} className="verified_btn">VERIFIED</a>
                                </div>

                            </div>
                        </div>


                        <div className={(dailyrounds && dailyrounds[0] && dailyrounds[0].date == currentDate && dailyrounds[0].hygiene_comfort) ? "tbl_block" : "tbl_block inactiveboard"}>
                            <h2 className="tbl_hed">
                                <div className="left">HYGIENE & COMFORT</div>
                                <div className="right"><a href="#" className="tbl_btn">Day of Admission</a></div>
                            </h2>
                            <div className="row m-0">

                                {hygiene_comfort && hygiene_comfort.length > 0 && hygiene_comfort.map((item, i) => {


                                    return (!medication[item.value] ? <><div className="col-sm-6 col1" onClick={(e) => { popupInOut(e, [item.value], true) }}>
                                        {item.label}
                                    </div>
                                        <div className="col-sm-6 col2">
                                            {((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"])) &&
                                                <div className="image_thumbs">

                                                    <div className="img_thumb" data-toggle="modal" data-target={"#" + item.value + "dialog"}>
                                                        <span className="material-icons zoom_out"> zoom_out_map </span>
                                                        <img src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} className="img" />

                                                        <div className="modal fade" id={item.value + "dialog"} role="dialog"
                                                            aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                                            <div className="modal-dialog" role="document">
                                                                <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <h5 className="modal-title">Preview</h5>
                                                                        <span className="material-icons" data-dismiss="modal"
                                                                            aria-label="Close">
                                                                            close
                                                                        </span>
                                                                    </div>
                                                                    <div className="modal-body">
                                                                        <img data-toggle="modal" data-target={"#" + item.value + "dialog"}
                                                                            src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>}
                                            {(dailyround && dailyround[item.value]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value])}  &nbsp;
                                            {(dailyround && dailyround[item.value + "_action"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_action"])}
                                        </div></> :
                                        <>
                                            <div className="col-sm-12 col1 " onClick={(e) => { popupInOut(e, [item.value], false) }}>
                                                {item.label}
                                            </div>
                                            <div className="col-sm-6 col">
                                                <div className=" dsp_flex">
                                                    <div className=" form_btns">
                                                        <p className="red_text">Notable Changes</p>
                                                        <a onClick={(e) => { setVisibleSpeechtotext(true), setspeechtextname([item.value]) }} className="icon_btn"><span className="material-symbols-outlined">
                                                            mic
                                                        </span> Record</a>

                                                        <div className="icon_btn input_btn"> <input className="custom-file-input" type="file" onChange={(e) => handleFile(e, [item.value])} /> </div>
                                                    </div>
                                                </div>
                                                {((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"])) &&
                                                    <div className="image_thumbs">

                                                        <div className="img_thumb" data-toggle="modal" data-target="#exampleModalLong">
                                                            <span className="material-icons zoom_out"> zoom_out_map </span>
                                                            <img src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} className="img" />

                                                            <div className="modal fade" id="exampleModalLong" role="dialog"
                                                                aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                                                <div className="modal-dialog" role="document">
                                                                    <div className="modal-content">
                                                                        <div className="modal-header">
                                                                            <h5 className="modal-title">Preview</h5>
                                                                            <span className="material-icons" data-dismiss="modal"
                                                                                aria-label="Close">
                                                                                close
                                                                            </span>
                                                                        </div>
                                                                        <div className="modal-body">
                                                                            <img data-toggle="modal" data-target="#exampleModalLong"
                                                                                src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>}

                                                <div className="form_group">

                                                    <input className="textarea" onChange={(e) => updateInputValue(e)} name={item.value} value={(dailyround && dailyround[item.value]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value])} />
                                                </div>

                                            </div>

                                            <div className="col-sm-6 col">
                                                <div className=" form_btns">
                                                    <p className="red_text">Actions Needed</p>
                                                    <a onClick={(e) => { setVisibleSpeechtotext(true), setspeechtextname([item.value + "_action"]) }} className="icon_btn"><span className="material-symbols-outlined"> mic </span> Record</a>
                                                </div>
                                                <div className="form_group">
                                                    <input className="textarea" onChange={(e) => updateInputValue(e)} name={item.value + "_action"} value={(dailyround && dailyround[item.value + "_action"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_action"])} />


                                                </div>
                                                {/* <button className='btn' onClick={(e) => updateDRData(e)}>Save</button> */}
                                            </div>

                                        </>)
                                })}




                                <div className="col-sm-12 col dsp_flex">
                                    <a onClick={(e) => updateDRData(e, "hygiene_comfort")} className="verified_btn">VERIFIED</a>
                                </div>

                            </div>
                        </div>


                        <div className={(dailyrounds && dailyrounds[0] && dailyrounds[0].date == currentDate && dailyrounds[0].breathing_respiratory) ? "tbl_block" : "tbl_block inactiveboard"}>
                            <h2 className="tbl_hed">
                                <div className="left">BREATHING & RESPIRATORY, DVT/PE </div>
                                <div className="right"><a href="#" className="tbl_btn">Day of Admission</a></div>
                            </h2>
                            <div className="row m-0">

                                {breathing_respiratory && breathing_respiratory.length > 0 && breathing_respiratory.map((item, i) => {


                                    return (!medication[item.value] ? <><div className="col-sm-6 col1" onClick={(e) => { popupInOut(e, [item.value], true) }}>
                                        {item.label}
                                    </div>
                                        <div className="col-sm-6 col2">
                                            {((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"])) &&
                                                <div className="image_thumbs">

                                                    <div className="img_thumb" data-toggle="modal" data-target={"#" + item.value + "dialog"}>
                                                        <span className="material-icons zoom_out"> zoom_out_map </span>
                                                        <img src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} className="img" />

                                                        <div className="modal fade" id={item.value + "dialog"} role="dialog"
                                                            aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                                            <div className="modal-dialog" role="document">
                                                                <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <h5 className="modal-title">Preview</h5>
                                                                        <span className="material-icons" data-dismiss="modal"
                                                                            aria-label="Close">
                                                                            close
                                                                        </span>
                                                                    </div>
                                                                    <div className="modal-body">
                                                                        <img data-toggle="modal" data-target={"#" + item.value + "dialog"}
                                                                            src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>}
                                            {(dailyround && dailyround[item.value]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value])}  &nbsp;
                                            {(dailyround && dailyround[item.value + "_action"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_action"])}
                                        </div></> :
                                        <>
                                            <div className="col-sm-12 col1 " onClick={(e) => { popupInOut(e, [item.value], false) }}>
                                                {item.label}
                                            </div>
                                            <div className="col-sm-6 col">
                                                <div className=" dsp_flex">
                                                    <div className=" form_btns">
                                                        <p className="red_text">Notable Changes</p>
                                                        <a onClick={(e) => { setVisibleSpeechtotext(true), setspeechtextname([item.value]) }} className="icon_btn"><span className="material-symbols-outlined">
                                                            mic
                                                        </span> Record</a>

                                                        <div className="icon_btn input_btn"> <input className="custom-file-input" type="file" onChange={(e) => handleFile(e, [item.value])} /> </div>
                                                    </div>
                                                </div>
                                                {((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"])) &&
                                                    <div className="image_thumbs">

                                                        <div className="img_thumb" data-toggle="modal" data-target="#exampleModalLong">
                                                            <span className="material-icons zoom_out"> zoom_out_map </span>
                                                            <img src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} className="img" />

                                                            <div className="modal fade" id="exampleModalLong" role="dialog"
                                                                aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                                                <div className="modal-dialog" role="document">
                                                                    <div className="modal-content">
                                                                        <div className="modal-header">
                                                                            <h5 className="modal-title">Preview</h5>
                                                                            <span className="material-icons" data-dismiss="modal"
                                                                                aria-label="Close">
                                                                                close
                                                                            </span>
                                                                        </div>
                                                                        <div className="modal-body">
                                                                            <img data-toggle="modal" data-target="#exampleModalLong"
                                                                                src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>}

                                                <div className="form_group">

                                                    <input className="textarea" onChange={(e) => updateInputValue(e)} name={item.value} value={(dailyround && dailyround[item.value]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value])} />
                                                </div>

                                            </div>

                                            <div className="col-sm-6 col">
                                                <div className=" form_btns">
                                                    <p className="red_text">Actions Needed</p>
                                                    <a onClick={(e) => { setVisibleSpeechtotext(true), setspeechtextname([item.value + "_action"]) }} className="icon_btn"><span className="material-symbols-outlined"> mic </span> Record</a>
                                                </div>
                                                <div className="form_group">
                                                    <input className="textarea" onChange={(e) => updateInputValue(e)} name={item.value + "_action"} value={(dailyround && dailyround[item.value + "_action"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_action"])} />


                                                </div>
                                                {/* <button className='btn' onClick={(e) => updateDRData(e)}>Save</button> */}
                                            </div>

                                        </>)
                                })}


                                <div className="col-sm-12 col dsp_flex">
                                    <a onClick={(e) => updateDRData(e, "breathing_respiratory")} className="verified_btn">VERIFIED</a>
                                </div>

                            </div>
                        </div>

                        <div className={(dailyrounds && dailyrounds[0] && dailyrounds[0].date == currentDate && dailyrounds[0].skin_pressure_care) ? "tbl_block" : "tbl_block inactiveboard"}>
                            <h2 className="tbl_hed">
                                <div className="left">SKIN & PRESSURE CARE </div>
                                <div className="right"><a href="#" className="tbl_btn">Day of Admission</a></div>
                            </h2>
                            <div className="row m-0">

                                {skin_pressure_care && skin_pressure_care.length > 0 && skin_pressure_care.map((item, i) => {


                                    return (!medication[item.value] ? <><div className="col-sm-6 col1" onClick={(e) => { popupInOut(e, [item.value], true) }}>
                                        {item.label}
                                    </div>
                                        <div className="col-sm-6 col2">
                                            {((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"])) &&
                                                <div className="image_thumbs">

                                                    <div className="img_thumb" data-toggle="modal" data-target={"#" + item.value + "dialog"}>
                                                        <span className="material-icons zoom_out"> zoom_out_map </span>
                                                        <img src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} className="img" />

                                                        <div className="modal fade" id={item.value + "dialog"} role="dialog"
                                                            aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                                            <div className="modal-dialog" role="document">
                                                                <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <h5 className="modal-title">Preview</h5>
                                                                        <span className="material-icons" data-dismiss="modal"
                                                                            aria-label="Close">
                                                                            close
                                                                        </span>
                                                                    </div>
                                                                    <div className="modal-body">
                                                                        <img data-toggle="modal" data-target={"#" + item.value + "dialog"}
                                                                            src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>}
                                            {(dailyround && dailyround[item.value]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value])}  &nbsp;
                                            {(dailyround && dailyround[item.value + "_action"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_action"])}
                                        </div></> :
                                        <>
                                            <div className="col-sm-12 col1 " onClick={(e) => { popupInOut(e, [item.value], false) }}>
                                                {item.label}
                                            </div>
                                            <div className="col-sm-6 col">
                                                <div className=" dsp_flex">
                                                    <div className=" form_btns">
                                                        <p className="red_text">Notable Changes</p>
                                                        <a onClick={(e) => { setVisibleSpeechtotext(true), setspeechtextname([item.value]) }} className="icon_btn"><span className="material-symbols-outlined">
                                                            mic
                                                        </span> Record</a>

                                                        <div className="icon_btn input_btn"> <input className="custom-file-input" type="file" onChange={(e) => handleFile(e, [item.value])} /> </div>
                                                    </div>
                                                </div>
                                                {((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"])) &&
                                                    <div className="image_thumbs">

                                                        <div className="img_thumb" data-toggle="modal" data-target="#exampleModalLong">
                                                            <span className="material-icons zoom_out"> zoom_out_map </span>
                                                            <img src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} className="img" />

                                                            <div className="modal fade" id="exampleModalLong" role="dialog"
                                                                aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                                                <div className="modal-dialog" role="document">
                                                                    <div className="modal-content">
                                                                        <div className="modal-header">
                                                                            <h5 className="modal-title">Preview</h5>
                                                                            <span className="material-icons" data-dismiss="modal"
                                                                                aria-label="Close">
                                                                                close
                                                                            </span>
                                                                        </div>
                                                                        <div className="modal-body">
                                                                            <img data-toggle="modal" data-target="#exampleModalLong"
                                                                                src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>}

                                                <div className="form_group">

                                                    <input className="textarea" onChange={(e) => updateInputValue(e)} name={item.value} value={(dailyround && dailyround[item.value]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value])} />
                                                </div>

                                            </div>

                                            <div className="col-sm-6 col">
                                                <div className=" form_btns">
                                                    <p className="red_text">Actions Needed</p>
                                                    <a onClick={(e) => { setVisibleSpeechtotext(true), setspeechtextname([item.value + "_action"]) }} className="icon_btn"><span className="material-symbols-outlined"> mic </span> Record</a>
                                                </div>
                                                <div className="form_group">
                                                    <input className="textarea" onChange={(e) => updateInputValue(e)} name={item.value + "_action"} value={(dailyround && dailyround[item.value + "_action"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_action"])} />


                                                </div>
                                                {/* <button className='btn' onClick={(e) => updateDRData(e)}>Save</button> */}
                                            </div>

                                        </>)
                                })}





                                <div className="col-sm-12 col dsp_flex">
                                    <a onClick={(e) => updateDRData(e, "skin_pressure_care")} className="verified_btn">VERIFIED</a>
                                </div>

                            </div>
                        </div>

                        <div className={(dailyrounds && dailyrounds[0] && dailyrounds[0].date == currentDate && dailyrounds[0].mobility_function) ? "tbl_block" : "tbl_block inactiveboard"}>
                            <h2 className="tbl_hed">
                                <div className="left">MOBILITY & FUNCTION </div>
                                <div className="right"><a href="#" className="tbl_btn">Day of Admission</a></div>
                            </h2>
                            <div className="row m-0">


                                {mobility_function && mobility_function.length > 0 && mobility_function.map((item, i) => {


                                    return (!medication[item.value] ? <><div className="col-sm-6 col1" onClick={(e) => { popupInOut(e, [item.value], true) }}>
                                        {item.label}
                                    </div>
                                        <div className="col-sm-6 col2">
                                            {((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"])) &&
                                                <div className="image_thumbs">

                                                    <div className="img_thumb" data-toggle="modal" data-target={"#" + item.value + "dialog"}>
                                                        <span className="material-icons zoom_out"> zoom_out_map </span>
                                                        <img src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} className="img" />

                                                        <div className="modal fade" id={item.value + "dialog"} role="dialog"
                                                            aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                                            <div className="modal-dialog" role="document">
                                                                <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <h5 className="modal-title">Preview</h5>
                                                                        <span className="material-icons" data-dismiss="modal"
                                                                            aria-label="Close">
                                                                            close
                                                                        </span>
                                                                    </div>
                                                                    <div className="modal-body">
                                                                        <img data-toggle="modal" data-target={"#" + item.value + "dialog"}
                                                                            src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>}
                                            {(dailyround && dailyround[item.value]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value])}  &nbsp;
                                            {(dailyround && dailyround[item.value + "_action"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_action"])}
                                        </div></> :
                                        <>
                                            <div className="col-sm-12 col1 " onClick={(e) => { popupInOut(e, [item.value], false) }}>
                                                {item.label}
                                            </div>
                                            <div className="col-sm-6 col">
                                                <div className=" dsp_flex">
                                                    <div className=" form_btns">
                                                        <p className="red_text">Notable Changes</p>
                                                        <a onClick={(e) => { setVisibleSpeechtotext(true), setspeechtextname([item.value]) }} className="icon_btn"><span className="material-symbols-outlined">
                                                            mic
                                                        </span> Record</a>

                                                        <div className="icon_btn input_btn"> <input className="custom-file-input" type="file" onChange={(e) => handleFile(e, [item.value])} /> </div>
                                                    </div>
                                                </div>
                                                {((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"])) &&
                                                    <div className="image_thumbs">

                                                        <div className="img_thumb" data-toggle="modal" data-target="#exampleModalLong">
                                                            <span className="material-icons zoom_out"> zoom_out_map </span>
                                                            <img src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} className="img" />

                                                            <div className="modal fade" id="exampleModalLong" role="dialog"
                                                                aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                                                <div className="modal-dialog" role="document">
                                                                    <div className="modal-content">
                                                                        <div className="modal-header">
                                                                            <h5 className="modal-title">Preview</h5>
                                                                            <span className="material-icons" data-dismiss="modal"
                                                                                aria-label="Close">
                                                                                close
                                                                            </span>
                                                                        </div>
                                                                        <div className="modal-body">
                                                                            <img data-toggle="modal" data-target="#exampleModalLong"
                                                                                src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>}

                                                <div className="form_group">

                                                    <input className="textarea" onChange={(e) => updateInputValue(e)} name={item.value} value={(dailyround && dailyround[item.value]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value])} />
                                                </div>

                                            </div>

                                            <div className="col-sm-6 col">
                                                <div className=" form_btns">
                                                    <p className="red_text">Actions Needed</p>
                                                    <a onClick={(e) => { setVisibleSpeechtotext(true), setspeechtextname([item.value + "_action"]) }} className="icon_btn"><span className="material-symbols-outlined"> mic </span> Record</a>
                                                </div>
                                                <div className="form_group">
                                                    <input className="textarea" onChange={(e) => updateInputValue(e)} name={item.value + "_action"} value={(dailyround && dailyround[item.value + "_action"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_action"])} />


                                                </div>
                                                {/* <button className='btn' onClick={(e) => updateDRData(e)}>Save</button> */}
                                            </div>

                                        </>)
                                })}

                                <div className="col-sm-12 col dsp_flex">
                                    <a onClick={(e) => updateDRData(e, "mobility_function")} className="verified_btn">VERIFIED</a>
                                </div>

                            </div>
                        </div>

                        <div className={(dailyrounds && dailyrounds[0] && dailyrounds[0].date == currentDate  && dailyrounds[0].inpatient_complex) ? "tbl_block" : "tbl_block inactiveboard"}>
                            <h2 className="tbl_hed">
                                <div className="left">INPATIENTS WITH COMPLEX NEEDS </div>
                                <div className="right"><a href="#" className="tbl_btn">Day of Admission</a></div>
                            </h2>
                            <div className="row m-0">


                                {inpatient_complex && inpatient_complex.length > 0 && inpatient_complex.map((item, i) => {


                                    return (!medication[item.value] ? <><div className="col-sm-6 col1" onClick={(e) => { popupInOut(e, [item.value], true) }}>
                                        {item.label}
                                    </div>
                                        <div className="col-sm-6 col2">
                                            {((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"])) &&
                                                <div className="image_thumbs">

                                                    <div className="img_thumb" data-toggle="modal" data-target={"#" + item.value + "dialog"}>
                                                        <span className="material-icons zoom_out"> zoom_out_map </span>
                                                        <img src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} className="img" />

                                                        <div className="modal fade" id={item.value + "dialog"} role="dialog"
                                                            aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                                            <div className="modal-dialog" role="document">
                                                                <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <h5 className="modal-title">Preview</h5>
                                                                        <span className="material-icons" data-dismiss="modal"
                                                                            aria-label="Close">
                                                                            close
                                                                        </span>
                                                                    </div>
                                                                    <div className="modal-body">
                                                                        <img data-toggle="modal" data-target={"#" + item.value + "dialog"}
                                                                            src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>}
                                            {(dailyround && dailyround[item.value]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value])}  &nbsp;
                                            {(dailyround && dailyround[item.value + "_action"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_action"])}
                                        </div></> :
                                        <>
                                            <div className="col-sm-12 col1 " onClick={(e) => { popupInOut(e, [item.value], false) }}>
                                                {item.label}
                                            </div>
                                            <div className="col-sm-6 col">
                                                <div className=" dsp_flex">
                                                    <div className=" form_btns">
                                                        <p className="red_text">Notable Changes</p>
                                                        <a onClick={(e) => { setVisibleSpeechtotext(true), setspeechtextname([item.value]) }} className="icon_btn"><span className="material-symbols-outlined">
                                                            mic
                                                        </span> Record</a>

                                                        <div className="icon_btn input_btn"> <input className="custom-file-input" type="file" onChange={(e) => handleFile(e, [item.value])} /> </div>
                                                    </div>
                                                </div>
                                                {((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"])) &&
                                                    <div className="image_thumbs">

                                                        <div className="img_thumb" data-toggle="modal" data-target="#exampleModalLong">
                                                            <span className="material-icons zoom_out"> zoom_out_map </span>
                                                            <img src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} className="img" />

                                                            <div className="modal fade" id="exampleModalLong" role="dialog"
                                                                aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                                                <div className="modal-dialog" role="document">
                                                                    <div className="modal-content">
                                                                        <div className="modal-header">
                                                                            <h5 className="modal-title">Preview</h5>
                                                                            <span className="material-icons" data-dismiss="modal"
                                                                                aria-label="Close">
                                                                                close
                                                                            </span>
                                                                        </div>
                                                                        <div className="modal-body">
                                                                            <img data-toggle="modal" data-target="#exampleModalLong"
                                                                                src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>}

                                                <div className="form_group">

                                                    <input className="textarea" onChange={(e) => updateInputValue(e)} name={item.value} value={(dailyround && dailyround[item.value]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value])} />
                                                </div>

                                            </div>

                                            <div className="col-sm-6 col">
                                                <div className=" form_btns">
                                                    <p className="red_text">Actions Needed</p>
                                                    <a onClick={(e) => { setVisibleSpeechtotext(true), setspeechtextname([item.value + "_action"]) }} className="icon_btn"><span className="material-symbols-outlined"> mic </span> Record</a>
                                                </div>
                                                <div className="form_group">
                                                    <input className="textarea" onChange={(e) => updateInputValue(e)} name={item.value + "_action"} value={(dailyround && dailyround[item.value + "_action"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_action"])} />


                                                </div>
                                                {/* <button className='btn' onClick={(e) => updateDRData(e)}>Save</button> */}
                                            </div>

                                        </>)
                                })}


                                <div className="col-sm-12 col dsp_flex">
                                    <a onClick={(e) => updateDRData(e, "inpatient_complex")} className="verified_btn">VERIFIED</a>
                                </div>

                            </div>
                        </div>

                        <div className={(dailyrounds && dailyrounds[0] && dailyrounds[0].date == currentDate  && dailyrounds[0].communications) ? "tbl_block" : "tbl_block inactiveboard"}>
                            <h2 className="tbl_hed">
                                <div className="left">COMMUNICATIONS</div>
                                <div className="right"><a href="#" className="tbl_btn">Day of Admission</a></div>
                            </h2>
                            <div className="row m-0">


                                {communications && communications.length > 0 && communications.map((item, i) => {


                                    return (!medication[item.value] ? <><div className="col-sm-6 col1" onClick={(e) => { popupInOut(e, [item.value], true) }}>
                                        {item.label}
                                    </div>
                                        <div className="col-sm-6 col2">
                                            {((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"])) &&
                                                <div className="image_thumbs">

                                                    <div className="img_thumb" data-toggle="modal" data-target={"#" + item.value + "dialog"}>
                                                        <span className="material-icons zoom_out"> zoom_out_map </span>
                                                        <img src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} className="img" />

                                                        <div className="modal fade" id={item.value + "dialog"} role="dialog"
                                                            aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                                            <div className="modal-dialog" role="document">
                                                                <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <h5 className="modal-title">Preview</h5>
                                                                        <span className="material-icons" data-dismiss="modal"
                                                                            aria-label="Close">
                                                                            close
                                                                        </span>
                                                                    </div>
                                                                    <div className="modal-body">
                                                                        <img data-toggle="modal" data-target={"#" + item.value + "dialog"}
                                                                            src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>}
                                            {(dailyround && dailyround[item.value]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value])}  &nbsp;
                                            {(dailyround && dailyround[item.value + "_action"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_action"])}
                                        </div></> :
                                        <>
                                            <div className="col-sm-12 col1 " onClick={(e) => { popupInOut(e, [item.value], false) }}>
                                                {item.label}
                                            </div>
                                            <div className="col-sm-6 col">
                                                <div className=" dsp_flex">
                                                    <div className=" form_btns">
                                                        <p className="red_text">Notable Changes</p>
                                                        <a onClick={(e) => { setVisibleSpeechtotext(true), setspeechtextname([item.value]) }} className="icon_btn"><span className="material-symbols-outlined">
                                                            mic
                                                        </span> Record</a>

                                                        <div className="icon_btn input_btn"> <input className="custom-file-input" type="file" onChange={(e) => handleFile(e, [item.value])} /> </div>
                                                    </div>
                                                </div>
                                                {((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"])) &&
                                                    <div className="image_thumbs">

                                                        <div className="img_thumb" data-toggle="modal" data-target="#exampleModalLong">
                                                            <span className="material-icons zoom_out"> zoom_out_map </span>
                                                            <img src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} className="img" />

                                                            <div className="modal fade" id="exampleModalLong" role="dialog"
                                                                aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                                                <div className="modal-dialog" role="document">
                                                                    <div className="modal-content">
                                                                        <div className="modal-header">
                                                                            <h5 className="modal-title">Preview</h5>
                                                                            <span className="material-icons" data-dismiss="modal"
                                                                                aria-label="Close">
                                                                                close
                                                                            </span>
                                                                        </div>
                                                                        <div className="modal-body">
                                                                            <img data-toggle="modal" data-target="#exampleModalLong"
                                                                                src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>}

                                                <div className="form_group">

                                                    <input className="textarea" onChange={(e) => updateInputValue(e)} name={item.value} value={(dailyround && dailyround[item.value]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value])} />
                                                </div>

                                            </div>

                                            <div className="col-sm-6 col">
                                                <div className=" form_btns">
                                                    <p className="red_text">Actions Needed</p>
                                                    <a onClick={(e) => { setVisibleSpeechtotext(true), setspeechtextname([item.value + "_action"]) }} className="icon_btn"><span className="material-symbols-outlined"> mic </span> Record</a>
                                                </div>
                                                <div className="form_group">
                                                    <input className="textarea" onChange={(e) => updateInputValue(e)} name={item.value + "_action"} value={(dailyround && dailyround[item.value + "_action"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_action"])} />


                                                </div>
                                                {/* <button className='btn' onClick={(e) => updateDRData(e)}>Save</button> */}
                                            </div>

                                        </>)
                                })}





                                <div className="col-sm-12 col dsp_flex">
                                    <a onClick={(e) => updateDRData(e, "communications")} className="verified_btn">VERIFIED</a>
                                </div>

                            </div>
                        </div>


                        {/* <div className="tbl_block">
                            <h2 className="tbl_hed">
                                <div className="left">TODAY'S PLAN (KIROKU)</div>
                                <div className="right"><a href="#" className="tbl_btn">Day of Admission</a></div>
                            </h2>
                            <div className="row m-0">

                                <div className="col-sm-12 col2">
                                    NORMAL
                                </div>

                                <div className="col-sm-12 col dsp_flex">
                                    <a href="#" className="verified_btn">VERIFIED</a>
                                </div>

                            </div>
                        </div> */}

                        <div className={(dailyrounds && dailyrounds[0] && dailyrounds[0].date == currentDate && dailyrounds[0].medical_plan) ? "tbl_block" : "tbl_block inactiveboard"}>
                            <h2 className="tbl_hed">
                                <div className="left">MEDICAL PLAN</div>
                                <div className="right"><a href="#" className="tbl_btn">Day of Admission</a></div>
                            </h2>
                            <div className="row m-0">

                                {medical_plan && medical_plan.length > 0 && medical_plan.map((item, i) => {


                                    return (!medication[item.value] ? <><div className="col-sm-6 col1" onClick={(e) => { popupInOut(e, [item.value], true) }}>
                                        {item.label}
                                    </div>
                                        <div className="col-sm-6 col2">
                                            {((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"])) &&
                                                <div className="image_thumbs">

                                                    <div className="img_thumb" data-toggle="modal" data-target={"#" + item.value + "dialog"}>
                                                        <span className="material-icons zoom_out"> zoom_out_map </span>
                                                        <img src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} className="img" />

                                                        <div className="modal fade" id={item.value + "dialog"} role="dialog"
                                                            aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                                            <div className="modal-dialog" role="document">
                                                                <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <h5 className="modal-title">Preview</h5>
                                                                        <span className="material-icons" data-dismiss="modal"
                                                                            aria-label="Close">
                                                                            close
                                                                        </span>
                                                                    </div>
                                                                    <div className="modal-body">
                                                                        <img data-toggle="modal" data-target={"#" + item.value + "dialog"}
                                                                            src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>}
                                            {(dailyround && dailyround[item.value]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value])}  &nbsp;
                                            {(dailyround && dailyround[item.value + "_action"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_action"])}
                                        </div></> :
                                        <>
                                            <div className="col-sm-12 col1 " onClick={(e) => { popupInOut(e, [item.value], false) }}>
                                                {item.label}
                                            </div>
                                            <div className="col-sm-6 col">
                                                <div className=" dsp_flex">
                                                    <div className=" form_btns">
                                                        <p className="red_text">Notable Changes</p>
                                                        <a onClick={(e) => { setVisibleSpeechtotext(true), setspeechtextname([item.value]) }} className="icon_btn"><span className="material-symbols-outlined">
                                                            mic
                                                        </span> Record</a>

                                                        <div className="icon_btn input_btn"> <input className="custom-file-input" type="file" onChange={(e) => handleFile(e, [item.value])} /> </div>
                                                    </div>
                                                </div>
                                                {((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"])) &&
                                                    <div className="image_thumbs">

                                                        <div className="img_thumb" data-toggle="modal" data-target="#exampleModalLong">
                                                            <span className="material-icons zoom_out"> zoom_out_map </span>
                                                            <img src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} className="img" />

                                                            <div className="modal fade" id="exampleModalLong" role="dialog"
                                                                aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                                                <div className="modal-dialog" role="document">
                                                                    <div className="modal-content">
                                                                        <div className="modal-header">
                                                                            <h5 className="modal-title">Preview</h5>
                                                                            <span className="material-icons" data-dismiss="modal"
                                                                                aria-label="Close">
                                                                                close
                                                                            </span>
                                                                        </div>
                                                                        <div className="modal-body">
                                                                            <img data-toggle="modal" data-target="#exampleModalLong"
                                                                                src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>}

                                                <div className="form_group">

                                                    <input className="textarea" onChange={(e) => updateInputValue(e)} name={item.value} value={(dailyround && dailyround[item.value]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value])} />
                                                </div>

                                            </div>

                                            <div className="col-sm-6 col">
                                                <div className=" form_btns">
                                                    <p className="red_text">Actions Needed</p>
                                                    <a onClick={(e) => { setVisibleSpeechtotext(true), setspeechtextname([item.value + "_action"]) }} className="icon_btn"><span className="material-symbols-outlined"> mic </span> Record</a>
                                                </div>
                                                <div className="form_group">
                                                    <input className="textarea" onChange={(e) => updateInputValue(e)} name={item.value + "_action"} value={(dailyround && dailyround[item.value + "_action"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_action"])} />


                                                </div>
                                                {/* <button className='btn' onClick={(e) => updateDRData(e)}>Save</button> */}
                                            </div>

                                        </>)
                                })}


                                <div className="col-sm-12 col dsp_flex">
                                    <a onClick={(e) => updateDRData(e, "medical_plan")} className="verified_btn">VERIFIED</a>
                                </div>

                            </div>
                        </div>


                        <div className={(dailyrounds && dailyrounds[0] && dailyrounds[0].date == currentDate && dailyrounds[0].instruction_nursing) ? "tbl_block" : "tbl_block inactiveboard"}>
                            <h2 className="tbl_hed">
                                <div className="left">INSTRUCTIONS FOR NURSING, PHYSIO & OT</div>
                                <div className="right"><a href="#" className="tbl_btn">Day of Admission</a></div>
                            </h2>
                            <div className="row m-0">

                                {instruction_nursing && instruction_nursing.length > 0 && instruction_nursing.map((item, i) => {


                                    return (!medication[item.value] ? <><div className="col-sm-6 col1" onClick={(e) => { popupInOut(e, [item.value], true) }}>
                                        {item.label}
                                    </div>
                                        <div className="col-sm-6 col2">
                                            {((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"])) &&
                                                <div className="image_thumbs">

                                                    <div className="img_thumb" data-toggle="modal" data-target={"#" + item.value + "dialog"}>
                                                        <span className="material-icons zoom_out"> zoom_out_map </span>
                                                        <img src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} className="img" />

                                                        <div className="modal fade" id={item.value + "dialog"} role="dialog"
                                                            aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                                            <div className="modal-dialog" role="document">
                                                                <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <h5 className="modal-title">Preview</h5>
                                                                        <span className="material-icons" data-dismiss="modal"
                                                                            aria-label="Close">
                                                                            close
                                                                        </span>
                                                                    </div>
                                                                    <div className="modal-body">
                                                                        <img data-toggle="modal" data-target={"#" + item.value + "dialog"}
                                                                            src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>}
                                            {(dailyround && dailyround[item.value]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value])}  &nbsp;
                                            {(dailyround && dailyround[item.value + "_action"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_action"])}
                                        </div></> :
                                        <>
                                            <div className="col-sm-12 col1 " onClick={(e) => { popupInOut(e, [item.value], false) }}>
                                                {item.label}
                                            </div>
                                            <div className="col-sm-6 col">
                                                <div className=" dsp_flex">
                                                    <div className=" form_btns">
                                                        <p className="red_text">Notable Changes</p>
                                                        <a onClick={(e) => { setVisibleSpeechtotext(true), setspeechtextname([item.value]) }} className="icon_btn"><span className="material-symbols-outlined">
                                                            mic
                                                        </span> Record</a>

                                                        <div className="icon_btn input_btn"> <input className="custom-file-input" type="file" onChange={(e) => handleFile(e, [item.value])} /> </div>
                                                    </div>
                                                </div>
                                                {((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"])) &&
                                                    <div className="image_thumbs">

                                                        <div className="img_thumb" data-toggle="modal" data-target="#exampleModalLong">
                                                            <span className="material-icons zoom_out"> zoom_out_map </span>
                                                            <img src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} className="img" />

                                                            <div className="modal fade" id="exampleModalLong" role="dialog"
                                                                aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                                                <div className="modal-dialog" role="document">
                                                                    <div className="modal-content">
                                                                        <div className="modal-header">
                                                                            <h5 className="modal-title">Preview</h5>
                                                                            <span className="material-icons" data-dismiss="modal"
                                                                                aria-label="Close">
                                                                                close
                                                                            </span>
                                                                        </div>
                                                                        <div className="modal-body">
                                                                            <img data-toggle="modal" data-target="#exampleModalLong"
                                                                                src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>}

                                                <div className="form_group">

                                                    <input className="textarea" onChange={(e) => updateInputValue(e)} name={item.value} value={(dailyround && dailyround[item.value]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value])} />
                                                </div>

                                            </div>

                                            <div className="col-sm-6 col">
                                                <div className=" form_btns">
                                                    <p className="red_text">Actions Needed</p>
                                                    <a onClick={(e) => { setVisibleSpeechtotext(true), setspeechtextname([item.value + "_action"]) }} className="icon_btn"><span className="material-symbols-outlined"> mic </span> Record</a>
                                                </div>
                                                <div className="form_group">
                                                    <input className="textarea" onChange={(e) => updateInputValue(e)} name={item.value + "_action"} value={(dailyround && dailyround[item.value + "_action"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_action"])} />


                                                </div>
                                                {/* <button className='btn' onClick={(e) => updateDRData(e)}>Save</button> */}
                                            </div>

                                        </>)
                                })}


                                <div className="col-sm-12 col dsp_flex">
                                    <a onClick={(e) => updateDRData(e, "instruction_nursing")} className="verified_btn">VERIFIED</a>
                                </div>

                            </div>
                        </div>

                        <div className={(dailyrounds && dailyrounds[0] && dailyrounds[0].date == currentDate && dailyrounds[0].daycase) ? "tbl_block" : "tbl_block inactiveboard"}>
                            <h2 className="tbl_hed">
                                <div className="left">DAY CASE</div>
                                <div className="right"><a href="#" className="tbl_btn">Day of Admission</a></div>
                            </h2>
                            <div className="row m-0">

                                {daycase && daycase.length > 0 && daycase.map((item, i) => {


                                    return (!medication[item.value] ? <><div className="col-sm-6 col1" onClick={(e) => { popupInOut(e, [item.value], true) }}>
                                        {item.label}
                                    </div>
                                        <div className="col-sm-6 col2">
                                            {((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"])) &&
                                                <div className="image_thumbs">

                                                    <div className="img_thumb" data-toggle="modal" data-target={"#" + item.value + "dialog"}>
                                                        <span className="material-icons zoom_out"> zoom_out_map </span>
                                                        <img src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} className="img" />

                                                        <div className="modal fade" id={item.value + "dialog"} role="dialog"
                                                            aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                                            <div className="modal-dialog" role="document">
                                                                <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <h5 className="modal-title">Preview</h5>
                                                                        <span className="material-icons" data-dismiss="modal"
                                                                            aria-label="Close">
                                                                            close
                                                                        </span>
                                                                    </div>
                                                                    <div className="modal-body">
                                                                        <img data-toggle="modal" data-target={"#" + item.value + "dialog"}
                                                                            src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>}
                                            {(dailyround && dailyround[item.value]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value])}  &nbsp;
                                            {(dailyround && dailyround[item.value + "_action"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_action"])}
                                        </div></> :
                                        <>
                                            <div className="col-sm-12 col1 " onClick={(e) => { popupInOut(e, [item.value], false) }}>
                                                {item.label}
                                            </div>
                                            <div className="col-sm-6 col">
                                                <div className=" dsp_flex">
                                                    <div className=" form_btns">
                                                        <p className="red_text">Notable Changes</p>
                                                        <a onClick={(e) => { setVisibleSpeechtotext(true), setspeechtextname([item.value]) }} className="icon_btn"><span className="material-symbols-outlined">
                                                            mic
                                                        </span> Record</a>

                                                        <div className="icon_btn input_btn"> <input className="custom-file-input" type="file" onChange={(e) => handleFile(e, [item.value])} /> </div>
                                                    </div>
                                                </div>
                                                {((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"])) &&
                                                    <div className="image_thumbs">

                                                        <div className="img_thumb" data-toggle="modal" data-target="#exampleModalLong">
                                                            <span className="material-icons zoom_out"> zoom_out_map </span>
                                                            <img src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} className="img" />

                                                            <div className="modal fade" id="exampleModalLong" role="dialog"
                                                                aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                                                <div className="modal-dialog" role="document">
                                                                    <div className="modal-content">
                                                                        <div className="modal-header">
                                                                            <h5 className="modal-title">Preview</h5>
                                                                            <span className="material-icons" data-dismiss="modal"
                                                                                aria-label="Close">
                                                                                close
                                                                            </span>
                                                                        </div>
                                                                        <div className="modal-body">
                                                                            <img data-toggle="modal" data-target="#exampleModalLong"
                                                                                src={((file && file[item.value + "_result"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_result"]))} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>}

                                                <div className="form_group">

                                                    <input className="textarea" onChange={(e) => updateInputValue(e)} name={item.value} value={(dailyround && dailyround[item.value]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value])} />
                                                </div>

                                            </div>

                                            <div className="col-sm-6 col">
                                                <div className=" form_btns">
                                                    <p className="red_text">Actions Needed</p>
                                                    <a onClick={(e) => { setVisibleSpeechtotext(true), setspeechtextname([item.value + "_action"]) }} className="icon_btn"><span className="material-symbols-outlined"> mic </span> Record</a>
                                                </div>
                                                <div className="form_group">
                                                    <input className="textarea" onChange={(e) => updateInputValue(e)} name={item.value + "_action"} value={(dailyround && dailyround[item.value + "_action"]) || (dailyrounds && dailyrounds[0] && dailyrounds[0][item.value + "_action"])} />


                                                </div>
                                                {/* <button className='btn' onClick={(e) => updateDRData(e)}>Save</button> */}
                                            </div>

                                        </>)
                                })}



                                <div className="col-sm-12 col dsp_flex">
                                    <a onClick={(e) => updateDRData(e, "daycase")} className="verified_btn">VERIFIED</a>
                                </div>

                            </div>
                        </div>

                        <br /> <br /> <br />
                    </div>

                                   
                </div>
            </div>

            {visisbleSpeechtotext &&
                <div className='popup_wrp'>
                    <div className="popup_full prescribe">
                        <div className='popup_hed'>
                            <h2>Speech to Text</h2>
                            <a onClick={(e) => { setVisibleSpeechtotext(false) }} className='popup_close'><span className="material-icons">close</span></a>
                        </div>
                        <div className='popup_content'>

                            <div className='popup_scroll'>
                                <div className='speech_block'>
                                    <div className='icon_blk'>
                                        <img src={speech} />

                                    </div>
                                    <div className='speeck_text'>


                                        <p id="transcribeResultText">{transcribeText}{transcript}</p>
                                    </div>
                                    <p>Microphone: <strong>{listening ? 'On' : 'Off'}</strong></p>
                                </div>
                                <div className='speeck_btns'>
                                    <div className='btn_left'>    <button className='btn sp_start' onClick={(e) => SpeechRecognition.startListening({ continuous: true })}><span className="material-icons"> radio_button_checked </span>Start</button>
                                        <button className='btn' onClick={(e) => SpeechRecognition.stopListening(e)}><span className="material-icons"> pause_presentation </span>Stop</button>
                                        <button className='btn' onClick={(e) => resetTranscript(e)}><span className="material-icons"> replay</span>Reset</button></div>
                                    <div className='btn_right'>  <button className='btn' type="text" id="myTextdelete" onClick={(e) => deleteSelect(e)}><span className="material-symbols-outlined"> delete </span>CLEAR TEXT</button></div>
                                </div>
                            </div>
                        </div>
                        <div className='popup_footer'>
                            <div className="prescribe_footer">
                                <button className="border_btn" onClick={(e) => { setVisibleSpeechtotext(false), saveTranscript(e, speechtextname) }}  ><span className="material-icons">save</span>SAVE </button>
                                <button className="btn dsp_flex" onClick={(e) => { setVisibleSpeechtotext(false) }} ><span className="material-icons"><span className="material-icons">close</span></span>DISCARD </button></div>
                        </div>
                    </div>
                </div>
            }


        </React.Fragment>
    )
}

WardLanding.propTypes = {
    getWards: PropTypes.func,
    getTheatres: PropTypes.func,
    wards: PropTypes.array,
    user: PropTypes.any,
    theatres: PropTypes.array,
    clinic: PropTypes.array,
    patients: PropTypes.array,
    doctors: PropTypes.array,
    profile: PropTypes.object,
    news: PropTypes.array,
}

const mapStateToProps = ({ Layout, Login }) => ({
    wards: Layout.wards,
    user: Login.user,
    theatres: Layout.theatres,
    clinic: Layout.clinic,
    patients: Layout.patients,
    doctors: Layout.doctors,
    profile: Layout.profile,
    news: Layout.news,
    dailyrounds: Layout.dailyrounds
})

const mapDispatchToProps = dispatch => ({
    getWards: () => dispatch(getWardsList()),
    getTheatres: () => dispatch(getTheatresList()),
    getClinic: () => dispatch(getClinicList()),
    getPatient: () => dispatch(getPatientList()),
    getUserprofile: (id) => dispatch(getUprofile(id)),
    getDoctor: () => dispatch(getDoctorList()),
    patientupdate: (id, data) => dispatch(updatePatient(id, data)),
    newsList: (data) => dispatch(getNews(data)),
    dailyRoundsList: (data) => dispatch(getDailyRounds(data)),
    dailyUpdate: (data) => dispatch(updateDailyRounds(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(WardLanding)
