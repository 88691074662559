import React, { useState, useEffect, useContext, useRef, createContext } from 'react';
import TimelinePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.timeline.min.js';
import RegionsPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.regions.min.js';
// import { FileContext } from './fileContext';
import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';
import wavesurfer from 'wavesurfer.js';
//const audioEncoder = require('audio-encoder');
//const ConcatenateBlobs = require('concatenateblobs');

// import ToggleButton from './ToggleButton';

var buffers = [];
var index = 0;
let type = 'audio/webm;codecs=opus'

const AudioWaveform = () => {
	const recorderControls = useAudioRecorder();

	const FileContext = createContext();
	const wavesurferRef = useRef(null);
	const timelineRef = useRef(null);

	// crate an instance of the wavesurfer
	const [blobsList, setBlobList] = useState([]);

	const [trimBlob, settrimBlob] = useState(null);

	// fetch file url from the context
	//const { fileURL, setFileURL } = useContext(FileContext);
	const [fileURL, setFileURL] = useState('');

	// crate an instance of the wavesurfer
	const [wavesurferObj, setWavesurferObj] = useState();

	const [playing, setPlaying] = useState(true); // to keep track whether audio is currently playing or not
	const [volume, setVolume] = useState(1); // to control volume level of the audio. 0-mute, 1-max
	const [zoom, setZoom] = useState(1); // to control the zoom level of the waveform
	const [duration, setDuration] = useState(0); // duration is used to set the default region of selection for trimming the audio

	// create the waveform inside the correct component
	useEffect(() => {
		// const script = document.createElement("script");
		// script.src = "https://cdn.webrtc-experiment.com/ConcatenateBlobs.js";
		// script.async = true;
		// document.body.appendChild(script);

		if (wavesurferRef.current && !wavesurferObj) {
			setWavesurferObj(
				wavesurfer.create({
					container: '#waveform',
					scrollParent: true,
					autoCenter: true,
					cursorColor: 'violet',
					loopSelection: true,
					waveColor: '#211027',
					progressColor: '#69207F',
					responsive: true,
					plugins: [
						TimelinePlugin.create({
							container: '#wave-timeline',
						}),
						RegionsPlugin.create({}),
					],
				})
			);


		}
	}, [wavesurferRef, wavesurferObj]);

	// once the file URL is ready, load the file to produce the waveform
	useEffect(() => {
		if (fileURL && wavesurferObj) {
			wavesurferObj.load(fileURL);
		}
	}, [fileURL, wavesurferObj]);

	useEffect(() => {
		if (wavesurferObj) {
			// once the waveform is ready, play the audio
			wavesurferObj.on('ready', () => {
				wavesurferObj.play();
				wavesurferObj.enableDragSelection({}); // to select the region to be trimmed
				setDuration(Math.floor(wavesurferObj.getDuration())); // set the duration in local state
			});

			// once audio starts playing, set the state variable to true
			wavesurferObj.on('play', () => {
				setPlaying(true);
			});

			// once audio starts playing, set the state variable to false
			wavesurferObj.on('finish', () => {
				setPlaying(false);
			});

			// if multiple regions are created, then remove all the previous regions so that only 1 is present at any given time
			wavesurferObj.on('region-updated', (region) => {
				const regions = region.wavesurfer.regions.list;
				const keys = Object.keys(regions);
				if (keys.length > 1) {
					regions[keys[0]].remove();
				}
			});
		}
	}, [wavesurferObj]);

	// set volume of the wavesurfer object, whenever volume variable in state is changed
	useEffect(() => {
		if (wavesurferObj) wavesurferObj.setVolume(volume);
	}, [volume, wavesurferObj]);

	// set zoom level of the wavesurfer object, whenever the zoom variable in state is changed
	useEffect(() => {
		if (wavesurferObj) wavesurferObj.zoom(zoom);
	}, [zoom, wavesurferObj]);

	// when the duration of the audio is available, set the length of the region depending on it, so as to not exceed the total lenght of the audio
	useEffect(() => {
		if (duration && wavesurferObj) {
			// add a region with default length
			wavesurferObj.addRegion({
				start: Math.floor(duration / 2) - Math.floor(duration) / 5, // time in seconds
				end: Math.floor(duration / 2), // time in seconds
				color: 'hsla(265, 100%, 86%, 0.4)', // color of the selected region, light hue of purple
			});
		}
	}, [duration, wavesurferObj]);

	const handlePlayPause = (e) => {
		wavesurferObj.playPause();
		setPlaying(!playing);
	};

	const handleReload = (e) => {
		// stop will return the audio to 0s, then play it again
		wavesurferObj.stop();
		wavesurferObj.play();
		setPlaying(true); // to toggle the play/pause button icon
	};

	const handleVolumeSlider = (e) => {
		setVolume(e.target.value);
	};

	const handleZoomSlider = (e) => {
		setZoom(e.target.value);
	};

	const handleTrim = (e) => {
		//console.log("wavesurferObj ", wavesurferObj)
		if (wavesurferObj) {
			// get start and end points of the selected region
			const region =
				wavesurferObj.regions.list[
				Object.keys(wavesurferObj.regions.list)[0]
				];

			//console.log("region ", region)

			if (region) {
				const start = region.start;
				const end = region.end;

				// obtain the original array of the audio
				const original_buffer = wavesurferObj.backend.buffer;
				console.log("original_buffer ", original_buffer)

				// create a temporary new buffer array with the same length, sample rate and no of channels as the original audio
				const new_buffer = wavesurferObj.backend.ac.createBuffer(
					//original_buffer.numberOfChannels,
					2,
					original_buffer.length,
					original_buffer.sampleRate
				);

				// create 2 indices:
				// left & right to the part to be trimmed
				const first_list_index = start * original_buffer.sampleRate;
				const second_list_index = end * original_buffer.sampleRate;
				const second_list_mem_alloc =
					original_buffer.length - end * original_buffer.sampleRate;

				// create a new array upto the region to be trimmed
				const new_list = new Float32Array(parseInt(first_list_index));

				// create a new array of region after the trimmed region
				const second_list = new Float32Array(
					parseInt(second_list_mem_alloc)
				);

				// create an array to combine the 2 parts
				const combined = new Float32Array(original_buffer.length);

				// 2 channels: 1-right, 0-left
				// copy the buffer values for the 2 regions from the original buffer

				// for the region to the left of the trimmed section
				original_buffer.copyFromChannel(new_list, 0);
				original_buffer.copyFromChannel(new_list, 0);

				// for the region to the right of the trimmed section
				original_buffer.copyFromChannel(
					second_list,
					0,
					second_list_index
				);
				original_buffer.copyFromChannel(
					second_list,
					0,
					second_list_index
				);

				// create the combined buffer for the trimmed audio
				combined.set(new_list);
				combined.set(second_list, first_list_index);

				// copy the combined array to the new_buffer
				new_buffer.copyToChannel(combined, 1);
				new_buffer.copyToChannel(combined, 0);

				// load the new_buffer, to restart the wavesurfer's waveform display
				wavesurferObj.loadDecodedBuffer(new_buffer);

				console.log("combined ", combined)
				console.log("new_buffer ", new_buffer)

				const wav = convertAudioBufferToBlob(new_buffer);
				console.log("wav ", wav)
				//settrimBlob(wav)
				//const url = createObjectURL(wav);
				//setFileURL(url)

				//setBlobList([wav])

				//setBlobList(blobsList => [...blobsList, wav])
				//mergeBlob()

				 const url = createObjectURL(wav);
				// //setFileURL(url)
				 const audio = document.createElement('audio');
				 audio.src = url;
				 audio.controls = true;
				 document.body.appendChild(audio);
			}
		}
	}


	const convertAudioBufferToBlob = (audioBuffer) => {

		var channelData = [],
			totalLength = 0,
			channelLength = 0;

		for (var i = 0; i < audioBuffer.numberOfChannels; i++) {
			channelData.push(audioBuffer.getChannelData(i));
			totalLength += channelData[i].length;
			if (i == 0) channelLength = channelData[i].length;
		}

		// interleaved
		const interleaved = new Float32Array(totalLength);

		for (
			let src = 0, dst = 0;
			src < channelLength;
			src++, dst += audioBuffer.numberOfChannels
		) {
			for (var j = 0; j < audioBuffer.numberOfChannels; j++) {
				interleaved[dst + j] = channelData[j][src];
			}
			//interleaved[dst] = left[src];
			//interleaved[dst + 1] = right[src];
		}

		// get WAV file bytes and audio params of your audio source
		const wavBytes = getWavBytes(interleaved.buffer, {
			isFloat: true, // floating point or 16-bit integer
			numChannels: audioBuffer.numberOfChannels,
			sampleRate: 48000,
		});
		const wav = new Blob([wavBytes], { type: "audio/webm;codecs=opus" });
		settrimBlob(wav)
		// const downloadLink = document.createElement('a')
		// downloadLink.href = URL.createObjectURL(wav)
		// downloadLink.setAttribute('download', 'my-audio.wav') // name file
		// console.log("downloadLink ", downloadLink)

		return wav;
	}


	// Returns Uint8Array of WAV bytes
	const getWavBytes = (buffer, options) => {
		const type = options.isFloat ? Float32Array : Uint16Array
		const numFrames = buffer.byteLength / type.BYTES_PER_ELEMENT

		const headerBytes = getWavHeader(Object.assign({}, options, { numFrames }))
		const wavBytes = new Uint8Array(headerBytes.length + buffer.byteLength);

		// prepend header, then add pcmBytes
		wavBytes.set(headerBytes, 0)
		wavBytes.set(new Uint8Array(buffer), headerBytes.length)

		return wavBytes
	}

	// adapted from https://gist.github.com/also/900023
	// returns Uint8Array of WAV header bytes
	const getWavHeader = (options) => {
		const numFrames = options.numFrames
		const numChannels = options.numChannels || 2
		const sampleRate = options.sampleRate || 44100
		const bytesPerSample = options.isFloat ? 4 : 2
		const format = options.isFloat ? 3 : 1

		const blockAlign = numChannels * bytesPerSample
		const byteRate = sampleRate * blockAlign
		const dataSize = numFrames * blockAlign

		const buffer = new ArrayBuffer(44)
		const dv = new DataView(buffer)

		let p = 0

		function writeString(s) {
			for (let i = 0; i < s.length; i++) {
				dv.setUint8(p + i, s.charCodeAt(i))
			}
			p += s.length
		}

		function writeUint32(d) {
			dv.setUint32(p, d, true)
			p += 4
		}

		function writeUint16(d) {
			dv.setUint16(p, d, true)
			p += 2
		}

		writeString('RIFF')              // ChunkID
		writeUint32(dataSize + 36)       // ChunkSize
		writeString('WAVE')              // Format
		writeString('fmt ')              // Subchunk1ID
		writeUint32(16)                  // Subchunk1Size
		writeUint16(format)              // AudioFormat https://i.stack.imgur.com/BuSmb.png
		writeUint16(numChannels)         // NumChannels
		writeUint32(sampleRate)          // SampleRate
		writeUint32(byteRate)            // ByteRate
		writeUint16(blockAlign)          // BlockAlign
		writeUint16(bytesPerSample * 8)  // BitsPerSample
		writeString('data')              // Subchunk2ID
		writeUint32(dataSize)            // Subchunk2Size

		return new Uint8Array(buffer)
	}

	const saveFile = () => {
		// obtain the original array of the audio
		// const original_buffer = wavesurferObj.backend.buffer;
		// console.log("original_buffer ", original_buffer)

		// // create a temporary new buffer array with the same length, sample rate and no of channels as the original audio
		// const new_buffer = wavesurferObj.backend.ac.createBuffer(
		// 	//original_buffer.numberOfChannels,
		// 	2,
		// 	original_buffer.length,
		// 	original_buffer.sampleRate
		// );

		// //wavesurferObj.loadDecodedBuffer(new_buffer);
		// const wav = convertAudioBufferToBlob(new_buffer);
		// const url = createObjectURL(wav);
		// const audio = document.createElement('audio');
		// audio.src = url;
		// audio.controls = true;
		// document.body.appendChild(audio);

		// mergeBlob()

	}

	const mergeBlob = (blobsList) => {
	
		//console.log("concatenateBlobs ", ConcatenateBlobs)
		//let blobs = blobsList;

		//if(blobFinal){
		//	console.log("Merge BlobFinal ", blobFinal)
		//	readAsArrayBuffer([blobFinal], type);
	//	}else 
		//if(blobsList.length > 0){
			console.log("Merge Blobs ", blobsList)
			readAsArrayBuffer(blobsList, type);
		//}
			

		// ConcatenateBlobs(blobsList, 'audio/webm;codecs=opus', function(resultingBlob) {
		// 	console.log(resultingBlob);
		// 	const url = createObjectURL(resultingBlob);
		// 	const audio = document.createElement('audio');
		// 	audio.src = url;
		// 	audio.controls = true;
		// 	document.body.appendChild(audio);

		// });
	}
	const readAsArrayBuffer = (blobs, type) => {
		//console.log("blobs read ", blobs, index)
		//console.log("blobs read ", blobs[index])
		if (!blobs[index]) {
			return concatenateBuffers(type);
		}
		var reader = new FileReader();
		reader.onload = function (event) {
			buffers.push(event.target.result);
			index++;
			readAsArrayBuffer(blobs, type);
		};
		reader.readAsArrayBuffer(blobs[index]);
	}

	const concatenateBuffers = (type) => {

		var byteLength = 0;
		buffers.forEach(function (buffer) {
			byteLength += buffer.byteLength;
		});

		var tmp = new Uint16Array(byteLength);
		var lastOffset = 0;
		buffers.forEach(function (buffer) {
			// BYTES_PER_ELEMENT == 2 for Uint16Array
			var reusableByteLength = buffer.byteLength;
			if (reusableByteLength % 2 != 0) {
				buffer = buffer.slice(0, reusableByteLength - 1)
			}
			tmp.set(new Uint16Array(buffer), lastOffset);
			lastOffset += reusableByteLength;
		});

		var blob = new Blob([tmp.buffer], {
			type: type
		});

		console.log("buffer Merged ", blob)
	//	settrimBlob(blob)
		const url = createObjectURL(blob);
		setFileURL(url)
		buffers = []
		index = 0

		// const audio = document.createElement('audio');
		// audio.src = url;
		// audio.controls = true;
		// document.body.appendChild(audio);

		// return blob;
	}

	const createObjectURL = (object) => {
		//return URL.createObjectURL(object)
		return (window.URL) ? window.URL.createObjectURL(object) : window.webkitURL.createObjectURL(object);
	}

	// useEffect(() => {
	// 	console.log('Do something after bloblist has changed', blobsList);
	// 	mergeBlob(null)
	//  }, [blobsList]);

	//  useEffect(() => {
	// 	console.log('Do something after trimobj has changed', trimBlob);
	// 	//mergeBlob(trimBlob)
	// 	if(trimBlob){
	// 		const url = createObjectURL(trimBlob);
	// 		//setFileURL(url)
	
	// 		const audio = document.createElement('audio');
	// 		audio.id  = 'audio-player';
	// 		audio.src = url;
	// 		audio.controls = true;
	// 		document.body.appendChild(audio);
	// 	}
		

	//  }, [trimBlob]);

	const addAudioElement = (blob) => {
		const url = createObjectURL(blob);
		console.log("blob ", blob)
		console.log("trimBlob ", trimBlob)
		//console.log("fileURL ", fileURL)
		//console.log(wavesurferObj, "wavesurferObj")
		console.log("blobsList ", blobsList)
		//setBlobList(blobsList => [...blobsList, blob])
		

		if(trimBlob){
			let blist = [trimBlob, blob]
			mergeBlob(blist)
		} else {
			settrimBlob(blob)
			setFileURL(url)
		}
		//if(trimBlob){
		//	let blobArray = [trimBlob, blob]
		//	mergeBlob(blobArray)
		//}else {
			
		//}
		//setTimeout(()=>{
			//console.log(blobsList, "blobsList Audio")
			
		//},1000)
		//mergeBlob()
		
		// if(wavesurferObj && wavesurferObj.current){
		// 	wavesurferObj.current.loadBlob(blob);
		// }else {
			//setTimeout(function(){
				
		//	},1000)
			
	//	setFileURL(url)
		//}

		//const audio = document.createElement('audio');
		//audio.src = url;
		//audio.controls = true;
		//document.body.appendChild(audio);


		//wavesurferObj.load(url)
	};

	// console.log("wavesurferObj render", wavesurferObj )
	//console.log("fileURL render", fileURL )
	// console.log("blobsList Render ", blobsList)

	return (
		<section className='waveform-container'>

			<AudioRecorder
				onRecordingComplete={(blob) => addAudioElement(blob)}
				recorderControls={recorderControls}
			/>
			<br />
			{/* <button onClick={recorderControls.stopRecording}>Stop recording</button> */}

			{/* <button onClick={(e) => mergeBlob()}>Merge</button> */}
			<br />

			<div ref={wavesurferRef} id='waveform' />
			<div ref={timelineRef} id='wave-timeline' />
			<div className='all-controls'>
				<div className='left-container'>

					<button
						title='play/pause'
						className='controls btn'
						onClick={handlePlayPause}>
						{playing ? (
							<i className='material-icons'>pause</i>
						) : (
							<i className='material-icons'>play_arrow</i>
						)}
					</button>
					<button
						title='reload'
						className='controls btn'
						onClick={handleReload}>
						<i className='material-icons'>replay</i>
					</button>
					<button className='trim btn' onClick={handleTrim}>
						<span className="material-symbols-outlined">
							cut
						</span>

					</button>
					<button className='btn' onClick={(e) => saveFile()}><span className="material-icons">
						save
					</span></button>
				</div>
				<div className='right-container'>
					<div className='volume-slide-container'>
						<i className='material-icons zoom-icon'>
							remove_circle
						</i>
						<input
							type='range'
							min='1'
							max='1000'
							value={zoom}
							onChange={handleZoomSlider}
							className='slider zoom-slider'
						/>
						<i className='material-icons zoom-icon'>add_circle</i>
					</div>
					<div className='volume-slide-container'>
						{volume > 0 ? (
							<i className='material-icons'>volume_up</i>
						) : (
							<i className='material-icons'>volume_off</i>
						)}
						<input
							type='range'
							min='0'
							max='1'
							step='0.05'
							value={volume}
							onChange={handleVolumeSlider}
							className='slider volume-slider'
						/>
					</div>
				</div>
			</div>
		</section>
	);
};

export default AudioWaveform;
