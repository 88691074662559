import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import MetaTags from 'react-meta-tags';
import xray from '../../assets/images/x-ray.jpg';
import {
    Modal
} from "reactstrap"
import WardMenus from "./wardMenus";
import { connect } from "react-redux"
import { getWardsList, getTheatresList, getClinicList, getPatientList, getUprofile, getDoctorList, updatePatient, getNews, updateDailyRounds, getDailyRounds } from "../../store/actions"

const ToDoList = props => {
    const [discard_popup, setdiscard_popup] = useState(false)
    const [clinicValues, setClinic] = useState({})
    const {
        wards, user, theatres, clinic, patients, profile, doctors
    } = props;

    // useEffect(() => {
    //     const { getWards, getTheatres, getClinic, getDoctor, getPatient, getUserprofile } = props

    //     getPatient()
    //     getDoctor()
    //     let userId = localStorage.getItem("authUser")
    //     getUserprofile(userId)
    // }, [])

    // useEffect(() => {
    //     let clinicPatient = JSON.parse(localStorage.getItem("p"))
    //     if (patients && patients.length > 0 && clinicPatient && clinicPatient._id) {
    //         const cPatient = patients.filter(
    //             usr => usr._id === clinicPatient._id
    //         )
    //         if (cPatient && cPatient[0]) {
    //             let cpatientData = cPatient[0]
    //             delete cpatientData["_id"]
    //             setClinic(clinicValues => ({
    //                 ...clinicValues,
    //                 ...cpatientData
    //             }));
    //         }

    //     }
    // }, [patients])


    return (
        <React.Fragment>

            <MetaTags>
                <title>TO DO List</title>
            </MetaTags>
            <div className='ward '>
                <div className="main mo_list">

                    <div className="no-wrapper">
                        <div className=" out_patient_section out_patient_details mb-0">
                            <WardMenus />
                        </div>

                        <div className="tbl_block">
                            <h2 className="tbl_hed">
                                <div className="left">TO DO</div>
                                <div className="right"></div>
                            </h2>

                            <div className="section mo_list">
                                <div className="wrapper">
                                    <div className="todo_block">
                                        <div className="todo">
                                            <div className="todo_check">
                                                <input className="inp-cbx displayNone" id="cbx-15" type="checkbox"  />
                                                <label className="cbx" htmlFor="cbx-15">
                                                    <span>
                                                        <svg width="12px" height="9px" viewBox="0 0 12 9">
                                                            <polyline points="1 5 4 8 11 1"></polyline>
                                                        </svg>
                                                    </span>
                                                    <span className="todo_name">Lorem Ipsum is simply dummy text</span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="todo">
                                            <div className="todo_check">
                                                <input className="inp-cbx displayNone" id="cbx-16" type="checkbox"  />
                                                <label className="cbx" htmlFor="cbx-16">
                                                    <span>
                                                        <svg width="12px" height="9px" viewBox="0 0 12 9">
                                                            <polyline points="1 5 4 8 11 1"></polyline>
                                                        </svg>
                                                    </span>
                                                    <span className="todo_name">Lorem Ipsum is simply dummy text</span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="todo">
                                            <div className="todo_check">
                                                <input className="inp-cbx displayNone" id="cbx-17" type="checkbox"  />
                                                <label className="cbx" htmlFor="cbx-17">
                                                    <span>
                                                        <svg width="12px" height="9px" viewBox="0 0 12 9">
                                                            <polyline points="1 5 4 8 11 1"></polyline>
                                                        </svg>
                                                    </span>
                                                    <span className="todo_name">Lorem Ipsum is simply dummy text</span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="todo">
                                            <div className="todo_check">
                                                <input className="inp-cbx displayNone" id="cbx-18" type="checkbox"   />
                                                <label className="cbx" htmlFor="cbx-18">
                                                    <span>
                                                        <svg width="12px" height="9px" viewBox="0 0 12 9">
                                                            <polyline points="1 5 4 8 11 1"></polyline>
                                                        </svg>
                                                    </span>
                                                    <span className="todo_name">Lorem Ipsum is simply dummy text</span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="todo">
                                            <div className="todo_check">
                                                <input className="inp-cbx displayNone" id="cbx-19" type="checkbox"  />
                                                <label className="cbx" htmlFor="cbx-19">
                                                    <span>
                                                        <svg width="12px" height="9px" viewBox="0 0 12 9">
                                                            <polyline points="1 5 4 8 11 1"></polyline>
                                                        </svg>
                                                    </span>
                                                    <span className="todo_name">Lorem Ipsum is simply dummy text</span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="todo">
                                            <div className="todo_check">
                                                <input className="inp-cbx displayNone" id="cbx-20" type="checkbox"  />
                                                <label className="cbx" htmlFor="cbx-20">
                                                    <span>
                                                        <svg width="12px" height="9px" viewBox="0 0 12 9">
                                                            <polyline points="1 5 4 8 11 1"></polyline>
                                                        </svg>
                                                    </span>
                                                    <span className="todo_name">Lorem Ipsum is simply dummy text</span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="todo">
                                            <div className="todo_check">
                                                <input className="inp-cbx displayNone" id="cbx-21" type="checkbox"  />
                                                <label className="cbx" htmlFor="cbx-21">
                                                    <span>
                                                        <svg width="12px" height="9px" viewBox="0 0 12 9">
                                                            <polyline points="1 5 4 8 11 1"></polyline>
                                                        </svg>
                                                    </span>
                                                    <span className="todo_name">Lorem Ipsum is simply dummy text</span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="todo">
                                            <div className="todo_check">
                                                <input className="inp-cbx displayNone" id="cbx-22" type="checkbox"  />
                                                <label className="cbx" htmlFor="cbx-22">
                                                    <span>
                                                        <svg width="12px" height="9px" viewBox="0 0 12 9">
                                                            <polyline points="1 5 4 8 11 1"></polyline>
                                                        </svg>
                                                    </span>
                                                    <span className="todo_name">Lorem Ipsum is simply dummy text</span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="todo">
                                            <div className="todo_check">
                                                <input className="inp-cbx displayNone" id="cbx-23" type="checkbox"  />
                                                <label className="cbx" htmlFor="cbx-23">
                                                    <span>
                                                        <svg width="12px" height="9px" viewBox="0 0 12 9">
                                                            <polyline points="1 5 4 8 11 1"></polyline>
                                                        </svg>
                                                    </span>
                                                    <span className="todo_name">Lorem Ipsum is simply dummy text</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>



                        <br /> <br /> <br />
                    </div>


                </div>
            </div>
        </React.Fragment>
    )
}

ToDoList.propTypes = {
    getWards: PropTypes.func,
    getTheatres: PropTypes.func,
    wards: PropTypes.array,
    user: PropTypes.any,
    theatres: PropTypes.array,
    clinic: PropTypes.array,
    patients: PropTypes.array,
    doctors: PropTypes.array,
    profile: PropTypes.object,
    news: PropTypes.array,
}

const mapStateToProps = ({ Layout, Login }) => ({
    wards: Layout.wards,
    user: Login.user,
    theatres: Layout.theatres,
    clinic: Layout.clinic,
    patients: Layout.patients,
    doctors: Layout.doctors,
    profile: Layout.profile,
    news: Layout.news,
    dailyrounds: Layout.dailyrounds
})

const mapDispatchToProps = dispatch => ({
    getWards: () => dispatch(getWardsList()),
    getTheatres: () => dispatch(getTheatresList()),
    getClinic: () => dispatch(getClinicList()),
    getPatient: () => dispatch(getPatientList()),
    getUserprofile: (id) => dispatch(getUprofile(id)),
    getDoctor: () => dispatch(getDoctorList()),
    patientupdate: (id, data) => dispatch(updatePatient(id, data)),
    newsList: (data) => dispatch(getNews(data)),
    dailyRoundsList: (data) => dispatch(getDailyRounds(data)),
    dailyUpdate: (data) => dispatch(updateDailyRounds(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(ToDoList)
