import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import MetaTags from 'react-meta-tags';


import PatientBlock from "../Clinic/patientBlock";
import { connect } from "react-redux"
import { getWardsList, getTheatresList, getClinicList, getPatientList, getUprofile, getDoctorList, updatePatient } from "../../store/actions"

const Infection = props => {


    const [visiblePatients, setPatient] = useState(true)
    const [visiblelanding, setClanding] = useState(false)
    const [successmessage, setSuccess] = useState("")


    const [assesmentValues, setAssesment] = useState({})

    const {
        wards, user, theatres, clinic, patients, profile, doctors
    } = props;

    useEffect(() => {
        const { getWards, getTheatres, getClinic, getDoctor, getPatient, getUserprofile } = props
        // getWards()
        // getTheatres()
        // getClinic()
        getPatient()
        getDoctor()
        let userId = localStorage.getItem("authUser")
        getUserprofile(userId)
    }, [])

    const updateRadioValue = (e) => {
        let val = e.target.value;
        let name = e.target.name;
        let updatedValue = { [name]: val };
        setAssesment(assesmentValues => ({
            ...assesmentValues,
            ...updatedValue
        }));
    }


    const closeClick = (e) => {
        e.preventDefault()
        localStorage.removeItem("a")
        props.history.push("/riskassesment")
    }

    let assesmentPage = JSON.parse(localStorage.getItem("a"))
    let clinicPatient = JSON.parse(localStorage.getItem("p"))

    return (
        <React.Fragment>

            <MetaTags>
                <title>{assesmentPage}</title>
            </MetaTags>
            <div className="dashboard ward">
                <div className="main">

                    <div className="popup_wrp">
                        <div className="popup_full prescribe">
                            <div className="popup_hed">
                                <h2>MENTAL STATUS </h2>
                                <div className="dsp-flex"> <a className="popup_close" onClick={(e) => closeClick(e)}><span className="material-icons">close</span></a></div>

                            </div>
                            <div className="popup_content">
                                <div className="popup_scroll w-btns main nutrition">
                                    <PatientBlock patient={clinicPatient} />
                                    <div className="bar">
                                        <div className="left">
                                            <div className="tag"> Guidance </div>
                                            <h3 className="sub_title">MENTAL STATUS </h3>
                                        </div>
                                        <div className="right">
                                            <a href="#" className="btn"> <span className="material-symbols-outlined"> assignment </span> Not
                                                Yet Done</a>
                                            <div className="tag"> Due </div>
                                        </div>
                                    </div>
                                    <div className="section ctr fb_table poc is ms">

                                        <div className="tbl_block">
                                            <div className="row m-0">
                                                <div className="col-sm-6 col1">
                                                    <div className="check-box">
                                                        <div className="round">
                                                            <input type="checkbox" id="checkbox-20" />
                                                            <label for="checkbox-20"></label>
                                                        </div>
                                                        SHORT TERM MEMORY LOSS
                                                    </div>

                                                </div>
                                                <div className="col-sm-6 col2">

                                                </div>
                                                <div className="col-sm-6 col1">
                                                    <div className="check_item mt-0 ">
                                                        <label className="check">ALTERED LEVEL OF CONSCIOUSNESS (DECREASED
                                                            ATTENTIVENESS</label>
                                                        <div className="select_btns popup_select">
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 1" /><span> Confused
                                                                </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 1" /><span> Delirious
                                                                </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 1" /><span> Somnolent
                                                                </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 1" /><span> Lethargic
                                                                </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 1" /><span> Obtunded
                                                                </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 1" /><span> Stuporous
                                                                </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 1" /><span> Comatose
                                                                </span></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col2">
                                                    <div className="yn_switch mb-0 mt-0 disable">
                                                        <div className="label-container">
                                                            <p>DoLS /LPS in place? </p>
                                                        </div>
                                                        <label for="check826" className="switch-container">
                                                            <input hidden="" type="checkbox" className="switch_check" id="check826" />
                                                            <div className="switch-bg"></div>
                                                            <div className="round-box"></div>
                                                            <div className="switch-left">
                                                                <span>NO</span>
                                                            </div>
                                                            <div className="switch-right">
                                                                <span>YES</span>
                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col1">
                                                    <div className="check_item mt-0 mb-10">
                                                        <label className="check">COGNITIVE (MENTAL PROCESSES OR THOUGHTS)
                                                            IMPAIRMENT</label>
                                                        <div className="select_btns popup_select">
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 1" /><span> Mild
                                                                </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 1" /><span> Moderate
                                                                </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 1" /><span> Severe
                                                                </span></label>
                                                            </div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 1" /><span> Profound
                                                                </span></label>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="yn_switch mb-20 mt-0 ">
                                                        <div className="label-container">
                                                            <p>Can they retain information </p>
                                                        </div>
                                                        <label for="check112" className="switch-container">
                                                            <input hidden="" type="checkbox" className="switch_check" id="check112" />
                                                            <div className="switch-bg"></div>
                                                            <div className="round-box"></div>
                                                            <div className="switch-left">
                                                                <span>NO</span>
                                                            </div>
                                                            <div className="switch-right">
                                                                <span>YES</span>
                                                            </div>
                                                        </label>
                                                    </div>
                                                    <div className="yn_switch mb-0 mt-0 ">
                                                        <div className="label-container">
                                                            <p>Can they use information to make specific decisions </p>
                                                        </div>
                                                        <label for="check113" className="switch-container">
                                                            <input hidden="" type="checkbox" className="switch_check" id="check113" />
                                                            <div className="switch-bg"></div>
                                                            <div className="round-box"></div>
                                                            <div className="switch-left">
                                                                <span>NO</span>
                                                            </div>
                                                            <div className="switch-right">
                                                                <span>YES</span>
                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col2">

                                                </div>
                                                <div className="col-sm-6 col1">
                                                    <p className="sub_title">MENTAL CAPACITY</p>
                                                    <div className="check-box mb-10">
                                                        <div className="round">
                                                            <input type="checkbox" id="checkbox-23" />
                                                            <label for="checkbox-23"></label>
                                                        </div>
                                                        This person LACKS capacity to make the specific decision of
                                                        DNACPR for themselves
                                                    </div>
                                                    <div className="check-box  mb-10">
                                                        <div className="round">
                                                            <input type="checkbox" id="checkbox-24" />
                                                            <label for="checkbox-24"></label>
                                                        </div>
                                                        Unable to make own decisions / Difficulty expressing themselves
                                                    </div>
                                                    <div className="check-box  mb-0">
                                                        <div className="round">
                                                            <input type="checkbox" id="checkbox-25" />
                                                            <label for="checkbox-25"></label>
                                                        </div>
                                                        Learning disability
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col2 disable">
                                                    <a href="#" className="link_btn">Referred to LD Liaison Nurse <span
                                                        className="material-icons icon"> arrow_right </span></a>
                                                </div>
                                                <div className="col-sm-6 col1">
                                                    <div className="check_item mt-0 mb-0">
                                                        <label className="check">PERSONAL RISK & BEHAVIOUR</label>
                                                        <div className="select_btns popup_select">
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 1" /><span> Level of risk is High
                                                                </span></label> </div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 1" /><span> Medium </span></label> </div>
                                                            <div className="select_btn"><label><input name="site" type="radio"
                                                                value="Site 1" /><span> Low </span></label> </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col2">

                                                </div>
                                                <div className="col-sm-6 col1">
                                                    <p className="sub_title">HISTORY OF CHALLENGING BEHAVIOURS</p>
                                                    <div className="check-box mb-10">
                                                        <div className="round">
                                                            <input type="checkbox" id="checkbox-26" />
                                                            <label for="checkbox-26"></label>
                                                        </div>
                                                        Reviewed and no risk identified
                                                    </div>
                                                    <div className="check-box  mb-10">
                                                        <div className="round">
                                                            <input type="checkbox" id="checkbox-27" />
                                                            <label for="checkbox-27"></label>
                                                        </div>
                                                        Hurtful to self / Hurtful to others / Socially offensive /disruptive
                                                        violent and aggressive behaviour

                                                    </div>
                                                    <div className="check-box  mb-20">
                                                        <div className="round">
                                                            <input type="checkbox" id="checkbox-28" />
                                                            <label for="checkbox-28"></label>
                                                        </div>
                                                        At risk behaviour, such as: wandering - history of suicide attempt
                                                    </div>
                                                    <div className="check-box  mb-0">
                                                        <div className="round">
                                                            <input type="checkbox" id="checkbox-28" />
                                                            <label for="checkbox-28"></label>
                                                        </div>
                                                        Other serious behaviour
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col2 disable">
                                                    <a href="#" className="link_btn mb-20">Risk identified and ALERT process initiated
                                                        <span className="material-icons icon"> arrow_right </span></a>
                                                    <div className="yn_switch mb-20 mt-0 ">
                                                        <div className="label-container">
                                                            <p>Are medications used to control any behaviours </p>
                                                        </div>
                                                        <label for="check114" className="switch-container">
                                                            <input hidden="" type="checkbox" className="switch_check" id="check114" />
                                                            <div className="switch-bg"></div>
                                                            <div className="round-box"></div>
                                                            <div className="switch-left">
                                                                <span>NO</span>
                                                            </div>
                                                            <div className="switch-right">
                                                                <span>YES</span>
                                                            </div>
                                                        </label>
                                                    </div>
                                                    <div className="yn_switch mb-20 mt-0 ">
                                                        <div className="label-container">
                                                            <p>Currently has a formal Behaviour Plan </p>
                                                        </div>
                                                        <label for="check115" className="switch-container">
                                                            <input hidden="" type="checkbox" className="switch_check" id="check115" />
                                                            <div className="switch-bg"></div>
                                                            <div className="round-box"></div>
                                                            <div className="switch-left">
                                                                <span>NO</span>
                                                            </div>
                                                            <div className="switch-right">
                                                                <span>YES</span>
                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col1">
                                                    <p className="sub_title">COMMUNICATION</p>
                                                    <div className="check-box mb-10">
                                                        <div className="round">
                                                            <input type="checkbox" id="checkbox-26" />
                                                            <label for="checkbox-26"></label>
                                                        </div>
                                                        Problems with reading or writing Glasses - Hearing aid - Contact lenses
                                                    </div>
                                                    <div className="check-box  mb-10">
                                                        <div className="round">
                                                            <input type="checkbox" id="checkbox-27" />
                                                            <label for="checkbox-27"></label>
                                                        </div>
                                                        Clear effective speech - slurred speech
                                                    </div>
                                                    <div className="check-box  mb-20">
                                                        <div className="round">
                                                            <input type="checkbox" id="checkbox-28" />
                                                            <label for="checkbox-28"></label>
                                                        </div>
                                                        Use of non-verbal communication
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col2">
                                                    <div className="form_group disable">
                                                        <label className="label">Actions</label>
                                                        <input type="text" className="form-control" placeholder="Enter" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="popup_footer">
                                <div className="prescribe_footer">
                                    <div className="dsp-flex">
                                        <a className="icon_btn"> <span className="material-icons">save</span> SAVE</a>
                                        <button className="border_btn"><span className="material-icons">save_as</span>SAVE &amp;
                                            CLOSE
                                        </button>
                                    </div>
                                    <button className="btn dsp_flex"><span className="material-icons"><span
                                        className="material-icons">close</span></span>DISCARD </button>
                                </div>
                            </div>
                        </div>
                    </div>



                </div>
            </div>




        </React.Fragment>
    )
}

Infection.propTypes = {
    t: PropTypes.any,
    getWards: PropTypes.func,
    getTheatres: PropTypes.func,
    wards: PropTypes.array,
    user: PropTypes.any,
    theatres: PropTypes.array,
    clinic: PropTypes.array,
    patients: PropTypes.array,
    doctors: PropTypes.array,
    profile: PropTypes.object
}

const mapStateToProps = ({ Layout, Login }) => ({
    wards: Layout.wards,
    user: Login.user,
    theatres: Layout.theatres,
    clinic: Layout.clinic,
    patients: Layout.patients,
    doctors: Layout.doctors,
    profile: Layout.profile
})

const mapDispatchToProps = dispatch => ({
    getWards: () => dispatch(getWardsList()),
    getTheatres: () => dispatch(getTheatresList()),
    getClinic: () => dispatch(getClinicList()),
    getPatient: () => dispatch(getPatientList()),
    getUserprofile: (id) => dispatch(getUprofile(id)),
    getDoctor: () => dispatch(getDoctorList()),
    patientupdate: (id, data) => dispatch(updatePatient(id, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Infection)
