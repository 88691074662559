import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import MetaTags from 'react-meta-tags';


import PatientBlock from "../Clinic/patientBlock";
import { connect } from "react-redux"
import { getWardsList, getTheatresList, getClinicList, getPatientList, getUprofile, getDoctorList, updatePatient } from "../../store/actions"

const Safeguarding = props => {


    const [visiblePatients, setPatient] = useState(true)
    const [visiblelanding, setClanding] = useState(false)
    const [successmessage, setSuccess] = useState("")


    const [assesmentValues, setAssesment] = useState({})

    const {
        wards, user, theatres, clinic, patients, profile, doctors
    } = props;

    useEffect(() => {
        const { getWards, getTheatres, getClinic, getDoctor, getPatient, getUserprofile } = props
        // getWards()
        // getTheatres()
        // getClinic()
        getPatient()
        getDoctor()
        let userId = localStorage.getItem("authUser")
        getUserprofile(userId)
    }, [])

    const updateRadioValue = (e) => {
        let val = e.target.value;
        let name = e.target.name;
        let updatedValue = { [name]: val };
        setAssesment(assesmentValues => ({
            ...assesmentValues,
            ...updatedValue
        }));
    }


    const closeClick = (e) => {
        e.preventDefault()
        localStorage.removeItem("a")
        props.history.push("/riskassesment")
    }

    let assesmentPage = JSON.parse(localStorage.getItem("a"))
    let clinicPatient = JSON.parse(localStorage.getItem("p"))

    return (
        <React.Fragment>

            <MetaTags>
                <title>{assesmentPage}</title>
            </MetaTags>
            <div className="dashboard ward">
                <div className="main">

                    <div className="popup_wrp">
                        <div className="popup_full prescribe">
                            <div className="popup_hed">
                                <h2>SAFEGUARDING ADULTS </h2>
                                <div className="dsp-flex"> <a className="popup_close" onClick={(e) => closeClick(e)}><span className="material-icons">close</span></a></div>

                            </div>
                            <div className="popup_content">
                                <div className="popup_scroll w-btns main nutrition">
                                    <PatientBlock patient={clinicPatient} />
                                    <div className="bar">
                                        <div className="left">
                                            <div className="tag"> Guidance </div>
                                            <h3 className="sub_title">SAFEGUARDING </h3>
                                        </div>
                                        <div className="right">
                                            <a href="#" className="btn"> <span className="material-symbols-outlined"> assignment </span> Not
                                                Yet Done</a>
                                            <div className="tag"> Due </div>
                                        </div>
                                    </div>
                                    <div className="section ctr fb_table poc is ms">

                                        <div className="tbl_block">
                                            <div className="row m-0">
                                                <div className="col-sm-6 col1">
                                                    <div className="check-box mb-0">
                                                        <div className="round">
                                                            <input type="checkbox" id="checkbox-20" />
                                                            <label for="checkbox-20"></label>
                                                        </div>
                                                        The Patient has NO carer responsibilities for a child (0-17 years old) or a
                                                        dependent adult
                                                    </div>

                                                </div>
                                                <div className="col-sm-6 col2">

                                                </div>
                                                <div className="col-sm-6 col1">
                                                    <div className="check-box">
                                                        <div className="round">
                                                            <input type="checkbox" id="checkbox-21" />
                                                            <label for="checkbox-21"></label>
                                                        </div>
                                                        The Patient is a Carer for a child (0-17 years old) or a dependent adult
                                                    </div>
                                                    <div className="check-box mb-0 disable">
                                                        <div className="round">
                                                            <input type="checkbox" id="checkbox-22" />
                                                            <label for="checkbox-22"></label>
                                                        </div>
                                                        Alternative arrangements / actions/agreements in place for the dependant
                                                        whilst the patient is in hospital
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col2">

                                                </div>
                                                <div className="col-sm-6 col1">
                                                    <div className="check-box">
                                                        <div className="round">
                                                            <input type="checkbox" id="checkbox-24" />
                                                            <label for="checkbox-24"></label>
                                                        </div>
                                                        The Patient is a Carer for a child (0-17 years old) or a dependent adult
                                                    </div>
                                                    <div className="check-box disable">
                                                        <div className="round">
                                                            <input type="checkbox" id="checkbox-25" />
                                                            <label for="checkbox-25"></label>
                                                        </div>
                                                        NO alternative arrangements are possible for the dependant whilst the
                                                        patient is in hospital.
                                                    </div>
                                                    <div className="check-box mb-0 disable">
                                                        <div className="round">
                                                            <input type="checkbox" id="checkbox-26" />
                                                            <label for="checkbox-26"></label>
                                                        </div>
                                                        Emergency adult social care team needs to be contacted
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col2">
                                                    <a href="#" className="link_btn disable">Contact emergency adult social care <span
                                                        className="material-icons icon"> arrow_right </span></a>
                                                </div>
                                                <div className="col-sm-6 col1">
                                                    <div className="check-box mb-0">
                                                        <div className="round">
                                                            <input type="checkbox" id="checkbox-27" />
                                                            <label for="checkbox-27"></label>
                                                        </div>
                                                        The patient feels safe at home
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col2 ">

                                                </div>
                                                <div className="col-sm-6 col1">
                                                    <div className="check-box">
                                                        <div className="round">
                                                            <input type="checkbox" id="checkbox-28" />
                                                            <label for="checkbox-28"></label>
                                                        </div>
                                                        The patient does not feel safe at home. There are concerns, or disclosures
                                                        made of abuse or neglect
                                                    </div>
                                                    <div className="check-box disable">
                                                        <div className="round">
                                                            <input type="checkbox" id="checkbox-29" />
                                                            <label for="checkbox-29"></label>
                                                        </div>
                                                        <span> Extent and nature of risk <br />
                                                            <span className="small">(i.e. who, relationship, past history/pattern of
                                                                violence, known criminal history)</span></span>
                                                    </div>
                                                    <div className="check-box disable">
                                                        <div className="round">
                                                            <input type="checkbox" id="checkbox-30" />
                                                            <label for="checkbox-30"></label>
                                                        </div>
                                                        Safeguarding referral to Adult Social Care to be made
                                                    </div>
                                                    <div className="check-box disable">
                                                        <div className="round">
                                                            <input type="checkbox" id="checkbox-31" />
                                                            <label for="checkbox-31"></label>
                                                        </div>
                                                        Safeguarding Team to be contacted for advice
                                                    </div>
                                                    <div className="check-box mb-0 disable">
                                                        <div className="round">
                                                            <input type="checkbox" id="checkbox-32" />
                                                            <label for="checkbox-32"></label>
                                                        </div>
                                                        Arrangements/actions/agreements in place
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col2">
                                                    <a href="#" className="link_btn mb-10 disable">Safeguarding referral to Adult Social
                                                        Care <span className="material-icons icon"> arrow_right </span></a>
                                                    <a href="#" className="link_btn disable">Contact the Safeguarding Team for advice on
                                                        <span className="material-icons icon"> arrow_right </span></a>
                                                </div>
                                                <div className="col-sm-6 col1">
                                                    <div className="check-box mb-0">
                                                        <div className="round">
                                                            <input type="checkbox" id="checkbox-33" />
                                                            <label for="checkbox-33"></label>
                                                        </div>
                                                        The patient has capacity to make the decision to remain in hospital
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col2">

                                                </div>
                                                <div className="col-sm-6 col1">
                                                    <div className="check-box mb-0">
                                                        <div className="round">
                                                            <input type="checkbox" id="checkbox-34" />
                                                            <label for="checkbox-34"></label>
                                                        </div>
                                                        The patient lacks capacity to make the decision to remain in hospital and is
                                                        likely to stay for &gt; 10 days Patient’s best interest used to care for the
                                                        patient </div>
                                                </div>
                                                <div className="col-sm-6 col2">

                                                </div>
                                                <div className="col-sm-6 col1">
                                                    <div className="check-box mb-0">
                                                        <div className="round">
                                                            <input type="checkbox" id="checkbox-35" />
                                                            <label for="checkbox-35"></label>
                                                        </div>
                                                        The patient lacks capacity to make the decision to remain in hospital
                                                        and is likely to stay &lt; 10 days
                                                        Deprivation of liberty (DoLS) request to be completed


                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col2">
                                                    <a href="#" className="link_btn mb-10 disable">Complete Deprivation of liberty
                                                        (DoLS) request <span className="material-icons icon"> arrow_right
                                                        </span></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>


                            <div className="popup_footer">
                                <div className="prescribe_footer">
                                    <div className="dsp-flex">
                                        <a className="icon_btn"> <span className="material-icons">save</span> SAVE</a>
                                        <button className="border_btn"><span className="material-icons">save_as</span>SAVE &amp;
                                            CLOSE
                                        </button>
                                    </div>
                                    <button className="btn dsp_flex"><span className="material-icons"><span
                                        className="material-icons">close</span></span>DISCARD </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



            </div>


        </React.Fragment >
    )
}

Safeguarding.propTypes = {
    t: PropTypes.any,
    getWards: PropTypes.func,
    getTheatres: PropTypes.func,
    wards: PropTypes.array,
    user: PropTypes.any,
    theatres: PropTypes.array,
    clinic: PropTypes.array,
    patients: PropTypes.array,
    doctors: PropTypes.array,
    profile: PropTypes.object
}

const mapStateToProps = ({ Layout, Login }) => ({
    wards: Layout.wards,
    user: Login.user,
    theatres: Layout.theatres,
    clinic: Layout.clinic,
    patients: Layout.patients,
    doctors: Layout.doctors,
    profile: Layout.profile
})

const mapDispatchToProps = dispatch => ({
    getWards: () => dispatch(getWardsList()),
    getTheatres: () => dispatch(getTheatresList()),
    getClinic: () => dispatch(getClinicList()),
    getPatient: () => dispatch(getPatientList()),
    getUserprofile: (id) => dispatch(getUprofile(id)),
    getDoctor: () => dispatch(getDoctorList()),
    patientupdate: (id, data) => dispatch(updatePatient(id, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Safeguarding)
