import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import MetaTags from 'react-meta-tags';
import Moment from 'moment';
import { removeSpecialCharacters } from '../../utils/utils';
import SignaturePad from 'react-signature-canvas'
import {
    Modal
} from "reactstrap"
import { connect } from "react-redux"
import { getWardsList, getTheatresList, getClinicList, getPatientList, getUprofile, getDoctorList, updateTheatrePatient, updateWardPatient, updatePatient } from "../../store/actions"

const clinicConsent4 = props => {

    const [clinicValues, setClinic] = useState({})
    const [consentValues, setConsent] = useState({})
    const [discard_popup, setdiscard_popup] = useState(false)
    const [file, setFile] = useState({});
    const [success_msg, setsuccess_msg] = useState(false)
    const {
        wards, user, theatres, clinic, patients, profile, doctors, setvisibleConsentForm4
    } = props;

    let staffsigPad = useRef(null);

    useEffect(() => {
        const { getPatient } = props
        getPatient()
    }, [])

    useEffect(() => {
        let clinicPatient = JSON.parse(localStorage.getItem("p"))
        if (patients && patients.length > 0 && clinicPatient && clinicPatient._id) {
            const cPatient = patients.filter(
                usr => usr._id === clinicPatient._id
            )
            if (cPatient && cPatient[0]) {
                let cpatientData = cPatient[0]
                delete cpatientData["_id"]
                setClinic(clinicValues => ({
                    ...clinicValues,
                    ...cpatientData
                }));
            }

        }
    }, [patients])

    const updateInputValue = (e) => {
        let val = e.target.value;
        let name = e.target.name;
        let updatedValue = {};
        updatedValue = { [name]: val };
        setClinic(clinicValues => ({
            ...clinicValues,
            ...updatedValue
        }));
        setConsent(consentValues => ({
            ...consentValues,
            ...updatedValue
        }));
    }

    const updateClinicValues = (e, name, val) => {
        e.preventDefault()
        let updatedValue = {};
        updatedValue = { [name]: val };
        setClinic(clinicValues => ({
            ...clinicValues,
            ...updatedValue
        }));
    }

    const updateClinic = (e) => {
        const { patientupdate } = props
        let clinicPatient = JSON.parse(localStorage.getItem("p"))
        let id = clinicPatient._id
        clinicValues["clinic_consent"] = "consent4"
        patientupdate(id, clinicValues)
        let patient = {
            ...clinicPatient,
            ...clinicValues
        }
        localStorage.setItem("p", JSON.stringify(patient))
        setsuccess_msg(!success_msg)

    }


    const handleChange = (event) => {
        updateClinicValues(event, event.target.name, event.target.checked)
    }

    const updateMultipleValues = (e, name, val, type) => {
        e.preventDefault()
        let tvalues = clinicValues
        e.target.parentElement.classList.add('active');
        if (tvalues[name]) {
            const exists = tvalues[name].includes(val);
            if (!exists) {
                tvalues[name].push(val)
            }

        } else {
            tvalues = {
                [name]: [val]
            }
        }
        // let updatedValue = {};
        // tvalues[type] = true
        setClinic(clinicValues => ({
            ...clinicValues,
            ...tvalues
        }));

    }

    const handleSelectGroup = (selectedGroup, name) => {
        let updatedValue = { [name]: selectedGroup };
        setClinic(clinicValues => ({
            ...clinicValues,
            ...updatedValue
        }));
    }

    const handleFile = async (e, name) => {

        const file = e.target.files[0]
        console.log("file ", file, name)
        let filename = e.target.files[0].name;
        // const base64 = await convertBase64(file)
        console.log("filename ", filename)
        let pdetails = JSON.parse(localStorage.getItem("p"))
        // var reader = new FileReader();
        //   reader.readAsDataURL(file);
        // reader.onload = async function (e) {
        // console.log("e ", e)
        let uploadFilePath = "ipr/" + pdetails._id + "/" + name + "_result" + "/" + removeSpecialCharacters(filename);
        let data = { source_bucket: "ipr-resources", sourcepath: uploadFilePath }
        console.log("data ", data)

        const response = await fetch(API_URL+'upload?appname=ipr', {
            method: "POST",
            mode: "cors",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
        const result = await response.json();

        console.log("result ", result)
        let url = result.result
        console.log("url ", url)
        const uploadImage = await fetch(url, {
            method: "PUT",
            mode: "cors",
            headers: {
                "Content-Type": "multipart/form-data",
                "Accept": "/",
                "Cache-Control": "no-cache",
                "Accept-Encoding": "gzip, deflate",
                "Connection": "keep-alive",
                "cache-control": "no-cache"
            },
            body: file
        })

        console.log("resultImage ", uploadImage)

        let imagePath = "https://ipr-resources.s3.amazonaws.com/"
        let imageUploadPath = imagePath + uploadFilePath;

        const { patientupdate } = props
        let updateObj = {
            [name + "_result"]: imageUploadPath,
            [name + "_update"]: new Date()
        }
        patientupdate(pdetails._id, updateObj)
        setFile(file => ({
            ...file,
            ...updateObj
        }));
        setClinic(clinicValues => ({
            ...clinicValues,
            ...updateObj
        }));

        let patient = {
            ...pdetails,
            ...updateObj
        }
        localStorage.setItem("p", JSON.stringify(patient))

    };


    //console.log("clinicValues 4 ", clinicValues)

    return (
        <React.Fragment>

            <MetaTags>
                <title>SURGICAL RECORD</title>
            </MetaTags>
            <div className="theatre">

                <div className='popup_wrp'>
                    <div className="popup_full prescribe">
                        <div className='popup_hed'>
                            <h2>CONSENT 4</h2>

                            <a className='popup_close' onClick={(e) => { setvisibleConsentForm4(false) }}><span className="material-icons">close</span></a>
                        </div>
                        <div className='popup_content'>

                            <div className='popup_scroll main pr height175 '>
                            
                                <div className="section simple_wound ctr">
                                   
                                    <h2 className="popup_title">FORM FOR ADULTS WHO LACK THE CAPACITY TO CONSENT TO INVESTIGATION OR
                                        TREATMENT</h2>
                                    <p className="note2">All sections to be completed by health professional proposing the procedure
                                    </p>
                                    <div className="ctr mb-20">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form_group">
                                                    <div className="floating-label-group"><input type="text" value={clinicValues.firstname}
                                                        className="form-control" autocomplete="off" /><label
                                                            className="floating-label">First Name</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form_group">
                                                    <div className="floating-label-group"><input type="text" value={clinicValues.lastname}
                                                        className="form-control" autocomplete="off" /><label
                                                            className="floating-label">Second Name</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form_group">
                                                    <div className="floating-label-group"><input type="text" value={clinicValues.sex}
                                                        className="form-control" autocomplete="off" /><label
                                                            className="floating-label">Sex</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form_group">
                                                    <div className="floating-label-group"><input type="text" value={clinicValues.age}
                                                        className="form-control" autocomplete="off" /><label
                                                            className="floating-label">Age</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form_group">
                                                    <div className="floating-label-group"><input type="text" value={clinicValues.dob}
                                                        className="form-control" autocomplete="off" /><label
                                                            className="floating-label">DOB</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form_group">
                                                    <div className="floating-label-group"><input type="text" value={clinicValues.hospitalNo}
                                                        className="form-control" autocomplete="off" /><label
                                                            className="floating-label">Hospital Number</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form_group">
                                                    <div className="floating-label-group"><input type="text" value={clinicValues.hospitalNo}
                                                        className="form-control" autocomplete="off" /><label
                                                            className="floating-label">NHS Number</label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form_group">
                                                    <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="cli_con1_responsible" value={clinicValues.cli_con1_responsible}
                                                        className="form-control" autocomplete="off" /><label
                                                            className="floating-label">Responsible Consultant</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form_group">
                                                    <div className="floating-label-group"><input type="time" onChange={(e) => updateInputValue(e)} name="cli_con1_time" value={clinicValues.cli_con1_time}
                                                        className="form-control" autocomplete="off" /><label
                                                            className="floating-label">Time</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form_group">
                                                    <div className="floating-label-group"><input type="date" onChange={(e) => updateInputValue(e)} name="cli_con1_date" value={clinicValues.cli_con1_date}
                                                        className="form-control" autocomplete="off" /><label
                                                            className="floating-label">Date</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form_group">
                                                    <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="cli_con1_loc" value={clinicValues.cli_con1_loc}
                                                        className="form-control" autocomplete="off" /><label
                                                            className="floating-label">Location</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                    <div className="ctr mt-20 mb-20">
                                        <p className="field_title mt-0">Special requirements</p>
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="cli_con1_spcl_requirement" value={clinicValues.cli_con1_spcl_requirement}
                                                className="form-control" autocomplete="off" /><label
                                                    className="floating-label">(eg other language/other communication
                                                    method)</label>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="ctr mt-20 mb-20">
                                        <h2 className="popup_title">A. DETAILS OF PROCEDURE OR COURSE OF TREATMENT PROPOSED.</h2>
                                        <p className="note2">(NB see guidance to health professionals for details of situations
                                            where court approval must first be sought)</p>


                                        <div className="check_item mt-20"><label className="check">NAME OF PROPOSED PROCEDURE OR COURSE
                                            OF TREATMENT</label>

                                            {clinicValues.cli_con1_course_treatment ?
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input type="radio"
                                                    value={clinicValues.cli_con1_course_treatment} checked /><span>{clinicValues.cli_con1_course_treatment} </span></label>
                                                </div> {clinicValues.cli_con1_course_treatment && <a onClick={(e) => updateClinicValues(e, "cli_con1_course_treatment", false)}><span className="material-symbols-outlined"> reply
                                                </span></a>}

                                            </div>
                                            :
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="cli_con1_course_treatment" type="radio"
                                                    value="RIGHT" /><span>RIGHT</span></label></div>
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="cli_con1_course_treatment" type="radio"
                                                    value="LEFT" /><span>LEFT</span></label></div>
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="cli_con1_course_treatment" type="radio"
                                                    value="BILATER AL" /><span>BILATER AL</span></label></div>
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="cli_con1_course_treatment" type="radio"
                                                    value="NA" /><span>NA</span></label></div>

                                            </div>}
                                        </div>

                                    </div>
                                    <div className="ctr mt-20 mb-20">
                                        <h2 className="popup_title2">B. ASSESSMENT OF PATIENT’S CAPACITY<span> (IN ACCORDANCE WITH
                                            THE MENTAL CAPACITY ACT)</span></h2>
                                        <p className="note2">I confirm that the patient lacks capacity to give or withhold consent
                                            to this procedure or course of treatment
                                            because of an impairment of the mind or brain or disturbance affecting the way their
                                            mind or brain works (for
                                            example, a disability, condition or trauma or the effect of drugs or alcohol) and
                                            they cannot do one or more of
                                            the following:</p>

                                        <p className="note2" >Select all that apply for Multiselect forms {clinicValues.cli_con1_assesment_capacity && <a onClick={(e) => updateClinicValues(e, "cli_con1_assesment_capacity", false)}><span className="material-symbols-outlined"> reply </span></a>}</p>
                                            {clinicValues.cli_con1_assesment_capacity && clinicValues.cli_con1_assesment_capacity_list && clinicValues.cli_con1_assesment_capacity_list.length > 0 ? <div className="check_list w_white mb-0">

                                                {clinicValues.cli_con1_assesment_capacity_list.map((item, i) => (
                                                    <div className="check_item active" key={i} onClick={(e) => updateClinicValues(e, "ane_biomedical", false)} ><label className="check">{item}</label></div>
                                                ))}

                                            </div> :        
                                        <div className="check_list w_white">
                                            <span className="check_btn2" onClick={(e) => updateClinicValues(e, "cli_con1_assesment_capacity", true)}><span className="material-icons"> done </span></span>

                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "cli_con1_assesment_capacity_list", "Understand information about the procedure or course of treatment", "cli_con1_assesment_capacity")}><label className="check">Understand information about the procedure or course of treatment</label>
                                            </div>

                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "cli_con1_assesment_capacity_list", "Retain that information in their mind", "cli_con1_assesment_capacity")}><label className="check">Retain that information in their mind</label>
                                            </div>
                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "cli_con1_assesment_capacity_list", "Use or weigh that information as part of the decision-making process", "cli_con1_assesment_capacity")}><label className="check">Use or weigh that information as part of the decision-making process</label>
                                            </div>
                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "cli_con1_assesment_capacity_list", "Communicate their decision (by talking, using sign language or any other means)", "cli_con1_assesment_capacity")}><label className="check">Communicate their decision (by talking, using sign language or any other means)</label>
                                            </div>
                                        </div>
}

                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="cli_con1_further_details" value={clinicValues.cli_con1_further_details}
                                                className="form-control" autocomplete="off" /><label
                                                    className="floating-label">Further details</label>
                                            </div>
                                        </div>
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="cli_con1_judgements" value={clinicValues.cli_con1_judgements}
                                                className="form-control" autocomplete="off" /><label
                                                    className="floating-label">How the above judgements are reached</label>
                                            </div>
                                        </div>
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="cli_con1_Colleagues" value={clinicValues.cli_con1_Colleagues}
                                                className="form-control" autocomplete="off" /><label
                                                    className="floating-label">Colleagues consulted:</label>
                                            </div>
                                        </div>
                                        <p className="note2">Attempts made to assist the patient make his or her own decision and
                                            why these were not successful.</p>

                                    </div>

                                    <div className="ctr mt-20 mb-20">
                                        <h2 className="popup_title2">C. ASSESSMENT OF PATIENT’S BEST INTERESTS</h2>
                                        <ul>
                                            <li>I am satisfied that the patient has not refused this procedure in a valid
                                                advance decision.</li>
                                            <li>As far as is reasonably possible, I have considered the person’s past and
                                                present wishes and feelings (in
                                                particular if they have been written down) any beliefs and values that would be
                                                likely to influence the
                                                decision in question.</li>
                                            <li>As far as possible, I have consulted other people (those involved in caring for
                                                the patient, interested in
                                                their welfare or the patient has said should be consulted) as appropriate^</li>

                                        </ul>
                                        <p>I have considered the patient’s best interests in accordance with the requirements of
                                            the Mental Capacity Act
                                            and believe the procedure to be in their best interests because:</p>
                                        <div className="row">
                                            <div className="col-md-1">
                                                <p className="field_title">1</p>
                                            </div>
                                            <div className="col-md-11">
                                                <div className="form_group">
                                                    <div className="floating-label-group"><input type="text"  onChange={(e) => updateInputValue(e)} name="cli_con1_patient_interests_1" value={clinicValues.cli_con1_patient_interests_1}
                                                        className="form-control" autocomplete="off" /><label
                                                            className="floating-label"></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-1">
                                                <p className="field_title">2</p>
                                            </div>
                                            <div className="col-md-11">
                                                <div className="form_group">
                                                    <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="cli_con1_patient_interests_2" value={clinicValues.cli_con1_patient_interests_2}
                                                        className="form-control" autocomplete="off" /><label
                                                            className="floating-label"></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-1">
                                                <p className="field_title">3</p>
                                            </div>
                                            <div className="col-md-11">
                                                <div className="form_group">
                                                    <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="cli_con1_patient_interests_3" value={clinicValues.cli_con1_patient_interests_3}
                                                        className="form-control" autocomplete="off" /><label
                                                            className="floating-label"></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <p>Where the lack of capacity is likely to be temporary, for example if patient
                                            unconscious, or where patient
                                            has fluctuating capacity: The treatment cannot wait until the patient recovers
                                            capacity because:</p>
                                        <div className="row">
                                            <div className="col-md-1">
                                                <p className="field_title">1</p>
                                            </div>
                                            <div className="col-md-11">
                                                <div className="form_group">
                                                    <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="cli_con1_patient_recovery_1" value={clinicValues.cli_con1_patient_recovery_1}
                                                        className="form-control" autocomplete="off" /><label
                                                            className="floating-label"></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-1">
                                                <p className="field_title">2</p>
                                            </div>
                                            <div className="col-md-11">
                                                <div className="form_group">
                                                    <div className="floating-label-group"><input type="text"  onChange={(e) => updateInputValue(e)} name="cli_con1_patient_recovery_2" value={clinicValues.cli_con1_patient_recovery_2}
                                                        className="form-control" autocomplete="off" /><label
                                                            className="floating-label"></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-1">
                                                <p className="field_title">3</p>
                                            </div>
                                            <div className="col-md-11">
                                                <div className="form_group">
                                                    <div className="floating-label-group"><input type="text"  onChange={(e) => updateInputValue(e)} name="cli_con1_patient_recovery_3" value={clinicValues.cli_con1_patient_recovery_3}
                                                        className="form-control" autocomplete="off" /><label
                                                            className="floating-label"></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="ctr mt-20 mb-20">
                                        <h2 className="popup_title2">D. INVOLVEMENT OF THE PATIENT’S FAMILY AND OTHERS CLOSE TO THE
                                            PATIENT</h2>
                                        <p className="note2">(Unless the person has an attorney or deputy, the final responsibility
                                            for determining what is in a person’s best interest
                                            will rest with the relevant health professional. However, the health professional
                                            must consult with those close to the
                                            patient (eg spouse/partner, family and friends, carer, supporter or advocate) as far
                                            as is practicable and as
                                            appropriate, (To be signed by a person or persons close to the patient, if they
                                            wish), treatments (including no
                                            treatment) and any particular concerns of this patient and his or her parents)</p>
                                        <p className="note2">I/We have been involved in a discussion with the relevant health
                                            professionals over the treatment of
                                            (Patient’s name).</p>
                                        <p className="note2">I/We understand that he/she is unable to give his/her own consent,
                                            based on the criteria set out in this form.</p>
                                        <p className="note2">I/We also understand that treatment can lawfully be provided if it is
                                            in his/her best interests to receive it.</p>
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text"  onChange={(e) => updateInputValue(e)} name="cli_con1_comments" value={clinicValues.cli_con1_comments}
                                                className="form-control" autocomplete="off" /><label
                                                    className="floating-label">Any other comments (including any concerns about
                                                    decision) Details:</label>
                                            </div>
                                        </div>
                                        <div className="signature_block">
                                       
                                        <SignaturePad canvasProps={{ className: "sigPad" }} ref={(ref) => { staffsigPad = ref }} />
                                        <p className="mt-30 mb-30">Parent&#39;s Signature</p>
                                        </div>
                                    
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="form_group">
                                                    <div className="floating-label-group"><input type="date" onChange={(e) => updateInputValue(e)} name="cli_con1_parent_date" value={clinicValues.cli_con1_parent_date}
                                                        className="form-control" autocomplete="off" /><label
                                                            className="floating-label">Date</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form_group">
                                                    <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="cli_con1_parent_relation" value={clinicValues.cli_con1_parent_relation}
                                                        className="form-control" autocomplete="off" /><label
                                                            className="floating-label">Relationship to patient</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form_group">
                                                    <div className="floating-label-group"><input type="text"  onChange={(e) => updateInputValue(e)} name="cli_con1_parent_name" value={clinicValues.cli_con1_parent_name}
                                                        className="form-control" autocomplete="off" /><label
                                                            className="floating-label">Name</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form_group">
                                                    <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="cli_con1_pt_address" value={clinicValues.cli_con1_pt_address}
                                                        className="form-control" autocomplete="off" /><label
                                                            className="floating-label">Address (if not the same as patient)</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="yn_switch mt-20 ">
                                            <div className="label-container">
                                                <p>If the person close to the patient was not available in person, has this
                                                    matter been discussed in any other
                                                    way (e.g. over the telephone?)</p>
                                            </div>
                                            <label className="switch-container">
                                                <input type="checkbox" className="switch_check" name="cli_con1_pt_over_telephone" onChange={(e) => handleChange(e)} checked={clinicValues.cli_con1_pt_over_telephone} />
                                                <div className="switch-bg"></div>
                                                <div className="round-box"></div>
                                                <div className="switch-left">
                                                    <span>YES</span>
                                                </div>
                                                <div className="switch-right">
                                                    <span>NO</span>
                                                </div>
                                            </label>
                                        </div>
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="cli_con1_pt_details" value={clinicValues.cli_con1_pt_details}
                                                className="form-control" autocomplete="off" /><label
                                                    className="floating-label">Details</label>
                                            </div>
                                        </div>

                                        <p className="mt-20"> <strong>Independent Mental Capacity Advocate (IMCA)</strong> </p>
                                        <div className="yn_switch mt-20 ">
                                            <div className="label-container">
                                                <p>For decisions about serious medical treatment, where there is no one
                                                    appropriate to consult other than paid
                                                    staff, has an Independent Mental Capacity Advocate (IMCA) been instructed?
                                                </p>
                                            </div>
                                            <label className="switch-container">
                                                <input  type="checkbox" className="switch_check" name="cli_con1_pt_mental_capacity" onChange={(e) => handleChange(e)} checked={clinicValues.cli_con1_pt_mental_capacity} />
                                                <div className="switch-bg"></div>
                                                <div className="round-box"></div>
                                                <div className="switch-left">
                                                    <span>YES</span>
                                                </div>
                                                <div className="switch-right">
                                                    <span>NO</span>
                                                </div>
                                            </label>
                                        </div>
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="cli_con1_pt_independent_details" value={clinicValues.cli_con1_pt_independent_details}
                                                className="form-control" autocomplete="off" /><label
                                                    className="floating-label">Details</label>
                                            </div>
                                        </div>
                                      
                                        <div className="signature_block">
                                       
                                        <SignaturePad canvasProps={{ className: "sigPad" }} ref={(ref) => { staffsigPad = ref }} />
                                        <p className="mt-30 mb-30">Signature</p>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="form_group">
                                                    <div className="floating-label-group"><input type="date" onChange={(e) => updateInputValue(e)} name="cli_con1_pt_Date" value={clinicValues.cli_con1_pt_Date}
                                                        className="form-control" autocomplete="off" /><label
                                                            className="floating-label">Date</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form_group">
                                                    <div className="floating-label-group"><input type="text"  onChange={(e) => updateInputValue(e)} name="cli_con1_pt_name" value={clinicValues.cli_con1_pt_name}
                                                        className="form-control" autocomplete="off" /><label
                                                            className="floating-label">Name</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-5">
                                                <div className="form_group">
                                                    <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="cli_con1_pt_job" value={clinicValues.cli_con1_pt_job}
                                                        className="form-control" autocomplete="off" /><label
                                                            className="floating-label">Job Title:</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                    <div className="ctr mt-20 mb-20">
                                        <h2 className="popup_title2">E. THE PATIENT HAS AN ATTORNEY OR DEPUTY</h2>
                                        <p className="note">(Where the patient has authorized an attorney to make decisions about
                                            the procedure in question under a Lasting Power of Attorney or a Court
                                            Appointed Deputy has been authorized to make decisions about the procedure in
                                            question, the attorney or deputy will have the final responsibility
                                            for determining whether a procedure is in the patient’s best interests.)</p>
                                        <ul>
                                            <li>I have been authorized to make decisions about the procedure in question
                                                Under a Lasting Power of Attorney / as a Court Appointed Deputy</li>
                                            <li>I have considered the relevant circumstances relating to the decision in
                                                question (see section C) and
                                                believe the procedure to be in the patient’s best interests.</li>
                                            <li>Any other comments (including the circumstances considered in assessing the
                                                patient’s best interests)</li>

                                        </ul>
                                        <div className="signature_block">
                                       
                                       <SignaturePad canvasProps={{ className: "sigPad" }} ref={(ref) => { staffsigPad = ref }} />
                                       <p className="mt-30 mb-30">Signature of attorney or deputy</p>
                                       </div>
                                        
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form_group">
                                                    <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="cli_con1_deputy_name" value={clinicValues.cli_con1_deputy_name}
                                                        className="form-control" autocomplete="off" /><label
                                                            className="floating-label">Name</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form_group">
                                                    <div className="floating-label-group"><input type="date" onChange={(e) => updateInputValue(e)} name="cli_con1_deputy_date" value={clinicValues.cli_con1_deputy_date}
                                                        className="form-control" autocomplete="off" /><label
                                                            className="floating-label">Date</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <p className="mt-20"> <strong>SIGNATURE OF HEALTH PROFESSIONAL PROPOSING TREATMENT</strong>
                                        </p>
                                        <p className="note">The above procedure is, in my clinical judgement, in the best interests
                                            of the patient, who lacks capacity to
                                            consent for himself or herself. Where possible and appropriate I have discussed the
                                            patient’s condition with
                                            those close to him or her, and taken their knowledge of the patient’s views and
                                            beliefs into account in
                                            determining his or her best interests.</p>
                                        <div className="check_list w_white">
                                            <div className="check_item"><label className="check">I have not sought a second
                                                opinion.</label>
                                            </div>

                                        </div>
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="cli_con1_second_option_signed" value={clinicValues.cli_con1_second_option_signed}
                                                className="form-control" autocomplete="off" /><label
                                                    className="floating-label">Signed</label>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="form_group">
                                                    <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="cli_con1_second_option_date" value={clinicValues.cli_con1_second_option_date}
                                                        className="form-control" autocomplete="off" /><label
                                                            className="floating-label">Date</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-9">
                                                <div className="form_group">
                                                    <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="cli_con1_second_option_name" value={clinicValues.cli_con1_second_option_name}
                                                        className="form-control" autocomplete="off" /><label
                                                            className="floating-label">Name</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="check_item">
                                            {clinicValues.cli_con1_second_consultant ?
                                                <div className="select_btns popup_select">
                                                    <div className="select_btn"><label><input type="radio"
                                                        value={clinicValues.cli_con1_second_consultant} checked /><span>{clinicValues.cli_con1_second_consultant} </span></label>
                                                    </div> {clinicValues.cli_con1_second_consultant && <a onClick={(e) => updateClinicValues(e, "cli_con1_second_consultant", false)}><span className="material-symbols-outlined"> reply
                                                    </span></a>}

                                                </div>
                                                :
                                                <div className="select_btns popup_select mt-0">
                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="cli_con1_second_consultant" type="radio"
                                                        value="Consultant" /><span>Consultant</span></label></div>
                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="cli_con1_second_consultant" type="radio"
                                                        value="Registrar" /><span>Registrar</span></label>
                                                    </div>

                                                </div>}
                                            </div>

                                        </div>

                                        <div className="check_list w_white">
                                            <div className="check_item"><label className="check">I have sought a second opinion.</label>
                                            </div>
                                        </div>
                                        <p className="note">I confirm my agreement with the decision (Where second opinion sought,
                                            she/he should sign below)</p>
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="cli_con1_sought_signed" value={clinicValues.cli_con1_sought_signed}
                                                className="form-control" autocomplete="off" /><label
                                                    className="floating-label">Signed</label>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="form_group">
                                                    <div className="floating-label-group"><input type="date" onChange={(e) => updateInputValue(e)} name="cli_con1_sought_date" value={clinicValues.cli_con1_sought_date}
                                                        className="form-control" autocomplete="off" /><label
                                                            className="floating-label">Date</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-9">
                                                <div className="form_group">
                                                    <div className="floating-label-group"><input type="text"  onChange={(e) => updateInputValue(e)} name="cli_con1_sought_name" value={clinicValues.cli_con1_sought_name}
                                                        className="form-control" autocomplete="off" /><label
                                                            className="floating-label">Name</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="check_item">
                                                {clinicValues.cli_con1_sought_consultant ?
                                                <div className="select_btns popup_select">
                                                    <div className="select_btn"><label><input type="radio"
                                                        value={clinicValues.cli_con1_sought_consultant} checked /><span>{clinicValues.cli_con1_sought_consultant} </span></label>
                                                    </div> {clinicValues.cli_con1_sought_consultant && <a onClick={(e) => updateClinicValues(e, "cli_con1_sought_consultant", false)}><span className="material-symbols-outlined"> reply
                                                    </span></a>}

                                                </div>
                                                :
                                                <div className="select_btns popup_select mt-0">
                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="cli_con1_sought_consultant" type="radio"
                                                        value="Consultant" /><span>Consultant</span></label></div>
                                                    <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="cli_con1_sought_consultant" type="radio"
                                                        value="Registrar" /><span>Registrar</span></label>
                                                    </div>

                                                </div>}
                                            </div>

                                        </div>
                                        <div className="yn_switch  ">
                                            <div className="label-container">
                                                <p>Copy accepted by patient</p>
                                            </div>
                                            <label className="switch-container">
                                                <input type="checkbox" className="switch_check" name="cli_con1_copyaccepted" onChange={(e) => handleChange(e)} checked={clinicValues.cli_con1_copyaccepted} />
                                                    <div className="switch-bg"></div>
                                                    <div className="round-box"></div>
                                                    <div className="switch-left">
                                                        <span>YES</span>
                                                    </div>
                                                    <div className="switch-right">
                                                        <span>NO</span>
                                                    </div>
                                            </label>
                                        </div>
                                        <div className="check_item">
                                            <div className="select_btns popup_select mt-0">
                                                <div className="select_btn"><label><input name="" type="radio"
                                                    value="" /><span>SAVE PDF</span></label></div>
                                                <div className="select_btn"><label><input name="" type="radio"
                                                    value="" /><span>VIEW PDF</span></label>
                                                </div>
                                                <div className="select_btn"><label><input name="" type="radio"
                                                    value="" /><span>SEND TO PATIENT RECORD</span></label></div>
                                                <div className="select_btn"><label><input name="" type="radio"
                                                    value="" /><span>SE MAIL TO PATIENT</span></label></div>
                                            </div>
                                        </div>




                                    </div>


                                </div>

                               
                            </div>
                            <div className='popup_footer'>
                                <div className="prescribe_footer">
                                    <div className="dsp-flex">
                                        <a className="icon_btn" onClick={(e) => { updateClinic(e), setvisibleConsentForm4(false) }}> <span className="material-icons">save</span> SAVE AS COMPLETE </a>
                                        <button className="border_btn" onClick={(e) => { updateClinic(e) }} ><span className="material-icons">save_as</span>SEND TO PATIENT
                                            RECORD
                                        </button>
                                    </div>
                                    <button className="btn dsp_flex" onClick={(e) => { setvisibleConsentForm4(false) }}><span className="material-icons"><span
                                        className="material-icons">close</span></span>CANCEL
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <Modal
                    isOpen={success_msg}
                    toggle={() => {
                        setsuccess_msg(false)
                    }}
                    centered={true}
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0"></h5>
                        <button
                            type="button"
                            onClick={() => {
                                setsuccess_msg(false)
                            }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <h1>Updated Successfully</h1>
                    </div>
                </Modal>

                <Modal
                    isOpen={discard_popup}
                    toggle={() => {
                        setdiscard_popup(false)
                    }}
                    centered={true}
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0"></h5>
                        <button
                            type="button"
                            onClick={() => {
                                setdiscard_popup(false)
                            }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <h4>Discard the Changes</h4>
                        <button onClick={() => { setConsent({}), setdiscard_popup(false) }} className='btn btn-success' >Yes</button>
                        <button onClick={() => { setdiscard_popup(false) }} className='btn btn-danger' >No</button>
                    </div>
                </Modal>

            </div>

        </React.Fragment>
    )
}

clinicConsent4.propTypes = {
    t: PropTypes.any,
    getWards: PropTypes.func,
    getTheatres: PropTypes.func,
    wards: PropTypes.array,
    user: PropTypes.any,
    theatres: PropTypes.array,
    clinic: PropTypes.array,
    patients: PropTypes.array,
    doctors: PropTypes.array,
    profile: PropTypes.object
}

const mapStateToProps = ({ Layout, Login }) => ({
    wards: Layout.wards,
    user: Login.user,
    theatres: Layout.theatres,
    clinic: Layout.clinic,
    patients: Layout.patients,
    doctors: Layout.doctors,
    profile: Layout.profile
})

const mapDispatchToProps = dispatch => ({
    getWards: () => dispatch(getWardsList()),
    getTheatres: () => dispatch(getTheatresList()),
    getClinic: () => dispatch(getClinicList()),
    getPatient: () => dispatch(getPatientList()),
    getUserprofile: (id) => dispatch(getUprofile(id)),
    getDoctor: () => dispatch(getDoctorList()),
    theatreupdate: (id, data) => dispatch(updateTheatrePatient(id, data)),
    wardupdate: (id, data) => dispatch(updateWardPatient(id, data)),
    patientupdate: (id, data) => dispatch(updatePatient(id, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(clinicConsent4)
