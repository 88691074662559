import React from "react";

function IconRightAngle(props) {
   return (
    <svg
      width="8"
      height="13"
      viewBox="0 0 8 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.1716 6.36398L0.2218 11.3137L1.636 12.7279L8 6.36398L1.636 0L0.2218 1.41421L5.1716 6.36398Z"
        fill="black"
      />
    </svg>
  );
}

export default IconRightAngle;
