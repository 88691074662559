import React from "react";

function IconSuccess(props) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="16" fill="#3BCDA1" />
      <path
        d="M14 19.1765L22.6667 10L24 11.4118L14 22L8 15.6471L9.33334 14.2353L14 19.1765Z"
        fill="#F8F8F8"
      />
    </svg>
  );
}

export default IconSuccess;
