import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import MetaTags from 'react-meta-tags';
import SignaturePad from 'react-signature-canvas'
import PatientBlock from "../Clinic/patientBlock";
import { removeSpecialCharacters } from '../../utils/utils';
import { Buffer } from 'buffer';
import { connect } from "react-redux"
import { getWardsList, getTheatresList, getClinicList, getPatientList, getUprofile, getDoctorList, updatePatient } from "../../store/actions"

const Procedures = props => {

    let provider_sign = useRef(null);
    let responsibility_sign = useRef(null);
    let photograph_sign = useRef(null);

    const [file, setFile] = useState({});
    const [legalInfo, setLegalInfo] = useState(false)
    const [clinicValues, setClinic] = useState({})

    const [selectedGroup, setselectedGroup] = useState(null)
    const [popupname, setpopup_name] = useState({})
    const [anydelay, setDelay] = useState(false)

    const {
        wards, user, theatres, clinic, patients, profile, doctors
    } = props;

    useEffect(() => {
        const { getWards, getTheatres, getClinic, getDoctor, getPatient, getUserprofile } = props
        // getWards()
        // getTheatres()
        // getClinic()
        getPatient()
        getDoctor()
        let userId = localStorage.getItem("authUser")
        getUserprofile(userId)
    }, [])


    useEffect(() => {
        let clinicPatient = JSON.parse(localStorage.getItem("p"))
        if (patients && patients.length > 0 && clinicPatient && clinicPatient._id) {
            const cPatient = patients.filter(
                usr => usr._id === clinicPatient._id
            )
            if (cPatient && cPatient[0]) {
                let cpatientData = cPatient[0]
                delete cpatientData["_id"]
                setClinic(clinicValues => ({
                    ...clinicValues,
                    ...cpatientData
                }));
            }

        }
    }, [patients])


    const updateInputValue = (e) => {
        let val = e.target.value;
        let name = e.target.name;
        let updatedValue = {};
        updatedValue = { [name]: val };
        setLegalInfo(legalInfo => ({
            ...legalInfo,
            ...updatedValue
        }));

    }

    const updateInputValues = (name, popupname, val) => {
        //  e.preventDefault()
        // let updatedValue = {
        //    [popupname]:{[name] : val}
        // };
        let updatedValue = clinicValues;
        //updatedValue = { [name]: val };
        // updatedValue[popupname][name] = val;
        if (updatedValue[popupname]) {
            updatedValue[popupname][name] = val;
        } else {
            updatedValue = {
                [popupname]: { [name]: val }

            };
        }
        console.log("updatedValue 2", updatedValue)
        setClinic(clinicValues => ({
            ...clinicValues,
            ...updatedValue
        }));
    }

    const updateClinic = (e) => {
        const { patientupdate } = props
        let clinicPatient = JSON.parse(localStorage.getItem("p"))
        let id = clinicPatient._id
        patientupdate(id, clinicValues)
        let patient = {
            ...clinicPatient,
            ...clinicValues
        }
        localStorage.setItem("p", JSON.stringify(patient))



    }

    const updateSwitchValue = (name, value) => {
        let updatedValue = {};
        updatedValue = { [name]: value };
        setLegalInfo(legalInfo => ({
            ...legalInfo,
            ...updatedValue
        }));

    }

    const handleChange = (event) => {
        updateSwitchValue(event.target.name, event.target.checked)
    }


    const clear = (e, refvalue) => {
        e.preventDefault()
        refvalue.clear()
    }
    const trim = (e, type, refvalue) => {
        e.preventDefault()
        sigUpload(refvalue.getCanvas().toDataURL('image/png'), type)
    }

    const sigUpload = async (filedata, name) => {
        const file = filedata
        //console.log("file ", file, name)
        let filename = name + ".png";
        // const base64 = await convertBase64(file)
        //console.log("filename ", filename)
        let pdetails = JSON.parse(localStorage.getItem("p"))

        const dataf = file.replace(/^data:image\/\w+;base64,/, "");

        const buf = Buffer.from(dataf, "base64");
        let uploadFilePath = "ipr/" + pdetails._id + "/" + name + "_result" + "/" + removeSpecialCharacters(filename);
        let data = { source_bucket: "ipr-resources", sourcepath: uploadFilePath }
        // console.log("data ", data)

        const response = await fetch(API_URL+'upload?appname=ipr', {
            method: "POST",
            mode: "cors",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
        const result = await response.json();
        // console.log("result ", result, buf.length)
        let url = result.result
        // console.log("url ", url)
        const base64Data = new Buffer.from(filedata.replace(/^data:image\/\w+;base64,/, ""), 'base64');
        // Getting the file type, ie: jpeg, png or gif
        const type = filedata.split(';')[0].split('/')[1];
        const uploadImage = await fetch(url, {
            method: "PUT",
            mode: "cors",
            headers: {
                'Content-Type': `image/${type}`,
                'Content-Encoding': 'base64',
                ACL: 'public-read',
            },
            body: base64Data
        })

        //console.log("resultImage ", uploadImage)

        let imagePath = "https://ipr-resources.s3.amazonaws.com/"
        let imageUploadPath = imagePath + uploadFilePath;


        let updateObj = {
            [name + "_result"]: imageUploadPath,
            [name + "_update"]: new Date()
        }

        setFile(file => ({
            ...file,
            ...updateObj
        }));
        setLegalInfo(legalInfo => ({
            ...legalInfo,
            ...updateObj
        }));

        // let patient = {
        //     ...pdetails,
        //     ...updateObj
        // }
        // localStorage.setItem("p", JSON.stringify(patient))

    };

    const handleFile = async (e, name) => {

        const file = e.target.files[0]
        // console.log("file ", file, name)
        let filename = e.target.files[0].name;
        // const base64 = await convertBase64(file)
        //console.log("filename ", filename)
        let pdetails = JSON.parse(localStorage.getItem("p"))
        // var reader = new FileReader();
        //   reader.readAsDataURL(file);
        // reader.onload = async function (e) {
        // console.log("e ", e)
        let uploadFilePath = "ipr/" + pdetails._id + "/" + name + "_result" + "/" + removeSpecialCharacters(filename);
        let data = { source_bucket: "ipr-resources", sourcepath: uploadFilePath }
        // console.log("data ", data)

        const response = await fetch(API_URL+'upload?appname=ipr', {
            method: "POST",
            mode: "cors",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
        const result = await response.json();

        // console.log("result ", result)
        let url = result.result
        // console.log("url ", url)
        const uploadImage = await fetch(url, {
            method: "PUT",
            mode: "cors",
            headers: {
                "Content-Type": "multipart/form-data",
                "Accept": "/",
                "Cache-Control": "no-cache",
                "Accept-Encoding": "gzip, deflate",
                "Connection": "keep-alive",
                "cache-control": "no-cache"
            },
            body: file
        })

        // console.log("resultImage ", uploadImage)

        let imagePath = "https://ipr-resources.s3.amazonaws.com/"
        let imageUploadPath = imagePath + uploadFilePath;

        let updateObj = {
            [name + "_result"]: imageUploadPath,
            [name + "_update"]: new Date()
        }
        // patientupdate(pdetails._id, updateObj)
        setFile(file => ({
            ...file,
            ...updateObj
        }));
        setLegalInfo(legalInfo => ({
            ...legalInfo,
            ...updateObj
        }));



    };

    const wardBack = (e) => {
        e.preventDefault()
        props.history.push("/wl")
        document.body.style.overflow = '';
    }

    let clinicPatient = JSON.parse(localStorage.getItem("p"))

    // console.log(" legalInfo ", legalInfo)

    return (
        <React.Fragment>

            <MetaTags>
                <title>PROCEDURES</title>
            </MetaTags>
            <div className='ward '>
                <div className="popup_wrp">
                    <div className="popup_full prescribe">
                        <div className="popup_hed">
                            <h2>PROCEDURES </h2>
                            <div className="dsp-flex"> <a className="popup_close" onClick={(e) => { wardBack(e) }}><span className="material-icons">close</span></a></div>

                        </div>
                        <div className="popup_content">
                        <div className="popup_scroll w-btns main ">
                        <div className="section ctr">
                            <div className="form_group">
                                <label className="label">Search Template </label>
                                <input type="text" className="form-control" placeholder="Search Template" />
                            </div>
                            <div className="procedures_templates">
                                <a href="#" className="template">Urinary Catheter</a>
                                <a href="#" className="template">IV access - Venepuncture</a>
                                <a href="#" className="template">Cannulation</a>
                                <a href="#" className="template">PICC line </a>
                                <a href="#" className="template">Central line</a>
                                <a href="#" className="template">Blood culture</a>
                                <a href="#" className="template">ECG recording</a>
                                <a href="#" className="template">IM injections</a>
                                <a href="#" className="template">SC injections</a>
                                <a href="#" className="template">IV injections (note: not cytotoxics etc.)</a>
                                <a href="#" className="template">Arterial blood gas sampling</a>
                                <a href="#" className="template">Oxygen therapy (starting on nasal specs, to venture masks,
                                    to 15L non-rebreather) Nasal cannula - simple oxygen mask - non rebreather mask </a>
                                <a href="#" className="template">Starting and administering nebuliser therapy</a>
                                <a href="#" className="template">Inserting a NG tube</a>
                                <a href="#" className="template">Performing Spirometry</a>
                                <a href="#" className="template">Taking all basic observations including HR, temperature, BP
                                    and SATS</a>
                                <a href="#" className="template">BM testing (only if confirmed as competent within the local
                                    programme)</a>
                                <a href="#" className="template">Setting up an IV infusion</a>
                                <a href="#" className="template">Performing CPR</a>
                                <a href="#" className="template">Using airway protection devices</a>
                                <a href="#" className="template">Ascitic tap (paracentesis) for diagnosis</a>
                                <a href="#" className="template">Pleural tap for diagnosis</a>
                                <a href="#" className="template">Nasal packing for haemorrhage</a>
                                <a href="#" className="template">Straightforward suturing </a>
                                    <a href="#" className="template">Bladder scanning</a>
                                    <a href="#" className="template">Removal of chest drains</a>
                                    <a href="#" className="template">Femoral venous blood sampling</a>
                                    <a href="#" className="template">Femoral arterial blood sampling</a>
                                    <a href="#" className="template">Femoral lines</a>
                                    <a href="#" className="template">Arterial lines</a>
                                    <a href="#" className="template">Lumbar puncture</a>
                                    <a href="#" className="template">Proctoscopy</a>
                                    <a href="#" className="template">Speculum insertion</a>
                                    <a href="#" className="template">Joint aspiration (knee only)</a>
                                    <a href="#" className="template">Skin biopsy</a>
                                    <a href="#" className="template">PICO </a>
                                    <a href="#" className="template">Epidural catheter</a>
                                    <a href="#" className="template">Dressing change</a>
                                    <a href="#" className="template">I&D</a>
                                    <a href="#" className="template">Aspiration</a>
                                    <a href="#" className="template">Block</a>
                                    <a href="#" className="template">Wound debridement</a>
                                    <a href="#" className="template">Tractions, splints, collars etc</a>
                                    <p className="sub_title mt-15 mb-10">FDs may undertake the following, if appropriately
                                        supervised:
                                    </p>
                                    <a href="#" className="template">Pleural tube drainage</a>
                                    <a href="#" className="template">Ascitic drainage (therapeutic)</a>
                                    <a href="#" className="template">Sigmoidoscospy</a>
                                    <a href="#" className="template">Gastroscopy</a>
                                    <a href="#" className="template">Central lines</a>
                            </div>
                            <h2 className="sub_title mt-15 mb-10">SAMPLEPROCEDURE TEMPLATE</h2>

                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form_group">
                                        <label className="label">PROCEDURE </label>
                                        <input type="text" className="form-control" placeholder="Enter" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form_group">
                                        <label className="label">CODE </label>
                                        <input type="text" className="form-control" placeholder="Enter" />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form_group">
                                        <label className="label">TIME </label>
                                        <input type="text" className="form-control" placeholder="Enter" />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form_group">
                                        <label className="label">DATE </label>
                                        <input type="text" className="form-control" placeholder="Enter" />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="check_item mt-0 mb-10"><label className="check">Universal precautions
                                            followed
                                        </label>
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input name="site" type="radio"
                                                        value="Site 1" /><span>Hand washing </span></label>
                                            </div>
                                            <div className="select_btn"><label><input name="site" type="radio"
                                                        value="Site 2" /><span>Gloves worn
                                                        Sharps disposal
                                                    </span></label></div>
                                            <div className="select_btn"><label><input name="site" type="radio"
                                                        value="Site 2" /><span>Alcohol gel/Hand Cleanser
                                                    </span></label></div>
                                            <div className="select_btn"><label><input name="site" type="radio"
                                                        value="Site 2" /><span>Other
                                                    </span></label></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form_group">
                                        <label className="label">Other </label>
                                        <input type="text" className="form-control" placeholder="Enter" />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="yn_switch mb-15 mt-10">
                                        <div className="label-container">
                                            <p>ASEPTIC TECHNIQUE </p>
                                        </div>
                                        <label for="check812" className="switch-container">
                                            <input hidden="" type="checkbox" className="switch_check" id="check812" />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>NO</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>YES</span>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form_group">
                                        <label className="label">PATIENT POSITION </label>
                                        <input type="text" className="form-control" placeholder="Enter" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form_group">
                                        <label className="label">SITE</label>
                                        <input type="text" className="form-control" placeholder="Enter" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form_group">
                                        <label className="label">MONITORING</label>
                                        <input type="text" className="form-control" placeholder="Enter" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form_group">
                                        <label className="label">DEVICE SIZE</label>
                                        <input type="text" className="form-control" placeholder="Enter" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form_group">
                                        <label className="label">LOCAL ANAESTHETIC</label>
                                        <input type="text" className="form-control" placeholder="Enter" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form_group">
                                        <label className="label">MEDICATION</label>
                                        <input type="text" className="form-control" placeholder="Enter" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form_group">
                                        <label className="label">VERIFICATION</label>
                                        <input type="text" className="form-control" placeholder="Enter" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form_group">
                                        <label className="label">COMPLICATIONS</label>
                                        <input type="text" className="form-control" placeholder="Enter" />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form_group">
                                        <label className="label">NOTES</label>
                                        <textarea name="" className="form-control" id="" cols="30" rows="3"></textarea>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-20">
                                <div className="col-md-3"></div>
                                <div className="col-md-6">
                                    <div className="signature_block mb-10">
                                        <p>Signature</p>
                                    </div>
                                </div>
                                <div className="col-md-3"></div>
                            </div>

                        </div>
                    </div>
                            <div className="popup_footer">
                                <div className="prescribe_footer">
                                    <div className="dsp-flex">
                                        <a className="icon_btn" onClick={(e) => { wardBack(e) }}> <span className="material-icons">save</span> SAVE</a>
                                        <button className="border_btn" onClick={(e) => { wardBack(e) }}><span className="material-icons">save_as</span>SAVE &amp;
                                            CLOSE
                                        </button>
                                    </div>

                                    <button className="btn dsp_flex" onClick={(e) => { wardBack(e) }}><span className="material-icons"><span className="material-icons">close</span></span>DISCARD </button>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>


        </React.Fragment>
    )
}

Procedures.propTypes = {
    t: PropTypes.any,
    getWards: PropTypes.func,
    getTheatres: PropTypes.func,
    wards: PropTypes.array,
    user: PropTypes.any,
    theatres: PropTypes.array,
    clinic: PropTypes.array,
    patients: PropTypes.array,
    doctors: PropTypes.array,
    profile: PropTypes.object
}

const mapStateToProps = ({ Layout, Login }) => ({
    wards: Layout.wards,
    user: Login.user,
    theatres: Layout.theatres,
    clinic: Layout.clinic,
    patients: Layout.patients,
    doctors: Layout.doctors,
    profile: Layout.profile
})

const mapDispatchToProps = dispatch => ({
    getWards: () => dispatch(getWardsList()),
    getTheatres: () => dispatch(getTheatresList()),
    getClinic: () => dispatch(getClinicList()),
    getPatient: () => dispatch(getPatientList()),
    getUserprofile: (id) => dispatch(getUprofile(id)),
    getDoctor: () => dispatch(getDoctorList()),
    patientupdate: (id, data) => dispatch(updatePatient(id, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Procedures)
