import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import MetaTags from 'react-meta-tags';
import {
    Modal
} from "reactstrap"
import Select from "react-select"
import Wardnotes from '../../assets/images/Wardnotes.svg';
import theatreNotes from '../../assets/images/theatreNotes.svg';
import clinicNotes from '../../assets/images/clinicNotes.svg';
import reports from '../../assets/images/reports.svg';
import MedRecRoom from '../../assets/images/MedRecRoom.svg';
import LifetimePatientRecord from '../../assets/images/LifetimePatientRecord.svg';
import diagnosis from '../../assets/images/diagnosis.svg';

import PatientBlock from "../Clinic/patientBlock";
import { connect } from "react-redux"
import { getWardsList, getTheatresList, getClinicList, getPatientList, getUprofile, getDoctorList, updatePatient } from "../../store/actions"

const Tpatient = props => {

    const [modal_standard, setmodal_standard] = useState(false)
    const [clinicValues, setClinic] = useState({})

    const [selectedGroup, setselectedGroup] = useState(null)
    const [popupname, setpopup_name] = useState({})
    const [anydelay, setDelay] = useState(false)

    const {
        wards, user, theatres, clinic, patients, profile, doctors
    } = props;

    useEffect(() => {
        const { getWards, getTheatres, getClinic, getDoctor, getPatient, getUserprofile } = props
        // getWards()
        // getTheatres()
        // getClinic()
        getPatient()
        getDoctor()
        let userId = localStorage.getItem("authUser")
        getUserprofile(userId)
    }, [])


    useEffect(() => {
        let clinicPatient = JSON.parse(localStorage.getItem("p"))
        if (patients && patients.length > 0 && clinicPatient && clinicPatient._id) {
            const cPatient = patients.filter(
                usr => usr._id === clinicPatient._id
            )
            if (cPatient && cPatient[0]) {
                let cpatientData = cPatient[0]
                delete cpatientData["_id"]
                setClinic(clinicValues => ({
                    ...clinicValues,
                    ...cpatientData
                }));
            }

        }
    }, [patients])

    const tog_standard = (name) => {
        if (name) {
            let stringReplace = name
            setpopup_name(
                {
                    popup: name,
                    id: stringReplace.replace(/[^A-Z0-9]/ig, "").toLowerCase()
                }
            )
        }
        setDelay(false)
        setmodal_standard(!modal_standard)
    }


    const updateInputValue = (e, popupname) => {
        let val = e.target.value;
        let name = e.target.name;
        let updatedValue = clinicValues;

        if (updatedValue[popupname]) {
            updatedValue[popupname][name] = val;
        } else {
            updatedValue = {
                [popupname]: { [name]: val }

            };
        }



        console.log("updatedValue ", updatedValue)
        setClinic(clinicValues => ({
            ...clinicValues,
            ...updatedValue
        }));
    }

    const updateInputValues = (name, popupname, val) => {
        //  e.preventDefault()
        // let updatedValue = {
        //    [popupname]:{[name] : val}
        // };
        let updatedValue = clinicValues;
        //updatedValue = { [name]: val };
        // updatedValue[popupname][name] = val;
        if (updatedValue[popupname]) {
            updatedValue[popupname][name] = val;
        } else {
            updatedValue = {
                [popupname]: { [name]: val }

            };
        }
        console.log("updatedValue 2", updatedValue)
        setClinic(clinicValues => ({
            ...clinicValues,
            ...updatedValue
        }));
    }

    const updateClinic = (e) => {
        const { patientupdate } = props
        let clinicPatient = JSON.parse(localStorage.getItem("p"))
        let id = clinicPatient._id
        patientupdate(id, clinicValues)
        let patient = {
            ...clinicPatient,
            ...clinicValues
        }
        localStorage.setItem("p", JSON.stringify(patient))
        tog_standard()


    }

    const optionGroup = [
        { label: "Anaesthesia – epidural", value: "Anaesthesia – epidural" },
        { label: "Anaesthetist – late", value: "Anaesthetist – late" },
        { label: "Anaesthetist – lunch", value: "Anaesthetist – lunch" },
        { label: "Anaesthetist – out of Department", value: "Anaesthetist – out of Department" },
        { label: "Anaesthetist - with patient", value: "Anaesthetist - with patient" },
        { label: "Case bumped / emergency", value: "Case bumped / emergency" },
        { label: "Chart – consent incomplete", value: "Chart – consent incomplete" },
        { label: "Chart – history and physical incomplete", value: "Chart – history and physical incomplete" },
        { label: "Chart – missing test results", value: "Chart – missing test results" },
        { label: "Chart – lost information", value: "Chart – lost information" },
        { label: "Chart – prep incomplete", value: "Chart – prep incomplete" },
        { label: "No delay", value: "No delay" },
        { label: "Equipment unavailable", value: "Equipment unavailable" },
        { label: "Room not ready", value: "Room not ready" },
        { label: "Staff unavailable", value: "Staff unavailable" },
        { label: "Equipment unavailable vendor", value: "Equipment unavailable vendor" },
        { label: "Turnover help unavailable", value: "Turnover help unavailable" },
        { label: "Patient – condition", value: "Patient – condition" },
        { label: "Patient – inadequate work up", value: "Patient – inadequate work up" },
        { label: "Patient – late", value: "Patient – late" },
        { label: "Patient – NPO", value: "Patient – NPO" },
        { label: "Patient – not ready nursing unit", value: "Patient – not ready nursing unit" },
        { label: "Patient – Prep not complete", value: "Patient – Prep not complete" },
        { label: "Previous case delay", value: "Previous case delay" },
        { label: "Previous case length", value: "Previous case length" },
        { label: "Radiology technician unavailable", value: "Radiology technician unavailable" },
        { label: "Surgeon late", value: "Surgeon late" },
        { label: "Surgeon lunch", value: "Surgeon lunch" },
        { label: "Surgeon out of Department", value: "Surgeon out of Department" }
    ]

    const handleSelectGroup = (selectedGroup, popupname, name) => {

        console.log("selc ", selectedGroup)
        setselectedGroup(selectedGroup)
        updateInputValues(name, popupname, selectedGroup.value)
    }

    const patientBack = (e) => {
        e.preventDefault()
        props.history.push("/tlist")
    }

    const handleChange = (event, popupname) => {
        setDelay(event.target.checked);
        updateInputValues(event.target.name, popupname, event.target.checked)
    }

    const menuClick = (e, menu) => {
        props.history.push("/" + menu)
    }



    let clinicPatient = JSON.parse(localStorage.getItem("p"))

    // console.log("clinicValues tpatient ", clinicValues)
    //console.log("clinicPatient ", clinicPatient)
    let surgeryConsent = "surgery"
    if (clinicValues.consent == "surgical2") {
        surgeryConsent = "surgery_consent2"
    } else if (clinicValues.consent == "surgical3") {
        surgeryConsent = "surgery_consent3"
    } else if (clinicValues.consent == "surgical4") {
        surgeryConsent = "surgery_consent4"
    }

    return (
        <React.Fragment>

            <MetaTags>
                <title>Theatre</title>
            </MetaTags>
            <div className='dashboard ward current_episode'>
                <div className="main theatre">
                    <div className="wrapper">

                        <div className="theatre_menu_block">


                            <div className="menu_block">
                                <div className="btn-group">
                                    <a className=" dropdown-toggle dropdown-toggle-split" data-toggle="dropdown"
                                        aria-haspopup="true" aria-expanded="false">
                                        <span className="sr-onlyd">Theatre List</span>
                                    </a>
                                    <div className="dropdown-menu">
                                        <a className="dropdown-item" onClick={(e) => menuClick(e, 'uc')}>Surgical log book</a>
                                        <a className="dropdown-item" onClick={(e) => menuClick(e, 'uc')}>Anaesthetic log book</a>
                                    </div>
                                </div>
                                <div className="btn-group">
                                    <a className=" dropdown-toggle dropdown-toggle-split" data-toggle="dropdown"
                                        aria-haspopup="true" aria-expanded="false">
                                        <span className="sr-onlyd">Session</span>
                                    </a>
                                    <div className="dropdown-menu">
                                        <a className="dropdown-item" onClick={(e) => menuClick(e, 'nursingpick')}>Nursing pick lists & preferences </a>
                                        <a className="dropdown-item" onClick={(e) => menuClick(e, 'machine')}>Machine/equipment checks</a>
                                        <a className="dropdown-item" onClick={(e) => menuClick(e, 'sessionteam')}>Session team </a>
                                        <a className="dropdown-item" onClick={(e) => menuClick(e, 'teambrief')}>Team brief/debrief</a>
                                    </div>
                                </div>
                                <div className="btn-group">
                                    <a className=" dropdown-toggle dropdown-toggle-split" data-toggle="dropdown"
                                        aria-haspopup="true" aria-expanded="false">
                                        <span className="sr-onlyd">Reports</span>
                                    </a>
                                    <div className="dropdown-menu">
                                        <a className="dropdown-item" onClick={(e) => menuClick(e, 'uc')}>Session Report</a>
                                        <a className="dropdown-item" onClick={(e) => menuClick(e, 'theatreR')}>Productivity Report</a>
                                        <a className="dropdown-item" onClick={(e) => menuClick(e, 'uc')}>Finance Report</a>
                                        <a className="dropdown-item" onClick={(e) => menuClick(e, 'uc')}>Patient Quality Report</a>
                                    </div>
                                </div>
                                <div className="btn-group">
                                    <a onClick={(e) => menuClick(e, 'tflow')}> Theatre Flow </a>
                                </div>
                            </div>
                        </div>

                        {/* <div className='row mt-20'>
                            <a onClick={(e) => patientBack(e)} className="icon_link pull-right"><span className="material-icons">
                                arrow_back_ios
                            </span> Back</a>
                        </div> */}

                        <div className=" patient_record prd">



                            <PatientBlock patient={clinicPatient} selectPatientList={true} />

                            <div className="schdule_menu">
                                <div className="wrp">
                                    <a onClick={() => { tog_standard("Patient sent") }} className={clinicPatient.patientsent ? "btn active" : "btn"}>Patient sent {clinicPatient.patientsent && clinicPatient.patientsent.start_time && <span className="time">{clinicPatient.patientsent.start_time}</span>}</a>
                                    <a onClick={() => { tog_standard("Into Anaesthetic room") }} className={clinicPatient.intoanaestheticroom ? "btn active" : "btn"} >Into Anaesthetic room {clinicPatient.intoanaestheticroom && clinicPatient.intoanaestheticroom.start_time && <span className="time">{clinicPatient.intoanaestheticroom.start_time}</span>}</a>

                                    <a onClick={() => { tog_standard("Anaesthesia started") }} className={clinicPatient.anaesthesiastarted ? "btn active" : "btn"} >Anaesthesia started {clinicPatient.anaesthesiastarted && clinicPatient.anaesthesiastarted.start_time && <span className="time">{clinicPatient.anaesthesiastarted.start_time}</span>}</a>

                                    <a onClick={() => { tog_standard("Anaesthetized ready for surgery") }} className={clinicPatient.anaesthetizedreadyforsurgery ? "btn active" : "btn"}>Anaesthetized ready for surgery {clinicPatient.anaesthetizedreadyforsurgery && clinicPatient.anaesthetizedreadyforsurgery.start_time && <span className="time">{clinicPatient.anaesthetizedreadyforsurgery.start_time}</span>}</a>


                                    <a onClick={() => { tog_standard("Patient Into Theatre") }} className={clinicPatient.patientintotheatre ? "btn active" : "btn"}>Patient Into Theatre {clinicPatient.patientintotheatre && clinicPatient.patientintotheatre.start_time && <span className="time">{clinicPatient.patientintotheatre.start_time}</span>}</a>

                                    <a onClick={() => { tog_standard("Time out completed") }} className={clinicPatient.timeoutcompleted ? "btn active" : "btn"}>Time out completed {clinicPatient.timeoutcompleted && clinicPatient.timeoutcompleted.start_time && <span className="time">{clinicPatient.timeoutcompleted.start_time}</span>}</a>

                                    <a onClick={() => { tog_standard("Closing") }} className={clinicPatient.closing ? "btn active" : "btn"}>Closing {clinicPatient.closing && clinicPatient.closing.start_time && <span className="time">{clinicPatient.closing.start_time}</span>}</a>

                                    <a onClick={() => { tog_standard("Procedure complete") }} className={clinicPatient.procedurecomplete ? "btn active" : "btn"}>Procedure complete {clinicPatient.procedurecomplete && clinicPatient.procedurecomplete.start_time && <span className="time">{clinicPatient.procedurecomplete.start_time}</span>}</a>

                                    <a onClick={() => { tog_standard("Anaesthesia stop") }} className={clinicPatient.anaesthesiastop ? "btn active" : "btn"}>Anaesthesia stop {clinicPatient.anaesthesiastop && clinicPatient.anaesthesiastop.start_time && <span className="time">{clinicPatient.anaesthesiastop.start_time}</span>}</a>

                                    <a onClick={() => { tog_standard("Sigh out") }} className={clinicPatient.sighout ? "btn active" : "btn"}>Sigh out {clinicPatient.sighout && clinicPatient.sighout.start_time && <span className="time">{clinicPatient.sighout.start_time}</span>}</a>

                                    <a onClick={() => { tog_standard("Patient out of theatre") }} className={clinicPatient.patientoutoftheatre ? "btn active" : "btn"}>Patient out of theatre {clinicPatient.patientoutoftheatre && clinicPatient.patientoutoftheatre.start_time && <span className="time">{clinicPatient.patientoutoftheatre.start_time}</span>}</a>

                                    <a onClick={() => { tog_standard("Start of Recovery") }} className={clinicPatient.startofrecovery ? "btn active" : "btn"}>Start of Recovery {clinicPatient.startofrecovery && clinicPatient.startofrecovery.start_time && <span className="time">{clinicPatient.startofrecovery.start_time}</span>}</a>

                                    <a onClick={() => { tog_standard("Ready to return to ward") }} className={clinicPatient.readytoreturntoward ? "btn active" : "btn"}>Ready to return to ward {clinicPatient.readytoreturntoward && clinicPatient.readytoreturntoward.start_time && <span className="time">{clinicPatient.readytoreturntoward.start_time}</span>}</a>

                                    <a onClick={() => { tog_standard("Left complex") }} className={clinicPatient.leftcomplex ? "btn active" : "btn"}>Left complex {clinicPatient.leftcomplex && clinicPatient.leftcomplex.start_time && <span className="time">{clinicPatient.leftcomplex.start_time}</span>}</a>
                                </div>
                            </div>

                            <div className="prd_btns mt-10">
                                <div className="btns">

                                </div>
                                <div className="btn-group">
                                    <button type="button" className="btn btn-primary">JACS</button>
                                    <button type="button" className="btn btn-primary">ICE</button>
                                    <button type="button" className="btn btn-primary">PACS</button>
                                </div>
                            </div>

                            <ul className="oncall_dboard">
                                <li>
                                    <a onClick={(e) => menuClick(e, 'patient')} className="item_card">
                                        <span className="icon"> <img src={Wardnotes} /></span>
                                        THIS PATIENT

                                    </a>
                                </li>
                                <li>
                                    <a onClick={(e) => menuClick(e, 'preop')} className="item_card">
                                        <span className="icon"> <img src={Wardnotes} /></span>
                                        PEROP ASSESMENT
                                    </a>
                                </li>
                                <li>
                                    <a onClick={(e) => menuClick(e, 'periop')} className="item_card">
                                        <span className="icon"> <img src={Wardnotes}/></span>
                                        PERIOPERATIVE RECORD
                                    </a>
                                </li>
                                <li>
                                    <a onClick={(e) => menuClick(e, 'anesthetic')} className="item_card">
                                        <span className="icon"> <img src={theatreNotes} /></span>
                                        ANAESTHESIA

                                    </a>
                                </li>

                                <li>
                                    <a onClick={(e) => menuClick(e, surgeryConsent)} className="item_card">
                                        <span className="icon"> <img src={reports} /></span>
                                        SURGICAL

                                    </a>
                                </li>
                                <li>
                                    <a onClick={(e) => menuClick(e, 'recovery')} className="item_card">
                                        <span className="icon"> <img src={MedRecRoom} /></span>
                                        RECOVERY RECORD
                                    </a>
                                </li>
                                <li>
                                    <a onClick={(e) => menuClick(e, 'prdetails')}  className="item_card">
                                        <span className="icon"> <img src={MedRecRoom} /></span>
                                        PATIENT RECORD

                                    </a>
                                </li>

                            </ul>

                         


                            <Modal
                                isOpen={modal_standard}
                                toggle={() => {
                                    tog_standard()
                                }}
                            >  <div className="timing_popup">

                                    <div className="modal-header">

                                        <h5 className="modal-title">
                                            <span className="sub_text">Timing Points</span>
                                            {popupname.popup}
                                        </h5>
                                        <button type="button" onClick={() => {
                                            tog_standard()
                                        }} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="date_field">
                                            <div className="form_group">
                                                <div className="floating-label-group"><input type="time" name={"start_time"}
                                                    className="form-control" onChange={(e) => updateInputValue(e, popupname.id)} /><label className="floating-label">Start Time</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="yn_switch mt-30 ">
                                            <div className="label-container">
                                                <p>ANY DELAY</p>
                                            </div>


                                            <label className="switch-container">
                                                <input type="checkbox" name={"anydelay"} className="switch_check" onChange={(e) => handleChange(e, popupname.id)} />
                                                <div className="switch-bg"></div>
                                                <div className="round-box"></div>

                                                <div className="switch-left">
                                                    <span>YES</span>
                                                </div>
                                                <div className="switch-right">
                                                    <span>NO</span>
                                                </div>
                                            </label>
                                        </div>
                                        {anydelay &&
                                            <div className="date_field">
                                                <div className="form_group">
                                                    <div className="floating-label-group">
                                                        <label className="floating-label">Select reason for the delay</label>
                                                        <Select
                                                            value={selectedGroup}
                                                            onChange={(e) => {
                                                                handleSelectGroup(e, popupname.id, "reason")
                                                            }}
                                                            name={popupname.id + "_reason"}
                                                            options={optionGroup}
                                                            classNamePrefix="select2-selection"
                                                        />



                                                    </div>
                                                </div>
                                            </div>}


                                        <div className="date_field mt-20">
                                            <div className="form_group">
                                                <div className="floating-label-group"><input type="time" name={"duration"}
                                                    className="form-control" onChange={(e) => updateInputValue(e, popupname.id)} /><label className="floating-label">Duration</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" onClick={(e) => { updateClinic(e) }} className="btn icon_btn "><span className="material-icons"> done
                                        </span>Save</button>
                                    </div>

                                </div>
                            </Modal>


                        </div>
                    </div>
                </div>
            </div>


        </React.Fragment>
    )
}

Tpatient.propTypes = {
    t: PropTypes.any,
    getWards: PropTypes.func,
    getTheatres: PropTypes.func,
    wards: PropTypes.array,
    user: PropTypes.any,
    theatres: PropTypes.array,
    clinic: PropTypes.array,
    patients: PropTypes.array,
    doctors: PropTypes.array,
    profile: PropTypes.object
}

const mapStateToProps = ({ Layout, Login }) => ({
    wards: Layout.wards,
    user: Login.user,
    theatres: Layout.theatres,
    clinic: Layout.clinic,
    patients: Layout.patients,
    doctors: Layout.doctors,
    profile: Layout.profile
})

const mapDispatchToProps = dispatch => ({
    getWards: () => dispatch(getWardsList()),
    getTheatres: () => dispatch(getTheatresList()),
    getClinic: () => dispatch(getClinicList()),
    getPatient: () => dispatch(getPatientList()),
    getUserprofile: (id) => dispatch(getUprofile(id)),
    getDoctor: () => dispatch(getDoctorList()),
    patientupdate: (id, data) => dispatch(updatePatient(id, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Tpatient)
