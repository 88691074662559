import React, { useState, useRef } from "react"
import { Link } from "react-router-dom"
import pi from '../../assets/images/personal_injury.svg'; //personal_injury
import chronic from '../../assets/images/chronic.svg'; //chronic
import amp from '../../assets/images/amp_stories.svg'; // amp_stories

const WardMenus = (props) => {
    const ref = useRef()
    const [showSidemenu, setshowSidemenu] = useState(false)
    const menuClick = (e) => {
        setshowSidemenu(!showSidemenu)
    }

    const redirectLink = (e, route) => {
        e.preventDefault()
        // console.log("rot", route)
        props.history.push("/" + route)
    }
    return (
        <div className="menu_block">
            <div className="left">
                <div className="side_menu">
                            <a className="menu" data-bs-toggle="offcanvas" href="#offcanvasExample" role="button"
                                aria-controls="offcanvasExample">
                                <span className="material-icons"> menu </span>
                            </a>
                            <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasExample"
                                aria-labelledby="offcanvasExampleLabel">
                                <div className="offcanvas-body">
                                    <ul className="nav nav-pills flex-column">
                                        <li className="nav-item first_level"><a className="nav-link" href="#"> bed
                                                management</a> </li>
                                        <li className="nav-item first_level"><a className="nav-link" href="#"> STAFFING
                                                LEVELS</a> </li>
                                        <li className="nav-item first_level"><a className="nav-link" href="#"> NURSING
                                                ACUITY</a> </li>
                                        <li className="nav-item first_level">
                                            <a className="nav-link collapsed" href="#submenu1" data-toggle="collapse"
                                                data-target="#submenu1">UNIT QUALITY REPORT
                                                <span className="material-icons menu_arrow"> navigate_next </span></a>
                                            <div className="collapse" id="submenu1" aria-expanded="false">
                                                <ul className="flex-column pl-2 nav">
                                                    <li className="nav-item secend_level"> <a className="nav-link collapsed"
                                                            href="#subsubmenu1" data-toggle="collapse"
                                                            data-target="#subsubmenu1"> <span className="dot"></span>
                                                            Infection Control </a> </li>
                                                    <li className="nav-item secend_level"><a className="nav-link" href="#">
                                                            <span className="dot"></span> Falls </a>
                                                    </li>
                                                    <li className="nav-item secend_level"><a className="nav-link" href="#">
                                                            <span className="dot"></span> Pressure ulcers </a>
                                                    </li>
                                                    <li className="nav-item secend_level"><a className="nav-link" href="#">
                                                            <span className="dot"></span>Friends & Family test </a>
                                                    </li>
                                                    <li className="nav-item secend_level"><a className="nav-link" href="#">
                                                            <span className="dot"></span> Others </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li className="nav-item first_level">
                                            <a className="nav-link collapsed" href="#submenu2" data-toggle="collapse"
                                                data-target="#submenu2">UNIT FINANCE REPORT
                                                <span className="material-icons menu_arrow"> navigate_next </span></a>
                                            <div className="collapse" id="submenu2" aria-expanded="false">
                                                <ul className="flex-column pl-2 nav">
                                                    <li className="nav-item secend_level"> <a className="nav-link collapsed"
                                                            href="#subsubmenu1" data-toggle="collapse"
                                                            data-target="#subsubmenu2"> <span className="dot"></span>
                                                            Occupancy </a> </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li className="nav-item first_level"><a className="nav-link" href="#">NURSING FORMS</a>
                                        </li>
                                        <li className="nav-item first_level"><a className="nav-link" href="#">WARD PROTOCOLS</a>
                                        </li>
                                        <li className="nav-item first_level"><a className="nav-link" href="#">STOCK ORDERS</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                <h2 className="title">Nursing Office <span className="sub">- WARD 1</span></h2>
               
            </div>
            <div className="right">
                {/* <div className="btn-group">
                    <button type="button" className="btn btn-primary">JACS</button>
                    <button type="button" className="btn btn-primary">ICE</button>
                    <button type="button" className="btn btn-primary">PACS</button>
                </div> */}
            </div>
        </div>
    )
}

export default WardMenus