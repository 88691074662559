import React from "react";

function IconSearch(props) {
   return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.16669 0.666626C12.3067 0.666626 15.6667 4.02663 15.6667 8.16663C15.6667 12.3066 12.3067 15.6666 8.16669 15.6666C4.02669 15.6666 0.666687 12.3066 0.666687 8.16663C0.666687 4.02663 4.02669 0.666626 8.16669 0.666626ZM8.16669 14C11.3896 14 14 11.3895 14 8.16663C14 4.94371 11.3896 2.33329 8.16669 2.33329C4.94377 2.33329 2.33335 4.94371 2.33335 8.16663C2.33335 11.3895 4.94377 14 8.16669 14ZM15.2378 14.0592L17.5948 16.4162L16.4163 17.5947L14.0593 15.2377L15.2378 14.0592Z"
        fill="white"
      />
    </svg>
  );
}

export default IconSearch;
