import React, { useState, useEffect } from "react";
import styles from "./index.module.scss";
import { useLocation, useHistory } from "react-router-dom";
import { IconLeftAngleCovered, IconRightAngle } from "../../../icons";
// import { Document, Page, pdfjs } from "react-pdf";
// import "react-pdf/dist/Page/TextLayer.css";
// import "react-pdf/dist/Page/AnnotationLayer.css";
import moment from "moment";

const CategoryDocumentDetails = () => {
  const location = useLocation();
  const history = useHistory();

  const { categoryId, categoryName, docName, pdf, subCategoryName } =
    location.state || {};

  const startTime = Date.now();
  const backToCategoryDocuments = () => {
    history.push( {
      pathname: "/categorydocuments",
      state: {
        id: categoryId,
        name: categoryName,
        subCategoryName: subCategoryName,
      },
    });
  };
  const startQuiz = () => {
    const endTime = Date.now() - startTime;
    const timeTakenInMinutes = moment.duration(endTime).asMinutes();
    
    console.log(timeTakenInMinutes)
   

    history.push( {
      pathname: "/quiz",
      state: {
        categoryId,
        categoryName,
        timeTakenInSeconds: timeTakenInMinutes.toFixed(2),
        pdf: pdf,
        subCategoryName,
      },
    });
  };

  useEffect(() => {
    // pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }, []);

  return (
    <div className={styles["page-container"]}>
      <div className={styles["page-main-header"]}>
        <div className={styles["hearder-left"]}>
          <div className={styles["category-content"]}>
            <span
              className={styles["category-back"]}
              onClick={backToCategoryDocuments}
            >
              <IconLeftAngleCovered />
            </span>
            <span className={styles["category-name"]} disabled>
              {categoryName && categoryName
                .toLowerCase()
                .split(" ")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" ")}
            </span>
            <span className={styles["category-back"]}>
              <IconRightAngle />
            </span>
            <span className={styles["doc-name"]}> {docName}</span>
          </div>
        </div>
      </div>

      <div className={styles["page-content"]}>
        <div className={styles["page-content-documents"]}>
          <div className={styles["pdf-container"]}>
            {/* <Document file={pdf}>
              <Page pageNumber={1} />
            </Document> */}
          </div>
          <button className={styles["online-button"]} onClick={startQuiz}>
            Start Quiz
          </button>
        </div>
      </div>
    </div>
  );
};

export default CategoryDocumentDetails;
