import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import Moment from 'moment';
import MetaTags from 'react-meta-tags';
import ra from '../../assets/images/ra.svg';
import os from '../../assets/images/os.svg';
import aoo from '../../assets/images/aoo.svg';
import airline_seat_flat from '../../assets/images/airline_seat_flat.svg';
import airline_seat_recline_normal from '../../assets/images/airline_seat_recline_normal.svg';
import man from '../../assets/images/man.svg';
import ecg_heart from '../../assets/images/ecg_heart.svg';
import device_thermostat from '../../assets/images/device_thermostat.svg';
import Slider from "react-rangeslider"
import "react-rangeslider/lib/index.css"
import { connect } from "react-redux"
import { getWardsList, getTheatresList, getClinicList, getPatientList, getUprofile, getDoctorList, updateNews } from "../../store/actions"

const News = props => {
    const [news, setnews] = useState({})
    const [score, setScore] = useState(null)
    const [modal_standard, setmodal_standard] = useState(false)
    const [clinicValues, setClinic] = useState({})

    const [selectedBpRange, setselectedBpRange] = useState({})

    const {
        wards, user, theatres, clinic, patients, profile, doctors
    } = props;

    useEffect(() => {
        const { getWards, getTheatres, getClinic, getDoctor, getPatient, getUserprofile } = props
        // getWards()
        // getTheatres()
        // getClinic()
        getPatient()
        getDoctor()
        let userId = localStorage.getItem("authUser")
        getUserprofile(userId)
    }, [])


    useEffect(() => {
        let clinicPatient = JSON.parse(localStorage.getItem("p"))
        if (patients && patients.length > 0 && clinicPatient && clinicPatient._id) {
            const cPatient = patients.filter(
                usr => usr._id === clinicPatient._id
            )
            if (cPatient && cPatient[0]) {
                let cpatientData = cPatient[0]
                delete cpatientData["_id"]
                setClinic(clinicValues => ({
                    ...clinicValues,
                    ...cpatientData
                }));
            }

        }
    }, [patients])

    const updateSliderValue = (value, name) => {
        let updatedValue = { [name]: value }
        if(name == "respiratory"){
            let score_respiratory = 0
            if(value <= 8){
                score_respiratory = 3
            } else if(value > 9 && value < 11){
                score_respiratory = 1
            }else if(value > 12 && value < 20){
                score_respiratory = 0
            }else if(value > 21 && value < 24){
                score_respiratory = 2
            }else if(value > 25){
                score_respiratory = 3
            }
            updatedValue["score_respiratory"] = score_respiratory
        }
        if(name == "scale1"){
            let score_scale1 = 0
            if(value <= 91){
                score_scale1 = 3
            } else if(value > 92 && value < 93){
                score_scale1 = 2
            }else if(value > 94 && value < 95){
                score_scale1 = 1
            }else if(value >= 96){
                score_scale1 = 0
            }
            updatedValue["score_scale1"] = score_scale1
        }
        if(name == "scale2"){
            let score_scale2 = 0
            if(value <= 83){
                score_scale2 = 3
            } else if(value > 84 && value < 85){
                score_scale2 = 2
            }else if(value > 86 && value < 87){
                score_scale2 = 1
            }else if(value > 88 && value < 92){
                score_scale2 = 0
            }else if(value > 93 && value < 94){
                score_scale2 = 1
            }else if(value > 95 && value < 96){
                score_scale2 = 2
            }else if(value >= 97){
                score_scale2 = 3
            }
            updatedValue["score_scale2"] = score_scale2
        }

        if(name == "heart_rate"){
            let score_heart_rate = 0
            if(value <= 40){
                score_heart_rate = 3
            } else if(value > 41 && value < 50){
                score_heart_rate = 1
            }else if(value > 51 && value < 100){
                score_heart_rate = 0
            }else if(value > 101 && value < 110){
                score_heart_rate = 1
            }else if(value > 110 && value < 130){
                score_heart_rate = 2
            }else if(value >= 131){
                score_heart_rate = 3
            }
            updatedValue["score_heart_rate"] = score_heart_rate
        }

        if(name == "temperature"){
            let score_temperature = 0
            if(value <= 35){
                score_temperature = 3
            } else if(value > 35 && value < 36){
                score_temperature = 1
            }else if(value > 36 && value < 38){
                score_temperature = 0
            }else if(value > 38 && value < 39){
                score_temperature = 1
            }else if(value >= 39){
                score_temperature = 2
            }
            updatedValue["score_temperature"] = score_temperature
        }

        if(name == "bp_lying_systolic"){
            let score_bp_lying_systolic = 0
            if(value <= 91){
                score_bp_lying_systolic = 3
            } else if(value > 92 && value < 101){
                score_bp_lying_systolic = 2
            }else if(value > 101 && value < 111){
                score_bp_lying_systolic = 1
            }else if(value > 112 && value < 201){
                score_bp_lying_systolic = 0
            }else if(value >= 201){
                score_bp_lying_systolic = 3
            }
            updatedValue["score_bp_lying_systolic"] = score_bp_lying_systolic
        }

        if(name == "bp_sitting_systolic"){
            let score_bp_sitting_systolic = 0
            if(value <= 91){
                score_bp_sitting_systolic = 3
            } else if(value > 92 && value < 101){
                score_bp_sitting_systolic = 2
            }else if(value > 101 && value < 111){
                score_bp_sitting_systolic = 1
            }else if(value > 112 && value < 201){
                score_bp_sitting_systolic = 0
            }else if(value >= 201){
                score_bp_sitting_systolic = 3
            }
            updatedValue["score_bp_sitting_systolic"] = score_bp_sitting_systolic
        }

        if(name == "bp_standing_systolic"){
            let score_bp_standing_systolic = 0
            if(value <= 91){
                score_bp_standing_systolic = 3
            } else if(value > 92 && value < 101){
                score_bp_standing_systolic = 2
            }else if(value > 101 && value < 111){
                score_bp_standing_systolic = 1
            }else if(value > 112 && value < 201){
                score_bp_standing_systolic = 0
            }else if(value >= 201){
                score_bp_standing_systolic = 3
            }
            updatedValue["score_bp_standing_systolic"] = score_bp_standing_systolic
        }

        setnews(news => ({
            ...news,
            ...updatedValue
        }));
    }

    const updateInputValue = (e) => {
        let val = e.target.value;
        let name = e.target.name;
        let updatedValue = {};
        updatedValue = { [name]: val };
        setnews(news => ({
            ...news,
            ...updatedValue
        }));
        
    }

    const updateSwitchValue = (name, value) => {
        let updatedValue = {};
        updatedValue = { [name]: value };
        setnews(news => ({
            ...news,
            ...updatedValue
        }));
        
    }

    const updateData = (e) => {
        const { newsupdate } = props
        let clinicPatient = JSON.parse(localStorage.getItem("p"))
        let id = clinicPatient._id
        const formatDate = Moment().format('MMM DD, YYYY')
        news['patientId'] = id
        news['date'] = formatDate
        // console.log("news ", news)
        newsupdate(news)
    
    }

    const saveData = (e) => {
        let scoreValue = news.score_respiratory ? news.score_respiratory : 0
        scoreValue += news.score_scale1 ? news.score_scale1 : 0
        scoreValue += news.score_scale2 ? news.score_scale2 : 0
        scoreValue += news.score_heart_rate ? news.score_heart_rate : 0
        scoreValue += news.score_temperature ? news.score_temperature : 0
        scoreValue += news.score_bp_lying_systolic ? news.score_bp_lying_systolic : 0
        scoreValue += news.score_bp_sitting_systolic ? news.score_bp_sitting_systolic : 0
        scoreValue += news.score_bp_standing_systolic ? news.score_bp_standing_systolic : 0
      
        // console.log("scoreVal ",scoreValue)
        let updatedValue = {};
        updatedValue = { ["scoreValue"]: scoreValue };
        setnews(news => ({
            ...news,
            ...updatedValue
        }));

        setScore(scoreValue)
    
    }
    
    const handleChange = (event) => {
        updateSwitchValue(event.target.name, event.target.checked)
    }

    const wardBack = (e) => {
        e.preventDefault()
        props.history.push("/wl")
    }

   console.log("news ",news)
    const time = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })
    const formatDate = Moment().format('MMM DD, YYYY')

    return (
        <React.Fragment>

            <MetaTags>
                <title>News</title>
            </MetaTags>
            <div className='ward '>
                <div className="popup_wrp">
                    <div className="popup_full prescribe">
                        <div className="popup_hed">
                            <h2>NEWS 2 </h2>
                            <div className="dsp-flex"> <span className="title_date">
                                <strong>{time},</strong> <br />
                                {formatDate}</span> <a className="popup_close" onClick={(e) => { wardBack(e) }}><span className="material-icons">close</span></a>
                            </div>

                        </div>
                        <div className="popup_content">
                            <div className="popup_scroll main news2">
                                <div className="section ctr">
                                    <div id="affiliate" className="rang_slider">
                                        <div className="js-calculator_input-wrap ag-calculator_input-wrap">
                                            <div className="dsp_flex">
                                                <label className="ag-calculator_label"> <span className="icon">
                                                    <img src={ra} alt="" />
                                                </span> Respiratory rate</label>

                                                <input className="rand_input" name="respiratory" disabled value={news.respiratory} />
                                            </div>
                                            <Slider
                                                min={0}
                                                max={30}
                                                value={news.respiratory || 0}
                                                orientation="horizontal"
                                                onChange={value => {
                                                    updateSliderValue(value, "respiratory")
                                                }}
                                            />
                                           
                                        </div>
                                        <div className="rang_text">
                                            <span className="rang_item"> <b>0</b></span>
                                            <span className="rang_item"> <b>30</b></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="section ctr">
                                    <div id="affiliate" className="rang_slider">
                                        <div className="js-calculator_input-wrap ag-calculator_input-wrap">
                                            <div className="dsp_flex">
                                                <label className="ag-calculator_label"> <span className="icon">
                                                    <img src={os} alt="" />
                                                </span> Oxygen Saturation</label>
                                                <div className="check_item mt-0">
                                                    <div className="select_btns popup_select mt-0">

                                                        <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)}  name="scale" type="radio"
                                                            value="SCALE 1" /><span>SCALE 1 </span></label></div>
                                                        <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)}  name="scale" type="radio"
                                                            value="SCALE 2" /><span>SCALE 2 </span></label></div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="dsp_flex mt-10">
                                                <p className="rang_p">Diagnosis of Chronic Hypoxia? (Scale 2)  Patient’s O2 target saturation level has been set to scale 1 (94-98%) following consultation with the medical team.</p>
                                                {news.scale == "SCALE 1" &&
                                                <input className="rand_input" disabled value={news.scale1} />}
                                                {news.scale == "SCALE 2" &&
                                                <input className="rand_input" disabled value={news.scale2} />}
                                            </div>
                                            {news.scale == "SCALE 1" &&
                                            <Slider
                                                min={70}
                                                max={100}
                                                value={news.scale1 || 70}
                                                orientation="horizontal"
                                                onChange={value => {
                                                    updateSliderValue(value, "scale1")
                                                }}
                                            />}
                                            {news.scale == "SCALE 2" &&
                                            <Slider
                                                min={70}
                                                max={100}
                                                value={news.scale2 || 70}
                                                orientation="horizontal"
                                                onChange={value => {
                                                    updateSliderValue(value, "scale2")
                                                }}
                                            />}
                                        </div>
                                        {news.scale &&
                                        <div className="rang_text">
                                            <span className="rang_item"> <b> 70</b> % </span>
                                            <span className="rang_item"> <b> 100</b> %</span>
                                        </div>}
                                    </div>
                                </div>

                                <div className="section ctr">
                                    <div className="dsp_flex">
                                        <h2 className="popup_title"> <span className="icon">
                                            <img src={aoo} alt="" />
                                        </span> Air Or Oxygen</h2>
                                        <div className="check_item mt-0">
                                            <div className="select_btns popup_select mt-0">

                                                <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)}  name="air_oxygen" type="radio"
                                                    value="Air" /><span>ON AIR</span></label></div>
                                                <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)}  name="air_oxygen" type="radio"
                                                    value="On O2" /><span>ON OXYGEN </span></label></div>
                                            </div>
                                        </div>
                                    </div>
                                    {news.air_oxygen == "On O2" &&
                                    <div className="check_item mt-0">
                                        <div className="select_btns popup_select">
                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="oxygen_mask" type="radio" value="Simple Mask" /><span>
                                                Simple Mask</span></label>
                                            </div>
                                            <div className="select_btn"><label><input onChange={(e) => updateInputValue(e)} name="oxygen_mask" type="radio" value="Nasal Cannula" /><span>Nasal Cannula </span></label></div>

                                        </div>
                                    </div>}

                                </div>
                                <div className="section ctr">
                                    <h2 className="popup_title dsp_flex">Blood Pressure </h2>
                                    <div className="bp_block">
                                        <div className="bp_col">
                                            <div id="affiliate" className="rang_slider">
                                                <div className="js-calculator_input-wrap ag-calculator_input-wrap">
                                                    <label className="ag-calculator_label" > <span className="icon">
                                                        <img src={airline_seat_flat} alt="" />
                                                    </span> Lying</label>
                                                    <div className="dsp_flex">
                                                        <span className="rang_label">Systolic</span>
                                                        <span className="inp_text"><b>{news.bp_lying_systolic || 0}</b> mmHg</span>
                                                    </div>
                                                    <Slider
                                                        min={0}
                                                        max={240}
                                                        value={news.bp_lying_systolic || 0}
                                                        orientation="horizontal"
                                                        onChange={value => {
                                                            updateSliderValue(value, "bp_lying_systolic")
                                                        }}
                                                    />
                                                </div>
                                                <div className="rang_text">
                                                    <span className="rang_item"> <b> 0</b> mmHg </span>
                                                    <span className="rang_item"> <b>240</b> mmHg</span>
                                                </div>
                                            </div>
                                            <div id="affiliate" className="rang_slider mt-30">
                                                <div className="js-calculator_input-wrap ag-calculator_input-wrap">

                                                    <div className="dsp_flex">
                                                        <span className="rang_label">Diastolic</span>
                                                        <span className="inp_text"><b>{news.bp_lying_diastolic || 0}</b> mmHg</span>
                                                    </div>
                                                    <Slider
                                                        min={0}
                                                        max={240}
                                                        value={news.bp_lying_diastolic || 0}
                                                        orientation="horizontal"
                                                        onChange={value => {
                                                            updateSliderValue(value, "bp_lying_diastolic")
                                                        }}
                                                    />
                                                </div>
                                                <div className="rang_text">
                                                    <span className="rang_item"> <b> 0</b> mmHg </span>
                                                    <span className="rang_item"> <b>240</b> mmHg</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="bp_col">
                                            <div id="affiliate" className="rang_slider">
                                                <div className="js-calculator_input-wrap ag-calculator_input-wrap">
                                                    <label className="ag-calculator_label" > <span className="icon">
                                                        <img src={airline_seat_recline_normal} alt="" />
                                                    </span> SITTING</label>
                                                    <div className="dsp_flex">
                                                        <span className="rang_label">Systolic</span>
                                                        <span className="inp_text"><b>{news.bp_sitting_systolic || 0}</b> mmHg</span>
                                                    </div>
                                                    <Slider
                                                        min={0}
                                                        max={240}
                                                        value={news.bp_sitting_systolic || 0}
                                                        orientation="horizontal"
                                                        onChange={value => {
                                                            updateSliderValue(value, "bp_sitting_systolic")
                                                        }}
                                                    />
                                                </div>
                                                <div className="rang_text">
                                                    <span className="rang_item"> <b> 0</b> mmHg </span>
                                                    <span className="rang_item"> <b>240</b> mmHg</span>
                                                </div>
                                            </div>
                                            <div id="affiliate" className="rang_slider mt-30">
                                                <div className="js-calculator_input-wrap ag-calculator_input-wrap">

                                                    <div className="dsp_flex">
                                                        <span className="rang_label">Diastolic</span>
                                                        <span className="inp_text"><b>{news.bp_sitting_diastolic || 0}</b> mmHg</span>
                                                    </div>
                                                    <Slider
                                                        min={0}
                                                        max={240}
                                                        value={news.bp_sitting_diastolic || 0}
                                                        orientation="horizontal"
                                                        onChange={value => {
                                                            updateSliderValue(value, "bp_sitting_diastolic")
                                                        }}
                                                    />
                                                </div>
                                                <div className="rang_text">
                                                    <span className="rang_item"> <b> 0</b>  mmHg</span>
                                                    <span className="rang_item"> <b>240</b> mmHg</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="bp_col">
                                            <div id="affiliate" className="rang_slider">
                                                <div className="js-calculator_input-wrap ag-calculator_input-wrap">
                                                    <label className="ag-calculator_label" > <span className="icon">
                                                        <img src={man} alt="" />
                                                    </span> STANDING</label>
                                                    <div className="dsp_flex">
                                                        <span className="rang_label">Systolic</span>
                                                        <span className="inp_text"><b>{news.bp_standing_systolic || 0}</b> mmHg</span>
                                                    </div>
                                                    <Slider
                                                        min={0}
                                                        max={240}
                                                        value={news.bp_standing_systolic || 0}
                                                        orientation="horizontal"
                                                        onChange={value => {
                                                            updateSliderValue(value, "bp_standing_systolic")
                                                        }}
                                                    />
                                                </div>
                                                <div className="rang_text">
                                                    <span className="rang_item"> <b> 0</b>  mmHg</span>
                                                    <span className="rang_item"> <b>240</b> mmHg</span>
                                                </div>
                                            </div>
                                            <div id="affiliate" className="rang_slider mt-30">
                                                <div className="js-calculator_input-wrap ag-calculator_input-wrap">

                                                    <div className="dsp_flex">
                                                        <span className="rang_label">Diastolic</span>
                                                        <span className="inp_text"><b>{news.bp_standing_diastolic || 0}</b> mmHg</span>
                                                    </div>
                                                    <Slider
                                                        min={0}
                                                        max={240}
                                                        value={news.bp_standing_diastolic || 0}
                                                        orientation="horizontal"
                                                        onChange={value => {
                                                            updateSliderValue(value, "bp_standing_diastolic")
                                                        }}
                                                    />
                                                </div>
                                                <div className="rang_text">
                                                    <span className="rang_item"> <b> 0</b>  mmHg</span>
                                                    <span className="rang_item"> <b>240</b> mmHg</span>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                                <div className="section ctr">
                                    <div id="affiliate" className="rang_slider">
                                        <div className="js-calculator_input-wrap ag-calculator_input-wrap">
                                            <div className="dsp_flex">
                                                <label className="ag-calculator_label" > <span className="icon">
                                                    <img src={ecg_heart} alt="" />
                                                </span> Heart Rate</label>
                                                <input className="rand_input" name="hrate" disabled value={news.heart_rate} />
                                            </div>
                                            <Slider
                                                min={0}
                                                max={200}
                                                value={news.heart_rate || 0}
                                                orientation="horizontal"
                                                onChange={value => {
                                                    updateSliderValue(value, "heart_rate")
                                                }}
                                            />
                                        </div>
                                        <div className="rang_text">
                                            <span className="rang_item"> <b> 0</b> BPM </span>
                                            <span className="rang_item"> <b>200</b> BPM</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="section ctr">
                                    <div id="affiliate" className="rang_slider">
                                        <div className="js-calculator_input-wrap ag-calculator_input-wrap">
                                            <div className="dsp_flex">
                                                <label className="ag-calculator_label" > <span className="icon">
                                                    <img src={device_thermostat} alt="" />
                                                </span> Temperature</label>
                                                <input className="rand_input" name="temp" disabled value={news.temperature} />
                                            </div>
                                            <Slider
                                                min={0}
                                                max={150}
                                                value={news.temperature || 0}
                                                orientation="horizontal"
                                                onChange={value => {
                                                    updateSliderValue(value, "temperature")
                                                }}
                                            />
                                        </div>
                                        <div className="rang_text">
                                            <span className="rang_item"> <b> 0</b> 0F</span>
                                            <span className="rang_item"> <b>150</b> 0F</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="form_group">
                                    <label className="label">Comments</label>
                                    <textarea onChange={(e) => updateInputValue(e)} name="comments" className="form-control" rows="4" cols="50"></textarea>
                                </div>
                                <button type="button" onClick={(e) => { saveData(e) }}  className="update_btn">View Score</button>


                                 {(score >= 1 && score < 4) &&          
                                <div className="risk_rang">
                                    <button type="button" className="text_btn full_btn">NEWS TOTAL {score}</button>
                                    <div className="yn_switch mb-20 ">
                                        <div className="label-container">
                                            <p>Escalation of care</p>
                                        </div>
                                        <label className="switch-container">
                                            <input type="checkbox" className="switch_check" name="escalation" onChange={(e) => handleChange(e)} />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>YES</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>NO</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="risk_info">
                                        <div className="risk_hed">
                                            <div className="text_btn2">NEWS SCORE {score}</div>
                                            <div className="text_btn">LOW RISK</div>
                                        </div>
                                        <div className="rish_content">
                                            <h3>Minimum 4 hourly observations if:</h3>
                                            <p>Admission to hospital / transfer from critical care in last24 hrs,</p>
                                            <p>Acute brain injury,</p>
                                            <p>NIV CPAB Airvo,</p>
                                            <p>O2 therapy,</p>
                                            <p>Chest drain,</p>
                                            <p>Tracheostomy,</p>
                                            <p>Epidural, PCA,</p>
                                            <p>Opiates commenced, or dose or route change.</p>
                                        </div>
                                    </div>
                                </div> }

                                {(score >= 5 && score < 7) &&
                                <div className="risk_rang medium">
                                    <button type="button" className="text_btn full_btn">NEWS TOTAL {score}</button>
                                    <div className="yn_switch mb-20 ">
                                        <div className="label-container">
                                            <p>Escalation of care</p>
                                        </div>
                                        <label className="switch-container">
                                            <input name="escalation" onChange={(e) => handleChange(e)} type="checkbox" className="switch_check" />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>YES</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>NO</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="risk_info">
                                        <div className="risk_hed">
                                            <div className="text_btn2">NEWS SCORE {score}</div>
                                            <div className="text_btn">Medium RISK</div>
                                        </div>
                                        <div className="rish_content">
                                            <h3>Minimum 1 hourly observations if:</h3>
                                            <p><strong>CLINICAL RESPONSE </strong></p>
                                            <p> <strong>HCA: </strong> Refer to Registered Nurse & Nurse in charge using SBAR &
                                                document referral</p>
                                            <p><strong>RN:</strong> Urgent face-to-face assessment of the patient. Contact ward
                                                doctor / ACP for urgent review within 30 minutes. Consider escalation to
                                                Critical Care Outreach if advice needed / patient is not improving. Recheck NEWS
                                                hourly until NEWS &gt; 5 & the patient is stable for at least 2 hours. Provide
                                                clinical care in an environment with monitoring facilities </p>
                                            <p><strong>Dr / ACP: </strong>ABCDE assessment & investigations, formulate &
                                                document management & monitoring plan. Refer to Critical Care Outreach
                                                if advice needed / no improvement. lf the patient does not improve
                                                within 60 minutes call for senior review.</p>
                                            <p><strong>Senior Medical Review:</strong> Assess patient and implement
                                                appropriate management plan. Establish criteria for further review /
                                                senior review <br />
                                                Review DNACPR status, treatment & escalation plan. Consider modifying
                                                observations &escalation for NEWS score in this category. Modified
                                                observations & escalation instructions (eg target limits - please Date,
                                                Time & Sign)
                                            </p>
                                            <p><strong>THINK SEPSIS: If NEWS &gt; 5 & could be due to infection</strong>
                                                screen for sepsis & document risk level as per sepsis screening & action
                                                tool in this IPOC. If Moderate or High risk - proceed to complete the
                                                sepsis IPOC and start Sepsis6.</p>
                                        </div>
                                    </div>
                                </div>}
                                {score >= 7 && 
                                <div className="risk_rang high">
                                    <button type="button" className="text_btn full_btn">NEWS TOTAL {score}</button>
                                    <div className="yn_switch mb-20 ">
                                        <div className="label-container">
                                            <p>Escalation of care</p>
                                        </div>
                                        <label className="switch-container">
                                            <input name="escalation" onChange={(e) => handleChange(e)} type="checkbox" className="switch_check" />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>YES</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>NO</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="risk_info">
                                        <div className="risk_hed">
                                            <div className="text_btn2">NEWS SCORE {score}</div>
                                            <div className="text_btn">High RISK</div>
                                        </div>
                                        <div className="rish_content">
                                            <h3>Every 30 minutes or as documented monitoring plan by Dr / ACP</h3>
                                            <p><strong>CLINICAL RESPONSE </strong></p>
                                            <p> <strong>HCA: </strong> Urgently refer to Registered Nurse & Nurse in charge of ward/department using SBAR & document referral.</p>
                                            <p><strong> RN:</strong> Immediate face-to-face assessment of the patient. Escalate to senior doctor immediately. Inform Critical Care Outreach if advice needed / patient is not improving.</p>
                                            <p><strong>Dr /ACP & Senior Medical Review:</strong> Immediate assessment and management. Senior Dr to assess response. Senior Dr to review DNACPR status, treatment & escalation plan. <br />
                                                Refer Critical Care Consultant / Outreach / if advice needed / no improvement (SpR/middle grade out of hours) or if admission to critical care is deemed necessary. Consider modifying observations & escalation for NEWS score in this category (below).
                                            </p>
                                            <p><strong>"THINK SEPSIS: If NEWS &gt; 5 & could be due to infection</strong>  screen for sepsis & document risk level as per sepsis screening & action tool in this IPOC. If Moderate or High risk -proceed to complete the sepsis IPOC and start Sepsis6.
                                                Modified observations & escalation instructions(eg target limits - please Date, Time & Sign)

                                            </p>

                                        </div>
                                    </div>
                                </div>
                                }

                        {score > 1 && <button type="button" onClick={(e) => { updateData(e), wardBack(e) }}  className="update_btn">Submit</button>}
                                    <p>&nbsp;</p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>


        </React.Fragment>
    )
}

News.propTypes = {
    t: PropTypes.any,
    getWards: PropTypes.func,
    getTheatres: PropTypes.func,
    wards: PropTypes.array,
    user: PropTypes.any,
    theatres: PropTypes.array,
    clinic: PropTypes.array,
    patients: PropTypes.array,
    doctors: PropTypes.array,
    profile: PropTypes.object
}

const mapStateToProps = ({ Layout, Login }) => ({
    wards: Layout.wards,
    user: Login.user,
    theatres: Layout.theatres,
    clinic: Layout.clinic,
    patients: Layout.patients,
    doctors: Layout.doctors,
    profile: Layout.profile
})

const mapDispatchToProps = dispatch => ({
    getWards: () => dispatch(getWardsList()),
    getTheatres: () => dispatch(getTheatresList()),
    getClinic: () => dispatch(getClinicList()),
    getPatient: () => dispatch(getPatientList()),
    getUserprofile: (id) => dispatch(getUprofile(id)),
    getDoctor: () => dispatch(getDoctorList()),
    newsupdate: (data) => dispatch(updateNews(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(News)
