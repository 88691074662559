import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import MetaTags from 'react-meta-tags';


import PatientBlock from "../Clinic/patientBlock";
import { connect } from "react-redux"
import { getWardsList, getTheatresList, getClinicList, getPatientList, getUprofile, getDoctorList, updatePatient } from "../../store/actions"

const NursingAdmission = props => {


    const [visiblePatients, setPatient] = useState(true)
    const [visiblelanding, setClanding] = useState(false)
    const [successmessage, setSuccess] = useState("")


    const [assesmentValues, setAssesment] = useState({})

    const {
        wards, user, theatres, clinic, patients, profile, doctors
    } = props;

    useEffect(() => {
        const { getWards, getTheatres, getClinic, getDoctor, getPatient, getUserprofile } = props
        // getWards()
        // getTheatres()
        // getClinic()
        getPatient()
        getDoctor()
        let userId = localStorage.getItem("authUser")
        getUserprofile(userId)
    }, [])

    const updateRadioValue = (e) => {
        let val = e.target.value;
        let name = e.target.name;
        let updatedValue = { [name]: val };
        setAssesment(assesmentValues => ({
            ...assesmentValues,
            ...updatedValue
        }));
    }


    const closeClick = (e) => {
        e.preventDefault()
        localStorage.removeItem("a")
        props.history.push("/riskassesment")
    }

    let assesmentPage = JSON.parse(localStorage.getItem("a"))
    let clinicPatient = JSON.parse(localStorage.getItem("p"))

    return (
        <React.Fragment>

            <MetaTags>
                <title>{assesmentPage}</title>
            </MetaTags>
            <div className="dashboard ward">
                <div className="main">

                    <div className="popup_wrp">
                        <div className="popup_full prescribe">
                            <div className="popup_hed">
                                <h2>NURSING ADMISSION ASSESSMENT </h2>
                                <div className="dsp-flex"> <a className="popup_close" onClick={(e) => closeClick(e)}><span className="material-icons">close</span></a></div>

                            </div>
                            <div className="popup_content">
                                <div className="popup_scroll w-btns main nutrition">


                                    <div className="modal add_form guidance_popup fade" id="guidance" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                        <div className="modal-dialog" role="document">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title">GUIDANCE</h5>
                                                    <span className="material-icons" data-dismiss="modal" aria-label="Close">
                                                        close
                                                    </span>
                                                </div>
                                                <div className="modal-body">
                                                    <p>Observe cannula 8 hourly or more frequently if clinically indicated
                                                    </p>
                                                    <p>Secure cannula with an appropriate dressing – change if soiled or contaminated
                                                    </p>
                                                    <p>Aseptic technique must be followed for insertion</p>
                                                    <p>Consider re-siting the cannula every 48-72 hours or as indicated by VIP score
                                                        Plan and document care
                                                    </p>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {clinicPatient && <PatientBlock patient={clinicPatient} />}
                                  
                                    <div className="section ctr">
                            <h2 className="popup_title mt-0">WARD ADMISSION CHECKLIST</h2>

                            <div className="sub_section">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form_group">
                                            <label className="label">Ward </label>
                                            <input type="text" className="form-control" placeholder="Enter" />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form_group">
                                            <label className="label">Date </label>
                                            <input type="date" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="form_group">
                                            <label className="label">Time </label>
                                            <input type="time" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="form_group">
                                            <label className="label">Hours </label>
                                            <input type="time" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form_group">
                                            <label className="label">By </label>
                                            <input type="text" className="form-control" placeholder="Enter" />
                                        </div>
                                    </div>
                                </div>

                                <div className="check_item mt-0 mb-0">
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 1" /><span>Walked </span></label>
                                        </div>
                                        <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 2" /><span>Stretcher
                                                </span></label>
                                        </div>
                                        <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 2" /><span>Wheel chair
                                                </span></label>
                                        </div>
                                        <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 2" /><span>Other
                                                </span></label>
                                        </div>
                                    </div>
                                </div>
                                <div className="form_group">
                                    <label className="label">Other </label>
                                    <input type="text" className="form-control" placeholder="Enter" />
                                </div>
                                <div className="check_item mt-0 mb-0"><label className="check">Transported with
                                    </label>
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 1" /><span>Oxygen </span></label>
                                        </div>
                                        <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 2" /><span>Monitor</span></label>
                                        </div>
                                        <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 2" /><span>IIV </span></label>
                                        </div>
                                        <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 2" /><span>Other </span></label>
                                        </div>
                                    </div>
                                </div>
                                <div className="form_group">
                                    <label className="label">Other </label>
                                    <input type="text" className="form-control" placeholder="Enter" />
                                </div>
                                <div className="check_item mt-0 mb-0"><label className="check">From
                                    </label>
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 1" /><span>Home </span></label>
                                        </div>
                                        <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 2" /><span>A&E</span></label>
                                        </div>
                                        <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 2" /><span>Care home </span></label>
                                        </div>
                                        <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 2" /><span>Residential home </span></label>
                                        </div>
                                        <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 2" /><span>Other </span></label>
                                        </div>
                                    </div>
                                </div>
                                <div className="form_group">
                                    <label className="label">Other </label>
                                    <input type="text" className="form-control" placeholder="Enter" />
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form_group">
                                            <label className="label">Accompanied by </label>
                                            <input type="text" className="form-control" placeholder="Enter" />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="check_item mt-0 mb-0"><label className="check">Informant
                                            </label>
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                            value="Site 1" /><span>Patient </span></label>
                                                </div>
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                            value="Site 2" /><span>Other </span></label>
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form_group">
                                            <label className="label">Other </label>
                                            <input type="text" className="form-control" placeholder="Enter" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form_group">
                                            <label className="label">Phone </label>
                                            <input type="text" className="form-control" placeholder="Enter" />
                                        </div>
                                    </div>
                                    <div className="col-md-6"></div>
                                    <div className="col-md-6">
                                        <div className="yn_switch mb-10 mt-10">
                                            <div className="label-container">
                                                <p>Valuables</p>
                                            </div>
                                            <label for="check812" className="switch-container">
                                                <input hidden="" type="checkbox" className="switch_check" id="check812" />
                                                <div className="switch-bg"></div>
                                                <div className="round-box"></div>
                                                <div className="switch-left">
                                                    <span>NO</span>
                                                </div>
                                                <div className="switch-right">
                                                    <span>YES</span>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form_group">
                                            <label className="label">Sent home with </label>
                                            <input type="text" className="form-control" placeholder="Enter" />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form_group">
                                            <label className="label">Reason for Admission </label>
                                            <input type="text" className="form-control" placeholder="Enter" />
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="check_item mt-0 mb-0"><label className="check">The person has capacity
                                                to agree to
                                            </label>
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                            value="Site 1" /><span>Remain informally </span></label>
                                                </div>
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                            value="Site 2" /><span>Need to consider the use of MHA S5(4)
                                                        </span></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form_group">
                                            <label className="label">OUTCOME </label>
                                            <input type="text" className="form-control" placeholder="Enter" />
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="sub_section">
                                <div className="check-box sqeare mt-0">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-205" />
                                        <label for="checkbox-205"></label>
                                    </div>
                                    Admission notes & orders Reviewed
                                </div>
                                <div className="check-box sqeare mt-0">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-205" />
                                        <label for="checkbox-205"></label>
                                    </div>
                                    Hand hygiene performed. Principles of asepsis and safety followed. Privacy, dignity
                                    and modesty promoted.
                                </div>
                                <div className="check-box sqeare mt-0">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-205" />
                                        <label for="checkbox-205"></label>
                                    </div>
                                    Patient ID: Confirmed using two patient identifiers (e.g., name and date of birth).
                                </div>
                                <div className="check-box sqeare mt-0">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-205" />
                                        <label for="checkbox-205"></label>
                                    </div>
                                    Wristband (ID & ALERTS) attached(White –all patients plus: red – allergies;
                                    orange-infection control; Green- fistula /lymphedema identification)
                                </div>
                                <div className="check-box sqeare mt-0">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-205" />
                                        <label for="checkbox-205"></label>
                                    </div>
                                    Provided with appropriate nightwear and jug of water and glass / hot drink if not
                                    NBM
                                </div>
                                <div className="check-box sqeare mt-0">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-205" />
                                        <label for="checkbox-205"></label>
                                    </div>
                                    Orientated to ward and introduced to staff.
                                </div>
                                <div className="check-box sqeare mt-0">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-205" />
                                        <label for="checkbox-205"></label>
                                    </div>
                                    Call bell / pager
                                </div>
                                <div className="check-box sqeare mt-0">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-205" />
                                        <label for="checkbox-205"></label>
                                    </div>
                                    Meal times
                                </div>
                                <div className="check-box sqeare mt-0">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-205" />
                                        <label for="checkbox-205"></label>
                                    </div>
                                    Toilet / Bathroom
                                </div>
                                <div className="check-box sqeare mt-0">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-205" />
                                        <label for="checkbox-205"></label>
                                    </div>
                                    Bed Controls
                                </div>
                                <div className="check-box sqeare mt-0">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-205" />
                                        <label for="checkbox-205"></label>
                                    </div>
                                    Visiting Hours
                                </div>
                                <div className="check-box sqeare mt-0">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-205" />
                                        <label for="checkbox-205"></label>
                                    </div>
                                    Lounge Room
                                </div>
                                <div className="check-box sqeare mt-0">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-205" />
                                        <label for="checkbox-205"></label>
                                    </div>
                                    Fire Exits
                                </div>
                                <div className="check-box sqeare mt-0">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-205" />
                                        <label for="checkbox-205"></label>
                                    </div>
                                    No Smoking
                                </div>
                                <div className="check-box sqeare mt-0">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-205" />
                                        <label for="checkbox-205"></label>
                                    </div>
                                    Telephone
                                </div>
                                <div className="check-box sqeare mt-0">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-205" />
                                        <label for="checkbox-205"></label>
                                    </div>
                                    TV / Radio / CH3
                                </div>
                                <div className="check-box sqeare mt-0">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-205" />
                                        <label for="checkbox-205"></label>
                                    </div>
                                    Policy
                                </div>
                                <div className="check-box sqeare mt-0">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-205" />
                                        <label for="checkbox-205"></label>
                                    </div>
                                    Staff uniforms
                                </div>
                            </div>
                            <div className="sub_section">
                                <div className="check-box sqeare mt-0">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-205" />
                                        <label for="checkbox-205"></label>
                                    </div>
                                    Completed front sheet.
                                </div>
                                <div className="check-box sqeare mt-0">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-205" />
                                        <label for="checkbox-205"></label>
                                    </div>
                                    Checked demographic details with patient - Checked that N.O.K is aware of admission
                                </div>
                                <div className="check-box sqeare mt-0">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-205" />
                                        <label for="checkbox-205"></label>
                                    </div>
                                    Completed Lifestyle assessment
                                </div>
                                <div className="check-box sqeare mt-0">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-205" />
                                        <label for="checkbox-205"></label>
                                    </div>
                                    Patient disclaimer signed
                                </div>
                                <div className="check-box sqeare mt-0">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-205" />
                                        <label for="checkbox-205"></label>
                                    </div>
                                    Summary sheet completed
                                </div>
                                <div className="check-box sqeare mt-0">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-205" />
                                        <label for="checkbox-205"></label>
                                    </div>
                                    Nursing handover document
                                </div>
                                <div className="check-box sqeare mt-0">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-205" />
                                        <label for="checkbox-205"></label>
                                    </div>
                                    Patients valuables documented & signed for
                                </div>
                                <div className="check-box sqeare mt-0">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-205" />
                                        <label for="checkbox-205"></label>
                                    </div>
                                    Bed board updated
                                </div>
                                <div className="check-box sqeare mt-0">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-205" checked />
                                        <label for="checkbox-205"></label>
                                    </div>
                                    Commenced
                                </div>
                                <div className="sub_bg">
                                    <div className="check-box sqeare mt-0">
                                        <div className="round">
                                            <input type="checkbox" id="checkbox-205" />
                                            <label for="checkbox-205"></label>
                                        </div>
                                        Wounds/IPOCS
                                    </div>
                                    <div className="check-box sqeare mt-0">
                                        <div className="round">
                                            <input type="checkbox" id="checkbox-205" />
                                            <label for="checkbox-205"></label>
                                        </div>
                                        Intentional rounding Chart- Ensured patient aware of this process.
                                    </div>
                                    <div className="check-box sqeare mt-0">
                                        <div className="round">
                                            <input type="checkbox" id="checkbox-205" />
                                            <label for="checkbox-205"></label>
                                        </div>
                                        If patient has pressure ulcer on admission complete AIR and arrange photograph
                                        and wound chart. Complete body map on Intentional Rounding chart
                                    </div>
                                    <div className="check-box sqeare mt-0">
                                        <div className="round">
                                            <input type="checkbox" id="checkbox-205" />
                                            <label for="checkbox-205"></label>
                                        </div>
                                        Intravenous Device Pathway if appropriate
                                    </div>
                                    <div className="check-box sqeare mt-0">
                                        <div className="round">
                                            <input type="checkbox" id="checkbox-205" />
                                            <label for="checkbox-205"></label>
                                        </div>
                                        Catheter care flow sheet if appropriate
                                    </div>
                                    <div className="check-box sqeare mt-0">
                                        <div className="round">
                                            <input type="checkbox" id="checkbox-205" />
                                            <label for="checkbox-205"></label>
                                        </div>
                                        Stool chart if appropriate
                                    </div>
                                </div>
                                <div className="check-box sqeare mt-0">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-205" />
                                        <label for="checkbox-205"></label>
                                    </div>
                                    Sensitivities and allergies checked by medical team, to include dietary
                                    allergies/intolerances, Medication chart
                                </div>
                                <div className="check-box sqeare mt-0">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-205" checked />
                                        <label for="checkbox-205"></label>
                                    </div>
                                    VTE Risk Assessment completed by medical staff. LMWH prescribed if indicated
                                </div>
                                <div className="sub_bg">
                                    <div className="check-box sqeare mt-0">
                                        <div className="round">
                                            <input type="checkbox" id="checkbox-205" />
                                            <label for="checkbox-205"></label>
                                        </div>
                                        Applied anti embolic stockings if indicated from VTE assessment.
                                    </div>
                                    <div className="check-box sqeare mt-0">
                                        <div className="round">
                                            <input type="checkbox" id="checkbox-205" />
                                            <label for="checkbox-205"></label>
                                        </div>
                                        VTE information leaflet given to patient
                                    </div>
                                </div>
                                <div className="check-box sqeare mt-0">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-205" />
                                        <label for="checkbox-205"></label>
                                    </div>
                                    Dementia Assessment (If patient is over 65 and an emergency admission) - Inform
                                    medical staff to complete their section
                                </div>
                                <div className="check-box sqeare mt-0">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-205" />
                                        <label for="checkbox-205"></label>
                                    </div>
                                    Discharge Checklist commenced: Estimated Date of Discharge (EDD) entered. Discharge
                                    Status column updated on IPR
                                </div>
                                <div className="check-box sqeare mt-0">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-205" />
                                        <label for="checkbox-205"></label>
                                    </div>
                                    Given patient information leaflets and discharge leaflet- explained discharge
                                    policies.
                                </div>
                                <div className="check-box sqeare mt-0">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-205" />
                                        <label for="checkbox-205"></label>
                                    </div>
                                    Informed patient of HELP phone
                                </div>
                                <div className="check-box sqeare mt-0">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-205" />
                                        <label for="checkbox-205"></label>
                                    </div>
                                    Friends and Family Test Explained.
                                </div>
                            </div>
                            <div className="sub_section">
                                <div className="check-box sqeare mt-0">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-205" />
                                        <label for="checkbox-205"></label>
                                    </div>
                                    Property and money checklists completed
                                </div>
                                <div className="check-box sqeare mt-0">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-205" />
                                        <label for="checkbox-205"></label>
                                    </div>
                                    Property disclaimer and restricted items form signed
                                </div>
                            </div>
                            <div className="sub_section">
                                <div className="row mt-10">
                                    <div className="col-md-8">
                                        <div className="check_item mt-0 mb-0">
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                            value="Site 1" /><span>No identified carer </span></label>
                                                </div>
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                            value="Site 2" /><span>There is an identified carer
                                                        </span></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form_group">
                                            <label className="label">Carer's name </label>
                                            <input type="text" className="form-control" placeholder="Enter" />
                                        </div>
                                    </div>
                                </div>

                                <div className="check-box sqeare mt-0">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-205" />
                                        <label for="checkbox-205"></label>
                                    </div>
                                    Ward Administrator Informed to send carers letter
                                </div>
                                <div className="check-box sqeare mt-0">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-205" />
                                        <label for="checkbox-205"></label>
                                    </div>
                                    Carer’s letter sent. (Preferably within 72 hours of admission)
                                </div>
                                <div className="check_item mt-0 mb-0"><label className="check">Consent to take photograph
                                        discussed.
                                    </label>
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 1" /><span>Consent form signed </span></label>
                                        </div>
                                        <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 2" /><span>Refused Consent for photography
                                                </span></label>
                                        </div>
                                        <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 2" /><span>Have no capacity for Consenting
                                                </span></label>
                                        </div>
                                    </div>
                                </div>
                                <div className="check_item mt-0 mb-0"><label className="check">Sharing of Patient Information
                                        leaflet discussed.
                                    </label>
                                    <div className="select_btns popup_select">
                                        <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 1" /><span>Consent form signed </span></label>
                                        </div>
                                        <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 2" /><span>Refused Consent for Information sharing
                                                </span></label>
                                        </div>
                                        <div className="select_btn"><label><input name="site" type="radio"
                                                    value="Site 2" /><span>Have no capacity for Consenting
                                                </span></label>
                                        </div>
                                    </div>
                                </div>


                                <div className="check-box sqeare mt-0">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-205" />
                                        <label for="checkbox-205"></label>
                                    </div>
                                    Person's rights discussed according to their status and relevant literature
                                    provided.
                                    Form 132 completed and signed.

                                </div>
                            </div>
                            <div className="sub_section">
                                <div className="check-box sqeare mt-0">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-205" checked />
                                        <label for="checkbox-205"></label>
                                    </div>
                                    RESUSCITATION STATUS IN THE EVENT OF A CARDIO-RESPIRATORY ARREST:
                                </div>
                                <div className="sub_bg">
                                    <div className="check-box sqeare mt-0">
                                        <div className="round">
                                            <input type="checkbox" id="checkbox-205" />
                                            <label for="checkbox-205"></label>
                                        </div>
                                        To be resuscitated
                                    </div>
                                    <div className="yn_switch mb-10 mt-10">
                                        <div className="label-container">
                                            <p>Not to be resuscitated - Have their end of life wishes been established?
                                            </p>
                                        </div>
                                        <label for="check815" className="switch-container">
                                            <input hidden="" type="checkbox" className="switch_check" id="check815" />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>NO</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>YES</span>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                <div className="check-box sqeare mt-0">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-205" />
                                        <label for="checkbox-205"></label>
                                    </div>
                                    Has the patient got an ADVANCE DIRECTIVE
                                </div>
                            </div>
                            <div className="sub_section">
                                <div className="check-box sqeare mt-0">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-205" />
                                        <label for="checkbox-205"></label>
                                    </div>
                                    Medical team informed of admission.
                                </div>
                                <div className="check-box sqeare mt-0">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-205" />
                                        <label for="checkbox-205"></label>
                                    </div>
                                    Notification of admission form sent to GP
                                </div>
                            </div>
                            <p className="ps1">Nursing associates, trainee nursing associates, assistant practitioners,
                                trainee assistant practitioners, therapy students and student nurses - all entries in
                                this document must be countersigned by a registered nurse.</p>


                        </div>

                        <div className="section ctr">
                            <h2 className="popup_title">NURSING CLINICAL ASSESSMENT</h2>
                            <p className="sub_title mt-10">OBS & SWABS</p>
                            <div className="sub_section">
                                <div className="check-box sqeare  mt-20">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-203" />
                                        <label for="checkbox-203"></label>
                                    </div>
                                    HEIGHT/ WEIGHT, BMI
                                </div>
                                <div className="check-box sqeare  mt-10">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-203" />
                                        <label for="checkbox-203"></label>
                                    </div>
                                    Baseline NEWS2:
                                </div>
                                <div className="check-box sqeare  mt-10">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-203" />
                                        <label for="checkbox-203"></label>
                                    </div>
                                    <span> LYING AND STANDING BP FOR POSTURAL HYPOTENSION <span
                                            className="sub_text">(Perform once on ALL patients) (if admitted with falls or
                                            over 65 years of age) &gt; 20mmHg systolic or &gt; l0mmHg diastolic drop, dizziness
                                            on standing - Doctors review for Postural Hypotension</span></span>

                                </div>
                                <div className="check-box sqeare  mt-10">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-203" />
                                        <label for="checkbox-203"></label>
                                    </div>
                                    ADMISSION BM for type 1 patients or patients on insulin
                                </div>
                                <div className="check-box sqeare  mt-10">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-203" />
                                        <label for="checkbox-203"></label>
                                    </div>
                                    The patient is insulin dependent: Inform …
                                </div>
                                <div className="check-box sqeare  mt-10">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-203" />
                                        <label for="checkbox-203"></label>
                                    </div>
                                    MRSA swab (if required, ie open wound/self-harm/IV drug use) sent
                                </div>
                                <div className="check-box sqeare  mt-10">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-203" />
                                        <label for="checkbox-203"></label>
                                    </div>
                                    The patient has a catheter: Swab catheter, complete Catheter Care Checklist and
                                    inform
                                </div>
                                <div className="check-box sqeare  mt-10">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-203" />
                                        <label for="checkbox-203"></label>
                                    </div>
                                    Urine sample for urinalysis or MSU if appropriate.
                                </div>
                                <div className="check-box sqeare  mt-10">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-203" />
                                        <label for="checkbox-203"></label>
                                    </div>
                                    Check target SPO2 set by medical staff (on EPMAR)
                                </div>
                            </div>
                            <div className="sub_section">
                                <div className="check-box sqeare  mt-10">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-203" checked />
                                        <label for="checkbox-203"></label>
                                    </div>
                                    Update RISK ASSESSMENTS within 24 hours - Starting within 4 hours of admission
                                </div>
                                <div className="sub_bg">
                                    <ol>
                                        <li>OBS & SWABS</li>
                                        <li>SKIN INTEGRITY</li>
                                        <li>WEIGHT AND MUST (to be completed within 24 hours of admission) </li>
                                        <li>PATIENT MOVEMENT & HANDLING</li>
                                        <li>SMOKING SCREENING </li>
                                        <li>ALCOHOL SCREENING (Patients &gt; 65 / under 65 with complex needs, such as
                                            frailty,
                                            an underlying condition e.g. Parkinson’s or Stroke)</li>
                                        <li>DAILY SUPERVISION AND ENGAGEMENT (ACUTE CONFUSION) <br />
                                            ASSESSMENT - Safety in hospital bed: nurse call bell - safety sides
                                            assessment -
                                            low bed requirement
                                        </li>
                                        <li>RISK OF FALLS & BONE HEALTH</li>
                                        <li>SAFEGUARDING ADULTS/CHILDREN</li>
                                        <li>CONTINENCE</li>
                                        <li>VTE (Medical)</li>
                                        <li>DEMENTIA (Medical)</li>
                                    </ol>
                                </div>
                            </div>
                            <div className="sub_section">
                                <p className="sub_title">SOCIAL HISTORY</p>
                                <div className="row mt-10">
                                    <div className="col-md-6">
                                        <div className="form_group">
                                            <label className="label">Lives alone/Lives with</label>
                                            <input type="text" className="form-control" placeholder="Enter" />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form_group">
                                            <label className="label">&nbsp;</label>
                                        </div>
                                        <div className="yn_switch mb-10 mt-10">
                                            <div className="label-container">
                                                <p>Stairs at home</p>
                                            </div>
                                            <label for="check816" className="switch-container">
                                                <input hidden="" type="checkbox" className="switch_check" id="check816" />
                                                <div className="switch-bg"></div>
                                                <div className="round-box"></div>
                                                <div className="switch-left">
                                                    <span>NO</span>
                                                </div>
                                                <div className="switch-right">
                                                    <span>YES</span>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form_group">
                                            <label className="label">Sleep pattern </label>
                                            <input type="text" className="form-control" placeholder="Enter" />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form_group">
                                            <label className="label">&nbsp;</label>
                                        </div>
                                        <div className="yn_switch mb-10 mt-10">
                                            <div className="label-container">
                                                <p>Immunizations current? </p>
                                            </div>
                                            <label for="check817" className="switch-container">
                                                <input hidden="" type="checkbox" className="switch_check" id="check817" />
                                                <div className="switch-bg"></div>
                                                <div className="round-box"></div>
                                                <div className="switch-left">
                                                    <span>NO</span>
                                                </div>
                                                <div className="switch-right">
                                                    <span>YES</span>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form_group">
                                            <label className="label">Last Tetanus toxoid? </label>
                                            <input type="text" className="form-control" placeholder="Enter" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form_group">
                                            <label className="label">&nbsp;</label>
                                        </div>
                                        <div className="yn_switch mb-10 mt-10">
                                            <div className="label-container">
                                                <p>Support Services </p>
                                            </div>
                                            <label for="check818" className="switch-container">
                                                <input hidden="" type="checkbox" className="switch_check" id="check818" />
                                                <div className="switch-bg"></div>
                                                <div className="round-box"></div>
                                                <div className="switch-left">
                                                    <span>NO</span>
                                                </div>
                                                <div className="switch-right">
                                                    <span>YES</span>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="check_item mt-0 mb-10"><label className="check">Type
                                            </label>
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                            value="Site 1" /><span>HHC </span></label>
                                                </div>
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                            value="Site 2" /><span>Hospice
                                                        </span></label>
                                                </div>
                                                <div className="select_btn"><label><input name="site" type="radio"
                                                            value="Site 2" /><span>Other
                                                        </span></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form_group">
                                            <label className="label">&nbsp;</label>
                                        </div>
                                        <div className="yn_switch mb-10 mt-10">
                                            <div className="label-container">
                                                <p>Additional Help needed? </p>
                                            </div>
                                            <label for="check819" className="switch-container">
                                                <input hidden="" type="checkbox" className="switch_check" id="check819" />
                                                <div className="switch-bg"></div>
                                                <div className="round-box"></div>
                                                <div className="switch-left">
                                                    <span>NO</span>
                                                </div>
                                                <div className="switch-right">
                                                    <span>YES</span>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form_group">
                                            <label className="label">Referral made to</label>
                                            <input type="text" className="form-control" placeholder="Enter" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="sub_section">
                                <p className="sub_title">IMPAIRMENT / DISABILITIES</p>
                                <div className="ald_section id">
                                    <div className="left">
                                        <div className="hed"></div>
                                        <div className="col">Impaired hearing</div>
                                        <div className="col">Impaired vision</div>
                                        <div className="col">Can perform ADL?</div>
                                        <div className="col">Can read?</div>
                                        <div className="col">Can write?</div>
                                        <div className="col">Walker</div>
                                        <div className="col">Crutches</div>
                                        <div className="col">Wheelchair</div>
                                    </div>
                                    <div className="right">
                                        <div className="hed">Yes</div>
                                        <div className="col">
                                            <div className="check-box sqeare mt-0">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-101" />
                                                    <label for="checkbox-101"></label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="check-box sqeare mt-0">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-102" />
                                                    <label for="checkbox-102"></label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="check-box sqeare mt-0">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-103" />
                                                    <label for="checkbox-103"></label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="check-box sqeare mt-0">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-104" />
                                                    <label for="checkbox-104"></label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="check-box sqeare mt-0">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-105" />
                                                    <label for="checkbox-105"></label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="check-box sqeare mt-0">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-106" />
                                                    <label for="checkbox-106"></label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="check-box sqeare mt-0">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-107" />
                                                    <label for="checkbox-107"></label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="check-box sqeare mt-0">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-108" />
                                                    <label for="checkbox-108"></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="right">
                                        <div className="hed">No</div>
                                        <div className="col">
                                            <div className="check-box sqeare mt-0">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-109" />
                                                    <label for="checkbox-109"></label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="check-box sqeare mt-0">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-110" />
                                                    <label for="checkbox-110"></label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="check-box sqeare mt-0">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-111" />
                                                    <label for="checkbox-111"></label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="check-box sqeare mt-0">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-112" />
                                                    <label for="checkbox-112"></label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="check-box sqeare mt-0">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-113" />
                                                    <label for="checkbox-113"></label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="check-box sqeare mt-0">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-114" />
                                                    <label for="checkbox-114"></label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="check-box sqeare mt-0">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-115" />
                                                    <label for="checkbox-115"></label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="check-box sqeare mt-0">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-116" />
                                                    <label for="checkbox-116"></label>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="left">
                                        <div className="hed"></div>
                                        <div className="col">Hearing Aid</div>
                                        <div className="col">Glasses/ Contacts</div>
                                        <div className="col">Dentures</div>
                                        <div className="col">Partial</div>
                                        <div className="col">Home O2</div>
                                        <div className="col">Cane</div>
                                        <div className="col">Prosthesis</div>
                                        <div className="col">Other</div>
                                    </div>
                                    <div className="right">
                                        <div className="hed">Yes</div>
                                        <div className="col">
                                            <div className="check-box sqeare mt-0">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-117" />
                                                    <label for="checkbox-117"></label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="check-box sqeare mt-0">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-118" />
                                                    <label for="checkbox-118"></label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="check-box sqeare mt-0">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-119" />
                                                    <label for="checkbox-119"></label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="check-box sqeare mt-0">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-120" />
                                                    <label for="checkbox-120"></label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="check-box sqeare mt-0">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-121" />
                                                    <label for="checkbox-121"></label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="check-box sqeare mt-0">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-122" />
                                                    <label for="checkbox-122"></label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="check-box sqeare mt-0">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-123" />
                                                    <label for="checkbox-123"></label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="check-box sqeare mt-0">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-124" />
                                                    <label for="checkbox-124"></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="right">
                                        <div className="hed">No</div>
                                        <div className="col">
                                            <div className="check-box sqeare mt-0">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-125" />
                                                    <label for="checkbox-125"></label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="check-box sqeare mt-0">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-126" />
                                                    <label for="checkbox-126"></label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="check-box sqeare mt-0">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-127" />
                                                    <label for="checkbox-127"></label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="check-box sqeare mt-0">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-128" />
                                                    <label for="checkbox-128"></label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="check-box sqeare mt-0">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-129" />
                                                    <label for="checkbox-129"></label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="check-box sqeare mt-0">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-130" />
                                                    <label for="checkbox-130"></label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="check-box sqeare mt-0">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-131" />
                                                    <label for="checkbox-131"></label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="check-box sqeare mt-0">
                                                <div className="round">
                                                    <input type="checkbox" id="checkbox-132" />
                                                    <label for="checkbox-132"></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="sub_section">
                                <p className="sub_title">DENTAL </p>
                                <div className="check_item mt-20 mb-0">
                                    <div className="select_btns mt-0">
                                        <div className="select_btn mb-0"><label><input name="site" type="radio"
                                                    value="Site 1" /><span>Own Teeth
                                                </span></label>
                                        </div>
                                        <div className="select_btn mb-0"><label><input name="site" type="radio"
                                                    value="Site 2" /><span>Dentures</span></label>
                                        </div>
                                    </div>
                                </div>
                                <div className="check_item mt-10 mb-0">
                                    <label className="check">Dental Hygiene</label>
                                    <div className="select_btns">
                                        <div className="select_btn mb-0"><label><input name="site" type="radio"
                                                    value="Site 1" /><span>Good
                                                </span></label>
                                        </div>
                                        <div className="select_btn mb-0"><label><input name="site" type="radio"
                                                    value="Site 2" /><span>Fair</span></label>
                                        </div>
                                        <div className="select_btn mb-0"><label><input name="site" type="radio"
                                                    value="Site 2" /><span>Poor</span></label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="sub_section">
                                <p className="sub_title">NUTRITION </p>

                                <div className="check_item mt-10 mb-0">
                                    <label className="check">Diet</label>
                                    <div className="select_btns">
                                        <div className="select_btn mb-0"><label><input name="site" type="radio"
                                                    value="Site 1" /><span>Regular
                                                </span></label>
                                        </div>
                                        <div className="select_btn mb-0"><label><input name="site" type="radio"
                                                    value="Site 2" /><span>NAS</span></label>
                                        </div>
                                        <div className="select_btn mb-0"><label><input name="site" type="radio"
                                                    value="Site 2" /><span>NCS Mechanical
                                                    Soft</span></label>
                                        </div>
                                        <div className="select_btn mb-0"><label><input name="site" type="radio"
                                                    value="Site 2" /><span>Pureed</span></label>
                                        </div>
                                    </div>
                                </div>
                                <div className="yn_switch mb-10 mt-10">
                                    <div className="label-container">
                                        <p>Recent weight change</p>
                                    </div>
                                    <label for="check900" className="switch-container">
                                        <input hidden="" type="checkbox" className="switch_check" id="check900" />
                                        <div className="switch-bg"></div>
                                        <div className="round-box"></div>
                                        <div className="switch-left">
                                            <span>NO</span>
                                        </div>
                                        <div className="switch-right">
                                            <span>YES</span>
                                        </div>
                                    </label>
                                </div>
                                <div className="yn_switch mb-10 mt-10">
                                    <div className="label-container">
                                        <p>Supplements</p>
                                    </div>
                                    <label for="check901" className="switch-container">
                                        <input hidden="" type="checkbox" className="switch_check" id="check901" />
                                        <div className="switch-bg"></div>
                                        <div className="round-box"></div>
                                        <div className="switch-left">
                                            <span>NO</span>
                                        </div>
                                        <div className="switch-right">
                                            <span>YES</span>
                                        </div>
                                    </label>
                                </div>
                                <div className="yn_switch mb-10 mt-10">
                                    <div className="label-container">
                                        <p>Conditions affecting eating, chewing, or swallowing</p>
                                    </div>
                                    <label for="check902" className="switch-container">
                                        <input hidden="" type="checkbox" className="switch_check" id="check902" />
                                        <div className="switch-bg"></div>
                                        <div className="round-box"></div>
                                        <div className="switch-left">
                                            <span>NO</span>
                                        </div>
                                        <div className="switch-right">
                                            <span>YES</span>
                                        </div>
                                    </label>
                                </div>
                                <div className="yn_switch mb-10 mt-10">
                                    <div className="label-container">
                                        <p>Monitoring required at mealtimes</p>
                                    </div>
                                    <label for="check903" className="switch-container">
                                        <input hidden="" type="checkbox" className="switch_check" id="check903" />
                                        <div className="switch-bg"></div>
                                        <div className="round-box"></div>
                                        <div className="switch-left">
                                            <span>NO</span>
                                        </div>
                                        <div className="switch-right">
                                            <span>YES</span>
                                        </div>
                                    </label>
                                </div>
                                <div className="yn_switch mb-10 mt-10">
                                    <div className="label-container">
                                        <p>Fluids. Monitoring</p>
                                    </div>
                                    <label for="check904" className="switch-container">
                                        <input hidden="" type="checkbox" className="switch_check" id="check904" />
                                        <div className="switch-bg"></div>
                                        <div className="round-box"></div>
                                        <div className="switch-left">
                                            <span>NO</span>
                                        </div>
                                        <div className="switch-right">
                                            <span>YES</span>
                                        </div>
                                    </label>
                                </div>
                                <div className="check_item mt-10 mb-0">
                                    <div className="select_btns mt-0">
                                        <div className="select_btn mb-0"><label><input name="site" type="radio"
                                                    value="Site 1" /><span>Increased
                                                </span></label>
                                        </div>
                                        <div className="select_btn mb-0"><label><input name="site" type="radio"
                                                    value="Site 2" /><span>Restricted</span></label>
                                        </div>
                                    </div>
                                </div>
                                <div className="check_item mt-10 mb-0">
                                    <label className="check">Mucous membranes</label>
                                    <div className="select_btns">
                                        <div className="select_btn mb-0"><label><input name="site" type="radio"
                                                    value="Site 1" /><span>Moist
                                                </span></label>
                                        </div>
                                        <div className="select_btn mb-0"><label><input name="site" type="radio"
                                                    value="Site 2" /><span>Dry</span></label>
                                        </div>
                                    </div>
                                </div>
                                <div className="check_item mt-10 mb-0">
                                    <label className="check">Skin turgor</label>
                                    <div className="select_btns">
                                        <div className="select_btn mb-0"><label><input name="site" type="radio"
                                                    value="Site 1" /><span>Good
                                                </span></label>
                                        </div>
                                        <div className="select_btn mb-0"><label><input name="site" type="radio"
                                                    value="Site 2" /><span>Fair Poor</span></label>
                                        </div>
                                    </div>
                                </div>
                                <p className="sub_title mt-20">Dietary Habits</p>
                                <div className="row mt-15">
                                    <div className="col-md-6">
                                        <div className="form_group">
                                            <label className="label">Special Diet</label>
                                            <input type="text" className="form-control" placeholder="Enter" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form_group">
                                            <label className="label">Supplements</label>
                                            <input type="text" className="form-control" placeholder="Enter" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <p className="sub_title">ELIMINATION </p>
                            <div className="check_item mt-10 mb-0">
                                <label className="check">Bladder Incontinence</label>
                                <div className="select_btns">
                                    <div className="select_btn mb-0"><label><input name="site" type="radio"
                                                value="Site 1" /><span>None
                                            </span></label>
                                    </div>
                                    <div className="select_btn mb-0"><label><input name="site" type="radio"
                                                value="Site 2" /><span>Occasional (less than daily)</span></label>
                                    </div>
                                    <div className="select_btn mb-0"><label><input name="site" type="radio"
                                                value="Site 2" /><span>Daily</span></label>
                                    </div>
                                </div>
                            </div>
                            <div className="check_item mt-10 mb-0">
                                <label className="check">Bowel Incontinence</label>
                                <div className="select_btns">
                                    <div className="select_btn mb-0"><label><input name="site" type="radio"
                                                value="Site 1" /><span>None
                                            </span></label>
                                    </div>
                                    <div className="select_btn mb-0"><label><input name="site" type="radio"
                                                value="Site 2" /><span>Occasional (less than daily)</span></label>
                                    </div>
                                    <div className="select_btn mb-0"><label><input name="site" type="radio"
                                                value="Site 2" /><span>Daily</span></label>
                                    </div>
                                </div>
                            </div>
                            <div className="yn_switch mb-10 mt-10">
                                <div className="label-container">
                                    <p>Incontinence management techniques</p>
                                </div>
                                <label for="check905" className="switch-container">
                                    <input hidden="" type="checkbox" className="switch_check" id="check905" />
                                    <div className="switch-bg"></div>
                                    <div className="round-box"></div>
                                    <div className="switch-left">
                                        <span>NO</span>
                                    </div>
                                    <div className="switch-right">
                                        <span>YES</span>
                                    </div>
                                </label>
                            </div>
                            <div className="yn_switch mb-10 mt-10">
                                <div className="label-container">
                                    <p>Bowel sounds present</p>
                                </div>
                                <label for="check906" className="switch-container">
                                    <input hidden="" type="checkbox" className="switch_check" id="check906" />
                                    <div className="switch-bg"></div>
                                    <div className="round-box"></div>
                                    <div className="switch-left">
                                        <span>NO</span>
                                    </div>
                                    <div className="switch-right">
                                        <span>YES</span>
                                    </div>
                                </label>
                            </div>
                            <div className="yn_switch mb-10 mt-10">
                                <div className="label-container">
                                    <p>Constipation</p>
                                </div>
                                <label for="check907" className="switch-container">
                                    <input hidden="" type="checkbox" className="switch_check" id="check907" />
                                    <div className="switch-bg"></div>
                                    <div className="round-box"></div>
                                    <div className="switch-left">
                                        <span>NO</span>
                                    </div>
                                    <div className="switch-right">
                                        <span>YES</span>
                                    </div>
                                </label>
                            </div>
                            <div className="yn_switch mb-10 mt-10">
                                <div className="label-container">
                                    <p>Ostomies</p>
                                </div>
                                <label for="check908" className="switch-container">
                                    <input hidden="" type="checkbox" className="switch_check" id="check908" />
                                    <div className="switch-bg"></div>
                                    <div className="round-box"></div>
                                    <div className="switch-left">
                                        <span>NO</span>
                                    </div>
                                    <div className="switch-right">
                                        <span>YES</span>
                                    </div>
                                </label>
                            </div>
                        </div>

                        <div className="section ctr">
                            <h2 className="popup_title ">ACCOMPANYING PERSONAL BELONGINGS</h2>
                            <div className="ald_section id apb">
                                <div className="left">
                                    <div className="hed"></div>
                                    <div className="col">Glasses</div>
                                    <div className="col">Contact Lenses</div>
                                    <div className="col">Dentures Upper Lower</div>
                                    <div className="col">Hearing Aid(s) Right Left</div>
                                    <div className="col">Prosthetics</div>
                                    <div className="col">Medications</div>
                                    <div className="col">Clothing</div>
                                    <div className="col">Valuables</div>
                                    <div className="col">Others </div>
                                </div>
                                <div className="right col1">
                                    <div className="hed">NA</div>
                                    <div className="col">
                                        <div className="check-box sqeare mt-0">
                                            <div className="round">
                                                <input type="checkbox" id="checkbox-101" />
                                                <label for="checkbox-101"></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="check-box sqeare mt-0">
                                            <div className="round">
                                                <input type="checkbox" id="checkbox-102" />
                                                <label for="checkbox-102"></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="check-box sqeare mt-0">
                                            <div className="round">
                                                <input type="checkbox" id="checkbox-103" />
                                                <label for="checkbox-103"></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="check-box sqeare mt-0">
                                            <div className="round">
                                                <input type="checkbox" id="checkbox-104" />
                                                <label for="checkbox-104"></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="check-box sqeare mt-0">
                                            <div className="round">
                                                <input type="checkbox" id="checkbox-105" />
                                                <label for="checkbox-105"></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="check-box sqeare mt-0">
                                            <div className="round">
                                                <input type="checkbox" id="checkbox-106" />
                                                <label for="checkbox-106"></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="check-box sqeare mt-0">
                                            <div className="round">
                                                <input type="checkbox" id="checkbox-107" />
                                                <label for="checkbox-107"></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="check-box sqeare mt-0">
                                            <div className="round">
                                                <input type="checkbox" id="checkbox-108" />
                                                <label for="checkbox-108"></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="check-box sqeare mt-0">
                                            <div className="round">
                                                <input type="checkbox" id="checkbox-108" />
                                                <label for="checkbox-108"></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="right col2">
                                    <div className="hed">Kept at own risk</div>
                                    <div className="col">
                                        <div className="check-box sqeare mt-0">
                                            <div className="round">
                                                <input type="checkbox" id="checkbox-109" />
                                                <label for="checkbox-109"></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="check-box sqeare mt-0">
                                            <div className="round">
                                                <input type="checkbox" id="checkbox-110" />
                                                <label for="checkbox-110"></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="check-box sqeare mt-0">
                                            <div className="round">
                                                <input type="checkbox" id="checkbox-111" />
                                                <label for="checkbox-111"></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="check-box sqeare mt-0">
                                            <div className="round">
                                                <input type="checkbox" id="checkbox-112" />
                                                <label for="checkbox-112"></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="check-box sqeare mt-0">
                                            <div className="round">
                                                <input type="checkbox" id="checkbox-113" />
                                                <label for="checkbox-113"></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="check-box sqeare mt-0">
                                            <div className="round">
                                                <input type="checkbox" id="checkbox-114" />
                                                <label for="checkbox-114"></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="check-box sqeare mt-0">
                                            <div className="round">
                                                <input type="checkbox" id="checkbox-115" />
                                                <label for="checkbox-115"></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="check-box sqeare mt-0">
                                            <div className="round">
                                                <input type="checkbox" id="checkbox-116" />
                                                <label for="checkbox-116"></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="check-box sqeare mt-0">
                                            <div className="round">
                                                <input type="checkbox" id="checkbox-116" />
                                                <label for="checkbox-116"></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="right col3">
                                    <div className="hed">Ward storage</div>
                                    <div className="col">
                                        <div className="check-box sqeare mt-0">
                                            <div className="round">
                                                <input type="checkbox" id="checkbox-117" />
                                                <label for="checkbox-117"></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="check-box sqeare mt-0">
                                            <div className="round">
                                                <input type="checkbox" id="checkbox-118" />
                                                <label for="checkbox-118"></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="check-box sqeare mt-0">
                                            <div className="round">
                                                <input type="checkbox" id="checkbox-119" />
                                                <label for="checkbox-119"></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="check-box sqeare mt-0">
                                            <div className="round">
                                                <input type="checkbox" id="checkbox-120" />
                                                <label for="checkbox-120"></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="check-box sqeare mt-0">
                                            <div className="round">
                                                <input type="checkbox" id="checkbox-121" />
                                                <label for="checkbox-121"></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="check-box sqeare mt-0">
                                            <div className="round">
                                                <input type="checkbox" id="checkbox-122" />
                                                <label for="checkbox-122"></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="check-box sqeare mt-0">
                                            <div className="round">
                                                <input type="checkbox" id="checkbox-123" />
                                                <label for="checkbox-123"></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="check-box sqeare mt-0">
                                            <div className="round">
                                                <input type="checkbox" id="checkbox-124" />
                                                <label for="checkbox-124"></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="check-box sqeare mt-0">
                                            <div className="round">
                                                <input type="checkbox" id="checkbox-124" />
                                                <label for="checkbox-124"></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="right col4">
                                    <div className="hed">Taken home by</div>
                                    <div className="col">
                                        <div className="check-box sqeare mt-0">
                                            <div className="round">
                                                <input type="checkbox" id="checkbox-125" />
                                                <label for="checkbox-125"></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="check-box sqeare mt-0">
                                            <div className="round">
                                                <input type="checkbox" id="checkbox-126" />
                                                <label for="checkbox-126"></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="check-box sqeare mt-0">
                                            <div className="round">
                                                <input type="checkbox" id="checkbox-127" />
                                                <label for="checkbox-127"></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="check-box sqeare mt-0">
                                            <div className="round">
                                                <input type="checkbox" id="checkbox-128" />
                                                <label for="checkbox-128"></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="check-box sqeare mt-0">
                                            <div className="round">
                                                <input type="checkbox" id="checkbox-129" />
                                                <label for="checkbox-129"></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="check-box sqeare mt-0">
                                            <div className="round">
                                                <input type="checkbox" id="checkbox-130" />
                                                <label for="checkbox-130"></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="check-box sqeare mt-0">
                                            <div className="round">
                                                <input type="checkbox" id="checkbox-131" />
                                                <label for="checkbox-131"></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="check-box sqeare mt-0">
                                            <div className="round">
                                                <input type="checkbox" id="checkbox-132" />
                                                <label for="checkbox-132"></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="check-box sqeare mt-0">
                                            <div className="round">
                                                <input type="checkbox" id="checkbox-132" />
                                                <label for="checkbox-132"></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="right col5">
                                    <div className="hed">Patient / Carer to sign </div>
                                    <div className="col">
                                        <div className="signature_block">
                                          
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="signature_block">
                                          
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="signature_block">
                                          
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="signature_block">
                                          
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="signature_block">
                                          
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="signature_block">
                                          
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="signature_block">
                                          
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="signature_block">
                                          
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="signature_block">
                                          
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="check-box sqeare  mt-20">
                                <div className="round">
                                    <input type="checkbox" id="checkbox-203" checked />
                                    <label for="checkbox-203"></label>
                                </div>
                                Patient advised to send all valuables home
                            </div>
                            <div className="form_group">
                                <label className="label">Taken by</label>
                                <input type="text" className="form-control" placeholder="Enter" />
                            </div>
                            <div className="check-box sqeare  mt-20">
                                <div className="round">
                                    <input type="checkbox" id="checkbox-203" checked />
                                    <label for="checkbox-203"></label>
                                </div>
                                Items taken in to safe custody
                            </div>
                            <div className="check_item mt-0 mb-10">
                                <div className="select_btns mt-0">
                                    <div className="select_btn mb-0"><label><input name="site" type="radio"
                                                value="Site 1" /><span>Night safe
                                            </span></label>
                                    </div>
                                    <div className="select_btn mb-0"><label><input name="site" type="radio"
                                                value="Site 2" /><span>Cashiers (less than daily)</span></label>
                                    </div>
                                    <div className="select_btn mb-0"><label><input name="site" type="radio"
                                                value="Site 2" /><span>Other</span></label>
                                    </div>
                                </div>
                            </div>
                            <div className="form_group">
                                <label className="label">Other</label>
                                <input type="text" className="form-control" placeholder="Enter" />
                            </div>
                            <div className="check-box sqeare  mt-20">
                                <div className="round">
                                    <input type="checkbox" id="checkbox-203" checked />
                                    <label for="checkbox-203"></label>
                                </div>
                                Record made in valuables book Reference
                            </div>
                            <div className="row">
                                <div className="col-md-5">
                                    <div className="form_group">
                                        <label className="label">Assessed by: Print Name</label>
                                        <input type="text" className="form-control" placeholder="Enter" />
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form_group">
                                        <label className="label">Date</label>
                                        <input type="text" className="form-control" placeholder="Enter" />
                                    </div>
                                </div>
                                <div className="col-md-5">
                                    <div className="signature_block">
                                        <p>Signature</p>
                                    </div>
                                </div>

                            </div>
                            <div className="row mt-15">
                                <div className="col-md-5">
                                    <div className="form_group">
                                        <label className="label">Witnessed by: Print Name</label>
                                        <input type="text" className="form-control" placeholder="Enter" />
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form_group">
                                        <label className="label">Date</label>
                                        <input type="text" className="form-control" placeholder="Enter" />
                                    </div>
                                </div>
                                <div className="col-md-5">
                                    <div className="signature_block">
                                        <p>Signature</p>
                                    </div>
                                </div>
                            </div>
                            <div className="check-box sqeare  mt-20">
                                <div className="round">
                                    <input type="checkbox" id="checkbox-203" />
                                    <label for="checkbox-203"></label>
                                </div>
                                Patient does not wish to send valuables or cash home / put items in safe custody.
                            </div>
                            <div className="sub_section">
                                <p className="sub_title">Patients Property Liability Disclaimer</p>
                                <p className="mt-15"><b>Please read the following before signing this form:</b></p>
                                <p>You have been informed of the Trust's arrangements for the safekeeping of your
                                    valuables. You have been advised to hand over your valuables for safekeeping whilst
                                    you remain in hospital.</p>
                                <p>You have decided to take responsibility for all your valuables whilst you remain in
                                    hospital.
                                    ……… Trust accepts no responsibility for loss or damage to any of your valuables
                                    however the loss or damage may occur, unless deposited for safekeeping.
                                </p>
                                <p>I have read and I understand the above.</p>
                                <p>Patient/Representative*</p>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="signature_block">
                                            <p>Signature</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="section ctr">
                            <h2 className="popup_title ">PREOP ADMISSION ASSESSMENT</h2>
                            <div className="sub_section">
                                <div className="check-box sqeare mt-0">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-206" checked />
                                        <label for="checkbox-206"></label>
                                    </div>
                                    No changes to pre-admission information including medication / discharge plan.
                                </div>
                                <div className="form_group mb-0">
                                    <label className="label">Changes</label>
                                    <input type="text" className="form-control" placeholder="Enter" />
                                </div>
                            </div>
                            <div className="sub_section">
                                <div className="check-box sqeare mt-0">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-206" />
                                        <label for="checkbox-206"></label>
                                    </div>
                                    Risk assessments remain unchanged from pre-admission
                                </div>

                            </div>
                            <div className="sub_section">
                                <div className="check_item mt-10 mb-0">
                                    <label className="check">If not performed</label>
                                    <div className="select_btns">
                                        <div className="select_btn mb-0"><label><input name="site" type="radio"
                                                    value="Site 1" /><span>VTE
                                                </span></label>
                                        </div>
                                        <div className="select_btn mb-0"><label><input name="site" type="radio"
                                                    value="Site 2" /><span>Moving and Handling
                                                </span></label>
                                        </div>
                                        <div className="select_btn mb-0"><label><input name="site" type="radio"
                                                    value="Site 2" /><span>Skin integrity
                                                </span></label>
                                        </div>
                                        <div className="select_btn mb-0"><label><input name="site" type="radio"
                                                    value="Site 2" /><span>Infection Control
                                                </span></label>
                                        </div>
                                        <div className="select_btn mb-0"><label><input name="site" type="radio"
                                                    value="Site 2" /><span>Nutritional
                                                </span></label>
                                        </div>
                                        <div className="select_btn mb-0"><label><input name="site" type="radio"
                                                    value="Site 2" /><span>Falls </span></label>
                                        </div>
                                    </div>
                                </div>
                                <div className="check-box sqeare mt-10">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-206" />
                                        <label for="checkbox-206"></label>
                                    </div>
                                    No signs/symptoms of infection identified (raised temperature, diarrhoea, vomiting,
                                    runny nose, cough, rash etc.)
                                </div>
                                <div className="check-box sqeare mt-0">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-206" />
                                        <label for="checkbox-206"></label>
                                    </div>
                                    Patient states they have bathed/showered today and has not shaved operative area
                                </div>
                                <div className="check-box sqeare mt-0">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-206" />
                                        <label for="checkbox-206"></label>
                                    </div>
                                    Patient advises that skin integrity is intact
                                </div>
                                <div className="check-box sqeare mt-0">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-206" />
                                        <label for="checkbox-206"></label>
                                    </div>
                                    All test results (including MRSA, CDiff, CPE) checked and no abnormalities found or
                                    further tests required
                                </div>
                                <div className="check-box sqeare mt-0">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-206" checked />
                                        <label for="checkbox-206"></label>
                                    </div>
                                    Glasses or hearing aids with patient.
                                </div>
                                <div className="form_group ">
                                    <label className="label">Details</label>
                                    <input type="text" className="form-control" placeholder="Enter" />
                                </div>
                                <div className="check-box sqeare mt-0">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-206" />
                                        <label for="checkbox-206"></label>
                                    </div>
                                    Own medication prescribed (if required) and stored securely
                                </div>
                                <div className="check-box sqeare mt-0">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-206" />
                                        <label for="checkbox-206"></label>
                                    </div>
                                    Routine medication taken prior to fasting as instructed
                                </div>
                                <div className="check-box sqeare mt-0">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-206" />
                                        <label for="checkbox-206"></label>
                                    </div>
                                    Measured for anti embolism stockings (if required) Size
                                </div>
                                <div className="check_item mt-10 ">
                                    <label className="check">Jewellery / Piercings / Metalwork</label>
                                    <div className="select_btns">
                                        <div className="select_btn "><label><input name="site" type="radio"
                                                    value="Site 1" /><span>removed
                                                </span></label>
                                        </div>
                                        <div className="select_btn "><label><input name="site" type="radio"
                                                    value="Site 2" /><span>taped (circle) Piercings to tongue
                                                </span></label>
                                        </div>
                                        <div className="select_btn "><label><input name="site" type="radio"
                                                    value="Site 2" /><span>facial area must be removed by the patient
                                                    prior to induction
                                                </span></label>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="sub_section">
                                <div className="check-box sqeare mt-0">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-206" />
                                        <label for="checkbox-206"></label>
                                    </div>
                                    Patient aware of plan for theatre and prepared safely for surgery
                                </div>
                                <div className="check_item mt-10 ">

                                    <div className="select_btns">
                                        <div className="select_btn "><label><input name="site" type="radio"
                                                    value="Site 1" /><span>Patients anxieties
                                                </span></label>
                                        </div>
                                        <div className="select_btn "><label><input name="site" type="radio"
                                                    value="Site 2" /><span>concerns relating to surgery discussed
                                                </span></label>
                                        </div>

                                    </div>
                                </div>
                                <div className="form_group ">
                                    <label className="label">Comments</label>
                                    <input type="text" className="form-control" placeholder="Enter" />
                                </div>
                                <div className="check-box sqeare mt-0">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-206" />
                                        <label for="checkbox-206"></label>
                                    </div>
                                    Patient has given verbal consent for bed rails to be used (post operatively if
                                    required) to maintain their safety whilst consciousness is impaired
                                </div>
                                <div className="check_item mt-10 ">

                                    <div className="select_btns">
                                        <div className="select_btn "><label><input name="site" type="radio"
                                                    value="Site 1" /><span>Anticipated discharge date
                                                </span></label>
                                        </div>
                                        <div className="select_btn "><label><input name="site" type="radio"
                                                    value="Site 2" /><span>time and escort person home
                                                </span></label>
                                        </div>

                                    </div>
                                </div>
                                <div className="form_group ">
                                    <label className="label">confirmed</label>
                                    <input type="text" className="form-control" placeholder="Enter" />
                                </div>
                            </div>
                            <div className="sub_section">
                                <div className="check-box sqeare mt-0">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-206" checked />
                                        <label for="checkbox-206"></label>
                                    </div>
                                    LMP confirmed
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form_group ">
                                            <label className="label">Date</label>
                                            <input type="date" className="form-control" />
                                        </div>
                                    </div>
                                </div>
                                <div className="yn_switch mb-10 mt-10">
                                    <div className="label-container">
                                        <p>Possibility of Pregnancy? </p>
                                    </div>
                                    <label for="check611" className="switch-container">
                                        <input hidden="" type="checkbox" className="switch_check" id="check611" />
                                        <div className="switch-bg"></div>
                                        <div className="round-box"></div>
                                        <div className="switch-left">
                                            <span>NO</span>
                                        </div>
                                        <div className="switch-right">
                                            <span>YES</span>
                                        </div>
                                    </label>
                                </div>
                                <div className="yn_switch mb-10 mt-10">
                                    <div className="label-container">
                                        <p>Pregnancy test required </p>
                                    </div>
                                    <label for="check611" className="switch-container">
                                        <input hidden="" type="checkbox" className="switch_check" id="check611" />
                                        <div className="switch-bg"></div>
                                        <div className="round-box"></div>
                                        <div className="switch-left">
                                            <span>NO</span>
                                        </div>
                                        <div className="switch-right">
                                            <span>YES</span>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div className="sub_section">
                                <div className="check_item mt-10 ">
                                    <label className="check">Pre-operative theatre checklist</label>
                                    <div className="select_btns">
                                        <div className="select_btn "><label><input name="site" type="radio"
                                                    value="Site 1" /><span>Commenced
                                                </span></label>
                                        </div>
                                        <div className="select_btn "><label><input name="site" type="radio"
                                                    value="Site 2" /><span>Completed (circle)
                                                </span></label>
                                        </div>
                                        <div className="select_btn "><label><input name="site" type="radio"
                                                    value="Site 2" /><span>facial area must be removed by the patient
                                                    prior to induction
                                                </span></label>
                                        </div>

                                    </div>
                                </div>
                                <div className="check-box sqeare mt-0">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-206" />
                                        <label for="checkbox-206"></label>
                                    </div>
                                    Correct site imaging films available for Consultant
                                </div>
                                <div className="check-box sqeare mt-0">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-206" />
                                        <label for="checkbox-206"></label>
                                    </div>
                                    Seen by Surgeon
                                </div>
                                <div className="check-box sqeare mt-0">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-206" />
                                        <label for="checkbox-206"></label>
                                    </div>
                                    Operation site marked by a Surgeon with an arrow using an indelible marker pen
                                </div>
                                <div className="check_item mt-10 ">
                                    <label className="check">Seen by Anaesthetist</label>
                                    <div className="select_btns">
                                        <div className="select_btn "><label><input name="site" type="radio"
                                                    value="Site 1" /><span>type of anaesthetic agreed
                                                </span></label>
                                        </div>
                                        <div className="select_btn "><label><input name="site" type="radio"
                                                    value="Site 2" /><span>anaesthetic consent recorded
                                                </span></label>
                                        </div>

                                    </div>
                                </div>
                                <div className="yn_switch mb-10 mt-10">
                                    <div className="label-container">
                                        <p>Seen by Physiotherapist (if required) </p>
                                    </div>
                                    <label for="check611" className="switch-container">
                                        <input hidden="" type="checkbox" className="switch_check" id="check611" />
                                        <div className="switch-bg"></div>
                                        <div className="round-box"></div>
                                        <div className="switch-left">
                                            <span>NO</span>
                                        </div>
                                        <div className="switch-right">
                                            <span>YES</span>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div className="sub_section">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form_group">
                                            <label className="label">The interventions initialled by </label>
                                            <input type="text" className="form-control" placeholder="Enter" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="signature_block">
                                            <p>signature of non registered staff</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-20">
                                    <div className="col-md-6">
                                        <div className="form_group">
                                            <label className="label">have been delegated by</label>
                                            <input type="text" className="form-control" placeholder="Enter" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="signature_block">
                                            <p>signature of responsible Registered Nurse</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                                </div>

                            </div>
                            <div className="popup_footer">
                                <div className="prescribe_footer">
                                    <div className="dsp-flex">
                                        <a className="icon_btn"> <span className="material-icons">save</span> SAVE</a>
                                        <button className="border_btn"><span className="material-icons">save_as</span>SAVE &amp;
                                            CLOSE
                                        </button>
                                    </div>
                                    <button className="btn dsp_flex"><span className="material-icons"><span
                                        className="material-icons">close</span></span>DISCARD </button>
                                </div>
                            </div>
                        </div>
                    </div>





                </div>
            </div>




        </React.Fragment>
    )
}

NursingAdmission.propTypes = {
    t: PropTypes.any,
    getWards: PropTypes.func,
    getTheatres: PropTypes.func,
    wards: PropTypes.array,
    user: PropTypes.any,
    theatres: PropTypes.array,
    clinic: PropTypes.array,
    patients: PropTypes.array,
    doctors: PropTypes.array,
    profile: PropTypes.object
}

const mapStateToProps = ({ Layout, Login }) => ({
    wards: Layout.wards,
    user: Login.user,
    theatres: Layout.theatres,
    clinic: Layout.clinic,
    patients: Layout.patients,
    doctors: Layout.doctors,
    profile: Layout.profile
})

const mapDispatchToProps = dispatch => ({
    getWards: () => dispatch(getWardsList()),
    getTheatres: () => dispatch(getTheatresList()),
    getClinic: () => dispatch(getClinicList()),
    getPatient: () => dispatch(getPatientList()),
    getUserprofile: (id) => dispatch(getUprofile(id)),
    getDoctor: () => dispatch(getDoctorList()),
    patientupdate: (id, data) => dispatch(updatePatient(id, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(NursingAdmission)
