import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import MetaTags from 'react-meta-tags';
import surgery from '../../assets/images/surgery.png';
import { useHistory } from "react-router-dom";

import { connect } from "react-redux"
import { getWardsList, getTheatresList, getClinicList, getPatientList, getUprofile, getDoctorList, updatePatient } from "../../store/actions"

const NursingPick = props => {

    const history = useHistory();
    const [visiblePatients, setPatient] = useState(true)
    const [visiblelanding, setClanding] = useState(false)
    const [successmessage, setSuccess] = useState("")


    const [clinicValues, setClinic] = useState({})
    const [clinicPatient, setCPatient] = useState({})

    const {
        wards, user, theatres, clinic, patients, profile, doctors
    } = props;

    useEffect(() => {
        $(".modal-backdrop").hide()
    }, [])

    useEffect(() => {
        const { getWards, getTheatres, getClinic, getDoctor, getPatient, getUserprofile } = props
        // getWards()
        // getTheatres()
        // getClinic()
        getPatient()
        getDoctor()
        let userId = localStorage.getItem("authUser")
        getUserprofile(userId)
    }, [])

    const selectPatient = (e) => {

        setCPatient(e)
        setPatient(false)
        setClanding(true)


    }
    const updateInputValue = (e) => {
        let val = e.target.value;
        let name = e.target.name;
        let updatedValue = {};
        updatedValue = { [name]: val };
        setClinic(clinicValues => ({
            ...clinicValues,
            ...updatedValue
        }));
    }

    const updateClinic = (e) => {
        const { patientupdate } = props
        let id = clinicPatient._id
        patientupdate(id, clinicValues)
        setSuccess("Update successfully")

        setTimeout(() => {
            setSuccess("")
        }, 2000);
    }

    const theatreBack = (e) => {
        e.preventDefault()
        // props.history.push("/theatre")
        history.goBack()
    }


    return (
        <React.Fragment>

            <MetaTags>
                <title>Nursing Pick Lists & Preferences</title>
            </MetaTags>
            <div className="theatre">
            <div className='popup_wrp'>
                <div className="popup_full prescribe">
                    <div className='popup_hed'>
                        <h2> Theatre list Advance Preparation</h2>
                        <a className='popup_close' onClick={(e) => theatreBack(e)}><span className="material-icons">close</span></a>
                    </div>
                    <div className='popup_content'>
                        <div className='popup_scroll main pr'>
                        <div className="ctr cont consent tla">
                                <div className="consent_btn">
                                    <a className="landing_btn"> 
                                        <img src={surgery} className="icon" />
                                        THEATRE SCHEDULE WEEK AHEAD </a>
                                </div>
                                <div className="consent_btn">
                                    <a className="landing_btn"> 
                                        <img src={surgery} className="icon" />
                                        EQUIPMENT ALERTS </a>
                                </div>
                                <div className="consent_btn">
                                    <a className="landing_btn">
                                        <img src={surgery} className="icon" />
                                        REPS</a>
                                </div>
                                <div className="consent_btn">
                                    <a className="landing_btn">
                                        <img src={surgery} className="icon" />
                                        PATIENT ALERTS</a>
                                </div>
                                <div className="consent_btn">
                                    <a className="landing_btn"> 
                                        <img src={surgery} className="icon" />
                                        PICK LISTS</a>
                                </div>
                                <div className="consent_btn">
                                    <a className="landing_btn"> 
                                        <img src={surgery} className="icon" />
                                        SURGEON FAVOURITES</a>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            </div>

        </React.Fragment>
    )
}

NursingPick.propTypes = {
    t: PropTypes.any,
    getWards: PropTypes.func,
    getTheatres: PropTypes.func,
    wards: PropTypes.array,
    user: PropTypes.any,
    theatres: PropTypes.array,
    clinic: PropTypes.array,
    patients: PropTypes.array,
    doctors: PropTypes.array,
    profile: PropTypes.object
}

const mapStateToProps = ({ Layout, Login }) => ({
    wards: Layout.wards,
    user: Login.user,
    theatres: Layout.theatres,
    clinic: Layout.clinic,
    patients: Layout.patients,
    doctors: Layout.doctors,
    profile: Layout.profile
})

const mapDispatchToProps = dispatch => ({
    getWards: () => dispatch(getWardsList()),
    getTheatres: () => dispatch(getTheatresList()),
    getClinic: () => dispatch(getClinicList()),
    getPatient: () => dispatch(getPatientList()),
    getUserprofile: (id) => dispatch(getUprofile(id)),
    getDoctor: () => dispatch(getDoctorList()),
    patientupdate: (id, data) => dispatch(updatePatient(id, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(NursingPick)
