import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import Moment from 'moment';
import MetaTags from 'react-meta-tags';
import PatientBlock from "../Clinic/patientBlock";
import { Link } from "react-router-dom"
import speech from '../../assets/images/speech_to_text.svg';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition'
import WardMenus from "./menus";
import { connect } from "react-redux"
import { removeSpecialCharacters } from '../../utils/utils';
import { getWardsList, getTheatresList, getClinicList, getPatientList, getUprofile, getDoctorList, updatePatient, getNews, updateDailyRounds, getDailyRounds } from "../../store/actions"

const Landing = props => {
    document.body.style.overflow = '';
    const currentDate = Moment().format('MMM DD, YYYY')
    let clinicPatient = JSON.parse(localStorage.getItem("p"))

    const [file, setFile] = useState({});
    const [prevCount, setprevCount] = useState(0)
    const [clinicValues, setClinic] = useState({})
    const [previousDate, setprevDate] = useState(currentDate)
    const [visisbleSpeechtotext, setVisibleSpeechtotext] = useState(false)
    const [speechtextname, setspeechtextname] = useState(false)
    const [dailyround, set_dailyround] = useState({})
    const [ang, setAng] = useState(false)
    const [medication, setMedication] = useState({ antiemetics_laxatives: false, analgesics: false, antibiotics: false })
    const [transcribeText, settranscribeText] = useState("")


    const {
        wards, user, theatres, clinic, patients, profile, doctors, news, dailyrounds
    } = props;

    useEffect(() => {
        const { getWards, getTheatres, getClinic, getDoctor, getPatient, getUserprofile, newsList, dailyRoundsList } = props
        // getWards()
        // getTheatres()
        // getClinic()
        const formatDate = Moment().subtract(1, 'day').format('MMM DD, YYYY')
        const formattodayDate = Moment().format('MMM DD, YYYY')
        let clinicPatient = JSON.parse(localStorage.getItem("p"))
        let id = clinicPatient._id
        let drdata = {
            "date": [formatDate, formattodayDate],
            "patientId": id
        }
        dailyRoundsList(drdata)

        getPatient()
        getDoctor()
        let userId = localStorage.getItem("authUser")
        getUserprofile(userId)
    }, [])


    useEffect(() => {
        let clinicPatient = JSON.parse(localStorage.getItem("p"))
        if (patients && patients.length > 0 && clinicPatient && clinicPatient._id) {
            const cPatient = patients.filter(
                usr => usr._id === clinicPatient._id
            )
            if (cPatient && cPatient[0]) {
                let cpatientData = cPatient[0]
                delete cpatientData["_id"]
                setClinic(clinicValues => ({
                    ...clinicValues,
                    ...cpatientData
                }));
            }

        }
    }, [patients])


    const updateInputValue = (e) => {
        let val = e.target.value;
        let name = e.target.name;
        let updatedValue = {};
        updatedValue = { [name]: val };

        set_dailyround(dailyround => ({
            ...dailyround,
            ...updatedValue
        }));
    }

    const updateInputValues = (e, name, val) => {
        e.preventDefault()
        let updatedValue = {};
        updatedValue = { [name]: val };
        set_dailyround(dailyround => ({
            ...dailyround,
            ...updatedValue
        }));
    }

    const handleChange = (event) => {
        updateInputValues(event, event.target.name, event.target.checked)
    }

  
   
    return (
        <React.Fragment>

            <MetaTags>
                <title>Nurse</title>
            </MetaTags>
            <div className='ward '>
                <div className="main n_office">
                    <div className="wrapper">
                        <div className="hed">
                            <WardMenus />
                        </div>
                    </div>

                    <div className="wrapper">
                <div className="section">
                    <div className="title_hed">
                        <h2 className="popup_title">Bed Management View</h2>
                        <div className="beds_count">Empty Beds - <span>75</span></div>
                    </div>
                    <table className="table table-striped no_table">
                        <thead>
                            <tr>

                                <th>Patients goinng to Theatre</th>
                                <th>NBM From</th>
                                <th>Theatre Flow</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <span className="icon_text"> <span className="material-icons icon"> personal_injury
                                        </span>Finley Goldsmith </span>
                                </td>
                                <td>14 May 2023 15:30</td>
                                <td> <a href="#" className="btn">In Recovery</a></td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="icon_text"> <span className="material-icons icon"> personal_injury
                                        </span>Finley Goldsmith </span>
                                </td>
                                <td>14 May 2023 15:30</td>
                                <td> <a href="#" className="btn">In Recovery</a></td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="icon_text"> <span className="material-icons icon"> personal_injury
                                        </span>Finley Goldsmith </span>
                                </td>
                                <td>14 May 2023 15:30</td>
                                <td> <a href="#" className="btn">In Recovery</a></td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="icon_text"> <span className="material-icons icon"> personal_injury
                                        </span>Finley Goldsmith </span>
                                </td>
                                <td>14 May 2023 15:30</td>
                                <td> <a href="#" className="btn">In Recovery</a></td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="icon_text"> <span className="material-icons icon"> personal_injury
                                        </span>Finley Goldsmith </span>
                                </td>
                                <td>14 May 2023 15:30</td>
                                <td> <a href="#" className="btn">In Recovery</a></td>
                            </tr>

                        </tbody>
                    </table>

                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="section mt-20">
                            <h2 className="popup_title">New Admissions</h2>
                            <table className="table table-striped no_table">
                                <thead>
                                    <tr>
                                        <th>Patients Name</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <span className="icon_text"> <span className="material-icons icon"> personal_injury
                                                </span>Finley Goldsmith </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className="icon_text"> <span className="material-icons icon"> personal_injury
                                                </span>Finley Goldsmith </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className="icon_text"> <span className="material-icons icon"> personal_injury
                                                </span>Finley Goldsmith </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className="icon_text"> <span className="material-icons icon"> personal_injury
                                                </span>Finley Goldsmith </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className="icon_text"> <span className="material-icons icon"> personal_injury
                                                </span>Finley Goldsmith </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="section mt-20">
                            <h2 className="popup_title">EDD</h2>
                            <table className="table table-striped no_table">
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            14 May 2023 15:30
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            14 May 2023 15:30
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            14 May 2023 15:30
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            14 May 2023 15:30
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            14 May 2023 15:30
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="section mt-20">
                            <h2 className="popup_title">Discharge Dues</h2>
                            <table className="table table-striped no_table">
                                <thead>
                                    <tr>
                                        <th>Patients Name</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <span className="icon_text"> <span className="material-icons icon"> personal_injury
                                                </span>Finley Goldsmith </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className="icon_text"> <span className="material-icons icon"> personal_injury
                                                </span>Finley Goldsmith </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className="icon_text"> <span className="material-icons icon"> personal_injury
                                                </span>Finley Goldsmith </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className="icon_text"> <span className="material-icons icon"> personal_injury
                                                </span>Finley Goldsmith </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className="icon_text"> <span className="material-icons icon"> personal_injury
                                                </span>Finley Goldsmith </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="section mt-20">
                            <h2 className="popup_title">Sleepers In/Out</h2>
                            <table className="table table-striped no_table">
                                <thead>
                                    <tr>
                                        <th>Patients Name</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <span className="icon_text"> <span className="material-icons icon"> personal_injury
                                                </span>Finley Goldsmith </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className="icon_text"> <span className="material-icons icon"> personal_injury
                                                </span>Finley Goldsmith </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className="icon_text"> <span className="material-icons icon"> personal_injury
                                                </span>Finley Goldsmith </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className="icon_text"> <span className="material-icons icon"> personal_injury
                                                </span>Finley Goldsmith </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className="icon_text"> <span className="material-icons icon"> personal_injury
                                                </span>Finley Goldsmith </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>
                <div className="section mt-20">
                    <div className="title_hed">
                        <h2 className="popup_title">Staffing Levels</h2>
                        <div className="date">May 15, 2023</div>
                    </div>
                    <table className="table table-striped no_table sl_table">
                        <thead>
                            <tr>
                                <th></th>
                                <th>DAY -SHIFT</th>
                                <th>DAY -SHIFT</th>
                                <th>NIGHT -SHIFT</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    WARD MANAGER
                                </td>
                                <td>
                                    <div className="form-group">
                                        <input type="text" placeholder="Enter" className="form-control" />
                                    </div>
                                </td>
                                <td>
                                    <div className="form-group">
                                        <input type="text" placeholder="Enter" className="form-control" />
                                    </div>
                                </td>
                                <td>
                                    <div className="form-group">
                                        <input type="text" placeholder="Enter" className="form-control" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    SENIOR CHARGE NURSE
                                </td>
                                <td>
                                    <div className="form-group">
                                        <input type="text" placeholder="Enter" className="form-control" />
                                    </div>
                                </td>
                                <td>
                                    <div className="form-group">
                                        <input type="text" placeholder="Enter" className="form-control" />
                                    </div>
                                </td>
                                <td>
                                    <div className="form-group">
                                        <input type="text" placeholder="Enter" className="form-control" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    NURSE IN CHARGE
                                </td>
                                <td>
                                    <div className="form-group">
                                        <input type="text" placeholder="Enter" className="form-control" />
                                    </div>
                                </td>
                                <td>
                                    <div className="form-group">
                                        <input type="text" placeholder="Enter" className="form-control" />
                                    </div>
                                </td>
                                <td>
                                    <div className="form-group">
                                        <input type="text" placeholder="Enter" className="form-control" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    CLINICAL NURSE MANAGER
                                </td>
                                <td>
                                    <div className="form-group">
                                        <input type="text" placeholder="Enter" className="form-control" />
                                    </div>
                                </td>
                                <td>
                                    <div className="form-group">
                                        <input type="text" placeholder="Enter" className="form-control" />
                                    </div>
                                </td>
                                <td>
                                    <div className="form-group">
                                        <input type="text" placeholder="Enter" className="form-control" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    MATRON/SERVICE MANAGER
                                </td>
                                <td>
                                    <div className="form-group">
                                        <input type="text" placeholder="Enter" className="form-control" />
                                    </div>
                                </td>
                                <td>
                                    <div className="form-group">
                                        <input type="text" placeholder="Enter" className="form-control" />
                                    </div>
                                </td>
                                <td>
                                    <div className="form-group">
                                        <input type="text" placeholder="Enter" className="form-control" />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="table table-striped no_table sl_table">
                        <thead>
                            <tr>
                                <th></th>
                                <th colSpan="2">DAY -SHIFT</th>

                                <th colSpan="2">DAY -SHIFT</th>

                            </tr>
                            <tr>
                                <th></th>
                                <th>WE PLANNED</th>
                                <th>WE HAVE</th>
                                <th>WE PLANNED</th>
                                <th>WE HAVE</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    REGISTERED NURSES
                                </td>
                                <td>
                                    <div className="form-group">
                                        <input type="text" placeholder="Enter" className="form-control" />
                                    </div>
                                </td>
                                <td>
                                    <div className="form-group">
                                        <input type="text" placeholder="Enter" className="form-control" />
                                    </div>
                                </td>
                                <td>
                                    <div className="form-group">
                                        <input type="text" placeholder="Enter" className="form-control" />
                                    </div>
                                </td>
                                <td>
                                    <div className="form-group">
                                        <input type="text" placeholder="Enter" className="form-control" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    AGENCY RNs
                                </td>
                                <td>
                                    <div className="form-group">
                                        <input type="text" placeholder="Enter" className="form-control" />
                                    </div>
                                </td>
                                <td>
                                    <div className="form-group">
                                        <input type="text" placeholder="Enter" className="form-control" />
                                    </div>
                                </td>
                                <td>
                                    <div className="form-group">
                                        <input type="text" placeholder="Enter" className="form-control" />
                                    </div>
                                </td>
                                <td>
                                    <div className="form-group">
                                        <input type="text" placeholder="Enter" className="form-control" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    BANK RNs
                                </td>
                                <td>
                                    <div className="form-group">
                                        <input type="text" placeholder="Enter" className="form-control" />
                                    </div>
                                </td>
                                <td>
                                    <div className="form-group">
                                        <input type="text" placeholder="Enter" className="form-control" />
                                    </div>
                                </td>
                                <td>
                                    <div className="form-group">
                                        <input type="text" placeholder="Enter" className="form-control" />
                                    </div>
                                </td>
                                <td>
                                    <div className="form-group">
                                        <input type="text" placeholder="Enter" className="form-control" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    HEALTH CARE ASSTS.
                                </td>
                                <td>
                                    <div className="form-group">
                                        <input type="text" placeholder="Enter" className="form-control" />
                                    </div>
                                </td>
                                <td>
                                    <div className="form-group">
                                        <input type="text" placeholder="Enter" className="form-control" />
                                    </div>
                                </td>
                                <td>
                                    <div className="form-group">
                                        <input type="text" placeholder="Enter" className="form-control" />
                                    </div>
                                </td>
                                <td>
                                    <div className="form-group">
                                        <input type="text" placeholder="Enter" className="form-control" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    AGENCY HCAs
                                </td>
                                <td>
                                    <div className="form-group">
                                        <input type="text" placeholder="Enter" className="form-control" />
                                    </div>
                                </td>
                                <td>
                                    <div className="form-group">
                                        <input type="text" placeholder="Enter" className="form-control" />
                                    </div>
                                </td>
                                <td>
                                    <div className="form-group">
                                        <input type="text" placeholder="Enter" className="form-control" />
                                    </div>
                                </td>
                                <td>
                                    <div className="form-group">
                                        <input type="text" placeholder="Enter" className="form-control" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    BANK HCAs
                                </td>
                                <td>
                                    <div className="form-group">
                                        <input type="text" placeholder="Enter" className="form-control" />
                                    </div>
                                </td>
                                <td>
                                    <div className="form-group">
                                        <input type="text" placeholder="Enter" className="form-control" />
                                    </div>
                                </td>
                                <td>
                                    <div className="form-group">
                                        <input type="text" placeholder="Enter" className="form-control" />
                                    </div>
                                </td>
                                <td>
                                    <div className="form-group">
                                        <input type="text" placeholder="Enter" className="form-control" />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>
                <div className="section mt-20 na_table">
                    <h2 className="popup_title">Nursing Acuity</h2>
                    <table className="table table-striped no_table ">
                        <thead>
                            <tr className="color1">
                                <th></th>
                                <th>Today</th>
                                <th>12-9-23</th>
                                <th>12-9-23</th>
                                <th>12-9-23</th>
                                <th>12-9-23</th>
                                <th>12-9-23</th>
                                <th>12-9-23</th>
                                <th>12-9-23</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="color2">
                                <td> <span className="icon_text"> Stable patients <a data-toggle="modal"
                                            data-target="#exampleModalLong"
                                            className="material-icons icon">arrow_outward</a></span></td>
                                <td> 20</td>
                                <td>20 </td>
                                <td> 20</td>
                                <td>25 </td>
                                <td> 12</td>
                                <td>20 </td>
                                <td> 25</td>
                                <td>25 </td>
                            </tr>
                            <tr className="color3">
                                <td> <span className="icon_text"> Stable patients <a href="# "
                                            className="material-icons icon">arrow_outward</a></span></td>
                                <td> 20</td>
                                <td>20 </td>
                                <td> 20</td>
                                <td>25 </td>
                                <td> 12</td>
                                <td>20 </td>
                                <td> 25</td>
                                <td>25 </td>
                            </tr>
                            <tr className="color4">
                                <td> <span className="icon_text"> Stable patients <a href="# "
                                            className="material-icons icon">arrow_outward</a></span></td>
                                <td> 20</td>
                                <td>20 </td>
                                <td> 20</td>
                                <td>25 </td>
                                <td> 12</td>
                                <td>20 </td>
                                <td> 25</td>
                                <td>25 </td>
                            </tr>
                            <tr className="color5">
                                <td> <span className="icon_text"> Stable patients <a href="# "
                                            className="material-icons icon">arrow_outward</a></span></td>
                                <td> 20</td>
                                <td>20 </td>
                                <td> 20</td>
                                <td>25 </td>
                                <td> 12</td>
                                <td>20 </td>
                                <td> 25</td>
                                <td>25 </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="modal add_form na_popup fade" id="exampleModalLong" tabIndex="-1" role="dialog"
                        aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Nursing Acuity</h5>
                                    <span className="material-icons" data-dismiss="modal" aria-label="Close">
                                        close
                                    </span>
                                </div>
                                <div className="modal-body">

                                    <div className="block color2">
                                        <h3>STABLE PATIENT (Straight forward)</h3>
                                        <ol>
                                            <li>Vitals stable - On 8 hourly observations.</li>
                                            <li>Alert and oriented, Calm, cooperative.</li>
                                            <li>Respiratory Stable on Room air.</li>
                                            <li>On Oral Medications.</li>
                                            <li>Blood glucose normal.</li>
                                            <li>Pain well managed with oral or IV meds 4 hourly.</li>
                                            <li> &gt; 2 Drains.</li>
                                            <li>Once/twice daily dressings / Wound Vac.</li>
                                            <li>Independent in ADLs.</li>
                                            <li>One person assist to bathroom/bedpan.</li>
                                            <li>Standard precautions. No infection isolation.</li>
                                            <li>No Safety / Falls risk</li>
                                            <li>Stable transfer Routine discharge
                                                High Volume - Up to 90% Standardised patient routes - Fast Throughput.
                                            </li>
                                        </ol>
                                    </div>
                                    <div className="block color3">
                                        <h3>MODERATE RISK PATIENT (Low complexity) If they have 2 or more of the following</h3>
                                        <ol>
                                            <li>On 4 hourly vitals observations – NEWS &lt; 3.</li>
                                            <li>Heartrate &lt; 130.</li>
                                            <li>Low grade temperature 98.7°F – 100 .3°F.</li>
                                            <li>Oxygen &gt; 2 L via nasal cannula.</li>
                                            <li>Has a Pacemaker.</li>
                                            <li>Anxious/slightly agitated.</li>
                                            <li>On IV infusion.</li>
                                            <li>Pain controlled only by PCA/Nerve block.</li>
                                            <li>Has Nausea/vomiting.</li>
                                            <li>Blood glucose requiring monitoring.</li>
                                            <li>Has an NG tube /Chest tube.</li>
                                            <li>Needs to be isolated for infection.</li>
                                            <li>On Dialysis. </li>
                                            <li>Incontinent bowel/bladder - Enema.</li>
                                            <li>Needs assistance with ADLs.</li>
                                            <li>Requires 2 person assist for out of bed.</li>
                                            <li>Sitter 1:1.</li>
                                            <li>Needs Out of area discharge.</li>
                                        </ol>
                                    </div>
                                    <div className="block color4">
                                        <h3>COMPLEX PATIENT If they have any of the following</h3>
                                        <ol>
                                        <li>On 2 hourly vitals.</li>
                                        <li>Delirium/altered mental status.</li>
                                        <li>Oxygen &lt; 2 L via nasal cannula.</li>
                                        <li>Change in BP Temperature &lt; 100 .3°F.</li>
                                        <li>1 Unit blood transfusion / Fluid bolus for BP.</li>
                                        <li>Needs 2 hourly pain management.</li>
                                        <li>Tracheostomy/ NG tube / Chest tube.</li>
                                        <li>Drain measured two hourly.</li>
                                        <li>Three times a day wound dressing /complex dressings by RN.</li>
                                        <li>Multiple wounds.</li>
                                        <li>Requires infection isolation.</li>
                                        <li>Blind, Deaf etc / Translator needed.</li>
                                        <li>Requires 2 hourly turns.</li>
                                        <li>Requires consistent assistance hourly.</li>
                                        <li>Bed alarm without sitter.</li>
                                        <li>Complex discharge.</li>
                                        <li>Discharge to hospice.</li>

                                        </ol>
                                    </div>
                                    <div className="block color5">
                                        <h3>HIGH-RISK PATIENT If they have any of the following.</h3>
                                        <ol>
                                            <li>Unstable vitals.</li>
                                            <li>Oxygen via mask.</li>
                                            <li>Can’t maintain secretions independently.</li>
                                            <li>Atrial fibrillation or PE.</li>
                                            <li>&lt; 1 blood transfusion.</li>
                                            <li>Highly agitated1: 1.    </li>
                                            <li>Restraints.</li>
                                            <li>end of life care.</li>
                                            <li>Uncontrolled pain with IV IM/PO.</li>
                                            <li>Drain measured hourly.</li>
                                            <li>Chest tube output &lt; 100ml/2 hrs.</li>
                                            <li>Active drainage, change &lt; 30 minutes or &lt; 3 times a day.</li>
                                            <li>Hourly toilet needs.</li>
                                            <li>Paraplegic or quadriplegic.</li>
                                            <li>Total care(lifts).</li>
                                            <li>Complicated post op.</li>
                                            <li>Transfer to HDU/ITU.</li>
                                        </ol>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="title_hed">
                        <h2 className="popup_title">Occupancy</h2>

                    </div>
                    <table className="table table-striped no_table occupancy">
                        <thead>
                            <tr>
                                <th>Total beds</th>
                                <th>occupancy</th>
                                <th>Percentage</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <span className="icon_text"> <span className="material-icons icon"> hotel
                                        </span>100 </span>
                                </td>
                                <td>80</td>
                                <td> <span href="#" className="btn">80%</span></td>
                            </tr>

                        </tbody>
                    </table>

                </div>
            </div>
                   

                </div>
            </div>

          


        </React.Fragment>
    )
}

Landing.propTypes = {
    getWards: PropTypes.func,
    getTheatres: PropTypes.func,
    wards: PropTypes.array,
    user: PropTypes.any,
    theatres: PropTypes.array,
    clinic: PropTypes.array,
    patients: PropTypes.array,
    doctors: PropTypes.array,
    profile: PropTypes.object,
    news: PropTypes.array,
}

const mapStateToProps = ({ Layout, Login }) => ({
    wards: Layout.wards,
    user: Login.user,
    theatres: Layout.theatres,
    clinic: Layout.clinic,
    patients: Layout.patients,
    doctors: Layout.doctors,
    profile: Layout.profile,
    news: Layout.news,
    dailyrounds: Layout.dailyrounds
})

const mapDispatchToProps = dispatch => ({
    getWards: () => dispatch(getWardsList()),
    getTheatres: () => dispatch(getTheatresList()),
    getClinic: () => dispatch(getClinicList()),
    getPatient: () => dispatch(getPatientList()),
    getUserprofile: (id) => dispatch(getUprofile(id)),
    getDoctor: () => dispatch(getDoctorList()),
    patientupdate: (id, data) => dispatch(updatePatient(id, data)),
    newsList: (data) => dispatch(getNews(data)),
    dailyRoundsList: (data) => dispatch(getDailyRounds(data)),
    dailyUpdate: (data) => dispatch(updateDailyRounds(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Landing)
