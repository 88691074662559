import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import MetaTags from 'react-meta-tags';
import Moment from 'moment';

import { connect } from "react-redux"
import { getWardsList, getTheatresList, getClinicList, getPatientList, getUprofile, getDoctorList, updatePatient, getNews, updateDailyRounds, getDailyRounds } from "../../store/actions"

const OnCallMeet = props => {
    document.body.style.overflow = '';
    document.body.style.padding = '';
    const {
        wards, user, theatres, clinic, patients, profile, doctors
    } = props;

    useEffect(() => {
        const {  getDoctor, getPatient, getUserprofile } = props
        
        getPatient()
        getDoctor()
        let userId = localStorage.getItem("authUser")
        getUserprofile(userId)
    }, [])

    const btnClick = (e, page) => {
        e.preventDefault()
        props.history.push("/"+page)
    }

    const oncallDate = Moment().format('MMM DD, YYYY')
    return (
        <React.Fragment>

            <MetaTags>
                <title>On Call</title>
            </MetaTags>
            <div className='ward '>
                <div className="main na_section oncall">
                    <div className="wrapper">
                           
                            <div className="step_block">
                   
                                <div className="oncall_wrp">
                                    <h2>ON CALL</h2>
                                    <div className="call_shift">
                                    <p>Are you ready to start your on call shift for today.</p>
                                    <p><b>{oncallDate}</b></p>
                                    <div className="btns">
                                        <button type="button"  onClick={(e) => btnClick(e, 'oncall')} className="btn">  No</button>
                                        <button type="button" onClick={(e) => btnClick(e, 'calllist')} className="btn">  YES</button>
                                    </div>
                                    </div>
                                </div>
                            </div>

                    </div>
                </div>
            </div>




        </React.Fragment>
    )
}

OnCallMeet.propTypes = {
    getWards: PropTypes.func,
    getTheatres: PropTypes.func,
    wards: PropTypes.array,
    user: PropTypes.any,
    theatres: PropTypes.array,
    clinic: PropTypes.array,
    patients: PropTypes.array,
    doctors: PropTypes.array,
    profile: PropTypes.object,
    news: PropTypes.array,
}

const mapStateToProps = ({ Layout, Login }) => ({
    wards: Layout.wards,
    user: Login.user,
    theatres: Layout.theatres,
    clinic: Layout.clinic,
    patients: Layout.patients,
    doctors: Layout.doctors,
    profile: Layout.profile,
    news: Layout.news,
    dailyrounds: Layout.dailyrounds
})

const mapDispatchToProps = dispatch => ({
    getWards: () => dispatch(getWardsList()),
    getTheatres: () => dispatch(getTheatresList()),
    getClinic: () => dispatch(getClinicList()),
    getPatient: () => dispatch(getPatientList()),
    getUserprofile: (id) => dispatch(getUprofile(id)),
    getDoctor: () => dispatch(getDoctorList()),
    patientupdate: (id, data) => dispatch(updatePatient(id, data)),
    newsList: (data) => dispatch(getNews(data)),
    dailyRoundsList: (data) => dispatch(getDailyRounds(data)),
    dailyUpdate: (data) => dispatch(updateDailyRounds(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(OnCallMeet)
