import React, { useState, useEffect } from "react";
import styles from "./index.module.scss";
import { useLocation, useHistory } from "react-router-dom";
import config from "../../../../configuration/config.js";
import axios from "../../../../configuration/axios.js";

const Question = () => {
  const location = useLocation();
  const history = useHistory();

  const { categoryId, categoryName, timeTakenInSeconds, pdf, subCategoryName } =
    location.state || {};
  console.log(timeTakenInSeconds);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState({});
  const [isOptionSelected, setIsOptionSelected] = useState(false);
  const [questions, setQuestions] = useState([]);
 
  const handleOptionChange = (event, multipleAnswers) => {
    const { value, checked } = event.target;

    if (multipleAnswers) {
      const currentAnswers = answers[currentQuestion] || [];
      if (checked) {
        setAnswers({
          ...answers,
          [currentQuestion]: [...currentAnswers, value],
        });
      } else {
        setAnswers({
          ...answers,
          [currentQuestion]: currentAnswers.filter((answer) => answer !== value),
        });
      }
    } else {
      setAnswers({ ...answers, [currentQuestion]: [value] });
      setIsOptionSelected(true);
    }
  };

  const handleSubmit = () => {
    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
      setIsOptionSelected(false);
    } else {
      handleFinalSubmit();
    }
  };

  const getQuestion = async () => {
    try {
      const response = await axios.get(config.quiz + "?pageNumber=1&limit=20&appname=ipr", {
        params: {
          category_id: categoryId,
        },
      });
      setQuestions(response?.data?.result?.data);
    } catch (error) {
      console.error("Error fetching questions:", error);
    }
  };
  console.log(questions);
  useEffect(() => {
    getQuestion();
  }, []);

  const handleFinalSubmit = async () => {
    const correctAnswersCount = questions.reduce((count, question, index) => {
      const selectedAnswers = answers[index] || [];
      const correctOptions = question.options
        .filter((option) => option.answer === true)
        .map((option) => option.label);

      const isCorrect =
        correctOptions.length === selectedAnswers.length &&
        correctOptions.every((answer) => selectedAnswers.includes(answer));

      return isCorrect ? count + 1 : count;
    }, 0);

    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString();
    const formattedTime = currentDate.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });

    try {
      const response = await axios.post(config.createResult+"/?appname=ipr", {
        userId: JSON.parse(localStorage.getItem("p"))._id,
        categoryId: categoryId,
        categoryName: categoryName,
        hospital:"ipr",
        subCategories: [
          {
            subCategoryName: subCategoryName,
            result: correctAnswersCount,
            outOf: questions.length,
            pdfLink: pdf,
            readTime: Math.ceil(timeTakenInSeconds / 60),
          },
        ],
      });
      console.log(response);
    } catch (error) {
      console.error("Error submitting questions:", error);
    }

    history.push( {
      pathname: "/result",
      state: {
        totalQuestions: questions.length,
        correctAnswers: correctAnswersCount,
        date: formattedDate,
        time: formattedTime,
        categoryName: categoryName,
        timeTakenInSeconds: timeTakenInSeconds,
        subCategoryName: subCategoryName,
      },
    });
  };

  return (
    <div className={styles["page-container"]}>
      <div className={styles["question-container"]}>
        <div className={styles["question-top-counter"]}>
          Question {currentQuestion + 1} /{" "}
          <span className={styles["question-total-count"]}>
            {questions.length}
          </span>
        </div>
        <div className={styles["question-label"]}>
          {questions[currentQuestion]?.question}
        </div>

        <div className={styles["question-options"]}>
          {questions[currentQuestion]?.options.map((option, index) => (
            <div key={index} className={styles["option-wrapper"]}>
              <label className={styles["option-label"]}>{option.label}</label>
              <input
                type={questions[currentQuestion]?.multiple_answers ? "checkbox" : "radio"}
                id={option.answerId}
                name="option"
                value={option.label}
                checked={answers[currentQuestion]?.includes(option.label) || false}
                onChange={(e) => handleOptionChange(e, questions[currentQuestion]?.multiple_answers)}
              />
            </div>
          ))}
        </div>
        <div className={styles["button-container"]}>
          <button
            className={styles["submit-button"]}
            onClick={handleSubmit}
            disabled={
              !isOptionSelected &&
              !questions[currentQuestion]?.multiple_answers
            }
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default Question;
