import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import MetaTags from 'react-meta-tags';
import Moment from 'moment';
import { removeSpecialCharacters } from '../../utils/utils';
import { Buffer } from 'buffer';
import SignaturePad from 'react-signature-canvas'
import {
    Modal
} from "reactstrap"
import { connect } from "react-redux"
import { getWardsList, getTheatresList, getClinicList, getPatientList, getUprofile, getDoctorList, updateTheatrePatient, updateWardPatient, updatePatient } from "../../store/actions"

const recovery = props => {

    const [clinicValues, setClinic] = useState({})
    const [file, setFile] = useState({});
    const [success_msg, setsuccess_msg] = useState(false)
    const {
        patients
    } = props;

    let re_anaesthetist_sign = useRef(null);
    let re_theatre_sign = useRef(null);
    let re_recovery_sign = useRef(null);
    let re_prescriber_sign = useRef(null);
    let re_recovery_practioner_sign = useRef(null);
    let re_recovery_nurse_sign = useRef(null);

    useEffect(() => {
        const { getPatient } = props
        getPatient()
    }, [])

    useEffect(() => {
        let clinicPatient = JSON.parse(localStorage.getItem("p"))
        if (patients && patients.length > 0 && clinicPatient && clinicPatient._id) {
            const cPatient = patients.filter(
                usr => usr._id === clinicPatient._id
            )
            if (cPatient && cPatient[0]) {
                let cpatientData = cPatient[0]
                delete cpatientData["_id"]
                setClinic(clinicValues => ({
                    ...clinicValues,
                    ...cpatientData
                }));
            }

        }
    }, [patients])

    const updateInputValue = (e) => {
        let val = e.target.value;
        let name = e.target.name;
        let updatedValue = {};
        updatedValue = { [name]: val };
        setClinic(clinicValues => ({
            ...clinicValues,
            ...updatedValue
        }));
    }

    const updateClinicValues = (e, name, val) => {
        e.preventDefault()
        let updatedValue = {};
        updatedValue = { [name]: val };
        setClinic(clinicValues => ({
            ...clinicValues,
            ...updatedValue
        }));
    }

    const updateClinic = (e) => {
        const { patientupdate } = props
        let clinicPatient = JSON.parse(localStorage.getItem("p"))
        let id = clinicPatient._id
        patientupdate(id, clinicValues)
        let patient = {
            ...clinicPatient,
            ...clinicValues
        }
        localStorage.setItem("p", JSON.stringify(patient))
        setsuccess_msg(!success_msg)

    }

    const theatreBack = (e) => {
        e.preventDefault()
        props.history.push("/tpatient")
    }

    const handleChange = (event) => {
        updateClinicValues(event, event.target.name, event.target.checked)
    }

    const updateMultipleValues = (e, name, val, type) => {
        e.preventDefault()
        let tvalues = clinicValues
        e.target.parentElement.classList.add('active');
        if (tvalues[name]) {
            const exists = tvalues[name].includes(val);
            if (!exists) {
                tvalues[name].push(val)
            }

        } else {
            tvalues = {
                [name]: [val]
            }
        }
        // let updatedValue = {};
        // tvalues[type] = true
        setClinic(clinicValues => ({
            ...clinicValues,
            ...tvalues
        }));

    }



    const handleFile = async (e, name) => {

        const file = e.target.files[0]
        console.log("file ", file, name)
        let filename = e.target.files[0].name;
        // const base64 = await convertBase64(file)
        console.log("filename ", filename)
        let pdetails = JSON.parse(localStorage.getItem("p"))
        // var reader = new FileReader();
        //   reader.readAsDataURL(file);
        // reader.onload = async function (e) {
        // console.log("e ", e)
        let uploadFilePath = "ipr/" + pdetails._id + "/" + name + "_result" + "/" + removeSpecialCharacters(filename);
        let data = { source_bucket: "ipr-resources", sourcepath: uploadFilePath }
        console.log("data ", data)

        const response = await fetch(API_URL+'upload?appname=ipr', {
            method: "POST",
            mode: "cors",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
        const result = await response.json();

        console.log("result ", result)
        let url = result.result
        console.log("url ", url)
        const uploadImage = await fetch(url, {
            method: "PUT",
            mode: "cors",
            headers: {
                "Content-Type": "multipart/form-data",
                "Accept": "/",
                "Cache-Control": "no-cache",
                "Accept-Encoding": "gzip, deflate",
                "Connection": "keep-alive",
                "cache-control": "no-cache"
            },
            body: file
        })

        console.log("resultImage ", uploadImage)

        let imagePath = "https://ipr-resources.s3.amazonaws.com/"
        let imageUploadPath = imagePath + uploadFilePath;

        const { patientupdate } = props
        let updateObj = {
            [name + "_result"]: imageUploadPath,
            [name + "_update"]: new Date()
        }
        patientupdate(pdetails._id, updateObj)
        setFile(file => ({
            ...file,
            ...updateObj
        }));
        setClinic(clinicValues => ({
            ...clinicValues,
            ...updateObj
        }));

        let patient = {
            ...pdetails,
            ...updateObj
        }
        localStorage.setItem("p", JSON.stringify(patient))

    };

    const clear = (e, refvalue) => {
        e.preventDefault()
        refvalue.clear()
    }
    const trim = (e, type, refvalue) => {
        e.preventDefault()
        sigUpload(refvalue.getCanvas().toDataURL('image/png'), type)
    }

    const sigUpload = async (filedata, name) => {
        const file = filedata
        //console.log("file ", file, name)
        let filename = name+".png";
        // const base64 = await convertBase64(file)
        //console.log("filename ", filename)
        let pdetails = JSON.parse(localStorage.getItem("p"))

        const dataf = file.replace(/^data:image\/\w+;base64,/, "");
  
        const buf = Buffer.from(dataf, "base64");
        let uploadFilePath = "ipr/" + pdetails._id + "/" + name + "_result" + "/" + removeSpecialCharacters(filename);
        let data = { source_bucket: "ipr-resources", sourcepath: uploadFilePath }
       // console.log("data ", data)

        const response = await fetch(API_URL+'upload?appname=ipr', {
            method: "POST",
            mode: "cors",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
        const result = await response.json();
       // console.log("result ", result, buf.length)
        let url = result.result
       // console.log("url ", url)
        const base64Data = new Buffer.from(filedata.replace(/^data:image\/\w+;base64,/, ""), 'base64');
        // Getting the file type, ie: jpeg, png or gif
        const type = filedata.split(';')[0].split('/')[1];
        const uploadImage = await fetch(url, {
            method: "PUT",
            mode: "cors",
            headers: {
               'Content-Type':`image/${type}`,
                'Content-Encoding': 'base64',
                ACL: 'public-read',
            },
            body: base64Data
        })

        //console.log("resultImage ", uploadImage)

        let imagePath = "https://ipr-resources.s3.amazonaws.com/"
        let imageUploadPath = imagePath + uploadFilePath;

        const { patientupdate } = props
        let updateObj = {
            [name + "_result"]: imageUploadPath,
            [name + "_update"]: new Date()
        }
        patientupdate(pdetails._id, updateObj)
        setFile(file => ({
            ...file,
            ...updateObj
        }));
        setClinic(clinicValues => ({
            ...clinicValues,
            ...updateObj
        }));

        let patient = {
            ...pdetails,
            ...updateObj
        }
        localStorage.setItem("p", JSON.stringify(patient))

    };



    const time = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })
    const formatDate = Moment().format('MMM DD, YYYY')

    console.log("clinicValues ", clinicValues)

    return (
        <React.Fragment>

            <MetaTags>
                <title>RECOVERY RECORD</title>
            </MetaTags>
            <div className="theatre">

                <div className='popup_wrp'>
                    <div className="popup_full prescribe">
                        <div className='popup_hed'>
                            <h2>RECOVERY RECORD</h2>
                            <button className="border_btn" onClick={(e) => { updateClinic(e) }}><span className="material-icons">save_as</span>QUICK SAVE
                            </button>
                            <a className='popup_close' onClick={(e) => { theatreBack(e) }}><span className="material-icons">close</span></a>
                        </div>
                        <div className='popup_content'>

                            <div className='popup_scroll main pr height175 '>
                                <div className="team_brief_time">
                                    <h3>Theatre to Recovery handover </h3>

                                </div>
                                <div className="section simple_wound ctr">
                                    <div className="row">
                                        <div className="col-md-8">
                                            <div className="form_group">
                                                <div className="floating-label-group">
                                                    <input type="text" className="form-control" onChange={(e) => updateInputValue(e)} name="re_handover_name" value={clinicValues.re_handover_name} />
                                                    <label className="floating-label">Name</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form_group">
                                                <div className="floating-label-group">
                                                    <input type="text" className="form-control" onChange={(e) => updateInputValue(e)} name="re_handover_age" value={clinicValues.re_handover_age} />
                                                    <label className="floating-label">Age</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-8">
                                            <div className="form_group">
                                                <div className="floating-label-group">
                                                    <input type="text" className="form-control" onChange={(e) => updateInputValue(e)} name="re_handover_relevant" value={clinicValues.re_handover_relevant} />
                                                    <label className="floating-label">Relevant comorbidities</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form_group">
                                                <div className="floating-label-group">
                                                    <input type="text" className="form-control" onChange={(e) => updateInputValue(e)} name="re_handover_asa" value={clinicValues.re_handover_asa} />
                                                    <label className="floating-label">ASA</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="floating-label-group">
                                                    <input type="text" className="form-control" onChange={(e) => updateInputValue(e)} name="re_handover_allergy" value={clinicValues.re_handover_allergy} />
                                                    <label className="floating-label">Allergies</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="floating-label-group">
                                                    <input type="text" className="form-control" onChange={(e) => updateInputValue(e)} name="re_handover_status" value={clinicValues.re_handover_status} />
                                                    <label className="floating-label">Current status of the pt</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="section simple_wound ctr">
                                    <h2 className="popup_title"> ANAESTHETIC INFORMATION</h2>
                                    <div className="pd_border"></div>
                                    <div className="form_group">
                                        <div className="floating-label-group">
                                            <input type="text" className="form-control" onChange={(e) => updateInputValue(e)} name="re_anaesthetic_procedure" value={clinicValues.re_anaesthetic_procedure} />
                                            <label className="floating-label">Name of procedure</label>
                                        </div>
                                    </div>
                                    <div className="check_item mt-0">
                                        {clinicValues.re_spinal ?
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input type="radio"
                                                    value={clinicValues.re_spinal} checked /><span>{clinicValues.re_spinal} </span></label>
                                                </div> {clinicValues.re_spinal && <a onClick={(e) => updateClinicValues(e, "re_spinal", false)}><span className="material-symbols-outlined"> reply
                                                </span></a>}

                                            </div>
                                            :
                                            <div className="select_btns popup_select mt-0">
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="re_spinal" type="radio" value="GA" /><span>GA</span></label>
                                                </div>
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="re_spinal" type="radio"
                                                    value="SPINAL" /><span> SPINAL </span></label></div>
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="re_spinal" type="radio"
                                                    value="EPIDURAL" /><span> EPIDURAL </span></label></div>
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="re_spinal" type="radio"
                                                    value="REGIONAL BLOCK " /><span> REGIONAL BLOCK </span></label></div>
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="re_spinal" type="radio"
                                                    value="LOCAL" /><span> LOCAL </span></label></div>
                                            </div>}
                                    </div>
                                    <div className="form_group">
                                        <div className="floating-label-group">
                                            <input type="text" className="form-control" onChange={(e) => updateInputValue(e)} name="re_anaesthetic_intra" value={clinicValues.re_anaesthetic_intra} />
                                            <label className="floating-label">Intra op anaesthetic course and any
                                                complications</label>
                                        </div>
                                    </div>
                                    <div className="form_group">
                                        <div className="floating-label-group">
                                            <input type="text" className="form-control" onChange={(e) => updateInputValue(e)} name="re_anaesthetic_medication" value={clinicValues.re_anaesthetic_medication} />
                                            <label className="floating-label">Medications, including opioids, anti-emetics and
                                                antibiotics</label>
                                        </div>
                                    </div>
                                    <div className="form_group">
                                        <div className="floating-label-group">
                                            <input type="text" className="form-control" onChange={(e) => updateInputValue(e)} name="re_anaesthetic_ivfluids" value={clinicValues.re_anaesthetic_ivfluids} />
                                            <label className="floating-label">IV fluids & blood products, with estimated
                                                losses</label>
                                        </div>
                                    </div>
                                    <div className="yn_switch mb-20  mt-20">
                                        <div className="label-container">
                                            <p>THROAT PACK PRESENT? </p>
                                        </div>
                                        <label className="switch-container">
                                            <input type="checkbox" className="switch_check"
                                                name="re_throat" onChange={(e) => handleChange(e)} checked={clinicValues.re_throat} />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>YES</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>NO</span>
                                            </div>
                                        </label>

                                    </div>
                                    <div className="yn_switch mb-20  mt-20">
                                        <div className="label-container">
                                            <p>Anticipated post op problems especially bleeding, pain and airway problems
                                            </p>
                                        </div>
                                        <label className="switch-container">
                                            <input type="checkbox" className="switch_check"
                                                name="re_anticipated" onChange={(e) => handleChange(e)} checked={clinicValues.re_anticipated} />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>YES</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>NO</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="yn_switch mb-20  mt-20">
                                        <div className="label-container">
                                            <p>Monitoring & Range for physiological parameters BP, Urine output </p>
                                        </div>
                                        <label className="switch-container">
                                            <input type="checkbox" className="switch_check"
                                                name="re_monitoring" onChange={(e) => handleChange(e)} checked={clinicValues.re_monitoring} />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>YES</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>NO</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="row mb-20">
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="floating-label-group">
                                                    <input type="text" className="form-control" onChange={(e) => updateInputValue(e)} name="re_analgesia" value={clinicValues.re_analgesia} />
                                                    <label className="floating-label">Analgesia plan</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="floating-label-group">
                                                    <input type="text" className="form-control" onChange={(e) => updateInputValue(e)} name="re_planivfluids" value={clinicValues.re_planivfluids} />
                                                    <label className="floating-label">Plan for IV Fluids</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="note2" >Select all that apply for Multiselect forms {clinicValues.re_arterial && <a onClick={(e) => updateClinicValues(e, "re_arterial", false)}><span className="material-symbols-outlined"> reply </span></a>}</p>
                                    {clinicValues.re_arterial && clinicValues.re_arterial_list && clinicValues.re_arterial_list.length > 0 ? <div className="check_list w_white mb-0">

                                        {clinicValues.re_arterial_list.map((item, i) => (
                                            <div className="check_item active" key={i} onClick={(e) => updateClinicValues(e, "re_arterial", false)} ><label className="check">{item}</label></div>
                                        ))}

                                    </div> :
                                        <div className="check_list w_white mb-0">
                                            <span className="check_btn2" onClick={(e) => updateClinicValues(e, "re_arterial", true)}><span className="material-icons"> done </span></span>


                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "re_arterial_list", "Arterial Blood Gas sample", "re_arterial")}><label className="check">Arterial Blood Gas sample </label>
                                            </div>
                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "re_arterial_list", "Remove Arterial Line in recovery", "re_arterial")}><label className="check">Remove Arterial Line in recovery
                                            </label>
                                            </div>
                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "re_arterial_list", "Capillary Glucose sample", "re_arterial")}><label className="check">Capillary Glucose sample </label>
                                            </div>
                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "re_arterial_list", "Chest X-Ray", "re_arterial")}><label className="check">Chest X-Ray </label>
                                            </div>

                                        </div>}

                                    <div className="check_item mt-20"><label className="check">DISCHARGE TO/TRANSFER TO </label>
                                        {clinicValues.re_discharge ?
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input type="radio"
                                                    value={clinicValues.re_discharge} checked /><span>{clinicValues.re_discharge} </span></label>
                                                </div> {clinicValues.re_discharge && <a onClick={(e) => updateClinicValues(e, "re_discharge", false)}><span className="material-symbols-outlined"> reply
                                                </span></a>}

                                            </div>
                                            :

                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="re_discharge" type="radio" value="Ward" /><span>Ward </span></label>
                                                </div>
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="re_discharge" type="radio" value="ICU" /><span>ICU </span></label></div>
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="re_discharge" type="radio" value="Home" /><span>Home
                                                </span></label></div>

                                            </div>}
                                    </div>
                                    <div className="form_group">
                                        <div className="floating-label-group">
                                            <input type="text" className="form-control" onChange={(e) => updateInputValue(e)} name="re_anaesthetic_problem" value={clinicValues.re_anaesthetic_problem} />
                                            <label className="floating-label">Contact number of person in case of an anaesthetic
                                                problem</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="section simple_wound ctr">
                                    <h2 className="popup_title">SURGICAL INFORMATION</h2>
                                    <div className="pd_border"></div>
                                    <div className="form_group">
                                        <div className="floating-label-group">
                                            <input type="text" className="form-control" onChange={(e) => updateInputValue(e)} name="re_Intraoperative" value={clinicValues.re_Intraoperative} />
                                            <label className="floating-label">Intraoperative surgical course and any
                                                complications</label>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="floating-label-group">
                                                    <input type="text" className="form-control" onChange={(e) => updateInputValue(e)} name="re_Blood_loss" value={clinicValues.re_Blood_loss} />
                                                    <label className="floating-label">Blood loss</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="floating-label-group">
                                                    <input type="text" className="form-control" onChange={(e) => updateInputValue(e)} name="re_Antibiotic" value={clinicValues.re_Antibiotic} />
                                                    <label className="floating-label">Antibiotic plan</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="floating-label-group">
                                                    <input type="text" className="form-control" onChange={(e) => updateInputValue(e)} name="re_Medication" value={clinicValues.re_Medication} />
                                                    <label className="floating-label">Medication plan</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="floating-label-group">
                                                    <input type="text" className="form-control" onChange={(e) => updateInputValue(e)} name="re_Drugs" value={clinicValues.re_Drugs} />
                                                    <label className="floating-label">Drugs to be restarted</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="yn_switch mb-20  mt-20">
                                        <div className="label-container">
                                            <p>Plan for dressings, tubes, drains or packs</p>
                                        </div>
                                        <label className="switch-container">
                                            <input type="checkbox" className="switch_check"
                                                name="re_dressings" onChange={(e) => handleChange(e)} checked={clinicValues.re_dressings} />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>YES</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>NO</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="check_item mt-20">
                                        {clinicValues.re_flowtrons ?
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input type="radio"
                                                    value={clinicValues.re_flowtrons} checked /><span>{clinicValues.re_flowtrons} </span></label>
                                                </div> {clinicValues.re_flowtrons && <a onClick={(e) => updateClinicValues(e, "re_flowtrons", false)}><span className="material-symbols-outlined"> reply
                                                </span></a>}

                                            </div> :
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="re_flowtrons" type="radio"
                                                    value="Flowtrons in recovery" /><span>Flowtrons in recovery </span></label>
                                                </div>
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="re_flowtrons" type="radio"
                                                    value="Menisectomy splints etc" /><span>Menisectomy splints etc </span></label></div>
                                            </div>}
                                    </div>
                                    <div className="yn_switch mb-20  mt-20">
                                        <div className="label-container">
                                            <p>Blood tests / X rays to be done</p>
                                        </div>
                                        <label className="switch-container">
                                            <input type="checkbox" className="switch_check"
                                                name="re_bloodtests" onChange={(e) => handleChange(e)} checked={clinicValues.re_bloodtests} />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>YES</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>NO</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="form_group">
                                        <div className="floating-label-group">
                                            <input type="text" className="form-control" onChange={(e) => updateInputValue(e)} name="re_anyplan" value={clinicValues.re_anyplan} />
                                            <label className="floating-label">Any plans for information to be given to the
                                                patient about the procedure</label>
                                        </div>
                                    </div>
                                    <div className="form_group">
                                        <div className="floating-label-group">
                                            <input type="text" className="form-control" onChange={(e) => updateInputValue(e)} name="re_contactnumber" value={clinicValues.re_contactnumber} />
                                            <label className="floating-label">Contact number for any surgical problems</label>
                                        </div>
                                    </div>
                                    <p className="field_title mt-30 bold">Anaesthetist </p>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form_group">
                                                <div className="floating-label-group">
                                                    <input type="text" className="form-control" onChange={(e) => updateInputValue(e)} name="re_anaesthetist_name" value={clinicValues.re_anaesthetist_name} />
                                                    <label className="floating-label">Name</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            {file.re_anaesthetist_sign_result || clinicValues.re_anaesthetist_sign_result ? <img src={file.re_anaesthetist_sign_result || clinicValues.re_anaesthetist_sign_result} /> :
                                            <div className="signature_block">
                                                <SignaturePad canvasProps={{ className: "sigPad" }} ref={(ref) => { re_anaesthetist_sign = ref }} />
                                                <p>Signature </p>
                                                <button onClick={(e) => trim(e, "re_anaesthetist_sign", re_anaesthetist_sign)}>Trim</button>
                                                <button onClick={(e) => clear(e, re_anaesthetist_sign)}> Clear</button>
                                            </div>}
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form_group">
                                                <div className="floating-label-group">
                                                    <input type="date" className="form-control" onChange={(e) => updateInputValue(e)} name="re_anaesthetist_date" value={clinicValues.re_anaesthetist_date} />
                                                    <label className="floating-label">Date</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form_group">
                                                <div className="floating-label-group">
                                                    <input type="time" className="form-control" onChange={(e) => updateInputValue(e)} name="re_anaesthetist_time" value={clinicValues.re_anaesthetist_time} />
                                                    <label className="floating-label">Time</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <p className="field_title mt-30 bold">Theatre Practitioner</p>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form_group">
                                                <div className="floating-label-group">
                                                    <input type="text" className="form-control" onChange={(e) => updateInputValue(e)} name="re_theatre_name" value={clinicValues.re_theatre_name} />
                                                    <label className="floating-label">Name</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                           
                                                {file.re_theatre_sign_result || clinicValues.re_theatre_sign_result ? <img src={file.re_theatre_sign_result || clinicValues.re_theatre_sign_result} /> :
                                            <div className="signature_block">
                                                <SignaturePad canvasProps={{ className: "sigPad" }} ref={(ref) => { re_theatre_sign = ref }} />
                                                <p>Signature </p>
                                                <button onClick={(e) => trim(e, "re_theatre_sign", re_theatre_sign)}>Trim</button>
                                                <button onClick={(e) => clear(e, re_theatre_sign)}> Clear</button>
                                            </div>}

                                        </div>
                                        <div className="col-md-3">
                                            <div className="form_group">
                                                <div className="floating-label-group">
                                                    <input type="date" className="form-control" onChange={(e) => updateInputValue(e)} name="re_theatre_date" value={clinicValues.re_theatre_date} />
                                                    <label className="floating-label">Date</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form_group">
                                                <div className="floating-label-group">
                                                    <input type="time" className="form-control" onChange={(e) => updateInputValue(e)} name="re_theatre_time" value={clinicValues.re_theatre_time} />
                                                    <label className="floating-label">Time</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="field_title mt-30 bold">Recovery Practitioner  </p>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form_group">
                                                <div className="floating-label-group">
                                                    <input type="text" className="form-control" onChange={(e) => updateInputValue(e)} name="re_recovery_name" value={clinicValues.re_recovery_name} />
                                                    <label className="floating-label">Name</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">

                                        {file.re_recovery_sign_result || clinicValues.re_recovery_sign_result ? <img src={file.re_recovery_sign_result || clinicValues.re_recovery_sign_result} /> :
                                            <div className="signature_block">
                                                <SignaturePad canvasProps={{ className: "sigPad" }} ref={(ref) => { re_recovery_sign = ref }} />
                                                <p>Signature </p>
                                                <button onClick={(e) => trim(e, "re_recovery_sign", re_recovery_sign)}>Trim</button>
                                                <button onClick={(e) => clear(e, re_recovery_sign)}> Clear</button>
                                            </div>}


                                        </div>
                                        <div className="col-md-3">
                                            <div className="form_group">
                                                <div className="floating-label-group">
                                                    <input type="date" className="form-control" onChange={(e) => updateInputValue(e)} name="re_recovery_date" value={clinicValues.re_recovery_date} />
                                                    <label className="floating-label">Date</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form_group">
                                                <div className="floating-label-group">
                                                    <input type="time" className="form-control" onChange={(e) => updateInputValue(e)} name="re_recovery_time" value={clinicValues.re_recovery_time} />
                                                    <label className="floating-label">Time</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="section simple_wound ctr">
                                    <h2 className="popup_title">RECOVERY RECORD</h2>
                                    <div className="pd_border"></div>
                                    <p className="field_title bold mt-20"></p>
                                    <div className="team_brief_time">
                                        <h3>INITIAL OBSERVATIONS </h3>
                                        <div className="dsp-flex">
                                            <div className="icon_text"><span className="material-icons"> schedule </span> {time}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="check_item mt-20"><label className="check">ON ARRIVAL </label>
                                        {clinicValues.re_onarrival ?
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input type="radio"
                                                    value={clinicValues.re_onarrival} checked /><span>{clinicValues.re_onarrival} </span></label>
                                                </div> {clinicValues.re_onarrival && <a onClick={(e) => updateClinicValues(e, "re_onarrival", false)}><span className="material-symbols-outlined"> reply
                                                </span></a>}

                                            </div>
                                            :
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="re_onarrival" type="radio" value="Conscious" /><span> Conscious </span></label>
                                                </div>
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="re_onarrival" type="radio" value="Unconscious" /><span>Unconscious </span></label></div>
                                            </div>}
                                    </div>
                                    <div className="check_item mt-0"><label className="check">AIRWAY</label>
                                        {clinicValues.re_airway ?
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input type="radio"
                                                    value={clinicValues.re_airway} checked /><span>{clinicValues.re_airway} </span></label>
                                                </div> {clinicValues.re_airway && <a onClick={(e) => updateClinicValues(e, "re_airway", false)}><span className="material-symbols-outlined"> reply
                                                </span></a>}

                                            </div>
                                            :
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="re_airway" type="radio" value="No tube" /><span> No tube </span></label>
                                                </div>
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="re_airway" type="radio" value="Mask & airway" /><span>Mask & airway </span></label></div>
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="re_airway" type="radio" value="Oral Airway" /><span>Oral Airway </span></label></div>
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="re_airway" type="radio" value="Nasal Airway" /><span>Nasal Airway </span></label></div>
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="re_airway" type="radio" value="LMA" /><span>LMA </span></label></div>
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="re_airway" type="radio" value="ET tube" /><span>ET tube </span></label></div>
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="re_airway" type="radio" value="Nasopharyngeal" /><span>Nasopharyngeal </span></label></div>
                                            </div>}
                                    </div>
                                    <div className="check_item mt-0"><label className="check">ON ARRIVAL </label>
                                        {clinicValues.re_onarrival2 ?
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input type="radio"
                                                    value={clinicValues.re_onarrival2} checked /><span>{clinicValues.re_onarrival2} </span></label>
                                                </div> {clinicValues.re_onarrival2 && <a onClick={(e) => updateClinicValues(e, "re_onarrival2", false)}><span className="material-symbols-outlined"> reply
                                                </span></a>}

                                            </div>
                                            :
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="re_onarrival2" type="radio"
                                                    value="Room air" /><span> Room air </span></label>
                                                </div>
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="re_onarrival2" type="radio"
                                                    value="Nasal cannulaO2" /><span>Nasal cannulaO2 </span></label></div>
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="re_onarrival2" type="radio"
                                                    value="Facemask" /><span>Facemask </span></label></div>
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="re_onarrival2" type="radio"
                                                    value="O2" /><span>O2 </span></label></div>
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="re_onarrival2" type="radio"
                                                    value="LMA" /><span>LMA </span></label></div>
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="re_onarrival2" type="radio"
                                                    value="T pieceO2" /><span>T pieceO2 </span></label></div>
                                            </div>}
                                    </div>
                                    <div className="check_item mt-0"><label className="check">RESPIRATORY EFFORT</label>
                                        {clinicValues.re_respiratory ?
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input type="radio"
                                                    value={clinicValues.re_respiratory} checked /><span>{clinicValues.re_respiratory} </span></label>
                                                </div> {clinicValues.re_respiratory && <a onClick={(e) => updateClinicValues(e, "re_respiratory", false)}><span className="material-symbols-outlined"> reply
                                                </span></a>}

                                            </div>
                                            :
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="re_respiratory" type="radio"
                                                    value="Spontaneous" /><span>Spontaneous </span></label>
                                                </div>
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="re_respiratory" type="radio"
                                                    value="Ventilated" /><span>Ventilated </span></label></div>
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="re_respiratory" type="radio"
                                                    value="Insufflation" /><span>Insufflation </span></label></div>
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="re_respiratory" type="radio"
                                                    value="Other" /><span>Other </span></label></div>

                                            </div>}
                                    </div>
                                    <div className="form_group form_tag">
                                        <div className="floating-label-group"><input type="text" onChange={(e) => updateInputValue(e)} name="re_removedat" value={clinicValues.re_removedat} className="form-control" autocomplete="off" /><label className="floating-label">REMOVED AT </label>
                                        </div>
                                        hrs
                                    </div>
                                </div>
                                <div className="section simple_wound ctr">
                                    <div className="prescription_tb2">
                                        <table className="code_table table-bordered sin">
                                            <thead>
                                                <tr>
                                                    <th width="30%"> </th>
                                                    <th width="30%">RIGHT LOWER LIMB</th>
                                                    <th width="25%">LEFT LOWER LIMB </th>
                                                    <th width="15%">TIME</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td> <b>Sensation +/-</b></td>
                                                    <td><input type="text" className="field" onChange={(e) => updateInputValue(e)} name="re_sensation_right" value={clinicValues.re_sensation_right} /></td>
                                                    <td><input type="text" className="field" onChange={(e) => updateInputValue(e)} name="re_sensation_left" value={clinicValues.re_sensation_left} /></td>
                                                    <td><input type="time" className="field" onChange={(e) => updateInputValue(e)} name="re_sensation_time" value={clinicValues.re_sensation_time} /></td>
                                                </tr>
                                                <tr>
                                                    <td> <b>Movement +/- </b></td>
                                                    <td><input type="text" className="field" onChange={(e) => updateInputValue(e)} name="re_movement_right" value={clinicValues.re_movement_right} /></td>
                                                    <td><input type="text" className="field" onChange={(e) => updateInputValue(e)} name="re_movement_left" value={clinicValues.re_movement_left} /></td>
                                                    <td><input type="time" className="field" onChange={(e) => updateInputValue(e)} name="re_movement_time" value={clinicValues.re_movement_time} /></td>
                                                </tr>
                                                <tr>
                                                    <td> <b>Capillary refill +/-</b></td>
                                                    <td><input type="text" className="field" onChange={(e) => updateInputValue(e)} name="re_capillary_right" value={clinicValues.re_capillary_right} /></td>
                                                    <td><input type="text" className="field" onChange={(e) => updateInputValue(e)} name="re_capillary_left" value={clinicValues.re_capillary_left} /></td>
                                                    <td><input type="time" className="field" onChange={(e) => updateInputValue(e)} name="re_capillary_time" value={clinicValues.re_capillary_time} /></td>
                                                </tr>
                                                <tr>
                                                    <td> <b>Femoral Pulse +/-</b></td>
                                                    <td><input type="text" className="field" onChange={(e) => updateInputValue(e)} name="re_femoral_right" value={clinicValues.re_femoral_right} /></td>
                                                    <td><input type="text" className="field" onChange={(e) => updateInputValue(e)} name="re_femoral_left" value={clinicValues.re_femoral_left} /></td>
                                                    <td><input type="time" className="field" onChange={(e) => updateInputValue(e)} name="re_femoral_time" value={clinicValues.re_femoral_time} /></td>
                                                </tr>
                                                <tr>
                                                    <td> <b>Popliteal Pulse +/-</b></td>
                                                    <td><input type="text" className="field" onChange={(e) => updateInputValue(e)} name="re_popliteal_right" value={clinicValues.re_popliteal_right} /></td>
                                                    <td><input type="text" className="field" onChange={(e) => updateInputValue(e)} name="re_popliteal_left" value={clinicValues.re_popliteal_left} /></td>
                                                    <td><input type="time" className="field" onChange={(e) => updateInputValue(e)} name="re_popliteal_time" value={clinicValues.re_popliteal_time} /></td>
                                                </tr>
                                                <tr>
                                                    <td> <b>DP Pulse +/-</b></td>
                                                    <td><input type="text" className="field" onChange={(e) => updateInputValue(e)} name="re_dppulse_right" value={clinicValues.re_dppulse_right} /></td>
                                                    <td><input type="text" className="field" onChange={(e) => updateInputValue(e)} name="re_dppulse_left" value={clinicValues.re_dppulse_left} /></td>
                                                    <td><input type="time" className="field" onChange={(e) => updateInputValue(e)} name="re_dppulse_time" value={clinicValues.re_dppulse_time} /></td>
                                                </tr>
                                                <tr>
                                                    <td> <b>PT Pulse +/-</b></td>
                                                    <td><input type="text" className="field" onChange={(e) => updateInputValue(e)} name="re_ptpulse_right" value={clinicValues.re_ptpulse_right} /></td>
                                                    <td><input type="text" className="field" onChange={(e) => updateInputValue(e)} name="re_ptpulse_left" value={clinicValues.re_ptpulse_left} /></td>
                                                    <td><input type="time" className="field" onChange={(e) => updateInputValue(e)} name="re_ptpulse_time" value={clinicValues.re_ptpulse_time} /></td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="check_item mt-20"><label className="check">AIRWAY</label>
                                        {clinicValues.re_airway_air ?
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input type="radio"
                                                    value={clinicValues.re_airway_air} checked /><span>{clinicValues.re_airway_air} </span></label>
                                                </div> {clinicValues.re_airway_air && <a onClick={(e) => updateClinicValues(e, "re_airway_air", false)}><span className="material-symbols-outlined"> reply
                                                </span></a>}

                                            </div>
                                            :
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="re_airway_air" type="radio"
                                                    value="Room air" /><span>Room air </span></label></div>
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="re_airway_air" type="radio"
                                                    value="Nasal cannulaO2" /><span>Nasal cannulaO2
                                                    </span></label></div>
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="re_airway_air" type="radio"
                                                    value="Facemask O2" /><span>Facemask O2 </span></label>
                                                </div>
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="re_airway_air" type="radio"
                                                    value="LMA" /><span>LMA </span></label></div>
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="re_airway_air" type="radio"
                                                    value=" T pieceO2" /><span> T pieceO2 </span></label>
                                                </div>
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="re_airway_air" type="radio"
                                                    value="Intubated" /><span>Intubated </span></label></div>
                                            </div>}
                                    </div>
                                    <div className="form_group form_tag">
                                        <div className="floating-label-group w90"><input type="text" onChange={(e) => updateInputValue(e)} name="re_oxygen" value={clinicValues.re_oxygen}
                                            className="form-control" autocomplete="off" /><label
                                                className="floating-label">Oxygen administered as prescribed at </label>
                                        </div>
                                        L/min
                                    </div>
                                    <div className="check_item mt-20">
                                        <label className="check">To maintain </label>
                                        <div className="row mt-10">
                                            <div className="col-md-5">
                                                {clinicValues.re_so_maintain ?
                                                    <div className="select_btns popup_select">
                                                        <div className="select_btn"><label><input type="radio"
                                                            value={clinicValues.re_so_maintain} checked /><span>{clinicValues.re_so_maintain} </span></label>
                                                        </div> {clinicValues.re_so_maintain && <a onClick={(e) => updateClinicValues(e, "re_so_maintain", false)}><span className="material-symbols-outlined"> reply
                                                        </span></a>}

                                                    </div>
                                                    :
                                                    <div className="select_btns popup_select mt-0">
                                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="re_so_maintain" type="radio"
                                                            value="Sp02 > 94% - 98%" /><span>Sp02 &gt; 94% - 98% </span></label>
                                                        </div>
                                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="re_so_maintain" type="radio"
                                                            value="88% - 92%" /><span>88% - 92% </span></label>
                                                        </div>
                                                    </div>}
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form_group form_tag">
                                                    <div className="floating-label-group w90"><input type="text" onChange={(e) => updateInputValue(e)} name="re_so_maintain_other" value={clinicValues.re_so_maintain_other}
                                                        className="form-control" autocomplete="off" /><label
                                                            className="floating-label">other </label>
                                                    </div>
                                                    %
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="form_group mt-10 mb-20">
                                        <div className="floating-label-group">
                                            <input type="text" className="form-control" onChange={(e) => updateInputValue(e)} name="re_discontinued_at" value={clinicValues.re_discontinued_at} />
                                            <label className="floating-label">Discontinued at</label>
                                        </div>
                                    </div>
                                    <p className="field_title mt-30 bold">INTERVENTION</p>
                                    <p className="note2" >Select all that apply for Multiselect forms {clinicValues.re_suction && <a onClick={(e) => updateClinicValues(e, "re_suction", false)}><span className="material-symbols-outlined"> reply </span></a>}</p>
                                    {clinicValues.re_suction && clinicValues.re_suction_list && clinicValues.re_suction_list.length > 0 ? <div className="check_list w_white mb-0">

                                        {clinicValues.re_suction_list.map((item, i) => (
                                            <div className="check_item active" key={i} onClick={(e) => updateClinicValues(e, "re_suction", false)} ><label className="check">{item}</label></div>
                                        ))}

                                    </div> :
                                        <div className="check_list w_white mb-0">
                                            <span className="check_btn2" onClick={(e) => updateClinicValues(e, "re_suction", true)}><span className="material-icons"> done </span></span>

                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "re_suction_list", "Suction used", "re_suction")}><label className="check">Suction used </label>
                                            </div>
                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "re_suction_list", "Airway inserted", "re_suction")}><label className="check">Airway inserted
                                            </label>
                                            </div>
                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "re_suction_list", "Waters circuit", "re_suction")}><label className="check">Waters circuit </label>
                                            </div>
                                            <div className="check_item" onClick={(e) => updateMultipleValues(e, "re_suction_list", "Jaw support", "re_suction")}><label className="check">Jaw support</label>
                                            </div>
                                        </div>}
                                    <p className="field_title mt-30 bold">IV FLUIDS </p>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="floating-label-group">
                                                    <input type="text" className="form-control" onChange={(e) => updateInputValue(e)} name="re_iv_fluids" value={clinicValues.re_iv_fluids} />
                                                    <label className="floating-label">IV fluids / blood given as
                                                        prescribed</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="floating-label-group">
                                                    <input type="text" className="form-control" onChange={(e) => updateInputValue(e)} name="re_volume" value={clinicValues.re_volume} />
                                                    <label className="floating-label">Volume</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="floating-label-group">
                                                    <input type="text" className="form-control" onChange={(e) => updateInputValue(e)} name="re_rate" value={clinicValues.re_rate} />
                                                    <label className="floating-label">Rate</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                           
                                            {file.re_prescriber_sign_result || clinicValues.re_prescriber_sign_result ? <img src={file.re_prescriber_sign_result || clinicValues.re_prescriber_sign_result} /> :
                                            <div className="signature_block">
                                                <SignaturePad canvasProps={{ className: "sigPad" }} ref={(ref) => { re_prescriber_sign = ref }} />
                                                <p>Prescriber's Signature </p>
                                                <button onClick={(e) => trim(e, "re_prescriber_sign", re_prescriber_sign)}>Trim</button>
                                                <button onClick={(e) => clear(e, re_prescriber_sign)}> Clear</button>
                                            </div>}

                                        </div>
                                        <div className="col-md-12">
                                            <div className="form_group">
                                                <div className="floating-label-group">
                                                    <input type="text" className="form-control" onChange={(e) => updateInputValue(e)} name="re_po_intake" value={clinicValues.re_po_intake} />
                                                    <label className="floating-label">Begin initial PO intake</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="field_title mt-30 bold">ANALGESIA </p>
                                    <div className="form_group">
                                        <div className="floating-label-group">
                                            <input type="text" className="form-control" onChange={(e) => updateInputValue(e)} name="re_painscore_onarrival" value={clinicValues.re_painscore_onarrival} />
                                            <label className="floating-label">Pain score on arrival</label>
                                        </div>
                                    </div>
                                    <div className="check_item mt-20"><label className="check">Analgesia prescribed</label>
                                        {clinicValues.re_analgesia_prescribed ?
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input type="radio"
                                                    value={clinicValues.re_analgesia_prescribed} checked /><span>{clinicValues.re_analgesia_prescribed} </span></label>
                                                </div> {clinicValues.re_analgesia_prescribed && <a onClick={(e) => updateClinicValues(e, "re_analgesia_prescribed", false)}><span className="material-symbols-outlined"> reply
                                                </span></a>}

                                            </div>
                                            :
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="re_analgesia_prescribed" type="radio"
                                                    value="Epidural (PCEA / Plain / with Fentanyl)" /><span>Epidural (PCEA / Plain / with Fentanyl)
                                                    </span></label></div>
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="re_analgesia_prescribed" type="radio"
                                                    value="PCA (Morphine / Fentanyl / Ketamine)" /><span>PCA (Morphine / Fentanyl / Ketamine)
                                                    </span></label></div>
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="re_analgesia_prescribed" type="radio"
                                                    value="Opiates" /><span>Opiates</span></label>
                                                </div>
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="re_analgesia_prescribed" type="radio"
                                                    value="NSAID" /><span>NSAID </span></label></div>

                                            </div>}
                                    </div>
                                    <div className="form_group">
                                        <div className="floating-label-group">
                                            <input type="text" className="form-control" onChange={(e) => updateInputValue(e)} name="re_painscore_discharge" value={clinicValues.re_painscore_discharge} />
                                            <label className="floating-label">Pain score on discharge</label>
                                        </div>
                                    </div>
                                    <div className="form_group">
                                        <div className="floating-label-group">
                                            <input type="text" className="form-control" onChange={(e) => updateInputValue(e)} name="re_anti_emetic" value={clinicValues.re_anti_emetic} />
                                            <label className="floating-label">Anti-emetic given as prescribed and nausea
                                                controlled </label>
                                        </div>
                                    </div>
                                    <div className="form_group mt-20">
                                        <div className="floating-label-group">
                                            <input type="text" className="form-control" onChange={(e) => updateInputValue(e)} name="re_skin" value={clinicValues.re_skin} />
                                            <label className="floating-label">SKIN INSPECTION </label>
                                        </div>
                                    </div>
                                    <div className="form_group mt-20">
                                        <div className="floating-label-group">
                                            <input type="text" className="form-control" onChange={(e) => updateInputValue(e)} name="re_drains" value={clinicValues.re_drains} />
                                            <label className="floating-label">Drains Output</label>
                                        </div>
                                    </div>
                                    <div className="form_group">
                                        <div className="floating-label-group">
                                            <input type="text" className="form-control" onChange={(e) => updateInputValue(e)} name="re_catheter" value={clinicValues.re_catheter} />
                                            <label className="floating-label">Catheter Output </label>
                                        </div>
                                    </div>
                                    <div className="check_item mt-20"><label className="check">Wound Dressing</label>
                                        {clinicValues.re_wound_dressing ?
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input type="radio"
                                                    value={clinicValues.re_wound_dressing} checked /><span>{clinicValues.re_wound_dressing} </span></label>
                                                </div> {clinicValues.re_wound_dressing && <a onClick={(e) => updateClinicValues(e, "re_wound_dressing", false)}><span className="material-symbols-outlined"> reply
                                                </span></a>}

                                            </div>
                                            :
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="re_wound_dressing" type="radio"
                                                    value="Appears clean and dry so left undisturbed" /><span>Appears clean and dry so left undisturbed
                                                    </span></label></div>
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="re_wound_dressing" type="radio"
                                                    value="Oozing" /><span>Oozing
                                                    </span></label></div>
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="re_wound_dressing" type="radio"
                                                    value="Active bleeding" /><span>Active bleeding </span></label>
                                                </div>
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="re_wound_dressing" type="radio"
                                                    value="Oedema" /><span>Oedema </span></label></div>
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="re_wound_dressing" type="radio"
                                                    value="Bruising" /><span>Bruising </span></label></div>
                                            </div>}
                                    </div>
                                    <div className="check_item"><label className="check">All lines </label>
                                        {clinicValues.re_alllines ?
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input type="radio"
                                                    value={clinicValues.re_alllines} checked /><span>{clinicValues.re_alllines} </span></label>
                                                </div> {clinicValues.re_alllines && <a onClick={(e) => updateClinicValues(e, "re_alllines", false)}><span className="material-symbols-outlined"> reply
                                                </span></a>}

                                            </div>
                                            :
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="re_alllines" type="radio"
                                                    value="Checked" /><span>Checked </span></label></div>
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="re_alllines" type="radio"
                                                    value="Flushed" /><span>Flushed
                                                    </span></label></div>

                                            </div>}
                                    </div>
                                    <div className="yn_switch mb-20  mt-0">
                                        <div className="label-container">
                                            <p>Pressure relief equipment used-Transair mattress</p>
                                        </div>
                                        <label className="switch-container">
                                            <input type="checkbox" className="switch_check"
                                                name="re_pressure_relief" onChange={(e) => handleChange(e)} checked={clinicValues.re_pressure_relief} />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>YES</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>NO</span>
                                            </div>
                                        </label>
                                    </div>

                                    <div className="check_item"><label className="check">Anti thrombolytic stockings</label>
                                        {clinicValues.re_anti_thrombolytic ?
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input type="radio"
                                                    value={clinicValues.re_anti_thrombolytic} checked /><span>{clinicValues.re_anti_thrombolytic} </span></label>
                                                </div> {clinicValues.re_anti_thrombolytic && <a onClick={(e) => updateClinicValues(e, "re_anti_thrombolytic", false)}><span className="material-symbols-outlined"> reply
                                                </span></a>}

                                            </div>
                                            :
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="re_anti_thrombolytic" type="radio"
                                                    value="Flowtrons" /><span>Flowtrons </span></label></div>
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="re_anti_thrombolytic" type="radio"
                                                    value="TEDS" /><span>TEDS
                                                    </span></label></div>

                                            </div>}
                                    </div>
                                    <div className="yn_switch mb-20  mt-0">
                                        <div className="label-container">
                                            <p>Patients privacy & Dignity protected - Curtains & Blankets </p>
                                        </div>
                                        <label className="switch-container">
                                            <input type="checkbox" className="switch_check"
                                                name="re_patient_privacy" onChange={(e) => handleChange(e)} checked={clinicValues.re_patient_privacy} />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>YES</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>NO</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="yn_switch mb-20  mt-0">
                                        <div className="label-container">
                                            <p>Trolley safety - Brakes applied </p>
                                        </div>
                                        <label className="switch-container">
                                            <input type="checkbox" className="switch_check"
                                                name="re_trolley" onChange={(e) => handleChange(e)} checked={clinicValues.re_trolley} />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>YES</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>NO</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="yn_switch mb-20  mt-30">
                                        <div className="label-container">
                                            <p>DISCHARGE CRITERIA MET FROM RECOVERY </p>
                                        </div>
                                        <label className="switch-container">
                                            <input type="checkbox" className="switch_check"
                                                name="re_discharge_criteria" onChange={(e) => handleChange(e)} checked={clinicValues.re_discharge_criteria} />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>YES</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>NO</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="row mb-20 mt-30">
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="floating-label-group">
                                                    <input type="text" className="form-control" onChange={(e) => updateInputValue(e)} name="re_personal_belong" value={clinicValues.re_personal_belong} />
                                                    <label className="floating-label">Any personal belongings on
                                                        discharge</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="floating-label-group">
                                                    <input type="text" className="form-control" onChange={(e) => updateInputValue(e)} name="re_newscore" value={clinicValues.re_newscore} />
                                                    <label className="floating-label">NEWS2 SCORE on discharge</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="prescription_tb2">
                                        <table className="code_table table-bordered sin">
                                            <thead>
                                                <tr>
                                                    <th width="20%"> TIME</th>
                                                    <th width="80%">NURSING NOTES</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td> <input type="time" className="field" onChange={(e) => updateInputValue(e)} name="re_nursing_time1" value={clinicValues.re_nursing_time1} /></td>
                                                    <td><input type="text" className="field" onChange={(e) => updateInputValue(e)} name="re_nursing_notes1" value={clinicValues.re_nursing_notes1} /></td>
                                                </tr>
                                                <tr>
                                                    <td> <input type="time" className="field" onChange={(e) => updateInputValue(e)} name="re_nursing_time2" value={clinicValues.re_nursing_time2} /></td>
                                                    <td><input type="text" className="field" onChange={(e) => updateInputValue(e)} name="re_nursing_notes2" value={clinicValues.re_nursing_notes2} /></td>
                                                </tr>
                                                <tr>
                                                    <td> <input type="time" className="field" onChange={(e) => updateInputValue(e)} name="re_nursing_time3" value={clinicValues.re_nursing_time3} /></td>
                                                    <td><input type="text" className="field" onChange={(e) => updateInputValue(e)} name="re_nursing_notes3" value={clinicValues.re_nursing_notes3} /></td>
                                                </tr>
                                                <tr>
                                                    <td> <input type="time" className="field" onChange={(e) => updateInputValue(e)} name="re_nursing_time4" value={clinicValues.re_nursing_time4} /></td>
                                                    <td><input type="text" className="field" onChange={(e) => updateInputValue(e)} name="re_nursing_notes4" value={clinicValues.re_nursing_notes4} /></td>
                                                </tr>
                                                <tr>
                                                    <td> <input type="time" className="field" onChange={(e) => updateInputValue(e)} name="re_nursing_time5" value={clinicValues.re_nursing_time5} /></td>
                                                    <td><input type="text" className="field" onChange={(e) => updateInputValue(e)} name="re_nursing_notes5" value={clinicValues.re_nursing_notes5} /></td>
                                                </tr>
                                                <tr>
                                                    <td> <input type="time" className="field" onChange={(e) => updateInputValue(e)} name="re_nursing_time6" value={clinicValues.re_nursing_time6} /></td>
                                                    <td><input type="text" className="field" onChange={(e) => updateInputValue(e)} name="re_nursing_notes6" value={clinicValues.re_nursing_notes6} /></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="section simple_wound ctr">
                                    <h2 className="popup_title">Recovery to ward handover</h2>
                                    <div className="pd_border"></div>
                                    <p className="field_title bold">HAND-OVER TO WARD NURSE</p>
                                    <div className="row mb-20">
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="floating-label-group">
                                                    <input type="text" className="form-control" onChange={(e) => updateInputValue(e)} name="re_handover_procedure" value={clinicValues.re_handover_procedure} />
                                                    <label className="floating-label">Procedure patient has undergone</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <div className="floating-label-group">
                                                    <input type="text" className="form-control" onChange={(e) => updateInputValue(e)} name="re_handover_anaesthesia" value={clinicValues.re_handover_anaesthesia} />
                                                    <label className="floating-label">Anaesthesia</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form_group">
                                                <div className="floating-label-group">
                                                    <input type="text" className="form-control" onChange={(e) => updateInputValue(e)} name="re_handover_events" value={clinicValues.re_handover_events} />
                                                    <label className="floating-label">Events in Recovery</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="check_item mt-10">
                                                {clinicValues.re_medication_given ?
                                                    <div className="select_btns popup_select">
                                                        <div className="select_btn"><label><input type="radio"
                                                            value={clinicValues.re_medication_given} checked /><span>{clinicValues.re_medication_given} </span></label>
                                                        </div> {clinicValues.re_medication_given && <a onClick={(e) => updateClinicValues(e, "re_medication_given", false)}><span className="material-symbols-outlined"> reply
                                                        </span></a>}

                                                    </div>
                                                    :
                                                    <div className="select_btns popup_select mt-0">
                                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="re_medication_given" type="radio"
                                                            value="Medication given" /><span>Medication given </span></label>
                                                        </div>
                                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="re_medication_given" type="radio"
                                                            value="Omitted pre" /><span>Omitted pre
                                                            </span></label></div>

                                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="re_medication_given" type="radio"
                                                            value="Peri" /><span> Peri
                                                            </span></label></div>

                                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="re_medication_given" type="radio"
                                                            value="Postoperatively" /><span> Postoperatively
                                                            </span></label></div>

                                                    </div>}
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="check_item mt-0">
                                                {clinicValues.re_cannula ?
                                                    <div className="select_btns popup_select">
                                                        <div className="select_btn"><label><input type="radio"
                                                            value={clinicValues.re_cannula} checked /><span>{clinicValues.re_cannula} </span></label>
                                                        </div> {clinicValues.re_cannula && <a onClick={(e) => updateClinicValues(e, "re_cannula", false)}><span className="material-symbols-outlined"> reply
                                                        </span></a>}

                                                    </div>
                                                    :
                                                    <div className="select_btns popup_select mt-0">
                                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="re_cannula" type="radio"
                                                            value="Cannula status" /><span>Cannula status </span></label>
                                                        </div>
                                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="re_cannula" type="radio"
                                                            value="Location(s)" /><span> Location(s) </span></label></div>
                                                        <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="re_cannula" type="radio"
                                                            value="Flushed" /><span> Flushed </span></label></div>

                                                    </div>}
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form_group">
                                                <div className="floating-label-group">
                                                    <input type="text" className="form-control" onChange={(e) => updateInputValue(e)} name="re_handover_ivfluids" value={clinicValues.re_handover_ivfluids} />
                                                    <label className="floating-label">IV fluid status / oral</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="yn_switch mb-20">
                                        <div className="label-container">
                                            <p>Drains / Packs / Tubes / Skin closure </p>
                                        </div>
                                        <label className="switch-container">
                                            <input type="checkbox" className="switch_check"
                                                name="re_drains_packs" onChange={(e) => handleChange(e)} checked={clinicValues.re_drains_packs} />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>YES</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>NO</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="yn_switch mb-20">
                                        <div className="label-container">
                                            <p>Blood loss </p>
                                        </div>
                                        <label className="switch-container">
                                            <input type="checkbox" className="switch_check"
                                                name="re_bloodloss" onChange={(e) => handleChange(e)} checked={clinicValues.re_bloodloss} />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>YES</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>NO</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="yn_switch mb-20">
                                        <div className="label-container">
                                            <p>Urine output (catheterised and when)</p>
                                        </div>
                                        <label className="switch-container">
                                            <input type="checkbox" className="switch_check"
                                                name="re_urine_output" onChange={(e) => handleChange(e)} checked={clinicValues.re_urine_output} />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>YES</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>NO</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="yn_switch mb-20">
                                        <div className="label-container">
                                            <p>Special instructions / requests for the Ward</p>
                                        </div>
                                        <label className="switch-container">
                                            <input type="checkbox" className="switch_check"
                                                name="re_spcl_instruction" onChange={(e) => handleChange(e)} checked={clinicValues.re_spcl_instruction} />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>YES</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>NO</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="yn_switch mb-20">
                                        <div className="label-container">
                                            <p>NEWS score</p>
                                        </div>
                                        <label className="switch-container">
                                            <input type="checkbox" className="switch_check"
                                                name="re_news_score" onChange={(e) => handleChange(e)} checked={clinicValues.re_news_score} />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>YES</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>NO</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="yn_switch mb-20">
                                        <div className="label-container">
                                            <p>Pressure Ulcer risk</p>
                                        </div>
                                        <label className="switch-container">
                                            <input type="checkbox" className="switch_check"
                                                name="re_pressure" onChange={(e) => handleChange(e)} checked={clinicValues.re_pressure} />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>YES</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>NO</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="yn_switch mb-20">
                                        <div className="label-container">
                                            <p>Manual handling risks</p>
                                        </div>
                                        <label className="switch-container">
                                            <input type="checkbox" className="switch_check"
                                                name="re_manual_risks" onChange={(e) => handleChange(e)} checked={clinicValues.re_manual_risks} />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>YES</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>NO</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="yn_switch mb-20">
                                        <div className="label-container">
                                            <p>Property form signed in presence of nurse / patient</p>
                                        </div>
                                        <label className="switch-container">
                                            <input type="checkbox" className="switch_check"
                                                name="re_property_signed" onChange={(e) => handleChange(e)} checked={clinicValues.re_property_signed} />
                                            <div className="switch-bg"></div>
                                            <div className="round-box"></div>
                                            <div className="switch-left">
                                                <span>YES</span>
                                            </div>
                                            <div className="switch-right">
                                                <span>NO</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="prescription_tb2 mb-10 ">
                                        <table className="code_table table-bordered sin">
                                            <thead>
                                                <tr>
                                                    <th width="20%"> </th>
                                                    <th width="25%">Name</th>
                                                    <th width="25%">Signature </th>
                                                    <th width="15%">Date</th>
                                                    <th width="15%">Time</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td> <b>Recovery Practitioner</b></td>
                                                    <td><input type="text" className="field" onChange={(e) => updateInputValue(e)} name="re_recovery_practioner_name" value={clinicValues.re_recovery_practioner_name} /></td>
                                                    <td>
                                                       
                                                    {file.re_recovery_practioner_sign_result || clinicValues.re_recovery_practioner_sign_result ? <img src={file.re_recovery_practioner_sign_result || clinicValues.re_recovery_practioner_sign_result} /> :
                                                <div className="signature_block">
                                                    <SignaturePad canvasProps={{ className: "sigPad" }} ref={(ref) => { re_recovery_practioner_sign = ref }} />
                                                    <p>Signature </p>
                                                    <button onClick={(e) => trim(e, "re_recovery_practioner_sign", re_recovery_practioner_sign)}>Trim</button>
                                                    <button onClick={(e) => clear(e, re_recovery_practioner_sign)}> Clear</button>
                                                </div>}

                                                    </td>
                                                    <td><input type="date" className="field"  onChange={(e) => updateInputValue(e)} name="re_recovery_practioner_date" value={clinicValues.re_recovery_practioner_date} /></td>
                                                    <td><input type="time" className="field" onChange={(e) => updateInputValue(e)} name="re_recovery_practioner_time" value={clinicValues.re_recovery_practioner_time} /></td>
                                                </tr>
                                                <tr>
                                                    <td> <b>Ward Nurse/ HCA</b></td>
                                                    <td><input type="text" className="field" onChange={(e) => updateInputValue(e)} name="re_recovery_nurse_name" value={clinicValues.re_recovery_nurse_name} /></td>
                                                    <td>
                                                       

                                                            {file.re_recovery_nurse_sign_result || clinicValues.re_recovery_nurse_sign_result ? <img src={file.re_recovery_nurse_sign_result || clinicValues.re_recovery_nurse_sign_result} /> :
                                                <div className="signature_block">
                                                    <SignaturePad canvasProps={{ className: "sigPad" }} ref={(ref) => { re_recovery_nurse_sign = ref }} />
                                                    <p>Signature </p>
                                                    <button onClick={(e) => trim(e, "re_recovery_nurse_sign", re_recovery_nurse_sign)}>Trim</button>
                                                    <button onClick={(e) => clear(e, re_recovery_nurse_sign)}> Clear</button>
                                                </div>}


                                                    </td>
                                                    <td><input type="date" className="field" onChange={(e) => updateInputValue(e)} name="re_recovery_ward_date" value={clinicValues.re_recovery_ward_date} /></td>
                                                    <td><input type="time" className="field" onChange={(e) => updateInputValue(e)} name="re_recovery_ward_time" value={clinicValues.re_recovery_ward_time} /></td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <div className='popup_footer'>
                                <div className="prescribe_footer">
                                    <div className="dsp-flex">
                                        <a className="icon_btn" onClick={(e) => { updateClinic(e), theatreBack(e) }}> <span className="material-icons">save</span> SAVE AS COMPLETE </a>
                                        <button className="border_btn" onClick={(e) => { updateClinic(e) }} ><span className="material-icons">save_as</span>SEND TO PATIENT
                                            RECORD
                                        </button>
                                    </div>
                                    <button className="btn dsp_flex" onClick={(e) => { theatreBack(e) }}><span className="material-icons"><span
                                        className="material-icons">close</span></span>CANCEL
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <Modal
                    isOpen={success_msg}
                    toggle={() => {
                        setsuccess_msg(false)
                    }}
                    centered={true}
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0"></h5>
                        <button
                            type="button"
                            onClick={() => {
                                setsuccess_msg(false)
                            }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <h1>Updated Successfully</h1>
                    </div>
                </Modal>

            </div>

        </React.Fragment>
    )
}

recovery.propTypes = {
    t: PropTypes.any,
    getWards: PropTypes.func,
    getTheatres: PropTypes.func,
    wards: PropTypes.array,
    user: PropTypes.any,
    theatres: PropTypes.array,
    clinic: PropTypes.array,
    patients: PropTypes.array,
    doctors: PropTypes.array,
    profile: PropTypes.object
}

const mapStateToProps = ({ Layout, Login }) => ({
    wards: Layout.wards,
    user: Login.user,
    theatres: Layout.theatres,
    clinic: Layout.clinic,
    patients: Layout.patients,
    doctors: Layout.doctors,
    profile: Layout.profile
})

const mapDispatchToProps = dispatch => ({
    getWards: () => dispatch(getWardsList()),
    getTheatres: () => dispatch(getTheatresList()),
    getClinic: () => dispatch(getClinicList()),
    getPatient: () => dispatch(getPatientList()),
    getUserprofile: (id) => dispatch(getUprofile(id)),
    getDoctor: () => dispatch(getDoctorList()),
    theatreupdate: (id, data) => dispatch(updateTheatrePatient(id, data)),
    wardupdate: (id, data) => dispatch(updateWardPatient(id, data)),
    patientupdate: (id, data) => dispatch(updatePatient(id, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(recovery)
