import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux"
import Moment from 'moment';
import { getDailyRounds } from "../../store/actions"

const Todos = props => {
    const {
        dailyrounds
    } = props;

    useEffect(() => {
        const {  dailyRoundsList } = props
       
        const formatDate = Moment().subtract(1, 'day').format('MMM DD, YYYY')
        const formattodayDate = Moment().format('MMM DD, YYYY')
        let clinicPatient = JSON.parse(localStorage.getItem("p"))
        let id = clinicPatient._id
        let drdata = {
            "date": [formatDate, formattodayDate],
            "patientId": id
        }
        dailyRoundsList(drdata)
    }, [])

    const wardBack = (e) => {
        e.preventDefault()
        props.history.push("/wl")
        document.body.style.overflow = '';
    }
     

    return (
        <React.Fragment>

            <MetaTags>
                <title>Todos</title>
            </MetaTags>
            <div className='ward '>
                <div className="popup_wrp">
                    <div className="popup_full prescribe">
                        <div className="popup_hed">
                            <h2>Todos </h2>
                            <div className="dsp-flex"> <a className="popup_close" onClick={(e) => { wardBack(e) }}><span className="material-icons">close</span></a></div>

                        </div>
                        <div className="popup_content">
                            <div className="popup_scroll w-btns main ">
                                <div className="section ctr">
                                    
                                    <table className="table table-striped ">
                                    <tbody>
                                    {dailyrounds && dailyrounds.length > 0 && Object.keys(dailyrounds[0]).map((item, i) => (
                                         <tr className="row" key={i}>
                                            <td className="col-md-6">{item}</td>
                                            <td className="col-md-6">{dailyrounds[0][item]}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                    </table>
                                </div>
                               

                            </div>
                            <div className="popup_footer">
                                <div className="prescribe_footer">
                                    <div className="dsp-flex">
                                        <a className="icon_btn"> <span className="material-icons">save</span> SAVE</a>
                                        <button className="border_btn" onClick={(e) => { wardBack(e) }}><span className="material-icons">save_as</span>SAVE &amp;
                                            CLOSE
                                        </button>
                                    </div>

                                    <button onClick={(e) => { wardBack(e) }} className="btn dsp_flex"><span className="material-icons"><span className="material-icons">close</span></span>DISCARD </button>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>


        </React.Fragment>
    )
}

Todos.propTypes = {
    t: PropTypes.any,
    getWards: PropTypes.func,
    getTheatres: PropTypes.func,
    wards: PropTypes.array,
    user: PropTypes.any,
    theatres: PropTypes.array,
    clinic: PropTypes.array,
    patients: PropTypes.array,
    doctors: PropTypes.array,
    profile: PropTypes.object
}

const mapStateToProps = ({ Layout, Login }) => ({
    dailyrounds: Layout.dailyrounds
})

const mapDispatchToProps = dispatch => ({
    dailyRoundsList: (data) => dispatch(getDailyRounds(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Todos)
