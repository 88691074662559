import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import MetaTags from 'react-meta-tags';
import SignaturePad from 'react-signature-canvas'
import PatientBlock from "../Clinic/patientBlock";
import { removeSpecialCharacters } from '../../utils/utils';
import { Buffer } from 'buffer';
import { connect } from "react-redux"
import { getWardsList, getTheatresList, getClinicList, getPatientList, getUprofile, getDoctorList, updatePatient } from "../../store/actions"

const FluidBalance = props => {

    let provider_sign = useRef(null);
    let responsibility_sign = useRef(null);
    let photograph_sign = useRef(null);

    const [file, setFile] = useState({});
    const [legalInfo, setLegalInfo] = useState(false)
    const [clinicValues, setClinic] = useState({})

    const [selectedGroup, setselectedGroup] = useState(null)
    const [popupname, setpopup_name] = useState({})
    const [anydelay, setDelay] = useState(false)

    const {
        wards, user, theatres, clinic, patients, profile, doctors
    } = props;

    useEffect(() => {
        const { getWards, getTheatres, getClinic, getDoctor, getPatient, getUserprofile } = props
        // getWards()
        // getTheatres()
        // getClinic()
        getPatient()
        getDoctor()
        let userId = localStorage.getItem("authUser")
        getUserprofile(userId)
    }, [])


    useEffect(() => {
        let clinicPatient = JSON.parse(localStorage.getItem("p"))
        if (patients && patients.length > 0 && clinicPatient && clinicPatient._id) {
            const cPatient = patients.filter(
                usr => usr._id === clinicPatient._id
            )
            if (cPatient && cPatient[0]) {
                let cpatientData = cPatient[0]
                delete cpatientData["_id"]
                setClinic(clinicValues => ({
                    ...clinicValues,
                    ...cpatientData
                }));
            }

        }
    }, [patients])


    const updateInputValue = (e) => {
        let val = e.target.value;
        let name = e.target.name;
        let updatedValue = {};
        updatedValue = { [name]: val };
        setLegalInfo(legalInfo => ({
            ...legalInfo,
            ...updatedValue
        }));

    }

    const updateInputValues = (name, popupname, val) => {
        //  e.preventDefault()
        // let updatedValue = {
        //    [popupname]:{[name] : val}
        // };
        let updatedValue = clinicValues;
        //updatedValue = { [name]: val };
        // updatedValue[popupname][name] = val;
        if (updatedValue[popupname]) {
            updatedValue[popupname][name] = val;
        } else {
            updatedValue = {
                [popupname]: { [name]: val }

            };
        }
        console.log("updatedValue 2", updatedValue)
        setClinic(clinicValues => ({
            ...clinicValues,
            ...updatedValue
        }));
    }

    const updateClinic = (e) => {
        const { patientupdate } = props
        let clinicPatient = JSON.parse(localStorage.getItem("p"))
        let id = clinicPatient._id
        patientupdate(id, clinicValues)
        let patient = {
            ...clinicPatient,
            ...clinicValues
        }
        localStorage.setItem("p", JSON.stringify(patient))



    }

    const updateSwitchValue = (name, value) => {
        let updatedValue = {};
        updatedValue = { [name]: value };
        setLegalInfo(legalInfo => ({
            ...legalInfo,
            ...updatedValue
        }));

    }

    const handleChange = (event) => {
        updateSwitchValue(event.target.name, event.target.checked)
    }


    const clear = (e, refvalue) => {
        e.preventDefault()
        refvalue.clear()
    }
    const trim = (e, type, refvalue) => {
        e.preventDefault()
        sigUpload(refvalue.getCanvas().toDataURL('image/png'), type)
    }

    const sigUpload = async (filedata, name) => {
        const file = filedata
        //console.log("file ", file, name)
        let filename = name + ".png";
        // const base64 = await convertBase64(file)
        //console.log("filename ", filename)
        let pdetails = JSON.parse(localStorage.getItem("p"))

        const dataf = file.replace(/^data:image\/\w+;base64,/, "");

        const buf = Buffer.from(dataf, "base64");
        let uploadFilePath = "ipr/" + pdetails._id + "/" + name + "_result" + "/" + removeSpecialCharacters(filename);
        let data = { source_bucket: "ipr-resources", sourcepath: uploadFilePath }
        // console.log("data ", data)

        const response = await fetch(API_URL+'upload?appname=ipr', {
            method: "POST",
            mode: "cors",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
        const result = await response.json();
        // console.log("result ", result, buf.length)
        let url = result.result
        // console.log("url ", url)
        const base64Data = new Buffer.from(filedata.replace(/^data:image\/\w+;base64,/, ""), 'base64');
        // Getting the file type, ie: jpeg, png or gif
        const type = filedata.split(';')[0].split('/')[1];
        const uploadImage = await fetch(url, {
            method: "PUT",
            mode: "cors",
            headers: {
                'Content-Type': `image/${type}`,
                'Content-Encoding': 'base64',
                ACL: 'public-read',
            },
            body: base64Data
        })

        //console.log("resultImage ", uploadImage)

        let imagePath = "https://ipr-resources.s3.amazonaws.com/"
        let imageUploadPath = imagePath + uploadFilePath;


        let updateObj = {
            [name + "_result"]: imageUploadPath,
            [name + "_update"]: new Date()
        }

        setFile(file => ({
            ...file,
            ...updateObj
        }));
        setLegalInfo(legalInfo => ({
            ...legalInfo,
            ...updateObj
        }));

        // let patient = {
        //     ...pdetails,
        //     ...updateObj
        // }
        // localStorage.setItem("p", JSON.stringify(patient))

    };

    const handleFile = async (e, name) => {

        const file = e.target.files[0]
        // console.log("file ", file, name)
        let filename = e.target.files[0].name;
        // const base64 = await convertBase64(file)
        //console.log("filename ", filename)
        let pdetails = JSON.parse(localStorage.getItem("p"))
        // var reader = new FileReader();
        //   reader.readAsDataURL(file);
        // reader.onload = async function (e) {
        // console.log("e ", e)
        let uploadFilePath = "ipr/" + pdetails._id + "/" + name + "_result" + "/" + removeSpecialCharacters(filename);
        let data = { source_bucket: "ipr-resources", sourcepath: uploadFilePath }
        // console.log("data ", data)

        const response = await fetch(API_URL+'upload?appname=ipr', {
            method: "POST",
            mode: "cors",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
        const result = await response.json();

        // console.log("result ", result)
        let url = result.result
        // console.log("url ", url)
        const uploadImage = await fetch(url, {
            method: "PUT",
            mode: "cors",
            headers: {
                "Content-Type": "multipart/form-data",
                "Accept": "/",
                "Cache-Control": "no-cache",
                "Accept-Encoding": "gzip, deflate",
                "Connection": "keep-alive",
                "cache-control": "no-cache"
            },
            body: file
        })

        // console.log("resultImage ", uploadImage)

        let imagePath = "https://ipr-resources.s3.amazonaws.com/"
        let imageUploadPath = imagePath + uploadFilePath;

        let updateObj = {
            [name + "_result"]: imageUploadPath,
            [name + "_update"]: new Date()
        }
        // patientupdate(pdetails._id, updateObj)
        setFile(file => ({
            ...file,
            ...updateObj
        }));
        setLegalInfo(legalInfo => ({
            ...legalInfo,
            ...updateObj
        }));



    };

    const wardBack = (e) => {
        e.preventDefault()
        props.history.push("/wl")
        document.body.style.overflow = '';
    }

    let clinicPatient = JSON.parse(localStorage.getItem("p"))

    // console.log(" legalInfo ", legalInfo)

    return (
        <React.Fragment>

            <MetaTags>
                <title>FLUID BALANCE</title>
            </MetaTags>
            <div className='ward '>
                <div className="popup_wrp">
                    <div className="popup_full prescribe">
                        <div className="popup_hed">
                            <h2>FLUID BALANCE </h2>
                            <div className="dsp-flex"> <a className="popup_close" onClick={(e) => { wardBack(e) }}><span className="material-icons">close</span></a></div>

                        </div>
                        <div className="popup_content">
                            <div className="popup_scroll w-btns main ">
                                <PatientBlock patient={clinicPatient} />

                                <div className="section ctr fb_table mb-0">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form_group">
                                        <label className="label">Date Started</label>
                                        <input type="text" className="form-control" placeholder="Enter" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form_group">
                                        <label className="label">Indication</label>
                                        <input type="text" className="form-control" placeholder="Enter" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form_group">
                                        <label className="label">Frequency of output recordings</label>
                                        <input type="text" className="form-control" placeholder="Enter" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form_group">
                                        <label className="label">Any Fluid Restriction</label>
                                        <input type="text" className="form-control" placeholder="Enter" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form_group">
                                        <label className="label">Yesterday’s balance</label>
                                        <input type="text" className="form-control" placeholder="Enter" />
                                    </div>
                                </div>

                            </div>
                            <h2 className="popup_title mt-20 mb-10">FLUID BALANCE LAST 24 HRS </h2>
                            <div className="tbl_block">

                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link active" id="home-tab" data-bs-toggle="tab"
                                            data-bs-target="#home" type="button" role="tab" aria-controls="home"
                                            aria-selected="true">INPUT</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="profile-tab" data-bs-toggle="tab"
                                            data-bs-target="#profile" type="button" role="tab" aria-controls="profile"
                                            aria-selected="false">OUTPUT</button>
                                    </li>

                                </ul>
                                <div className="tab-content" id="myTabContent">
                                    <div className="tab-pane fade show active" id="home" role="tabpanel"
                                        aria-labelledby="home-tab">
                                        <div className="patients_table">
                                            <div className="sidebarNews">
                                                <ul>
                                                    <li className="hed">&nbsp;</li>
                                                    <li>7.00</li>
                                                    <li>8.00</li>
                                                    <li>9.00</li>
                                                    <li>10.00</li>
                                                    <li>CUMULATIVE</li>
                                                    <li>11.00</li>
                                                    <li>12.00</li>
                                                    <li>13.00</li>
                                                    <li>14.00</li>
                                                    <li>CUMULATIVE</li>
                                                    <li>15.00</li>
                                                    <li>16.00</li>
                                                    <li>17.00</li>
                                                    <li>18.00</li>
                                                    <li>CUMULATIVE</li>
                                                    <li>19.00</li>
                                                    <li>20.00</li>
                                                    <li>21.00</li>
                                                    <li>22.00</li>
                                                    <li>CUMULATIVE</li>
                                                    <li>23.00</li>
                                                    <li>0.00</li>
                                                    <li>1.00</li>
                                                    <li>2.00</li>
                                                    <li>CUMULATIVE</li>
                                                    <li>3.00</li>
                                                    <li>4.00</li>
                                                    <li>5.00</li>
                                                    <li>6.00</li>
                                                    <li>TOTAL</li>
                                                    <li>NET FLUID BALANCE</li>
                                                </ul>
                                            </div>
                                            <div className="news_result ">
                                                <div className="news_result_wrp ">
                                                    <ul className="news_item ">
                                                        <li className="hed bgwhite">Oral/NG/PEG/TPN
                                                            /Irrigation
                                                        </li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li className="emty"> </li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li className="emty"> </li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li className="emty"> </li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li className="emty"> </li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li className="emty"> </li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> </li>
                                                        <li> </li>
                                                    </ul>
                                                    <ul className="news_item ">
                                                        <li className="hed bgwhite">IV fluids</li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li className="emty"> </li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li className="emty"> </li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li className="emty"> </li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li className="emty"> </li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li className="emty"> </li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> </li>
                                                        <li> </li>
                                                    </ul>
                                                    <ul className="news_item ">
                                                        <li className="hed bgwhite">IV Drugs / Other Intake</li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li className="emty"> </li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li className="emty"> </li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li className="emty"> </li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li className="emty"> </li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li className="emty"> </li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> </li>
                                                        <li> </li>
                                                    </ul>
                                                    <ul className="news_item ">
                                                        <li className="hed bgwhite">TOTAL</li>
                                                        <li>8 / min</li>
                                                        <li>8 / min</li>
                                                        <li>8 / min</li>
                                                        <li>8 / min</li>
                                                        <li className="emty"> </li>
                                                        <li>8 / min</li>
                                                        <li>8 / min</li>
                                                        <li>8 / min</li>
                                                        <li>8 / min</li>
                                                        <li className="emty"> </li>
                                                        <li>8 / min</li>
                                                        <li>8 / min</li>
                                                        <li>8 / min</li>
                                                        <li>8 / min</li>
                                                        <li className="emty"> </li>
                                                        <li>8 / min</li>
                                                        <li>8 / min</li>
                                                        <li>8 / min</li>
                                                        <li>8 / min</li>
                                                        <li className="emty"> </li>
                                                        <li>8 / min</li>
                                                        <li>8 / min</li>
                                                        <li>8 / min</li>
                                                        <li>8 / min</li>
                                                        <li className="emty"> </li>
                                                        <li>8 / min</li>
                                                        <li>8 / min</li>
                                                        <li>8 / min</li>
                                                        <li>8 / min</li>
                                                        <li> </li>
                                                        <li> </li>
                                                    </ul>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="profile" role="tabpanel"
                                        aria-labelledby="profile-tab">
                                        <div className="patients_table">
                                            <div className="sidebarNews">
                                                <ul>
                                                    <li className="hed">&nbsp;</li>
                                                    <li>7.00</li>
                                                    <li>8.00</li>
                                                    <li>9.00</li>
                                                    <li>10.00</li>
                                                    <li>CUMULATIVE</li>
                                                    <li>11.00</li>
                                                    <li>12.00</li>
                                                    <li>13.00</li>
                                                    <li>14.00</li>
                                                    <li>CUMULATIVE</li>
                                                    <li>15.00</li>
                                                    <li>16.00</li>
                                                    <li>17.00</li>
                                                    <li>18.00</li>
                                                    <li>CUMULATIVE</li>
                                                    <li>19.00</li>
                                                    <li>20.00</li>
                                                    <li>21.00</li>
                                                    <li>22.00</li>
                                                    <li>CUMULATIVE</li>
                                                    <li>23.00</li>
                                                    <li>0.00</li>
                                                    <li>1.00</li>
                                                    <li>2.00</li>
                                                    <li>CUMULATIVE</li>
                                                    <li>3.00</li>
                                                    <li>4.00</li>
                                                    <li>5.00</li>
                                                    <li>6.00</li>
                                                    <li>TOTAL</li>
                                                    <li>NET FLUID BALANCE</li>
                                                </ul>
                                            </div>
                                            <div className="news_result ">
                                                <div className="news_result_wrp ">
                                                    <ul className="news_item ">
                                                        <li className="hed bgwhite">Urine / Catheter
                                                        </li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li className="emty"> </li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li className="emty"> </li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li className="emty"> </li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li className="emty"> </li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li className="emty"> </li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> </li>
                                                        <li> </li>
                                                    </ul>
                                                    <ul className="news_item ">
                                                        <li className="hed bgwhite">Vomit/NG</li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li className="emty"> </li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li className="emty"> </li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li className="emty"> </li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li className="emty"> </li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li className="emty"> </li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> </li>
                                                        <li> </li>
                                                    </ul>
                                                    <ul className="news_item ">
                                                        <li className="hed bgwhite">Faeces / stoma</li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li className="emty"> </li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li className="emty"> </li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li className="emty"> </li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li className="emty"> </li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li className="emty"> </li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> </li>
                                                        <li> </li>
                                                    </ul>
                                                    <ul className="news_item ">
                                                        <li className="hed bgwhite">Other
                                                            Eg: Drains
                                                        </li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li className="emty"> </li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li className="emty"> </li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li className="emty"> </li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li className="emty"> </li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li className="emty"> </li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        <li> </li>
                                                        <li> </li>
                                                    </ul>
                                                    <ul className="news_item ">
                                                        <li className="hed bgwhite">TOTAL</li>
                                                        <li>8 / min</li>
                                                        <li>8 / min</li>
                                                        <li>8 / min</li>
                                                        <li>8 / min</li>
                                                        <li className="emty"> </li>
                                                        <li>8 / min</li>
                                                        <li>8 / min</li>
                                                        <li>8 / min</li>
                                                        <li>8 / min</li>
                                                        <li className="emty"> </li>
                                                        <li>8 / min</li>
                                                        <li>8 / min</li>
                                                        <li>8 / min</li>
                                                        <li>8 / min</li>
                                                        <li className="emty"> </li>
                                                        <li>8 / min</li>
                                                        <li>8 / min</li>
                                                        <li>8 / min</li>
                                                        <li>8 / min</li>
                                                        <li className="emty"> </li>
                                                        <li>8 / min</li>
                                                        <li>8 / min</li>
                                                        <li>8 / min</li>
                                                        <li>8 / min</li>
                                                        <li className="emty"> </li>
                                                        <li>8 / min</li>
                                                        <li>8 / min</li>
                                                        <li>8 / min</li>
                                                        <li>8 / min</li>
                                                        <li> </li>
                                                        <li> </li>
                                                    </ul>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                            <h2 className="popup_title mt-20 mb-10">FLUID BALANCE EDIT  </h2>
                            <div className="tbl_block fbe">

                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link active" id="home-tab" data-bs-toggle="tab"
                                            data-bs-target="#home2" type="button" role="tab" aria-controls="home2"
                                            aria-selected="true">INPUT</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="profile-tab" data-bs-toggle="tab"
                                            data-bs-target="#profile2" type="button" role="tab" aria-controls="profile2"
                                            aria-selected="false">OUTPUT</button>
                                    </li>

                                </ul>
                                <div className="tab-content" id="myTabContent">
                                    <div className="tab-pane fade show active" id="home2" role="tabpanel"
                                        aria-labelledby="home-tab">
                                        <div className="patients_table">
                                            <div className="sidebarNews">
                                                <ul>
                                                    <li className="hed">TIME</li> 
                                                    <li>ENTER</li>
                                                   
                                                </ul>
                                            </div>
                                            <div className="news_result ">
                                                <div className="news_result_wrp ">
                                                    <ul className="news_item ">
                                                        <li className="hed bgwhite">Oral/NG/PEG/TPN 
                                                            /Irrigation
                                                            
                                                        </li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                       
                                                    </ul>
                                                    <ul className="news_item ">
                                                        <li className="hed bgwhite">IV fluids</li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        
                                                    </ul>
                                                    <ul className="news_item ">
                                                        <li className="hed bgwhite">IV Drugs / Other Intake</li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                       
                                                    </ul>
                                             
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="profile2" role="tabpanel"
                                        aria-labelledby="profile-tab">
                                        <div className="patients_table">
                                            <div className="sidebarNews">
                                                <ul>
                                                    <li className="hed">TIME</li>
                                                    <li>ENTER</li>
                                                    
                                                </ul>
                                            </div>
                                            <div className="news_result ">
                                                <div className="news_result_wrp ">
                                                    <ul className="news_item ">
                                                        <li className="hed bgwhite">Urine / Catheter
                                                        </li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                       
                                                    </ul>
                                                    <ul className="news_item ">
                                                        <li className="hed bgwhite">Faeces / stoma</li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>
                                                        
                                                    </ul>
                                                    <ul className="news_item ">
                                                        <li className="hed bgwhite">Other
                                                            Eg: Drains
                                                            TOTAL 
                                                            </li>
                                                        <li> <input type="text" className="form-control"
                                                                placeholder="Enter" /></li>                                                        
                                                    </ul>
                                                  
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>


                            </div>
                          
                        </div>
                        <div className="popup_footer">
                                <div className="prescribe_footer">
                                    <div className="dsp-flex">
                                        <a className="icon_btn"> <span className="material-icons">save</span> SAVE</a>
                                        <button className="border_btn" onClick={(e) => { wardBack(e) }}><span className="material-icons">save_as</span>SAVE &amp;
                                            CLOSE
                                        </button>
                                    </div>

                                    <button className="btn dsp_flex" onClick={(e) => { wardBack(e) }}><span className="material-icons"><span className="material-icons">close</span></span>DISCARD </button>
                                </div>
                            </div>
                    </div>

                </div>
            </div>


        </React.Fragment>
    )
}

FluidBalance.propTypes = {
    t: PropTypes.any,
    getWards: PropTypes.func,
    getTheatres: PropTypes.func,
    wards: PropTypes.array,
    user: PropTypes.any,
    theatres: PropTypes.array,
    clinic: PropTypes.array,
    patients: PropTypes.array,
    doctors: PropTypes.array,
    profile: PropTypes.object
}

const mapStateToProps = ({ Layout, Login }) => ({
    wards: Layout.wards,
    user: Login.user,
    theatres: Layout.theatres,
    clinic: Layout.clinic,
    patients: Layout.patients,
    doctors: Layout.doctors,
    profile: Layout.profile
})

const mapDispatchToProps = dispatch => ({
    getWards: () => dispatch(getWardsList()),
    getTheatres: () => dispatch(getTheatresList()),
    getClinic: () => dispatch(getClinicList()),
    getPatient: () => dispatch(getPatientList()),
    getUserprofile: (id) => dispatch(getUprofile(id)),
    getDoctor: () => dispatch(getDoctorList()),
    patientupdate: (id, data) => dispatch(updatePatient(id, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(FluidBalance)
