import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import MetaTags from 'react-meta-tags';
import Select from "react-select"
import widthsvg from '../../assets/images/width.svg';
import trending_down from '../../assets/images/trending_down.svg';
import trending_up from '../../assets/images/trending_up.svg';
import Menus from "./onCallMenus";
import PatientBlock from "../Clinic/patientBlock";
import { connect } from "react-redux"
import Moment from 'moment';
import { getWardsList, getTheatresList, getClinicList, getPatientList, getUprofile, getDoctorList, updatePatient, getNews, updateDailyRounds, getDailyRounds, teamListUpdate } from "../../store/actions"

const sericalSpinal = props => {
    document.body.style.overflow = '';
    document.body.style.padding = '';
    let clinicPatient = JSON.parse(localStorage.getItem("p"))
    const [discard_popup, setdiscard_popup] = useState(false)
    const [allied_value, setallied_value] = useState(null)
    const [clinicValues, setClinic] = useState({})
    const [wardValues, setWard] = useState({})
    const [patientValues, setPatient] = useState({})

    const {
        wards, user, theatres, clinic, patients, profile, doctors
    } = props;

    const optionGroup = [
        { label: "Normal", value: "Normal" },
        { label: "Flaccid", value: "Flaccid" },
        { label: "Spastic", value: "Spastic" },
        { label: "Clonus", value: "Clonus" }
    ]

    const upperLimb = [
        {
            name:"upper_tome",
            value:"Tone"
        },
        {
            name:"upper_power",
            value:"Power"
        },
        {
            name:"upper_shoulder",
            value:"Shoulder abduction C5"
        },
        {
            name:"upper_elbow",
            value:"Elbow flexion C5/C6"
        },
        {
            name:"upper_elbowC7",
            value:"Elbow extension C7/C8"
        },
        {
            name:"upper_wrist",
            value:"Wrist extension C6/C7"
        },
        {
            name:"upper_grip",
            value:"Grip C7/C8"
        },
        {
            name:"upper_grip",
            value:"Finger abduction T1"
        }];


    const upperLimbReflex = [
        {
            name:"upper_reflexes",
            value:"Reflexes"
        },
        {
            name:"upper_biceps",
            value:"Biceps C6"
        },
        {
            name:"upper_triceps",
            value:"Triceps C7"
        },
        {
            name:"upper_pronator",
            value:"Pronator"
        }];  
        

        const lowerLimb = [
            {
                name:"lower_tome",
                value:"Tone"
            },
            {
                name:"lower_power",
                value:"Power"
            },
            {
                name:"lower_hipflexion",
                value:"Hip flexion L2/L3"
            },
            {
                name:"lower_extension",
                value:"Knee extension L3/L4"
            },
            {
                name:"lower_kneeflexion",
                value:"Knee flexion L5/S1"
            },
            {
                name:"lower_dorsiflexion",
                value:"Foot dorsiflexion L4/L5"
            },
            {
                name:"lower_ehll5",
                value:"EHL L5"
            },
            {
                name:"lower_footplant",
                value:"Foot plantarflexion S1/S2"
            }];
        
        
        const lowerLimbReflex = [
            {
                name:"lower_reflexes",
                value:"Reflexes"
            },
            {
                name:"lower_kneel3-4",
                value:"Knee L3/4"
            },
            {
                name:"lower_ankle",
                value:"Ankle S1/S2"
            },
            {
                name:"lower_planator",
                value:"Plantar response"
            }]; 

    const sensationC = ["Occipital", "-", "-", "-", "-", "-", "-"];
    const coOrdinationSensation = ["-", "-", "-", "-", "-", "-", "-","-", "-", "-","-", "-"];

    
    const sensationCNerve = ["Median Nerve", "Ulnar Nerve", "Radial nerve"]

    const lowersensationCNerve = ["Sciatic Nerve", "Common Peroneal Nerve", "Coordination", "PR examination","Perianal sensation","Anal tone","Anal squeeze"]
    const lowerSensationC = ["Occipital", "-", "-", "-", "-"];
    const lowerSensationC2 = ["-", "-", "-", "-"];
    

    const todayDate = Moment().format('MMM DD, YYYY')
    const dayBeforeone = Moment().subtract(1,'d').format('MMM DD, YYYY')
    const dayBeforetwo = Moment().subtract(2,'d').format('MMM DD, YYYY')
    const dayBeforethree = Moment().subtract(3,'d').format('MMM DD, YYYY')

    const daysList = [
        {
            name: todayDate.toLowerCase().replace(/\W/g, ''),
            value: "Today"
        },
        {
            name: dayBeforeone.toLowerCase().replace(/\W/g, ''),
            value: dayBeforeone
        },
        {
            name: dayBeforetwo.toLowerCase().replace(/\W/g, ''),
            value: dayBeforetwo
        },
        {
            name:  dayBeforethree.toLowerCase().replace(/\W/g, ''),
            value: dayBeforethree
        }
    ]
    



    useEffect(() => {
        const { getWards, getTheatres, getClinic, getDoctor, getPatient, getUserprofile } = props

        getPatient()
        getDoctor()
        let userId = localStorage.getItem("authUser")
        getUserprofile(userId)
    }, [])

    useEffect(() => {
        let clinicPatient = JSON.parse(localStorage.getItem("p"))
        if (patients && patients.length > 0 && clinicPatient && clinicPatient._id) {
            const cPatient = patients.filter(
                usr => usr._id === clinicPatient._id
            )
            if (cPatient && cPatient[0]) {
                let cpatientData = cPatient[0]
                delete cpatientData["_id"]
                setClinic(clinicValues => ({
                    ...clinicValues,
                    ...cpatientData
                }));
            }

        }
    }, [patients])

    const updateInputValue = (e) => {
        let val = e.target.value;
        let name = e.target.name;
        let updatedValue = {};
        updatedValue = { [name]: val };
        setPatient(patientValues => ({
            ...patientValues,
            ...updatedValue
        }));

    }

    const updateMultipleValues = (e, name, val, type) => {
        e.preventDefault()
        let tvalues = clinicValues
        e.target.parentElement.classList.add('active');
        if (tvalues[name]) {
            const exists = tvalues[name].includes(val);
            if (!exists) {
                tvalues[name].push(val)
            }

        } else {
            tvalues = {
                [name]: [val]
            }
        }
        // let updatedValue = {};
        // tvalues[type] = true
        setPatient(patientValues => ({
            ...patientValues,
            ...tvalues
        }));

    }

    const handleSelect = (e, value, name) => {
         console.log("selectedGroup ", e, value)
        let updatedValue = { [name]: value };
        setPatient(patientValues => ({
            ...patientValues,
            ...updatedValue
        }));
    }

    const handleSelectGroup = (selectedGroup, name) => {
        // console.log("selectedGroup ", selectedGroup)
        let updatedValue = { [name]: selectedGroup };
        setPatient(patientValues => ({
            ...patientValues,
            ...updatedValue
        }));
    }

    const updateClinicValues = (e, name, val) => {
        e.preventDefault()
        let updatedValue = {};
        updatedValue = { [name]: val };
        setPatient(patientValues => ({
            ...patientValues,
            ...updatedValue
        }));
    }

    const switchChange = (event) => {
        updateClinicValues(event, event.target.name, event.target.checked)
    }

    const selectCheckbox = (e, labelname) => {
        // console.log(e.target.name, e.target.checked)
        let name = e.target.name;
        if (e.target.checked) {
            let val = e.target.value;
            let updatedValue = {};
            if (patientValues[labelname]) {
                updatedValue[labelname] = patientValues[labelname]
                updatedValue[labelname][name] = val;
            } else {
                updatedValue[labelname] = { [name]: val };
            }

            setPatient(patientValues => ({
                ...patientValues,
                ...updatedValue
            }));
        } else {
            delete patientValues[labelname][name]
            setPatient(patientValues)
        }
    }

    const updateCheckbox = (e) => {
        // console.log(e.target.name, e.target.checked)
        if (e.target.checked) {
            let val = e.target.value;
            let name = e.target.name;
            let updatedValue = {};
            updatedValue = { [name]: val };
            setPatient(patientValues => ({
                ...patientValues,
                ...updatedValue
            }));
        } else {
            let name = e.target.name;
            delete patientValues[name]
            setPatient(patientValues)
        }
    }
    const submitUpdate = (e) => {
        const { teamupdatelist } = props
        let clinicPatient = JSON.parse(localStorage.getItem("p"))
        let id = clinicPatient.patientId
        let bodyObj = {
            obj: patientValues,
            query: { "patientId": id },
            method: "Update"
        }
        teamupdatelist(bodyObj, "serical")

    }

    console.log("dayslList", daysList)

    return (
        <React.Fragment>

            <MetaTags>
                <title>SERICAL SPINAL NEUROLOGICAL EXAM</title>
            </MetaTags>
            <div className='ward '>
                <div className="main n_office oncall">
                    <div className="wrapper">
                        <Menus menuName={"SERICAL SPINAL NEUROLOGICAL EXAM"} />

                        <PatientBlock patient={clinicPatient} />
                        <p className="sub_title mt-10 mb-10">Upper Limb</p>
                        <div className="ssn_block">
                            <table className="table table-bordered ssn_table">
                                <thead>
                                    <tr>
                                        <th className="headcol th1m"></th>
                           
                                        {daysList && daysList.length > 0 && daysList.map((item, i) => (
                                        <th colspan="2" className="th1" key={i}>{item.value}</th>
                                        ))}

                                       
                                    </tr>
                                    <tr>
                                        <th className="headcol th2m"></th>
                                        <th className="th2">Right</th>
                                        <th className="th2">Left</th>
                                        <th className="th2">Right</th>
                                        <th className="th2">Left</th>
                                        <th className="th2">Right</th>
                                        <th className="th2">Left</th>
                                        <th className="th2">Right</th>
                                        <th className="th2">Left</th>
                                    </tr>
                                </thead>
                                <tbody>


                                    {upperLimb && upperLimb.length > 0 && upperLimb.map((item, i) => (
                                        

                                        <tr key={i}>
                                        <td className="headcol">
                                            <b>{item.value}</b>
                                        </td>
                                        <td>
                                            <div className="dropdown">
                                                <button className="td_btn dropdown-toggle" type="button" id="dropdownMenu2"
                                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                     {patientValues[item.name.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_right_"+i] || "Normal"}
                                                </button>
                                          

                                                <div className="dropdown-menu"  aria-labelledby="dropdownMenu2">
                                                    
                                                    <button onClick={(e) => { handleSelect(e,"Normal", item.name.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_right_"+i) }}  className="dropdown-item" type="button">Normal</button>
                                                    <button  onClick={(e) => { handleSelect(e,"Flaccid", item.name.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_right_"+i) }}  className="dropdown-item" type="button">Flaccid</button>
                                                    <button onClick={(e) => { handleSelect(e,"Spastic", item.name.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_right_"+i) }}   className="dropdown-item" type="button">Spastic</button>
                                                    <button onClick={(e) => { handleSelect(e,"Clonus", item.name.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_right_"+i) }} className="dropdown-item" type="button">Clonus</button>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dropdown">
                                                <button className="td_btn dropdown-toggle" type="button" id="dropdownMenu2"
                                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                     {patientValues[item.name.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_left_"+i] || "Normal"}
                                                </button>
                                          

                                                <div className="dropdown-menu"  aria-labelledby="dropdownMenu2">
                                                    
                                                    <button onClick={(e) => { handleSelect(e,"Normal", item.name.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_left_"+i) }}  className="dropdown-item" type="button">Normal</button>
                                                    <button  onClick={(e) => { handleSelect(e,"Flaccid", item.name.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_left_"+i) }}  className="dropdown-item" type="button">Flaccid</button>
                                                    <button onClick={(e) => { handleSelect(e,"Spastic", item.name.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_left_"+i) }}   className="dropdown-item" type="button">Spastic</button>
                                                    <button onClick={(e) => { handleSelect(e,"Clonus", item.name.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_left_"+i) }} className="dropdown-item" type="button">Clonus</button>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dropdown">
                                            <button className="td_btn dropdown-toggle" type="button" id="dropdownMenu2"
                                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                     {patientValues[item.name.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_right_"+i] || "Normal"}
                                                </button>
                                          

                                                <div className="dropdown-menu"  aria-labelledby="dropdownMenu2">
                                                    
                                                    <button onClick={(e) => { handleSelect(e,"Normal", item.name.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_right_"+i) }}  className="dropdown-item" type="button">Normal</button>
                                                    <button  onClick={(e) => { handleSelect(e,"Flaccid", item.name.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_right_"+i) }}  className="dropdown-item" type="button">Flaccid</button>
                                                    <button onClick={(e) => { handleSelect(e,"Spastic", item.name.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_right_"+i) }}   className="dropdown-item" type="button">Spastic</button>
                                                    <button onClick={(e) => { handleSelect(e,"Clonus", item.name.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_right_"+i) }} className="dropdown-item" type="button">Clonus</button>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dropdown">
                                            <button className="td_btn dropdown-toggle" type="button" id="dropdownMenu2"
                                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                     {patientValues[item.name.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_left_"+i] || "Normal"}
                                                </button>
                                          

                                                <div className="dropdown-menu"  aria-labelledby="dropdownMenu2">
                                                    
                                                    <button onClick={(e) => { handleSelect(e,"Normal", item.name.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_left_"+i) }}  className="dropdown-item" type="button">Normal</button>
                                                    <button  onClick={(e) => { handleSelect(e,"Flaccid", item.name.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_left_"+i) }}  className="dropdown-item" type="button">Flaccid</button>
                                                    <button onClick={(e) => { handleSelect(e,"Spastic", item.name.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_left_"+i) }}   className="dropdown-item" type="button">Spastic</button>
                                                    <button onClick={(e) => { handleSelect(e,"Clonus", item.name.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_left_"+i) }} className="dropdown-item" type="button">Clonus</button>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dropdown">
                                            <button className="td_btn dropdown-toggle" type="button" id="dropdownMenu2"
                                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                     {patientValues[item.name.toLowerCase().replace(/\W/g, '')+dayBeforetwo.toLowerCase().replace(/\W/g, '')+"_right_"+i] || "Normal"}
                                                </button>
                                          

                                                <div className="dropdown-menu"  aria-labelledby="dropdownMenu2">
                                                    
                                                    <button onClick={(e) => { handleSelect(e,"Normal", item.name.toLowerCase().replace(/\W/g, '')+dayBeforetwo.toLowerCase().replace(/\W/g, '')+"_right_"+i) }}  className="dropdown-item" type="button">Normal</button>
                                                    <button  onClick={(e) => { handleSelect(e,"Flaccid", item.name.toLowerCase().replace(/\W/g, '')+dayBeforetwo.toLowerCase().replace(/\W/g, '')+"_right_"+i) }}  className="dropdown-item" type="button">Flaccid</button>
                                                    <button onClick={(e) => { handleSelect(e,"Spastic", item.name.toLowerCase().replace(/\W/g, '')+dayBeforetwo.toLowerCase().replace(/\W/g, '')+"_right_"+i) }}   className="dropdown-item" type="button">Spastic</button>
                                                    <button onClick={(e) => { handleSelect(e,"Clonus", item.name.toLowerCase().replace(/\W/g, '')+dayBeforetwo.toLowerCase().replace(/\W/g, '')+"_right_"+i) }} className="dropdown-item" type="button">Clonus</button>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dropdown">
                                            <button className="td_btn dropdown-toggle" type="button" id="dropdownMenu2"
                                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                     {patientValues[item.name.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_left_"+i] || "Normal"}
                                                </button>
                                          

                                                <div className="dropdown-menu"  aria-labelledby="dropdownMenu2">
                                                    
                                                    <button onClick={(e) => { handleSelect(e,"Normal", item.name.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_left_"+i) }}  className="dropdown-item" type="button">Normal</button>
                                                    <button  onClick={(e) => { handleSelect(e,"Flaccid", item.name.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_left_"+i) }}  className="dropdown-item" type="button">Flaccid</button>
                                                    <button onClick={(e) => { handleSelect(e,"Spastic", item.name.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_left_"+i) }}   className="dropdown-item" type="button">Spastic</button>
                                                    <button onClick={(e) => { handleSelect(e,"Clonus", item.name.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_left_"+i) }} className="dropdown-item" type="button">Clonus</button>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dropdown">
                                            <button className="td_btn dropdown-toggle" type="button" id="dropdownMenu2"
                                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                     {patientValues[item.name.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_right_"+i] || "Normal"}
                                                </button>
                                          

                                                <div className="dropdown-menu"  aria-labelledby="dropdownMenu2">
                                                    
                                                    <button onClick={(e) => { handleSelect(e,"Normal", item.name.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_right_"+i) }}  className="dropdown-item" type="button">Normal</button>
                                                    <button  onClick={(e) => { handleSelect(e,"Flaccid", item.name.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_right_"+i) }}  className="dropdown-item" type="button">Flaccid</button>
                                                    <button onClick={(e) => { handleSelect(e,"Spastic", item.name.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_right_"+i) }}   className="dropdown-item" type="button">Spastic</button>
                                                    <button onClick={(e) => { handleSelect(e,"Clonus", item.name.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_right_"+i) }} className="dropdown-item" type="button">Clonus</button>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dropdown">
                                            <button className="td_btn dropdown-toggle" type="button" id="dropdownMenu2"
                                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                     {patientValues[item.name.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_left_"+i] || "Normal"}
                                                </button>
                                          

                                                <div className="dropdown-menu"  aria-labelledby="dropdownMenu2">
                                                    
                                                    <button onClick={(e) => { handleSelect(e,"Normal", item.name.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_left_"+i) }}  className="dropdown-item" type="button">Normal</button>
                                                    <button  onClick={(e) => { handleSelect(e,"Flaccid", item.name.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_left_"+i) }}  className="dropdown-item" type="button">Flaccid</button>
                                                    <button onClick={(e) => { handleSelect(e,"Spastic", item.name.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_left_"+i) }}   className="dropdown-item" type="button">Spastic</button>
                                                    <button onClick={(e) => { handleSelect(e,"Clonus", item.name.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_left_"+i) }} className="dropdown-item" type="button">Clonus</button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    ))}




                                    {upperLimbReflex && upperLimbReflex.length > 0 && upperLimbReflex.map((item, i) => (
                                        

                                        <tr key={i} className="bg3">
                                            <td className="headcol">
                                                <b>{item.value}</b>
                                            </td>
                                            <td>
                                                <div className="dropdown">
                                                    <button className="td_btn dropdown-toggle" type="button" id="dropdownMenu2"
                                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                         {patientValues[item.name.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_right_"+i] || "Normal"}
                                                    </button>
                                              

                                                    <div className="dropdown-menu"  aria-labelledby="dropdownMenu2">
                                                        
                                                        <button onClick={(e) => { handleSelect(e,"Normal", item.name.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_right_"+i) }}  className="dropdown-item" type="button">Normal</button>
                                                        <button  onClick={(e) => { handleSelect(e,"Absent", item.name.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_right_"+i) }}  className="dropdown-item" type="button">Absent</button>
                                                        <button onClick={(e) => { handleSelect(e,"Reduced", item.name.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_right_"+i) }}   className="dropdown-item" type="button">Reduced</button>
                                                        <button onClick={(e) => { handleSelect(e,"Brisk", item.name.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_right_"+i) }} className="dropdown-item" type="button">Brisk</button>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="dropdown">
                                                    <button className="td_btn dropdown-toggle" type="button" id="dropdownMenu2"
                                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                         {patientValues[item.name.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_left_"+i] || "Normal"}
                                                    </button>
                                              

                                                    <div className="dropdown-menu"  aria-labelledby="dropdownMenu2">
                                                        
                                                        <button onClick={(e) => { handleSelect(e,"Normal", item.name.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_left_"+i) }}  className="dropdown-item" type="button">Normal</button>
                                                        <button  onClick={(e) => { handleSelect(e,"Absent", item.name.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_left_"+i) }}  className="dropdown-item" type="button">Absent</button>
                                                        <button onClick={(e) => { handleSelect(e,"Reduced", item.name.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_left_"+i) }}   className="dropdown-item" type="button">Reduced</button>
                                                        <button onClick={(e) => { handleSelect(e,"Brisk", item.name.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_left_"+i) }} className="dropdown-item" type="button">Brisk</button>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="dropdown">
                                                <button className="td_btn dropdown-toggle" type="button" id="dropdownMenu2"
                                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                         {patientValues[item.name.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_right_"+i] || "Normal"}
                                                    </button>
                                              

                                                    <div className="dropdown-menu"  aria-labelledby="dropdownMenu2">
                                                        
                                                        <button onClick={(e) => { handleSelect(e,"Normal", item.name.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_right_"+i) }}  className="dropdown-item" type="button">Normal</button>
                                                        <button  onClick={(e) => { handleSelect(e,"Absent", item.name.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_right_"+i) }}  className="dropdown-item" type="button">Absent</button>
                                                        <button onClick={(e) => { handleSelect(e,"Reduced", item.name.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_right_"+i) }}   className="dropdown-item" type="button">Reduced</button>
                                                        <button onClick={(e) => { handleSelect(e,"Brisk", item.name.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_right_"+i) }} className="dropdown-item" type="button">Brisk</button>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="dropdown">
                                                <button className="td_btn dropdown-toggle" type="button" id="dropdownMenu2"
                                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                         {patientValues[item.name.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_left_"+i] || "Normal"}
                                                    </button>
                                              

                                                    <div className="dropdown-menu"  aria-labelledby="dropdownMenu2">
                                                        
                                                        <button onClick={(e) => { handleSelect(e,"Normal", item.name.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_left_"+i) }}  className="dropdown-item" type="button">Normal</button>
                                                        <button  onClick={(e) => { handleSelect(e,"Absent", item.name.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_left_"+i) }}  className="dropdown-item" type="button">Absent</button>
                                                        <button onClick={(e) => { handleSelect(e,"Reduced", item.name.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_left_"+i) }}   className="dropdown-item" type="button">Reduced</button>
                                                        <button onClick={(e) => { handleSelect(e,"Brisk", item.name.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_left_"+i) }} className="dropdown-item" type="button">Brisk</button>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="dropdown">
                                                <button className="td_btn dropdown-toggle" type="button" id="dropdownMenu2"
                                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                         {patientValues[item.name.toLowerCase().replace(/\W/g, '')+dayBeforetwo.toLowerCase().replace(/\W/g, '')+"_right_"+i] || "Normal"}
                                                    </button>
                                              

                                                    <div className="dropdown-menu"  aria-labelledby="dropdownMenu2">
                                                        
                                                        <button onClick={(e) => { handleSelect(e,"Normal", item.name.toLowerCase().replace(/\W/g, '')+dayBeforetwo.toLowerCase().replace(/\W/g, '')+"_right_"+i) }}  className="dropdown-item" type="button">Normal</button>
                                                        <button  onClick={(e) => { handleSelect(e,"Absent", item.name.toLowerCase().replace(/\W/g, '')+dayBeforetwo.toLowerCase().replace(/\W/g, '')+"_right_"+i) }}  className="dropdown-item" type="button">Absent</button>
                                                        <button onClick={(e) => { handleSelect(e,"Reduced", item.name.toLowerCase().replace(/\W/g, '')+dayBeforetwo.toLowerCase().replace(/\W/g, '')+"_right_"+i) }}   className="dropdown-item" type="button">Reduced</button>
                                                        <button onClick={(e) => { handleSelect(e,"Brisk", item.name.toLowerCase().replace(/\W/g, '')+dayBeforetwo.toLowerCase().replace(/\W/g, '')+"_right_"+i) }} className="dropdown-item" type="button">Brisk</button>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="dropdown">
                                                <button className="td_btn dropdown-toggle" type="button" id="dropdownMenu2"
                                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                         {patientValues[item.name.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_left_"+i] || "Normal"}
                                                    </button>
                                              

                                                    <div className="dropdown-menu"  aria-labelledby="dropdownMenu2">
                                                        
                                                        <button onClick={(e) => { handleSelect(e,"Normal", item.name.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_left_"+i) }}  className="dropdown-item" type="button">Normal</button>
                                                        <button  onClick={(e) => { handleSelect(e,"Absent", item.name.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_left_"+i) }}  className="dropdown-item" type="button">Absent</button>
                                                        <button onClick={(e) => { handleSelect(e,"Reduced", item.name.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_left_"+i) }}   className="dropdown-item" type="button">Reduced</button>
                                                        <button onClick={(e) => { handleSelect(e,"Brisk", item.name.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_left_"+i) }} className="dropdown-item" type="button">Brisk</button>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="dropdown">
                                                <button className="td_btn dropdown-toggle" type="button" id="dropdownMenu2"
                                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                         {patientValues[item.name.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_right_"+i] || "Normal"}
                                                    </button>
                                              

                                                    <div className="dropdown-menu"  aria-labelledby="dropdownMenu2">
                                                        
                                                        <button onClick={(e) => { handleSelect(e,"Normal", item.name.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_right_"+i) }}  className="dropdown-item" type="button">Normal</button>
                                                        <button  onClick={(e) => { handleSelect(e,"Absent", item.name.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_right_"+i) }}  className="dropdown-item" type="button">Absent</button>
                                                        <button onClick={(e) => { handleSelect(e,"Reduced", item.name.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_right_"+i) }}   className="dropdown-item" type="button">Reduced</button>
                                                        <button onClick={(e) => { handleSelect(e,"Brisk", item.name.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_right_"+i) }} className="dropdown-item" type="button">Brisk</button>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="dropdown">
                                                <button className="td_btn dropdown-toggle" type="button" id="dropdownMenu2"
                                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                         {patientValues[item.name.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_left_"+i] || "Normal"}
                                                    </button>
                                              

                                                    <div className="dropdown-menu"  aria-labelledby="dropdownMenu2">
                                                        
                                                        <button onClick={(e) => { handleSelect(e,"Normal", item.name.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_left_"+i) }}  className="dropdown-item" type="button">Normal</button>
                                                        <button  onClick={(e) => { handleSelect(e,"Absent", item.name.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_left_"+i) }}  className="dropdown-item" type="button">Absent</button>
                                                        <button onClick={(e) => { handleSelect(e,"Reduced", item.name.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_left_"+i) }}   className="dropdown-item" type="button">Reduced</button>
                                                        <button onClick={(e) => { handleSelect(e,"Brisk", item.name.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_left_"+i) }} className="dropdown-item" type="button">Brisk</button>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}

                                    
                                    
                                </tbody>
                            </table>
                        </div>

                        <div className="ssn_block">
                            <table className="table table-bordered ssn_table">

                                <thead>
                                    <tr>
                                        <th className="headcol th1m"></th>
                                        {daysList && daysList.length > 0 && daysList.map((item, i) => (
                                        <th colspan="2" className="th1" key={i}>{item.value}</th>
                                        ))}
                                    </tr>
                                    <tr>
                                        <th className="headcol th2m"> <b>Sensation </b></th>
                                        <th className="th2">Right</th>
                                        <th className="th2">Left</th>
                                        <th className="th2">Right</th>
                                        <th className="th2">Left</th>
                                        <th className="th2">Right</th>
                                        <th className="th2">Left</th>
                                        <th className="th2">Right</th>
                                        <th className="th2">Left</th>
                                    </tr>
                                </thead>
                                <tbody>


                                {sensationC && sensationC.length > 0 && sensationC.map((item, i) => (
                                     
                                <tr key={i}>
                                        <td className="headcol" >
                                            {item}
                                        </td>
                                        <td>
                                            <div className="dropdown ">
                                                <button className="td_btn" type="button" id="dropdownMenu2" data-toggle="dropdown"
                                                    aria-haspopup="true" aria-expanded="false">
                                                    <span className="text">C{i+1}</span> <img src={patientValues[item.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_right_"+i] || widthsvg} className="icon" />
                                                </button>
                                                <div className="dropdown-menu symbols" aria-labelledby="dropdownMenu2">
                                                    <button className="dropdown-item item1" type="button" 
                                                    onClick={(e) => { handleSelect(e, widthsvg, item.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_right_"+i) }} > <img
                                                        src={widthsvg} /></button>
                                                    <button className="dropdown-item item2" onClick={(e) => { handleSelect(e, trending_down, item.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_right_"+i) }}  type="button"> <img
                                                        src={trending_down} /></button>
                                                    <button className="dropdown-item item3" onClick={(e) => { handleSelect(e, trending_up, item.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_right_"+i) }}  type="button"> <img
                                                        src={trending_up} /></button>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dropdown ">
                                                <button className="td_btn" type="button" id="dropdownMenu2" data-toggle="dropdown"
                                                    aria-haspopup="true" aria-expanded="false">
                                                    <span className="text">C{i+1}</span> <img src={patientValues[item.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_left_"+i] || widthsvg} className="icon" />
                                                </button>
                                                <div className="dropdown-menu symbols" aria-labelledby="dropdownMenu2">
                                                    <button className="dropdown-item item1" type="button" 
                                                    onClick={(e) => { handleSelect(e, widthsvg, item.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_left_"+i) }} > <img
                                                        src={widthsvg} /></button>
                                                    <button className="dropdown-item item2" onClick={(e) => { handleSelect(e, trending_down, item.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_left_"+i) }}  type="button"> <img
                                                        src={trending_down} /></button>
                                                    <button className="dropdown-item item3" onClick={(e) => { handleSelect(e, trending_up, item.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_left_"+i) }}  type="button"> <img
                                                        src={trending_up} /></button>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dropdown ">
                                                <button className="td_btn" type="button" id="dropdownMenu2" data-toggle="dropdown"
                                                    aria-haspopup="true" aria-expanded="false">
                                                    <span className="text">C{i+1}</span> <img src={patientValues[item.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_right_"+i] || widthsvg} className="icon" />
                                                </button>
                                                <div className="dropdown-menu symbols" aria-labelledby="dropdownMenu2">
                                                    <button className="dropdown-item item1" type="button" 
                                                    onClick={(e) => { handleSelect(e, widthsvg, item.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_right_"+i) }} > <img
                                                        src={widthsvg} /></button>
                                                    <button className="dropdown-item item2" onClick={(e) => { handleSelect(e, trending_down, item.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_right_"+i) }}  type="button"> <img
                                                        src={trending_down} /></button>
                                                    <button className="dropdown-item item3" onClick={(e) => { handleSelect(e, trending_up, item.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_right_"+i) }}  type="button"> <img
                                                        src={trending_up} /></button>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dropdown ">
                                                <button className="td_btn" type="button" id="dropdownMenu2" data-toggle="dropdown"
                                                    aria-haspopup="true" aria-expanded="false">
                                                    <span className="text">C{i+1}</span> <img src={patientValues[item.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_left_"+i] || widthsvg} className="icon" />
                                                </button>
                                                <div className="dropdown-menu symbols" aria-labelledby="dropdownMenu2">
                                                    <button className="dropdown-item item1" type="button" 
                                                    onClick={(e) => { handleSelect(e, widthsvg, item.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_left_"+i) }} > <img
                                                        src={widthsvg} /></button>
                                                    <button className="dropdown-item item2" onClick={(e) => { handleSelect(e, trending_down, item.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_left_"+i) }}  type="button"> <img
                                                        src={trending_down} /></button>
                                                    <button className="dropdown-item item3" onClick={(e) => { handleSelect(e, trending_up, item.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_left_"+i) }}  type="button"> <img
                                                        src={trending_up} /></button>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dropdown ">
                                                <button className="td_btn" type="button" id="dropdownMenu2" data-toggle="dropdown"
                                                    aria-haspopup="true" aria-expanded="false">
                                                    <span className="text">C{i+1}</span> <img src={patientValues[item.toLowerCase().replace(/\W/g, '')+dayBeforetwo.toLowerCase().replace(/\W/g, '')+"_right_"+i] || widthsvg} className="icon" />
                                                </button>
                                                <div className="dropdown-menu symbols" aria-labelledby="dropdownMenu2">
                                                    <button className="dropdown-item item1" type="button" 
                                                    onClick={(e) => { handleSelect(e, widthsvg, item.toLowerCase().replace(/\W/g, '')+dayBeforetwo.toLowerCase().replace(/\W/g, '')+"_right_"+i) }} > <img
                                                        src={widthsvg} /></button>
                                                    <button className="dropdown-item item2" onClick={(e) => { handleSelect(e, trending_down, item.toLowerCase().replace(/\W/g, '')+dayBeforetwo.toLowerCase().replace(/\W/g, '')+"_right_"+i) }}  type="button"> <img
                                                        src={trending_down} /></button>
                                                    <button className="dropdown-item item3" onClick={(e) => { handleSelect(e, trending_up, item.toLowerCase().replace(/\W/g, '')+dayBeforetwo.toLowerCase().replace(/\W/g, '')+"_right_"+i) }}  type="button"> <img
                                                        src={trending_up} /></button>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dropdown ">
                                                <button className="td_btn" type="button" id="dropdownMenu2" data-toggle="dropdown"
                                                    aria-haspopup="true" aria-expanded="false">
                                                    <span className="text">C{i+1}</span> <img src={patientValues[item.toLowerCase().replace(/\W/g, '')+dayBeforetwo.toLowerCase().replace(/\W/g, '')+"_left_"+i] || widthsvg} className="icon" />
                                                </button>
                                                <div className="dropdown-menu symbols" aria-labelledby="dropdownMenu2">
                                                    <button className="dropdown-item item1" type="button" 
                                                    onClick={(e) => { handleSelect(e, widthsvg, item.toLowerCase().replace(/\W/g, '')+dayBeforetwo.toLowerCase().replace(/\W/g, '')+"_left_"+i) }} > <img
                                                        src={widthsvg} /></button>
                                                    <button className="dropdown-item item2" onClick={(e) => { handleSelect(e, trending_down, item.toLowerCase().replace(/\W/g, '')+dayBeforetwo.toLowerCase().replace(/\W/g, '')+"_left_"+i) }}  type="button"> <img
                                                        src={trending_down} /></button>
                                                    <button className="dropdown-item item3" onClick={(e) => { handleSelect(e, trending_up, item.toLowerCase().replace(/\W/g, '')+dayBeforetwo.toLowerCase().replace(/\W/g, '')+"_left_"+i) }}  type="button"> <img
                                                        src={trending_up} /></button>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dropdown ">
                                                <button className="td_btn" type="button" id="dropdownMenu2" data-toggle="dropdown"
                                                    aria-haspopup="true" aria-expanded="false">
                                                    <span className="text">C{i+1}</span> <img src={patientValues[item.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_right_"+i] || widthsvg} className="icon" />
                                                </button>
                                                <div className="dropdown-menu symbols" aria-labelledby="dropdownMenu2">
                                                    <button className="dropdown-item item1" type="button" 
                                                    onClick={(e) => { handleSelect(e, widthsvg, item.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_right_"+i) }} > <img
                                                        src={widthsvg} /></button>
                                                    <button className="dropdown-item item2" onClick={(e) => { handleSelect(e, trending_down, item.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_right_"+i) }}  type="button"> <img
                                                        src={trending_down} /></button>
                                                    <button className="dropdown-item item3" onClick={(e) => { handleSelect(e, trending_up, item.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_right_"+i) }}  type="button"> <img
                                                        src={trending_up} /></button>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dropdown ">
                                                <button className="td_btn" type="button" id="dropdownMenu2" data-toggle="dropdown"
                                                    aria-haspopup="true" aria-expanded="false">
                                                    <span className="text">C{i+1}</span> <img src={patientValues[item.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_left_"+i] || widthsvg} className="icon" />
                                                </button>
                                                <div className="dropdown-menu symbols" aria-labelledby="dropdownMenu2">
                                                    <button className="dropdown-item item1" type="button" 
                                                    onClick={(e) => { handleSelect(e, widthsvg, item.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_left_"+i) }} > <img
                                                        src={widthsvg} /></button>
                                                    <button className="dropdown-item item2" onClick={(e) => { handleSelect(e, trending_down, item.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_left_"+i) }}  type="button"> <img
                                                        src={trending_down} /></button>
                                                    <button className="dropdown-item item3" onClick={(e) => { handleSelect(e, trending_up, item.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_left_"+i) }}  type="button"> <img
                                                        src={trending_up} /></button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))}



{sensationCNerve && sensationCNerve.length > 0 && sensationCNerve.map((item, i) => (
                                     
                                     <tr key={i} className='bg2'>
                                             <td className="headcol" key={i}>
                                                 {item}
                                             </td>
                                             <td>
                                                 <div className="dropdown ">
                                                     <button className="td_btn" type="button" id="dropdownMenu2" data-toggle="dropdown"
                                                         aria-haspopup="true" aria-expanded="false">
                                                          <img src={patientValues[item.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_right_"+i] || widthsvg} className="icon" />
                                                     </button>
                                                     <div className="dropdown-menu symbols" aria-labelledby="dropdownMenu2">
                                                         <button className="dropdown-item item1" type="button" 
                                                         onClick={(e) => { handleSelect(e, widthsvg, item.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_right_"+i) }} > <img
                                                             src={widthsvg} /></button>
                                                         <button className="dropdown-item item2" onClick={(e) => { handleSelect(e, trending_down, item.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_right_"+i) }}  type="button"> <img
                                                             src={trending_down} /></button>
                                                         <button className="dropdown-item item3" onClick={(e) => { handleSelect(e, trending_up, item.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_right_"+i) }}  type="button"> <img
                                                             src={trending_up} /></button>
                                                     </div>
                                                 </div>
                                             </td>
                                             <td>
                                                 <div className="dropdown ">
                                                     <button className="td_btn" type="button" id="dropdownMenu2" data-toggle="dropdown"
                                                         aria-haspopup="true" aria-expanded="false">
                                                          <img src={patientValues[item.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_left_"+i] || widthsvg} className="icon" />
                                                     </button>
                                                     <div className="dropdown-menu symbols" aria-labelledby="dropdownMenu2">
                                                         <button className="dropdown-item item1" type="button" 
                                                         onClick={(e) => { handleSelect(e, widthsvg, item.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_left_"+i) }} > <img
                                                             src={widthsvg} /></button>
                                                         <button className="dropdown-item item2" onClick={(e) => { handleSelect(e, trending_down, item.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_left_"+i) }}  type="button"> <img
                                                             src={trending_down} /></button>
                                                         <button className="dropdown-item item3" onClick={(e) => { handleSelect(e, trending_up, item.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_left_"+i) }}  type="button"> <img
                                                             src={trending_up} /></button>
                                                     </div>
                                                 </div>
                                             </td>
                                             <td>
                                                 <div className="dropdown ">
                                                     <button className="td_btn" type="button" id="dropdownMenu2" data-toggle="dropdown"
                                                         aria-haspopup="true" aria-expanded="false">
                                                          <img src={patientValues[item.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_right_"+i] || widthsvg} className="icon" />
                                                     </button>
                                                     <div className="dropdown-menu symbols" aria-labelledby="dropdownMenu2">
                                                         <button className="dropdown-item item1" type="button" 
                                                         onClick={(e) => { handleSelect(e, widthsvg, item.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_right_"+i) }} > <img
                                                             src={widthsvg} /></button>
                                                         <button className="dropdown-item item2" onClick={(e) => { handleSelect(e, trending_down, item.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_right_"+i) }}  type="button"> <img
                                                             src={trending_down} /></button>
                                                         <button className="dropdown-item item3" onClick={(e) => { handleSelect(e, trending_up, item.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_right_"+i) }}  type="button"> <img
                                                             src={trending_up} /></button>
                                                     </div>
                                                 </div>
                                             </td>
                                             <td>
                                                 <div className="dropdown ">
                                                     <button className="td_btn" type="button" id="dropdownMenu2" data-toggle="dropdown"
                                                         aria-haspopup="true" aria-expanded="false">
                                                          <img src={patientValues[item.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_left_"+i] || widthsvg} className="icon" />
                                                     </button>
                                                     <div className="dropdown-menu symbols" aria-labelledby="dropdownMenu2">
                                                         <button className="dropdown-item item1" type="button" 
                                                         onClick={(e) => { handleSelect(e, widthsvg, item.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_left_"+i) }} > <img
                                                             src={widthsvg} /></button>
                                                         <button className="dropdown-item item2" onClick={(e) => { handleSelect(e, trending_down, item.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_left_"+i) }}  type="button"> <img
                                                             src={trending_down} /></button>
                                                         <button className="dropdown-item item3" onClick={(e) => { handleSelect(e, trending_up, item.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_left_"+i) }}  type="button"> <img
                                                             src={trending_up} /></button>
                                                     </div>
                                                 </div>
                                             </td>
                                             <td>
                                                 <div className="dropdown ">
                                                     <button className="td_btn" type="button" id="dropdownMenu2" data-toggle="dropdown"
                                                         aria-haspopup="true" aria-expanded="false">
                                                          <img src={patientValues[item.toLowerCase().replace(/\W/g, '')+dayBeforetwo.toLowerCase().replace(/\W/g, '')+"_right_"+i] || widthsvg} className="icon" />
                                                     </button>
                                                     <div className="dropdown-menu symbols" aria-labelledby="dropdownMenu2">
                                                         <button className="dropdown-item item1" type="button" 
                                                         onClick={(e) => { handleSelect(e, widthsvg, item.toLowerCase().replace(/\W/g, '')+dayBeforetwo.toLowerCase().replace(/\W/g, '')+"_right_"+i) }} > <img
                                                             src={widthsvg} /></button>
                                                         <button className="dropdown-item item2" onClick={(e) => { handleSelect(e, trending_down, item.toLowerCase().replace(/\W/g, '')+dayBeforetwo.toLowerCase().replace(/\W/g, '')+"_right_"+i) }}  type="button"> <img
                                                             src={trending_down} /></button>
                                                         <button className="dropdown-item item3" onClick={(e) => { handleSelect(e, trending_up, item.toLowerCase().replace(/\W/g, '')+dayBeforetwo.toLowerCase().replace(/\W/g, '')+"_right_"+i) }}  type="button"> <img
                                                             src={trending_up} /></button>
                                                     </div>
                                                 </div>
                                             </td>
                                             <td>
                                                 <div className="dropdown ">
                                                     <button className="td_btn" type="button" id="dropdownMenu2" data-toggle="dropdown"
                                                         aria-haspopup="true" aria-expanded="false">
                                                          <img src={patientValues[item.toLowerCase().replace(/\W/g, '')+dayBeforetwo.toLowerCase().replace(/\W/g, '')+"_left_"+i] || widthsvg} className="icon" />
                                                     </button>
                                                     <div className="dropdown-menu symbols" aria-labelledby="dropdownMenu2">
                                                         <button className="dropdown-item item1" type="button" 
                                                         onClick={(e) => { handleSelect(e, widthsvg, item.toLowerCase().replace(/\W/g, '')+dayBeforetwo.toLowerCase().replace(/\W/g, '')+"_left_"+i) }} > <img
                                                             src={widthsvg} /></button>
                                                         <button className="dropdown-item item2" onClick={(e) => { handleSelect(e, trending_down, item.toLowerCase().replace(/\W/g, '')+dayBeforetwo.toLowerCase().replace(/\W/g, '')+"_left_"+i) }}  type="button"> <img
                                                             src={trending_down} /></button>
                                                         <button className="dropdown-item item3" onClick={(e) => { handleSelect(e, trending_up, item.toLowerCase().replace(/\W/g, '')+dayBeforetwo.toLowerCase().replace(/\W/g, '')+"_left_"+i) }}  type="button"> <img
                                                             src={trending_up} /></button>
                                                     </div>
                                                 </div>
                                             </td>
                                             <td>
                                                 <div className="dropdown ">
                                                     <button className="td_btn" type="button" id="dropdownMenu2" data-toggle="dropdown"
                                                         aria-haspopup="true" aria-expanded="false">
                                                          <img src={patientValues[item.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_right_"+i] || widthsvg} className="icon" />
                                                     </button>
                                                     <div className="dropdown-menu symbols" aria-labelledby="dropdownMenu2">
                                                         <button className="dropdown-item item1" type="button" 
                                                         onClick={(e) => { handleSelect(e, widthsvg, item.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_right_"+i) }} > <img
                                                             src={widthsvg} /></button>
                                                         <button className="dropdown-item item2" onClick={(e) => { handleSelect(e, trending_down, item.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_right_"+i) }}  type="button"> <img
                                                             src={trending_down} /></button>
                                                         <button className="dropdown-item item3" onClick={(e) => { handleSelect(e, trending_up, item.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_right_"+i) }}  type="button"> <img
                                                             src={trending_up} /></button>
                                                     </div>
                                                 </div>
                                             </td>
                                             <td>
                                                 <div className="dropdown ">
                                                     <button className="td_btn" type="button" id="dropdownMenu2" data-toggle="dropdown"
                                                         aria-haspopup="true" aria-expanded="false">
                                                          <img src={patientValues[item.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_left_"+i] || widthsvg} className="icon" />
                                                     </button>
                                                     <div className="dropdown-menu symbols" aria-labelledby="dropdownMenu2">
                                                         <button className="dropdown-item item1" type="button" 
                                                         onClick={(e) => { handleSelect(e, widthsvg, item.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_left_"+i) }} > <img
                                                             src={widthsvg} /></button>
                                                         <button className="dropdown-item item2" onClick={(e) => { handleSelect(e, trending_down, item.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_left_"+i) }}  type="button"> <img
                                                             src={trending_down} /></button>
                                                         <button className="dropdown-item item3" onClick={(e) => { handleSelect(e, trending_up, item.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_left_"+i) }}  type="button"> <img
                                                             src={trending_up} /></button>
                                                     </div>
                                                 </div>
                                             </td>
                                         </tr>
                                     ))}

                             

                                </tbody>
                            </table>
                        </div>
                        <h3 className="popup_title mb-10">Coordination</h3>
                        <div className="ssn_block">
                            <table className="table table-bordered ssn_table">

                                <thead>
                                    <tr>
                                        <th className="headcol th1m"></th>
                                        {daysList && daysList.length > 0 && daysList.map((item, i) => (
                                        <th colspan="2" className="th1" key={i}>{item.value}</th>
                                        ))}
                                    </tr>
                                    <tr>
                                        <th className="headcol th2m"> <b>Sensation TRUNK </b></th>
                                        <th className="th2">Right</th>
                                        <th className="th2">Left</th>
                                        <th className="th2">Right</th>
                                        <th className="th2">Left</th>
                                        <th className="th2">Right</th>
                                        <th className="th2">Left</th>
                                        <th className="th2">Right</th>
                                        <th className="th2">Left</th>
                                    </tr>
                                </thead>
                                <tbody>



                                {coOrdinationSensation && coOrdinationSensation.length > 0 && coOrdinationSensation.map((item, i) => (
                                     
                                     <tr>
                                             <td className="headcol" key={i}>
                                                 {item}
                                             </td>
                                             <td>
                                                 <div className="dropdown ">
                                                     <button className="td_btn" type="button" id="dropdownMenu2" data-toggle="dropdown"
                                                         aria-haspopup="true" aria-expanded="false">
                                                         <span className="text">T{i+1}</span> <img src={patientValues[item.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_right_"+i] || widthsvg} className="icon" />
                                                     </button>
                                                     <div className="dropdown-menu symbols" aria-labelledby="dropdownMenu2">
                                                         <button className="dropdown-item item1" type="button" 
                                                         onClick={(e) => { handleSelect(e, widthsvg, item.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_right_"+i) }} > <img
                                                             src={widthsvg} /></button>
                                                         <button className="dropdown-item item2" onClick={(e) => { handleSelect(e, trending_down, item.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_right_"+i) }}  type="button"> <img
                                                             src={trending_down} /></button>
                                                         <button className="dropdown-item item3" onClick={(e) => { handleSelect(e, trending_up, item.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_right_"+i) }}  type="button"> <img
                                                             src={trending_up} /></button>
                                                     </div>
                                                 </div>
                                             </td>
                                             <td>
                                                 <div className="dropdown ">
                                                     <button className="td_btn" type="button" id="dropdownMenu2" data-toggle="dropdown"
                                                         aria-haspopup="true" aria-expanded="false">
                                                         <span className="text">T{i+1}</span> <img src={patientValues[item.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_left_"+i] || widthsvg} className="icon" />
                                                     </button>
                                                     <div className="dropdown-menu symbols" aria-labelledby="dropdownMenu2">
                                                         <button className="dropdown-item item1" type="button" 
                                                         onClick={(e) => { handleSelect(e, widthsvg, item.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_left_"+i) }} > <img
                                                             src={widthsvg} /></button>
                                                         <button className="dropdown-item item2" onClick={(e) => { handleSelect(e, trending_down, item.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_left_"+i) }}  type="button"> <img
                                                             src={trending_down} /></button>
                                                         <button className="dropdown-item item3" onClick={(e) => { handleSelect(e, trending_up, item.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_left_"+i) }}  type="button"> <img
                                                             src={trending_up} /></button>
                                                     </div>
                                                 </div>
                                             </td>
                                             <td>
                                                 <div className="dropdown ">
                                                     <button className="td_btn" type="button" id="dropdownMenu2" data-toggle="dropdown"
                                                         aria-haspopup="true" aria-expanded="false">
                                                         <span className="text">T{i+1}</span> <img src={patientValues[item.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_right_"+i] || widthsvg} className="icon" />
                                                     </button>
                                                     <div className="dropdown-menu symbols" aria-labelledby="dropdownMenu2">
                                                         <button className="dropdown-item item1" type="button" 
                                                         onClick={(e) => { handleSelect(e, widthsvg, item.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_right_"+i) }} > <img
                                                             src={widthsvg} /></button>
                                                         <button className="dropdown-item item2" onClick={(e) => { handleSelect(e, trending_down, item.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_right_"+i) }}  type="button"> <img
                                                             src={trending_down} /></button>
                                                         <button className="dropdown-item item3" onClick={(e) => { handleSelect(e, trending_up, item.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_right_"+i) }}  type="button"> <img
                                                             src={trending_up} /></button>
                                                     </div>
                                                 </div>
                                             </td>
                                             <td>
                                                 <div className="dropdown ">
                                                     <button className="td_btn" type="button" id="dropdownMenu2" data-toggle="dropdown"
                                                         aria-haspopup="true" aria-expanded="false">
                                                         <span className="text">T{i+1}</span> <img src={patientValues[item.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_left_"+i] || widthsvg} className="icon" />
                                                     </button>
                                                     <div className="dropdown-menu symbols" aria-labelledby="dropdownMenu2">
                                                         <button className="dropdown-item item1" type="button" 
                                                         onClick={(e) => { handleSelect(e, widthsvg, item.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_left_"+i) }} > <img
                                                             src={widthsvg} /></button>
                                                         <button className="dropdown-item item2" onClick={(e) => { handleSelect(e, trending_down, item.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_left_"+i) }}  type="button"> <img
                                                             src={trending_down} /></button>
                                                         <button className="dropdown-item item3" onClick={(e) => { handleSelect(e, trending_up, item.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_left_"+i) }}  type="button"> <img
                                                             src={trending_up} /></button>
                                                     </div>
                                                 </div>
                                             </td>
                                             <td>
                                                 <div className="dropdown ">
                                                     <button className="td_btn" type="button" id="dropdownMenu2" data-toggle="dropdown"
                                                         aria-haspopup="true" aria-expanded="false">
                                                         <span className="text">T{i+1}</span> <img src={patientValues[item.toLowerCase().replace(/\W/g, '')+dayBeforetwo.toLowerCase().replace(/\W/g, '')+"_right_"+i] || widthsvg} className="icon" />
                                                     </button>
                                                     <div className="dropdown-menu symbols" aria-labelledby="dropdownMenu2">
                                                         <button className="dropdown-item item1" type="button" 
                                                         onClick={(e) => { handleSelect(e, widthsvg, item.toLowerCase().replace(/\W/g, '')+dayBeforetwo.toLowerCase().replace(/\W/g, '')+"_right_"+i) }} > <img
                                                             src={widthsvg} /></button>
                                                         <button className="dropdown-item item2" onClick={(e) => { handleSelect(e, trending_down, item.toLowerCase().replace(/\W/g, '')+dayBeforetwo.toLowerCase().replace(/\W/g, '')+"_right_"+i) }}  type="button"> <img
                                                             src={trending_down} /></button>
                                                         <button className="dropdown-item item3" onClick={(e) => { handleSelect(e, trending_up, item.toLowerCase().replace(/\W/g, '')+dayBeforetwo.toLowerCase().replace(/\W/g, '')+"_right_"+i) }}  type="button"> <img
                                                             src={trending_up} /></button>
                                                     </div>
                                                 </div>
                                             </td>
                                             <td>
                                                 <div className="dropdown ">
                                                     <button className="td_btn" type="button" id="dropdownMenu2" data-toggle="dropdown"
                                                         aria-haspopup="true" aria-expanded="false">
                                                         <span className="text">T{i+1}</span> <img src={patientValues[item.toLowerCase().replace(/\W/g, '')+dayBeforetwo.toLowerCase().replace(/\W/g, '')+"_left_"+i] || widthsvg} className="icon" />
                                                     </button>
                                                     <div className="dropdown-menu symbols" aria-labelledby="dropdownMenu2">
                                                         <button className="dropdown-item item1" type="button" 
                                                         onClick={(e) => { handleSelect(e, widthsvg, item.toLowerCase().replace(/\W/g, '')+dayBeforetwo.toLowerCase().replace(/\W/g, '')+"_left_"+i) }} > <img
                                                             src={widthsvg} /></button>
                                                         <button className="dropdown-item item2" onClick={(e) => { handleSelect(e, trending_down, item.toLowerCase().replace(/\W/g, '')+dayBeforetwo.toLowerCase().replace(/\W/g, '')+"_left_"+i) }}  type="button"> <img
                                                             src={trending_down} /></button>
                                                         <button className="dropdown-item item3" onClick={(e) => { handleSelect(e, trending_up, item.toLowerCase().replace(/\W/g, '')+dayBeforetwo.toLowerCase().replace(/\W/g, '')+"_left_"+i) }}  type="button"> <img
                                                             src={trending_up} /></button>
                                                     </div>
                                                 </div>
                                             </td>
                                             <td>
                                                 <div className="dropdown ">
                                                     <button className="td_btn" type="button" id="dropdownMenu2" data-toggle="dropdown"
                                                         aria-haspopup="true" aria-expanded="false">
                                                         <span className="text">T{i+1}</span> <img src={patientValues[item.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_right_"+i] || widthsvg} className="icon" />
                                                     </button>
                                                     <div className="dropdown-menu symbols" aria-labelledby="dropdownMenu2">
                                                         <button className="dropdown-item item1" type="button" 
                                                         onClick={(e) => { handleSelect(e, widthsvg, item.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_right_"+i) }} > <img
                                                             src={widthsvg} /></button>
                                                         <button className="dropdown-item item2" onClick={(e) => { handleSelect(e, trending_down, item.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_right_"+i) }}  type="button"> <img
                                                             src={trending_down} /></button>
                                                         <button className="dropdown-item item3" onClick={(e) => { handleSelect(e, trending_up, item.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_right_"+i) }}  type="button"> <img
                                                             src={trending_up} /></button>
                                                     </div>
                                                 </div>
                                             </td>
                                             <td>
                                                 <div className="dropdown ">
                                                     <button className="td_btn" type="button" id="dropdownMenu2" data-toggle="dropdown"
                                                         aria-haspopup="true" aria-expanded="false">
                                                         <span className="text">T{i+1}</span> <img src={patientValues[item.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_left_"+i] || widthsvg} className="icon" />
                                                     </button>
                                                     <div className="dropdown-menu symbols" aria-labelledby="dropdownMenu2">
                                                         <button className="dropdown-item item1" type="button" 
                                                         onClick={(e) => { handleSelect(e, widthsvg, item.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_left_"+i) }} > <img
                                                             src={widthsvg} /></button>
                                                         <button className="dropdown-item item2" onClick={(e) => { handleSelect(e, trending_down, item.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_left_"+i) }}  type="button"> <img
                                                             src={trending_down} /></button>
                                                         <button className="dropdown-item item3" onClick={(e) => { handleSelect(e, trending_up, item.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_left_"+i) }}  type="button"> <img
                                                             src={trending_up} /></button>
                                                     </div>
                                                 </div>
                                             </td>
                                         </tr>
                                     ))}


                                   
                                </tbody>
                            </table>
                        </div>
                        <p className="sub_title mt-10 mb-10">Lower limb</p>
                        <div className="ssn_block">
                            <table className="table table-bordered ssn_table">
                                <thead>
                                    <tr>
                                        <th className="headcol th1m"></th>
                                        {daysList && daysList.length > 0 && daysList.map((item, i) => (
                                        <th colspan="2" className="th1" key={i}>{item.value}</th>
                                        ))}
                                    </tr>
                                    <tr>
                                        <th className="headcol th2m"></th>
                                        <th className="th2">Right</th>
                                        <th className="th2">Left</th>
                                        <th className="th2">Right</th>
                                        <th className="th2">Left</th>
                                        <th className="th2">Right</th>
                                        <th className="th2">Left</th>
                                        <th className="th2">Right</th>
                                        <th className="th2">Left</th>
                                    </tr>
                                </thead>
                                <tbody>

                                {lowerLimb && lowerLimb.length > 0 && lowerLimb.map((item, i) => (
                                        

                                        <tr key={i}>
                                        <td className="headcol">
                                            <b>{item.value}</b>
                                        </td>
                                        <td>
                                            <div className="dropdown">
                                                <button className="td_btn dropdown-toggle" type="button" id="dropdownMenu2"
                                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                     {patientValues[item.name.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_right_"+i] || "Normal"}
                                                </button>
                                          

                                                <div className="dropdown-menu"  aria-labelledby="dropdownMenu2">
                                                    
                                                    <button onClick={(e) => { handleSelect(e,"Normal", item.name.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_right_"+i) }}  className="dropdown-item" type="button">Normal</button>
                                                    <button  onClick={(e) => { handleSelect(e,"Flaccid", item.name.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_right_"+i) }}  className="dropdown-item" type="button">Flaccid</button>
                                                    <button onClick={(e) => { handleSelect(e,"Spastic", item.name.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_right_"+i) }}   className="dropdown-item" type="button">Spastic</button>
                                                    <button onClick={(e) => { handleSelect(e,"Clonus", item.name.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_right_"+i) }} className="dropdown-item" type="button">Clonus</button>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dropdown">
                                                <button className="td_btn dropdown-toggle" type="button" id="dropdownMenu2"
                                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                     {patientValues[item.name.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_left_"+i] || "Normal"}
                                                </button>
                                          

                                                <div className="dropdown-menu"  aria-labelledby="dropdownMenu2">
                                                    
                                                    <button onClick={(e) => { handleSelect(e,"Normal", item.name.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_left_"+i) }}  className="dropdown-item" type="button">Normal</button>
                                                    <button  onClick={(e) => { handleSelect(e,"Flaccid", item.name.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_left_"+i) }}  className="dropdown-item" type="button">Flaccid</button>
                                                    <button onClick={(e) => { handleSelect(e,"Spastic", item.name.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_left_"+i) }}   className="dropdown-item" type="button">Spastic</button>
                                                    <button onClick={(e) => { handleSelect(e,"Clonus", item.name.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_left_"+i) }} className="dropdown-item" type="button">Clonus</button>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dropdown">
                                            <button className="td_btn dropdown-toggle" type="button" id="dropdownMenu2"
                                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                     {patientValues[item.name.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_right_"+i] || "Normal"}
                                                </button>
                                          

                                                <div className="dropdown-menu"  aria-labelledby="dropdownMenu2">
                                                    
                                                    <button onClick={(e) => { handleSelect(e,"Normal", item.name.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_right_"+i) }}  className="dropdown-item" type="button">Normal</button>
                                                    <button  onClick={(e) => { handleSelect(e,"Flaccid", item.name.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_right_"+i) }}  className="dropdown-item" type="button">Flaccid</button>
                                                    <button onClick={(e) => { handleSelect(e,"Spastic", item.name.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_right_"+i) }}   className="dropdown-item" type="button">Spastic</button>
                                                    <button onClick={(e) => { handleSelect(e,"Clonus", item.name.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_right_"+i) }} className="dropdown-item" type="button">Clonus</button>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dropdown">
                                            <button className="td_btn dropdown-toggle" type="button" id="dropdownMenu2"
                                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                     {patientValues[item.name.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_left_"+i] || "Normal"}
                                                </button>
                                          

                                                <div className="dropdown-menu"  aria-labelledby="dropdownMenu2">
                                                    
                                                    <button onClick={(e) => { handleSelect(e,"Normal", item.name.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_left_"+i) }}  className="dropdown-item" type="button">Normal</button>
                                                    <button  onClick={(e) => { handleSelect(e,"Flaccid", item.name.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_left_"+i) }}  className="dropdown-item" type="button">Flaccid</button>
                                                    <button onClick={(e) => { handleSelect(e,"Spastic", item.name.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_left_"+i) }}   className="dropdown-item" type="button">Spastic</button>
                                                    <button onClick={(e) => { handleSelect(e,"Clonus", item.name.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_left_"+i) }} className="dropdown-item" type="button">Clonus</button>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dropdown">
                                            <button className="td_btn dropdown-toggle" type="button" id="dropdownMenu2"
                                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                     {patientValues[item.name.toLowerCase().replace(/\W/g, '')+dayBeforetwo.toLowerCase().replace(/\W/g, '')+"_right_"+i] || "Normal"}
                                                </button>
                                          

                                                <div className="dropdown-menu"  aria-labelledby="dropdownMenu2">
                                                    
                                                    <button onClick={(e) => { handleSelect(e,"Normal", item.name.toLowerCase().replace(/\W/g, '')+dayBeforetwo.toLowerCase().replace(/\W/g, '')+"_right_"+i) }}  className="dropdown-item" type="button">Normal</button>
                                                    <button  onClick={(e) => { handleSelect(e,"Flaccid", item.name.toLowerCase().replace(/\W/g, '')+dayBeforetwo.toLowerCase().replace(/\W/g, '')+"_right_"+i) }}  className="dropdown-item" type="button">Flaccid</button>
                                                    <button onClick={(e) => { handleSelect(e,"Spastic", item.name.toLowerCase().replace(/\W/g, '')+dayBeforetwo.toLowerCase().replace(/\W/g, '')+"_right_"+i) }}   className="dropdown-item" type="button">Spastic</button>
                                                    <button onClick={(e) => { handleSelect(e,"Clonus", item.name.toLowerCase().replace(/\W/g, '')+dayBeforetwo.toLowerCase().replace(/\W/g, '')+"_right_"+i) }} className="dropdown-item" type="button">Clonus</button>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dropdown">
                                            <button className="td_btn dropdown-toggle" type="button" id="dropdownMenu2"
                                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                     {patientValues[item.name.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_left_"+i] || "Normal"}
                                                </button>
                                          

                                                <div className="dropdown-menu"  aria-labelledby="dropdownMenu2">
                                                    
                                                    <button onClick={(e) => { handleSelect(e,"Normal", item.name.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_left_"+i) }}  className="dropdown-item" type="button">Normal</button>
                                                    <button  onClick={(e) => { handleSelect(e,"Flaccid", item.name.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_left_"+i) }}  className="dropdown-item" type="button">Flaccid</button>
                                                    <button onClick={(e) => { handleSelect(e,"Spastic", item.name.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_left_"+i) }}   className="dropdown-item" type="button">Spastic</button>
                                                    <button onClick={(e) => { handleSelect(e,"Clonus", item.name.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_left_"+i) }} className="dropdown-item" type="button">Clonus</button>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dropdown">
                                            <button className="td_btn dropdown-toggle" type="button" id="dropdownMenu2"
                                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                     {patientValues[item.name.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_right_"+i] || "Normal"}
                                                </button>
                                          

                                                <div className="dropdown-menu"  aria-labelledby="dropdownMenu2">
                                                    
                                                    <button onClick={(e) => { handleSelect(e,"Normal", item.name.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_right_"+i) }}  className="dropdown-item" type="button">Normal</button>
                                                    <button  onClick={(e) => { handleSelect(e,"Flaccid", item.name.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_right_"+i) }}  className="dropdown-item" type="button">Flaccid</button>
                                                    <button onClick={(e) => { handleSelect(e,"Spastic", item.name.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_right_"+i) }}   className="dropdown-item" type="button">Spastic</button>
                                                    <button onClick={(e) => { handleSelect(e,"Clonus", item.name.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_right_"+i) }} className="dropdown-item" type="button">Clonus</button>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dropdown">
                                            <button className="td_btn dropdown-toggle" type="button" id="dropdownMenu2"
                                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                     {patientValues[item.name.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_left_"+i] || "Normal"}
                                                </button>
                                          

                                                <div className="dropdown-menu"  aria-labelledby="dropdownMenu2">
                                                    
                                                    <button onClick={(e) => { handleSelect(e,"Normal", item.name.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_left_"+i) }}  className="dropdown-item" type="button">Normal</button>
                                                    <button  onClick={(e) => { handleSelect(e,"Flaccid", item.name.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_left_"+i) }}  className="dropdown-item" type="button">Flaccid</button>
                                                    <button onClick={(e) => { handleSelect(e,"Spastic", item.name.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_left_"+i) }}   className="dropdown-item" type="button">Spastic</button>
                                                    <button onClick={(e) => { handleSelect(e,"Clonus", item.name.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_left_"+i) }} className="dropdown-item" type="button">Clonus</button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    ))}




                                    {lowerLimbReflex && lowerLimbReflex.length > 0 && lowerLimbReflex.map((item, i) => (
                                        

                                        <tr key={i} className="bg3">
                                            <td className="headcol">
                                                <b>{item.value}</b>
                                            </td>
                                            <td>
                                                <div className="dropdown">
                                                    <button className="td_btn dropdown-toggle" type="button" id="dropdownMenu2"
                                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                         {patientValues[item.name.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_right_"+i] || "Normal"}
                                                    </button>
                                              

                                                    <div className="dropdown-menu"  aria-labelledby="dropdownMenu2">
                                                        
                                                        <button onClick={(e) => { handleSelect(e,"Normal", item.name.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_right_"+i) }}  className="dropdown-item" type="button">Normal</button>
                                                        <button  onClick={(e) => { handleSelect(e,"Absent", item.name.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_right_"+i) }}  className="dropdown-item" type="button">Absent</button>
                                                        <button onClick={(e) => { handleSelect(e,"Reduced", item.name.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_right_"+i) }}   className="dropdown-item" type="button">Reduced</button>
                                                        <button onClick={(e) => { handleSelect(e,"Brisk", item.name.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_right_"+i) }} className="dropdown-item" type="button">Brisk</button>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="dropdown">
                                                    <button className="td_btn dropdown-toggle" type="button" id="dropdownMenu2"
                                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                         {patientValues[item.name.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_left_"+i] || "Normal"}
                                                    </button>
                                              

                                                    <div className="dropdown-menu"  aria-labelledby="dropdownMenu2">
                                                        
                                                        <button onClick={(e) => { handleSelect(e,"Normal", item.name.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_left_"+i) }}  className="dropdown-item" type="button">Normal</button>
                                                        <button  onClick={(e) => { handleSelect(e,"Absent", item.name.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_left_"+i) }}  className="dropdown-item" type="button">Absent</button>
                                                        <button onClick={(e) => { handleSelect(e,"Reduced", item.name.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_left_"+i) }}   className="dropdown-item" type="button">Reduced</button>
                                                        <button onClick={(e) => { handleSelect(e,"Brisk", item.name.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_left_"+i) }} className="dropdown-item" type="button">Brisk</button>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="dropdown">
                                                <button className="td_btn dropdown-toggle" type="button" id="dropdownMenu2"
                                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                         {patientValues[item.name.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_right_"+i] || "Normal"}
                                                    </button>
                                              

                                                    <div className="dropdown-menu"  aria-labelledby="dropdownMenu2">
                                                        
                                                        <button onClick={(e) => { handleSelect(e,"Normal", item.name.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_right_"+i) }}  className="dropdown-item" type="button">Normal</button>
                                                        <button  onClick={(e) => { handleSelect(e,"Absent", item.name.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_right_"+i) }}  className="dropdown-item" type="button">Absent</button>
                                                        <button onClick={(e) => { handleSelect(e,"Reduced", item.name.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_right_"+i) }}   className="dropdown-item" type="button">Reduced</button>
                                                        <button onClick={(e) => { handleSelect(e,"Brisk", item.name.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_right_"+i) }} className="dropdown-item" type="button">Brisk</button>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="dropdown">
                                                <button className="td_btn dropdown-toggle" type="button" id="dropdownMenu2"
                                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                         {patientValues[item.name.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_left_"+i] || "Normal"}
                                                    </button>
                                              

                                                    <div className="dropdown-menu"  aria-labelledby="dropdownMenu2">
                                                        
                                                        <button onClick={(e) => { handleSelect(e,"Normal", item.name.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_left_"+i) }}  className="dropdown-item" type="button">Normal</button>
                                                        <button  onClick={(e) => { handleSelect(e,"Absent", item.name.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_left_"+i) }}  className="dropdown-item" type="button">Absent</button>
                                                        <button onClick={(e) => { handleSelect(e,"Reduced", item.name.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_left_"+i) }}   className="dropdown-item" type="button">Reduced</button>
                                                        <button onClick={(e) => { handleSelect(e,"Brisk", item.name.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_left_"+i) }} className="dropdown-item" type="button">Brisk</button>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="dropdown">
                                                <button className="td_btn dropdown-toggle" type="button" id="dropdownMenu2"
                                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                         {patientValues[item.name.toLowerCase().replace(/\W/g, '')+dayBeforetwo.toLowerCase().replace(/\W/g, '')+"_right_"+i] || "Normal"}
                                                    </button>
                                              

                                                    <div className="dropdown-menu"  aria-labelledby="dropdownMenu2">
                                                        
                                                        <button onClick={(e) => { handleSelect(e,"Normal", item.name.toLowerCase().replace(/\W/g, '')+dayBeforetwo.toLowerCase().replace(/\W/g, '')+"_right_"+i) }}  className="dropdown-item" type="button">Normal</button>
                                                        <button  onClick={(e) => { handleSelect(e,"Absent", item.name.toLowerCase().replace(/\W/g, '')+dayBeforetwo.toLowerCase().replace(/\W/g, '')+"_right_"+i) }}  className="dropdown-item" type="button">Absent</button>
                                                        <button onClick={(e) => { handleSelect(e,"Reduced", item.name.toLowerCase().replace(/\W/g, '')+dayBeforetwo.toLowerCase().replace(/\W/g, '')+"_right_"+i) }}   className="dropdown-item" type="button">Reduced</button>
                                                        <button onClick={(e) => { handleSelect(e,"Brisk", item.name.toLowerCase().replace(/\W/g, '')+dayBeforetwo.toLowerCase().replace(/\W/g, '')+"_right_"+i) }} className="dropdown-item" type="button">Brisk</button>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="dropdown">
                                                <button className="td_btn dropdown-toggle" type="button" id="dropdownMenu2"
                                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                         {patientValues[item.name.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_left_"+i] || "Normal"}
                                                    </button>
                                              

                                                    <div className="dropdown-menu"  aria-labelledby="dropdownMenu2">
                                                        
                                                        <button onClick={(e) => { handleSelect(e,"Normal", item.name.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_left_"+i) }}  className="dropdown-item" type="button">Normal</button>
                                                        <button  onClick={(e) => { handleSelect(e,"Absent", item.name.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_left_"+i) }}  className="dropdown-item" type="button">Absent</button>
                                                        <button onClick={(e) => { handleSelect(e,"Reduced", item.name.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_left_"+i) }}   className="dropdown-item" type="button">Reduced</button>
                                                        <button onClick={(e) => { handleSelect(e,"Brisk", item.name.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_left_"+i) }} className="dropdown-item" type="button">Brisk</button>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="dropdown">
                                                <button className="td_btn dropdown-toggle" type="button" id="dropdownMenu2"
                                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                         {patientValues[item.name.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_right_"+i] || "Normal"}
                                                    </button>
                                              

                                                    <div className="dropdown-menu"  aria-labelledby="dropdownMenu2">
                                                        
                                                        <button onClick={(e) => { handleSelect(e,"Normal", item.name.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_right_"+i) }}  className="dropdown-item" type="button">Normal</button>
                                                        <button  onClick={(e) => { handleSelect(e,"Absent", item.name.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_right_"+i) }}  className="dropdown-item" type="button">Absent</button>
                                                        <button onClick={(e) => { handleSelect(e,"Reduced", item.name.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_right_"+i) }}   className="dropdown-item" type="button">Reduced</button>
                                                        <button onClick={(e) => { handleSelect(e,"Brisk", item.name.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_right_"+i) }} className="dropdown-item" type="button">Brisk</button>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="dropdown">
                                                <button className="td_btn dropdown-toggle" type="button" id="dropdownMenu2"
                                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                         {patientValues[item.name.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_left_"+i] || "Normal"}
                                                    </button>
                                              

                                                    <div className="dropdown-menu"  aria-labelledby="dropdownMenu2">
                                                        
                                                        <button onClick={(e) => { handleSelect(e,"Normal", item.name.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_left_"+i) }}  className="dropdown-item" type="button">Normal</button>
                                                        <button  onClick={(e) => { handleSelect(e,"Absent", item.name.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_left_"+i) }}  className="dropdown-item" type="button">Absent</button>
                                                        <button onClick={(e) => { handleSelect(e,"Reduced", item.name.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_left_"+i) }}   className="dropdown-item" type="button">Reduced</button>
                                                        <button onClick={(e) => { handleSelect(e,"Brisk", item.name.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_left_"+i) }} className="dropdown-item" type="button">Brisk</button>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}


                                   
                                </tbody>
                            </table>
                        </div>
                        <div className="ssn_block">
                            <table className="table table-bordered ssn_table">

                                <thead>
                                    <tr>
                                        <th className="headcol th1m"></th>
                                        {daysList && daysList.length > 0 && daysList.map((item, i) => (
                                        <th colspan="2" className="th1" key={i}>{item.value}</th>
                                        ))}
                                    </tr>
                                    <tr>
                                        <th className="headcol th2m"> <b>Sensation </b></th>
                                        <th className="th2">Right</th>
                                        <th className="th2">Left</th>
                                        <th className="th2">Right</th>
                                        <th className="th2">Left</th>
                                        <th className="th2">Right</th>
                                        <th className="th2">Left</th>
                                        <th className="th2">Right</th>
                                        <th className="th2">Left</th>
                                    </tr>
                                </thead>
                                <tbody>

                                {lowerSensationC && lowerSensationC.length > 0 && lowerSensationC.map((item, i) => (
                                     
                                     <tr key={i}>
                                             <td className="headcol" >
                                                 {item}
                                             </td>
                                             <td>
                                                 <div className="dropdown ">
                                                     <button className="td_btn" type="button" id="dropdownMenu2" data-toggle="dropdown"
                                                         aria-haspopup="true" aria-expanded="false">
                                                         <span className="text">L{i+1}</span> <img src={patientValues[item.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_right_l_"+i] || widthsvg} className="icon" />
                                                     </button>
                                                     <div className="dropdown-menu symbols" aria-labelledby="dropdownMenu2">
                                                         <button className="dropdown-item item1" type="button" 
                                                         onClick={(e) => { handleSelect(e, widthsvg, item.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_right_l_"+i) }} > <img
                                                             src={widthsvg} /></button>
                                                         <button className="dropdown-item item2" onClick={(e) => { handleSelect(e, trending_down, item.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_right_l_"+i) }}  type="button"> <img
                                                             src={trending_down} /></button>
                                                         <button className="dropdown-item item3" onClick={(e) => { handleSelect(e, trending_up, item.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_right_l_"+i) }}  type="button"> <img
                                                             src={trending_up} /></button>
                                                     </div>
                                                 </div>
                                             </td>
                                             <td>
                                                 <div className="dropdown ">
                                                     <button className="td_btn" type="button" id="dropdownMenu2" data-toggle="dropdown"
                                                         aria-haspopup="true" aria-expanded="false">
                                                         <span className="text">L{i+1}</span> <img src={patientValues[item.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_left_l_"+i] || widthsvg} className="icon" />
                                                     </button>
                                                     <div className="dropdown-menu symbols" aria-labelledby="dropdownMenu2">
                                                         <button className="dropdown-item item1" type="button" 
                                                         onClick={(e) => { handleSelect(e, widthsvg, item.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_left_l_"+i) }} > <img
                                                             src={widthsvg} /></button>
                                                         <button className="dropdown-item item2" onClick={(e) => { handleSelect(e, trending_down, item.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_left_l_"+i) }}  type="button"> <img
                                                             src={trending_down} /></button>
                                                         <button className="dropdown-item item3" onClick={(e) => { handleSelect(e, trending_up, item.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_left_l_"+i) }}  type="button"> <img
                                                             src={trending_up} /></button>
                                                     </div>
                                                 </div>
                                             </td>
                                             <td>
                                                 <div className="dropdown ">
                                                     <button className="td_btn" type="button" id="dropdownMenu2" data-toggle="dropdown"
                                                         aria-haspopup="true" aria-expanded="false">
                                                         <span className="text">L{i+1}</span> <img src={patientValues[item.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_right_l_"+i] || widthsvg} className="icon" />
                                                     </button>
                                                     <div className="dropdown-menu symbols" aria-labelledby="dropdownMenu2">
                                                         <button className="dropdown-item item1" type="button" 
                                                         onClick={(e) => { handleSelect(e, widthsvg, item.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_right_l_"+i) }} > <img
                                                             src={widthsvg} /></button>
                                                         <button className="dropdown-item item2" onClick={(e) => { handleSelect(e, trending_down, item.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_right_l_"+i) }}  type="button"> <img
                                                             src={trending_down} /></button>
                                                         <button className="dropdown-item item3" onClick={(e) => { handleSelect(e, trending_up, item.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_right_l_"+i) }}  type="button"> <img
                                                             src={trending_up} /></button>
                                                     </div>
                                                 </div>
                                             </td>
                                             <td>
                                                 <div className="dropdown ">
                                                     <button className="td_btn" type="button" id="dropdownMenu2" data-toggle="dropdown"
                                                         aria-haspopup="true" aria-expanded="false">
                                                         <span className="text">L{i+1}</span> <img src={patientValues[item.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_left_l_"+i] || widthsvg} className="icon" />
                                                     </button>
                                                     <div className="dropdown-menu symbols" aria-labelledby="dropdownMenu2">
                                                         <button className="dropdown-item item1" type="button" 
                                                         onClick={(e) => { handleSelect(e, widthsvg, item.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_left_l_"+i) }} > <img
                                                             src={widthsvg} /></button>
                                                         <button className="dropdown-item item2" onClick={(e) => { handleSelect(e, trending_down, item.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_left_l_"+i) }}  type="button"> <img
                                                             src={trending_down} /></button>
                                                         <button className="dropdown-item item3" onClick={(e) => { handleSelect(e, trending_up, item.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_left_l_"+i) }}  type="button"> <img
                                                             src={trending_up} /></button>
                                                     </div>
                                                 </div>
                                             </td>
                                             <td>
                                                 <div className="dropdown ">
                                                     <button className="td_btn" type="button" id="dropdownMenu2" data-toggle="dropdown"
                                                         aria-haspopup="true" aria-expanded="false">
                                                         <span className="text">L{i+1}</span> <img src={patientValues[item.toLowerCase().replace(/\W/g, '')+dayBeforetwo.toLowerCase().replace(/\W/g, '')+"_right_l_"+i] || widthsvg} className="icon" />
                                                     </button>
                                                     <div className="dropdown-menu symbols" aria-labelledby="dropdownMenu2">
                                                         <button className="dropdown-item item1" type="button" 
                                                         onClick={(e) => { handleSelect(e, widthsvg, item.toLowerCase().replace(/\W/g, '')+dayBeforetwo.toLowerCase().replace(/\W/g, '')+"_right_l_"+i) }} > <img
                                                             src={widthsvg} /></button>
                                                         <button className="dropdown-item item2" onClick={(e) => { handleSelect(e, trending_down, item.toLowerCase().replace(/\W/g, '')+dayBeforetwo.toLowerCase().replace(/\W/g, '')+"_right_l_"+i) }}  type="button"> <img
                                                             src={trending_down} /></button>
                                                         <button className="dropdown-item item3" onClick={(e) => { handleSelect(e, trending_up, item.toLowerCase().replace(/\W/g, '')+dayBeforetwo.toLowerCase().replace(/\W/g, '')+"_right_l_"+i) }}  type="button"> <img
                                                             src={trending_up} /></button>
                                                     </div>
                                                 </div>
                                             </td>
                                             <td>
                                                 <div className="dropdown ">
                                                     <button className="td_btn" type="button" id="dropdownMenu2" data-toggle="dropdown"
                                                         aria-haspopup="true" aria-expanded="false">
                                                         <span className="text">L{i+1}</span> <img src={patientValues[item.toLowerCase().replace(/\W/g, '')+dayBeforetwo.toLowerCase().replace(/\W/g, '')+"_left_l_"+i] || widthsvg} className="icon" />
                                                     </button>
                                                     <div className="dropdown-menu symbols" aria-labelledby="dropdownMenu2">
                                                         <button className="dropdown-item item1" type="button" 
                                                         onClick={(e) => { handleSelect(e, widthsvg, item.toLowerCase().replace(/\W/g, '')+dayBeforetwo.toLowerCase().replace(/\W/g, '')+"_left_l_"+i) }} > <img
                                                             src={widthsvg} /></button>
                                                         <button className="dropdown-item item2" onClick={(e) => { handleSelect(e, trending_down, item.toLowerCase().replace(/\W/g, '')+dayBeforetwo.toLowerCase().replace(/\W/g, '')+"_left_l_"+i) }}  type="button"> <img
                                                             src={trending_down} /></button>
                                                         <button className="dropdown-item item3" onClick={(e) => { handleSelect(e, trending_up, item.toLowerCase().replace(/\W/g, '')+dayBeforetwo.toLowerCase().replace(/\W/g, '')+"_left_l_"+i) }}  type="button"> <img
                                                             src={trending_up} /></button>
                                                     </div>
                                                 </div>
                                             </td>
                                             <td>
                                                 <div className="dropdown ">
                                                     <button className="td_btn" type="button" id="dropdownMenu2" data-toggle="dropdown"
                                                         aria-haspopup="true" aria-expanded="false">
                                                         <span className="text">L{i+1}</span> <img src={patientValues[item.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_right_l_"+i] || widthsvg} className="icon" />
                                                     </button>
                                                     <div className="dropdown-menu symbols" aria-labelledby="dropdownMenu2">
                                                         <button className="dropdown-item item1" type="button" 
                                                         onClick={(e) => { handleSelect(e, widthsvg, item.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_right_l_"+i) }} > <img
                                                             src={widthsvg} /></button>
                                                         <button className="dropdown-item item2" onClick={(e) => { handleSelect(e, trending_down, item.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_right_l_"+i) }}  type="button"> <img
                                                             src={trending_down} /></button>
                                                         <button className="dropdown-item item3" onClick={(e) => { handleSelect(e, trending_up, item.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_right_l_"+i) }}  type="button"> <img
                                                             src={trending_up} /></button>
                                                     </div>
                                                 </div>
                                             </td>
                                             <td>
                                                 <div className="dropdown ">
                                                     <button className="td_btn" type="button" id="dropdownMenu2" data-toggle="dropdown"
                                                         aria-haspopup="true" aria-expanded="false">
                                                         <span className="text">L{i+1}</span> <img src={patientValues[item.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_left_l_"+i] || widthsvg} className="icon" />
                                                     </button>
                                                     <div className="dropdown-menu symbols" aria-labelledby="dropdownMenu2">
                                                         <button className="dropdown-item item1" type="button" 
                                                         onClick={(e) => { handleSelect(e, widthsvg, item.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_left_l_"+i) }} > <img
                                                             src={widthsvg} /></button>
                                                         <button className="dropdown-item item2" onClick={(e) => { handleSelect(e, trending_down, item.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_left_l_"+i) }}  type="button"> <img
                                                             src={trending_down} /></button>
                                                         <button className="dropdown-item item3" onClick={(e) => { handleSelect(e, trending_up, item.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_left_l_"+i) }}  type="button"> <img
                                                             src={trending_up} /></button>
                                                     </div>
                                                 </div>
                                             </td>
                                         </tr>
                                     ))}


{lowerSensationC2 && lowerSensationC2.length > 0 && lowerSensationC2.map((item, i) => (
                                     
                                     <tr key={i} className="bg2">
                                             <td className="headcol" >
                                                 {item}
                                             </td>
                                             <td>
                                                 <div className="dropdown ">
                                                     <button className="td_btn" type="button" id="dropdownMenu2" data-toggle="dropdown"
                                                         aria-haspopup="true" aria-expanded="false">
                                                         <span className="text">S{i+1}</span> <img src={patientValues[item.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_right_s_"+i] || widthsvg} className="icon" />
                                                     </button>
                                                     <div className="dropdown-menu symbols" aria-labelledby="dropdownMenu2">
                                                         <button className="dropdown-item item1" type="button" 
                                                         onClick={(e) => { handleSelect(e, widthsvg, item.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_right_s_"+i) }} > <img
                                                             src={widthsvg} /></button>
                                                         <button className="dropdown-item item2" onClick={(e) => { handleSelect(e, trending_down, item.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_right_s_"+i) }}  type="button"> <img
                                                             src={trending_down} /></button>
                                                         <button className="dropdown-item item3" onClick={(e) => { handleSelect(e, trending_up, item.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_right_s_"+i) }}  type="button"> <img
                                                             src={trending_up} /></button>
                                                     </div>
                                                 </div>
                                             </td>
                                             <td>
                                                 <div className="dropdown ">
                                                     <button className="td_btn" type="button" id="dropdownMenu2" data-toggle="dropdown"
                                                         aria-haspopup="true" aria-expanded="false">
                                                         <span className="text">S{i+1}</span> <img src={patientValues[item.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_left_s_"+i] || widthsvg} className="icon" />
                                                     </button>
                                                     <div className="dropdown-menu symbols" aria-labelledby="dropdownMenu2">
                                                         <button className="dropdown-item item1" type="button" 
                                                         onClick={(e) => { handleSelect(e, widthsvg, item.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_left_s_"+i) }} > <img
                                                             src={widthsvg} /></button>
                                                         <button className="dropdown-item item2" onClick={(e) => { handleSelect(e, trending_down, item.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_left_s_"+i) }}  type="button"> <img
                                                             src={trending_down} /></button>
                                                         <button className="dropdown-item item3" onClick={(e) => { handleSelect(e, trending_up, item.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_left_s_"+i) }}  type="button"> <img
                                                             src={trending_up} /></button>
                                                     </div>
                                                 </div>
                                             </td>
                                             <td>
                                                 <div className="dropdown ">
                                                     <button className="td_btn" type="button" id="dropdownMenu2" data-toggle="dropdown"
                                                         aria-haspopup="true" aria-expanded="false">
                                                         <span className="text">S{i+1}</span> <img src={patientValues[item.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_right_s_"+i] || widthsvg} className="icon" />
                                                     </button>
                                                     <div className="dropdown-menu symbols" aria-labelledby="dropdownMenu2">
                                                         <button className="dropdown-item item1" type="button" 
                                                         onClick={(e) => { handleSelect(e, widthsvg, item.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_right_s_"+i) }} > <img
                                                             src={widthsvg} /></button>
                                                         <button className="dropdown-item item2" onClick={(e) => { handleSelect(e, trending_down, item.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_right_s_"+i) }}  type="button"> <img
                                                             src={trending_down} /></button>
                                                         <button className="dropdown-item item3" onClick={(e) => { handleSelect(e, trending_up, item.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_right_s_"+i) }}  type="button"> <img
                                                             src={trending_up} /></button>
                                                     </div>
                                                 </div>
                                             </td>
                                             <td>
                                                 <div className="dropdown ">
                                                     <button className="td_btn" type="button" id="dropdownMenu2" data-toggle="dropdown"
                                                         aria-haspopup="true" aria-expanded="false">
                                                         <span className="text">S{i+1}</span> <img src={patientValues[item.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_left_s_"+i] || widthsvg} className="icon" />
                                                     </button>
                                                     <div className="dropdown-menu symbols" aria-labelledby="dropdownMenu2">
                                                         <button className="dropdown-item item1" type="button" 
                                                         onClick={(e) => { handleSelect(e, widthsvg, item.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_left_s_"+i) }} > <img
                                                             src={widthsvg} /></button>
                                                         <button className="dropdown-item item2" onClick={(e) => { handleSelect(e, trending_down, item.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_left_s_"+i) }}  type="button"> <img
                                                             src={trending_down} /></button>
                                                         <button className="dropdown-item item3" onClick={(e) => { handleSelect(e, trending_up, item.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_left_s_"+i) }}  type="button"> <img
                                                             src={trending_up} /></button>
                                                     </div>
                                                 </div>
                                             </td>
                                             <td>
                                                 <div className="dropdown ">
                                                     <button className="td_btn" type="button" id="dropdownMenu2" data-toggle="dropdown"
                                                         aria-haspopup="true" aria-expanded="false">
                                                         <span className="text">S{i+1}</span> <img src={patientValues[item.toLowerCase().replace(/\W/g, '')+dayBeforetwo.toLowerCase().replace(/\W/g, '')+"_right_s_"+i] || widthsvg} className="icon" />
                                                     </button>
                                                     <div className="dropdown-menu symbols" aria-labelledby="dropdownMenu2">
                                                         <button className="dropdown-item item1" type="button" 
                                                         onClick={(e) => { handleSelect(e, widthsvg, item.toLowerCase().replace(/\W/g, '')+dayBeforetwo.toLowerCase().replace(/\W/g, '')+"_right_s_"+i) }} > <img
                                                             src={widthsvg} /></button>
                                                         <button className="dropdown-item item2" onClick={(e) => { handleSelect(e, trending_down, item.toLowerCase().replace(/\W/g, '')+dayBeforetwo.toLowerCase().replace(/\W/g, '')+"_right_s_"+i) }}  type="button"> <img
                                                             src={trending_down} /></button>
                                                         <button className="dropdown-item item3" onClick={(e) => { handleSelect(e, trending_up, item.toLowerCase().replace(/\W/g, '')+dayBeforetwo.toLowerCase().replace(/\W/g, '')+"_right_s_"+i) }}  type="button"> <img
                                                             src={trending_up} /></button>
                                                     </div>
                                                 </div>
                                             </td>
                                             <td>
                                                 <div className="dropdown ">
                                                     <button className="td_btn" type="button" id="dropdownMenu2" data-toggle="dropdown"
                                                         aria-haspopup="true" aria-expanded="false">
                                                         <span className="text">S{i+1}</span> <img src={patientValues[item.toLowerCase().replace(/\W/g, '')+dayBeforetwo.toLowerCase().replace(/\W/g, '')+"_left_s_"+i] || widthsvg} className="icon" />
                                                     </button>
                                                     <div className="dropdown-menu symbols" aria-labelledby="dropdownMenu2">
                                                         <button className="dropdown-item item1" type="button" 
                                                         onClick={(e) => { handleSelect(e, widthsvg, item.toLowerCase().replace(/\W/g, '')+dayBeforetwo.toLowerCase().replace(/\W/g, '')+"_left_s_"+i) }} > <img
                                                             src={widthsvg} /></button>
                                                         <button className="dropdown-item item2" onClick={(e) => { handleSelect(e, trending_down, item.toLowerCase().replace(/\W/g, '')+dayBeforetwo.toLowerCase().replace(/\W/g, '')+"_left_s_"+i) }}  type="button"> <img
                                                             src={trending_down} /></button>
                                                         <button className="dropdown-item item3" onClick={(e) => { handleSelect(e, trending_up, item.toLowerCase().replace(/\W/g, '')+dayBeforetwo.toLowerCase().replace(/\W/g, '')+"_left_s_"+i) }}  type="button"> <img
                                                             src={trending_up} /></button>
                                                     </div>
                                                 </div>
                                             </td>
                                             <td>
                                                 <div className="dropdown ">
                                                     <button className="td_btn" type="button" id="dropdownMenu2" data-toggle="dropdown"
                                                         aria-haspopup="true" aria-expanded="false">
                                                         <span className="text">S{i+1}</span> <img src={patientValues[item.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_right_s_"+i] || widthsvg} className="icon" />
                                                     </button>
                                                     <div className="dropdown-menu symbols" aria-labelledby="dropdownMenu2">
                                                         <button className="dropdown-item item1" type="button" 
                                                         onClick={(e) => { handleSelect(e, widthsvg, item.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_right_s_"+i) }} > <img
                                                             src={widthsvg} /></button>
                                                         <button className="dropdown-item item2" onClick={(e) => { handleSelect(e, trending_down, item.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_right_s_"+i) }}  type="button"> <img
                                                             src={trending_down} /></button>
                                                         <button className="dropdown-item item3" onClick={(e) => { handleSelect(e, trending_up, item.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_right_s_"+i) }}  type="button"> <img
                                                             src={trending_up} /></button>
                                                     </div>
                                                 </div>
                                             </td>
                                             <td>
                                                 <div className="dropdown ">
                                                     <button className="td_btn" type="button" id="dropdownMenu2" data-toggle="dropdown"
                                                         aria-haspopup="true" aria-expanded="false">
                                                         <span className="text">S{i+1}</span> <img src={patientValues[item.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_left_s_"+i] || widthsvg} className="icon" />
                                                     </button>
                                                     <div className="dropdown-menu symbols" aria-labelledby="dropdownMenu2">
                                                         <button className="dropdown-item item1" type="button" 
                                                         onClick={(e) => { handleSelect(e, widthsvg, item.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_left_s_"+i) }} > <img
                                                             src={widthsvg} /></button>
                                                         <button className="dropdown-item item2" onClick={(e) => { handleSelect(e, trending_down, item.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_left_s_"+i) }}  type="button"> <img
                                                             src={trending_down} /></button>
                                                         <button className="dropdown-item item3" onClick={(e) => { handleSelect(e, trending_up, item.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_left_s_"+i) }}  type="button"> <img
                                                             src={trending_up} /></button>
                                                     </div>
                                                 </div>
                                             </td>
                                         </tr>
                                     ))}


                                  
                                   


                                    {lowersensationCNerve && lowersensationCNerve.length > 0 && lowersensationCNerve.map((item, i) => (
                                     
                                     <tr className="bg3" key={i}>
                                             <td className="headcol" key={i}>
                                                 {item}
                                             </td>
                                             <td>
                                                 <div className="dropdown ">
                                                     <button className="td_btn" type="button" id="dropdownMenu2" data-toggle="dropdown"
                                                         aria-haspopup="true" aria-expanded="false">
                                                          <img src={patientValues[item.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_right_"+i] || widthsvg} className="icon" />
                                                     </button>
                                                     <div className="dropdown-menu symbols" aria-labelledby="dropdownMenu2">
                                                         <button className="dropdown-item item1" type="button" 
                                                         onClick={(e) => { handleSelect(e, widthsvg, item.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_right_"+i) }} > <img
                                                             src={widthsvg} /></button>
                                                         <button className="dropdown-item item2" onClick={(e) => { handleSelect(e, trending_down, item.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_right_"+i) }}  type="button"> <img
                                                             src={trending_down} /></button>
                                                         <button className="dropdown-item item3" onClick={(e) => { handleSelect(e, trending_up, item.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_right_"+i) }}  type="button"> <img
                                                             src={trending_up} /></button>
                                                     </div>
                                                 </div>
                                             </td>
                                             <td>
                                                 <div className="dropdown ">
                                                     <button className="td_btn" type="button" id="dropdownMenu2" data-toggle="dropdown"
                                                         aria-haspopup="true" aria-expanded="false">
                                                          <img src={patientValues[item.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_left_"+i] || widthsvg} className="icon" />
                                                     </button>
                                                     <div className="dropdown-menu symbols" aria-labelledby="dropdownMenu2">
                                                         <button className="dropdown-item item1" type="button" 
                                                         onClick={(e) => { handleSelect(e, widthsvg, item.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_left_"+i) }} > <img
                                                             src={widthsvg} /></button>
                                                         <button className="dropdown-item item2" onClick={(e) => { handleSelect(e, trending_down, item.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_left_"+i) }}  type="button"> <img
                                                             src={trending_down} /></button>
                                                         <button className="dropdown-item item3" onClick={(e) => { handleSelect(e, trending_up, item.toLowerCase().replace(/\W/g, '')+todayDate.toLowerCase().replace(/\W/g, '')+"_left_"+i) }}  type="button"> <img
                                                             src={trending_up} /></button>
                                                     </div>
                                                 </div>
                                             </td>
                                             <td>
                                                 <div className="dropdown ">
                                                     <button className="td_btn" type="button" id="dropdownMenu2" data-toggle="dropdown"
                                                         aria-haspopup="true" aria-expanded="false">
                                                          <img src={patientValues[item.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_right_"+i] || widthsvg} className="icon" />
                                                     </button>
                                                     <div className="dropdown-menu symbols" aria-labelledby="dropdownMenu2">
                                                         <button className="dropdown-item item1" type="button" 
                                                         onClick={(e) => { handleSelect(e, widthsvg, item.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_right_"+i) }} > <img
                                                             src={widthsvg} /></button>
                                                         <button className="dropdown-item item2" onClick={(e) => { handleSelect(e, trending_down, item.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_right_"+i) }}  type="button"> <img
                                                             src={trending_down} /></button>
                                                         <button className="dropdown-item item3" onClick={(e) => { handleSelect(e, trending_up, item.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_right_"+i) }}  type="button"> <img
                                                             src={trending_up} /></button>
                                                     </div>
                                                 </div>
                                             </td>
                                             <td>
                                                 <div className="dropdown ">
                                                     <button className="td_btn" type="button" id="dropdownMenu2" data-toggle="dropdown"
                                                         aria-haspopup="true" aria-expanded="false">
                                                          <img src={patientValues[item.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_left_"+i] || widthsvg} className="icon" />
                                                     </button>
                                                     <div className="dropdown-menu symbols" aria-labelledby="dropdownMenu2">
                                                         <button className="dropdown-item item1" type="button" 
                                                         onClick={(e) => { handleSelect(e, widthsvg, item.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_left_"+i) }} > <img
                                                             src={widthsvg} /></button>
                                                         <button className="dropdown-item item2" onClick={(e) => { handleSelect(e, trending_down, item.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_left_"+i) }}  type="button"> <img
                                                             src={trending_down} /></button>
                                                         <button className="dropdown-item item3" onClick={(e) => { handleSelect(e, trending_up, item.toLowerCase().replace(/\W/g, '')+dayBeforeone.toLowerCase().replace(/\W/g, '')+"_left_"+i) }}  type="button"> <img
                                                             src={trending_up} /></button>
                                                     </div>
                                                 </div>
                                             </td>
                                             <td>
                                                 <div className="dropdown ">
                                                     <button className="td_btn" type="button" id="dropdownMenu2" data-toggle="dropdown"
                                                         aria-haspopup="true" aria-expanded="false">
                                                          <img src={patientValues[item.toLowerCase().replace(/\W/g, '')+dayBeforetwo.toLowerCase().replace(/\W/g, '')+"_right_"+i] || widthsvg} className="icon" />
                                                     </button>
                                                     <div className="dropdown-menu symbols" aria-labelledby="dropdownMenu2">
                                                         <button className="dropdown-item item1" type="button" 
                                                         onClick={(e) => { handleSelect(e, widthsvg, item.toLowerCase().replace(/\W/g, '')+dayBeforetwo.toLowerCase().replace(/\W/g, '')+"_right_"+i) }} > <img
                                                             src={widthsvg} /></button>
                                                         <button className="dropdown-item item2" onClick={(e) => { handleSelect(e, trending_down, item.toLowerCase().replace(/\W/g, '')+dayBeforetwo.toLowerCase().replace(/\W/g, '')+"_right_"+i) }}  type="button"> <img
                                                             src={trending_down} /></button>
                                                         <button className="dropdown-item item3" onClick={(e) => { handleSelect(e, trending_up, item.toLowerCase().replace(/\W/g, '')+dayBeforetwo.toLowerCase().replace(/\W/g, '')+"_right_"+i) }}  type="button"> <img
                                                             src={trending_up} /></button>
                                                     </div>
                                                 </div>
                                             </td>
                                             <td>
                                                 <div className="dropdown ">
                                                     <button className="td_btn" type="button" id="dropdownMenu2" data-toggle="dropdown"
                                                         aria-haspopup="true" aria-expanded="false">
                                                          <img src={patientValues[item.toLowerCase().replace(/\W/g, '')+dayBeforetwo.toLowerCase().replace(/\W/g, '')+"_left_"+i] || widthsvg} className="icon" />
                                                     </button>
                                                     <div className="dropdown-menu symbols" aria-labelledby="dropdownMenu2">
                                                         <button className="dropdown-item item1" type="button" 
                                                         onClick={(e) => { handleSelect(e, widthsvg, item.toLowerCase().replace(/\W/g, '')+dayBeforetwo.toLowerCase().replace(/\W/g, '')+"_left_"+i) }} > <img
                                                             src={widthsvg} /></button>
                                                         <button className="dropdown-item item2" onClick={(e) => { handleSelect(e, trending_down, item.toLowerCase().replace(/\W/g, '')+dayBeforetwo.toLowerCase().replace(/\W/g, '')+"_left_"+i) }}  type="button"> <img
                                                             src={trending_down} /></button>
                                                         <button className="dropdown-item item3" onClick={(e) => { handleSelect(e, trending_up, item.toLowerCase().replace(/\W/g, '')+dayBeforetwo.toLowerCase().replace(/\W/g, '')+"_left_"+i) }}  type="button"> <img
                                                             src={trending_up} /></button>
                                                     </div>
                                                 </div>
                                             </td>
                                             <td>
                                                 <div className="dropdown ">
                                                     <button className="td_btn" type="button" id="dropdownMenu2" data-toggle="dropdown"
                                                         aria-haspopup="true" aria-expanded="false">
                                                          <img src={patientValues[item.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_right_"+i] || widthsvg} className="icon" />
                                                     </button>
                                                     <div className="dropdown-menu symbols" aria-labelledby="dropdownMenu2">
                                                         <button className="dropdown-item item1" type="button" 
                                                         onClick={(e) => { handleSelect(e, widthsvg, item.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_right_"+i) }} > <img
                                                             src={widthsvg} /></button>
                                                         <button className="dropdown-item item2" onClick={(e) => { handleSelect(e, trending_down, item.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_right_"+i) }}  type="button"> <img
                                                             src={trending_down} /></button>
                                                         <button className="dropdown-item item3" onClick={(e) => { handleSelect(e, trending_up, item.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_right_"+i) }}  type="button"> <img
                                                             src={trending_up} /></button>
                                                     </div>
                                                 </div>
                                             </td>
                                             <td>
                                                 <div className="dropdown ">
                                                     <button className="td_btn" type="button" id="dropdownMenu2" data-toggle="dropdown"
                                                         aria-haspopup="true" aria-expanded="false">
                                                          <img src={patientValues[item.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_left_"+i] || widthsvg} className="icon" />
                                                     </button>
                                                     <div className="dropdown-menu symbols" aria-labelledby="dropdownMenu2">
                                                         <button className="dropdown-item item1" type="button" 
                                                         onClick={(e) => { handleSelect(e, widthsvg, item.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_left_"+i) }} > <img
                                                             src={widthsvg} /></button>
                                                         <button className="dropdown-item item2" onClick={(e) => { handleSelect(e, trending_down, item.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_left_"+i) }}  type="button"> <img
                                                             src={trending_down} /></button>
                                                         <button className="dropdown-item item3" onClick={(e) => { handleSelect(e, trending_up, item.toLowerCase().replace(/\W/g, '')+dayBeforethree.toLowerCase().replace(/\W/g, '')+"_left_"+i) }}  type="button"> <img
                                                             src={trending_up} /></button>
                                                     </div>
                                                 </div>
                                             </td>
                                         </tr>
                                     ))}

                                   
                                </tbody>
                            </table>
                        </div>
                        <button type="button" className="update_btn mb-30">Submit</button>
                        <br /><br /><br /><br />
                    </div>
                </div>
            </div>




        </React.Fragment>
    )
}

sericalSpinal.propTypes = {
    getWards: PropTypes.func,
    getTheatres: PropTypes.func,
    wards: PropTypes.array,
    user: PropTypes.any,
    theatres: PropTypes.array,
    clinic: PropTypes.array,
    patients: PropTypes.array,
    doctors: PropTypes.array,
    profile: PropTypes.object,
    news: PropTypes.array,
}

const mapStateToProps = ({ Layout, Login }) => ({
    wards: Layout.wards,
    user: Login.user,
    theatres: Layout.theatres,
    clinic: Layout.clinic,
    patients: Layout.patients,
    doctors: Layout.doctors,
    profile: Layout.profile,
    news: Layout.news,
    dailyrounds: Layout.dailyrounds
})

const mapDispatchToProps = dispatch => ({
    getWards: () => dispatch(getWardsList()),
    getTheatres: () => dispatch(getTheatresList()),
    getClinic: () => dispatch(getClinicList()),
    getPatient: () => dispatch(getPatientList()),
    getUserprofile: (id) => dispatch(getUprofile(id)),
    getDoctor: () => dispatch(getDoctorList()),
    patientupdate: (id, data) => dispatch(updatePatient(id, data)),
    newsList: (data) => dispatch(getNews(data)),
    dailyRoundsList: (data) => dispatch(getDailyRounds(data)),
    dailyUpdate: (data) => dispatch(updateDailyRounds(data)),
    teamupdatelist: (data, tablename) => dispatch(teamListUpdate(data, tablename))
})

export default connect(mapStateToProps, mapDispatchToProps)(sericalSpinal)
