import React from "react";

function IconViewArrow(props) {
  const { iconColor = "#0F2E57", iconSize = 10 } = props;
  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox="0 0 9 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.75 1.5C8.75 1.08579 8.41421 0.75 8 0.75L1.25 0.75C0.835786 0.75 0.5 1.08579 0.5 1.5C0.5 1.91421 0.835786 2.25 1.25 2.25H7.25V8.25C7.25 8.66421 7.58579 9 8 9C8.41421 9 8.75 8.66421 8.75 8.25L8.75 1.5ZM1.53033 9.03033L8.53033 2.03033L7.46967 0.96967L0.46967 7.96967L1.53033 9.03033Z"
        fill={iconColor}
      />
    </svg>
  );
}

export default IconViewArrow;
