import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import MetaTags from 'react-meta-tags';
import surgery from '../../assets/images/surgery.png';
import { connect } from "react-redux"
import { getWardsList, getTheatresList, getClinicList, getPatientList, getUprofile, getDoctorList, updatePatient } from "../../store/actions"
import { removeSpecialCharacters } from '../../utils/utils';
const Tpatient = props => {

    const [clinicValues, setClinic] = useState({})
    const [file, setFile] = useState({});

    useEffect(() => {
        const { getPatient } = props

        getPatient()
        // getDoctor()
        //   let userId = localStorage.getItem("authUser")
        //   getUserprofile(userId)
    }, [])

    const updateInputValue = (e) => {
        let val = e.target.value;
        let name = e.target.name;
        let updatedValue = {};
        updatedValue = { [name]: val };
        setClinic(clinicValues => ({
            ...clinicValues,
            ...updatedValue
        }));
    }

    const updateClinicValues = (e, name, val) => {
        //  e.preventDefault()
        let updatedValue = {};
        updatedValue = { [name]: val };
        setClinic(clinicValues => ({
            ...clinicValues,
            ...updatedValue
        }));
    }

    const updateClinic = (e) => {
        const { patientupdate } = props
        let clinicPatient = JSON.parse(localStorage.getItem("p"))
        let id = clinicPatient._id
        patientupdate(id, clinicValues)
        let patient = {
            ...clinicPatient,
            ...clinicValues
        }
        localStorage.setItem("p", JSON.stringify(patient))

    }


    const handleFile = async (e, name) => {

        const file = e.target.files[0]
       // console.log("file ", file, name)
        let filename = e.target.files[0].name;
        // const base64 = await convertBase64(file)
       // console.log("filename ", filename)
        let pdetails = JSON.parse(localStorage.getItem("p"))
        // var reader = new FileReader();
        //   reader.readAsDataURL(file);
        // reader.onload = async function (e) {
        // console.log("e ", e)
        let uploadFilePath = "ipr/" + pdetails._id + "/" + name + "_result" + "/" + removeSpecialCharacters(filename);
        let data = { source_bucket: "ipr-resources", sourcepath: uploadFilePath }
        //console.log("data ", data)

        const response = await fetch(API_URL+'upload?appname=ipr', {
            method: "POST",
            mode: "cors",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
        const result = await response.json();

        //console.log("result ", result)
        let url = result.result
        //console.log("url ", url)
        const uploadImage = await fetch(url, {
            method: "PUT",
            mode: "cors",
            headers: {
                "Content-Type": "multipart/form-data",
                "Accept": "/",
                "Cache-Control": "no-cache",
                "Accept-Encoding": "gzip, deflate",
                "Connection": "keep-alive",
                "cache-control": "no-cache"
            },
            body: file
        })

        //console.log("resultImage ", uploadImage)

        let imagePath = "https://ipr-resources.s3.amazonaws.com/"
        let imageUploadPath = imagePath + uploadFilePath;

        const { patientupdate } = props
        let updateObj = {
            [name + "_result"]: imageUploadPath,
            [name + "_update"]: new Date()
        }
        patientupdate(pdetails._id, updateObj)
        setFile(file => ({
            ...file,
            ...updateObj
        }));
        setClinic(clinicValues => ({
            ...clinicValues,
            ...updateObj
        }));

        let patient = {
            ...pdetails,
            ...updateObj
        }
        localStorage.setItem("p", JSON.stringify(patient))

    };

    const menuClick = (e, menu) => {
        props.history.push("/" + menu)
    }

    const handleChange = (event) => {
        updateClinicValues(event, event.target.name, event.target.checked)
    }

    let clinicPatient = JSON.parse(localStorage.getItem("p"))

    // console.log("anydelay ", anydelay)
    //console.log("clinicValues ", clinicValues)
   // console.log("file ", file)
    return (
        <React.Fragment>

            <MetaTags>
                <title>About Patient</title>
            </MetaTags>
            <div className='popup_wrp theatre'>
                <div className="popup_full prescribe">
                    <div className='popup_hed'>
                        <h2> ABOUT THIS PATIENT</h2>
                        <a className='popup_close' onClick={(e) => menuClick(e, 'tpatient')}><span className="material-icons">close</span></a>
                    </div>
                    <div className='popup_content'>

                        <div className='popup_scroll main pr atp height190 '>

                            <div className="section simple_wound">
                                <h2 className="popup_title">ADVANCE NOTIFICATIONS</h2>
                                <div className="pd_border"></div>
                                <p className="field_title mt-0">ALERTS</p>
                                {clinicPatient.allergy || clinicValues.allergy ? <div className="check_list w_white">
                                    <div className="check_item" onClick={(e) => updateClinicValues(e, "allergy", false)} ><label className="check">{clinicPatient.allergy || clinicValues.allergy}</label></div>

                                </div> : (clinicValues.allergy == undefined || clinicValues.allergy == false) &&
                                <div className="check_list w_white">
                                    <div className="check_item" onClick={(e) => updateClinicValues(e, "allergy", "Latex allergy")} ><label className="check">Latex allergy</label></div>
                                    <div className="check_item" onClick={(e) => updateClinicValues(e, "allergy", "Metal allergy")}><label className="check">Metal allergy</label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateClinicValues(e, "allergy", "Other relevant allergy")}><label className="check"> Other relevant allergy </label></div>
                                    <div className="check_item" onClick={(e) => updateClinicValues(e, "allergy", "High BMI (over35) Weight")}><label className="check">High BMI (over35) Weight </label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateClinicValues(e, "allergy", "HIV/Hep B/C")}><label className="check">HIV/Hep B/C</label></div>
                                    <div className="check_item" onClick={(e) => updateClinicValues(e, "allergy", "Axillary clearance etc in women")}><label className="check">Axillary clearance etc in women
                                    </label></div>
                                    <div className="check_item" onClick={(e) => updateClinicValues(e, "allergy", "Primary language: English")}><label className="check">Primary language: English</label></div>

                                    <div className="check_item" onClick={(e) => updateClinicValues(e, "allergy", "MRSA/ COVID")}><label className="check">MRSA/ COVID</label></div>
                                    <div className="check_item" onClick={(e) => updateClinicValues(e, "allergy", "Patient has a Pacemaker / ICD - seen by technician")}><label className="check">Patient has a Pacemaker / ICD - seen by technician</label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateClinicValues(e, "allergy", "Jehovah’s witness: Patient refuses blood/blood products")}><label className="check"> Jehovah’s witness: Patient refuses blood/blood products</label></div>
                                    <div className="check_item" onClick={(e) => updateClinicValues(e, "allergy", "Disability / Mobility problem/Difficulty")}><label className="check">Disability / Mobility problem/Difficulty</label></div>
                                    <div className="check_item" onClick={(e) => updateClinicValues(e, "allergy", "Cytotoxic drugs in the last 48 hours")}><label className="check">Cytotoxic drugs in the last 48 hours</label></div>

                                    <div className="check_item" onClick={(e) => updateClinicValues(e, "allergy", "Prosthesis / Metals/screws/other prosthesis")}><label className="check">Prosthesis / Metals/screws/other prosthesis</label></div>
                                    <div className="check_item" onClick={(e) => updateClinicValues(e, "allergy", "LMP date: Comments: Pregnancy test/no tampons")}><label className="check">LMP date: Comments: Pregnancy test/no tampons</label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateClinicValues(e, "allergy", "Diabetic")}><label className="check">Diabetic</label></div>
                                    <div className="check_item" onClick={(e) => updateClinicValues(e, "allergy", "On anticoagulants")}><label className="check">On anticoagulants</label></div>
                                    <div className="check_item" onClick={(e) => updateClinicValues(e, "allergy", "Needle phobia")}><label className="check">Needle phobia</label></div>
                                    <div className="check_item" onClick={(e) => updateClinicValues(e, "allergy", "Sleep apnoea")}><label className="check">Sleep apnoea</label></div>

                                    <div className="check_item" onClick={(e) => updateClinicValues(e, "allergy", "Previous anaesthetic problem")}><label className="check">Previous anaesthetic problem</label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateClinicValues(e, "allergy", "Nut allergy")}><label className="check">Nut allergy</label>
                                    </div>

                                </div>}


                                <div className="form_group">
                                    <div className="floating-label-group"><input type="text" name="special_diet" onChange={(e) => updateInputValue(e)} value={clinicPatient.special_diet || clinicValues.special_diet} className="form-control" /><label
                                        className="floating-label">Special diet</label>
                                    </div>
                                </div>
                                <div className="form_group mb-20">
                                    <textarea className="textarea" name="concerns" onChange={(e) => updateInputValue(e)} value={clinicPatient.concerns || clinicValues.concerns} placeholder="Other concerns" rows="2"
                                        cols="30"></textarea>

                                </div>


                            </div>
                            <div className="section simple_wound ctr">
                                <h2 className="popup_title">Links to</h2>
                                <div className="pd_border"></div>
                                <div className="ctr cont consent tla">
                                    <div className="consent_btn"> <a className="landing_btn"> <img src={surgery}
                                        className="icon" /> LAST CLINIC DICTATION</a> </div>
                                    <div className="consent_btn"> <a className="landing_btn"> <img src={surgery}
                                        className="icon" /> LAST OPERATION RECORD</a> </div>
                                    <div className="consent_btn"> <a className="landing_btn"> <img src={surgery}
                                        className="icon" /> X RAYS </a> </div>
                                    <div className="consent_btn"> <a className="landing_btn"> <img src={surgery}
                                        className="icon" /> LAST ANAESTHETIC RECORD</a> </div>
                                </div>
                            </div>
                            <div className="section simple_wound">
                                <div className="row ctr">
                                    <div className="col-md-4">
                                        <div className="measure">
                                            <label>Age</label>
                                            <div className="dsp_flex"> <input type="text" name="age" onChange={(e) => updateInputValue(e)} value={clinicPatient.age || clinicValues.age} className="field" />
                                                <span>Years</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="measure">
                                            <label>HEIGHT</label>
                                            <div className="dsp_flex"> <input name="height" onChange={(e) => updateInputValue(e)} value={clinicPatient.height || clinicValues.height} type="text" className="field" />
                                                <span>cms</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="measure">
                                            <label>WEIGHT</label>
                                            <div className="dsp_flex"> <input name="weight" onChange={(e) => updateInputValue(e)} value={clinicPatient.weight || clinicValues.weight} type="text" className="field" />
                                                <span>Kgs</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="measure">
                                            <label>BMI</label>
                                            <div className="dsp_flex"> <input type="text" name="bmi" onChange={(e) => updateInputValue(e)} value={clinicPatient.bmi || clinicValues.bmi} className="field" />
                                                <span>kg/m2</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="check_item"><label className="check">ASA</label>
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input type="radio"
                                                    value="1" onClick={(e) => updateInputValue(e)} name="asa" checked={clinicValues.asa == "1" ? "checked" : ""} /><span>1</span></label></div>
                                                <div className="select_btn" ><label><input type="radio"
                                                    value="2" onClick={(e) => updateInputValue(e)} name="asa" checked={clinicValues.asa == "2" ? "checked" : ""} /><span>2</span></label></div>
                                                <div className="select_btn"><label><input type="radio"
                                                    value="3" onClick={(e) => updateInputValue(e)} name="asa" checked={clinicValues.asa == "3" ? "checked" : ""} /><span>3</span></label></div>
                                                <div className="select_btn"><label><input type="radio"
                                                    value="4" onClick={(e) => updateInputValue(e)} name="asa" checked={clinicValues.asa == "4" ? "checked" : ""} /><span>4</span></label></div>
                                                <div className="select_btn"><label><input type="radio"
                                                    value="5" onClick={(e) => updateInputValue(e)} name="asa" checked={clinicValues.asa == "5" ? "checked" : ""} /><span>5</span></label></div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="check_item"><label className="check">Hand</label>
                                            <div className="select_btns popup_select">
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="hand" checked={clinicValues.hand == "Left" ? "checked" : ""} type="radio"
                                                    value="Left" /><span>Left </span></label></div>
                                                <div className="select_btn"><label><input onClick={(e) => updateInputValue(e)} name="hand" checked={clinicValues.hand == "Right" ? "checked" : ""} type="radio"
                                                    value="Right" /><span>Right </span></label></div>

                                            </div>

                                        </div>
                                    </div>

                                </div>

                            </div>
                            <div className="section simple_wound">
                                <h2 className="popup_title">INVESTIGATIONS</h2>
                                <div className="pd_border"></div>
                                <div className="row ctr">
                                    <div className="col-md-4">
                                        <div className="measure">
                                            <label>HB</label>
                                            <div className="dsp_flex"> <input type="text" name="hb" onChange={(e) => updateInputValue(e)} value={clinicPatient.hb || clinicValues.hb} className="field" />
                                                <span>grams </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="measure">
                                            <label>WBC</label>
                                            <div className="dsp_flex"> <input type="text" name="wbc" onChange={(e) => updateInputValue(e)} value={clinicPatient.wbc || clinicValues.wbc} className="field" />
                                                <span>Ml</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="measure">
                                            <label>Hct</label>
                                            <div className="dsp_flex"> <input type="text" name="hct" onChange={(e) => updateInputValue(e)} value={clinicPatient.hct || clinicValues.hct} className="field" />
                                                <span>%</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="measure">
                                            <label>PLTS</label>
                                            <div className="dsp_flex"> <input type="text" name="units" onChange={(e) => updateInputValue(e)} value={clinicPatient.units || clinicValues.units} className="field" />
                                                <span>Units</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="measure">
                                            <label>Na</label>
                                            <div className="dsp_flex"> <input type="text" name="naunits" onChange={(e) => updateInputValue(e)} value={clinicPatient.naunits || clinicValues.naunits} className="field" />
                                                <span>Units</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="measure">
                                            <label>K</label>
                                            <div className="dsp_flex"> <input type="text" name="kunits" onChange={(e) => updateInputValue(e)} value={clinicPatient.kunits || clinicValues.kunits} className="field" />
                                                <span>Units</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="measure">
                                            <label>Cr</label>
                                            <div className="dsp_flex"> <input type="text" name="crunits" onChange={(e) => updateInputValue(e)} value={clinicPatient.crunits || clinicValues.crunits} className="field" />
                                                <span>Units</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="measure">
                                            <label>Ur</label>
                                            <div className="dsp_flex"> <input type="text" name="urunits" onChange={(e) => updateInputValue(e)} value={clinicPatient.urunits || clinicValues.urunits} className="field" />
                                                <span>Units</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="measure">
                                            <label>Cl</label>
                                            <div className="dsp_flex"> <input type="text" name="clunits" onChange={(e) => updateInputValue(e)} value={clinicPatient.clunits || clinicValues.clunits} className="field" />
                                                <span>Units</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="measure">
                                            <label>Venous HCO3</label>
                                            <div className="dsp_flex"> <input type="text" name="venous" onChange={(e) => updateInputValue(e)} value={clinicPatient.venous || clinicValues.venous} className="field" />
                                                <span>Units</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="measure">
                                            <label>GLUCOSE</label>
                                            <div className="dsp_flex"> <input type="text" name="glucose" onChange={(e) => updateInputValue(e)} value={clinicPatient.glucose || clinicValues.glucose} className="field" />
                                                <span>Units</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="measure">
                                            <label>BLOOD GROUP</label>
                                            <div className="dsp_flex"> <input type="text" name="bloodgroup" onChange={(e) => updateInputValue(e)} value={clinicPatient.bloodgroup || clinicValues.bloodgroup} className="field" />

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="measure">
                                            <label>INR</label>
                                            <div className="dsp_flex"> <input type="text" name="inr" onChange={(e) => updateInputValue(e)} value={clinicPatient.inr || clinicValues.inr} className="field" />

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="measure">
                                            <label>APTT</label>
                                            <div className="dsp_flex"> <input type="text" name="aptt" onChange={(e) => updateInputValue(e)} value={clinicPatient.aptt || clinicValues.aptt} className="field" />

                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="yn_switch ">
                                    <div className="label-container">
                                        <p>BLOOD GROUPED AND CROSS MATCHED? </p>
                                    </div>

                                    <label className="switch-container">
                                        <input onChange={(e) => handleChange(e)} name="bloodgroupcrossmatched" type="checkbox" className="switch_check" />
                                        <div className="switch-bg"></div>
                                        <div className="round-box"></div>
                                        <div className="switch-left">
                                            <span>YES</span>
                                        </div>
                                        <div className="switch-right">
                                            <span>NO</span>
                                        </div>
                                    </label>
                                </div>
                                <div className="form_group">
                                    <div className="floating-label-group"><input type="text" name="unitsofblood" onChange={(e) => updateInputValue(e)} value={clinicPatient.unitsofblood || clinicValues.unitsofblood}
                                        className="form-control" /><label
                                            className="floating-label">UNITS OF BLOOD AVAILABLE</label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        {
                                            clinicPatient.ecg_result || (file && file.ecg_result) ?
                                                <img src={(clinicPatient.ecg_result) || (file && file.ecg_result)} className="img" />
                                                :
                                                <div className="file_upload">
                                                    <div className="upload_file">
                                                        <span className="material-symbols-outlined">
                                                            cloud_upload
                                                        </span>
                                                    </div>
                                                    <input className="custom-file-input" type="file" onChange={(e) => handleFile(e, "ecg")} />

                                                    <div className="file_ttile">ECG</div>

                                                </div>}
                                    </div>
                                    <div className="col-md-6">
                                    {
                                            clinicPatient.cxr_result || (file && file.cxr_result) ?
                                                <img src={(clinicPatient.cxr_result) || (file && file.cxr_result)} className="img" />
                                                :
                                        <div className="file_upload">
                                            <div className="upload_file">
                                                <span className="material-symbols-outlined">
                                                    cloud_upload
                                                </span>
                                            </div>
                                            <input className="custom-file-input" type="file" onChange={(e) => handleFile(e, "cxr")} />

                                            <div className="file_ttile">CXR</div>
                                        </div>}
                                    </div>
                                    <div className="col-md-6">
                                    {
                                            clinicPatient.limbxray_result || (file && file.limbxray_result) ?
                                                <img src={(clinicPatient.limbxray_result) || (file && file.limbxray_result)} className="img" />
                                                :
                                        <div className="file_upload">
                                            <div className="upload_file">
                                                <span className="material-symbols-outlined">
                                                    cloud_upload
                                                </span>
                                            </div>
                                            <input className="custom-file-input" type="file" onChange={(e) => handleFile(e, "limbxray")} />

                                            <div className="file_ttile">LIMB Xray</div>
                                        </div>
                                    }
                                    </div>
                                    <div className="col-md-6">
                                    {
                                            clinicPatient.mri_result || (file && file.mri_result) ?
                                                <img src={(clinicPatient.mri_result) || (file && file.mri_result)} className="img" />
                                                :
                                        <div className="file_upload">
                                            <div className="upload_file">
                                                <span className="material-symbols-outlined">
                                                    cloud_upload
                                                </span>
                                            </div>
                                            <input className="custom-file-input" type="file" onChange={(e) => handleFile(e, "mri")} />

                                            <div className="file_ttile">MRI</div>
                                        </div>
}
                                    </div>
                                </div>
                                <div className="form_group">
                                    <div className="floating-label-group"><input type="text" name="medications" onChange={(e) => updateInputValue(e)} value={clinicPatient.medications || clinicValues.medications}
                                        className="form-control" /><label
                                            className="floating-label">MEDICATIONS</label>
                                    </div>
                                </div>
                                <div className="yn_switch ">
                                    <div className="label-container">
                                        <p>Any anticoagulants like Apixaban, warfarin Clopidogrel </p>
                                    </div>
                                    <label className="switch-container">
                                        <input onChange={(e) => handleChange(e)} name="anticoagulants" type="checkbox" className="switch_check" />
                                        <div className="switch-bg"></div>
                                        <div className="round-box"></div>
                                        <div className="switch-left">
                                            <span>YES</span>
                                        </div>
                                        <div className="switch-right">
                                            <span>NO</span>
                                        </div>
                                    </label>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text" name="lastdose" onChange={(e) => updateInputValue(e)} value={clinicPatient.lastdose || clinicValues.lastdose}
                                                className="form-control" /><label
                                                    className="floating-label">Last Dose</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text" name="inr2" onChange={(e) => updateInputValue(e)} value={clinicPatient.inr2 || clinicValues.inr2}
                                                className="form-control" /><label
                                                    className="floating-label">INR</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text" name="diabetes" onChange={(e) => updateInputValue(e)} value={clinicPatient.diabetes || clinicValues.diabetes}
                                                className="form-control" /><label
                                                    className="floating-label">Diabetes On sliding scale </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text" name="ivfluids" onChange={(e) => updateInputValue(e)} value={clinicPatient.ivfluids || clinicValues.ivfluids}
                                                className="form-control" /><label
                                                    className="floating-label">IV Fluids </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text" name="premeds" onChange={(e) => updateInputValue(e)} value={clinicPatient.premeds || clinicValues.premeds}
                                                className="form-control" /><label
                                                    className="floating-label">Pre meds </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form_group">
                                            <div className="floating-label-group"><input type="text" name="emlacream" onChange={(e) => updateInputValue(e)} value={clinicPatient.emlacream || clinicValues.emlacream}
                                                className="form-control" /><label
                                                    className="floating-label">Emla cream </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="yn_switch mt-20">
                                    <div className="label-container">
                                        <p>ACE inhibitors omitted on the day </p>
                                    </div>
                                    <label className="switch-container">
                                        <input onChange={(e) => handleChange(e)} name="aceinhibitors" type="checkbox" className="switch_check" />
                                        <div className="switch-bg"></div>
                                        <div className="round-box"></div>
                                        <div className="switch-left">
                                            <span>YES</span>
                                        </div>
                                        <div className="switch-right">
                                            <span>NO</span>
                                        </div>
                                    </label>
                                </div>
                                <div className="yn_switch ">
                                    <div className="label-container">
                                        <p>Inhalers / sprays taken to theatre </p>
                                    </div>
                                    <label className="switch-container">
                                        <input onChange={(e) => handleChange(e)} name="inhalers" type="checkbox" className="switch_check" />
                                        <div className="switch-bg"></div>
                                        <div className="round-box"></div>
                                        <div className="switch-left">
                                            <span>YES</span>
                                        </div>
                                        <div className="switch-right">
                                            <span>NO</span>
                                        </div>
                                    </label>
                                </div>

                            </div>
                            <div className="section simple_wound">
                                <h2 className="popup_title">VITAL SIGNS</h2>
                                <div className="pd_border"></div>
                                <div className="row ctr">
                                    <div className="col-md-4">
                                        <div className="measure">
                                            <label>TEMP</label>
                                            <div className="dsp_flex"> <input type="text" name="temp" onChange={(e) => updateInputValue(e)} value={clinicPatient.temp || clinicValues.temp} className="field" />
                                                <span>C</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="measure">
                                            <label>PR…</label>
                                            <div className="dsp_flex"> <input type="text" name="pressurebpm" onChange={(e) => updateInputValue(e)} value={clinicPatient.pressurebpm || clinicValues.pressurebpm} className="field" />
                                                <span>bpm </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="measure">
                                            <label>BP</label>
                                            <div className="dsp_flex"> <input type="text" name="bphg" onChange={(e) => updateInputValue(e)} value={clinicPatient.bphg || clinicValues.bphg} className="field" />
                                                <span>mm Hg</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="measure">
                                            <label>RESP. RATE </label>
                                            <div className="dsp_flex"> <input type="text" name="resprate" onChange={(e) => updateInputValue(e)} value={clinicPatient.resprate || clinicValues.resprate} className="field" />
                                                <span>min</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="measure">
                                            <label>SPO2 </label>
                                            <div className="dsp_flex"> <input type="text" name="spo2" onChange={(e) => updateInputValue(e)} value={clinicPatient.spo2 || clinicValues.spo2} className="field" />

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="measure">
                                            <label>BM</label>
                                            <div className="dsp_flex"> <input type="text" name="bm" onChange={(e) => updateInputValue(e)} value={clinicPatient.bm || clinicValues.bm} className="field" />

                                            </div>
                                        </div>
                                    </div>


                                </div>

                            </div>
                            <div className="section simple_wound">
                                <h2 className="popup_title">PERSONAL ITEMS</h2>
                                <div className="pd_border"></div>

                                {clinicPatient.personalitems || clinicValues.personalitems ? <div className="check_list w_white">
                                    <div className="check_item" onClick={(e) => updateClinicValues(e, "personalitems", false)} ><label className="check">{clinicPatient.personalitems || clinicValues.personalitems}</label></div>

                                </div> : (clinicValues.personalitems == undefined || clinicValues.personalitems == false) &&
                                <div className="check_list w_white">
                                    <div className="check_item" onClick={(e) => updateClinicValues(e, "personalitems", "Removed / taped: Jewellery - body piercings")}><label className="check">Removed / taped: Jewellery - body piercings</label></div>
                                    <div className="check_item" onClick={(e) => updateClinicValues(e, "personalitems", "Piercings to tongue/facial area removed by the patient")}><label className="check">Piercings to tongue/facial area removed by the patient </label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateClinicValues(e, "personalitems", "Cosmetics / nail varnish / False nails removed")}><label className="check"> Cosmetics / nail varnish / False nails removed</label></div>
                                    <div className="check_item" onClick={(e) => updateClinicValues(e, "personalitems", "Hearing aid/ Contact lenses / glasses /Dentures removed")}><label className="check">Hearing aid/ Contact lenses / glasses /Dentures removed </label>
                                    </div>
                                    <div className="check_item" onClick={(e) => updateClinicValues(e, "personalitems", "Any artificial limbs//prostheses/metalwork?")}><label className="check">Any artificial limbs//prostheses/metalwork?</label></div>

                                </div>}

                            </div>
                        </div>
                    </div>
                    <div className='popup_footer'>
                        <div className="prescribe_footer">
                            <div className="dsp-flex">
                                <a className="icon_btn" onClick={(e) => { updateClinic(e) }} > <span className="material-icons">save</span> SAVE</a>
                                <button className="border_btn" onClick={(e) => { updateClinic(e), menuClick(e, 'tpatient') }}><span className="material-icons">save_as</span>SAVE & CLOSE
                                </button>
                            </div>

                            <button className="btn dsp_flex" onClick={(e) => menuClick(e, 'tpatient')}><span className="material-icons"><span
                                className="material-icons">close</span></span>DISCARD </button>
                        </div>
                    </div>
                </div>
            </div>


        </React.Fragment>
    )
}

Tpatient.propTypes = {
    t: PropTypes.any,
    getWards: PropTypes.func,
    getTheatres: PropTypes.func,
    wards: PropTypes.array,
    user: PropTypes.any,
    theatres: PropTypes.array,
    clinic: PropTypes.array,
    patients: PropTypes.array,
    doctors: PropTypes.array,
    profile: PropTypes.object
}

const mapStateToProps = ({ Layout, Login }) => ({
    wards: Layout.wards,
    user: Login.user,
    theatres: Layout.theatres,
    clinic: Layout.clinic,
    patients: Layout.patients,
    doctors: Layout.doctors,
    profile: Layout.profile
})

const mapDispatchToProps = dispatch => ({
    getWards: () => dispatch(getWardsList()),
    getTheatres: () => dispatch(getTheatresList()),
    getClinic: () => dispatch(getClinicList()),
    getPatient: () => dispatch(getPatientList()),
    getUserprofile: (id) => dispatch(getUprofile(id)),
    getDoctor: () => dispatch(getDoctorList()),
    patientupdate: (id, data) => dispatch(updatePatient(id, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Tpatient)
